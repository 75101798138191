'use strict';

const Crypto = require('crypto');

'use strict';

export function triposService (freshideas) {
    freshideas.factory('TriPosService', ['Pure', function (Pure) {
        var _this = {};

        _this.createAuthHeader = function (message, developerKey, developerSecret) {
            var algorithm = 'tp-hmac-md5';
            var nonce = Pure.generateUuid();
            var requestDate = new Date().toISOString();
            var parsedUrl = new URL(message.url);
            var canonicalUri = parsedUrl.pathname;
            var canonicalQueryStr = parsedUrl.search;
            var method = message.method;
            var bodyHash = getBodyHash(JSON.stringify(message.data));

            // 1. Get the header information
            var canonicalHeaderInfo = getCanonicalHeaderInfo(message.headers);
            var canonicalSignedHeaders = canonicalHeaderInfo.canonicalSignedHeaders;
            var canonicalHeaderStr = canonicalHeaderInfo.canonicalHeaderStr;

            // 2. Calculate the request hash
            var requestHash = getCanonicalRequestHash(
                method, canonicalUri, canonicalQueryStr, canonicalHeaderStr, canonicalSignedHeaders, bodyHash
            );

            // 3. Get the signature hash
            var keySignatureHash = getKeySignatureHash(requestDate, nonce + developerSecret);

            var unhashedSignature = algorithm.toUpperCase() + '\n' + requestDate + '\n' + developerKey + '\n' + requestHash;

            // 4. Get the actual auth signature
            var signature = getKeySignatureHash(keySignatureHash, unhashedSignature);

            // 5. Create the auth header
            var tpAuthorization = [
                'Version=1.0',
                'Algorithm='+algorithm.toUpperCase(),
                'Credential='+developerKey,
                'SignedHeaders='+canonicalSignedHeaders,
                'Nonce='+nonce,
                'RequestDate='+requestDate,
                'Signature='+signature
            ].join(',');

            return tpAuthorization;
        };

        function getCanonicalHeaderInfo (headers) {
            var canonicalSignedHeaders = [];
            var canonicalHeaders = {};
            var uniqHeaders = [];
            _.each(headers, function (v, k) {
                if (k.indexOf('tp-') === 0) return;
                canonicalSignedHeaders.push(k);
                if (uniqHeaders.indexOf(k) === -1) {
                    // uniq
                    uniqHeaders.push(k);
                    // var headerHolder = {k: [v]};
                    canonicalHeaders[k] = [v];
                } else {
                    // not uniq
                    canonicalHeaders[k].push(v);
                }
            });
            canonicalSignedHeaders = canonicalSignedHeaders.sort().join(';');

            // each canonicalHeader is its own line in a multiline string
            var canonicalHeaderStr = '';
            var canonicalHeaderArray = [];
            _.each(canonicalHeaders, function (v, k) {
                canonicalHeaderArray.push(k+':'+v.join(', '));
            });

            canonicalHeaderStr = canonicalHeaderArray.sort().join('\n');

            return {
                canonicalSignedHeaders: canonicalSignedHeaders,
                canonicalHeaderStr: canonicalHeaderStr
            };
        }

        function getCanonicalRequestHash (method, uri, query, headerStr, signedHeaderStr, bodyHash) {
            var canonicalRequest = method + '\n';
            canonicalRequest += uri + '\n';
            if (query === null) query = '';
            canonicalRequest += query + '\n';
            canonicalRequest += headerStr + '\n';
            canonicalRequest += signedHeaderStr + '\n';
            canonicalRequest += bodyHash;

            return Crypto.createHash('md5').update(
                new Buffer(canonicalRequest, 'utf8')
            ).digest('hex');
        }

        function getBodyHash (body) {
          return Crypto.createHash('md5').update(
            new Buffer(body, 'utf8')
          ).digest('hex');
        }

        function getKeySignatureHash (key, data) {
          return Crypto.createHmac(
            'md5',
            new Buffer(key, 'utf8')
          ).update(
            new Buffer(data, 'utf8')
          ).digest('hex');
        }

        return _this;
    }]);
}
