'use strict';

export function cardTerminal (freshideas) {

    freshideas.constant('TerminalEvent', {
        SUCCESS: 'SUCCESS',
        FAILED: 'FAILED',
        PRINT: 'PRINT',
        BUSY: 'BUSY',
        BATCH_EMPTY: 'BATCH_EMPTY'
    });

    freshideas.factory('CardTerminal', [
        'CardTerminalOpenEdge',
        'CardTerminalGlobalPayEast',
        'iOSCardTerminalGlobalPayEast',
        'CardTerminalCtPayments',
        'CardTerminalVantiv',
        'CardTerminalHeartland',
        'CardTerminalClover',
        'CardTerminalMonerisCore',
        'iOSCardTerminalMonerisCore',
        'CardTerminalPax',
        'iOSCardTerminalPax',
        'CardTerminalEquinoxEPOS',
        'iOSCardTerminalEquinoxEpos',
        'Platform',
        function (
            CardTerminalOpenEdge,
            CardTerminalGlobalPayEast,
            iOSCardTerminalGlobalPayEast,
            CardTerminalCtPayments,
            CardTerminalVantiv,
            CardTerminalHeartLand,
            CardTerminalClover,
            CardTerminalMonerisCore,
            iOSCardTerminalMonerisCore,
            CardTerminalPax,
            iOSCardTerminalPax,
            CardTerminalEquinoxEPOS,
            iOSCardTerminalEquinoxEpos,
            Platform
        ) {

            var isIosWebkit = function () {
                return Platform.isIosWebkit();
            };

            var isTizen = function () {
                return Platform.isTizen();
            };

            var findProcessor = function (processorType) {

                if (processorType === 'globalpay') {
                    if (isIosWebkit() || isTizen()) {
                        return iOSCardTerminalGlobalPayEast;
                    } else {
                        return CardTerminalGlobalPayEast;
                    }
                } else if (processorType === 'openedge') {
                    return CardTerminalOpenEdge;
                } else if (processorType === 'ctpayment') {
                    return CardTerminalCtPayments;
                } else if (processorType === 'vantiv') {
                    return CardTerminalVantiv;
                } else if (processorType === 'heartland') {
                    return CardTerminalHeartLand;
                } else if (processorType === 'moneris') {
                    if (isIosWebkit() || isTizen()) {
                        // return iOSCardTerminalMoneris;
                        return iOSCardTerminalGlobalPayEast;
                    } else {
                        // return CardTerminalMoneris;
                        return CardTerminalGlobalPayEast;
                    }
                } else if (processorType === 'moneriscore') {
                    if (isIosWebkit() || isTizen()) {
                        // return iOSCardTerminalMoneris;
                        return iOSCardTerminalMonerisCore;
                    } else {
                        // return CardTerminalMoneris;
                        return CardTerminalMonerisCore;
                    }
                } else if (processorType === 'ingenico') {
                    if (isIosWebkit() || isTizen()) {
                        // return iOSCardTerminalIngenico;
                        return iOSCardTerminalGlobalPayEast;
                    } else {
                        // return CardTerminalIngenico;
                        return CardTerminalGlobalPayEast;
                    }
                } else if (processorType === 'clover' || processorType === 'clover_cloud') {
                    return CardTerminalClover;
                } else if (processorType === 'pax') {
                    if (isIosWebkit() || isTizen()) {
                        return iOSCardTerminalPax;
                    } else {
                        return CardTerminalPax;
                    }
                } else if (processorType === 'equinox-epos') {
                    if (isIosWebkit() || isTizen()) {
                        return iOSCardTerminalEquinoxEpos;
                    } else {
                        return CardTerminalEquinoxEPOS;
                    }
                } else {
                    return null;
                }
            };

            var parseResponse = function (response, type) {
                var cardInterface = findProcessor(type);

                return cardInterface.parseResponse(JSON.parse(response));
            };

            var init = function (terminalConfig) {
                try {
                    var processor = findProcessor(terminalConfig.type);

                    if (processor == null) {
                        return null;
                    }
                    processor.init(terminalConfig);
                    return processor;
                } catch (e) {
                    console.error(e);
                    return null;
                }
            };

            return {
                init: init,
                parseResponse: parseResponse,
                findProcessor: findProcessor
            };
        }
    ]);
}
