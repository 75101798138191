'use strict';

const angular = require('angular');
const $ = require('jquery');

export default angular.module('freshideas.services.export', []).factory('Export', [
    '$window',
    '$http',
    'Reports',
    function ($window, $http, Reports) {
        var cssInliner = function (element) {
            var reportStyleSheet = _.find(document.styleSheets, function (styleSheet) {
                return (styleSheet.href || '').indexOf('reports.css') > -1;
            });
            var rules = ((reportStyleSheet || {}).cssRules) || [];
            for (var idx = 0, len = rules.length; idx < len; idx++) {
                $(element).find(rules[idx].selectorText).each(function (i, elem) {
                    elem.style.cssText += rules[idx].style.cssText;
                });
            }
        };
        // s2ab = "string to array buffer", which is neeeded to export excel data
        // as a blob object. (https://stackoverflow.com/questions/34993292/how-to-save-xlsx-data-to-file-as-a-blob)
        var s2ab = function (s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        };

        // Excel Export: http://angularjs4u.com/modules/5-angularjs-microsoft-word-excel-integrations/
        var template = '<!--[if gte mso 9]&gt;{worksheet}&lt;![endif]--><table>{table}</table>', base64 = function (s) {
                return $window.btoa(unescape(encodeURIComponent(s)));
            }, format = function (s, c) {
                return s.replace(/{(\w+)}/g, function (m, p) {
                    return c[p];
                });
            };
        return {
            tableToExcel: function (tableId, worksheetName) {
                var tableClone = $(tableId).clone();
                tableClone.find('.downloadable-report').addClass('printable');
                cssInliner(tableClone);
                // somehow the new excel workflow does not ignore "display: hidden"
                // property set by `.print-hidden`. Instead, remove those elements.
                tableClone.find('.print-hidden').remove();
                var ctx = {
                    worksheet: worksheetName,
                    table: tableClone.html()
                };
                var encodedXls = base64(format(template, ctx));

                // Blob has much higher size limit than data URI. Might look into
                // using well-established libraries like TableExport/FileSaver/StreamSaver
                // in the future if we are running into more Excel realted issues.
                var blob = new Blob([s2ab(atob(encodedXls))], {type: 'application/vnd.ms-excel'});
                return blob;
            },
            tableToPdf: function (tableId, orientation) {
                window.print();
            },
            generateAndDownload: function (url, name) {
                return $http({
                    method: 'GET',
                    url: url
                }).then(function (response) {
                    var blob = new Blob([response.data], {type: 'text/csv'});

                    // Create a DOMString representing the blob and point the link element towards it
                    var url = window.URL.createObjectURL(blob);

                    // Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
                    var anchor = document.createElement('a');
                    anchor.style = 'display: none';
                    document.body.appendChild(anchor);

                    anchor.href = url;
                    anchor.download = name + '.csv';
                    anchor.click();

                    // Release the reference to the file by revoking the Object URL
                    window.URL.revokeObjectURL(url);

                    // Clean up the anchor element
                    anchor.parentNode.removeChild(anchor);
                });
            },
            downloadCSV: function (csvString, fileName) {
                var csvData = new Blob([csvString], {type: 'text/csv'});
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.style = 'display: none';
                var csvUrl = window.URL.createObjectURL(csvData);
                a.href = csvUrl;
                a.download = fileName + '.csv';
                a.click();
                window.URL.revokeObjectURL(a.href);
                a.remove();
            }
        };
    }
]);
