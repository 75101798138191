'use strict';


(function () {
    var LoyaltyCollection = (function () {
        var LoyaltyCollection = function () { };

        const lodash = require('lodash');

        var _rules = {
            0: {
                name: 'smb.pos.loyalty.level.one', // Regular
                value: 10,
                pointsRequired: 500,
                visitsRequired: 0,
                visitsPeriod: 0 // months
            },
            1: {
                name: 'smb.pos.loyalty.level.two', // Elite
                value: 15,
                pointsRequired: 3000,
                visitsRequired: 0,
                visitsPeriod: 0 // months
            },
            2: {
                name: 'smb.pos.loyalty.level.three', // Gold
                value: 30,
                pointsRequired: 0,
                visitsRequired: 20,
                visitsPeriod: 6 // months
            },
            /** Temporary addtition to add flexibility to points rules as per organization
            ** Please refactor when adding full functionality of rule set as per organization **/
            getRule: function (level, val) {
                var ruleToReturn = lodash.cloneDeep(this[level]);
                if (val) {
                    ruleToReturn.value = val;
                }
                return ruleToReturn;
            }
        };

        LoyaltyCollection.prototype.calculatePointsToCollect = function (subtotal, pointsDollarEquivalent, loyaltyLevel = 0, rules = _rules) {
            var rule = (pointsDollarEquivalent) ? rules.getRule(loyaltyLevel, pointsDollarEquivalent) : rules.getRule(loyaltyLevel);
            var pointsEarned = Math.round(subtotal * rule.value);

            return pointsEarned;
        };

        LoyaltyCollection.prototype.calculateNextLoyaltyLevel = function (level, loyalty, rules = _rules) {
            var nextLevel = level + 1;
            var rule = rules[nextLevel];

            if (!rule) { // no higher level so return current level
                return level;
            }
            if (rule.pointsRequired && loyalty.points && loyalty.points >= rule.pointsRequired) {
                return nextLevel;
            } else if (rule.visitsRequired && loyalty.totalVisits && loyalty.totalVisits >= rule.visitsRequired) {
                return nextLevel;
            }
            return level;
        };

        LoyaltyCollection.prototype.getLevelName = function (level, rules = _rules) {
            var _level = rules[level];

            if (_level) {
                return _level.name;
            } else {
                return '';
            }
        };

        return LoyaltyCollection;
    })();

    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = new LoyaltyCollection();
    } else {
        window.LoyaltyCollection = new LoyaltyCollection();
    }

})();
