'use strict';

const angular = require('angular');

let freshideasPosInventoryV2 = angular.module('freshideas.inventoryV2', []);
freshideasPosInventoryV2.constant('ORDER_STATUS', {
    INCOMING: 'IN-TRANSIT', // orders from Netsuite on arrival
    STORED: 'ARRIVED', // orders stored and not opened -> this action is called "stash" in the code to avoid confusion between "item storage" and "stored order" or even just "store"
    RECEIVED: 'RECEIVED', // orders opened and verified match (no missing/over)
    INCOMPLETE: 'PARTIALLY RECEIVED' // orders opened and verified but with missing/over
});


require('./inventory.service.js')(freshideasPosInventoryV2);

require('./inventory.controller.js')(freshideasPosInventoryV2);
require('./inventory.list.controller.js')(freshideasPosInventoryV2);
require('./inventory.receive.controller.js')(freshideasPosInventoryV2);
require('./inventory.order.controller.js')(freshideasPosInventoryV2);
require('./inventory.item.storageScan.controller.js')(freshideasPosInventoryV2);
require('./inventory.order.stash.controller.js')(freshideasPosInventoryV2);
require('../../common/controllers/scanningItemModal.js')(freshideasPosInventoryV2);

export default freshideasPosInventoryV2;


