'use strict';

const moment = require('moment');

export function cardTerminalMoneris (freshideas) {

    freshideas.factory('MonerisCommon', [
        '$log',
        '$timeout',
        'TerminalEvent',
        function ($log, $timeout, TerminalEvent) {
        var _self = {};

        var printResult;
        var responses;

        const HEARTBEAT = '\x11';
        const FS = '\x1c';

        const HOST_RESPONSE_CODE = '401';
        // const HOST_RESPONSE_TEXT = '402';
        // const TRANSACTION_SUCCESS = '000';

        const TRANSACTION_TYPE_PURCHASE = '00';
        const TRANSACTION_TYPE_REFUND = '03';
        const TRANSACTION_TYPE_VOID = '05';
        const TRANSACTION_TYPE_AUTO_SETTLE = '20';
        const LAST_TRANSACTION_STATUS = '91';

        const DATA_TAG_TRANSACTION_AMOUNT = '\x1c001';
        const DATA_TAG_TENDER_TYPE = '\x1c002';
        const DATA_TAG_AUTH_NO = '\x1c005';
        // const DATA_TAG_REFERENCE_ID = '\x1c011';
        // const DATA_TAG_TRAN_TYPE_CLASS = '\x1c013';

        // const TENDER_TYPE_DEBIT = '1';
        // const TENDER_TYPE_CREDIT = '2';

        const TRANSACTION_TYPE = '100';
        const TRANSACTION_STATUS = '101';

        const TRANSACTION_DATE = '102';
        const TRANSACTION_TIME = '103';

        // this differs from TOTAL_AMOUNT which also includes cashback.
        // this does not include cashback amount
        const TRANSACTION_AMOUNT = '104';
        const TIP_AMOUNT = '105';
        const DEBIT_SURCHARGE_AMOUNT = '107';
        const TOTAL_AMOUNT = '109';
        const INVOICE_NUMBER= '110';

        // const CREDIT_TRANSACTION_REFERENCE_NUM = '111';
        const DEBIT_TRANSACTION_RECEIPT_NUM = '712';
        const TRANSACTION_SEQUENCE_NUM = '112';

        const CARD_TYPE = '300';
        const CARD_NAME = '301';
        const MASKED_CARD_NUMBER = '302';
        const CUSTOMER_LANGUAGE = '303';
        const DEBIT_ACCOUNT_TYPE = '304';

        const ENTRY_METHOD = '305';
        const EMV_AID = '306';

        const EMV_TVR1 = '307';
        const EMV_TVR2 = '308';
        const EMV_TSI1 = '309';
        const EMV_TSI2 = '310';
        const EMV_APP_LABEL = '311';
        const EMV_APP_PREFERRED_NAME = '312';

        // 0 None
        // 1 Pin
        // 2 Signature
        // 3 Pin + Signature
        const CVM_METHOD = '315';
        const BALANCE_DUE = '317';

        const AUTHORIZATION_NUM = '400';
        const HOST_RESPONSE_ISO_CODE = '403';
        const CARD_BALANCE = '406';
        const PARTIAL_APPROVED_AMOUNT = '420';
        const BATCH_NUM = '500';
        const DEMO_MODE = '600';
        const TERMINAL_ID = '601';

        const MERCHANT_NAME = '602';
        const MONERIS_ADDRESS_LINE_1 = '603';
        const MONERIS_ADDRESS_LINE_2 = '604';

        // 0 do not print
        // 1 print cardholder signature line
        // 2 print merchant signature line
        const MONERIS_PRINT_SIGNATURE = '713';
        const FORM_FACTOR = '717';

        var customerAccountType = {
            '0': 'default',
            '1': 'savings',
            '2': 'chequing',
        };

        var transactionType = {
            '00': 'purchase',
            // '01': 'Preauth', //not supported
            // '02': 'Preauth Completion', //not supported
            '03': 'refund',
            '05': 'purchaseCorrection',
            '06': 'refundCorrection',
            '20': 'batchClose',
            '60': 'balanceInquiry',
            '91': 'recallMessage',
        };

        var toTransactionType = function (code) {
            return transactionType[code];
        };

        _self.init = function () {
            printResult = null;
            responses = [];
        };

        var isASCII = function (str) {
            return /^[\x00-\x7F]*$/.test(str);
        };

        var toTransactionDateTime = function (date, time) {
            if (date && time) {
                var year, month, day, hour, minute, second;

                [year, month, day] = date.match(/.{1,2}/g);
                [hour, minute, second] = time.match(/.{1,2}/g);

                return '20'+year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second;
            } else {
                // for failed transactions, it is possible the terminal does not
                // return any datetime to us. In this case we will make it ourselves.
                return moment().format('YYYY-MM-DD HH:mm:ss');
            }
        };

        var splitFields = function (fieldStr) {
            if (fieldStr[0] != FS) {
                throw new Error('Fields does not start with correct character');
            }

            // remove leading FS
            fieldStr = fieldStr.substring(1);

            var result = {};
            var fields = fieldStr.split(FS);
            for (var i of Object.keys(fields)) {
                var field = fields[i];
                var tag = field.substring(0, 3);
                var data = field.substring(3);
                result[tag] = data;
            }

            return result;
        };

        var parseReceived = function (str) {
            var tranStatus = str.substring(0, 2);
            var multiTran = str[2];
            var fields = str.substring(3);

            var result = fields ? splitFields(fields) : {};

            // tranStatus 91 means we recalled the transaction info from the terminal
            // in this case we want to set the tranStatus what we would normally receive
            // if we had not recalled from the terminal
            if (tranStatus == '91') {
                if (result['101']) {
                    tranStatus = result['101'];
                    multiTran = '0';
                } else {
                    throw new Error('Recall last transaction without transaction status!');
                }
            }

            return [tranStatus, multiTran, result];
        };

        _self.ingenicoTerminalResponse = function (transactionStatus, result) {
            /*
            We're using this parser to process both Moneris and GlobalPayment responses.
            There're minor differences:

            - Moneris signature line TAG(713)
                0 – Do not print signature line
                1 – Print Cardholder Signature line
                2 – Print Merchant Signature line
            -  Moneris returns single digit strings for CARD_TYPE
            */

            var isCredit = result[CARD_TYPE] == '1';
            var isDebit = result[CARD_TYPE] == '2';

            var showCustomerSignatureLine = result[MONERIS_PRINT_SIGNATURE] === '1';
            var showMerchantSignatureLine = result[MONERIS_PRINT_SIGNATURE] === '2';

            var verifiedByPin = result[CVM_METHOD] === '1' || result[CVM_METHOD] === '3';

            var isReversal = ['19', '20', '21', '22'].includes(result[TRANSACTION_STATUS]);

            var formFactor = result[FORM_FACTOR];
/*
100:"00" # transaction type
101:"00" # transaction status
102:"180814" # transaction date
103:"133044" # transaction time
104:"196" # transaction amount
109:"196" # total amount
112:"0010012550" # transaction sequence
300:"2" # customer tender type
301:"INTERAC" # customer card name
302:"************0454" #customer account number
303:"0" # customer language
304:"2" # customer account type
305:"C" # customer card entry mode !!!!!!!!!!
306:"A0000002771010" # EMV AID (MONERIS HAS THIS 306 AS ENTRY METHOD, and 308 and EMV AID!!)
307:"8000008000" # EMV 1
309:"6800" # EMV 2
311:"Interac" # EMV application level
312:"" # EMV preferred name
313:"743DF3E1C8C0F120" #EMV AROC
314:"FCDE5AB10F5F46F0" #EMV final cryptogram
315:"1" #CVM indicator
400:"453094  " # authorization #
401:"001"  # HOST RESPONSE CODE
403:"00"  # HOST RESPONSE ISO CODE
601:"84013690" # 601 TERMINAL ID
602:"LUCOVA"  # 602 MERCHANT NAME
603:"88 QUEENS QUAY W - 17TH F" # ADDRESS 1
604:"TORONTO      ON" # ADDRESS 2
712:"C84013690-001-001-255-0"  # 712 RECEIPT #
*/

            var printObj = {
                amount: result[TRANSACTION_AMOUNT],
                merchantName: result[MERCHANT_NAME],
                addressLine1: result[MONERIS_ADDRESS_LINE_1],
                addressLine2: result[MONERIS_ADDRESS_LINE_2],
                transactionType: toTransactionType(result[TRANSACTION_TYPE]),
                transactionTypeCode: result[TRANSACTION_TYPE],
                cardName: result[CARD_NAME],
                transactionAmount: result[TRANSACTION_AMOUNT] ? (result[TRANSACTION_AMOUNT]/100).toFixed(2) : '0.00',
                balanceDue: result[BALANCE_DUE] ? (result[BALANCE_DUE]/100).toFixed(2) : '0.00',
                tipAmount: result[TIP_AMOUNT] ? (result[TIP_AMOUNT]/100).toFixed(2) : '0.00',
                totalAmount: result[TOTAL_AMOUNT] ? (result[TOTAL_AMOUNT]/100).toFixed(2) : '0.00',
                approvedAmount: result[TOTAL_AMOUNT] ? (result[TOTAL_AMOUNT]/100).toFixed(2) : '0.00',
                cardNumber: result[MASKED_CARD_NUMBER],
                transactionDateTime: toTransactionDateTime(result[TRANSACTION_DATE], result[TRANSACTION_TIME]),
                cardEntryMethod: result[ENTRY_METHOD],
                authorizationNumber: result[AUTHORIZATION_NUM],
                emvAppLabel: result[EMV_APP_LABEL],
                emvAid: result[EMV_AID],
                emvTvr: result[EMV_TVR2] || result[EMV_TVR1],
                emvTsi: result[EMV_TSI2] || result[EMV_TSI1],

                transactionStatus: result[TRANSACTION_STATUS] || transactionStatus,
                success: ['00', '01'].includes(result[TRANSACTION_STATUS]),
                approvedWithMalfunc: result[TRANSACTION_STATUS] == '01',
                declinedByCardOnline: result[TRANSACTION_STATUS] == '11',
                cancelledByUser: result[TRANSACTION_STATUS] == '13',
                cardRemoved: result[TRANSACTION_STATUS] == '19',
                partialApprovalCancelled: result[TRANSACTION_STATUS] == '23',

                invoiceNumber: result[INVOICE_NUMBER],
                hostResponseCode: result[HOST_RESPONSE_CODE],
                hostResponseIsoCode: result[HOST_RESPONSE_ISO_CODE],
                terminalTimeout: false,
                isReversal: isReversal,
                formFactor: formFactor,
                cardBalance: result[CARD_BALANCE],
                customerLanguage: result[CUSTOMER_LANGUAGE] === '1' ? 'fr': 'en',

                terminalId: result[TERMINAL_ID],
                transactionId: result[TRANSACTION_SEQUENCE_NUM],
                maskedCardNumber: result[MASKED_CARD_NUMBER],
                batchNum: result[BATCH_NUM],
                signatureBase64Png: null,
                transactionSequenceNum: result[TRANSACTION_SEQUENCE_NUM],
                cvmResult: result[CVM_METHOD],
                demoMode: result[DEMO_MODE] === '1',
                isCredit: isCredit,
                isDebit: isDebit, // is debit
                showMerchantSignatureLine: showMerchantSignatureLine,
                showCustomerSignatureLine: showCustomerSignatureLine,
                verifiedByPin: verifiedByPin,
            };

            printObj.referenceNumber = result[DEBIT_TRANSACTION_RECEIPT_NUM];
            if (isDebit) {
                printObj.debitAccountType = customerAccountType[result[DEBIT_ACCOUNT_TYPE]];
                printObj.surchargeAmount = result[DEBIT_SURCHARGE_AMOUNT] ? (result[DEBIT_SURCHARGE_AMOUNT]/100).toFixed(2) : '0.00';
            }

            // if (isCredit) {
            // }

            if (result[PARTIAL_APPROVED_AMOUNT]) {
                printObj.partiallyApproved = (result[PARTIAL_APPROVED_AMOUNT]/100).toFixed(2);
            }

            if (isASCII(result[EMV_APP_PREFERRED_NAME])) {
                printObj.emvAppPreferredName = result[EMV_APP_PREFERRED_NAME];
            }

            return printObj;
        };

        function trimHeartbeats (text) {
            text = text.replace(new RegExp('^(['+HEARTBEAT+']*)', 'g'), '');
            text = text.replace(new RegExp('(['+HEARTBEAT+']*)$', 'g'), '');
            return text;
        }

        _self.onDataReceived = function (data, callback) {
            var raw = trimHeartbeats(data.toString());
            if (raw.length > 0) {
                var arr = parseReceived(raw);
                var multiTran = arr[1];

                if (multiTran === '0') {
                    responses.push(arr);
                    allDataReceived(responses, callback);
                    responses = [];
                }
            }
        };

        function allDataReceived (responses, callback) {

            var success = true;

            for (var i of Object.keys(responses)) {
                var arr = responses[i];

                var transactionStatus = arr[0];
                var result = arr[2];

                if (transactionStatus === '99') {
                    printResult = result;
                    callback(TerminalEvent.PRINT);

                    return;
                } else if (['00', '01'].includes(transactionStatus)) {
                    if (!printResult) {
                        // We receive '00' or '01' as a final message from the terminal
                        // after a successful transaction. If for some reason the terminal
                        // did *not* send us a printResult before this, then we need to use
                        // the current result as the printResult. The two have a similar
                        printResult = result;
                        $log.error('Transaction success without printResult');
                    }
                } else if (transactionStatus === '15') {
                    callback(TerminalEvent.BATCH_EMPTY);

                    return;
                } else if (['10', '11', '12', '13',
                    '14', '16', '17', '18', '19', '20', '21',
                    '22', '23'].includes(transactionStatus)) {
                    // callback(TerminalEvent.FAILED, result);

                    success = false;
                } else if (transactionStatus === '95') {
                    callback(TerminalEvent.BUSY);

                    return;
                } else {
                    // callback(TerminalEvent.FAILED, result);
                    success = false;
                }
            }

            // TODO only storing last result for partial transaction!!
            if (success) {
                callback(TerminalEvent.SUCCESS, printResult, transactionStatus);
            } else {
                callback(TerminalEvent.FAILED, printResult, transactionStatus);
            }
        }

        // different between GlobalPayments and Moneris
        _self.creditSaleCmd = function (amount) {
            return TRANSACTION_TYPE_PURCHASE
                    + DATA_TAG_TRANSACTION_AMOUNT + Math.round(Number(amount)*100)
                    + DATA_TAG_TENDER_TYPE + '0';
        };

        _self.lastTransactionStatus = function () {
            return LAST_TRANSACTION_STATUS;
        };

        // different between GlobalPayments and Moneris
        _self.creditVoidCmd = function (totalAmount, authNum) {
            return TRANSACTION_TYPE_VOID
                    + DATA_TAG_TRANSACTION_AMOUNT + Math.round(Number(totalAmount)*100)
                    + DATA_TAG_TENDER_TYPE + '0'
                    + DATA_TAG_AUTH_NO + authNum;
        };

        _self.creditReturnCmd = function (amount, authNum) {
            return TRANSACTION_TYPE_REFUND
                + '\x1c001' + Math.round(Number(amount)*100)
                    + DATA_TAG_TENDER_TYPE + '0'
                    + DATA_TAG_AUTH_NO + authNum;
        };

        _self.autoSettleCmd = function () {
            return TRANSACTION_TYPE_AUTO_SETTLE;
        };

        _self.parseResponse = function (transactionStatus, result) {
            return _self.ingenicoTerminalResponse(transactionStatus, result);
        };

        // called by creditVoid and creditReturn from Moneris electron/swift
        _self.buildResponse = function (transaction, cardTransactionId) {
            var origResponseObj = transaction.terminalResponses.find(function (e) {
              return e.cardTransactionId == cardTransactionId;
            });

            var response = JSON.parse(origResponseObj.cardTerminalResponse);

            return _self.parseResponse(null, response);
        };

        _self.getPrintResult = function () {
            return printResult;
        };

        var saleInProgress = false;
        _self.timeoutWrapper = function (f, timedOut) {
            return function (res, rej) {
                var timeout = null;

                if (saleInProgress) {
                    $log.error('Multiple sales initiated in parallel!');
                }
                saleInProgress = true;

                var createTimeout = function () {
                    if (timeout) {
                        cancelTimeout();
                    }
                    timeout = $timeout(function () {
                        timedOut(resolve, reject);
                    }, 45000);
                };

                var cancelTimeout = function () {
                    if (timeout) {
                        $timeout.cancel(timeout);
                        timeout = null;
                    }
                };

                var resolve = function (x) {
                    cancelTimeout();
                    saleInProgress = false;
                    res(x);
                };

                var reject = function (x) {
                    cancelTimeout();
                    saleInProgress = false;
                    rej(x);
                };

                var onData = function (x) {
                    createTimeout();
                };

                var onConnected = function () {
                    createTimeout();
                };

                try {
                    f(resolve, reject, onData, onConnected);
                } catch (e) {
                    $log.error(e);
                    reject(e);
                }
            };
        };

        return _self;
    }]);

    freshideas.factory('iOSCardTerminalMoneris', [
        '$log',
        'BridgedPromise',
        'IosTcpClient',
        'CashierShift',
        'MonerisCommon',
        'TerminalEvent',
        function ($log, BridgedPromise, IosTcpClient, CashierShift, MonerisCommon, TerminalEvent) {
        var _this = {};
        var config;

        _this.init = function (terminalConfig) {
            config = terminalConfig;

            MonerisCommon.init();
        };

        var respondTo = function (client, event, result, transactionStatus, successCallback, errorCallback) {
            if (event == TerminalEvent.PRINT) {
                setTimeout(function () {
                    client.write('990');
                }, 2000);
            } else if (event == TerminalEvent.SUCCESS) {
                client.disconnect();

                successCallback([
                    MonerisCommon.ingenicoTerminalResponse(transactionStatus, result),
                    result
                ]);
            } else if (event == TerminalEvent.FAILED) {
                client.disconnect();
                if (_.isEmpty(result)) {
                    errorCallback(
                        {
                            data: null,
                            message: 'FAILED transaction with no payload'
                        }
                    );
                } else {
                    errorCallback({
                        data: [
                            MonerisCommon.ingenicoTerminalResponse(transactionStatus, result),
                            result
                    ]});
                }
            } else if (event == TerminalEvent.BUSY) {
                client.disconnect();
                errorCallback({
                    data: null,
                    message: 'Terminal is busy'
                });
            } else {
                client.disconnect();
                if (_.isEmpty(result)) {
                    errorCallback({
                        data: null,
                        message: 'Unknown event: ' + event
                    });
                } else {
                    errorCallback({
                        data: [
                            MonerisCommon.ingenicoTerminalResponse(transactionStatus, result),
                            result
                    ]});
                }
            }
        };

        var lastTransactionStatus = function () {
            return new Promise(function (resolve, reject) {
              IosTcpClient.connect(config.port, config.ip).then(function (client) {

                var defaultMessage = MonerisCommon.lastTransactionStatus();

                // Listen to the stream of data from the terminal
                client.onData(function (data) {
                    try {
                        MonerisCommon.onDataReceived(data, function (event, result, transactionStatus) {
                            try {
                                respondTo(client, event, result, transactionStatus, function (response) {
                                    resolve(response);
                                }, function (error) {
                                    reject(error);
                                });
                            } catch (e) {
                                $log.error(e);
                                $log.error(result);
                                throw e;
                            }
                        });
                    } catch (e) {
                        $log.error(e);
                        $log.error({
                            request: defaultMessage,
                            response: data
                        });
                        reject();
                    }
                });

                client.write(defaultMessage);
            }).catch(function (error) {
                $log.error(error);
                reject({message: error.toString(), type: 'busy'});
            });
          });
        };

        _this.creditSale = function (amount) {
            var trace = [];
            var didResolveOrReject = false;

            var timedOut = function (resolve, reject) {
                if (!didResolveOrReject) {
                    // the connection closed before we know the state of the transaction!!
                    // let's get the lastTransaction status

                    lastTransactionStatus().then(resolve, reject);
                }
            };

            return new Promise(MonerisCommon.timeoutWrapper(function (resolve, reject, onData, onConnected) {
                IosTcpClient.connect(config.port, config.ip).then(function (client) {
                    onConnected();
                    var message = MonerisCommon.creditSaleCmd(amount);

                    // Listen to the stream of data from the terminal
                    client.onData(function (data) {
                        try {
                            onData();
                            trace.push(
                                {
                                    type: 'read',
                                    data: data ? data.toString() : 'NA',
                                    printResult: MonerisCommon.getPrintResult(),
                                }
                            );

                            MonerisCommon.onDataReceived(data, function (event, result, transactionStatus) {
                                respondTo(client, event, result, transactionStatus, function (response) {
                                    didResolveOrReject = true;
                                    resolve(response);
                                }, function (error) {
                                    $log.error(trace);
                                    throw error;
                                });
                            });
                        } catch (e) {
                            $log.error(e);
                            $log.error({
                                request: message,
                                response: data
                            });
                            didResolveOrReject = true;
                            reject({message: e.toString()});
                        }
                    });

                    trace.push(
                        {
                            type: 'write',
                            data: message,
                        }
                    );
                    client.write(message);
                }).catch(function (error) {
                    $log.error(error);
                    $log.error(trace);
                    didResolveOrReject = true;
                    reject({message: error && error.toString()});
                });
            }, timedOut));
        };

        _this.creditVoid = function (transactionId, params={}) {
            // Moneris requires an authCode from the original Transaction
            // In order to process refund requests.
            if (!params.transaction) {
                return Promise.reject('transaction provided by Moneris is required.');
            }

            var transaction = params.transaction;
            var response = MonerisCommon.buildResponse(transaction, transactionId);

            var message = MonerisCommon.creditVoidCmd(
                response.approvedAmount,
                response.authorizationNumber.trim());

            return _this.creditVoid2(transactionId, {message: message});
        };

        _this.creditVoid2 = function (transactionId, params={}) {
            return new Promise(function (resolve, reject) {
                IosTcpClient.connect(config.port, config.ip).then(function (client) {
                    // Listen to the stream of data from the terminal
                    client.onData(function (data) {
                        MonerisCommon.onDataReceived(data, function (event, result, transactionStatus) {
                            respondTo(client, event, result, transactionStatus, function (response) {
                                resolve(response);
                            }, function (error) {
                                reject(error);
                            });
                        });
                    });

                    client.write(params.message);
                }).catch(function (error) {
                    $log.error(error);
                    reject();
                });
            });
        };

        _this.creditReturn = function (amount, params={}) {
            // Moneris requires an authCode from the original Transaction
            // In order to process refund requests.
            if (!params.transaction) {
                return Promise.reject('transaction provided by Moneris is required.');
            }
            if (!params.transactionId) {
                return Promise.reject('transactionId provided by Moneris is required.');
            }

            var transaction = params.transaction;
            var response = MonerisCommon.buildResponse(transaction, params.transactionId);

            var message = MonerisCommon.creditReturnCmd(
                amount,
                response.authorizationNumber.trim());

            return _this.creditReturn2(amount, {message: message});
        };

        _this.creditReturn2 = function (amount, params={}) {
            return new Promise(function (resolve, reject) {
                IosTcpClient.connect(config.port, config.ip).then(function (client) {
                    // Listen to the stream of data from the terminal
                    client.onData(function (data) {
                        MonerisCommon.onDataReceived(data, function (event, result, transactionStatus) {
                            respondTo(client, event, result, transactionStatus, function (response) {
                                resolve(response);
                            }, function (error) {
                                $log.error(error);
                                reject(error);
                            });
                        });
                    });

                    client.write(params.message);
                }).catch(function (error) {
                    $log.error(error);
                    reject();
                });
            });
        };

        _this.autoSettle = function () {
            return new Promise(function (resolve, reject) {
                IosTcpClient.connect(config.port, config.ip).then(function (client) {
                    // Listen to the stream of data from the terminal
                    client.onData(function (data) {
                        MonerisCommon.onDataReceived(data, function (event, result, transactionStatus) {
                            if (event == TerminalEvent.SUCCESS
                                || event == TerminalEvent.BATCH_EMPTY) {
                                resolve(result);
                            } else {
                                $log.error([event, result]);
                                reject();
                            }

                            client.disconnect();
                        });
                    });

                    var cmd = MonerisCommon.autoSettleCmd();

                    client.write(cmd);
                }).catch(function (error) {
                    $log.error(error);
                    reject();
                });
            });
        };

        _this.debitSale = function (amount) {
            return _this.creditSale(amount);
        };

        _this.debitReturn = function (amount) {
            return _this.creditReturn(amount);
        };

        _this.parseResponse = function (response) {
            return MonerisCommon.parseResponse(null, response);
        };

        _this.testSale = function () {
            return _this.creditSale(0.01);
        };

        return _this;
    }]);


    freshideas.factory('CardTerminalMoneris', [
        '$log',
        'CashierShift',
        'MonerisCommon',
        'TerminalEvent',
        function ($log, CashierShift, MonerisCommon, TerminalEvent) {
        var _this = {};

        var config;
        var client;

        if (typeof nodeRequire !== 'undefined') {
            var net = nodeRequire('net');
        }

        _this.init = function (terminalConfig) {
            if (typeof window !== 'undefined') {
                // running from browser
                if (!window.process || !window.process.type) {
                    throw new Error('Running in non-electron environment. Terminal will be disabled');
                }
            }

            client = new net.Socket();

            MonerisCommon.init();

            config = terminalConfig;
        };

        var respondTo = function (client, event, result, transactionStatus, successCallback, errorCallback) {
            if (event == TerminalEvent.PRINT) {
                setTimeout(function () {
                    client.write('990');
                }, 1500);
            } else if (event == TerminalEvent.SUCCESS) {
                successCallback([
                    MonerisCommon.ingenicoTerminalResponse(transactionStatus, result),
                    result
                ]);
            } else if (event == TerminalEvent.FAILED) {
                if (_.isEmpty(result)) {
                    errorCallback(
                        {
                            data: null,
                            message: 'FAILED transaction with no payload'
                        }
                    );
                } else {
                    errorCallback({
                        data: [
                            MonerisCommon.ingenicoTerminalResponse(transactionStatus, result),
                            result
                    ]});
                }
            } else if (event == TerminalEvent.BUSY) {
                errorCallback({
                    data: null,
                    message: 'Terminal is busy'
                });
            } else {
                if (_.isEmpty(result)) {
                    errorCallback({
                        data: null,
                        message: 'Unknown event: ' + event
                    });
                } else {
                    errorCallback({data: [
                        MonerisCommon.ingenicoTerminalResponse(transactionStatus, result),
                        result
                    ]});
                }
            }
        };

        var lastTransactionStatus = function () {
            var retryClient;
            return new Promise(function (resolve, reject) {
                // retryClient.connect({timeout: 20000, port: config.port, host: config.ip}, function () {
                var connected = function () {
                    var defaultMessage = MonerisCommon.lastTransactionStatus();

                    retryClient.on('data', function (data) {
                        try {
                            var status = data.toString().substring(0, 2);
                            if (status === '95') {
                                setTimeout(connect, 5000);
                                retryClient.end();
                                return;
                            } else if (status === '91') {
                                // remove leading '91\xfc'
                                data = data.slice(3);
                            }

                            MonerisCommon.onDataReceived(data, function (event, result, transactionStatus) {
                                try {
                                    respondTo(retryClient, event, result, transactionStatus, function (response) {
                                        resolve(response);
                                    }, function (error) {
                                        reject(error);
                                    });
                                } catch (e) {
                                    $log.error(e);
                                    $log.error(result);
                                    throw e;
                                }
                            });
                        } catch (e) {
                            $log.error(e);
                            $log.error({
                                request: defaultMessage,
                                response: data
                            });
                            reject();
                        }
                    });

                    retryClient.write(defaultMessage);
                };

                retryClient.on('error', function (error) {
                    reject({message: error.toString(), type: 'busy'});
                });

                retryClient.on('close', function () {
                });

                var connect = function () {
                    retryClient = new net.Socket();
                    retryClient.connect({timeout: 5000, port: config.port, host: config.ip}, connected);

                    retryClient.on('error', function (error) {
                        if (error && error.errno == 'ECONNREFUSED') {
                            // terminal is still busy - try connecting
                            // again later
                            setTimeout(connect, 5000);
                        } else {
                            reject({message: error.toString()});
                        }
                     });
                };

                connect();
            });
        };

        _this.creditSale = function (amount) {
            var connectAttempts = 0;
            var didConnect = false;
            var didResolveOrReject = false;

            var timedOut = function (resolve, reject) {
                if (client) {
                    client.end();
                }
                client = null;

                if (!didResolveOrReject) {
                    // the connection closed before we know the state of the transaction!!
                    // let's get the lastTransaction status

                    setTimeout(function () {
                        lastTransactionStatus().then(resolve, reject);
                    }, 5000);
                }
            };

            return new Promise(MonerisCommon.timeoutWrapper(function (resolve, reject, onData, onConnected) {
                var connect = function () {
                    connectAttempts++;

                    client.connect({timeout: 20000, port: config.port, host: config.ip}, function () {
                        onConnected();
                        didConnect = true;
                        var message = MonerisCommon.creditSaleCmd(amount);

                        client.on('data', function (data) {
                            try {
                                onData();
                                MonerisCommon.onDataReceived(data, function (event, result, transactionStatus) {
                                    respondTo(client, event, result, transactionStatus, function (response) {
                                        didResolveOrReject = true;
                                        resolve(response);
                                    }, function (error) {
                                        $log.error(error);
                                        throw error;
                                    });
                                });
                            } catch (e) {
                                $log.error(e);
                                $log.error({
                                    request: message,
                                    response: data
                                });
                                didResolveOrReject = true;
                                reject({message: e.toString()});
                            }
                        });

                        client.write(message);
                    });
                };
                connect();

                client.on('error', function (error) {
                    if (!didConnect && connectAttempts < 3) {
                        setTimeout(connect, 1000);
                    } else {
                        if (didConnect) {
                            // only log errors after successful connect
                            $log.error(error);
                        }

                        didResolveOrReject = true;
                        reject({message: error.toString()});
                    }
                });

                client.on('close', function () {
                });
            }, timedOut));
        };

        _this.creditVoid = function (transactionId, params={}) {

            if (!params.transaction) {
                return Promise.reject('transaction provided by Moneris is required.');
            }
            var transaction = params.transaction;

            var response = MonerisCommon.buildResponse(transaction, transactionId);

            var message = MonerisCommon.creditVoidCmd(
                response.approvedAmount,
                response.authorizationNumber.trim());

            return _this.creditVoid2(transactionId, {message: message});
        };
        _this.creditVoid2 = function (transactionId, params={}) {
            return new Promise(function (resolve, reject) {
                client.connect({timeout: 20000, port: config.port, host: config.ip}, function () {
                    client.write(params.message);
                });

                client.on('error', function (error) {
                    reject();
                });

                client.on('data', function (data) {
                    MonerisCommon.onDataReceived(data, function (event, result, transactionStatus) {
                        respondTo(client, event, result, transactionStatus, function (response) {
                            resolve(response);
                        }, function (error) {
                            reject(error);
                        });
                    });
                });
            });
        };

        _this.creditReturn = function (amount, params={}) {
            // Moneris requires an authCode from the original Transaction
            // In order to process refund requests.
            if (!params.transaction) {
                return Promise.reject('transaction provided by Moneris is required.');
            }
            if (!params.transactionId) {
                return Promise.reject('transactionId provided by Moneris is required.');
            }

            var transaction = params.transaction;

            var response = MonerisCommon.buildResponse(transaction, params.transactionId);

            var message = MonerisCommon.creditReturnCmd(
                amount,
                response.authorizationNumber.trim());

            return _this.creditReturn2(amount, {message: message});
        };

        _this.creditReturn2 = function (amount, params={}) {
            return new Promise(function (resolve, reject) {
                client.connect({timeout: 20000, port: config.port, host: config.ip}, function () {
                    client.write(params.message);
                });

                client.on('error', function (error) {
                    reject();
                });

                client.on('data', function (data) {
                    MonerisCommon.onDataReceived(data, function (event, result, transactionStatus) {
                        respondTo(client, event, result, transactionStatus, function (response) {
                            resolve(response);
                        }, function (error) {
                            reject(error);
                        });
                    });
                });
            });
        };

        _this.testSale = function () {
            return _this.creditSale(0.01);
        };

        _this.autoSettle = function () {

            return new Promise(function (resolve, reject) {
                client.connect({timeout: 20000, port: config.port, host: config.ip}, function () {
                    client.write(MonerisCommon.autoSettleCmd());
                });

                client.on('error', function (error) {
                    reject();
                });

                client.on('data', function (data) {
                    MonerisCommon.onDataReceived(data, function (event, result, transactionStatus) {
                        if (event == TerminalEvent.SUCCESS
                            || event == TerminalEvent.BATCH_EMPTY) {
                            resolve(result);
                        } else {
                            reject();
                        }
                    });
                });
            });
        };

        _this.debitSale = function (amount) {
            return _this.creditSale(amount);
        };

        _this.debitReturn = function (amount) {
            return _this.creditReturn(amount);
        };

        _this.parseResponse = function (response) {
            return MonerisCommon.parseResponse(null, response);
        };

        return _this;
    }]);
}
