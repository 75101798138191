'use strict';


var PATRON_STATUS = {
    ALLOWED_ACCESS: {
        id: 'UNKNOWN',
        label: 'available.allow',
        shortLabel: 'available.allow',
        value: 0,
        icon: 'fa-check-circle',
    },
    FINANCIAL_AID: {
        id: 'FINANCIAL_AID',
        label: 'available.block.financialAid',
        shortLabel: 'available.block.financialAid.short',
        value: 1,
        icon: 'fa-times-circle',
    },
    FOOD_SERVICE: {
        id: 'FOOD_SERVICE',
        label: 'available.block.foodService',
        shortLabel: 'available.block.foodService.short',
        value: 2,
        icon: 'fa-times-circle',
    },
    getById: function (id) {
        for (var key in this) {
            if (this[key].id === id) {
                return this[key];
            }
        }
        return null;
    },
    getByValue: function (value) {
        for (var key in this) {
            if (this[key].value === value) {
                return this[key];
            }
        }
        return null;
    },
};

var PATRON_STATUSES = [
    PATRON_STATUS.ALLOWED_ACCESS,
    PATRON_STATUS.FINANCIAL_AID,
    PATRON_STATUS.FOOD_SERVICE,
];

'use strict';

import * as angular from 'angular';
import * as moment from 'moment';

const freshideasResourcesUsers = require('../common/resources/users.js').default;

const mod = angular.module('freshideas.patrons', [freshideasResourcesUsers.name]);

mod.controller(
    'PatronsCtrl',
    ['$scope',
    '$timeout',
    '$translate',
    'Company',
    'Meals',
    'Patrons',
    'Security',
    'ErrorUtil',
    'notificationTranslationHelper',
    '$location',
    '$modal',
    'SmbPosService',
    'USER_ROLE_TYPE',
    'CompanyAttributesService',
     function (
        $scope,
        $timeout,
        $translate,
        Company,
        Meals,
        Patrons,
        Security,
        ErrorUtil,
        notificationTranslationHelper,
        $location,
        $modal,
        SmbPosService,
        USER_ROLE_TYPE,
        CompanyAttributesService) {

         const currentUser = Security.getUser() || {};
         $scope.isUserManager = currentUser.roleType === USER_ROLE_TYPE.MANAGER;
         $scope.isUserSiteAdmin = currentUser.permission === 'SITEADMIN';
         $scope.isUserFullAdmin = currentUser.permission === 'FULLADMIN';
         $scope.selectedCompanyId = currentUser.companyId;

         $scope.showMeals = (currentUser.company.companyType !== 'SMB');

         $scope.patronStatuses = PATRON_STATUSES;

         $scope.rightSideBar = {
             show: null,
         };
         $scope.allPatrons = {
             checked: false,
             fromDb: false,
         };

         $scope.selected = {
             all: true,
             mealPlanId: undefined,
             status: '',
         };

         $scope.patrons = [];
         $scope.displayedPatrons = [];

         $scope.customerAddShow = false;
         $scope.toggleAddCustomer = () => {
             $scope.customerAddShow = !$scope.customerAddShow;
             $scope.customerDetailShow = false;
         };

         $scope.customerDetailShow = false;
         // This function is being passed to the child component
         $scope.toggleShowCustomerDetail = () => {
             $scope.customerDetailShow = !$scope.customerDetailShow;
             $scope.customerAddShow = false;
         };

         $scope.patronSearch = {
             patronSearchFilter: '',
             companyId: 0,
         };

         $scope.patronsByStatus = {
             companyId: 0,
             status: 'UNKNOWN',
         };

         $scope.selectSortOptions = [{
            label: 'Last visit',
            value: 'LAST_VISIT'
         },
         {
            label: 'Quantity of Points',
            value: 'QUANTITY_OF_POINTS'
         }];
         $scope.selectPatronStatusOptions = [{
            label: 'Active',
            value: 'ACTIVE'
         },
         {
            label: 'Inactive',
            value: 'INACTIVE'
         }];
         $scope.selectedSortFilter = $scope.selectSortOptions[0];
         $scope.lastSortFilterValue = $scope.selectSortOptions[0].value;

         $scope.selectedPatronStatusFilter = $scope.selectPatronStatusOptions[0];
         $scope.lastPatronStatusFilterValue = $scope.selectPatronStatusOptions[0].value;
         $scope.patronstatus = $scope.lastPatronStatusFilterValue;
         $scope.loadCompanies = function () {
            Company.liteCompanies({}, function (response) {
                $scope.companies = response.entries;

                var selectedCompanyId = $scope.selectedCompanyId || currentUser.companyId;
                var selectedCompany = _.findWhere(
                  $scope.companies, {
                    companyId: selectedCompanyId,
                  });

                if (selectedCompany) {
                    $scope.setSelectedCompany(selectedCompany);
                }

            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            });
         };
         $scope.loadCompanies();

         $scope.setSelectedCompany = function (company) {
            $scope.selectedCompany = company;
            $scope.selectedCompanyId = company.companyId;

            $scope.patronSearch.companyId = $scope.selectedCompanyId;

            $scope.resetList();
            $scope.loadMealPlans();
         };

         $scope.loadAllPatronsFromDb = function () {
            $scope.allPatrons.fromDb = true;
         };

         var onPatronsLoaded = function (patrons) {
             angular.forEach(patrons, function (patron) {
                 patron.fullName = patron.firstName + ' ' + patron.lastName;
                 parsePatronMealPlans(patron);
             });

             if ($scope.allPatrons.fromDb) {
                $scope.checkAllPatrons();
             }
         };

         var parsePatronMealPlans = function (patron) {
             _.each(patron.mealPlans, function (mealPlan) {
                mealPlan.sortOrder = (mealPlan.priority >= 0)? mealPlan.priority : Infinity;
             });

             patron.mealPlans = _.sortBy(patron.mealPlans, 'sortOrder');
         };

         $scope.showPatronDetailView = function (patron) {
            // NOTE: have to use `angular.copy` because changes to some properties (eg. patron.mealPlans)
            //  don't get reflected for some reason. Will investigate further in future optimizations
            $scope.patron = angular.copy(patron);
            $scope.rightSideBar.show = 'patron-detail';

            parsePatronMealPlans($scope.patron);
         };

         $scope.onSavePatron = function (patron, ignoreRefresh) {
             var patrons = [patron];
             updatePatronsInScope(patrons);

             if (!ignoreRefresh) {
                $scope.showPatronDetailView(patron);
             }
         };

         $scope.onAddPatron = function (patron) {
             $scope.loadLoyaltyDetails();
             $scope.loadPatrons();
             $scope.customerAddShow = false;
         };

         $scope.onPatronsStatusChange = function (changedPatrons) {
             for (var i = 0; i < $scope.patrons.length; i++) {
                 if (changedPatrons.ids.indexOf($scope.patrons[i].patronId) !== -1) {
                     var status = PATRON_STATUS.getById(changedPatrons.status);
                     $scope.patrons[i].status = status.value;
                 }
             }
         };

         var updatePatronsInScope = function (patrons, checked) {
             for (var i = 0; i < patrons.length; i++) {
                 var patron = patrons[i];
                 for (var y = 0; y < $scope.patrons.length; y++) {
                     if (patron.patronId == $scope.patrons[y].patronId) {
                         if (checked !== null) {
                             patron.checked = checked;
                         }
                         $scope.patrons[y] = patron;
                     }
                 }
             }
             onPatronsLoaded($scope.patrons);
         };

         $scope.selectPatron = function ($event, patron) {
             angular.forEach($scope.patrons, function (patron) {
                 patron.selected = false;
             });
             patron.selected = true;
             $scope.showPatronDetailView(patron);
             angular.forEach($scope.patrons, function (patron) {
                 patron.checked = false;
             });
             $scope.showPatronDetailView(patron);
             $scope.allPatrons.fromDb = false;
             $scope.patrons = $scope.displayedPatrons;
         };

         $scope.concealSearchInput = function () {
             $scope.patronSearch.patronSearchFilter = '';
         };

         $scope.loadMealPlans = function () {
             Meals.getMealPlans(
               {
                   'companyId': $scope.selectedCompanyId,
               },
               function (response) {
                   $scope.mealPlans = response.entries;
               });
         };

         $scope.pagination = {
            totalCount: 0,
            numPerPage: 15,
            page: 0,
         };
         $scope.resetPaginationPage = function () {
            $scope.pagination.page = 0;
         };

         $scope.findNextPatrons = function () {
            if ($scope.isLoadingPatrons || !$scope.patronSearch.patronSearchFilter) {
                return;
            }
            if ($scope.pagination.page >= $scope.pagination.totalCount / $scope.pagination.numPerPage - 1) {
                return;
            }

            $scope.pagination.page = $scope.pagination.page + 1;
            $scope.pagination.page = Math.min(Math.floor($scope.pagination.totalCount / $scope.pagination.numPerPage), $scope.pagination.page);

            $scope.lastListFunction();
         };

        let searchTimer;
        $scope.searchCustomers = () => {
            $scope.isLoadingPatrons = true;
            $timeout.cancel(searchTimer);
            searchTimer = $timeout(() => {
                $scope.lastListFunction();
            }, 500);
        };

         $scope.resetList = function (toSearch) {
            $scope.resetPaginationPage();
            $scope.patrons = [];
            $scope.displayedPatrons = [];

            if (!toSearch) {
                $scope.concealSearchInput();
            }
         };
         $scope.setSortFilterCriteria = function (filterValue) {
            $scope.lastSortFilterValue = angular.copy(filterValue);
            $scope.resetList(true);
            $scope.lastListFunction();
         };
         $scope.setPatronStatusFilter = function (filterValue) {
            $scope.lastPatronStatusFilterValue = angular.copy(filterValue);
            $scope.patronstatus = $scope.lastPatronStatusFilterValue;
            $scope.resetList(true);
            $scope.lastListFunction();
         };
         $scope.loadPatrons = function (loadAllFromDb) {
             $scope.isLoadingPatrons = true;
             var loadParam = {
                 companyId: $scope.selectedCompanyId || currentUser.companyId,
                 reactive: CompanyAttributesService.allowReactivePatronLookup()
             };
             if (loadAllFromDb) {
                loadParam.offset = 0;
                loadParam.limit = $scope.pagination.totalCount;
             } else {
                loadParam.offset = $scope.pagination.page * $scope.pagination.numPerPage;
                loadParam.limit = $scope.pagination.numPerPage;
             }

             if ($scope.patronSearch.patronSearchFilter) {
                loadParam.patronSearchFilter = $scope.patronSearch.patronSearchFilter;
             }
             if ($scope.lastSortFilterValue) {
                loadParam.sortFilter = $scope.lastSortFilterValue;
             }
             if ($scope.lastPatronStatusFilterValue) {
                loadParam.statusFilter = $scope.lastPatronStatusFilterValue;
             }
             $scope.lastListFunction = function (loadAllFromDb) {
                $scope.loadPatrons(loadAllFromDb);
             };
             if ($scope.allPatrons.fromDb && !loadAllFromDb) {
                $scope.displayedPatrons = [...$scope.displayedPatrons, ...$scope.patrons];
                $scope.isLoadingPatrons = false;
             } else if (loadParam.patronSearchFilter) {
                 return Patrons.listPatrons(loadParam, function (response) {
                    if ($scope.patronSearch.patronSearchFilter) {
                        $scope.resetList(true);
                     }
                     $scope.isLoadingPatrons = false;
                     $scope.pagination.totalCount = response.totalCount;
                     $scope.patrons = response.entries;
                     onPatronsLoaded($scope.patrons);

                     if (loadAllFromDb) {
                        $scope.displayedPatrons = [...$scope.displayedPatrons, ...$scope.patrons];
                     } else {
                        $scope.displayedPatrons = [...$scope.displayedPatrons, ...$scope.patrons];
                     }

                     return true;
                 }, function (error) {
                     $scope.isLoadingPatrons = false;
                     notificationTranslationHelper.notifyError(
                         ErrorUtil.parseError(error), null, true);
                 });
             } else {
                 $scope.isLoadingPatrons = false;
             }
         };

         $scope.loadLoyaltyDetails = function () {
             $scope.loadingLoyaltyDetails = true;
             Patrons.getLoyaltyDetails().$promise.then(function (response) {
                $scope.totalLoyalCustomers = response.totalLoyalCustomers;
                $scope.totalLoyalPoints = response.totalLoyalPoints;
                $scope.totalLoyalDollars = response.totalLoyalDollars;
                $scope.totalAppUsers = response.totalAppUsers;
                $scope.loadingLoyaltyDetails = false;
             }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                $scope.loadingLoyaltyDetails = false;
             });
         };
         $scope.$on('pos::patronLoyaltyCardUpdated', function () {
            $scope.loadLoyaltyDetails();
            $scope.resetList(true);
            $scope.loadPatrons();
         });
         $scope.$on('pos::patronUpdated', function () {
            $scope.resetList(true);
            $scope.loadPatrons();
         });
         $scope.lastListFunction = function () {
            $scope.loadPatrons();
         };
         $scope.init = () => {
            $scope.loadLoyaltyDetails();
            $scope.resetList(true);
            $scope.lastListFunction();
         };

         $scope.init();
     }]
);

mod.controller(
    'PatronDetailCtrl',
    [
    '$scope',
    '$modal',
    'Patrons',
    'Security',
    'ErrorUtil',
    'notificationTranslationHelper',
    'PosAlertService',
    'CashierShift',
    'CurrentSession',
    'SmbPosService',
    'USER_ROLE_TYPE',
     function (
        $scope,
        $modal,
        Patrons,
        Security,
        ErrorUtil,
        notificationTranslationHelper,
        PosAlertService,
        CashierShift,
        CurrentSession,
        SmbPosService,
        USER_ROLE_TYPE) {

         $scope.commentInput = '';
         $scope.hasMorePages = true;

         let customerHistoryPage = 0;

         const currentUser = Security.getUser() || {};
         $scope.isUserManager = currentUser.roleType === USER_ROLE_TYPE.MANAGER;
         $scope.isUserSiteAdmin = currentUser.permission === 'SITEADMIN';
         $scope.isUserFullAdmin = currentUser.permission === 'FULLADMIN';

         // transaction details modal
         let modalPromise;
         const unsetModal = $scope.unsetModal = () => {
            modalPromise = undefined;
         };
         const setModal = $scope.setModal = (config) => {
            if (modalPromise && !modalPromise.isPending) {
                return Promise.reject();
            }

            const modalInstance = $modal.open(config, (error) => {
                unsetModal();
            });
            modalPromise = modalInstance.result;

            return modalPromise;
         };

         $scope.resetToDateCounter = () => {
            $scope.transactionHistoryItems = [];
            $scope.hasMorePages = true;
            customerHistoryPage = 0;
         };

         $scope.refreshCustomerDetailsList = () => {
            $scope.hasMorePages = true;
            customerHistoryPage = 0;
            $scope.transactionHistoryItems = [];
            $scope.showPatronTransactionHistory($scope.patron);
            $scope.getPatronPinnedNotes($scope.patron);
         };

         $scope.getPatronPinnedNotes = (patron) => {
            Patrons.getPatronPinnedNotes({'patronId': patron.patronId},
            (res) => $scope.patronPinnedNotes = res),
            (err) => PosAlertService.showAlertByName('general');
         };

         $scope.pinOrUnPinNote = (item, id) => {
            const adjustment = {
                pinned: !item.pinned,
                id: id,
                createdAt: moment(item.transactionDateTime).unix()
            };
            Patrons.patronAddNote({}, adjustment, function (response) {
                $scope.refreshCustomerDetailsList();
            }, function (error) {
                PosAlertService.showAlertByName('general');
            });
         };

         $scope.savePatronNote = () => {
            let params = {};
            params.requestedPermission = 'pos:edit_meal_plan_balances';
            params.verifyAllUserPinsForCompany = true;

            params.callback = function (pinResponse) {
                const adjustment = {
                    employeeId: pinResponse.user.userId,
                    patronId: $scope.patron.patronId,
                    note: $scope.commentInput
                };
                Patrons.patronAddNote({}, adjustment, function (response) {
                    $scope.refreshCustomerDetailsList();
                    $scope.commentInput = '';
                }, function (error) {
                    $scope.commentInput = '';
                    PosAlertService.showAlertByName('general');
                    notificationTranslationHelper.notifyError(
                        ErrorUtil.parseError(error), null, true);
                });
            };
            params.errorCallback = function (error) {
                PosAlertService.showAlertByName('pincode-fail');
            };
            $scope.$emit('PincodeAuthentication:Required', params);

            return params;
         };

         $scope.showTransaction = (transaction) => {

            // in the case transaction id doesn't exist
            // ideally this shouldn't happen, but it's better to add this alert
            // so UI doesn't look broken.
            if (!transaction.transactionId) {
                PosAlertService.showAlertByName('general', {
                    title: 'general.error.cannot.find.transaction.ttl',
                    message: 'general.error.cannot.find.transaction.msg'
                });
            } else {
                return setModal({
                    templateUrl: 'reports/reports.singleTransaction.tpl.html',
                    controller: 'SingleTransactionCtrl',
                    windowClass: 'single-transaction-modal modal-80 products2 modal--pull-right',
                    animation: true,
                    backdrop: true,
                    resolve: {
                        selectedTransaction: () => {
                            return transaction;
                        },
                        searchStartDateTime: () => {
                            return moment(transaction.transactionDateTime).subtract(1, 'days').valueOf();
                        },
                        searchEndDateTime: () => {
                            return moment(transaction.transactionDateTime).add(1, 'days').valueOf();
                        },
                        currencyCode: () => {
                            return 'CAD';
                        }
                    }
                }).then((itemSaved) => {
                    unsetModal();
                    return itemSaved;
                }).catch((error) => {
                    unsetModal();
                });
            }
        };

         var parsePatronMealPlans = function (patron) {
             _.each(patron.mealPlans, function (mealPlan) {
                mealPlan.sortOrder = (mealPlan.priority >= 0 && !mealPlan.expired)? mealPlan.priority : Infinity;
             });

             patron.mealPlans = _.sortBy(patron.mealPlans, 'sortOrder');

             $scope.patron.archivedMealPlans = $scope.patron.archivedMealPlans || [];
             $scope.allMealPlans = angular.copy(patron.mealPlans.concat($scope.patron.archivedMealPlans));
         };

         $scope.statuses = PATRON_STATUSES;

         $scope.showPatronMealPlans = function (patron) {
             parsePatronMealPlans($scope.patron);
         };

         $scope.endSearchDate = moment().endOf('day').toDate();
         $scope.transactionHistoryItems = [];

         $scope.showPatronTransactionHistory = function (patron) {
             $scope.customerTransactionsFound = true;
             $scope.selectedPatronId = currentUser.companyId;
             $scope.isLoadingTransactionHistoryItems = true;

             const params = {
                customerId: patron.patronId,
                page: customerHistoryPage
              };
             Patrons.getPatronHistoryV2(params).$promise
                .then((response) => {
                    customerHistoryPage++;
                    $scope.transactionHistoryItems = [...$scope.transactionHistoryItems, ...response];

                    if (!response.length) {
                        $scope.hasMorePages = false;
                        $scope.customerTransactionsFound = false;
                    } else {
                        $scope.hasMorePages = true;
                        $scope.customerTransactionsFound = true;
                    }

                    $scope.isLoadingTransactionHistoryItems = false;
                }).catch((error) => {
                    $scope.isLoadingTransactionHistoryItems = false;
                });
         };

         var hasEditBalancesPermission = function () {
             Security.loadRolePermissions().then(function () {
                 if (Security.hasPermissionForUser('patrons:edit_meal_plan_balances')) {
                     return true;
                 }
             });
             return false;
         };
         $scope.hasEditBalancesPermission = hasEditBalancesPermission();

        const sumGiftCardBalance = (giftCards) => {
            let sum = 0;
            for (let card of giftCards) {
                if (card.currentBalanceCents) {
                    sum += (+card.currentBalanceCents/100);
                }
            }
            return sum;
        };

        const fetchGiftCards = (lucovaGiftCards) => {

            if (!lucovaGiftCards || !lucovaGiftCards.length) {
                return;
            }
            const giftCardPromises = [];
            lucovaGiftCards.forEach((lucovaGiftCard) => {
                giftCardPromises.push(
                    CashierShift.lookupGiftCard({
                        giftCardId: lucovaGiftCard.id,
                        code: lucovaGiftCard.code,
                        currencyId: CurrentSession.getCompany().baseCurrencyId,
                        cashierShiftId: SmbPosService.shift.cashierShiftId
                    }).$promise
                );
            });

            return Promise.all(giftCardPromises).then(function (giftCards) {

                // displaying number of available gift cards and
                // total balance of these cards on frontend.
                $scope.availableGiftCards = giftCards;
                $scope.numberOfGiftCards = giftCards.length;
                const sumGiftCards = sumGiftCardBalance(giftCards);
                $scope.totalAvailableGiftCardsBalance = sumGiftCards > 0 ? sumGiftCards : '0.00';

                return {
                    success: true
                };
            }, function (error) {
                $scope.error = {
                    giftCard: true
                };

                if (error && error.data && error.data.exception && error.data.exception.appCode == 444) {
                    $scope.error.foreignGiftCard = true;
                }

                return {
                    success: false,
                    error: error
                };
            });
        };

        const giftCardFromLucovaPatron = () => {
            const patron = $scope.patron;
            if (!patron || !patron.lucovaUser) {
                return;
            }
            let lucovaUser = patron.lucovaUser;
            let lucovaGiftCards = (lucovaUser.nown && lucovaUser.nown.nown_gift_cards) ? lucovaUser.nown.nown_gift_cards : [];
             // ensuring backwards compatibility as older versions of the mobile apps may send nown_gift_nums instead of
                // nown_gift_cards
                let lucovaGiftNums = (lucovaUser.nown && lucovaUser.nown.nown_gift_nums) ? lucovaUser.nown.nown_gift_nums : [];
                _.each(lucovaGiftNums, (num) => {
                    let giftCard = {
                        code: num
                    };
                    lucovaGiftCards.push(giftCard);
                });

            fetchGiftCards(lucovaGiftCards);
        };


         $scope.init = () => {
            giftCardFromLucovaPatron();
         };

         $scope.init();
     }]
);

mod.controller(
    'PatronAddCtrl',
    ['$scope', 'Security', 'notificationTranslationHelper', 'WizardHandler', 'PosAlertService', 'CashierShift',
     function ($scope, Security, notificationTranslationHelper, WizardHandler, PosAlertService, CashierShift) {

         var currentUser = Security.getUser() || {};

         $scope.statuses = PATRON_STATUSES;

         var makePatron = function () {
             return {
                 companyId: currentUser.companyId,
                 password: ''
             };
         };

         $scope.patron = makePatron();

         $scope.min = function (val1, val2) {
             return Math.min(val1, val2);
         };

         $scope.closeModal = () => {
            $scope.toggleAddCustomer();
            $scope.patron = {};
         };

        $scope.datePickerSettings = {isOpen: false};
        $scope.toggleDatePicker = ($event) => {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.datePickerSettings.isOpen = !$scope.datePickerSettings.isOpen;
        };
        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

         $scope.saveBasicInfo = function (patron) {
            if (patron.birthDate) {
                // we are storing birthDate as a string
                patron.birthDate = moment(patron.birthDate).format('YYYY-MM-DD');
            }

            CashierShift.signupNewPatron({}, patron).$promise.then((response) => {
                notificationTranslationHelper.notifyMessage('patrons.add.added');
                WizardHandler.wizard().reset();
                $scope.onAddPatron(patron);
                $scope.patron = {};
            }).catch((error) => {
                if (error.status === 400) {
                    var errorData = error.data || {};
                    var errorMessage = errorData.error || '';

                    PosAlertService.showAlertByName('patron-add-fail', {
                        message: errorMessage,
                    });
                } else {
                    PosAlertService.showAlertByName('patron-add-fail');
                }
            });
        };

         var canExit = function (form) {
            return form.$valid;
         };
         $scope.canExitFn = function (form) {
            return canExit;
         };
     }]
);

// TODO check if this part is curently being used in the app or it is a dead section
mod.controller(
    'MealPlanEditCtrl',
    ['$scope', '$element', '$attrs', 'Patrons', 'Meals', 'Security', 'ConfirmModalService',
     'ErrorUtil', 'notificationTranslationHelper',
     function ($scope, $element, $attrs, Patrons, Meals, Security, ConfirmModalService,
        ErrorUtil, notificationTranslationHelper) {
          var currentUser = Security.getUser() || {};

         $scope.onMealPlanUpdate = function (mealPlan) {
             var balance = mealPlan.remainingServicePeriodBalance;
             if (mealPlan.mealPlanType == 'MEAL') {
                 mealPlan.remainingServicePeriodMeals = balance;
             } else if (mealPlan.mealPlanType == 'DCB') {
                 mealPlan.remainingServicePeriodDcb = balance;
             } else if (mealPlan.mealPlanType == 'ICB') {
                 mealPlan.remainingServicePeriodCharge = balance;
             }

             for (var i = 0; i < $scope.patron.mealPlans.length; i++) {
                 if ($scope.patron.mealPlans[i].mealPlanId == mealPlan.mealPlanId) {
                     $scope.patron.mealPlans[i] = mealPlan;
                 }
             }
         };

         $scope.toggleMealPlanActive = function (mealPlan) {
            if (mealPlan.priority >= 0) {
                mealPlan.priority = -1;
            } else {
                if (originalPriority >= 0) {
                    mealPlan.priority = originalPriority;
                } else {
                    var maxPatronMealPlanPriority = Math.max(-1, _.max($scope.patron.mealPlans, function (mealPlan) {
                        return isNaN(mealPlan.priority)? -1 : mealPlan.priority;
                    }).priority );
                    mealPlan.priority = maxPatronMealPlanPriority + 1;
                }
            }
         };

         $scope.saveMealPlan = function (mealPlan) {

             var params = {
                 patronId: mealPlan.patronId,
                 mealPlanId: mealPlan.mealPlanId,
                 remainingServicePeriod: mealPlan.remainingServicePeriodBalance,
                 type: mealPlan.mealPlanType,
                 priority: mealPlan.priority,
             };

             Meals.updatePatronMealPlan(params, {}, function (success) {
                 notificationTranslationHelper.notifyMessage('patrons.mealPlanEdit.saved');
                 $scope.onMealPlanUpdate(mealPlan);
                 $scope.onSaveMealPlan()($scope.patron);
             }, function (error) {
                 if (error.status === 400) {
                     notificationTranslationHelper.notifyError(
                         'patron.mealPlanEdit.invalid', null, false);
                 } else {
                     notificationTranslationHelper.notifyError(
                         ErrorUtil.parseError(error), null, true);
                 }
             });
         };

        $scope.archiveMealPlan = function (mealPlan) {
            // only existing meal plan can be archived
            if (!mealPlan.isNew) {
                var modalTitle = 'Confirm Archiving';
                var modalConfirmMessage = 'Are you sure you want to archive ' + mealPlan.name + '?';
                var clickAction = function () {
                    $scope.confirmArchiveMealPlan(mealPlan);
                };

                ConfirmModalService.openConfirmModal(
                    modalTitle,
                    modalConfirmMessage,
                    clickAction
                );
            }
        };
        $scope.confirmArchiveMealPlan = function (mealPlan) {
            var params = {
                companyId: currentUser.companyId,
                patronId: mealPlan.patronId,
                mealPlanId: mealPlan.mealPlanId,
            };
            mealPlan.processing = true;
            Patrons.archivePatronMealPlan(params, {}, function () {

                var patronMealPlanListPromise = Patrons.getPatronMealPlanList(params).$promise;
                var archivedPatornMealPlanListPromise = Patrons.getArchivedPatronMealPlanList(params).$promise;

                Promise.all([patronMealPlanListPromise, archivedPatornMealPlanListPromise]).then(function (response) {
                    mealPlan.processing = false;
                    mealPlan.expired = true;

                    $scope.patron.mealPlans = response[0];
                    $scope.patron.archivedMealPlans = response[1];
                    $scope.patron.hasExpiredMealPlans = !!$scope.patron.archivedMealPlans.length;

                    $scope.onSaveMealPlan()($scope.patron, true);
                }).catch(function () {
                    mealPlan.processing = false;
                });
            });
        };

         var originalPriority = -1;
         $scope.$watch('mealPlan', function (mealPlan) {
            if (mealPlan) {
                originalPriority = mealPlan.priority;
            }
         });
     }]);

// TODO check if this part is curently being used in the app or it is a dead section
mod.controller(
    'MealPlanAddCtrl',
    ['$scope', '$element', '$attrs', 'Patrons', 'Meals',
     'ErrorUtil', 'notificationTranslationHelper', '$timeout',
     function ($scope, $element, $attrs, Patrons, Meals,
        ErrorUtil, notificationTranslationHelper, $timeout) {

            $scope.$watch('patron', function (patron) {
                if (patron) {
                    Patrons.getUninitializedCompanyMeal({
                        companyId: $scope.patron.companyId,
                        patronId: $scope.patron.patronId,
                        includeAll: true,
                    }, function (companyMealPlans) {
                        $scope.companyMealPlans = companyMealPlans;
                    });
                }
            });

            $scope.toggleCompanyMealSelection = function (companyMealPlan) {
                companyMealPlan.selected = !companyMealPlan.selected;
            };
            $scope.selectedCompanyMealPlanCount = function () {
                var selectedCompanyMealPlans = _.where(
                    $scope.companyMealPlans,
                    {
                        selected: true,
                    }
                );
                return selectedCompanyMealPlans.length;
            };
            $scope.addSelectedCompanyMealPlans = function () {
                $scope.patron.mealPlans = $scope.patron.mealPlans || [];

                var selectedCompanyMealPlans = _.where(
                    $scope.companyMealPlans,
                    {
                        selected: true,
                    }
                );
                _.each(selectedCompanyMealPlans, function (companyMealPlan) {
                    delete companyMealPlan.selected;
                    companyMealPlan.isNew = true;

                    var maxPatronMealPlanPriority = Math.max(-1, _.max($scope.patron.mealPlans, function (mealPlan) {
                        return isNaN(mealPlan.priority)? -1 : mealPlan.priority;
                    }).priority );
                    companyMealPlan.priority = maxPatronMealPlanPriority + 1;
                    companyMealPlan.patronId = $scope.patron.patronId;

                    $scope.patron.mealPlans.push(angular.copy(companyMealPlan));
                });

                Patrons.update({}, $scope.patron, function (success) {
                    $scope.onAddMealPlans($scope.patron);
                });
            };

            $scope.getUnitializedMealPlans = function (companyMealPlan, companyMealPlanIndex, companyMealPlans) {
                if ($scope.patron && $scope.patron.mealPlans && $scope.patron.mealPlans.length > 0) {
                    var initializedCompanyMeal = _.findWhere(
                        $scope.patron.mealPlans,
                        {
                            mealPlanId: companyMealPlan.mealPlanId,
                        }
                    );
                    return !initializedCompanyMeal;
                } else {
                    return true;
                }
            };
     }]);

mod.controller(
    'PatronEditCtrl',
    ['$scope', 'Security', 'notificationTranslationHelper', 'WizardHandler', 'PosAlertService', 'ConfirmModalService', 'Patrons', 'Lucova',
     function ($scope, Security, notificationTranslationHelper, WizardHandler, PosAlertService, ConfirmModalService, Patrons, Lucova) {

        var user = Security.getUser() || {};

        $scope.datePickerSettings = {isOpen: false};
        $scope.toggleBirthdatePicker = ($event) => {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.datePickerSettings.isOpen = !$scope.datePickerSettings.isOpen;
        };
        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        $scope.canArchive = function () {
            return $scope.$parent.patronstatus == 'INACTIVE';
        };
        $scope.archivePatron = function (patron) {
            var modalTitle = 'Archive Customer';
            var modalConfirmMessage = 'Are you sure you want to archive ' + patron.fullName + '?';

            ConfirmModalService.openConfirmModal(
                modalTitle,
                modalConfirmMessage,
                null,
                null,
                $scope,
                true
            ).then(() => {
                $scope.confirmArchivePatron(patron);
            }).catch(() => {
                return; // cancel button is clicked on confirm modal
            });
        };

        $scope.confirmArchivePatron = function (patron) {
            var params = {
                companyId: user.companyId,
                patronId: patron.patronId,
            };
            Patrons.archivePatron(params).$promise.then((response) => {
                WizardHandler.wizard().reset();
                $scope.$parent.patronEditView = false;
                $scope.$parent.toggleShowCustomerDetail();
                $scope.$emit('pos::patronUpdated');
            }).catch((error) => {
                if (error.status === 400) {
                    var errorData = error.data || {};
                    var errorMessage = errorData.error || '';
                    PosAlertService.showAlertByName('archive-patron-fail', {
                        message: errorMessage,
                    });
                } else {
                    PosAlertService.showAlertByName('archive-patron-fail');
                }
            });
        };

        $scope.updatePatron = function (firstname, lastname, birthDate, patron) {
            let lucovaProfile = {};

            if (birthDate) {
                // we are storing birthDate as a string
                patron.birthDate = moment(birthDate).format('YYYY-MM-DD');

                const [year, month, day] = patron.birthDate.split('-');
                lucovaProfile.birthday = {
                    year: parseInt(year),
                    month: parseInt(month),
                    day: parseInt(day)
                };
            }

            if (firstname) {
                patron.firstName = firstname;
            }
            if (lastname) {
                patron.lastName = lastname;
            } else {
                patron.lastName = '';
            }

            patron.fullName = patron.firstName + ' ' + patron.lastName;

            Patrons.updatePatron({}, patron).$promise
                .then((response) => {
                    // Update lucova if we have at least one field to update
                    if (Object.keys(lucovaProfile).length > 0) {
                        lucovaProfile['user_name'] = patron.patronKey;
                        Lucova.manager().updateCustomerProfile(lucovaProfile).$promise.catch(console.error);
                    }

                    notificationTranslationHelper.notifyMessage('patrons.update.details');
                    WizardHandler.wizard().reset();
                    $scope.$parent.patronEditView = false;
                    $scope.$emit('pos::patronUpdated');
                }).catch((error) => {
                    if (error.status === 400) {
                        var errorData = error.data || {};
                        var errorMessage = errorData.error || '';
                        PosAlertService.showAlertByName('patron-details-update-fail', {
                            message: errorMessage,
                        });
                    } else {
                        PosAlertService.showAlertByName('patron-details-update-fail');
                    }
                });
        };
    }]
);

mod.directive('patronDetailView', function () {

    return {
        controller: 'PatronDetailCtrl',
        templateUrl: 'patrons/patron-detail.tpl.html',
        restrict: 'E',
        scope: {
            patron: '=',
            companyMealPlans: '=',
            onSavePatron: '=',
            toggleShowCustomerDetail: '=',
            customerDetailShow: '=',
            patronstatus: '='
        },
        link: function (scope) {

            scope.$watch('patron', function (patron) {
                if (patron) {
                    scope.showPatronTransactionHistory(patron);
                    scope.getPatronPinnedNotes(patron);
                }
            });

            scope.mealPlanEditView = false;
            scope.patronEditView = false;

            scope.closeMealPlanEditView = () => {
                scope.mealPlanEditView = false;
            };

            scope.toggleMealPlanEditView = (mealPlan) => {
                scope.mealPlan = mealPlan;
                scope.mealPlanEditView = !scope.mealPlanEditView;
            };

            scope.closePatronEditView = () => {
                scope.patronEditView = false;
            };

            scope.togglePatronEditView = (patron) => {
                scope.patron = patron;
                scope.patronEditView = !scope.patronEditView;
                scope.birthdate = patron.birthDate;
                scope.firstname = patron.firstName;
                scope.lastname = patron.lastName;
            };

            scope.showPatronDetail = function (mealPlan) {
                if (mealPlan) {
                    var updatedMealPlanIndex = scope.patron.mealPlans.findIndex(function (mp) {
                        return mp.mealPlanId === mealPlan.mealPlanId;
                    });
                    scope.patron.mealPlans[updatedMealPlanIndex] = mealPlan;
                }
                scope.showPatronTransactionHistory(scope.patron);
            };
        },
    };
});

mod.directive('patronAddView', function () {

    return {
        controller: 'PatronAddCtrl',
        templateUrl: 'patrons/patron-add.tpl.html',
        restrict: 'E',
        scope: {
            onSavePatron: '=',
            onAddPatron: '=',
            rightSideBar: '=',
            customerAddShow: '=',
            toggleAddCustomer: '='
        },
        link: function (scope) {
        },
    };
});

// TODO check if this part is curently being used in the app or it is a dead section
mod.directive('mealPlanEditView', function () {

    return {
        controller: 'MealPlanEditCtrl',
        templateUrl: 'patrons/patron-meal-plan-edit.tpl.html',
        restrict: 'E',
        scope: {
            patron: '=',
            mealPlan: '=',
            showPatronDetailView: '&',
            onSaveMealPlan: '&',
        },
        link: function (scope, element, attrs) {

            scope.sideBar = {};

            scope.$watch('mealPlan', function (mealPlan) {
                if (mealPlan !== undefined) {
                    mealPlan.currentBalance = 0;
                }
                if (mealPlan !== undefined && mealPlan.mealPlanType !== undefined) {
                    var balance = 0;
                    var rspBalance = 0;
                    if (mealPlan.mealPlanType == 'MEAL') {
                        balance = mealPlan.currentMealPlanBalance;
                        rspBalance = mealPlan.remainingServicePeriodMeals;
                    } else if (mealPlan.mealPlanType == 'DCB') {
                        balance = mealPlan.currentDcbBalance;
                        rspBalance = mealPlan.remainingServicePeriodDcb;
                    } else if (mealPlan.mealPlanType == 'ICB') {
                        balance = mealPlan.currentChargeBalance;
                        rspBalance = mealPlan.remainingServicePeriodCharge;
                    }
                    mealPlan.currentBalance = balance;
                    mealPlan.remainingServicePeriodBalance = rspBalance;
                }
                scope.mealPlan = mealPlan;
            });

            scope.$watch('patron', function (patron) {
                scope.patron = patron;
            });

            scope.showMealPlanEditView = function (mealPlan) {
                scope.sideBar.show = 'edit-view';
            };

            scope.showAdjustmentView = function (mealPlan) {
                scope.sideBar.show = 'balance-adjustment';
            };

            scope.showDepositView = function (mealPlan) {
                scope.sideBar.show = 'balance-deposit';
            };

            scope.showRefundView = function (mealPlan) {
                scope.sideBar.show = 'balance-refund';
            };

            scope.showMealPlanEditView();
        },
    };
});

mod.directive('patronEditView', function () {

    return {
        controller: 'PatronEditCtrl',
        templateUrl: 'patrons/patron-edit-details.tpl.html',
        restrict: 'E',
        scope: {
            patron: '=',
            firstname: '=',
            lastname: '=',
            birthdate: '=',
            patronEditView: '=',
            closePatronEditView: '=',
        },
        link: function (scope, element, attrs) {
            scope.$watch('patron', function (patron) {
                if (patron) {
                    scope.patron = patron;
                }
            });
        },
    };
});

// TODO check if this part is curently being used in the app or it is a dead section
mod.directive('mealPlanAddView', function () {

    return {
        controller: 'MealPlanAddCtrl',
        templateUrl: 'patrons/patron-meal-plan-add.tpl.html',
        restrict: 'E',
        scope: {
            patron: '=',
            showPatronDetailView: '&',
            onAddMealPlans: '=',
        },
        link: function (scope, element, attrs) {

            scope.sideBar = {};

            scope.showMealPlanAddView = function () {
                scope.sideBar.show = 'add-view';
            };

            scope.showMealPlanEditView = function (mealPlan) {
                scope.sideBar.show = 'edit-view';
            };

            scope.showMealPlanEditView();
        },
    };
});

mod.directive('whenInfiniteScrolled', () => {
    return {
      restrict: 'A',
      link: (scope, elem, attrs) => {

        // we get a list of elements of size 1 and need the first element
        const list = elem[0];

        // we load more elements when scrolled past a limit
        elem.bind('scroll', () => {
          if (list.scrollTop+list.offsetHeight+5 >= list.scrollHeight) {
            scope.loading = true;

          // we can give any function which loads more elements into the list
            scope.$apply(attrs.whenInfiniteScrolled);
          }
        });
      }
    };
});

mod.directive('ngEnter', function () {

    return {
        link: function (scope, element, attrs) {
            element.bind('keydown keypress', function (event) {
                if (event.which === 13) {
                    scope.$apply(function () {
                        scope.$eval(attrs.ngEnter);
                    });
                    event.preventDefault();
                }
            });
        },
    };
});

mod.directive('focusMe', function () {

    return {
        link: function (scope, element, attrs) {
            var isFocused = attrs.focusMe;
            scope.$watch(isFocused, function (value) {
                if (value === true) {
                    element.focus();
                }
            });
        },
    };
});

mod.filter('formatBalance', function () {
    return function (balance, type, format) {
        var result = '';
        if (format === undefined) {
            format = [
                '%d %m', '$%d',
            ];
        }
        if (balance !== undefined && type !== undefined) {
            if (type == 'MEAL') {
                result = format[0].replace('%d', balance);
                if (balance === 1) {
                    result = result.replace('%m', 'point');
                } else {
                    result = result.replace('%m', 'points');
                }
            } else if (type == 'DCB') {
                result = format[1].replace('%d', balance);
            } else if (type == 'ICB') {
                result = format[1].replace('%d', balance);
            }
        }
        return result;
    };
});

mod.component('patronDetailsEditView', {
    selector: 'patronDetailsEditView',
    bindings: {
        patron: '=',
        firstname: '=',
        lastname: '=',
        birthdate: '=',
        patronEditView: '=',
        closePatronEditView: '='},
    templateUrl: 'patrons/patron-edit-details.tpl.html',
    controller: 'PatronEditCtrl'
});

export default mod;
