'use strict';

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosStartCtrl', [
        '$scope',
        '$modal',
        '$log',
        '$timeout',
        '$rootScope',
        'Security',
        'Users',
        'SecondaryDisplay',
        'SmbPosService',
        'CurrentSession',
        'CommonOfflineCache',
        'PosStatusService',
        'GatewayAlphaPay',
        'KioskService',
        'SharedDataService',
        'CompanyAttributesService',
        'GatewayFiit',
        'PosUsersService',
        function ($scope, $modal, $log, $timeout, $rootScope, Security, Users, SecondaryDisplay, SmbPosService, CurrentSession, CommonOfflineCache, PosStatusService, GatewayAlphaPay, KioskService, SharedDataService, CompanyAttributesService, GatewayFiit, PosUsersService) {
            if (KioskService.isKiosk()) {
                $scope.isKiosk = true;
                $scope.keyBuffer = [];

                // keylogger to watch for close shift command
                var logKey = function (e) {
                    $scope.keyBuffer.push = function (...args) {
                        if (this.length >= 3) {
                            this.shift();
                        }
                        return Array.prototype.push.apply(this, args);
                    };
                    $scope.keyBuffer.push(e.code);
                    if ($scope.keyBuffer.length == 3
                    && $scope.keyBuffer[0] == 'ControlLeft'
                    && $scope.keyBuffer[1] == 'KeyC'
                    && $scope.keyBuffer[2] == 'KeyX') {
                        $scope.keyBuffer = [];
                        $scope.openCashierOptions();
                    } else if ($scope.keyBuffer.length == 3
                    && $scope.keyBuffer[0] == 'ControlLeft'
                    && $scope.keyBuffer[1] == 'KeyC'
                    && $scope.keyBuffer[2] == 'KeyS') {
                        $scope.keyBuffer = [];
                        $scope.hideKiosk = true;
                    }
                };
                document.addEventListener('keydown', logKey);
            }

            const getCurrentMenuPeriod = function () {
                if (CommonOfflineCache.getCurrentMenuPeriod()) {
                    return CommonOfflineCache.getCurrentMenuPeriod().description;
                }

                return '';
            };

            const getCurrentFiitServicePeriod = function () {
                const fiitServicePeriodId = CommonOfflineCache.getCurrentFiitServicePeriodId();
                const sp = $scope.fiitServicePeriods || GatewayFiit.getServicePeriods();
                if (!fiitServicePeriodId) {
                    return '';
                }

                const fiitServicePeriod = _.find(sp, {'servicePeriodId': fiitServicePeriodId});
                if (!(fiitServicePeriod && fiitServicePeriod.description)) {
                    return '';
                }

                return fiitServicePeriod.description;
            };

            const callBackFunc = () => {
                $scope.isCashierOptionsModalOpen = false;
            };

            $scope.getCurrentMenu = function () {
                if (!$scope.shift || !$scope.shift.started) {
                    return;
                }

                const selectedMenu = CommonOfflineCache.getCurrentMenu();

                if (selectedMenu) {
                    return selectedMenu.menuName;
                }

                if ($scope.isCashierOptionsModalOpen) {
                    return;
                }

                if (CompanyAttributesService.hasMenuV2Enabled()) {
                    $scope.isCashierOptionsModalOpen = true;
                    $scope.openCashierOptions(callBackFunc, {openMenuSwitchModal: true});
                } else {
                    return '';
                }
            };

            $scope.openDrawerDisabled = Security.getUser().company.attributes.other__hide_open_drawer_button === 'true';

            $scope.openTimeTracking = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'pos/smb/templates/pos.time-tracking.tpl.html',
                    animation: false,
                    backdrop: true,
                    controller: 'SmbPosTimeTrackingCtrl',
                    windowClass: 'smb-pos smb-pos-popup',
                    keyboard: false
                });

                modalInstance.result.then(function () {
                    $scope.refreshClockedInUsers();
                }, function () {
                    $scope.refreshClockedInUsers();
                });
            };

            const CLOCKED_IN_USER_MOINTOR_INTERVAL = 300000; // every 5 minutes for now
            var monitorClockedInUsersFn;
            var monitorClockedInUsers = function () {
                if (!$scope.offline) {
                    Users.getClockedInUsers({}, function (response) {
                        $scope.clockedIn = response;
                        CommonOfflineCache.saveClockedInUsers(response);
                        PosUsersService.setClockedInUsers(response);
                    });
                } else {
                     CommonOfflineCache.getClockedInUsers().then(function (response) {
                        $scope.clockedIn = response;
                        PosUsersService.setClockedInUsers(response);
                    });
                }
                monitorClockedInUsersFn = $timeout(monitorClockedInUsers, CLOCKED_IN_USER_MOINTOR_INTERVAL);
            };
            $scope.refreshClockedInUsers = function () {
                $timeout.cancel(monitorClockedInUsersFn);
                monitorClockedInUsers();
            };

            $scope.openCashDrawerForOpenStation = function () {
                var cashDrawerOpenReason = {
                    description: 'Counting Cash For Opening Station'
                };

                var cashDrawerOpenEntry = $scope.$parent.createCashDrawerOpenEntry(cashDrawerOpenReason,
                        CurrentSession.getUser(), undefined, 'other');

                    var callBackSuccess = function (reportResponse) {
                        SmbPosService.loadStatus();
                    };
                    var errorBackSuccess = function (error) {
                        SmbPosService.loadStatus();
                        $log.error({
                            message: 'Error while logging Cash Drawer Activity on open station',
                            context: {
                                activity: 'logCashDrawerOpenActivity',
                                posStation: $scope.newShift.posStationId,
                                location: $scope.newShift.locationId,
                                user: CurrentSession.getUser()
                            }
                        });
                    };

                    cashDrawerOpenEntry.locationId = $scope.newShift.locationId;
                    cashDrawerOpenEntry.posStationId = $scope.newShift.posStationId;

                    SmbPosService.openDrawerAsPromise($scope.newShift).then(function () {
                        $scope.$parent.logCashDrawerActivity(cashDrawerOpenEntry, callBackSuccess, errorBackSuccess);
                    }, function () {
                        cashDrawerOpenEntry.description = '[Open Failed] ' + cashDrawerOpenEntry.description;
                        $scope.$parent.logCashDrawerActivity(cashDrawerOpenEntry, callBackSuccess, errorBackSuccess);
                    });
            };

            $scope.init = function () {
                monitorClockedInUsers();
                $scope.currentMenuPeriod = getCurrentMenuPeriod();
                $scope.currentFiitServicePeriod = getCurrentFiitServicePeriod();
            };
            $scope.init();

            const openCashierOptions = $rootScope.$on('kioskNavBar:openCashierOptions', function (event, data) {
                $scope.openCashierOptions();
            });

            const updateFiitStats = $rootScope.$on('updateFiitStats', () => {
                $scope.currentMenuPeriod = getCurrentMenuPeriod();
                $scope.currentFiitServicePeriod = getCurrentFiitServicePeriod();
            });

            $scope.$on('$destroy', function () {
                if (monitorClockedInUsersFn) {
                    $timeout.cancel(monitorClockedInUsersFn);
                }
                document.removeEventListener('keydown', logKey);
                openCashierOptions();
                updateFiitStats();
            });
        }
    ]);
};
