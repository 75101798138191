'use strict';


const angular = require('angular');
const ngResource = require('angular-resource');

export default angular.module('freshideas.resources.settings', [ngResource]).service('Settings', [
    '$resource',
    'EnvConfig',
    function ($resource, EnvConfig) {
        return $resource(EnvConfig.host + '/freshideas/web/settings', {}, {
            'getEmailSettings': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/settings/emailSettings'
            },
            'updateEmailSettings': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/settings/emailSettings'
            },
            'getTenderTypes': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/settings/tenderType/list'
            },
            'addTenderType': {
                method: 'PUT',
                cache: false,
                url: EnvConfig.host + '/freshideas/web/settings/tenderType'
            },
            'deleteTenderType': {
                method: 'DELETE',
                url: EnvConfig.host + '/freshideas/web/settings/tenderType'
            },
            'getServicePeriod': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/settings/servicePeriod/'
            },
            'getServicePeriods': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/settings/servicePeriod/list'
            },
            'addServicePeriod': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/settings/servicePeriod'
            },
            'addServicePeriods': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/settings/servicePeriods',
                isArray: true
            },
            'updateServicePeriod': {
                method: 'PUT',
                url: EnvConfig.host + '/freshideas/web/settings/servicePeriod'
            },
            'deleteServicePeriod': {
                method: 'DELETE',
                url: EnvConfig.host + '/freshideas/web/settings/servicePeriod'
            },
            'getMealEquivalency': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/settings/mealEquivalency'
            },
            'getMealEquivalencies': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/settings/mealEquivalency/list'
            },
            'addMealEquivalency': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/settings/mealEquivalency'
            },
            'updateMealEquivalency': {
                method: 'PUT',
                url: EnvConfig.host + '/freshideas/web/settings/mealEquivalency'
            },
            'deleteMealEquivalency': {
                method: 'DELETE',
                url: EnvConfig.host + '/freshideas/web/settings/mealEquivalency'
            },
            'getMealEquivalencyOptions': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/settings/mealEquivalencyOption/list'
            },
            'addMealEquivalencyOption': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/settings/mealEquivalencyOption'
            },
            'updateMealEquivalencyOption': {
                method: 'PUT',
                url: EnvConfig.host + '/freshideas/web/settings/mealEquivalencyOption'
            },
            'deleteMealEquivalencyOption': {
                method: 'DELETE',
                url: EnvConfig.host + '/freshideas/web/settings/mealEquivalencyOption'
            },
            'getTaxRates': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/settings/taxRate/list'
            },
            'addTaxRate': {
                method: 'PUT',
                url: EnvConfig.host + '/freshideas/web/settings/taxRate'
            },
            'updateTaxRate': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/settings/taxRate'
            },
            'deleteTaxRate': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/settings/deleteTaxRate'
            },
            'createRole': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/settings/role'
            },
            'getRoles': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/settings/role',
                isArray: true
            },
            'getRolePermissions': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/settings/rolePermissions',
                isArray: false
            },
            'updatePermissions': {
                method: 'PUT',
                url: EnvConfig.host + '/freshideas/web/settings/rolePermissions'
            },
            'addDiscount': {
                method: 'PUT',
                url: EnvConfig.host + '/freshideas/web/settings/discounts'
            },
            'getDiscounts': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/settings/discounts',
                isArray: true
            },
            'updateDiscount': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/settings/discounts',
            },
            'addMealPlan': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/settings/mealPlan',
            },
            'updateMealPlan': {
                method: 'PUT',
                url: EnvConfig.host + '/freshideas/web/settings/mealPlan',
            },
            'deleteMealPlan': {
                method: 'DELETE',
                url: EnvConfig.host + '/freshideas/web/settings/deleteMealPlan',
            }
        });
    }
]);
