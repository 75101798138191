'use strict';


const angular = require('angular');

export default angular.module('freshideas.services.userActivity', [])
    .factory('UserActivityTypeService', [function () {
        return {
            'PATRON__ADD': 'Add Patron',
            'PATRON__UPDATE': 'Update Patron',
            'PATRON__DELETE': 'Delete Patron',
            'PATRON__IMPORT': 'Import Patron',
            'PATRONS__ADD': 'Batch Add Patron',
            'PATRONS__UPDATE': 'Batch Update Patron',
            'PATRONS__UPDATE_STATUS': 'Batch Update Patron Status',
            'PATRONS__UPDATE_MEAL_PLANS': 'Batch Update Patron Meal Plans',
            'PATRONS__DELETE_MEAL_PLANS': 'Batch Delete Patron Meal Plans',
            'PATRON_MEAL_PLAN__DEPOSIT': 'Patron Meal Plan Deposit',
            'PATRON_MEAL_PLAN__REFUND': 'Patron Meal Plan Refund',
            'PATRON_MEAL_PLAN__ADJUSTMENT': 'Patron Meal Plan Deduction',
            'PATRON_MEAL_PLAN__UPDATE_BALANCE': 'Patron Meal Plan Balance Update',
            'PATRON_MEAL_PLAN__IMPORT_BALANCE_ADJUSTMENTS': 'Patron Meal Plan Balance Adjustments Import',
            'PATRON_MEAL_PLAN__DEDUCT': 'Deduct Meals',
            'PATRON_MEAL_PLAN__MAINTAINENCE': 'Patron Meal Plan Maintainence',
            'PATRON_MEAL_PLAN__AUTO_RESET': 'Patron Meal Plan Balance Reset',
            'POS__NO_SALE': 'POS No Sale',
            'POS__TENDER_TRANSACTION': 'Transaction',
            'POS__TENDER_PREORDER_TRANSACTION': 'Preorder',
            'POS__REFUND_TRANSACTION': 'Refund Transaction',
            'POS__CAPTURE_PREAUTH_TRANSACTION': 'Capture Preauth',
            'POS__INITIALIZE_PREAUTH_TRANSACTION': 'Initialize Preauth'
        };
    }]);
