module.exports = function (posModule) {
    posModule.controller('PosTransactionRefundRetryCtrl', [
        '$scope',
        'tender',
        'retryDetails',
        'transaction',
        'CardTerminal',
        function (
            $scope,
            tender,
            retryDetails,
            transaction,
            CardTerminal
        ) {
            $scope.refunding = false;

            $scope.tender = tender;

            $scope.refundType = retryDetails.refundType;
            $scope.message = retryDetails.notifyUser;

            $scope.retry = function (complete = true) {
                $scope.refunding = true;

                let terminal = CardTerminal.init(retryDetails.terminalConfig);
                let transactionId = retryDetails.data[0].attributeTranId;

                let terminalAction;

                // if ($scope.refundType === 'void') {
                //     terminalAction = terminal.creditVoid(transactionId, {tenderToVoid: tender, transaction: transaction});
                // } else {
                //     var amountToRefund = tender.amountCents / 100; // parseFloat(terminalResponse.approvedAmount);
                //     terminalAction = terminal.creditReturn(amountToRefund, {tenderToReturn: tender, transactionId: transactionId, transaction: transaction});
                // }

                // complete multi part transaction
                terminalAction = terminal.completeMultiPartTransaction({transactionId: transactionId}, complete);

                terminalAction.then(function (response) {
                    $scope.$close({response});
                }, function (err) {
                    $scope.$dismiss(err);
                }).catch(function (e) {
                    console.error(e);
                    $scope.$dismiss(e);
                });

            };
        }
    ]);
};
