'use strict';

const angular = require('angular');
const moment = require('moment');

const AppConstants = require('../../common/freshideas/app-constants');
const THIRD_PARTY_APPS = AppConstants.thirdPartyChannels;

export default angular.module('freshideas.services.preorder', [])
.factory('PreorderService', [
    '$translate',
    'CurrentSession',
    'PREORDER_STATUS',
    function (
        $translate,
        CurrentSession,
        PREORDER_STATUS
    ) {

        // takes a param of channel name string. this should be coming directly from
        // the payment server.
        const getChannelDisplayName = (channel) => {
            if (!channel) {
                // in case an undefined/null has been passed.
                return $translate.instant('general.thirdPartyApp.none');
            }

            // ensure the channel exists in the channel constant.
            // else return a generic label.
            if (THIRD_PARTY_APPS.hasOwnProperty(channel)) {
                return THIRD_PARTY_APPS[channel].displayName;
            } else {
                return $translate.instant('general.thirdPartyApp');
            }
        };

        // takes a param of channel name string. this should be coming directly from
        // the payment server.
        const getChannelIcon = (channel) => {
            // ensure the channel exists in the channel constant.
            // else return nown logo as fallback
            if (THIRD_PARTY_APPS.hasOwnProperty(channel)) {
                return THIRD_PARTY_APPS[channel].appIcon;
            } else {
                return THIRD_PARTY_APPS['NOWN'].appIcon;
            }
        };

        const _setAppIcon = (preorder) => {
            if (preorder.appIcon) {
                // if an app icon has already been set previously, stop and return.
                return;
            }

            let icon;
            if (preorder.app_fid === 'eatable' || preorder.app_fid === 'freshx') {
                // first party (campus only)
                icon = '../../images/apps/' + preorder.app_fid + '-app-icon.png';
            } else if (preorder.is_third_party_preorder && preorder.preorder_details) {
                // third party
                icon = getChannelIcon(preorder.preorder_details.channel);
            } else {
                // first party
                let appIcon = CurrentSession.getCompany().attributes.app_icon || 'apps/nown-app-icon.png';
                icon = '../../images/' + appIcon;
            }
            preorder.appIcon = icon;
        };

        const _setEstimatedTime = (preorder) => {
            let formattedTime;
            if (preorder.status === PREORDER_STATUS.PRINT_PENDING || preorder.status === PREORDER_STATUS.PENDING) {
                var estimatedTime = moment().add(preorder.prep_time_sec, 'seconds');
                formattedTime = estimatedTime.format('hh:mm a');
            } else {
                formattedTime = moment.unix(preorder.estimated_completion).format('hh:mm a');
            }
            preorder.formattedTime = formattedTime;
        };

        // delivery service provider (e.g. self-delivery or channel-provided delivery service)
        const _setServiceProvider = (preorder) => {
            // only set a service provider if this is a delivery order.
            if (!preorder.is_delivery) {
                return;
            }

            // if delivery service is provided by a third party, set the channel display name as the provider.
            // else simply set the company's name.
            if (preorder.preorder_details && !preorder.preorder_details.is_self_delivery) {
                const channelName = getChannelDisplayName(preorder.preorder_details.channel);
                preorder.deliveryServiceProvider = channelName;
            } else {
                preorder.deliveryServiceProvider = CurrentSession.getCompany().companyName;
            }
        };

        const transformPreorder = (preorder) => {
            // set app icons and formatted estimated time
            _setAppIcon(preorder);
            _setEstimatedTime(preorder);
            _setServiceProvider(preorder);
        };

        return {
            getChannelDisplayName: getChannelDisplayName,
            getChannelIcon: getChannelIcon,
            transformPreorder: transformPreorder
        };
    }
]);
