'use strict';

export function focusMe (freshideas) {

    freshideas.directive('focusMe', ['$timeout', '$parse', function ($timeout, $parse) {
        return {
            // scope: true,   // optionally create a child scope
            link: function (scope, element, attrs) {
                var model = $parse(attrs.focusMe);
                scope.$watch(model, function (value) {
                    if (value === true) {
                        $timeout(function () {
                            element[0].focus();
                        });
                    }
                });
                // to address @blesh's comment, set attribute value to 'false'
                // on blur event:
                element.bind('blur', function () {
                    // $parse returns a function with 'assign' property only if the expression it is converting (ie. 'attrs.focusMe`) is
                    // a variable. If the expression is a primitive, $parse simply returns the primitive and 'assign' wouldn't be available.
                    if (model.assign) {
                        scope.$apply(model.assign(scope, false));
                    }
                });

                scope.$on('$destroy', function () {
                    // strange memory leak where if we don't remove the input
                    // element we focus()'ed then, node leaks occur :/
                    element.remove();
                });
            }
        };
    }]);
}
