'use strict';

const angular = require('angular');
const moment = require('moment');

module.exports = function (freshideasReports) {
    freshideasReports.controller('DailySalesMealsServedReportCtrl', [
        '$scope',
        '$timeout',
        'Reports',
        'Export',
        'Lookup',
        function ($scope, $timeout, Reports, Export, Lookup) {
            var refreshLocations = function (companies) {
                if (companies) {
                    $scope.locations = _.flatten(_.map(companies, function (company) {
                        return company.children;
                    }), true);
                }
            };
            var refreshMealPeriods = function (locations) {
                if (locations) {
                    var mealPeriods = _.flatten(_.map(locations, function (location) {
                        return _.filter(location.children, function (child) {
                            return child.type === 'mealPeriod';
                        });
                    }), true);
                    $scope.mealPeriods = mealPeriods;
                    var currentMealPeriodId = $scope.reportSearch.mealPeriodId;
                    var foundMealPeriod = _.find($scope.mealPeriods, function (mealPeriod) {
                        return mealPeriod.id === currentMealPeriodId;
                    });
                    if (!foundMealPeriod) {
                        $scope.reportSearch.mealPeriodId = undefined;
                    }
                }
            };
            var tick = function () {
                $scope.currentDateTime = moment();
                $timeout(tick, 60 * 1000);
            };
            $timeout(tick, 60 * 1000);
            $scope.$watch('reportSearch.companyId', function (companyId) {
                if (companyId) {
                    var foundCompany = _.find($scope.companyHierarchy, function (company) {
                        return company.id === companyId;
                    });
                    if (foundCompany) {
                        refreshLocations([foundCompany]);
                    }
                } else {
                    var companyHierarchy = angular.copy($scope.companyHierarchy);
                    refreshLocations(companyHierarchy);
                }
            });
            $scope.$watch('reportSearch.locationId', function (locationId) {
                if (locationId) {
                    var foundLocation;
                    _.each($scope.companyHierarchy, function (company) {
                        var l = _.find(company.children, function (location) {
                            return location.id === locationId;
                        });
                        if (l) {
                            foundLocation = l;
                        }
                    });
                    if (foundLocation) {
                        refreshMealPeriods([foundLocation]);
                    }
                } else {
                    refreshMealPeriods($scope.locations);
                }
            });
            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };
            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };
            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };
            $scope.clearFilters = function (isAdvancedSearch) {
                $scope.reportSearch = {isAdvancedSearch: !!isAdvancedSearch};
            };
            $scope.clearFilters();
            $scope.lookupEntity = function (type, id) {
                if (!$scope.baseSettings) {
                    return;
                }
                var entities = $scope.baseSettings[type] || [];
                var foundEntity = _.find(entities, function (entity) {
                    return entity.id === id;
                }) || {};
                return foundEntity.name || '';
            };
            $scope.lookupCompanyHierarchy = function () {
                Lookup.companyHierarchy({}, function (response) {
                    $scope.companyHierarchy = response;
                    $scope.baseSettings = {};
                    $scope.baseSettings.companies = $scope.companyHierarchy;
                    $scope.baseSettings.locations = [];
                    _.each($scope.baseSettings.companies, function (company) {
                        var locations = company.children || [];
                        $scope.baseSettings.locations = $scope.baseSettings.locations.concat(locations);
                    });
                    $scope.baseSettings.mealPeriods = [];
                    $scope.baseSettings.poses = [];
                    _.each($scope.baseSettings.locations, function (location) {
                        _.each(location.children, function (child) {
                            if (child.type === 'mealPeriod') {
                                $scope.baseSettings.mealPeriods.push(child);
                            } else if (child.type === 'pos') {
                                $scope.baseSettings.poses.push(child);
                            }
                        });
                    });
                    var companyHierarchy = angular.copy($scope.companyHierarchy);
                    $scope.companies = companyHierarchy;
                    refreshLocations($scope.companies);
                    refreshMealPeriods($scope.locations);
                });
            };
            var convertReport = function (rawReport) {
                var report = angular.copy(rawReport);
                report.allTotals = {
                    amount: 0,
                    total: 0,
                    count: 0,
                    taxes: 0
                };
                _.each(report, function (campus) {
                    _.each(campus.campus.dates, function (date) {
                        _.each(date.locations, function (location) {
                            _.each(location.dailyMealPeriods, function (mealPeriod) {
                                var mealPlanTotals = {
                                    amount: 0,
                                    total: 0,
                                    meal: 0,
                                    taxes: 0
                                };
                                _.each(mealPeriod.mealPlans, function (mealPlan) {
                                    mealPlanTotals.meal += mealPlan.count || 0;
                                });
                                var totals = {
                                    amount: mealPeriod.dcb.amount + mealPeriod.charge.amount + mealPeriod.cash.amount + mealPeriod.creditCard.amount + mealPeriod.debitCard.amount + mealPeriod.other.amount,
                                    total: mealPeriod.dcb.total + mealPeriod.charge.total + mealPeriod.cash.total + mealPeriod.creditCard.total + mealPeriod.debitCard.total + mealPeriod.other.total,
                                    count: mealPeriod.dcb.count + mealPeriod.charge.count + mealPeriod.cash.count + mealPeriod.creditCard.count + mealPeriod.debitCard.count + mealPeriod.other.count,
                                    taxes: mealPeriod.dcb.taxes + mealPeriod.charge.taxes + mealPeriod.cash.taxes + mealPeriod.creditCard.taxes + mealPeriod.debitCard.taxes + mealPeriod.other.taxes,
                                    meal: mealPlanTotals.meal || 0
                                };
                                mealPeriod.total = totals;
                                report.allTotals.amount += totals.amount || 0;
                                report.allTotals.total += totals.total || 0;
                                report.allTotals.count += totals.count || 0;
                                report.allTotals.taxes += totals.taxes || 0;
                                report.allTotals.meal += totals.meal || 0;
                            });
                        });
                    });
                });
                return report;
            };
            $scope.getDailySalesMealsServedReport = function () {
                var reportSearch = {};
                reportSearch.startDateTime = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                reportSearch.endDateTime = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                if ($scope.reportSearch.companyId) {
                    reportSearch.companyId = $scope.reportSearch.companyId;
                }
                if ($scope.reportSearch.locationId) {
                    reportSearch.locationId = $scope.reportSearch.locationId;
                }
                if ($scope.reportSearch.mealPeriodId) {
                    reportSearch.servicePeriodId = $scope.reportSearch.mealPeriodId;
                }
                Reports.getDailySalesMealsServed(reportSearch, function (response) {
                    $scope.results = convertReport(response);
                    var currentReportSearch = {
                        fromDate: $scope.reportSearch.fromDate,
                        toDate: $scope.reportSearch.toDate,
                        companyName: $scope.lookupEntity('companies', $scope.reportSearch.companyId),
                        locationName: $scope.lookupEntity('locations', $scope.reportSearch.locationId),
                        mealPeriodName: $scope.lookupEntity('mealPeriods', $scope.reportSearch.mealPeriodId)
                    };
                    currentReportSearch = _.pick(currentReportSearch, function (value) {
                        return !!value;
                    });
                    if (_.isEmpty(currentReportSearch)) {
                        $scope.currentReportSearch = undefined;
                    } else {
                        $scope.currentReportSearch = currentReportSearch;
                    }
                });
            };
            $scope.init = function () {
                $scope.reportSearch = {
                    fromDate: moment().startOf('week').toDate(),
                    toDate: moment().endOf('day').toDate(),
                    isAdvancedSearch: true
                };
                $scope.lookupCompanyHierarchy();
                $scope.getDailySalesMealsServedReport();
            };
            $scope.init();
            $scope.exportToPdf = function (tableId) {
                Export.tableToPdf(tableId, 'portrait');
            };
        }
    ]);
};
