'use strict';

const angular = require('angular');
const freshideasResourcesUsers = require('./users.js').default;
const freshideasResourcesLocation = require('./location.js').default;
const freshideasResourcesCashier = require('./cashier-shift.js').default;
const freshideasResourcesSettings = require('./settings.js').default;
const freshideasResourcesPosStatus = require('../services/pos-status-service.js').default;
require('localforage');
const localForageModule = require('angular-localforage');
const Decimal = require('decimal.js').default;

export default angular.module('freshideas.resources.offline-cache',
    [
        freshideasResourcesUsers.name,
        freshideasResourcesLocation.name,
        freshideasResourcesCashier.name,
        freshideasResourcesSettings.name,
        freshideasResourcesPosStatus.name,
        localForageModule
    ]).config(['$localForageProvider', function ($localForageProvider) {
        $localForageProvider.config({
            name: 'freshIdeasStorage',
            version: 1.0,
            storeName: 'cache',
            description: 'freshIdeas config'
        });
    }])
    .factory('CommonOfflineCache',
        [
            'Patrons',
            'Locations',
            'Menu',
            'CashierShift',
            'Settings',
            'PosStatusService',
            '$localForage',
            'SharedDataService',
            'EnvConfig',
            'Lucova',
            'Users',
            'CompanyAttributesService',
            '$rootScope',
            function (Patrons, Locations, Menu, CashierShift, Settings, PosStatusService, $localForage, SharedDataService, EnvConfig, Lucova, Users, CompanyAttributesService, $rootScope) {

                const INTERVAL = 300000;

                const getFromCache = (key) => {
                    return $localForage.getItem(key);
                };

                var refreshCacheLogic = function (companyId, locationId) {

                    function refresh () {
                        Locations.listAllPrinters({'locationId': locationId}).$promise.then(function (response) {
                            SharedDataService.printers = response.entries;
                        });
                        // Clear menu items from the cache
                        SharedDataService.clearMenuCache();

                        // CashierShift.posMenuCached({ 'locationId': locationId });
                        if (CompanyAttributesService.hasMenuV2Enabled()) {
                            let currentMenuId = (getCurrentMenu()) ? getCurrentMenu().menuId : null;
                            Menu.getMenuGrid({'menuId': currentMenuId});
                        } else {
                            Locations.getLocationHierarchy({'locationId': locationId}, true);
                        }

                        Patrons.schoolCardMask({'companyId': companyId}, function (response) {
                            $localForage.setItem('schoolCardMask', response.regex).then(function () {
                                console.log('School card mask was saved');
                            });
                        });

                        // Cache FIIT patrons
                        Patrons.listPatrons({'companyId': companyId, 'limit': 9999999}, function (response) {
                            var patrons = response.entries;
                            $localForage.setItem('patrons', patrons).then(function () {
                                console.log('All patrons was saved');
                            });
                        });

                        // Cache Lucova users
                        if (Lucova.isConnected()) {
                            cacheAllLucovaUsers(companyId);
                        }

                        // warming up cache by service worker
                        CashierShift.currentCashierShift({}, function (response) { });

                        Settings.getServicePeriods({'companyId': companyId, 'limit': 9999999}, function (response) {
                            var servicePeriods = response.entries;
                            $localForage.setItem('servicePeriods', servicePeriods).then(function () {
                                console.log('All servicePeriods was saved');
                            });
                        });

                        if (PosStatusService.shiftStarted) {
                            setTimeout(refresh, INTERVAL);
                        }
                    }

                    refresh();
                };

                const saveLastStart = (lastStart) => {
                    return $localForage.setItem('lastWebviewRefresh', lastStart);
                };

                const getLastStart = () => {
                    return getFromCache('lastWebviewRefresh');
                };

                var cacheMenuItems = function (companyId, locationId) {
                    if (!CompanyAttributesService.hasRetailMenu()) return;

                    SharedDataService.clearMenuCache();
                    Locations.cacheMenuItemsForSearch({'locationId': locationId, 'type': 0});
                    Locations.cacheMenuItemsForSearch({'locationId': locationId, 'type': 1, 'limit': 2000});
                };

                var cacheAllLucovaUsers = function (companyId) {
                    Lucova.manager().getAllUsers({
                        company_id: companyId,
                        app_fid: EnvConfig.appFid
                    }).$promise.then(function (response) {
                        if (response.success) {
                            $localForage.setItem('lucovaUsers', response.patrons).then(function () {
                                console.log('All lucova users was saved');
                            });
                        }
                    });
                };

                var getPatronsFromCacheLogic = function () {
                    return getFromCache('patrons');
                };
                var saveLocationIdLogic = function (locationId) {
                    $localForage.setItem('currentLocationId', locationId);
                };
                var getLocationIdLogic = function () {
                    return getFromCache('currentLocationId');
                };
                var savePosStationMenuLogic = function (locationId) {
                    // CashierShift.posMenuCached({'locationId': locationId}, function (response) {
                    // $localForage.setItem('posStationMenu', response);
                    // });
                };
                var getPatronLogic = function (key, patrons) {
                    var toReturn;

                    return getFromCache('schoolCardMask').then(function (regex) {
                        key = key + ''; // sometimes `key` can be a numerical patron id
                        var parsedPatronKey = key.match(regex);

                        _.each(patrons, function (patron) {

                            if (patron.patronKey === key || patron.emailAddress === key
                                || patron.patronId == key || (parsedPatronKey != null && patron.patronKey === parsedPatronKey[1])) {
                                toReturn = patron;
                            }
                        });
                        toReturn ? console.log('Patron was found in cache : ', toReturn) : console.log('Patron was not found in cache');

                        return toReturn;
                    });
                };

                var getPatron = function (key) {
                    return new Promise(function (resolve, reject) {
                        getPatronsFromCacheLogic().then(function (patrons) {
                            return getPatronLogic(key, patrons);
                        }).then(function (patron) {
                            if (patron) {
                                resolve(patron);
                            }

                            reject();
                        });
                    });
                };

                var getPatronForLucovaUsername = function (username) {
                    return new Promise(function (resolve, reject) {
                        getFromCache('lucovaUsers').then(function (users) {
                            // users may return undefined
                            if (users && users[username]) {
                                return getPatron(users[username]);
                            }

                            reject();
                        }).then(function (patron) {
                            resolve(patron);
                        });
                    });
                };

                var getPatronsLogic = function (keys) {
                    return new Promise(function (resolve, reject) {
                        getPatronsFromCacheLogic().then(function (patrons) {
                            var toReturn = {};

                            return getFromCache('schoolCardMask').then(function (regex) {

                                _.each(keys, function (key) {
                                    key = key + ''; // sometimes `key` can be a numerical patron id
                                    var parsedPatronKey = key.match(regex);

                                    _.each(patrons, function (patron) {
                                        if (patron.patronKey === key || patron.emailAddress === key
                                            || patron.patronId == key || (parsedPatronKey != null && patron.patronKey === parsedPatronKey[1])) {
                                            toReturn[patron.patronCompanyKey] = patron;
                                        }
                                    });
                                });

                                return toReturn;
                            });
                        }).then(function (patrons) {
                            if (patrons) {
                                resolve(patrons);
                            }

                            reject();
                        });
                    });
                };

                var setCurrentMenuPeriod = function (menuPeriod, toReset = true) {
                    if (menuPeriod) {
                        var tmpMenuPeriod = angular.copy(menuPeriod);
                        if (toReset) {
                            tmpMenuPeriod.menuPeriodsCancelled = [];
                        }
                        localStorage.setItem('menuPeriod', JSON.stringify(tmpMenuPeriod));
                        $rootScope.$emit('updateFiitStats');
                    }
                };

                var getCurrentMenuPeriod = function () {
                    return JSON.parse(localStorage.getItem('menuPeriod'));
                };

                var unsetCurrentMenuPeriod = function () {
                    localStorage.removeItem('menuPeriod');
                };

                var setCurrentMenu = async function (menu) {
                    if (menu) {
                        await localStorage.setItem('menu', JSON.stringify(menu));
                    }
                };

                var getCurrentMenu = function (menu) {
                    return JSON.parse(localStorage.getItem('menu'));
                };

                var unsetCurrentMenu = function () {
                    localStorage.removeItem('menu');
                };

                const setCurrentFiitServicePeriodId = function (fiitServicePeriodId) {
                    localStorage.setItem('fiitServicePeriodId', JSON.stringify(fiitServicePeriodId));
                    $rootScope.$emit('updateFiitStats');
                };

                const getCurrentFiitServicePeriodId = function () {
                    return JSON.parse(localStorage.getItem('fiitServicePeriodId'));
                };

                const unsetCurrentFiitServicePeriodId = function (fiitServicePeriodId) {
                    localStorage.removeItem('fiitServicePeriodId');
                };

                var setQuickChargeSession = function (quickChargeSession) {
                    if (quickChargeSession) {
                        localStorage.setItem('quickChargeSession', JSON.stringify(quickChargeSession));
                    }
                };

                var getQuickChargeSession = function () {
                    return JSON.parse(localStorage.getItem('quickChargeSession'));
                };

                var unsetQuickChargeSession = function () {
                    localStorage.removeItem('quickChargeSession');
                };


                var setMenuPeriodToSwitchTo = function (millisTillAlert = 2000, menuPeriod) {
                    if (menuPeriod) {
                        var menuPeriodToSwitchTo = getMenuPeriodToSwitchTo() || {};
                        menuPeriodToSwitchTo.menuPeriod = angular.copy(menuPeriod);
                        menuPeriodToSwitchTo.millisTillAlert = millisTillAlert;
                        localStorage.setItem('switchMenuPeriodTo', JSON.stringify(menuPeriodToSwitchTo));
                    }
                };

                var getMenuPeriodToSwitchTo = function () {
                    return JSON.parse(localStorage.getItem('switchMenuPeriodTo'));

                };

                var resetMenuPeriodToSwitchTo = function () {
                    localStorage.setItem('switchMenuPeriodTo', JSON.stringify({}));
                };

                var canQuickCharge = function (patron) {
                    var validMealUnits = _.filter(patron.mealPlans, function (mealPlan) {
                        return mealPlan.remainingServicePeriodMeals >= 1;
                    });

                    if (validMealUnits.length > 0) {
                        return true;
                    }

                    return false;
                };

                var getMealPlansLogic = function (key, patrons) {
                    var toReturn;

                    _.each(patrons, function (patron) {

                        if (key === patron.patronId) {
                            toReturn = patron.mealPlans;
                        }

                    });
                    toReturn ? console.log('MealPlans was found in cache : ', toReturn) : console.log('MealPlans was not found in cache');

                    return toReturn;
                };
                var getServicePeriodLogic = function (key, servicePeriods) {
                    var toReturn;

                    _.each(servicePeriods, function (servicePeriod) {

                        if (key === servicePeriod.servicePeriodId) {
                            toReturn = servicePeriod;
                        }

                    });
                    toReturn ? console.log('ServicePeriod was found in cache : ', toReturn) : console.log('ServicePeriod was not found in cache');

                    return toReturn;
                };
                var getPosStationMenuLogic = function () {
                    return getFromCache('posStationMenu').then(function (response) {
                        return response;
                    });
                };

                var refreshPatronLogic = function (tenderTransaction) {

                    let patronsPromise = getFromCache('patrons');
                    return patronsPromise.then(function (patrons) {
                        _.each(patrons, function (patron) {
                            if (patron.patronId === tenderTransaction.patronId) {

                                // This checking the same with back-end checking..
                                if (tenderTransaction.remainingBalance > 0) {
                                    console.error('Insufficient tender amount');
                                    return;
                                }

                                if (!tenderTransaction.guestTransaction && tenderTransaction.patronId < 0) {
                                    console.info('Invalid transaction state.  Transaction is a patron transaction without a patron id.');
                                    return;
                                }

                                let tenderMealCount = tenderTransaction.mealPlanCount;
                                let tenderDcbCount = new Decimal(tenderTransaction.dcbAmount).toNearest(SharedDataService.baseDollar).toNumber();
                                let tenderIcbCount = new Decimal(tenderTransaction.chargeAmount).toNearest(SharedDataService.baseDollar).toNumber();

                                if (tenderMealCount < 0 || tenderDcbCount < 0 || tenderIcbCount < 0) {
                                    console.error('Invalid values provided');
                                    return;
                                }

                                let remainingMealTender = tenderMealCount; // RECHECK (Forced to 1 as a temporary fix to resetting meals to 0) tenderTransaction.mealPlanCount;
                                let remainingDcbTender = tenderDcbCount;
                                let remainingIcbTender = tenderIcbCount;

                                let mealPlansTenderSettled = false;
                                let dcbTenderSettled = false;
                                let icbTenderSettled = false;

                                tenderTransaction.dcbMealPlanIds = '';

                                _.each(patron.mealPlans, function (mealPlan) {

                                    if (mealPlan.priority < 0) return;

                                    let currentMealBalance = mealPlan.currentMealPlanBalance;
                                    let currentDcbBalance = new Decimal(mealPlan.currentDcbBalance).toNearest(SharedDataService.baseDollar).toNumber();
                                    let currentIcbBalance = new Decimal(mealPlan.currentChargeBalance).toNearest(SharedDataService.baseDollar).toNumber();
                                    let servicePeriodMealBalance = mealPlan.remainingServicePeriodMeals;
                                    let servicePeriodDcbBalance = new Decimal(mealPlan.remainingServicePeriodDcb).toNearest(SharedDataService.baseDollar).toNumber();
                                    let servicePeriodIcbBalance = new Decimal(mealPlan.remainingServicePeriodCharge).toNearest(SharedDataService.baseDollar).toNumber();

                                    if (!mealPlansTenderSettled && remainingMealTender > 0 && currentMealBalance > 0) {

                                        let newServicePeriodMealBalance = servicePeriodMealBalance - remainingMealTender;
                                        let newCurrentMealBalance = currentMealBalance - remainingMealTender;

                                        if (newServicePeriodMealBalance >= 0) {
                                            mealPlan.remainingServicePeriodMeals = newServicePeriodMealBalance;

                                            let newCurrentMealMealBalanceInt = Math.max(newCurrentMealBalance, 0);
                                            mealPlan.currentMealPlanBalance = newCurrentMealMealBalanceInt;

                                            remainingMealTender = 0;
                                            mealPlansTenderSettled = true;
                                        } else {
                                            let newCurrentMealMealBalanceInt = Math.max(newCurrentMealBalance, 0);
                                            mealPlan.currentMealPlanBalance = newCurrentMealMealBalanceInt;

                                            remainingMealTender = remainingMealTender - servicePeriodMealBalance;

                                            mealPlan.remainingServicePeriodMeals = 0;

                                            mealPlansTenderSettled = remainingMealTender === 0;
                                        }
                                    }

                                    console.info('[DEDUCT] Remaining balance: ', remainingDcbTender);
                                    console.info('[DEDUCT] Getting plan balance: ', mealPlan.currentDcbBalance);
                                    console.info('[DEDUCT] Getting plan remaining: ', mealPlan.remainingServicePeriodDcb);

                                    if (!dcbTenderSettled
                                        && remainingDcbTender > 0
                                        && servicePeriodDcbBalance > 0
                                        && currentDcbBalance > 0) {

                                        console.info('[DEDUCT] Calculate: ', (currentDcbBalance - remainingDcbTender));

                                        let dcbBalanceToDeduct = Math.min(Math.min(currentDcbBalance, servicePeriodDcbBalance), remainingDcbTender);
                                        let newCurrentDcbBalance = new Decimal(currentDcbBalance - dcbBalanceToDeduct).toNearest(SharedDataService.baseDollar).toNumber();
                                        let newServicePeriodDcbBalance = new Decimal(servicePeriodDcbBalance - dcbBalanceToDeduct).toNearest(SharedDataService.baseDollar).toNumber();

                                        console.info('[DEDUCT] Calculate2: ', newCurrentDcbBalance);

                                        mealPlan.currentDcbBalance = newCurrentDcbBalance;

                                        if (newServicePeriodDcbBalance > 0) {
                                            console.info('[DEDUCT] v1');
                                            remainingDcbTender = 0;
                                            mealPlan.remainingServicePeriodDcb = newServicePeriodDcbBalance;

                                            dcbTenderSettled = true;
                                        } else {
                                            console.info('[DEDUCT] v2');
                                            remainingDcbTender = new Decimal(remainingDcbTender - dcbBalanceToDeduct).toNearest(SharedDataService.baseDollar).toNumber();
                                            mealPlan.remainingServicePeriodDcb = 0;

                                            dcbTenderSettled = remainingDcbTender === 0;
                                        }
                                    }

                                    if (!icbTenderSettled && remainingIcbTender > 0) {

                                        let newCurrentIcbBalance = new Decimal(currentIcbBalance - tenderIcbCount).toNearest(SharedDataService.baseDollar).toNumber();
                                        let newServicePeriodIcbBalance = new Decimal(servicePeriodIcbBalance - tenderIcbCount).toNearest(SharedDataService.baseDollar).toNumber();

                                        if (newCurrentIcbBalance >= 0) {
                                            mealPlan.currentChargeBalance = newCurrentIcbBalance;
                                            mealPlan.remainingServicePeriodCharge = newServicePeriodIcbBalance;
                                            remainingIcbTender = new Decimal(remainingIcbTender - tenderIcbCount).toNearest(SharedDataService.baseDollar).toNumber();

                                            // NOTE: previous line was `if (remainingICBBalance > .01) {` - why was it compared to 0.01?
                                            if (remainingIcbTender > 0.01) {
                                                icbTenderSettled = true;
                                            }
                                        }
                                    }
                                });

                                if (remainingIcbTender > 0 || remainingDcbTender > 0) {
                                    console.error('Invalid amount tendered. Please review the transaction');
                                    return;
                                }

                                if (mealPlansTenderSettled || dcbTenderSettled || icbTenderSettled) {
                                    $localForage.setItem('patrons', patrons).then(function () {
                                        console.log('All patrons was saved');
                                    });
                                }
                            }
                        });

                    });
                };
                var updatePatronLogic = function (patronKey) {
                    Patrons.byPatronKey({'patronKey': patronKey}, function (response) {
                        let patronsPromise = getFromCache('patrons');
                        patronsPromise.then(function (patrons) {
                            _.each(patrons, function (patron, index) {

                                if (patron.patronId === response.patronId) {
                                    // patron.mealPlans = response.mealPlans;
                                    patrons[index] = response;
                                }

                            });

                            $localForage.setItem('patrons', patrons).then(function () {/* ALL PATRONS WAS SUCCESSFULLY SAVED*/});

                        });
                    });
                };

                var saveCurrentLocationLogic = function (currentLocation) {
                    $localForage.setItem('currentLocation', currentLocation);
                };

                var getCurrentLocationLogic = function () {
                    return getFromCache('currentLocation');
                };

                var cacheAllUsersInCompany = function () {
                    Users.getUsersForOffline({}, function (response) {
                        var pinEntries = {};
                        response.entries.forEach(function (user) {
                            if (user.pin) {
                                pinEntries[user.pin] = {
                                    user: user
                                };
                            }
                        });
                        $localForage.setItem('pinEntries', pinEntries);
                    }, function () {
                        $localForage.setItem('pinEntries', {});
                    });
                };

                var getUserByPin = function (pin, verifyAllUserPinsForCompany) {
                    return new Promise(function (resolve, reject) {
                        var pinUser;
                        getFromCache('pinEntries').then(function (pinEntries) {
                            if (pinEntries && pinEntries[pin]) {
                                pinUser = pinEntries[pin];
                                pinUser.pin = pin;
                                if (verifyAllUserPinsForCompany) {
                                    resolve(pinUser);
                                }
                                findClockedInUser(pinUser.user.userId).then(function () {
                                    resolve(pinUser);
                                }, function () {
                                    var error = {
                                        error: 'general.error.oops-not-clocked-in.msg',
                                        exception: {
                                            appCode: 430,
                                            message: 'general.error.oops-not-clocked-in.msg'
                                        }
                                    };
                                    reject(error);
                                });
                            } else {
                                var error = {
                                    error: 'general.error.user-pin-not-found.msg',
                                    exception: {
                                        appCode: 412,
                                        message: 'general.error.user-pin-not-found.msg'
                                    }
                                };
                                reject(error);
                            }
                        }, function (error) {
                            reject();
                        });
                    });
                };

                var saveClockedInUsers = function (clockedIn) {
                    return $localForage.setItem('clockedIn', clockedIn);
                };

                var findClockedInUser = function (userId) {
                    return new Promise(function (resolve, reject) {
                        getFromCache('clockedIn').then(function (items) {
                            var found = items.find(function (item) {
                                return item.user.userId === userId;
                            });
                            if (found) {
                                resolve();
                                return;
                            }
                            reject();
                        }, function (error) {
                            reject();
                        });
                    });
                };

                var getClockedInUsers = function () {
                    return getFromCache('clockedIn');
                };

                var saveLastUser = function (lastUser) {
                    $localForage.setItem('lastUser', lastUser);
                };

                var getLastUser = function () {
                    return getFromCache('lastUser');
                };

                var saveLocationPosStations = function (locationPosStations) {
                    $localForage.setItem('locationPosStations', locationPosStations);
                };

                var saveMenuPeriods = function (menuPeriods) {
                    $localForage.setItem('menuPeriods', menuPeriods);
                };

                var saveMenus = function (menus) {
                    $localForage.setItem('menus', menus);
                };

                var getLocationPosStations = function () {
                    return getFromCache('locationPosStations');
                };

                var getMenuPeriods = function () {
                    return getFromCache('menuPeriods');
                };

                var getMenus = function () {
                    return getFromCache('menus');
                };

                var saveLocationShiftStatus = function (locationShiftStatus) {
                    $localForage.setItem('locationShiftStatus', locationShiftStatus);
                };

                var getLocationShiftStatus = function () {
                    return getFromCache('locationShiftStatus');
                };

                var saveCompanyLocations = function (companyLocations) {
                    $localForage.setItem('companyLocations', companyLocations);
                };

                var getCompanyLocations = function () {
                    return getFromCache('companyLocations');
                };

                var saveLocationServicePeriods = function (locationServicePeriods) {
                    $localForage.setItem('locationServicePeriods', locationServicePeriods);
                };

                var getLocationServicePeriods = function () {
                    return getFromCache('locationServicePeriods');
                };

                var saveCurrentServicePeriod = function (servicePeriod) {
                    $localForage.setItem('servicePeriod', servicePeriod);
                };

                var getCurrentServicePeriod = function () {
                    return getFromCache('servicePeriod');
                };

                return {
                    refreshCache: refreshCacheLogic,
                    getPatronsFromCache: getPatronsFromCacheLogic,
                    saveLocationId: saveLocationIdLogic,
                    getLocationId: getLocationIdLogic,
                    savePosStationMenu: savePosStationMenuLogic,
                    getPatron: getPatronLogic,
                    getPatrons: getPatronsLogic,
                    getMealPlans: getMealPlansLogic,
                    getServicePeriod: getServicePeriodLogic,
                    getPosStationMenu: getPosStationMenuLogic,
                    refreshPatron: refreshPatronLogic,
                    updatePatron: updatePatronLogic,
                    saveCurrentLocation: saveCurrentLocationLogic,
                    getCurrentLocation: getCurrentLocationLogic,
                    getPatronForLucovaUsername: getPatronForLucovaUsername,
                    canQuickCharge: canQuickCharge,
                    cacheAllLucovaUsers: cacheAllLucovaUsers,
                    cacheAllUsersInCompany: cacheAllUsersInCompany,
                    getUserByPin: getUserByPin,
                    saveClockedInUsers: saveClockedInUsers,
                    getClockedInUsers: getClockedInUsers,
                    saveLastUser: saveLastUser,
                    getLastUser: getLastUser,
                    saveLocationPosStations: saveLocationPosStations,
                    getLocationPosStations: getLocationPosStations,
                    saveLocationShiftStatus: saveLocationShiftStatus,
                    getLocationShiftStatus: getLocationShiftStatus,
                    saveCompanyLocations: saveCompanyLocations,
                    getCompanyLocations: getCompanyLocations,
                    saveLocationServicePeriods: saveLocationServicePeriods,
                    getLocationServicePeriods: getLocationServicePeriods,
                    saveCurrentServicePeriod: saveCurrentServicePeriod,
                    getCurrentServicePeriod: getCurrentServicePeriod,
                    saveMenuPeriods: saveMenuPeriods,
                    getMenuPeriods: getMenuPeriods,
                    cacheMenuItems: cacheMenuItems,
                    setCurrentMenuPeriod: setCurrentMenuPeriod,
                    getCurrentMenuPeriod: getCurrentMenuPeriod,
                    setMenuPeriodToSwitchTo: setMenuPeriodToSwitchTo,
                    getMenuPeriodToSwitchTo: getMenuPeriodToSwitchTo,
                    resetMenuPeriodToSwitchTo: resetMenuPeriodToSwitchTo,
                    unsetCurrentMenuPeriod: unsetCurrentMenuPeriod,
                    setQuickChargeSession: setQuickChargeSession,
                    getQuickChargeSession: getQuickChargeSession,
                    unsetQuickChargeSession: unsetQuickChargeSession,
                    saveMenus: saveMenus,
                    getMenus: getMenus,
                    setCurrentMenu: setCurrentMenu,
                    getCurrentMenu: getCurrentMenu,
                    unsetCurrentMenu: unsetCurrentMenu,
                    setCurrentFiitServicePeriodId: setCurrentFiitServicePeriodId,
                    getCurrentFiitServicePeriodId: getCurrentFiitServicePeriodId,
                    unsetCurrentFiitServicePeriodId: unsetCurrentFiitServicePeriodId,
                    saveLastStart: saveLastStart,
                    getLastStart: getLastStart
                };
            }]);
