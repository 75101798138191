'use strict';


module.exports = function (freshideasReports) {

    freshideasReports.controller('ReportBaseCtrl', [
        '$scope',
        'Export',
        'Security',
        'Platform',
        function ($scope, Export, Security, Platform) {
            var currentUser = Security.getUser() || {};
            var currentCompanyType = currentUser.company.companyType;

            $scope.isIosWebkit = Platform.isIosWebkit();
            $scope.isElectron = Platform.isElectron();

            $scope.showMeals = (currentCompanyType !== 'SMB');

            $scope.exportToExcel = function (tableId, reportName) {
                // TODO: turn this into a directive
                $scope.exportHref = Export.tableToExcel(tableId, 'Sheet 1');
                var objectUrl = URL.createObjectURL($scope.exportHref);
                $('#DownloadAsExcel')[0].href = objectUrl;
                $('#DownloadAsExcel')[0].download = reportName + '.xls';
                $('#DownloadAsExcel')[0].click();

                URL.revokeObjectURL(objectUrl);
            };
        }
    ]);
};
