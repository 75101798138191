'use strict';


const angular = require('angular');
const ngResource = require('angular-resource');

export default angular.module('freshideas.resources.solink', [ngResource])
    .factory('Solink', ['$resource', 'EnvConfig', function ($resource, EnvConfig) {
        return $resource(EnvConfig.host + '/freshideas/web/solink', {}, {
            sendSolinkEvent: {method: 'POST', url: EnvConfig.host + '/freshideas/web/solink/events'},
        });
    }]);
