'use strict';

module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ProductMenuExportCtrl', [
        '$scope',
        '$translate',
        'EnvConfig',
        function ($scope, $translate, EnvConfig) {
            $scope.exportOptions = [
                {
                    name: $translate.instant('product.menu.export.includeModifier'),
                    option: 'includeModifiers',
                    selected: true,
                },
                {
                    name: $translate.instant('product.menu.export.includeInActive'),
                    option: 'includeInactive',
                    selected: false,
                },
                {
                    name: $translate.instant('product.menu.export.includeNotInGrid'),
                    option: 'includeNotOnGrid',
                    selected: false,
                }
            ];

            $scope.closeModal = function () {
                $scope.$close({action: 'cancel'});
            };

            $scope.setDownloadUrl = function () {
                let params = {};
                let resourceUrl = EnvConfig.nown_protocol + EnvConfig.nown_host
                + '/freshideas/web/menu/export';

                $scope.exportOptions.forEach(function (item) {
                    params[item.option] = item.selected;
                });

                $scope.$close({
                    resourceUrl: resourceUrl,
                    params: params,
                    action: 'export'
                });
            };

        }
    ]);
};
