'use strict';


module.exports = function (freshideasPosInventoryV2) {
    freshideasPosInventoryV2.controller('InventoryV2ReceiveCtrl', [
        '$scope',
        '$modal',
        '$timeout',
        'ORDER_STATUS',
        'InventoryV2Service',
        'CurrentSession',
        function ($scope, $modal, $timeout, ORDER_STATUS, InventoryV2Service, CurrentSession) {

            $scope.ORDER_STATUS = ORDER_STATUS;

            /* $scope.newlyScanned;
            $scope.stocksReceived = [];

            $scope.onScanned = function (qrCode) {
                var params = {
                    upc: qrCode
                };

                $scope.justScanned = false;

                return InventoryV2Service.searchMenuItem(params)
                    .then($scope.addStockReceived)
                    .catch(function (error) {
                        $scope.onScanFailed(error, qrCode);
                    });
            };

            $scope.addStockReceived = function (menuItem) {
                menuItem.timestamp = new Date();

                $scope.justScanned = true;

                $scope.newlyScanned = {
                    success: true,
                    menuItem: menuItem,
                    timestamp: menuItem.timestamp
                };

                $scope.stocksReceived.push(menuItem);
            };
            $scope.onScanFailed = function (error, qrCode) {
                $scope.justScanned = true;

                $scope.newlyScanned = {
                    success: false,
                    error: error,
                    upc: qrCode,
                    timestamp: new Date()
                };
            };

            var resetScan = function () {
                $scope.justScanned = false;
                $scope.newlyScanned = undefined;
                $scope.stocksReceived.length = 0;
            };

            $scope.receiveStocks = function () {
                return InventoryV2Service.receiveStocks($scope.stocksReceived, $scope.warehouseToReceive)
                    .then($scope.onStocksReceived)
                    .catch($scope.onStocksReceiveFailed);
            };
            $scope.onStocksReceived = function (result) {
                resetScan();

                $scope.$parent.switchView('list');
            };
            $scope.onStocksReceiveFailed = function (error) {
                console.log(error);
            };

            $scope.warehouses = [];
            $scope.warehouseToCreate = {
                name: '',
                description: ''
            };

            $scope.getWarehouses = function () {
                InventoryV2Service.getWarehouses()
                    .then(function (result) {
                        $scope.warehouses = result;
                    });
            };
            $scope.addWarehouse = function (warehouse) {
                InventoryV2Service.addWarehouse(warehouse)
                    .then(function (result) {
                        $scope.getWarehouses();
                        resetWarehouseToCreate();
                    });
            };

            var resetWarehouseToCreate = function () {
                $scope.warehouseToCreate = {
                    name: '',
                    description: ''
                };
            };

            $scope.init = function () {
                $scope.getWarehouses();
            };
            $scope.init(); */

            // var incomingData = {};

            $scope.getIncomingOrders = function () {
                // TODO: add sort by - defaulted to arrival date ASC
                var params = {
                    companyId: CurrentSession.getCompany().companyId
                };

                return InventoryV2Service.getIncomingOrders(params)
                    .then(incomingOrdersReceived)
                    .catch(incomingOrdersReceiveFailed);
            };

            var incomingOrdersReceived = function (response) {
                // incomingData = response;
                $scope.incomingData = response;

                parsePagination(response);
            };

            var incomingOrdersReceiveFailed = function (response) {
                // incomingData = {};
                $scope.incomingData = {};
            };

            $scope.pagination = {
                currentPage: 1,
                totalPages: 1,
                limit: 10
            };
            var resetPagination = function (response) {
                $scope.pagination = {
                    currentPage: 1,
                    totalPages: 1,
                    limit: 10
                };
            };
            var parsePagination = function (response) {
                $scope.pagination = {
                    currentPage: Math.floor(response.offset / response.limit) + 1,
                    totalPages: Math.floor(response.offset / response.limit) + 1,
                    limit: response.limit
                };
            };

            $scope.viewOrder = function (order) {
                var modalInstance = $modal.open({
                    templateUrl: 'inventory/v2/templates/inventory.order.tpl.html',
                    controller: 'InventoryV2OrderCtrl',
                    windowClass: 'inventory-modal modal-90 modal--pull-right',
                    resolve: {
                        order: function () {
                            return order;
                        }
                    }
                });

                modalInstance.result.then(function (response) {
                    $scope.getIncomingOrders();
                });
            };


            $scope.init = function () {
                resetPagination();
                $scope.getIncomingOrders();
            };

            $scope.init();
        }
    ]);
};
