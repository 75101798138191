'use strict';

const CanvasCompress = require('canvas-compress');
const Sortable = require('sortablejs').default;

module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ProductModifierCreateCtrl', [
        '$scope',
        '$modal',
        '$q',
        '$timeout',
        '$translate',
        'ProductsService',
        'modifier',
        'parentItem',
        'currentPriceOverride',
        'isMasterMenu',
        'CompanyAttributesService',
        'PosAlertService',
        'isRootCompany',
        'isCampus',
        'CustomReceiptService',
        'FileUploader',
        function (
            $scope,
            $modal,
            $q,
            $timeout,
            $translate,
            ProductsService,
            modifier,
            parentItem,
            currentPriceOverride,
            isMasterMenu,
            CompanyAttributesService,
            PosAlertService,
            isRootCompany,
            isCampus,
            CustomReceiptService,
            FileUploader) {

            $scope.isMasterMenu = isMasterMenu;
            $scope.hasRetailMenu = CompanyAttributesService.hasRetailMenu();
            $scope.isRootCompany = isRootCompany;
            $scope.isCampus = isCampus;
            $scope.ModifierCreateForm = {};
            $scope.current = {};
            $scope.hideModifierInput = !!modifier;
            var sortable;

            var modifierIndex, overrideIndex, originalReplaceItemPrice;
            var populateInitialModifier = function (modifier) {
                if (_.isEmpty(modifier)) {
                    $scope.modifier = {
                        active: true,
                        printOnAllTypes: true,
                        printOnCustomerReceipt: true,
                        printOnKitchenSheet: true,
                        children: [],
                        minSelection: -1,
                        maxSelection: -1,
                        includes: -1,
                        allowMultipleSelection: false,
                        mobileEnabled: true,
                        posEnabled: true,
                        _index: new Date().valueOf()
                    };
                } else {
                    $scope.modifier = angular.copy(modifier);
                }

                $scope.isNew = !$scope.modifier.locationServicePeriodMenuId;

                modifierIndex = $scope.modifier.locationServicePeriodMenuId || $scope.modifier._index;
                overrideIndex = (currentPriceOverride)
                    ? currentPriceOverride.locationServicePeriodMenuId || currentPriceOverride._index
                    : undefined;

                $scope.modifier.replaceItemPrice = modifierIndex === overrideIndex;
                $scope.overrideItemPriceEnabled = (overrideIndex == undefined)
                    ? true
                    : overrideIndex === modifierIndex;

                originalReplaceItemPrice = $scope.modifier.replaceItemPrice;

                $scope.currentPriceOverride = currentPriceOverride;
                $scope.parentItem = parentItem;

                _.each($scope.modifier.children, function (modifierOption) {
                    setModifierOptionTaxRateName(modifierOption);
                    parseModifierOptionTaxRules(modifierOption);
                });

                $scope.parseCurrentModifierLinkedItems();
            };

            $scope.settings = {
                numSelectionOptions: [-1]
            };
            $scope.taxRule = function (name) {
                return ProductsService.getTaxRule(name);
            };

            var parseModifierOptionTaxRules = function (modifierOption) {
                var taxRules = modifierOption.taxRules;
                _.each(taxRules, function (taxRule, key) {
                    if (taxRules[key] === 'false') {
                        taxRules[key] = false;
                    } else if (taxRules[key] === 'true') {
                        taxRules[key] = true;
                    }
                });
            };

            $scope.addModifierOption = function () {
                var newModifierOption = {
                    isNew: true,
                    name: '',
                    price: undefined,
                    showDetail: true,
                    active: true,
                    taxRules: {},
                    subtype: 'modifier_option'
                };

                setModifierOptionTaxRateName(newModifierOption);

                $scope.modifier.children.push(newModifierOption);

                createUploader(newModifierOption);

                $scope.isAddingNewOption = true;
            };

            $scope.saveModifierOption = function (option) {
                if (!sortable) {
                    intiailizeSortableModifierList();
                }

                if (option.isNew) {
                    $scope.isAddingNewOption = false;
                } else {
                    // new modifier options will upload only on 'save modifier'
                    // only attempt to upload if an image is added
                    if (option.uploadStatus === UploadStatus.PREPARED) {
                        $scope.beginUpload();
                    }
                }


                if (option.imageDeleteConfirmation) {
                    ProductsService.deleteMenuItemImages(option).catch((err) => {
                        PosAlertService.showAlertByName('general', {
                            title: 'product.item.image.delete.error.title',
                            message: err.data.error
                        });
                    });
                    option.uploadStatus = UploadStatus.DELETED;
                }

                option.showDetail = false;
                option.original = {
                    name: option.name,
                    price: option.price
                };
            };

            $scope.removeModifierOption = function (option) {
                if (option.isNew) {
                    $scope.isAddingNewOption = false;
                }

                var optionIndex = $scope.modifier.children.indexOf(option);
                if (optionIndex > -1) {
                    $scope.modifier.children.splice(optionIndex, 1);
                }
            };

            var optionImageUploader = {};
            $scope.toggleOptionShowDetail = function (modifierOption) {
                if ($scope.parentItem._inherited) {
                    return;
                }

                modifierOption.showDetail = (modifierOption.isNew && !modifierOption.hasOwnProperty('showDetail'))? true : !modifierOption.showDetail;

                // updates/reloads selected modifierOption with uploaded image
                if (modifierOption.uploadStatus === UploadStatus.COMPLETED || modifierOption.uploadStatus === UploadStatus.DELETED) {
                    ProductsService.loadMenuItem(modifierOption.locationServicePeriodMenuId).then((response) => {
                        modifierOption.menuItemImages = response.menuItemImages;
                        modifierOption.defaultImage = response.defaultImage;
                    });
                }

                modifierOption.imageDeleteConfirmation = false;
                createUploader(modifierOption);
            };

            const UploadStatus = Object.freeze(
                {
                    'NONE': 1,
                    'PREPARED': 2,
                    'COMPLETED': 3,
                    'ERROR': 4,
                    'DELETED': 5
                }
            );

            var createUploader = function (modifierOption) {
                modifierOption.uploadStatus = UploadStatus.NONE;
                $scope.optionImageUploader = optionImageUploader = createSingleFileUploader('/freshideas/web/inventory/menuItem/', modifierOption);

                optionImageUploader.onAfterAddingFile = function (fileItem, response, status, headers) {
                    // delete compressedImage if necessary
                    // e.g. user selects img1, but decides to select img2 instead.
                    delete modifierOption.compressedImage;
                    $scope.isImageCompressing = true;

                    const compressor = new CanvasCompress({
                        type: CanvasCompress.isSupportedType(fileItem.file.type) ? fileItem.file.type : CanvasCompress.MIME.JPEG,
                        width: 800,
                        height: 800,
                        quality: 0.3,
                    });

                    compressor.process(fileItem._file).then((res) => {
                        modifierOption.compressedImage = res.result;
                        modifierOption.compressedImage.src = URL.createObjectURL(res.result.blob);
                        modifierOption.fileName = fileItem._file.name;
                        modifierOption.uploadStatus = UploadStatus.PREPARED;
                        $scope.isImageCompressing = false;
                    });

                    if (modifierOption.isNew) {
                        // saving index of new option & index of item in queue.
                        // later when saving modifier, it can check for image uploads
                        var optionIndex = $scope.modifier.children.length - 1;
                        $scope.requiresImageUpload[optionIndex] = (optionImageUploader.queue.length - 1);
                    }
                };
                optionImageUploader.onCompleteAll = function (fileItem, response, status, headers) {
                    modifierOption.uploadStatus = UploadStatus.COMPLETED;
                };
                optionImageUploader.onBeforeUploadItem = function (item) {
                    // Upload compressed blob instead of original file.
                    item._file = modifierOption.compressedImage.blob;

                    let imageId = '';
                    if (modifierOption.defaultImage) {
                        imageId = modifierOption.defaultImage.menuItemImageId;
                    }

                    if (!modifierOption.isNew) {
                        item.formData.push({
                            id: modifierOption.locationServicePeriodMenuId,
                            imageId: imageId,
                            name: modifierOption.name,
                        });
                    }

                };
                optionImageUploader.onErrorItem = function (fileItem, response, status, headers) {
                    // 413: Entity too large
                    if (status == 413) {
                        PosAlertService.showAlertByName('file-upload-failed-large-file');
                    } else if (response.exception.appCode === 454 || response.exception.appCode === 455 || response.exception.appCode === 456) {
                        PosAlertService.showAlertByName('general', {
                            title: 'product.item.image.upload.error.title',
                            message: response.exception.message
                        });
                    } else {
                        PosAlertService.showAlertByName('file-upload-failed');
                    }
                };
            };

            $scope.deleteModifierOptionImage = function (option) {
                PosAlertService.showAlertByName('general-alert', {
                    title: $translate.instant('product.item.image.delete.title'),
                    message: $translate.instant('product.item.image.delete.confirmation'),
                    modalCallback: function () {
                        return option.imageDeleteConfirmation = true;
                    }
                });
            };

            $scope.selectModifierOptionTaxRate = function (modifierOption) {
                var attributes = [];

                var modalAttribute = {
                    identifier: 'taxRateFid',
                    name: 'product.item.tax.label',
                    options: ProductsService.taxRates,
                    optionName: 'taxRateName',
                    optionIdentifier: 'taxRateFid',
                };

                attributes.push(modalAttribute);

                if (ProductsService.isTaxRuleEnabled('CA_ON_PREPARED_GOODS')) {
                    attributes.push({
                        identifier: 'taxRules.' + ProductsService.getTaxRule('CA_ON_PREPARED_GOODS').name,
                        name: 'taxRule.ca_on_prepared_goods.label',
                        description: 'taxRule.ca_on_prepared_goods.description',
                        options: 'toggle'
                    });
                }

                var modalInstance = $modal.open({
                    templateUrl: 'products/templates/general.list.tpl.html',
                    controller: 'ProductGeneralListCtrl',
                    windowClass: 'modal-50 products2',
                    animation: true,
                    // backdrop: 'static'
                    resolve: {
                        title: function () {
                            return 'product.item.tax.action';
                        },
                        entity: function () {
                            return modifierOption;
                        },
                        attributes: function () {
                            return attributes;
                        }
                    }
                });

                modalInstance.result.finally(function () {
                    setModifierOptionTaxRateName(modifierOption);
                });
            };

            var setModifierOptionTaxRateName = function (modifierOption) {
                var identifierObj = {
                    taxRateFid: modifierOption.taxRateFid || ProductsService.taxRateFidsConsts.DEFAULT
                };

                var foundTaxRate = _.findWhere(ProductsService.taxRates, identifierObj);

                if (foundTaxRate) {
                    modifierOption.taxRateId = null;
                    modifierOption.taxRateName = foundTaxRate.taxRateName;
                    modifierOption.taxRateFid = foundTaxRate.taxRateFid;
                }
            };


            $scope.forceMaxOneSelection = function (newValue) {
                if (newValue) {
                    populateNumSelectionOptions(1);

                    $scope.modifier.allowMultipleSelection = false;
                    $scope.modifier.minSelection = Math.min(1, $scope.modifier.minSelection);
                    $scope.modifier.maxSelection = Math.min(1, $scope.modifier.maxSelection);
                } else {
                    populateNumSelectionOptions();
                }
            };

            var highlightInputsFn;
            var runHighlightInputs = function () {
                if (highlightInputsFn) {
                    return;
                }

                $scope.highlightInputs = true;
                highlightInputsFn = $timeout(function () {
                    $scope.highlightInputs = false;
                    highlightInputsFn = undefined;
                }, 1000);
            };
            var isModifierValid = function () {
                if (!$scope.ModifierCreateForm.$valid) {
                    runHighlightInputs();
                    return false;
                }

                return true;
            };

            $scope.saveModifier = function () {
                if (!isModifierValid()) {
                    return;
                }
                $scope.modifier = CustomReceiptService.prepareReceiptPrintOptions($scope.modifier);
                var isParentItemNew = !parentItem.locationServicePeriodMenuId;
                if (isParentItemNew) {
                    saveModifierToNewItem();
                } else {
                    saveModifierToExistingItem();
                }
            };

            var saveModifierToExistingItem = function () {
                var sanitizedModifier = sanitizeModifier($scope.modifier);
                if (sanitizedModifier.replaceItemPrice) {
                    parentItem.priceReplacedBy = sanitizedModifier;
                } else {
                    if (modifierIndex === overrideIndex) {
                        parentItem.priceReplacedBy = undefined;
                    }
                }

                var isModifierNew = !sanitizedModifier.locationServicePeriodMenuId;
                if (isModifierNew) {
                    addModifierToExistingItem(sanitizedModifier);
                } else {
                    editModifierOnExistingItem(sanitizedModifier);
                }
            };
            var addModifierToExistingItem = function (sanitizedModifier) {
                return ProductsService
                    .addModifier(sanitizedModifier, parentItem)
                    .then(function (modifierSaved) {
                        if (isItemPriceReplacedByChanged()) {
                            var replacedBy = (sanitizedModifier.replaceItemPrice)? modifierSaved : undefined;
                            return ProductsService.editMenuItemPriceReplacedBy(parentItem, replacedBy);
                        } else {
                            var deferred = $q.defer();
                            deferred.resolve();

                            return deferred.promise;
                        }
                    })
                    .then(function () {
                        return ProductsService.loadMenuItem(parentItem.locationServicePeriodMenuId);
                    })
                    .then(function (updatedParentItem) {
                        $scope.hideStatusIndicator();
                        updatedParentItem.children = CustomReceiptService.processReceiptPrintFilters(updatedParentItem.children);
                        $scope.$close({
                            parentItem: updatedParentItem
                        });
                    })
                    .catch(function () {
                        $scope.hideStatusIndicator();
                    });
            };
            var editModifierOnExistingItem = function (sanitizedModifier) {
                return ProductsService
                    .editModifier(sanitizedModifier, parentItem)
                    .then(function (modifierSaved) {
                        if (isItemPriceReplacedByChanged()) {
                            var replacedBy = (sanitizedModifier.replaceItemPrice)? modifierSaved : undefined;
                            return ProductsService.editMenuItemPriceReplacedBy(parentItem, replacedBy);
                        } else {
                            var deferred = $q.defer();
                            deferred.resolve();

                            return deferred.promise;
                        }
                    })
                    .then(function () {
                        return ProductsService.loadMenuItem(parentItem.locationServicePeriodMenuId);
                    })
                    .then(function (updatedParentItem) {
                        var modifiers = updatedParentItem.children;
                        modifiers = CustomReceiptService.processReceiptPrintFilters(modifiers);

                        var activeModifierCategory = _.find(modifiers, (modifier) => {
                            return modifier.locationServicePeriodMenuId === $scope.modifier.locationServicePeriodMenuId;
                        });

                        /*
                            Due to new modifier options not having an ID until 'save modifier' is hit,
                            images for options can only be uploaded here.
                        */

                       if (activeModifierCategory) {
                           updateModifierOptionUploadInfo(activeModifierCategory);
                       }

                        $scope.$close({
                            parentItem: updatedParentItem
                        });
                    });
            };

            var updateModifierOptionUploadInfo = function (modifierCategory) {
                if (Object.keys($scope.requiresImageUpload).length > 0) {
                    Object.keys($scope.requiresImageUpload).forEach(function (optionIndex) {
                        var queueIndex = $scope.requiresImageUpload[optionIndex];
                        var id = modifierCategory.children[optionIndex].locationServicePeriodMenuId;
                        if (id) {
                            optionImageUploader.url = '/freshideas/web/inventory/menuItem/' + id + '/upload/images/';
                            optionImageUploader.queue[queueIndex].formData.push({
                                id: id,
                                name: modifierCategory.children[optionIndex].name
                            });
                            optionImageUploader.queue[queueIndex].uploader.url = '/freshideas/web/inventory/menuItem/' + id + '/upload/images/';
                            optionImageUploader.uploadItem(queueIndex);
                        }
                    });
                }
            };

            var saveModifierToNewItem = function () {
                saveSelectedModifierToNewItem($scope.modifier);
            };
            var saveSelectedModifierToNewItem = function (selectedModifier) {
                var sanitizedModifier = sanitizeModifier(selectedModifier);
                if (sanitizedModifier.replaceItemPrice) {
                    parentItem.priceReplacedBy = sanitizedModifier;
                } else {
                    if (modifierIndex === overrideIndex) {
                        parentItem.priceReplacedBy = undefined;
                    }
                }

                $scope.$close({
                    modifier: sanitizedModifier,
                    parentItem: parentItem
                });
            };

            var attachModifierToExistingItem = function () {
                var sanitizedModifier = sanitizeModifier($scope.modifier);
                return ProductsService
                        .attachModifier([sanitizedModifier], parentItem)
                        .then(function () {
                            $scope.hideStatusIndicator();
                            $scope.$close({
                                modifier: sanitizedModifier,
                                parentItem: parentItem
                            });
                        })
                        .catch(function () {
                            $scope.hideStatusIndicator();
                        });
            };

            var isItemPriceReplacedByChanged = function () {
                return originalReplaceItemPrice !== $scope.modifier.replaceItemPrice;
            };

            var sanitizeModifier = function (modifierToSanitize) {
                var sanitized = angular.copy(modifierToSanitize);
                delete sanitized.isNew;

                if (sanitized.selectionIncluded && sanitized.allowMultipleSelection) {
                    sanitized.includes = 1;
                    delete sanitized.selectionIncluded;
                }

                // `requireSelection` is not an actual item attribute - it is determinend by
                // `minSelection`. This block ensures the `requireSelection` setting is
                // correctly reflected to `minSelection`
                if (sanitized.requireSelection && sanitized.minSelection < 1) {
                    // if the item requires selection, internally ensures the minSelection if at least 1
                    sanitized.minSelection = 1;
                } else if (!sanitized.requireSelection && !sanitized.allowMultipleSelection) {
                    // if the item does not require selection, and that it has not been explicitly
                    // defined (ie. if `allowMultipleSelection` is off), ensure minSelection is -1
                    sanitized.minSelection = -1;
                }

                if (!sanitized.allowMultipleSelection) {
                    sanitized.maxSelection = Math.min(sanitized.maxSelection, 1);
                    sanitized.minSelection = Math.min(sanitized.minSelection, 1);
                }

                _.each(sanitized.children, function (option) {
                    delete option.isNew;
                    delete option.showDetail;
                    option.mobileEnabled = sanitized.mobileEnabled;
                });

                delete sanitized.linkedItems;
                delete sanitized.linkedItemName;
                delete sanitized.linkedItemMap;

                return sanitized;
            };

            var populateNumSelectionOptions = function (max = 15) {
                $scope.settings.numSelectionOptions = [-1];
                for (var i = 1; i <= max; i++) {
                    $scope.settings.numSelectionOptions.push(i);
                }
            };

            $scope.transformModifierInput = function (tag) {
                var newModifier = {
                    name: tag,
                    active: true,
                    children: $scope.modifier.children,
                    minSelection: $scope.modifier.minSelection,
                    maxSelection: $scope.modifier.maxSelection,
                    includes: $scope.modifier.includes,
                    allowMultipleSelection: $scope.modifier.allowMultipleSelection,
                    _index: new Date().valueOf()
                };

                return newModifier;
            };
            $scope.formatModifierSelectOption = function (modifierSelectOption) {
                var modifierName = modifierSelectOption.name;
                var description = '';

                if (modifierSelectOption.locationServicePeriodMenuId) {
                    var linkedItems = modifierSelectOption.linkedItems || [];
                    var linkedItemDescription = (linkedItems.length === 1)? 'product.modifier.linkedItem.count' : 'product.modifier.linkedItem.counts';
                    linkedItemDescription = $translate.instant(linkedItemDescription, {
                        count: linkedItems.length
                    });
                    description = '<span class="ui-select-choice--secondary">' + linkedItemDescription + ' (' + modifierSelectOption.linkedItemName + ')</span>';
                } else {
                    description = '(New Description)';
                }

                return (description)? modifierName + ' - ' + description : modifierName;
            };
            $scope.parseCurrentModifierLinkedItems = function () {
                var modifierId = $scope.modifier.locationServicePeriodMenuId;

                if (modifierId) {
                    var foundModifier = _.findWhere($scope.existingModifiers, {locationServicePeriodMenuId: modifierId});

                    if (foundModifier) {
                        $scope.linkedItems = _.filter(foundModifier.linkedItems, function (linkedItem) {
                            return linkedItem.locationServicePeriodMenuId != parentItem.locationServicePeriodMenuId;
                        });
                    }
                }
            };

            $scope.selectExistingModifier = function (modifier, $select) {
                if (modifier.locationServicePeriodMenuId) {
                    reuseModifier(modifier);
                }
            };

            var reuseModifier = function (reusedModifier) {
                var modalInstance = $modal.open({
                    templateUrl: 'products/templates/product.modifier.reuse.tpl.html',
                    controller: 'ProductModifierReuseCtrl',
                    windowClass: 'modal-50 products2',
                    animation: false,
                    // backdrop: 'static'
                    resolve: {
                        modifier: function () {
                            return reusedModifier;
                        }
                    }
                });

                modalInstance.result.then(function (response) {
                    var modifierToSave = angular.copy(reusedModifier);
                    if (response.action === 'duplicate') {
                        // _index is an alternative identifier used to uniquely identify a modifier
                        // that has not been saved to the database. This should be set when duplicating
                        // a modifier because it is essentially creating a new modifier.
                        modifierToSave._index = new Date().valueOf();

                        $scope.showStatusIndicator(STATUS.DUPLICATING);
                        $scope.current.modifier = undefined;

                        delete modifierToSave.locationServicePeriodMenuId;
                        delete modifierToSave.linkedItems;
                        delete modifierToSave.linkedItemName;
                        delete modifierToSave.linkedItemMap;

                        _.each(modifierToSave.children, function (child) {
                            delete child.locationServicePeriodMenuId;
                        });

                        $scope.populate(modifierToSave);

                        if (parentItem.locationServicePeriodMenuId) {
                            saveModifierToExistingItem();
                        } else {
                            saveModifierToNewItem();
                        }
                    } else if (response.action === 'attach') {
                        $scope.showStatusIndicator(STATUS.ATTACHING);
                        $scope.current.modifier = undefined;
                        $scope.populate(modifierToSave);

                        if (parentItem.locationServicePeriodMenuId) {
                            attachModifierToExistingItem();
                        } else {
                            saveModifierToNewItem();
                        }
                    }
                }, function (error) {
                    $scope.current.modifier = undefined;
                });
            };

            $scope.unlinkModifier = function () {
                var unlinkedModifier = angular.copy($scope.modifier);
                unlinkedModifier._index = new Date().valueOf();

                delete unlinkedModifier.locationServicePeriodMenuId;
                delete unlinkedModifier.linkedItems;
                delete unlinkedModifier.linkedItemName;

                $scope.populate(unlinkedModifier);
            };

            $scope.populate = function (modifierToPopulate) {
                populateInitialModifier(modifierToPopulate);

                _.each($scope.modifier.children, function (modifierOption) {
                    modifierOption.original = {
                        name: modifierOption.name,
                        price: modifierOption.price
                    };
                });

                if (!$scope.modifier.allowMultipleSelection && $scope.modifier.minSelection > 0) {
                    $scope.modifier.requireSelection = true;
                }

                populateNumSelectionOptions();
            };


            var STATUS = $scope.STATUS = {
                DUPLICATING: 'duplicating',
                ATTACHING: 'attaching'
            };
            $scope.showStatusIndicator = function (status) {
                $scope.showMask = true;
                $scope.status = status;
                $scope.isSavingItem = true;
            };
            $scope.hideStatusIndicator = function () {
                $scope.showMask = false;
                $scope.status = '';
                $scope.isSavingItem = false;
            };

            var checkIfAllReceiptsToggledOn = function (type) {
                switch (type) {
                    case 'customer':
                        if ($scope.modifier.printOnKitchenSheet) {
                            $scope.modifier.printOnAllTypes = true;
                        }
                        break;
                    case 'kitchen':
                        if ($scope.modifier.printOnCustomerReceipt) {
                            $scope.modifier.printOnAllTypes = true;
                        }
                        break;
                }
            };

            $scope.printTypeToggled = function (type, enabled) {
                if (enabled) {
                    checkIfAllReceiptsToggledOn(type);
                } else {
                    if (type === 'customer') {
                        var foundPrice = $scope.modifier.children.find(function (modifierOption) {
                            return modifierOption.price;
                        });
                        if (foundPrice) {
                            PosAlertService.showAlertByName('general', {
                                title: 'pos.products.modifier.receipt.cannot.hide.title',
                                message: 'pos.products.modifier.receipt.cannot.hide.description'
                            });
                            // cancel locationToggle directive
                            return true;
                        }
                    }
                }
            };

            $scope.isHiddenOnCustomerReceipt = function () {
                return !$scope.modifier.printOnCustomerReceipt || !$scope.parentItem.printOnCustomerReceipt;
            };

            $scope.showReceiptErrorIfHidden = function () {
                if (!$scope.modifier.printOnCustomerReceipt || !$scope.parentItem.printOnCustomerReceipt) {
                    PosAlertService.showAlertByName('general', {
                        title: 'pos.products.modifier.receipt.cannot.set.price.title',
                        message: 'pos.products.modifier.receipt.cannot.set.price.description'
                    });
                }
            };

            function intiailizeSortableModifierList () {
                if ($scope.parentItem._inherited || sortable) {
                    return;
                }

                var el = document.getElementById('modifier-group-list');
                if (!el) {
                    return;
                }

                sortable = Sortable.create(el, {
                    handle: '.mod-drag-handle',
                    onMove: function (event, origEvent) {
                        return true;
                    },
                    onStart: function (event) {
                    },
                    onEnd: function (event) {
                        var modifiers = $scope.modifier.children;
                        var mod1 = modifiers[event.oldIndex];
                        var mod2 = modifiers[event.newIndex];

                        // Ensure the new index is valid for sorting (ie. 0 or larger)
                        if (mod2.currentCategoryIndex >= 0) {
                            var minCategoryIndex = 0;
                            modifiers.splice(event.oldIndex, 1);
                            modifiers.splice(event.newIndex, 0, mod1);

                            for (var i = 0; i < modifiers.length; i++) {
                                if (modifiers[i].currentCategoryIndex >= 0) {
                                    modifiers[i].currentCategoryIndex = minCategoryIndex;
                                    minCategoryIndex++;
                                }
                            }
                        }
                    },
                });

                $scope.$on('$destroy', function () {
                    sortable.destroy();
                });
            }

            $scope.removeModifierThirdPartyPrice = function (modifierOption) {
                modifierOption.third_party_price = undefined;
            };

            $scope.init = function () {
                $scope.populate(modifier);
                $scope.requiresImageUpload = {};

                // used to temporarily disable image uploads on iPad due to lack of camera permission
                $scope.platform = navigator.platform;
            };

            $scope.existingModifiers = ProductsService.productModifiers;
            $scope.init();
            // Using a timeout here because we want to ensure that the view is fully rendered before
            // binding to a UI element inside the called function `intiailizeSortableModifierList`
            $timeout(() => {
                intiailizeSortableModifierList();
            }, 1000);

            $scope.beginUpload = function () {
                optionImageUploader.uploadAll();
            };

            function createSingleFileUploader (url, option) {
                var uploaderConfig = {};
                uploaderConfig.url = url + option.locationServicePeriodMenuId + '/upload/images/';

                var uploader = new FileUploader(uploaderConfig);
                uploader.filters.push({
                    name: 'imageFilter',
                    fn: function (item /* {File|FileLikeObject} */, options) {
                        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                        return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
                    }
                });
                uploader.onAfterAddingFile = function (fileItem) {
                    // The banner uploader should be accepting only 1 file. However, there does not seem to be a way
                    // to remove or override the existing file, and `queueLimit` of 1 simply throws an error when there
                    // is already a file. This line ensures that only 1 file is chosen for upload.
                    this.queue = [this.queue[this.queue.length - 1]];
                };
                uploader.onAfterAddingAll = function (addedFileItems) {
                };
                return uploader;
            }

        }])
        .value('modifier', undefined)
        .value('parentItem', undefined)
        .value('currentPriceOverride', undefined);
};
