import * as angular from 'angular';
import * as moment from 'moment';

const freshideasPatronsGrow = angular.module('freshideas.customersGrow', [])
    .controller('CustomersGrowCtrl',
        ['$scope',
        'PosAlertService',
        'Organization',
        'CurrentSession',
        '$timeout',
        '$translate',
        'CompanyAttributesService',
        function CustomersGrowCtrl (
            $scope,
            PosAlertService,
            Organization,
            CurrentSession,
            $timeout,
            $translate,
            CompanyAttributesService) {

        $scope.newCustomersSince = '';
        $scope.loyaltyRedemptionPerDollar = CurrentSession.getCompany().organization.settings.loyaltyRedemptionPerDollar | 0;
        $scope.wereSettingsUpdated = false;
        $scope.welcomeOfferActivationCount = 0;
        $scope.birthdayOfferActivationCount = 0;

        const DEFAULT_BIRTHDAY_OFFER_DURATION = 7; // days

        const settingsUpdated = () => {
            $scope.wereSettingsUpdated = true;
            $timeout(() => {
                $scope.wereSettingsUpdated = false;
            }, 2000);
        };

        const INTERNAL_OFFER_NAMES = Object.freeze({
            WELCOME_OFFER: 'welcome_offer',
            BIRTHDAY_OFFER: 'birthday_offer'
        });

        const CUSTOMER_USAGE_LIMITS = Object.freeze({
            WELCOME_OFFER: 1,
            BIRTHDAY_OFFER: 1,
        });

        const OFFER_TYPES = $scope.OFFER_TYPES = Object.freeze({
            EXTRA_POINTS: 'EXTRA_POINTS',
            POINTS_MULTIPLIER: 'POINTS_MULTIPLIER',
            PERCENT_DISCOUNT: 'PERCENT_DISCOUNT', // Corresponding value is a discount percent (0 - 100)
            FREE_ITEM: 'FREE_ITEM', // Corresponding value is an item ID (Long type validation)
            AMOUNT_DISCOUNT_CENTS: 'AMOUNT_DISCOUNT_CENTS'
        });

        $scope.welcomeOfferPageOpen = false;
        $scope.closeWelcomeOfferPage = () => {
            $scope.welcomeOfferPageOpen = false;
            setWelcomeOfferPageInitialSettings();
        };

        $scope.birthdayOfferPageOpen = false;
        $scope.closeBirthdayOfferPage = () => {
            $scope.birthdayOfferPageOpen = false;
            setBirthdayOfferPageInitialSettings();
        };

        const setWelcomeOfferPageInitialSettings = () => {
            // Here we are deep cloning the object (this method works because we do not expect to have
            // Dates, functions, undefined, regExp or Infinity within this object), in the future we
            // should change this with spread operater, currently it's not well-supported for cloning objects.
            if (!$scope.initialWelcomeOfferSettings
                || !Object.getOwnPropertyNames($scope.initialWelcomeOfferSettings).length) {
                PosAlertService.showAlertByName('welcome-offer-load-unsuccessful');
                $scope.welcomeOfferPageOpen = false;
            } else {
                $scope.welcomeOfferSettings = JSON.parse(JSON.stringify($scope.initialWelcomeOfferSettings));
                // We convert the valueOffer from string to number to be able to create a 2 way data binding with input type number
                $scope.welcomeOfferSettings.offerValue = +($scope.welcomeOfferSettings.offerValue);
                $scope.offerAvailabilityPeriod = $scope.welcomeOfferSettings.offerAvailabilityPeriod.length?
                    $scope.welcomeOfferSettings.offerAvailabilityPeriod[0] : [];
                getOfferLocalDateDetails();
            }
        };

        $scope.openWelcomeOfferPage = () => {
            const params = {};
            params.requestedPermission = 'settings';
            params.callback = (pinResponse) => {
                $scope.welcomeOfferPageOpen = true;
                setWelcomeOfferPageInitialSettings();
                $scope.offerAvailabilityPeriod.endDateTime?
                    $scope.welcomeOfferEndDateOptions = true: null;
            };

            params.errorCallback = (error) => {
                if (error) {
                    const exception = error.exception || {};
                    if (exception.appCode === 412) {
                        PosAlertService.showAlertByName('manager-pin-invalid', {
                            title: 'general.error.tiles.welcomeOffer.manager-pin.ttl'
                        });
                    } else {
                        PosAlertService.showAlertByName('manager-pin-invalid', {
                            title: 'general.error.tiles.welcomeOffer.manager-pin.ttl',
                            message: exception.message || ''
                        });
                    }
                }
            };
            params.verifyAllUserPinsForCompany = true;
            params.forceCheckIfManagerLogin = true;
            params.message = 'general.popup.manager-pin.ttl';
            $scope.$emit('PincodeAuthentication:Required', params);
        };

        const setBirthdayOfferPageInitialSettings = () => {
            let durationDays = DEFAULT_BIRTHDAY_OFFER_DURATION;
            let offerValue = 0;

            if (!$scope.initialBirthdayOfferSettings || !Object.keys($scope.initialBirthdayOfferSettings).length) {
                $scope.initialBirthdayOfferSettings = {
                    enabled: false,
                    minTransactionCount: 1,
                    offerType: OFFER_TYPES.EXTRA_POINTS,
                    name: $translate.instant('patrons.grow.page.birthdayOfferFormTitle') || 'Happy Birthday!',
                    description: $translate.instant('patrons.grow.page.birthdayOfferFormDesc') || '',
                    offerAvailabilityPeriod: [{}], // this will help avoid extensive null checks when creating the payload
                };
            } else {
                offerValue = Number($scope.initialBirthdayOfferSettings.offerValue);

                const offerAvailabilityPeriod = $scope.initialBirthdayOfferSettings.offerAvailabilityPeriod;
                if (offerAvailabilityPeriod && offerAvailabilityPeriod.length == 1 && offerAvailabilityPeriod[0].durationHours) {
                    durationDays = offerAvailabilityPeriod[0].durationHours / 24;
                }
            }

            $scope.birthdayOfferSettings = Object.assign($scope.initialBirthdayOfferSettings, {offerValue, durationDays});
        };

        $scope.openBirthdayOfferPage = () => {
            if (!CompanyAttributesService.allowBirthdayRewards()) {
                PosAlertService.showAlertByName('general', {
                    title: 'general.error.tiles.birthdayOffer.unavailable',
                    message: 'setupWizard.merchant.linkToFiitMps.lucovaAlerts.contactSupport'
                });
                return;
            }

            const params = {};
            params.requestedPermission = 'settings';
            params.callback = () => {
                $scope.birthdayOfferPageOpen = true;
                setBirthdayOfferPageInitialSettings();
            };

            params.errorCallback = (error) => {
                if (error) {
                    const exception = error.exception || {};
                    PosAlertService.showAlertByName('manager-pin-invalid', {
                        message: exception.message || ''
                    });
                }
            };

            params.verifyAllUserPinsForCompany = true;
            params.forceCheckIfManagerLogin = true;
            params.message = 'general.popup.manager-pin.ttl';
            $scope.$emit('PincodeAuthentication:Required', params);
        };

        const getOfferUnixDateDetails = () => {
            const formattedStartDate = moment($scope.welcomeOfferStartDate).format('YYYY-MM-DD');
            const formattedStartHour = moment($scope.welcomeOfferStartHour, 'hh:mm A').format('HH:mm:ss');
            const formattedEndDate = moment($scope.welcomeOfferEndDate).format('YYYY-MM-DD');
            const formattedEndHour = moment($scope.welcomeOfferEndHour, 'hh:mm A').format('HH:mm:ss');
            $scope.offerAvailabilityPeriod.startDateTime = moment(formattedStartDate+' '+formattedStartHour).unix();
            $scope.offerAvailabilityPeriod.endDateTime = moment(formattedEndDate+' '+formattedEndHour).unix();
        };

        $scope.saveWelcomeOfferDetails = () => {
            getOfferUnixDateDetails();
            let welcomeOfferSettingsParams = {};
            if ($scope.offerAvailabilityPeriod.id) {
                welcomeOfferSettingsParams = {
                    id: $scope.welcomeOfferSettings.id,
                    name: $scope.welcomeOfferSettings.name,
                    description: $scope.welcomeOfferSettings.description,
                    offerType: $scope.welcomeOfferSettings.offerType,
                    offerValue: $scope.welcomeOfferSettings.offerValue,
                    enabled: $scope.welcomeOfferSettings.enabled,
                    offerAvailabilityPeriod: [{
                        id: $scope.offerAvailabilityPeriod.id,
                        offerId: $scope.welcomeOfferSettings.id,
                        endDateTime: $scope.welcomeOfferEndDateOptions? $scope.offerAvailabilityPeriod.endDateTime: null,
                        startDateTime: $scope.offerAvailabilityPeriod.startDateTime
                    }]
                };
            } else {
                welcomeOfferSettingsParams = {
                    id: $scope.welcomeOfferSettings.id,
                    name: $scope.welcomeOfferSettings.name,
                    description: $scope.welcomeOfferSettings.description,
                    offerType: $scope.welcomeOfferSettings.offerType,
                    offerValue: $scope.welcomeOfferSettings.offerValue,
                    enabled: $scope.welcomeOfferSettings.enabled,
                    offerAvailabilityPeriod: [{
                        offerId: $scope.welcomeOfferSettings.id,
                        endDateTime: $scope.welcomeOfferEndDateOptions? $scope.offerAvailabilityPeriod.endDateTime: null,
                        startDateTime: $scope.offerAvailabilityPeriod.startDateTime
                    }]
                };
            }

            if (!($scope.welcomeOfferSettings.offerValue > 0)) {
                // offer value should be more than zero
                PosAlertService.showAlertByName('welcome-offer-settings-invalid-value');
            } else if (!$scope.offerAvailabilityPeriod.id
                        && $scope.offerAvailabilityPeriod.startDateTime < moment().unix()) {
                // startDate unix timestamp should not be in the past if the time is created for the first time
                PosAlertService.showAlertByName('welcome-offer-settings-invalid-startDate');
            } else if ($scope.welcomeOfferEndDateOptions
                        && ($scope.offerAvailabilityPeriod.startDateTime > $scope.offerAvailabilityPeriod.endDateTime)) {
                // startDate unix timestamp should not be bigger than endDate unix timestamp
                PosAlertService.showAlertByName('welcome-offer-settings-invalid-endDate');
            } else if ($scope.welcomeOfferSettings.name.trim() === ''
                        || $scope.welcomeOfferSettings.description.trim === '') {
                // offer title and description should not be an empty string
                PosAlertService.showAlertByName('welcome-offer-settings-invalid-text');
            } else {
                Organization.updateOrCreateOffer(welcomeOfferSettingsParams,
                    (res) => {
                        $scope.initialWelcomeOfferSettings = res;
                        setWelcomeOfferPageInitialSettings();
                        settingsUpdated();
                    },
                    (err) => {
                        setWelcomeOfferPageInitialSettings();
                        PosAlertService.showAlertByName('welcome-offer-settings-unsuccessful');
                    });
            }
        };

        $scope.saveBirthdayOfferDetails = () => {
            const autoApply = $scope.birthdayOfferSettings.offerType === OFFER_TYPES.EXTRA_POINTS
                || $scope.birthdayOfferSettings.offerType === OFFER_TYPES.POINTS_MULTIPLIER;

            const birthdayOfferSettingsParams = {
                id: $scope.birthdayOfferSettings.id,
                internalName: INTERNAL_OFFER_NAMES.BIRTHDAY_OFFER,
                name: $scope.birthdayOfferSettings.name,
                description: $scope.birthdayOfferSettings.description,
                offerType: $scope.birthdayOfferSettings.offerType,
                offerValue: $scope.birthdayOfferSettings.offerValue,
                enabled: $scope.birthdayOfferSettings.enabled,
                customerUsageLimit: CUSTOMER_USAGE_LIMITS.BIRTHDAY_OFFER,
                isAvailableYearly: true,
                minTransactionCount: $scope.birthdayOfferSettings.minTransactionCount,
                autoApply,
                offerAvailabilityPeriod: [{
                    id: $scope.birthdayOfferSettings.offerAvailabilityPeriod[0].id,
                    offerId: $scope.birthdayOfferSettings.id,
                    startDateTime: moment().unix(),
                    durationHours: $scope.birthdayOfferSettings.durationDays * 24
                }]
            };

            const errorMessage = isBirthdayOfferValid(birthdayOfferSettingsParams);
            if (errorMessage) {
                PosAlertService.showAlertByName('general', {
                    message: errorMessage
                });
                return;
            }

            Organization.updateOrCreateOffer(birthdayOfferSettingsParams,
                (res) => {
                    $scope.initialBirthdayOfferSettings = res;
                    setBirthdayOfferPageInitialSettings();
                    settingsUpdated();
                },
                (err) => {
                    setBirthdayOfferPageInitialSettings();
                    PosAlertService.showAlertByName('general', {
                        message: err.data.error || ''
                    });
                });
        };

        const isBirthdayOfferValid = (birthdayOfferSettingsParams) => {
            let errorMessage = false;

            if (!birthdayOfferSettingsParams.name || !birthdayOfferSettingsParams.description) {
                errorMessage = 'general.error.tiles.birthdayOffer.emptyText';
            }

            if (!birthdayOfferSettingsParams.minTransactionCount || !Number.isInteger(birthdayOfferSettingsParams.minTransactionCount)) {
                errorMessage = 'general.error.tiles.birthdayOffer.minTransactionCount';
            }

            if (!birthdayOfferSettingsParams.offerType) {
                errorMessage = 'general.error.tiles.birthdayOffer.offerType';
            }

            if (!birthdayOfferSettingsParams.offerValue) {
                errorMessage = 'general.error.tiles.birthdayOffer.offerValue';
            }

            if (!birthdayOfferSettingsParams.offerAvailabilityPeriod[0].durationHours) {
                errorMessage = 'general.error.tiles.birthdayOffer.duration';
            }

            return errorMessage;
        };

        // Date Picker options/settings
        $scope.datePickerOpened = {};
        $scope.toggleFromDatePicker = ($event) => {
            $event.preventDefault();
            $event.stopPropagation();
            const status = !!$scope.datePickerOpened.from;
            const newStatus = !status;
            $scope.datePickerOpened.from = newStatus;
            if (newStatus) {
                $scope.datePickerOpened.to = false;
            }
        };
        $scope.toggleToDatePicker = ($event) => {
            $event.preventDefault();
            $event.stopPropagation();
            const status = !!$scope.datePickerOpened.to;
            const newStatus = !status;
            $scope.datePickerOpened.to = newStatus;
            if (newStatus) {
                $scope.datePickerOpened.from = false;
            }
        };
        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        const getHoursOfDay = () => {
            let hoursPerDay = 48;
            let time = [];
            for (let i = 0; i < hoursPerDay; i++) {
                if ( !(i%2) ) {
                    time.push(moment(i/2, 'hh:mm').format('hh:mm A'));
                } else {
                    time.push(moment(Math.floor(i/2), 'hh:mm').add(30, 'minutes').format('hh:mm A'));
                }
            }
            return time;
        };

        // we convert the unix timestamp to local date and hour format
        // that we need for a location/company. we convert the local date and
        // time to unix timestamp once again when we want to send them to the back-end
        const getOfferLocalDateDetails = () => {
            if ( $scope.offerAvailabilityPeriod.startDateTime ) {
                const unixStartDate = $scope.offerAvailabilityPeriod.startDateTime;
                $scope.welcomeOfferStartDate = moment(unixStartDate).local().format('YYYY/MM/DD');
                $scope.welcomeOfferStartHour = moment(unixStartDate).local().format('hh:mm A');
            } else {
                $scope.welcomeOfferStartDate = $scope.getInitialDate();
                $scope.welcomeOfferStartHour = $scope.getInitialRoundedHour();
            }

            if ( $scope.offerAvailabilityPeriod.endDateTime ) {
                const unixEndDate = $scope.offerAvailabilityPeriod.endDateTime;
                $scope.welcomeOfferEndDate = moment(unixEndDate).local().format('YYYY/MM/DD');
                $scope.welcomeOfferEndHour = moment(unixEndDate).local().format('hh:mm A');
            } else {
                $scope.welcomeOfferEndDate = $scope.threeMonthFromNow;
                $scope.welcomeOfferEndHour = $scope.hoursOfDay[0];
            }
        };

        $scope.hoursOfDay = getHoursOfDay();
        // We want to round the initial date and time of the offer to the closest half
        // hour to be able to create a 2 way data binding with select hour menu so if the
        // time is bigger that 11:30 pm we should go to the next day, else the date will be today's date.
        $scope.getInitialDate = () => {
            const currentHour = moment().hour();
            const currentMinutes = moment().minute();
            if (currentHour >= 23 && currentMinutes >= 30) {
                return moment().add(1, 'day').format('YYYY/MM/DD');
            }
            return moment().format('YYYY/MM/DD');
        };
        // We round the initial hour to the closest half an hour (10:00, 10:30, ...) to be able to create 2 way data binding with the hour select menu
        $scope.getInitialRoundedHour = () => {
            const currentMinutes = moment().minute();
            if (currentMinutes >= 30) {
                return moment().minute(0).add(1, 'hour').format('hh:mm A');
            } else {
                return moment().minute(30).format('hh:mm A');
            }
        };
        $scope.threeMonthFromNow = moment(moment().add(3, 'months')).format('YYYY/MM/DD');
        $scope.welcomeOfferEndDateOptions = false;
        $scope.Math = Math;

        $scope.setWelcomeOfferEndDate = () => {
            $scope.welcomeOfferEndDateOptions = true;
        };

        $scope.deleteWelcomeOfferEndDate = () => {
            $scope.welcomeOfferEndDateOptions = false;
            $scope.welcomeOfferEndDate = $scope.threeMonthFromNow;
            $scope.welcomeOfferEndHour = $scope.hoursOfDay[0];
        };

        const currentUnix = moment().valueOf();

        const updateOfferStatistics = (offerStatistics = []) => {
            if (!offerStatistics.length || offerStatistics.length === 0) {
                $scope.welcomeOfferActivationCount = 0;
                $scope.birthdayOfferActivationCount = 0;
                return;
            }

            offerStatistics.forEach((stat) => {
                if (stat.internalName === INTERNAL_OFFER_NAMES.WELCOME_OFFER) {
                    $scope.welcomeOfferActivationCount = stat.offerActivationCount;
                } else if (stat.internalName === INTERNAL_OFFER_NAMES.BIRTHDAY_OFFER) {
                    $scope.birthdayOfferActivationCount = stat.offerActivationCount;
                }
            });
        };

        $scope.updateCustomerGrowStatistics = (fromDate) => {
            Organization.getOfferStatistics({
                internalNames: ['welcome_offer', 'birthday_offer'],
                startDateTime: fromDate,
                endDateTime: currentUnix
            }, (res) => {
                updateOfferStatistics(res);
            }, (err) => {
                PosAlertService.showAlertByName('customer-grow-statistics-unsuccessful');
                updateOfferStatistics();
            });

            Organization.getNewPatronCount({
                startDateTime: fromDate,
                endDateTime: currentUnix
            }, (res) => {
                $scope.newPatronCount = res.newPatronCount;
            }, (err) => {
                $scope.newPatronCount = 0;
            });
        };

        // Customer grow page select date menu options
        $scope.selectDateOptions = [
            {
                name: $translate.instant('patrons.grow.page.last30Days'),
                unixValue: moment().subtract(30, 'days').startOf('day').valueOf()
            },
            {
                name: $translate.instant('patrons.grow.page.lastWeek'),
                unixValue: moment().subtract(1, 'week').startOf('day').valueOf()
            },
            {
                name: $translate.instant('patrons.grow.page.last3Months'),
                unixValue: moment().subtract(3, 'months').startOf('day').valueOf()
            },
            {
                name: $translate.instant('patrons.grow.page.last6Months'),
                unixValue: moment().subtract(6, 'months').startOf('day').valueOf()
            },
            {
                name: $translate.instant('patrons.grow.page.lastYear'),
                unixValue: moment().subtract(1, 'year').startOf('day').valueOf()
            }
        ];

        $scope.init = () => {
            Organization.getAllOffers({}, (res) => {
                $scope.availableOffers = res;
                // The 'welcome_offer' is auto generated for all organizations
                // in the back-end and it is initially set to disabled
                $scope.initialWelcomeOfferSettings = $scope.availableOffers.find((offer) => {
                    return offer.internalName === INTERNAL_OFFER_NAMES.WELCOME_OFFER;
                });

                $scope.initialBirthdayOfferSettings = $scope.availableOffers.find((offer) => {
                    return offer.internalName === INTERNAL_OFFER_NAMES.BIRTHDAY_OFFER;
                });
            });

            Organization.getOfferStatistics({
                internalNames: ['welcome_offer', 'birthday_offer'],
                startDateTime: $scope.selectDateOptions[0].unixValue,
                endDateTime: currentUnix
            }, (res) => {
                updateOfferStatistics(res);
            }, (err) => {
                PosAlertService.showAlertByName('customer-grow-statistics-unsuccessful');
                updateOfferStatistics();
            });

            Organization.getNewPatronCount({
                startDateTime: $scope.selectDateOptions[0].unixValue,
                endDateTime: currentUnix
            }, (res) => {
                $scope.newPatronCount = res.newPatronCount;
            }, (err) => {
                $scope.newPatronCount = 0;
            });
        };

        $scope.init();

    }]);

freshideasPatronsGrow.component('customersSideNav', {
    selector: 'customersSideNav', // <customers-side-nav>
    bindings: {},
    templateUrl: 'patrons/components/patron-sidenav/patron-sidenav.tpl.html',
    controller: 'PatronsSidenav'
});

require('../patron-sidenav/patron-sidenav.component')(freshideasPatronsGrow);

export default freshideasPatronsGrow;
