'use strict';

const Controller = new (require('../../external/pos.controller.js'))();

module.exports = function (posModule) {

    posModule
        .controller('PosTransactionExchangeStartCtrl', [
            '$scope',
            '$modal',
            '$translate',
            'CurrentSession',
            'LucovaWebSocket',
            'Pure',
            'SharedFunctionService',
            'SmbPosService',
            'TRANSACTION_STATUS',
            function (
                $scope,
                $modal,
                $translate,
                CurrentSession,
                LucovaWebSocket,
                Pure,
                SharedFunctionService,
                SmbPosService,
                TRANSACTION_STATUS
            ) {
                var currentOrder = SmbPosService.startOrder({patron: {}});
                var adjustments = {
                    dcbAdjustment: 0,
                    cashAdjustment: 0,
                    creditCardAdjustment: 0,
                    debitCardAdjustment: 0,
                    otherAdjustment: 0,
                    dollarDiscount: 0,
                    mealEquivalencyAdjustment: false,
                    percentDiscount: 0
                };
                var transactionAmount = 10; // TODO
                var initialTenders = [{
                    type: 'giftcard',
                    field: 'giftCardAdjustment',
                    amount: 5,
                    uuid: Pure.generateUuid(),
                    giftCardId: 3406,
                    giftCard: {
                        id: 3406,
                        code: '117001',
                        organizationId: 1,
                        organization: {
                            id: 1
                        },
                        currentBalanceCents: 500
                    }
                }];

                var tenderData = {
                    receipt: [],
                    fullReceipt: [],
                    available: [],
                    adjustments: adjustments,
                    currentOrderBalance: {
                        'totalMeals': 0,
                        'subTotalAmount': 0,
                        'totalAmount': 0,
                        'taxAmount': 0,
                        'totalDiscount': 0,
                        'mealError': false
                    },
                    deferred: false
                };

                var addMiscItem = function () {
                    var item = {
                        price: 10,
                        originalPrice: 10
                    };
                    item.upc = -9999;
                    item.total = item.price;
                    item.itemTotalPrice = item.price;
                    item.itemPrice = item.price;
                    item.originalPrice = item.originalPrice;
                    item.taxRate = 0;
                    item.taxRules = {};

                    tenderData.fullReceipt.length = 0;
                    tenderData.fullReceipt.push(item);
                };

                var rebuildReceipt = function () {
                    tenderData.receipt.length = 0;
                    var updatedFlatReceipt = Controller.rebuildReceipt(tenderData.fullReceipt);
                    tenderData.receipt.push(...updatedFlatReceipt);

                    var calculated = SharedFunctionService.calculateBalances(tenderData.receipt, tenderData.available, tenderData.adjustments, createPosData(), $scope.patron);
                    Controller.copyCalculations(calculated.payments, tenderData.currentOrderBalance);

                    var manualCoupon = {};
                    tenderData.tenderAmounts = SharedFunctionService.populateTenderAmounts(calculated.tenderAmounts, manualCoupon, $scope.patron);
                    // SecondaryDisplay.updateTransaction($scope.receipt, $scope.currentOrderBalance, $scope.tenderAmounts, $scope.order.patron);
                };

                var createPosData = function () {
                    return SharedFunctionService.createPosData(tenderData.receipt, currentOrder.patron, true,
                        SmbPosService.shift.locationId, tenderData.currentOrderBalance,
                        CurrentSession.getCompany().organization.settings, {
                        lucovaUser: $scope.lucovaUser
                    }
                    );
                };

                // TODO: tendering flag?
                $scope.openTenderForExchangeTransaction = function () {
                    addMiscItem();
                    rebuildReceipt();
                    var posData = createPosData();

                    LucovaWebSocket.setTransactionStatus(TRANSACTION_STATUS.INITIAL);
                    var tenderModal = $modal.open({
                        templateUrl: 'pos/smb/templates/pos.tender.tpl.html',
                        controller: 'PosTenderTransactionCtrl',
                        windowClass: 'smb-pos__tender-modal',
                        animation: false,
                        resolve: {
                            sendToKdsAndBackend: function () {
                                return function (suspendedTransaction, orderNumber) {
                                    // sendToKdsAndBackend(suspendedTransaction, orderNumber);
                                };
                            },
                            posData: function () {
                                return posData;
                            },
                            patron: function () {
                                return currentOrder.patron;
                            },
                            lucovaUser: function () {
                                return currentOrder.patron.lucovaUser;
                            },
                            currentOrder: function () {
                                return currentOrder;
                            },
                            verified: function () {
                                return $scope.verified; // TODO?
                            },
                            guestTransaction: function () {
                                // return $scope.guestTransaction; // TODO?
                                return currentOrder.guest;
                            },
                            cashierShiftId: function () {
                                console.log(SmbPosService.shift.cashierShiftId);
                                return SmbPosService.shift.cashierShiftId;
                            },
                            locationId: function () {
                                console.log(SmbPosService.shift.locationId);
                                return SmbPosService.shift.locationId;
                            },
                            receipt: function () {
                                return tenderData.receipt;
                            },
                            fullReceipt: function () {
                                return tenderData.fullReceipt;
                            },
                            available: function () {
                                return []; // angular.copy($scope.available);
                            },
                            servicePeriodId: function () {
                                return SmbPosService.shift.servicePeriodId;
                            },
                            transactionAmount: function () {
                                return transactionAmount;
                            },
                            quickCharge: function () {
                                return false;
                            },
                            campaignDiscount: function () {
                                return {}; // campaignLargerDiscount; // TODO?
                            },
                            manualCoupon: function () {
                                return {};
                            },
                            TRANSACTION_STATUS: function () {
                                return TRANSACTION_STATUS;
                            },
                            isOffline: function () {
                                return false;
                            },
                            suspendedOrder: function () {
                                return undefined;
                            },
                            initialAdjustments: function () {
                                return adjustments;
                            },
                            initialTenders: function () {
                                return initialTenders;
                            },
                            applyTransactionPercentageDiscount: function () {
                                return 0; // $scope.applyTransactionPercentageDiscount;
                            },
                            cancelledItemsCount: function () {
                                return 0;
                            },
                            cancelledItemsAmount: function () {
                                return 0;
                            },
                            cancelledItemsUserId: function () {
                                return undefined;
                            },
                            solinkCancelledItems: function () {
                                return [];
                            },
                            guestLabel: function () {
                                return '';
                            },
                            originalTransactionId: function () {
                                return undefined;
                            },
                            /* The below injections are empty because they aren't being used, but we still need to inject them
                               to satisfy PosTenderTransactionCtrl's listed injections. */
                            refundTransactionForExchange: function () {
                                return function () { };
                            },
                            modifyExistingItem: function () {
                                return function () { };
                            },
                            removeReceiptItem: function () {
                                return function () { };
                            },
                            modifyItemQuantity: function () {
                                return function () { };
                            },
                            ticketPrePrint: function () {
                                return undefined;
                            },
                            buzzerCode: function () {
                                return undefined;
                            }
                        },
                        backdrop: 'static',
                        keyboard: false
                    }, function (error) {
                        $scope.tendering = undefined;
                    });

                    tenderModal.result.then(function (tender) {
                        if (tender === 'done') {
                            $scope.isDone = true;
                        }

                        $scope.tendering = undefined;
                        LucovaWebSocket.setTransactionStatus(TRANSACTION_STATUS.DEAD);
                        $scope.switchView('start');
                    }, function () {
                        $scope.tendering = undefined;
                    });

                };
            }
        ]);
};
