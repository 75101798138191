'use strict';


module.exports = function (freshideasSmbPos) {

    const SharedDataService = require('../../external/pos.data-service.js');
    const Decimal = require('decimal.js').default;

    freshideasSmbPos.controller('SmbPosEndingBalanceCtrl', [
        '$scope',
        '$timeout',
        '$window',
        '$log',
        '$modalInstance',
        'shift',
        function ($scope,
            $timeout,
            $window,
            $log,
            $modalInstance,
            shift) {

            $scope.shift = Object.assign({}, shift);

            // eg. baseCent of 1 has an exponent of 0, baseCent of 100 has an exponent of 2, etc
            var baseCurrencyExponent = Math.floor(Math.log10(SharedDataService.baseCent));
            var baseCurrencyFactor = Math.pow(10, baseCurrencyExponent);

            // Navigation
            $scope.goBack = function (checkmarkString = false) {
                $modalInstance.dismiss();
            };

            $scope.saveBalance = function () {
                $modalInstance.close($scope.shift);
            };

            // Keypad for "Enter Ending Cash Balance"
            var value = '0';
            $scope.addValue = function (val) {
                if (val !== 'del') {
                    if (value.charAt(0) !== '0') {
                        if (value.length > 9) {
                            return;
                        }
                        value += val;
                    } else {
                        value = val;
                    }
                } else {
                    value = value.slice(0, -1);
                }
                setValue();
            };

            function setValue () {
                if (value === '' || value === '0') {
                    $scope.shift.endingBalance = '0.00';
                } else {

                    var valString;

                    if (value.length === 1) {
                        valString = '00'+value;
                    } else if (value.length === 2) {
                        valString = '0'+value;
                    } else {
                        valString = value;
                    }

                    var dollarValue = valString.slice(0, -2);
                    var centValue = valString.slice(-2);
                    var currencyValueString = dollarValue + '.' + centValue;

                    var currencyValue = currencyValue = new Decimal(currencyValueString).times(baseCurrencyFactor).toNumber();
                    $scope.shift.endingBalance = currencyValue + '';
                }
            }
        }
    ]);
};
