'use strict';


const moment = require('moment');

module.exports = function (freshideasReports) {

    freshideasReports.controller('ItemSalesReport', [
        '$scope',
        '$location',
        'Reports',
        'Utils',
        'Lookup',
        'Export',
        'Security',
        'Platform',
        'DateRangeService',
        'USER_ROLE_TYPE',
        'PosAlertService',
        function ($scope, $location, Reports, Utils, Lookup, Export, Security, Platform, DateRangeService, USER_ROLE_TYPE, PosAlertService) {
            var currentUser = Security.getUser() || {};
            $scope.isManager = currentUser.roleType === USER_ROLE_TYPE.MANAGER;
            $scope.isAccountant = currentUser.roleType === USER_ROLE_TYPE.ACCOUNTANT;
            $scope.isSiteAdmin = currentUser.permission === 'SITEADMIN';
            $scope.searchDisabled = false;
            var allowRetry;
            currentUser = Security.getUser();
            $scope.isFullAdmin = (currentUser.permission === 'FULLADMIN');
            $scope.isIosWebkit = Platform.isIosWebkit();
            $scope.isElectron = Platform.isElectron();
            $scope.searchBar = {
                text: ''
            };

            $scope.init = function () {
                if (DateRangeService.getFromDate()) {
                    $scope.itemSalesSearch.startDateTime = moment(DateRangeService.getFromDate()).startOf('day').toDate();
                }
                if (DateRangeService.getToDate()) {
                    $scope.itemSalesSearch.endDateTime = moment(DateRangeService.getToDate()).endOf('day').toDate();
                }
                loadCompanyHierarchy().$promise.then(function () {
                    $scope.loadItemSales();
                });
            };

            function loadCompanyHierarchy () {
                return Lookup.locationsByCompany({isAdvancedSearch: true}, function (response) {
                    $scope.company = _.findWhere(response, {id: currentUser.companyId});
                    $scope.locations = [];
                    let children = ($scope.company && $scope.company.children) ? $scope.company.children : [];

                    if (children.length) {
                        if (children.length > 1) {
                            $scope.locations.push({
                                id: null,
                                name: 'All Locations'
                            });
                        }
                        _.each(children, function (location) {
                            $scope.locations.push(location);
                        });
                        $scope.itemSalesSearch.locationId = currentUser.company.locationId;
                    }
                });
            }

            $scope.setSortByField = function (sortBy) {
                $scope.currentReportSearch.sortBy = sortBy;
                $scope.currentReportSearch.ascending = !$scope.currentReportSearch.ascending;

                var greater = $scope.currentReportSearch.ascending ? 1 : -1;
                var less = $scope.currentReportSearch.ascending ? -1 : 1;
                $scope.itemSalesData.sort((a, b) => {
                    if (a[sortBy] > b[sortBy]) {
                        return greater;
                    }
                    if (a[sortBy] < b[sortBy]) {
                        return less;
                    }
                    return 0;
                });
            };

            $scope.resetFilters = function () {
                $scope.itemSalesSearch.startDateTime = moment().startOf('week').toDate();
                $scope.itemSalesSearch.endDateTime = moment().endOf('day').toDate();
                $scope.itemSalesSearch.isAdvancedSearch = true;
                $scope.itemSalesSearch.sortBy = 'itemName';
                $scope.itemSalesSearch.ascending = true;
                $scope.itemSalesSearch.offSet = 0;
                $scope.itemSalesSearch.limit = 50;
                $scope.itemSalesSearch.locationId = currentUser.company.locationId;

                $scope.loadItemSales();
            };

            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };
            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };

            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };

            $scope.itemSalesSearch = {
                isAdvancedSearch: true,
                startDateTime: moment().startOf('week').toDate(),
                endDateTime: moment().endOf('day').toDate(),
                sortBy: 'itemName',
                ascending: true,
                offSet: 0,
                limit: 50,
            };
            $scope.saveSelectedFromDate = function () {
                var selectedFromDate = moment($scope.itemSalesSearch.startDateTime).startOf('day').valueOf();
                DateRangeService.setFromDate(selectedFromDate);
            };
            $scope.saveSelectedToDate = function () {
                var selectedToDate = moment($scope.itemSalesSearch.endDateTime).endOf('day').valueOf();
                DateRangeService.setToDate(selectedToDate);
            };

            function resetPagingOptions () {
                $scope.pagingOptions = {
                    startRecord: 0,
                    pageSize: 10,
                    currentPage: 1,
                };
            }
            resetPagingOptions();

            $scope.pageUp = function () {
                var start = $scope.pagingOptions.startRecord;
                var newStart = start + $scope.pagingOptions.pageSize;
                if ($scope.itemSalesData.length > newStart) {
                    $scope.pagingOptions.startRecord = newStart;
                    $scope.pagingOptions.currentPage++;
                }
            };

            $scope.pageDown = function () {
                var start = $scope.pagingOptions.startRecord;
                var newIndex = start - $scope.pagingOptions.pageSize;
                if (start > 0) {
                    if (newIndex < 0) {
                        $scope.pagingOptions.startRecord = 0;
                    } else {
                        $scope.pagingOptions.startRecord = newIndex;
                    }
                    $scope.pagingOptions.currentPage--;
                }
            };

            $scope.pagesAvailable = function () {
                var itemLen = $scope.itemSalesData.length;
                var pageSize = $scope.pagingOptions.pageSize;
                return (itemLen > pageSize) ? Math.ceil(itemLen/pageSize) : 1;
            };

            function reduceItemsList (list) {
                let retval = [];
                Object.keys(list).forEach((key) => {
                    var item = list[key].reduce((acu, cur) => {
                        if (acu) {
                            acu.quantity += cur.quantity;
                            acu.price += cur.price;
                            acu.taxAmount += cur.taxAmount;

                            let unionMods = _.union(acu.modifiers || [], cur.modifiers || []);
                            if (unionMods.length) {
                                acu.modifiers = unionMods;
                            }
                            return acu;
                        } else {
                            return cur;
                        }
                    });
                    retval.push(item);
                });
                return retval;
            }

            $scope.filterItemSales = function (data) {
                if (data.length > 0) {
                    $scope.isLoading = false;

                    var itemSalesData = _.filter(data, function (item) {
                        return item.quantity > 0;
                    });

                    var modsApplied = $scope.setBaseItemsFromSales(itemSalesData);
                    var groupedItems = reduceItemsList(_.groupBy(modsApplied, 'itemName'));

                    groupedItems.forEach((item) => {
                        item.modifiers = reduceItemsList(_.groupBy(item.modifiers, 'itemName'));

                        // Add modifier cost to item's price
                        item.modifiers.forEach((modifier) => {
                            item.price += modifier.price;
                        });
                    });

                    $scope.itemSalesData = groupedItems;
                    $scope.originalSalesData = $scope.itemSalesData;

                    $scope.currentReportSearch = angular.copy($scope.itemSalesSearch);
                    $scope.searchAction();
                } else {
                    $scope.isLoading = false;
                    $scope.itemSalesData = [];
                    $scope.itemSalesDataSize = 0;
                }
            };

            $scope.loadItemSales = function () {
                $scope.searchDisabled = true;
                clearTimeout(allowRetry);
                allowRetry = setTimeout(function () {
                    $scope.searchDisabled = false;
                }, 30000);
                $scope.itemSalesSearch.startDateTime = moment($scope.itemSalesSearch.startDateTime).startOf('day').valueOf();
                $scope.itemSalesSearch.endDateTime = moment($scope.itemSalesSearch.endDateTime).endOf('day').valueOf();

                $scope.itemSalesData = [];
                $scope.itemSalesDataSize = 0;
                $scope.isLoading = true;

                Reports.getDatedItemSalesReport($scope.itemSalesSearch, function (response) {
                    $scope.filterItemSales(response);
                }, function (error) {
                    $scope.isLoading = false;
                    if (error.data.exception.appCode == 450) {
                        PosAlertService.showAlertByName('invalid-date-range', {message: error.data.error});
                    }
                });
            };

            $scope.getDownloadUrl = function () {
                if ($scope.isIosWebkit || $scope.isElectron) {
                    return;
                }
                var reportSearch = {};
                reportSearch.startDateTime = moment($scope.itemSalesSearch.startDateTime).startOf('day').valueOf();
                reportSearch.endDateTime = moment($scope.itemSalesSearch.endDateTime).endOf('day').valueOf();
                if ($scope.itemSalesSearch.locationId) {
                    reportSearch.locationId = $scope.itemSalesSearch.locationId;
                }

                var downloadUrl = $location.protocol() + '://' + $location.host() + ':' + $location.port()
                 + '/freshideas/web/reports/itemSales/export';

                var queryString = jQuery.param(reportSearch);
                return downloadUrl + '?' + queryString;
            };

            $scope.setBaseItemsFromSales = function (datedItemSales) {
                // Base item list contains base items only
                var baseItemList = datedItemSales.filter((itemSalesIterate) => {
                    return itemSalesIterate.itemIndex === itemSalesIterate.parentIndex ||
                    (itemSalesIterate.type == 'item' || itemSalesIterate.type == '' || itemSalesIterate.type == null) &&
                    (itemSalesIterate.subtype == '' || itemSalesIterate.subtype == null);
                });

                // Build modifiers of each base item into baseItem.modifiers
                baseItemList.forEach((baseItem) => {
                    baseItem.modifiers = datedItemSales.filter((itemSalesIterate) => {
                        if (itemSalesIterate.locationServicePeriodMenuId !== itemSalesIterate.origin
                            && itemSalesIterate.origin === baseItem.locationServicePeriodMenuId) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                });
                return baseItemList;
            };

            $scope.toggleShowItemModifier = function (itemSales) {
                if (!itemSales.showModifiers) {
                    itemSales.showModifiers = true;
                } else {
                    itemSales.showModifiers = false;
                }
            };

            $scope.setAllShowModifiers = function (expanded) {
                $scope.itemSalesData.forEach((item) => {
                    item.showModifiers = expanded;
                });
            };

            function calculateTotals () {
                $scope.totalSales = 0;
                $scope.totalQuantity = 0;

                $scope.itemSalesData.forEach((item) => {
                    $scope.totalSales += item.price || 0;
                    $scope.totalQuantity += item.quantity || 0;
                });
            }

            $scope.searchAction = function () {
                // Search filter
                if ($scope.searchBar.text !== '') {
                    var searchTextLowerCase = $scope.searchBar.text.toLowerCase();
                    var filteredArray = [];

                    $scope.originalSalesData.forEach((baseItemSale) => {
                        var isAdded = false;

                        var baseItemName = baseItemSale.itemName.toLowerCase();
                        if (baseItemName.indexOf(searchTextLowerCase) !== -1) {
                            filteredArray.push(baseItemSale);
                            isAdded = true;
                        }

                        if (isAdded) {
                            return;
                        }

                        if (baseItemSale.modifiers.length > 0) {
                            var pushedBaseItem = false;
                            baseItemSale.modifiers.forEach((modifierItemSale) => {
                                var modifierItemName = modifierItemSale.itemName.toLowerCase();
                                if (modifierItemName.indexOf(searchTextLowerCase) !== -1) {
                                    if (!pushedBaseItem) {
                                        pushedBaseItem = Object.assign(
                                            {}, baseItemSale);
                                        pushedBaseItem.modifiers = [];
                                        pushedBaseItem.modifiers.push(modifierItemSale);
                                        filteredArray.push(pushedBaseItem);
                                        isAdded = true;
                                    } else {
                                        pushedBaseItem.modifiers.push(modifierItemSale);
                                    }
                                }
                            });
                        }

                        if (isAdded) {
                            return;
                        }

                        if (baseItemSale.tagNames && baseItemSale.tagNames.length) {
                            for (var tagName of baseItemSale.tagNames) {
                                tagName = tagName.toLowerCase();
                                if (tagName.indexOf(searchTextLowerCase) !== -1) {
                                    filteredArray.push(baseItemSale);
                                    isAdded = true;
                                    break;
                                }
                            }
                        }
                    });

                    $scope.itemSalesData = filteredArray;
                    $scope.setAllShowModifiers(false);
                } else {
                    $scope.itemSalesData = $scope.originalSalesData;
                }
                resetPagingOptions();
                calculateTotals();
            };

            $scope.clearSearch = function () {
                $scope.searchBar.text = '';
                $scope.itemSalesData = $scope.originalSalesData;
                calculateTotals();
            };

            $scope.exportToPdf = function (tableId) {
                Export.tableToPdf(tableId, 'landscape');
            };

            $scope.init();

            $scope.$on('$destroy', function () {
                clearTimeout(allowRetry);
            });

            $scope.$watch('itemSalesSearch.locationId', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('itemSalesSearch.startDateTime', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('itemSalesSearch.endDateTime', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });
        }
    ]);

};
