'use strict';


export function bluetoothTest (freshideas) {

    freshideas.factory('BluetoothSelfTest', [
        '$timeout',
        'Logger',
        function ($timeout, Logger) {
            var enabled = false;
            var path;
            var child;

            var run = function (bleId, callback) {
                if (!enabled || window.process.platform !== 'win32' || !bleId || !path || !child) {
                    return;
                }

                var parameters = [bleId];

                child.execFile(path, parameters, function (err, stdout, stderr) {
                    let exitCode = 0;
                    if (err) {
                        exitCode = err.code;
                    }
                    if (callback) {
                        callback(exitCode);
                    }
                });
            };

            if (typeof window !== 'undefined') {
                // running from browser
                if (window.process && window.process.type) {
                    // running from electron
                    enabled = true;
                    let exeDir = nodeRequire('electron').remote.getGlobal('libDir');
                    path = nodeRequire('path').join(exeDir, 'LucovaBleScanner.exe');
                    child = nodeRequire('child_process');
                } else {
                    enabled = false;
                }
            }

            var execProcess = {
                run
            };

            return execProcess;
        }
    ]);
}
