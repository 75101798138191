
module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ScanForTCPPrinters', [
        '$scope',
        '$modal',
        '$modalInstance',
        'BridgedPromise',
        'ProductsService',
        'Platform',
        'IpScanner',
        function ($scope,
            $modal,
            $modalInstance,
            BridgedPromise,
            ProductsService,
            Platform,
            IpScanner) {
            $scope.selectedTCPPrinter = {
                ip: null,
                port: 9100
            };

            $scope.tcpPrintersIpList = [];
            $scope.isDoneScanning = false;
            $scope.isError = false;

            $scope.close = function (cancel) {
                if ($scope.selectedTCPPrinter.ip && !cancel) {
                    $modalInstance.close($scope.selectedTCPPrinter);
                } else {
                    $modalInstance.close();
                }
            };

            // Send to native to check for card terminals
            var scanForTcpIpPrinters = function () {
                var promise;
                if (Platform.isElectron()) {
                    promise = IpScanner.scan(9100);
                } else {
                    promise = BridgedPromise.dispatch('discoverCardTerminal', {
                        port: 9100
                    });
                }
                try {
                    promise.then(function (response) {
                        $scope.tcpPrintersIpList = JSON.parse(response.cardTerminals);
                        $scope.isDoneScanning = true;
                    }).catch(function (error) {
                        $scope.isDoneScanning = true;
                        $scope.isError = true;
                    });
                } catch (error) {
                    $scope.isDoneScanning = true;
                    $scope.isError = true;
                }
            };

            $scope.selectCardTerminal = function (ipAddress) {
                if ($scope.selectedTCPPrinter.ip === ipAddress) {
                    // Already selected
                    $scope.selectedTCPPrinter.ip = null;
                } else {
                    $scope.selectedTCPPrinter.ip = ipAddress;
                }
            };

            scanForTcpIpPrinters();
        }]);
};

