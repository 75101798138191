'use strict';


const angular = require('angular');

export default angular.module('freshideas.services.kioskModal', [])
    .factory('KioskModalService', ['$modal', '$http', 'KioskService',
    function ($modal, $http, KioskService) {
        var content = {
            'confirmation': {
                'type': 'confirm',
                'title': 'general.areYouSure',
                'subtitle': '',
                'message': '',
                'buttonType': 'ok',
                'buttonTitle': null,
                'buttonTitleOk': 'general.confirm',
                'buttonTitleCancel': 'general.back',
                'confirmOnTimeout': false
            },
            'alert': {
                'type': 'alert',
                'title': 'general.error.somethingWentWrong.ttl',
                'subtitle': '',
                'message': '',
                'buttonType': 'ok',
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null,
                'confirmOnTimeout': false
            },
            'oops': {
                'type': 'alert',
                'title': 'general.error.oops.ttl',
                'subtitle': '',
                'message': '',
                'buttonType': 'ok',
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null,
                'confirmOnTimeout': false
            }
        };

        function showModalByName (name, params) {
            // Prevent customization from overriding the default alert params
            var obj = angular.copy(content[name]);

            if (obj) {
                if (params) {
                    obj['type'] = params['type'] || obj['type'];
                    obj['title'] = params['title'] || obj['title'];
                    obj['subtitle'] = params['subtitle'] || obj['subtitle'];
                    obj['message'] = params['message'] || obj['message'];
                    obj['detail'] = params['detail'] || obj['detail'];
                    obj['visuals'] = params['visuals'] || obj['visuals'];
                    obj['buttonType'] = params['buttonType'] || obj['buttonType'];
                    obj['buttonTitle'] = params['buttonTitle'] || obj['buttonTitle'];
                    obj['buttonTitleOk'] = params['buttonTitleOk'] || obj['buttonTitleOk'];
                    obj['buttonTitleCancel'] = params['buttonTitleCancel'] || obj['buttonTitleCancel'];
                    obj['modalCallback'] = params['modalCallback'];
                    obj['dismissModalCallback'] = params['dismissModalCallback'];
                    obj['timeOut'] = params['timeOut'] || 0;
                    obj['confirmOnTimeout'] = params['confirmOnTimeout'] || obj['confirmOnTimeout'];
                    obj['cancelBtnImg'] = params['cancelBtnImg'] || 'arrow';
                }

                return showModal(
                    obj['type'],
                    obj['title'],
                    obj['subtitle'],
                    obj['message'],
                    obj['detail'],
                    obj['visuals'],
                    obj['modalCallback'],
                    obj['buttonType'],
                    obj['buttonTitle'],
                    obj['buttonTitleOk'],
                    obj['buttonTitleCancel'],
                    obj['dismissModalCallback'],
                    obj['timeOut'],
                    obj['confirmOnTimeout'],
                    obj['cancelBtnImg']
                );
            }
        }

        function showModal (
            type,
            title,
            subtitle,
            message,
            detail,
            visuals,
            modalCallback,
            buttonType,
            buttonTitle,
            buttonTitleOk,
            buttonTitleCancel,
            dismissModalCallback,
            timeOut,
            confirmOnTimeout,
            cancelBtnImg) {

            var modalInstance = $modal.open({
                templateUrl: 'common/modals/kioskGeneralModal.tpl.html',
                controller: [
                    '$scope',
                    '$modal',
                    '$modalInstance',
                    '$translate',
                    'type',
                    'title',
                    'subtitle',
                    'message',
                    'detail',
                    'visuals',
                    'buttonType',
                    'buttonTitle',
                    'buttonTitleOk',
                    'buttonTitleCancel',
                    'timeOut',
                    'cancelBtnImg',
                    function ($scope, $modal, $modalInstance, $translate, type, title, subtitle, message, detail, visuals, buttonType, buttonTitle, buttonTitleOk, buttonTitleCancel, timeOut, cancelBtnImg) {
                        $scope.type = type;
                        $scope.title = title;
                        $scope.subtitle = subtitle;
                        $scope.message = message;
                        $scope.detail = detail;
                        $scope.visuals = visuals;
                        $scope.buttonType = buttonType;
                        $scope.buttonTitle = buttonTitle;
                        $scope.buttonTitleOk = buttonTitleOk;
                        $scope.buttonTitleCancel = buttonTitleCancel;
                        $scope.iconName = 'fa-exclamation-circle';
                        $scope.cancelBtnImg = cancelBtnImg;

                        let intervalID = null;

                        $scope.confirm = function () {
                            $modalInstance.close();
                            if (intervalID) {
                                clearInterval(intervalID);
                            }
                        };

                        $scope.cancel = function (isAutoCancel = false) {
                            $modalInstance.dismiss(isAutoCancel);
                            if (intervalID) {
                                clearInterval(intervalID);
                            }
                        };

                        if (timeOut > 0) {
                            const startTimeSec = Date.now()/1000;
                            intervalID = setInterval(() => {
                                const nowSec = Date.now()/1000;
                                if (nowSec - startTimeSec > timeOut) {
                                    if (confirmOnTimeout) {
                                        $scope.confirm();
                                    } else {
                                        $scope.cancel(true);
                                    }
                                }
                            }, 1000);
                        }
                    }
                ],
                windowClass: 'kiosk-modal-general',
                animation: false,
                resolve: {
                    type: function () {
                        return type || 'error';
                    },
                    title: function () {
                        return title || 'general.error.oops';
                    },
                    subtitle: function () {
                        return subtitle || '';
                    },
                    message: function () {
                        return message || 'general.error.default';
                    },
                    detail: function () {
                        return detail || '';
                    },
                    visuals: function () {
                        return visuals || [];
                    },
                    buttonType: function () {
                        return buttonType || 'ok';
                    },
                    buttonTitle: function () {
                        return buttonTitle;
                    },
                    buttonTitleOk: function () {
                        return buttonTitleOk;
                    },
                    buttonTitleCancel: function () {
                        return buttonTitleCancel;
                    },
                    timeOut: function () {
                        return timeOut;
                    },
                    cancelBtnImg: function () {
                        return cancelBtnImg;
                    }
                },
                backdrop: 'static'
            }, function (error) {
                //
            });

            modalInstance.result.then(function (result) {
                if (modalCallback) {
                    modalCallback();
                }
            }, function (isAutoCancel) {
                if (dismissModalCallback) {
                    dismissModalCallback(isAutoCancel);
                }
            });

            return modalInstance;
        }


        return {
            showModal: showModal,
            showModalByName: showModalByName
        };
    }]);
