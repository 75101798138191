'use strict';


const angular = require('angular');
const ngResource = require('angular-resource');

export default angular.module('freshideas.resources.deliverect', [ngResource])
    .factory('Deliverect', ['$resource', 'EnvConfig', function ($resource, EnvConfig) {
        return $resource(EnvConfig.host + '/freshideas/web/deliverect', {}, {
            getDeliverectAccounts: {method: 'GET', url: EnvConfig.host + '/freshideas/web/deliverect/accounts'},
            getDeliverectAccountLocations: {method: 'GET', url: EnvConfig.host + '/freshideas/web/deliverect/locations'}
        });
    }]);
