'use strict';


module.exports = function (freshideasDirectivesCommon) {

    freshideasDirectivesCommon.directive('percentageInput', ['$timeout', function ($timeout) {
        var percentageRegex = /([0-9]+[.]?[0-9]*)(%)?/;
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function (scope, elem, attrs, ngModelCtrl) {
                ngModelCtrl.$formatters.push(function (value) {
                    var decimal = parseInt(attrs.decimal);
                    if (isNaN(decimal)) {
                        return isNaN(value) ? '' : value * 100 + '%';
                    } else {
                        return isNaN(value) ? '' : (value * 100).toFixed(decimal) + '%';
                    }
                });

                var parseViewValue = function (displayValue) {
                    var result;
                    var newViewValue;
                    if (percentageRegex.test(displayValue)) {
                        ngModelCtrl.$setValidity('percentage', true);
                        var value = percentageRegex.exec(displayValue)[1];
                        newViewValue = value + '%';
                        result = Number(value) / 100;
                    } else {
                        ngModelCtrl.$setValidity('percentage', false);
                        newViewValue = '0%';
                        result = 0;
                    }

                    ngModelCtrl.$viewValue = newViewValue;

                    if (newViewValue != displayValue) {
                        ngModelCtrl.$render();
                    }

                    return result;
                };

                ngModelCtrl.$parsers.push(parseViewValue);

                var timeout;
                elem.on('click input paste keyup', function () {
                    timeout = $timeout(function () {
                        $timeout.cancel(timeout);

                        parseViewValue(elem.val());

                        $timeout(function () {
                            var value = elem.val() || '';

                            // If the cursor is beyond '%', move it back to right
                            // before the '%'
                            if (elem[0].selectionStart >= value.length) {
                                var cursorPos = value.length - 1;
                                elem[0].setSelectionRange(cursorPos, cursorPos);
                            }
                        }, 0);

                        scope.$apply();
                    });
                });

                scope.$on('$destroy', function () {
                    elem.off('click input paste keyup');
                });
            }
        };
    }]);

};

