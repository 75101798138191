'use strict';


const angular = require('angular');

module.exports = function (freshideasLocations) {

    freshideasLocations.controller('EditLocationPrintersCtrl', [
        '$scope',
        '$modalInstance',
        'Locations',
        'location',
        'selectedCompanyId',
        'isWizardMode',
        'notificationTranslationHelper',
        'ConfirmModalService',
        'PrintService',
        function ($scope, $modalInstance, Locations, location, selectedCompanyId, isWizardMode, notificationTranslationHelper, ConfirmModalService, PrintService) {
            $scope.location = location;
            if (location.selectedPrinter) {
                $scope.selectedPrinter = location.selectedPrinter;
            }

            $scope.printers = [];
            $scope.loadPrinters = function () {
                if (!isWizardMode) {
                    Locations.getLocationPrinters(
                      {
                          'locationId': location.locationId,
                      },
                      function (response) {
                          $scope.printers = response.entries || [];
                          $scope.deselectPrinter();
                      },
                      function (error) {
                        console.error(error);
                        notificationTranslationHelper.notifyError(
                          error.data.error, null, false);
                    });
                }
            };
            var getNewPrinter = function () {
                return {
                    isNew: true,
                    locationId: $scope.location.locationId,
                    name: '',
                    ipAddress: '',
                    port: undefined
                };
            };
            $scope.addPrinter = function () {
                $scope.selectedPrinter = getNewPrinter();
            };
            $scope.selectPrinter = function (printer) {
                $scope.selectedPrinter = printer;
            };
            $scope.deselectPrinter = function () {
                $scope.selectedPrinter = undefined;
            };
            $scope.saveSelectedPrinter = function () {
                var printer = angular.copy($scope.selectedPrinter);
                var isNew = printer.isNew;

                if (!printer.port) {
                    printer.port = 80;
                }
                if (isNew) {
                    Locations.addPrinter({}, printer, function (response) {
                        $scope.loadPrinters();

                        if (isWizardMode) {
                            $scope.$close(response);
                        }
                    });
                } else {
                    Locations.updatePrinter({}, printer, function (response) {
                        $scope.loadPrinters();

                        if (isWizardMode) {
                            $scope.$close(response);
                        }
                    });
            }
            };
            $scope.deleteSelectedPrinter = function () {
                ConfirmModalService.openConfirmModal('Delete Printer', 'Do you wish to delete the printer named \'' + $scope.selectedPrinter.name + '\'?', confirmDeleteSelectedPrinter, $scope.selectedPrinter, $scope, true);
            };
            var confirmDeleteSelectedPrinter = function () {
                Locations.deletePrinter(
                    {
                        printerId: $scope.selectedPrinter.printerId
                    },
                    {},
                    function (response) {
                        $scope.loadPrinters();

                        if (isWizardMode) {
                            $scope.$close();
                        }
                    });
            };
            $scope.testPrinter = function () {
                // Empty now because the old was old and unused. Will re-implement
                // if needed
            };
            $scope.loadPrinters();
        }
    ]).value('isWizardMode', false);

};
