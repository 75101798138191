'use strict';


const angular = require('angular');

export default angular.module('freshideas.services.audio', [])
    .factory('AudioService', [function () {
        return {
            transactionSuccess: function () {
                var audio = new Audio('/audio/transaction-success-3.mp3');
                audio.play();
            },
            tipSound: function () {
                var audio = new Audio('/audio/tip.wav');
                audio.play();
            },
            mobileOrder: function () {
                var audio = new Audio('/audio/mobile-order-new.wav');
                audio.play();
            },
            newChatMessage: function () {
                var audio = new Audio('/audio/chat-notification.mp3');
                audio.play();
            },
            errorSound: function () {
                var audio = new Audio('/audio/error.mp3');
                audio.play();
            },
            loudErrorSound: function () {
                var audio = new Audio('/audio/loud-error.mp3');
                audio.play();
            }
        };
    }]);
