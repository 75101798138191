'use strict';

const angular = require('angular');
const moment = require('moment');

module.exports = function (freshideasReports) {
    freshideasReports.controller('ReportsCtrl', [
        '$scope',
        '$state',
        'Reports',
        'Security',
        'Calculate',
        'Lookup',
        'Platform',
        'DateRangeService',
        'GatewayFiit',
        'USER_ROLE_TYPE',
        function ($scope, $state, Reports, Security, Calculate, Lookup, Platform, DateRangeService, GatewayFiit, USER_ROLE_TYPE) {
            $scope.isFiitEnabled = GatewayFiit.isEnabled();
            var currentUser = Security.getUser() || {};
            $scope.isManager = currentUser.roleType === USER_ROLE_TYPE.MANAGER;
            $scope.isAccountant = currentUser.roleType === USER_ROLE_TYPE.ACCOUNTANT;
            $scope.isSiteAdmin = currentUser.permission === 'SITEADMIN';
            $scope.isIosWebkit = Platform.isIosWebkit();
            $scope.isElectron = Platform.isElectron();
            $scope.searchDisabled = true;
            var autoReload;
            var allowRetry;

            $scope.changeView = function (view) {
                switch (view) {
                case 'transaction-report':
                    $state.go('freshideas.report.transactions');
                    break;
                case 'summary-report':
                    $state.go('freshideas.report.summaryReport');
                    break;
                case 'sales-report':
                    $state.go('freshideas.report.salesReport');
                    break;
                case 'daily-sales-meals-served':
                    $state.go('freshideas.report.dailySalesMealsServed');
                    break;
                case 'meal-plan-usage-report':
                    $state.go('freshideas.report.mealPlanUsage');
                    break;
                case 'active-patrons-report':
                    $state.go('freshideas.report.activePatrons');
                    break;
                case 'patron-counts-report':
                    $state.go('freshideas.report.patronCounts');
                    break;
                case 'patron-activity-report':
                    $state.go('freshideas.report.patronActivity');
                    break;
                case 'end-of-shift-report':
                    $state.go('freshideas.report.endOfShift');
                    break;
                case 'deposit-refund-report':
                    $state.go('freshideas.report.depositRefund');
                    break;
                case 'session-audit-report':
                    $state.go('freshideas.report.sessionAudit');
                    break;
                case 'location-revenue-report':
                    $state.go('freshideas.report.locationRevenue');
                    break;
                case 'mobile-transactions-report':
                    $state.go('freshideas.report.mobileTransactions');
                    break;
                case 'patron-meal-plan-history-report':
                    $state.go('freshideas.report.patronMealPlanHistory');
                    break;
                case 'item-sales-report':
                    $state.go('freshideas.report.itemSales');
                    break;
                case 'patron-spend-report':
                    $state.go('freshideas.report.patronSpend');
                    break;
                case 'participation-rate-report':
                    $state.go('freshideas.report.participationRate');
                    break;
                case 'meal-plan-sales-report':
                    $state.go('freshideas.report.mealPlanSales');
                    break;
                case 'loss-prevention-report':
                    $state.go('freshideas.report.lossPrevention');
                    break;
                default:
                    $state.go('freshideas.home');
                }
            };

            $scope.transactionSearch = {
                isAdvancedSearch: true,
                companyId: '0',
                mealPeriodId: '0',
                startDateTime: moment().startOf('week').toDate(),
                endDateTime: moment().endOf('day').toDate(),
                employeeId: 0,
                transactionId: undefined,
                patronCardNumber: undefined,
                patronStudentId: undefined,
                firstName: undefined,
                lastName: undefined,
                guestTransactionsOnly: false,
                sortBy: 'transaction_id',
                ascending: false,
                mealPlanIds: '',
                dcbMealPlanIds: '',
                locationId: '',
                posStationId: '',
                mealPlanType: '',
                mealPlanId: '0',
                offset: 0,
                limit: -1
            };

            $scope.lineChartOptions = {
              'chart': {
                'height': 300,
                'type': 'lineChart',
                'margin': {
                    'top': 0,
                    'right': 0,
                    'bottom': 0,
                    'left': 0
                },
                'showLegend': false,
                'showLabels': false,
                'useInteractiveGuideline': true,
                'dispatch': {},
                'xAxis': {
                  'axisLabel': 'Time'
                },
                'yAxis': {
                  'axisLabel': 'Transactions',
                  'axisLabelDistance': -10
                }
              }
            };

            $scope.lineData = [];

            $scope.pieChartOptions = {
                chart: {
                    'type': 'pieChart',
                    'width': 100,
                    'height': 100,
                    'x': function (d) {
                        return d.key;
                    },
                    'y': function (d) {
                        return d.y;
                    },
                    'duration': 500,
                    'labelThreshold': 0.01,
                    'labelType': 'key',
                    'labelSunbeamLayout': false,
                    'transitionDuration': 500,
                    'showLegend': false,
                    'showLabels': false,
                    'legend': {
                        'margin': {
                            'top': 0,
                            'right': 0,
                            'bottom': 0,
                            'left': 0
                        }
                    },
                    'pie': {
                        'margin': {
                            'top': 0,
                            'right': 0,
                            'bottom': 0,
                            'left': 0
                        }
                    },
                    'margin': {
                        'right': 0,
                        'left': 0,
                        'top': 0,
                        'bottom': 0
                    },
                    'dispatch': {
                        renderEnd: function (e) {
                            d3.selectAll('#pieChart svg')
                            .attr('width', null)
                            .attr('height', null)
                            .attr('viewBox', '0 0 100 100')
                            .style('width', '16vw')
                            .style('height', '16vw');
                        }
                    }
                }
            };

            $scope.pieData = [
                {
                    key: 'Credit/Debit',
                    y: 0,
                    transactions: 0,
                    sales: 0,
                    color: '#D5A3CB',
                    type: 'dollar'
                },
                {
                    key: 'Cash',
                    y: 0,
                    transactions: 0,
                    sales: 0,
                    color: '#9183B9',
                    type: 'dollar'
                },
                {
                    key: 'Loyalty',
                    y: 0,
                    transactions: 0,
                    sales: 0,
                    color: '#cf000f',
                    type: 'dollar'
                },
                {
                    key: 'Other',
                    y: 0,
                    transactions: 0,
                    sales: 0,
                    color: '#E97F12',
                    type: 'dollar'
                },
                {
                    key: 'Meal Units',
                    y: 0,
                    transactions: 0,
                    sales: 0,
                    prefixText: '',
                    color: '#418C6D',
                    type: 'dollar',
                    showOnMealsOnly: true
                },
                {
                    key: 'DCB',
                    y: 0,
                    transactions: 0,
                    sales: 0,
                    color: '#C3E4D7',
                    type: 'dollar',
                    showOnMealsOnly: true
                },
            ];

            $scope.loyaltyPieData = [{
                key: 'Collected',
                y: 0,
                sales: 0,
                color: '#418C6D',
                type: 'unit'
            }, {
                key: 'Redeemed',
                y: 0,
                sales: 0,
                color: '#C3E4D7',
                type: 'unit'
            }];

            $scope.pieData = Calculate.normalize($scope.pieData, 'y');

            $scope.resetFilters = function () {
                $scope.itemSalesSearch.startDateTime = moment().startOf('week').toDate();
                $scope.itemSalesSearch.endDateTime = moment().endOf('day').toDate();
                $scope.loadLossPreventionReport();
            };

            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };

            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };

            $scope.clearFilters = function (isAdvancedSearch) {
                $scope.reportSearch = {isAdvancedSearch: !!isAdvancedSearch};
                $scope.reportSearch.fromDate = moment().startOf('week').toDate();
                $scope.reportSearch.toDate = moment().endOf('day').toDate();
                $scope.reportSearch.locationId = currentUser.company.locationId;
            };

            var refreshLocations = function (companies) {
                if (companies) {
                    $scope.locations = _.flatten(_.map(companies, function (company) {
                        return company.children;
                    }), true);
                }
            };

            function lookupCompanyHierarchy () {
                return Lookup.locationsByCompany({}, function (response) {
                    $scope.company = _.findWhere(response, {id: currentUser.companyId});
                    $scope.locations = [];
                    let children = ($scope.company && $scope.company.children) ? $scope.company.children : [];

                    if (children.length) {
                        if (children.length > 1) {
                            $scope.locations.push({
                                id: null,
                                name: 'All Locations'
                            });
                        }
                        _.each(children, function (location) {
                            $scope.locations.push(location);
                        });
                        $scope.reportSearch.locationId = currentUser.company.locationId;
                    }
                });
            }

            $scope.getSalesReport = function () {
                $scope.searchDisabled = true;
                clearTimeout(autoReload);
                clearTimeout(allowRetry);
                allowRetry = setTimeout(function () {
                    $scope.searchDisabled = false;
                }, 30000);

                $scope.hasSearched = true;

                loadTopItemsByCount();
                loadTopItemsByAmount();
                loadFifteenMinuteCount();
                loadOverview();
                loadMobileOrder();
                loadTopCategoriesByCount();
                loadTopCategoriesByAmount();
            };

            function loadMobileOrder () {
                if ($scope.loadingMobileOrder) {
                    return;
                }
                var reportSearch = {};
                reportSearch.startDateTime = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                reportSearch.endDateTime = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                $scope.mobileOrder = {};
                $scope.loadingMobileOrder = true;
                Reports.getMobileOrderReport(reportSearch).$promise.then((response) => {
                    $scope.mobileOrder = response;
                    $scope.loadingMobileOrder = false;
                }).catch((error) => {
                    $scope.loadingMobileOrder = false;
                });
            }

            function loadOverview () {
                if ($scope.loadingSalesReport) {
                    return;
                }
                var reportSearch = {};
                reportSearch.startDateTime = moment($scope.reportSearch.fromDate).startOf('day').format('YYYY-MM-DD');
                reportSearch.endDateTime = moment($scope.reportSearch.toDate).endOf('day').format('YYYY-MM-DD');
                if ($scope.reportSearch.locationId) {
                    reportSearch.locationId = $scope.reportSearch.locationId;
                }
                $scope.loadingSalesReport = true;

                Reports.getOverview(reportSearch).$promise.then((response) => {
                    $scope.loadingSalesReport = false;
                    setTimerIfFinishedLoading();
                    var overview = response[0];
                    var results = {};
                    results.allTotals = {
                        // Loyalty
                        mealEquivalency: {
                            amount: overview.totalMealEqAmount,
                            count: overview.mealEqCount + overview.refundLoyaltyCount
                        },
                        cash: {
                            count: overview.cashCount + overview.refundCashCount,
                            amount: overview.totalCashAmount - overview.refundCashAmount
                        },
                        card: {
                            count: overview.cardCount + overview.refundCardCount,
                            amount: overview.totalCardAmount - overview.refundCardAmount
                        },
                        sales: {
                            amount: overview.netSales
                                  - overview.refundSubtotal
                                  - overview.giftCardActivation
                                  - overview.giftCardReload
                                  - overview.cashCardReload
                                  - overview.cashCardActivation,
                            count: overview.cashCount
                                 + overview.cardCount
                                 + overview.otherCount
                                 + overview.mealEqCount
                                 + overview.refundCashCount
                                 + overview.refundCardCount
                                 + overview.refundOtherCount
                                 + overview.refundLoyaltyCount
                        },
                        other: {
                            count: overview.otherCount + overview.refundOtherCount,
                            amount: overview.totalOtherAmount - overview.refundOtherAmount
                        },
                        tx: {
                            amount: 0,
                            count: 0
                        },
                        tip: {
                            amount: overview.totalTipAmount,
                            count: 0
                        },
                        subtotal: {
                            amount: 0,
                            count: 0
                        },
                        total: {
                            amount: 0,
                            count: 0
                        },
                        loyalty: {
                            collected: overview.loyaltyCollected - overview.loyaltyReclaimed,
                            redeemed: overview.loyaltyRedeemed - overview.loyaltyRefunded
                        },
                        discounts: {
                            amount: overview.totalDiscounts,
                            count: overview.discountCount
                        },
                        mealUnits: {
                            amount: overview.totalFiitMealUnitAmount || 0,
                            count: overview.totalFiitMealCount || 0,
                            units: overview.totalFiitMealUnitCount || 0
                        },
                        dcb: {
                            amount: overview.totalFiitDcbAmount || 0,
                            count: overview.totalFiitDcbCount || 0
                        },
                        averageTicket: overview.averageTicket,
                        averageTips: overview.averageTips
                    };
                    $scope.results = results;

                    if ($scope.results && $scope.results.allTotals) {
                        $scope.pieData[0].y = $scope.results.allTotals.card.count;
                        $scope.pieData[0].transactions = $scope.results.allTotals.card.count;
                        $scope.pieData[0].sales = $scope.results.allTotals.card.amount;

                        $scope.pieData[1].y = $scope.results.allTotals.cash.count;
                        $scope.pieData[1].transactions = $scope.results.allTotals.cash.count;
                        $scope.pieData[1].sales = $scope.results.allTotals.cash.amount;

                        $scope.pieData[2].y = $scope.results.allTotals.mealEquivalency.count;
                        $scope.pieData[2].transactions = $scope.results.allTotals.mealEquivalency.count;
                        $scope.pieData[2].sales = $scope.results.allTotals.mealEquivalency.amount;

                        $scope.pieData[3].y = $scope.results.allTotals.other.count;
                        $scope.pieData[3].transactions = $scope.results.allTotals.other.count;
                        $scope.pieData[3].sales = $scope.results.allTotals.other.amount;

                        $scope.pieData[4].y = $scope.results.allTotals.mealUnits.count;
                        $scope.pieData[4].transactions = $scope.results.allTotals.mealUnits.count;
                        $scope.pieData[4].sales = $scope.results.allTotals.mealUnits.amount;
                        $scope.pieData[4].prefixText = $scope.results.allTotals.mealUnits.units + ' units, ';

                        $scope.pieData[5].y = $scope.results.allTotals.dcb.count;
                        $scope.pieData[5].transactions = $scope.results.allTotals.dcb.count;
                        $scope.pieData[5].sales = $scope.results.allTotals.dcb.amount;

                        $scope.pieData = Calculate.normalize($scope.pieData, 'y');

                        $scope.loyaltyPieData[0].y = $scope.results.allTotals.loyalty.collected;
                        $scope.loyaltyPieData[1].y = $scope.results.allTotals.loyalty.redeemed;
                        $scope.loyaltyPieData[1].sales = $scope.results.allTotals.loyalty.redeemed;

                        $scope.loyaltyPieData = Calculate.normalize($scope.loyaltyPieData, 'y');
                    }
                }).catch((error) => {
                    $scope.loadingSalesReport = false;
                });
            }

            function loadFifteenMinuteCount () {
                if ($scope.loadingFifteenMinuteCount) {
                    return;
                }
                var reportSearch = {};
                reportSearch.startDateTime = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                reportSearch.endDateTime = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                $scope.loadingFifteenMinuteCount = true;
                Reports.get15minutesOverview(reportSearch).$promise.then((response) => {
                    $scope.fifteenMinCount = response[0].percentizedIntervalCount;
                    $scope.tick = response[0].ticks;
                    $scope.intToDay = {0: 'm', 1: 't', 2: 'w', 3: 't', 4: 'f', 5: 's', 6: 's'};
                    $scope.loadingFifteenMinuteCount = false;
                }).catch((err) => {
                    $scope.loadingFifteenMinuteCount = false;
                });
            }

            function loadTopItemsByCount () {
                if ($scope.loadingTopItemsByCount) {
                    return;
                }
                var itemSalesSearch = {};
                itemSalesSearch.startDateTime = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                itemSalesSearch.endDateTime = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                itemSalesSearch.isAdvancedSearch = true;
                itemSalesSearch.sortBy = 'quantity';
                itemSalesSearch.ascending = true;
                itemSalesSearch.offSet = 0;
                itemSalesSearch.limit = 5;
                itemSalesSearch.locationId = $scope.reportSearch.locationId;

                $scope.loadingTopItemsByCount = true;

                // Calculate the top five selling items for display
                Reports.getOverviewItemSalesReport(itemSalesSearch).$promise.then((response) => {
                    $scope.topFiveItemsByCount = response;
                    $scope.loadingTopItemsByCount = false;
                    setTimerIfFinishedLoading();

                }).catch((error) => {
                    $scope.loadingTopItemsByCount = false;
                });
            }

            function loadTopItemsByAmount () {
                if ($scope.loadingTopItemsByAmount) {
                    return;
                }
                var itemSalesSearch = {};
                itemSalesSearch.startDateTime = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                itemSalesSearch.endDateTime = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                itemSalesSearch.isAdvancedSearch = true;
                itemSalesSearch.sortBy = 'sub_total';
                itemSalesSearch.ascending = true;
                itemSalesSearch.offSet = 0;
                itemSalesSearch.limit = 5;
                itemSalesSearch.locationId = $scope.reportSearch.locationId;

                $scope.loadingTopItemsByAmount = true;

                // Calculate the top five selling items for display
                Reports.getOverviewItemSalesReport(itemSalesSearch).$promise.then((response) => {
                    $scope.topFiveItemsByAmount = response;
                    $scope.loadingTopItemsByAmount = false;
                    setTimerIfFinishedLoading();
                }).catch((error) => {
                    $scope.loadingTopItemsByAmount = false;
                });
            }

            function loadTopCategoriesByCount () {
                if ($scope.loadingTopCategoriesByCount) {
                    return;
                }
                var topCategoriesSearch = {};
                topCategoriesSearch.startDateTime = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                topCategoriesSearch.endDateTime = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                topCategoriesSearch.isAdvancedSearch = true;
                topCategoriesSearch.sortBy = 'quantity';
                topCategoriesSearch.limit = 5;
                topCategoriesSearch.locationId = $scope.reportSearch.locationId;

                $scope.loadingTopCategoriesByCount = true;

                // Calculate the top five selling categories for display
                Reports.getOverviewTopCategories(topCategoriesSearch).$promise.then((response) => {
                    $scope.topCategoriesByCount = response;
                    $scope.loadingTopCategoriesByCount = false;
                    setTimerIfFinishedLoading();
                }).catch((error) => {
                    $scope.loadingTopCategoriesByCount = false;
                });
            }

            function loadTopCategoriesByAmount () {
                if ($scope.loadingTopCategoriesByAmount) {
                    return;
                }
                var topCategoriesSearch = {};
                topCategoriesSearch.startDateTime = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                topCategoriesSearch.endDateTime = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                topCategoriesSearch.isAdvancedSearch = true;
                topCategoriesSearch.sortBy = 'sub_total';
                topCategoriesSearch.limit = 5;
                topCategoriesSearch.locationId = $scope.reportSearch.locationId;

                $scope.loadingTopCategoriesByAmount = true;

                // Calculate the top five selling categories for display
                Reports.getOverviewTopCategories(topCategoriesSearch).$promise.then((response) => {
                    $scope.topCategoriesByAmount = response;
                    $scope.loadingTopCategoriesByAmount = false;
                    setTimerIfFinishedLoading();
                }).catch((error) => {
                    $scope.loadingTopCategoriesByAmount = false;
                });
            }

            $scope.getLoyaltyData = function () {
                var reportSearch = {};
                reportSearch.startDateTime = moment().startOf('week').valueOf();
                reportSearch.endDateTime = moment().endOf('day').valueOf();
                if ($scope.reportSearch.companyId) {
                    reportSearch.companyId = $scope.reportSearch.companyId;
                }

                Reports.getLoyaltyActivityReport(reportSearch, function (response) {
                    $scope.loyaltyPieData.length = 0;

                    $scope.loyaltyPieData = [{
                        key: 'Collected',
                        y: response.pointsEarned,
                        color: '#418C6D',
                        type: 'unit'
                    }, {
                        key: 'Redeemed',
                        y: response.pointsRedeemed,
                        sales: response.pointsRedeemed,
                        color: '#C3E4D7',
                        type: 'unit'
                    }];
                });
            };


            $scope.$watch('reportSearch.companyId', function (companyId) {
                if (companyId) {
                    var foundCompany = _.find($scope.companyHierarchy, function (company) {
                        return company.id === companyId;
                    });
                    if (foundCompany) {
                        refreshLocations([foundCompany]);
                    }
                } else {
                    var companyHierarchy = angular.copy($scope.companyHierarchy);
                    refreshLocations(companyHierarchy);
                }
            });

            $scope.saveSelectedFromDate = function () {
                var selectedFromDate = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                DateRangeService.setFromDate(selectedFromDate);
            };
            $scope.saveSelectedToDate = function () {
                var selectedToDate = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                DateRangeService.setToDate(selectedToDate);
            };

            $scope.finishedLoadingAll = function () {
                return ($scope.loadingTopItemsByCount === false && $scope.loadingTopItemsByAmount === false
                    && $scope.loadingTopCategoriesByCount === false && $scope.loadingTopCategoriesByAmount === false
                    && $scope.loadingSalesReport === false);
            };

            var setTimerIfFinishedLoading = function () {
                if ($scope.finishedLoadingAll()) {
                    autoReload = setTimeout($scope.getSalesReport, 300000);
                }
            };

            $scope.hasSearched = false;
            $scope.init = function () {
                var fromDate;
                var toDate;
                if (DateRangeService.getFromDate()) {
                    fromDate = moment(DateRangeService.getFromDate()).startOf('day').toDate();
                } else {
                    fromDate = moment().startOf('week').toDate();
                }
                if (DateRangeService.getToDate()) {
                    toDate = moment(DateRangeService.getToDate()).endOf('day').toDate();
                } else {
                    toDate = moment().endOf('day').toDate();
                }
                $scope.reportSearch = {
                    fromDate: fromDate,
                    toDate: toDate,
                    isAdvancedSearch: true
                };
                Security.loadRolePermissions().then(function () {
                    if (!Security.hasPermissionForUser('reports:overview')) {
                        $scope.changeView('end-of-shift-report');
                    } else {
                        lookupCompanyHierarchy().$promise.then(function () {
                            // Disable for now to prevent too many requests going to the server
                            // every time the reports page is openn
                            // $scope.getSalesReport();
                        });
                    }
                });
            };

            $scope.$on('$destroy', function () {
                clearTimeout(autoReload);
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.locationId', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.fromDate', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.toDate', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.init();

        }
    ]);
};
