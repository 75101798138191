'use strict';


const moment = require('moment');

module.exports = function (freshideasReports) {

    freshideasReports.controller('MealPlanSalesReport', [
        '$scope',
        '$rootScope',
        'Reports',
        'Utils',
        '$modal',
        'NgGridSortService',
        'NgGridTemplateService',
        'Lookup',
        'Meals',
        'Export',
        'Security',
        'Platform',
        function ($scope, $rootScope, Reports, Utils, $modal, NgGridSortService, NgGridTemplateService, Lookup, Meals, Export, Security, Platform) {

            $scope.isIosWebkit = Platform.isIosWebkit();
            $scope.currentUser = Security.getUser();
            $scope.isFullAdmin = ($scope.currentUser.permission === 'FULLADMIN');
            $scope.hasSearched = false;

            $scope.init = function () {
                $scope.loadCompanyHierarchy().$promise
                    .then($scope.loadCompanyMeals);
            };

            $scope.loadCompanyHierarchy = function () {
                return Lookup.companyHierarchy({}, function (response) {
                    $scope.company = _.findWhere(response, {id: $scope.currentUser.companyId});

                    $scope.locationMap = {};
                    $scope.locationOptions = $scope.company.children;
                    _.each($scope.locationOptions, function (location) {
                        location.selected = true;
                        $scope.locationMap[location.id] = location;
                    });

                    $scope.mealPlanSalesSearch.locationIds = _.map($scope.locationOptions, function (option) {
                        return option.id;
                    });
                });
            };

            $scope.loadCompanyMeals = function () {
                return Meals.getMealPlans({companyId: $scope.currentUser.companyId}, function (response) {
                    $scope.mealPlans = response.entries;

                    $scope.mealPlanMap = {};
                    _.each($scope.mealPlans, function (mealPlan) {
                        $scope.mealPlanMap[mealPlan.mealPlanId] = mealPlan;
                    });

                    $scope.dcbMealPlanOptions = _.where($scope.mealPlans, {mealPlanType: 'DCB'});
                    $scope.mealPlanOptions = _.where($scope.mealPlans, {mealPlanType: 'MEAL'});
                });
            };

            $scope.toggleLocation = function (location) {
                location.selected = !location.selected;

                var locationIds = _.map($scope.locationOptions, function (option) {
                    return (option.selected)? option.id : undefined;
                });
                locationIds = _.compact(locationIds);

                $scope.mealPlanSalesSearch.locationIds = locationIds;
            };

            $scope.toggleMealPlan = function (mealPlan) {
                mealPlan.selected = !mealPlan.selected;

                var mealPlanIds = _.map($scope.mealPlanOptions, function (option) {
                    return (option.selected)? option.mealPlanId : undefined;
                });
                mealPlanIds = _.compact(mealPlanIds);

                var dcbMealPlanIds = _.map($scope.dcbMealPlanOptions, function (option) {
                    return (option.selected)? option.mealPlanId : undefined;
                });
                dcbMealPlanIds = _.compact(dcbMealPlanIds);

                $scope.mealPlanSalesSearch.mealPlanIds = mealPlanIds;
                $scope.mealPlanSalesSearch.dcbMealPlanIds = dcbMealPlanIds;
                $scope.mealPlanSalesSearch.allMealPlanIds = mealPlanIds.concat(dcbMealPlanIds);
            };

            $scope.setSortByField = function (sortBy) {
                $scope.currentReportSearch.sortBy = sortBy;
                $scope.currentReportSearch.ascending = !$scope.currentReportSearch.ascending;
            };

            $scope.resetFilters = function () {
                $scope.mealPlanSalesSearch.startDateTime = moment().startOf('week').toDate();
                $scope.mealPlanSalesSearch.endDateTime = moment().endOf('day').toDate();

                _.each($scope.locationOptions, function (location) {
                    location.selected = true;
                });

                $scope.mealPlanSalesSearch.allMealPlanIds = [];
                $scope.mealPlanSalesSearch.dcbMealPlanIds = [];
                $scope.mealPlanSalesSearch.mealPlanIds = [];

                _.each($scope.dcbMealPlanOptions, function (option) {
                    option.selected = false;
                });
                _.each($scope.mealPlanOptions, function (option) {
                    option.selected = false;
                });
            };

            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };
            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };

            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };

            $scope.mealPlanSalesSearch = {
                isAdvancedSearch: true,
                startDateTime: moment().startOf('week').toDate(),
                endDateTime: moment().endOf('day').toDate(),
                mealPlanIds: []
            };

            $scope.loadMealPlanSales = function () {
                var mealPlanSalesSearch = {};
                mealPlanSalesSearch.startDateTime = moment($scope.mealPlanSalesSearch.startDateTime).startOf('day').valueOf();
                mealPlanSalesSearch.endDateTime = moment($scope.mealPlanSalesSearch.endDateTime).endOf('day').valueOf();
                mealPlanSalesSearch.mealPlanIds = $scope.mealPlanSalesSearch.mealPlanIds;
                mealPlanSalesSearch.dcbMealPlanIds = $scope.mealPlanSalesSearch.dcbMealPlanIds;
                mealPlanSalesSearch.allMealPlanIds = $scope.mealPlanSalesSearch.allMealPlanIds;
                mealPlanSalesSearch.locationIds = $scope.mealPlanSalesSearch.locationIds;

                $scope.mealPlanSalesDataByLocations = {};
                $scope.hasData = false;

                $scope.isLoading = true;
                $scope.hasSearched = true;

                Reports.getMealPlanSalesReport(mealPlanSalesSearch, function (response) {
                    var data = response;

                    if (data.length > 0) {
                        $scope.isLoading = false;
                        $scope.hasData = true;

                        var mealPlanSalesData = [];
                        _.each(response, function (data) {
                            var matchedMealPlan = _.findWhere($scope.mealPlans, {mealPlanId: data.mealPlanId});
                            if (matchedMealPlan
                                && mealPlanSalesSearch.allMealPlanIds.indexOf(data.mealPlanId) > -1) {
                                data.mealPlanName = matchedMealPlan.name;
                                data.mealPlanType = matchedMealPlan.mealPlanType;
                                mealPlanSalesData.push(data);
                            } else {
                                if (data.mealPlanId === null) {
                                    if (data.locationId === null) {
                                        var overallMealData = angular.copy(data);
                                        overallMealData.locationId = 'Total';
                                        overallMealData.mealPlanName = 'Overall Meal Total';
                                        overallMealData.mealPlanType = 'MEAL';
                                        overallMealData.aggregated = true;
                                        mealPlanSalesData.push(overallMealData);

                                        var overallDcbData = angular.copy(data);
                                        overallDcbData.locationId = 'Total';
                                        overallDcbData.mealPlanName = 'Overall DCB Total';
                                        overallDcbData.mealPlanType = 'DCB';
                                        overallDcbData.aggregated = true;
                                        mealPlanSalesData.push(overallDcbData);
                                    } else {
                                        var locationMealData = angular.copy(data);
                                        locationMealData.mealPlanName = 'Location Meal Total';
                                        locationMealData.mealPlanType = 'MEAL';
                                        locationMealData.aggregated = true;
                                        mealPlanSalesData.push(locationMealData);

                                        var locationDcbData = angular.copy(data);
                                        locationDcbData.mealPlanName = 'Location DCB Total';
                                        locationDcbData.mealPlanType = 'DCB';
                                        locationDcbData.aggregated = true;
                                        mealPlanSalesData.push(locationDcbData);
                                    }
                                }
                            }
                        });

                        $scope.mealPlanSalesDataByLocations = _.groupBy(mealPlanSalesData, 'locationId');
                    } else {
                        $scope.isLoading = false;
                        $scope.mealPlanSalesDataByLocations = {};
                    }

                    $scope.currentReportSearch = mealPlanSalesSearch;
                }, function (error) {
                    $scope.isLoading = false;
                    $scope.hasData = false;
                });
            };

            $scope.isDownloadingTransactions = {};
            $scope.downloadTranscationsAsBlob = function (locationId, mealPlanType, mealPlanId) {
                var transactionSearch = {
                    offset: 0,
                    limit: -1,
                    companyId: $scope.currentUser.companyId,
                    locationId: locationId,
                    startDateTime: moment($scope.currentReportSearch.startDateTime).startOf('day').valueOf(),
                    endDateTime: moment($scope.currentReportSearch.endDateTime).endOf('day').valueOf(),
                    mealPlanId: mealPlanId,
                    mealPlanType: mealPlanType
                };

                var baseUrl = '/freshideas/web/reports/mealPlanSales/mealPlanTransactions';
                var queryString = jQuery.param(transactionSearch);
                var downloadUrl = baseUrl + '?' + queryString;

                var mealPlanName = $scope.mealPlanMap[mealPlanId].name;
                mealPlanName = mealPlanName.replace(new RegExp(' ', 'g'), '_');

                var startDateString = moment(transactionSearch.startDateTime).format('YYYYMMDD');
                var endDateString = moment(transactionSearch.endDateTime).format('YYYYMMDD');
                var dateRangeString = startDateString + '-' + endDateString;

                var currentDateTimeString = moment().format('YYYYMMDDHHmmss');
                var filename = [currentDateTimeString, mealPlanName, dateRangeString].join('--');

                $scope.isDownloadingTransactions[locationId + '-' + mealPlanId] = true;
                Export.generateAndDownload(downloadUrl, filename)
                    .then(function () {
                        $scope.isDownloadingTransactions[locationId + '-' + mealPlanId] = false;
                    });
            };

            $scope.exportToPdf = function (tableId) {
                // wrap the actual export code in a `setTimeout` so that the
                // all patron spend data are generated in the UI first
                setTimeout(function () {
                    Export.tableToPdf(tableId, 'landscape');
                }, 0);
            };

            $scope.init();
        }
    ]);

};
