'use strict';

const moment = require('moment');
module.exports = function (freshideasReports) {
    freshideasReports.controller('LossPreventionReport', [
        '$scope',
        '$rootScope',
        '$location',
        '$translate',
        '$filter',
        'Reports',
        'Utils',
        '$modal',
        'NgGridSortService',
        'NgGridTemplateService',
        'Lookup',
        'Export',
        'Security',
        'Platform',
        'DateRangeService',
        function ($scope,
            $rootScope,
            $location,
            $translate,
            $filter,
            Reports,
            Utils,
            $modal,
            NgGridSortService,
            NgGridTemplateService,
            Lookup,
            Export,
            Security,
            Platform,
            DateRangeService) {

            $scope.searchDisabled = false;
            var allowRetry;
            $scope.init = function () {
                if (DateRangeService.getFromDate()) {
                    $scope.itemSalesSearch.startDateTime = moment(DateRangeService.getFromDate()).startOf('day').toDate();
                }
                if (DateRangeService.getToDate()) {
                    $scope.itemSalesSearch.endDateTime = moment(DateRangeService.getToDate()).endOf('day').toDate();
                }
                $scope.loadLossPreventionReport();
            };

            $scope.setSortByField = function (tableName, sortBy) {
                $scope.currentReportSearch[tableName].sortBy = sortBy;
                $scope.currentReportSearch[tableName].ascending = !$scope.currentReportSearch[tableName].ascending;
            };

            $scope.resetFilters = function () {
                $scope.itemSalesSearch.startDateTime = moment().startOf('week').toDate();
                $scope.itemSalesSearch.endDateTime = moment().endOf('day').toDate();
                $scope.loadLossPreventionReport();
            };

            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };

            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };

            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };

            $scope.itemSalesSearch = {
                startDateTime: moment().startOf('week').toDate(),
                endDateTime: moment().endOf('day').toDate(),
                isAdvancedSearch: true
            };

            $scope.saveSelectedFromDate = function () {
                var selectedFromDate = moment($scope.itemSalesSearch.startDateTime).startOf('day').valueOf();
                DateRangeService.setFromDate(selectedFromDate);
            };
            $scope.saveSelectedToDate = function () {
                var selectedToDate = moment($scope.itemSalesSearch.endDateTime).endOf('day').valueOf();
                DateRangeService.setToDate(selectedToDate);
            };

            $scope.getTenderType = function (transaction) {
                if (_.find(transaction.userActivityDetails, {attribute: 'cashAmount'})) {
                    return $translate.instant('reports.lossPrevention.tenderType.cash');
                } else if (_.find(transaction.userActivityDetails, {attribute: 'creditCardAmount'})) {
                    return $translate.instant('reports.lossPrevention.tenderType.creditDebitCard');
                } else {
                    return $translate.instant('reports.lossPrevention.tenderType.unknown');
                }
            };

            $scope.getTenderAmount = function (transaction) {
                var cashEntry = _.find(transaction.userActivityDetails, {attribute: 'cashAmount'});
                if (cashEntry) {
                    return parseFloat(cashEntry.value);
                }
                var creditEntry = _.find(transaction.userActivityDetails, {attribute: 'creditCardAmount'});
                if (creditEntry) {
                    return parseFloat(creditEntry.value);
                }

                return null;
            };

            $scope.getTenderMismatch = function (transaction) {
                var tenderEntry = _.find(transaction.userActivityDetails, {attribute: 'tenderMismatch'});
                if (tenderEntry) {
                    return tenderEntry.value === 'true'
                        ? $translate.instant('general.yes') : $translate.instant('general.no');
                }
                return null;
            };

            $scope.getCashDrawerActivityType = function (activityType) {
                var translateObject = 'reports.lossPrevention.cashdrawer.'+ activityType;
                if (activityType) {
                    return $translate.instant(translateObject);
                }
                return null;
            };

            $scope.getCashDrawerAmount = function (activityType, amount) {
                if (activityType == 'other') {
                    return 'N/A';
                } else if (activityType == 'payout') {
                    return '-' + $filter('currency')(amount);
                } else {
                    return $filter('currency')(amount);
                }
            };

            $scope.getCancelledCount = function (transaction) {
                var cancelledCount = _.find(transaction.userActivityDetails, {attribute: 'count'});
                if (cancelledCount) {
                    return parseInt(cancelledCount.value);
                }
                return null;
            };

            $scope.getCancelledAmount = function (transaction) {
                var cancelledAmount = _.find(transaction.userActivityDetails, {attribute: 'amount'});
                if (cancelledAmount) {
                    return parseFloat(cancelledAmount.value);
                }
                return null;
            };

            $scope.loadLossPreventionReport = function () {
                $scope.searchDisabled = true;
                clearTimeout(allowRetry);
                allowRetry = setTimeout(function () {
                    $scope.searchDisabled = false;
                }, 30000);
                $scope.itemSalesSearch.startDateTime = moment($scope.itemSalesSearch.startDateTime).startOf('day').valueOf();
                $scope.itemSalesSearch.endDateTime = moment($scope.itemSalesSearch.endDateTime).endOf('day').valueOf();

                $scope.itemSalesData = [];
                $scope.itemSalesDataSize = 0;
                $scope.isLoading = true;

                Reports.getLossPreventionReport($scope.itemSalesSearch, function (response) {
                    $scope.lossPreventionReport = response;
                    $scope.currentReportSearch = Object.assign(
                        angular.copy($scope.itemSalesSearch), {
                            noSale: {
                                sortBy: 'timestamp',
                                ascending: false
                            },
                            void: {
                                sortBy: 'timestamp',
                                ascending: false
                            },
                            refund: {
                                sortBy: 'timestamp',
                                ascending: false
                            },
                            cancel: {
                                sortBy: 'timestamp',
                                ascending: false
                            },
                            drawerActivity: {
                                sortBy: 'createdTime',
                                ascending: false
                            }
                        });
                    $scope.isLoading = false;
                }, function (error) {
                    $scope.isLoading = false;
                });
            };

            $scope.init();

            $scope.$on('$destroy', function () {
                clearTimeout(allowRetry);
            });
        }
    ]);

};
