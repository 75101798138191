'use strict';

export function promiseRetryService (freshideas) {
    freshideas.service('PromiseRetry', function () {
        var start = function (params) {
            var fn = params.fn;
            var timeout = params.timeout || 0;
            var maxRetry = params.maxRetry || 0;
            var retryCount = params.retryCount || 0;
            var interval = params.interval || 0;

            var fnPromise = fn();
            var timeoutPromise = new Promise(function (resolve, reject) {
                setTimeout(function () {
                    reject({timeout: true});
                }, timeout);
            });

            var promise = new Promise(function (resolve, reject) {

                var innerPromise = Promise.race([timeoutPromise, fnPromise]);
                innerPromise.then(function (response) {
                    resolve(response);
                }, function (error) {
                    if (error && error.timeout && retryCount < maxRetry) {
                        var retryParams = {
                            fn: fn,
                            timeout: timeout,
                            maxRetry: maxRetry,
                            retryCount: retryCount + 1,
                            interval: interval
                        };

                        setTimeout(function () {
                            start(retryParams).then(function (response) {
                                resolve(response);
                            }).catch(function (error) {
                                reject(error);
                            });
                        }, interval);
                    } else {
                        reject(error);
                    }
                });
            });
            return promise;
        };

        return {
            start
        };
    });
}
