'use strict';

const angular = require('angular');
const moment = require('moment');

export default angular.module('freshideas.utils', []).factory('Utils', [function () {

    function encodeUriQuery (val, pctEncodeSpaces) {
        return encodeURIComponent(val).
            replace(/%40/gi, '@').
            replace(/%3A/gi, ':').
            replace(/%24/g, '$').
            replace(/%2C/gi, ',').
            replace(/%20/g, (pctEncodeSpaces ? '%20' : '+'));
    }

    var Utils = {};

    /**
     * Convert undefines to empty string.
     * @param val
     * @returns {*|string}
     */
    Utils.makeSafe = function (val) {
        return val || '';
    };

    Utils.isEmpty = function (value) {
        return angular.isUndefined(value) || value === '' || value === null || value !== value;
    };

    Utils.buildUrl = function (url, params) {
        if (!params) {
            return url;
        }

        var parts = [];
        angular.forEach(params, function (value, key) {
            if (value === null || angular.isUndefined(value)) {
                return;
            }
            if (!angular.isArray(value)) {
                value = [value];
            }

            angular.forEach(value, function (v) {
                if (angular.isObject(v)) {
                    v = angular.toJson(v);
                }
                parts.push(encodeUriQuery(key) + '=' +
                encodeUriQuery(v));
            });
        });
        return url + ((url.indexOf('?') === -1) ? '?' : '&') + parts.join('&');
    };

    Utils.serverSidePagingRowNumber = function (currentPage, pageSize) {
        return currentPage === 1 ? 0 : currentPage * pageSize - pageSize;
    };

    Utils.serverSideTotalItems = function (resultsSize, currentPage, pageSize) {
        if (currentPage === 1) {
            return resultsSize < pageSize ? resultsSize : currentPage * pageSize + 1;
        } else {
            return resultsSize < pageSize ? resultsSize + (pageSize * (currentPage - 1)) : currentPage * pageSize + 1;
        }

    };

    Utils.serverSideStartRecord = function (currentPage, pageSize) {
        return currentPage * pageSize - pageSize + 1;
    };

    Utils.serverSideEndRecord = function (resultsSize, currentPage, pageSize) {
        if (resultsSize < pageSize) {
            if (currentPage === 1) {
                return resultsSize;
            } else {
                return (currentPage - 1) * pageSize + resultsSize;
            }
        } else {
            return currentPage * pageSize;
        }
    };

    Utils.buildDefaultPageSizes = function () {
        return [10, 20, 50, 100];
    };

    Utils.toNewLineDelimitedString = function (inputArray) {
        var result = '';
        _.each(inputArray, function (str) {
            result = result.concat(str).concat('\n');
        });
        return result;
    };

    /**
     * Takes the incoming date string and time and
     * converts it into a moment.
     *
     * @param dateString: 2014-08-12T04:00:00.000Z
     * @param time: {hours:3,minutes:15,seconds:0,meridian:'PM'}
     * @returns {*}
     */
    Utils.dateTimeToMoment = function (dateString, time) {
        var m;
        if (dateString) {
            m = moment(dateString);
            if (time) {
                var t = moment(time, 'HH:mm A');
                m.hour(t.hours());
                m.minute(t.minutes());
                m.second(t.seconds());
            }
        }
        return m;
    };

    /**
     * Takes the incoming date string and time and
     * converts it into millis from epoch.
     *
     * @param dateString: 2014-08-12T04:00:00.000Z
     * @param time: {hours:3,minutes:15,seconds:0,meridian:'PM'}
     * @returns {*}
     */
    Utils.dateTimeToMillis = function (dateString, time) {
        var m = this.dateTimeToMoment(dateString, time);
        if (m) {
            return m.valueOf();
        }
    };

    return Utils;
}]);
