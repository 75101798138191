'use strict';
const Decimal = require('decimal.js').default;

export default angular.module('freshideas.services.solink', [])
    .factory('SolinkService', [
        'Solink',
        'CompanyAttributesService',
        'CurrentSession',
        'SystemService',
        'SharedDataService',
        'SmbPosService',
        '$timeout',
        '$log',
        function (Solink, CompanyAttributesService, CurrentSession, SystemService, SharedDataService, SmbPosService, $timeout, $log) {

            const isSolinkEnabled = function () {
                const solinkClientSecret = CompanyAttributesService.getSolinkClientSecret();

                return solinkClientSecret && solinkClientSecret != -1;
            };

            /* Communication with the frontend */

            // Generates a simplified version of a nested receipt item that can be used with this integration
            const getSolinkItem = (originalItem) => {
                /*
                Commented by Nick Simone 2021/12/03
                We need to store the discount information with the actual item to handle the
                case where the item is removed from the basket (it is simpler to remove just
                the item than to remove the item and associated discounts as seperate entities).

                We don't include modifiers in Solink. It seems unecessary, will clutter the UI, and waste performance.
                This means that we have to use the price of the item with the price of its modifiers included.
                */

                let totalItemPrice;
                let discountObj = {};

                if (originalItem.discount && originalItem.discount.totalAmount < 0) {
                    // This item has a discount associated with it
                    if (originalItem.visualPrice) {
                        totalItemPrice = (originalItem.visualPrice - originalItem.discount.totalAmount) / originalItem.quantity;
                    } else {
                        totalItemPrice = originalItem.price;
                    }
                    discountObj = {
                        discountAmount: originalItem.discount.totalAmount / originalItem.quantity, // per item
                        discountName: originalItem.discount.name,
                    };
                } else {
                    // There is no discount on this item
                    if (originalItem.visualPrice) {
                        totalItemPrice = originalItem.visualPrice / originalItem.quantity;
                    } else {
                        totalItemPrice = originalItem.price;
                    }
                }

                const item = {
                    quantity: 1,
                    name: originalItem.name,
                    // Include the discounted amount in the price of items so discounts make sense
                    price: totalItemPrice,
                    locationServicePeriodMenuId: originalItem.locationServicePeriodMenuId,
                    discountObj: discountObj,
                };

                return item;
            };

            const updateItem = (item, transactionUuid, fullReceipt, cancelledItems) => {

                if (!isSolinkEnabled()) {
                    return;
                }

                const quantity = item.quantity;
                const prevQuantity = item.solinkItemTimes ? item.solinkItemTimes.length : 0;
                const isNew = prevQuantity === 0;

                if (quantity > prevQuantity) {
                    // We have increased the quantity of this item or it is a new item

                    if (isNew) {
                        item.solinkItemTimes = [];
                    }

                    for (let i = 0; i < quantity - prevQuantity; i++) {
                        item.solinkItemTimes.push(getCurrentTime());
                        sendItemAddedToBasket(transactionUuid, fullReceipt, cancelledItems);
                    }
                } else if (quantity < prevQuantity) {
                    // We have decreased the quantity of this item
                    let solinkItem;

                    for (let i = 0; i < prevQuantity - quantity; i++) {
                        solinkItem = getSolinkItem(item);
                        solinkItem.timeRemovedFromBasket = getCurrentTime();
                        solinkItem.timeAddedToBasket = item.solinkItemTimes.pop();
                        cancelledItems.push(solinkItem);
                        sendItemRemovedFromBasket(transactionUuid, fullReceipt, cancelledItems);
                    }
                } else {
                    // The modifier modal was opened. Just send an cart update to Solink
                    sendItemUpdated(transactionUuid, fullReceipt, cancelledItems);
                }

                return item.solinkItemTimes;
            };

            /*
            Fully cancel the item. At this point it will still be present in the fullReceipt.
            We need the information from the fullReceipt to add cancelled items, so we update
            that here and tell Solink (updateItem) after splicing the item out of the fullReceipt.
            */
            const cancelItem = (item, cancelledItems) => {
                if (!isSolinkEnabled()) {
                    return;
                }

                for (let i = 0; i < item.quantity; i++) {
                    let solinkItem = getSolinkItem(item);
                    solinkItem.timeRemovedFromBasket = getCurrentTime();
                    solinkItem.timeAddedToBasket = item.solinkItemTimes[i];
                    cancelledItems.push(solinkItem);
                }
            };

            /* Communication with the backend */

            let eventSentTimer;
            let lastEventUuid = '';

            const sendToSolink = (event) => {
                if (isSolinkEnabled()) {
                    let offlineQueueMap = getOfflineObj(KEY);
                    return Solink.sendSolinkEvent({}, event).$promise.then(
                        function (response) {
                            lastEventUuid = event.transactionUuid;
                            // Remove this from the offline queue if present
                            if (offlineQueueMap[event.transactionUuid]) {
                                delete offlineQueueMap[event.transactionUuid];
                            }
                            setOfflineObj(KEY, offlineQueueMap);
                        }).catch(function (err) {
                            // Queue this for offline mode
                            offlineQueueMap[event.transactionUuid] = event;
                            setOfflineObj(KEY, offlineQueueMap);
                        });
                }
            };


            const queueEventToSend = (event) => {
                if (event.state === 'CLOSED' || event.transactionUuid !== lastEventUuid) {
                    sendToSolink(event);
                } else {
                    $timeout.cancel(eventSentTimer);
                    eventSentTimer = $timeout(() => {
                        sendToSolink(event);
                    }, 500);
                }
            };

            const getCurrentTime = () => {
                return Date.now() - SystemService.getSystemTimeOffsetMilliseconds();
            };

            /* Offline Mode
            Right now, in order to debug freezing on the tizen kiosks, we are avoiding using local storage
            here. We can easily swap back to local storage by modifying getOfflineObject and setOfflineObject.
            */

            const KEY = 'solinkEventQueue';
            let memStorage = {};
            memStorage[KEY] = {};

            // Send the entire offline queue to Solink. We use await here to ensure these go through one-at-a-time.
            const sendEventQueue = async () => {
                let offlineQueueMap = getOfflineObj(KEY);
                for (let event in offlineQueueMap) {
                    if (event) {
                        await sendToSolink(offlineQueueMap[event]);
                    }
                }
            };

            const getOfflineObj = (KEY) => {
                return memStorage[KEY];
            };

            const setOfflineObj = (KEY, obj) => {
                memStorage[KEY] = obj;
            };

            const roundToDollarAmount = (price) => {
                // Use decimal for better rounding but return a float so it shows correctly on Solink's UI
                return parseFloat((new Decimal(price).toNearest(SharedDataService.baseDollar)).toString());
            };

            /* Payload generation */

            const addItemToItemsList = (item, list, status, cancelled = false) => {

                if (!item.timeAddedToBasket) {
                    item.timeAddedToBasket = getCurrentTime();
                }

                let type;
                let itemPrice = item.price;
                let itemTime = item.timeAddedToBasket;
                let discountType = 'discount';
                let discountAmount = item.discountObj.discountAmount;

                if (item.type && item.type === 'payment') {
                    type = 'payment';
                } else if (status === 'REFUND') {
                    type = 'return';
                } else if (status === 'VOID') {
                    type = 'void';
                } else {
                    type = 'sale';
                }

                if (cancelled) {
                    // this is a cancelled item
                    type = 'void';
                    discountType = 'void';
                    itemPrice *= -1;
                    discountAmount *= -1;
                    itemTime = item.timeRemovedFromBasket;
                }

                list.push({
                    'time': itemTime, // epoch ms
                    'quantity': Math.round(item.quantity), // Solink forces us to round this
                    'description': item.name || 'Unknown Item',
                    'unitPrice': itemPrice || 0.00,
                    'type': type, // sale, modifier, void, return etc
                });

                // Discount on this item
                if (item.discountObj && item.discountObj.discountAmount && item.discountObj.discountAmount !== 0) {
                    list.push({
                        'time': itemTime,
                        'quantity': Math.round(item.quantity),
                        'description': item.discountObj.discountName,
                        'unitPrice': discountAmount,
                        'type': discountType,
                    });
                }
            };

            const sendTransaction = (transactionUuid, transactionStartTime, receiptItems,
                removedItems, title, state, status, {receiptNumber, tenders, cashRounding, tax} = {}) => {

                if (!isSolinkEnabled()) {
                    return;
                }

                // Do not send packages solink will not recognize.
                if (!transactionUuid || !title || !(state === 'OPEN' || state === 'CLOSED') || !status) {
                    throw new Error('Not enough details specified for Solink.');
                }

                let items = [];

                let currentCompanyId = CurrentSession.getCompany().companyId;

                // Expand the list to a list of solink items all with quantity 1
                let solinkReceiptItems = [];
                let solinkItem;
                receiptItems.forEach((item) => {

                    // Refunds and voids come without item times
                    // Add current time before sending
                    if (!item.solinkItemTimes) {
                        item.solinkItemTimes = [];
                        for (let i = 0; i < item.quantity; i++) {
                            item.solinkItemTimes.push(getCurrentTime());
                        }
                    }

                    item.solinkItemTimes.forEach((time) => {
                        solinkItem = getSolinkItem(item);
                        if (status === 'REFUND' || status == 'VOID') {
                            // since all the times are the same on refunds/voids,
                            // we can use the quantity here instead of just using 1
                            solinkItem.quantity = item.quantity;
                        }
                        solinkItem.timeAddedToBasket = time;
                        solinkReceiptItems.push(solinkItem);
                    });
                });

                solinkReceiptItems.forEach((item) => {
                    addItemToItemsList(item, items, status);
                });

                removedItems.forEach((item) => {
                    // Add the items twice: once cancelled, once not - we need the
                    // timestamps from when they were first added as well
                    addItemToItemsList(item, items, status, false, false);
                    addItemToItemsList(item, items, status, true, false);
                });

                // Add tenders
                if (tenders) {
                    Array.prototype.forEach.call(tenders, (tender) => {
                        items.push(tender);
                    });
                }

                if (tax) {
                    let taxAmount = tax.amount;
                    items.push({
                        'time': tax.timeApplied,
                        'quantity': 1.0,
                        'description': 'Tax',
                        'unitPrice': taxAmount,
                        'type': 'tax',
                    });
                }

                // Add cash rounding
                if (cashRounding) {
                    let cashRoundingType;
                    if (status === 'REFUND') {
                        cashRoundingType = 'return';
                    } else if (status === 'VOID') {
                        cashRoundingType = 'void';
                    } else {
                        cashRoundingType = 'sale';
                    }
                    items.push({
                        'time': cashRounding.timeApplied,
                        'quantity': 1.0,
                        'description': 'Cash Rounding',
                        // Negate the amount - this needs to show up like a sale item
                        // and add up to the total with the other items
                        'unitPrice': cashRounding.amount * -1,
                        'type': cashRoundingType,
                    });
                }

                // Round all prices to 2 decimal places
                items.forEach((listItem) => {
                    listItem.unitPrice = roundToDollarAmount(listItem.unitPrice);
                });

                let jsonData;

                if (state == 'CLOSED') {
                    jsonData = {
                        'details': {
                            'items': items,
                            'registerId': SharedDataService.posStation.posStationName,
                            'receiptNumber': receiptNumber || 'Unknown (Offline)',
                            'storeNumber': SmbPosService.shift.location.name || '',
                            'externalId': 'default',
                        },
                        'subtitle': title,
                        'state': state,
                        'type': 'transaction',
                        'subtype': 'nown',
                        'locationExternalId': currentCompanyId.toString(),
                        'transactionId': transactionUuid,
                        'startTime': transactionStartTime - SystemService.getSystemTimeOffsetMilliseconds() || getCurrentTime(),
                        'endTime': getCurrentTime()
                    };
                } else {
                    jsonData = {
                        'details': {
                            'items': items,
                            'registerId': SharedDataService.posStation.posStationName,
                            'storeNumber': SmbPosService.shift.location.name || '',
                            'externalId': 'default',
                        },
                        'subtitle': title,
                        'state': state,
                        'type': 'transaction',
                        'subtype': 'nown',
                        'locationExternalId': currentCompanyId.toString(),
                        'transactionId': transactionUuid,
                        'startTime': transactionStartTime - SystemService.getSystemTimeOffsetMilliseconds() || getCurrentTime(),
                    };
                }

                let event = {
                    'jsonData': JSON.stringify(jsonData),
                    'companyId': currentCompanyId,
                    'transactionUuid': transactionUuid,
                    'eventType': 'transaction',
                    'nownTimestamp': getCurrentTime(),
                    'eventStatus': state
                };

                return queueEventToSend(event);
            };

            /*
                Here we do not use the pre-set companyId.
                This gives us more flexibility with the bookmark event
                so it can be used for login events etc.
            */
            const sendBookmark = (title, state, companyId, time = getCurrentTime()) => {

                if (!isSolinkEnabled()) {
                    return;
                }

                // do not send packages solink will not recognize
                if (!title || !(state === 'OPEN' || state === 'CLOSED') || !companyId) {
                    throw new Error('Not enough data provided for Solink.');
                }

                let jsonData = {
                    'details': {},
                    'type': 'bookmark',
                    'subtype': 'nown',
                    'state': state,
                    'startTime': time,
                    'endTime': time,
                    'title': title,
                    'locationExternalId': companyId.toString(),
                };

                let event = {
                    'jsonData': JSON.stringify(jsonData),
                    'companyId': companyId,
                    'eventType': 'bookmark',
                    'nownTimestamp': time,
                    'eventStatus': state
                };

                return queueEventToSend(event);
            };

            /* Events */

            const sendCompletedTransaction = (transactionUuid, transactionStartTime, items, removedItems, details) => {
                return sendTransaction(transactionUuid, transactionStartTime, items, removedItems, 'Transaction Complete', 'CLOSED', 'COMPLETE',
                    details);
            };

            const sendCancelledTransaction = (transactionUuid, transactionStartTime, items, removedItems) => {
                return sendTransaction(transactionUuid, transactionStartTime, items, removedItems, 'Transaction Cancelled', 'CLOSED', 'COMPLETE');
            };

            const sendRefundTransaction = (transactionUuid, items, details) => {
                return sendTransaction(transactionUuid, getCurrentTime(), items, [], 'Refund', 'CLOSED', 'REFUND', details);
            };

            const sendVoidTransaction = (transactionUuid, items, details) => {
                return sendTransaction(transactionUuid, getCurrentTime(), items, [], 'Transaction Voided', 'CLOSED', 'VOID', details);
            };

            const sendTransactionStart = (transactionUuid, transactionStartTime) => {
                return sendTransaction(transactionUuid, transactionStartTime, [], [], 'Transaction Started', 'OPEN', 'IN PROGRESS');
            };

            const sendHeldOrder = (transactionUuid, heldOrderStartTime, items, removedItems) => {
                return sendTransaction(transactionUuid, heldOrderStartTime, items, removedItems, 'Order Held', 'OPEN', 'HELD');
            };

            const sendHeldOrderUpdated = (transactionUuid, items, removedItems) => {
                return sendTransaction(transactionUuid, getCurrentTime(), items, removedItems, 'Held Order Updated', 'OPEN', 'HELD-UPDATED');
            };

            const sendItemAddedToBasket = (transactionUuid, items, removedItems) => {
                return sendTransaction(transactionUuid, getCurrentTime(), items, removedItems, 'Item Added To Basket', 'OPEN', 'IN PROGRESS');
            };

            const sendItemRemovedFromBasket = (transactionUuid, items, removedItems) => {
                return sendTransaction(transactionUuid, getCurrentTime(), items, removedItems, 'Item Removed From Basket', 'OPEN', 'IN PROGRESS');
            };

            const sendItemUpdated = (transactionUuid, items, removedItems) => {
                return sendTransaction(transactionUuid, getCurrentTime(), items, removedItems, 'Item Updated', 'OPEN', 'IN PROGRESS');
            };

            const sendPaymentSuccess = (transactionUuid, items, removedItems, details) => {
                return sendTransaction(transactionUuid, getCurrentTime(), items, removedItems, 'Payment Succeeded', 'OPEN', 'IN PROGRESS',
                    details);
            };

            const sendPaymentFailed = (transactionUuid, items, removedItems) => {
                return sendTransaction(transactionUuid, getCurrentTime(), items, removedItems, 'Payment Failed', 'OPEN', 'IN PROGRESS');
            };

            const sendLogin = (companyId, timestamp) => {
                return sendBookmark('Login', 'CLOSED', companyId, timestamp);
            };

            return {
                sendCompletedTransaction: sendCompletedTransaction,
                sendHeldOrder: sendHeldOrder,
                sendHeldOrderUpdated: sendHeldOrderUpdated,
                sendItemAddedToBasket: sendItemAddedToBasket,
                sendItemRemovedFromBasket: sendItemRemovedFromBasket,
                sendVoidTransaction: sendVoidTransaction,
                sendRefundTransaction: sendRefundTransaction,
                sendCancelledTransaction: sendCancelledTransaction,
                sendPaymentSuccess: sendPaymentSuccess,
                sendPaymentFailed: sendPaymentFailed,
                sendLogin: sendLogin,
                sendTransactionStart: sendTransactionStart,
                sendEventQueue: sendEventQueue,
                updateItem: updateItem,
                cancelItem: cancelItem,
            };
        }
    ]);
