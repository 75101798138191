'use strict';


module.exports = function (freshideasDirectivesCommon) {
    const SharedDataService = require('../../external/pos.data-service.js');

    freshideasDirectivesCommon
        .directive('numpad', ['$timeout', '$filter', 'EventListenerService', function ($timeout, $filter, EventListenerService) {

            var formatValue = function (type, value) {
                var formattedValue = '';
                var v;

                switch (type) {
                    case 'currency-cents-no-negative':
                        formattedValue = (isNaN(value) || value == null) ? undefined : (value * 100);
                        break;
                    case 'currency-no-negative':
                    case 'currency':
                        formattedValue = (isNaN(value) || value == null) ? undefined : $filter('centToDollar')(value * 100);
                        break;
                    case 'percentage':
                        formattedValue = (isNaN(value))? undefined : $filter('percentage')(value * 100);
                        break;
                    case 'upc':
                        formattedValue = value;
                        break;
                    case 'decimal':
                        v = value || 0;
                        formattedValue = parseFloat(v); // $filter('number')(value);
                        break;
                    default: // number
                        v = value || 0;
                        formattedValue = parseInt(v); // $filter('number')(value);
                }

                return formattedValue;
            };

            return {
                require: 'ngModel',
                restrict: 'A',
                scope: {
                    numpadType: '@',
                    ngModel: '=',
                    onChange: '=',
                    note: '=',
                    fieldType: '@',
                    numpadDisabled: '@',
                    onClose: '=' // Commented By Akash Mehta -> This function will only be CALLED IF the numpad modal is CLOSED & NOT DISMISSED
                },
                controller: [
                    '$scope',
                    '$modal',
                    function ($scope, $modal) {
                        // Allow attaching a listener to `on` so that an ng-model
                        // change can be reflected in the numpad
                        $scope.modelWatch = {
                            on: undefined
                        };

                        $scope.openNumpad = function (wideNumpad) {
                            var windowClass = 'modal-numpad modal-fullscreen-transparent modal-right';
                            if (wideNumpad) {
                                windowClass += ' modal-numpad--wide';
                            }

                            var modalInstance = $modal.open({
                                templateUrl: 'common/freshideas/numpad.tpl.html',
                                controller: 'NumpadCtrl',
                                animation: false,
                                windowClass: windowClass,
                                resolve: {
                                    type: function () {
                                        return $scope.numpadType;
                                    },
                                    initialValue: function () {
                                        return $scope.ngModel;
                                    },
                                    modelWatch: function () {
                                        return $scope.modelWatch;
                                    },
                                    note: function () {
                                        return $scope.note;
                                    }
                                },
                                backdrop: true
                            });

                            return modalInstance.result;
                        };
                    }
                ],
                link: function (scope, elem, attrs, ngModelCtrl) {
                    var isUpcNumpad = scope.numpadType === 'upc';
                    var wideNumpad = isUpcNumpad;

                    elem.addClass('input--numpad');

                    var initializeViewValue = function (value) {
                        var viewValue = formatValue(scope.numpadType, scope.ngModel);
                        ngModelCtrl.$viewValue = viewValue;
                        ngModelCtrl.$render();

                        if (scope.modelWatch.on) {
                            scope.modelWatch.on(value);
                        }
                    };
                    scope.$watch('ngModel', initializeViewValue);
                    $timeout(initializeViewValue, 0);

                    elem.on('click touch keyup', function () {
                        if (scope.numpadDisabled === 'true') {
                            return;
                        }
                        scope.openNumpad(wideNumpad).then(function (values) {
                            ngModelCtrl.$setViewValue(values.modelValue);
                            elem.blur();

                            if (scope.onChange) {
                                scope.onChange(values.modelValue, scope.fieldType);
                            }

                            if (scope.onClose) {
                                scope.onClose();
                            }
                        });
                    });

                    scope.$on('$destroy', function () {
                        elem.off('click touch keyup');
                    });
                }
            };
        }
    ]).controller('NumpadCtrl', [
        '$scope',
        '$filter',
        'type',
        'initialValue',
        'modelWatch',
        'note',
        function ($scope, $filter, type, initialValue, modelWatch, note) {
            $scope.type = type || 'number';
            $scope.note = note;

            // eg. baseCent of 1 has an exponent of 0, baseCent of 100 has an exponent of 2, etc
            var baseCurrencyExponent = Math.floor(Math.log10(SharedDataService.baseCent));
            var baseCurrencyFactor = Math.pow(10, baseCurrencyExponent);

            var value = ''; // internal
            $scope.viewValue = ''; // value to display
            $scope.modelValue = ''; // value to output

            $scope.ok = function () {
                if ($scope.type === 'number') {
                    $scope.$close({
                        type: $scope.type,
                        viewValue: parseInt($scope.viewValue),
                        modelValue: parseInt($scope.modelValue)
                    });
                } else if ($scope.type === 'decimal') {
                    $scope.$close({
                        type: $scope.type,
                        viewValue: parseFloat($scope.viewValue),
                        modelValue: parseFloat($scope.modelValue)
                    });
                } else if ($scope.type === 'currency-cents-no-negative') {
                    $scope.$close({
                        type: $scope.type,
                        viewValue: parseInt($scope.viewValue),
                        modelValue: parseInt($scope.modelValue * 100)
                    });
                } else {
                    $scope.$close({
                        type: $scope.type,
                        viewValue: $scope.viewValue,
                        modelValue: $scope.modelValue
                    });
                }


            };

            $scope.addValue = function (val) {
                if (val === 'del') {
                    value = value.slice(0, -1);
                } else if (val === 'neg') {
                    if (value.charAt(0) !== '-' && value.length > 0) {
                        value = '-'+value;
                    }
                } else {
                    // Only one decimal
                    if (val === '.' && value.indexOf('.') > -1) {
                        return;
                    }

                    // Max 3 numbers after decimal point
                    if (value.indexOf('.') > -1 && value.split('.')[1].length === 3) {
                        return;
                    }

                    value += val;
                    if (value.charAt(0) === '.') {
                        value = '0' + value;
                    }
                }

                setValue();
            };

            $scope.onUpcCodeScanned = function (upcCode) {
                if (!upcCode) {
                    return;
                }

                $scope.viewValue = upcCode;
                $scope.modelValue = upcCode;
                $scope.ok();
            };

            $scope.clearValue = function () {
                value = '';
                setValue();
            };

            $scope.keyPressed = (val) => {
                if ( 0 <= +val.key && +val.key <= 9 ) {
                    $scope.addValue(val.key);
                } else if (val.key === 'Backspace') {
                    $scope.addValue('del');
                } else if ( val.key === '-' ) {
                    $scope.addValue('neg');
                } else if ( val.key === 'Enter') {
                    $scope.ok();
                }
            };

            var setValue = function () {
                switch ($scope.type) {
                    case 'currency-cents-no-negative':
                    case 'currency-no-negative':
                    case 'currency':
                        /* var modelValue = parseInt(value);

                        $scope.viewValue = isNaN(modelValue)? undefined : $filter('centToDollar')(modelValue);
                        $scope.modelValue = isNaN(modelValue)? undefined : modelValue / 100.00; */

                        $scope.viewValue = (isNaN(value))? undefined : $filter('centToDollar')(value * baseCurrencyFactor);
                        $scope.modelValue = (value / 100 * baseCurrencyFactor);
                        break;
                    case 'percentage':
                        $scope.viewValue = (isNaN(value))? undefined : $filter('percentage')(value);
                        $scope.modelValue = (value / 100);
                        break;
                    case 'upc':
                        $scope.viewValue = value;
                        $scope.modelValue = value;
                        break;
                    default: // number
                        var v = value || 0;
                        $scope.viewValue = v; // $filter('number')(value);
                        $scope.modelValue = v;
                }
            };

            var parseInitialValue = function () {
                if (!initialValue) {
                    return;
                }

                switch ($scope.type) {
                    case 'currency-no-negative':
                    case 'currency':
                        value = Math.round(initialValue * 100 / baseCurrencyFactor) + '';
                        break;
                    case 'currency-cents-no-negative':
                        value = Math.round(initialValue / baseCurrencyFactor) + '';
                        break;
                    case 'percentage':
                        value = Math.round(initialValue * 100) + '';
                        break;
                    case 'upc':
                        value = initialValue + '';
                        break;
                    default: // number
                        value = initialValue + '';
                }
            };

            $scope.init = function () {
                parseInitialValue();
                setValue();

                modelWatch.on = function (v) {
                    value = v;
                    setValue();
                };
            };

            $scope.init();
        }
    ]).value('note', undefined);

};

