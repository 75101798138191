'use strict';


const angular = require('angular');

export default angular.module('freshideas.services.calculate', []).factory('Calculate', [function () {
        // This module accepts an array of objects and converts one or multiple numerical fields based on the conversion needed.
        // A typical input can look like this:
        //      [
        //          { key: 'key1', amount: 120, tax: 12, total: 132 },
        //          { key: 'key2', amount: 100, tax: 10, total: 110 },
        //          { key: 'key3', amount: 230, tax: 23, total: 253 },
        //          { key: 'key4', amount: 400, tax: 40, total: 440 },
        //          { key: 'key5', amount: 310, tax: 31, total: 341 }
        //      }]
        return {
            // `percentize` method converts the numerical value of a specified field into a percentage, with the highest value of the
            //      specified field from all objects as 100, while a value of 0 is considered to have a percentage of 0. The percentage
            //      value is stored as a new field called `percentized` in the original array.
            // One use case for this method is drawing a bar graph.
            // Based on the example above, a percentize method done on the "amount" field would have a output like this:
            //      [
            //          { key: 'key1', amount: 120, tax: 12, total: 132, percentized: 30 },
            //          { key: 'key2', amount: 100, tax: 10, total: 110, percentized: 25 },
            //          { key: 'key3', amount: 230, tax: 23, total: 253, percentized: 57.5 },
            //          { key: 'key4', amount: 400, tax: 40, total: 440, percentized: 100 },
            //          { key: 'key5', amount: 310, tax: 31, total: 341, percentized: 77.5 }
            //      }]
            // An additional `smooth` parameter specifies the percentage value of a 0 value. This can be useful for bar graphs where
            //      the accuracy of the bar visualizations are not as important, but rather that visualization is needed even for
            //      a relative small value.
            percentize: function (originals, field, smooth) {
                // Find the max value of the specified field. The max value should always be greater than 0
                var max = _.max(originals, field)[field] || 1;
                _.each(originals, function (original) {
                    original.percentized = max === 0 ? 0 : original[field] / max * 100;
                    if (smooth) {
                        original.percentized = original.percentized * ((100 - smooth) / 100) + smooth; // make percentized value at least 50% to allow more text to be displayed
                    }
                });
                return originals;
            },
            // percentize_multiple is similar to percentize, but can be performed on multiple fields. The percentage values are stored
            //      as a hash as percentized field, with corresponding keys as original field names
            percentize_multiple: function (originals, fields, smooth) {
                var maxes = {};
                _.each(fields, function (field) {
                    // Find the max value of all fields. The max value should always be greater than 0
                    maxes[field] = _.max(originals, field)[field] || 1;
                });
                smooth = smooth || 0;
                _.each(originals, function (original) {
                    original.percentized = {};
                    _.each(fields, function (field) {
                        var percentized = maxes[field] === 0 ? 0 : original[field] / maxes[field] * 100;
                        percentized = percentized + (100 - percentized) / 100 * smooth;
                        original.percentized[field] = percentized;
                    });
                });
                return originals;
            },
            // `normalize` method converts the numerical value of a specified field into a percentage value, where the sum of the field
            //      values from all objects is considered to have a percentage of 100. The percentage value is stored as a new field called
            //      `normalized` in the origin array. In other words, all `normalized` values should add up to 100.
            // One use case for this method is for drawing a pie graph.
            // Based on the example above, a percentize method done on the "amount" field would have a output like this:
            //      [
            //          { key: 'key1', amount: 120, tax: 12, total: 132, normalized: 12.63 },
            //          { key: 'key2', amount: 100, tax: 10, total: 110, normalized: 10.52 },
            //          { key: 'key3', amount: 230, tax: 23, total: 253, normalized: 24.21 },
            //          { key: 'key4', amount: 400, tax: 40, total: 440, normalized: 42.11 },
            //          { key: 'key5', amount: 310, tax: 31, total: 341, normalized: 32.63 }
            //      }]
            normalize: function (originals, field) {
                var sum = originals.reduce(function (sum, item) {
                    return sum + item[field];
                }, 0);
                _.each(originals, function (original) {
                    original.normalized = sum === 0 ? 0 : original[field] / sum * 100;
                });
                return originals;
            },
            max_multiple: function (originals, fields) {
                var maxes = {};
                _.each(fields, function (field) {
                    // Find the max value of all fields. The max value should always be greater than 0
                    maxes[field] = _.max(originals, field)[field] || 1;
                });
                _.each(originals, function (original) {
                    original.max = {};
                    _.each(fields, function (field) {
                        var isMax = original[field] === maxes[field];
                        original.max[field] = isMax;
                    });
                });
                return originals;
            },
            chain: function (originals, operations) {
                var self = this;
                _.each(operations, function (operation) {
                    var method = operation.method;
                    var fields = operation.fields;
                    self[method].call(self, originals, fields);
                });
                return originals;
            }
        };
    }]);
