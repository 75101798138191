'use strict';

let helpServiceModule = angular.module('freshideas.services.help', []);

helpServiceModule.factory('HelpService', [
    function () {
        let queryText = '';

        let getQueryText = function () {
            return queryText;
        };

        let setQueryText = function (text = '') {
            queryText = text;
        };

        return {
            getQueryText: getQueryText,
            setQueryText: setQueryText,
        };
    }
]);

export default helpServiceModule;
