'use strict';

const angular = require('angular');
const INTERVAL = 1000 * 60;
const WORKER_INTERVAL = 1000 * 15;
const MAX_INTERVAL = 1000 * 60 * 60;
const MAX_UPC_LENGTH = 60; // chars

export default angular
    .module('freshideas.resources.offline-transaction', [])
    .constant('TRANSACTION_TYPE', {
        FIIT: 'fiit',
        LUCOVA: 'lucova',
        GUEST: 'guest',
        FIITMPS: 'fiitmps'
    }).factory('OfflineTransactionService', [
        'CashierShift',
        'GatewayFiit',
        'TRANSACTION_TYPE',
        '$log',
        'PosStatusService',
        function (CashierShift, GatewayFiit, TRANSACTION_TYPE, $log, PosStatusService) {

            function pushTransaction (payload, callBack) {
                payload.timestamp = Date.now();
                payload.offline = true;
                payload.attempts = 0;
                payload.nextRetry = payload.timestamp + INTERVAL;

                service.push('cashiershift.tendertransaction', payload);

                if (callBack != undefined) {
                    callBack();
                }
                return payload;
            }

            function schedule () {
                setTimeout(() => {
                    service.beginProcessingTransactions();
                }, WORKER_INTERVAL);
            }

            var service = {};

            var forceRetry = false;

            service.put = function (key, val) {
                try {
                    localStorage.setItem(key, JSON.stringify(val));
                    PosStatusService.setOfflineTransactionCount(val.length);
                } catch (err) {
                    $log.error({
                        message: 'Error while writing to Local Storage',
                        context: {
                            error: err || {}
                        }
                    });
                }
            };

            service.get = function (key) {
                var value = localStorage.getItem(key);
                return value && JSON.parse(value);
            };

            service.delete = function (key) {
                return localStorage.removeItem(key);
            };

            service.push = function (key, value) {
                var arr = service.get(key);
                if (!arr || !(arr instanceof Array)) {
                    arr = [];
                }
                arr.push(value);
                service.put(key, arr);
            };

            service.pop = function (key) {
                var arr = service.get(key);
                var element = arr.shift();

                service.put(key, arr);

                return element;
            };

            service.beginProcessingTransactions = async function () {
                const items = localStorage.getItem('cashiershift.tendertransaction');
                const queue = items ? JSON.parse(items) : [];

                if (!queue || queue.length === 0) {
                    schedule();
                    return;
                }

                var retryQueue = [];
                var queueLength = queue.length;

                while (queueLength > 0) {
                    var payload = queue.pop();

                    queueLength--;

                    if (payload == undefined) {
                        continue;
                    }

                    if (!forceRetry && payload.nextRetry > Date.now()) {
                        retryQueue.push(payload);
                        continue;
                    }

                    let promise;
                    switch (payload.type) {
                        case TRANSACTION_TYPE.GUEST:
                            promise = service.tenderGuestTransaction(payload);
                            break;
                        case TRANSACTION_TYPE.LUCOVA:
                            promise = service.tenderLucovaTransaction(payload);
                            break;
                        case TRANSACTION_TYPE.FIITMPS:
                            promise = service.tenderFiitMpsTransaction(payload);
                    }

                    await promise.catch((error) => {
                        service.recordError(payload, error);
                        retryQueue.push(payload);
                    });
                }
                // save the retry queue to local storage
                // retry queue could be empty, this means all payload from the original queue
                // have been successfully processed
                PosStatusService.setOfflineTransactionCount(service.getOfflineTransactionCount());
                localStorage.setItem('cashiershift.tendertransaction', JSON.stringify(retryQueue));
                forceRetry = false;
                schedule();
            };

            service.recordError = function (payload, error) {
                payload.attempts = payload.attempts ? payload.attempts + 1 : 1;

                let multi = INTERVAL * payload.attempts;
                multi = (multi < MAX_INTERVAL) ? multi : MAX_INTERVAL;
                payload.nextRetry = Date.now() + multi;

                if (multi < MAX_INTERVAL) {
                    if (payload.attempts == 3) {
                        $log.error({
                            message: 'Transaction failed 3 times',
                            context: {
                                transaction: payload,
                                error: error || {}
                            }
                        });
                    }
                } else {
                    if (payload.attempts % 5 == 0) {
                        $log.error({
                            message: 'Transaction failed ' + payload.attempts + ' times',
                            context: {
                                transaction: payload,
                                error: error || {}
                            }
                        });
                    }
                }

                // Log any transaction that is still failing to be posted after we come back online
                if (!PosStatusService.isOffline()) {
                    const {locationId, posStationId, totalSales, transactionUuid, attempts, timestamp, nextRetry} = payload;
                    const offlineKey = `OFFLINETX:${locationId}:${posStationId}:${transactionUuid}`;
                    const offlinePayload = {
                        transactionUuid,
                        attempts,
                        queuedTimestamp: timestamp,
                        nextRetryTimestamp: nextRetry,
                        locationId,
                        posStationId,
                        totalSales
                    };
                    CashierShift.reportFailingTransaction({}, {transactionKey: offlineKey, liteTransactionPayload: JSON.stringify(offlinePayload)})
                        .$promise
                        .catch(console.error);
                }
            };

            service.tenderGuestTransaction = function (payload) {
                // Commented By Akash Mehta on December 20 2020
                // This is a PATCH to ensure any UPCs exceeding the max length expected by the backend
                // are trimmed down to an acceptable length. Else these transactions will always get rejected
                // by the backend and remain in the offline queue for eternity
                if (Array.isArray(payload.receiptItems)) {
                    payload.receiptItems.forEach((item) => {
                        if (item.upc) {
                            const upcString = '' + item.upc;
                            item.upc = upcString.slice(0, MAX_UPC_LENGTH);
                        }
                    });
                }

                return CashierShift.tenderTransaction({}, payload).$promise;
            };

            service.tenderLucovaTransaction = function (payload) {
                return Promise.resolve();
            };

            service.tenderFiitMpsTransaction = function (payload) {
                return new Promise((resolve, reject) => {
                    GatewayFiit.recordTransactionOnFiit(payload)
                        .then(resolve)
                        .catch(reject);
                });
            };

            service.pushTenderTransaction = function (payload, callBack) {
                payload['type'] = TRANSACTION_TYPE.GUEST;

                return pushTransaction(payload, callBack);
            };

            service.pushLucovaTransaction = function (payload, callBack) {
                payload['type'] = TRANSACTION_TYPE.LUCOVA;

                return pushTransaction(payload, callBack);
            };

            service.pushFiitMpsTransaction = function (payload, callback) {
                payload['type'] = TRANSACTION_TYPE.FIITMPS;

                return pushTransaction(payload, callback);
            };

            service.getOfflineTransactionCount = function () {
                const offlineTx = localStorage.getItem('cashiershift.tendertransaction');
                return offlineTx ? JSON.parse(offlineTx).length : 0;
            };

            service.retryAllTransactions = function () {
                // This flag ensures that all transactions in the queue will be posted during the next
                // rety cycle regarless of the computed payload nextRetry timestamp
                forceRetry = true;
            };

            service.copyOfflineQueueToClipboard = function () {
                let textarea;
                let result;
                const string = localStorage.getItem('cashiershift.tendertransaction');

                try {
                    textarea = document.createElement('textarea');
                    textarea.setAttribute('readonly', true);
                    textarea.setAttribute('contenteditable', true);
                    textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
                    textarea.value = string;

                    document.body.appendChild(textarea);

                    textarea.focus();
                    textarea.select();

                    const range = document.createRange();
                    range.selectNodeContents(textarea);

                    const sel = window.getSelection();
                    sel.removeAllRanges();
                    sel.addRange(range);

                    textarea.setSelectionRange(0, textarea.value.length);
                    result = document.execCommand('copy');
                } catch (err) {
                    console.error(err);
                    result = null;
                } finally {
                    document.body.removeChild(textarea);
                }

                // manual copy fallback using prompt
                if (!result) {
                    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
                    const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
                    result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
                    if (!result) {
                        return false;
                    }
                }
                return true;
            };

            // Ensures that we set the offline count right away after service initilization
            if (service.getOfflineTransactionCount() > 0) {
                PosStatusService.setOfflineTransactionCount(service.getOfflineTransactionCount());
            }

            schedule();

            return service;
        }]);
