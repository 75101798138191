'use strict';

export function mobileOrderModal (freshideas) {
    freshideas.controller('MobileOrderModalCtrl', [
        '$scope',
        '$log',
        '$modalInstance',
        'CurrentSession',
        'EnvConfig',
        'LucovaWebSocket',
        'Lucova',
        'preorder',
        'photoUrl',
        'KdsService',
        'Pure',
        'PosStatusService',
        'PosAlertService',
        function ($scope, $log, $modalInstance, CurrentSession, EnvConfig, LucovaWebSocket, Lucova, preorder, photoUrl, KdsService, Pure, PosStatusService, PosAlertService) {
            $scope.envConfig = EnvConfig;
            $scope.preorder = preorder;

            $scope.getPhotoUrl = function () {
                return EnvConfig.lucovaHost + photoUrl;
            };

            $scope.init = function () {
            };

            var pushKdsMessage = function (payload) {
                // Send update through Socket.IO
                try {
                    Lucova.manager().pushMessage({
                        terminalId: Lucova.getTerminalId(),
                        data: payload
                    },
                        function (response) { },
                        function (error) { }
                    );
                } catch (e) {
                    console.log(e);
                }
            };

            $scope.acceptOrder = function () {
                if (PosStatusService.isOffline()) {
                    PosAlertService.showAlertByName('general', {
                        title: 'general.error.offline.ttl',
                        message: 'pos.mobile.order.offline.description'
                    });
                    return;
                }
                LucovaWebSocket.acceptPreorder($scope.preorder,
                    function (response) {
                        $modalInstance.close();
                    },
                    function (err) {
                        $modalInstance.close();
                    });

                var currentCompany = CurrentSession.getCompany();
                if (!currentCompany) {
                    $log.error('Failed to get company from CurrentSession when accepting order.');
                    return;
                }

                var isKdsEnabled = currentCompany.attributes.kds_enabled === 'true';
                if (!isKdsEnabled) {
                    return;
                }

                if (!currentCompany.locationId) {
                    $log.error('Failed to get location ID from CurrentSession when accepting order.');
                    return;
                }

                var locationId = currentCompany.locationId;
                var receiptItems = [];
                for (var item of preorder.items) {
                    var receiptItem = {
                        itemName: item.name,
                        itemQuantity: item.quantity,
                        itemNotes: item.note,
                    };
                    receiptItems.push(receiptItem);
                }
                var kdsDataObject = {
                    uuid: Pure.generateUuid(),
                    guestTransaction: false,
                    locationId: locationId,
                    patronName: preorder.first_name,
                    patronPhotoUrl: photoUrl,
                    status: 0,
                    receipt: receiptItems,
                    timestamp: new Date().getTime(),
                    lastUpdated: new Date().getTime(),
                    //  TODO: Get the actual receipt counter from Lucova backend
                    receiptCounter: preorder.receipt_counter || 0
                };
                KdsService.addReceiptFiit(kdsDataObject).then(function (response) {
                    pushKdsMessage(kdsDataObject);
                }, function (error) {
                    pushKdsMessage(kdsDataObject);
                    console.log(error);
                });
            };

            $scope.declineOrder = function () {
                var id = $scope.preorder._id;

                Lucova.manager().cancelMobileOrder({}, {
                    preorder_id: id
                }, function (response) {
                    $modalInstance.close();
                }, function (error) {
                    $modalInstance.close();
                });
            };

            $scope.init();
        }
    ]);

    freshideas.controller('ChatHeadCtrl', [
        '$scope',
        '$modal',
        'LucovaWebSocket',
        'EnvConfig',
        function ($scope, $modal, LucovaWebSocket, EnvConfig) {
            $scope.modalActive = false; // Whether or not the "accept order" modal is showing
            $scope.printPendingOrders = []; // Set to result of getPrintPendingOrders()
                                            // Used for $scope.$watch to detect changes to printPendingOrders
                                            // without having to make redundant calls
            $scope.chatHeadPosition = {}; // Object showing all the positions of the chat heads (key: user_name)

            $scope.init = function () {
            };

            // Preorder user's photo URL
            $scope.getPhotoUrl = function (url) {
                return EnvConfig.lucovaHost + url;
            };

            // Returns all pending preorders (haven't been printed yet)
            $scope.getPrintPendingOrders = function () {
                var printPendingOrders = _.filter(LucovaWebSocket.getMobileOrderUsers(), function (patron) {
                    return patron.preorder[0].status === 'print_pending';
                });
                $scope.printPendingOrders = printPendingOrders;
                return printPendingOrders;
            };

            // When printPendingOrders changes:
            // Remove orders that don't exist from chatHeadPosition
            $scope.$watch(
                function () {
                    return $scope.printPendingOrders;
                },
                function (newValue, oldValue) {
                    Object.keys($scope.chatHeadPosition).forEach(function (userName) {
                        if (_.filter(newValue, {'user_name': userName}).length === 0) {
                            $scope.chatHeadPosition = _.omit($scope.chatHeadPosition, userName);
                        }
                    });
                },
                true
            );

            $scope.showModal = function (order) {
                $scope.modalActive = true;

                setTimeout(function () {
                    var modalInstance = $modal.open({
                        templateUrl: 'common/modals/modal.mobileOrder.tpl.html',
                        controller: 'MobileOrderModalCtrl',
                        animation: false,
                        backdrop: true,
                        resolve: {
                            preorder: function () {
                                return order.preorder[0];
                            },
                            photoUrl: function () {
                                return order.photo_url;
                            }
                        }
                    }, function (error) {
                        $scope.modalActive = false;
                    });

                    modalInstance.result.then(function () { // Closed using the X button
                        $scope.modalActive = false;
                    }, function () { // Closed by clicking out of modal
                        $scope.modalActive = false;
                    });
                }, 10);
            };

            // Called when user drags a chat head
            $scope.panChatHead = function (event, order) {
                // Respect screen boundaries
                if (event.center.x > window.innerWidth - 75) {
                    event.center.x = window.innerWidth - 75;
                } else if (event.center.x < 75) {
                    event.center.x = 75;
                }

                if (event.center.y > window.innerHeight - 75) {
                    event.center.y = window.innerHeight - 75;
                } else if (event.center.y < 75) {
                    event.center.y = 75;
                }

                // Set chat head position
                $scope.chatHeadPosition[order.user_name] = {
                    'x': event.center.x - 75,
                    'y': event.center.y - 75
                };
            };

            $scope.init();
        }
    ]);

}

