'use strict';


module.exports = function (posModule) {
    posModule.controller('SuspendController', [
        '$scope',
        '$modalInstance',
        'SmbPosService',
        'Locations',
        'Security',
        'sendToKdsAndBackend',
        'printSuspendedMainReceipt',
        'printSuspendedKitchenReceipts',
        'suspendMode',
        'guestTransaction',
        'currentOrder',
        'fullReceipt',
        'receipt',
        'solinkCancelledItems',
        'goToHome',
        'currentOrderBalance',
        'suspendedOrder',
        'SolinkService',
        'adjustments',
        '$log',
        function ($scope,
            $modalInstance,
            SmbPosService,
            Locations,
            Security,
            sendToKdsAndBackend,
            printSuspendedMainReceipt,
            printSuspendedKitchenReceipts,
            suspendMode,
            guestTransaction,
            currentOrder,
            fullReceipt,
            receipt,
            solinkCancelledItems,
            goToHome,
            currentOrderBalance,
            suspendedOrder,
            SolinkService,
            adjustments,
            $log) {
            $scope.kdsEnabled = Security.getUser().company.attributes.kds_enabled === 'true';
            $scope.close = function (goHome) {
                if (goHome) {
                    goToHome();
                }

                $modalInstance.close();
            };

            $scope.printOptions = {
                main: {
                    type: 'main',
                    label: 'smb.pos.modals.suspend.form.printBill',
                    selected: false
                },
                secondary: {
                    type: 'secondaryKDS',
                    label: 'smb.pos.modals.suspend.form.printToSecondary',
                    selected: false
                },
                newOnly: {
                    type: 'newOnly',
                    label: 'smb.pos.modals.suspend.form.printNewOnly',
                    selected: false,
                },
            };
            $scope.togglePrintOption = function (option) {
                option.selected = !option.selected;
                // "all" and "new only" cannot be selected at the same time
                if (option.selected) {
                    if (option.type === 'secondaryKDS') {
                        $scope.printOptions.newOnly.selected = false;
                    } else if (option.type === 'newOnly') {
                        $scope.printOptions.secondary.selected = false;
                    }
                }
            };

            $scope.emailOption = {
                label: 'smb.pos.modals.suspend.form.emailBill',
                selected: false
            };
            $scope.toggleEmailOption = function (option) {
                option.selected = !option.selected;
            };

            // Dirty check for whether or not comments was added to
            $scope.commentsModified = false;

            // Initialize passed in variables in scope
            $scope.adjustments = adjustments;
            $scope.sendToKdsAndBackend = sendToKdsAndBackend;
            $scope.printSuspendedKitchenReceipts = printSuspendedKitchenReceipts;
            $scope.suspendMode = suspendMode;
            $scope.guestTransaction = guestTransaction;
            $scope.fullReceipt = fullReceipt.map((receiptItem) => Object.assign(receiptItem, {parent: null}));
            $scope.receipt = receipt;
            $scope.solinkCancelledItems = solinkCancelledItems;
            $scope.currentOrder = currentOrder;
            $scope.currentOrderBalance = currentOrderBalance;
            $scope.suspendedOrder = suspendedOrder;

            // Initialize transaction object which will be sent to the Fiit backend
            $scope.initializeTransactionObject = function () {
                let extraDataObj = {
                    adjustments: adjustments
                };

                if (!_.isEmpty($scope.suspendedOrder)) {
                    $scope.transactionObject = $scope.suspendedOrder;
                    $scope.transactionObjectInitialState = Object.assign({}, $scope.transactionObject);

                    Object.assign($scope.transactionObject, {
                        receiptJson: JSON.stringify($scope.fullReceipt),
                        receiptNumItems: _.filter($scope.receipt, {level: 0}).length,
                        receiptTotalCostCents: $scope.currentOrderBalance.totalAmount * 100,
                        extraData: JSON.stringify(extraDataObj)
                    });
                } else {
                    var transactionObject = {
                        locationId: SmbPosService.shift.locationId,
                        receiptJson: JSON.stringify($scope.fullReceipt),
                        receiptNumItems: _.filter($scope.receipt, {level: 0}).length,
                        receiptTotalCostCents: $scope.currentOrderBalance.totalAmount * 100,
                        orderName: '',
                        orderEmail: '',
                        orderPhoneNumber: '',
                        messages: [],
                        extraData: JSON.stringify(extraDataObj)
                    };

                    if ($scope.guestTransaction) {
                        // Guest Transaction
                        transactionObject.guestTransaction = true;
                    } else {
                        // Lucova User Transaction, stored in Lucova Fiit backend
                        transactionObject.guestTransaction = false;

                        if ($scope.currentOrder.patron && $scope.currentOrder.patron.lucovaUser) {
                            transactionObject.patronPhotoUrl = $scope.currentOrder.patron.lucovaUser.photo_url;
                        }

                        // Sometimes patronKey and patronId do not exist
                        // This may occur if the Lucova user has not been registered on the Fiit backend yet
                        if ($scope.currentOrder.patron.fiitPatron
                            && $scope.currentOrder.patron.fiitPatron.patronKey) {
                            transactionObject.patronKey = $scope.currentOrder.patron.fiitPatron.patronKey;
                        }

                        if ($scope.currentOrder.patron.fiitPatron
                            && $scope.currentOrder.patron.fiitPatron.patronId) {
                            transactionObject.patronId = $scope.currentOrder.patron.fiitPatron.patronId;
                        }

                        transactionObject.orderName = $scope.currentOrder.patron.fullName;
                    }
                    $scope.transactionObject = transactionObject;
                }
            };
            $scope.messageTextArea = '';
            $scope.initializeTransactionObject();

            // Reset "emailCustomer" flag if email field is empty
            $scope.validateEmail = function (email) {
                var emailTest = /^.+@.+\..+$/;
                return emailTest.test(String(email).toLowerCase());
            };

            $scope.checkEmail = function () {
                if ($scope.emailOption.selected && $scope.transactionObject.orderEmail.length === 0) {
                    $scope.emailOption.selected = false;
                }
            };

            $scope.appendMessage = function () {
                if ($scope.messageTextArea.length > 0) {
                    $scope.transactionObject.messages.push({
                        suspendMessage: $scope.messageTextArea,
                        timestamp: new Date().getTime()
                    });
                    $scope.commentsModified = true;
                    $scope.messageTextArea = '';
                }
            };

            // Send transaction object to Fiit backend
            $scope.suspendTransaction = function (goToHome) {

                // Data for Solink
                let transactionUuid;
                let heldOrderStartTime;

                // Create a new held order
                if ($scope.suspendMode === 'new_suspend') {
                    Locations.addSuspend($scope.transactionObject).$promise.then(function (response) {
                        $scope.transactionObject.suspendId = response.suspendId;
                        printSuspendReceipts();

                        try {
                            transactionUuid = $scope.adjustments.transactionUuid;
                            heldOrderStartTime = $scope.adjustments.transactionStartTime;
                            SolinkService.sendHeldOrder(transactionUuid, heldOrderStartTime, $scope.fullReceipt, $scope.solinkCancelledItems);
                        } catch (err) {
                            $log.error('Failed to send event to Solink', err);
                        }
                        $scope.close(goToHome);
                    });
                    // Update an existing held order
                } else if (!_.isEqual($scope.transactionObject, $scope.transactionObjectInitialState)
                    || $scope.commentsModified) {
                    Locations.updateSuspend($scope.transactionObject).$promise.then(function (response) {
                        printSuspendReceipts();

                        try {
                            transactionUuid = $scope.adjustments.transactionUuid;
                            SolinkService.sendHeldOrderUpdated(transactionUuid, $scope.fullReceipt, $scope.solinkCancelledItems);
                        } catch (err) {
                            $log.error('Failed to send event to Solink', err);
                        }
                        $scope.close(goToHome);
                    });
                    // Existing held order did not change, do not update
                } else {
                    printSuspendReceipts();

                    $scope.close(goToHome);
                }
            };

            const setItemAndChildrenAsPrinted = (item) => {
                item.quantityPrintedToKitchen = item.quantity;
                item.updatedSinceLastKitchenPrint = false;
                item.createdSinceLastKitchenPrint = false;

                // We need to recursively update all modifiers attached to this
                // item as children

                if (!item.children) {
                    return;
                }

                item.children.forEach((item) => {
                    setItemAndChildrenAsPrinted(item);
                });
            };

            const setItemsAsPrinted = () => {
                // Set all items in the flat list to printed
                $scope.fullReceipt.forEach((item) => {
                    setItemAndChildrenAsPrinted(item);
                });

                /*
                Commented by Nick Simone 2021/10/07
                Here we need to update the held order because it is saved before the kitchen
                ticket was printed, and therefore doesn't know what to set the fields above to
                */
                $scope.transactionObject.receiptJson = JSON.stringify($scope.fullReceipt);
                Locations.updateSuspend($scope.transactionObject);
            };

            var printSuspendReceipts = function () {
                if ($scope.printOptions.main.selected) {
                    printSuspendedMainReceipt($scope.transactionObject);
                }

                // Send to KDS if toggled
                if ($scope.printOptions.secondary.selected) {
                    if ($scope.kdsEnabled) {
                        sendToKdsAndBackend($scope.transactionObject, null, true);
                    }

                    SmbPosService.printKitchenReceipts($scope.receipt, $scope.transactionObject,
                        {successCallback: setItemsAsPrinted, orderName: $scope.transactionObject.orderName});
                } else if ($scope.printOptions.newOnly.selected) {
                    if ($scope.kdsEnabled) {
                        sendToKdsAndBackend($scope.transactionObject, null, true);
                    }

                    SmbPosService.printNewItemsToKitchen($scope.receipt, $scope.transactionObject,
                        {successCallback: setItemsAsPrinted, orderName: $scope.transactionObject.orderName});
                } else {
                    return;
                }
            };
        }
    ]);
};
