'use strict';


const angular = require('angular');
const ngResource = require('angular-resource');

export default angular.module('freshideas.security.users', [ngResource]).service('SecurityUsers', ['$resource', 'EnvConfig', function ($resource, EnvConfig) {
    var SecurityUsers = $resource(EnvConfig.host + '/freshideas/web/users/meSession', {}, {
        'login': {method: 'GET', url: EnvConfig.host + '/freshideas/web/users/me', params: {ignoreAuthModule: true}},
        'changePassword': {method: 'POST', url: EnvConfig.host + '/freshideas/web/users/changePasswordByToken'},
        'forgotPassword': {method: 'POST', url: EnvConfig.host + '/freshideas/web/users/forgotPassword'}, // params: {ignoreAuthModule: true}},
        'logout': {method: 'POST', url: EnvConfig.host + '/freshideas/web/users/logout'},
        'getUser': {method: 'GET', url: EnvConfig.host + '/freshideas/web/users/me'},
        'getWithToken': {method: 'GET', url: EnvConfig.host + '/freshideas/web/users/meToken'},
        'seenTutorial': {method: 'POST', url: EnvConfig.host + '/freshideas/web/users/seenTutorial'},
        'me': {method: 'GET', url: EnvConfig.host + '/freshideas/web/users/me'}
    });
    return SecurityUsers;
}]);
