'use strict';

/* globals ngGridFlexibleHeightPlugin */

const angular = require('angular');
const freshideasResourcesUsers = require('../common/resources/users.js').default;
const freshideasResourcesCompany = require('../common/resources/company.js').default;
const freshideasDirectivesCommon = require('../common/freshideas/freshideas-angular-directives.js').default;
const infiniteScroll = require('ng-infinite-scroll');
require('angular-file-upload');

export default angular.module('freshideas.users', [
    freshideasResourcesUsers.name,
    freshideasResourcesCompany.name,
    freshideasDirectivesCommon.name,
    infiniteScroll,
    'angularFileUpload',
    'pascalprecht.translate'
]).controller('UsersCtrl', [
    '$scope',
    '$rootScope',
    '$modal',
    '$location',
    '$q',
    '$timeout',
    '$filter',
    'Users',
    'Patrons',
    'Company',
    'Security',
    '$translate',
    'notificationTranslationHelper',
    'ErrorUtil',
    'EnvConfig',
    'Utils',
    'NgGridSortService',
    'NgGridTemplateService',
    function (
        $scope,
        $rootScope,
        $modal,
        $location,
        $q,
        $timeout,
        $filter,
        Users,
        Patrons,
        Company,
        Security,
        $translate,
        notificationTranslationHelper,
        ErrorUtil,
        EnvConfig,
        Utils,
        NgGridSortService,
        NgGridTemplateService) {
        $scope.pageSizeOptions = Utils.buildDefaultPageSizes();
        $scope.templateUrl = $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/freshideas/web/patron/template';
        $scope.getExportUrl = function () {
            if ($scope.isPatrons === false && $scope.isLog === true) {
                return $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/freshideas/web/patron/export?patronId=' + $scope.selectedPatrons.patronId;
            } else {
                return $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/freshideas/web/patron/exportPatron?companyId=' + $scope.patronSearch.companyId;
            }
        };
        $scope.balanceAdjustmentsTemplateUrl = $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/freshideas/web/patron/templateBalanceAdjustments';
        $scope.getBalanceExportUrl = function () {
            return $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/freshideas/web/patron/exportBalanceAdjustments?companyId=' + $scope.pageInfo.selectedCompanyId;
        };
        var selectedTab = '';
        var userActive = false;
        var patronActive = false;
        Security.loadRolePermissions().then(function () {
            if (Security.hasPermissionForUser('users_patrons:users:view')) {
                selectedTab = 'users';
                userActive = true;
            } else if (Security.hasPermissionForUser('users_patrons:patrons:view')) {
                selectedTab = 'patrons';
                patronActive = true;
            }
        });
        // init pageInfo... this object will keep track of
        // what company / tab specific data should get
        // loaded
        $scope.pageInfo = {
            selectedCompanyId: 0,
            selectedPatronId: 0,
            selectedTab: selectedTab,
            // indicate what shows in the content window
            activeTab: {
                // indicate which tab is actually highlighted
                users: userActive,
                patrons: patronActive
            }
        };
        $scope.patronPagingOptions = {
            currentRowNumber: 0,
            currentPage: 1,
            pageSize: 10,
            startRecord: 0,
            endRecord: 0,
            pageSizeOptions: Utils.buildDefaultPageSizes()
        };
        $scope.userPagingOptions = {
            currentRowNumber: 0,
            currentPage: 1,
            pageSize: 10,
            startRecord: 0,
            endRecord: 0,
            pageSizeOptions: Utils.buildDefaultPageSizes()
        };
        $scope.patronLogGridOptions = {
            columnDefs: [
                {
                    field: 'mealPlanCount',
                    displayName: 'patronLog.table.mealPlanCount',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'mealPeriod',
                    displayName: 'patronLog.table.mealPeriod',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'locationName',
                    displayName: 'patronLog.table.posLocation',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'chargeAmount',
                    displayName: 'patronLog.table.chargeAmount',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'dcbAmount',
                    displayName: 'patronLog.table.dcbAmount',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'cashAmount',
                    displayName: 'patronLog.table.cashAmount',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'creditCardAmount',
                    displayName: 'patronLog.table.creditCardAmount',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'transactionDateTime',
                    displayName: 'patronLog.table.transactionDate',
                    width: '**',
                    sortable: false,
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{COL_FIELD | date:\'yyyy-MM-dd HH:mm:ss\'}}</span><div>'
                },
                {
                    field: '',
                    displayName: 'general.actions',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()">' + '<span ng-cell-text>' + '<div class="action-buttons">' + '<a ng-click="viewTransactionDetails(row.entity)" class="blue"><i class="fa fa-plus bigger-130"></i></a>' + '</div>' + '</span>' + '</div>'
                }
            ],
            plugins: [new ngGridFlexibleHeightPlugin({minHeight: 565})],
            showFooter: true,
            footerRowHeight: 30,
            rowHeight: 30,
            multiSelect: true,
            showSelectionCheckbox: true,
            selectedItems: $scope.selectedTransaction,
            enableSorting: true,
            selectWithCheckboxOnly: true,
            enableColumnResize: true,
            enableHighlighting: true,
            footerTemplate: '<div class="grid-footer"><div class="col-xs-4"></div><div class="col-xs-4"><pager align="false" ng-model="transactionPagingOptions.currentPage" ' + 'total-items="transactionPagingOptions.totalItems" items-per-page="transactionPagingOptions.pageSize" ng-change="viewPatronLog(selectedPatrons.patronId);"></pager>' + '</div><div class="col-xs-4"><span translate="general.records"/> {{transactionPagingOptions.startRecord}} - {{transactionPagingOptions.endRecord}} of {{patronTransactionCount}}</div></div>',
            data: 'transactions'
        };
        $scope.updateUsersPaging = function () {
            $scope.userPagingOptions.currentPage = 1;
            $scope.loadUsers();
        };
        $scope.updatePatronsPaging = function () {
            if ($scope.isPatrons === false && $scope.isLog === true) {
                $scope.transactionPagingOptions.currentPage = 1;
                $scope.viewPatronLog();
            } else {
                $scope.patronPagingOptions.currentPage = 1;
                $scope.loadPatrons();
            }
        };
        $scope.selectTab = function (tab) {
            $scope.pageInfo.selectedTab = tab;
            $scope.loadView();
        };
        $scope.addPatron = function () {
            var modalInstance = $modal.open({
                templateUrl: 'common/modals/modalEditPatron.tpl.html',
                controller: 'EditPatronCtrl',
                windowClass: 'modal-70',
                resolve: {
                    patron: function () {
                        return undefined;
                    },
                    companyId: function () {
                        return $scope.pageInfo.selectedCompanyId;
                    },
                    patronId: function () {
                        return undefined;
                    },
                    isEdit: function () {
                        return false;
                    }
                },
                backdrop: 'static'
            });
            modalInstance.result.then(function (user) {
                notificationTranslationHelper.notifyMessage('patrons.addPatron.success');
                $scope.loadPatrons();
            });
        };
        $scope.deletePatron = function (patronToDelete, isBatch) {

            delete patronToDelete['mealPlans'];

            return Patrons.deletePatron(patronToDelete, function (response) {
                if (!isBatch) {
                    notificationTranslationHelper.notifyMessage('patrons.deletePatron.success');
                    $scope.loadPatrons();
                }
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            }).$promise;
        };
        $scope.deleteSelectedPatrons = function () {
            var deletedPromises = [];
            _.each($scope.selectedPatrons, function (entry) {
                deletedPromises.push($scope.deletePatron(entry, true));
            });
            $q.all(deletedPromises).then(function () {
                notificationTranslationHelper.notifyMessage('patrons.deleteSelected.success');
                $scope.loadPatrons();
            });
            $scope.selectedPatrons.length = 0;
        };
        $scope.editPatron = function (patronToEdit) {
            var modalInstance = $modal.open({
                templateUrl: 'common/modals/modalEditPatron.tpl.html',
                controller: 'EditPatronCtrl',
                windowClass: 'modal-70',
                resolve: {
                    patron: function () {
                        return patronToEdit;
                    },
                    companyId: function () {
                        return patronToEdit.companyId;
                    },
                    patronId: function () {
                        return patronToEdit.patronId;
                    },
                    isEdit: function () {
                        return true;
                    }
                },
                backdrop: 'static'
            });
            modalInstance.result.then(function (user) {
                notificationTranslationHelper.notifyMessage('patrons.editPatron.success');
                $scope.loadPatrons();
            });
        };
        $scope.savePatronId = function (selectedPatronId) {
            $scope.isPatrons = false;
            $scope.isLog = true;
            $scope.transactionPagingOptions = {
                currentRowNumber: 0,
                currentPage: 1,
                pageSize: 10,
                startRecord: 0,
                endRecord: 0,
                pageSizeOptions: Utils.buildDefaultPageSizes()
            };
            $scope.selectedPatrons.patronId = selectedPatronId.patronId;
            $scope.getPatronTransactionCount();
            $scope.viewPatronLog();
        };
        $scope.viewPatronLog = function () {
            $scope.transactionSearch = {
                patronDetailId: 0,
                limit: $scope.transactionPagingOptions.pageSize
            };
            $scope.selectedPatronId = Security.getUser().companyId;
            $scope.transactionSearch.patronDetailId = $scope.selectedPatrons.patronId;
            $scope.transactionSearch.offset = Utils.serverSidePagingRowNumber($scope.transactionPagingOptions.currentPage, $scope.transactionPagingOptions.pageSize);
            $scope.transactionSearch.limit = $scope.transactionPagingOptions.pageSize;
            var patronsLogPromise = Patrons.getPatronHistory($scope.transactionSearch).$promise;
            patronsLogPromise.then(function (response) {
                $scope.transactions = response;
                $scope.transactionPagingOptions.totalItems = Utils.serverSideTotalItems(response.length, $scope.transactionPagingOptions.currentPage, $scope.transactionPagingOptions.pageSize);
                $scope.transactionPagingOptions.startRecord = Utils.serverSideStartRecord($scope.transactionPagingOptions.currentPage, $scope.transactionPagingOptions.pageSize);
                $scope.transactionPagingOptions.endRecord = Utils.serverSideEndRecord(response.length, $scope.transactionPagingOptions.currentPage, $scope.transactionPagingOptions.pageSize);
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            });
        };
        $scope.viewTransactionDetails = function (transactionItemDetail) {
            $rootScope.patronTransactionId = transactionItemDetail.transactionId;
            var modalInstance = $modal.open({
                templateUrl: 'common/modals/modalViewTransactionDetails.tpl.html',
                controller: 'viewTransactionDetailsCtrl',
                windowClass: 'modal-60',
                backdrop: 'static'
            });
            modalInstance.result.then();
        };
        $scope.loadView = function () {
            switch ($scope.pageInfo.selectedTab) {
            case 'patrons':
                $scope.initPatronsTable();
                $scope.getPatronCount();
                $scope.loadPatrons();
                break;
            case 'users':
                $scope.initUserTable();
                $scope.loadUsers();
                break;
            }
        };
        $scope.loadPatrons = function () {
            if (angular.isDefined($scope.pageInfo.selectedCompanyId)) {
                $scope.patronSearch = $scope.patronSearch || {};
                $scope.patronSearch.companyId = $scope.pageInfo.selectedCompanyId === 0 ? -1 : $scope.pageInfo.selectedCompanyId;
            }
            $scope.patronSearch.offset = Utils.serverSidePagingRowNumber($scope.patronPagingOptions.currentPage, $scope.patronPagingOptions.pageSize);
            $scope.patronSearch.limit = $scope.patronPagingOptions.pageSize;
            var patronsPromise = Patrons.listPatrons($scope.patronSearch).$promise;
            patronsPromise.then(function (response) {
                $scope.patronData = response.entries;
                $scope.patronPagingOptions.totalItems = Utils.serverSideTotalItems(response.entries.length, $scope.patronPagingOptions.currentPage, $scope.patronPagingOptions.pageSize);
                $scope.patronPagingOptions.startRecord = Utils.serverSideStartRecord($scope.patronPagingOptions.currentPage, $scope.patronPagingOptions.pageSize);
                $scope.patronPagingOptions.endRecord = Utils.serverSideEndRecord(response.entries.length, $scope.patronPagingOptions.currentPage, $scope.patronPagingOptions.pageSize);
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            });
        };
        $scope.initPatronsTable = function () {
            $scope.isPatrons = true;
            $scope.isLog = false;
            $scope.patronSearch = {
                patronKeyFilter: '',
                companyId: 0,
                offset: 0,
                showFilters: true,
                limit: $scope.patronPagingOptions.pageSize
            };

            $scope.myDefsPatron = [
                {
                    field: 'patronId',
                    displayName: 'patrons.table.patronId',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'patronKey',
                    displayName: 'patrons.table.patronName',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'patronCompanyKey',
                    displayName: 'patrons.table.patronCompanyKey',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'firstName',
                    displayName: 'patrons.table.firstName',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'lastName',
                    displayName: 'patrons.table.lastName',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'emailAddress',
                    displayName: 'patrons.table.emailAddress',
                    width: '**',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: '',
                    displayName: 'general.actions',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()">' + '<span ng-cell-text>' + '<div class="action-buttons">' + '<a ng-click="editPatron(row.entity)" class="blue" has-perm resource="users_patrons:patrons:edit_information"><i class="fa fa-pencil bigger-130"></i></a>' + '<a confirm-modal params="patronKey" entity-prefix="row.entity." confirm-message="patrons.actions.confirmDeletePatron" confirm-title="patron.table.confirmDeleteTitle" on-confirm="deletePatron(row.entity)" class="red" has-perm resource="users_patrons:patrons:delete"><i class="fa fa-trash-o bigger-130"></i></a>' + '<a ng-click="savePatronId(row.entity)" class="blue" bindonce="viewPatronLogText" bo-title="viewPatronLogText" has-perm resource="users_patrons:patrons:view_transactions"> <i class="fa fa-eye bigger-130"></i></a>' + '</div>' + '</span>' + '</div>'
                }];
            $scope.patronGridOptions = {
                plugins: [new ngGridFlexibleHeightPlugin({minHeight: 565})],
                showFooter: true,
                footerRowHeight: 30,
                rowHeight: 30,
                multiSelect: true,
                showSelectionCheckbox: true,
                selectedItems: $scope.selectedPatrons,
                enableSorting: true,
                selectWithCheckboxOnly: true,
                enableColumnResize: true,
                enableHighlighting: true,
                footerTemplate: '<div class="grid-footer"><div class="col-xs-4"></div><div class="col-xs-4"><pager align="false" ng-model="patronPagingOptions.currentPage" ' + 'total-items="patronPagingOptions.totalItems" items-per-page="patronPagingOptions.pageSize" ng-change="loadPatrons();"></pager>' + '</div><div class="col-xs-4"><span translate="general.records"/> {{patronPagingOptions.startRecord}} - {{patronPagingOptions.endRecord}} of {{patronCount}}</div></div>',
                data: 'patronData',
                init: function (grid, $scope) {
                    setTimeout(function () {
                        $scope.patronGridOptions.$gridServices.DomUtilityService.RebuildGrid(
                            $scope.patronGridOptions.$gridScope,
                            $scope.patronGridOptions.ngGrid
                        );
                    }, 10);
                },
                columnDefs: 'myDefsPatron'
            };
        };
        $scope.addUser = function () {
            var modalInstance = $modal.open({
                templateUrl: 'common/modals/modalEditUser.tpl.html',
                controller: 'EditUserCtrl',
                resolve: {
                    user: function () {
                        return undefined;
                    },
                    companyId: function () {
                        return $scope.pageInfo.selectedCompanyId;
                    },
                    isEdit: function () {
                        return false;
                    }
                },
                backdrop: 'static'
            });
            modalInstance.result.then(function (user) {
                notificationTranslationHelper.notifyMessage('users.addUser.success', {user: user.userName});
                $scope.loadUsers();
            });
        };
        $scope.deleteUser = function (userToDelete, isBatch) {
            return Users.deleteUser(userToDelete, function (response) {
                if (!isBatch) {
                    notificationTranslationHelper.notifyMessage('users.deleteUser.success');
                    $scope.loadUsers();
                }
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            }).$promise;
        };
        $scope.deleteSelectedUsers = function () {
            var deletedPromises = [];
            _.each($scope.selectedUsers, function (entry) {
                deletedPromises.push($scope.deleteUser(entry, true));
            });
            $q.all(deletedPromises).then(function () {
                notificationTranslationHelper.notifyMessage('users.deleteSelected.success');
                $scope.loadUsers();
            });
            $scope.selectedUsers.length = 0;
        };
        $scope.editUser = function (userToEdit) {
            var modalInstance = $modal.open({
                templateUrl: 'common/modals/modalEditUser.tpl.html',
                controller: 'EditUserCtrl',
                resolve: {
                    user: function () {
                        return userToEdit;
                    },
                    companyId: function () {
                        return userToEdit.companyId;
                    },
                    isEdit: function () {
                        return true;
                    }
                },
                backdrop: 'static'
            });
            modalInstance.result.then(function (user) {
                notificationTranslationHelper.notifyMessage('users.editUser.success', {user: user.userName});
                $scope.loadUsers();
            });
        };
        $scope.initUserTable = function () {
            $scope.userSearch = {
                offset: 1,
                limit: $scope.userPagingOptions.pageSize,
                companyId: $scope.pageInfo.selectedCompanyId === 0 ? -1 : $scope.pageInfo.selectedCompanyId,
                showFilters: true,
            };

            $scope.myDefsUser = [
                {
                    field: 'userId',
                    displayName: 'users.table.colUserId',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    width: '*'
                },
                {
                    field: 'username',
                    displayName: 'users.table.colUsername',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    width: '*'
                },
                {
                    field: 'email',
                    displayName: 'users.table.colEmailAddress',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    width: '**'
                },
                {
                    field: 'firstname',
                    displayName: 'users.table.colFirstName',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    width: '*'
                },
                {
                    field: 'lastname',
                    displayName: 'users.table.colLastName',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    width: '*'
                },
                {
                    field: 'roleName',
                    displayName: 'users.table.colPermission',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    width: '*'
                },
                {
                    field: '',
                    displayName: 'general.actions',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()">' + '<span ng-cell-text>' + '<div class="action-buttons">' + '<a ng-click="editUser(row.entity)" class="blue" has-perm resource="users_patrons:users:edit"><i class="fa fa-pencil bigger-130"></i></a>' + '<a class="blue" change-password-for-user user="row.entity" has-perm resource="users_patrons:users:edit_passwords"><i class="fa fa-key bigger-130" rel="popover" title="{{\'users.passwordSelfService.popover\' |translate}}"></i></a>' + '<a confirm-modal params="userName" entity-prefix="row.entity." confirm-message="users.actions.confirmDeleteUser" confirm-title="users.table.confirmDeleteTitle" on-confirm="deleteUser(row.entity)" class="red" has-perm resource="users_patrons:users:delete"><i class="fa fa-trash-o bigger-130"></i></a>' + '</div>' + '</span>' + '</div>'
                }];
            $scope.userGridOptions = {
                plugins: [new ngGridFlexibleHeightPlugin({minHeight: 565})],
                showFooter: true,
                footerRowHeight: 30,
                rowHeight: 30,
                multiSelect: true,
                showSelectionCheckbox: true,
                selectedItems: $scope.selectedUsers,
                enableSorting: true,
                selectWithCheckboxOnly: true,
                enableColumnResize: true,
                enableHighlighting: true,
                footerTemplate: '<div class="grid-footer"><div class="col-xs-4"></div><div class="col-xs-4"><pager align="false" ng-model="userPagingOptions.currentPage" ' + 'total-items="userPagingOptions.totalItems" items-per-page="userPagingOptions.pageSize" ng-change="loadUsers();"></pager>' + '</div><div class="col-xs-4"><span translate="general.records"></span> {{userPagingOptions.startRecord}} - {{userPagingOptions.endRecord}}</div></div>',
                data: 'userGridData',
                init: function (grid, $scope) {
                    setTimeout(function () {
                        $scope.userGridOptions.$gridServices.DomUtilityService.RebuildGrid(
                            $scope.userGridOptions.$gridScope,
                            $scope.userGridOptions.ngGrid
                        );
                    }, 10);
                },
                columnDefs: 'myDefsUser'
            };
        };
        $scope.getPatronCount = function () {
            $scope.patronCountSearch = {companyId: 0};
            if (angular.isDefined($scope.pageInfo.selectedCompanyId)) {
                $scope.patronCountSearch.companyId = $scope.pageInfo.selectedCompanyId === 0 ? -1 : $scope.pageInfo.selectedCompanyId;
            }
            Patrons.patronsCount($scope.patronCountSearch, function (response) {
                $scope.patronCount = response.count;
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            });
        };
        $scope.getPatronTransactionCount = function () {
            $scope.patronTransactionCountSearch = {patronTransactionId: 0};
            $scope.patronTransactionCountSearch.patronTransactionId = $scope.selectedPatrons.patronId;
            Patrons.patronsTransactionCount($scope.patronTransactionCountSearch, function (response) {
                $scope.patronTransactionCount = response.count;
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            });
        };
        $scope.loadUsers = function () {
            if (angular.isDefined($scope.userSearch)) {
                $scope.userSearch.offset = Utils.serverSidePagingRowNumber($scope.userPagingOptions.currentPage, $scope.userPagingOptions.pageSize);
                $scope.userSearch.limit = $scope.userPagingOptions.pageSize;
                $scope.userSearch.companyId = $scope.pageInfo.selectedCompanyId;
                Users.listUsers($scope.userSearch).$promise.then(function (response) {
                    $scope.userGridData = response.entries;
                    $scope.userPagingOptions.totalItems = Utils.serverSideTotalItems(response.entries.length, $scope.userPagingOptions.currentPage, $scope.userPagingOptions.pageSize);
                    $scope.userPagingOptions.startRecord = Utils.serverSideStartRecord($scope.userPagingOptions.currentPage, $scope.userPagingOptions.pageSize);
                    $scope.userPagingOptions.endRecord = Utils.serverSideEndRecord(response.entries.length, $scope.userPagingOptions.currentPage, $scope.userPagingOptions.pageSize);
                }, function (error) {
                    notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                });
            }
        };
        $scope.addMoreCompany = function () {
            if (angular.isDefined($scope.displayCompany) && $scope.displayCompany.length < $scope.filteredCompany.length) {
                $scope.displayCompany = $scope.displayCompany.concat($scope.filteredCompany.slice($scope.displayCompany.length, $scope.displayCompany.length + 40));
            }
        };
        $scope.selectCompany = function (company) {
            if ($scope.pageInfo.selectedCompanyId === company.companyId) {
                $scope.isPatrons = true;
                $scope.isLog = false;
                return;
            }
            $scope.pageInfo.selectedCompany = company;
            $scope.pageInfo.selectedCompanyId = company.companyId;
            $scope.pageInfo.selectedCompanyName = company.companyName;
            if ($scope.pageInfo.activeTab.users) {
                $scope.loadUsers();
            } else {
                $scope.isPatrons = true;
                $scope.isLog = false;
                $scope.getPatronCount();
                $scope.loadPatrons();
            }
        };
        $scope.addNewCompany = function () {
            var modalInstance = $modal.open({
                templateUrl: 'common/modals/companyModal.tpl.html',
                controller: 'EditCompanyCtrl',
                backdrop: 'static',
                // so user
                // does not
                // accidentally
                // close
                // modal
                resolve: {
                    companyId: function () {
                        return undefined;
                    }
                }
            });
            modalInstance.result.then(function (group) {
                notificationTranslationHelper.notifyMessage('users.addNewCompany.success');
                $scope.init(); // reload groups
            });
        };
        $scope.initDisplayedCompanyList = function () {
            $scope.displayCompany = $scope.filteredCompany.slice(0, 40);
        };
        $scope.filterCompany = function () {
            $scope.filteredCompany = $filter('filter')($scope.companies, $scope.companySearch);
            $scope.initDisplayedCompanyList();
        };
        $scope.$watch('companySearch', function (search) {
            if (angular.isDefined(search)) {
                $scope.filterCompany();
            }
        });
        $scope.initCompanyTree = function () {
            // define a root element to hold all other
            // groups.
            $scope.rootCompany = [{
                    companyName: 'general.rootCompanyName',
                    companyId: 0,
                    parentCompanyNumber: 0,
                    children: []
                }];
            $translate('general.rootCompanyName').then(function (text) {
                $scope.rootCompany[0].companyName = text;
            });
            $translate('users.tooltip.editCompany').then(function (text) {
                $scope.editCompanyText = text;
            });
            $translate('users.tooltip.deleteCompany').then(function (text) {
                $scope.deleteCompanyText = text;
            });
            $translate('users.tooltip.viewPatronLog').then(function (text) {
                $scope.viewPatronLogText = text;
            });
            $scope.pageInfo.selectedCompany = $scope.rootCompany[0];
            Company.liteCompanies({parentCompanyNumber: -1}, function (response) {
                // Don't 'unflatten' for
                // delegated admins
                $scope.rootCompany[0].children = response.entries;
                $scope.nestedCompany = $scope.rootCompany;
                // Do depth first search and
                // add 'depth' property to
                // nodes
                var tmpCompany = [];
                var dfs = function (node, depth) {
                    node.depth = depth;
                    tmpCompany.push(node);
                    _.each(node.children, function (node) {
                        dfs(node, depth + 1);
                    });
                };
                dfs($scope.rootCompany[0], 0);
                $scope.companies = tmpCompany;
                $scope.filteredCompany = $scope.companies;
                $scope.selectCompany($scope.filteredCompany[1]);
                $scope.filterCompany();
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            });
        };
        $scope.init = function () {
            $scope.isFullAdmin = Security.getUser().permission === 'FULLADMIN';
            $scope.selectedUsers = [];
            $scope.selectedPatrons = [];
            $scope.initCompanyTree();
            $scope.loadView();
            if (!angular.isDefined($scope.pageInfo.selectedCompanyId)) {
                var companyId = Security.getUser().companyId;
                $scope.pageInfo.selectedCompanyId = angular.isDefined(companyId) ? companyId : 0;
            }
        };
        $scope.init();
        // *************** User actions on Companies
        // ****************************************************************
        $scope.editCompany = function (company) {
            var modalInstance = $modal.open({
                templateUrl: 'common/modals/companyModal.tpl.html',
                controller: 'EditCompanyCtrl',
                resolve: {
                    companyId: function () {
                        return company.companyId;
                    }
                }
            });
            modalInstance.result.then(function (success) {
                $scope.init(); // reload companies
            });
        };
        $scope.deleteCompany = function (company) {
            Company.deleteCompany({'companyId': company.companyId}, function (success) {
                notificationTranslationHelper.notifyMessage('users.deleteCompany.success', {companyName: company.companyName});
                $scope.init();
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            });
        };
    }
]).controller('EditCompanyCtrl', [
    '$scope',
    '$modalInstance',
    '$translate',
    'Company',
    'companyId',
    'notificationTranslationHelper',
    'Utils',
    'ErrorUtil',
    function (
        $scope,
        $modalInstance,
        $translate,
        Company,
        companyId,
        notificationTranslationHelper,
        Utils,
        ErrorUtil) {
        $scope.isEdit = true;
        $scope.init = function () {
            $scope.companyId = companyId;
            if (angular.isDefined($scope.companyId)) {
                Company.get({companyId: companyId}, function (response) {
                    $scope.company = response;
                });
            } else {
                $scope.isEdit = false;
                Company.getDefaultCompany(function (response) {
                    $scope.company = response;
                });
            }
        };
        $scope.addCompany = function () {
            Company.add({}, $scope.company, function (success) {
                $modalInstance.close(success);
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            });
        };
        $scope.updateCompany = function () {
            Company.update({}, $scope.company, function (success) {
                notificationTranslationHelper.notifyMessage('users.editCompany.success', {companyName: $scope.company.companyName});
                $modalInstance.close(success);
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            });
        };
        $scope.cancelModal = function () {
            $modalInstance.dismiss('cancel');
        };
        $scope.init();
    }
]).controller('EditUserCtrl', [
    '$scope',
    '$modal',
    '$modalInstance',
    '$translate',
    'Users',
    'user',
    'companyId',
    'isEdit',
    'notificationTranslationHelper',
    'Utils',
    'ErrorUtil',
    'Security',
    'LookupService',
    'PosStatusService',
    'Settings',
    'PosAlertService',
    function (
        $scope,
        $modal,
        $modalInstance,
        $translate,
        Users,
        user,
        companyId,
        isEdit,
        notificationTranslationHelper,
        Utils,
        ErrorUtil,
        Security,
        LookupService,
        PosStatusService,
        Settings,
        PosAlertService) {
        $scope.isEdit = isEdit;
        $scope.selectedCompanyId = companyId;
        $scope.PosStatusService = PosStatusService;
        Settings.getRoles({companyId: companyId}, function (response) {
            $scope.roleTypes = _.map(response, function (e) {
                return {
                    id: e.roleId,
                    name: e.roleName
                };
            });
        });
        $scope.isFullAdmin = (Security.getUser().permission === 'FULLADMIN');
        $scope.permissionTypeOptions = [];
        // only fulladmin can create a siteadmin
        if (Security.getUser().permission === 'FULLADMIN') {
            $scope.permissionTypeOptions.push({
                id: 'SITEADMIN',
                name: 'user.siteadmin.option'
            });
        }
        $scope.permissionTypeOptions.push({
            id: 'NOBODY',
            name: 'user.role.option'
        });
        $scope.init = function () {
            if (!$scope.isEdit) {
                Users.getDefaultUser(function (response) {
                    $scope.toEdit = response;
                    $scope.toEdit.companyId = $scope.selectedCompanyId;
                });
            } else {
                $scope.toEdit = angular.copy(user);
            }
        };
        $scope.addUser = function () {
            var updatedUser = angular.copy($scope.toEdit);
            updatedUser.pin = updatedUser.pin + '';

            if (!updatedUser.roleName && updatedUser.roleId) {
                updatedUser.roleName = _.find($scope.roleTypes, {id: updatedUser.roleId}).name;
            }

            Users.add({}, updatedUser, function (success) {
                $modalInstance.close(success);
            }, function (error) {
                var data = error.data || {};
                var exception = data.exception || {};

                if (exception.appCode === 414) {
                    PosAlertService.showAlertByName('add-user-username-exists');
                } else {
                    PosAlertService.showAlertByName('add-user-fail', {
                        message: exception.message || ''
                    });
                }
            });
        };
        $scope.updateUser = function () {
            if ($scope.toEdit.permission == 'SITEADMIN') {
                $scope.toEdit.roleId = 0;
                $scope.toEdit.roleName = '';
            }
            var updatedUser = angular.copy($scope.toEdit);
            updatedUser.pin = updatedUser.pin + '';

            Users.update({}, updatedUser, function (success) {
                $modalInstance.close(success);
                // After we've updated a user's role, let's reload their role's
                // permissions
                LookupService.getRolePermissions().then(function (rolePermissions) {
                    Security.rolePermissions = rolePermissions;
                });
            }, function (error) {
                var data = error.data || {};
                var exception = data.exception || {};

                PosAlertService.showAlertByName('edit-user-fail', {
                    message: exception.message || ''
                });
            });
        };
        $scope.cancelModal = function () {
            $modalInstance.dismiss('cancel');
        };

        $scope.updatePincodeOnPopup = function () {
            var message = 'Update Pincode';
            var actionName = 'Update Pincode';
            var cancelActionName = 'Cancel Update Pincode';
            var requestedPermission = '';

            var modalInstance = $modal.open({
                templateUrl: 'common/freshideas/pincodeAuthentication.tpl.html',
                controller: 'EditUserPinCtrl',
                windowClass: 'pincode-authentication-modal',
                modal: true,
                resolve: {
                    message: function () {
                        return message;
                    },
                    actionName: function () {
                        return actionName;
                    },
                    cancelActionName: function () {
                        return cancelActionName;
                    },
                    requestedPermission: function () {
                        return requestedPermission;
                    },
                    verifyAllUserPinsForCompany: function () {
                        return false;
                    }
                }
            });

            modalInstance.result.then(function (response) {
                $scope.toEdit.pin = response;
            }, function (error) {

            });
        };

        $scope.init();
    }
]).controller('EditUserPinCtrl', [
    '$scope',
    '$controller',
    'PincodeAuthentication',
    'Security',
    'message',
    'actionName',
    'cancelActionName',
    'requestedPermission',
    'verifyAllUserPinsForCompany',
    function (
        $scope,
        $controller,
        PincodeAuthentication,
        Security,
        message,
        actionName,
        cancelActionName,
        requestedPermission,
        verifyAllUserPinsForCompany) {
        // reuse the existing PincodeAuthenticationController but override the `authenticate` function by simply passing
        // the final value back
        $controller('PincodeAuthenticationController', {
            $scope: $scope,
            PincodeAuthentication: PincodeAuthentication,
            Security: Security,
            message: message,
            actionName: actionName,
            cancelActionName: cancelActionName,
            requestedPermission: requestedPermission,
            verifyAllUserPinsForCompany: false,
            forceCheckIfManagerLogin: false,
            forceCheckIfSiteAdmin: false
        });

        $scope.authenticate = function (pin) {
            $scope.$close($scope.authenticationForm.pincode);
        };
    }
]).controller('EditPatronCtrl', [
    '$scope',
    '$rootScope',
    '$window',
    '$modalInstance',
    '$translate',
    'Meals',
    'Patrons',
    'patron',
    'companyId',
    'patronId',
    'isEdit',
    'notificationTranslationHelper',
    'Utils',
    'ErrorUtil',
    function (
        $scope,
        $rootScope,
        $window,
        $modalInstance,
        $translate,
        Meals,
        Patrons,
        patron,
        companyId,
        patronId,
        isEdit,
        notificationTranslationHelper,
        Utils,
        ErrorUtil) {
        $scope.isEdit = isEdit;
        $scope.selectedCompanyId = companyId;
        $scope.selectedPatronId = patronId;

        $scope.form = {};
        $scope.objForm = {};

        $scope.accessTypeOptions = [
            {
                id: 0,
                name: 'available.allow'
            },
            {
                id: 1,
                name: 'available.block.financialAid'
            },
            {
                id: 2,
                name: 'available.block.foodService'
            }
        ];
        $scope.init = function () {
            $scope.patronMeal = {
                companyId: $scope.selectedCompanyId,
                patronId: $scope.selectedPatronId,
                includeAll: true
            };
            if (patronId !== undefined || patronId > 0) {
                Patrons.getCompanyMeal({companyId: $scope.selectedCompanyId}, function (companyMeals) {
                    $scope.companyMeals = angular.copy(companyMeals);

                    Patrons.getPatronMealPlanList($scope.patronMeal, function (patronMealPlans) {
                        $scope.meals = angular.copy(patronMealPlans);

                        $scope.toEdit = angular.copy(patron);
                        $scope.toEdit.mealPlans = patronMealPlans;

                    }, function (error) {
                        notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                    });
                });
            }
            if (patronId === undefined || patronId === 0) {
                $scope.companyMeal = {companyId: $scope.selectedCompanyId};
                Patrons.getCompanyMeal($scope.companyMeal, function (response) {
                    _.each(response, function (mealPlan) {
                        mealPlan.isNew = true;
                    });
                    $scope.companyMeals = angular.copy(response); // save a copy of the company meals
                    $scope.meals = []; // save a copy of the oriinmeals

                    $scope.toEdit = {};
                    $scope.toEdit.companyId = $scope.selectedCompanyId;
                    $scope.toEdit.mealPlans = [];
                });
            }
        };
        $scope.isVisible = false;
        // showing adjust meal balance
        $scope.showEditPatronMealBalance = function () {
            if ($scope.isVisible === false) {
                $scope.isVisible = true;
            }
        };
        // hiding adjust meal balance
        $scope.hideEditPatronMealBalance = function () {
            if ($scope.isVisible === true) {
                $scope.isVisible = false;
            }
        };
        $scope.addPatron = function () {
            $scope.toEdit.status = 0;
            $scope.toEdit.password = '';
            Patrons.add({}, $scope.toEdit, function (success) {
                $modalInstance.close(success);
            }, function (error) {
                if (error.status === 400) {
                    var errorMessage = (error.data && error.data.error)? error.data.error : 'patron.add.invalid';
                    notificationTranslationHelper.notifyError(errorMessage, null, false);
                } else {
                    notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                }
            });
        };
        $scope.isNumberKey = function (event) {
            var charCode = event.charCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                event.preventDefault();
            }
            return true;
        };
        $scope.updatePatron = function () {
            for (var i = 0; i < $scope.toEdit.mealPlans.length; i++) {
                if ($scope.toEdit.mealPlans[i].priority === '') {
                    $scope.toEdit.mealPlans[i].priority = 0;
                }
            }
            Patrons.update({}, $scope.toEdit, function (success) {
                $modalInstance.close(success);
            }, function (error) {
                if (error.status === 400) {
                    var errorMessage = (error.data && error.data.error)? error.data.error : 'patron.add.invalid';
                    notificationTranslationHelper.notifyError(errorMessage, null, false);
                } else {
                    notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                }
            });
        };
        $scope.adjustPatronMealBalance = function () {
            Patrons.adjustMealBalance({}, $scope.toEdit, function (success) {
                if ($scope.isVisible === true) {
                    $scope.isVisible = false;
                }
            }, function (error) {
                if (error.status === 400) {
                    notificationTranslationHelper.notifyError('patron.add.invalid', null, false);
                } else {
                    notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                }
            });
        };
        $scope.cancelModal = function () {
            $modalInstance.dismiss('cancel');
        };

        $scope.init();
    }
]).controller('viewTransactionDetailsCtrl', [
    '$scope',
    '$rootScope',
    'Patrons',
    '$modalInstance',
    '$translate',
    'notificationTranslationHelper',
    'Utils',
    'ErrorUtil',
    'NgGridTemplateService',
    function (
        $scope,
        $rootScope,
        Patrons,
        $modalInstance,
        $translate,
        notificationTranslationHelper,
        Utils,
        ErrorUtil,
        NgGridTemplateService) {
        $scope.patronPagingOptions = {
            currentRowNumber: 0,
            currentPage: 1,
            pageSize: 10,
            startRecord: 0,
            endRecord: 0,
            pageSizeOptions: Utils.buildDefaultPageSizes()
        };
        $scope.init = function () {
            // VIEW TRANSACTION DETAILS TABLE IN MODAL
            $scope.transactionLogColDef = [
                {
                    field: 'quantity',
                    displayName: 'transactionDetails.table.quantity',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'name',
                    displayName: 'transactionDetails.table.name',
                    width: '**',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'total',
                    displayName: 'transactionDetails.table.total',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'taxAmount',
                    displayName: 'transactionDetails.table.tax',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'itemPrice',
                    displayName: 'transactionDetails.table.itemPrice',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'upc',
                    displayName: 'transactionDetails.table.upc',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'error',
                    displayName: 'transactionDetails.table.error',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                }
            ];
            $scope.transactionLogData = [];
            $scope.viewTransactionDetailsGridOptions = {
                columnDefs: $scope.transactionLogColDef,
                plugins: [new ngGridFlexibleHeightPlugin({minHeight: 520})],
                showFooter: true,
                footerRowHeight: 30,
                enableSorting: true,
                enableHighlighting: true,
                footerTemplate: '<div class="grid-footer"><div class="col-xs-4"></div><div class="col-xs-4"><pager align="false" ng-model="patronPagingOptions.currentPage" ' + 'total-items="patronPagingOptions.totalItems" items-per-page="patronPagingOptions.pageSize" ng-change="loadPatrons();"></pager>' + '</div><div class="col-xs-4"><span translate="general.records"/> {{patronPagingOptions.startRecord}} - {{patronPagingOptions.endRecord}}</div></div>',
                data: 'transactionItem'
            };
            $scope.patronTransactionItemDetails();
        };
        $scope.patronTransactionItemDetails = function () {
            $scope.patronTransactionItemId = $rootScope.patronTransactionId;
            $scope.transactionItemSearch = {patronTItemId: 0};
            $scope.transactionItemSearch.patronTItemId = $scope.patronTransactionItemId;
            $scope.transactionItemSearch.offset = Utils.serverSidePagingRowNumber($scope.patronPagingOptions.currentPage, $scope.patronPagingOptions.pageSize);
            $scope.transactionItemSearch.limit = $scope.patronPagingOptions.pageSize;
            Patrons.getpatronTransactionItem($scope.transactionItemSearch, function (response) {
                $scope.transactionItem = response;
                $scope.patronPagingOptions.totalItems = Utils.serverSideTotalItems(response.length, $scope.patronPagingOptions.currentPage, $scope.patronPagingOptions.pageSize);
                $scope.patronPagingOptions.endRecord = Utils.serverSideEndRecord(response.length, $scope.patronPagingOptions.currentPage, $scope.patronPagingOptions.pageSize);
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            });
        };
        $scope.cancelModal = function () {
            $modalInstance.dismiss('cancel');
        };
        $scope.init();
    }
]);
