'use strict';

module.exports = function (freshideasLocation) {
    freshideasLocation
    .directive('locationEditItem', [
        function () {
            return {
                restrict: 'E',
                templateUrl: 'locations/location-side-content/location-edit-item/location-edit-item.tpl.html',
                controller: 'LocationEditItemCtrl',
                scope: {
                    item: '=',
                    onClose: '='
                },
                link: function (scope, element, attr) {
                    console.log('location edit item');
                }
            };
        }
        ])
    .controller('LocationEditItemCtrl', [
        '$scope',
        '$modal',
        '$q',
        '$translate',
        '$location',
        'Locations',
        'LocationFactory',
        'Company',
        'PosAlertService',
        'notificationTranslationHelper',
        'ErrorUtil',
        'Inventory',
        function ($scope, $modal, $q, $translate, $location, Locations, LocationFactory, Company, PosAlertService, notificationTranslationHelper, ErrorUtil, Inventory) {

            $scope.tab = 0;

            $scope.locationFactory = LocationFactory;

            $scope.tabs = [{
                'id': 'custom',
                'name': 'Custom'
            },
            {
                'id': 'free',
                'name': 'Free'
            },
            {
                'id': 'dynamic',
                'name': 'Dynamic'
            }];

            $scope.init = function () {

                $scope.tab = 0;

                $scope.$watch('item', function () {

                    $scope.item.isFree = $scope.item.price === 0;

                    if ($scope.item.customPriceEnabled) {
                        $scope.item.priceTab = $scope.tabs[2].id;
                    } else if ($scope.item.isFree) {
                        $scope.item.priceTab = $scope.tabs[1].id;
                    } else {
                        $scope.item.priceTab = $scope.tabs[0].id;
                    }
                });
            };

            $scope.$watch('quartz', function () {});

            $scope.setTab = function (index) {
                $scope.tab = index;
            };

            $scope.cancel = function () {
                LocationFactory.dismissSideContent();
            };

            $scope.save = function () {
                if (validate()) {
                    if ($scope.item.priceTab === 'free') {
                        $scope.item.mealPlanEligible = false;
                        $scope.item.mealPlanCount = 0;
                        $scope.item.mealEquivalencyEnabled = false;
                        $scope.item.customPriceEnabled = false;
                        $scope.item.isFree = true;
                        $scope.item.price = 0;
                    } else if ($scope.item.priceTab === 'dynamic') {
                        $scope.item.mealPlanEligible = false;
                        $scope.item.mealPlanCount = 0;
                        $scope.item.mealEquivalencyEnabled = false;
                        $scope.item.customPriceEnabled = true;
                        $scope.item.isFree = false;
                        $scope.item.price = 0;
                    } else {
                        $scope.item.customPriceEnabled = false;
                        $scope.item.isFree = false;
                    }

                    console.log($scope.item);
                    console.log($scope.item.isFree);

                    LocationFactory.editMenuItem($scope.item, true);
                }
            };

            var confirmDeleteMessage = 'Are you sure you want to delete this item?';
            $scope.delete = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'common/freshideas/modalConfirmDelete.tpl.html',
                    controller: 'ConfirmDeleteModalCtrl',
                    resolve: {
                        message: function () {
                            return confirmDeleteMessage;
                        },
                        paramMap: function () {
                            return {};
                        },
                        title: function () {
                            return 'Delete Item';
                        },
                        allowCancel: function () {
                            return true;
                        }
                    }
                });
                modalInstance.result.then(function () {
                    $scope.locationFactory.deleteMenuItem($scope.item);
                });
            };

            var validate = function () {

                if ((!$scope.item.price || $scope.item.price === 0) && !$scope.item.isFree && $scope.item.priceTab === 'custom') {

                    PosAlertService.showError('error', 'Invalid Price', '', 'Please enter a valid price for this item or Mark as Free.');
                    return false;
                }

                if ((!$scope.item.mealPlanCount || $scope.item.mealPlanCount === 0) && (!$scope.isFree && $scope.item.mealPlanEligible)) {

                    PosAlertService.showError('error', 'Invalid Meal Unit', '', 'Please enter a valid meal unit count when enabling meal plan eligible.');
                    return false;
                }

                console.log($scope.item);

                return true;
            };

            $scope.$on('$destroy', function () {
                if ($scope.onClose) {
                    $scope.onClose();
                }
            });

            $scope.init();
        }
    ])
    .controller('LocationEditItemModifierCtrl', [
        '$scope',
        '$modal',
        '$q',
        '$translate',
        '$location',
        'Locations',
        'LocationFactory',
        'Company',
        'notificationTranslationHelper',
        'ErrorUtil',
        'Inventory',
        function ($scope, $modal, $q, $translate, $location, Locations, LocationFactory, Company, notificationTranslationHelper, ErrorUtil, Inventory) {

            $scope.locationFactory = LocationFactory;
            // TODO

            $scope.hideAssignModifier = function () {
                $scope.modifierPage = 0;
            };

            $scope.toggleModifierChildren = function (modifier) {
                modifier.showChildren = !modifier.showChildren;
            };

            $scope.showAssignModifier = function () {
                $scope.applicableModifiers = [];
                $scope.modifierSelectedCount = 0;

                $scope.modifierPage = 1;

                $scope.applicableModifiers = parseApplicableModifiers($scope.locationFactory.modifiers);
            };

            var parseApplicableModifiers = function (modifiers) {
              return $scope.locationFactory.modifiers;
            };

            $scope.modifierSelectedCount = 0;

            $scope.toggleModifierSelected = function (modifier) {
                modifier.selected = !modifier.selected;

                $scope.modifierSelectedCount = _.where($scope.applicableModifiers, {selected: true}).length;

                if (modifier.selected) {
                  $scope.locationFactory.addToParent($scope.item.locationServicePeriodMenuId, [modifier.id]).then(function () {
                    $scope.item.children.push(modifier);
                  });
                } else {
                  $scope.locationFactory.deleteFromParent($scope.item.locationServicePeriodMenuId, modifier.id).then(function () {
                      var matchedModifierIndex = $scope.item.children.indexOf(modifier);
                      if (matchedModifierIndex > -1) {
                          $scope.item.children.splice(matchedModifierIndex, 1);
                      }
                  });
                }
            };

            $scope.unassignModifier = function (modifier) {
                var confirmDeleteMessage = 'Are you sure you want to remove ' + modifier.name + ' as a modifier?';

                var modalInstance = $modal.open({
                    templateUrl: 'common/freshideas/modalConfirmDelete.tpl.html',
                    controller: 'ConfirmDeleteModalCtrl',
                    resolve: {
                        message: function () {
                            return confirmDeleteMessage;
                        },
                        paramMap: function () {
                            return {};
                        },
                        title: function () {
                            return 'Remove Modifier from Item';
                        },
                        allowCancel: function () {
                            return true;
                        }
                    }
                });
                modalInstance.result.then(function () {

                  $scope.locationFactory.deleteFromParent($scope.item.locationServicePeriodMenuId, modifier.id).then(function () {
                        var matchedModifierIndex = $scope.item.children.indexOf(modifier);
                        if (matchedModifierIndex > -1) {
                            $scope.item.children.splice(matchedModifierIndex, 1);
                        }
                    });
                });
            };


            $scope.modifierPage = 0;
        }
    ]);
};
