/* Used in pos.time-tracking.js to store time cards created in offline mode into Local Storage.
   An interval is set to keep retrying and send these time cards to the backend, until it is successful.*/

'use strict';

const angular = require('angular');
const INTERVAL = 1000 * 60;
const WORKER_INTERVAL = 1000 * 15;
const MAX_INTERVAL = 1000 * 60 * 60;
const moment = require('moment');

export default angular
    .module('freshideas.resources.offline-timecard', [])
    .constant('TIMECARD_ACTION', {
        CLOCKIN: 'clockin',
        CLOCKOUT: 'clockout',
        CLOCKIN_AND_CLOCKOUT: 'complete'
    }).factory('OfflineTimeCard', [
        '$rootScope',
        'Users',
        'TIMECARD_ACTION',
        '$log',
        'PosStatusService',
        'PosUsersService',
        'CommonOfflineCache',
        'Reports',
        'Pure',
        function ($rootScope, Users, TIMECARD_ACTION, $log, PosStatusService, PosUsersService, CommonOfflineCache, Reports, Pure) {
            var offline = PosStatusService.isOffline();
            $rootScope.$watch(function () {
                return PosStatusService.isOffline();
            }, function (value) {
                offline = value;
                if (!offline) {
                    service.beginProcessingTimeCards(true);
                }
            });

            var service = {};

            var pushTimeCard = function (payload, callBack) {
                // inject variables
                payload.timestamp = Date.now();
                payload.offline = true;
                payload.attempts = 0;
                payload.nextRetry = payload.timestamp + INTERVAL;

                var storedTimeCards = service.get('users.timecard');
                var foundActiveTimeCard;
                /* Check for an offline time card that hasn't been clocked out for the requested user. */
                if (storedTimeCards) {
                    foundActiveTimeCard = storedTimeCards.find(function (timeCard) {
                        return (timeCard.user.userId == payload.user.userId && !timeCard.endTime);
                    });
                }
                /* Because an offline clock-in doesn't generate a time card in the backend until
                   the POS goes back online, it makes more sense to just add an endTime to the existing
                   offline time card before pushing it to the backend instead of waiting for the clock-in
                   and then clocking out.*/
                if (foundActiveTimeCard) {
                    var filteredTimeCards = storedTimeCards.filter(function (timeCard) {
                        return (timeCard.user.userId != payload.user.userId || timeCard.endTime);
                    });
                    foundActiveTimeCard['TIMECARD_ACTION'] = TIMECARD_ACTION.CLOCKIN_AND_CLOCKOUT;
                    foundActiveTimeCard.endTime = payload.endTime;
                    filteredTimeCards.push(foundActiveTimeCard);
                    service.put('users.timecard', filteredTimeCards);
                } else {
                    service.push('users.timecard', payload);
                }

                if (callBack != undefined) {
                    callBack();
                }
                return payload;
            };

            service.clockIn = function (payload, callback) {
                payload['TIMECARD_ACTION'] = TIMECARD_ACTION.CLOCKIN;
                return pushTimeCard(payload, callback);
            };

            service.clockOut = function (payload, callback) {
                payload['TIMECARD_ACTION'] = TIMECARD_ACTION.CLOCKOUT;
                return pushTimeCard(payload, callback);
            };

            service.put = function (key, val) {
                localStorage.setItem(key, JSON.stringify(val));
            };

            service.get = function (key) {
                var value = localStorage.getItem(key);
                return value && JSON.parse(value);
            };

            service.delete = function (key) {
                return localStorage.removeItem(key);
            };

            service.push = function (key, value) {
                var arr = service.get(key);
                if (!arr || !(arr instanceof Array)) {
                    arr = [];
                }
                arr.push(value);
                service.put(key, arr);
            };

            service.pop = function (key) {
                var arr = service.get(key);
                var element = arr.shift();

                service.put(key, arr);

                return element;
            };

            service.beginProcessingTimeCards = function (ignoreNextRetry) {
                var timecards = service.get('users.timecard');

                if (!timecards || !timecards.length) {
                    return;
                }
                service.delete('users.timecard');

                timecards.forEach((timecard) => {
                    if (timecard == undefined) {
                        return;
                    }
                    if (!ignoreNextRetry && timecard.nextRetry > Date.now()) {
                        service.push('users.timecard', timecard);
                        return;
                    }
                    switch (timecard.TIMECARD_ACTION) {
                        case 'clockin':
                            service.clockInUser(timecard);
                            break;
                        case 'clockout':
                            service.clockOutUser(timecard);
                            break;
                        case 'complete':
                            service.completeTimeCard(timecard);
                            break;
                    }
                });
            };

            service.handleError = function (payload, error) {
                payload.attempts = payload.attempts ? payload.attempts + 1 : 1;

                let multi = INTERVAL * payload.attempts;
                multi = (multi < MAX_INTERVAL) ? multi : MAX_INTERVAL;
                payload.nextRetry = Date.now() + multi;

                if (payload.attempts == 3) {
                    $log.error({
                        message: 'Clock-In/Clock-Out failed 3 times',
                        context: {
                            timecard: payload,
                            error: error || {}
                        }
                    });
                }
                service.push('users.timecard', payload);
            };

            service.clockInUser = function (payload) {
                var promise = Users.addOfflineTimecard({}, payload).$promise;

                return promise.then(function (response) {
                    Users.getClockedInUsers({}, function (response) {
                        PosUsersService.setClockedInUsers(response);
                        CommonOfflineCache.saveClockedInUsers(response);
                    });
                }).catch(function (error) {
                    service.handleError(payload, error);
                });
            };

            service.clockOutUser = function (payload) {
                var promise = Users.updateTimecard({}, payload).$promise;

                return promise.then(function (response) {
                    Users.getClockedInUsers({}, function (response) {
                        PosUsersService.setClockedInUsers(response);
                        CommonOfflineCache.saveClockedInUsers(response);
                    });
                    var userId = response.user.userId;
                    var duration = moment.duration(response.endTime - response.startTime);
                    var newMinutes = Math.floor(duration.as('minutes'));
                    var currentWeekLabourSearch = {
                        userId: userId,
                        startDateTime: moment().startOf('week').valueOf(),
                        endDateTime: moment().endOf('week').valueOf(),
                    };
                    var timeCard = response;
                    Reports.getLabourReport(currentWeekLabourSearch, function (response) {
                        var newLabourData = Pure.calculateNewLabour(userId, newMinutes, timeCard, response);
                        Users.addLabour(newLabourData);
                    });
                }).catch(function (error) {
                    service.handleError(payload, error);
                });
            };

            service.completeTimeCard = function (payload) {
                var promise = Users.addOfflineTimecard({}, payload).$promise;

                return promise.then(function (response) {
                    var userId = response.user.userId;
                    var duration = moment.duration(response.endTime - response.startTime);
                    var newMinutes = Math.floor(duration.as('minutes'));
                    var currentWeekLabourSearch = {
                        userId: userId,
                        startDateTime: moment().startOf('week').valueOf(),
                        endDateTime: moment().endOf('week').valueOf(),
                    };
                    var timeCard = response;
                    Reports.getLabourReport(currentWeekLabourSearch, function (response) {
                        var newLabourData = Pure.calculateNewLabour(userId, newMinutes, timeCard, response);
                        Users.addLabour(newLabourData);
                    });
                    Users.getClockedInUsers({}, function (response) {
                        PosUsersService.setClockedInUsers(response);
                        CommonOfflineCache.saveClockedInUsers(response);
                    });
                }).catch(function (error) {
                    service.handleError(payload, error);
                });
            };

            setInterval(() => {
                service.beginProcessingTimeCards(false);
            }, WORKER_INTERVAL);

            return service;
        }]);
