'use strict';


module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ProductCategorySelectCtrl', [
        '$scope',
        'ProductsService',
        'actions',
        function ($scope, ProductsService, actions) {

            $scope.execute = function (type) {
                var action = actions[type];
                if (action) {
                    action();
                }
            };

        }])
        .value('actions', {});
};
