'use strict';


(function () {
    var TaxRuleService = (function () {

        var rules = {};

        var TaxRule = {
            // Fetch a tax rule by key, but only return certain info
            get: function (key) {
                var result = {};

                var rule = rules[key];
                if (rule) {
                    result.name = rule.name;
                    result.key = rule.key;
                    result.enableTag = rule.enableTag;
                }

                return result;
            },
            // Determines whether a tax rule can be enabled. Allows user to turn
            // on/off the rule
            canEnable: function (key, ...args) {
                var rule = rules[key];

                return (rule && rule.canEnable)? rule.canEnable(...args) : false;
            },
            // Determines whether a tax rule has been enabled
            isEnabled: function (key, ...args) {
                var rule = rules[key];

                return (rule && rule.isEnabled)? rule.isEnabled(...args) : false;
            },
            // Perform action based on the rule, eg. transform a parameter object if the
            // tax rule is enabled.
            run: function (key, ...args) {
                var rule = rules[key];

                if (rule && rule.run) {
                    rule.run(...args);
                }
            },
            runAll: function (...args) {
                Object.keys(rules).forEach(function (key) {
                    TaxRule.run(key, ...args);
                });
            },
            findByName: function (name) {
                var result = {};

                var rule = _.findWhere(rules, {name: name});
                if (rule) {
                    result.name = rule.name;
                    result.key = rule.key;
                    result.enableTag = rule.enableTag;
                }

                return result;
            }
        };

        require('./tax-rules/ca-on-prepared-goods.js')(rules);
        require('./tax-rules/us-ny-nyc-clothing-footwear.js')(rules);

        return TaxRule;
    })();

    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = TaxRuleService;
    } else {
        window.TaxRuleService = TaxRuleService;
    }

})();
