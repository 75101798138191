'use strict';

const angular = require('angular');
const ngResources = require('angular-resource');

export default angular.module('freshideas.resources.homebase', [ngResources])
    .service('Homebase', ['$resource', 'EnvConfig', function ($resource, EnvConfig) {
        return $resource(EnvConfig.host + '/freshideas/web/homebase', {}, {
            'getHomebaseConnection': {
                method: 'GET'
            },
            'connectToHomebase': {
                method: 'POST'
            },
            'disconnectFromHomebase': {
                method: 'DELETE'
            },
            'getHomebaseCompanyIntegrations': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/homebase/locations',
                isArray: true
            },
            'integrateCompanies': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/homebase/locations',
                isArray: true
            },
            'deleteCompanyIntegration': {
                method: 'DELETE',
                url: EnvConfig.host + '/freshideas/web/homebase/locations'
            },
            'importHomebaseEmployees': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/homebase/import'
            },
            'getHomebaseEmployeeStatus': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/homebase/employeeStatus'
            },
        });
    }]);
