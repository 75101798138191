'use strict';

const angular = require('angular');

module.exports = function (freshideasReports) {
    freshideasReports.controller('SingleTransactionCtrl', [
        '$scope',
        '$translate',
        'CardTerminal',
        'CashierShift',
        'TerminalCommon',
        'Platform',
        'GIFTCARD_SOURCE',
        'selectedTransaction',
        'currencyCode',
        function (
            $scope,
            $translate,
            CardTerminal,
            CashierShift,
            TerminalCommon,
            Platform,
            GIFTCARD_SOURCE,
            selectedTransaction,
            currencyCode) {
            $scope.isIosWebkit = Platform.isIosWebkit();
            $scope.isElectron = Platform.isElectron();
            $scope.selectedTransaction = selectedTransaction;
            $scope.currencyCode = currencyCode;
            $scope.GIFTCARD_SOURCE = GIFTCARD_SOURCE;

            $scope.transactionSearch = {
                transactionId: $scope.selectedTransaction.transactionId,
                offset: 0,
                limit: 1
            };

            var sortTransactionItemsAsPerIndex = function (a, b) {
                if (a.itemIndex < b.itemIndex) {
                    return -1;
                } else {
                    return 1;
                }
            };

            $scope.originalReceiptItemMap = {};
            var setInformation = function (transaction) {
                transaction.totalItemQuantity = 0;
                _.each(transaction.items, function (item) {
                    $scope.originalReceiptItemMap[item.receiptItemId] = item;
                    transaction.totalItemQuantity += item.quantity;
                });
                _.each($scope.transactionInfo.tenders, function (tender) {
                    _parseTransactionCreditCardNumber($scope.transactionInfo, tender);
                });

                $scope.transactionInfo.items.sort(sortTransactionItemsAsPerIndex);
            };

            var fetchTransaction = function () {
                var transactionSearch = angular.copy($scope.transactionSearch);
                transactionSearch.findAcrossOrganization = true;

                CashierShift.searchTransactions(transactionSearch)
                    .$promise.then((response) => {
                        if (!response) {
                            return;
                        }
                        $scope.transactionInfo = response.entries[0];
                        setInformation($scope.transactionInfo);
                    });
            };

            var _parseTransactionCreditCardNumber = function (selectedTransaction, selectedTender) {
                for (var tender of selectedTransaction.tenders) {
                    if ( tender.transactionType === 'CREDIT' && tender === selectedTender) {
                        var creditTender = tender;
                    }
                }

                if ( creditTender ) {
                    var posStation = selectedTransaction.posStation;
                    if (!creditTender.terminalResponse) {
                        creditTender._identifier = $translate.instant('smb.pos.tenderType.manualEntry');
                    } else {
                        _grabCCFromStation(creditTender, posStation);
                    }
                }
            };

            var _grabCCFromStation = function ( tender, posStation ) {
                var terminalConfig = posStation.cardTerminalProperties;
                var terminalType = terminalConfig.type;
                TerminalCommon.init(terminalConfig);
                var parsedResponse = CardTerminal.parseResponse(tender.terminalResponse, terminalType);

                // Setting tender info
                tender._parsedResponse = parsedResponse;
                tender._identifier = parsedResponse.maskedCardNumber;
            };

            fetchTransaction();
        }
    ]);
};
