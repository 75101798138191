'use strict';

export function mevBoxService (freshideas) {
    freshideas.factory('MevBoxService', [
        'Platform',
        'EnvConfig',
        'MEV_STATUS',
        'SharedDataService',
        'CompanyAttributesService',
        function (Platform, EnvConfig, MEV_STATUS, SharedDataService, CompanyAttributesService) {
            const OP_CODE_PRINTER_STATUS = -2;

            const CASH_DRAW_CMD = '\x1b\x40\x1b\x70\x30\x0d\x0a';
            const CUT_CMD = '\x1d\x56\x01\x0d\x0a';

            const RESPONSE_CODE_LENGTH = 4;
            const RESPONSE_CODES = {
                4: 'PRINTER_READY',
                5: 'PRINTER_NOT_READY',
                3: 'LOGOUT',
                1: 'VALID_DATA',
                2: 'NO_EXTRA_DATA',
                1024: 'ERROR_INCOMPLETE_OPCODE_TIMEOUT',
                1028: 'ERROR_DATA_LEN_MAX',
                1026: 'ERROR_DATA_LEN_MIN',
                1027: 'ERROR_16MB_LIMIT_EXCEEDED',
                1029: 'ERROR_INVALID_OP_CODE',
                1030: 'ERROR_SRM_CLOSED'
            };
            const SRM_PORT = 8888;

            const packXML = (xml) => {
                var byteArray = [];
                var buffer = Buffer.from(xml, 'latin1');
                for (var i = 0; i < buffer.length; i++) {
                    byteArray.push(buffer[i]);
                }

                return Buffer.from(byteArray);
            };

            const longToByteArray = (long) => {
                // we want to represent the input as a 8-bytes array
                var byteArray = [0, 0, 0, 0];

                for (var index = 0; index < byteArray.length; index++) {
                    var byte = long & 0xff;
                    byteArray[index] = byte;
                    long = (long - byte) / 256;
                }

                return Buffer.from(byteArray);
            };

            const getSecureSocket = () => {
                return new Promise(async (resolve, reject) => {
                    let {ipAddress} = getSrmDevice();
                    if (!ipAddress) {
                        return reject('No Srm Device Ip Address Available');
                    }

                    let tls = nodeRequire('tls');
                    let options = {
                        port: SRM_PORT,
                        host: ipAddress,
                        rejectUnauthorized: false,
                        servername: ipAddress,
                        requestCert: true
                    };
                    const socket = tls.connect(options, () => resolve(socket));
                    socket.addListener('error', (error) => reject(error));
                });
            };

            /**
             * Sends either xml payload or command op code
             */
            const send = ({xml, opcode}) => {
                return new Promise(async (resolve, reject) => {
                    if (!opcode && !xml) {
                        return reject({success: false, message: 'Invalid parameter'});
                    }
                    let socket = await getSecureSocket().catch((error) => { });

                    if (!socket) {
                        return reject({success: false, message: 'Unable to open socket connection'});
                    }

                    let timeout = null;
                    let commandQueue = [];

                    let beginWrite = () => {
                        var command = commandQueue.shift();
                        if (!command) {
                            socket.destroy();
                            return;
                        }
                        socket.write(command);
                    };

                    socket.addListener('close', () => { });
                    socket.addListener('data', (data) => {
                        if (timeout) {
                            clearTimeout(timeout);
                            timeout = null;
                        }
                        if (data) {
                            if (data.length === RESPONSE_CODE_LENGTH) {
                                var responseCode = data.readInt32LE();
                                var responseType = RESPONSE_CODES[responseCode];
                                if (opcode || responseCode === 2) {
                                    resolve({success: true, responseCode, responseType});
                                }
                            } else {
                                var xmlResponse = data.toString('utf-8');
                                if (xml) {
                                    resolve({success: true, xmlResponse});
                                }
                            }
                            beginWrite();
                        } else {
                            reject({success: false});
                        }
                    });

                    if (opcode) {
                        commandQueue.push(longToByteArray(opcode));
                    } else if (xml) {
                        let dataBuffer = packXML(xml);
                        let dataLengthBuffer = longToByteArray(dataBuffer.length);
                        commandQueue.push(dataLengthBuffer);
                        commandQueue.push(dataBuffer);
                    }

                    beginWrite();
                });
            };

            const delay = (timeout) => {
                return new Promise(async (resolve, reject) => {
                    setTimeout(resolve, timeout);
                });
            };

            const openCashDrawer = () => {
                return new Promise(async (resolve, reject) => {
                    const xml = `<reqMEV><demandeIR noVersionIR="v01.00"><![CDATA[${CASH_DRAW_CMD}]]></demandeIR></reqMEV>`;
                    let status = undefined;
                    let retryCount = 0;

                    do {
                        status = await getStatus();
                        if (!status || status !== MEV_STATUS.OK) {
                            await delay(1000);
                        }
                        retryCount++;
                    } while ((!status || status !== MEV_STATUS.OK) && retryCount < 4);

                    if (status === MEV_STATUS.OK) {
                        send({xml}).then(resolve).catch(reject);
                    } else {
                        reject('Printer is not ready');
                    }
                });
            };

            const amountFormat = (number) => {
                let tempNumber = number ? Math.abs(number) : 0;
                let floatString = tempNumber.toFixed(2);
                if (floatString.length < 9) {
                    floatString = '0'.repeat(9 - floatString.length) + floatString;
                }
                floatString = (number < 0 ? '-' : '+') + floatString;

                return floatString;
            };

            const nownTenderTypeToMevType = (nownTenderType) => {
                let mevTenderType;
                switch (nownTenderType) {
                    case 'CASH':
                        mevTenderType = 'ARG';
                        break;
                    case 'CREDIT':
                    case 'CREDIT_VISA':
                    case 'CREDIT_MASTERCARD':
                    case 'CREDIT_AMEX':
                        mevTenderType = 'CRE';
                        break;
                    case 'DEBIT':
                        mevTenderType = 'DEB';
                        break;
                    case 'GIFTCARD':
                    case 'OTHER':
                    case 'LOYALTY':
                        mevTenderType = 'AUT';
                        break;
                    default:
                        mevTenderType = 'ARG';
                        break;
                }
                return mevTenderType;
            };

            const printReceipt = ({body, transactionSubTotal, transactionTotal, transactionTaxQst, transactionTaxGst, receiptId,
                transactionType, transactionDateTime, isDuplicate, isCustomerCopy, openDrawer, iconUrl, trainingMode, tenderTypes,
                isSplitTransaction, remainingBalance, isTransactionAlreadyInitialized}) => {
                return new Promise(async (resolve, reject) => {
                    /**
                     * etatDoc
                     * modeTrans
                        O (Operational)
                        F (Training)
                     * typeTrans
                     * autreCompte
                     * serveurTrans
                     * tableTrans
                     * comptoir
                     */
                    /**
                     *  duplicata reimpression
                        N          N         => Client receipt
                        N          O         => Client receipt
                        O          N         => Merchant
                    */
                    let hasRemainingBalance = remainingBalance && remainingBalance > 0;
                    if (isCustomerCopy && hasRemainingBalance && isSplitTransaction) {
                        // We do not want to print the customer copy until the end
                        return resolve();
                    }

                    // map nown transaction type to mev transaction type
                    // check that every tender type in the array is the same
                    let paiementTrans;
                    let singleTenderTypeUsed = tenderTypes.every((val, i, arr) => val === arr[0]);
                    if (tenderTypes.length > 1
                        || !singleTenderTypeUsed
                        || isTransactionAlreadyInitialized) {
                        paiementTrans = 'MIX';
                    } else {
                        paiementTrans = nownTenderTypeToMevType(tenderTypes[0] || 'AUT');
                    }

                    if (transactionType == 'VOID'
                        || transactionType == 'REFUND'
                        || transactionType == 'REFUND_PARTIAL') {
                        transactionTotal = -transactionTotal;
                        transactionSubTotal = -transactionSubTotal;

                        transactionTaxQst = -transactionTaxQst;
                        transactionTaxGst = -transactionTaxGst;
                    }

                    let cData = CUT_CMD;

                    if (openDrawer) {
                        cData += ' ' + CASH_DRAW_CMD;
                    }

                    let reimpression;
                    let duplicata;

                    if (!isCustomerCopy) {
                        duplicata = 'O';
                        reimpression = 'N';
                    } else {
                        duplicata = 'N';
                        reimpression = isDuplicate ? 'O' : 'N';
                    }

                    let modeTrans = trainingMode ? 'F' : 'O';
                    let xml = '<reqMEV><trans noVersionTrans="v02.00" '
                        + 'etatDoc="I" modeTrans="' + modeTrans + '" '
                        + 'duplicata="' + duplicata + '">'
                        + '<doc><graphique><![CDATA[]]></graphique><texte><![CDATA[' + body + '\n\n\n]]></texte></doc>'
                        + '<alignement><![CDATA[' + cData + ']]></alignement>'
                        + '<donneesTrans typeTrans="RFER" '
                        + 'reimpression="' + reimpression + '" '
                        + 'autreCompte="S" serveurTrans="" tableTrans="" '
                        + 'paiementTrans="' + paiementTrans + '" '
                        + 'comptoir="O" '
                        + 'numeroTrans="' + receiptId + '" '
                        + 'dateTrans="' + transactionDateTime + '" '
                        + 'mtTransAvTaxes="' + amountFormat(transactionSubTotal) + '" '
                        + 'TPSTrans="' + amountFormat(transactionTaxGst) + '" '
                        + 'TVQTrans="' + amountFormat(transactionTaxQst) + '" '
                        + 'mtTransApTaxes="' + amountFormat(transactionTotal) + '"/></trans></reqMEV>\n';

                    if (EnvConfig.env !== 'production') {
                        console.log(xml);
                    }

                    let status = undefined;
                    let retryCount = 0;

                    do {
                        status = await getStatus();
                        if (!status || status !== MEV_STATUS.OK) {
                            await delay(1000);
                        }
                        retryCount++;
                    } while ((!status || status !== MEV_STATUS.OK) && retryCount <= 10);

                    if (status === MEV_STATUS.OK) {
                        send({xml}).then(resolve).catch(reject);
                    } else {
                        reject('Printer is not ready');
                    }
                });
            };

            const getSrmDevice = () => {
                // SharedDataService.posSrmDevice is set in pos-service
                let hasPosSrmDevice = !!SharedDataService.posSrmDevice;
                // SharedDataService.locationSrmDevices is set in pos-service
                let defaultSrm = (SharedDataService.locationSrmDevices || []).find((device) => device.defaultDevice);

                let device = hasPosSrmDevice ? SharedDataService.posSrmDevice : defaultSrm;
                return device;
            };

            const getStatus = () => {
                return new Promise(async (resolve, reject) => {
                    // Override the SRM
                    if (SharedDataService.bypassSrm) {
                        return resolve(MEV_STATUS.BYPASS);
                    }

                    let srmDevice = getSrmDevice();

                    // SRM Flag is not enabled & No SRM device has been assigned
                    if (!CompanyAttributesService.hasMevBox() && !srmDevice) {
                        return resolve(MEV_STATUS.NON);
                    }

                    // No SRM has been assigned to the station
                    // SRM is only available on electron
                    if (!srmDevice || !Platform.isElectron()) {
                        return resolve(MEV_STATUS.UNAVAILABLE);
                    }

                    let status = await send({opcode: OP_CODE_PRINTER_STATUS}).catch((error) => {
                    });
                    if (status && status.success && status.responseCode === 4) {
                        return resolve(MEV_STATUS.OK);
                    }
                    return resolve(MEV_STATUS.UNAVAILABLE);
                });
            };

            const setMevBoxBypass = (bypass) => {
                SharedDataService.bypassSrm = bypass;
            };

            var mevService = {
                getStatus,
                getSrmDevice,
                printReceipt,
                openCashDrawer,
                setMevBoxBypass
            };

            return mevService;
        }]);
}
