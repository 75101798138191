'use strict';


const moment = require('moment');

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosTimeTrackingCtrl', [
        '$scope',
        '$modal',
        '$translate',
        '$timeout',
        'PosAlertService',
        'PrintService',
        'SmbPosService',
        'PosUsersService',
        'Users',
        'Reports',
        'CompanyAttributesService',
        'CommonOfflineCache',
        'PosStatusService',
        'OfflineTimeCard',
        'Pure',
        'CurrentSession',
        'EnvConfig',
        function (
            $scope,
            $modal,
            $translate,
            $timeout,
            PosAlertService,
            PrintService,
            SmbPosService,
            PosUsersService,
            Users,
            Reports,
            CompanyAttributesService,
            CommonOfflineCache,
            PosStatusService,
            OfflineTimeCard,
            Pure,
            CurrentSession,
            EnvConfig) {

            $scope.modalTypes = {
                DEFAULT_TIME_MANAGEMENT: 1,
                CONFIRM_CLOCKOUT_TO_CLOCK_IN: 2
            };

            var globalPinObj;
            var isClockingIn = false;
            $scope.timestamps = {};
            $scope.currentModal = $scope.modalTypes.DEFAULT_TIME_MANAGEMENT;
            $scope.enhanceSecurityMeasures = CompanyAttributesService.enhanceSecurityMeasures();
            $scope.isOpenStationPage = false;
            $scope.clockedIn = PosUsersService.getClockedInUsers;
            var loadClockedInUsers = function () {
                if (!PosStatusService.isOffline()) {
                    Users.getClockedInUsers({}, function (response) {
                        $timeout( function () {
                            PosUsersService.setClockedInUsers(response);
                            CommonOfflineCache.saveClockedInUsers(response);
                        }, 0);
                    });
                } else {
                    CommonOfflineCache.getClockedInUsers().then(function (clockedIn) {
                        PosUsersService.setClockedInUsers(clockedIn);
                    });
                }
            };

            $scope.startClockIn = function () {
                var requestedPermission = 'pos:clockin';
                var params = {
                    callback: function (pinObj) {
                        confirmClockIn(pinObj).catch(() => {
                            // NOOP, errors handled in confirmClockIn
                        });
                    },
                    errorCallback: function (error) {
                        if (error) {
                            var exception = error.exception || {};
                            if (exception.appCode === 412) {
                                PosAlertService.showAlertByName('clockin-invalid-pin');
                            } else {
                                PosAlertService.showAlertByName('clockin-fail', {
                                    message: exception.message || ''
                                });
                            }
                        }
                    },
                    requestedPermission: requestedPermission,
                    message: 'smb.pos.clockIn.description',
                    verifyAllUserPinsForCompany: true,
                    forceAuthenticate: true
                };

                $scope.$emit('PincodeAuthentication:Required', params);
            };

            var confirmClockIn = async function (pinObj, toShowSuccessMessage = true) {
                // Changing to rebase
                if (PosStatusService.isOffline()) {
                    var clockedIn = await CommonOfflineCache.getClockedInUsers();
                    var foundUser = clockedIn.find(function (obj) {
                        return obj.user.userId == pinObj.user.userId;
                    });
                    if (foundUser) {
                        PosAlertService.showAlertByName('clockin-already-clockedin');
                        return;
                    }
                    var clockInObj = {
                        user: pinObj.user,
                        company: {
                            companyId: pinObj.user.companyId
                        },
                        startTime: moment().valueOf()
                    };
                    clockedIn.push(clockInObj);
                    CommonOfflineCache.saveClockedInUsers(clockedIn).then(function () {
                        var successMessage = $translate.instant('smb.pos.clockIn.success', {
                            name: pinObj.user.firstname,
                            currentTimeString: moment().format('hh:mm A')
                        });
                        OfflineTimeCard.clockIn(clockInObj);
                        PosAlertService.showAlertByName('clockin-success', {
                            message: successMessage
                        });
                        loadClockedInUsers();
                        if (!$scope.isOpenStationPage) {
                            $scope.$close();
                        }
                    }, function (error) {
                        PosAlertService.showAlertByName('general-error');
                        if (!$scope.isOpenStationPage) {
                            $scope.$close();
                        }
                    });
                    return;
                }

                /* Generic modal with a loading spinner to indicate that a clock in is in progress. Automatically
                closes when the request succeeds or fails. Can be closed manually. */

                let modalInstance;
                if (EnvConfig.env !== 'test') {
                    modalInstance = PosAlertService.showAlertByName('general-loading', {
                        title: 'general.processing',
                        message: 'pos.employeeShifts.clockingIn.message',
                        buttonType: 'ok'
                    });
                }
                return Users.clockIn({}, pinObj, function (response) {
                    if (EnvConfig.env !== 'test' && modalInstance) {
                        $timeout(() => modalInstance.close(), 0);
                    }
                    var successMessage = $translate.instant('smb.pos.clockIn.success', {
                        name: pinObj.user.firstname,
                        currentTimeString: moment().format('hh:mm A')
                    });

                    if (toShowSuccessMessage) {
                        PosAlertService.showAlertByName('clockin-success', {
                            message: successMessage
                        });
                    }

                    loadClockedInUsers();
                    if (!$scope.isOpenStationPage) {
                        $scope.$close();
                    }
                }, function (error) {
                    if (EnvConfig.env !== 'test' && modalInstance) {
                        $timeout(() => modalInstance.close(), 0);
                    }
                    isClockingIn = false;
                    var data = error.data || {};
                    var exception = data.exception || {};


                    $scope.switchToDefaultScreen();


                    if (exception.appCode === 410) {
                        PosAlertService.showAlertByName('clockin-already-clockedin');
                    } else if (exception.appCode === 451) {
                        var message = 'general.error.clockin-already-clockedin.msg';
                        if (exception.message) {
                            var locationName = ' - ' + (exception.message.split(':'))[1] || '';
                            message = $translate.instant('general.error.clockin-already-clockedin.location.msg', {
                                'location': locationName
                            });
                        }
                        PosAlertService.showAlertByName('clockin-already-clockedin', {
                            message: message
                        });
                    } else {
                        PosAlertService.showAlertByName('clockin-fail', {
                            message: exception.message || ''
                        });
                    }
                }).$promise;
            };

            $scope.switchToDefaultScreen = function () {
                $scope.currentModal = $scope.modalTypes.DEFAULT_TIME_MANAGEMENT;
                globalPinObj = undefined;
            };


            $scope.confirmClockoutToClockIn = function () {
                var newTime = computeTimestamps($scope.timestamps['endTime'], 'endTime');
                if (!newTime || !globalPinObj) {
                    return;
                }


                if (isClockingIn) {
                    return;
                } else {
                    isClockingIn = true;
                }

                var pinObjToPost = angular.copy(globalPinObj);
                pinObjToPost.ignoreLocation = true;
                pinObjToPost.clockOutTime = newTime;

                confirmClockOut(pinObjToPost, false).then(function () {
                    confirmClockIn(pinObjToPost, false).then(function () {
                        $scope.switchToDefaultScreen();
                        var successMessage = $translate.instant('smb.pos.clockout.confirm.to.clock.in.success', {
                            name: pinObjToPost.user.firstname,
                            clockOutTimeString: moment(newTime).format('hh:mm A'),
                            currentTimeString: moment().format('hh:mm A')
                        });

                        PosAlertService.showAlertByName('clockin-success', {
                            message: successMessage
                        });

                        isClockingIn = false;
                        globalPinObj = undefined;
                    }, function (clockInError) {
                        isClockingIn = false;
                    });
                }, function (clockOutError) {
                    isClockingIn = false;
                });
            };

            // Break related
            $scope.getActiveBreak = function (timeCard) {
                return _.find(timeCard.timeCardBreaks, {
                    isActive: true
                });
            };

            $scope.$watch(function () {
                return _.findIndex(SmbPosService.shift.cashierBreaks, {
                    isActive: true
                }) !== -1;
            }, function (isActiveBreak) {
                $scope.isActiveBreak = isActiveBreak;
            });

            $scope.startTakeABreak = function (timeCard) {
                var requestedPermission = 'pos:clockin-takeabreak';
                var message = $translate.instant('smb.pos.takeABreak.description', {
                    name: timeCard.user.firstname
                });
                var params = {
                    callback: function (pinObj) {
                        pinObj.user = timeCard.user;
                        confirmTakeABreak(pinObj);
                    },
                    errorCallback: function (error) {
                        if (error) {
                            var exception = error.exception || {};

                            if (exception.appCode === 412 || exception.appCode === 413) {
                                PosAlertService.showAlertByName('takeABreak-invalid-pin');
                            } else {
                                PosAlertService.showAlertByName('takeABreak-fail', {
                                    message: exception.message || ''
                                });
                            }
                        }
                    },
                    requestedPermission: requestedPermission,
                    message: message,
                    verifyAllUserPinsForCompany: true,
                    forceAuthenticate: true
                };

                $scope.$emit('PincodeAuthentication:Required', params);
            };

            var takeABreakOperations = function (pinObj, homebaseBreakId) {
                /* Generic modal with a loading spinner to indicate that a start break is in progress. Automatically
                closes when the request succeeds or fails. Can be closed manually. */
                if (EnvConfig.env !== 'test') {
                    var modalInstance = PosAlertService.showAlertByName('general-loading', {
                        title: 'general.processing',
                        message: 'pos.employeeShifts.startingBreak.message',
                        buttonType: 'ok'
                    });
                }
                return Users.takeABreak(pinObj, function (response) {
                    if (EnvConfig.env !== 'test') {
                        modalInstance.close();
                    }
                    var successMessage = $translate.instant('smb.pos.takeABreak.success', {
                        name: pinObj.user.firstname,
                        currentTimeString: moment(response.startTime).format('hh:mm A')
                    });
                    PosAlertService.showAlertByName('takeABreak-success', {
                        message: successMessage
                    });

                    loadClockedInUsers();
                    if (!$scope.isOpenStationPage) {
                        $scope.$close();
                    }
                }, function (error) {
                    if (EnvConfig.env !== 'test') {
                        modalInstance.close();
                    }
                    error = error || {};
                    var data = error.data || {};
                    var exception = data.exception || {};

                    if (exception.appCode === 412 || exception.appCode === 413) {
                        PosAlertService.showAlertByName('takeABreak-invalid-pin');
                    } else {
                        PosAlertService.showAlertByName('takeABreak-fail', {
                            message: exception.message || ''
                        });
                    }
                }).$promise;
            };

            var confirmTakeABreak = function (pinObj) {
                if (CurrentSession.isHomebase()) {
                    var homebaseBreakModalInstance = $modal.open({
                        templateUrl: 'common/modals/modalHomebaseSelectBreak.tpl.html',
                        animation: false,
                        backdrop: 'static',
                        windowClass: 'homebase__select-break-modal',
                        controller: [
                            '$scope',
                            'userId',
                            'Homebase',
                            '$log',
                            'PosAlertService',
                            function ($scope, userId, Homebase, $log, PosAlertService) {
                                var loadHomebaseEmployeeStatus = function () {
                                    $scope.loading = true;
                                    $scope.homebaseBreaks = [];
                                    Homebase.getHomebaseEmployeeStatus({userId: userId}, function (response) {
                                        $scope.loading = false;
                                        var breaks = response.mandated_breaks.map((b) => {
                                            var breakName = b.paid ? 'Paid ' : 'Unpaid ';
                                            breakName += b.duration + ' min. Break';
                                            return {
                                                id: b.id,
                                                name: breakName
                                            };
                                        });
                                        $scope.homebaseBreaks = breaks;
                                    }, function (error) {
                                        $scope.loading = false;
                                        PosAlertService.showAlertByName('oops-general', {
                                            message: 'pos.homebase.get.employee.status.error',
                                            dismissModalCallback: function () {
                                                $scope.$dismiss();
                                            }
                                        });
                                        $log.error('Error fetching employee status from Homebase.', error);
                                    });
                                };

                                $scope.homebaseBreakModel = {
                                    selectedHomebaseBreak: null
                                };

                                $scope.confirmHomebaseBreak = function () {
                                    $scope.$close($scope.homebaseBreakModel.selectedHomebaseBreak.id);
                                };

                                $scope.cancel = function () {
                                    $scope.$dismiss();
                                };

                                var init = function () {
                                    loadHomebaseEmployeeStatus();
                                };

                                init();
                            }
                        ],
                        resolve: {
                            userId: function () {
                                return pinObj.user.userId;
                            }
                        }
                    });

                    homebaseBreakModalInstance.result.then(function (selectedHomebaseBreakId) {
                        pinObj.homebaseBreakId = selectedHomebaseBreakId;
                        takeABreakOperations(pinObj, selectedHomebaseBreakId);
                    });
                } else {
                    takeABreakOperations(pinObj);
                }
            };

            $scope.startEndBreak = function (timeCard) {
                var requestedPermission = 'pos:clockin-endbreak';
                var message = $translate.instant('smb.pos.endBreak.description', {
                    name: timeCard.user.firstname
                });
                var params = {
                    callback: function (pinObj) {
                        pinObj.user = timeCard.user;
                        confirmEndBreak(pinObj);
                    },
                    errorCallback: function (error) {
                        if (error) {
                            var exception = error.exception || {};

                            if (exception.appCode === 412 || exception.appCode === 413) {
                                PosAlertService.showAlertByName('takeABreak-invalid-pin');
                            } else {
                                PosAlertService.showAlertByName('takeABreak-fail', {
                                    message: exception.message || ''
                                });
                            }
                        }
                    },
                    requestedPermission: requestedPermission,
                    message: message,
                    verifyAllUserPinsForCompany: true,
                    forceAuthenticate: true
                };

                $scope.$emit('PincodeAuthentication:Required', params);
            };

            var confirmEndBreak = function (pinObj) {
                /* Generic modal with a loading spinner to indicate that a start break is in progress. Automatically
                closes when the request succeeds or fails. Can be closed manually. Disabled for test environment */
                if (EnvConfig.env !== 'test') {
                    var modalInstance = PosAlertService.showAlertByName('general-loading', {
                        title: 'general.processing',
                        message: 'pos.employeeShifts.endingBreak.message',
                        buttonType: 'ok'
                    });
                }
                return Users.endBreak({}, pinObj, function (response) {
                    if (EnvConfig.env !== 'test') {
                        modalInstance.close();
                    }
                    var duration = moment.duration(response.endTime - response.startTime);
                    var hours = Math.floor(duration.as('hours')); // .as('hours') shows total number of hours
                    var minutes = duration.minutes(); // `.minutes()` shows remaining minutes (after hours)

                    var hourString = hours + ' ' + ((hours === 1)? 'hour' : 'hours');
                    var minuteString = minutes + ' ' + ((minutes === 1)? 'minute' : 'minutes');
                    var durationString = hourString + ' ' + minuteString;

                    var successMessage = $translate.instant('smb.pos.endBreak.success', {
                        name: pinObj.user.firstname,
                        currentTimeString: moment(response.endTime).format('hh:mm A'),
                        durationString: durationString
                    });
                    PosAlertService.showAlertByName('endBreak-success', {
                        message: successMessage
                    });

                    loadClockedInUsers();
                    if (!$scope.isOpenStationPage) {
                        $scope.$close();
                    }
                }, function (error) {
                    if (EnvConfig.env !== 'test') {
                        modalInstance.close();
                    }
                    error = error || {};
                    var data = error.data || {};
                    var exception = data.exception || {};

                    if (exception.appCode === 412 || exception.appCode === 413) {
                        PosAlertService.showAlertByName('endBreak-invalid-pin');
                    } else {
                        PosAlertService.showAlertByName('endBreak-fail', {
                            message: exception.message || ''
                        });
                    }
                }).$promise;
            };

            $scope.startClockOut = function (timeCard) {
                var requestedPermission = 'pos:clockin';
                var message = $translate.instant('smb.pos.clockOut.description', {
                    name: timeCard.user.firstname
                });
                var params = {
                    callback: function (pinObj) {
                        pinObj.user = timeCard.user;
                        confirmClockOut(pinObj);
                    },
                    errorCallback: function (error) {
                        if (error) {
                            var exception = error.exception || {};

                            if (exception.appCode === 412 || exception.appCode === 413) {
                                PosAlertService.showAlertByName('clockout-invalid-pin');
                            } else {
                                PosAlertService.showAlertByName('clockout-fail', {
                                    message: exception.message || ''
                                });
                            }
                        }
                    },
                    requestedPermission: requestedPermission,
                    message: message,
                    verifyAllUserPinsForCompany: true,
                    forceAuthenticate: true
                };

                $scope.$emit('PincodeAuthentication:Required', params);
            };
            $scope.cancelClockOut = function () {
                $scope.selectedTimeCard = undefined;
                $scope.setPage('list');
            };
            $scope.clockOut = function (user, pin) {
                var pinObj = {
                    pin: pin,
                    user: user
                };
                confirmClockOut(pinObj);
            };

            var confirmClockOut = async function (pinObj, toShowSuccessMessage = true) {
                if (PosStatusService.isOffline()) {
                    var foundUser = await CommonOfflineCache.getUserByPin(pinObj.pin, true);
                    if (foundUser && foundUser.user && foundUser.user.userId != pinObj.user.userId) {
                        PosAlertService.showAlertByName('clockout-invalid-pin');
                        return;
                    } else if (!foundUser) {
                        PosAlertService.showAlertByName('clockout-fail');
                        return;
                    }
                    var clockedIn = await CommonOfflineCache.getClockedInUsers();
                    var timeCardToClockOut = clockedIn.find(function (obj) {
                        return obj.user.userId == pinObj.user.userId;
                    });
                    if (!timeCardToClockOut) {
                        PosAlertService.showAlertByName('clockout-fail');
                        return;
                    }
                    timeCardToClockOut.endTime = moment().valueOf();
                    var filteredClockedIn = clockedIn.filter(function (userTimeCard) {
                        if (userTimeCard.id && timeCardToClockOut.id) {
                            return userTimeCard.id != timeCardToClockOut.id;
                        } else {
                            return userTimeCard.user.userId != timeCardToClockOut.user.userId;
                        }
                    });
                    CommonOfflineCache.saveClockedInUsers(filteredClockedIn).then(function () {
                        OfflineTimeCard.clockOut(timeCardToClockOut);
                        var duration = moment.duration(timeCardToClockOut.endTime - timeCardToClockOut.startTime);
                        var hours = Math.floor(duration.as('hours'));
                        var minutes = duration.minutes();

                        var hourString = hours + ' ' + ((hours === 1)? 'hour' : 'hours');
                        var minuteString = minutes + ' ' + ((minutes === 1)? 'minute' : 'minutes');
                        var durationString = hourString + ' ' + minuteString;

                        var successMessage = $translate.instant('smb.pos.clockOut.success', {
                            name: timeCardToClockOut.user.firstname,
                            currentTimeString: moment(timeCardToClockOut.endTime).format('hh:mm A'),
                            durationString: durationString
                        });
                        PosAlertService.showAlertByName('clockout-success', {
                            message: successMessage
                        });
                        loadClockedInUsers();
                        if (!$scope.isOpenStationPage) {
                            $scope.$close();
                        }
                    }, function (error) {
                        PosAlertService.showAlertByName('general-error');
                        if (!$scope.isOpenStationPage) {
                            $scope.$close();
                        }
                    });
                } else {
                    /* Generic modal with a loading spinner to indicate that a clock out is in progress. Automatically
                    closes when the request succeeds or fails. Can be closed manually. */
                    if (EnvConfig.env !== 'test') {
                        var modalInstance = PosAlertService.showAlertByName('general-loading', {
                            title: 'general.processing',
                            message: 'pos.employeeShifts.clockingOut.message',
                            buttonType: 'ok'
                        });
                    }
                    return Users.clockOut({}, pinObj, function (response) {
                        if (EnvConfig.env !== 'test') {
                            modalInstance.close();
                        }
                        var duration = moment.duration(response.endTime - response.startTime);
                        var hours = Math.floor(duration.as('hours')); // .as('hours') shows total number of hours
                        var minutes = duration.minutes(); // `.minutes()` shows remaining minutes (after hours)

                        var hourString = hours + ' ' + ((hours === 1)? 'hour' : 'hours');
                        var minuteString = minutes + ' ' + ((minutes === 1)? 'minute' : 'minutes');
                        var durationString = hourString + ' ' + minuteString;

                        var successMessage = $translate.instant('smb.pos.clockOut.success', {
                            name: pinObj.user.firstname,
                            currentTimeString: moment(response.endTime).format('hh:mm A'),
                            durationString: durationString
                        });

                        if (toShowSuccessMessage) {
                            PosAlertService.showAlertByName('clockout-success', {
                                message: successMessage
                            });
                        }

                        printTimeCards([response]);

                        loadClockedInUsers();

                        if (!$scope.isOpenStationPage) {
                            $scope.$close();
                        }

                        var userId = response.user.userId;
                        var newMinutes = Math.floor(duration.as('minutes'));
                        var currentWeekLabourSearch = {
                            userId: userId,
                            startDateTime: moment().startOf('week').valueOf(),
                            endDateTime: moment().endOf('week').valueOf(),
                        };
                        var timeCard = response;
                        Reports.getLabourReport(currentWeekLabourSearch, function (response) {
                            var newLabourData = Pure.calculateNewLabour(userId, newMinutes, timeCard, response);
                            Users.addLabour(newLabourData);
                        });
                    }, function (error) {
                        if (EnvConfig.env !== 'test') {
                            modalInstance.close();
                        }
                        error = error || {};
                        var data = error.data || {};
                        var exception = data.exception || {};
                        isClockingIn = false;

                        if (exception.appCode === 412 || exception.appCode === 413) {
                            PosAlertService.showAlertByName('clockout-invalid-pin');
                        } else {
                            PosAlertService.showAlertByName('clockout-fail', {
                                message: exception.message || ''
                            });
                        }
                    }).$promise;
                }
            };

            $scope.checkIfTimecardEdited = function () {
                var isError = false;
                /* eslint-disable guard-for-in */
                for (var type in $scope.timestamps) {
                    try {
                        if ($scope.timestamps[type].timeInHrsMins) {
                            var timeStringArr = $scope.timestamps[type].timeInHrsMins.split(':');
                            if (parseInt(timeStringArr[0]) > 12 || parseInt(timeStringArr[1]) > 59) {
                                PosAlertService.showAlertByName('invalid-date-range', {
                                    message: 'Invalid Clock Out Time'
                                });
                                isError = true;
                                continue;
                            }
                        }
                        if (!$scope.timestamps[type].newTimestamp) {
                            continue;
                        }
                    } catch (error) {
                        PosAlertService.showAlertByName('invalid-date-range', {
                            message: 'Invalid Clock Out Time'
                        });
                    }
                }

                return isError;
                /* eslint-enable */
            };

            var computeTimestamps = function (timestampObj, type) {
                try {

                    if (!$scope.timestamps[type]) {
                        return undefined;
                    }

                    if (!timestampObj.newTimestamp || !timestampObj.timeInHrsMins) {
                        PosAlertService.showAlertByName('invalid-date-range', {
                            message: $translate.instant('smb.pos.clockout.to.clockin.invalid.label', {
                                errorLabel: timestampObj.errorLabel
                            })
                        });
                        return undefined;
                    }

                    var isError = $scope.checkIfTimecardEdited();
                    if (isError) {
                        return undefined;
                    }

                    var tmpTime = timestampObj.timeInHrsMins + ' ' + timestampObj.timestampPeriod;
                    var tmpDateString = moment(timestampObj.newTimestamp).format('MM/DD/YYYY');
                    var newDateMoment = moment(tmpDateString + ' ' + tmpTime);
                    var newDate = newDateMoment.valueOf();

                    if (!newDateMoment.isValid()) {
                        throw new Error('Error while converting date');
                    }

                    if (newDate > moment().valueOf()) {
                        PosAlertService.showAlertByName('invalid-date-range', {
                            message: 'smb.pos.clockout.to.clockin.invalid.clockout.time'
                        });
                        return undefined;
                    }

                    if (type == 'endTime') {
                        return newDate;
                    }
                } catch (error) {
                    PosAlertService.showAlertByName('invalid-date-range', {
                        message: $translate.instant('smb.pos.clockout.to.clockin.invalid.label', {
                            errorLabel: timestampObj.errorLabel
                        })
                    });
                    return undefined;
                }
            };

            var printTimeCards = function (timeCards) {
                var report = {};
                report.timeCards = timeCards;

                PrintService.printTimeCards(report, SmbPosService.shift.posStation).catch(console.error);
            };

            var init = function () {
                loadClockedInUsers();
                $scope.shift = SmbPosService.shift;
            };
            init();

        }
    ]);


};
