'use strict';

export function secondaryDisplay (freshideas) {
    freshideas.factory('SecondaryDisplay', ['Platform', 'Locations', 'SmbPosService', 'EnvConfig',
        function (Platform, Locations, SmbPosService, EnvConfig) {

            const channel = 'customer-display';
            const MESSAGE_TYPE = {
                BROADCAST: 'broadcast-message',
                GET_DISPLAY: 'get-displays',
                CLEAR_SCREEN: 'clear_screen',
                BEGIN_TRANSACTION: 'begin_transaction',
                TRANSACTION_UPDATE: 'transaction_update',
                RECEIPT_ID: 'receipt_id',
                UPDATE_SLIDES: 'update_slides',
                UPDATE_LANGUAGE: 'update_language',
                UPDATE_APP_PAY_LABEL: 'update_app_pay_label',
            };
            var ipcRenderer;

            if (!Platform.isElectron()) {
                console.log('Running in non-electron environment. Disable Secondary display');
            } else {
                console.log('Init Secondary Display');
                ipcRenderer = nodeRequire('electron').ipcRenderer;
            }

            function sendMessage (message) {
                if (EnvConfig.env !== 'test') {
                    let {locationId, posStationId} = SmbPosService.shift;

                    if (!Platform.isElectron() || !ipcRenderer) { // Send to backend and deliver by ws
                        if (!locationId || !posStationId) {
                            return;
                        }
                        Locations.sendPushMessage({
                            type: channel,
                            message: JSON.stringify(message),
                            locationId: locationId,
                            posStationId: posStationId,
                        });
                    } else {
                        /**
                         * It is necessary to clone the message object. Since electron 8 had a
                         * breaking change in ipcRenderer.send
                         * https://github.com/electron/electron/blob/8de06f0c571bc24e4230063e3ef0428390df773e/docs/breaking-changes.md#L184-L233
                         * Uncaught DOMException: Failed to execute 'postMessage' on 'Window': An object could not be cloned.
                         */
                        message = JSON.parse(JSON.stringify(message));
                        ipcRenderer.send(MESSAGE_TYPE.BROADCAST, message);
                    }
                }
            }

            function sendSyncMessage (messageType, message) {
                if (!Platform.isElectron() || !ipcRenderer) {
                    return;
                }
                /**
                 * It is necessary to clone the message object. Since electron 8 had a
                 * breaking change in ipcRenderer.send
                 * https://github.com/electron/electron/blob/8de06f0c571bc24e4230063e3ef0428390df773e/docs/breaking-changes.md#L184-L233
                 * Uncaught DOMException: Failed to execute 'postMessage' on 'Window': An object could not be cloned.
                 */
                message = JSON.parse(JSON.stringify(message));
                return ipcRenderer.send(messageType, message);
            }

            let clearDisplay = function () {
                sendMessage({
                    type: MESSAGE_TYPE.CLEAR_SCREEN,
                    payload: {}
                });
            };

            let beginTransaction = function (customer) {
                sendMessage({
                    type: MESSAGE_TYPE.BEGIN_TRANSACTION,
                    payload: {
                        customer: customer
                    }
                });
            };

            let updateTransaction = function (items, currentOrderBalance, tenderAmounts, patron, page) {
                sendMessage({
                    type: MESSAGE_TYPE.TRANSACTION_UPDATE,
                    payload: {
                        items: items,
                        currentOrderBalance: currentOrderBalance,
                        tenderAmounts: tenderAmounts,
                        patron: patron,
                        page: page
                    }
                });
            };

            let sendReceiptId = function (receiptId) {
                sendMessage({
                    type: MESSAGE_TYPE.RECEIPT_ID,
                    payload: {
                        receiptId: receiptId
                    }
                });
            };

            /**
             * Slides is a list of image urls
             * @param {*} slides
             */
            let updateSlides = function (slides, lastUpdatedTimestamp) {
                sendMessage({
                    type: MESSAGE_TYPE.UPDATE_SLIDES,
                    payload: {
                        slides,
                        lastUpdatedTimestamp,
                    }
                });
            };

            let getDisplays = function () {
                return sendSyncMessage({}) || [];
            };

            let updateLanguage = (locale) => {
                sendMessage({
                    type: MESSAGE_TYPE.UPDATE_LANGUAGE,
                    payload: {
                        locale: locale,
                    }
                });
            };

            let updateMobileAppName = (appName) => {
                sendMessage({
                    type: MESSAGE_TYPE.UPDATE_APP_PAY_LABEL,
                    payload: {
                        appName: appName,
                    }
                });
            };

            return {
                getDisplays,
                updateSlides,
                clearDisplay,
                sendReceiptId,
                beginTransaction,
                updateTransaction,
                updateLanguage,
                updateMobileAppName,
            };
        }]);

}
