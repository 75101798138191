'use strict';

export function cardTerminalVantiv (freshideas) {

    freshideas.factory('CardTerminalVantiv', ['$http', 'TriPosService', function ($http, TriPosService) {
        var config;
        var _this = {};

        _this.init = function (terminalConfig) {
            config = terminalConfig;
        };

        var buildHeaders = function (data) {
            return {
                'Content-Type': 'application/json',
                'tp-application-name': 'Lucova',
                'tp-application-version': '1.0.0',
                'tp-application-id': '12345',
                'tp-authorization': 'Version=1.0, Credential='+config.developerKey,
                'tp-return-logs': 'false'
            };
        };

        var buildUrl = function (endpoint) {
            return 'http://' + config.ip + ':' + config.port + endpoint;
        };

        var createRequest = function (message, tenderType) {
            return new Promise(function (resolve, reject) {
                message.headers['tp-authorization'] = TriPosService.createAuthHeader(message, config.developerKey, config.developerSecret);

                $http(message).then(function (response) {
                    var parsedRes = buildResponse(response.data, tenderType);
                    console.log('ct: response_code = ', response.status);
                    console.log('ct: raw=', response.data);
                    console.log('ct: parsed=', parsedRes);

                    if (response.data.isApproved) {
                        resolve([
                            parsedRes,
                            response.data
                        ]);
                    } else {
                        reject();
                    }
                }).catch(function (error) {
                    console.log('ct: ', error);
                    reject(error);
                });
            });
        };

        _this.creditSale = function (amount) {
            var data = {
                laneId: config.laneId,
                transactionAmount: amount
            };

            var url = buildUrl('/api/v1/sale');

            var message = {
              url: url,
              method: 'POST',
              headers: buildHeaders(data),
              data: data
            };

            return createRequest(message, 'PURCHASE');
        };

        _this.creditVoid = function (transactionId) {
            var data = {
                laneId: config.laneId,
            };

            var url = buildUrl('/api/v1/void/' + transactionId);

            var message = {
              url: url,
              method: 'POST',
              headers: buildHeaders(data),
              data: data
            };

            return createRequest(message, 'VOID');
        };

        _this.creditReturn = function (amount) {
            var data = {
                laneId: config.laneId,
                transactionAmount: amount
            };

            var url = buildUrl('/api/v1/refund');

            var message = {
              url: url,
              method: 'POST',
              headers: buildHeaders(data),
              data: data
            };

            return createRequest(message, 'REFUND');
        };

        _this.debitSale = function (amount) {
            _this.creditSale(amount);
        };

        _this.debitReturn = function (amount) {
            _this.creditReturn(amount);
        };

        _this.isCancellableFromPos = function (amount) {
            return false;
        };

        var buildResponse = function (data, tenderType) {
            // var isSignatureRequired = function (signature) {
            //     if (signature == null) {
            //         return false;
            //     }

            //     var sigCodes = [
            //         'SignatureRequired',
            //         'SignatureRequiredCancelledByCardholder',
            //         'SignatureRequiredNotSupportedByPinPad',
            //         'SignatureRequiredPinPadError'
            //     ];
            //     return sigCodes.includes(signature.statusCode);
            // };

            var getAttribute = function (dict, key) {
                if (dict == null) {
                    return null;
                }

                return dict[key];
            };

            return {
                terminalId: data.terminalId,
                transactionId: data.transactionId,
                transactionType: tenderType,
                cardType: data.paymentType,
                cardBrand: data.cardLogo,
                maskedCardNumber: data.accountNumber,
                entryMethod: data.entryMode,
                approvalCode: data.approvalNumber,
                approvedAmount: data.totalAmount,
                success: data.isApproved,
                batchNum: data.binValue,
                tipAmount: data.tipAmount,
                signatureBase64Png: null,
                transactionSequenceNum: null,
                debitAccountType: data.paymentType,
                emvAid: getAttribute(data.emv, 'applicationIdentifier'),
                emvTvr: null,
                emvTsi: null,
                emvAppLabel: getAttribute(data.emv, 'applicationLabel'),
                cvmResult: null,
                hostResponseCode: getAttribute(data._processor, 'hostResponseCode'),
                hostResponseIsoCode: getAttribute(data._processor, 'hostResponseMessage'),
                demoMode: null,
                isCredit: data.paymentType === 'Credit',
                isDebit: data.paymentType === 'Debit',
                // showSignatureLine: isSignatureRequred(data.signature)
                showSignatureLine: false
            };
        };

        _this.parseResponse = function (response) {
            return buildResponse(response);
        };

        return _this;
    }]);
}
