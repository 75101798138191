'use strict';


module.exports = function (freshideasSystem) {

    freshideasSystem.factory('SystemConnectivityService', [
        '$rootScope',
        '$timeout',
        function ($rootScope, $timeout) {
            var connectivity = {
                lowConnectivity: false
            };

            return connectivity;
        }
    ]);

    freshideasSystem.controller('SystemConnectivityCtrl', [
        '$scope',
        '$timeout',
        function ($scope, $timeout) {


        }]);

};
