'use strict';


const moment = require('moment');

module.exports = function (freshideasReports) {

    freshideasReports.controller('GiftCardReport', [
        '$scope',
        '$location',
        'CurrentSession',
        'Reports',
        'Utils',
        'Lookup',
        'Export',
        'Security',
        'Platform',
        'DateRangeService',
        'USER_ROLE_TYPE',
        function ($scope, $location, CurrentSession, Reports, Utils, Lookup, Export, Security, Platform, DateRangeService, USER_ROLE_TYPE) {

            $scope.isRootCompany = CurrentSession.isRootCompany();

            $scope.searchDisabled = false;
            var allowRetry;
            var currentUser = Security.getUser();
            $scope.isManager = currentUser.roleType === USER_ROLE_TYPE.MANAGER;
            $scope.isAccountant = currentUser.roleType === USER_ROLE_TYPE.ACCOUNTANT;
            $scope.isSiteAdmin = currentUser.permission === 'SITEADMIN';
            $scope.isFullAdmin = (currentUser.permission === 'FULLADMIN');
            $scope.isIosWebkit = Platform.isIosWebkit();
            $scope.searchBar = {
                text: ''
            };

            const SegmentationView = Object.freeze(
                {
                    'TYPE': 'typeSegment',
                    'STATUS': 'statusSegment',
                    'AGE': 'ageSegment'
                }
            );

            $scope.pieDataColors = ['#59A1B3', '#6BB0C2', '#7DC0D1', '#92D1E0', '#A8E1F0'];

            $scope.init = function () {
                $scope.isLoading = true;

                if (DateRangeService.getFromDate()) {
                    $scope.giftCardSearch.startDateTime = moment(DateRangeService.getFromDate()).startOf('day').toDate();
                }
                if (DateRangeService.getToDate()) {
                    $scope.giftCardSearch.endDateTime = moment(DateRangeService.getToDate()).endOf('day').toDate();
                }
                $scope.giftCardSearchFilter = angular.copy($scope.giftCardSearch);
                lookupCompanyHierarchy(true).$promise.then(function () {
                    $scope.loadGiftCardActivities();
                });
            };

            function lookupCompanyHierarchy (includeDeprecated = false) {
                var locationsSearch = {
                    includeDeprecated: includeDeprecated
                };
                return Lookup.locationsByCompany(locationsSearch, function (response) {
                    $scope.company = _.findWhere(response, {id: currentUser.companyId});
                    $scope.locations = [];
                    let children = ($scope.company && $scope.company.children) ? $scope.company.children : [];

                    if (children.length) {
                        if (children.length > 1) {
                            $scope.locations.push({
                                id: null,
                                name: 'All Locations'
                            });
                        }
                        _.each(children, function (location) {
                            $scope.locations.push(location);
                        });
                        $scope.giftCardSearchFilter.selectedLocation = currentUser.company.locationId;
                    }
                });
            }

            $scope.setSortByField = function (sortBy) {
                $scope.currentReportSearch.sortBy = sortBy;
                $scope.currentReportSearch.ascending = !$scope.currentReportSearch.ascending;

                var greater = $scope.currentReportSearch.ascending ? 1 : -1;
                var less = $scope.currentReportSearch.ascending ? -1 : 1;
                $scope.giftCardActivityData.sort((a, b) => {
                    if (a[sortBy] > b[sortBy]) {
                        return greater;
                    }
                    if (a[sortBy] < b[sortBy]) {
                        return less;
                    }
                    return 0;
                });
            };

            $scope.resetFilters = function () {
                $scope.giftCardSearch.startDateTime = moment().startOf('week').toDate();
                $scope.giftCardSearch.endDateTime = moment().endOf('day').toDate();
                $scope.giftCardSearch.isAdvancedSearch = true;
                $scope.giftCardSearch.sortBy = '';
                $scope.giftCardSearch.ascending = true;
                $scope.giftCardSearch.offSet = 0;
                $scope.giftCardSearch.limit = 50;
                $scope.giftCardSearch.selectedLocation = currentUser.company.locationId;
                delete $scope.giftCardSearch.locationId;

                $scope.giftCardSearchFilter = angular.copy($scope.giftCardSearch);
                $scope.loadGiftCardActivities();
            };

            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };
            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };

            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };

            $scope.giftCardSearch = {
                isAdvancedSearch: true,
                startDateTime: moment().startOf('week').toDate(),
                endDateTime: moment().endOf('day').toDate(),
                sortBy: '',
                ascending: true,
                offSet: 0,
                limit: 50,
                selectedLocation: ''
            };
            $scope.giftCardSearchFilter = angular.copy($scope.giftCardSearch);

            $scope.saveSelectedFromDate = function () {
                var selectedFromDate = moment($scope.giftCardSearchFilter.startDateTime).startOf('day').valueOf();
                DateRangeService.setFromDate(selectedFromDate);
            };
            $scope.saveSelectedToDate = function () {
                var selectedToDate = moment($scope.giftCardSearchFilter.endDateTime).endOf('day').valueOf();
                DateRangeService.setToDate(selectedToDate);
            };

            function resetPagingOptions () {
                $scope.pagingOptions = {
                    startRecord: 0,
                    pageSize: 10,
                    currentPage: 1,
                };
            }
            resetPagingOptions();

            $scope.pageUp = function () {
                var start = $scope.pagingOptions.startRecord;
                var newStart = start + $scope.pagingOptions.pageSize;
                if ($scope.giftCardActivityData.length > newStart) {
                    $scope.pagingOptions.startRecord = newStart;
                    $scope.pagingOptions.currentPage++;
                }
            };

            $scope.pageDown = function () {
                var start = $scope.pagingOptions.startRecord;
                var newIndex = start - $scope.pagingOptions.pageSize;
                if (start > 0) {
                    if (newIndex < 0) {
                        $scope.pagingOptions.startRecord = 0;
                    } else {
                        $scope.pagingOptions.startRecord = newIndex;
                    }
                    $scope.pagingOptions.currentPage--;
                }
            };

            $scope.pagesAvailable = function () {
                var itemLen = $scope.giftCardActivityData.length;
                var pageSize = $scope.pagingOptions.pageSize;
                return (itemLen > pageSize) ? Math.ceil(itemLen/pageSize) : 1;
            };

            $scope.pieChartOptions = {
                chart: {
                    'type': 'pieChart',
                    'width': 100,
                    'height': 100,
                    'x': function (d) {
                        return d.key;
                    },
                    'y': function (d) {
                        return d.y;
                    },
                    'duration': 500,
                    'labelThreshold': 0.01,
                    'labelType': 'key',
                    'labelSunbeamLayout': false,
                    'transitionDuration': 500,
                    'showLegend': false,
                    'showLabels': false,
                    'legend': {
                        'margin': {
                            'top': 0,
                            'right': 0,
                            'bottom': 0,
                            'left': 0
                        }
                    },
                    'pie': {
                        'margin': {
                            'top': 0,
                            'right': 0,
                            'bottom': 0,
                            'left': 0
                        }
                    },
                    'margin': {
                        'right': 0,
                        'left': 0,
                        'top': 0,
                        'bottom': 0
                    },
                    'dispatch': {
                        renderEnd: function (e) {
                            d3.selectAll('#pieChart svg')
                            .attr('width', null)
                            .attr('height', null)
                            .attr('viewBox', '0 0 100 100')
                            .style('width', 'auto')
                            .style('height', 'auto');
                        }
                    }
                }
            };

            $scope.pieData = [];

            $scope.selectSegment = (selectedSegment) => {
                $scope.segmentationSettings.currentView = selectedSegment;
                $scope.processSegmentationData($scope.giftCardSegmentationData, selectedSegment);
            };

            var calculateSegmentationTotals = (segmentationData) => {
                $scope.segmentationTotals = {};

                _.each(Object.keys(segmentationData), (key) => {
                    var segmentTotal = {
                        count: 0,
                        amountCents: 0
                    };

                    _.each(segmentationData[key], (segment) => {
                        segmentTotal.count += segment.count;
                        segmentTotal.amountCents += segment.amountCents;
                    });
                    $scope.segmentationTotals[key] = segmentTotal;
                });
            };

            // underThreeMonths -> Under Three Months
            $scope.parseCamelCase = (string) => {
                if (!string) {
                    return 'N/A';
                }

                var spacedString = string.replace(/([A-Z]+)/g, ' $1');
                return spacedString.charAt(0).toUpperCase() + spacedString.slice(1);
            };

            $scope.processSegmentationData = (segmentationData, selectedSegment) => {

                // clear pieData
                $scope.pieData.length = 0;

                $scope.currentSegmentationData = segmentationData[selectedSegment];

                _.each($scope.currentSegmentationData, (segment, index) => {
                    // grab predefined color based on index
                    var pieColor = $scope.pieDataColors[index] || '#59A1B3';

                    // add pieColor to segment (shown in segment table as well)
                    segment.pieColor = pieColor;

                    // do not need to push to pieData if 0
                    if (segment.amountCents) {
                        var data = {
                            key: $scope.parseCamelCase(segment.segmentLabel),
                            y: (segment.amountCents / 100),
                            amount: (segment.amountCents / 100),
                            color: pieColor,
                            type: 'currency'
                        };

                        $scope.pieData.push(data);
                    }
                });
            };

            // default segmentation view.
            $scope.segmentationSettings = {
                currentView: SegmentationView.TYPE
            };

            $scope.filterGiftCardActivities = function (data) {
                if (data) {
                    $scope.isLoading = false;
                    $scope.totalGiftCardAmount = data.totalGiftCardAmountCents / 100;
                    $scope.totalGiftCardCount = data.totalGiftCardCount;
                    $scope.giftCardActivityData = data.giftCardOverview;
                    $scope.giftCardLocationOverview = data.giftCardLocationOverview;
                    $scope.originalGiftCardData = $scope.giftCardActivityData;

                    $scope.currentReportSearch = angular.copy($scope.giftCardSearch);
                    $scope.searchAction();
                } else {
                    $scope.isLoading = false;
                    $scope.giftCardActivityData = [];
                    $scope.giftCardActivityDataSize = 0;
                }
            };

            $scope.loadGiftCardActivities = function () {
                $scope.searchDisabled = true;
                clearTimeout(allowRetry);
                allowRetry = setTimeout(function () {
                    $scope.searchDisabled = false;
                }, 30000);
                $scope.giftCardSearch.startDateTime = moment($scope.giftCardSearchFilter.startDateTime).startOf('day').valueOf();
                $scope.giftCardSearch.endDateTime = moment($scope.giftCardSearchFilter.endDateTime).endOf('day').valueOf();
                $scope.giftCardSearch.selectedLocation = angular.copy($scope.giftCardSearchFilter.selectedLocation);

                var transactionSearch = {
                    startDateTime: $scope.giftCardSearch.startDateTime,
                    endDateTime: $scope.giftCardSearch.endDateTime,
                    sortBy: '',
                    limit: 60,
                    locationId: $scope.giftCardSearch.selectedLocation
                };

                $scope.giftCardActivityData = [];
                $scope.giftCardActivityDataSize = 0;
                $scope.isLoading = true;

                Reports.getGiftCardReport(transactionSearch, function (response) {
                    $scope.filterGiftCardActivities(response);
                }, function (error) {
                    $scope.isLoading = false;
                });

                // only run if root company and if either case is true:
                // 1. user selected `all locations` (e.g. realfruit account has to select `all locations`)
                // 2. the root location is the only location of this client (e.g. forgetmenot doesn't have an 'all locations' filter because it is a single location store)
                if (($scope.isRootCompany && $scope.locations.length === 1) || ($scope.isRootCompany && !transactionSearch.locationId)) {
                    $scope.isLoadingSegmentation = true;
                    Reports.getGiftCardSegmentation({}, function (response) {
                        $scope.giftCardSegmentationData = response.toJSON();

                        // defaults segmentation view to 'type'
                        $scope.segmentationSettings.currentView = SegmentationView.TYPE;
                        // processes data for pie chart
                        $scope.processSegmentationData($scope.giftCardSegmentationData, SegmentationView.TYPE);
                        // performs a calculation of totals for each segment
                        calculateSegmentationTotals($scope.giftCardSegmentationData);
                        $scope.isLoadingSegmentation = false;
                    }, function (error) {
                        $scope.isLoadingSegmentation = false;
                    });
                }
            };

            $scope.loadTransactionsForGiftCardActivity = function (giftCardActivity) {
                var transactionSearch = {
                    startDateTime: $scope.giftCardSearch.startDateTime,
                    endDateTime: $scope.giftCardSearch.endDateTime,
                    giftCardActivityType: giftCardActivity.key,
                    sortBy: '',
                    locationId: $scope.giftCardSearch.selectedLocation,
                    limit: 60
                };

                if (!transactionSearch.giftCardActivityType) {
                    delete transactionSearch.giftCardActivityType;
                }

                giftCardActivity.transactions = [];
                giftCardActivity.transactionsSize = 0;
                giftCardActivity.isLoadingTransactions = true;

                Reports.getGiftCardTransactions(transactionSearch, function (response) {
                    giftCardActivity.transactions = response;
                    giftCardActivity.transactionsSize = response.length;
                    giftCardActivity.isLoadingTransactions = false;
                }, function (error) {
                    $scope.isLoadingTransactions = false;
                });
            };

            $scope.getDownloadUrl = function () {
                var reportSearch = {};
                reportSearch.startDateTime = moment($scope.giftCardSearch.startDateTime).startOf('day').valueOf();
                reportSearch.endDateTime = moment($scope.giftCardSearch.endDateTime).endOf('day').valueOf();
                if ($scope.giftCardSearch.locationId) {
                    reportSearch.locationId = $scope.giftCardSearch.locationId;
                }
            };

            $scope.toggleShowGiftCardTransactions = function (giftCardActivity) {
                if (giftCardActivity.count > 0) {
                    if (!giftCardActivity.showTransactions) {
                        giftCardActivity.showTransactions = true;
                        $scope.loadTransactionsForGiftCardActivity(giftCardActivity);
                    } else {
                        giftCardActivity.showTransactions = false;
                    }
                }
            };

            $scope.setAllShowTransactions = function (expanded) {
                $scope.giftCardActivityData.forEach((item) => {
                    if (item.count > 0) {
                        item.showTransactions = expanded;
                        $scope.loadTransactionsForGiftCardActivity(item);
                    }
                });
            };

            $scope.searchAction = function () {
                // Search filter
                if ($scope.searchBar.text !== '') {
                    var searchTextLowerCase = $scope.searchBar.text.toLowerCase();
                    var filteredArray = [];

                    $scope.originalGiftCardData.forEach((giftCardActivity) => {
                        var activityName = giftCardActivity.friendlyName.toLowerCase();
                        if (activityName.indexOf(searchTextLowerCase) !== -1) {
                            filteredArray.push(giftCardActivity);
                        }
                    });

                    $scope.giftCardActivityData = filteredArray;
                } else {
                    $scope.giftCardActivityData = $scope.originalGiftCardData;
                }
                resetPagingOptions();
            };

            function resetLocationsPagingOptions () {
                $scope.locationsPagingOptions = {
                    startRecord: 0,
                    pageSize: 10,
                    currentPage: 1,
                };
            }
            resetLocationsPagingOptions();

            $scope.locationsPageUp = function () {
                var start = $scope.locationsPagingOptions.startRecord;
                var newStart = start + $scope.locationsPagingOptions.pageSize;
                if ($scope.giftCardLocationOverview.length > newStart) {
                    $scope.locationsPagingOptions.startRecord = newStart;
                    $scope.locationsPagingOptions.currentPage++;
                }
            };

            $scope.locationsPageDown = function () {
                var start = $scope.locationsPagingOptions.startRecord;
                var newIndex = start - $scope.locationsPagingOptions.pageSize;
                if (start > 0) {
                    if (newIndex < 0) {
                        $scope.locationsPagingOptions.startRecord = 0;
                    } else {
                        $scope.locationsPagingOptions.startRecord = newIndex;
                    }
                    $scope.locationsPagingOptions.currentPage--;
                }
            };

            $scope.locationsPagesAvailable = function () {
                if ($scope.giftCardLocationOverview) {
                    var itemLen = $scope.giftCardLocationOverview.length;
                    var pageSize = $scope.locationsPagingOptions.pageSize;
                    return (itemLen > pageSize) ? Math.ceil(itemLen / pageSize) : 1;
                } else {
                    return 0;
                }
            };


            $scope.clearSearch = function () {
                $scope.searchBar.text = '';
                $scope.giftCardActivityData = $scope.originalGiftCardData;
            };

            $scope.exportToPdf = function (tableId) {
                Export.tableToPdf(tableId, 'landscape');
            };

            $scope.init();

            $scope.$on('$destroy', function () {
                clearTimeout(allowRetry);
            });

            $scope.$watch('giftCardSearch.locationId', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('giftCardSearch.startDateTime', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('giftCardSearch.endDateTime', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });
        }
    ]);

};
