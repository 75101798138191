'use strict';

module.exports = function (posModule) {

    posModule.controller('PosInventorySearchCtrl', [
        '$scope',
        'CashierShift',
        'EventListenerService',
        'SmbPosService',
        function ($scope,
            CashierShift,
            EventListenerService,
            SmbPosService) {
            var locationId = $scope.shiftLocationId;

            $scope.inventorySearch = {
                searchString: '',
                serviceLocation: locationId
            };
            $scope.searchResults = [];
            $scope.isSearching = true;
            $scope.searchInventory = function () {
                $scope.isSearching = true;
                var searchString = $scope.inventorySearch.searchString;
                if (searchString) {
                    CashierShift.inventorySearch($scope.inventorySearch, function (response) {
                        $scope.searchResults = response.entries;
                        $scope.isSearching = false;
                    }, function (error) {
                        $scope.searchResults = undefined;
                        $scope.isSearching = false;
                    });
                } else {
                    $scope.searchResults = undefined;
                    $scope.isSearching = false;
                }
            };
            $scope.listInventory = function () {
                $scope.isSearching = true;
                $scope.isListingAll = true;
                $scope.inventorySearch.searchString = '';
                CashierShift.inventoryList($scope.inventorySearch, function (response) {
                    $scope.searchResults = response.entries;
                    $scope.isSearching = false;
                }, function (error) {
                    $scope.searchResults = undefined;
                    $scope.isSearching = false;
                });
            };
            $scope.updateUpcItemQuantity = function (upcItem, increase) {
                var increment = increase ? 1 : -1;
                var quantity = upcItem.quantity || 1;
                quantity = Math.max(quantity + increment, 1);
                upcItem.quantity = quantity;
            };

            EventListenerService.create('inventorySearch', 'barcodeEvent', function (event) {
                var data = event.detail.data;

                $scope.selectUpcItem(data);
            });

            $scope.$on('$destroy', function () {
                EventListenerService.destroy('inventorySearch');
            });

            $scope.selectUpcItem = function (upcItem) {
                var upcLookupObj = {
                    upc: upcItem.upc,
                    serviceLocation: locationId
                };
                SmbPosService.searchMenuItemsByUpc(upcLookupObj).then(function (response) {
                    var quantity = upcItem.quantity || 1;
                    var total = Number(quantity) * response.unitPrice;
                    var taxAmount = 0;
                    var itemTaxAmount = 0;
                    var totalItemAmount = response.unitPrice;
                    if (response.taxRate) {
                        taxAmount = total * response.taxRate;
                        itemTaxAmount = response.unitPrice * response.taxRate;
                        totalItemAmount += itemTaxAmount;
                    }
                    var resultUpcItem = {
                        'inventoryId': response.inventoryItemId,
                        'quantity': quantity,
                        'name': response.name,
                        'taxAmount': taxAmount,
                        'price': response.unitPrice,
                        'total': total + taxAmount,
                        'upc': upcItem.upc,
                        'itemPrice': response.unitPrice,
                        'itemTaxAmount': itemTaxAmount,
                        'itemTotalPrice': totalItemAmount,
                        'taxRate': response.taxRate,
                        'mealPlanAmount': response.mealPlanIndicator ? 1 : 0,
                        'mealPlanCount': ((response.mealPlanIndicator) ? Number(quantity) : 0)
                    };

                    $scope.addInventoryItem(resultUpcItem);
                });
            };
            $scope.listInventory();


            var debouncedInventorySearch = _.debounce($scope.searchInventory, 300);
            $scope.watchInventorySearchString = function () {
                $scope.isSearching = true;
                if ($scope.inventorySearch.searchString) {
                    $scope.isListingAll = false;
                }
                debouncedInventorySearch.call();
            };
        }
    ]);
};
