'use strict';


module.exports = function (rules) {
    const Decimal = require('decimal.js').default;
    const SharedDataService = require('../../external/pos.data-service.js');

    var rule = {
        name: 'us_ny_nyc_clothing_footwear',
        enableTag: 'has_us_ny_nyc_clothing_footwear',
        key: 'US_NY_NYC_CLOTHING_FOOTWEAR',
        canEnable: function (company, location) {
            let country = (company) ? company.country : null;
            if (!country) {
                country = (company && company.businessAddress)? company.businessAddress.country : '';
            }

            let state = (location)? location.province || location.region : '';
            let city = (location)? location.city : '';

            return country === 'United States'
                && state === 'NY'
                && city === 'New York';
        },
        isEnabled: function (company, location) {
            return this.canEnable(company, location);
        },
        run: function (company, location, receipt) {
            if (this.isEnabled(company, location)) {
                // Commented By Akash Mehta on November 12 2020
                // All modifiers (eligible for prepared goods or not) are automatically eligible
                // for prepared goods if the main/top/root item is eligible for prepared goods tax
                // This list inclueds : modifiers, negative modifiers as well as discounts
                receipt.forEach(function (listItem) {
                    if (listItem.level > 0 && (!listItem.taxRules || (listItem.taxRules && listItem.taxRules[rule.name] !== 'true'))) {
                        var rootItem = receipt.find((receiptItem) => receiptItem.index == listItem.index && receiptItem.level == 0);
                        if (rootItem.taxRules && rootItem.taxRules[rule.name] === 'true') {
                            listItem.taxRules = listItem.taxRules || {};
                            listItem.taxRules[rule.name] = 'true';
                        }
                    }
                });

                _computeItemTaxRates(receipt);
            }
        }
    };

    var _computeItemTaxRates = function (receiptList) {
        for (let listItem of receiptList) {
            var itemTaxRate = new Decimal(_computeItemTaxRate(listItem, receiptList));
            var itemPrice = new Decimal(listItem.itemPrice);
            var itemTaxAmount = itemPrice.times(itemTaxRate).toNearest(SharedDataService.baseDollar); // ensure item-level tax rounding
            var itemQuantity = new Decimal(listItem.quantity);

            listItem.taxRate = parseFloat(itemTaxRate.valueOf());
            listItem.taxAmount = parseFloat(itemTaxAmount.times(itemQuantity).valueOf());
            listItem.price = parseFloat(itemPrice.times(itemQuantity).valueOf());
            listItem.total = parseFloat((itemPrice.plus(itemTaxAmount)).times(itemQuantity).valueOf());
            listItem.itemTaxAmount = parseFloat(itemTaxAmount.valueOf());
            listItem.itemTotalPrice = parseFloat(itemPrice.plus(itemTaxAmount).valueOf());
        }
    };

    var _computeItemTaxRate = function (item, receiptList) {
        var itemOwnTaxRate = item.taxRate;
        var taxRules = item.taxRules || {};

        var itemBundlePrice = _computeItemUnitBundlePrice(item, receiptList);

        var isRuleApplicable = (
            taxRules[rule.name] === 'true'
            && itemBundlePrice < 110);

        var taxRate;
        if (isRuleApplicable) {
            taxRate = 0;

            var taxRulesAppliedString = item.taxRulesApplied || '';
            var taxRulesApplied = taxRulesAppliedString.split(',').filter(function (taxRuleApplied) {
                return !!taxRuleApplied;
            });
            taxRulesApplied.push(rule.name);

            item.taxRulesApplied = taxRulesApplied.join(',');
        } else {
            taxRate = itemOwnTaxRate;
        }

        return taxRate;
    };

    var _computeItemUnitBundlePrice = function (item, receiptList) {
        var unitBundlePrice = new Decimal(0);
        _.each(receiptList, function (listItem) {
            if (listItem.index === item.index) {
                var itemPrice = new Decimal(listItem.itemPrice);

                unitBundlePrice = unitBundlePrice.plus(itemPrice);
            }
        });

        return unitBundlePrice.toNumber();
    };

    rules[rule.key] = rule;
};
