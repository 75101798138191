'use strict';


module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ProductSavePromptCtrl', [
        '$scope',
        function ($scope) {
            $scope.discard = function () {
                $scope.$close({
                    action: 'discard'
                });
            };
            $scope.save = function () {
                $scope.$close({
                    action: 'save'
                });
            };
        }]);
};
