'use strict';

module.exports = function (freshideasDirectivesCommon) {
    freshideasDirectivesCommon.directive('nownSupport', [
        function () {
            return {
                restrict: 'A',
                scope: {
                    modalClass: '=?',
                    iconVisible: '=?',
                    isLabelVisible: '=?labelVisible',
                },
                template: `
                    <button class="smb-pos__support-icon" ng-click="openHelpModel($event)" lucova-ripple ng-class="{'highlight': highlightHelp}">
                        <div class="smb-pos___support-modal-message" ng-show="!isIconVisible">
                            <span style="font-size: 18px; margin: 0px 5px;">{{ 'general.error.help' | translate }}</span>
                            <span class="lnr lnr-link" style="font-size: 20px;"></span>
                        </div>
                        <span class="lnr lnr-question-circle" ng-show="isIconVisible"></span>
                        <div class="smb-pos__support-icon-label" ng-if="isLabelVisible">{{ 'general.help' | translate }}</div>
                    </button>
                `,
                controller: [
                    '$scope',
                    '$modal',
                    '$timeout',
                    '$rootScope',
                    function ($scope, $modal, $timeout, $rootScope) {
                        const ANIMATION_DURATION = 2000; // ms
                        $scope.isIconVisible = true;

                        if ($scope.iconVisible != undefined || $scope.iconVisible != null) {
                            $scope.isIconVisible = $scope.iconVisible;
                        }

                        let modalWindowClass = (!$scope.modalClass) ? '' : $scope.modalClass;

                        $rootScope.$on('showWhatsNew', function (event, url) {
                            $scope.openHelpModel(event, url);
                        });

                        $scope.openHelpModel = function (event, url) {
                            // event.preventDefault();
                            event.stopPropagation();

                            let modalInstance = $modal.open({
                                templateUrl: 'common/partials/nown-support.tpl.html',
                                controller: 'SupportController',
                                animation: false,
                                windowClass: `modal-60 ${modalWindowClass}`,
                                resolve: {
                                    url: () => url
                                },
                                backdrop: false
                            });

                            modalInstance.result.finally(() => {
                                $scope.highlightHelp = true;
                                $timeout(() => {
                                    $scope.highlightHelp = false;
                                }, ANIMATION_DURATION);
                            });

                            return modalInstance.result;
                        };
                    }
                ],
            };
        }
    ]).controller('SupportController', [
        '$scope',
        '$sce',
        'HelpService',
        '$timeout',
        'url',
        function ($scope, $sce, HelpService, $timeout, url) {
            let baseUrl = 'https://support.nownpos.com/';
            $scope.supportPageIsLoading = true;

            let queryText = HelpService.getQueryText();
            let queryFor = (queryText && queryText.length > 0) ? `search?query=${queryText}` : '';

            let completeUrl = url || baseUrl + queryFor;
            $scope.supportUrl = $sce.trustAsResourceUrl(completeUrl);

            $scope.setSupportPageIsLoading = function (value = false) {
                $timeout(() => {
                    $scope.supportPageIsLoading = value;
                });
            };

            $scope.close = function (event) {
                event.stopPropagation();
                $scope.$close();
            };
        }
    ]).directive('ngOnload', [
        function () {
            return {
                restrict: 'A',
                scope: {
                    // callBack: '&ngOnload',
                },
                link: function (scope, element, attributes, controllers) {
                    element[0].onload = function (event) {
                        scope.$parent.setSupportPageIsLoading();
                    };
                }
            };
        }
    ]);
};
