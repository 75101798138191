'use strict';

/* globals ngGridFlexibleHeightPlugin */

const angular = require('angular');

module.exports = function (freshideasSettings) {

    freshideasSettings.controller('SettingsUsersController', [
        '$scope',
        'Users',
        'Utils',
        'ErrorUtil',
        'NgGridSortService',
        'NgGridTemplateService',
        'notificationTranslationHelper',
        '$q',
        '$modal',
        function ($scope, Users, Utils, ErrorUtil, NgGridSortService, NgGridTemplateService, notificationTranslationHelper, $q, $modal) {

            $scope.selectedUsers = [];
            $scope.userPagingOptions = {
                currentRowNumber: 0,
                currentPage: 1,
                pageSize: 10,
                startRecord: 0,
                endRecord: 0
            };
            $scope.pageSizeOptions = Utils.buildDefaultPageSizes();
            $scope.userSearch = {
                offset: 1,
                limit: $scope.userPagingOptions.pageSize,
                companyId: undefined,
                showFilters: true,
            };

            $scope.myDefsUser = [
                {
                    field: 'userId',
                    displayName: 'users.table.colUserId',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    width: '*'
                },
                {
                    field: 'username',
                    displayName: 'users.table.colUsername',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    width: '*'
                },
                {
                    field: 'email',
                    displayName: 'users.table.colEmailAddress',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    width: '**'
                },
                {
                    field: 'firstname',
                    displayName: 'users.table.colFirstName',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    width: '*'
                },
                {
                    field: 'lastname',
                    displayName: 'users.table.colLastName',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    width: '*'
                },
                {
                    field: 'roleName',
                    displayName: 'users.table.colPermission',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    width: '*'
                },
                {
                    field: '',
                    displayName: 'general.actions',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()">' + '<span ng-cell-text>' + '<div class="action-buttons">' + '<a ng-click="editUser(row.entity)" class="blue" has-perm resource="users_patrons:users:edit"><i class="fa fa-pencil bigger-130"></i></a>' + '<a class="blue" change-password-for-user user="row.entity" has-perm resource="users_patrons:users:edit_passwords"><i class="fa fa-key bigger-130" rel="popover" title="{{\'users.passwordSelfService.popover\' |translate}}"></i></a>' + '<a confirm-modal params="userName" entity-prefix="row.entity." confirm-message="users.actions.confirmDeleteUser" confirm-title="users.table.confirmDeleteTitle" on-confirm="deleteUser(row.entity)" class="red" has-perm resource="users_patrons:users:delete"><i class="fa fa-trash-o bigger-130"></i></a>' + '</div>' + '</span>' + '</div>'
                }];
            $scope.userGridOptions = {
                plugins: [new ngGridFlexibleHeightPlugin({minHeight: 400})],
                showFooter: true,
                footerRowHeight: 30,
                rowHeight: 30,
                multiSelect: true,
                showSelectionCheckbox: true,
                selectedItems: $scope.selectedUsers,
                enableSorting: true,
                selectWithCheckboxOnly: true,
                enableColumnResize: true,
                enableHighlighting: true,
                footerTemplate: '<div class="grid-footer"><div class="col-xs-4"></div><div class="col-xs-4"><pager align="false" ng-model="userPagingOptions.currentPage" ' + 'total-items="userPagingOptions.totalItems" items-per-page="userPagingOptions.pageSize" ng-change="loadUsers();"></pager>' + '</div><div class="col-xs-4"><span translate="general.records"></span> {{userPagingOptions.startRecord}} - {{userPagingOptions.endRecord}}</div></div>',
                data: 'userGridData',
                init: function (grid, $scope) {
                    setTimeout(function () {
                        $scope.userGridOptions.$gridServices.DomUtilityService.RebuildGrid(
                            $scope.userGridOptions.$gridScope,
                            $scope.userGridOptions.ngGrid
                        );
                    }, 10);
                },
                columnDefs: 'myDefsUser'
            };

            $scope.addUser = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalEditUser.tpl.html',
                    controller: 'EditUserCtrl',
                    resolve: {
                        user: function () {
                            return undefined;
                        },
                        companyId: function () {
                            return $scope.selectedCompany.id;
                        },
                        isEdit: function () {
                            return false;
                        }
                    },
                    backdrop: 'static'
                });
                modalInstance.result.then(function (user) {
                    notificationTranslationHelper.notifyMessage('users.addUser.success', {user: user.userName});
                    $scope.loadUsers();
                });
            };
            $scope.deleteUser = function (userToDelete, isBatch) {
                return Users.deleteUser(userToDelete, function (response) {
                    if (!isBatch) {
                        notificationTranslationHelper.notifyMessage('users.deleteUser.success');
                        $scope.loadUsers();
                    }
                }, function (error) {
                    notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                }).$promise;
            };
            $scope.deleteSelectedUsers = function () {
                var deletedPromises = [];
                _.each($scope.selectedUsers, function (entry) {
                    deletedPromises.push($scope.deleteUser(entry, true));
                });
                $q.all(deletedPromises).then(function () {
                    notificationTranslationHelper.notifyMessage('users.deleteSelected.success');
                    $scope.loadUsers();
                });
                $scope.selectedUsers = [];
            };
            $scope.editUser = function (userToEdit) {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalEditUser.tpl.html',
                    controller: 'EditUserCtrl',
                    resolve: {
                        user: function () {
                            return userToEdit;
                        },
                        companyId: function () {
                            return userToEdit.companyId;
                        },
                        isEdit: function () {
                            return true;
                        }
                    },
                    backdrop: 'static'
                });
                modalInstance.result.then(function (user) {
                    notificationTranslationHelper.notifyMessage('users.editUser.success', {user: user.userName});
                    $scope.loadUsers();
                });
            };
            $scope.loadUsers = function () {
                if (angular.isDefined($scope.userSearch)) {
                    $scope.userSearch.offset = Utils.serverSidePagingRowNumber($scope.userPagingOptions.currentPage, $scope.userPagingOptions.pageSize);
                    $scope.userSearch.limit = $scope.userPagingOptions.pageSize;
                    $scope.userSearch.companyId = $scope.selectedCompany.id;
                    Users.listUsers($scope.userSearch).$promise.then(function (response) {
                        $scope.userGridData = response.entries;
                        $scope.userPagingOptions.totalItems = Utils.serverSideTotalItems(response.entries.length, $scope.userPagingOptions.currentPage, $scope.userPagingOptions.pageSize);
                        $scope.userPagingOptions.startRecord = Utils.serverSideStartRecord($scope.userPagingOptions.currentPage, $scope.userPagingOptions.pageSize);
                        $scope.userPagingOptions.endRecord = Utils.serverSideEndRecord(response.entries.length, $scope.userPagingOptions.currentPage, $scope.userPagingOptions.pageSize);
                        if (!response.entries || response.entries.length < 1) {
                            $scope.userGridData = [];
                        }
                    }, function (error) {
                        $scope.userGridData = [];
                        notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                    });
                }
            };
            $scope.$watch('selectedCompany.id', function (companyId) {
                if (companyId) {
                    $scope.loadUsers();
                } else {
                    $scope.userGridData = [];
                }
            });

        }
    ]);
};
