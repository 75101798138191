'use strict';

const angular = require('angular');

module.exports = function (freshideasInventory) {

    freshideasInventory.controller('InventoryItemEditCtrl', [
        '$scope',
        '$q',
        '$modal',
        'selectedInventoryItem',
        'selectedCompanyId',
        'location',
        'printers',
        'taxRates',
        'isEdit',
        'Inventory',
        function ($scope, $q, $modal, selectedInventoryItem, selectedCompanyId,
                  location, printers, taxRates, isEdit, Inventory) {
            initializeInventoryItem();
            $scope.location = location;
            $scope.printers = printers;
            $scope.taxRates = _.map(taxRates, function (taxRate) {
                taxRate.id = parseInt(taxRate.id);
                return taxRate;
            });
            $scope.revertInventoryItem = function () {
                initializeInventoryItem();
            };
            $scope.saveInventoryItem = function () {
                if (isEdit) {
                    $scope.selectedInventoryItem.inventoryItemId =
                        $scope.selectedInventoryItem.id;
                    Inventory.updateInventory(
                        {},
                        $scope.selectedInventoryItem,
                        function (response) {
                            $scope.$close();
                        }
                    );
                } else {
                    $scope.selectedInventoryItem.locationId =
                        $scope.location.id;
                    Inventory.putInventory(
                        {},
                        $scope.selectedInventoryItem,
                        function (response) {
                            $scope.$close();
                        }
                    );
                }
            };
            function initializeInventoryItem () {
                $scope.selectedInventoryItem = angular.copy(
                    selectedInventoryItem || {}
                );
                if (!isEdit) {
                    $scope.selectedInventoryItem.isNew = true;
                } else {
                    $scope.selectedInventoryItem.mealPlanIndicator =
                        !!$scope.selectedInventoryItem.mealIndicator;
                }
            }

            // This code is a workaround to accomodate the UPC scanner
            // when it sends a carriage return

            // $scope.addInventoryItem = function () {
            //     saveInventoryItem();
            //     $scope.$close();
            // };
            // $scope.submitInventoryItem = function () {
            //     var item = angular.copy($scope.selectedInventoryItem);
            //     $modal.open({
            //         templateUrl: 'inventory/inventory.inventoryItem' +
            //                 '.edit.confirmation.tpl.html',
            //         controller: ['$scope', function ($scope) {
            //             $scope.item = item;
            //             $scope.isEdit = !!isEdit;
            //             $scope.confirm = function () {
            //                 saveInventoryItem();
            //                 $scope.$close();
            //             };
            //         }],
            //         windowClass: 'modal',
            //         backdrop: 'static',
            //     });
            // };
        },
    ]);
};
