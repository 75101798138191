'use strict';


const angular = require('angular');
const ngResource = require('angular-resource');

export default angular.module('freshideas.resources.admin', [ngResource]).service('Admin', [
    '$resource',
    'EnvConfig',
    function ($resource, EnvConfig) {
        return $resource(EnvConfig.host + '/freshideas/web/admin', {}, {
            'getPlatformUpdateSettings': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/admin/platform-update'
            },
            'getLocation': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/admin/location'
            },
            'getTransactionCountByOrganization': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/admin/organization-transaction-count',
                isArray: true
            },
            'getTransactionCountByLocation': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/admin/location-transaction-count',
                isArray: true
            },
        });
    }
]);
