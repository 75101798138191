'use strict';

module.exports = function (posModule) {

    posModule.controller('SetupSelectLocationCtrl', [
        '$scope',
        '$modal',
        '$modalInstance',
        'locations',
        function (
            $scope,
            $modal,
            $modalInstance,
            locations) {

            var tmplocations = angular.copy(locations);
            $scope.locations = _.filter(tmplocations, function (location) {
                return location.companyId > 0;
            });

            $scope.filteredLocations = angular.copy($scope.locations);

            /** Function to save the changes of the modal
            **  Calls the function to check if the price is changed or not
            **  and closes the modal
            **/
            $scope.save = function () {
                // checkIfPriceChanged();
                $modalInstance.close($scope.locations);
            };

            $scope.selectLocation = function (location) {
                _.each($scope.locations, function (orgLocation) {
                    if (orgLocation.id != location.id) {
                        orgLocation.isSelected = false;
                    }
                });

                location.isSelected = true;
            };

            $scope.clearLocationSearch = function () {
                $scope.locationSearch = '';
            };

            /**
            ** Init function for the controller
            **/
            $scope.init = function () {
            };

            $scope.init();
        }
    ]);
};
