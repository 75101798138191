module.exports = (freshideasPatronsgrow) => {
    freshideasPatronsgrow.controller('PatronsSidenav', [
        '$scope',
        '$state',
        '$translate',
        'GatewayFiit',
        'CurrentSession',
        'CompanyAttributesService',
        function (
            $scope,
            $state,
            $translate,
            GatewayFiit,
            CurrentSession,
            CompanyAttributesService
        ) {
            $scope.state = $state;
            $scope.navOpen = true;

            $scope.openNav = (param) => {
                $scope.navOpen = param;
            };

            $scope.isRootCompany = CurrentSession.isRootCompany();
            $scope.isCampus = CurrentSession.isCampus();
            $scope.navBtns = [
                {
                    url: '/customers',
                    show: true,
                    name: $translate.instant('customer.sidenav.customerList'),
                    navigateTo: 'freshideas.patrons',
                    position: 0
                },
                {
                    url: '/customers/grow',
                    show: ($scope.isRootCompany && !$scope.isCampus),
                    name: $translate.instant('customer.sidenav.grow'),
                    navigateTo: 'freshideas.customersGrow',
                    position: 1
                },
                {
                    url: '/customers/loyalty',
                    show: ($scope.isRootCompany && !$scope.isCampus && CompanyAttributesService.loyaltyStepsEnabled()),
                    name: $translate.instant('general.loyalty'),
                    navigateTo: 'freshideas.customersLoyalty',
                    position: 2
                }
            ];

            $scope.changeView = (view) => {
                $scope.openNav(false);
                $scope.state.go(view);
            };

            const onInit = () => {
                if ($state.current.url !== '/customers') {
                    $scope.openNav(false);
                }
            };

            onInit();
        }
    ]);
};
