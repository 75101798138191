'use strict';


const angular = require('angular');
const freshideasResourcesLocations = require('../common/resources/location.js').default;
const freshideasLocation = angular.module('freshideas.location', [freshideasResourcesLocations.name]);

freshideasLocation
.factory('LocationFactory', ['Locations', 'Lookup', 'CashierShift', 'Inventory', 'Security', 'PosAlertService', function (Locations, Lookup, CashierShift, Inventory, Security, PosAlertService) {

    var listType = 'bar-items';
    var isSideContent = false;
    var sideContent = {};

    var location = null;
    var servicePeriod = null;

    var hierarchy = {};
    var allItems = null;
    var modifiers = null;
    var categories = null;

    var servicePeriods = [];
    var taxRates = [];
    var printers = [];
    var periods = [];
    var companyId = Security.getUser().companyId;

    var searchString = '';
    var selectedListItems = [];
    var filteredList = null;

    var sideSearchString = '';
    var selectedSideListItems = [];
    var filteredSideList = null;

    var crumbs = [];

    var tabs = [
        {'name': 'ITEMS', 'id': 'items'},
        {'name': 'CATEGORIES', 'id': 'categories'},
        {'name': 'MODIFIERS', 'id': 'modifiers'}
    ];
    var currentTab = 'items';

    /* MAIN LIST */

    var selectListItem = function (listItem) {

        var index = this.selectedListItems.indexOf(listItem);

        if (index === -1) {
            this.selectedListItems.push(listItem);
        } else {
            this.selectedListItems.splice(index, 1);
        }
    };

    var selectAllListItems = function () {
        var self = this;

        _.each(this.filteredList, function (listItem) {
            if (self.selectedListItems.indexOf(listItem) === -1) {
                self.selectedListItems.push(listItem);
            }
        });
    };

    var deselectAllListItems = function (itemId) {

        this.selectedListItems = [];
    };

    var filterSearch = function () {

        var val = this.searchString.toLowerCase();

        this.filteredList = this.listItems ? _.filter(this.listItems, function (child) {
                return child['name'].toLowerCase().indexOf(val.toLowerCase()) !== -1;
            }) : null;
    };

    /* SIDE LIST */

    var selectSideListItem = function (listItem) {

        var index = this.selectedSideListItems.indexOf(listItem);

        if (index === -1) {
            this.selectedSideListItems.push(listItem);
        } else {
            this.selectedSideListItems.splice(index, 1);
        }
    };

    var selectAllSideListItems = function () {
        var self = this;

        _.each(this.filteredSideList, function (listItem) {
            if (self.selectedSideListItems.indexOf(listItem) === -1) {
                self.selectedSideListItems.push(listItem);
            }
        });
    };

    var deselectAllSideListItems = function (itemId) {

        this.selectedSideListItems = [];
    };

    var filterSideSearch = function () {

        var val = this.sideSearchString.toLowerCase();
        this.sideListItems = [];

        if (this.currentTab === 'items') {
            this.sideListItems = this.allItems;
        } else if (this.currentTab === 'categories') {
            this.sideListItems = this.categories;
        } else if (this.currentTab === 'modifiers') {
            this.sideListItems = this.modifiers;
        }

        this.filteredSideList = this.sideListItems ? _.filter(this.sideListItems, function (child) {
                return child['name'].toLowerCase().indexOf(val.toLowerCase()) !== -1;
            }) : null;

        this.filteredSideList = _.difference(this.filteredSideList, this.filteredList);
    };

    var selectTab = function (tabId) {

        this.selectedSideListItems = [];
        this.currentTab = tabId;
        this.filterSideSearch();
    };

    var loadLocations = function () {
        var self = this;
        return Lookup.companyLocations({'companyId': this.companyId}).$promise.then(function (response) {
            self.locations = _.map(response, function (location) {
                location.id = parseInt(location.id);
                return location;
            });
            return self.locations;
        });
    };

    var loadTaxRates = function () {
        return [];
    };

    var loadLocationPrinters = function () {
        var self = this;
        return Locations.getLocationPrinters({'locationId': this.locationId}).$promise.then(function (response) {
            self.printers = _.map(response.entries, function (printer) {
                printer.printerId = parseInt(printer.printerId);
                return printer;
            });
        });
    };

    var loadServicePeriods = function () {
        var self = this;
        return Lookup.locationServicePeriods({'locationId': this.locationId}).$promise.then(function (response) {
            self.periods = _.map(response, function (servicePeriod) {
                servicePeriod.servicePeriodId = parseInt(servicePeriod.servicePeriodId);
                return servicePeriod;
            });
        });
    };

    var loadLocationHierarchy = function (filterInactive = false, topLevel = false, limit = 1) {
        this.listItems = null;
        this.crumbs = [];
        this.selectedListItems = [];
    };

    var loadLocationHierarchyItems = function () {
        return this.loadLocationHierarchy(false, false, 1);
    };

    var loadLocationMenu = function () {
        // var self = this;
        // return CashierShift.posMenuAll({'locationId': this.locationId}).$promise.then(function (response) {

        //     self.servicePeriods = response.posMenuItemEntries;

        //     if (self.servicePeriod) {
        //         self.servicePeriod = _.find(self.servicePeriods, {'servicePeriodId': self.servicePeriod.servicePeriodId});

        //         if (self.servicePeriod) {
        //             self.allItems = self.servicePeriod.entries;
        //         } else {
        //             self.allItems = [];
        //         }
        //     }

        //     self.filterSideSearch();

        //     return self.servicePeriods;
        // });
    };

    var loadLocationCategories = function () {
        var self = this;
        this.categories = null;

        Locations.getLocationCategories({'locationId': this.locationId}).$promise.then(function (response) {

            self.categories = _.filter(response.entries, function (cat) {
                var categoryServicePeriodIds = cat.servicePeriodIds || [];
                return categoryServicePeriodIds.indexOf(self.servicePeriod.servicePeriodId) > -1;
            });

            self.filterSideSearch();
        });
    };

    var loadLocationModifiers = function () {
        var self = this;
        this.modifiers = null;

        Locations.getLocationModifiers({'locationId': this.locationId}).$promise.then(function (response) {

            self.modifiers = _.filter(response.entries, function (mod) {
                var modifierServicePeriodIds = mod.servicePeriodIds || [];
                return modifierServicePeriodIds.indexOf(self.servicePeriod.servicePeriodId);
            });

            self.filterSideSearch();
        });
    };

    /* SET LEVELS */

    var setLocation = function (loc) {
        this.resetSelections();
        this.crumbs.length = 0;
        this.location = loc;
        this.locationId = (loc)? loc.id : null;
        this.dismissSideContent();
    };

    var setServicePeriod = function (servicePeriod) {
        this.resetSelections();
        this.crumbs.length = 0;
        this.servicePeriod = servicePeriod;
        if (servicePeriod) {
            this.allItems = servicePeriod.entries;
        } else {
            this.allItems = [];
        }
        this.loadLocationHierarchyItems();
        this.loadLocationCategories();
        this.loadLocationModifiers();
        this.filterSideSearch();
        this.dismissSideContent();
    };

    var navigateTree = function (item) {
        var itemCopy = angular.copy(item);
        delete itemCopy['children'];
        this.crumbs.push(itemCopy);

        this.loadLocationHierarchyItems();
    };

    var navigatedTree = function (item) {
        this.crumbs.pop();
        this.crumbs.push(item);
        this.isSideContent = false;
        this.sideContent = {};
        this.selectedListItems = [];
        this.listItems = item.children;
        this.filterSearch();
    };

    var back = function () {
        this.crumbs.pop();
        this.loadLocationHierarchyItems();
    };

    var checkPop = function (itemId) {

        var index = _.findIndex(this.crumbs, function (el) {
            return el.locationServicePeriodMenuId === itemId;
        });

        if (index > -1) {
            this.crumbs.splice(index, (this.crumbs.length - index));
            this.loadLocationHierarchyItems();
        }
    };

    /* ASSOCIATIONS */

    var resetSelections = function () {
        this.selectedListItems = [];
        this.selectedSideListItems = [];
    };


    var addToParent = function (parentId, childIds) {

        var associations = {
            'locationId': this.locationId,
            'parentIds': [parentId],
            'childIds': childIds
        };

        var self = this;

        if (associations) {
            return Locations.addAssociations(associations).$promise.then(function (response) {
                self.resetSelections();
                self.loadLocationHierarchyItems();

                return true;
            });
        }
    };

    var deleteFromParent = function () {

        var childIdsAndTypes = this.getItemIdsAndTypes(this.selectedListItems);

        var parentIds = [];
        var childIds = childIdsAndTypes.ids;

        var self = this;

        if (this.crumbs.length > 0) {
            var itemId = this.getLastCrumbId();
            parentIds.push(itemId);

            this.resetSelections();
        } else {
            _.each(this.selectedListItems, function (listItem) {
                listItem.forceTopLevel = false;

                if (listItem.type === 'item') {
                    self.editMenuItem(listItem);
                } else if (listItem.type === 'category') {
                    self.editCategory(listItem, false);
                } else if (listItem.type === 'modifier') {
                    self.editModifier(listItem, false);
                }
            });

            this.resetSelections();
            this.loadLocationHierarchyItems();

            return;
        }

        var association = {
            'locationId': this.locationId,
            'parentIds': parentIds,
            'childIds': childIds
        };

        if (association) {
            return Locations.removeAssociations(association).$promise.then(function (response) {
                self.loadLocationHierarchyItems();

                return true;
            });
        }
    };

    var groupSelected = function () {
        var item = {
            name: 'No Name',
            description: '',
            color: '#FFFFFF',
            active: true,
            forceTopLevel: false,
            allowMultipleSelection: true,
            minSelection: -1,
            maxSelection: -1,
            includes: 0
        };

        var self = this;
        var parent = this.getLastCrumb();
        var childIdsAndTypes = self.getItemIdsAndTypes(this.selectedSideListItems);
        var childIds = childIdsAndTypes.ids;

        if (!parent) {
            item.forceTopLevel = true;
        }

        this.resetSelections();

        this.addCategory(item).then(function (response) {
            if (parent) {
                var parentId = self.getLastCrumbId();

                self.addToParent(parentId, [response.locationServicePeriodMenuId]).then(function () {
                    self.addToParent(response.locationServicePeriodMenuId, childIds).then(function () {
                        self.showEditCategory(response);
                    });
                });
            } else {
                self.addToParent(response.locationServicePeriodMenuId, childIds).then(function () {
                    self.showEditCategory(response);
                });
            }
        });
    };

    var addAssociations = function () {

        var parentIdsAndTypes = this.getItemIdsAndTypes(this.selectedListItems);
        var childIdsAndTypes = this.getItemIdsAndTypes(this.selectedSideListItems);

        var parentIds = parentIdsAndTypes.ids;
        var childIds = childIdsAndTypes.ids;

        var self = this;

        if (parentIds.length === 0) {
            if (this.crumbs.length > 0) {
                var item = this.getLastCrumb();
                var itemId = this.getLastCrumbId();
                parentIds.push(itemId);
                parentIdsAndTypes.types.push(item.type);

                this.resetSelections();
            } else {

                _.each(this.selectedSideListItems, function (listItem) {

                    if (listItem.type !== 'modifier') {
                        listItem.forceTopLevel = true;
                        self.editMenuItem(listItem);
                    }
                });

                this.resetSelections();

                return;
            }
        }

        if (parentIdsAndTypes.types.indexOf('item') !== -1 && childIdsAndTypes.types.indexOf('item') !== -1) {
            PosAlertService.showError('error', 'Invalid Item', '', 'You cannot add an item inside another item. To do this you\'ll need to first place it inside a modifier.');
            return;
        }

        var associations = {
            'locationId': this.locationId,
            'parentIds': parentIds,
            'childIds': childIds
        };

        if (associations) {
            return Locations.addAssociations(associations).$promise.then(function (response) {
                self.loadLocationHierarchyItems();
                self.resetSelections();

                return true;
            }).catch(function (error) {
                PosAlertService.showError('error', 'Invalid Item', '', 'This item cannot be added as a sub-item of itself, please try a different menu item.');
            });
        }
    };

    var getLastCrumb = function () {
        if (this.crumbs.length > 0) {
            return this.crumbs[this.crumbs.length - 1];
        }

        return null;
    };

    var getLastCrumbId = function () {
        var listItem = this.getLastCrumb();

        if (listItem) {
            return listItem.locationServicePeriodMenuId;
        }

        return null;
    };


    var getItemIdsAndTypes = function (items) {

        var ids = [];
        var types = [];

        _.each(items, function (listItem) {
            ids.push(listItem.locationServicePeriodMenuId);
            types.push(listItem.type);
        });

        return {ids: ids, types: types};
    };

    var isEligible = function (itemId) {

        var found = _.find(this.filteredList, function (el) {
            return el.locationServicePeriodMenuId === itemId;
        });

        return found === null || found === undefined;
    };

    var addMenuItem = function (item) {
        if (item.name !== '') {
            var self = this;
            var parent = self.getLastCrumb();

            if (!parent) {
                item.forceTopLevel = true;
            }

            return Inventory.addMenuItem({}, item, function (response) {
                self.dismissSideContent();

                if (response && response.locationServicePeriodMenuId) {

                    self.allItems.push(response);
                    self.filterSideSearch();

                    if (parent && (parent.type === 'category' || parent.type === 'modifier')) {
                        self.addToParent(parent.locationServicePeriodMenuId, [response.locationServicePeriodMenuId]).then(function () {
                            PosAlertService.showError('success', 'Created Item', '', 'The item has been successfully created and added to this list.');
                        });
                    } else {
                        PosAlertService.showError('success', 'Created Item', '', 'The item has been successfully created.');
                    }

                    return response;
                } else {
                    PosAlertService.showError('error', 'Error Creating Item', '', 'The item could not be created.');
                }

                return null;
            });
        }

        return null;
    };

    var editMenuItem = function (item, showAlert = false) {
        var self = this;
        return Inventory.updateMenuItem({'menuItemId': item.locationServicePeriodMenuId}, item, function (response) {
            self.dismissSideContent();
            self.resetSelections();

            if (showAlert) {
                PosAlertService.showError('success', 'Updated Item', '', 'This item has been updated.');
            }

            var index = _.findIndex(self.allItems, function (el) {
                return el.locationServicePeriodMenuId === item.locationServicePeriodMenuId;
            });

            if (index > -1) {
                self.allItems.splice(index, 1);
                self.allItems.push(item);
            }
            self.filterSideSearch();
        });
    };

    var deleteMenuItem = function (item) {
        var self = this;
        return Inventory.deleteMenuItem({'menuItemId': item.locationServicePeriodMenuId}).$promise.then(function (response) {
            self.dismissSideContent();
            self.resetSelections();

            PosAlertService.showError('success', 'Deleted Item', '', 'This item has been deleted.');

            var index = _.findIndex(self.allItems, function (el) {
                return el.locationServicePeriodMenuId === item.locationServicePeriodMenuId;
            });

            if (index > -1) {
                self.allItems.splice(index, 1);
            }

            self.checkPop(item.locationServicePeriodMenuId);
            self.filterSideSearch();
        });
    };

    var addCategory = function (item) {
        if (item.name !== '') {
            item.locationId = this.locationId;

            // remove for now until there is a way to specify the service period to add to
            // item.servicePeriodId = this.servicePeriod.servicePeriodId;

            var self = this;
            var parent = this.getLastCrumb();

            if (!parent) {
                item.forceTopLevel = true;
            }

            return Locations.addCategory(item).$promise.then(function (response) {
                self.dismissSideContent();

                var parentId = self.getLastCrumbId();
                if (response && response.locationServicePeriodMenuId) {
                    self.categories.push(response);
                    self.filterSideSearch();

                    if (parent && parent.type === 'category') {
                        self.addToParent(parentId, [response.locationServicePeriodMenuId]).then(function () {
                            PosAlertService.showError('success', 'Created Category', '', 'The category has been successfully created and added to this list.');
                        });
                    } else {
                        PosAlertService.showError('success', 'Created Category', '', 'The category has been successfully created.');
                    }

                    return response;
                } else {
                    PosAlertService.showError('error', 'Error Creating Category', '', 'The category could not be created.');
                }

                return null;
            });
        }

        return null;
    };

    var editCategory = function (item, shouldAlert = true) {

        item.locationId = this.locationId;

        var self = this;

        return Locations.updateCategory(item, function (response) {
            self.dismissSideContent();
            self.resetSelections();

            if (shouldAlert) {
                PosAlertService.showError('success', 'Updated Category', '', 'This category has been updated.');
            }

            var index = _.findIndex(self.categories, function (el) {
                return el.locationServicePeriodMenuId === item.locationServicePeriodMenuId;
            });

            if (index > -1) {
                self.categories.splice(index, 1);
                self.categories.push(item);
            }
            self.filterSideSearch();
        });
    };

    var deleteCategory = function (item) {
        var self = this;

        return Locations.deleteCategory({'menuCategoryId': item.locationServicePeriodMenuId}).$promise.then(function (response) {
            self.dismissSideContent();
            self.resetSelections();

            PosAlertService.showError('success', 'Deleted Category', '', 'This category has been deleted.');

            var index = _.findIndex(self.categories, function (el) {
                return el.locationServicePeriodMenuId === item.locationServicePeriodMenuId;
            });

            if (index > -1) {
                self.categories.splice(index, 1);
            }

            self.checkPop(item.locationServicePeriodMenuId);
            self.filterSideSearch();
        });
    };

    var addModifier = function (item) {
        if (item.name !== '') {
            item.locationId = this.locationId;

            // remove for now until there is a way to specify the service period to add to
            // item.servicePeriodId = this.servicePeriod.servicePeriodId;

            var self = this;
            var parent = this.getLastCrumb();

            return Locations.addModifier(item).$promise.then(function (response) {
                self.dismissSideContent();

                var parentId = self.getLastCrumbId();
                if (response && response.locationServicePeriodMenuId) {
                    self.modifiers.push(response);
                    self.filterSideSearch();

                    if (parent && parent.type === 'item') {
                        self.addToParent(parentId, [response.locationServicePeriodMenuId]).then(function () {
                            PosAlertService.showError('success', 'Created Modifier', '', 'The modifier has been successfully created and added to this list.');
                        });
                    } else {
                        PosAlertService.showError('success', 'Created Modifier', '', 'The modifier has been successfully created.');
                    }

                    return response;
                } else {
                    PosAlertService.showError('error', 'Error Creating Modifier', '', 'The modifier could not be created.');
                }

                return null;
            });
        }

        return null;
    };

    var editModifier = function (item, shouldAlert = true) {

        item.locationId = this.locationId;

        var self = this;

        return Locations.updateModifier(item, function (response) {
            self.dismissSideContent();
            self.resetSelections();

            if (shouldAlert) {
                PosAlertService.showError('success', 'Updated Modifier', '', 'This modifier has been updated.');
            }

            var index = _.findIndex(self.modifiers, function (el) {
                return el.locationServicePeriodMenuId === item.locationServicePeriodMenuId;
            });

            if (index > -1) {
                self.modifiers.splice(index, 1);
                self.modifiers.push(item);
            }
            self.filterSideSearch();
        });
    };

    var deleteModifier = function (item) {
        var self = this;

        return Locations.deleteModifier({'menuModifierId': item.locationServicePeriodMenuId}).$promise.then(function (response) {
            self.dismissSideContent();
            self.resetSelections();

            PosAlertService.showError('success', 'Deleted Modifier', '', 'This modifier has been deleted.');

            var index = _.findIndex(self.modifiers, function (el) {
                return el.locationServicePeriodMenuId === item.locationServicePeriodMenuId;
            });

            if (index > -1) {
                self.modifiers.splice(index, 1);
            }

            self.checkPop(item.locationServicePeriodMenuId);
            self.filterSideSearch();
        });
    };

    var showMainContent = function (type, data) {
        this.mainContent = {
            type: type,
            data: data
        };
    };

    var showSideContent = function (type, data, callback) {
        this.isSideContent = true;
        this.sideContent = {
            type: type,
            data: data,
            callback: callback
        };
    };

    var showAddItem = function () {
        this.showSideContent('add-item', {});
    };

    var showAddCategory = function () {
        this.showSideContent('add-category', {});
    };

    var showAddModifier = function () {
        this.showSideContent('add-modifier', {});
    };

    var showEditItem = function (listItem) {
        this.showSideContent('edit-item', angular.copy(listItem));
    };

    var showEditCategory = function (listItem) {
        this.showSideContent('edit-category', angular.copy(listItem));
    };

    var showEditModifier = function (listItem) {
        this.showSideContent('edit-modifier', angular.copy(listItem));
    };

    var dismissSideContent = function () {
        this.loadLocationHierarchyItems();
        this.isSideContent = false;
        this.sideContent = {};
    };

    return {
        allItems: allItems,
        searchString: searchString,
        sideSearchString: sideSearchString,
        listType: listType,
        isSideContent: isSideContent,
        sideContent: sideContent,
        periods: periods,
        taxRates: taxRates,
        printers: printers,
        servicePeriods: servicePeriods,
        categories: categories,
        modifiers: modifiers,
        companyId: companyId,
        location: location,
        setLocation: setLocation,
        servicePeriod: servicePeriod,
        addToParent: addToParent,
        deleteFromParent: deleteFromParent,
        addCategory: addCategory,
        editCategory: editCategory,
        deleteCategory: deleteCategory,
        addModifier: addModifier,
        editModifier: editModifier,
        deleteModifier: deleteModifier,
        addMenuItem: addMenuItem,
        editMenuItem: editMenuItem,
        deleteMenuItem: deleteMenuItem,
        addAssociations: addAssociations,
        showAddItem: showAddItem,
        showAddCategory: showAddCategory,
        showAddModifier: showAddModifier,
        showEditItem: showEditItem,
        showEditCategory: showEditCategory,
        showEditModifier: showEditModifier,
        showMainContent: showMainContent,
        showSideContent: showSideContent,
        dismissSideContent: dismissSideContent,
        setServicePeriod: setServicePeriod,
        loadLocations: loadLocations,
        loadServicePeriods: loadServicePeriods,
        loadLocationMenu: loadLocationMenu,
        loadTaxRates: loadTaxRates,
        loadLocationPrinters: loadLocationPrinters,
        loadLocationCategories: loadLocationCategories,
        loadLocationModifiers: loadLocationModifiers,
        loadLocationHierarchy: loadLocationHierarchy,
        loadLocationHierarchyItems: loadLocationHierarchyItems,
        hierarchy: hierarchy,
        getLastCrumb: getLastCrumb,
        getLastCrumbId: getLastCrumbId,
        filterSearch: filterSearch,
        filteredList: filteredList,
        filterSideSearch: filterSideSearch,
        filteredSideList: filteredSideList,
        selectedListItems: selectedListItems,
        selectListItem: selectListItem,
        selectAllListItems: selectAllListItems,
        deselectAllListItems: deselectAllListItems,
        selectedSideListItems: selectedSideListItems,
        selectSideListItem: selectSideListItem,
        selectAllSideListItems: selectAllSideListItems,
        deselectAllSideListItems: deselectAllSideListItems,
        groupSelected: groupSelected,
        navigateTree: navigateTree,
        navigatedTree: navigatedTree,
        crumbs: crumbs,
        back: back,
        tabs: tabs,
        selectTab: selectTab,
        currentTab: currentTab,
        isEligible: isEligible,
        getItemIdsAndTypes: getItemIdsAndTypes,
        resetSelections: resetSelections,
        checkPop: checkPop
    };
}])
.controller('LocationCtrl', [
    '$scope',
    '$modal',
    '$q',
    '$translate',
    '$location',
    'LocationFactory',
    'Locations',
    'Company',
    'notificationTranslationHelper',
    'ErrorUtil',
    'Security',
    function ($scope, $modal, $q, $translate, $location, LocationFactory, Locations, Company, notificationTranslationHelper, ErrorUtil, Security) {

        $scope.locationFactory = LocationFactory;
        $scope.mainContent = {name: 'servicePeriod'};
        $scope.hasSidebarNav = true;

        $scope.showMeals = (Security.getUser().company.companyType !== 'SMB');

        var init = function () {
            console.log('Started Location');
            $scope.loadLocations();
        };

        $scope.loadLocations = function () {
            $scope.locationFactory.loadLocations().then(function (locations) {
                $scope.setCurrentLocation(locations[0]);
                $scope.openLocationOptions();
            });
        };

        $scope.toggleSideNavBar = function () {
            $scope.hasSidebarNav = !$scope.hasSidebarNav;
        };

        $scope.setCurrentLocation = function (location) {
            if ($scope.locationFactory.location != location) {
                $scope.locationFactory.setLocation(location);

                $scope.locationFactory.loadLocationMenu().then(function (servicePeriods) {
                    $scope.showServicePeriod(servicePeriods[0]);
                });

                $scope.locationFactory.loadLocationPrinters();
                $scope.locationFactory.loadTaxRates();
            }
        };
        $scope.showServicePeriod = function (servicePeriod) {
            $scope.locationFactory.showMainContent('servicePeriod', {name: (servicePeriod)? servicePeriod.servicePeriodName: null});
            $scope.locationFactory.setServicePeriod(servicePeriod);
        };

        $scope.showInventory = function () {
            $scope.locationFactory.showMainContent('inventory', {name: 'Inventory List'});
        };
        $scope.showInvoice = function () {
            $scope.locationFactory.showMainContent('invoice', {name: 'Invoice List'});
        };

        $scope.setMainContent = function (contentName, data) {
            $scope.mainContent = {
                name: contentName,
                data: data
            };
        };

        $scope.openLocationOptions = function () {
            var modalInstance = $modal.open({
                templateUrl: 'common/modals/modalSelectLocation.tpl.html',
                controller: 'SelectLocationCtrl',
                resolve: {
                    locations: function () {
                        return $scope.locationFactory.locations;
                    },
                    currentLocation: function () {
                        return $scope.locationFactory.location;
                    }
                },
                windowClass: 'modal-50',
                backdrop: 'static'
            });
            modalInstance.result.then(function (location) {
                $scope.setCurrentLocation(location);
            });
        };

        init();
    }
])
.controller(
    'SelectLocationCtrl', [
        '$scope',
        'locations',
        'currentLocation',
        function ($scope, locations, currentLocation) {
            $scope.locations = locations;
            $scope.currentLocation = currentLocation;
        }
    ]
);

require('./location-menu-list/location-menu-list.js')(freshideasLocation);
require('./location-inventory/location-inventory.js')(freshideasLocation);
require('./location-invoice/location-invoice.js')(freshideasLocation);
require('./location-side-content/location-add-item/location-add-item.js')(freshideasLocation);
require('./location-side-content/location-edit-item/location-edit-item.js')(freshideasLocation);
require('./location-side-content/location-add-category/location-add-category.js')(freshideasLocation);
require('./location-side-content/location-edit-category/location-edit-category.js')(freshideasLocation);
require('./location-side-content/location-add-modifier/location-add-modifier.js')(freshideasLocation);
require('./location-side-content/location-edit-modifier/location-edit-modifier.js')(freshideasLocation);
require('./location-side-content/location-assign-category/location-assign-category.js')(freshideasLocation);
require('./common/form-elements/form-elements.js')(freshideasLocation);
require('./common/topnav/topnav.js')(freshideasLocation);
require('./common/sidenav/sidenav.js')(freshideasLocation);
require('./common/topnav-side-content/topnav-side-content.js')(freshideasLocation);
require('./common/breadcrumb/breadcrumb.js')(freshideasLocation);

export default freshideasLocation;
