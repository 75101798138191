'use strict';

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbManualCheckinCtrl', [
        '$scope',
        'SmbPosService',
        'selectPatron',
        'isGatewayFiitEnabled',
        '$timeout',
        'isExistingOrder',
        '$modal',
        'showError',
        'isQuickChargeEnabled',
        'Security',
        'USER_ROLE_TYPE',
        'Platform',
        'EnvConfig',
        'autoCloseModalAfterScan',
        'BridgedPromise',
        'dismissButtonText',
        '$rootScope',
        function (
            $scope,
            SmbPosService,
            selectPatron,
            isGatewayFiitEnabled,
            $timeout,
            isExistingOrder,
            $modal,
            showError,
            isQuickChargeEnabled,
            Security,
            USER_ROLE_TYPE,
            Platform,
            EnvConfig,
            autoCloseModalAfterScan,
            BridgedPromise,
            dismissButtonText,
            $rootScope) {

            $scope.hasIosScanner = false;

            if (Platform.isIosWebkit()) {
                BridgedPromise.dispatch('hasQRScanner', {}).then(({hasQRScanner}) => {
                    $scope.hasIosScanner = !!hasQRScanner;
                }).catch((error) => {
                    console.error('IOS container does not support qr scanner. Disabling feature');
                });
            }

            $scope.initIosScanner = () => {
                if ($scope.hasIosScanner) {
                    window.webkit.messageHandlers.bridge.postMessage({'type': 'initQRScanner'});
                }
            };

            if (navigator.platform.indexOf('iPad') === -1) {
                $timeout(() => {
                    $('#patronSearchTextBox').focus();
                }, 500);
            }

            const NOTIFICATION_DURATION = 1500; // ms

            $rootScope.$on('checkIn::notification', (event, showSuccessNotification) => showNotification(showSuccessNotification));

            // will show error notification if showSuccessNotification is false
            const showNotification = (showSuccessNotification) => {
                if (showSuccessNotification) {
                    $scope.showPatronAddedPopup = true;
                    $scope.showPatronNotFoundPopup = false;
                } else {
                    $scope.showPatronNotFoundPopup = true;
                    $scope.showPatronAddedPopup = false;
                }

                $timeout(() => {
                    $scope.showPatronAddedPopup = false;
                    $scope.showPatronNotFoundPopup = false;
                }, NOTIFICATION_DURATION);
            };

            // $timeout used for loading because barcode-qr-detector functions aren't automatically watched.
            const patronLoaded = () => {
                $timeout(() => {
                    $scope.loadingPatron = false;
                }, 100);
            };

            const SEARCH_DEBOUNCE = EnvConfig.searchDebounce || 500; // ms
            $scope.patrons = SmbPosService.patrons;
            $scope.dismissButtonText = dismissButtonText || null;

            // This will return in the format 2021-02-26
            const getCurrentDate = () => {
                const date = new Date();
                const currentYear = date.getFullYear();
                const currentMonth = ('0' + (date.getMonth() + 1)).slice(-2);
                const currentDay = ('0' + date.getDate()).slice(-2);
                return (`${currentYear}-${currentMonth}-${currentDay}`);
            };

            $scope.currentDate = getCurrentDate();

            const currentUser = Security.getUser() || {};
            $scope.isUserManager = currentUser.roleType === USER_ROLE_TYPE.MANAGER;
            $scope.isUserSiteAdmin = currentUser.permission === 'SITEADMIN';
            $scope.isUserFullAdmin = currentUser.permission === 'FULLADMIN';

            $scope.selectPatron = function (patron) {
                if (!isExistingOrder) {
                    selectPatron(patron);
                } else {
                    $scope.$close(patron);
                }
            };

            $scope.$on('pos::close-patron-search', function (event, args) {
                $scope.$close();
            });

            $scope.loadingPatron = false;
            $scope.error = '';
            if (showError) {
                $scope.error = 'smb.pos.manualCheckinError.msg';
            }
            $scope.isGatewayFiitEnabled = isGatewayFiitEnabled;
            var location = SmbPosService.shift.location;

            var displayError = function (err) {
                $scope.error = err || 'smb.pos.manualCheckinError.msg';
                patronLoaded();
            };

            /**
            *** Commented By Akash Mehta on Mar 27th 2020
            *** This method is desgined to fetch a Fiit account. This is to allow
            *** scanning any phsyical card directly on the home screen before a transaction even begins
            *** This function will attempt to fetch FIIT meal cards if Gateway Fiit is enabled
            *** and returns a transformed patron.
            *** NOTE : `fiitMpsAccount` field is added to store the meal Plan account details from FIIT.
            *** This field is different from `fiitPatron` which is bascially the loyalty profile of the
            *** patron on Nown.

            *** Commented By Chris Lo on Nov 11th, 2020
            *** we've added a param, autoSelect, as the patron search modal can now be used to fetch from fiit when
            *** nown-fiit integration is enabled.
            *** in this case, we need to display the patron in the modal and not automatically select it.
            **/
            var fetchFiitMpsAccount = async function (cardNumber, autoSelect, err) {
                if (cardNumber) {
                    SmbPosService.fetchPatronFromFiitBackend(undefined, cardNumber, {}, {})
                    .then((result) => {
                        if (autoSelect) {
                            selectPatron(result, isQuickChargeEnabled);
                            patronLoaded();
                            if (autoCloseModalAfterScan) {
                                $scope.$close();
                            }
                        } else {
                            $scope.filteredPatrons.push(result);
                            // if patron list was empty, set it to false, now that it contains fiit patrons.
                            if ($scope.isPatronListEmpty) {
                                $scope.isPatronListEmpty = false;
                            }
                            patronLoaded();
                        }
                    })
                    .catch((err) => {
                        // NOOP, nothing needs to be done if a patron is not found
                    })
                    // finally will ensure accurate loading state
                    .finally(() => {
                        patronLoaded();
                    });
                } else {
                    displayError(err);
                }
            };

            $scope.lookupLucovaPatrons = function (qrcode = '') {
                if ($scope.loadingPatron) {
                    return;
                }

                $scope.loadingPatron = true;
                $scope.$apply();

                SmbPosService.fetchLucovaPatron(qrcode).then((patron) => {
                    if (patron) {
                        $scope.error = '';
                        $scope.selectPatron(patron[0]);
                    }

                    patronLoaded();
                }).catch((err) => {
                    if (isGatewayFiitEnabled) {
                        fetchFiitMpsAccount(qrcode, true, err);
                    } else {
                        displayError(err);
                    }
                });
            };

            var patronTypeAll = {
                name: 'smb.pos.patron.type.all',
                placeholder: 'All Customers',
                value: 'all',
            };

            $scope.constants = {
                patronTypes: [{
                    name: 'smb.pos.patron.type.checkedIn',
                    placeholder: 'Start Order',
                    value: 'checkedIn',
                    show: true
                }, {
                    name: 'smb.pos.patron.type.mobileOrderHistory',
                    secondary: 0,
                    placeholder: 'Pre-order',
                    value: 'preorder',
                    show: $scope.mobileOrdersEnabled
                }, {
                    name: 'smb.pos.patron.type.suspended',
                    secondary: 0,
                    placeholder: 'Saved',
                    value: 'suspended',
                    show: $scope.suspendEnabled
                },
                patronTypeAll]
            };

            $scope.currentPatronType = {};
            var previousPatronType = {};
            $scope.patronSearch = {
                searchString: ''
            };
            $scope.filteredPatrons = [];

            $scope.clearSearchPatrons = function () {
                $scope.patronSearch.searchString = '';
                $scope.searchPatrons();
            };

            $scope.searchPatrons = _.debounce(() => {
                if (!$scope.patronSearch.searchString) {
                    $scope.filteredPatrons.length = 0;
                    $scope.setPatronType(previousPatronType);
                } else {
                    const qrPattern = /(^l:[1,2]:.*)/;
                    const isQrCode = qrPattern.test($scope.patronSearch.searchString);

                    if (isQrCode) {
                        $scope.lookupLucovaPatrons($scope.patronSearch.searchString);
                        $scope.clearSearchPatrons();
                        return;
                    }

                    $scope.filterAllPatrons();
                }
            }, SEARCH_DEBOUNCE);

            $scope.patronSort = {
                field: 'lucovaUser.rssi',
                sortASC: false
            };

            $scope.setPatronType = function (patronType) {
                // Show suspended
                if (patronType.value === 'suspended') {
                    SmbPosService.loadSuspend();
                    $scope.currentPatronType = patronType;
                    $scope.showSuspended = true;
                    return;
                }

                // Disable showSuspended
                $scope.showSuspended = false;
                $scope.isPatronListLoading = true;
                $scope.currentPatronType = patronType;

                SmbPosService.clearPreorder();

                if (patronType.value === 'checkedIn') {
                    $scope.patronSort.field = 'lucovaUser.rssi';
                    $scope.patronSort.sortASC = true;

                    previousPatronType = $scope.currentPatronType;
                    $scope.filterPatrons(patronType);
                } else {
                    $scope.patronSort.field = 'lucovaUser.preorder[0].transaction_opened';
                    $scope.patronSort.sortASC = true;

                    previousPatronType = $scope.currentPatronType;
                    $scope.filterPatrons(patronType);
                }
            };

            $scope.getPatronPhoto = function (photoUrl, isCompleteUrl) {
                return SmbPosService.getPatronPhoto(photoUrl, isCompleteUrl);
            };

            $scope.filterAllPatrons = function () {
                $scope.isPatronListLoading = true;
                $scope.currentPatronType = patronTypeAll;

                $scope.filteredPatrons.length = 0;

                SmbPosService.fetchAllPatrons($scope.patronSearch.searchString).then(function () {
                    $scope.patronSort.field = 'fullName';
                    $scope.patronSort.sortASC = false;

                    setFilteredPatrons($scope.patrons.all);
                });

                // if nown-fiit integration enabled,
                // perform a search to fetch fiit patrons
                if ($scope.isGatewayFiitEnabled) {
                    fetchFiitMpsAccount($scope.patronSearch.searchString, false);
                }
            };

            var setFilteredPatrons = function (filteredPatrons = []) {

                let _patrons = applyFilters(filteredPatrons, $scope.currentPatronType.value);
                $scope.filteredPatrons.push(..._patrons);

                $scope.isPatronListEmpty = !filteredPatrons || filteredPatrons.length === 0;
                $scope.isPatronListLoading = false;
            };

            $scope.filterPatrons = function (patronType) {
                var patrons = $scope.patrons || {};
                patronType = patronType || $scope.currentPatronType;

                $scope.filteredPatrons.length = 0;
                var filteredPatrons = patrons[patronType.value];
                setFilteredPatrons(filteredPatrons);
            };

            $scope.isNameEmpty = function (patron) {
                let name;

                if (patron.fiitPatron) {
                    name = patron.fiitPatron.firstName + ' ' + patron.fiitPatron.lastName;
                }

                return /\S/.test(name);
            };

            // different behaviors based on currentPatronType
            function applyFilters (_patrons, type) {
                _patrons = _patrons ? _patrons : [];
                let patrons = _.filter(_patrons, function (patron) {
                    if (!patron.lucovaUser
                        || !patron.lucovaUser.preorder
                        || !patron.lucovaUser.preorder.length) {
                        return patron;
                    }

                    var preOrderStatus = patron.lucovaUser.preorder[0].status;

                    if (type === 'checkedIn') {
                        // only show patron if nearby & doesn't have an order in progress
                        if (patron.isNearby && !patron.lucovaUser.preorder[0].fulfilled
                            && preOrderStatus != 'completed'
                            && preOrderStatus != 'cancelled') {
                            return patron;
                        }
                    } else if (type === 'preorder') {
                        // only show fulfilled-completed or cancelled orders in the 'mobile order history' tab
                        if (patron.lucovaUser.preorder[0].fulfilled || preOrderStatus === 'cancelled') {
                            return patron;
                        }
                    } else {
                        // if no preorders e.g. bluetooth check-in
                        return patron;
                    }
                });
                return patrons;
            }

            $scope.resetError = function () {
                $scope.error = '';
            };

            $scope.checkIfEnterKeyPressed = function (e) {
                // This assumes QR code is always 106 characters long.
                if (e.keyCode === 13) {
                    $('#patronSearchTextBox').blur();
                    var searchString = $scope.patronSearch.searchString;
                    if (searchString.length >= 106) {
                        $scope.patronSearch.searchString = '';
                        var qrCodeInput = searchString.substring(searchString.length - 106, searchString.length);
                        $scope.lookupLucovaPatrons(qrCodeInput);
                    }
                }
            };

            $scope.openCustomerLoyalty = function (patron) {
                var modalInstance = $modal.open({
                    templateUrl: 'pos/smb/templates/pos.loyalty.tpl.html',
                    animation: false,
                    backdrop: true,
                    controller: 'SmbPosLoyaltyCtrl',
                    windowClass: 'smb-pos smb-pos__loyalty-modal',
                    resolve: {
                        patron: function () {
                            return patron;
                        },
                        location: function () {
                            return location;
                        },
                        goHome: function () {
                            return function () {};
                        },
                        adjustments: function () {
                            return {};
                        }
                    }
                });

                modalInstance.result.then(function (response) {
                    $scope.$close();
                    selectPatron(patron, response);
                });
            };

            $scope.$on('$destroy', function () {
                $scope.searchPatrons.cancel();
            });
        }
    ]);
};
