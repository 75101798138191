'use strict';


module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbUpgradeCtrl', [
        '$scope',
        'Company',
        function ($scope, Company) {

            $scope.upgradeClicked = false;

            $scope.requestUpgrade = function () {
                Company.upgrade({}).$promise.then(function (response) {
                    $scope.upgradeClicked = true;
                    window.open('https://www.nownpos.com/nown-signup-2/');
                }).catch(function (err) {
                    console.log(err);
                });
            };
        }
    ]);
};
