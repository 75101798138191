'use strict';


module.exports = function (freshideasPosInventoryV2) {
    freshideasPosInventoryV2.controller('InventoryV2Ctrl', [
        '$scope',
        '$state',
        '$interval',
        'InventoryV2Service',
        'CurrentSession',
        function ($scope, $state, $interval, InventoryV2Service, CurrentSession) {
            $scope.company = CurrentSession.getCompany();

            $scope.currentView = 'incoming';

            $scope.switchView = function (view) {
                $scope.currentView = view;
            };

            $scope.switchToOds = function () {
                $state.go('freshideas.kds');
            };

            var timestampInterval;

            var initializeTimestampInterval = function () {
                timestampInterval = $interval(function () {
                    // Update current timestamp
                    $scope.currentTimestamp = Date.now();
                }, 1000);
            };

            $scope.init = function () {
                $scope.currentTimestamp = Date.now();
                initializeTimestampInterval();
            };

            $scope.$on('$destroy', function (event) {
                // Cancel active scope intervals
                $interval.cancel(timestampInterval);
            });

            $scope.optionItems = [
                {label: 'View Orders', callbackFunc: $scope.switchToOds}
            ];


            $scope.executeOption = function (choice) {
                if (choice.callbackFunc) {
                    choice.callbackFunc();
                }
            };


            $scope.init();
        }
    ]);
};
