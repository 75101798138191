'use strict';

module.exports = function (freshideasDirectivesCommon) {

    freshideasDirectivesCommon.directive('simpleBarChart', [function () {
        return {
            scope: {
                values: '=',
                field: '=',
                valueFormatter: '&',
                valueSuffix: '=',
                labelFunction: '&'
            },
            templateUrl: 'common/directives/simple-bar-chart.tpl.html',
            controller: [
                '$scope',
                function ($scope) {
                }
            ],
            restrict: 'E',
            link: function (scope, element, attrs) {
                var chartElem = element.find('.chart-area');
                var redrawChart = function (newValues) {
                    var values = scope.values;
                    var field = scope.field;
                    if (values && field) {
                        // var chartWidth = chartElem.width() || 300;
                        // var chartHeight = chartElem.height() || 300;
                        // var barMargin = 0;
                        // var numOfBars = values.length;
                        var barHeight = function (d, i) {
                            d = d || {};
                            var allPercentized = d.percentized || {};
                            var percentized = allPercentized[field] || 0;
                            return percentized;
                        };
                        var barY = function (d, i) {
                            var height = barHeight(d, i);
                            return 100 - height;
                        };
                        var barGraphics = chartElem.find('.bars .bar-graphic-inner');
                        barGraphics.each(function (index) {
                            $(this).height(barHeight(values[index], index) + '%').css('top', barY(values[index], index) + '%');
                        });
                    }
                };
                scope.$watch('values', redrawChart);
                scope.$watch('field', redrawChart);
            }
        };
    }]);

};

