'use strict';

module.exports = function (freshideasReports) {

    freshideasReports.controller('DiscountsReportsSubTable', [
        '$scope',
        '$location',
        'Reports',
        'Utils',
        'Lookup',
        'Export',
        'Security',
        'Platform',
        'DateRangeService',
        function ($scope, $location, Reports, Utils, Lookup, Export, Security, Platform, DateRangeService) {
            $scope.init = function (discount, isExport = false) {
                $scope.selectedDiscount = discount;
                $scope.transactionResultSearch = {};
                $scope.transactionResultSearch.sortBy = '';
                $scope.transactionResultSearch.ascending = false;
                $scope.isExport = isExport;
            };

            $scope.setSortTransactionsByField = function (sortBy) {
                $scope.transactionResultSearch.sortBy = sortBy;
                $scope.transactionResultSearch.ascending = !$scope.transactionResultSearch.ascending;

                var greater = $scope.transactionResultSearch.ascending ? 1 : -1;
                var less = $scope.transactionResultSearch.ascending ? -1 : 1;
                $scope.selectedDiscount.transactions.sort((a, b) => {
                    if (a[sortBy] > b[sortBy]) {
                        return greater;
                    }
                    if (a[sortBy] < b[sortBy]) {
                        return less;
                    }
                    return 0;
                });
            };

            $scope.opened = {};

            function resetTransactionsPagingOptions () {
                $scope.transactionsPagingOptions = {
                    startRecord: 0,
                    pageSize: 10,
                    currentPage: 1,
                };
            }
            resetTransactionsPagingOptions();

            $scope.transactionsPageUp = function () {
                var start = $scope.transactionsPagingOptions.startRecord;
                var newStart = start + $scope.transactionsPagingOptions.pageSize;
                if ($scope.selectedDiscount.transactions.length > newStart) {
                    $scope.transactionsPagingOptions.startRecord = newStart;
                    $scope.transactionsPagingOptions.currentPage++;
                }
            };

            $scope.transactionsPageDown = function () {
                var start = $scope.transactionsPagingOptions.startRecord;
                var newIndex = start - $scope.transactionsPagingOptions.pageSize;
                if (start > 0) {
                    if (newIndex < 0) {
                        $scope.transactionsPagingOptions.startRecord = 0;
                    } else {
                        $scope.transactionsPagingOptions.startRecord = newIndex;
                    }
                    $scope.transactionsPagingOptions.currentPage--;
                }
            };

            $scope.transactionsPagesAvailable = function () {
                var itemLen = $scope.selectedDiscount.transactions.length;
                var pageSize = $scope.transactionsPagingOptions.pageSize;
                return (itemLen > pageSize) ? Math.ceil(itemLen/pageSize) : 1;
            };

            $scope.init();
        }
    ]);

};
