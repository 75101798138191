'use strict';


const Decimal = require('decimal.js').default;
const SharedDataService = require('../../external/pos.data-service.js');

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosTenderIncompleteCtrl', [
        '$scope',
        'tenderData',
        'posObj',
        function (
            $scope,
            tenderData,
            posObj) {

            $scope.posObj = posObj;
            $scope.tenderData = tenderData;

            $scope.cashTenders = [];
            var getCashTenders = function () {
                $scope.cashTenders = _.filter($scope.tenderData.allTenders, function (tender) {
                    return tender.type === 'cash';
                });

                var totalCashAmount = new Decimal(0);
                _.each($scope.cashTenders, function (tender) {
                    var cashAmount = new Decimal(tender.amount);
                    totalCashAmount = totalCashAmount.plus(cashAmount);
                });

                $scope.totalCashAmount = totalCashAmount.toNearest(SharedDataService.baseDollar).toNumber();
            };

            $scope.init = function () {
                getCashTenders();
            };

            $scope.init();
        }
    ]);
};
