'use strict';


module.exports = function (freshideasSmbPos) {

    // NOTE: The purpose of this controller is to reuse the existing `PosCtrl`
    // so that many calculations and logics do not have to be duplicated or
    // re-written.
    freshideasSmbPos.controller('SmbPosOrderMirrorCtrl', [
        '$scope',
        '$controller',
        'SmbPosService',
        function ($scope, $controller, SmbPosService) {
            var mockStateParams = {
                shiftLocationId: SmbPosService.shift.locationId,
                studentId: $scope.currentOrder.patron,
                adjustments: $scope.adjustments
            };

            $controller('PosCtrl', {
                $stateParams: mockStateParams,
                $scope: $scope
            });
        }
    ]);
};
