'use strict';


export function ipScanner (freshideas) {
    freshideas.factory('IpScanner', [
        'Platform',
        function (
            Platform) {

            var ipcRenderer;
            if (Platform.isElectron()) {
                ipcRenderer = nodeRequire('electron').ipcRenderer;
            }

            var scan = function (port = 5555) {
                return new Promise(function (resolve, reject) {
                    if (!Platform.isElectron() || !ipcRenderer) {
                        reject();
                    }
                    ipcRenderer.once('scan-for-ports', (event, ipList) => {
                        resolve({cardTerminals: JSON.stringify(ipList || [])});
                    });
                    ipcRenderer.send('scan-for-ports', port);
                });
            };

            return {
                scan: scan
            };
        }]);
}
