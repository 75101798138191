'use strict';

const angular = require('angular');

export default angular
    .module('freshideas.services.receipt-builder', [])
    .factory('ReceiptBuilderService', [
        'CardTerminal',
        function (CardTerminal) {

        var _this = {};

        _this.buildCardReceipt = function (cardResponses=[]) {
            var cardReceiptArr = [];

            cardResponses.forEach(function (cardResponse) {
                cardReceiptArr.push(
                    CardTerminal.parseResponse(
                        cardResponse.cardTerminalResponse,
                        cardResponse.processorType));
            });

            return cardReceiptArr;
        };

        return _this;
    }]);

