'use strict';

const angular = require('angular');
const ngResource = require('angular-resource');
const ngCookies = require('angular-cookies');

export default angular.module('lucova.resources', [ngResource, ngCookies])
    .factory('Lucova',
        ['$resource',
        '$http',
        '$cookies',
        '$log',
        '$timeout',
        'EnvConfig',
        'Base64',
        function (
            $resource,
            $http,
            $cookies,
            $log,
            $timeout,
            EnvConfig,
            Base64) {

        var initialized = false;
        var basicAuth = null;
        var terminalId = null;
        var loginResponse = null;
        var listeners = [];

        var Lucova = {
            reset: function () {
                initialized = false;
                basicAuth = null;
                terminalId = null;
                loginResponse = null;
                listeners.length = 0;
            },
            saveLogin: function (pBasicAuth, pTerminalId, pLoginResponse) {
                basicAuth = pBasicAuth;
                terminalId = pTerminalId;
                loginResponse = pLoginResponse;
            },
            login: function (terminalId, locationId) {
                var login = $resource(EnvConfig.lucovaHost + '/', {}, {
                    'login': {
                        method: 'POST',
                        headers: {
                            'LUCOVA-TERMINAL-TYPE': 'blastoise',
                            'LUCOVA-TERMINAL-VERSION': '4',
                            'LUCOVA-TERMINAL-BUILD': '100',
                            'LUCOVA-TERMINAL-ID': terminalId
                        },
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/login'
                    },
                });

                return login.login(
                    {},
                    {
                        credential_type: 'fiit',
                        location_id: locationId,
                        terminal_id: terminalId,
                        fiit_host: EnvConfig.nown_host,
                        has_scv: true,
                        // NOTE: To get this working on local host, change the JSESSIONID to
                        // the cookie obtained when logging in @ https://lucova-staging.nownpos.com/#/smb-pos
                        // and fiit_host to 'lucova-dev.nownpos.com'
                        pos_headers: {
                            JSESSIONID: $cookies.get('JSESSIONID'),
                            raw_cookie: document.cookie
                        }
                    }, // parameters
                    function (response) {
                        initialized = true;

                        if (response.success) {
                            var merchantUser = response.user_name;
                            var merchantToken = response.auth_token;
                            var basicAuth = 'Basic ' + Base64.encode(merchantUser + ':' + merchantToken);
                            Lucova.saveLogin(basicAuth, terminalId, response);
                        }

                        // This ensures the promise gets resolved first before the listeners
                        // are executed
                        $timeout(function () {
                            for (let listener of listeners) {
                                listener(response);
                            }
                        }, 0);
                    },
                    function (error) {
                        initialized = true;

                        $log.error(error);

                        // This ensures the promise gets resolved first before the listeners
                        // are executed
                        $timeout(function () {
                            for (let listener of listeners) {
                                listener({success: false});
                            }
                        }, 0);
                    }
                ).$promise;
            },
            getTerminalId: function () {
                return terminalId;
            },
            getLoginResponse: function () {
                return loginResponse;
            },
            isInitialized: function () {
                return initialized;
            },
            isConnected: function () {
                return (basicAuth && terminalId);
            },
            addListener: function (listener) {
                if (listener instanceof Function) {
                    listeners.push(listener);
                }
            },
            removeListener: function (listener) {
                var index = listeners.indexOf(listener);
                if (index > -1) {
                    listeners.splice(index, 1);
                }
            },
            clearListeners: function () {
                listeners.length = 0;
            },
            manager: function (encrypted) {
                if (!this.isConnected()) {
                    throw new Error('Lucova.manager not connected');
                }

                var headers = {
                    'LUCOVA-TERMINAL-TYPE': 'blastoise',
                    'LUCOVA-TERMINAL-VERSION': '4',
                    'LUCOVA-TERMINAL-BUILD': '100',
                    'LUCOVA-TERMINAL-ID': terminalId,
                    'Authorization': basicAuth
                };

                if (encrypted) {
                    headers['LUCOVA-ENCRYPTED'] = true;
                }

                return $resource(EnvConfig.lucovaHost + '/', {}, {
                    'getPreorderStats': {
                        method: 'GET',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/preorder-stats'
                    },
                    'getLocationPreorderHistory': {
                        method: 'GET',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/preorder-history'
                    },
                    'getUsers': {
                        method: 'GET',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/nearby'
                    },
                    'getScheduledPreorders': {
                        method: 'GET',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/pending-preorders'
                    },
                    'getAllUsers': {
                        method: 'GET',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/fiit-patrons'
                    },
                    'getUserInfo': {
                        method: 'GET',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/user'
                    },
                    'getUserTransactions': {
                        method: 'GET',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/list-transactions'
                    },
                    'beginDirect': {
                        method: 'POST',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/transaction'
                    },
                    'cancelDirect': {
                        method: 'POST',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/confirm-direct'
                    },
                    'cancelPOSTransactions': {
                        method: 'GET',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/cancel-pending-pos'
                    },
                    'sendMerchantEvent': {
                        method: 'POST',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/event'
                    },
                    'getMobileOrders': {
                        method: 'GET',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/preorder'
                    },
                    'completeMobileOrder': {
                        method: 'PUT',
                        headers: headers,
                        params: {
                            status: 'completed'
                        },
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/preorder'
                    },
                    'cancelMobileOrder': {
                        method: 'PUT',
                        headers: headers,
                        params: {
                            status: 'cancelled'
                        },
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/preorder'
                    },
                    'acknowledgeMobileOrder': {
                        method: 'PUT',
                        headers: headers,
                        params: {
                            status: 'acknowledged'
                        },
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/preorder'
                    },
                    'fulfillMobileOrder': {
                        method: 'PUT',
                        headers: headers,
                        params: {
                            fulfilled: true
                        },
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/preorder'
                    },
                    'completeDeliveryOrder': {
                        method: 'PUT',
                        headers: headers,
                        params: {
                            status: 'out_for_delivery'
                        },
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/preorder'
                    },
                    'failDeliveryOrder': {
                        method: 'PUT',
                        headers: headers,
                        params: {
                            status: 'failed_to_fulfill'
                        },
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/preorder'
                    },
                    'updateMobileOrderNotes': {
                        method: 'PUT',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/preorder'
                    },
                    'userProximityNotify': {
                        method: 'POST',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/user-proximity-notify'
                    },
                    'postUserTransaction': {
                        method: 'POST',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/post-user-transaction',
                    },
                    'refundUserTransaction': {
                        method: 'POST',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/refund-user-transaction',
                    },
                    'pushPoll': {
                        method: 'GET',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/push-poll'
                    },
                    'confirmMessageReceived': {
                        method: 'POST',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/websocket-message-received'
                    },
                    'bridgeProperties': {
                        method: 'POST',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/bridge-properties'
                    },
                    'pushMessage': {
                        method: 'POST',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/push-message'
                    },
                    // chat endpoints
                    'sendMessage': {
                        method: 'POST',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/send-message'
                    },
                    'confirmMessage': {
                        method: 'POST',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/confirm-message'
                    },
                    'getUnconfirmedMessages': {
                        method: 'GET',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/unconfirmed-messages'
                    },
                    'getActiveChat': {
                        method: 'GET',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/active-chat'
                    },
                    'getOpenChats': {
                        method: 'GET',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/open-chats'
                    },
                    // Gets 10 latest chats, starting from index
                    'getLastPreorderChatsByIndex': {
                        method: 'GET',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/preorder-chat-transactions'
                    },
                    // Gets chat for specific preorder_id
                    'getPreorderChatById': {
                        method: 'GET',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/preorder-chat'
                    },
                    // Sends a message to a specific preorder chat
                    'sendPreorderChat': {
                        method: 'POST',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/preorder-chat'
                    },
                    // updates latest read flag, when POS user views the latest message
                    'updateChatAsRead': {
                        method: 'PUT',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/preorder-chat-update-read'
                    },
                    'getNode': {
                        method: 'GET',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/node'
                    },
                    'updateNode': {
                        method: 'PUT',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/node'
                    },
                    'updateCustomerProfile': {
                        method: 'PUT',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/user-profile'
                    },
                    // obtains information about a gift card from payments server
                    'getGiftCardById': {
                        method: 'GET',
                        headers: headers,
                        cache: false,
                        url: EnvConfig.lucovaHost + '/merchant/e-gift-card'
                    },
                });
            }
        };
        return Lucova;
    }]);

