'use strict';

const angular = require('angular');
const moment = require('moment');

/* globals ngGridFlexibleHeightPlugin */

module.exports = function (freshideasReports) {
    freshideasReports.controller('DepositRefundReportCtrl', [
        '$scope',
        '$state',
        '$rootScope',
        'Reports',
        'Utils',
        '$modal',
        'NgGridSortService',
        'NgGridTemplateService',
        'Export',
        function ($scope, $state, $rootScope, Reports, Utils, $modal, NgGridSortService, NgGridTemplateService, Export) {

            $scope.init = function () {
                $scope.loadDepositRefund();
            };

            $scope.setSortByField = function (sortBy) {
                return;

                // Disable sorting for now. Need to map frontend field name to database field name, and UI is rather jumpy
                //   When switching between different sort field and order
                /*
                $scope.depositRefundSearch.sortBy = sortBy;
                $scope.depositRefundSearch.ascending = !$scope.depositRefundSearch.ascending;

                $scope.loadDepositRefund();
                */
            };

            $scope.resetFilters = function () {
                $scope.depositRefundSearch.startDateTime = moment().startOf('week').toDate();
                $scope.depositRefundSearch.endDateTime = moment().endOf('day').toDate();
                $scope.depositRefundSearch.isAdvancedSearch = true;
                $scope.depositRefundSearch.sortBy = 'eventTime';
                $scope.depositRefundSearch.ascending = false;
                $scope.depositRefundSearch.offSet = 0;
                $scope.depositRefundSearch.limit = 0;
                $scope.depositRefundSearch.patronKey = '';

                $scope.loadDepositRefund();
            };

            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };
            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };

            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };

            $scope.depositRefundSearch = {
                isAdvancedSearch: true,
                startDateTime: moment().startOf('week').toDate(),
                endDateTime: moment().endOf('day').toDate(),
                sortBy: 'eventTime',
                ascending: false,
                offSet: 0,
                limit: 50,
                patronKey: ''
            };

            $scope.pagingOptions = {
                currentPage: 1,
                totalItems: 0,
                pageSize: 50,
                startRecord: 1,
                endRecord: 50
            };
            $scope.changePage = function () {
                $scope.depositRefundSearch.offSet = Utils.serverSidePagingRowNumber($scope.pagingOptions.currentPage, $scope.pagingOptions.pageSize);
                $scope.depositRefundSearch.limit = $scope.pagingOptions.pageSize;

                $scope.loadDepositRefund();
            };
            $scope.loadDepositRefund = function () {
                $scope.depositRefundSearch.startDateTime = moment($scope.depositRefundSearch.startDateTime).startOf('day').valueOf();
                $scope.depositRefundSearch.endDateTime = moment($scope.depositRefundSearch.endDateTime).endOf('day').valueOf();

                Reports.getDepositRefundReport($scope.depositRefundSearch, function (response) {
                    var data = response.entries;

                    if (data.length > 0) {
                        $scope.depositRefundData = data;
                        $scope.pagingOptions.totalItems = response.totalCount; // Utils.serverSideTotalItems(response.length, $scope.pagingOptions.currentPage, $scope.pagingOptions.pageSize);
                        $scope.pagingOptions.startRecord = response.offset + 1; // Utils.serverSideStartRecord($scope.pagingOptions.currentPage, $scope.pagingOptions.pageSize);
                        $scope.pagingOptions.endRecord = Math.min(response.totalCount, response.offset + response.limit); // Utils.serverSideEndRecord(response.length, $scope.pagingOptions.currentPage, $scope.pagingOptions.pageSize);

                        $scope.currentReportSearch = angular.copy($scope.depositRefundSearch);
                    } else {
                        $scope.depositRefundData = [];
                    }
                });
            };

             $scope.showError = function (title, subtitle, message, buttonTitle) {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalError.tpl.html',
                    controller: 'ErrorCtrl',
                    windowClass: 'modal-50',
                    resolve: {
                        title: function () {
                            return title;
                        },
                        subtitle: function () {
                            return subtitle;
                        },
                        message: function () {
                            return message;
                        },
                        buttonTitle: function () {
                            return buttonTitle;
                        }
                    },
                    backdrop: 'static'
                }, function (error) {
                });
                modalInstance.result.then(function (tender) {
                    $state.go('pos');
                });
            };

            $scope.gridOptions = {

                data: 'depositRefundData',
                plugins: [new ngGridFlexibleHeightPlugin({minHeight: 520})],
                enableColumnResize: true,
                enablePaging: true,
                showFooter: false,
                multiSelect: false,
                showSelectionCheckbox: false,
                enableHighlighting: false,
                columnDefs: [
                    {
                        field: 'id',
                        displayName: 'ID',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'patronName',
                        displayName: 'Patron Name',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'patronKey',
                        displayName: 'Patron Card №/Patron Key',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'mealPlanName',
                        displayName: 'Meal Plan Name',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'userName',
                        displayName: 'User Name',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'eventTime',
                        displayName: 'Event Time',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{COL_FIELD | date:\'yyyy-MM-dd HH:mm:ss\'}}</span><div>'
                    },
                    {
                        field: 'eventType',
                        displayName: 'Event Type',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'paymentType',
                        displayName: 'Payment Type',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'planType',
                        displayName: 'Plan Type',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'icbBalanceChange',
                        displayName: 'ICB Balance Change',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'mealBalanceChange',
                        displayName: 'Meal Balance Change',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'dcbBalanceChange',
                        displayName: 'DCB Balance Change',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'comment',
                        displayName: 'Comment',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    }
                ]
            };
            $scope.exportToPdf = function (tableId) {
                Export.tableToPdf(tableId, 'landscape');
            };

            $scope.init();
        }
    ]);
};
