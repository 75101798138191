'use strict';

const convert = require('xml-js');
const moment = require('moment');

export function cardTerminalHeartland (freshideas) {

    freshideas.factory('CardTerminalHeartland', [
        '$log',
        '$timeout',
        'TerminalEvent',
        function (
            $log,
            $timeout,
            TerminalEvent) {

            var config;
            var client;

            var toTransactionDateTime = function (datetime) {
                if (datetime) {
                    var year, month, day, hour, minute, second;

                    [month, day, year, hour, minute, second] = datetime.match(/.{1,2}/g);

                    return '20'+year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second;
                } else {
                    // for failed transactions, it is possible the terminal does not
                    // return any datetime to us. In this case we will make it ourselves.
                    return moment().format('YYYY-MM-DD HH:mm:ss');
                }
            };

            var init = async function (terminalConfig) {
                if (typeof window !== 'undefined') {
                    // running from browser
                    if (!window.process || !window.process.type) {
                        throw new Error('Running in non-electron environment. Terminal will be disabled');
                    }
                }

                config = terminalConfig;
            };

            var initializeSocket = function () {
                if (client == null) {
                    var net = nodeRequire('net');
                    client = new net.Socket();

                    client.on('end', () => {
                        console.log('ENDed');
                        client = null;
                    });

                    client.on('close', () => {
                        console.log('CLOSEd');
                        client = null;
                    });

                    return new Promise(function (resolve, reject) {
                        client.connect({timeout: 10000, port: config.port, host: config.ip}, function () {
                            resolve();
                        });

                        client.on('error', function () {
                            console.log('ERROR CLOSEd');
                            client = null;
                            reject();
                        });
                    });
                } else {
                    return Promise.resolve();
                }
            };

            var longToByteArray = function (long) {
                // we want to represent the input as a 8-bytes array
                var byteArray = [0, 0, 0, 0];

                for ( var index = 0; index < byteArray.length; index ++ ) {
                    var byte = long & 0xff;
                    byteArray [index] = byte;
                    long = (long - byte) / 256;
                }

                return byteArray;
            };

            var packXML = function (xml) {
                var myBuffer = [];
                var buffer = new Buffer(xml, 'latin1');
                for (var i = 0; i < buffer.length; i++) {
                    myBuffer.push(buffer[i]);
                }

                var length = myBuffer.length;

                var arr = longToByteArray(length);
                myBuffer.unshift(arr[0]);
                myBuffer.unshift(arr[1]);

                // return new Buffer(myBuffer);
                return myBuffer;
            };

            var generic = function (xml, allBytesReceived, reject) {
                    console.log('==========================================');
                    console.log(xml);
                    var message = packXML(xml);

                    var messageSize = 0;
                    var numBytesReceived = 0;
                    var bytesReceived = null;
                    client.removeAllListeners('data');

                    var f = function (data) {
                        try {
                            if (!messageSize) {
                                messageSize = data[0]*256 + data[1];
                                console.log('messageSize:' + messageSize);
                                bytesReceived = Buffer.alloc(messageSize, data.slice(2));
                                numBytesReceived += data.length - 2;
                                console.log('numBytesReceived:' + numBytesReceived);
                            } else {
                                console.log('FILL:' + numBytesReceived);
                                bytesReceived.fill(data, numBytesReceived);
                                numBytesReceived += data.length;
                            }

                            if (numBytesReceived == messageSize) {
                                allBytesReceived(bytesReceived);
                                client.removeAllListeners('data');
                            }
                        } catch (e) {
                            console.log(e);
                            reject();
                            client.removeAllListeners('data');
                        }
                    };

                    client.on('data', f);
                    client.write(Buffer.from(message, 'latin1'));
            };

            var reset = function () {
                var xml = '<SIP><Version>1.0</Version><ECRId>1</ECRId><Request>Reset</Request><RequestId>52</RequestId></SIP>';

                return new Promise(function (resolve, reject) {
                    var allBytesReceived = function (bytesReceived) {
                        console.log('all bytes received!');
                        var xml = bytesReceived.toString();
                        console.log(xml);
                        resolve({});
                    };

                    generic(xml, allBytesReceived, reject);
                });
            };

            var laneOpen = function () {
                var xml = '<SIP><Version>1.0</Version><ECRId>1</ECRId><Request>LaneOpen</Request><RequestId>52</RequestId></SIP>';

                return new Promise(function (resolve, reject) {
                    var allBytesReceived = function (bytesReceived) {
                        console.log('all bytes received!');
                        var xml = bytesReceived.toString();
                        console.log(xml);
                        resolve({});
                    };

                    generic(xml, allBytesReceived, reject);
                });
            };

            var laneClose = function () {
                var xml = '<SIP><Version>1.0</Version><ECRId>1</ECRId><Request>LaneClose</Request><RequestId>52</RequestId></SIP>';

                return new Promise(function (resolve, reject) {
                    var allBytesReceived = function (bytesReceived) {
                        console.log('all bytes received!');
                        resolve({});
                    };

                    generic(xml, allBytesReceived, reject);
                });
            };

            var dig = function (result, arr) {
                let pointer = result;

                try {
                    for (let val of arr) {
                        pointer = pointer[val];

                        if (pointer == null) {
                            return null;
                        }
                    }
                } catch (e) {
                    return null;
                }

                return pointer;
            };

            var parseResponse = function (xml) {

                var options = {compact: true};
                var result = convert.xml2js(xml, options);


                var totalAmountCents = dig(result, ['SIP', 'AuthorizedAmount', '_text']) ? parseInt(dig(result, ['SIP', 'AuthorizedAmount', '_text'])) : 0;

                return {
                    paymentProcessor: 'heartland',

                    terminalId: dig(result, ['SIP', 'DeviceId', '_text']),
                    transactionId: dig(result, ['SIP', 'ResponseId', '_text']),

                    transactionType: dig(result, ['SIP', 'Response', '_text']),
                    cardType: dig(result, ['SIP', 'CardGroup', '_text']),
                    cardName: dig(result, ['SIP', 'CardType', '_text']),
                    maskedCardNumber: dig(result, ['SIP', 'MaskedPAN', '_text']),
                    cardNumber: dig(result, ['SIP', 'MaskedPAN', '_text']),
                    success: (dig(result, ['SIP', 'Result', '_text']) === '0'
                        && dig(result, ['SIP', 'GatewayRspCode', '_text']) === '0'
                        && dig(result, ['SIP', 'ResponseCode', '_text']) === '00')
                        || dig(result, ['SIP', 'PartialApproval', '_text']) === '1',

                    balanceDue: dig(result, ['SIP', 'BalanceDueAmount', '_text']) ? (parseInt(dig(result, ['SIP', 'BalanceDueAmount', '_text']))/100).toFixed(2) : 0.0,
                    batchNum: -1,
                    tipAmount: dig(result, ['SIP', 'TipAmount', '_text']) ? (parseInt(dig(result, ['SIP', 'TipAmount', '_text']))/100).toFixed(2) : 0.0,
                    signatureBase64Png: null,
                    debitAccountType: dig(result, ['SIP', 'CardGroup', '_text']),
                    emvAid: dig(result, ['SIP', 'EMV_AID', '_text']),
                    emvTvr: dig(result, ['SIP', 'EMV_TVR', '_text']),
                    emvTsi: dig(result, ['SIP', 'EMV_TSI', '_text']),
                    emvAppLabel: dig(result, ['SIP', 'EMV_ApplicationName', '_text']),
                    emvCryptogramType: dig(result, ['SIP', 'EMV_CryptogramType', '_text']),
                    emvCryptogram: dig(result, ['SIP', 'EMV_Cryptogram', '_text']),

                    cvmResult: 'N/A',
                    hostResponseCode: dig(result, ['SIP', 'GatewayRspCode', '_text']),
                    hostResponseIsoCode: dig(result, ['SIP', 'ResponseCode', '_text']),
                    demoMode: false,
                    isCredit: dig(result, ['SIP', 'CardGroup', '_text']) === 'CREDIT',
                    isDebit: dig(result, ['SIP', 'CardGroup', '_text']) === 'DEBIT',
                    showCustomerSignatureLine: dig(result, ['SIP', 'SignatureLine', '_text']) === '1' && totalAmountCents >= 2500,

                    customerLanguage: 'en',
                    merchantId: 'MID',
                    transactionAmount: dig(result, ['SIP', 'AuthorizedAmount', '_text']) ? (parseInt(dig(result, ['SIP', 'AuthorizedAmount', '_text']))/100).toFixed(2) : '0.00',
                    partiallyApproved: dig(result, ['SIP', 'PartialApproval', '_text']) === '1',
                    transactionTypeCode: dig(result, ['SIP', 'Response', '_text']),
                    surchargeAmount: null,
                    totalAmount: dig(result, ['SIP', 'AuthorizedAmount', '_text']) ? (parseInt(dig(result, ['SIP', 'AuthorizedAmount', '_text']))/100).toFixed(2) : '0.00',
                    approvedAmount: dig(result, ['SIP', 'AuthorizedAmount', '_text']) ? (parseInt(dig(result, ['SIP', 'AuthorizedAmount', '_text']))/100).toFixed(2) : '0.00',
                    cardBalance: null,
                    transactionDateTime: toTransactionDateTime(dig(result, ['SIP', 'TransactionTime', '_text'])),
                    referenceNumber: dig(result, ['SIP', 'ReferenceNumber', '_text']),
                    cardEntryMethod: dig(result, ['SIP', 'CardAcquisition', '_text']),
                    authorizationNumber: dig(result, ['SIP', 'ApprovalCode', '_text']),
                    emvAppPreferredName: dig(result, ['SIP', 'EMV_ApplicationName', '_text']),
                    transactionStatus: -1,
                    verifiedByPin: dig(result, ['SIP', 'PinVerified', '_text']) === '1',
                    // invoiceNumber: result.SIP.SIPId._text,
                    formFactor: null,
                    transactionSequenceNum: dig(result, ['SIP', 'ReferenceNumber', '_text']),
                    cardholderName: dig(result, ['SIP', 'CardholderName', '_text']),

                    isDeviceBusy: dig(result, ['SIP', 'Result', '_text']) === '1507',
                };
            };

            var creditReturn = async function (amount) {
                await initializeSocket();
                await reset();
                await laneOpen();

                var amountCents = Math.round(Number(amount)*100, 10);

                var xml = '<SIP>' +
                  '<Version>1.0</Version>' +
                  '<ECRId>1004</ECRId>' +
                  '<Request>Refund</Request>' +
                  '<RequestId>' + parseInt(Date.now()/100) + '</RequestId>' +
                  '<TotalAmount>' + amountCents + '</TotalAmount>' +
                  '<CardGroup>Credit</CardGroup>' +
                  '<ConfirmAmount>0</ConfirmAmount>' +
                '</SIP>';

                return new Promise(function (resolve, reject) {
                    var allBytesReceived = function (bytesReceived) {
                        console.log('all bytes received!');
                        var xml = bytesReceived.toString();
                        console.log(xml);
                        var obj = parseResponse(xml);
                        console.log(obj);

                        if (obj.success) {
                            resolve([obj, xml]);
                        } else {
                            reject({data: [obj, xml]});
                        }
                    };

                    generic(xml, allBytesReceived, reject);
                });
            };

            var creditVoid = async function (transactionId) {
                await initializeSocket();
                await reset();
                await laneOpen();

                var xml = '<SIP>' +
                  '<Version>1.0</Version>' +
                  '<ECRId>1004</ECRId>' +
                  '<Request>Void</Request>' +
                  '<RequestId>' + parseInt(Date.now()/100) + '</RequestId>' +
                  '<TransactionId>' + transactionId + '</TransactionId>' +
                '</SIP>';

                return new Promise(function (resolve, reject) {
                    var allBytesReceived = function (bytesReceived) {
                        console.log('all bytes received!');
                        var xml = bytesReceived.toString();
                        console.log(xml);
                        var obj = parseResponse(xml);
                        console.log(obj);

                        if (obj.success) {
                            resolve([obj, xml]);
                        } else {
                            reject({data: [obj, xml]});
                        }
                    };

                    generic(xml, allBytesReceived, reject);
                });
            };

            var creditSale = async function (amount) {
                await initializeSocket();
                await reset();
                await laneOpen();

                var amountCents = Math.round(Number(amount)*100, 10);

                var xml = '<SIP>' +
                  '<Version>1.0</Version>' +
                  '<ECRId>1004</ECRId>' +
                  '<Request>Sale</Request>' +
                  '<RequestId>' + parseInt(Date.now()/100) + '</RequestId>' +
                  '<CardGroup>All</CardGroup>' +
                  '<ConfirmAmount>0</ConfirmAmount>' +
                  '<BaseAmount>' + amountCents+ '</BaseAmount>' +
                  '<TaxAmount>0</TaxAmount>' +
                  '<TipAdjustAllowed>0</TipAdjustAllowed>' +
                  '<EBTAmount>' + amountCents + '</EBTAmount>' +
                  '<TotalAmount>' + amountCents + '</TotalAmount>' +
                '</SIP>';

// <SIP>
 // <Version>1.0</Version>
 // <ECRId>E004</ECRId>
 // <Request>Sale</Request>
 // <CardGroup>Credit</CardGroup>
 // <BaseAmount>587</BaseAmount>
 // <TotalAmount>587</TotalAmount>
 // <TransactionId>5870</TransactionId>
 // <ApprovalCode>5870</ApprovalCode>
// </SIP>


// <SIP>
// <Version>1.0</Version>
// <ECRId>1004</ECRId>
// <SIPId>16266PP82539637</SIPId>
// <DeviceId>90914322</DeviceId>
// <RequestId>10043</RequestId>
// <Response>Sale</Response>
// <ResponseId>1134233289</ResponseId>
// <MultipleMessage>0</MultipleMessage>
// <Result>0</Result>
// <ResultText>Success</ResultText>
// <TransactionTime>020519105432</TransactionTime>
// <GatewayRspCode>0</GatewayRspCode>
// <GatewayRspMsg>Success</GatewayRspMsg>
// <ResponseCode>00</ResponseCode>
// <ResponseText>APPROVAL</ResponseText>
// <ApprovalCode>010871</ApprovalCode>
// <ReferenceNumber>903613752227</ReferenceNumber>
// <CardType>MASTERCARD</CardType>
// <CardGroup>CREDIT</CardGroup>
// <CardAcquisition>EMV TAP</CardAcquisition>
// <MaskedPAN>************4111</MaskedPAN>
// <EMV_AID>A0000000041010</EMV_AID>
// <EMV_ApplicationName>MasterCard</EMV_ApplicationName>
// <EMV_TVR>0000008000</EMV_TVR>
// <EMV_TSI>0000</EMV_TSI>
// <EMV_CryptogramType>ARQC</EMV_CryptogramType>
// <EMV_Cryptogram>46CB2C5BD800013F</EMV_Cryptogram>
// <SignatureLine>0</SignatureLine>
// <PinVerified>0</PinVerified>
// <TipAdjustAllowed>1</TipAdjustAllowed>
// <QPSQualified>0</QPSQualified>
// <TipAmount>0</TipAmount>
// <TaxAmount>0</TaxAmount>
// <AuthorizedAmount>100</AuthorizedAmount>
// </SIP>

                var timeout = null;
                var isRetrying = false;
                return new Promise(function (resolve, reject) {
                    var allBytesReceived = function (bytesReceived) {

                        console.log('all bytes received!');
                        var xml = bytesReceived.toString();
                        console.log(xml);
                        var obj = parseResponse(xml);
                        console.log(obj);

                        if (isRetrying && obj.isDeviceBusy) {
                            return;
                        }
                        $timeout.cancel(timeout);

                        if (obj.success) {
                            resolve([obj, xml]);
                        } else {
                            reject({data: [obj, xml]});
                        }
                    };

                    var failed = function (e) {
                        $timeout.cancel(timeout);
                        reject(e);
                    };

                    var send = function () {
                        isRetrying = true;
                        timeout = $timeout(send, 70000);
                        generic(xml, allBytesReceived, failed);
                    };

                    // timeout = $timeout(send, 70000);
                    generic(xml, allBytesReceived, failed);
                });

            };

            var debitSale = function (amount) {
                return creditSale(amount);
            };

            var debitReturn = function (amount) {
                return creditReturn(amount);
            };

            var testSale = function () {
                return creditSale(0.01);
            };

            var autoSettle = function () {
// <SIP>[0D][0A]
  // <Version>1.0</Version>[0D][0A]
  // <ECRId>1004</ECRId>[0D][0A]
  // <Request>CloseBatch</Request>[0D][0A]
  // <RequestId>100418</RequestId>[0D][0A]
// </SIP>[0A]
            };

            var isCancellableFromPos = function () {
                return true;
            };

            var cancelFromPos = async function () {
                await reset();
                return Promise.resolve();
            };

            return {
                init: init,
                laneOpen: laneOpen,
                laneClose: laneClose,
                creditSale: creditSale,
                creditVoid: creditVoid,
                creditReturn: creditReturn,
                debitSale: debitSale,
                debitReturn: debitReturn,
                testSale: testSale,
                autoSettle: autoSettle,
                parseResponse: parseResponse,
                isCancellableFromPos: isCancellableFromPos,
                cancelFromPos: cancelFromPos
            };
        }
    ]);
}
