'use strict';

/* eslint-disable no-case-declarations */
export function print (freshideas) {
    freshideas.factory('ElectronPrinter', [
        'Platform',
        '$log',
        function (Platform, $log) {
            var Helper = require('./print.helper.js');
            var escpos, icon, lastIconUrl;

            /**
             * Utility to download and image from a URL, or load it from local file system.
             * The resulting image is a ESC pos compatible image or an error
             * @param {*} url
             * @param {*} cached
             * returns a promise that gets resolved with an obj {pixels, error}
             */
            function loadEscposImage (url, cached = true) {
                return new Promise((resolve, reject) => {
                    if (!url) {
                        return resolve({error: 'Url of Dir required'});
                    }
                    if (lastIconUrl && (lastIconUrl === url) && icon && cached === true) {
                        return resolve({pixels: icon});
                    }
                    try {
                        escpos.Image.load(url, function (image) {
                            if (image instanceof Error) {
                                return resolve({error: image});
                            }
                            icon = image;
                            lastIconUrl = url;
                            resolve({pixels: icon});
                        });
                    } catch (error) {
                        resolve({error: error});
                    }
                });
            }

            function initPrinter (config) {
                return new Promise((resolve, reject) => {
                    if (!Platform.isElectron() || !config) {
                        return reject();
                    }
                    let connection;
                    try {
                        switch (config.interface) {
                            case Helper.PRINT_INTERFACE.USB:
                                const {vid, pid} = config;
                                if (vid && pid) {
                                    connection = new escpos.USB(vid, pid);
                                }
                                break;
                            case Helper.PRINT_INTERFACE.TCP:
                                const {ipAddress, port} = config;
                                if (ipAddress) {
                                    connection = new escpos.Network(ipAddress, port || 9100);
                                    connection.device.once('connect', () => {
                                        connection.device.setTimeout(0);
                                    });
                                    connection.device.setTimeout(config.timeout || 5000, () => {
                                        if (connection && connection.device) {
                                            connection.device.destroy();
                                        }
                                        let message = `Timeout connection - address: ${ipAddress}`;
                                        let err = new Error(message);
                                        reject(err);
                                    });
                                }
                                break;
                            default:
                                connection = new escpos.USB();
                                break;
                        }
                        if (!connection) {
                            return reject('Unable to create device');
                        }
                        let printer = new escpos.Printer(connection, {encoding: config.encoding || '852'});
                        try {
                            connection.open(function (error, device) {
                                if (error) {
                                    return reject(error);
                                }
                                resolve({device: device, printer: printer});
                            });
                        } catch (error) {
                            reject(error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                });
            }

            function createQR (printer, data = '') {
                return new Promise((resolve, reject) => {
                    printer.qrimage(data, function (err) {
                        resolve();
                    });
                });
            }

            function processPrint (printerObj, printer, device, iconImage) {
                return new Promise(async (resolve, reject) => {
                    try {
                        if (printerObj.xml) {
                            let rows = Helper.xml2json(printerObj.xml);

                            try {
                                if (printerObj.iconUrl && iconImage) {
                                    printer.align(Helper.ALIGN_TYPE.center);
                                    printer.image(iconImage, 'd24');
                                }
                            } catch (error) {
                                $log.error(error);
                            }
                            for (let r of rows) {
                                if (r && r.name === 'alignment') {
                                    printer.align(Helper.ALIGN_TYPE[r.attributes.position]);
                                } else if (r && r.name === 'text') {
                                    let tempText = '';

                                    let textElements = r.elements;
                                    if (textElements) {
                                        textElements.forEach((t) => {
                                            tempText += Helper.unescapeXML(Helper.unpack(t.text));
                                        });
                                        printer.lineSpace(null); // sets line height to default
                                    } else {
                                        printer.lineSpace(1);
                                        tempText += '\n';
                                    }
                                    let emphasis = r.attributes ? (r.attributes.emphasis === 'true') : false;
                                    let underline = r.attributes ? (r.attributes.underline === 'true') : false;
                                    // let invert = r.attributes ? (r.attributes.invert === 'true') : false;
                                    let width = r.attributes ? Number(r.attributes.width) : 1;
                                    let height = r.attributes ? Number(r.attributes.height) : 1;
                                    // let thickness = r.attributes ? r.attributes.thickness : 'medium';

                                    printer.size(width, height);
                                    if (r.attributes && !r.elements && underline) {
                                        printer.text('__________________________________________');
                                    } else if (r.attributes) {
                                        if (emphasis === true && underline === true) {
                                            printer.style('BU'); // BOLD & UNDERLINE
                                        } else if (emphasis === true) {
                                            printer.style('B'); // BOLD
                                        } else if (underline === true) {
                                            printer.style('U'); // UNDERLINE
                                        } else {
                                            printer.style('normal');
                                        }
                                    } else {
                                        printer.style('normal');
                                    }
                                    printer.pureText(tempText);
                                } else if (r && r.name === 'cutpaper') {
                                    var partialCut = false;

                                    try {
                                        // Type values = 'partial', 'full'
                                        partialCut = r.attributes.type === 'partial';
                                    } catch (error) {
                                        console.error(error);
                                    }

                                    printer.lineSpace(null);
                                    printer.pureText('\n');
                                    printer.cut(partialCut);
                                } else if (r && r.name === 'ruledline') {
                                    printer.text('__________________________________________');
                                } else if (r && r.name === 'qrcode') {
                                    let data = r.elements[0].text || '';
                                    await createQR(printer, data);
                                } else if (r && r.name === 'rawdata') {
                                    let data = r.elements[0].text || '';
                                    let {pixels} = await loadEscposImage(atob(data), false);

                                    printer.image(pixels, 'd24');
                                }
                            }
                        }
                        resolve();
                    } catch (error) {
                        // $log.error(error);
                        reject(error);
                    }
                });
            }

            function getStringDescriptor (printer, id) {
                return new Promise(async (resolve, reject) => {
                  printer.getStringDescriptor(id, (err, data) => {
                    if (err) {
                      resolve('');
                    }
                    resolve(data);
                  });
                });
            }

            function fillInPrinterDescriptors (printer) {
              return new Promise(async (resolve, reject) => {
                if (!printer.manufacturer) {
                  let manufacturer = await getStringDescriptor(printer, printer.deviceDescriptor.iManufacturer);
                  printer.manufacturer ? printer.manufacturer.push(manufacturer) : printer.manufacturer = [manufacturer];
                }
                if (!printer.productName) {
                  let productName = await getStringDescriptor(printer, printer.deviceDescriptor.iProduct);
                  printer.productName ? printer.productName.push(productName) : printer.productName = [productName];
                }
                if (!printer.serialNumber) {
                  let serialNumber = await getStringDescriptor(printer, printer.deviceDescriptor.iSerialNumber);
                  printer.serialNumber ? printer.serialNumber.push(serialNumber) : printer.serialNumber = [serialNumber];
                }
                resolve();
              });
            }

            var scanForUsbPrinters = function () {
                return new Promise(async (resolve, reject) => {
                    if (!Platform.isElectron()) {
                        return reject();
                    }
                    let printers = escpos.USB.findPrinter() || [];

                    if (!printers.length) {
                        return resolve(printers);
                    }

                    let i = printers.length;
                    while (i--) {
                        try {
                            let printer = printers[i];
                            printer.open();
                            await fillInPrinterDescriptors(printer);
                            printer.close();
                        } catch (error) {
                            // probably a device thats not supported by LIBUSB
                            // so remove from list
                            printers.splice(i, 1);
                        }
                    }
                    return resolve(Helper.filterProperties(printers));
                });
            };

            var kickExternalCashDrawer = function (type) {
                /**
                * ESC/P Command    Hex Command         Description
                * -------------------------------------------------
                * ESC p 0          1B,70,00            Epson cash drawer 1 open command
                * ESC p 1          1B,70,01            Epson cash drawer 2 open command
                * ESC x 1          1B,78,01            M80 cash drawer 1 open command
                * ESC x 2          1B,78,02            M80 cash drawer 2 open command
                * ESC p 1 c c    1B,70,31,63,63        MINT cash drawer 2 open command
                *  */
                const cashDrawerTypes = {
                    epson1: [0x1B, 0x70, 0x00],
                    epson2: [0x1B, 0x70, 0x31],
                    m801: [0x1B, 0x78, 0x01],
                    m802: [0x1B, 0x78, 0x02],
                    mint2: [0x1B, 0x70, 0x31, 0x63, 0x63]
                };

                return new Promise((resolve, reject) => {
                    const port = 'COM5';
                    const connection = new escpos.Serial(port);

                    connection.open(function (openError) {
                        if (openError) {
                            console.error(`Error: Unable to open cash drawer on ${port}`, openError);
                            resolve();
                            return;
                        }

                        connection.write(cashDrawerTypes[type]);
                        connection.close(function (closeError) {
                            if (closeError) {
                                console.error(`Error: Failed to release cash drawer com port ${port}`, closeError);
                            }
                            resolve();
                            return;
                        });
                    });
                });
            };

            var print = function (printerObj = Helper.PRINTER_DEFAULT) {
                return new Promise(async (resolve, reject) => {
                    if (!Platform.isElectron()) {
                        return reject();
                    }
                    let _printer;
                    try {
                        const {device, printer} = await initPrinter(printerObj);
                        _printer = printer;

                        // Some printers do not have a cashdrawer port inline with the print port
                        // the following ensures that we route the cash drawer kick command to the
                        // correct port
                        const {openDrawer, cashDrawerType} = printerObj;
                        if (openDrawer) {
                            if (cashDrawerType) {
                                kickExternalCashDrawer(cashDrawerType);
                            } else {
                                printer.cashdraw().font('a');
                            }
                        }

                        if (printerObj.iconUrl) {
                            let {pixels} = await loadEscposImage(printerObj.iconUrl);
                            await processPrint(printerObj, printer, device, pixels);
                        } else {
                            await processPrint(printerObj, printer, device);
                        }
                    } catch (error) {
                        // this is mostly used for when a printer cannot be found. reenable when needed
                        // $log.error(error);
                        reject(error);
                    } finally {
                        if (_printer) {
                            _printer.close((err) => {
                                if (err) {
                                    $log.error(err);
                                }
                                resolve();
                            });
                        }
                    }
                });
            };

            if (Platform.isElectron()) {
                escpos = nodeRequire('escpos');
            }

            return {
                scanForUsbPrinters: scanForUsbPrinters,
                print: print
            };
        }]);
}
