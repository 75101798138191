'use strict';


module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosTransactionRePrintOptionsCtrl', [
        '$scope',
        'PrintService',
        'PrintType',
        'GuestLabelService',
        'CurrentSession',
        'SmbPosService',
        'PrintReceiptType',
        'transaction',
        'populateKitchenTransactionObject',
        'SharedDataService',
        'CompanyAttributesService',
        'Platform',
        'PosAlertService',
        '$translate',
        function (
            $scope,
            PrintService,
            PrintType,
            GuestLabelService,
            CurrentSession,
            SmbPosService,
            PrintReceiptType,
            transaction,
            populateKitchenTransactionObject,
            SharedDataService,
            CompanyAttributesService,
            Platform,
            PosAlertService,
            $translate) {

            $scope.PrintType = PrintType;
            $scope.canPrintGiftReceipt = CurrentSession.getCompany().attributes['receipt__has_gift_receipt'] === 'true';
            $scope.isIosWebkit = Platform.isIosWebkit();
            $scope.isElectron = Platform.isElectron();
            $scope.patron = transaction.patron;

            $scope.showKitchenReceipts = function () {
                var posPrinters = SharedDataService.posStation.posPrinters;
                var kitchenPrinters = _.find(posPrinters, function (posPrinter) {
                    return posPrinter.kitchenPrinter === true;
                });
                return kitchenPrinters && !CompanyAttributesService.hasRetailMenu();
            };

            $scope.printReceipt = function (printType) {
                SmbPosService.reprintReceipt(transaction, PrintReceiptType.TRANSACTION, true, printType);
            };

            $scope.printGiftReceiptV2 = function () {
                SmbPosService.reprintGiftReceipt(transaction, true);
            };

            $scope.printKitchenSheets = async function () {
                var transactionDetail;
                if (populateKitchenTransactionObject) {
                    var response = await SmbPosService.getTransactionResult(
                        transaction.transactionId, true);
                    transactionDetail = response;
                } else if (transaction.detail) {
                    transactionDetail = transaction.detail;
                }

                SmbPosService.printKitchenReceipts(
                    transactionDetail.receipt,
                    transactionDetail
                );
            };

            $scope.emailReceipt = async () => {
                try {
                    if (!transaction.patron) {
                        // linkPatron will update the transaction.patron object on success
                        await SmbPosService.linkPatron(transaction);
                    }

                    $scope.sendingEmail = true;
                    await SmbPosService.emailReceipt({transactionUuid: transaction.transactionUuid});
                    $scope.sendingEmail = false;
                    $scope.emailSent = true;
                    $scope.$apply();
                } catch (error) {
                    $scope.sendingEmail = false;
                    if (transaction.patron) {
                        // alert only if patron is linked/exists, but email failed to send
                        let message = $translate.instant('smb.pos.signupForm.error');
                        if (error.data && error.data.error) {
                            message = error.data.error;
                        }

                        PosAlertService.showAlertByName('general-error', {
                            message
                        });
                    }
                    $scope.$apply();
                }
            };
        }
    ]);
};
