module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ScanningItemModalCtrl', [
        '$scope',
        '$modal',
        '$modalInstance',
        '$translate',
        'sku',
        function ($scope,
            $modal,
            $modalInstance,
            $translate,
            sku) {

            $scope.messageToShow = $translate.instant('smb.inventory.items.currently-settling', {
                sku: sku
            });
            $scope.isDoneScanning = false;
            $scope.isError = false;


        }]);
};

