'use strict';


const moment = require('moment');

module.exports = function (freshideasReports) {

    freshideasReports.controller('PatronMealPlanHistoryReport', [
    '$scope',
    '$timeout',
    'Reports',
    'Export',
    'Lookup',
    'Meals',
    'Security',
    'Calculate',
    'UserActivityTypeService',
    'Platform',
    function ($scope, $timeout, Reports, Export, Lookup, Meals, Security, Calculate, UserActivityTypeService, Platform) {

        var userActivityTypes = UserActivityTypeService;
        $scope.isIosWebkit = Platform.isIosWebkit();

        var trackedFields = [
            'remainingServicePeriodMeals',
            'currentMealPlanBalance',
            'remainingServicePeriodDcb',
            'currentDcbBalance',
            'remainingServicePeriodCharge',
            'currentChargeBalance'
        ];

        $scope.getUserActivityType = function (type) {
            return userActivityTypes[type] || '';
        };


        $scope.clearFilters = function (isAdvancedSearch) {
            $scope.reportSearch = {isAdvancedSearch: !!isAdvancedSearch};
        };

        $scope.opened = {};
        $scope.toggleFromDatePicker = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();
            var status = !!$scope.opened.from;
            var newStatus = !status;
            $scope.opened.from = newStatus;
            if (newStatus) {
                $scope.opened.to = false;
            }
        };
        $scope.toggleToDatePicker = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();
            var status = !!$scope.opened.to;
            var newStatus = !status;
            $scope.opened.to = newStatus;
            if (newStatus) {
                $scope.opened.from = false;
            }
        };

        // default reportSearch
        $scope.reportSearch = {
            patronKey: '',
            fromDate: moment().subtract(1, 'days').startOf('day').toDate(),
            toDate: moment().endOf('day').toDate()
        };

        $scope.getPatronMealPlanHistory = function () {

            if ($scope.reportSearch.patronKey) {
                var reportSearch = {
                    patronKey: $scope.reportSearch.patronKey,
                    startDateTime: moment($scope.reportSearch.fromDate).startOf('day').valueOf(),
                    endDateTime: moment($scope.reportSearch.toDate).endOf('day').valueOf()
                };

                Reports.getPatronMealPlanHistoryReport(reportSearch, function (response) {
                    $scope.currentPatron = response.patron;

                    var groupedPatronMealPlanRevisions = groupPatronMealPlanRevisions(response.patronMealPlanRevisions);
                    markRevisionChanges(groupedPatronMealPlanRevisions);

                    $scope.groupedPatronMealPlanRevisions =
                        (_.isEmpty(groupedPatronMealPlanRevisions))?
                            undefined : groupedPatronMealPlanRevisions;

                    $scope.currentReportSearch = {
                        patronKey: reportSearch.patronKey,
                        fromDate: reportSearch.startDateTime,
                        endDate: reportSearch.endDateTime
                    };

                    $scope.result = true;
                }, function (error) {
                    // patron not found
                    $scope.currentReportSearch = {
                        patronKey: reportSearch.patronKey,
                        fromDate: reportSearch.startDateTime,
                        endDate: reportSearch.endDateTime
                    };

                    $scope.result = false;
                });
            }
        };

        var groupPatronMealPlanRevisions = function (patronMealPlanRevisions) {
            return _.groupBy(patronMealPlanRevisions, function (patronMealPlanRevision) {
                return patronMealPlanRevision.patronMealPlan.mealPlanId;
            });
        };
        var markRevisionChanges = function (groupedPatronMealPlanRevisions) {
            _.each(groupedPatronMealPlanRevisions, function (patronMealPlanRevisions) {
                patronMealPlanRevisions = _.sortBy(patronMealPlanRevisions, 'timestamp');

                var previousValues;
                _.each(patronMealPlanRevisions, function (patronMealPlanRevision) {
                    if (previousValues) {
                        var changes = {};
                        _.each(trackedFields, function (trackedField) {
                            if (previousValues[trackedField] !== patronMealPlanRevision.patronMealPlan[trackedField]) {
                               changes[trackedField] = (patronMealPlanRevision.patronMealPlan[trackedField] - previousValues[trackedField]);
                            }
                            previousValues[trackedField] = patronMealPlanRevision.patronMealPlan[trackedField];
                        });
                        patronMealPlanRevision.patronMealPlan.changes = changes;
                    } else {
                        previousValues = {};
                        _.each(trackedFields, function (trackedField) {
                            previousValues[trackedField] = patronMealPlanRevision.patronMealPlan[trackedField];
                        });
                    }
                });
            });
        };

        $scope.init = function () {
        };

        $scope.init();

        $scope.exportToPdf = function (tableId) {
            Export.tableToPdf(tableId, 'portrait');
        };

    }]);

};
