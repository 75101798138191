'use strict';

export function cardTerminalCtPayments (freshideas) {

    freshideas.factory('CardTerminalCtPayments', [
        '$http',
        '$log',
        'EnvConfig',
        function (
            $http,
            $log,
            EnvConfig) {
        // var paymentUrl;
        var config;

        var cardTypes = {
            'A': 'American Express',
            'M': 'MasterCard',
            'I': 'Diners',
            'V': 'Visa',
            'O': 'Discover'
        };

        var trxTypes = {
            'C': 'Credit',
            'D': 'Debit'
        };

        var init = function (terminalConfig) {
            config = {
                host: EnvConfig.ctpaymentsGatewayUrl,
                companyNumber: terminalConfig.companyNumber,
                merchantNumber: terminalConfig.merchantNumber,
                terminalNumber: terminalConfig.terminalNumber,
                apiKey: terminalConfig.apiKey
            };
        };

        var parseAmount = function (amount) {
            // $10.00 -> 00000001000
            amount = Math.round((amount * 100)).toString();
            return '0'.repeat(11 - amount.length) + amount;
        };

        var handleResponse = function (response, transType, successCallback, errorCallback) {
            if (response.returnCode == '  00') {
                var terminalResponse = parseResponse(response, transType);

                successCallback(terminalResponse);
            } else {
                errorCallback();
            }
        };

        var generateInvoiceId = function () {
            var invoiceId = '';
            while (invoiceId.length != 12) {
                invoiceId = Math.floor(100000000000 + Math.random() * 900000000000).toString();
            }
            return invoiceId;
        };

        var creditSale = function (amount) {
            amount = parseAmount(amount);

            // generate random 12 digit number
            var invoiceId = generateInvoiceId();

            var payload = 'CompanyNumber=' + config.companyNumber +
                      '&MerchantNumber=' + config.merchantNumber +
                      '&CustomerNumber=' + spaces(8) +
                      '&Amount=' + amount +
                      '&InvoiceNumber=' + invoiceId +
                      '&InputType=N' +
                      '&CardType='+ spaces(1) +
                      '&CardNumber=' + spaces(40) +
                      '&ExpirationDate=' + spaces(4) +
                      '&Cvv2Cvc2Number=' + spaces(3) +
                      '&MerchantTerminalNumber=' + config.terminalNumber +
                      '&LanguageCode=E' +
                      '&CurrencyCode=CAD' +
                      '&OperatorID=LUCOVA01';

            // Payload needs to be base64 encoded
            var data = 'auth-api-key=' + config.apiKey + '&payload=' + btoa(payload);
            var url = config.host + '/purchase';

            return new Promise(function (resolve, reject) {
                var req = {
                    method: 'POST',
                    url: url,
                    data: data,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json'
                    }
                };

                $http(req).then(function (response) {
                    handleResponse(response.data, 'PURCHASE', function (terminalResponse) {
                        resolve([terminalResponse, response.data]);
                    }, function () {
                        reject();
                    });
                }, function (error) {
                    reject();
                });
            });
        };

        var spaces = function (amnt=0) {
            return ' '.repeat(amnt);
        };

        var creditVoid = function (transactionId) {
            $log.error({
                message: 'Implement credit void ct payments',
            });
        };

        var creditReturn = function (amount) {
            $log.error({
                message: 'Implement credit return ct payments',
            });
        };

        var autoSettle = function () {
            $log.error({
                message: 'Implement auto settle ct payments',

            });
        };

        var debitSale = function (amount) {
            return creditSale(amount);
        };

        var debitReturn = function (amount) {
            return creditReturn(amount);
        };

        var testSale = function () {
            return creditSale(0.01);
        };

        var parseResponse = function (response, transType) {
            // Signature line must be printed if the 2 first digits of the CVM are:
            // 03, 05, 43, 45, 1E, 5E
            var showSigniture = ['03', '05', '43', '45', '1E', '5E'].indexOf(response.cvmResults.substring(0, 2)) >= 0;

            return {
                terminalId: response.bankTerminalNumber,
                transactionId: response.transactionNumber,
                transactionType: transType,
                cardType: trxTypes[response.trxType],
                cardBrand: cardTypes[response.cardType],
                maskedCardNumber: response.cardNumber,
                entryMethod: null,
                approvalCode: null,
                approvedAmount: response.amount / 100,
                success: true,
                batchNum: response.batchNumber,
                tipAmount: response.tipAmount ? response.tipAmount / 100 : null,
                signatureBase64Png: null,
                transactionSequenceNum: response.sequenceNumber,
                debitAccountType: response.accountType,
                emvAid: response.emvAID,
                emvTvr: response.emvTVR,
                emvTsi: response.emvTSI,
                emvAppLabel: response.emvLabel,
                cvmResult: response.cvmResults,
                hostResponseCode: null,
                hostResponseIsoCode: response.terminalDisp,
                demoMode: response.demoMode,
                isCredit: response.trxType == 'C',
                isDebit: response.trxType == 'D',
                showSignatureLine: showSigniture
            };
        };

        var isCancellableFromPos = function () {
            return false;
        };

        return {
            init: init,
            creditSale: creditSale,
            creditVoid: creditVoid,
            creditReturn: creditReturn,
            debitSale: debitSale,
            debitReturn: debitReturn,
            testSale: testSale,
            autoSettle: autoSettle,
            parseResponse: parseResponse,
            isCancellableFromPos: isCancellableFromPos,
        };
    }]);
}
