'use strict';


const angular = require('angular');
const Decimal = require('decimal.js').default;
const moment = require('moment');
const SharedDataService = require('../../external/pos.data-service.js');

module.exports = function (posModule) {

    posModule
        .controller('PosTransactionRefundLongCtrl', [
            '$scope',
            '$modal',
            '$translate',
            'Calculate',
            'CardTerminal',
            'CashierShift',
            'Locations',
            'Users',
            'Lucova',
            'PosAlertService',
            'PosBuilderService',
            'PrintService',
            'PrintType',
            'PrintReceiptType',
            'Security',
            'SharedFunctionService',
            'SmbPosService',
            'TerminalCommon',
            'CompanyAttributesService',
            'GatewayFiit',
            'GIFTCARD_SOURCE',
            '$timeout',
            function (
                $scope,
                $modal,
                $translate,
                Calculate,
                CardTerminal,
                CashierShift,
                Locations,
                Users,
                Lucova,
                PosAlertService,
                PosBuilderService,
                PrintService,
                PrintType,
                PrintReceiptType,
                Security,
                SharedFunctionService,
                SmbPosService,
                TerminalCommon,
                CompanyAttributesService,
                GatewayFiit,
                GIFTCARD_SOURCE,
                $timeout) {
                /* Used in case $0 items are selected for refund, so that the POS doesn't think nothing is selected
                   if the total is $0. */
                $scope.checkedItems = 0;
                $scope.dateOptions = {
                    formatYear: 'yy',
                    startingDay: 1,
                };

                const transactionTypeLabels = {
                    'ALL': 'All',
                    'POS': 'POS Only',
                    'PREORDER': 'Mobile Order Only',
                    'INCOMPLETE': 'Incomplete Transactions only',
                    'OTHER': 'Other'
                };

                $scope.transactionTypeLabels = transactionTypeLabels;
                $scope.GIFTCARD_SOURCE = GIFTCARD_SOURCE;
                $scope.areDatePickerOpen = {};
                $scope.toggleStartDatePicker = function ($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                    var status = !!$scope.areDatePickerOpen.start;
                    var newStatus = !status;
                    $scope.areDatePickerOpen.start = newStatus;
                    if (newStatus) {
                        $scope.areDatePickerOpen.end = false;
                    }
                };
                $scope.toggleEndDatePicker = function ($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                    var status = !!$scope.areDatePickerOpen.end;
                    var newStatus = !status;
                    $scope.areDatePickerOpen.end = newStatus;
                    if (newStatus) {
                        $scope.areDatePickerOpen.start = false;
                    }
                };
                $scope.datePickerClass = function () {
                    return 'transaction-search-date-picker';
                };
                $scope.toggleShowSearch = function () {
                    $scope.showSearch = !$scope.showSearch;
                };
                $scope.hideShowSearch = function () {
                    $scope.showSearch = false;
                };

                $scope.isExchangeEnabled = function () {
                    return CompanyAttributesService.hasExchangeEnabled();
                };

                $scope.transactionSearch = {
                    transactionId: undefined,
                    transactionUuid: undefined,
                    startDate: moment().subtract(1, 'week').startOf('day').toDate(),
                    endDate: moment().endOf('day').toDate(),
                    offset: 0,
                    limit: 15,
                    source: 'ALL' // transactionTypeLabels.ALL
                };

                $scope.pagination = {
                    numPerPage: 15,
                    page: 1,
                    totalPages: 1,
                    totalCount: 0
                };

                var setTransactionSearchResults = function (response) {
                    $scope.isLoading = false;
                    $scope.transactions = response.entries;
                    _.each($scope.transactions, function (transaction) {
                        if (transaction.refundStatus != 'NONE' && transaction.refundStatus != 'REFUND_PARTIAL') {
                            transaction.isRefunded = true;
                        }

                        transaction.totalItemQuantity = 0;
                        _.each(transaction.items, function (item) {
                            transaction.totalItemQuantity += item.quantity;
                        });
                    });

                    $scope.pagination.page = Math.floor(response.offset / $scope.pagination.numPerPage) + 1;
                    $scope.pagination.totalCount = response.totalCount;
                    $scope.pagination.totalPages = Math.ceil(response.totalCount / $scope.pagination.numPerPage);
                };

                $scope.searchTransactions = function (resetPages) {

                    let transactionSearch = angular.copy($scope.transactionSearch);
                    let searchInput = transactionSearch.transactionId;

                    if (typeof searchInput !== 'undefined' && searchInput.includes('#')) {
                        searchInput = searchInput.split('#')[1];
                    }

                    // Check for the presence of letters to determine if this is a QR code or not
                    const regex = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;
                    if (typeof searchInput !== 'undefined' && searchInput.match(regex)) {
                        $scope.onQrCodeScan(searchInput);
                        return;
                    }

                    $scope.isLoading = true;
                    $scope.transactions = [];

                    if (resetPages) {
                        $scope.transactionSearch.offset = 0;
                    }

                    transactionSearch.startDate = moment(transactionSearch.startDate).startOf('day').valueOf();
                    transactionSearch.endDate = moment(transactionSearch.endDate).endOf('day').valueOf();

                    if (transactionSearch.transactionId) {
                        transactionSearch.findAcrossOrganization = true;
                    } else {
                        transactionSearch.findAcrossOrganization = false;
                    }


                    $scope.hideShowSearch();

                    return CashierShift.searchTransactions(transactionSearch, function (response) {
                        setTransactionSearchResults(response);
                        $scope.currentTransactionSearch = transactionSearch;
                    });
                };
                $scope.searchNextTransactions = function () {
                    // Check if the user is scanning a QR code
                    $scope.transactionSearch.offset += $scope.pagination.numPerPage;
                    $scope.searchTransactions();
                };
                $scope.searchLastTransactions = function () {
                    $scope.transactionSearch.offset = ($scope.pagination.totalPages - 1) * $scope.pagination.numPerPage;
                    $scope.searchTransactions();
                };
                $scope.searchPreviousTransactions = function () {
                    var offset = Math.max(0, $scope.transactionSearch.offset - $scope.pagination.numPerPage);
                    $scope.transactionSearch.offset = offset;
                    $scope.searchTransactions();
                };
                $scope.searchFirstTransactions = function () {
                    $scope.transactionSearch.offset = 0;
                    $scope.searchTransactions();
                };

                $scope.canRefundByItem = function (transaction) {
                    return !GatewayFiit.isEnabled() && !transaction.deliveryFee;
                };

                $scope.hasFiitmpsTender = function (tenders) {
                    var result = false;
                    for (var tender of tenders) {
                        if (tender.transactionTenderDetail && tender.transactionTenderDetail.otherType === 'fiitmps') {
                            result = true;
                            break;
                        }
                    }
                    return result;
                };

                $scope.isParentTransactionItem = function (item) {
                    return $scope.receiptParentItems.indexOf(item.itemIndex) !== -1;
                };

                $scope.linkPatron = async (transaction) => {
                    $scope.blockQrScan = true;
                    const patron = await SmbPosService.linkPatron(transaction).catch(() => {});

                    if (patron) {
                        $scope.transactions.find((t) => t.transactionId === transaction.transactionId).patron = patron;
                        $scope.showPatronLinkedPopup = true;
                        $scope.$apply();
                        $timeout(() => {
                            $scope.showPatronLinkedPopup = false;
                        }, 1500);
                    }

                    $timeout(function () {
                        $scope.blockQrScan = false;
                    }, 500);
                };

                $scope.reprintReceipt = function (transaction) {
                    if (transaction.refundStatus == 'NONE') {
                        $modal.open({
                            templateUrl: 'pos/smb/templates/pos.print.options.tpl.html',
                            controller: 'SmbPosTransactionRePrintOptionsCtrl',
                            windowClass: 'smb-pos smb-pos-popup',
                            animation: true,
                            backdrop: true,
                            keyboard: false,
                            resolve: {
                                transaction: function () {
                                    return transaction;
                                },
                                populateKitchenTransactionObject: function () {
                                    return true;
                                },
                                linkPatron: () => $scope.linkPatron
                            }
                        });
                    } else {
                        SmbPosService.reprintReceipt(transaction, PrintReceiptType.REFUND, true);
                    }
                };

                var sortTransactionItemsAsPerIndex = function (a, b) {
                    if (a.itemIndex < b.itemIndex) {
                        return -1;
                    } else {
                        return 1;
                    }
                };

                $scope.selectTransaction = function (transaction) {
                    // $scope.originalSelectedTransaction = transaction;
                    $scope.selectedTransaction = angular.copy(transaction);
                    $scope.toRefund = false;

                    $scope.originalResult = calculateReceipt($scope.selectedTransaction);
                    _.each($scope.selectedTransaction.items, function (item) {
                        // item.mealEquivalencyEnabled = true;
                        item.loyaltyEnabled = true;
                    });


                    $scope.selectedTransaction.items.sort(sortTransactionItemsAsPerIndex);

                    $scope.originalReceiptItemMap = {};
                    _.each(transaction.items, function (item) {
                        $scope.originalReceiptItemMap[item.receiptItemId] = item;
                    });

                    $scope.receiptParentItems = [];
                    _.each(transaction.items, function (item) {
                        if ($scope.receiptParentItems.indexOf(item.index) == -1) {
                            $scope.receiptParentItems.push(item.index);
                        }
                    });

                    $scope.updatedReceiptItemMap = angular.copy($scope.originalReceiptItemMap);
                    _.each(transaction.refundTransactions, function (refundTransaction) {
                        _.each(refundTransaction.items, function (item) {
                            $scope.updatedReceiptItemMap[item.transactionItemId].quantity -= item.quantity;
                        });
                    });

                    _.each($scope.selectedTransaction.items, function (item) {
                        item.quantity = $scope.updatedReceiptItemMap[item.receiptItemId].quantity;

                        item.isRefunded = (item.quantity === 0);
                        item.toRefund = false;

                        item._isRefundable = isItemRefundable(item);
                    });

                    _checkIfTransactionRefundable($scope.selectedTransaction);

                    if ($scope.selectedTransaction.isRefunded) {
                        $scope.toggleAllForRefund();
                    }

                    $scope.selectedTransaction.tenders = _sortRefundTenders($scope.selectedTransaction.tenders);
                    _.each($scope.selectedTransaction.tenders, function (tender) {
                        _parseTransactionCreditCardNumber($scope.selectedTransaction, tender);
                    });
                };

                $scope.fetchTransaction = function (transactionId) {
                    var transactionSearch = {
                        transactionId: transactionId,
                        startDate: 0
                    };

                    return CashierShift.searchTransactions(transactionSearch, function (response) {
                        if (response && response.entries && response.entries.length > 0) {
                            $scope.selectTransaction(response.entries[0]);
                        }
                    });
                };

                $scope.onQrCodeScan = function (qrCode = '') {
                    // Ensure we are only showing one error at a time
                    if (!$scope.showingQrCodeError && !$scope.blockQrScan) {
                        $scope.isLoading = true;
                        // Get only the transaction uuid and exclude the app URL
                        let transactionUuid;
                        if (qrCode.includes('#')) {
                            transactionUuid = qrCode.split('#')[1];
                        } else {
                            transactionUuid = qrCode;
                        }

                        let transactionSearch = angular.copy($scope.transactionSearch);
                        transactionSearch.transactionId = undefined;
                        transactionSearch.transactionUuid = transactionUuid;
                        transactionSearch.startDate = moment().subtract(1, 'year').startOf('day').valueOf(),
                        transactionSearch.endDate = moment().endOf('day').valueOf();
                        transactionSearch.findAcrossOrganization = true;

                        try {
                            return CashierShift.searchTransactions(transactionSearch, function (response) {
                                if (response && response.entries && response.entries.length > 0) {
                                    $scope.selectTransaction(response.entries[0]);
                                    setTransactionSearchResults(response);
                                } else {
                                    // No transaction was found with the given uuid
                                    $scope.showingQrCodeError = true;
                                    PosAlertService.showAlertByName('general-error', {
                                        'title': $translate.instant('smb.transaction.search.qrcode.failedSearch.title'),
                                        'buttonType': 'ok',
                                        'message': $translate.instant('smb.transaction.search.qrcode.failedSearch.message'),
                                        'dismissModalCallback': function () {
                                            $scope.showingQrCodeError = false;
                                        }
                                    });
                                }
                            });
                        } catch (e) {
                            return PosAlertService.showAlertByName('general-error', {
                                'title': $translate.instant('smb.transaction.search.qrcode.failedSearch.title'),
                                'buttonType': 'ok',
                                'message': $translate.instant('smb.transaction.search.qrcode.failedSearch.message'),
                                'dismissModalCallback': function () {
                                    $scope.showingQrCodeError = false;
                                }
                            });
                        }
                    }
                };

                $scope.showingQrCodeError = false;

                var _checkIfTransactionRefundable = function (transaction) {
                    /**
                     * Source Ids
                     * SOURCE_FIIT = 0
                     * SOURCE_LUCOVA = 1
                     * SOURCE_LUCOVA_PREORDER = 2
                     * SOURCE_SHOPIFY_ONLINE = 3
                     */
                    // For now
                    var isPosTransaction = transaction.sourceId === 0;
                    var areSomeItemsStillRefundable = _.some(transaction.items, function (item) {
                        return !item.isRefunded && item._isRefundable;
                    });

                    var hasAlphaPayTender = false;
                    _.each(transaction.tenders, function (tender) {
                        if (tender.transactionType === 'OTHER') {
                            if (tender.transactionTenderDetail && tender.transactionTenderDetail.otherType === 'alphapay') {
                                hasAlphaPayTender = true;
                            }
                        }
                    });

                    var isRefundable = $scope.shift && $scope.shift.cashierShiftId && isPosTransaction && areSomeItemsStillRefundable
                        && !hasAlphaPayTender && !$scope.hasFiitmpsTender(transaction.tenders);

                    if (isRefundable) {
                        transaction.isRefundable = true;
                        delete transaction._refundErrorReason;
                    } else {
                        transaction.isRefundable = false;
                        transaction._refundErrorReason = {
                            shiftUnavailable: !($scope.shift && $scope.shift.cashierShiftId),
                            lucova: !isPosTransaction,
                            items: !areSomeItemsStillRefundable,
                            alphaPay: hasAlphaPayTender,
                            shopifyEcom: transaction.sourceId === 3
                        };
                    }
                };

                var _parseTransactionCreditCardNumber = function (selectedTransaction, selectedTender) {
                    // Grabbing by transaction posStation instead of the 'current' posStation.
                    // CC number will display based on the posStation used for the transaction.

                    // set tender only if CREDIT & matches the currently iterated tender.
                    for (var tender of selectedTransaction.tenders) {
                        if ( tender.transactionType === 'CREDIT' && tender === selectedTender) {
                            var creditTender = tender;
                        }
                    }

                    if ( creditTender ) {
                        if (!creditTender.terminalResponse || !selectedTransaction.terminalResponses) {
                            creditTender._identifier = $translate.instant('smb.pos.tenderType.manualEntry');
                        } else {
                            let terminalType = selectedTransaction.terminalResponses[0].processorType;
                            _grabCCFromStation(creditTender, terminalType);
                        }
                    }
                };

                var _grabCCFromStation = function ( tender, terminalType ) {
                    // var terminalConfig = posStation.cardTerminalProperties;
                    // var terminalType = terminalConfig.type;
                    // TerminalCommon.init(terminalConfig);

                    // This is a silly hack to enable Global Payments payloads
                    // to be parsed. Need to remove this in the future.
                    var terminalConfig = {
                        type: terminalType
                    };
                    TerminalCommon.init(terminalConfig);


                    let parsedResponse = CardTerminal.parseResponse(tender.terminalResponse, terminalType);

                    // Setting tender info
                    tender._parsedResponse = parsedResponse;
                    tender._identifier = parsedResponse.maskedCardNumber;
                };


                /*
                var _parseTenderCreditCardNumber = function (tender) {
                    if (tender.transactionType !== 'CREDIT') {
                        return;
                    }

                    if (!tender.terminalResponse) {
                        tender._identifier = $translate.instant('smb.pos.tenderType.manualEntry');
                    } else {
                        // This assumes the card terminal type for each location doesn't change. If not,
                        // we will need to find a way to identify the card terminal type of each
                        // terminal response before it is is processed
                        return Locations.getLocationPrinters({'locationId': SmbPosService.shift.locationId})
                            .$promise.then(function (response) {
                                var terminalConfig = response.posStation.cardTerminalProperties;
                                var terminalType = terminalConfig.type;

                                TerminalCommon.init(terminalConfig);
                                var parsedResponse = CardTerminal.parseResponse(tender.terminalResponse, terminalType);
                                tender._parsedResponse = parsedResponse;
                                tender._identifier = parsedResponse.maskedCardNumber;
                            });
                    }
                };
                */

                var calculateReceipt = function (transaction) {
                    _.each(transaction.items, function (receiptItem) {
                        receiptItem.taxRules = {};
                    });

                    var updatedItems = angular.copy(transaction.items);

                    var posData = PosBuilderService.createPosData(
                        updatedItems,
                        transaction.location.locationId,
                        null,
                        !!transaction.patron
                    );

                    return SharedFunctionService.calculateBalances(updatedItems, [], {}, posData, transaction.patron);
                };

                $scope.toggleItem = function (item) {
                    if (!item._isRefundable) {
                        return;
                    }

                    if (item.quantity > 0) {
                        item.quantity = 0;
                    } else {
                        var originalItem = $scope.originalReceiptItemMap[item.receiptItemId];
                        item.quantity = originalItem.quantity;
                    }

                    $scope.updatedResult = calculateReceipt($scope.selectedTransaction);
                    calculateRefundDetail();
                };

                $scope.toggleAllForRefund = function (item) {
                    if ($scope.toRefund) {
                        _.each($scope.selectedTransaction.items, function (item) {
                            if (item._isRefundable && item.isRefunded === false) {
                                $scope.checkedItems--;
                                var originalItem = $scope.updatedReceiptItemMap[item.receiptItemId];
                                item.quantity = originalItem.quantity;
                                item.toRefund = false;
                            }
                        });
                        $scope.toRefund = false;
                    } else {
                        _.each($scope.selectedTransaction.items, function (item) {
                            if (item._isRefundable && item.isRefunded === false) {
                                $scope.checkedItems++;
                                item.quantity = 0;
                                item.toRefund = true;
                            }
                        });
                        $scope.toRefund = true;
                    }

                    $scope.updatedResult = calculateReceipt($scope.selectedTransaction);
                    calculateRefundDetail();
                };

                var isItemRefundable = function (item) {
                    var ignore = ['giftcard.create', 'giftcard.reload', 'loyalty.reload', 'loyalty.create'];
                    return !(ignore.indexOf(item.subtype) >= 0);
                };

                $scope.toggleForRefund = function (item) {
                    if (item.toRefund === true) {
                        $scope.checkedItems--;
                        var originalItem = $scope.updatedReceiptItemMap[item.receiptItemId];
                        item.quantity = originalItem.quantity;
                        item.toRefund = false;
                        var isOtherSelectedForRefund = false;
                        _.each($scope.selectedTransaction.items, function (transItem) {
                            if (transItem.itemIndex != item.itemIndex && item.index === transItem.index) {
                                transItem.quantity = $scope.updatedReceiptItemMap[transItem.receiptItemId].quantity;
                                transItem.toRefund = false;
                            } else if (transItem.itemIndex != item.itemIndex && item.index != transItem.index) {
                                if (transItem.toRefund == true) {
                                    isOtherSelectedForRefund = true;
                                }
                            }
                        });

                        for (var transItem in $scope.selectedTransaction.items) {
                            if ($scope.selectedTransaction.items[transItem].toRefund == true) {
                                isOtherSelectedForRefund = true;
                                break;
                            }
                        }

                        if (isOtherSelectedForRefund == false) {
                            $scope.toRefund = false;
                        }
                    } else {
                        $scope.checkedItems++;
                        item.quantity = 0;
                        item.toRefund = true;

                        _.each($scope.selectedTransaction.items, function (transItem) {
                            if (transItem.itemIndex != item.itemIndex && item.index == transItem.index) {
                                transItem.quantity = 0;
                                transItem.toRefund = true;
                            }
                        });

                        $scope.toRefund = true;
                    }

                    $scope.updatedResult = calculateReceipt($scope.selectedTransaction);
                    calculateRefundDetail();
                };

                $scope.updateRefundTransaction = function (item, modification) {
                    // Add item/ increase refund quantity  to refund transactions
                    if (modification === 'minus') {
                        if (item.quantity == $scope.updatedReceiptItemMap[item.receiptItemId].quantity) {
                            $scope.checkedItems++;
                        }
                        if (item.isRefunded === false && item.quantity > 0) {
                            item.toRefund = true;
                            item.quantity--;
                            _.each($scope.selectedTransaction.items, function (transItem) {
                                if (transItem.itemIndex != item.itemIndex && item.index == transItem.index) {
                                    transItem.quantity--;
                                    transItem.toRefund = true;
                                }
                            });
                            $scope.toRefund = true;
                        }
                    }

                    // Remove item/ reduce refund quantity from refund transactions
                    if (modification === 'plus') {
                        if (item.isRefunded === false && item.quantity < $scope.updatedReceiptItemMap[item.receiptItemId].quantity - 1) {
                            item.toRefund = true;
                            $scope.toRefund = true;
                        } else {
                            $scope.checkedItems--;
                            item.toRefund = false;
                            $scope.toRefund = false;
                        }
                        _.each($scope.selectedTransaction.items, function (transItem) {
                            if (transItem.itemIndex != item.itemIndex && item.index == transItem.index) {
                                transItem.toRefund = item.toRefund;
                                transItem.quantity++;
                            } else if (transItem.itemIndex != item.itemIndex && item.index != transItem.index) {
                                if (transItem.toRefund === true) {
                                    $scope.toRefund = true;
                                }
                            }
                        });

                        item.quantity++;
                    }

                    $scope.updatedResult = calculateReceipt($scope.selectedTransaction);
                    calculateRefundDetail();
                };

                var calculateRefundDetail = function () {
                     $scope.refundDetail = {
                        payments: {
                            subTotalAmount: 0,
                            taxAmount: 0,
                            totalAmount: 0,
                            totalMeals: 0
                        },
                        tenderAmounts: {
                            remainingBalance: 0,
                            loyaltyEarned: 0
                        }
                    };

                    var originalSubTotal = new Decimal($scope.originalResult.payments.subTotalAmount);
                    var originalTax = new Decimal($scope.originalResult.payments.taxAmount);
                    var originalTotal = new Decimal($scope.originalResult.payments.totalAmount);
                    var originalTotalMeals = new Decimal($scope.originalResult.payments.totalMeals);
                    var originalRemainingBalance = new Decimal($scope.originalResult.tenderAmounts.remainingBalance);
                    var originalLoyaltyEarned = new Decimal($scope.originalResult.tenderAmounts.loyaltyEarned);

                    var updatedSubTotal = new Decimal($scope.updatedResult.payments.subTotalAmount);
                    var updatedTax = new Decimal($scope.updatedResult.payments.taxAmount);
                    var updatedTotal = new Decimal($scope.updatedResult.payments.totalAmount);
                    var updatedTotalMeals = new Decimal($scope.updatedResult.payments.totalMeals);
                    var updatedRemainingBalance = new Decimal($scope.updatedResult.tenderAmounts.remainingBalance);
                    var updatedLoyaltyEarned = new Decimal($scope.updatedResult.tenderAmounts.loyaltyEarned);

                    var refundSubTotal = originalSubTotal.minus(updatedSubTotal);
                    var refundTax = originalTax.minus(updatedTax);
                    var refundTotal = originalTotal.minus(updatedTotal);
                    var refundTotalMeals = originalTotalMeals.minus(updatedTotalMeals);
                    var refundRemainingBalance = originalRemainingBalance.minus(updatedRemainingBalance);
                    var refundLoyaltyEarned = originalLoyaltyEarned.minus(updatedLoyaltyEarned);

                    $scope.refundDetail.payments.subTotalAmount = refundSubTotal.toNearest(SharedDataService.baseDollar).toNumber();
                    $scope.refundDetail.payments.taxAmount = refundTax.toNearest(SharedDataService.baseDollar).toNumber();
                    $scope.refundDetail.payments.totalAmount = refundTotal.toNearest(SharedDataService.baseDollar).toNumber();
                    $scope.refundDetail.payments.totalMeals = refundTotalMeals.toNumber();
                    $scope.refundDetail.tenderAmounts.remainingBalance = refundRemainingBalance.toNearest(SharedDataService.baseDollar).toNumber();
                    $scope.refundDetail.tenderAmounts.loyaltyEarned = refundLoyaltyEarned.toNumber();

                    var effectiveDecimalValues = calculateEffectiveDecimalValues();
                    var tendersWithRatio = calculateTenderTypeRatios($scope.selectedTransaction);
                    calculateRefundAmountsByTenderType(tendersWithRatio, effectiveDecimalValues);

                    $scope.refundAmountsByTenderType = tendersWithRatio;

                    $scope.refundTransaction = buildRefundTransaction($scope.selectedTransaction.items, $scope.selectedTransaction.tenders, $scope.selectedTransaction);
                };

                var calculateEffectiveDecimalValues = function () {
                    var originalEffectiveValue = new Decimal($scope.originalResult.tenderAmounts.remainingBalance);
                    var updatedEffectiveValue = new Decimal($scope.updatedResult.tenderAmounts.remainingBalance);
                    var diffEffectiveValue = originalEffectiveValue.minus(updatedEffectiveValue);
                    var ratioEffectiveValue = (originalEffectiveValue.isZero())
                        ? new Decimal(0)
                        : updatedEffectiveValue.dividedBy(originalEffectiveValue);

                    return {
                        original: originalEffectiveValue,
                        updated: updatedEffectiveValue,
                        diff: diffEffectiveValue,
                        ratio: ratioEffectiveValue
                    };
                };
                var calculateTenderTypeRatios = function (transaction) {
                    var tenders = [{
                        name: 'cash',
                        type: 'CASH',
                        amount: transaction.cashAmount
                    }, {
                        name: 'credit',
                        type: 'CREDIT',
                        amount: transaction.creditCardAmount
                    }, {
                        name: 'debit',
                        type: 'DEBIT',
                        amount: transaction.debitCardAmount
                    }, {
                        name: 'mealEq',
                        type: 'LOYALTY',
                        amount: transaction.mealEqAmount,
                        meals: transaction.mealPlanCount
                    }, {
                        nane: 'dcb',
                        type: 'DCB',
                        amount: transaction.dcbAmount
                    }];

                    Calculate.normalize(tenders, 'amount');

                    var tenderMap = {};
                    _.each(tenders, function (tender) {
                        tenderMap[tender.name] = tender;
                    });

                    return tenderMap;
                };
                var calculateRefundAmountsByTenderType = function (tendersWithRatio, effectiveDecimalValues) {
                    var decimalDiff = effectiveDecimalValues.diff;
                    _.each(tendersWithRatio, function (tender) {
                        var decimalPercent = new Decimal(tender.normalized); // expected range: 0-100
                        var decimalAmountToRefund = decimalPercent.times(decimalDiff);
                        tender.amountToRefund = decimalAmountToRefund.toNearest(SharedDataService.baseDollar).toNumber();

                        if (tender.name === 'mealEq') {
                            var mealsToAmountRatio = (tender.amount > 0)? tender.meals / tender.amount : 0;
                            var deciamlMealsToAmountRatio = new Decimal(mealsToAmountRatio);
                            var deciamlMealsToRefund = decimalAmountToRefund.times(deciamlMealsToAmountRatio);
                            tender.mealsToRefund = deciamlMealsToRefund.dividedBy(new Decimal(100)).round().toNumber();
                        }
                    });

                    var loyaltyEarnedToRefundRatio = (new Decimal(1)).minus(effectiveDecimalValues.ratio);
                    var decimalLoyaltyEarned = new Decimal($scope.selectedTransaction.loyaltyEarned);
                    var decimalLoyaltyEarnedToRefund = decimalLoyaltyEarned.times(loyaltyEarnedToRefundRatio);

                    var loyaltyEarnedTender = {
                        name: 'loyaltyEarned',
                        type: 'LOYALTY',
                        amount: decimalLoyaltyEarned.toNumber(),
                        amountToRefund: decimalLoyaltyEarnedToRefund.round().toNumber()
                    };
                    tendersWithRatio.loyaltyEarned = loyaltyEarnedTender;

                    return tendersWithRatio;
                };

                var buildRefundTransaction = function (items, tenders, selectedTransaction) {
                    var refundItems = _buildRefundTransactionItems(items);
                    var refundTenders = _buildRefundTransactionTenders(tenders);

                    // TODO: loyalty reclaimed for partial refunds
                    var refund = {
                        transactionId: $scope.selectedTransaction.transactionId,
                        description: $scope.selectedTransaction.description,
                        toRefundServicePeriodBalance: true,
                        refundType: 'REFUND', // can it be void too?
                        userId: Security.getUser().userId,
                        cashierShiftId: $scope.shift.cashierShiftId,
                        subtotalCents: new Decimal($scope.refundDetail.payments.subTotalAmount).times(new Decimal(100)).toNearest(SharedDataService.baseCent).toNumber(),
                        taxCents: new Decimal($scope.refundDetail.payments.taxAmount).times(new Decimal(100)).toNearest(SharedDataService.baseCent).toNumber(),
                        totalCents: new Decimal($scope.refundDetail.payments.totalAmount).times(new Decimal(100)).toNearest(SharedDataService.baseCent).toNumber(),
                        mealPlanCount: Math.round($scope.refundDetail.payments.totalMeals),
                        items: refundItems,
                        tenders: refundTenders
                    };

                    return refund;
                };
                var _buildRefundTransactionItems = function (items) {
                    var refundItems = [];
                    var hasFiitTender = $scope.hasFiitmpsTender($scope.selectedTransaction.tenders);
                    _.each(items, function (item) {
                        if (item.toRefund === true) {
                            var originalQuantity = $scope.updatedReceiptItemMap[item.receiptItemId].quantity;
                            var updatedQuantity = item.quantity;
                            var quantityToRefund = originalQuantity - updatedQuantity;

                            if (quantityToRefund > 0) {
                                var item2 = {
                                    transactionItemId: item.receiptItemId,
                                    quantity: quantityToRefund
                                };

                                /**
                                ** Commented By Akash Mehta
                                ** This block of code is to just update the items on the UI.
                                ** Currently we calculate the item amounts on the backend.
                                ** However on the frontend, we are showing different amounts
                                ** than what is expected. Hence we are just updating the prices
                                ** on the front end.
                                **/
                                if (hasFiitTender) {
                                    item2.price = item.price;
                                    item2.taxAmount = item.taxAmount;
                                    item2.total = item.total;
                                }
                                refundItems.push(item2);
                            }
                        }
                    });

                    return refundItems;
                };
                var _buildRefundTransactionTenders = function (tenders) {
                    var refundTenders = [];
                    _.each(tenders, function (tender, tenderType) {
                        var patron = $scope.selectedTransaction.patron;

                        var refundTender = {};
                        refundTender.transactionType = tender.type;

                        if (tender.type === 'LOYALTY') {
                            if (patron && patron.mealPlans && patron.mealPlans[0]) {
                                refundTender.patronMealPlan = {
                                    patronMealPlanId: patron.mealPlans[0].patronMealPlanId
                                };
                                refundTender.patron = {
                                    patronId: patron.patronId
                                };
                                refundTender.mealPlan = {
                                    mealPlanId: patron.mealPlans[0].mealPlanId
                                };
                            }
                            refundTender.amountMeals = tender.mealsToRefund;
                        } else {
                            refundTender.amountCents = tender.amountToRefund;
                        }

                        refundTender.transactionTender = angular.copy(tender);

                        if (tender.amountToRefund > 0) {
                            refundTenders.push(refundTender);
                        }
                    });

                    return refundTenders;
                };

                var _buildRefundTransactionAmount = function (refundItems, transactionItems, transaction) {
                    var refundItemMap = {};
                    var refundAmount = new Decimal(0.00);
                    _.each(refundItems, function (item) {
                        refundItemMap[item.transactionItemId] = item.quantity;
                    });

                    _.each(transactionItems, function (item) {
                        // exclude loyalty discount to ensure loyalty points are refunded correctly
                        if (item.toRefund === true && item.subtype != 'loyalty') {
                            refundAmount = refundAmount.plus(item.itemTotalPrice * (refundItemMap[item.receiptItemId] || 0));
                        }
                    });

                    var appliedDeliveryFee = new Decimal(transaction.deliveryFee || 0);
                    var appliedDeliveryTax = new Decimal(transaction.deliveryTax || 0);

                    refundAmount = refundAmount.plus(appliedDeliveryFee).plus(appliedDeliveryTax);

                    return refundAmount.toNearest(SharedDataService.baseDollar).toNumber();
                };
                var _buildUsedTransactionTenders = function (refundTransactions) {
                    var usedTenders = {};
                    _.each(refundTransactions, function (refTrans) {
                        _.each(refTrans.tenders, function (tender) {
                            if (!tender.transactionTender) {
                                return;
                            }

                            if (usedTenders[tender.transactionTender.transactionTenderId] != null) {
                                usedTenders[tender.transactionTender.transactionTenderId].amountCents += tender.amountCents;
                                usedTenders[tender.transactionTender.transactionTenderId].amountMeals += tender.amountMeals;
                            } else {
                                usedTenders[tender.transactionTender.transactionTenderId] = {
                                    amountCents: tender.amountCents || 0,
                                    amountMeals: tender.amountMeals || 0
                                };
                            }
                        });
                    });

                    return usedTenders;
                };

                var _buildRefundAvailableTenders = function (tenders, usedTenders) {
                    var availableTenders = angular.copy(tenders);
                    _.each(availableTenders, function (tender) {
                        if (usedTenders[tender.transactionTenderId] != undefined) {
                            tender.amountCents -= usedTenders[tender.transactionTenderId].amountCents;
                            tender.amountMeals -= usedTenders[tender.transactionTenderId].amountMeals;
                        }
                    });

                    var sortedAvailableTenders = _sortRefundTenders(availableTenders);

                    return sortedAvailableTenders;
                };

                var _sortRefundTenders = function (refundTenders) {
                    var refundTenderTypeMap = {};

                    _.each(refundTenders, function (refundTender) {
                        var tenderType = refundTender.transactionType;

                        if (refundTenderTypeMap[tenderType] == null) {
                            refundTenderTypeMap[tenderType] = [];
                        }
                        refundTenderTypeMap[tenderType].push(refundTender);
                    });

                    var loyaltyTenders = refundTenderTypeMap['MEAL'] || [];
                    var giftCardTenders = refundTenderTypeMap['GIFTCARD'] || [];
                    var creditCardTenders = refundTenderTypeMap['CREDIT'] || [];

                    var cashTenders = refundTenderTypeMap['CASH'] || [];
                    var otherTenders = refundTenderTypeMap['OTHER'] || [];
                    var cashOrOtherTenders = cashTenders.concat(otherTenders);

                    loyaltyTenders = _.sortBy(loyaltyTenders, 'transactionTenderId');
                    giftCardTenders = _.sortBy(giftCardTenders, 'transactionTenderId');
                    creditCardTenders = _.sortBy(creditCardTenders, 'transactionTenderId');
                    cashOrOtherTenders = _.sortBy(cashOrOtherTenders, 'transactionTenderId');

                    var sortedTenders = loyaltyTenders.concat(giftCardTenders).concat(creditCardTenders).concat(cashOrOtherTenders);

                    return sortedTenders;
                };

                var _buildRefundTendersAsPerAmount = function (tenders, refundAmount, transaction) {
                    var refundTenders = [];
                    var refundTransactions = transaction.refundTransactions;
                    var amountYetToRefund = new Decimal(refundAmount).times(100).toNumber();
                    var usedTenders = _buildUsedTransactionTenders(refundTransactions);
                    $scope.refundAmountRoundingCents = 0;
                    // tenders.sort(sortTenders);
                    var availableTenders = _buildRefundAvailableTenders(tenders, usedTenders);

                    _.each(availableTenders, function (tender, tenderType) {
                        if (amountYetToRefund > 0 && (tender.amountCents > 0 || tender.amountMeals > 0)) {
                            var patron = transaction.patron;

                            var refundTender = {};
                            refundTender.transactionType = tender.transactionType;
                            refundTender.amountMeals = 0;
                            refundTender.amountCents = 0;

                            var amountToRefund;
                            if (tender.transactionType === 'MEAL') {
                                if (patron && patron.mealPlans.length > 0) {
                                    let pmp = patron.mealPlans.find((mp) => {
                                        return mp.patronMealPlanId == tender.patronMealPlanId && mp.mealPlanId == tender.mealPlanId;
                                    });
                                    if (pmp) {
                                        refundTender.patronMealPlan = {
                                            patronMealPlanId: pmp.patronMealPlanId
                                        };
                                        refundTender.patron = {
                                            patronId: patron.patronId
                                        };
                                        refundTender.mealPlan = {
                                            mealPlanId: pmp.mealPlanId
                                        };
                                    }
                                }

                                var amountCentsValue = tender.amountMeals;
                                amountToRefund = Math.min(amountYetToRefund, amountCentsValue);

                                refundTender.amountMeals = amountToRefund;

                                amountYetToRefund -= amountToRefund;
                            } else {
                                var minimumDenomination = 1;
                                if (tender.transactionType === 'CASH') {
                                    minimumDenomination = 5;
                                }

                                /**
                                 * refunding other tender that was originally from
                                 * a DCB meal plan will have a patronId, mealPlanId,
                                 * and patronMealPlanId
                                 */
                                if (tender.transactionType === 'OTHER') {
                                    if (patron && patron.mealPlans.length > 0) {
                                        let pmp = patron.mealPlans.find((mp) => {
                                            return mp.patronMealPlanId == tender.patronMealPlanId && mp.mealPlanId == tender.mealPlanId;
                                        });
                                        refundTender.patronMealPlan = {
                                            patronMealPlanId: pmp.patronMealPlanId
                                        };
                                        refundTender.patron = {
                                            patronId: patron.patronId
                                        };
                                        refundTender.mealPlan = {
                                            mealPlanId: pmp.mealPlanId
                                        };
                                    }

                                    /**
                                    ** Commented By Akash Mehta
                                    ** This block of code is to ensure that the database is up to date as well.
                                    ** Currently we only support full refunds for any transaction which has FIIT tender
                                    ** Hence we are blindly assingning the mealAmount to the refund meal Amount.
                                    ** This will change when we have partial refunds for FIIT.
                                    **/
                                    if (tender.transactionTenderDetail
                                        && tender.transactionTenderDetail.otherType === 'fiitmps') {
                                        refundTender.amountMeals = tender.amountMeals;
                                    }
                                }
                                // "Soft Minimum"
                                // The soft minimum is similar to finding the absolute minimum of "amount of refund" and "total value of transaciton",
                                // but also ensure all cases of cash rounding are covered (whether the cash rounding is over or below the sales) .
                                var roundedAmountYetToRefund = Math.round(amountYetToRefund / minimumDenomination) * minimumDenomination;
                                var roundedTenderAmountCents = Math.round(tender.amountCents / minimumDenomination) * minimumDenomination;
                                var roundedAmountToRefund = Math.min(roundedAmountYetToRefund, roundedTenderAmountCents);

                                var unroundedAmountToRefund = 0;
                                if (roundedAmountYetToRefund > roundedTenderAmountCents) {
                                    unroundedAmountToRefund = tender.amountCents;
                                } else {
                                    unroundedAmountToRefund = amountYetToRefund;
                                }

                                $scope.refundAmountRoundingCents = unroundedAmountToRefund - roundedAmountToRefund;
                                // End "Soft Minimum"

                                amountToRefund = roundedAmountToRefund;
                                refundTender.amountCents = amountToRefund;

                                amountYetToRefund -= amountToRefund;
                            }

                            refundTender.transactionTender = angular.copy(tender);
                            refundTender.terminalResponse = tender.terminalResponse;
                            refundTender.giftCard = tender.giftCard;

                            refundTenders.push(refundTender);
                        }
                    });

                    return refundTenders;
                };

                $scope.startRefundTransaction = function (transaction) {
                    var findAcrossOrganization = ($scope.transactionSearch.transactionId) ? true : false;
                    CashierShift.transactionResult({
                        transactionId: transaction.transactionId,
                        findAcrossOrganization: findAcrossOrganization
                    }, function (transactionResponse) {
                        var refundItems = _buildRefundTransactionItems(transaction.items);
                        var refundAmount = _buildRefundTransactionAmount(refundItems, transaction.items, transaction);
                        var refundTenders = _buildRefundTendersAsPerAmount(transaction.tenders, refundAmount, transaction);

                        transactionResponse.items = refundItems;
                        transactionResponse.cashierShiftId = $scope.shift.cashierShiftId;
                        transaction.refundAmount = refundAmount;
                        transactionResponse.tenders = refundTenders;
                        transactionResponse.refundAmountRoundingCents = $scope.refundAmountRoundingCents;

                        var modalInstance = $modal.open({
                            templateUrl: 'pos/refund/pos.transaction.refund.tpl.html',
                            controller: 'PosTransactionRefundCtrl',
                            windowClass: 'smb-pos smb-pos__void-modal',
                            backdrop: 'static',
                            animation: false,
                            resolve: {
                                patron: function () {
                                    return transaction.patron;
                                },
                                isLucova: function () {
                                    // sourceId 0 = FIIT/Nown tx, 1 = Lucova, 2 = Preorder
                                    return transaction.sourceId > 0;
                                },
                                transaction: function () {
                                    return transactionResponse;
                                },
                                description: function () {
                                    return '';
                                },
                                mustRefundServicePeriod: function () {
                                    return true;
                                },
                                terminalResponses: function () {
                                    return transaction.terminalResponses;
                                },
                                refundType: function () {
                                    var transactionItemsCount = 0;
                                    for (var item of transaction.items) {
                                        transactionItemsCount += item.quantity;
                                    }

                                    if (transactionItemsCount === 0) {
                                        return 'refund';
                                    } else {
                                        return 'refund_partial';
                                    }
                                },
                                alteredTransactionItems: function () {
                                    return transaction.items;
                                },
                                hasFiitMpsTender: function () {
                                    return $scope.hasFiitmpsTender(transaction.tenders);
                                }
                            }
                        });

                        modalInstance.result.then(function () {
                            $scope.selectedTransaction = undefined;
                            $scope.searchTransactions();
                        });

                        // Function to call the review refund modal after selecting a tender.
                        // Commented for now

                        /*
                        var transRefundTenderModalInstance = $modal.open({
                            templateUrl: 'pos/refund/pos.transaction.refund.tender.tpl.html',
                            controller: 'PosTransactionRefundTenderCtrl',
                            windowClass: 'smb-pos',
                            backdrop: 'static',
                            animation: false,
                            resolve: {
                                $modal : function(){
                                    return $modal;
                                },
                                transaction: function () {
                                    return transaction;
                                }

                            }
                        });


                        transRefundTenderModalInstance.result.then(function(refTenders){
                            var refundTenders = [];
                            _.each(refTenders, function(tender){
                                _.each(transactionResponse.tenders, function(transTender){
                                    if(transTender.transactionTenderId == tender.transactionTenderId){
                                        var refundTen = {};
                                        refundTen.transactionType = transTender.transactionType;
                                        refundTen.patron = transTender.patron;
                                        refundTen.amountMeals = transTender.amountMeals;
                                        refundTen.amountCents = tender.selectedAmountCents;
                                        refundTen.transactionTender = transTender;
                                        refundTenders.push(refundTen);
                                    }
                                });
                            });

                            modalInstance = $modal.open(transRefundObj);

                            modalInstance.result.then(function () {
                                $scope.selectedTransaction = undefined;
                                $scope.searchTransactions();
                            });*/


                        // Add dismissed refresh section;
                    }, function () {
                    });
                };

                var STAGES = $scope.STAGES = {
                    INITIAL: {
                        name: 'initial',
                        showProgress: false
                    },
                    LUCOVA: {
                        name: 'lucova',
                        showProgress: true
                    },
                    TERMINAL: {
                        name: 'terminal',
                        showProgress: true
                    },
                    POS: {
                        name: 'pos',
                        showProgress: true
                    },
                    SUCCESS: {
                        name: 'success',
                        showProgress: false
                    },
                    FAIL: {
                        name: 'fail',
                        showProgress: false
                    },
                };

                $scope.currentStage = {};
                $scope.setCurrentStage = function (stage, data) {
                    var currentStage = angular.copy(stage);

                    if (data) {
                        currentStage.data = data;
                    }

                    $scope.currentStage = currentStage;
                };

                $scope.refundLucovaTransaction = function (refundType, transaction) {
                    // sourceId 0 = FIIT/Nown, 1 = Lucova, 2 = Preorder
                    return (transaction.sourceId > 0) ?
                        $scope.executeLucovaRefund(refundType, transaction) :
                        Promise.resolve();
                };
                $scope.executeLucovaRefund = function (refundType, transaction ) {
                    // TODO: update parameters based on actual Lucova backend implementation
                    $scope.setCurrentStage(STAGES.LUCOVA);

                    // TODO
                    return Lucova.manager().refundUserTransaction({
                        transaction_id: transaction.transactionId,
                    }, {}).$promise;
                };

                // Attach refundPosTransaction to $scope to allow for unit testing
                $scope.refundCreditDebitTransaction = function (refundType, transaction) {
                    // TODO
                    return (transaction.terminalResponses && transaction.terminalResponses.length)?
                        $scope.executeCreditDebitRefund(refundType, transaction.terminalResponses) :
                        Promise.resolve();
                };

                $scope.executeCreditDebitRefund = function (refundType, terminalResponses) {
                    // todo: this needs to be updated
                    $scope.$apply(function () {
                        $scope.setCurrentStage(STAGES.TERMINAL);
                    });

                    /* Note:

                    A transaction can now have multiple terminal responses.
                    Although the database design allows for this we haven't yet
                    added support for partial approved payments. Which would result in
                    the before mentioned 'multiple' terminal responses.

                    In the future we'll have to iterate through each of the terminal
                    responses and void each one.
                    */
                    var terminalResponseObj = terminalResponses[0];

                    return Locations.getLocationPrinters({'locationId': SmbPosService.shift.locationId})
                        .$promise.then(function (printerResponse) {
                            if (printerResponse.posStation == null) {
                                markCreditDebitRefundFail({
                                    reason: 'transactions.refund.fail.terminal.notFound'
                                });
                            }

                            var terminalConfig = printerResponse.posStation.cardTerminalProperties;
                            var terminal = CardTerminal.init(terminalConfig);
                            var terminalResponse = terminal.parseResponse(
                                JSON.parse(terminalResponseObj.cardTerminalResponse));

                            if (refundType === 'void') {
                                return terminal.creditVoid(terminalResponseObj.cardTransactionId).then(function (voidResponse) {
                                    PrintService.printCreditCardReturnRefundReceipt(voidResponse[0], printerResponse);
                                    return voidResponse[0];
                                });
                            } else if (refundType === 'refund') {
                                var amountToRefund = parseFloat(terminalResponse.approvedAmount);

                                return terminal.creditReturn(amountToRefund, {
                                    transactionId: terminalResponseObj.cardTransactionId
                                }).then(function (returnResponse) {

                                    PrintService.printReceipt(printerResponse.posStation, null, [returnResponse[0]], false, PrintType.ALL);
                                    return returnResponse[0];
                                });
                            } else {
                                return Promise.reject({});
                            }
                        }).catch(function (error) {
                            markCreditDebitRefundFail(error);
                            throw error;
                        });
                };
                var markCreditDebitRefundFail = function (exception) {
                    if ($scope.currentStage.name === 'fail') {
                        return;
                    }

                    exception = exception || {};

                    var failMessageName = ($scope.refundType === 'void')? 'transaction-void-fail' : 'transaction-refund-fail';
                    var failMessage = ($scope.refundType === 'void')? 'transactions.void.fail.terminal' : 'transactions.refund.fail.terminal';
                    PosAlertService.showAlertByName(failMessageName, {
                        message: failMessage
                    });

                    $scope.setCurrentStage(STAGES.FAIL, exception);
                };

                $scope.refundPosTransaction = function (refundType, refundTerminalResponse, refundTransaction, pinObj) {
                    $scope.setCurrentStage(STAGES.POS);

                    if ($scope.selectedTransaction.terminalResponse && !refundTerminalResponse) {
                        markCreditDebitRefundFail();
                    }

                    // TODO: should this be more strict and must have a found credit/debit tender?
                    var creditDebitRefundTender = _.find(refundTransaction.tenders, function (tender) {
                        return tender.transactionType === 'CREDIT' || tender.transactionType === 'DEBIT';
                    });
                    if (creditDebitRefundTender) {
                        creditDebitRefundTender.terminalResponse = JSON.stringify(refundTerminalResponse);
                    }

                    if (pinObj && pinObj.user && pinObj.user.userId) {
                        refundTransaction.userId = pinObj.user.userId;
                    }

                    return CashierShift.refundTransaction({}, refundTransaction, function (response) {
                        $scope.setCurrentStage(STAGES.SUCCESS);

                        var successMessageName = ($scope.refundType === 'void')? 'transaction-void-success' : 'transaction-refund-success';
                        PosAlertService.showAlertByName(successMessageName, {
                            modalCallback: function () {
                                $scope.selectedTransaction.refundStatus = 'REFUND';
                                $scope.selectedTransaction.isRefunded = true;
                            }
                        });
                    }, function (error) {
                        var errorData = error.data || {};
                        var exception = errorData.exception || {};

                        $scope.setCurrentStage(STAGES.FAIL, exception);

                        var failMessageName = ($scope.refundType === 'void')? 'transaction-void-fail' : 'transaction-refund-fail';
                        PosAlertService.showAlertByName(failMessageName, {
                            message: exception.message
                        });
                    }).$promise;
                };

                $scope.refund = function () {
                    var refundType = 'refund';
                    var requestedPermission = 'pos:refunds';
                    var params = {
                        callback: function (pinObj) {
                            $scope.refundLucovaTransaction(refundType, $scope.selectedTransaction)
                                .then(function () {
                                    return $scope.refundCreditDebitTransaction(refundType, $scope.selectedTransaction);
                                })
                                .then(function (refundTerminalResponse) {
                                    return $scope.refundPosTransaction(refundType, refundTerminalResponse, $scope.refundTransaction, pinObj);
                                })
                                .then($scope.searchTransactions);
                        },
                        errorCallback: function (error) {
                            if (error) {
                                var data = error.data || {};
                                var exception = data.exception || {};

                                PosAlertService.showAlertByName('pincode-fail', {
                                    message: exception.message || ''
                                });
                            }
                        },
                        requestedPermission: requestedPermission,
                    };

                    $scope.$emit('PincodeAuthentication:Required', params);

                    return params;
                };

                $scope.init = function () {
                    $scope.searchTransactions();
                };
                $scope.init();
            }
        ]);
};
