'use strict';

const angular = require('angular');
let freshideasReports = angular.module('freshideas.reports', []);

require('./reports.base.js')(freshideasReports);
require('./reports.sidenav.js')(freshideasReports);
require('./reports.activePatrons.js')(freshideasReports);
require('./reports.patronMealPlanHistory.js')(freshideasReports);
require('./reports.itemSales.js')(freshideasReports);
require('./reports.advancedItemSales.js')(freshideasReports);
require('./reports.patronSpend.js')(freshideasReports);
require('./reports.participationRate.js')(freshideasReports);
require('./reports.mealPlanSales.js')(freshideasReports);
require('./reports.employeeShifts.js')(freshideasReports);

require('./reports.overview.js')(freshideasReports);
require('./reports.transaction.js')(freshideasReports);
require('./reports.summary.js')(freshideasReports);
require('./reports.sales.js')(freshideasReports);
require('./reports.dailySalesMealsServed.js')(freshideasReports);
require('./reports.patronCounts.js')(freshideasReports);
require('./reports.patronActivity.js')(freshideasReports);
require('./reports.endOfShift.js')(freshideasReports);
require('./reports.depositRefund.js')(freshideasReports);
require('./reports.sessionAudit.js')(freshideasReports);
require('./reports.locationRevenue.js')(freshideasReports);
require('./reports.mobileTransactions.js')(freshideasReports);
require('./reports.lossPrevention.js')(freshideasReports);
require('./reports.discounts.js')(freshideasReports);
require('./reports.discounts.subtable.js')(freshideasReports);
require('./reports.labour.js')(freshideasReports);
require('./reports.giftCard.js')(freshideasReports);
require('./reports.giftCard.subtable.js')(freshideasReports);
require('./reports.singleTransaction.js')(freshideasReports);
require('./reports.inventoryStock.js')(freshideasReports);
export default freshideasReports;
