'use strict';


const angular = require('angular');
const ngResource = require('angular-resource');

export default angular.module('freshideas.resources.menu', [ngResource])
    .service('Menu', [
        '$resource',
        'EnvConfig',
        'SharedDataService',
        function (
        $resource,
        EnvConfig,
        SharedDataService) {
        var Menu = $resource(EnvConfig.host + '/freshideas/web/menu', {}, {
            'itemSearch': {method: 'GET', url: EnvConfig.host + '/freshideas/web/menu/itemSearch'},
            'menuGrid': {method: 'GET', url: EnvConfig.host + '/freshideas/web/menu/grid'},
            'menuGridV2': {method: 'GET', url: EnvConfig.host + '/freshideas/web/menu/gridV2'},
            'lookupUpc': {method: 'GET', url: EnvConfig.host + '/freshideas/web/menu/upcLookup'},
            'availableMenusForCompany': {method: 'GET', url: EnvConfig.host + '/freshideas/web/menu/availableMenusForCompany', isArray: true},
            'activeMenusForCompany': {method: 'GET', url: EnvConfig.host + '/freshideas/web/menu/activeMenusForCompany', isArray: true},
            'addMenuItem': {method: 'POST', url: EnvConfig.host + '/freshideas/web/menu/item'},
            'updateMenuItem': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/menu/item'},
            'associateItems': {method: 'POST', url: EnvConfig.host + '/freshideas/web/menu/associations', isArray: true},
            'updatePageOrder': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/menu/updatePageOrder'},
            'updatePageName': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/menu/updatePageName', isArray: true},

            'uploadMenuItemImage': {method: 'POST', url: EnvConfig.host + '/freshideas/web/menu/item/images'},
            'updateMenuItemImage': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/menu/item/images'},
            'deleteMenuItemImage': {method: 'DELETE', url: EnvConfig.host + '/freshideas/web/menu/item/images/:menuImageId'},
            'updateMobileMenu': {method: 'GET', url: EnvConfig.host + '/freshideas/web/menu/updateMobileMenu'},
            'updateMenuItemAvailability': {method: 'POST', url: EnvConfig.host + '/freshideas/web/menu/item/available'},

            'searchMasterProductList': {method: 'GET', url: EnvConfig.host + '/freshideas/web/menu/searchMasterProductList', isArray: true},
            'copyItemFromMenu': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/menu/item/copyFromMenu'},
            'deleteItemFromMenu': {method: 'POST', url: EnvConfig.host + '/freshideas/web/menu/deleteItemFromMenu'},
            'deleteItemFromCategory': {method: 'POST', url: EnvConfig.host + '/freshideas/web/menu/deleteItemFromCategory'},

            'createNewMenu': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/menu/create'},
            'updateMenu': {method: 'POST', url: EnvConfig.host + '/freshideas/web/menu/update'},

            'unassignPrintersFromAssociatedItems': {method: 'POST', url: EnvConfig.host + '/freshideas/web/menu/unassignPrinter'},
            'toggleKitchenPrintingForAllItems': {method: 'POST', url: EnvConfig.host + '/freshideas/web/menu/toggleKitchenPrintingForAllItems'},
            'activeMenusAndCompaniesMap': {method: 'GET', url: EnvConfig.host + '/freshideas/web/menu/activeMenusAndCompaniesMap'},
            'getLoyaltyStepItems': {method: 'GET', url: EnvConfig.host + '/freshideas/web/menu/loyaltyStepItems'},
        });

        // NOTE: This menu cache is being shared in products and in POS screen. Be careful when working with this cache
        // to not contaminate the data. Making a clone on individual item if necessary.
        Menu.getMenuGrid = function (params, {useAndUpdateCache = true, checkForMenuPeriod = true} = {}) {
            let menuId = params.menuId || SharedDataService.getDefaultMenu().menuId;
            let menuIdKey = `menuId=${menuId}`;
            let menuToCache = {};

            if (!params.languageId || params.languageId === 0) {
                params.languageId = SharedDataService.preferredLanguage.languageId;
            }

            if (useAndUpdateCache && SharedDataService.cachedProducts[menuIdKey] && SharedDataService.cachedProductsTimestamp[menuIdKey]) {
                params.cacheTimestamp = SharedDataService.cachedProductsTimestamp[menuIdKey][params.languageId];
            }

            return new Promise((resolve, reject) => {
                let cachedProducts = SharedDataService.cachedProducts[menuIdKey];
                let hasValidCache = cachedProducts && cachedProducts[params.languageId];
                let canUseCache = hasValidCache && useAndUpdateCache;

                if (canUseCache) {
                    // Resovle with the last cached menu. Any changes that are detected
                    // after the network request will be available at the start of
                    // the next transaction
                    resolve(cachedProducts[params.languageId]);
                }

                Menu.menuGrid(params).$promise.then(function (response) {
                    if (response.useCache) {
                        // Nothing has changed in the menu, in addition the response will not actually contain a menu
                        // This return is important. Without it, the cached menu could be overwritten
                        let cachedProducts = SharedDataService.cachedProducts[menuIdKey];
                        let hasValidCache = cachedProducts && cachedProducts[params.languageId];

                        if (hasValidCache) {
                            return resolve(cachedProducts[params.languageId]);
                        } else {
                            return resolve(response);
                        }
                    }

                    let cachedProducts = {};
                    let cachedProductsTimestamp = {};

                    cachedProducts[menuIdKey] = {};
                    cachedProducts[menuIdKey][params.languageId] = response;

                    cachedProductsTimestamp[menuIdKey] = {};
                    cachedProductsTimestamp[menuIdKey][params.languageId] = response.newCacheTimestamp;

                    menuToCache.cachedProducts = cachedProducts;
                    menuToCache.cachedProductsTimestamp = cachedProductsTimestamp;

                    // ToDo: Clear Grid0 Cache
                    SharedDataService.addToCachedProducts(menuToCache);

                    resolve(response);
                }).catch(reject);
            });
        };

        /**
        **  Function to parse an item and store it in Memory against an item's upc code.
        **  If an item does not have a upc code, it will not be stored.
        **  Every item/category is stored with all of its children.
        **  We also parse through all childrens and store them as well if they have a upc
        **/
        Menu.processItem = function (entry) {
            var upc = entry.upc;
            if (upc) {
                if (!SharedDataService.menuItemsMap[upc] && Object.keys(SharedDataService.menuItemsMap).length >= 4000) {
                    return;
                }

                SharedDataService.menuItemsMap[upc] = entry;
                if (entry.children) {
                    _.each(entry.children, function (child) {
                        Menu.processItem(child);
                    });
                }
            }
        };

        return Menu;
    }]);
