'use strict';


module.exports = function (posModule) {
    posModule.controller('PosTenderReceiptsCtrl', [
        '$scope',
        'merchantOnly',
        'customerOnly',
        'printAll',
        function (
            $scope,
            merchantOnly,
            customerOnly,
            printAll) {
            $scope.merchantOnly = function () {
                merchantOnly();

                $scope.$close('done');
            };

            $scope.customerOnly = function () {
                customerOnly();

                $scope.$close('done');
            };

            $scope.printAll = function () {
                printAll();

                $scope.$close('done');
            };

            $scope.closeModal = function () {
                $scope.$dismiss();
            };

        }]);
};
