'use strict';


module.exports = function (posModule) {
    posModule.controller('TipsController', [
        '$scope',
        '$modalInstance',
        'tipFields',
        function ($scope,
            $modalInstance,
            tipFields) {
            $scope.close = function () {
                $modalInstance.close();
            };
            $scope.tipFields = tipFields;

            $scope.tipPercentage = function () {
                var tipPercentage = ($scope.tipFields.tip_amount
                    / $scope.tipFields.full_amount) * 100;
                return Math.round(tipPercentage);
            };
        }
    ]);
};
