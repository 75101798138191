'use strict';

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosGiftCardActivationCtrl', [
        '$scope',
        'CashierShift',
        'activatedGiftCards',
        'transaction',
        function (
            $scope,
            CashierShift,
            activatedGiftCards,
            transaction) {

            var EMAIL_STATUS = $scope.EMAIL_STATUS = {
                NOT_SEND: 0,
                SENDING: 1,
                SENT: 2,
                FAILED: -1
            };

            $scope.activatedGiftCards = activatedGiftCards;

            $scope.options = {
                carousel: {
                    interval: 15000,
                    noWrap: false,
                }
            };

            $scope.receiptEmailStatus = EMAIL_STATUS.NOT_SEND;
            $scope.emailReceipt = function () {
                var emailReceipt = angular.copy(transaction);
                emailReceipt.guestEmail = $scope.guestEmail;

                $scope.receiptEmailStatus = EMAIL_STATUS.SENDING;
                CashierShift.emailReceipt({}, emailReceipt, function () {
                    $scope.receiptEmailStatus = EMAIL_STATUS.SENT;
                }, function () {
                    $scope.receiptEmailStatus = EMAIL_STATUS.FAILED;
                });
            };
        }
    ]);
};
