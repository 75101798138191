'use strict';

const angular = require('angular');

export default angular
    .module('freshideas.resources.fiitMps', [])
    .factory('FiitMpsResource', [
        '$http',
        '$q',
        '$resource',
        'CurrentSession',
        'EnvConfig',
        'Pure',
        function (
            $http,
            $q,
            $resource,
            CurrentSession,
            EnvConfig,
            Pure) {
            var FiitMps = $resource(EnvConfig.fiitHost + '/freshideas/web/gateway/fiit', {}, {
                'get': {method: 'GET', url: EnvConfig.host + '/freshideas/web/gateway/fiit'},
                'sync': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/gateway/fiit/sync'},
                'link': {method: 'POST', url: EnvConfig.host + '/freshideas/web/gateway/fiit/link'},
                'unlink': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/gateway/fiit/unlink'},
            });

            var config = function () {
                var configMap = CurrentSession.getGateway('fiitmps') || {};

                if (configMap.token) {
                    var decodedString = atob(configMap.token);
                    var decodedArray = decodedString.split(',');
                    configMap.rawToken = decodedArray[0];
                }

                return configMap;
            };

            FiitMps.getLocation = function (params = {}) {
                var fiitMpsConfig = config();

                if (fiitMpsConfig.url) {
                    return $http({
                        method: 'GET',
                        url: Pure.buildUrl(fiitMpsConfig.url, '/payment/gateway/location'),
                        params: params,
                        headers: {
                            'Authorization': 'Basic ' + fiitMpsConfig.rawToken
                        },
                        cache: false
                    }).then(function (response) {
                        return response.data;
                    });
                } else {
                    return $q.resolve({
                        success: false
                    });
                }
            };

            FiitMps.getAccount = function (params = {}) {
                var fiitMpsConfig = config();
                const useV3 = CurrentSession.getCompany().attributes.use_patron_v3 === 'true';
                const path = useV3 ? '/payment/gateway/patronV3' : '/payment/gateway/patronV2';

                return $http({
                    method: 'GET',
                    url: Pure.buildUrl(fiitMpsConfig.url, path),
                    params: params,
                    headers: {
                        'Authorization': 'Basic ' + fiitMpsConfig.rawToken
                    },
                    cache: false
                }).then((response) => {
                    if (!useV3 && !response.data.success) {
                        // when v2 fails with a non-fail status code
                        return Promise.reject({data: response.data.error});
                    }

                    return useV3 ? response.data : response.data.data;
                });
            };

            FiitMps.estimate = function (params = {}) {
                var fiitMpsConfig = config();

                return $http({
                    method: 'POST',
                    url: Pure.buildUrl(fiitMpsConfig.url, '/payment/gateway/transaction/estimate'),
                    data: params,
                    headers: {
                        'Authorization': 'Basic ' + fiitMpsConfig.rawToken
                    },
                    cache: false,
                }).then(function (response) {
                    return response.data;
                });
            };

            FiitMps.initPreauth = function (params = {}) {
                var fiitMpsConfig = config();

                return $http({
                    method: 'POST',
                    url: Pure.buildUrl(fiitMpsConfig.url, '/payment/gateway/transaction/init-pre-auth'),
                    data: params,
                    headers: {
                        'Authorization': 'Basic ' + fiitMpsConfig.rawToken
                    },
                    cache: false,
                }).then(function (response) {
                    return response.data;
                });
            };

            FiitMps.cancelPreauth = function (params = {}) {
                var fiitMpsConfig = config();

                return $http({
                    method: 'POST',
                    url: Pure.buildUrl(fiitMpsConfig.url, '/payment/gateway/transaction/cancel-pre-auth'),
                    data: params,
                    cache: false,
                }).then(function (response) {
                    return response.data;
                });
            };

            FiitMps.completePreauth = function (params = {}) {
                var fiitMpsConfig = config();

                return $http({
                    method: 'POST',
                    url: Pure.buildUrl(fiitMpsConfig.url, '/payment/gateway/transaction/capture-pre-auth'),
                    data: params,
                    headers: {
                        'Authorization': 'Basic ' + fiitMpsConfig.rawToken
                    },
                    cache: false,
                }).then(function (response) {
                    return response.data;
                });
            };

            FiitMps.recordTransactionOnFiit = function (params = {}) {
                var fiitMpsConfig = config();

                return $http({
                    method: 'POST',
                    url: Pure.buildUrl(fiitMpsConfig.url, '/payment/gateway/transaction/record'),
                    data: params,
                    headers: {
                        'Authorization': 'Basic ' + fiitMpsConfig.rawToken
                    },
                    cache: false,
                }).then(function (response) {
                    return response.data;
                });
            };

            FiitMps.void = function (params = {}) {
                var fiitMpsConfig = config();
                params.refundType = 'VOID';

                return $http({
                    method: 'POST',
                    url: Pure.buildUrl(fiitMpsConfig.url, '/payment/gateway/transaction/refund'),
                    data: params,
                    headers: {
                        'Authorization': 'Basic ' + fiitMpsConfig.rawToken
                    },
                    cache: false,
                }).then(function (response) {
                    return response.data;
                });
            };
            FiitMps.refund = function (params = {}) {
                var fiitMpsConfig = config();
                params.refundType = 'REFUND';

                return $http({
                    method: 'POST',
                    url: Pure.buildUrl(fiitMpsConfig.url, '/payment/gateway/transaction/refund'),
                    data: params,
                    headers: {
                        'Authorization': 'Basic ' + fiitMpsConfig.rawToken
                    },
                    cache: false,
                }).then(function (response) {
                    return response.data;
                });
            };

            FiitMps.getLastTransaction = function (patronId) {
                const fiitMpsConfig = config();

                return $http({
                    method: 'GET',
                    url: Pure.buildUrl(fiitMpsConfig.url, '/payment/gateway/last-transaction'),
                    params: {patronId},
                    headers: {
                        'Authorization': 'Basic ' + fiitMpsConfig.rawToken
                    },
                    cache: false
                }).then(function (response) {
                    return response.data;
                });
            };

            return FiitMps;
        }]);
