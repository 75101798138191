'use strict';


module.exports = function (freshideasPosInventoryV2) {
    freshideasPosInventoryV2.controller('InventoryV2OrderStashCtrl', [
        '$scope',
        'InventoryV2Service',
        'ORDER_STATUS',
        'order',
        function ($scope, InventoryV2Service, ORDER_STATUS, order) {
            $scope.order = angular.copy(order);

            $scope.save = function () {
                // TODO: possibly make the save request to backend here because it might
                // make sense to also close the main Order popup after this is saved?

                //  For now, since we are not abiding to the storage table,
                // consider using the storage column on order item level.
                // Location will be pre-fetched
                InventoryV2Service.stashOrder($scope.order).then(function (response) {
                    $scope.$close(response);
                }, function (error) {

                });


            };

            $scope.init = function () {
            };

            $scope.init();
        }
    ]);
};
