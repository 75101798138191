'use strict';


module.exports = function (freshideasDirectivesCommon) {

    freshideasDirectivesCommon.directive('highlighter', ['$timeout', function ($timeout) {
        return {
            restrict: 'A',
            scope: {
                model: '=highlighter'
            },
            link: function (scope, elem, attrs, ngModelCtrl) {
                scope.$watch('model', function (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        elem.addClass('highlight');

                        $timeout(function () {
                            elem.removeClass('highlight');
                        }, 500);
                    }
                });
            }
        };
    }]);

};

