'use strict';


// const angular = require('angular');
module.exports = function (freshideasLocation) {
  freshideasLocation.controller('LocationBreadcrumb', [
      '$scope',
      '$modal',
      '$q',
      '$translate',
      '$location',
      'Locations',
      'Company',
      'notificationTranslationHelper',
      'ErrorUtil',
      function ($scope, $modal, $q, $translate, $location, Locations, Company, notificationTranslationHelper, ErrorUtil) {

          $scope.init = function () {
              console.log('Started Menu List');
          };

          $scope.init();
      }
  ]);
};
