'use strict';


const moment = require('moment');

module.exports = function (freshideasReports) {

    freshideasReports.controller('EmployeeShiftsReport', [
        '$scope',
        '$rootScope',
        '$location',
        'Reports',
        'Utils',
        '$modal',
        'NgGridSortService',
        'NgGridTemplateService',
        'Lookup',
        'Export',
        'Security',
        'Platform',
        'DateRangeService',
        'USER_ROLE_TYPE',
        function ($scope, $rootScope, $location, Reports, Utils, $modal, NgGridSortService, NgGridTemplateService, Lookup, Export, Security, Platform, DateRangeService, USER_ROLE_TYPE) {
            $scope.searchDisabled = false;
            var allowRetry;
            var currentUser = Security.getUser() || {};
            $scope.isManager = currentUser.roleType === USER_ROLE_TYPE.MANAGER;
            $scope.isAccountant = currentUser.roleType === USER_ROLE_TYPE.ACCOUNTANT;
            $scope.isSiteAdmin = currentUser.permission === 'SITEADMIN';
            $scope.isFullAdmin = (currentUser.permission === 'FULLADMIN');
            $scope.isIosWebkit = Platform.isIosWebkit();
            $scope.isElectron = Platform.isElectron();

            $scope.init = function () {
                if (DateRangeService.getFromDate()) {
                    $scope.employeeShiftsSearch.startDateTime = moment(DateRangeService.getFromDate()).startOf('day').toDate();
                }
                if (DateRangeService.getToDate()) {
                    $scope.employeeShiftsSearch.endDateTime = moment(DateRangeService.getToDate()).endOf('day').toDate();
                }
                loadCompanyHierarchy().$promise.then(function () {
                    $scope.loadEmployeeShifts();
                });
            };

            var loadCompanyHierarchy = function () {
                return Lookup.locationsByCompany({isAdvancedSearch: true}, function (response) {
                    $scope.company = _.findWhere(response, {id: currentUser.companyId});
                    $scope.locations = [];
                    $scope.servicePeriodMap = {};
                    let children = ($scope.company && $scope.company.children) ? $scope.company.children : [];

                    if (children.length) {
                        if (children.length > 1) {
                            $scope.locations.push({
                                id: null,
                                name: 'All Locations'
                            });
                        }
                        _.each(children, function (location) {
                            $scope.locations.push(location);

                            var locationServicePeriods = _.where(location.children, {type: 'mealPeriod'});
                            _.each(locationServicePeriods, function (servicePeriod) {
                                $scope.servicePeriodMap[servicePeriod.id] = servicePeriod;
                            });
                        });
                        $scope.employeeShiftsSearch.locationId = currentUser.company.locationId;
                    }
                });
            };

            $scope.setSortByField = function (sortBy) {
                $scope.currentReportSearch.sortBy = sortBy;
                $scope.currentReportSearch.ascending = !$scope.currentReportSearch.ascending;
            };

            $scope.resetFilters = function () {
                $scope.employeeShiftsSearch.startDateTime = moment().startOf('week').toDate();
                $scope.employeeShiftsSearch.endDateTime = moment().endOf('day').toDate();
                $scope.employeeShiftsSearch.isAdvancedSearch = true;
                $scope.employeeShiftsSearch.sortBy = 'itemName';
                $scope.employeeShiftsSearch.ascending = true;
                $scope.employeeShiftsSearch.offSet = 0;
                $scope.employeeShiftsSearch.limit = 50;
                $scope.employeeShiftsSearch.locationId = currentUser.company.locationId;
                $scope.loadEmployeeShifts();
            };

            $scope.getDownloadUrl = function () {
                if ($scope.isIosWebkit || $scope.isElectron) {
                    return;
                }

                var reportSearch = {};

                if ($scope.employeeShiftsSearch.locationId) {
                    reportSearch.locationId = $scope.employeeShiftsSearch.locationId;
                }
                reportSearch.startDateTime = moment($scope.employeeShiftsSearch.startDateTime).startOf('day').valueOf();
                reportSearch.endDateTime = moment($scope.employeeShiftsSearch.endDateTime).endOf('day').valueOf();

                var downloadUrl = $location.protocol() + '://' + $location.host() + ':' + $location.port()
                 + '/freshideas/web/reports/employeeShifts/export';

                var queryString = jQuery.param(reportSearch);
                return downloadUrl + '?' + queryString;
            };

            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };
            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };

            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };
            $scope.employeeShiftsSearch = {
                isAdvancedSearch: true,
                startDateTime: moment().startOf('week').toDate(),
                endDateTime: moment().endOf('day').toDate(),
                sortBy: 'itemName',
                ascending: true,
                offSet: 0,
                limit: 50,
            };
            $scope.saveSelectedFromDate = function () {
                var selectedFromDate = moment($scope.employeeShiftsSearch.startDateTime).startOf('day').valueOf();
                DateRangeService.setFromDate(selectedFromDate);
            };
            $scope.saveSelectedToDate = function () {
                var selectedToDate = moment($scope.employeeShiftsSearch.endDateTime).endOf('day').valueOf();
                DateRangeService.setToDate(selectedToDate);
            };
            $scope.pagingOptions = {
                currentPage: 1,
                totalItems: 0,
                pageSize: 50,
                startRecord: 1,
                endRecord: 50
            };
            $scope.changePage = function () {
                $scope.employeeShiftsSearch.offSet = Utils.serverSidePagingRowNumber($scope.pagingOptions.currentPage, $scope.pagingOptions.pageSize);
                $scope.employeeShiftsSearch.limit = $scope.pagingOptions.pageSize;

                $scope.loadEmployeeShifts();
            };
            $scope.loadEmployeeShifts = function () {
                $scope.searchDisabled = true;
                clearTimeout(allowRetry);
                allowRetry = setTimeout(function () {
                    $scope.searchDisabled = false;
                }, 30000);
                $scope.employeeShiftsSearch.startDateTime = moment($scope.employeeShiftsSearch.startDateTime).startOf('day').valueOf();
                $scope.employeeShiftsSearch.endDateTime = moment($scope.employeeShiftsSearch.endDateTime).endOf('day').valueOf();

                $scope.employeeShiftsData = [];
                $scope.employeeShiftsDataSize = 0;
                $scope.isLoading = true;

                Reports.getEmployeeShiftsReport($scope.employeeShiftsSearch, function (response) {
                    var data = response;

                    if (data.length > 0) {
                        $scope.isLoading = false;

                        var employeeShiftsData = response;
                        _.each(employeeShiftsData, function (shift) {
                            shift.employeeName = shift.user.firstname + ' ' + shift.user.lastname;
                            shift.employeeId = shift.user.userId;
                        });

                        $scope.groupedEmployeeShiftsData = {};
                        _.each(employeeShiftsData, function (shift) {
                            var employeeId = shift.user.userId;

                            if (!$scope.groupedEmployeeShiftsData[employeeId]) {
                                $scope.groupedEmployeeShiftsData[employeeId] = {
                                    employeeId: employeeId,
                                    totalTime: 0,
                                    adjustedTime: 0,
                                    totalBreakMillis: 0,
                                    shifts: []
                                };
                            }
                            var employeeShiftsData = $scope.groupedEmployeeShiftsData[employeeId];
                            // Incomplete shift
                            if (!shift.endTime) {
                                shift.duration = new Date().getTime() - shift.startTime;
                            }

                            // Active break
                            shift.activeBreak = _.find(shift.timeCardBreaks, {
                                isActive: true
                            });

                            if (shift.activeBreak) {
                                shift.totalBreakMillis += (new Date().getTime() - shift.activeBreak.startTime);
                            }

                            // adjustedTime = duration - break time
                            shift.adjustedTime = shift.duration - shift.totalBreakMillis;
                            employeeShiftsData.shifts.push(shift);

                            // Add to employee totals
                            if (shift.endTime) {
                                employeeShiftsData.totalTime += shift.duration;
                                employeeShiftsData.totalBreakMillis += shift.totalBreakMillis;
                                employeeShiftsData.adjustedTime +=
                                    (shift.duration - shift.totalBreakMillis);
                            } else {
                                employeeShiftsData.totalTime += (new Date().getTime() - shift.startTime);
                                employeeShiftsData.totalBreakMillis += shift.totalBreakMillis;
                                employeeShiftsData.adjustedTime +=
                                    (new Date().getTime() - shift.startTime - shift.totalBreakMillis);
                            }
                        });

                        $scope.groupedEmployeeShiftsDataSize = _.keys($scope.groupedEmployeeShiftsData).length;

                        $scope.pagingOptions.totalItems = response.totalCount; // Utils.serverSideTotalItems(response.length, $scope.pagingOptions.currentPage, $scope.pagingOptions.pageSize);
                        $scope.pagingOptions.startRecord = response.offset + 1; // Utils.serverSideStartRecord($scope.pagingOptions.currentPage, $scope.pagingOptions.pageSize);
                        $scope.pagingOptions.endRecord = Math.min(response.totalCount, response.offset + response.limit); // Utils.serverSideEndRecord(response.length, $scope.pagingOptions.currentPage, $scope.pagingOptions.pageSize);

                        $scope.currentReportSearch = angular.copy($scope.employeeShiftsSearch);
                    } else {
                        $scope.isLoading = false;
                        $scope.groupedEmployeeShiftsData = {};
                        $scope.groupedEmployeeShiftsDataSize = 0;
                    }
                }, function (error) {
                    $scope.isLoading = false;
                });
            };

            $scope.exportToPdf = function (tableId) {
                Export.tableToPdf(tableId, 'landscape');
            };

            $scope.init();

            $scope.$on('$destroy', function () {
                clearTimeout(allowRetry);
            });

            $scope.$watch('employeeShiftsSearch.locationId', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('employeeShiftsSearch.startDateTime', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('employeeShiftsSearch.endDateTime', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });
        }
    ]);

};
