'use strict';

export function loggedIn (freshideas) {
    freshideas.controller('LoggedInCtrl', [
        '$scope',
        '$state',
        '$location',
        'Security',
        'PosStatusService',
        'PosAlertService',
        'EnvConfig',
        'LucovaBluetooth',
        'SharedDataService',
        'ElectronUpdater',
        'CurrentSession',
        function (
            $scope,
            $state,
            $location,
            Security,
            PosStatusService,
            PosAlertService,
            EnvConfig,
            LucovaBluetooth,
            SharedDataService,
            ElectronUpdater,
            CurrentSession) {
            $scope.security = Security;

            // check for electron update
            ElectronUpdater.downloadAppUpdate();

            // Logout the user
            $scope.logout = function () {
                PosAlertService.showAlertByName('logout', {
                    'modalCallback': function () {
                        PosStatusService.setLoggedIn(false);
                        PosStatusService.shiftStarted = false;
                        Security.logout();
                        LucovaBluetooth.stop();
                        $state.go('freshideas.home', {});
                    }
                });
            };

            $scope.navbarNavigations = {};
            $scope.env = EnvConfig.env;
            $scope.showEnv = EnvConfig.showEnv;

            $scope.toSetup = function () {
                $state.go('freshideas.setup');
            };
        }
    ]);
}
