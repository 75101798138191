'use strict';

module.exports = function (freshideasLocation) {
    freshideasLocation
    .directive('locationEditCategory', [
        function () {
            return {
                restrict: 'E',
                templateUrl: 'locations/location-side-content/location-edit-category/location-edit-category.tpl.html',
                controller: 'LocationEditCategoryCtrl',
                scope: {
                    item: '=',
                    onClose: '='
                },
                link: function (scope, element, attr) {
                    console.log('location edit category');
                }
            };
        }
        ])
    .controller('LocationEditCategoryCtrl', [
        '$scope',
        '$modal',
        '$q',
        '$translate',
        '$location',
        'Locations',
        'LocationFactory',
        'Company',
        'notificationTranslationHelper',
        'ErrorUtil',
        'Inventory',
        function ($scope, $modal, $q, $translate, $location, Locations, LocationFactory, Company, notificationTranslationHelper, ErrorUtil, Inventory) {

            $scope.init = function () {
                //
            };

            $scope.cancel = function () {
                LocationFactory.dismissSideContent();
            };

            $scope.save = function () {
                LocationFactory.editCategory($scope.item);
            };

            $scope.delete = function () {
                LocationFactory.deleteCategory($scope.item);
            };

            $scope.$on('$destroy', function () {
                if ($scope.onClose) {
                    $scope.onClose();
                }
            });

            $scope.init();
        }
        ]);
};
