'use strict';
module.exports = function (freshideasDirectivesCommon) {
    freshideasDirectivesCommon.directive('patronLoyalty', function () {
        return {
            restrict: 'E',
            scope: {
                patron: '=',
                location: '='
            },
            templateUrl: 'common/components/patron_loyalty/patron-loyalty.html',
            controller: ['$scope', '$attrs', 'SmbPosService', function ($scope, $attrs, SmbPosService) {
                var getPhotoUrl = function (patron) {
                    if (!patron || !patron.lucovaUser || !patron.lucovaUser.photo_url) {
                        return '';
                    }
                    return SmbPosService.getPatronPhoto(patron.lucovaUser.photo_url);
                };
                $scope.photoUrl = getPhotoUrl($scope.patron);

                $scope.$watch(() => {
                    return $scope.patron;
                }, (patron) => {
                    $scope.photoUrl = getPhotoUrl(patron);
                });
            }]
        };
    });
};
