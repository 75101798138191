'use strict';

const angular = require('angular');

var i18n = angular.module('freshideas.services.i18n', ['pascalprecht.translate']);

i18n.factory('notificationTranslationHelper', ['notifications', '$translate', function (notifications, $translate) {
    var factory = {};
    factory.notifyMessage = function (key, params) {
        $translate(key, params).then(function (text) {
            if (!angular.isDefined(text)) {
                notifications.pushMessage({message: 'general.notification.success'});
            } else {
                notifications.pushMessage({message: text});
            }
        });
    };

    //TODO: Refactor serverError parameter as it is no longer used!!!
    factory.notifyError = function (error, params, serverError) {
        if (typeof error === 'object') {
            if (angular.isDefined(error.message)) {
                notifications.pushError({message: error.message});
            } else if (angular.isDefined(error) && !angular.isDefined(error.key)) {
                notifications.pushError({message: error});
            } else if (angular.isDefined(error.key)) {
                $translate(error.key, error.args).then(function (text) {
                    notifications.pushError({message: text});
                });
            } else {
                notifications.pushError({message: 'general.notification.error'});
            }
        } else {
            $translate(error, params).then(function (text) {
                if (!angular.isDefined(text)) {
                    notifications.pushError({message: 'general.notification.error'});
                } else {
                    notifications.pushError({message: text});
                }
            });
        }
    };

    return factory;
}]);

export default i18n;

