'use strict';


module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosAddGuestNameCtrl', [
        '$scope',
        '$timeout',
        '$window',
        '$log',
        '$modal',
        function ($scope,
            $timeout,
            $window,
            $log,
            $modal) {

            $scope.nameInput;
            $scope.saveGuestName = function () {
                $scope.$close($scope.nameInput);
            };
            $scope.cancelAddGuestName = function () {
                $scope.$dismiss();
            };
            $scope.clearGuestNameInput = function () {
                $scope.nameInput = '';
            };
        }
    ]);
};
