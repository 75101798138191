'use strict';

const moment = require('moment');

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('PreorderDashboardCtrl', [
        '$scope',
        '$rootScope',
        '$log',
        '$modal',
        '$state',
        '$stateParams',
        '$timeout',
        '$translate',
        'CurrentSession',
        'KdsService',
        'Lucova',
        'LucovaWebSocket',
        'PosStatusService',
        'PosAlertService',
        'PREORDER_STATUS',
        'Pure',
        'Security',
        'SmbPosService',
        'PreorderService',
        function (
            $scope,
            $rootScope,
            $log,
            $modal,
            $state,
            $stateParams,
            $timeout,
            $translate,
            CurrentSession,
            KdsService,
            Lucova,
            LucovaWebSocket,
            PosStatusService,
            PosAlertService,
            PREORDER_STATUS,
            Pure,
            Security,
            SmbPosService,
            PreorderService) {

            const TableColumns = Object.freeze(
                {
                    'PLACED': 1,
                    'DUE': 2,
                    'CUSTOMER': 3,
                    'STATUS': 4,
                    'PAYMENT': 5
                }
            );

            const Order = Object.freeze(
                {
                    'DESC': 'desc',
                    'ASC': 'asc',
                }
            );

            const PageTab = Object.freeze(
                {
                    'CURRENT': 'current',
                    'SCHEDULED': 'scheduled',
                    'COMPLETED': 'completed'
                }
            );

            // key - internal status
            // value - external status
            // this is used to sort status messages using the external facing status
            const StatusMap = Object.freeze(
                {
                    'PRINT_PENDING': 'Pending Cashier Acceptance - Payment Preauthorized',
                    'PENDING': 'Pending Cashier Acceptance - Payment Preauthorized',
                    'CANCELLED': 'Cancelled',
                    'ACKNOWLEDGED': 'Preparing',
                    'COMPLETED': 'Ready for Pickup - Payment Captured (Paid)',
                    'FULFILLED': 'Order Fulfilled',
                }
            );

            const _initialPreorderStats = {
                failed: 0,
                pending: 0,
                cancelled: 0,
                preparing: 0,
                ready: 0,
                fulfilled: 0
            };

            $scope.filteredPatrons = [];
            $scope.completedOrdersPatronList = [];
            $scope.pagination = {
                currentOrders: {
                    index: 0,
                    total: 0,
                    limit: 20,
                    isLoading: false
                },
                completedOrders: {
                    index: 0,
                    total: 0,
                    limit: 20,
                    isLoading: false
                },
                scheduledOrders: {
                    index: 0,
                    total: 0,
                    limit: 20,
                    isLoading: false
                }
            };

            const _resetPagination = function () {
                $scope.pagination.currentOrders.index = 0;
                $scope.pagination.completedOrders.index = 0;
                $scope.pagination.scheduledOrders.index = 0;
            };

            const _loadPreorderHistory = function (offset = 0, limit = 20, type = 'ALL') {
                setStatusBreakdown();
                $scope.pagination.currentOrders.isLoading = true;
                $scope.pagination.scheduledOrders.isLoading = true;
                $scope.pagination.completedOrders.isLoading = true;

                SmbPosService.loadPreorderHistory(type, offset, limit).then(function (result) {
                    _resetTabs();

                    if (!result.length) {
                        return;
                    }

                    const currentOrderPatronCopy = angular.copy(result);
                    const completedOrderPatronCopy = angular.copy(result);

                    _.each(currentOrderPatronCopy, function (preorderUser) {
                        const lucovaUser = preorderUser.lucovaUser;

                        let filteredPreorders = _.filter(lucovaUser.preorder, function (preorder) {
                            return preorder.status != PREORDER_STATUS.COMPLETED;
                        });

                        lucovaUser.preorder = filteredPreorders;
                        if (lucovaUser.preorder && lucovaUser.preorder.length) {
                            $scope.filteredPatrons.push(preorderUser);
                        }
                    });

                    _.each(completedOrderPatronCopy, function (preorderUser) {
                        const lucovaUser = preorderUser.lucovaUser;

                        let filteredPreorders = _.filter(lucovaUser.preorder, function (preorder) {
                            return preorder.status === PREORDER_STATUS.COMPLETED;
                        });

                        lucovaUser.preorder = filteredPreorders;
                        if (lucovaUser.preorder && lucovaUser.preorder.length) {
                            $scope.completedOrdersPatronList.push(preorderUser);
                        }
                    });

                    sortPatronsByPlacedTime($scope.filteredPatrons, Order.DESC);
                    sortPatronsByPlacedTime($scope.completedOrdersPatronList, Order.DESC);
                });
            };

            $scope.goToPage = function (type, pageIndex) {
                let offset = 0;

                switch (type) {
                    case PageTab.CURRENT:
                        offset = pageIndex * $scope.pagination.currentOrders.limit;
                        $scope.pagination.currentOrders.isLoading = true;
                        $scope.pagination.currentOrders.index = pageIndex;

                        _loadPreorderHistory(offset, $scope.pagination.currentOrders.limit, 'CURRENT');
                        break;
                    case PageTab.SCHEDULED:
                        break;
                    case PageTab.COMPLETED:
                        offset = pageIndex * $scope.pagination.completedOrders.limit;
                        $scope.pagination.completedOrders.isLoading = true;
                        $scope.pagination.completedOrders.index = pageIndex;

                        _loadPreorderHistory(offset, $scope.pagination.completedOrders.limit, 'COMPLETED');
                        break;
                    default:
                }
            };

            // default table sort
            $scope.orderSort = {
                value: TableColumns.PLACED,
                order: Order.DESC
            };

            $scope.currentTime;
            $scope.tickInterval = 1000; // ms

            var tickTimeout;
            var tick = function () {
                var momentDateTime = moment();
                $scope.currentTime = momentDateTime.format('MMMM Do, h:mm:ss a');

                tickTimeout = $timeout(tick, $scope.tickInterval); // reset the timer
            };

            $scope.formatTime = (timestamp) => {
                return moment.unix(timestamp).format('h:mm A');
            };

            $scope.countdownEstimate = function (preorder) {
                if (preorder.status === PREORDER_STATUS.PRINT_PENDING || preorder.status === PREORDER_STATUS.ACKNOWLEDGED) {
                    var target = preorder.estimated_completion;
                    var now = moment();

                    var diff = moment.duration(moment.unix(target).diff(now));
                    var duration = moment.duration(diff, 'milliseconds');

                    if (duration.asSeconds() > 0) {
                        var min = moment.utc(duration.asMilliseconds()).format('m');
                        return $translate.instant('pos.preorder.dashboard.due.time.label', {
                            min: min
                        });
                    } else {
                        return '-';
                    }
                } else {
                    return '-';
                }
            };

            $scope.getStatusTime = (preorder) => {
                var ts = '';
                if (preorder.status === PREORDER_STATUS.COMPLETED) {
                    ts = preorder.transaction_closed;
                } else if (preorder.status_history.length) {
                    var foundStatus = _.find(preorder.status_history, (status) => {
                        // status = {status: 'status string', ts: timestamp}
                        return status.status === preorder.status;
                    });

                    if (foundStatus) {
                        ts = foundStatus.ts;
                    }
                }
                return moment.unix(ts);
            };

            $scope.getTenderList = (tenders) => {
                var nameArr = _.map(tenders, (tender) => {
                    if (tender.card_name === 'nown_gift') {
                        return $translate.instant('pos.preorder.dashboard.tender.type.giftCard.label');
                    } else if (tender.card_name === 'fiit') {
                        return $translate.instant('pos.preorder.dashboard.tender.type.fiit.label');
                    } else {
                        return tender.card_name;
                    }
                });

                return nameArr.join(', ');
            };

            // Returns all pending preorders (haven't been printed yet)
            // Also used for receiving new mobile orders
            $scope.getPrintPendingOrders = () => {
                var printPendingOrders = _.filter(LucovaWebSocket.getMobileOrderUsers(), (lucovaUser) => {
                    var preorder = lucovaUser.preorder;
                    preorder = _.filter(preorder, (order) => {
                        return order.status === PREORDER_STATUS.PRINT_PENDING;
                    });
                    if (preorder.length) {
                        return lucovaUser;
                    }
                });

                $scope.printPendingOrders = printPendingOrders;
                return printPendingOrders;
            };

            $scope.refreshPatrons = () => {
                // refresh dashboard
                _loadPreorderHistory();

                // scheduled orders
                SmbPosService.getScheduledPreorders().then((response) => {
                    $scope.scheduledPreorderError = false;
                    $scope.scheduledPatrons = response || [];
                    sortPatronsByPlacedTime($scope.scheduledPatrons, Order.DESC);
                }, (error) => {
                    $scope.scheduledPatrons = [];
                    $scope.scheduledPreorderError = true;
                });
                $scope.updatedAt = moment();
            };

            var compareBy = (argA, argB, order) => {
                if (argA < argB) {
                    return order === Order.ASC ? -1 : 1;
                } else if (argA > argB) {
                    return order === Order.ASC ? 1 : -1;
                } else {
                    return 0;
                }
            };

            var sortPatronsByPlacedTime = (patronsArr, order) => {
                patronsArr.sort((a, b) => {
                    let aPreorderArr = a.lucovaUser.preorder;
                    let bPreorderArr = b.lucovaUser.preorder;

                    /*
                    The preorder array when the lucovaUser object comes in from websocket is spliced
                    (see Pure.splicePreorderArrayFromPatrons),
                    so this preorder array will only contain 1 set of preorder.
                    This is to prevent calling preorder[0] directly as it is error prone.
                    */

                    let argA;
                    let argB;

                    _.each(aPreorderArr, (preorder) => {
                        argA = preorder.transaction_opened;
                    });

                    _.each(bPreorderArr, (preorder) => {
                        argB = preorder.transaction_opened;
                    });

                    return compareBy(argA, argB, order);
                });
            };

            var sortPatronsByDueTime = (patrons, order) => {
                patrons.sort((a, b) => {
                    let aPreorderArr = a.lucovaUser.preorder;
                    let bPreorderArr = b.lucovaUser.preorder;

                    let argA;
                    let argB;

                    /*
                    The preorder array when the lucovaUser object comes in from websocket is spliced
                    (see Pure.splicePreorderArrayFromPatrons),
                    so this preorder array will only contain 1 set of preorder.
                    This is to prevent calling preorder[0] directly as it is error prone.
                    */

                    _.each(aPreorderArr, (preorder) => {
                        if (preorder.status === 'pending') {
                            argA = preorder.target_timestamp;
                        } else {
                            argA = $scope.countdownEstimate(preorder);
                        }
                    });

                    _.each(bPreorderArr, (preorder) => {
                        if (preorder.status === 'pending') {
                            argB = preorder.target_timestamp;
                        } else {
                            argB = $scope.countdownEstimate(preorder);
                        }
                    });

                    return compareBy(argA, argB, order);
                });
            };

            var sortPatronsByPayment = (patrons, order) => {
                patrons.sort((a, b) => {
                    let aPreorderArr = a.lucovaUser.preorder;
                    let bPreorderArr = b.lucovaUser.preorder;

                    let argA;
                    let argB;

                    /*
                    The preorder array when the lucovaUser object comes in from websocket is spliced
                    (see Pure.splicePreorderArrayFromPatrons),
                    so this preorder array will only contain 1 set of preorder.
                    This is to prevent calling preorder[0] directly as it is error prone.
                    */

                    _.each(aPreorderArr, (preorder) => {
                        argA = preorder.total_cents;
                    });

                    _.each(bPreorderArr, (preorder) => {
                        argB = preorder.total_cents;
                    });

                    return compareBy(argA, argB, order);
                });
            };

            // due to differences between external & internal wording
            // this is necessary when sorting via status.
            var getExternalStatus = (preorder) => {
                if (preorder.fulfilled) {
                    return StatusMap.FULFILLED;
                } else {
                    var status = preorder.status.toUpperCase();
                    return StatusMap[status];
                }
            };

            var sortPatronsByStatus = (patrons, order) => {
                patrons.sort((a, b) => {
                    let aPreorderArr = a.lucovaUser.preorder;
                    let bPreorderArr = b.lucovaUser.preorder;

                    let argA;
                    let argB;

                    /*
                    The preorder array when the lucovaUser object comes in from websocket is spliced
                    (see Pure.splicePreorderArrayFromPatrons),
                    so this preorder array will only contain 1 set of preorder.
                    This is to prevent calling preorder[0] directly as it is error prone.
                    */

                    _.each(aPreorderArr, (preorder) => {
                        argA = getExternalStatus(preorder);
                    });

                    _.each(bPreorderArr, (preorder) => {
                        argB = getExternalStatus(preorder);
                    });

                    return compareBy(argA, argB, order);
                });
            };

            var sortPatronsByName = (patrons, order) => {
                patrons.sort((a, b) => {
                    let argA = a.lucovaUser.full_name.toUpperCase();
                    let argB = b.lucovaUser.full_name.toUpperCase();

                    return compareBy(argA, argB, order);
                });
            };

            var setOrderSort = (column, orderParam) => {
                var order = orderParam;

                if (!order) {
                    if (column === $scope.orderSort.value) {
                        // if clicked on the same column, change only the order and not value
                        if ($scope.orderSort.order === Order.DESC) {
                            order = Order.ASC;
                        } else if ($scope.orderSort.order === Order.ASC) {
                            order = Order.DESC;
                        }
                    } else {
                        if (column === TableColumns.PLACED || column === TableColumns.DUE) {
                            order = Order.DESC;
                        } else {
                            order = Order.ASC;
                        }
                    }
                }

                $scope.orderSort = {
                    value: column,
                    order: order
                };
            };

            $scope.sortTable = (column, filteredPatronsArr, orderParam) => {
                setOrderSort(column, orderParam);

                if (column === TableColumns.PLACED) {
                    sortPatronsByPlacedTime(filteredPatronsArr, $scope.orderSort.order);
                } else if (column === TableColumns.DUE) {
                    sortPatronsByDueTime(filteredPatronsArr, $scope.orderSort.order);
                } else if (column === TableColumns.CUSTOMER) {
                    sortPatronsByName(filteredPatronsArr, $scope.orderSort.order);
                } else if (column === TableColumns.STATUS) {
                    sortPatronsByStatus(filteredPatronsArr, $scope.orderSort.order);
                } else if (column === TableColumns.PAYMENT) {
                    sortPatronsByPayment(filteredPatronsArr, $scope.orderSort.order);
                }
            };

            $scope.filterPatrons = (patronsObj, filteredPatronsArr) => {
                var patrons = patronsObj || {};
                filteredPatronsArr = patrons.ungroupedPreorder || [];

                sortPatronsByPlacedTime(filteredPatronsArr, Order.DESC);
            };

            var setLocationName = () => {
                var name = Security.getUser().company.companyName;
                var index = name.indexOf(' - ');
                // take location name if a dash is included
                // e.g. Real Fruit Bubble Tea - Toronto Eaton Centre
                $scope.locationName = index > 0 ? name.slice(index + 3) : name;
            };

            const _resetStatusBreakdown = function () {
                $scope.statusBreakdown = _initialPreorderStats;
            };

            const setStatusBreakdown = function () {
                _resetStatusBreakdown();
                try {
                    Lucova.manager().getPreorderStats().$promise.then((result) => {
                        $scope.statusBreakdown = result.stats;
                        calculateSuccessRate();
                    }).catch((error) => {
                        console.warn('Error while getting preorder stats!!', error);
                        calculateSuccessRate();
                    });
                } catch (error) {
                    console.warn('Error while getting preorder stats!!', error);
                    calculateSuccessRate();
                }
            };

            $scope.successRate = 0;
            var calculateSuccessRate = () => {
                let successfulOrders = 0;
                let totalOrders = 0;
                let currentOrdersSize = 0;
                let completedOrdersSize = 0;

                _.each($scope.statusBreakdown, (value, key) => {
                    totalOrders += value;
                    // populate total page numbers
                    currentOrdersSize += ['pending', 'preparing', 'failed', 'cancelled'].includes(key) ? value : 0;
                    completedOrdersSize += ['ready', 'fulfilled'].includes(key) ? value : 0;
                });

                $scope.pagination.currentOrders.total = Math.ceil(currentOrdersSize / $scope.pagination.currentOrders.limit) || 1;
                $scope.pagination.completedOrders.total = Math.ceil(completedOrdersSize / $scope.pagination.completedOrders.limit) || 1;

                successfulOrders = (totalOrders - $scope.statusBreakdown.cancelled - $scope.statusBreakdown.failed);

                // set success rate to 100% by default if no orders.
                if (totalOrders >= 1) {
                    var successRate = (successfulOrders / totalOrders) * 100;

                    // if there are decimal places
                    if (successRate % 1 !== 0) {
                        successRate = successRate.toFixed(2);
                    }

                    $scope.successRate = successRate || 0;
                } else {
                    $scope.successRate = 100;
                }
            };

            var scheduledOrderTimer = null;
            // calls SmbPosService.getScheduledProerders() every 30 seconds,
            // until cleaned up on $destroy
            var registerScheduledOrderTimer = function (argument) {
                // scheduled orders
                SmbPosService.getScheduledPreorders().then((response) => {
                    $scope.scheduledPreorderError = false;
                    $scope.scheduledPatrons = response || [];
                    sortPatronsByPlacedTime($scope.scheduledPatrons, Order.DESC);
                }, (error) => {
                    $scope.scheduledPatrons = [];
                    $scope.scheduledPreorderError = true;
                });

                scheduledOrderTimer = $timeout(registerScheduledOrderTimer, 30000);
            };

            const _resetTabs = function () {
                $scope.filteredPatrons = [];
                $scope.completedOrdersPatronList = [];

                $scope.pagination.currentOrders.isLoading = false;
                $scope.pagination.scheduledOrders.isLoading = false;
                $scope.pagination.completedOrders.isLoading = false;
            };

            $scope.init = () => {
                $scope.viewTab(PageTab.CURRENT);
                setLocationName();

                _resetTabs();
                // regular orders
                _loadPreorderHistory();

                // scheduled orders
                $scope.scheduledPatrons = [];
                $timeout(function () {
                    registerScheduledOrderTimer();
                }, 15000);

                tick();
            };

            $scope.goBack = () => {
                if ($stateParams.prevPage === 'ods') {
                    $state.go('freshideas.kds');
                } else {
                    $state.go('freshideas.home');
                }
            };

            // for opening a selected mobile order by patron & type
            $scope.selectOrder = (selectedPreorder, selectedPatron, filteredPatronsArr) => {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modal.mobileOrder.patron.tpl.html',
                    animation: false,
                    backdrop: 'static',
                    controller: 'MobileOrderPatronModalCtrl',
                    windowClass: 'mobile-order-screen',
                    keyboard: false,
                    resolve: {
                        mobileOrderPatrons: function () {
                            return filteredPatronsArr;
                        },
                        activeIndex: function () {
                            return _.findIndex(filteredPatronsArr, function (patron) {
                                // finding index of patron via preorder id
                                // due to chance of having multiple same users but different preorders
                                var toFind;
                                if (selectedPatron.lucovaUser.preorder.length) {
                                    _.each(selectedPatron.lucovaUser.preorder, (preorder) => {
                                        toFind = preorder._id;
                                    });
                                }

                                var found = false;
                                if (patron.lucovaUser.preorder.length) {
                                    _.each(patron.lucovaUser.preorder, (preorder) => {
                                        if (preorder._id === toFind) {
                                            found = true;
                                        }
                                    });
                                }

                                return found;
                            });
                        },
                        modalType: function () {
                            var type = 'ACCEPTED';

                            if (selectedPatron.lucovaUser.preorder.length) {
                                _.each(selectedPatron.lucovaUser.preorder, (preorder) => {
                                    if (preorder.status === PREORDER_STATUS.PRINT_PENDING) {
                                        type = 'NEW';
                                    }
                                });
                            }
                            return type;
                        },
                        activeUserPreorderId: function () {
                            return (selectedPatron.lucovaUser.user_name + '_' + selectedPreorder.short_id);
                        }

                    }
                });

                modalInstance.result.then(function (resolved) {
                });
            };

            $scope.acknowledgeOrder = function (event, user, acknowledgedOrder) {
                /*
                    user object is used to grab name and photo_url.
                    reason why we are passing in order seperately is because
                    user object contains a list of preorders and may cause issues in the future
                    if we loop through the list of preorders, even though the preorder
                    list has been spliced to only contain 1 preorder.
                */

                // stops opening of modal on click of accept button
                // as the button is in a parent container with an ng-click that triggers opening of modal
                if (event) {
                    event.stopPropagation();
                }

                acknowledgedOrder.isAccepted = false;
                if (PosStatusService.isOffline()) {
                    PosAlertService.showAlertByName('general', {
                        title: 'general.error.offline.ttl',
                        message: 'pos.mobile.order.offline.description'
                    });
                    return;
                }

                LucovaWebSocket.acceptPreorder(acknowledgedOrder, function (response) {
                    acknowledgedOrder.isAccepted = true;
                    SmbPosService.loadLucovaUsers();

                    // sort order by currently selected order
                    $scope.sortTable($scope.orderSort.value, $scope.filteredPatrons, $scope.orderSort.order);
                }, function (err) {
                    if (err && err.code === 100) {
                        PosAlertService.showAlertByName('general', {
                            title: 'mobile.order.printer.error.ttl',
                            message: 'mobile.order.printer.error.noprinter'
                        });
                    } else {
                        $log.error('Failed to accept preorder', err);
                    }
                });

                var currentCompany = CurrentSession.getCompany();
                if (!currentCompany) {
                    $log.error('Failed to get company from CurrentSession when accepting order.');
                    return;
                }

                var isKdsEnabled = currentCompany.attributes.kds_enabled === 'true';
                if (!isKdsEnabled) {
                    return;
                }

                if (!currentCompany.locationId) {
                    $log.error('Failed to get location ID from CurrentSession when accepting order.');
                    return;
                }

                KdsService.sendPreorderToKds(user, acknowledgedOrder);
            };

            $scope.openHelpModal = () => {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalPreorderDashboardHelp.tpl.html',
                    controller: 'PreorderDashboardHelpCtrl',
                    animation: false,
                    windowClass: 'preorder-dashboard-help',
                    backdrop: true
                });
                return modalInstance.result.then(function (value) {
                });
            };

            $scope.toPlayAnimation = (preorder) => {
                if (preorder.status === PREORDER_STATUS.PRINT_PENDING || preorder.status === PREORDER_STATUS.PENDING) {
                    var time = preorder.transaction_opened;
                    var now = moment();

                    var diff = moment.duration(moment.unix(time).diff(now));
                    var duration = moment.duration(diff, 'milliseconds');

                    // Math.abs(duration.asSeconds()) ensures value will be positive
                    return Math.abs(duration.asSeconds()) >= 60;
                }
            };

            $scope.getPatronPhoto = function (photoUrl) {
                return SmbPosService.getPatronPhoto(photoUrl);
            };

            $scope.viewTab = (tab) => {
                if ($scope.currentTab != tab) {
                    let pollType = '';
                    let pollLimit = 20;

                    switch (tab) {
                        case PageTab.CURRENT:
                            pollType = 'CURRENT';
                            pollLimit = $scope.pagination.currentOrders.limit;
                        break;
                        case PageTab.COMPLETED:
                            pollType = 'COMPLETED';
                            pollLimit = $scope.pagination.completedOrders.limit;
                        break;
                        default:
                            pollType = 'ALL';
                    }

                    _resetPagination();
                    _loadPreorderHistory(0, pollLimit, pollType);
                }

                $scope.currentTab = tab;
            };

            var getPatronChat = (preorder) => {
                // getting chat for current patron
                var preorderId = preorder._id;
                Lucova.manager().getPreorderChatById({
                    preorder_id: preorderId
                }, function (response) {
                    // setting chat channel
                    if (response.chat_channel != null) {
                        preorder.chat_channel = response.chat_channel;
                    }
                }, function (error) {
                    $log.error(error);
                });
            };

            var onChatUpdate = function (event, payload) {
                var foundPatron = _.find($scope.filteredPatrons, (patron) => {
                    var preorderArray = patron.lucovaUser.preorder;

                    var preorder = _.filter(preorderArray, (order) => {
                        return order._id === payload.preorder_id;
                    });

                    if (preorder.length) {
                        return patron;
                    }
                });

                if (foundPatron) {
                    // refresh chat channel here
                    _.each(foundPatron.lucovaUser.preorder, (preorder) => {
                        getPatronChat(preorder);
                    });
                }
            };

            var unbindChatListener = $rootScope.$on('pos.mobileOrder.dashboard.chat.update', onChatUpdate);

            $scope.init();

            var unbindPosUsersUpdateListener = $rootScope.$on('pos.users.update', $scope.refreshPatrons);

            $scope.$on('$destroy', function () {
                // cleans up the ticker on header bar
                $timeout.cancel(tickTimeout);

                // cleans up the continuous 30 sec timer for /pending-preorders
                if (scheduledOrderTimer) {
                    $timeout.cancel(scheduledOrderTimer);
                    scheduledOrderTimer = null;
                }

                // Unbind rootScope listeners
                unbindPosUsersUpdateListener();
                unbindChatListener();
            });
        }
    ]);
};
