'use strict';


const Decimal = require('decimal.js').default;
const _ = require('underscore');
const SharedDataService = require('../pos.data-service.js');

(function () {
    var LoyaltyRedemption = (function () {
        var LoyaltyRedemption = function () {};

        var _rules = [
            {
                discount: 100,
                range: {
                    min: 0,
                    max: 5.99
                },
            },
            {
                discount: 40,
                range: {
                    min: 6.00,
                    max: 10.99
                },
            },
            {
                discount: 30,
                range: {
                    min: 11.00,
                    max: 20.99
                },
            },
            {
                discount: 20,
                range: {
                    min: 21.00,
                    max: 40.99
                },
            },
            {
                discount: 10,
                range: {
                    min: 41.00,
                    max: 80.99
                },
            },
            {
                discount: 5,
                range: {
                    min: 81.00,
                    max: Number.MAX_SAFE_INTEGER
                },
            }
        ];

        //   NOTE: Ensure the amount passed in as `subtotal` is the `subtotal` of the "eligible pre-tax sales amount".
        //   This will require calculating the pre-tax prices of all eligible item and summing them up.
        //   This is already well-handled by `pos.meal-plann-calculations.js`, but just a reminder in case this
        //   method neds to be called outside of `pos.meal-plan-calculations.js`
        var _getTier = function (subtotal, patronLoyaltyPoints, rules, redemptionPerDollar = 100) {
            if (redemptionPerDollar == undefined || redemptionPerDollar == null) {
                redemptionPerDollar = 100;
            }
            if (!rules || rules.length == 0) {
                rules = _rules;
            }
            var redemptionFactor = 100 / redemptionPerDollar;

            for (var cntr = 0; cntr < rules.length; cntr++) {
                var rule = rules[cntr];

                if (subtotal >= rule.range.min && subtotal <= rule.range.max) {
                    var pointsRequired = Math.ceil(new Decimal(subtotal).times(rule.discount).div(redemptionFactor).toNumber());
                    if (patronLoyaltyPoints >= pointsRequired) {
                        return {
                            discount: rule.discount
                        };
                    }
                }
            }

            return {
                discount: 0
            };
        };

        var _calculateAvailableLoyaltyPoints = function (availableLoyaltyPlanList) {
            var availablePoints = 0;

            _.each(availableLoyaltyPlanList, function (plan) {
                if (plan.name === 'Loyalty' || plan.mealPlanDescription === 'loyalty') {
                    availablePoints += plan.currentMealPlanBalance;
                }
            });

            return availablePoints;
        };

        LoyaltyRedemption.prototype.calculateRequiredLoyaltyValue = function (flatCartItems, adjustments) {
            var requiredLoyaltyValue = new Decimal(0);

            _.each(flatCartItems, function (cartItem) {
                if (cartItem.loyaltyEnabled) {
                    var receiptItemValue = new Decimal(cartItem.itemPrice * cartItem.quantity);
                    requiredLoyaltyValue = requiredLoyaltyValue.plus(receiptItemValue);
                }
            });

            var dollarDiscount = (adjustments)? adjustments.dollarDiscount || 0 : 0;
            requiredLoyaltyValue = requiredLoyaltyValue.minus(new Decimal(dollarDiscount));

            return requiredLoyaltyValue;
        };

        LoyaltyRedemption.prototype.calculateLoyaltyDiscountPercentage = function (flatCartItems, adjustments,
            availableLoyaltyPlanList, organizationSettings) {
            var availableLoyaltyPoints = new Decimal(_calculateAvailableLoyaltyPoints(availableLoyaltyPlanList));
            var requiredLoyaltyValue = this.calculateRequiredLoyaltyValue(flatCartItems, adjustments);

            var loyaltyRedemptionRules = (organizationSettings) ? organizationSettings.loyaltyRedemptionRules : null;
            var loyaltyRedemptionPerDollar = (organizationSettings) ? organizationSettings.loyaltyRedemptionPerDollar : null;

            var loyaltyTier = _getTier(
                requiredLoyaltyValue.toNearest(SharedDataService.baseDollar).toNumber(),
                availableLoyaltyPoints.toDecimalPlaces(0).toNumber(),
                loyaltyRedemptionRules,
                loyaltyRedemptionPerDollar
            );

            if (loyaltyTier.discount) {
                return loyaltyTier.discount;
            } else {
                return 0;
            }
        };

        //   NOTE: Ensure the amount passed in as `subtotal` is the `subtotal` of the "eligible pre-tax sales amount".
        //   This will require calculating the pre-tax prices of all eligible item and summing them up.
        //   This is already well-handled by `pos.meal-plann-calculations.js`, but just a reminder in case this
        //   method neds to be called outside of `pos.meal-plan-calculations.js`
        LoyaltyRedemption.prototype.getPointsRedeemable = function (subtotal, patronLoyaltyPoints, rules, redemptionPerDollar) {
            if (redemptionPerDollar == undefined) {
                redemptionPerDollar = 100;
            }
            if (!rules || rules.length == 0) {
                rules = _rules;
            }
            var redemptionFactor = 100 / redemptionPerDollar;

            for (var cntr = 0; cntr < rules.length; cntr++) {
                var rule = rules[cntr];

                if (subtotal >= rule.range.min && subtotal <= rule.range.max) {
                    var pointsRequired = Math.ceil(new Decimal(subtotal).times(rule.discount).div(redemptionFactor).toNumber());
                    if (patronLoyaltyPoints >= pointsRequired) {
                        return pointsRequired;
                    }
                }
            }

            return 0;
        };


        return LoyaltyRedemption;
    })();

    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = new LoyaltyRedemption();
    } else {
        window.LoyaltyRedemption = new LoyaltyRedemption();
    }

})();
