'use strict';

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosEndShiftTipsPayoutCtrl', [
        '$scope',
        '$timeout',
        '$window',
        '$log',
        '$modalInstance',
        '$translate',
        '$filter',
        'SmbPosService',
        'shift',
        function ($scope,
            $timeout,
            $window,
            $log,
            $modalInstance,
            $translate,
            $filter,
            SmbPosService,
            shift) {

            $scope.shift = Object.assign({}, shift);

            $scope.textToDisplay = $translate.instant('smb.pos.tipsPayout.prompt', {
                                        totalTip: $filter('currency')(shift.totalTip)
                                    });
            // Navigation
            $scope.goBack = function (checkmarkString = false) {
                $modalInstance.dismiss();
            };

            $scope.removeTipsFromCashDrawer = function () {
                $modalInstance.close();
            };
        }
    ]);
};
