'use strict';

const Honeybadger = require('honeybadger-js');

export function loggingService (freshideas, moment) {

    freshideas.config(['$logProvider', '$provide', function ($logProvider, $provide) {
        $provide.decorator('$log', ['$delegate', 'Logging', function ($delegate, Logging) {
            var methods = {
                debug: function (...args) {
                    if ($logProvider.debugEnabled()) {
                        $delegate.debug(...args);
                        Logging.debug(...args);
                    }
                },
                log: function (...args) {
                    if ($logProvider.debugEnabled()) {
                        $delegate.log(...args);
                        Logging.log(...args);
                    }
                },
                info: function (...args) {
                    if ($logProvider.debugEnabled()) {
                        $delegate.info(...args);
                        Logging.info(...args);
                    }
                },
                warn: function (...args) {
                    $delegate.warn(...args);
                    Logging.warn(...args);
                },
                error: function (...args) {
                    $delegate.error(...args);
                    Logging.error(...args);
                },
            };
            return methods;
        }]);
    }])
.service('Logging', ['$injector', function ($injector) {

        var EnvConfig = {};

        try {
            EnvConfig = $injector.get('EnvConfig');
        } catch (e) {
            Honeybadger.notify(e);
        }
        var toSendDiagnostic = function () {
            return EnvConfig.env === 'production';
        };
        var sendDiagnostic = function (level, error) {

            try {
                if (error instanceof Error) {
                    Honeybadger.notify(error);
                } else if (typeof error === 'string') {
                    Honeybadger.notify(
                        error,
                        {
                            fingerprint: error
                        }
                    );
                } else if (error && error.message) {
                    Honeybadger.notify(
                        error,
                        {
                            fingerprint: error.message
                        }
                    );
                } else {
                    Honeybadger.notify(JSON.stringify(error));
                }

            } catch (e) {
                // Catch all errors that happen here or else an error in here
                // will cause an infinite loop
                Honeybadger.notify(e);
            }
        };


        var service = {
            error: function (msg) {
                self.type = 'error';

                if (toSendDiagnostic()) {
                    sendDiagnostic('ERROR', msg);
                }
            },
            warn: function (msg) {
                self.type = 'warn';

                if (toSendDiagnostic()) {
                    sendDiagnostic('WARN', msg);
                }
            },
            info: function (msg) {
                self.type = 'info';

                // if (toSendDiagnostic()) {
                //     sendDiagnostic('INFO', arguments);
                // }
            },
            log: function (msg) {
                self.type = 'log';

                // if (toSendDiagnostic()) {
                //     sendDiagnostic('DEBUG', arguments);
                // }
            },
            debug: function (msg) {
                self.type = 'debug';

                // if (toSendDiagnostic()) {
                //     sendDiagnostic('DEBUG', arguments);
                // }
            },
            logs: [],
            LucovaWebSocket: undefined
        };

        return service;
    }]);
}
