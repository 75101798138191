'use strict';

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('ItemAvailabilityCtrl', [
        '$scope',
        '$modalInstance',
        '$timeout',
        '$translate',
        '$log',
        'LucovaWebSocket',
        'SmbPosService',
        'PosAlertService',
        'SharedDataService',
        'CommonOfflineCache',
        'Inventory',
        'Locations',
        'page',
        'searchResults',
        function (
            $scope,
            $modalInstance,
            $timeout,
            $translate,
            $log,
            LucovaWebSocket,
            SmbPosService,
            PosAlertService,
            SharedDataService,
            CommonOfflineCache,
            Inventory,
            Locations,
            page,
            searchResults) {

            $scope.searchResults = searchResults || [];

            $scope.dismiss = function () {
                $modalInstance.dismiss();
            };

            $scope.smbPos = SmbPosService;
            $scope.LucovaWebSocket = LucovaWebSocket;

            const PAGES = Object.freeze({
                FIRST_SHIFT_PAGE: 'firstShiftPage',
                ITEM_SEARCH_PAGE: 'itemSearchPage',
                ITEM_EDIT_PAGE: 'itemEditPage'
            });

            var timer;
            $scope.searchAction = () => {
                // activeOnly param set to null by default, false if first shift page
                const search = {
                    searchQuery: $scope.search.text,
                    locationId: $scope.locationDetail.locationId,
                    menuPeriodId: (CommonOfflineCache.getCurrentMenuPeriod()) ? CommonOfflineCache.getCurrentMenuPeriod().menuPeriodId : undefined,
                    itemsOnly: true,
                    activeOnly: true,
                    availableOnly: $scope.search.unavailableOnly ? false : null,
                    linkedModifiersOnly: true,
                };

                if (timer) {
                    $timeout.cancel(timer);
                }

                let timeout = timer ? 500 : 0;
                $scope.searching = true;
                timer = $timeout(function () {
                    SmbPosService.searchItems(search).$promise.then((response) => {
                        var sp = response.posMenuItemEntries[0];
                        var results = sp.entries.map((e) => {
                            return {item: e};
                        });

                        // filtering out category & modifier groups to return only items & modifier options
                        var filteredResults = _.filter(results, (result) => {
                            return result.item.type != 'category' && result.item.type != 'modifier';
                        });

                        $scope.searchResults.length = 0;
                        $scope.searchResults.push(...filteredResults);
                        $scope.searching = false;
                    }).catch(() => $scope.searching = false);
                }, timeout);
            };

            // used on main item search page, not used on first shift page
            // as first shift page marks item as available directly.
            $scope.selectedItem;
            $scope.selectItem = (item) => {
                $scope.selectedItem = item;
                $scope.page = PAGES.ITEM_EDIT_PAGE;
                $scope.pageTitle = item.name;
                $scope.pageDescription = $translate.instant('smb.pos.itemAvailabilityModal.header.edit.description', {
                    type: $scope.selectedItem.subtype,
                    available: $scope.selectedItem.available
                });

                if ($scope.selectedItem.subtype === 'modifier_option') {
                    let search = {
                        optionId: $scope.selectedItem.locationServicePeriodMenuId
                    };
                    Locations.getParentItemsFromModifier(search).$promise.then((response) => {
                        $scope.selectedItem.linkedItems = response;
                    });
                }
            };

            var updateMenuMobile = function (locationId) {
                // Update mobile order menu on backend
                Locations.updateMenuMobile({
                    locationId: locationId
                });
            };

            var updateMenuItemAvailability = function (item) {
                // These fields can be too big to transfer and are not used
                delete item.children;
                delete item.categories;
                delete item._parent;

                return SmbPosService.updateMenuItemAvailability(item).then(function (response) {
                        updateMenuMobile($scope.locationDetail.locationId);
                        return response;
                    })
                    .catch(function (error) {
                        PosAlertService.showError('error', 'general.error.edit-item-fail.ttl', '', 'The item could not be edited.');
                        $log.error(error);
                    });
            };

            // note that for first-shift page, users can mark availability directly without confirming.
            $scope.saveItem = function (item) {
                var itemToSave = angular.copy(item);

                itemToSave.available = !itemToSave.available;
                itemToSave.locationIdToUpdateFor = $scope.locationDetail.locationId;

                updateMenuItemAvailability(itemToSave).then((savedItem) => {
                    // update selected item's availability and re-fetch search results
                    // first shift page does not use a $scope.selectedItem as it doesn't
                    // require confirmation
                    if ($scope.page === PAGES.FIRST_SHIFT_PAGE) {
                        item.available = savedItem.available;
                    } else {
                        $scope.searchAction();
                    }

                    // manually update page description
                    if ($scope.page === PAGES.ITEM_EDIT_PAGE) {
                        $scope.pageDescription = $translate.instant('smb.pos.itemAvailabilityModal.header.edit.description', {
                            type: $scope.selectedItem.subtype,
                            active: $scope.selectedItem.available
                        });
                    }
                });
            };

            $scope.markAvailability = function (item) {
                var title;
                var message;

                if (item.available) {
                    title = $translate.instant('item.availability.confirmation.unavail.title');
                    message = $translate.instant('item.availability.confirmation.unavail.description');
                } else {
                    title = $translate.instant('item.availability.confirmation.avail.title');
                    message = $translate.instant('item.availability.confirmation.avail.description');
                }

                PosAlertService.showAlertByName('general-alert', {
                    title: title,
                    message: message,
                    buttonTitleOk: 'item.availability.confirmation.btn',
                    modalCallback: function () {
                        $scope.saveItem(item);
                    }
                });
            };

            $scope.returnToSearchPage = () => {
                // clear selectedItem
                delete $scope.selectedItem;
                $scope.selectedItem;

                // default page when item avail modal is opened.
                $scope.page = PAGES.ITEM_SEARCH_PAGE;
                $scope.pageTitle = $translate.instant('smb.pos.itemAvailabilityModal.header.title');
                $scope.pageDescription = $translate.instant('smb.pos.itemAvailabilityModal.header.description');
                focusOnSearch();
            };

            $scope.typeLabel = (item) => {
                if (item.subtype === 'modifier_option') {
                    return $translate.instant('smb.pos.itemAvailabilityModal.result.label.option');
                } else {
                    return $translate.instant('smb.pos.itemAvailabilityModal.result.label.item');
                }
            };

            var focusOnSearch = function () {
                window.setTimeout(function () {
                    document.getElementById('searchBar').focus();
                }, 0);
            };

            $scope.init = function () {
                $scope.search = {
                    text: null
                };
                $scope.locationDetail = SharedDataService.location;

                // default page when item avail modal is opened
                $scope.page = page;
                if ($scope.page === PAGES.ITEM_SEARCH_PAGE) {
                    $scope.pageTitle = $translate.instant('smb.pos.itemAvailabilityModal.header.title');
                    $scope.pageDescription = $translate.instant('smb.pos.itemAvailabilityModal.header.description');
                    focusOnSearch();
                    $scope.searchAction();
                } else if ($scope.page === PAGES.FIRST_SHIFT_PAGE) {
                    $scope.pageTitle = $translate.instant('smb.pos.itemAvailabilityModal.header.firstShift.title');
                    $scope.pageDescription = $translate.instant('smb.pos.itemAvailabilityModal.header.firstShift.description');
                }
            };

            $scope.init();
        }
    ]);
};
