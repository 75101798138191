'use strict';

// TODO check if this is still being used
// const angular = require('angular');
module.exports = function (freshideasLocation) {
  freshideasLocation.controller('LocationSidenav', [
    '$scope',
    '$modal',
    '$q',
    '$translate',
    '$location',
    'CashierShift',
    'LocationFactory',
    'Locations',
    'Company',
    'notificationTranslationHelper',
    'ErrorUtil',
    function (
        $scope,
        $modal,
        $q,
        $translate,
        $location,
        CashierShift,
        LocationFactory,
        Locations,
        Company,
        notificationTranslationHelper,
        ErrorUtil) {

        var init = function () {
            console.log('Started Sidenav');
        };

        init();
    }
    ]);
};
