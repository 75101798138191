'use strict';


module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosUpcLookupCtrl', [
        '$scope',
        'upc',
        'errMessage',
        'selectUpcItem',
        'addInventoryItem',
        function (
            $scope,
            upc,
            errMessage,
            selectUpcItem,
            addInventoryItem) {
            $scope.upc = upc;
            $scope.errMessage = errMessage;

            $scope.selectUpcItem = function () {
                if (!$scope.upc || $scope.upc.length < 2) {
                    $scope.errMessage = 'Invalid UPC. Please check the numbers and try again.';
                    return;
                }
                selectUpcItem($scope.upc, onUpcLookupError, onSuccess);
            };

            var onUpcLookupError = function (err) {
                $scope.errMessage = err;
            };

            var onSuccess = function (resultUpcItem) {
                addInventoryItem(resultUpcItem);
                $scope.$close();
            };
        }
    ]);
};
