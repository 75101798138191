'use strict';

const angular = require('angular');

module.exports = function (freshIdeasGatewayService) {
    freshIdeasGatewayService
        .factory('GatewayFiit', [
            '$rootScope',
            'CurrentSession',
            'FiitMpsResource',
            function (
                $rootScope,
                CurrentSession,
                FiitMpsResource) {

                var type = 'fiitmps';
                var service = {};
                var fiitLocation;

                /*
                Commented by Nick Simone 2022/03/02
                We need to store the FIIT service period in nown for use in the nown-fiit integration.
                We need to send it to FIIT with any transactions that are recorded there.
                */
                let servicePeriods = [];
                let servicePlans = [];
                let servicePlanMap = {};

                service.getServicePeriods = () => {
                    return servicePeriods;
                };

                service.getType = function () {
                    return type;
                };

                service.isEnabled = function () {
                    if (!service.fiitMps) {
                        return false;
                    }

                    return !(Object.keys(service.fiitMps).length === 0 && service.fiitMps.constructor === Object);
                };

                service.get = function (onSuccessCb) {
                    return FiitMpsResource.get().$promise
                        .then(async function (response) {
                            await onRefresh(response);
                            if (onSuccessCb) onSuccessCb();
                        });
                };

                service.sync = function () {
                    return FiitMpsResource.sync().$promise
                        .then(function (response) {
                            onRefresh(response);
                            return response;
                        });
                };

                service.link = function (request) {
                    var transformed = buildToken(request);
                    return FiitMpsResource.link({}, transformed).$promise
                        .then(function (response) {
                            onRefresh(response);
                            // If any Lucova errors occur in the linking process, they're included
                            // in the response here. These potential errors are passed back so that
                            // the error messages can be displayed on the UI
                            return response;
                        });
                };

                service.unlink = function () {
                    return FiitMpsResource.unlink().$promise
                        .then(function (response) {
                            onRefresh(response);
                            return response;
                        });
                };

                service.reloadLocation = async () => {
                    const response = await FiitMpsResource.getLocation();

                    if (response && response.success) {
                        fiitLocation = response.data || {};
                        servicePeriods = fiitLocation.servicePeriods || [];
                        servicePlans = fiitLocation.mealPlans || [];

                        for (const prop of Object.keys(servicePlanMap)) {
                            delete servicePlanMap[prop];
                        }

                        servicePlans.forEach((plan) => servicePlanMap[plan.mealPlanId] = plan);
                    }

                    return response;
                };

                const onRefresh = async function (response) {
                    if (response.gatewayType === 'FIITMPS') {
                        service.fiitMps = response.attributes;
                    } else {
                        service.fiitMps = {};
                    }
                    CurrentSession.setGateway('fiitmps', service.fiitMps);
                    await service.reloadLocation();
                    return service.fiitMps;
                };

                service.validateLink = function (credential) {
                    var errors = [];

                    var isTokenValidFormat = true;
                    try {
                        var transformed = buildToken(credential);
                        window.atob(transformed.accessToken);
                    } catch (e) {
                        isTokenValidFormat = false;
                    }

                    if (!isTokenValidFormat
                        || !credential.accessToken
                        || credential.accessToken.length === 0) {
                        errors.push({
                            type: 'gateway.fiitmps.error.linkSetup.invalidAccessToken.message'
                        });
                    }

                    return errors;
                };

                service.getInfo = function () {
                    return FiitMpsResource.getInfo();
                };

                service.getLocation = function () {
                    return fiitLocation;
                };


                service.getServicePlanMap = function () {
                    return servicePlanMap;

                };
                service.getAccount = FiitMpsResource.getAccount;

                service.getOfflineAccount = function (patronId) {
                    return {
                        patronId: patronId,
                        status: -1, // denote "offline"
                        mealPlanSummary: {
                            amountCents: 25000,
                            amountMeals: 1,
                            enabled: true,
                            offline: true
                        },
                    };
                };

                service.estimate = function (params) {
                    var estimateObj = angular.copy(params);
                    return FiitMpsResource.estimate(estimateObj);
                };

                service.initPreauth = function (params) {
                    var preauthObj = angular.copy(params);
                    return FiitMpsResource.initPreauth(preauthObj);
                };

                service.cancelPreauth = function (params) {
                    return FiitMpsResource.cancelPreauth(params);
                };

                service.completePreauth = function (params) {
                    return FiitMpsResource.completePreauth(params);
                };

                service.recordTransactionOnFiit = function (params) {
                    return FiitMpsResource.recordTransactionOnFiit(params);
                };

                service.parseGatewayResponse = function (response) {
                    return response;
                };

                service.void = function (params) {
                    return FiitMpsResource.void(params);
                };

                service.refund = function (params) {
                    return FiitMpsResource.refund(params);
                };

                service.getLastTransaction = (patronId) => {
                    return FiitMpsResource.getLastTransaction(patronId);
                };

                service.getUnexpectedErrorCodes = () => [
                    1000, // Unexpected Error
                    1001, // Unauthorized
                    // 1002 Patron Not Found, but this is expected (e.g. looking up a half-completed patron key) and is handled case by case
                    1003, // Invalid patron search
                    1004, // No Company timezone configured
                ];

                var init = function () {
                    service.get();
                };

                $rootScope.$on('auth-loginConfirmed', function (event, user) {
                    init();
                });

                var buildToken = function (credential) {
                    var build = angular.copy(credential);

                    build.accessToken = build.accessToken || '';
                    if (build.accessToken.substr(-1) !== '=') {
                        // build.accessToken = build.accessToken + '=';
                    }

                    return build;
                };

                return service;
            }
        ]);
};
