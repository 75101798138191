'use strict';

const angular = require('angular');
const ngResources = require('angular-resource');

export default angular.module('freshideas.resources.logger', [ngResources])
    .service('ErrorLogging', ['$resource', 'EnvConfig', function ($resource, EnvConfig) {
        return $resource(EnvConfig.host + '/freshideas/web/logger', {}, {
            'log': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/logger/logError',
                isArray: true
            }
        });
    }]);
