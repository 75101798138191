'use strict';

const angular = require('angular');

export default angular
    .module('freshideas.resources.event-listener', [])
    .factory('EventListenerService', [function (
        ) {
        var _self = {};
        var events = {};

        _self.create = function (identifier, eventName, callBack) {
            if (!events[identifier]) {
                var event = {
                    eventName: eventName,
                    callBack: callBack
                };

                events[identifier] = event;

                window.addEventListener(eventName, event.callBack);
            }
        };

        _self.destroy = function (identifier) {
            var event = events[identifier];

            if (event) {
                window.removeEventListener(event.eventName, event.callBack);

                events[identifier] = null;
            }
        };

        return _self;
    }]);
