'use strict';

const angular = require('angular');
const INTERVAL = 1000 * 60;
const WORKER_INTERVAL = 1000 * 15;
const MAX_INTERVAL = 1000 * 60 * 60;

export default angular
    .module('freshideas.resources.offline-email', [])
    .factory('OfflineEmailSignupService', [
        'SmbPosService',
        'CashierShift',
        '$log',
        function (SmbPosService, CashierShift, $log) {
            var service = {};

            service.pushSignup = function (signupObj, tenderAmounts, callBack) {
                // inject variables
                signupObj.timestamp = Date.now();
                signupObj.offline = true;
                signupObj.attempts = 0;
                signupObj.nextRetry = signupObj.timestamp + INTERVAL;

                service.push('cashiershift.emailSignup', {signup: signupObj, tenderAmounts: tenderAmounts});

                if (callBack) {
                    callBack();
                }
                return signupObj;
            };

            service.put = function (key, val) {
                try {
                    localStorage.setItem(key, JSON.stringify(val));
                } catch (err) {
                     $log.error({
                        message: 'Error while writing to Local Storage',
                        context: {
                            error: err || {}
                        }
                    });
                }
            };

            service.get = function (key) {
                var value = localStorage.getItem(key);
                return value && JSON.parse(value);
            };

            service.delete = function (key) {
                return localStorage.removeItem(key);
            };
            /*
            * convenience function for using the get() and set() methods to
            * push to an array.
            */

            service.push = function (key, value) {
                var arr = service.get(key);
                if (!arr || !(arr instanceof Array)) {
                    arr = [];
                }
                arr.push(value);
                service.put(key, arr);
            };

            var beginProcessingSignups = function () {
                var signupAndTenderAmounts = service.get('cashiershift.emailSignup');

                if (!signupAndTenderAmounts || !signupAndTenderAmounts.length) {
                    return;
                }
                service.delete('cashiershift.emailSignup');

                signupAndTenderAmounts.forEach((signupAndTenderAmount) => {
                    if (signupAndTenderAmount == undefined) {
                        return;
                    }
                    if (signupAndTenderAmount.signup.nextRetry > Date.now()) {
                        service.push('cashiershift.emailSignup', signupAndTenderAmount);
                        return;
                    }
                    signupPatron(signupAndTenderAmount);
                });
            };

            var handleError = function (signupAndTenderAmount, error) {
                signupAndTenderAmount.attempts = signupAndTenderAmount.attempts ? signupAndTenderAmount.attempts + 1 : 1;

                let multi = INTERVAL * signupAndTenderAmount.attempts;
                multi = (multi < MAX_INTERVAL) ? multi : MAX_INTERVAL;
                signupAndTenderAmount.nextRetry = Date.now() + multi;


                if (multi < MAX_INTERVAL) {
                    if (signupAndTenderAmount.attempts == 3) {
                        $log.error({
                            message: 'Signup failed 3 times',
                            context: {
                                signup: signupAndTenderAmount,
                                error: error || {}
                            }
                        });
                    }
                } else {
                    if (signupAndTenderAmount.attempts % 5 == 0) {
                        $log.error({
                            message: 'Signup failed ' + signupAndTenderAmount.attempts + ' times',
                            context: {
                                email: signupAndTenderAmount,
                                error: error || {}
                            }
                        });
                    }
                }

                service.push('cashiershift.emailSignup', signupAndTenderAmount);
            };

            var signupPatron = function (signupAndTenderAmount) {
                SmbPosService.signupNewPatron(signupAndTenderAmount.signup).then(function (response) {
                    console.log('Offline signup posted correctly');
                    SmbPosService.linkGuestToNewPatron(response, signupAndTenderAmount.tenderAmounts).then(function () {
                        SmbPosService.emailReceipt(signupAndTenderAmount.tenderAmounts);
                    }).catch(function (error) {
                        if (error.status < 400 || error.status >= 500) {
                            handleError(signupAndTenderAmount, error);
                        }
                    });
                }, function (error) {
                    if (error.status < 400 || error.status >= 500) {
                        handleError(signupAndTenderAmount, error);
                    }
                });
            };

            // Begin scheduled task to process emails
            setInterval(() => {
                beginProcessingSignups();
            }, WORKER_INTERVAL);

            return service;
        }]);
