'use strict';

const angular = require('angular');
const moment = require('moment');

module.exports = function (freshideasReports) {
    freshideasReports.controller('MobileTransactionsReportCtrl', [
          '$scope',
          '$timeout',
          'Reports',
          'Export',
          'Lookup',
          'Meals',
          'Security',
          'Calculate',
          'DateRangeService',
          'USER_ROLE_TYPE',
          function ($scope, $timeout, Reports, Export, Lookup, Meals, Security, Calculate, DateRangeService, USER_ROLE_TYPE) {
              var currentUser = Security.getUser() || {};
              $scope.isManager = currentUser.roleType === USER_ROLE_TYPE.MANAGER;
              $scope.isAccountant = currentUser.roleType === USER_ROLE_TYPE.ACCOUNTANT;
              $scope.isSiteAdmin = currentUser.permission === 'SITEADMIN';
              $scope.searchDisabled = false;
              var allowRetry;
              var refreshLocations = function (companies) {
                  if (companies) {
                      $scope.locations = _.flatten(_.map(companies, function (company) {
                          return company.children;
                      }), true);
                  }
              };
              var tick = function () {
                  $scope.currentDateTime = moment();
                  $timeout(tick, 60 * 1000);
              };
              $timeout(tick, 60 * 1000);
              $scope.onCompanyChange = function () {
                  var companyId = $scope.reportSearch.companyId;
                  if (companyId) {
                      var foundCompany = _.find($scope.companyHierarchy, function (company) {
                          return company.id === companyId;
                      });
                      if (foundCompany) {
                          refreshLocations([foundCompany]);
                      }
                  } else {
                      var companyHierarchy = angular.copy($scope.companyHierarchy);
                      refreshLocations(companyHierarchy);
                  }
              };
              $scope.onLocationChange = function () {
              };
              $scope.opened = {};
              $scope.toggleFromDatePicker = function ($event) {
                  $event.preventDefault();
                  $event.stopPropagation();
                  var status = !!$scope.opened.from;
                  var newStatus = !status;
                  $scope.opened.from = newStatus;
                  if (newStatus) {
                      $scope.opened.to = false;
                  }
              };
              $scope.toggleToDatePicker = function ($event) {
                  $event.preventDefault();
                  $event.stopPropagation();
                  var status = !!$scope.opened.to;
                  var newStatus = !status;
                  $scope.opened.to = newStatus;
                  if (newStatus) {
                      $scope.opened.from = false;
                  }
              };
              $scope.dateOptions = {
                  formatYear: 'yy',
                  startingDay: 1
              };
              $scope.clearFilters = function (isAdvancedSearch) {
                  $scope.reportSearch = {isAdvancedSearch: !!isAdvancedSearch};
                  $scope.reportSearch.fromDate = moment().startOf('week').toDate();
                  $scope.reportSearch.toDate = moment().endOf('day').toDate();
                  $scope.reportSearch.locationId = currentUser.company.locationId;
              };
              $scope.clearFilters();
              $scope.lookupEntity = function (type, id) {
                  if (!$scope.baseSettings) {
                      return;
                  }
                  var entities = $scope.baseSettings[type] || [];
                  var foundEntity = _.find(entities, function (entity) {
                      return entity.id === id;
                  }) || {};
                  return foundEntity.name || '';
              };
              function lookupCompanyHierarchy () {
                return Lookup.locationsByCompany({}, function (response) {
                    $scope.company = _.findWhere(response, {id: currentUser.companyId});
                    $scope.locations = [];
                    let children = ($scope.company && $scope.company.children) ? $scope.company.children : [];

                    if (children.length) {
                        if (children.length > 1) {
                            $scope.locations.push({
                                id: null,
                                name: 'All Locations'
                            });
                        }
                        _.each(children, function (location) {
                            $scope.locations.push(location);
                        });
                        $scope.reportSearch.locationId = currentUser.company.locationId;
                    }
                });
            }
              var convertReport = function (rawReport) {
                  var report = angular.copy(rawReport);
                  report = _(report).chain().sortBy(function (location) {
                      return location.locationId;
                  }).sortBy(function (location) {
                      return location.campusId;
                  }).value();
                  return report;
              };
              $scope.getMobileTransactionsReport = function (isAdvancedSearch) {
                  $scope.searchDisabled = true;
                  clearTimeout(allowRetry);
                  allowRetry = setTimeout(function () {
                      $scope.searchDisabled = false;
                  }, 30000);
                  var reportSearch = {};
                  reportSearch.startDateTime = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                  reportSearch.endDateTime = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                  $scope.currentDateTime = moment();
                  if ($scope.reportSearch.companyId) {
                      reportSearch.companyId = $scope.reportSearch.companyId;
                  }
                  if ($scope.reportSearch.locationId) {
                      reportSearch.locationId = $scope.reportSearch.locationId;
                  }
                  $scope.searching = true;
                  Reports.getMobileTransactionsReport(reportSearch, function (response) {
                      var summary = response.mobileTransactionsSummary || {};
                      var results = summary.locations || [];
                      $scope.results = convertReport(results);
                      var currentReportSearch = {
                          fromDate: $scope.reportSearch.fromDate,
                          toDate: $scope.reportSearch.toDate,
                          companyName: $scope.lookupEntity('companies', $scope.reportSearch.companyId),
                          locationName: $scope.lookupEntity('locations', $scope.reportSearch.locationId),
                          isAdvancedSearch: isAdvancedSearch
                      };
                      currentReportSearch = _.pick(currentReportSearch, function (value) {
                          return !!value;
                      });
                      if (_.isEmpty(currentReportSearch)) {
                          $scope.currentReportSearch = undefined;
                      } else {
                          $scope.currentReportSearch = currentReportSearch;
                      }
                      $scope.reportSearch = $scope.reportSearch || {};
                      $scope.reportSearch.isAdvancedSearch = isAdvancedSearch;
                      $scope.initialSearch = false;
                      $scope.searching = false;
                  });
              };
              $scope.saveSelectedFromDate = function () {
                  var selectedFromDate = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                  DateRangeService.setFromDate(selectedFromDate);
              };
              $scope.saveSelectedToDate = function () {
                  var selectedToDate = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                  DateRangeService.setToDate(selectedToDate);
              };

              $scope.init = function () {
                  var fromDate;
                  var toDate;
                  if (DateRangeService.getFromDate()) {
                      fromDate = moment(DateRangeService.getFromDate()).startOf('day').toDate();
                  } else {
                      fromDate = moment().startOf('week').toDate();
                  }
                  if (DateRangeService.getToDate()) {
                      toDate = moment(DateRangeService.getToDate()).endOf('day').toDate();
                  } else {
                      toDate = moment().endOf('day').toDate();
                  }
                  $scope.reportSearch = {
                      fromDate: fromDate,
                      toDate: toDate,
                      isAdvancedSearch: true
                  };
                  lookupCompanyHierarchy().$promise.then(function () {
                    $scope.getMobileTransactionsReport(true);
                  });
              };
              $scope.init();
              $scope.exportToPdf = function (tableId) {
                  Export.tableToPdf(tableId, 'portrait');
              };
              $scope.initialSearch = true;
              $scope.searching = false;

              $scope.$on('$destroy', function () {
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.locationId', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.fromDate', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.toDate', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });
          }
     ]);
};
