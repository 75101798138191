'use strict';

export function pingPongService (freshideas) {

    freshideas.service('PingPong', [
        '$timeout',
        'Lookup',
        'MonitoringService',
        'PosStatusService',
        'SolinkService',
        '$log',
        function ($timeout, Lookup, MonitoringService, PosStatusService, SolinkService, $log) {
            var pingPongCount = 0;
            var totalDuration = 0;
            var averageDuration = 0;

            var startHeartBeat = function () {
                $timeout(function () {
                    var data = {
                        shiftStarted: PosStatusService.shiftStarted
                    };

                    // this is a Chrome-only functionality
                    if (window.performance && window.performance.memory) {
                        var usedJSHeapSize = window.performance.memory.usedJSHeapSize / 1024 / 1024;
                        data['memory'] = {
                            usedJSHeapSize: usedJSHeapSize
                        };

                        MonitoringService.add(
                            'memory-usage',
                            usedJSHeapSize,
                            {
                                data: data,
                                terminalId: localStorage.terminalId,
                            }
                        );
                    }

                    var pingPongStartTime = new Date();
                    Lookup.pingPong(data, function () {
                        PosStatusService.setOffline(false);
                        // send offline solink data
                        try {
                            SolinkService.sendEventQueue();
                        } catch (err) {
                            $log.error('Could not send Solink offline queue');
                        }
                        var pingPongEndTime = new Date();
                        var duration = pingPongEndTime - pingPongStartTime;
                        totalDuration += duration;
                        pingPongCount++;

                        // Determine connectivity based on the average duration of three pingpong requests
                        if (pingPongCount >= 3) {
                            averageDuration = totalDuration / pingPongCount;
                            if (averageDuration > 500) {
                                PosStatusService.setLowConnectivity(true);
                            } else {
                                PosStatusService.setLowConnectivity(false);
                            }
                            totalDuration = 0;
                            pingPongCount = 0;
                            averageDuration = 0;
                        }
                    }, function (error) {
                        if (error.status <= 0 || error.status > 500) {
                            PosStatusService.setOffline(true);
                        } else {
                            PosStatusService.setOffline(false);
                        }
                    });

                    startHeartBeat();
                }, 10000);
            };

            var service = {
                startHeartBeat: startHeartBeat
            };

            return service;
        }
    ]);
}
