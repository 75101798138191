'use strict';

module.exports = function (posModule) {

    posModule
        .controller('PosTransactionRefundStartCtrl', [
            '$scope',
            '$state',
            'SmbPosService',
            'PosAlertService',
            function ($scope,
                $state,
                SmbPosService,
                PosAlertService) {
                $scope.newShift = {};

                var isShiftFormDisabled = false;
                $scope.startShift = function () {
                    if (isShiftFormDisabled) {
                        return;
                    }

                    isShiftFormDisabled = true;
                    SmbPosService.startShift($scope.newShift).then(function () {
                        isShiftFormDisabled = false;
                        $scope.newShift = {};
                    }).catch(function () {
                        isShiftFormDisabled = false;
                    });
                };

                $scope.loadPosStations = function () {
                    SmbPosService.loadPOSStations($scope.newShift.locationId).then(function () {

                    });
                };

                $scope.loadServicePeriods = function () {
                    SmbPosService.loadServicePeriods($scope.newShift.locationId).then(function () {

                    });
                };

                $scope.init = function () {
                    $scope.shift = SmbPosService.shift;
                    $scope.data = SmbPosService.data;
                };
                $scope.init();
            }
        ]);
};

