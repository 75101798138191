'use strict';

const angular = require('angular');
const moment = require('moment');

/* Filters */

export default angular.module('freshideas.filters', [])

    .filter('interpolate', ['version', function (version) {
        return function (text) {
            return String(text).replace(/\%VERSION\%/mg, version);
        };
    }])

    // Used for simple data paging
    .filter('startFrom', function () {
        return function (input, start) {
            if (!input) {
                return;
            }
            start = +start; //parse to int
            return input.slice(start);
        };
    })

    // Grabs a page of data from an array
    .filter('dataPage', function () {
        return function (input, start, howMany) {
            if (!input) {
                return;
            }
            start = +start;
            return input.slice(start, (start + howMany));
        };
    })

    .filter('yesNo', function () {
        return function (input) {
            return (!angular.isDefined(input) || input === false || input === 0) ? 'general.no' : 'general.yes';
        };
    })

    .filter('toTrueFalse', function () {
        return function (input) {
            return (!angular.isNumber(input) || input === false || input === 0) ? false : true;
        };
    })

    .filter('percentage', ['$filter', function ($filter) {
        return function (input, decimals) {
            return $filter('number')(input, decimals) + '%';
        };
    }])

    .filter('trimSting', ['$filter', function ($filter) {
        return function (input, length = 10) {
            if (!input) {
                return '';
            }

            if (input.length > length) {
                return input.substr(0, length) + '...';
            }

            return input;
        };
    }])

    .filter('meal', function () {
        return function (input) {
            if (input === 1) {
                return input + ' meal'
            } else {
                return input + ' meals'
            }
        };
    })

    .filter('currency', ['$filter','CurrentSession', 'SharedDataService', function ($filter, CurrentSession, SharedDataService) {
        return function (input, currency) {

            var locale = CurrentSession.getCompany().locale;
            if (!locale) {
                if (window.navigator) {
                   if (window.navigator.languages) {
                       locale = window.navigator.languages[0];
                   } else {
                       locale = window.navigator.userLanguage || window.navigator.language;
                   }
                } else {
                    locale = 'en-US'
                }
            }

            var baseCurrency = currency || SharedDataService.currencyCode || 'CAD';

            var formatStr = new Intl.NumberFormat(locale, { style: 'currency', currency: baseCurrency }).format(input);
            return formatStr.replace(/ /g, ' '); // replace no-break space (char code 160) with regular space (char code 32)
        };
    }])

    .filter('seconds', ['$filter', function ($filter) {
        return function (input) {
            if (input < 60) {
                return $filter('number')(input, 2) + ' s';
            } else {
                return $filter('number')((input / 3600), 1) + ' hrs';
            }
        };
    }])

    .filter('timeDuration', ['$filter', function ($filter) {
        return function (input, unit) {
            unit = unit || 'seconds';
            return moment.duration(input, unit).humanize();
        };
    }])

    .filter('dateTimeFormatter', ['$filter', function ($filter) {
        return function (input, inputFormat, outputFormat) {
            // Commented By Akash Mehta on December 16 2020
            // The code below depends on the error handling behaviour implemented
            // by the moment library itself. Tested with various inputs
            var inputMoment = moment(input, inputFormat);
            return inputMoment.format(outputFormat);
        };
    }])

    .filter("locationNameFilter", ['$filter', function($filter) { // register new filter
        return function(inputLocationId, locations) { // filter arguments
            var locationFound = _.filter(locations, function (location) { 
                return location.id == inputLocationId; 
            });

            if(!locationFound || locationFound.length == 0) {
                return '';
            } else {
                return locationFound[0].name;
            }
        };
    }])

    .filter("shortLocationNameFilter", ['$filter', function($filter) { // register new filter
        return function(inputLocationId, locations) { // filter arguments
            var locationFound = _.filter(locations, function (location) { 
                return location.id == inputLocationId; 
            });
    
            if(!locationFound || locationFound.length == 0) {
                return '';
            } else {
                var name = locationFound[0].name;
                var strArr = name.split('');
                var index = (strArr.indexOf('-') + 1);
                
                // take location name only if a hyphen is included
                // e.g. 'Real Fruit Bubble Tea - Toronto Eaton Centre' should display as
                // 'Toronto Eaton Centre'
                if (index > 0) {
                    index++;

                    // checking for a space after hyphen because some locations/clients
                    // may not have space between hyphen and location name
                    // e.g. 'Real Fruit Bubble Tea-Toronto EatonCentre'
                    // we need to remove this empty space if it does.
                    if (strArr[index] === ' ') {
                        index++;
                    }

                    var shortenedName = strArr.splice(index, strArr.length).join('');
                    return shortenedName;
                } else {
                    return name;
                }
            }
        };
    }])

    .filter("locationNameByCompanyFilter", ['$filter', function($filter) { // register new filter
        return function(inputCompanyId, locations) { // filter arguments
            var locationFound = _.filter(locations, function (location) {
                return location.companyId == inputCompanyId;
            });

            if(!locationFound || locationFound.length == 0) {
                return '';
            } else {
                return locationFound[0].name;
            }
        };
    }])

    .filter('hrsMinsSecs', ['$filter', function ($filter) {
        return function (s) {
            var h = Math.floor(s / ( 60 * 60 ));
            s -= h * ( 60 * 60 );
            var m = Math.floor(s / 60);
            s -= m * 60;

            if (h > 0 && m > 0 && s > 0) {
                return h + ' hrs, ' + m + ' mins, ' + s + ' secs';
            }
            if (h > 0 && m > 0) {
                return h + ' hrs, ' + m + ' mins';
            }
            if (h > 0 && s > 0) {
                return h + ' hrs, ' + s + ' secs';
            }
            if (m > 0 && s > 0) {
                return m + ' mins, ' + s + ' secs';
            }
            if (h > 0) {
                return h + ' hrs';
            }
            if (m > 0) {
                return m + ' mins';
            }
            return s + ' secs';
        };
    }])

    // ng-repeat orderBy doesn't work on objects, this does when objects are used as hash arrays
    .filter('orderObjectBy', function () {
        return function (items, field, reverse) {
            // Push into array
            var filtered = [];
            angular.forEach(items, function (item) {
                filtered.push(item);
            });

            // Determine property type
            var isNumber;
            if (filtered.length < 1 || !angular.isDefined(filtered[0][field])) {
                return items;
            } else if (angular.isNumber(filtered[0][field])) {
                isNumber = true;
            } else  {
                isNumber = false;
            }

            if (isNumber) {
                filtered.sort(function (a, b) {
                    return a[field] - b[field];
                });
            } else {
                filtered.sort(function (a, b) {
                    if (a[field] > b[field]) {
                        return 1;
                    }
                    if (a[field] < b[field]) {
                        return -1;
                    }
                    return 0;
                });
            }

            if (reverse) {
                filtered.reverse();
            }

            return filtered;
        };
    })

    .filter('arrayToCsv', function () {
        return function (items) {
            var result = items;
            if (angular.isDefined(items) && angular.isArray(items)) {
                result = items.sort().join(', ');
            }

            return result;
        };
    })

    .filter('abs', function () {
        return function (value) {
            return Math.abs(value);
        };
    })

    .filter('titleCase', function() {
        return function (input) {
            var smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i;

            if(!input) {
                return '';
            }

            input = input.toLowerCase();
            return input.replace(/[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g, function(match, index, title) {
                if (index > 0 && index + match.length !== title.length &&
                    match.search(smallWords) > -1 && title.charAt(index - 2) !== ":" &&
                    (title.charAt(index + match.length) !== '-' || title.charAt(index - 1) === '-') &&
                    title.charAt(index - 1).search(/[^\s-]/) < 0) {
                    return match.toLowerCase();
                }

                if (match.substr(1).search(/[A-Z]|\../) > -1) {
                    return match;
                }

                return match.charAt(0).toUpperCase() + match.substr(1);
            });
        }
    })

    // Source: http://tianes.logdown.com/posts/2015/12/08/rounding-a-number-to-the-nearest-neighbour-up-and-down
    .filter('round', function () {
        /* Use this $filter to round Numbers UP, DOWN and to his nearest neighbour.
           You can also use multiples */

        /* Usage Examples:
            - Round Nearest: {{ 4.4 | round }} // result is 4
            - Round Up: {{ 4.4 | round:'':'up' }} // result is 5
            - Round Down: {{ 4.6 | round:'':'down' }} // result is 4
            ** Multiples
            - Round by multiples of 10 {{ 5 | round:10 }} // result is 10
            - Round UP by multiples of 10 {{ 4 | round:10:'up' }} // result is 10
            - Round DOWN by multiples of 10 {{ 6 | round:10:'down' }} // result is 0
        */
        return function (value, mult, dir) {
            dir = dir || 'nearest';
            mult = mult || 1;
            value = !value ? 0 : Number(value);
            if (dir === 'up') {
                return Math.ceil(value / mult) * mult;
            } else if (dir === 'down') {
                return Math.floor(value / mult) * mult;
            } else {
                return Math.round(value / mult) * mult;
            }
        };
    })

    /* Filter for formatting string dates utilizing the moment.js lib) */
    .filter('moment', ['$filter', function ($filter) {
        return function (dateString, format) {
            return moment(dateString, format).format('L');
        };
    }]);
