'use strict';


const angular = require('angular');
const ngResources = require('angular-resource');

export default angular.module('freshideas.resources.reports', [ngResources])
    .service('Reports', ['$resource', 'EnvConfig', function ($resource, EnvConfig) {
        return $resource(EnvConfig.host + '/freshideas/web/reports', {}, {
            'getCampusStats': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/campusStats',
                isArray: true
            },
            'getOverview': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/overview',
                isArray: true
            },
            'getExchangeRates': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/exchangeRates',
            },
            'getTransactionHistory': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/transactionHistory',
                isArray: true
            },
            'getSummaryReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/summaryReport'
            },
            'getSalesReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/salesSummary',
                isArray: true
            },
            'getDailySalesMealsServed': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/dailySales',
                isArray: true
            },
            'getPatronCounts': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/patronCount',
                // isArray: true
            },
            'get15minutesOverview': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/15minuteOverview',
                isArray: true
            },
            'getPatronActivity': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/patronActivity'
            },
            'getActivePatrons': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/activePatrons',
                isArray: true
            },
            'getEndOfShiftReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/endOfShiftV2',
                isArray: true
            },
            'previousEndOfShiftReports': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/previousEndOfShiftReports',
                isArray: true
            },
            'getDepositRefundReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/depositRefund',
            },
            'getSessionAuditReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/sessionAudit'
            },
            'getLocationRevenueReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/locationRevenue'
            },
            'getMobileTransactionsReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/mobileTransactions'
            },
            'getPatronMealPlanHistoryReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/patronMealPlanHistory'
            },
            'getOverviewItemSalesReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/overviewItemSales',
                isArray: true
            },
            'getOverviewTopCategories': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/overviewTopCategories',
                isArray: true
            },
            'getDatedItemSalesReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/datedItemSales',
                isArray: true
            },
            'getAdvancedItemSalesReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/advancedItemSales',
                isArray: true
            },
            'getPatronSpendReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/patronSpend',
                isArray: true
            },
            'getParticipationRateReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/participationRate',
                isArray: true
            },
            'getMealPlanSalesReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/mealPlanSales',
                isArray: true
            },
            'getMobileOrderReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/mobileOrder'
            },
            'getEmployeeShiftsReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/employeeShifts',
                isArray: true
            },
            'getLoyaltyActivityReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/loyaltyActivity',
            },
            'exportTransactions': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/transactionHistory/export'
            },
            'exportPdf': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/reports/export/pdf',
                transformResponse: function (data) {
                    return angular.fromJson({data: data});
                }
            },
            'exportExcel': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/downloadSalesSummary',
            },
            'getLossPreventionReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/lossPrevention'
            },
            'getDiscountsReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/discounts',
                isArray: true
            },
            'getDiscountTransactions': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/discount/transactions',
                isArray: true
            },
            'getDiscountTransactionItems': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/discount/transactionItems',
                isArray: true
            },
            'getLabourReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/labour',
                isArray: true
            },
            'getLabourSubSales': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/labour/subSales',
                isArray: true
            },
            'getGiftCardReport': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/giftCard'
            },
            'getGiftCardTransactions': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/giftCard/transactions',
                isArray: true
            },
            'getGiftCardSegmentation': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/giftCard/segmentation',
            },
            'getItemSalesTimeBreakdown': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/reports/itemSalesTimeBreakdown'
            }
        });
    }]);
