'use strict';

const CanvasCompress = require('canvas-compress');

module.exports = function (freshideasProducts) {
    freshideasProducts.controller('MasterEditCtrl', [
        '$scope',
        '$modal',
        'MenuService',
        'PosAlertService',
        'FileUploader',
        'item',
        'organizationLanguages',
        'menus',
        function (
            $scope,
            $modal,
            MenuService,
            PosAlertService,
            FileUploader,
            item,
            organizationLanguages,
            menus) {

            // TODO: check if this is still required after getting fresh data on item click.
            // a deep copy must be performed on item and menus.
            // this is because we dont want to taint the parent data if the user closes the modal without saving
            $scope.item = angular.copy(item);
            $scope.organizationLanguages = organizationLanguages;
            $scope.menus = angular.copy(menus);

            // used to temporarily disable image uploads on iPad due to lack of camera permission
            $scope.platform = navigator.platform;

            const ITEM_TYPES = $scope.ITEM_TYPES = Object.freeze({
                CATEGORY: 'category',
                ITEM: 'item',
                MODIFIER_GROUP: 'modifier',
                MODIFIER_OPTION: 'modifier_option',
            });

            const UploadStatus = Object.freeze(
                {
                    'NONE': 1,
                    'PREPARED': 2,
                    'COMPLETED': 3,
                    'ERROR': 4,
                    'DELETED': 5
                }
            );

            $scope.pageSettings = {
                itemType: ITEM_TYPES.ITEM,
                isSavingItem: false
            };

            $scope.closeModal = () => {
                $scope.$close();
            };


            let modalPromise;
            const setModal = (config) => {
                if (modalPromise && !modalPromise.isPending) {
                    return Promise.reject();
                }

                var modalInstance = $modal.open(config, function (error) {
                    unsetModal();
                });
                modalPromise = modalInstance.result;

                return modalPromise;
            };
            const unsetModal = () => {
                modalPromise = undefined;
            };

            // open a modal to copy the item from a selected menu.
            // the param indicates which menu this item should be associated with.
            // the response from the modal indicates which menu to copy the item from.
            // to be more verbose, we use copy the item from menuToCopyFrom to menuToAssociateWith
            const openMenuAssociationModal = (menuToAssociateWith) => {
                return setModal({
                    templateUrl: 'products/menuV2/templates/product.master.association.tpl.html',
                    controller: 'MasterAssociationCtrl',
                    windowClass: 'modal-80 master-product__associations-modal',
                    animation: true,
                    // backdrop: 'static'
                    resolve: {
                        menus: () => {
                            // pass the original associated menus.
                            // this is to ensure an item can be copied from.
                            return $scope.associatedMenusOriginal;
                        }
                    }
                }).then((menuToCopyFrom) => {
                    if (menuToCopyFrom) {
                        menuToAssociateWith.menuToCopyFrom = menuToCopyFrom;
                    }
                    unsetModal();
                }).catch(() => {
                    unsetModal();
                });
            };

            $scope.selectMenuToCopyFrom = (menu) => {
                // only open menu association modal if selecting to associate with
                // another menu.
                if (menu.associated) {
                    openMenuAssociationModal(menu);
                }
            };

            // validation to ensure there is at least 1 menu association before proceeding.
            const validateItemName = () => {
                const defaultLanguage = $scope.organizationLanguages.find((language) => language.defaultLanguage);
                const translations = $scope.item.translations;

                // return boolean based on whether or not the default language contains an item name
                return !!(translations.hasOwnProperty(defaultLanguage.languageId) && translations[defaultLanguage.languageId].itemName);
            };

            // show generic modal when an error occurs on saving item.
            const showItemSaveError = () => {
                PosAlertService.showAlertByName('general-error', {
                    title: 'product.masterProductModal.save.error.ttl',
                    message: 'product.masterProductModal.save.error.desc'
                });
            };

            $scope.saveItem = () => {
                // ensure the default language contains an item name.
                if (!validateItemName()) {
                    PosAlertService.showAlertByName('general-error', {
                        title: 'product.masterProductModal.itemName.error.ttl',
                        message: 'product.masterProductModal.itemName.error.ttl'
                    });
                    return;
                }

                // ensure there is at least 1 menu association before proceeding.
                if (!MenuService.validateMenuAssociations($scope.menuAssociations)) {
                    PosAlertService.showAlertByName('general-error', {
                        title: 'product.masterProductModal.menuAssociations.error.ttl',
                        message: 'product.masterProductModal.menuAssociations.error.desc'
                    });
                    return;
                }

                // double confirmation to delete item image
                if ($scope.imageDeleteConfirmation) {
                    const languageImageMap = $scope.item.languageImageMap;
                    const toDelete = [];
                    for (const key in languageImageMap) {
                        if (languageImageMap.hasOwnProperty(key)) {
                            if (languageImageMap[key].imageDeleteConfirmation) {
                                toDelete.push(languageImageMap[key].menuItemImageId);
                            }
                        }
                    }

                    MenuService.deleteMenuItemImages($scope.item, toDelete).catch((err) => {
                        PosAlertService.showAlertByName('general', {
                            title: 'product.item.image.delete.error.title',
                            message: err.data.error
                        });
                    });
                }

                if ($scope.uploadStatus === UploadStatus.PREPARED) {
                    $scope.beginUpload();
                }

                $scope.pageSettings.isSavingItem = true;
                MenuService.saveItemOnMasterProductList($scope.item)
                .then(() => {
                    MenuService.copyItemFromMenus($scope.item.locationServicePeriodMenuId, $scope.menuAssociations)
                    .then(() => {

                        // filter & map only those with .associated as false
                        const filteredMenuIds = $scope.menuAssociations
                        .filter((menu) => !menu.associated)
                        .map((menu) => {
                            return menu.menuId;
                        });

                        MenuService.deleteItemFromMenus($scope.item.locationServicePeriodMenuId, filteredMenuIds, $scope.item.children)
                        .then(() => {
                            $scope.pageSettings.isSavingItem = false;
                            $scope.closeModal();
                        }).catch((err) => {
                            showItemSaveError();
                            $scope.pageSettings.isSavingItem = false;
                            return;
                        });
                    }).catch((err) => {
                        showItemSaveError();
                        $scope.pageSettings.isSavingItem = false;
                        return;
                    });
                }).catch((err) => {
                    showItemSaveError();
                    $scope.pageSettings.isSavingItem = false;
                    return;
                });
            };

            const setItemType = (item) => {
                // only modifier option has a subtype of 'modifier_option'
                if (item.subtype === ITEM_TYPES.MODIFIER_OPTION) {
                    $scope.pageSettings.itemType = ITEM_TYPES.MODIFIER_OPTION;
                } else if (item.type) {
                    $scope.pageSettings.itemType = item.type;
                }
            };

            $scope.menuAssociations = [];

            const setMenuAssociations = () => {
                $scope.menus.all.forEach((menu) => {
                    const liteMenu = {
                        menuId: menu.menuId,
                        menuName: menu.menuName,
                        priceCents: 0,
                        associated: false
                    };
                    $scope.menuAssociations.push(liteMenu);
                });

                $scope.menuAssociations.forEach((menu) => {
                    const foundMenuVariation = $scope.item.menuVariations.find((menuVariation) => {
                        return menuVariation.menuId === menu.menuId;
                    });

                    // set true/false based on if a menu variation was found
                    menu.associated = !!foundMenuVariation;
                    if (menu.associated) {
                        menu.priceCents = foundMenuVariation.priceCents;
                    }
                });

                // filter & save the original associated menus list.
                $scope.associatedMenusOriginal = $scope.menuAssociations.filter((menu) => {
                    return menu.associated;
                });
            };

            const createSingleFileUploader = (url, item) => {
                var uploaderConfig = {};
                uploaderConfig.url = url;
                var uploader = new FileUploader(uploaderConfig);
                uploader.filters.push({
                    name: 'imageFilter',
                    fn: function (item /* {File|FileLikeObject} */, options) {
                        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                        return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
                    }
                });
                uploader.onAfterAddingFile = function (fileItem) {
                    // The image uploader should be accepting only 1 file. However, there does not seem to be a way
                    // to remove or override the existing file, and `queueLimit` of 1 simply throws an error when there
                    // is already a file. This line ensures that only 1 file is chosen for upload.
                    this.queue = [this.queue[this.queue.length - 1]];
                };
                uploader.onAfterAddingAll = function (addedFileItems) {
                };
                return uploader;
            };

            const imageUploader = $scope.imageUploader = createSingleFileUploader('/freshideas/web/menu/item/images', $scope.item);
            imageUploader.onAfterAddingFile = function (fileItem) {
                const localeImage = $scope.item.languageImageMap[fileItem.languageId];

                // clear compressedImage if necessary
                localeImage.compressedImage = null;
                localeImage.isImageCompressing = true;

                const compressor = new CanvasCompress({
                    type: CanvasCompress.isSupportedType(fileItem.file.type) ? fileItem.file.type : CanvasCompress.MIME.JPEG,
                    width: 800,
                    height: 800,
                    quality: 0.3,
                });

                compressor.process(fileItem._file).then((res) => {
                    localeImage.compressedImage = res.result;
                    localeImage.compressedImage.src = URL.createObjectURL(res.result.blob);
                    localeImage.queueIndex = (this.queue.length - 1);
                    localeImage.fileName = fileItem._file.name;
                    localeImage.isImageCompressing = false;
                    $scope.uploadStatus = UploadStatus.PREPARED;
                });
            };

            imageUploader.onCompleteAll = function (fileItem, response, status, headers) {
                $scope.uploadStatus = UploadStatus.COMPLETED;
            };
            imageUploader.onBeforeUploadItem = function (item) {
                const localeImage = $scope.item.languageImageMap[item.languageId];
                // Upload compressed blob instead of original file.
                item._file = localeImage.compressedImage.blob;

                let imageId = '';
                if ($scope.item.defaultImage) {
                    imageId = $scope.item.defaultImage.menuItemImageId;
                }

                if ($scope.item && $scope.item.locationServicePeriodMenuId) {
                    item.formData.push({
                        id: $scope.item.locationServicePeriodMenuId,
                        languageId: item.languageId,
                        imageId: imageId,
                        name: $scope.item.name
                    });
                }
            };
            imageUploader.onErrorItem = function (fileItem, response, status, headers) {
                // 413: Entity too large
                if (status == 413) {
                    PosAlertService.showAlertByName('file-upload-failed-large-file');
                } else {
                    PosAlertService.showAlertByName('file-upload-failed');
                }
            };

            $scope.beginUpload = () => {
                // Commented by chris lo, march 23rd, 2021.
                // we cannot simply upload all from the uploader's queue with imageUploader.uploadAll()
                // e.g. if a person adds 1 image for 2 locales, queue is 2 (this is fine)
                // if the person decides to switch one of the images, queue will now be 3.
                // while the newest image will overwrite the previous image,
                // this would have called /images 3 times unnecessarily.
                // thus we need to ensure that we only upload with the recorded queue index.
                const languageImageMap = $scope.item.languageImageMap;
                for (const key in languageImageMap) {
                    if (languageImageMap.hasOwnProperty(key)) {
                        if (languageImageMap[key].compressedImage) {
                            imageUploader.uploadItem(languageImageMap[key].queueIndex);
                        }
                    }
                }
            };

            $scope.deleteMenuItemImage = (item, languageImageProperty) => {
                PosAlertService.showAlertByName('general-alert', {
                    title: 'product.item.image.delete.title',
                    message: 'product.item.image.delete.confirmation',
                    modalCallback: () => {
                        languageImageProperty.imageDeleteConfirmation = true;
                        $scope.imageDeleteConfirmation = true;
                    }
                });
            };


            $scope.init = () => {
                setItemType($scope.item);
                setMenuAssociations();
                MenuService.setLanguageImageMap($scope.item, $scope.organizationLanguages);
            };
            $scope.init();
        }]);
};
