'use strict';

/* global Hammer */

module.exports = function (freshideasDirectivesCommon) {

    var DRAGGING_DROP_EMPTY_ONLY = 2;

    var globalIsDragging = false;

    freshideasDirectivesCommon.directive('orderable', [
        function () {
            return {
                scope: {},
                link: function (scope, element, attrs) {
                    element[0].addEventListener('touchmove', function (e) {
                        if ((e.target && e.target.hasAttribute('orderable').length)
                            || globalIsDragging) {
                            e.preventDefault();
                        }
                    }, false);

                    scope.$on('$destroy', function () {
                        element[0].removeEventListener('touchmove');
                    });
                }
            };
        }
    ]).directive('orderableItemViewport', [
        function () {
            /**
             * @this Controller
             * @param {any} $scope
            */
            function Controller ($scope) {
                this.setDragging = function (isDragging) {
                    $scope.$apply(function () {
                        $scope.draggingStatus.isDragging = isDragging;

                        globalIsDragging = isDragging;
                    });
                };

                this.startScroll = function (e, direction) {
                    $scope.startScroll(e, direction);
                };
                this.stopScroll = function (e) {
                    $scope.stopScroll(e);
                };
                this.getScrollContainer = function () {
                    return $scope.parentScrollContainer();
                };
                this.getViewPortElement = function () {
                    return $scope.getViewPortElement();
                };
            }
            return {
                controller: ['$scope', Controller],
                link: function (scope, element, attrs, orderableItemViewport) {
                    var hoverDuration = 0;
                    var hoverThreshold = 30;

                    var isScrolling = false;
                    var startScroll = function (e, direction) { // direction = 1 (down) or -1 (up)
                        e.preventDefault();

                        if (isScrolling) {
                            return;
                        }
                        isScrolling = direction;
                        hoverDuration = 0;

                        persistScroll(direction);
                    };
                    var stopScroll = function (e) { // direction = 1 (down) or -1 (up)
                        e.preventDefault();

                        resetScrollStatus();
                    };
                    var resetScrollStatus = function () {
                        isScrolling = false;
                        hoverDuration = 0;
                    };

                    var computeScrollSpeed = function () {
                        if (hoverDuration < hoverThreshold) {
                            return 0;
                        } else {
                            return Math.pow(hoverDuration / 2, 1.1);
                        }
                    };

                    var scroll = function (direction) {
                        if (globalIsDragging) {
                            hoverDuration++;

                            var elementToScroll = element.parent();
                            var scrollTop = elementToScroll.scrollTop();

                            var scrollSpeed = computeScrollSpeed();
                            var scrollChange = direction * scrollSpeed;
                            var newScrollTop = scrollTop + scrollChange;

                            elementToScroll.scrollTop(newScrollTop);
                        } else {
                            resetScrollStatus();
                        }
                    };

                    var persistScroll = function (direction) {
                        if (isScrolling) {
                            scroll(direction);
                            setTimeout(function () {
                                persistScroll(direction);
                            }, 16);
                        }
                    };

                    var parentScrollContainer = function () {
                        return element.parent().parent();
                    };

                    var getViewPortElement = function () {
                        return element.parent();
                    };

                    scope.startScroll = startScroll;
                    scope.stopScroll = stopScroll;
                    scope.parentScrollContainer = parentScrollContainer;
                    scope.getViewPortElement = getViewPortElement;
                }
            };
        }
    ]).directive('orderableItems', [
        function () {
            /**
             * @this Controller
             * @param {*} $scope
             */
            function Controller ($scope) {
                this.setDragging = function (isDragging) {
                    $scope.setDragging(isDragging);
                };
                this.startScroll = function (e, direction) {
                    $scope.startScroll(e, direction);
                };
                this.stopScroll = function (e) {
                    $scope.stopScroll(e);
                };
                this.getScrollContainer = function () {
                    return $scope.getScrollContainer();
                };
                this.getViewPortElement = function () {
                    return $scope.getViewPortElement();
                };
            }
            return {
                scope: {
                    draggingStatus: '='
                },
                controller: ['$scope', Controller],
                require: ['^^orderableItemViewport'],
                link: function (scope, element, attrs, ctrlArray) {
                    var orderableItemViewport = ctrlArray[0];
                    scope.setDragging = function (isDragging) {
                        orderableItemViewport.setDragging(isDragging);
                    };
                    scope.startScroll = function (e, direction) {
                        orderableItemViewport.startScroll(e, direction);
                    };
                    scope.stopScroll = function (e) {
                        orderableItemViewport.stopScroll(e);
                    };
                    scope.getScrollContainer = function () {
                        return orderableItemViewport.getScrollContainer();
                    };
                    scope.getViewPortElement = function () {
                        return orderableItemViewport.getViewPortElement();
                    };
                }
            };
        }
    ]).directive('orderableItem', [
        '$timeout',
        function ($timeout) {
            return {
                scope: {
                    onDrop: '&',
                    onPageChange: '&',
                    page: '=',
                    disabled: '=',
                    dragMode: '='
                },
                require: ['ngModel', '?^^orderableItems'],
                link: function (scope, element, attrs, ctrlArray) {
                    var ngModel = ctrlArray[0];
                    var orderableItems = ctrlArray[1];

                    var dropTarget;

                    var hmItem = new Hammer(element[0]);
                    hmItem.get('pan').set({
                        threshold: 3 // adjust this to optimize drag UX if needed
                    });

                    var elementWidth = element.width();
                    var elementHeight = element.height();
                    var elementOffsetTop = element[0].offsetTop;
                    var elementOffsetLeft = element[0].offsetLeft;

                    var clone;

                    var setDropTarget = function (d) {
                        if (dropTarget && dropTarget != d) {
                            unsetDropTarget();
                        }

                        while (d && d.hasAttribute) {
                            if (d.hasAttribute('orderable-item') || d.hasAttribute('orderable-group')) {
                                break;
                            }

                            d = d.parentNode;
                        }

                        if (d && d.hasAttribute) {
                            if (scope.dragMode == DRAGGING_DROP_EMPTY_ONLY
                                && d.hasAttribute('orderable-item')
                                && !d.hasAttribute('orderable-item-disabled')) {
                                return;
                            }

                            $(d).addClass('sortable--drop-target');
                            dropTarget = d;

                            if (dropTarget && dropTarget.hasAttribute('orderable-group-tab')) {
                                var index = $(dropTarget).parent().find('[orderable-group-tab]').index(dropTarget);

                                if (index > -1 && scope.onPageChange()) {
                                    scope.$apply(function () {
                                        scope.onPageChange()(index, true);
                                    });
                                }
                            } else if (dropTarget && dropTarget.hasAttribute('orderable-group-dropzone')) {
                                if (scope.onPageChange()) {
                                    scope.$apply(function () {
                                        scope.onPageChange()(-1);
                                    });
                                }
                            }
                        }
                    };
                    var unsetDropTarget = function () {
                        if (dropTarget) {
                            $(dropTarget).removeClass('sortable--drop-target');
                        }

                        dropTarget = undefined;
                    };
                    var checkDropTarget = function () {
                        if (!dropTarget) {
                            return;
                        }

                        if (dropTarget.hasAttribute('orderable-item')) {
                            var index = $(dropTarget).parent().find('[orderable-item]').index(dropTarget);

                            if (index > -1 && scope.onDrop()) {
                                scope.$apply(function () {
                                    scope.onDrop()(ngModel.$modelValue, index);
                                });
                            }
                        } else {
                            if (scope.onPageChange()) {
                                scope.$apply(function () {
                                    scope.onPageChange()(itemPage, true);
                                });
                            }

                            if (dropTarget.hasAttribute('orderable-group-dropzone')) {
                                if (scope.onPageChange()) {
                                    scope.$apply(function () {
                                        scope.onDrop()(ngModel.$modelValue, -1);
                                    });
                                }
                            }
                        }
                    };

                    var itemPage = scope.page;

                    if (scope.disabled) {
                        element.attr('orderable-item-disabled', true);
                        return;
                    }

                    $timeout(function () {
                        hmItem.on('press', function (e) {
                            e.preventDefault();

                            unsetDropTarget();

                            var elementAnchor = element.parent();
                            var scrollableContainer = orderableItems.getScrollContainer();
                            var scrollOffset = orderableItems.getViewPortElement().scrollTop();

                            elementWidth = element.outerWidth();
                            elementHeight = element.outerHeight();
                            elementOffsetTop = element[0].offsetTop - scrollOffset;
                            elementOffsetLeft = element[0].offsetLeft;

                            var elementCloneWidth, elementCloneHeight, elementCloneInitialOffsetLeft, elementCloneInitialOffsetTop;

                            elementCloneWidth = elementWidth;
                            elementCloneHeight = elementHeight;
                            elementCloneInitialOffsetLeft = elementOffsetLeft;
                            elementCloneInitialOffsetTop = elementOffsetTop;

                            element.addClass('sortable--dragging');

                            scrollableContainer.find('[orderable-scroll]').addClass('sortable--dragging');

                            clone = element.clone();
                            clone.css('width', elementCloneWidth)
                                .css('height', elementCloneHeight)
                                .css('position', 'absolute')
                                .css('left', elementCloneInitialOffsetLeft)
                                .css('top', elementCloneInitialOffsetTop)
                                .css('pointer-events', 'none')
                                .css('outline', '3px solid black')
                                .css('z-index', '9999');
                            clone.addClass('sortable-clone--dragging');

                            var cloneTemplate = clone.find('[orderable-item-clone]');
                            if (cloneTemplate && cloneTemplate.length) {
                                clone.children().hide();

                                cloneTemplate.show();

                                // TODO: too specific to the use case...need a better way to change the background color
                                var cloneTemplateBackgroundColor = cloneTemplate.css('background-color');
                                clone.css('background-color', cloneTemplateBackgroundColor);
                            }

                            scrollableContainer.append(clone);

                            if (orderableItems) {
                                orderableItems.setDragging(scope.dragMode);
                            }

                            hmItem.on('pan', function (e) {
                                e.preventDefault();

                                if (!clone) {
                                    return false;
                                }

                                var dropTarget = document.elementFromPoint(e.center.x, e.center.y);
                                if (clone[0].isSameNode(dropTarget)) {
                                    // Hack to get the element that the item is being dragged over, since the clone
                                    // is almost always going to be the element that gets detected.
                                    clone.css('display', 'none');
                                    dropTarget = document.elementFromPoint(e.center.x, e.center.y);
                                    clone.css('display', 'block');
                                }

                                setDropTarget(dropTarget);

                                clone
                                    .css('width', elementCloneWidth)
                                    .css('height', elementCloneHeight)
                                    .css('position', 'absolute')
                                    .css('left', elementCloneInitialOffsetLeft + e.deltaX)
                                    .css('top', elementCloneInitialOffsetTop + e.deltaY)
                                    .css('z-index', '9999')
                                    .css('opacity', 1);


                                scrollableContainer.find('[orderable-scroll]').removeClass('sortable--hover');

                                if (dropTarget && dropTarget.hasAttribute('orderable-scroll')) {
                                    if (dropTarget.getAttribute('direction') === 'up') {
                                        $(dropTarget).addClass('sortable--hover');
                                        orderableItems.startScroll(e.srcEvent, -1);
                                    } else if (dropTarget.getAttribute('direction') === 'down') {
                                        $(dropTarget).addClass('sortable--hover');
                                        orderableItems.startScroll(e.srcEvent, 1);
                                    } else {
                                        orderableItems.stopScroll(e.srcEvent);
                                    }
                                } else {
                                    orderableItems.stopScroll(e.srcEvent);
                                }

                                var dropzone;
                                if (dropTarget && dropTarget.hasAttribute('orderable-group-dropzone')) {
                                    dropzone = dropTarget;
                                } else if (dropTarget && dropTarget.parentNode && dropTarget.parentNode.hasAttribute('orderable-group-dropzone')) {
                                    dropzone = dropTarget.parentNode;
                                }

                                if (dropzone) {
                                    clone.css('opacity', 0.4);
                                }

                                return false;
                            });

                            hmItem.on('pressup panend', function (e) {
                                e.preventDefault();

                                var dropTargetAnchorScrollTop = 0;
                                if (dropTarget && dropTarget.hasAttribute('orderable-item')) {
                                    dropTargetAnchorScrollTop = $(dropTarget).parent().parent().scrollTop();
                                }

                                checkDropTarget();
                                unsetDropTarget();

                                if (orderableItems) {
                                    orderableItems.setDragging(false);
                                    elementAnchor.parent().scrollTop(dropTargetAnchorScrollTop);
                                }

                                element.removeClass('sortable--dragging');
                                scrollableContainer.find('[orderable-scroll]').removeClass('sortable--dragging');

                                if (clone) {
                                    clone.remove();
                                    clone = undefined;
                                }

                                hmItem.off('pan pressup panend');

                                return false;
                            });

                        });

                        scope.$on('$destroy', function () {
                            hmItem.off('press');
                        });
                    });
                }
            };
        }
    ]).directive('orderableGroups', [
        function () {
            return {
                link: function (scope, element, attrs) {

                }
            };
        }
    ]).directive('orderableGroup', [
        function () {
            return {
                link: function (scope, element, attrs) {

                }
            };
        }
    ]).directive('orderableScroll', [
        function () {
            return {
                link: function (scope, element, attrs) {

                }
            };
        }
    ]);
};
