'use strict';


module.exports = function (freshideasSetup) {

    // Allows preview image uploaded to a file uploader. This is copied from
    // the example in `angular-file-upload` library (https://github.com/nervgh/angular-file-upload/blob/master/examples/image-preview/directives.js),
    // with some modification so that the image rendering uses img + data URI instead of canvas element.

    freshideasSetup.directive('ngThumb', ['$window', function ($window) {
        var helper = {
            support: !!($window.FileReader),
            isFile: function (item) {
                return angular.isObject(item) && item instanceof $window.File;
            },
            isImage: function (file) {
                var type = '|' +
                    file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
            }
        };

        return {
            restrict: 'A',
            template: '<img/>',
            link: function (scope, element, attributes) {
                if (!helper.support) {
                    return;
                }

                var params = scope.$eval(attributes.ngThumb);

                if (!helper.isFile(params.file)) {
                    return;
                }
                if (!helper.isImage(params.file)) {
                    return;
                }

                var image = element.find('img');
                var reader = new FileReader();

                reader.onload = function (e) {
                    image.attr('src', e.target.result);
                };
                reader.readAsDataURL(params.file);
            }
        };
    }]);
};
