'use strict';


module.exports = function (posModule) {
    posModule.controller('PosPrinterStatusCtrl', [
        '$scope',
        'PrintStatusService',
        'Locations',
        'locationId',
        function ($scope, PrintStatusService, Locations, locationId) {
            $scope.PrintStatus = PrintStatusService;
            $scope.printerStatuses = {};

            $scope.getLocationPrinters = function () {
                Locations.getLocationPrinters(
                    {locationId: locationId},
                    function (response) {
                        $scope.posStation = response.posStation;
                        $scope.kitchenPrinters = response.entries;
                    }
                );
            };
            $scope.findKitchenPrinterName = function (printerId) {
                var matchedPrinter = _.findWhere(
                    $scope.kitchenPrinters,
                    {printerId: parseInt(printerId)}
                );

                return (matchedPrinter)
                    ? matchedPrinter.name
                    : ('#' + printerId);
            };

            $scope.refresh = function () {
                $scope.printerStatuses = angular.copy(
                    PrintStatusService.getPrinterStatuses()
                );
            };

            $scope.getLocationPrinters();
            $scope.refresh();

        }
    ]);
};
