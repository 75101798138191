'use strict';


const moment = require('moment');

module.exports = function (freshideasReports) {

    freshideasReports.controller('PatronSpendReport', [
        '$scope',
        '$state',
        '$rootScope',
        'Reports',
        'Utils',
        '$modal',
        'NgGridSortService',
        'NgGridTemplateService',
        'Lookup',
        'Meals',
        'Export',
        'Security',
        'Platform',
        function ($scope, $state, $rootScope, Reports, Utils, $modal, NgGridSortService, NgGridTemplateService, Lookup, Meals, Export, Security, Platform) {

            $scope.currentUser = Security.getUser();
            $scope.isFullAdmin = ($scope.currentUser.permission === 'FULLADMIN');
            $scope.isIosWebkit = Platform.isIosWebkit();

            $scope.init = function () {
                $scope.loadCompanyHierarchy().$promise
                    .then($scope.loadCompanyMeals)
                    .then($scope.loadPatronSpend);
            };

            $scope.loadCompanyHierarchy = function () {
                return Lookup.companyHierarchy({}, function (response) {
                    $scope.company = _.findWhere(response, {id: $scope.currentUser.companyId});
                });
            };

            $scope.loadCompanyMeals = function () {
                return Meals.getMealPlans({companyId: $scope.currentUser.companyId}, function (response) {
                    $scope.mealPlans = response.entries;
                });
            };

            $scope.setSortByField = function (sortBy) {
                $scope.currentReportSearch.sortBy = sortBy;
                $scope.currentReportSearch.ascending = !$scope.currentReportSearch.ascending;
            };

            $scope.resetFilters = function () {
                $scope.patronSpendSearch.startDateTime = moment().startOf('week').toDate();
                $scope.patronSpendSearch.endDateTime = moment().endOf('day').toDate();
                $scope.patronSpendSearch.isAdvancedSearch = true;
                $scope.patronSpendSearch.sortBy = 'patronCompanyKey';
                $scope.patronSpendSearch.ascending = true;
                $scope.patronSpendSearch.offSet = 0;
                $scope.patronSpendSearch.limit = 50;
                $scope.patronSpendSearch.location = undefined;

                $scope.loadPatronSpend();
            };

            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };
            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };

            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };

            $scope.patronSpendSearch = {
                isAdvancedSearch: true,
                startDateTime: moment().startOf('week').toDate(),
                endDateTime: moment().endOf('day').toDate(),
                sortBy: 'patronCompanyKey',
                ascending: true,
                offSet: 0,
                limit: 50,
            };

            $scope.loadPatronSpend = function () {
                $scope.patronSpendSearch.startDateTime = moment($scope.patronSpendSearch.startDateTime).startOf('day').valueOf();
                $scope.patronSpendSearch.endDateTime = moment($scope.patronSpendSearch.endDateTime).endOf('day').valueOf();

                $scope.patronSpendData = [];
                $scope.isLoading = true;

                Reports.getPatronSpendReport($scope.patronSpendSearch, function (response) {
                    var data = response;

                    $scope.resultLimit = 50;

                    if (data.length > 0) {
                        $scope.isLoading = false;

                        $scope.patronSpendData = response;
                        _.each($scope.patronSpendData, function (datum) {
                            datum.dcbMealPlanIds = (datum.dcbMealPlanIds)? datum.dcbMealPlanIds.split(',') : [];
                            datum.mealPlanIds = (datum.mealPlanIds)? datum.mealPlanIds.split(',') : [];
                        });

                        $scope.currentReportSearch = angular.copy($scope.patronSpendSearch);
                    } else {
                        $scope.isLoading = false;
                        $scope.patronSpendData = [];
                    }
                }, function (error) {
                    $scope.isLoading = false;
                });
            };

            $scope.loadMoreResults = function () {
                var resultLimit = Math.min($scope.resultLimit + 50, $scope.patronSpendData.length);
                $scope.resultLimit = resultLimit;
            };

            $scope.showError = function (title, subtitle, message, buttonTitle) {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalError.tpl.html',
                    controller: 'ErrorCtrl',
                    windowClass: 'modal-50',
                    resolve: {
                        title: function () {
                            return title;
                        },
                        subtitle: function () {
                            return subtitle;
                        },
                        message: function () {
                            return message;
                        },
                        buttonTitle: function () {
                            return buttonTitle;
                        }
                    },
                    backdrop: 'static'
                }, function (error) {
                });
                modalInstance.result.then(function (tender) {
                    $state.go('pos');
                });
            };

            $scope.exportToPdf = function (tableId) {
                $scope.resultLimit = $scope.patronSpendData.length;

                // wrap the actual export code in a `setTimeout` so that the
                // all patron spend data are generated in the UI first
                setTimeout(function () {
                    Export.tableToPdf(tableId, 'landscape');
                }, 0);
            };

            $scope.init();
        }
    ]);

};
