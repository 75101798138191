'use strict';


module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ProductModifierReuseV2Ctrl', [
        '$scope',
        'ProductsService',
        'modifier',
        function ($scope, ProductsService, modifier) {

            $scope.modifier = modifier;

            $scope.attachToItem = function () {
                $scope.$close({
                    action: 'attach'
                });
            };
            $scope.duplicate = function () {
                $scope.$close({
                    action: 'duplicate'
                });
            };

        }])
        .value('modifier', {});
};
