'use strict';

// /* globals StarWebPrintBuilder, StarWebPrintTrader, message, async */

export default angular.module('freshideas.services.pos.users', [])
.factory('PosUsersService', [
    function () {
        var clockedInUsers = [];
        let lastKDSView = 'incomplete';

        var setClockedInUsers = function (response) {
            clockedInUsers = response;
        };

        var getClockedInUsers = function () {
            return clockedInUsers;
        };

        const getLastKDSView = () => {
            return lastKDSView;
        };

        const setLastKDSView = (view) => {
            lastKDSView = view;
        };

        return {
            setClockedInUsers,
            getClockedInUsers,
            setLastKDSView,
            getLastKDSView,
        };
    }
]);
