'use strict';


/**
 * sudo apt-get purge modemmanager
 * This is important on Linux. Dont overlook
 */

export function lucovaBluetooth (freshideas) {

    freshideas.factory('NodeSerialPort', ['Platform', function (Platform) {
        if (Platform.isElectron()) {
            let serialport = nodeRequire('serialport');
            return serialport;
        } else {
            return null;
        }
    }]);

    freshideas.factory('NodeOs', ['Platform', function (Platform) {
        if (Platform.isElectron()) {
            let os = nodeRequire('os');
            return os;
        } else {
            return null;
        }
    }]);

    freshideas.factory('AppVersion', ['Electron', 'Platform', function (Electron, Platform) {
        let getVersion = function () {
            if (Platform.isElectron()) {
                return Electron.remote.app.getVersion();
            } else {
                return '1.0.0';
            }
        };

        return {
            getVersion
        };
    }]);

    freshideas.factory('LucovaBluetooth', [
        'BluetoothSelfTest',
        'BluetoothHelper',
        'Logger',
        'Lucova',
        'NodeSerialPort',
        'NodeOs',
        'AppVersion',
        'VersionCompare',
        'Platform',
        function (
            BluetoothSelfTest,
            BluetoothHelper,
            Logger,
            Lucova,
            NodeSerialPort,
            NodeOs,
            AppVersion,
            VersionCompare,
            Platform) {

            let serialport = NodeSerialPort;
            let os = NodeOs;
            let notify = true;
            let isElectron = false;
            let usbConnected = false;

            // this references the ID for the setInterval function which
            // keeps the self BLE test running
            let selfBleTestIntervalId;
            let portMonitorIntervalId;
            let hardwareResetTimeoutId;

            // this flag is set to true only while the hardware is initializing
            // should be true for less than 10 seconds after initBluetooth is
            // called
            let isInitializing = false;

            let stop = function () {
                // Handle iOS case
                if (Platform.isIosWebkit()) {
                    return;
                }
                notify = false;
            };

            let usbStatus = function () {
                if (Platform.isIosWebkit()) {
                    return true;
                }
                return usbConnected;
            };

            let clearPreviousIntervals = function () {
                if (selfBleTestIntervalId) {
                    clearInterval(selfBleTestIntervalId);
                    selfBleTestIntervalId = null;
                }
                if (portMonitorIntervalId) {
                    clearInterval(portMonitorIntervalId);
                    portMonitorIntervalId = null;
                }
                if (hardwareResetTimeoutId) {
                    clearTimeout(hardwareResetTimeoutId);
                    hardwareResetTimeoutId = null;
                }
            };

            let initBluetoothV1 = function (bleId, iBeacon, usbStatusCallback) {

                // Handle iOS case
                if (Platform.isIosWebkit()) {
                    window.webkit.messageHandlers.bridge.postMessage({'type': 'initBluetooth', 'bleId': bleId, 'iBeacon': iBeacon});
                    return;
                }

                if (!isElectron) {
                    return;
                }
                if (!bleId) {
                    // require bleid to start broadcasting
                    Logger.warn('require ble ID to start broadcasting');
                    return;
                }
                if (isInitializing) {
                    throw new Error('lucovabluetooth is busy');
                }
                isInitializing = true;

                // this flag is set to true while we are running self-tests such
                // as the bluetooth self-scanner and the port scanner. Will be
                // true
                clearPreviousIntervals();

                var promise = new Promise(function (resolve, reject) {

                    usbConnected = false;
                    notify = true;

                    var bleTestFailedCount = 0;
                    var cmdQueue = [];
                    var spoolBuffer = [];
                    let connectedPort = null;
                    var closeTimeout = null;
                    var postReset = false;

                    resetQueue();
                    scanPortsAndConnect();

                    // the purpose of this wrapper is to ensure that any
                    // uncaught exceptions after a setTimeout still cause the
                    // encapsulating Promise to reject.
                    function callbackWrapper (fn) {
                        return function (...args) {
                            try {
                                return fn.apply(this, args);
                            } catch (err) {
                                reject(err);
                                throw err;
                            }
                        };
                    }

                    function resetQueue () {
                        let cmd = BluetoothHelper.COMMANDS;
                        let advData = cmd.GAP_UpdateAdvLucova;
                        let scnRspData = BluetoothHelper.createScanRspPacket(bleId);

                        if (iBeacon) {
                            advData = BluetoothHelper.createIbeaconAdvPacket(iBeacon);
                        }
                        cmdQueue = [
                            cmd.HCI_EXT_ChipReset,
                            cmd.GAP_DeviceInit,
                            cmd.GATT_AddService,
                            cmd.GATT_AddAttribute,
                            scnRspData,
                            advData,
                            cmd.GAP_MakeDiscoverable
                        ];
                    }

                    /**
                     * Shuld be renamed to something like reinitializeHardware
                     * the param 'hardReset' is never used
                     * @param {*} hardReset
                     */
                    function reinitializeHardware () {
                        isInitializing = false;
                        setTimeout(callbackWrapper(function () {
                            initBluetoothV1(bleId, iBeacon, usbStatusCallback);
                        }), 1000);
                    }

                    function scanPortsAndConnect () {
                        Logger.debug('trying to find port....');

                        serialport.list(callbackWrapper(function (err, ports) {
                            if (err) {
                                reinitializeHardware();
                                return;
                            }
                            var bluetoothPort = ports.find((port) => {
                                var manufacturer = port.manufacturer;
                                return manufacturer && manufacturer.includes(BluetoothHelper.USB_NAME);
                            });

                            if (!bluetoothPort) {
                                Logger.debug('No Bluetooth device found.');
                                reinitializeHardware();
                                return;
                            }

                            Logger.debug('Bluetooth device found: ' + bluetoothPort.manufacturer);
                            connectToPort(bluetoothPort);
                        }));
                    }

                    function onOpen () {
                        Logger.debug('Serial Port opened!');
                        usbConnected = true;
                        connectedPort.on('close', onClose);
                        connectedPort.on('data', onData);
                        connectedPort.on('error', onError);
                        writeFromQueue();
                    }

                    function connectToPort (port) {
                        connectedPort = new serialport(port.comName, {
                            autoOpen: false,
                            baudRate: 115200,
                            dataBits: 8,
                            stopBits: 1,
                            parity: 'none',
                            rtscts: true,
                        });

                        connectedPort.on('open', onOpen);
                        connectedPort.open(callbackWrapper(function (err) {
                            if (err) {
                                reinitializeHardware();
                                Logger.error('***Error opening port ' + err);
                            }
                        }));
                    }

                    // everytime read/write activity happens on the port we set a
                    // 10 second timeout to close it. If activity keeps happening
                    // we keep extending the window before closing.
                    function resetCloseTimeout () {
                        if (!postReset) {
                            return;
                        }

                        if (closeTimeout != null) {
                            clearTimeout(closeTimeout);
                        }
                        closeTimeout = setTimeout(callbackWrapper(disconnectFromUSB), 2000);
                    }

                    function disconnectFromUSB () {
                        connectedPort.close(callbackWrapper(function (err) {
                            if (err) {
                                Logger.error(err);
                            }
                            Logger.debug('Serial Port closed: Queue empty!');
                        }));

                        portMonitorIntervalId = setInterval(function () {
                            beginPortMonitor(portMonitorIntervalId);
                        }, 2000);

                        beginBleTester();
                        scheduleHardwareReset();

                        resolve();
                    }

                    function writeFromQueue () {
                        if (cmdQueue.length == 0) {
                            return;
                        }
                        if (connectedPort && connectedPort.isOpen) {
                            connectedPort.write(cmdQueue.shift());
                            connectedPort.drain(callbackWrapper((err) => {
                                if (err) {
                                    Logger.error('Serial Port Drain Error', err);
                                    reinitializeHardware();
                                } else {
                                    if (cmdQueue.length == 0) {
                                        resetCloseTimeout();
                                    }
                                }
                            }));
                        } else {
                            Logger.error('Expected to write to port but it was not open');
                            reinitializeHardware();
                        }
                    }

                    function spoolData (data) {
                        spoolBuffer = spoolBuffer.concat(Array.from(data));
                        return spoolBuffer;
                    }

                    function onData (data) {
                        resetCloseTimeout();

                        var spooled = spoolData(data);
                        var arr = BluetoothHelper.parseResponse(spooled);

                        arr.forEach(callbackWrapper((obj) => {
                            var err = obj.err;
                            var response = obj.body;
                            if (err) {
                                reinitializeHardware();
                            } else if (response) {
                                if (response.type == 'GAP_DeviceInitDone') {
                                    Logger.info('mac addr: ' + response.prop.deviceAddress + ', ble id: ' + bleId);
                                    try {
                                    Lucova.manager().bridgeProperties({build_version: '1.0', mac_addr: response.prop.deviceAddress, system_properties: getSystemProperties()},
                                        function (response) { }, function (error) {
                                            console.log(error); // using console.log instead of $log to prevent accidental loop
                                        });
                                    } catch (error) {
                                        Logger.error(error);
                                    }
                                } else if (response.type == 'HCIExt_ResetSystemDone') { // telling us it's about to restart
                                    // we are about to be disconnected so we need
                                    // to re-connect in 5 seconds
                                    setTimeout(
                                        callbackWrapper(
                                            function () {
                                                scanPortsAndConnect();
                                                postReset = true;
                                            }), 3000);
                                    Logger.info('usb soft reset done!');
                                    return;
                                }

                                // wait 1s before we write again in-case USB is
                                // still sending us data.
                                setTimeout(callbackWrapper(writeFromQueue), 500);
                            }
                        }));
                    }

                    function beginPortMonitor (intervalId) {
                        serialport.list((err, ports) => {
                            if (err) {
                                Logger.error(err.message);
                                return;
                            }

                            let found = ports.find((port) => {
                                var manufacturer = port.manufacturer;
                                return manufacturer && manufacturer.includes(BluetoothHelper.USB_NAME);
                            });

                            if (!found) {
                                clearInterval(intervalId);
                                if (notify && usbConnected) {
                                    usbConnected = false;
                                    try {
                                        usbStatusCallback(false);
                                    } catch (err) {
                                        Logger.error(err);
                                    }

                                    Logger.info('*****USB disconnected******');
                                    reinitializeHardware();
                                }
                            }
                        });
                    }

                    function runTest (intervalId) {
                        if (!usbConnected) {
                            return;
                        }
                        BluetoothSelfTest.run(bleId, callbackWrapper((code) => {
                            let message = '';
                            switch (code) {
                                case 0:
                                    // SUCCESS
                                    message = 'SUCCESS';
                                    bleTestFailedCount = 0;
                                    logTestResult(message);
                                    break;
                                case 1:
                                    // RADIO NOT AVAILABLE
                                    message = 'RADIO NOT AVAILABLE';
                                    logTestResult(message);
                                    break;
                                case 4:
                                    // OTHER ERROR
                                    message = 'OTHER ERROR';
                                    logTestResult(message);
                                    break;
                                case 6:
                                    // NOT SUPPORTED
                                    message = 'NOT SUPPORTED';
                                    logTestResult(message);
                                    break;
                                case 9:
                                    // timeout
                                    message = 'TIMEOUT';
                                    bleTestFailedCount++;
                                    if (bleTestFailedCount >= 3) {
                                        bleTestFailedCount = 0;
                                        Logger.warn('BLE self test failed +3');
                                        logTestResult(message);
                                        clearInterval(intervalId);

                                        if (notify) {
                                            reinitializeHardware();
                                        }
                                    } else {
                                        Logger.warn('BLE self test failed ' + bleTestFailedCount);
                                    }
                                    break;
                                default:
                                    // do something
                                    message = 'UNKNOWN';
                                    logTestResult(message);
                                    break;
                            }
                        }));
                    }

                    function beginBleTester () {
                        selfBleTestIntervalId = setInterval(function () {
                            runTest(selfBleTestIntervalId);
                        }, 20000);
                    }

                    function scheduleHardwareReset () {
                        hardwareResetTimeoutId = setTimeout(function () {
                            if (notify) {
                                // notify is set to false when the shift is ended
                                reinitializeHardware();
                            }
                        }, 1000 * 60 * 60); // schedule hardware reset for every hr
                    }

                    function logTestResult (result) {
                        try {
                        Lucova.manager().sendMerchantEvent({type: 'ble_self_test', level: 'info', message: result},
                            function (response) { }, function (error) {
                                console.log(error); // using console.log instead of $log to prevent accidental loop
                            });
                        } catch (error) {
                            Logger.error(error);
                        }
                    }

                    function onError (error) {
                        Logger.error('Serial Port Error', error);
                    }

                    function onClose (close) {
                        Logger.info('Serial port closed', close ? close : '');
                    }

                    function getSystemProperties () {
                        return {
                            uptime: os.uptime(),
                            totalmem: os.totalmem(),
                            freemem: os.freemem(),
                            platform: os.platform(),
                            release: os.release(),
                            cpus: os.cpus(),
                            arch: os.arch()
                        };
                    }
                });
                promise.then(function () {
                    isInitializing = false;
                    Logger.info('Initialization completed successfully');
                }).catch(function (err) {
                    isInitializing = false;
                    Logger.error('Initialization completed unsuccessfully');
                    Logger.error(err);
                });
            };

            let initBluetoothV2 = function (bleId, iBeacon, usbStatusCallback) {

                // Handle iOS case
                if (Platform.isIosWebkit()) {
                    window.webkit.messageHandlers.bridge.postMessage({'type': 'initBluetooth', 'bleId': bleId, 'iBeacon': iBeacon});
                    return;
                }

                if (!isElectron) {
                    return;
                }
                if (!bleId) {
                    // require bleid to start broadcasting
                    Logger.warn('[V2] require ble ID to start broadcasting');
                    return;
                }
                if (isInitializing) {
                    throw new Error('[V2] lucovabluetooth is busy');
                }
                isInitializing = true;

                // this flag is set to true while we are running self-tests such
                // as the bluetooth self-scanner and the port scanner. Will be
                // true
                clearPreviousIntervals();

                var promise = new Promise(function (resolve, reject) {

                    usbConnected = false;
                    notify = true;

                    var bleTestFailedCount = 0;
                    var cmdQueue = [];
                    var spoolBuffer = [];
                    let connectedPort = null;
                    var closeTimeout = null;
                    var postReset = false;

                    resetQueue();
                    scanPortsAndConnect();

                    // the purpose of this wrapper is to ensure that any
                    // uncaught exceptions after a setTimeout still cause the
                    // encapsulating Promise to reject.
                    function callbackWrapper (fn) {
                        return function (...args) {
                            try {
                                return fn.apply(this, args);
                            } catch (err) {
                                reject(err);
                                throw err;
                            }
                        };
                    }

                    function resetQueue () {
                        let cmd = BluetoothHelper.COMMANDS;
                        let advData = cmd.GAP_UpdateAdvLucova;
                        let scnRspData = BluetoothHelper.createScanRspPacket(bleId);

                        if (iBeacon) {
                            advData = BluetoothHelper.createIbeaconAdvPacket(iBeacon);
                        }
                        cmdQueue = [
                            cmd.HCI_EXT_ChipReset,
                            cmd.GAP_DeviceInit,
                            cmd.GATT_AddService,
                            cmd.GATT_AddAttribute,
                            scnRspData,
                            advData,
                            cmd.GAP_MakeDiscoverable
                        ];
                    }

                    /**
                     * Shuld be renamed to something like reinitializeHardware
                     * the param 'hardReset' is never used
                     * @param {*} hardReset
                     */
                    function reinitializeHardware () {
                        isInitializing = false;
                        setTimeout(callbackWrapper(function () {
                            initBluetoothV2(bleId, iBeacon, usbStatusCallback);
                        }), 1000);
                    }

                    function scanPortsAndConnect () {
                        Logger.debug('[V2] trying to find port....');

                        serialport.list().then(callbackWrapper(function (ports) {
                            if (!ports) {
                                reinitializeHardware();
                                return;
                            }
                            var bluetoothPort = ports.find((port) => {
                                var manufacturer = port.manufacturer;
                                return manufacturer && manufacturer.includes(BluetoothHelper.USB_NAME);
                            });

                            if (!bluetoothPort) {
                                Logger.debug('[V2] No Bluetooth device found.');
                                reinitializeHardware();
                                return;
                            }

                            Logger.debug('[V2] Bluetooth device found: ' + bluetoothPort.manufacturer);
                            connectToPort(bluetoothPort);
                        })).catch((err) => {
                            if (err) {
                                reinitializeHardware();
                                return;
                            }
                        });
                    }

                    function onOpen () {
                        Logger.debug('[V2] Serial Port opened!');
                        usbConnected = true;
                        connectedPort.on('close', onClose);
                        connectedPort.on('data', onData);
                        connectedPort.on('error', onError);
                        writeFromQueue();
                    }

                    function connectToPort (port) {
                        connectedPort = new serialport(port.path, {
                            autoOpen: false,
                            baudRate: 115200,
                            dataBits: 8,
                            stopBits: 1,
                            parity: 'none',
                            rtscts: true,
                        });

                        connectedPort.on('open', onOpen);
                        connectedPort.open(callbackWrapper(function (err) {
                            if (err) {
                                reinitializeHardware();
                                Logger.error('[V2] ***Error opening port ' + err);
                            }
                        }));
                    }

                    // everytime read/write activity happens on the port we set a
                    // 10 second timeout to close it. If activity keeps happening
                    // we keep extending the window before closing.
                    function resetCloseTimeout () {
                        if (!postReset) {
                            return;
                        }

                        if (closeTimeout != null) {
                            clearTimeout(closeTimeout);
                        }
                        closeTimeout = setTimeout(callbackWrapper(disconnectFromUSB), 2000);
                    }

                    function disconnectFromUSB () {
                        if (connectToPort.isOpen) {
                            connectedPort.close(callbackWrapper(function (err) {
                                if (err) {
                                    Logger.error(err);
                                }
                                Logger.debug('[V2] Serial Port closed: Queue empty!');
                            }));
                        }

                        portMonitorIntervalId = setInterval(function () {
                            beginPortMonitor(portMonitorIntervalId);
                        }, 2000);

                        beginBleTester();
                        scheduleHardwareReset();

                        resolve();
                    }

                    function writeFromQueue () {
                        if (cmdQueue.length == 0) {
                            return;
                        }
                        if (connectedPort && connectedPort.isOpen) {
                            connectedPort.write(cmdQueue.shift());
                            connectedPort.drain(callbackWrapper((err) => {
                                if (err) {
                                    Logger.error('[V2] Serial Port Drain Error', err);
                                    reinitializeHardware();
                                } else {
                                    if (cmdQueue.length == 0) {
                                        resetCloseTimeout();
                                    }
                                }
                            }));
                        } else {
                            Logger.error('[V2] Expected to write to port but it was not open');
                            reinitializeHardware();
                        }
                    }

                    function spoolData (data) {
                        spoolBuffer = spoolBuffer.concat(Array.from(data));
                        return spoolBuffer;
                    }

                    function onData (data) {
                        resetCloseTimeout();

                        var spooled = spoolData(data);
                        var arr = BluetoothHelper.parseResponse(spooled);

                        arr.forEach(callbackWrapper((obj) => {
                            var err = obj.err;
                            var response = obj.body;
                            if (err) {
                                reinitializeHardware();
                            } else if (response) {
                                if (response.type == 'GAP_DeviceInitDone') {
                                    Logger.info('[V2] mac addr: ' + response.prop.deviceAddress + ', ble id: ' + bleId);
                                    try {
                                    Lucova.manager().bridgeProperties({build_version: '1.0', mac_addr: response.prop.deviceAddress, system_properties: getSystemProperties()},
                                        function (response) { }, function (error) {
                                            console.log(error); // using console.log instead of $log to prevent accidental loop
                                        });
                                    } catch (error) {
                                        Logger.error(error);
                                    }
                                } else if (response.type == 'HCIExt_ResetSystemDone') { // telling us it's about to restart
                                    // we are about to be disconnected so we need
                                    // to re-connect in 5 seconds
                                    setTimeout(
                                        callbackWrapper(
                                            function () {
                                                scanPortsAndConnect();
                                                postReset = true;
                                            }), 3000);
                                    Logger.info('[V2] usb soft reset done!');
                                    return;
                                }

                                // wait 1s before we write again in-case USB is
                                // still sending us data.
                                setTimeout(callbackWrapper(writeFromQueue), 500);
                            }
                        }));
                    }

                    function beginPortMonitor (intervalId) {
                        serialport.list().then((ports) => {
                            let found = ports.find((port) => {
                                var manufacturer = port.manufacturer;
                                return manufacturer && manufacturer.includes(BluetoothHelper.USB_NAME);
                            });

                            if (!found) {
                                clearInterval(intervalId);
                                if (notify && usbConnected) {
                                    usbConnected = false;
                                    try {
                                        usbStatusCallback(false);
                                    } catch (err) {
                                        Logger.error(err);
                                    }

                                    Logger.info('[V2] *****USB disconnected******');
                                    reinitializeHardware();
                                }
                            }
                        }).catch((err) => {
                            if (err) {
                                Logger.error(err.message);
                                return;
                            }
                        });
                    }

                    function runTest (intervalId) {
                        if (!usbConnected) {
                            return;
                        }
                        BluetoothSelfTest.run(bleId, callbackWrapper((code) => {
                            let message = '';
                            switch (code) {
                                case 0:
                                    // SUCCESS
                                    message = 'SUCCESS';
                                    bleTestFailedCount = 0;
                                    logTestResult(message);
                                    break;
                                case 1:
                                    // RADIO NOT AVAILABLE
                                    message = 'RADIO NOT AVAILABLE';
                                    logTestResult(message);
                                    break;
                                case 4:
                                    // OTHER ERROR
                                    message = 'OTHER ERROR';
                                    logTestResult(message);
                                    break;
                                case 6:
                                    // NOT SUPPORTED
                                    message = 'NOT SUPPORTED';
                                    logTestResult(message);
                                    break;
                                case 9:
                                    // timeout
                                    message = 'TIMEOUT';
                                    bleTestFailedCount++;
                                    if (bleTestFailedCount >= 3) {
                                        bleTestFailedCount = 0;
                                        Logger.warn('BLE self test failed +3');
                                        logTestResult(message);
                                        clearInterval(intervalId);

                                        if (notify) {
                                            reinitializeHardware();
                                        }
                                    } else {
                                        Logger.warn('BLE self test failed ' + bleTestFailedCount);
                                    }
                                    break;
                                default:
                                    // do something
                                    message = 'UNKNOWN';
                                    logTestResult(message);
                                    break;
                            }
                        }));
                    }

                    function beginBleTester () {
                        selfBleTestIntervalId = setInterval(function () {
                            runTest(selfBleTestIntervalId);
                        }, 20000);
                    }

                    function scheduleHardwareReset () {
                        hardwareResetTimeoutId = setTimeout(function () {
                            if (notify) {
                                // notify is set to false when the shift is ended
                                reinitializeHardware();
                            }
                        }, 1000 * 60 * 60); // schedule hardware reset for every hr
                    }

                    function logTestResult (result) {
                        try {
                        Lucova.manager().sendMerchantEvent({type: 'ble_self_test', level: 'info', message: result},
                            function (response) { }, function (error) {
                                console.log(error); // using console.log instead of $log to prevent accidental loop
                            });
                        } catch (error) {
                            Logger.error(error);
                        }
                    }

                    function onError (error) {
                        Logger.error('[V2] Serial Port Error', error);
                    }

                    function onClose (close) {
                        Logger.info('[V2] Serial port closed', close ? close : '');
                    }

                    function getSystemProperties () {
                        return {
                            uptime: os.uptime(),
                            totalmem: os.totalmem(),
                            freemem: os.freemem(),
                            platform: os.platform(),
                            release: os.release(),
                            cpus: os.cpus(),
                            arch: os.arch()
                        };
                    }
                });
                promise.then(function () {
                    isInitializing = false;
                    Logger.info('[V2] Initialization completed successfully');
                }).catch(function (err) {
                    isInitializing = false;
                    Logger.error('[V2] Initialization completed unsuccessfully');
                    Logger.error(err);
                });
            };

            isElectron = Platform.isElectron() || (Platform.isTestEnv && Platform.isTestEnv());
            if (!isElectron) {
                console.log('Running in non-electron environment. Bluetooth will be disabled');
            }

            let appVersion = AppVersion.getVersion();

            var bluetooth = {
                stop,
                usbStatus
            };

            // In version 2.0.0 we upgraded to electron 9.2.0 and node-serialport 9.0.1 which
            // is no longer backwards compatible
            // If App version is earlier than 2.0.0 load V1 init function
            if (VersionCompare.compare(appVersion, '2.0.0') < 0) {
                bluetooth.initBluetooth = initBluetoothV1;
            } else {
                bluetooth.initBluetooth = initBluetoothV2;
            }

            return bluetooth;
        }]);
}
