'use strict';

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SaveMealCardCtrl', [
        '$scope',
        'CommonOfflineCache',
        'Organization',
        'PosAlertService',
        'GatewayFiit',
        '$log',
        'qrcode',
        'isQuickCharge',
        'cashierShiftId',
        function (
            $scope,
            CommonOfflineCache,
            Organization,
            PosAlertService,
            GatewayFiit,
            $log,
            qrcode,
            isQuickCharge,
            cashierShiftId) {
                $scope.qrcode = qrcode;
                $scope.mealCardToSave = {qrcode};

                const servicePeriodId = CommonOfflineCache.getCurrentFiitServicePeriodId();
                const servicePeriods = GatewayFiit.getServicePeriods() || [];
                const servicePeriod = _.find(servicePeriods, {'servicePeriodId': servicePeriodId});

                const quickchargeSessionId = isQuickCharge && CommonOfflineCache.getQuickChargeSession()
                    ? CommonOfflineCache.getQuickChargeSession().quickChargeSessionId : undefined;

                $scope.saveCard = () => {
                    $scope.savingCard = true;
                    const payload = Object.assign({
                        mealCardNumber: qrcode,
                        menuPeriod: CommonOfflineCache.getCurrentMenuPeriod().description,
                        servicePeriod: servicePeriod ? servicePeriod.description : null,
                        quickchargeSessionId,
                        cashierShiftId
                    }, $scope.mealCardToSave);

                    Organization.saveMealCard(payload).$promise
                    .then(() => {
                        $scope.savingCard = false;
                        $scope.$close({savedMealCard: $scope.mealCardToSave});
                    })
                    .catch((err) => {
                        $scope.savingCard = false;
                        PosAlertService.showAlertByName('general', {
                            title: 'setupWizard.merchant.fiit.mealCardSaveError',
                            message: 'general.error.try.again.later'
                        });

                        // send a honeybadger log
                        $log.error('Failed to save meal card', err);
                    });
                };
        }
    ]);
};
