'use strict';

module.exports = function (freshideasSetup) {

    freshideasSetup.controller('MerchantSetupAlphaPayCtrl', [
        '$scope',
        'GatewayService',
        'Security',
        function (
            $scope,
            GatewayService,
            Security
        ) {

            var AlphaPayService = GatewayService.init('alphaPay');

            var LINK_STATUS = {
                'UNLINKED': 'unlinked',
                'LINKED': 'linked',
                'NOT_APPLICABLE': 'not applicable'
            };
            $scope.linkStatus = LINK_STATUS.UNLINKED;

            $scope.getLinkStatus = function () {
                if (canConnectToAlphaPay()) {
                    AlphaPayService
                        .get()
                        .then(onLinkStatusRetrieved)
                        .catch(onLinkStatusError);
                } else {
                    $scope.linkStatus = LINK_STATUS.NOT_APPLICABLE;
                }
            };
            var onLinkStatusRetrieved = function (response) {
                if (AlphaPayService.alphaPay && AlphaPayService.alphaPay.isEnabled) {
                    $scope.linkStatus = LINK_STATUS.LINKED;
                    $scope.alphaPay = AlphaPayService.alphaPay;
                } else {
                    $scope.linkStatus = LINK_STATUS.UNLINKED;
                    $scope.alphaPay = undefined;
                }
            };
            var onLinkStatusError = function (error) {
                var data = error.data || {};
                var message = data.error || '';

                $scope.linkErrors.push({
                    type: message
                });
            };

            var canConnectToAlphaPay = function () {
                return !Security.isTrial();
            };

            $scope.linkSetup = {
                linking: false
            };
            var resetLinkSetup = function () {
                $scope.linkSetup.linking = false;
            };

            $scope.linkErrors = [];

            $scope.linkToAlphaPay = function () {
                $scope.linkErrors = [];
                $scope.linkSetup.linking = true;

                return AlphaPayService.link($scope.linkSetup.credential)
                    .then(onAlphaPayLinked)
                    .catch(onAlphaPayLinkError)
                    .then(function () {
                        $scope.linkSetup.linking = false;
                    });
            };
            var onAlphaPayLinked = function (response) {
                resetLinkSetup();

                $scope.getLinkStatus();
            };
            var onAlphaPayLinkError = function (error = {}) {
                var data = error.data || {};
                var message = data.error || '';

                if (error.status === -1) {
                    message = 'setupWizard.merchant.linkToAlphaPay.cannotConnect.description';
                }

                $scope.linkErrors.push({
                    type: message
                });
            };

            $scope.syncToAlphaPay = function () {
                $scope.linkErrors = [];
                $scope.linkSetup.linking = true;

                return AlphaPayService.sync()
                    .then(onAlphaPayLinked)
                    .catch(onAlphaPaySyncError)
                    .then(function () {
                        $scope.linkSetup.linking = false;
                    });
            };
            var onAlphaPaySyncError = function (error = {}) {
                var data = error.data || {};
                var message = data.error || '';

                if (error.status === -1) {
                    message = 'setupWizard.merchant.linkToAlphaPay.cannotSync.description';
                }

                $scope.linkErrors.push({
                    type: message
                });
            };

            $scope.unlinkFromAlphaPay = function () {
                $scope.linkErrors = [];

                return AlphaPayService.unlink()
                    .then(onAlphaPayUnlink)
                    .catch(onAlphaPayUnlinkError)
                    .then(function () {
                        $scope.linkSetup.linking = false;
                    });
            };
            var onAlphaPayUnlink = function (response) {
                onLinkStatusRetrieved(response);
            };
            var onAlphaPayUnlinkError = function (error) {
                var data = error.data || {};
                var message = data.error || '';

                $scope.linkErrors.push({
                    type: message
                });
            };

            $scope.canLinkToAlphaPay = function () {
                return AlphaPayService.isAvailable // whether the function exists
                    && AlphaPayService.isAvailable(); // whether the function returns true/false
            };

            $scope.init = function () {
                $scope.getLinkStatus();
            };
            $scope.init();
        }
    ]);

};
