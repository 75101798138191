'use strict';


export function systemLog (freshideas) {

    freshideas.factory('Logger', [
        function () {

            var enabled = false;
            var writeStream;
            var path;
            var fs;

            const defaults = {
                flags: 'a',
                defaultEncoding: 'utf8',
                fd: null,
                mode: 0o666,
                autoClose: true
            };
            const EOL = '\r\n';

            if (typeof window !== 'undefined') {
                // running from browser
                if (window.process && window.process.type) {
                    // running from electron
                    enabled = true;
                    if (!writeStream) {
                        initLogger();
                    }
                } else {
                    enabled = false;
                }
            }

            function initLogger () {
                if (!enabled) {
                    return;
                }
                path = nodeRequire('path');
                let os = nodeRequire('os');
                fs = nodeRequire('fs');

                const sep = path.sep;
                const targetDir = path.join(os.homedir(), 'FIIT POS', 'log');
                const initDir = path.isAbsolute(targetDir) ? sep : '';

                cleanOldLogs(targetDir);
                targetDir.split(sep).reduce((parentDir, childDir) => {
                    const curDir = path.resolve(parentDir, childDir);
                    if (!fs.existsSync(curDir)) {
                        fs.mkdirSync(curDir);
                    }

                    return curDir;
                }, initDir);

                writeStream = fs.createWriteStream(targetDir + '/' + getDay() + '.log', defaults);
            }

            function cleanOldLogs (dir) {
                if (!enabled) {
                    return;
                }
                fs.readdir(dir, function (err, files) {
                    if (err) {
                        console.error(err);
                        return;
                    }
                    files.forEach(function (file, index) {
                        let curFile = path.join(dir, file);
                        fs.stat(curFile, function (err, stat) {
                            if (err) {
                                console.error(err);
                                return;
                            }
                            if (stat.ctime && daysSince(new Date(), new Date(stat.ctime)) >= 13) {
                                // delete files older than 5 days
                                fs.unlink(curFile, (err) => {
                                    if (err) throw err;
                                    console.log('successfully deleted', curFile);
                                });
                            }
                        });
                    });
                });
            }

            function daysSince (firstDate, secondDate) {
                var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
            }

            var debug = function (message) {
                console.debug(message);
                write(message, 'debug');
            };

            var error = function (message) {
                console.error(message);
                write(message, 'error');
            };

            var warn = function (message) {
                console.warn(message);
                write(message, 'warn');
            };

            var info = function (message) {
                console.log(message);
                write(message, 'info');
            };

            function write (message, level) {
                if (!enabled) {
                    return;
                }
                if (path.basename(writeStream.path) != (getDay() + '.log')) {
                    initLogger();
                }
                if (writeStream) {
                    writeStream.write('[' + getDateTime() + '] [' + level.toUpperCase() + '] - ' + message + EOL);
                }
            }

            function getDay () {
                var date = new Date();

                var year = date.getFullYear();

                var month = date.getMonth() + 1;
                month = (month < 10 ? '0' : '') + month;

                var day = date.getDate();
                day = (day < 10 ? '0' : '') + day;

                return year + '-' + month + '-' + day;
            }

            function getDateTime () {
                var date = new Date();

                var hour = date.getHours();
                hour = (hour < 10 ? '0' : '') + hour;

                var min = date.getMinutes();
                min = (min < 10 ? '0' : '') + min;

                var sec = date.getSeconds();
                sec = (sec < 10 ? '0' : '') + sec;

                var year = date.getFullYear();

                var month = date.getMonth() + 1;
                month = (month < 10 ? '0' : '') + month;

                var day = date.getDate();
                day = (day < 10 ? '0' : '') + day;

                return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec;
            }

            const logger = {
                debug,
                error,
                warn,
                info
            };
            return logger;
        }]);
}
