'use strict';

const angular = require('angular');
const ngResources = require('angular-resource');

export default angular.module('freshideas.resources.quickbooks', [ngResources])
    .service('QuickBooks', ['$resource', 'EnvConfig', function ($resource, EnvConfig) {
        return $resource(EnvConfig.host + '/freshideas/web/quickbooks', {}, {
            'connectToQuickBooks': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/quickbooks/installation-uri'
            },
            'disconnectFromQuickBooks': {
                method: 'DELETE',
                url: EnvConfig.host + '/freshideas/web/quickbooks/'
            }
        });
    }]);
