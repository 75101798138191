'use strict';


module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ProductCategoryCtrl', [
        '$scope',
        '$timeout',
        '$translate',
        'PosAlertService',
        'ProductsService',
        'MenuService',
        'category',
        'organizationLanguages',
        'index',
        'menus',
        'parentItemId',
        'refreshProducts',
        '$q',
        function (
            $scope,
            $timeout,
            $translate,
            PosAlertService,
            ProductsService,
            MenuService,
            category,
            organizationLanguages,
            index,
            menus,
            parentItemId,
            refreshProducts,
            $q) {
            $scope.ProductCategoryCreateForm = {};

            $scope.category = angular.copy(category) || {active: true, color: '#40d0ad', type: 'category'};
            $scope.isNew = !$scope.category.locationServicePeriodMenuId;
            $scope.menus = menus;
            $scope.organizationLanguages = organizationLanguages;

            if (index >= 0 && $scope.isNew) {
                $scope.category.menuOrderId = index + 1;
            }

            $scope.taxRule = function (name) {
                return ProductsService.getTaxRule(name);
            };

            var highlightInputsFn;
            var runHighlightInputs = function () {
                if (highlightInputsFn) {
                    return;
                }

                $scope.highlightInputs = true;
                highlightInputsFn = $timeout(function () {
                    $scope.highlightInputs = false;
                    highlightInputsFn = undefined;
                }, 1000);
            };

            $scope.getMenuName = (menuId) => {
                return MenuService.getMenuName(menuId, $scope.menus.all);
            };

            var isCategoryValid = function () {
                if (!$scope.ProductCategoryCreateForm.$valid) {
                    runHighlightInputs();
                    return false;
                }

                return true;
            };

            // this filter is used to determine the current menu when editing a category.
            $scope.menuFilter = (menu) => {
                return menu.menuId === $scope.menus.selected.menuId;
            };

            $scope.colorOptions = [
                '#e6e9ed',
                '#ffcf47',
                '#40d0ad',
                '#69b5f8',
                '#ee85c1',
                '#ff585b',

                '#ddcfc7',
                '#f7efcb',
                '#b3e0b3',
                '#a2d5ff',
                '#9e88dd',
                '#ffaa8e'
            ];
            $scope.toggleColorOptions = function () {
                $scope.showColorOptions = !$scope.showColorOptions;
            };
            $scope.setColor = function (color) {
                $scope.category.color = color;
            };

            $scope.saveCategory = () => {
                if (!isCategoryValid()) {
                    return;
                }

                $scope.savingCategory = true;

                var categoryToSave = angular.copy($scope.category);

                categoryToSave.menuVariations = $scope.menuAssociations.filter((menu) => {
                    // filter only associated menus.
                    if (menu.associated) {
                        return {
                            menuId: menu.menuId,
                            associatedCompanyIds: menu.associatedCompanyIds || []
                        };
                    }
                });

                let savePromise;
                if ($scope.isNew) {

                    if (!MenuService.validateMenuAssociations($scope.menuAssociations)) {
                        PosAlertService.showAlertByName('general-error', {
                            title: 'product.masterProductModal.menuAssociations.error.ttl',
                            message: 'product.masterProductModal.menuAssociations.error.desc'
                        });
                        $scope.isSavingItem = false;
                        return;
                    }

                    savePromise = MenuService.addMenuItemInQueue(categoryToSave);
                } else {
                    savePromise = MenuService.updateMenuItemInQueue(categoryToSave, $scope.menus);
                }

                savePromise.then((savedCategory) => {

                    let promiseArr = [];
                    let itemsToSave = [];
                    savedCategory.menuVariations.map((menuVariation) => {
                        let savedCategoryCopy = angular.copy(savedCategory);
                        savedCategoryCopy.parentItemId = parentItemId;
                        savedCategoryCopy.menuId = menuVariation.menuId;

                        itemsToSave.push(savedCategoryCopy);
                    });

                    promiseArr.push(MenuService.addAssociations({
                        itemToSave: itemsToSave,
                    }));

                    // we need to refresh the products list in the parent component
                    // we can only perform this AFTER all product associations are done.
                    // refreshProducts function is passed from product.menu.ctrl.js to products.ctrl.js to here.
                    $q.all(promiseArr).then(() => {
                        MenuService.updateMobileMenu(ProductsService.locationDetail.locationId);
                        refreshProducts();
                    }).finally(() => {
                        $scope.savingCategory = false;
                        $scope.$close(savedCategory);
                    });
                });
            };

            var confirmDeleteCategory = function () {
                return MenuService.deleteItemFromCategory($scope.category.locationServicePeriodMenuId, [ProductsService.getSelectedMenuId()])
                .then(function () {
                    $scope.$close();
                });
            };

            $scope.deleteCategory = function () {
                // verify we can grab the first available translation to display.
                if (!$scope.category.itemDetails
                    && !$scope.category.itemDetails.translations
                    && !$scope.category.itemDetails.translations.length) {
                    return;
                }

                let firstAvailableTranslation = $scope.category.itemDetails.translations.find((translation) => {
                    return translation.itemName;
                });

                // provide a fallback name in the edge case translation cannot be found.
                if (!firstAvailableTranslation) {
                    firstAvailableTranslation = {itemName: $translate.instant('product.category.delete.fallbackName')};
                }

                PosAlertService.showAlertByName('general-alert', {
                    title: 'product.category.delete.title',
                    message: $translate.instant('product.category.delete.confirmation', {
                        categoryName: firstAvailableTranslation.itemName
                    }),
                    buttonTitleOk: 'item.availability.confirmation.btn',
                    modalCallback: function () {
                        return confirmDeleteCategory();
                    }
                });
            };

            $scope.init = () => {
                MenuService.setMenuVariations($scope.category, $scope.menus);
                MenuService.setTranslations($scope.category, $scope.organizationLanguages);
                $scope.menuAssociations = MenuService.setMenuAssociations($scope.menus);
            };

            $scope.init();

        }])
        .value('category', undefined)
        .value('index', -1)
        .value('targetCategory', undefined);
};
