'use strict';


export function posStatusController (freshideas) {

    freshideas.controller('PosStatusController', [
        '$scope',
        'PosStatusService',
        'SystemConnectivityService',
        function ($scope, PosStatusService, SystemConnectivityService) {
            // Show warning bar on top if bluetooth is offline
            $scope.posStatusService = PosStatusService;

            $scope.iosBluetoothOnline = function () {
                return PosStatusService.iosBluetoothOnline();
            };

            $scope.offline = PosStatusService.isOffline();

            $scope.$watch(function () {
                return PosStatusService.isOffline();
            }, function (value, oldValue) {
                $scope.offline = value;
            });

            $scope.shiftStarted = PosStatusService.shiftStarted;

            $scope.$watch(function () {
                return PosStatusService.shiftStarted;
            }, function (value) {
                $scope.shiftStarted = value;
            });

            // NOTE: Need to place offline alert with a controller that is always present. PosStatusController seems to be the
            // one that fits the bill the most. Will determine if we should move this to a different part of the code.
            $scope.connectivity = SystemConnectivityService;

        }]);

}
