'use strict';

const angular = require('angular');

export default angular
    .module('freshideas.resources.ios-tcp-client', [])
    .factory('IosTcpClient', [
        'BridgedPromise',
        '$q',
        'Platform',
        'Lucova',
        function (
            BridgedPromise,
            $q,
            Platform,
            Lucova) {
        var _self = {};

        // have a function that just listens for tcp events

        // need a way to receive the continuous stream of responses
        // but also send messages at any point


        if (Platform.isIosWebkit()) {
            _self.connect = function (port, ip) {
                var dataCallback;
                var _retryAction = function (action, numTries) {
                    return $q.when()
                        .then(action)
                        .catch(function (error) {
                            if (numTries <= 0) {
                                throw error;
                            }
                            return _retryAction(action, numTries - 1);
                        });
                };

                var onData = function (callback) {
                    dataCallback = callback;
                    var message = {
                        type: 'CardTerminalOnData'
                    };

                    window.addEventListener('CardTerminalOnData', dataHandler);

                    window.webkit.messageHandlers.bridge.postMessage(message);
                };

                var dataHandler = function (response) {
                    console.log('TCP: onData: ');
                    console.log(response.detail);

                    dataCallback(response.detail.data);
                };

                var disconnect = function () {
                    console.log('TCP: Disconnecting');

                    window.removeEventListener('CardTerminalOnData', dataHandler);
                };

                var write = function (data) {
                    console.log('TCP: Writing: ' + data);
                    return BridgedPromise.dispatch('CardTerminalWrite', {data: data});
                };

                var promise = new Promise(function (resolve, reject) {
                    console.log('TCP: Connecting');

                    // Retry connecting to card terminal up to five times
                    // An exception will get thrown and reach the catch block below
                    // upon the fifth failure.
                    _retryAction(
                        function () {
                            return BridgedPromise.dispatch('CardTerminalConnect', {
                                host: ip,
                                port: port
                            });
                        },
                        5
                    ).then(function () {
                        console.log('TCP: Connected');

                        resolve({
                            onData: onData,
                            write: write,
                            disconnect: disconnect
                        });
                    }).catch(function () {
                        // This message should get logged by Honeybadger higher up
                        // WARNING: Do not reject with empty payload because
                        // cardterminal.moneris, cardterminal.globalpay etc.
                        // all expect some kind of valid error object to be passed into
                        // their respective catch blocks.
                        //
                        // This string will work as well
                        reject('TCP: iOS failed to connect to card terminal 5 times');
                    });
                });

                return promise;
            };
        } else if (Platform.isTizen()) {
            _self.connect = function (port, ip) {
                var dataCallback;
                var _retryAction = function (action, numTries) {
                    return $q.when()
                        .then(action)
                        .catch(function (error) {
                            if (numTries <= 0) {
                                throw error;
                            }
                            return _retryAction(action, numTries - 1);
                        });
                };

                var onData = function (callback) {
                    dataCallback = callback;
                    window.addEventListener('paymentResponse', dataHandler);
                };

                var dataHandler = function (response) {
                    console.log('TCP: onData: ');
                    console.log(response.detail);

                    Lucova.manager().sendMerchantEvent({type: 'tizen', level: 'info', message: JSON.stringify(response)},
                        function (response) { }, function (error) {
                            console.log(error); // using console.log instead of $log to prevent accidental loop
                        });

                    if (dataCallback) {
                        dataCallback(response.detail.data);
                    }
                };

                var disconnect = function () {
                    console.log('TCP: Disconnecting');
                    window.removeEventListener('paymentResponse', dataHandler);
                };

                var write = function (data) {
                    console.log('TCP: Writing: ' + data);
                    window.parent.postMessage(
                        {
                            msgType: 'paymentCmd',
                            message: {
                                ip: ip,
                                port: port,
                                message: data
                            }
                        }, '*');
                };

                var promise = new Promise(function (resolve, reject) {
                    console.log('TCP: Connecting');

                    resolve(
                       {
                           onData: onData,
                           write: write,
                           disconnect: disconnect
                       }
                    );
                });

                return promise;
            };
        }

        return _self;
    }]);
