
module.exports = function (freshideasProducts) {
    freshideasProducts.controller('AddCardTerminalController', [
        '$scope',
        '$modalInstance',
        function ($scope,
            $modalInstance) {
            $scope.cardTerminal = {
                ip: null,
                port: 5555
            };

            $scope.ipTest = function (ip) {
                return /^(([1-9]?\d|1\d\d|2[0-4]\d|25[0-5])(\.(?!$)|(?=$))){4}$/.test(ip || '');
            };

            $scope.close = function (cancel) {
                if (!cancel) {
                    $modalInstance.close($scope.cardTerminal);
                } else {
                    $modalInstance.close();
                }
            };

        }]);
};

