'use strict';
const Decimal = require('decimal.js').default;
const SharedDataService = require('../../external/pos.data-service.js');

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosTenderTerminalRecoveryCtrl', [
        '$scope',
        '$timeout',
        'tender',
        'terminalInterface',
        'multiPartData',
        function (
            $scope,
            $timeout,
            tender,
            terminalInterface,
            multiPartData
        ) {
            $scope.tender = tender;
            $scope.isLoading = true;

            $scope.initialStep = {
                name: 'initial',
                option: ''
            };
            $scope.retryStep = {
                name: 'retry',
                option: '',
                attempt: 0,
                lastCharge: undefined
            };

            $scope.multiPartStep = {
                name: 'multiPart',
                option: '',
                lastCharge: undefined
            };

            $scope.steps = {
                initial: $scope.initialStep,
                retry: $scope.retryStep,
                multiPart: $scope.multiPartStep,
            };

            $scope.setOption = function (name, option) {
                if ($scope.steps[name].option === option) {
                    $scope.steps[name].option = '';
                } else {
                    $scope.steps[name].option = option;
                }
            };

            $scope.confirmOption = function (name) {
                if (name === 'initial') {
                    switch ($scope.initialStep.option) {
                        case 'notSure':
                            fetchLastStatus();
                            break;
                        case 'no':
                            $scope.$dismiss();
                            break;
                        case 'yes':
                            // flag to indicate this is a credit card tender
                            // without terminal response, like a manul credit
                            // card workflow
                            $scope.$close({isManual: true});
                            break;
                    }

                    $scope.initialStep.option = '';
                    return;
                }

                if (name === 'retry') {
                    switch ($scope.retryStep.option) {
                        case 'reattempt':
                            fetchLastStatus();
                            break;
                        case 'exit':
                            $scope.$dismiss();
                            break;
                        case 'yes':
                            $scope.$close({
                                isManual: false,
                                result: $scope.retryStep.lastChargeData
                            });
                            break;
                        case 'initial':
                            $scope.currentStep = $scope.initialStep;
                            break;
                    }

                    $scope.retryStep.option = '';
                    return;
                }

                if (name === 'multiPart') {
                    switch ($scope.multiPartStep.option) {
                        case 'exit':
                            $scope.$close({
                                result: $scope.multiPartStep.lastCharge,
                                completeMultiPartTrx: false
                            });
                            break;
                        case 'continue':
                            $scope.$close({
                                result: $scope.multiPartStep.lastCharge,
                                completeMultiPartTrx: true
                            });
                            break;
                    }

                    $scope.multiPartStep.option = '';
                    return;
                }
            };

            var fetchLastStatus = async function (firstTry) {
                $scope.retryStep.attempt++;
                $scope.retryStep.isLoading = true;

                if (terminalInterface) {
                    var lastChargeData;
                    try {
                        lastChargeData = await terminalInterface.lastTransactionStatus();
                        if (lastChargeData) {
                            var transactionDateTime = lastChargeData[0].transactionDateTime;
                            var approvedAmount = new Decimal(lastChargeData[0].approvedAmount).toNearest(SharedDataService.baseDollar).toNumber();
                            var tipAmount = new Decimal(lastChargeData[0].tipAmount).toNearest(SharedDataService.baseDollar).toNumber();
                            var approvedAmountWithoutTip = new Decimal(approvedAmount).minus(new Decimal(tipAmount)).toNearest(SharedDataService.baseDollar).toNumber();
                            var partiallyApproved = !!lastChargeData[0].partiallyApproved;
                            var cardNumber = lastChargeData[0].cardNumber;

                            if (!multiPartData.multiPart) {
                                $scope.retryStep.lastChargeData = lastChargeData;

                                $scope.retryStep.lastCharge = [{
                                    transactionDateTime: transactionDateTime,
                                    approvedAmount: approvedAmountWithoutTip,
                                    tipAmount: tipAmount,
                                    partiallyApproved: partiallyApproved,
                                    cardNumber: cardNumber
                                }];
                            } else {
                                $scope.multiPartStep.message = multiPartData.notifyUser;

                                $scope.multiPartStep.lastCharge = [{
                                    transactionDateTime: transactionDateTime,
                                    approvedAmount: approvedAmountWithoutTip,
                                    tipAmount: tipAmount,
                                    partiallyApproved: partiallyApproved,
                                    cardNumber: cardNumber
                                }];
                            }

                        }
                    } catch (e) {
                        console.log(e);
                    }

                    $scope.retryStep.isLoading = false;
                    if (!lastChargeData && $scope.retryStep.attempt <= 1) {
                        fetchLastStatus(firstTry);
                    } else {
                        $scope.isLoading = false;

                        if (firstTry && !lastChargeData) {
                            $scope.currentStep = $scope.initialStep;
                        } else if (multiPartData.multiPart) {
                            $scope.currentStep = $scope.multiPartStep;
                        } else {
                            $scope.currentStep = $scope.retryStep;
                        }

                        return;
                    }
                }

                $scope.isLoading = false;
                $scope.currentStep = $scope.initialStep;
            };

            $scope.init = function () {
                fetchLastStatus(true);
            };
            $scope.init();
        }]
    );
};
