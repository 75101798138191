'use strict';


const angular = require('angular');

export default angular.module('freshideas.services.dateRange', [])
.factory('DateRangeService', [
    '$timeout',
    '$window',
    '$log',
    'Locations',
    'BridgedPromise',
    'EventListenerService',
    'SharedDataService',
    function (
        $timeout,
        $window,
        $log,
        Locations,
        BridgedPromise,
        EventListenerService,
        SharedDataService) {

        var toDate, fromDate;

        var getFromDate = function () {
            return fromDate;
        };

        var getToDate = function () {
            return toDate;
        };

        var setFromDate = function (date) {
            fromDate = date;
        };

        var setToDate = function (date) {
            toDate = date;
        };

        return {
            getFromDate: getFromDate,
            getToDate: getToDate,
            setFromDate: setFromDate,
            setToDate: setToDate
        };
    }
]);
