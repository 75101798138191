'use strict';


export function posExpired (freshideas) {
    freshideas.controller('PosExpiredController', [
    '$scope',
    '$log',
    'PosStatusService',
    'LucovaBluetooth',
    'Company',
    'Security',
    function ($scope, $log, PosStatusService, LucovaBluetooth, Company, Security) {
        $scope.posStatusService = PosStatusService;

        $scope.upgradeClicked = false;

        $scope.requestUpgrade = function () {
            Company.upgrade({}).$promise.then(function (response) {
                $scope.upgradeClicked = true;
                window.open('https://www.nownpos.com/nown-signup-2/');
            }).catch(function (err) {
                $log.error(err);
            });
        };

        $scope.logout = function () {
            PosStatusService.shiftStarted = false;
            Security.logout();
            LucovaBluetooth.stop();
        };
    }]);
}


