'use strict';

const angular = require('angular');
// const $ = require('jquery');
// const moment = require('moment');

export default angular.module('freshideas.services.monitoring', []).factory('MonitoringService', [
    'EnvConfig',
    function (EnvConfig) {

        var enabled = EnvConfig.env === 'production';
        var monitors = {};

        var initialize = function (options) {
            var name = options.name;
            var type = options.type; // eg. 'once', 'average', 'duration'
            var threshold = options.threshold || 0;
            var action = options.action; // send to HoneyBadger, send to Lucova, etc

            // A monitor is used to accept an input (eg. a metric in the system), which then
            //   calculates an output value based on its `type`, and perform an action if
            //   the output value meets the threshold. Here are the fields needed to initialize
            //   a monitor
            // - name:      A name to identify the monitor. This allows a monitor to be defined
            //                  one time (eg. in the beginnign of the code) and be reused anywhere
            //                  in the code without having to repeat certain attributes (eg. action)
            // - type:      The type of monitor. Possible values are `once`, `average`, or `duration`.
            // - threshold: Minimum value needed to trigger the `action`.
            // - action:    Action to perform (eg. HoneyBadger, FIIT logging)
            monitors[name] = monitors[name] || {
                name: name,
                type: type,
                threshold: threshold,
                action: action,
                _data: undefined // data for internal use (eg. promise callback to calculate duration)
            };

            return this;
        };

        var verifyName = function (name) {
            if (!monitors[name]) {
                throw new Error('Monitor name "' + name + '" is not initialized');
            }
        };

        var add = function (name, value, context) {
            var nameArray = name.split('.');
            var mainName = nameArray[0];
            verifyName(mainName);

            var monitor = monitors[mainName];
            if (monitor) {

                // Current only three monitor types are supported
                // `once`:      This looks at the current input value and perform action if it
                //                  meets the threshold.
                // `average`:   This stores the historical total and count of the input values
                //                  and calculates the running average. If the running average
                //                  goes above the threshold, perform the action
                // `duration`:  This stores a `start` value and an `end` value, and calculate
                //                  the duration between the 2 values. If the duration is above
                //                  a threshold, perform the action. To prevent memory leak,
                //                  there is a 60 second timeout to receive an `end` value after
                //                  a `start` value is received.
                switch (monitor.type) {
                    case 'once':
                        _addOnce(monitor, value, context);
                        break;
                    case 'average':
                        _addAvg(monitor, value, context);
                        break;
                    case 'duration':
                        // _addDuration(monitor, value, nameArray[1], nameArray[2], context);
                        break;
                }
            }

            return this;
        };

        var _addOnce = function (monitor, value, context) {
            if (value >= monitor.threshold) {
                _executeAction(monitor, {
                    value: value,
                    context: context
                });
            }
        };
        var _addAvg = function (monitor, value, context) {
            monitor._data = monitor._data || {
                total: 0,
                count: 0
            };

            monitor._data.total = monitor._data.total + value;
            monitor._data.count = monitor._data.count + 1;

            var average = monitor._data.total / monitor._data.count;

            if (average >= monitor.threshold) {
                _executeAction(monitor, {
                    value: average,
                    context: context
                });
            }
        };
        // Not used for now
        // var _addDuration = function (monitor, value, identifier, endPoint, context) {
        //     monitor._data = monitor._data || {};
        //     monitor._data[identifier] = monitor._data[identifier] || {};

        //     switch (endPoint) {
        //         case 'start':
        //             var mainPromise = new Promise(function (resolve, reject) {
        //                 monitor._data[identifier]._resolve = resolve;
        //                 monitor._data[identifier]._reject = reject;
        //             });

        //             var timeoutPromise = new Promise(function (resolve, reject) {
        //                 setTimeout(function () {
        //                     reject({timeout: true});
        //                 }, 60000);
        //             });

        //             var promise = new Promise(function (resolve, reject) {
        //                 var innerPromise = Promise.race([timeoutPromise, mainPromise]);
        //                 innerPromise.then(function (data) {
        //                     var end = data.value;
        //                     var endContext = data.context;

        //                     var duration = end - value;
        //                     if (duration >= monitor.threshold) {
        //                         _executeAction(monitor, {
        //                             identifier: identifier,
        //                             value: duration,
        //                             context: {
        //                                 start: context,
        //                                 end: endContext
        //                             }
        //                         });
        //                     }

        //                     delete monitor._data[identifier];
        //                 }, function (error) {
        //                     delete monitor._data[identifier];
        //                 });
        //             });

        //             monitor._data[identifier].start = value;
        //             monitor._data[identifier]._promise = promise;
        //             break;
        //         case 'end':
        //             if (monitor._data[identifier]._resolve) {
        //                 monitor._data[identifier]._resolve({
        //                     value: value,
        //                     context: context
        //                 });
        //             }

        //             delete monitor._data[identifier];
        //             break;
        //     }
        // };

        var _executeAction = function (monitor, params) {
            if (enabled) {
                // inject recently visited url history.
                monitor.action(params);
            }
        };

        return {
            initialize: initialize,
            add: add,
            monitors: monitors
        };
    }]);
