'use strict';

const moment = require('moment');

module.exports = function (freshideasReports) {
    freshideasReports.controller('SummaryReportCtrl', [
        '$scope',
        'Reports',
        'Calculate',
        'Export',
        '$filter',
        'DateRangeService',
        'Security',
        'Lookup',
        'USER_ROLE_TYPE',
        function ($scope, Reports, Calculate, Export, $filter, DateRangeService, Security, Lookup, USER_ROLE_TYPE) {
            var currentUser = Security.getUser() || {};
            $scope.isManager = currentUser.roleType === USER_ROLE_TYPE.MANAGER;
            $scope.isAccountant = currentUser.roleType === USER_ROLE_TYPE.ACCOUNTANT;
            $scope.isSiteAdmin = currentUser.permission === 'SITEADMIN';
            $scope.searchDisabled = false;
            var allowRetry;
            var convertReport = function (report) {
                var topPurchasedItems = _.sortBy(report.purchasedItems, function (item) {
                    return -item.quantity;
                }) || [];
                var days = [
                    'monday',
                    'tuesday',
                    'wednesday',
                    'thursday',
                    'friday',
                    'saturday',
                    'sunday'
                ];
                var dailyInfos = _.map(days, function (day) {
                    var dailyInfo = _.find(report.dailyInformation, function (dailyInformation) {
                        return dailyInformation.dayName === day;
                    }) || {
                        dayName: day,
                        dollarAmount: 0,
                        quantity: 0,
                        unitAmount: 0
                    };
                    return dailyInfo;
                });
                Calculate.chain(dailyInfos, [
                    {
                        method: 'max_multiple',
                        fields: [
                            'quantity',
                            'dollarAmount',
                            'unitAmount'
                        ]
                    },
                    {
                        method: 'percentize_multiple',
                        fields: [
                            'quantity',
                            'dollarAmount',
                            'unitAmount'
                        ]
                    }
                ]);
                var dailyMaxes = {};
                dailyMaxes.quantity = _.filter(dailyInfos, function (dailyInfo) {
                    return dailyInfo.max.quantity;
                });
                dailyMaxes.dollarAmount = _.filter(dailyInfos, function (dailyInfo) {
                    return dailyInfo.max.dollarAmount;
                });
                dailyMaxes.unitAmount = _.filter(dailyInfos, function (dailyInfo) {
                    return dailyInfo.max.unitAmount;
                });
                var allTrafficLocations = [];
                var trafficLocations = _.map(report.trafficLocations, function (trafficLocation) {
                    var trafficTimes = trafficLocation.trafficTimes;
                    return _.map(trafficTimes, function (trafficTime) {
                        trafficTime.locationName = trafficLocation.name;
                        trafficTime.locationId = trafficLocation.id;
                        return trafficTime;
                    });
                });
                allTrafficLocations = _.flatten(trafficLocations, true);
                // allTrafficLocations = _.sortBy(allTrafficLocations, function (topTrafficLocation) {
                //     return topTrafficLocation.quantity;
                // });
                var topTrafficLocations = _.first(allTrafficLocations, 3);
                var nonTopTrafficLocations = _.rest(allTrafficLocations, 3);
                var bottomTrafficLocations = _.last(nonTopTrafficLocations, 1);
                var totalTransactions = 0;
                var totalSales = 0;
                var totalMeals = 0;
                _.each(dailyInfos, function (dailyInfo) {
                    totalTransactions += dailyInfo.quantity || 0;
                    totalSales += dailyInfo.dollarAmount || 0;
                    totalMeals += dailyInfo.unitAmount || 0;
                });
                var totalShiftHours = report.cashierShifts / (1000 * 60 * 60);
                return {
                    topPurchasedItems: topPurchasedItems,
                    dailyInfos: dailyInfos,
                    dailyMaxes: dailyMaxes,
                    topTrafficLocations: topTrafficLocations,
                    bottomTrafficLocations: bottomTrafficLocations,
                    totalSales: totalSales,
                    totalMeals: totalMeals,
                    totalTransactions: totalTransactions,
                    totalShiftHours: totalShiftHours
                };
            };
            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };
            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };
            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };
            var dayShortNames = {
                'monday': 'M',
                'tuesday': 'Tu',
                'wednesday': 'W',
                'thursday': 'Th',
                'friday': 'F',
                'saturday': 'Sa',
                'sunday': 'Su'
            };
            $scope.formatCentToDollar = function (cent) {
                return $filter('centToDollar')(cent);
            };
            $scope.dayShortName = function (dayName) {
                return dayShortNames[dayName];
            };
            $scope.getSummaryReport = function () {
                $scope.searchDisabled = true;
                clearTimeout(allowRetry);
                allowRetry = setTimeout(function () {
                    $scope.searchDisabled = false;
                }, 30000);
                var reportSearch = {};
                reportSearch.startDateTime = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                var endDateTime = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                var currentDateTime = moment().valueOf();
                reportSearch.endDateTime = Math.min(endDateTime, currentDateTime);
                reportSearch.locationId = $scope.reportSearch.locationId;
                $scope.loadingSummaryReport = true;
                Reports.getSummaryReport(reportSearch, function (response) {
                    var report = response.summaryReport || {};
                    $scope.results = convertReport(report);
                    var currentReportSearch = {
                        fromDate: $scope.reportSearch.fromDate,
                        toDate: $scope.reportSearch.toDate
                    };
                    currentReportSearch = _.pick(currentReportSearch, function (value) {
                        return !!value;
                    });
                    if (_.isEmpty(currentReportSearch)) {
                        $scope.currentReportSearch = undefined;
                    } else {
                        $scope.currentReportSearch = currentReportSearch;
                    }
                    $scope.loadingSummaryReport = false;
                });
            };
            $scope.saveSelectedFromDate = function () {
                var selectedFromDate = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                DateRangeService.setFromDate(selectedFromDate);
            };
            $scope.saveSelectedToDate = function () {
                var selectedToDate = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                DateRangeService.setToDate(selectedToDate);
            };
            function loadCompanyHierarchy () {
                return Lookup.locationsByCompany({isAdvancedSearch: true}, function (response) {
                    $scope.company = _.findWhere(response, {id: currentUser.companyId});
                    $scope.locations = [];
                    let children = ($scope.company && $scope.company.children) ? $scope.company.children : [];

                    if (children.length) {
                        if (children.length > 1) {
                            $scope.locations.push({
                                id: null,
                                name: 'All Locations'
                            });
                        }
                        _.each(children, function (location) {
                            $scope.locations.push(location);
                        });
                        $scope.reportSearch.locationId = currentUser.company.locationId;
                    }
                });
            }
            $scope.clearFilters = function () {
                $scope.reportSearch.fromDate = moment().startOf('week').toDate();
                $scope.reportSearch.toDate = moment().endOf('day').toDate();
                $scope.reportSearch.isAdvancedSearch = true;
                $scope.reportSearch.sortBy = 'itemName';
                $scope.reportSearch.locationId = currentUser.company.locationId;

                $scope.loadItemSales();
            };
            $scope.init = function () {
                var fromDate;
                var toDate;
                if (DateRangeService.getFromDate()) {
                    fromDate = moment(DateRangeService.getFromDate()).startOf('day').toDate();
                } else {
                    fromDate = moment().startOf('week').toDate();
                }
                if (DateRangeService.getToDate()) {
                    toDate = moment(DateRangeService.getToDate()).endOf('day').toDate();
                } else {
                    toDate = moment().endOf('day').toDate();
                }
                $scope.reportSearch = {
                    fromDate: fromDate,
                    toDate: toDate,
                    isAdvancedSearch: true
                };
                loadCompanyHierarchy().$promise.then(function () {
                    $scope.getSummaryReport();
                });
            };
            $scope.init();
            $scope.exportToPdf = function (tableId) {
                Export.tableToPdf(tableId, 'portrait');
            };
            $scope.$on('$destroy', function () {
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.locationId', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.toDate', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.fromDate', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });
        }
    ]);
};
