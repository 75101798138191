'use strict';

const angular = require('angular');

/**
 * This module internalizes global login event broadcasting and failed http retry queue.
 */
export default angular.module('freshideas.security.interceptor', ['http-auth-interceptor-buffer'])

    .factory('AuthService', ['$rootScope', 'HttpBuffer', function ($rootScope, HttpBuffer) {
        return {
            /**
             * Call this function to indicate that authentication was successful and trigger a
             * retry of all deferred requests.
             * @param data an optional argument to pass on to $broadcast which may be useful for
             * example if you need to pass through details of the user that was logged in
             */
            loginConfirmed: function (data, configUpdater) {
                var updater = configUpdater || function (config) {
                    return config;
                };
                $rootScope.$broadcast('auth-loginConfirmed', data);
                HttpBuffer.retryAll(updater);
            },

            /**
             * Call this function to indicate that authentication should not proceed.
             * All deferred requests will be abandoned or rejected (if reason is provided).
             * @param data an optional argument to pass on to $broadcast.
             * @param reason if provided, the requests are rejected; abandoned otherwise.
             */
            loginCancelled: function (data, reason) {
                HttpBuffer.rejectAll(reason);
                $rootScope.$broadcast('auth-loginCancelled', data);
            }
        };
    }])

    .config(['$httpProvider', function ($httpProvider) {
        var interceptor = ['$rootScope', '$q', 'HttpBuffer', 'EnvConfig', function ($rootScope, $q, HttpBuffer, EnvConfig) {
            return {
                'responseError': function (response) {
                    // Check for ignoreAuthModule and reject if found
                    if (angular.isDefined(response.config)
                        && angular.isDefined(response.config.params)
                        && response.config.params.ignoreAuthModule) {
                        return $q.reject(response);
                    }

                    // logout only if the Unauthorized request is from Nōwn's own backend
                    if (response.status === 401 && response.config.url.indexOf('/freshideas/web/') > -1) {
                        var deferred = $q.defer();
                        HttpBuffer.append(response.config, deferred);
                        $rootScope.$broadcast('auth-loginRequired');
                        return deferred.promise;
                    }

                    // otherwise, default behavior
                    return $q.reject(response);
                }
            };
        }];
        $httpProvider.interceptors.push(interceptor);
    }]);


/**
 * Private module, a utility, required internally by 'mdm.security.interceptor'.
 */
angular.module('http-auth-interceptor-buffer', [])

    .factory('HttpBuffer', ['$injector', function ($injector) {
        /** Holds all the requests, so they can be re-requested in future. */
        var buffer = [];

        /** Service initialized later because of circular dependency problem. */
        var $http;

        function retryHttpRequest (config, deferred) {
            function successCallback (response) {
                deferred.resolve(response);
            }

            function errorCallback (response) {
                deferred.reject(response);
            }

            $http = $http || $injector.get('$http');
            $http(config).then(successCallback, errorCallback);
        }

        return {
            /**
             * Appends HTTP request configuration object with deferred response attached to buffer.
             */
            append: function (config, deferred) {
                buffer.push({
                    config: config,
                    deferred: deferred
                });
            },

            /**
             * Abandon or reject (if reason provided) all the buffered requests.
             */
            rejectAll: function (reason) {
                if (reason) {
                    for (var i = 0; i < buffer.length; ++i) {
                        buffer[i].deferred.reject(reason);
                    }
                }
                buffer = [];
            },

            /**
             * Retries all the buffered requests clears the buffer.
             */
            retryAll: function (updater) {
                for (var i = 0; i < buffer.length; ++i) {
                    retryHttpRequest(updater(buffer[i].config), buffer[i].deferred);
                }
                buffer = [];
            }
        };
    }]);
