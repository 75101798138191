'use strict';


module.exports = function (freshideasSystem) {

    freshideasSystem.factory('SystemTerminalService', [
        '$rootScope',
        '$timeout',
        '$log',
        'BridgedPromise',
        'Lookup',
        'PromiseRetry',
        'SystemService',
        function (
            $rootScope,
            $timeout,
            $log,
            BridgedPromise,
            Lookup,
            PromiseRetry,
            SystemService) {

            var TERMINAL_STATUS = {
                OK: 0,
                REQUIRES_UPDATE: 1,
                CAN_USE_UPDATE: 2
            };

            var minimumTerminalBuildNumber = 0;
            var recommendedTerminalBuildNumber = 0;
            var currentTerminalBuildNumber = 0;

            var minimizeUntil = 0;

            var onSystemStatusUpdated = function (event, status) {
                minimumTerminalBuildNumber = status.minimumTerminalBuildNumber;
                recommendedTerminalBuildNumber = status.recommendedTerminalBuildNumber;

                getCurrentBuildNumber();
            };

            var getCurrentBuildNumber = function () {
                if (SystemService.isWebkit) {
                    PromiseRetry.start({
                        fn: function () {
 return BridgedPromise.dispatch('getBuildNumber', {});
},
                        timeout: 20000,
                        maxRetry: 0 // No retry because poll is every 30s anyways
                    }).then(function (response) {
                        var buildNumber = parseInt(response.buildNumber);
                        buildNumber = (isNaN(buildNumber))? 0 : buildNumber;

                        currentTerminalBuildNumber = buildNumber;
                        $rootScope.$apply(function () {
                            validateCurrentBuildNumber();
                        });
                    }).catch(function () {
                        $log.info('getBuildnumber: No Response');
                    });
                }
            };

            var validateCurrentBuildNumber = function () {
                if (currentTerminalBuildNumber < minimumTerminalBuildNumber) {
                    terminal.terminalStatus = TERMINAL_STATUS.REQUIRES_UPDATE;
                } else if (currentTerminalBuildNumber < recommendedTerminalBuildNumber) {
                    if (minimizeUntil > recommendedTerminalBuildNumber) {
                        terminal.terminalStatus = TERMINAL_STATUS.OK;
                    } else {
                        terminal.terminalStatus = TERMINAL_STATUS.CAN_USE_UPDATE;
                    }
                } else {
                    terminal.terminalStatus = TERMINAL_STATUS.OK;
                }
            };

            var minimize = function () {
                minimizeUntil = recommendedTerminalBuildNumber + 1;
                terminal.terminalStatus = TERMINAL_STATUS.OK;
            };

            $rootScope.$on('system.status', onSystemStatusUpdated);

            var terminal = {
                terminalStatus: TERMINAL_STATUS.OK,
                minimize: minimize,
                TERMINAL_STATUS: TERMINAL_STATUS
            };
            return terminal;
        }
    ]);

    freshideasSystem.controller('SystemTerminalCtrl', [
        '$scope',
        '$sce',
        '$timeout',
        '$translate',
        'SystemService',
        'SystemTerminalService',
        function (
            $scope,
            $sce,
            $timeout,
            $translate,
            SystemService,
            SystemTerminalService) {

            $scope.terminal = SystemTerminalService;
            $scope.TERMINAL_STATUS = $scope.terminal.TERMINAL_STATUS;

            var regexParagraphStart = /&lt;p&gt;/g;
            var regexParagraphEnd = /&lt;\/p&gt;/g;
            $translate('general.terminalRefresh.required.message').then(function (result) {
                result = result.replace(regexParagraphStart, '<p>');
                result = result.replace(regexParagraphEnd, '</p>');
                $scope.messageRequiresUpdate = $sce.trustAsHtml(result);
            });

            $scope.openAppStore = function () {
                if (SystemService.isWebkit) {
                    var message = {
                        type: 'openAppStore'
                    };
                    window.webkit.messageHandlers.bridge.postMessage(message);
                }
            };

            $scope.minimize = function () {
                SystemTerminalService.minimize();
            };
        }
    ]);

};
