'use strict';


module.exports = function (freshideasSetup) {

    // Allows preview image uploaded to a file uploader. This is copied from
    // the example in `angular-file-upload` library (https://github.com/nervgh/angular-file-upload/blob/master/examples/image-preview/directives.js),
    // with some modification so that the image rendering uses img + data URI instead of canvas element.

    freshideasSetup.directive('scrollToTopWhen', ['$timeout', function ($timeout) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                scope.$on(attrs.scrollToTopWhen, function () {
                    $timeout(function () {
                        element[0].scrollTop = 0;
                    }, 0);
                });
            }
        };
    }]);
};
