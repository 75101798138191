'use strict';

module.exports = function (freshideasLocation) {

    freshideasLocation
    .directive('locationAssignCategory', [
        function () {
            return {
                restrict: 'E',
                templateUrl: 'locations/location-side-content/location-assign-category/location-assign-category.tpl.html',
                controller: 'LocationAssignCategoryCtrl',
                scope: {
                    item: '='
                },
                link: function (scope, element, attr) {
                    console.log('location assign category');
                }
            };
        }
        ])
    .controller('LocationAssignCategoryCtrl', [
        '$scope',
        '$modal',
        '$q',
        '$translate',
        '$location',
        'LocationFactory',
        'Locations',
        'Company',
        'notificationTranslationHelper',
        'ErrorUtil',
        function ($scope, $modal, $q, $translate, $location, LocationFactory, Locations, Company, notificationTranslationHelper, ErrorUtil) {

            $scope.locationFactory = LocationFactory;

            var init = function () {
                console.log('Started Menu List');
            };

            $scope.editListItem = function (ev, item) {
                ev.stopPropagation();

                if (item.type === 'category') {
                    $scope.locationFactory.showEditCategory(item);

                } else if (item.type === 'modifier') {
                    $scope.locationFactory.showEditModifier(item);

                } else if (item.type === 'item') {
                    $scope.locationFactory.showEditItem(item);
                }
            };

            $scope.selectSideListItem = function (ev, item) {
                ev.stopPropagation();

                if ($scope.locationFactory.isEligible(item.locationServicePeriodMenuId)) {
                    $scope.locationFactory.selectSideListItem(item);
                }
            };


            init();
        }
        ]);
};
