module.exports = function (freshideasProducts) {
    freshideasProducts.controller('PatronIdCheckModalCtrl', [
        '$scope',
        '$modal',
        '$modalInstance',
        'idChecked',
        'selectedPatron',
        'PosAlertService',
        function ($scope,
            $modal,
            $modalInstance,
            idChecked,
            selectedPatron,
            PosAlertService) {

                // can be expanded in the future if more items need to be added.
                $scope.idCheckParams = {
                    idChecked: idChecked
                };

                $scope.selectedPatron = selectedPatron;

                $scope.checkId = () => {
                    var requestedPermission = 'pos:id_authentication';
                    var params = {
                        callback: function (pinObj) {
                            $scope.idCheckParams.idChecked = true;
                            $modalInstance.close($scope.idCheckParams);
                        },
                        errorCallback: function (error) {
                            if (error) {
                                PosAlertService.showAlertByName('giftcard-auth-invalid-pin');
                            }
                        },
                        requestedPermission: requestedPermission,
                        message: 'smb.pos.giftcard.auth.description'
                    };

                    $scope.$emit('PincodeAuthentication:Required', params);
                };

                $scope.dismiss = function () {
                    $modalInstance.close($scope.idCheckParams);
                };
        }]);
};
