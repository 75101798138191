module.exports = function (freshideasProducts) {
    freshideasProducts.controller('GenericScanningModalCtrl', [
        '$scope',
        '$modal',
        '$modalInstance',
        '$translate',
        'message',
        function ($scope,
            $modal,
            $modalInstance,
            $translate,
            message) {

            $scope.messageToShow = message;
            $scope.isDoneScanning = false;
            $scope.isError = false;

        }]);
};

