'use strict';


module.exports = function (posModule) {
    posModule.controller('CardTerminalSummaryController', [
        '$scope',
        '$modalInstance',
        'receiptFields',
        function ($scope, $modalInstance, receiptFields) {
            $scope.close = function () {
                $modalInstance.close();
            };
            $scope.receiptFields = receiptFields;
        }
    ]);
};
