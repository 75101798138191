'use strict';


module.exports = function (freshIdeasDirectiveCommon) {

    freshIdeasDirectiveCommon.directive('currencyInput', [
        '$filter',
        function ($filter) {
            return {
                require: 'ngModel',
                restrict: 'A',
                link: function (scope, elem, attrs, ngModelCtrl) {
                    var factor = attrs.cent ? 100 : 1;
                    ngModelCtrl.$formatters.push(function (value) {
                        return $filter('currency')(value / factor, '$', 2);
                    });
                    ngModelCtrl.$parsers.push(function (displayValue) {
                        return (displayValue === undefined)? undefined : Number(displayValue.replace(/[^0-9-.]/g, '')) * factor;
                    });
                }
            };
        }
    ]);

    freshIdeasDirectiveCommon.directive('currencyInputNoSign', [
        '$filter',
        function ($filter) {
            return {
                require: 'ngModel',
                restrict: 'A',
                link: function (scope, elem, attrs, ngModelCtrl) {
                    var factor = attrs.cent ? 100 : 1;
                    ngModelCtrl.$formatters.push(function (value) {
                        return $filter('currency')(value / factor, '', 2);
                    });
                    ngModelCtrl.$parsers.push(function (displayValue) {
                        return (displayValue === undefined)? undefined : Number(displayValue.replace(/[^0-9-.]/g, '')) * factor;
                    });
                }
            };
        }
    ]);

};
