'use strict';

const angular = require('angular');
const freshideasResourcesLocations = require('../common/resources/location.js').default;
const freshideasProducts2 = angular.module('freshideas.products2', [freshideasResourcesLocations.name, 'ui.router', 'pascalprecht.translate', 'angular-barcode']);

freshideasProducts2
    .config([
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider.state('freshideas.products2', {
                rootUrl: 'products',
                url: '/products2',
                templateUrl: 'products/templates/products.tpl.html',
                controller: 'Products2Ctrl'
            });
        }
    ]);


require('../external/pos.data-service.js');
require('./products.service.js')(freshideasProducts2);
require('./products.ctrl.js')(freshideasProducts2);
require('./product-menu.ctrl.js')(freshideasProducts2);

require('./product.item.create.ctrl.js')(freshideasProducts2);
require('./product.category.select.ctrl.js')(freshideasProducts2);
require('./product.category.create.ctrl.js')(freshideasProducts2);
require('./product.modifier.create.ctrl.js')(freshideasProducts2);
require('./product.modifier.reuse.ctrl.js')(freshideasProducts2);
require('./product.save.prompt.ctrl.js')(freshideasProducts2);
require('./product.rename.prompt.ctrl.js')(freshideasProducts2);

require('./product.general.list.ctrl.js')(freshideasProducts2);
require('./product.shopify.items.ctrl.js')(freshideasProducts2);
require('./product.print.label.ctrl.js')(freshideasProducts2);
require('../common/controllers/dateTimeModifier.ctrl.js')(freshideasProducts2);


export default freshideasProducts2;


