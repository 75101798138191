'use strict';

const angular = require('angular');

/**
 * This is the default template used to display <input> <select> and <textarea>
 */
angular.module('input.html', []).run(['$templateCache', function ($templateCache) {
    $templateCache.put('input.html',
        '<div>' +
        '<label class="control-label"></label>' +
        '<div>' +
        '<span class="help-block red" ng-repeat="error in $fieldErrors"><span ng-if="$validationMessages">{{$validationMessages[error](this)}}</span><span ng-if="!$validationMessages" translate="{{$fieldKey + \'.error.\' + error}}" translate-values="{{$fieldErrorData}}"></span></span>' +
        '</div>' +
        '</div>');
}]);

export default angular.module('freshideas.directives.fieldDirective', ['input.html'])

/**
 * @ngdoc directive
 * @name field
 * @restrict E
 *
 * @description
 * Creates a label and input pair.
 *
 * @param {string} [fieldType=text] - Types supported are 'text', 'select', and 'textarea'.
 * @param {string=} fieldErrorData - Data object to be bound for validation errors.
 * @param {string=} tooltipEnabled
 * @param {string=} tooltipPlacement
 * @param {string=} fieldPrefix
 * @param {string=} fieldSuffix
 * @param {boolean=} disableLabel
 * @param {string=} fieldKey
 * @param {string=} fieldText
 * @param {string=} fieldClass
 * @param {string=} labelClass
 * @param {string=} divClass
 */
    .directive('field', ['$interpolate', '$compile', '$http', '$templateCache', function ($interpolate, $compile, $http, $templateCache) {

        // Load a template, possibly from the $templateCache, and instantiate a DOM element from it
        function loadTemplate(template) {
            return $http.get(template, {cache: $templateCache}).then(function (response) {
                return angular.element(response.data);
            }, function (response) {
                throw new Error('Template not found: ' + template);
            });
        }

        function findLabelElement(templateElement) {
            return templateElement.find('label');
        }

        function findDivElement(templateElement) {
            return templateElement.find('div');
        }

        // Search through the originalDirective's element for elements that contain information about how to map
        // validation keys to messages
        function getValidationMessageMap(originalElement) {
            // Find all the <validator> child elements and extract their (key, message) info
            var validationMessages;
            angular.forEach(originalElement.find('validator'), function (element) {
                if (!validationMessages) {
                    validationMessages = {};
                }
                // Wrap the element in jqLite/jQuery
                element = angular.element(element);
                // Store the message info to be provided to the scope later
                // The content of the validation element may include interpolation {{}}
                // so we will actually store a function created by the $interpolate service
                // To get the interpolated message we will call this function with the scope. e.g.
                //   var messageString = getMessage(scope);
                validationMessages[element.attr('key')] = $interpolate(element.text());
            });
            return validationMessages;
        }

        function getStaticOptions(originalElement) {
            var staticOptions = [];
            angular.forEach(originalElement.find('option'), function (element) {
                staticOptions.push(element);
            });
            return staticOptions;
        }

        return {
            restrict: 'E',
            priority: 200,        // We need this directive to happen before ng-model and ng-list
            terminal: true,       // We are going to deal with this element
            compile: function (element, attrs) {
                if (attrs.ngRepeat || attrs.ngSwitch || attrs.ngIf) {
                    throw new Error('The ng-repeat, ng-switch and ng-if directives are not supported on the same element as the field directive.');
                }
                if (!attrs.ngModel) {
                    throw new Error('The ng-model directive must appear on the field element');
                }

                var validationMessages = getValidationMessageMap(element);

                var staticOptions = getStaticOptions(element);

                // Clear the directive's original element
                element.html('');

                return function postLink(scope, element, attrs) {

                    // Load up the template for this kind of field, default to the simple input if none given
                    loadTemplate(attrs.template || 'input.html').then(function (templateElement) {
                        // Set up the scope - the template will have its own scope, which is a child of the directive's scope
                        // Here you can attach fields to the scope, perhaps validation patterns and such for display
                        var childScope = scope.$new();

                        // Attach the i18n field key to the new scope
                        childScope.$fieldKey = attrs.fieldKey;

                        // Attach a copy of the message map to the scope if we are not using translations
                        if (validationMessages) {
                            childScope.$validationMessages = angular.copy(validationMessages);
                        } else {
                            // Watch translation data object for use in translation errors
                            if (attrs.fieldErrorData) {
                                scope.$watch(attrs.fieldErrorData, function (newErrorData) {
                                    childScope.$fieldErrorData = newErrorData;
                                }, true);
                            }
                        }

                        // Generate an id for the field from the ng-model expression and the current scope
                        // We replace dots with underscores to work with browsers and ngModel lookup on the FormController
                        // We couldn't do this in the compile function as we need to be able to calculate the unique id from the scope
                        childScope.$fieldId = attrs.ngModel.replace('.', '_').toLowerCase() + '_' + childScope.$id;

                        // Update the $fieldErrors array when the validity of the field changes
                        childScope.$watch('$field.$dirty && $field.$error', function (errorList) {
                            childScope.$fieldErrors = [];
                            angular.forEach(errorList, function (invalid, key) {
                                if (invalid) {
                                    childScope.$fieldErrors.push(key);
                                }
                            });
                        }, true);

                        // Append a new input / select / textarea to the div
                        // Then copy over all left over attributes to the final field element
                        // We can't use interpolation in the template for directives such as ng-model
                        var divElement = findDivElement(templateElement);
                        //var inputElement = findInputElement(templateElement); // May want to still use this in the case of a supplied template?
                        var fieldType = attrs.fieldType || 'input';
                        var inputType;
                        var field;
                        switch (fieldType) {
                            case 'input':
                                field = '<input>';
                                // Check if 'type' attribute is specified, and if not add type="text"
                                if (!attrs.type) {
                                    inputType = 'text';
                                }
                                break;
                            case 'select':
                                field = '<select></select>';
                                break;
                            case 'textarea':
                                field = '<textarea></textarea>';
                                break;
                        }

                        var inputElement = angular.element(field);
                        divElement.prepend(inputElement);

                        angular.forEach(attrs.$attr, function (original, normalized) {
                            var value = element.attr(original);
                            inputElement.attr(original, value);
                        });

                        // Add input type if not supplied by caller
                        if (inputType) {
                            inputElement.attr('type', inputType);
                        }

                        // If there were static options specified, copy them over here
                        if (staticOptions.length > 0) {
                            angular.forEach(staticOptions, function (option) {
                                inputElement.append(option);
                            });
                        }

                        // Wire up the new input (id and name) after the div and its label (for).
                        // We need to set the input element's name here before we compile the template.
                        // If we leave it to be interpolated at the next $digest the formController doesn't pick it up
                        inputElement.attr('name', attrs.fieldId || childScope.$fieldId);
                        inputElement.attr('id', childScope.$fieldId);

                        // Add class to input
                        if (attrs.fieldClass) {
                            inputElement.addClass(attrs.fieldClass);
                        }

                        // Add tooltip information to input
                        if (angular.isDefined(attrs.fieldKey) && angular.isDefined(attrs.tooltipEnabled)) {
                            inputElement.attr('tooltip', '{{\'' + attrs.fieldKey + '.toolTip\' | translate}}');
                            inputElement.attr('tooltip-trigger', 'focus');

                            // See if caller specified placement
                            if (attrs.tooltipPlacement) {
                                inputElement.attr('tooltip-placement', attrs.tooltipPlacement);
                            } else {
                                inputElement.attr('tooltip-placement', 'right');
                            }
                            inputElement.attr('tooltip-append-to-body', 'true');
                        }

                        // Add the prefix if supplied
                        if (attrs.fieldPrefix) {
                            var prefixElement = angular.element('<span class="freshideas-field-prefix" translate=\'' + attrs.fieldPrefix + '\'></span>');
                            inputElement.before(prefixElement);
                        }

                        // Add the suffix if supplied
                        if (attrs.fieldSuffix) {
                            var suffixElement = angular.element('<span class="freshideas-field-suffix" translate=\'' + attrs.fieldSuffix + '\'></span>');
                            inputElement.after(suffixElement);
                        }

                        // Wire up the label if they have provided a fieldKey or fieldText
                        var labelElement = findLabelElement(templateElement);
                        if (!angular.isDefined(attrs.disableLabel)) {
                            labelElement.attr('for', childScope.$fieldId);

                            // Add required to label class if input is required and it is empty or there are field errors
                            if (angular.isDefined(attrs.required)) {
                                labelElement.attr('ng-class', '{\'freshideas-req-label\': $fieldErrors.length > 0 || $field.$isEmpty($field.$viewValue)}');
                            }

                            // Add label class
                            if (attrs.labelClass) {
                                labelElement.addClass(attrs.labelClass);
                            }

                            if (angular.isDefined(attrs.fieldKey)) {

                                // Localize label text
                                labelElement.html('<span translate=\'' + attrs.fieldKey + '.label\'></span>');
                            } else if (angular.isDefined(attrs.labelText)) {
                                labelElement.html(attrs.labelText);
                            }
                        } else {
                            labelElement.remove();
                        }

                        // Wire up the div
                        if (attrs.divClass) {
                            divElement.addClass(attrs.divClass);
                        }

                        // Place our template as a child of the original element.
                        // This needs to be done before compilation to ensure that it picks up any containing form.
                        element.append(templateElement);

                        // We now compile and link our template here in the postLink function
                        // This allows the ng-model directive on our template's <input> element to access the ngFormController
                        $compile(templateElement)(childScope);

                        // Now that our template has been compiled and linked we can access the <input> element's ngModelController
                        childScope.$field = inputElement.controller('ngModel');
                    });
                };
            }
        };
    }]);
