'use strict';

module.exports = function (freshideasDirectiveCommon) {
    freshideasDirectiveCommon.directive('inModalNotification', [
        '$translate',
        function (
            $translate
        ) {
            return {
                restrict: 'E',
                template: `<section class="notification-panel">
                    <header>
                        <div class="header-info">
                            <div class="header-title" ng-class="{ 'danger-text': notificationType == 'danger' }">{{ notificationTitle }}</div>
                            <div class="header-subtitle">{{ notificationDescription }}</div>
                        </div>
                    </header>
                    <footer>
                        <div class="action-buttons">
                            <button class="action-cancel" ng-if="actionResolve" ng-click="doRejectCallback($event)" lucova-ripple>{{ actionRejectLabel }}</button>
                            <button class="action-continue" ng-if="actionReject" ng-click="doResolveCallback($event)" lucova-ripple>{{ actionResolveLabel }}</button>
                        </div>
                    </footer>
                </section>`,
                scope: {
                    notificationType: '@',
                    notificationTitle: '@',
                    notificationDescription: '@',
                    actionResolveLabel: '@',
                    actionResolve: '=?',
                    actionRejectLabel: '@',
                    actionReject: '=?'
                },
                link: function (localScope, element, attributes, controllerList) {
                    localScope.notificationType = localScope.notificationType || 'danger';
                    localScope.notificationTitle = localScope.notificationTitle || $translate.instant('general.error');
                    localScope.notificationDescription = localScope.notificationDescription || $translate.instant('general.error.default');
                    localScope.actionRejectLabel = localScope.actionRejectLabel || $translate.instant('general.error.cancel');
                    localScope.actionResolveLabel = localScope.actionResolveLabel || $translate.instant('general.error.okay');

                    localScope.doRejectCallback = function (event) {
                        event.stopPropagation();

                        if (localScope.actionReject) {
                            localScope.actionReject({action: 'cancel', event: event});
                        }
                    };

                    localScope.doResolveCallback = function (event) {
                        event.stopPropagation();

                        if (localScope.actionResolve) {
                            localScope.actionResolve({action: 'continue', event: event});
                        }
                    };
                }
            };
        }
    ]);
};
