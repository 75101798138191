'use strict';

const angular = require('angular');
const $ = require('jquery');
const moment = require('moment');
const uiBootstrap = require('angular-ui-bootstrap');

const freshideasDirectiveCommon = angular.module('freshideas.directives.common', [uiBootstrap, 'pascalprecht.translate'])
    //  Ng-min is similar to 'min' except it allows for a dynamic 'ng-min' interpolation ('ng-min' can change in realtime
    //  and the validator will kick in). 'min' as of yet does not allow this.
    //  See: https://github.com/angular/angular.js/issues/2404
    .directive('ngMin', ['Utils', function (Utils) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attr, ctrl) {
                scope.$watch(attr.ngMin, function () {
                    ctrl.$setViewValue(ctrl.$viewValue);
                });
                var minValidator = function (value) {
                    var min = scope.$eval(attr.ngMin) || 0;

                    if (!Utils.isEmpty(value) && value < min) {
                        ctrl.$setValidity('min', false);
                        return undefined;
                    } else {
                        ctrl.$setValidity('min', true);
                        return value;
                    }
                };

                ctrl.$parsers.push(minValidator);
                ctrl.$formatters.push(minValidator);
            }
        };
    }])

    //  Ng-max is similar to 'max' except it allows for a dynamic 'ng-max' interpolation ('ng-max' can change in realtime
    //  and the validator will kick in). 'max' as of yet does not allow this.
    //  See: https://github.com/angular/angular.js/issues/2404
    .directive('ngMax', ['Utils', function (Utils) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attr, ctrl) {
                scope.$watch(attr.ngMax, function () {
                    ctrl.$setViewValue(ctrl.$viewValue);
                });
                var maxValidator = function (value) {
                    var max = scope.$eval(attr.ngMax) || Infinity;
                    if (!Utils.isEmpty(value) && value > max) {
                        ctrl.$setValidity('max', false);
                        return undefined;
                    } else {
                        ctrl.$setValidity('max', true);
                        return value;
                    }
                };

                ctrl.$parsers.push(maxValidator);
                ctrl.$formatters.push(maxValidator);
            }
        };
    }])

    // Allows for autofill on forms / enabling buttons / validation
    // https://github.com/angular/angular.js/issues/1460
    .directive('autoFillSync', ['$timeout', function ($timeout) {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ngModel) {
                var origVal = elem.val();
                var timer = $timeout(function () {
                    var newVal = elem.val();
                    if (ngModel.$pristine && origVal !== newVal) {
                        ngModel.$setViewValue(newVal);
                    }
                }, 500);

                scope.$on('$destroy', function () {
                    $timeout.cancel(timer);
                });
            }
        };
    }])

    // Allows for autocomplete on forms / enabling buttons / validation.  This is when you
    // are typing in a form field and the browser provides a pulldown.  Which is different from
    // autofill which is when you access a page and the saved data is already populated.  This issue
    // is tracked under the same github
    // https://github.com/angular/angular.js/issues/1460
    // https://github.com/angular/angular.js/issues/1460#issuecomment-28662156
    .directive('autoCompleteSync', ['$interval', function ($interval) {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ngModel) {
                var timer = $interval(function () {
                    var prevVal = '';
                    if (!angular.isUndefined(attrs.xAutoFillPrevVal)) {
                        prevVal = attrs.xAutoFillPrevVal;
                    }
                    if (elem.val() !== prevVal) {
                        if (!angular.isUndefined(ngModel)) {
                            if (!(elem.val() === '' && ngModel.$pristine)) {
                                attrs.xAutoFillPrevVal = elem.val();
                                ngModel.$setViewValue(elem.val());
                            }
                        } else {
                            elem.trigger('input');
                            elem.trigger('change');
                            elem.trigger('keyup');
                            attrs.xAutoFillPrevVal = elem.val();
                        }
                    }
                }, 300);

                scope.$on('$destroy', function () {
                    $interval.cancel(timer);
                });
            }
        };
    }])

    // Allows auto-focus on a forms first field
    // Usage: <input type="text" autofocus>
    .directive('autofocus', ['$timeout', function ($timeout) {
        return {
            restrict: 'A',
            link: function ($scope, $element) {
                $timeout(function () {
                    $element[0].focus();
                });
            }
        };
    }])

    // Confirm Delete Button
    .directive('confirmDeleteButton', [function () {
        return {
            restrict: 'E',
            scope: {
                message: '@',
                buttonMode: '=',
                buttonSize: '=',
                onConfirm: '&'
            },
            controller: 'ConfirmDeleteCtrl',
            templateUrl: 'common/freshideas/confirmDeleteButton.tpl.html',
            replace: true
        };
    }])

    .controller('ConfirmDeleteCtrl', ['$scope', '$attrs', '$modal', function ($scope, $attrs, $modal) {
        $scope.message = $scope.message || 'general.areYouSure';
        $scope.requestConfirm = function () {
            var modalInstance = $modal.open({
                templateUrl: 'common/freshideas/modalConfirmDelete.tpl.html',
                controller: 'ConfirmDeleteModalCtrl',
                resolve: {
                    message: function () {
                        return $scope.message;
                    }
                }
            });

            modalInstance.result.then(function () {
                // Call the confirm callback
                $scope.onConfirm();
            });
        };
    }])

    .service('ConfirmModalService', ['$modal', function ($modal) {
        this.openConfirmModal = function (modalTitle, modalConfirmMessage, clickAction, paramMap, scope, allowCancel) {
            var modalInstance = $modal.open({
                templateUrl: 'common/freshideas/modalConfirmDelete.tpl.html',
                controller: 'ConfirmDeleteModalCtrl',
                windowClass: 'modal-confirmation',
                resolve: {
                    message: function () {
                        return modalConfirmMessage;
                    },
                    title: function () {
                        return modalTitle;
                    },
                    allowCancel: function () {
                        return allowCancel;
                    },
                    paramMap: function () {
                        return paramMap;
                    }
                }
            });

            var promise = new Promise(function (resolve, reject) {
                modalInstance.result.then(function () {
                    // Call the confirm callback
                    if (clickAction) {
                        clickAction();
                    }
                    resolve();
                }, function () {
                    reject();
                });
            });

            return promise;
        };

        this.openDarkConfirmModal = function (modalTitle, modalConfirmMessage, clickAction, paramMap, scope, allowCancel) {
            var modalInstance = $modal.open({
                templateUrl: 'common/freshideas/modalConfirmDelete.tpl.html',
                controller: 'ConfirmDeleteModalCtrl',
                windowClass: 'modal-confirmation modal-confirmation--dark',
                resolve: {
                    message: function () {
                        return modalConfirmMessage;
                    },
                    title: function () {
                        return modalTitle;
                    },
                    allowCancel: function () {
                        return allowCancel;
                    },
                    paramMap: function () {
                        return paramMap;
                    }
                }
            });

            var promise = new Promise(function (resolve, reject) {
                modalInstance.result.then(function () {
                    // Call the confirm callback
                    if (clickAction) {
                        clickAction();
                    }
                    resolve();
                }, function () {
                    reject();
                });
            });

            return promise;
        };
    }])

    // Confirm Modal Attribute: Attach to any element to initiate the popup
    .directive('confirmModal', ['$modal',
        function ($modal) {
            return {
                link: function (scope, element, attr) {
                    var params = attr.params; // these params correspond to the placeholder key in the i18n label
                    var prefix = attr.entityPrefix; // this is the prefix of the object that contains the actual values that we interpolate with the i18n label, e.g. row.entity. for ng-grids.
                    var msg = attr.confirmMessage || 'general.areYouSure';
                    var clickAction = attr.onConfirm;
                    var title = attr.confirmTitle || 'general.delete';
                    var allowCancel = attr.confirmCancellable || true;

                    scope.$watch(attr.confirmDisabled, function (value) {
                        element.unbind('click');
                        if (!value) {
                            element.bind('click', function () {
                                var paramMap = {};
                                if (angular.isDefined(params)) {
                                    _.each(params.split(','), function (param) {
                                        paramMap[param] = scope.$eval(prefix + param);
                                    });

                                }
                                var modalInstance = $modal.open({
                                    templateUrl: 'common/freshideas/modalConfirmDelete.tpl.html',
                                    controller: 'ConfirmDeleteModalCtrl',
                                    resolve: {
                                        message: function () {
                                            return msg;
                                        },
                                        paramMap: function () {
                                            return paramMap;
                                        },
                                        title: function () {
                                            return title;
                                        },
                                        allowCancel: function () {
                                            return allowCancel;
                                        }
                                    }
                                });

                                modalInstance.result.then(function () {
                                    // Call the confirm callback
                                    scope.$eval(clickAction);
                                });
                            });
                        }
                    });
                }
            };
        }])

    // Confirm Delete Modalddress
    .controller('ConfirmDeleteModalCtrl', ['$scope', '$modalInstance', 'message', 'paramMap', 'title', 'allowCancel', function ($scope, $modalInstance, message, paramMap, title, allowCancel) {
        $scope.message = message;
        $scope.paramMap = paramMap;
        $scope.title = title;
        $scope.allowCancel = angular.isDefined(allowCancel) ? allowCancel : true;
        $scope.confirm = function () {
            $modalInstance.close(true);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('false');
        };
    }])

    // File Upload
    .directive('fileUpload', function () {
        return {
            restrict: 'A',
            scope: true,
            link: function (scope, el) {
                el.bind('change', function (event) {
                    var files = event.target.files;
                    // iterate files since 'multiple' may be specified on the element
                    for (var i = 0; i < files.length; i++) {
                        // emit event upward
                        scope.$emit('fileSelected', {file: files[i]});
                    }
                });
            }
        };
    })

    // Scroll fadeout horizontal
    .directive('horizontalScrollFade', function () {
        return {
            restrict: 'A',
            scope: true,
            link: function (scope, el) {
                el.bind('scroll', function (event) {
                    // Some devices don't completely reach the end of a scroll.
                    // A threshold in pixels is used to compensate for that.
                    var triggerThreshold = 32;
                    var scrollableItems = event.target;

                    const findAllDescendents = (element) => {
                        // [].slice.call() -  It is a technique to convert array-like objects to real arrays. Since element.children
                        // is just an HTMLCollection, we have to use [].slice.call to conver it to an actual Array
                        var children = [].slice.call(element.children);
                        var found = 0;
                        while (children.length > found) {
                            children = children.concat([].slice.call(children[found].children));
                            found++;
                        }
                        return children;
                    };

                    const allDescendents = findAllDescendents(scrollableItems);

                    var fadeRight = allDescendents.find((element) => {
                        return element.className === 'fade-right';
                    });
                    var fadeLeft = allDescendents.find((element) => {
                        return element.className === 'fade-left';
                    });

                    if (!(fadeRight && fadeLeft)) {
                        return;
                    }

                    if ((scrollableItems.offsetWidth + scrollableItems.scrollLeft) >= scrollableItems.scrollWidth - triggerThreshold) {
                        // End of scroll list
                        fadeRight.style.opacity = 0;
                        fadeRight.style.visibility = 'hidden';
                    } else {
                        fadeRight.style.opacity = 1;
                        fadeRight.style.visibility = 'visible';
                    }
                    if (scrollableItems.scrollLeft <= 0 + triggerThreshold) {
                        // Start of scroll list
                        fadeLeft.style.opacity = 0;
                        fadeLeft.style.visibility = 'hidden';
                    } else {
                        fadeLeft.style.opacity = 1;
                        fadeLeft.style.visibility = 'visible';
                    }
                });
            }
        };
    })

    // Scroll fadeout vertical
    .directive('verticalScrollFade', function () {
        return {
            restrict: 'A',
            scope: true,
            link: function (scope, el) {
                el.bind('scroll', function (event) {
                    // Some devices don't completely reach the end of a scroll.
                    // A threshold in pixels is used to compensate for that.
                    var triggerThreshold = 32;
                    var scrollableItems = event.target;

                    const findAllDescendents = (element) => {
                        // [].slice.call() -  It is a technique to convert array-like objects to real arrays. Since element.children
                        // is just an HTMLCollection, we have to use [].slice.call to conver it to an actual Array
                        var children = [].slice.call(element.children);
                        var found = 0;
                        while (children.length > found) {
                            children = children.concat([].slice.call(children[found].children));
                            found++;
                        }
                        return children;
                    };

                    const allDescendents = findAllDescendents(scrollableItems);

                    var fadeTop = allDescendents.find((element) => {
                        return element.className === 'fade-top';
                    });
                    var fadeBottom = allDescendents.find((element) => {
                        return element.className === 'fade-bottom';
                    });

                    if (!(fadeBottom && fadeTop)) {
                        return;
                    }

                    if ((scrollableItems.offsetHeight + scrollableItems.scrollTop) >= scrollableItems.scrollHeight - triggerThreshold) {
                        // End of scroll list
                        fadeBottom.style.opacity = 0;
                        fadeBottom.style.visibility = 'hidden';
                    } else {
                        fadeBottom.style.opacity = 1;
                        fadeBottom.style.visibility = 'visible';
                    }
                    if (scrollableItems.scrollTop <= 0 + triggerThreshold) {
                        // Start of scroll list
                        fadeTop.style.opacity = 0;
                        fadeTop.style.visibility = 'hidden';
                    } else {
                        fadeTop.style.opacity = 1;
                        fadeTop.style.visibility = 'visible';
                    }
                });
            }
        };
    })

    .directive('yesNoNone', function () {
        return {
            restrict: 'E',
            replace: true,
            template: '<select ng-model="model" class="form-control input-sm"><option value="" translate="general.all"></option><option value="1" translate="general.yes"></option><option value="0" translate="general.no"></option></select>',
            scope: {
                model: '='
            }
        };
    })

    .directive('monthYearPicker', function () {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'common/freshideas/monthYearPicker.tpl.html',
            scope: {
                selectedMonth: '=',
                selectedYear: '=',
                start: '@',
                end: '@'
            },
            link: function (scope) {
                var now = moment();
                if (!scope.end) {
                    scope.end = now.year();
                }

                if (!scope.start) {
                    scope.start = now.subtract('years', 10).year();
                }

                scope.years = [];
                for (var i = scope.start; i < scope.end + 1; i++) {
                    scope.years.push(i);
                }

                scope.nextMonth = function () {
                    if (scope.selectedMonth === 11) {
                        if (scope.nextYear()) {
                            scope.selectedMonth = 0;
                        } else {
                            return;
                        }
                    } else {
                        scope.selectedMonth++;
                    }
                };

                scope.prevMonth = function () {
                    if (scope.selectedMonth === 0) {
                        if (scope.prevYear()) {
                            scope.selectedMonth = 11;
                        } else {
                            return;
                        }
                    } else {
                        scope.selectedMonth--;
                    }
                };

                scope.nextYear = function () {
                    var nextYear = scope.selectedYear + 1;
                    if (scope.years.indexOf(nextYear) === -1) {
                        return false;
                    } else {
                        scope.selectedYear++;
                        return true;
                    }
                };

                scope.prevYear = function () {
                    var prevYear = scope.selectedYear - 1;
                    if (scope.years.indexOf(prevYear) === -1) {
                        return false;
                    } else {
                        scope.selectedYear--;
                        return true;
                    }
                };
            }
        };
    })

    .service('DownloadFileService', ['$rootScope', function ($rootScope) {
        this.downloadFileUrl = '';
        this.downloadFile = function (downloadFileUrl) {
            this.downloadFileUrl = downloadFileUrl;
        };
        $rootScope.$on('downloadFile.request', function (downloadFileUrl) {
            this.downloadFileUrl = downloadFileUrl;
        });
    }])

    .directive('downloadFile', ['DownloadFileService', '$timeout', function (DownloadFileService, $timeout) {
        return {
            restrict: 'E',
            template: '<div></div>',
            replace: true,
            link: function (scope, element) {
                scope.$watch(function () {
                    return DownloadFileService.downloadFileUrl;
                }, function () {
                    if (angular.isDefined(DownloadFileService.downloadFileUrl) && (DownloadFileService.downloadFileUrl.length > 5)) {
                        element.append('<a class="downloadReportTemporaryLink" href="' + DownloadFileService.downloadFileUrl + '" target="_blank" download></a>');
                        $timeout(function () {
                            $('.downloadReportTemporaryLink').click(function () {
                                window.location = $(this).attr('href');
                            });
                            $('.downloadReportTemporaryLink').click();
                            $('.downloadReportTemporaryLink').remove();
                        }, 0, false);
                    }
                });
            }
        };
    }])

    .directive('monthSelect', function () {
        return {
            restrict: 'E',
            replace: true,
            template: '<select class="input-sm" ng-model="model"><option value="0">Jan</option><option value="1">Feb</option><option value="2">Mar</option><option value="3">Apr</option><option value="4">May</option><option value="5">Jun</option><option value="6">Jul</option><option value="7">Aug</option><option value="8">Sep</option><option value="9">Oct</option><option value="10">Nov</option><option value="11">Dec</option></select>',
            scope: {
                model: '='
            }
        };
    })

    // Depends on moment.js
    .directive('yearSelect', function () {
        return {
            restrict: 'E',
            replace: true,
            template: '<select class="input-sm" ng-model="model" ng-options="y as y for y in years"></select>',
            scope: {
                model: '=',
                start: '@',
                end: '@'
            },
            link: function (scope) {
                var now = moment();
                if (!scope.end) {
                    scope.end = now.year();
                }

                if (!scope.start) {
                    scope.start = now.subtract('years', 10).year();
                }

                scope.years = [];
                for (var i = scope.start; i < scope.end + 1; i++) {
                    scope.years.push(i);
                }
            }
        };
    })

    .directive('freshideasTimeSelector', function () {
        return {
            restrict: 'E',
            scope: {
                ngModel: '=',
                ngDisabled: '=',
                ngClass: '=',
                name: '@',
                showOnlyHours: '@',
                displaySuffix: '@',
                timeInMillis: '@'
            },
            templateUrl: 'common/freshideas/timePulldownSelector.tpl.html',
            link: function (scope, element, attrs) {
                if (angular.isDefined(attrs.showOnlyHours)) {
                    scope.showOnlyHours = true;
                } else {
                    scope.showOnlyHours = false;
                }
                if (angular.isDefined(attrs.timeInMillis)) {
                    scope.timeInMillis = true;
                } else {
                    scope.timeInMillis = false;
                }

                scope.optionValues = [
                    {value: 0},
                    {value: 0.5},
                    {value: 1},
                    {value: 1.5},
                    {value: 2},
                    {value: 2.5},
                    {value: 3},
                    {value: 3.5},
                    {value: 4},
                    {value: 4.5},
                    {value: 5},
                    {value: 5.5},
                    {value: 6},
                    {value: 6.5},
                    {value: 7},
                    {value: 7.5},
                    {value: 8},
                    {value: 8.5},
                    {value: 9},
                    {value: 9.5},
                    {value: 10},
                    {value: 10.5},
                    {value: 11},
                    {value: 11.5},
                    {value: 12},
                    {value: 12.5},
                    {value: 13},
                    {value: 13.5},
                    {value: 14},
                    {value: 14.5},
                    {value: 15},
                    {value: 15.5},
                    {value: 16},
                    {value: 16.5},
                    {value: 17},
                    {value: 17.5},
                    {value: 18},
                    {value: 18.5},
                    {value: 19},
                    {value: 19.5},
                    {value: 20},
                    {value: 20.5},
                    {value: 21},
                    {value: 21.5},
                    {value: 22},
                    {value: 22.5},
                    {value: 23},
                    {value: 23.5}
                ];

                for (var i = scope.optionValues.length - 1; i >= 0; i--) {
                    if (scope.showOnlyHours) {
                        if (scope.optionValues[i].value !== (scope.optionValues[i].value | 0)) {
                            scope.optionValues.splice(i, 1);
                            continue;
                        }
                    }

                    scope.optionValues[i].label = moment().hour(Math.floor(scope.optionValues[i].value));
                    scope.optionValues[i].label = scope.optionValues[i].label.minute((scope.optionValues[i].value % 1) * 60);
                    scope.optionValues[i].label = scope.optionValues[i].label.format('LT');

                    if (scope.timeInMillis) {
                        scope.optionValues[i].value = scope.optionValues[i].value * 3600000;
                    }

                    if (angular.isDefined(scope.displaySuffix)) {
                        scope.optionValues[i].label = scope.optionValues[i].label + scope.displaySuffix;
                    }
                }
            }
        };
    })

    // Inits focus on a newly added element
    .directive('initFocus', ['$timeout', function ($timeout) {
        var timer;
        return function (scope, elm, attr) {
            if (timer) {
                clearTimeout(timer);
            }

            var timeout = attr.focusTimeout || 0;
            timer = $timeout(function () {
                $(elm).focus();
            }, timeout);

            scope.$on('$destroy', function () {
                $timeout.cancel(timer);
            });
        };
    }])

    /**
     * Unifies our IOS barcode listener and an onkey listener
     * Usage: barcode-qr-detector="callback"
     * callback is defined in your controller and will be called
     * with one value the data recieved
     */
    .directive('barcodeQrDetector', () => {
        var listenersCallback = {};
        var listeners = [];
        var ignore = ['TEXTAREA', 'INPUT'];
        var keys = '';
        var regexp = RegExp(/^[A-Za-z0-9=+/?;:\-_%#]$/);

        // Commented By Akash Mehta on January 15 2021
        // Using a timeout to replace the enter key (new line char) to track whether we have
        // actually reached the end of an input or not. Sometimes, the input barcode may have a
        // new line character within it causing our code to split the input into two parts.
        var timeoutReference;
        function clearNewlineTimeout () {
           if (timeoutReference) {
               clearTimeout(timeoutReference);
               timeoutReference = null;
           }
       }

        function onBarcodeEvent (event) {
            notifyFirst(event.detail.data);
            keys = '';
        }

        function documentListener (e) {
            // Submit if "enter" (13) was received
            if (e.keyCode === 13) {
                clearNewlineTimeout();
                timeoutReference = setTimeout(function () {
                    notifyFirst(keys);
                    keys = '';
                }, 100);
            } else if (regexp.test(e.key)) {
                clearNewlineTimeout();
                keys += e.key;
            } else { // Do nothing if non-input characters were received
                return;
            }
        }

        function notifyFirst (data) {
            // ignore only if activeElement is in blackList and doesn't contain QR code data
            const qrCodeRegex = (/^l:[1,2]:.*/);
            if (ignore.indexOf(document.activeElement.nodeName) > -1 && !qrCodeRegex.test(data)) {
                return;
            }

            // sanitize input QR data to format -> l:[1,2]:*
            // below will remove any leading corrupt data if present
            let sanitizedData = data.match(/l:[1,2]:.*/g);
            if (sanitizedData && sanitizedData.length) {
                data = sanitizedData[0];
            }

            if (listeners.length) {
                let listener = getFirstListener();
                listener(data);
            }
        }

        function register () {
            window.addEventListener('barcodeEvent', onBarcodeEvent);

            // In Nown IOS Wrapper version 1.2.0 we have a barcode/qr code scanner
            window.addEventListener('BarcodeScannerOnData', onBarcodeEvent);

            document.onkeydown = documentListener;

            // This is used by the Tizen os (Samsung kiosks) container built by 900 solutions
            window.parent.postMessage({msgType: 'attachBarcodeScanner'}, '*');
        }

        function unregister () {
            document.onkeydown = null;
            window.removeEventListener('barcodeEvent', onBarcodeEvent);
            window.removeEventListener('BarcodeScannerOnData', onBarcodeEvent);
        }

        function addListener (id, callback) {
            listeners.unshift(id);
            listenersCallback[id] = callback;
        }

        function deleteListener (id) {
            listeners.splice(listeners.indexOf(id), 1);
            delete listenersCallback[id];
        }

        function getFirstListener () {
            return listenersCallback[listeners[0]]();
        }

        return {
            restrict: 'A',
            scope: {
                dataCallback: '&barcodeQrDetector'
            },
            link: (scope, element, attrs) => {
                if (listeners.length === 0) {
                    register();
                }
                addListener(scope.$id, scope.dataCallback);
                keys = ''; // Make sure we clear any data not processed from last scan

                scope.$on('$destroy', function () {
                    deleteListener(scope.$id);

                    if (listeners.length === 0) {
                        unregister();
                    }
                });
            }
        };
    })
    /*
     * This directive will display minVersionIOS'+attr.minVersion+'.png image to the
     * right of the element.
     * TODO we may want to extend this directive to support multiple arguments in the future (e.g. minVersionAndroid)
     */
    .directive('minOs', function () {
        return {
            link: function (scope, elem, attr) {
                if (attr.minVersion !== undefined) {
                    var imgSrc = 'images/minVersionIOS' + attr.minVersion + '.png';
                    var imgElement = '<img class="osVersionMarker" src="' + imgSrc + '"/>';
                    elem.after(imgElement);
                }
            }
        };
    })

    .directive('emailList', ['$compile', function ($compile) {
        return {
            terminal: true,
            priority: 1000,
            compile: function (element, attr) {

                element.removeAttr('email-list');
                element.attr('ng-pattern', '/^([\\w+-.%]+@[\\w-.]+\\.[A-Za-z]{2,4};?)+$/');

                return {
                    pre: function preLink (scope, iElement, iAttrs, controller) {
                    },
                    post: function postLink (scope, iElement, iAttrs, controller) {
                        $compile(iElement)(scope);
                    }
                };
            }
        };
    }])

/**
 *  Provides the + and - button and keeps track of the state of the collapsible sections on a page to allow for the proper state of the icon.
 */
    .directive('expandCollapse', function () {
        return {
            restrict: 'E',
            scope: {
                expandCollapseWidgets: '=',
                collapsedStatus: '='
            },
            template: '<span><a class="freshideas-page-header-action" ng-click="toggle()" ng-if="collapsedStatus" tooltip="{{\'general.expandAll\' | translate}}"><i class="fa fa-plus bigger-130"></i> </a>' +
                '<a class="freshideas-page-header-action" ng-click="toggle()" ng-if="!collapsedStatus" tooltip="{{\'general.collapseAll\' | translate}}"><i class=" fa fa-minus bigger-130"></i> </a></span>',
            replace: true,
            controller: ['$scope', function ($scope) {
                $scope.$watch('expandCollapseWidgets', function (widget) {
                    var allTrue = _.every($scope.expandCollapseWidgets, function (widget) {
                        return widget.collapse === true;
                    });

                    var allFalse = _.every($scope.expandCollapseWidgets, function (widget) {
                        return widget.collapse === false;
                    });

                    if (allTrue) {
                        $scope.collapsedStatus = true;
                    }

                    if (allFalse) {
                        $scope.collapsedStatus = false;
                    }
                }, true);

                $scope.toggle = function () {
                    if ($scope.collapsedStatus) {
                        _.each($scope.expandCollapseWidgets, function (widget) {
                            widget.collapse = false;
                        });

                        $scope.collapsedStatus = false;
                    } else {
                        _.each($scope.expandCollapseWidgets, function (widget) {
                            widget.collapse = true;
                        });

                        $scope.collapsedStatus = true;
                    }
                };
            }]
        };
    })

/**
 * Use this directive with ng-grid when inline edit is enabled to detect when a row has been modified.
 */
    .directive('rowChanged', function () {
        return {
            scope: {
                row: '='
            },
            link: function (scope, element, attrs) {
                scope.entity = scope.row.entity;
                scope.$watch('entity', function (oldVal, newVal) {
                    if (!_.isEqual(oldVal, newVal)) {
                        scope.row.entity.changed = true;
                    }
                }, true);
            }
        };
    })

    // https://github.com/nelsoncash/angular-ripple
    .directive('lucovaRipple', function () {
        return {
          restrict: 'A',
          link: function (scope, element, attrs) {
            var x, y, size, offsets,
              rippleFunc = function (e) {
                // Event shouldn't fire on disabled elements
                if (e.currentTarget.disabled) {
                    return;
                }

                // Event shouldn't fire when this attribute is false
                if (attrs.lucovaRipple === 'false') {
                    return;
                }

                // Event shouldn't fire when user is dragging
                if (window.dragging === true) {
                    return;
                }

                var ripple = this.querySelector('.lucova-ripple');
                var eventType = e.type;

                // Ripple
                if (ripple === null) {
                  // Create ripple
                  ripple = document.createElement('span');
                  ripple.className += ' lucova-ripple';

                  // Prepend ripple to element
                  this.insertBefore(ripple, this.firstChild);

                  // Set ripple size
                  if (!ripple.offsetHeight && !ripple.offsetWidth) {
                    size = Math.max(element[0].offsetWidth, element[0].offsetHeight);
                    ripple.style.width = size + 'px';
                    ripple.style.height = size + 'px';
                  }
                }

                // Remove animation effect
                ripple.className = ripple.className.replace(/ ?(animate)/g, '');

                // Get click coordinates by event type
                if (eventType === 'mouseup') {
                  x = e.pageX;
                  y = e.pageY;
                } else if (eventType === 'touchend') {
                  try {
                    var origEvent;

                    if (typeof e.changedTouches !== 'undefined') {
                      origEvent = e.changedTouches[0];
                    } else {
                      origEvent = e.originalEvent;
                    }

                    x = origEvent.pageX;
                    y = origEvent.pageY;
                  } catch (e) {
                    // fall back to center of el
                    x = ripple.offsetWidth / 2;
                    y = ripple.offsetHeight / 2;
                  }
                }

                // set new ripple position by click or touch position
                function getPos (element) {
                  var de = document.documentElement;
                  var box = element.getBoundingClientRect();
                  var top = box.top + window.pageYOffset - de.clientTop;
                  var left = box.left + window.pageXOffset - de.clientLeft;
                  return {top: top, left: left};
                }

                offsets = getPos(element[0]);
                ripple.style.left = (x - offsets.left - size / 2) + 'px';
                ripple.style.top = (y - offsets.top - size / 2) + 'px';

                // Add animation effect
                ripple.className += ' animate';
              };

            // Logic to determine when user is dragging
            var touchStartFunc = function () {
                window.dragging = false;
            };

            var dragFunc = function () {
                window.dragging = true;
            };

            var eventTypeTouchStart = ('ontouchstart' in document) ? 'touchstart' : 'mousedown';
            var eventTypeTouchEnd = ('ontouchend' in document) ? 'touchend' : 'mouseup';
            var eventTypeDrag = ('ontouchmove' in document) ? 'touchmove' : 'mousemove';

            // Unbind body events first to make sure each one set only once even if there are many of this directive
            angular.element('body').unbind(eventTypeTouchStart);
            angular.element('body').unbind(eventTypeDrag);

            // Ripple fires when user ends touch on element
            // Ripple shouldn't fire when user is dragging
            element.on(eventTypeTouchEnd, rippleFunc);
            angular.element('body').on(eventTypeTouchStart, touchStartFunc);
            angular.element('body').on(eventTypeDrag, dragFunc);

            // Remove the event listener on scope destroy
            scope.$on('$destroy', function () {
              element.off(eventTypeTouchEnd, rippleFunc);
            });
          }
        };
    })

    .directive('scrollOverflowDetector', ['$timeout', function ($timeout) {
        return {
            restrict: 'A',
            scope: {
                receipt: '='
            },
            link: function (scope, element, attrs) {
                // $timeout 0: Makes it execute on the next processor cycle
                // In this case it makes the directive link occur after the DOM has
                // finished rendering
                $timeout(function () {
                    if (element[0].scrollHeight > element[0].parentNode.parentElement.clientHeight) {
                        scope.receipt.scrollable = true;
                    }
                }, 0);
            }
        };
    }])

/**
 * The $tooltip service creates tooltip- and popover-like directives as well as
 * houses global options for them.
 */
    .provider('$tooltipForPopover', function () {
        // The default options tooltip and popover.
        var defaultOptions = {
            placement: 'top',
            animation: true,
            popupDelay: 0
        };

        // Default hide triggers for each show trigger
        var triggerMap = {
            'mouseenter': 'mouseleave',
            'click': 'click',
            'focus': 'blur'
        };

        // The options specified to the provider globally.
        var globalOptions = {};

        /**
         * `options({})` allows global configuration of all tooltips in the
         * application.
         *
         *   var app = angular.module( 'App', ['ui.bootstrap.tooltip'], function ( $tooltipProvider ) {
   *     // place tooltips left instead of top by default
   *     $tooltipProvider.options( { placement: 'left' } );
   *   });
         */
        this.options = function (value) {
            angular.extend(globalOptions, value);
        };

        /**
         * This allows you to extend the set of trigger mappings available. E.g.:
         *
         *   $tooltipProvider.setTriggers( 'openTrigger': 'closeTrigger' );
         */
        this.setTriggers = function setTriggers (triggers) {
            angular.extend(triggerMap, triggers);
        };

        /**
         * This is a helper function for translating camel-case to snake-case.
         */
        function snakeCase (name) {
            var regexp = /[A-Z]/g;
            var separator = '-';
            return name.replace(regexp, function (letter, pos) {
                return (pos ? separator : '') + letter.toLowerCase();
            });
        }

        /**
         * Returns the actual instance of the $tooltip service.
         * TODO support multiple triggers
         */
        this.$get = ['$window', '$compile', '$timeout', '$parse', '$document', '$position', '$interpolate', function ($window, $compile, $timeout, $parse, $document, $position, $interpolate) {
            return function $tooltip (type, prefix, defaultTriggerShow) {
                var options = angular.extend({}, defaultOptions, globalOptions);

                /**
                 * Returns an object of show and hide triggers.
                 *
                 * If a trigger is supplied,
                 * it is used to show the tooltip; otherwise, it will use the `trigger`
                 * option passed to the `$tooltipProvider.options` method; else it will
                 * default to the trigger supplied to this directive factory.
                 *
                 * The hide trigger is based on the show trigger. If the `trigger` option
                 * was passed to the `$tooltipProvider.options` method, it will use the
                 * mapped trigger from `triggerMap` or the passed trigger if the map is
                 * undefined; otherwise, it uses the `triggerMap` value of the show
                 * trigger; else it will just use the show trigger.
                 */
                function getTriggers (trigger) {
                    var show = trigger || options.trigger || defaultTriggerShow;
                    var hide = triggerMap[show] || show;
                    return {
                        show: show,
                        hide: hide
                    };
                }

                var directiveName = snakeCase(type);

                var startSym = $interpolate.startSymbol();
                var endSym = $interpolate.endSymbol();
                var template =
                    '<' + directiveName + '-popup ' +
                    'title="' + startSym + 'tt_title' + endSym + '" ' +
                    'content="' + startSym + 'tt_content' + endSym + '" ' +
                    'placement="' + startSym + 'tt_placement' + endSym + '" ' +
                    'animation="tt_animation" ' +
                    'is-open="tt_isOpen"' +
                    'compile-scope="$parent"' +
                    '>' +
                    '</' + directiveName + '-popup>';

                return {
                    restrict: 'EA',
                    scope: true,
                    link: function link (scope, element, attrs) {
                        var tooltip = $compile(template)(scope);
                        var transitionTimeout;
                        var popupTimeout;
                        var $body = $document.find('body');
                        var appendToBody = angular.isDefined(options.appendToBody) ? options.appendToBody : false;
                        var triggers = getTriggers(undefined);
                        var hasRegisteredTriggers = false;
                        var hasEnableExp = angular.isDefined(attrs[prefix + 'Enable']);

                        // By default, the tooltip is not open.
                        // TODO add ability to start tooltip opened
                        scope.tt_isOpen = false;

                        function toggleTooltipBind () {
                            if (!scope.tt_isOpen) {
                                showTooltipBind();
                            } else {
                                hideTooltipBind();
                            }
                        }

                        // Show the tooltip with delay if specified, otherwise show it immediately
                        function showTooltipBind () {
                            if (hasEnableExp && !scope.$eval(attrs[prefix + 'Enable'])) {
                                return;
                            }
                            if (scope.tt_popupDelay) {
                                popupTimeout = $timeout(show, scope.tt_popupDelay);
                            } else {
                                scope.$apply(show);
                            }
                        }

                        function hideTooltipBind () {
                            scope.$apply(function () {
                                hide();
                            });
                        }

                        // Show the tooltip popup element.
                        function show () {
                            var position,
                                ttWidth,
                                ttHeight,
                                ttPosition;

                            // Don't show empty tooltips.
                            if (!scope.tt_content) {
                                return;
                            }

                            // If there is a pending remove transition, we must cancel it, lest the
                            // tooltip be mysteriously removed.
                            if (transitionTimeout) {
                                $timeout.cancel(transitionTimeout);
                            }

                            // Set the initial positioning.
                            tooltip.css({top: 0, left: 0, display: 'block'});

                            // Now we add it to the DOM because need some info about it. But it's not
                            // visible yet anyway.
                            if (appendToBody) {
                                $body.append(tooltip);
                            } else {
                                element.after(tooltip);
                            }

                            // Get the position of the directive element.
                            position = appendToBody ? $position.offset(element) : $position.position(element);

                            // Get the height and width of the tooltip so we can center it.
                            ttWidth = tooltip.prop('offsetWidth');
                            ttHeight = tooltip.prop('offsetHeight');

                            // Calculate the tooltip's top and left coordinates to center it with
                            // this directive.
                            switch (scope.tt_placement) {
                                case 'right':
                                    ttPosition = {
                                        top: position.top + position.height / 2 - ttHeight / 2,
                                        left: position.left + position.width
                                    };
                                    break;
                                case 'bottom':
                                    ttPosition = {
                                        top: position.top + position.height,
                                        left: position.left + position.width / 2 - ttWidth / 2
                                    };
                                    break;
                                case 'left':
                                    ttPosition = {
                                        top: position.top + position.height / 2 - ttHeight / 2,
                                        left: position.left - ttWidth
                                    };
                                    break;
                                default:
                                    ttPosition = {
                                        top: position.top - ttHeight,
                                        left: position.left + position.width / 2 - ttWidth / 2
                                    };
                                    break;
                            }

                            ttPosition.top += 'px';
                            ttPosition.left += 'px';

                            // Now set the calculated positioning.
                            tooltip.css(ttPosition);

                            // And show the tooltip.
                            scope.tt_isOpen = true;
                        }

                        // Hide the tooltip popup element.
                        function hide (destroy) {
                            // First things first: we don't show it anymore.
                            scope.tt_isOpen = false;

                            // if tooltip is going to be shown after delay, we must cancel this
                            $timeout.cancel(popupTimeout);

                            // And now we remove it from the DOM. However, if we have animation, we
                            // need to wait for it to expire beforehand.
                            // FIXME: this is a placeholder for a port of the transitions library.
                            if (scope.tt_animation) {
                                transitionTimeout = $timeout(function () {
                                    remove(destroy);
                                }, 500);
                            } else {
                                remove(destroy);
                            }
                        }

                        function remove (destroy) {
                            if (destroy) {
                                tooltip.remove();
                            } else {
                                // corresponds to jQuery's 'detach' (should be included in jqLite?)
                                angular.forEach(tooltip, function (e) {
                                    e.parentNode.removeChild(e);
                                });
                            }
                        }

                        /**
                         * Observe the relevant attributes.
                         */
                        attrs.$observe(type, function (val) {
                            scope.tt_content = val;

                            if (!val && scope.tt_isOpen) {
                                hide();
                            }
                        });

                        attrs.$observe(prefix + 'Title', function (val) {
                            scope.tt_title = val;
                        });

                        attrs.$observe(prefix + 'Placement', function (val) {
                            scope.tt_placement = angular.isDefined(val) ? val : options.placement;
                        });

                        attrs.$observe(prefix + 'Animation', function (val) {
                            scope.tt_animation = angular.isDefined(val) ? !!val : options.animation;
                        });

                        attrs.$observe(prefix + 'PopupDelay', function (val) {
                            var delay = parseInt(val, 10);
                            scope.tt_popupDelay = !isNaN(delay) ? delay : options.popupDelay;
                        });

                        attrs.$observe(prefix + 'Trigger', function (val) {

                            if (hasRegisteredTriggers) {
                                element.unbind(triggers.show, showTooltipBind);
                                element.unbind(triggers.hide, hideTooltipBind);
                            }

                            triggers = getTriggers(val);

                            if (triggers.show === triggers.hide) {
                                element.bind(triggers.show, toggleTooltipBind);
                            } else {
                                element.bind(triggers.show, showTooltipBind);
                                element.bind(triggers.hide, hideTooltipBind);
                            }

                            hasRegisteredTriggers = true;
                        });

                        attrs.$observe(prefix + 'AppendToBody', function (val) {
                            appendToBody = angular.isDefined(val) ? $parse(val)(scope) : appendToBody;
                        });

                        // if a tooltip is attached to <body> we need to remove it on
                        // location change as its parent scope will probably not be destroyed
                        // by the change.
                        if (appendToBody) {
                            scope.$on('$locationChangeSuccess', function closeTooltipOnLocationChangeSuccess () {
                                if (scope.tt_isOpen) {
                                    hide();
                                }
                            });
                        }

                        // Make sure tooltip is destroyed and removed.
                        scope.$on('$destroy', function onDestroyTooltip () {
                            $timeout.cancel(popupTimeout);
                            remove(true);
                            tooltip.unbind();
                            tooltip = null;
                            $body = null;
                        });
                    }
                };
            };
        }];
    })

    .directive('changePasswordForUser', ['$modal', 'Users', 'notificationTranslationHelper', 'ErrorUtil',
        function ($modal, Users, notificationTranslationHelper, ErrorUtil) {
            return {
                link: function (scope, element, attr) {
                    var clickAction = attr.onClose;
                    var user = attr.user;
                    element.bind('click', function () {
                        var modalInstance = $modal.open({
                            templateUrl: 'common/modals/modalChangePasswordForUser.tpl.html',
                            controller: ['$rootScope', '$scope', '$modalInstance', 'user', function ($rootScope, $scope, $modalInstance, user) {
                                $scope.user = user;
                                $scope.changePassword = function () {
                                    // flag that is used by the backend to determine whether to ignore the password that is sent or not.
                                    user.updatePassword = 1;
                                    var updatePromise = Users.update({}, user);
                                    updatePromise.$promise.then(
                                        function (success) {
                                            $modalInstance.close(user);
                                            $rootScope.$broadcast('refresh-general-info');
                                        }, function (error) {
                                            notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                                        });
                                };
                                $scope.cancelModal = function () {
                                    $modalInstance.dismiss('cancel');
                                };
                            }],
                            resolve: {
                                user: function () {
                                    return scope.$eval(user);
                                }
                            }
                        });
                        modalInstance.result.then(function () {
                            notificationTranslationHelper.notifyMessage('users.passwordSelfService.save.success');
                            scope.$eval(clickAction);
                        });
                    });
                }
            };
        }])

        /*
        * tab indexes should a positive int > 0 assigned as follows:
        *
        * x01       : to the ticket container (should have class 'kds-receipt-tile-container-inner')
        * x02 - x99 : to the the items (should be a button with class 'kds-tile-item-container')
        * (x+1)00   : to the footer button (should be a button with class 'kds-tile-bottom-button')
        *
        * this tab index structure is important and should be properly maintained, as we are using
        * tabIndex % 100 to perform calculations for changing focus
        */
        .directive('bumpBarViewPort', [function () {
            return {
                scope: {
                    hasBumpBarSupport: '<bumpBarViewPort',
                    bumpBarKeyBinds: '<bumpBarKeyBinds' // should have shape {KeyboardEvent.code: 'ACTION_NAME', ...}
                },
                link: function (scope, element) {
                    const getTicketElement = (tabIndex) => {
                        if (!tabIndex) return;
                        const ticketTabIndex = tabIndex - ((tabIndex - 1) % 100);
                        return element[0].querySelector(`[tabindex='${ticketTabIndex}']`);
                    };

                    const getItemsContainerElement = (tabIndex) => {
                        if (!tabIndex) return;
                        const firstItemTabIndex = tabIndex - ((tabIndex - 1) % 100) + 1;
                        const firstItem = element[0].querySelector(`[tabindex='${firstItemTabIndex}']`);

                        if (!firstItem) return null;
                        return firstItem.parentElement;
                    };

                    const getFooterButtonElement = (tabIndex) => {
                        if (!tabIndex) return;
                        const footerButtonTabIndex = tabIndex - ((tabIndex - 1) % 100) + 99;
                        return element[0].querySelector(`[tabindex='${footerButtonTabIndex}']`);
                    };

                    const focusAndScroll = (element) => {
                        if (!element) {
                            return;
                        }

                        element.focus({preventScroll: true});
                        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'});
                    };

                    const ACTIONS = {
                        FOCUS: (action) => {
                            const ticketTabIndex = TAB_INDEX_MAP[action];
                            if (!ticketTabIndex) return;

                            const ticket = getTicketElement(ticketTabIndex);
                            if (ticket) focusAndScroll(ticket);
                        },
                        UP: () => {
                            const currentFocus = document.activeElement;
                            if (!currentFocus || !currentFocus.tabIndex || currentFocus.tabIndex < 1) return;

                            const currentTabIndex = currentFocus.tabIndex;
                            switch (currentTabIndex % 100) {
                                // focused on footer button
                                case 0: {
                                    const itemsContainer = getItemsContainerElement(currentTabIndex);
                                    if (itemsContainer) {
                                        itemsContainer.lastElementChild.focus();
                                    }
                                    break;
                                }
                                // focused on ticket
                                case 1: {
                                    const footerButton = getFooterButtonElement(currentTabIndex);
                                    if (footerButton) {
                                        footerButton.focus();
                                    } else {
                                        const itemsContainer = getItemsContainerElement(currentTabIndex);
                                        if (itemsContainer) itemsContainer.lastElementChild.focus();
                                    }
                                    break;
                                }
                                // focused on an item within the ticket
                                default: {
                                    if (currentFocus.previousElementSibling) {
                                        currentFocus.previousElementSibling.focus();
                                    } else {
                                        getFooterButtonElement(currentTabIndex).focus();
                                    }
                                }
                            }
                        },
                        DOWN: () => {
                            const currentFocus = document.activeElement;
                            if (!currentFocus || !currentFocus.tabIndex || currentFocus.tabIndex < 1) return;

                            const currentTabIndex = currentFocus.tabIndex;
                            switch (currentTabIndex % 100) {
                                // focused on footer button
                                case 0: {
                                    const itemsContainer = getItemsContainerElement(currentTabIndex);
                                    if (itemsContainer) itemsContainer.firstElementChild.focus();
                                    break;
                                }
                                // focused on ticket
                                case 1: {
                                    const itemsContainer = getItemsContainerElement(currentTabIndex);
                                    if (itemsContainer) {
                                        itemsContainer.firstElementChild.focus();
                                    } else {
                                        const footerButton = getFooterButtonElement(currentTabIndex);
                                        if (footerButton) footerButton.focus();
                                    }
                                    break;
                                }
                                // focused on an item within the ticket
                                default: {
                                    if (currentFocus.nextElementSibling) {
                                        currentFocus.nextElementSibling.focus();
                                    } else {
                                        const footerButton = getFooterButtonElement(currentTabIndex);
                                        if (footerButton) {
                                            footerButton.focus();
                                        } else {
                                            getTicketElement(currentTabIndex).focus();
                                        }
                                    }
                                }
                            }
                        },
                        LEFT: () => {
                            const currentFocus = document.activeElement;
                            if (!currentFocus || !currentFocus.tabIndex || currentFocus.tabIndex < 1) {
                                // focus on the last ticket
                                const firstTicket = getTicketElement(1);
                                if (!firstTicket) return;

                                const ticketsContainer = firstTicket.parentElement.parentElement;
                                focusAndScroll(ticketsContainer.lastElementChild.firstElementChild);
                                return;
                            }

                            const currentTabIndex = currentFocus.tabIndex;
                            const ticketContainer = getTicketElement(currentTabIndex).parentElement;

                            if (ticketContainer.previousElementSibling) {
                                focusAndScroll(ticketContainer.previousElementSibling.firstElementChild);
                            } else {
                                const lastTicketContainer = ticketContainer.parentElement.lastElementChild;
                                focusAndScroll(lastTicketContainer.firstElementChild);
                            }
                        },
                        RIGHT: () => {
                            const currentFocus = document.activeElement;
                            if (!currentFocus || !currentFocus.tabIndex || currentFocus.tabIndex < 1) {
                                // focus on the first ticket
                                const firstTicket = getTicketElement(1);
                                if (firstTicket) focusAndScroll(firstTicket);
                                return;
                            }

                            const currentTabIndex = currentFocus.tabIndex;
                            const ticketContainer = getTicketElement(currentTabIndex).parentElement;

                            if (ticketContainer.nextElementSibling) {
                                focusAndScroll(ticketContainer.nextElementSibling.firstElementChild);
                            } else {
                                const firstTicketContainer = ticketContainer.parentElement.firstElementChild;
                                focusAndScroll(firstTicketContainer.firstElementChild);
                            }
                        },
                        CLICK: () => {
                            const currentFocus = document.activeElement;
                            if (!currentFocus || !currentFocus.tabIndex || currentFocus.tabIndex < 1) return;

                            currentFocus.click();
                        },
                        BUMP: () => {
                            const currentFocus = document.activeElement;
                            if (!currentFocus || !currentFocus.tabIndex || currentFocus.tabIndex < 1) return;

                            const currentTabIndex = currentFocus.tabIndex;
                            const footerButton = getFooterButtonElement(currentTabIndex);
                            if (footerButton) {
                                footerButton.focus();
                                footerButton.click();
                            }
                        }
                    };

                    const ACTION_MAP = {
                        'SELECT_TICKET_1': ACTIONS.FOCUS,
                        'SELECT_TICKET_2': ACTIONS.FOCUS,
                        'SELECT_TICKET_3': ACTIONS.FOCUS,
                        'SELECT_TICKET_4': ACTIONS.FOCUS,
                        'SELECT_TICKET_5': ACTIONS.FOCUS,
                        'SELECT_TICKET_6': ACTIONS.FOCUS,
                        'SELECT_TICKET_7': ACTIONS.FOCUS,
                        'SELECT_TICKET_8': ACTIONS.FOCUS,
                        'SELECT_TICKET_9': ACTIONS.FOCUS,
                        'SELECT_TICKET_10': ACTIONS.FOCUS,
                        'UP': ACTIONS.UP,
                        'DOWN': ACTIONS.DOWN,
                        'LEFT': ACTIONS.LEFT,
                        'RIGHT': ACTIONS.RIGHT,
                        'CLICK': ACTIONS.CLICK,
                        'BUMP_TICKET': ACTIONS.BUMP
                    };

                    const TAB_INDEX_MAP = {
                        'SELECT_TICKET_1': 1,
                        'SELECT_TICKET_2': 101,
                        'SELECT_TICKET_3': 201,
                        'SELECT_TICKET_4': 301,
                        'SELECT_TICKET_5': 401,
                        'SELECT_TICKET_6': 501,
                        'SELECT_TICKET_7': 601,
                        'SELECT_TICKET_8': 701,
                        'SELECT_TICKET_9': 801,
                        'SELECT_TICKET_10': 901
                    };

                    const runAction = (event) => {
                        event.preventDefault();
                        event.stopPropagation();

                        const {code} = event;
                        const action = scope.bumpBarKeyBinds[code];

                        if (!scope.hasBumpBarSupport || !action) return;

                        ACTION_MAP[action](action);
                    };

                    // Enable key listener for bump bar support
                    window.addEventListener('keydown', runAction);
                    scope.$on('$destroy', function () {
                        window.removeEventListener('keydown', runAction);
                    });
                }
            };
        }]);

require('../directives/currency-input-directive.js')(freshideasDirectiveCommon);
require('../directives/percentage-input-directive.js')(freshideasDirectiveCommon);
require('../directives/simple-bar-chart.js')(freshideasDirectiveCommon);
require('../directives/numpad-directive.js')(freshideasDirectiveCommon);
require('../components/rounded-btn/rounded-btn')(freshideasDirectiveCommon);
require('../directives/orderable.js')(freshideasDirectiveCommon);
require('../directives/highlighter-directive.js')(freshideasDirectiveCommon);
require('../components/patron_loyalty/patron-loyalty.js')(freshideasDirectiveCommon);
require('../components/loading_indicator/loading_indicator.js')(freshideasDirectiveCommon);
require('../directives/lazy-src-directive.js')(freshideasDirectiveCommon);
require('../directives/nown-support-directive.js')(freshideasDirectiveCommon);
require('../directives/badge.js')(freshideasDirectiveCommon);
require('../directives/in-modal-notification.js')(freshideasDirectiveCommon);

export default freshideasDirectiveCommon;
