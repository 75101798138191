'use strict';


var PAYMENT_TYPES = [{
    id: 'cash',
    name: 'Cash'
}, {
    id: 'cheque',
    name: 'Cheque'
}, {
    id: 'credit-card',
    name: 'Credit Card'
}, {
    id: 'other',
    name: 'Other'
}];


const angular = require('angular');
const moment = require('moment');
const freshideasResourcesUsers = require('../../common/resources/users.js').default;
const mod = angular.module('freshideas.patrons.meal-plans', [freshideasResourcesUsers.name]);

mod.controller(
    'MealPlanBalanceAdjustmentCtrl',
    ['$scope', 'Patrons', 'Security', 'ErrorUtil', 'notificationTranslationHelper', 'PosAlertService',
     function ($scope, Patrons, Security, ErrorUtil, notificationTranslationHelper, PosAlertService) {

         var user = Security.getUser() || {};
         $scope.user = user;

         $scope.$watch('patron', function (patron) {
             $scope.patron = patron;
         });

         $scope.zeroBalance = function (mealPlanType) {
             $scope.adjustment.balance = $scope.mealPlan.currentBalance;
         };

         $scope.resetInputs = () => {
            $scope.adjustment = createAdjustment();

         };

         $scope.goBack = function (mealPlan) {
            $scope.adjustment = createAdjustment();
            $scope.$parent.showPatronDetail(mealPlan);
         };

         $scope.savePatronAdjustment = function (adjustment, patron, mealPlan) {

             var params = {};
             params.requestedPermission = 'pos:edit_meal_plan_balances';
             params.verifyAllUserPinsForCompany = true;

             params.callback = function (pinResponse) {

                 var mealBalance = 0;
                 var dcbBalance = 0;
                 if (mealPlan.mealPlanType == 'MEAL') {
                     mealBalance = adjustment.balance;
                 }
                 if (mealPlan.mealPlanType == 'DCB') {
                     dcbBalance = adjustment.balance;
                 }
                 var adjustment2 = {
                     timestamp: adjustment.timestamp,
                     userId: pinResponse.user.userId,
                     patronId: patron.patronId,
                     mealPlanId: mealPlan.mealPlanId,
                     mealBalance: mealBalance,
                     dcbBalance: dcbBalance,
                     comment: adjustment.comment,
                     paymentType: '' // No payment type for adjustment, but needed for backend.
                 };
                 Patrons.patronAddNewAdjustment({}, adjustment2, function (response) {
                     // Reload patrons on search so they have the updated balance as soon as the update is complete.
                     $scope.$emit('pos::patronLoyaltyCardUpdated');
                     if (mealPlan.mealPlanType == 'MEAL') {
                         mealPlan.currentMealPlanBalance += mealBalance;
                         mealPlan.currentBalance += mealBalance;
                     }
                     if (mealPlan.mealPlanType == 'DCB') {
                         mealPlan.currentDcbBalance += dcbBalance;
                     }

                     $scope.adjustment = createAdjustment();
                     $scope.goBack(mealPlan);
                     $scope.$parent.closeMealPlanEditView();
                 }, function (error) {
                     notificationTranslationHelper.notifyError(
                         ErrorUtil.parseError(error), null, true);
                 });
             };
             params.errorCallback = function (error) {
                 PosAlertService.showAlertByName('pincode-fail');
             };
             $scope.$emit('PincodeAuthentication:Required', params);

             return params;
         };

         $scope.showMealPlanEditView = function (mealPlan) {
             $scope.adjustment = createAdjustment();
             $scope.showPatronMealPlanEditView()(mealPlan);
         };

         var createAdjustment = function () {
             return {
                 timestamp: moment().valueOf(),
                 userId: user.userId,
                 comment: null,
                 balance: null,
             };
         };

         var init = function () {
             $scope.adjustment = createAdjustment();
         };

         init();

     }]);

mod.controller(
    'MealPlanBalanceDepositCtrl',
    ['$scope', 'Patrons', 'Security',
     'ErrorUtil', 'notificationTranslationHelper',
     function ($scope, Patrons, Security,
        ErrorUtil, notificationTranslationHelper) {

         var user = Security.getUser();
         $scope.user = user;

         $scope.paymentTypes = PAYMENT_TYPES;

         $scope.$watch('patron', function (patron) {
             $scope.patron = patron;
         });

         $scope.depositToBalance = function (deposit, patron, mealPlan) {

             if (mealPlan.mealPlanType == 'MEAL') {
                 deposit.mealBalance = deposit.balance;
             } else if (mealPlan.mealPlanType == 'DCB') {
                 deposit.dcbBalance = deposit.balance;
             } else if (mealPlan.mealPlanType == 'ICB') {
                 deposit.icbBalance = deposit.balance;
             }

             if (patron !== undefined) {
                 deposit.patronId = patron.patronId;
             }

             if (mealPlan !== undefined) {
                 deposit.mealPlanId = mealPlan.mealPlanId;
             }

             Patrons.patronAddNewDeposit({}, deposit, function (response) {
                 notificationTranslationHelper.notifyMessage(
                     'patrons.mealPlanEdit.deposit.saved');
                 if (mealPlan.mealPlanType == 'MEAL') {
                     mealPlan.currentMealPlanBalance += deposit.balance;
                     mealPlan.currentBalance = mealPlan.currentMealPlanBalance;
                 } else if (mealPlan.mealPlanType == 'DCB') {
                     mealPlan.currentDcbBalance += deposit.balance;
                     mealPlan.currentBalance = mealPlan.currentDcbBalance;
                 } else if (mealPlan.mealPlanType == 'ICB') {
                     mealPlan.currentChargeBalance += deposit.balance;
                     mealPlan.currentBalance = mealPlan.currentChargeBalance;
                 }
                 $scope.showMealPlanEditView(mealPlan);
                 $scope.deposit = createDeposit();
                 $scope.onMealPlanUpdate()(mealPlan);
                 $scope.onSaveMealPlan()()($scope.patron, true); // find a way to pass this callback without being wrapped in an angular evaluation
             }, function (error) {
                 notificationTranslationHelper.notifyError(
                     ErrorUtil.parseError(error), null, true);
             });
         };

         $scope.showMealPlanEditView = function (mealPlan) {
             $scope.deposit = createDeposit();
             $scope.showPatronMealPlanEditView()(mealPlan);
         };

         var createDeposit = function () {
             return {
                 timestamp: moment().valueOf(),
                 userId: user.userId,
                 balance: 0,
                 paymentType: null,
                 authorization: null,
                 comment: null,
             };
         };

         var init = function () {
             $scope.deposit = createDeposit();
         };

         init();
     }]);

mod.controller(
    'MealPlanBalanceRefundCtrl',
    ['$scope', '$element', '$attrs', 'Patrons', 'Security',
     'ErrorUtil', 'notificationTranslationHelper',
     function ($scope, $element, $attrs, Patrons, Security,
        ErrorUtil, notificationTranslationHelper) {

         var user = Security.getUser();
         $scope.user = user;

         $scope.paymentTypes = PAYMENT_TYPES;

         $scope.$watch('patron', function (patron) {
             $scope.patron = patron;
             if (patron !== undefined) {
                 $scope.refund.patronId = patron.patronId;
             }
         });

         $scope.$watch('mealPlan', function (mealPlan) {
             if (mealPlan !== undefined) {
                 $scope.refund.mealPlanId = mealPlan.mealPlanId;
             }
         });

         $scope.refundBalance = function (refund, patron, mealPlan) {

             if (mealPlan.mealPlanType == 'MEAL') {
                 refund.mealBalance = refund.balance;
             } else if (mealPlan.mealPlanType == 'DCB') {
                 refund.dcbBalance = refund.balance;
             } else if (mealPlan.mealPlanType == 'ICB') {
                 refund.chargeBalance = refund.balance;
             }

             if (patron !== undefined) {
                 refund.patronId = patron.patronId;
             }

             if (mealPlan !== undefined) {
                 refund.mealPlanId = mealPlan.mealPlanId;
             }

             Patrons.patronAddNewRefund({}, refund, function (response) {
                 notificationTranslationHelper.notifyMessage(
                     'patrons.mealPlanEdit.refund.saved');
                 if (mealPlan.mealPlanType == 'MEAL') {
                     mealPlan.currentMealPlanBalance += refund.balance;
                     mealPlan.currentBalance = mealPlan.currentMealPlanBalance;
                 } else if (mealPlan.mealPlanType == 'DCB') {
                     mealPlan.currentDcbBalance += refund.balance;
                     mealPlan.currentBalance = mealPlan.currentDcbBalance;
                 } else if (mealPlan.mealPlanType == 'ICB') {
                     mealPlan.currentChargeBalance += refund.balance;
                     mealPlan.currentBalance = mealPlan.currentChargeBalance;
                 }
                 $scope.showMealPlanEditView(mealPlan);
                 $scope.refund = createRefund();
                 $scope.onMealPlanUpdate()(mealPlan);
                 $scope.onSaveMealPlan()()($scope.patron, true); // find a way to pass this callback without being wrapped in an angular evaluation
             }, function (error) {
                 notificationTranslationHelper.notifyError(
                     ErrorUtil.parseError(error), null, true);
             });
         };

         $scope.showMealPlanEditView = function (mealPlan) {
             $scope.refund = createRefund();
             $scope.showPatronMealPlanEditView()(mealPlan);
         };

         var createRefund = function () {
             return {
                 timestamp: moment().valueOf(),
                 userId: user.userId,
                 paymentType: null,
                 authorization: null,
                 balance: 0,
                 comment: null,
             };
         };

         var init = function () {
             $scope.refund = createRefund();
         };

         init();
     }]);

mod.directive('mealPlanBalanceAdjustment', function () {

    return {
        controller: 'MealPlanBalanceAdjustmentCtrl',
        templateUrl: 'patrons/meal-plans/balance-adjustment.tpl.html',
        restrict: 'E',
        scope: {
            patron: '=',
            mealPlan: '=',
            showPatronMealPlanEditView: '&',
            onMealPlanUpdate: '&',
            onSaveMealPlan: '&'
        },
        link: function (scope, element, attrs) {
            scope.$watch('mealPlan', function (mealPlan) {
                scope.mealPlan = mealPlan;
                if (mealPlan !== undefined) {
                    scope.mpType = mealPlan.mealPlanType;
                }
            });
        }
    };
});

mod.directive('mealPlanBalanceDeposit', function () {

    return {
        controller: 'MealPlanBalanceDepositCtrl',
        templateUrl: 'patrons/meal-plans/balance-deposit.tpl.html',
        restrict: 'E',
        scope: {
            patron: '=',
            mealPlan: '=',
            showPatronMealPlanEditView: '&',
            onMealPlanUpdate: '&',
            onSaveMealPlan: '&'
        },
        link: function (scope, element, attrs) {
            scope.$watch('mealPlan', function (mealPlan) {
                scope.mealPlan = mealPlan;
                if (mealPlan !== undefined) {
                    scope.mpType = mealPlan.mealPlanType;
                }
            });
        }
    };
});

mod.directive('mealPlanBalanceRefund', function () {

    return {
        controller: 'MealPlanBalanceRefundCtrl',
        templateUrl: 'patrons/meal-plans/balance-refund.tpl.html',
        restrict: 'E',
        scope: {
            patron: '=',
            mealPlan: '=',
            showPatronMealPlanEditView: '&',
            onMealPlanUpdate: '&',
            onSaveMealPlan: '&'
        },
        link: function (scope, element, attrs) {
            scope.$watch('mealPlan', function (mealPlan) {
                scope.mealPlan = mealPlan;
                if (mealPlan !== undefined) {
                    scope.mpType = mealPlan.mealPlanType;
                }
            });
        }
    };
});

mod.component('mealPlanAdjustmentView', {
    selector: 'mealPlanAdjustmentView',
    bindings: {
        patron: '=',
        mealPlan: '=',
        mealPlanEditView: '=',
        closeMealPlanEditView: '=',
        showPatronMealPlanEditView: '&',
        onMealPlanUpdate: '&',
        onSaveMealPlan: '&'},
    templateUrl: 'patrons/components/patron-meal-plan-adjustment/patron-meal-plan-adjustment.tpl.html',
    controller: 'MealPlanBalanceAdjustmentCtrl'
});

export default mod;
