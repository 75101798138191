'use strict';

const angular = require('angular');
const $ = require("jquery");
//const $ = require('jquery-ui');

export default angular.module('freshideas.directives.jquery', [])

    .directive('aceFileUpload', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                // Get the options for the attributes
                var options = scope.$eval(attrs.aceFileUpload) || {};
                $(element).ace_file_input(options)
                    .on('change', function (event) {
                        var files = event.target.files;
                        for (var i = 0; i < files.length; i++) {
                            //emit event upward
                            scope.$emit('fileSelected', {file: files[i]});
                        }
                    });
            }
        };
    })

    // Requires jQuery
    .directive('drag', function () {
        return {
            restrict: 'A',
            link: function (scope, element) {
                $(element).draggable();
            }
        };
    })

    // Requires bootstrap-timepicker.min.js
    .directive('freshideasTimepicker', ['$timeout', function ($timeout) {
        return {
            restrict: 'A',
            scope: {
                time: '=',
                className: '='
            },
            link: function (scope, element, attrs) {
                scope.$watch('time', function () {
                    $timeout(function () {
                        element.timepicker();
                        element.timepicker('setTime', scope.time);
                        element.timepicker().on('changeTime.timepicker', function (e) {
                            scope.$apply(function () {
                                scope.time = e.time.value;
                            });
                        });
                        element.data('timepicker').$widget.addClass(scope.className);
                    });
                });
            }
        };
    }])

    // Requires jQuery
    .directive('sortable', function () {
        return {
            restrict: 'A',
            scope: {
                onSortUpdate: '&',
                sortableOptions: '&'
            },
            link: function (scope, element, attrs) {
                var sortableOptions = angular.isDefined(scope.sortableOptions()) ? scope.sortableOptions() : {
                    connectWith: '.widget-container-span',
                    handle: '.widget-header',
                    items: '> .widget-box',
                    opacity: 0.8,
                    revert: true,
                    forceHelperSize: true,
                    placeholder: 'widget-placeholder',
                    forcePlaceholderSize: true,
                    tolerance: 'pointer'
                };
                // TODO resolve issue with jquery libs
                //$(element).sortable(sortableOptions);
                var attributeToArray = angular.isDefined(attrs.attribute) ? attrs.attribute : 'id';
                $(element).on('sortupdate', function (event, ui) {
                    var data = $(element);//.sortable('toArray', {attribute: attributeToArray});
                    if (attrs.onSortUpdate) {
                        scope.onSortUpdate({data: data});
                    }
                });
            }
        };
    })

    // Requires jQuery.
    // Ex: <input date-picker year-month-mode ng-model='aDate'> {{ aDate }}
    .directive('datePicker', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModelCtrl) {

                var updateModel = function () {
                    scope.$apply(function () {
                        var date = $(element).datepicker('getDate');
                        //$(element).datepicker('setDate', $(element).val());
                        ngModelCtrl.$setViewValue(date);
                    });
                };

                var onSelectHandler = function (userHandler) {
                    if (userHandler) {
                        // Caller has specified their own onSelect handler
                        // so call this as well as updating the model
                        return function (value, picker) {
                            updateModel();
                            return userHandler(value, picker);
                        };
                    } else {
                        return updateModel;
                    }
                };

                var yearMonthModeBeforeShow = function () {
                    var datestr = $(element).val();
                    if (datestr.length > 0) {
                        var year = datestr.substring(datestr.length - 4, datestr.length);
                        var month = jQuery.inArray(datestr.substring(0, datestr.length - 5), $(element).datepicker('option', 'monthNames'));
                        $(element).datepicker('option', 'defaultDate', new Date(year, month));
                        $(element).datepicker('setDate', new Date(year, month));
                    }
                };

                var yearMonthModeOnClose = function () {
                    var month = $('#ui-datepicker-div .ui-datepicker-month :selected').val();
                    var year = $('#ui-datepicker-div .ui-datepicker-year :selected').val();
                    $(element).datepicker('option', 'defaultDate', new Date(year, month));
                    $(element).datepicker('setDate', new Date(year, month));
                    updateModel();

                };

                var setUpDatePicker = function () {
                    var options = scope.$eval(attrs.datePicker) || {};
                    // Bind to the date picker select event
                    options.onSelect = onSelectHandler(options.onSelect);
                    // In case the user changes the text directly in the input box
                    $(element).bind('change', updateModel);

                    // Check if we want only month/year ('yearMonthMode' attribute)
                    var yearMonthMode = angular.isDefined(attrs.yearMonthMode);
                    if (yearMonthMode) {
                        options.dateFormat = 'MM yy';
                        options.changeMonth = true;
                        options.changeYear = true;
                        options.showButtonPanel = true;
                        options.beforeShow = yearMonthModeBeforeShow;
                        options.onClose = yearMonthModeOnClose;
                    }
                    // Remove any previous date picker, to ensure any config changes are picked up
                    $(element).datepicker('destroy');
                    // Create the new datepicker widget
                    $(element).datepicker(options);
                    // Render will update the date picker with the date
                    ngModelCtrl.$render();
                };

                ngModelCtrl.$formatters.push(function (date) {
                    if (angular.isDefined(date) && date !== null && !angular.isDate(date)) {
                        throw new Error('ng-Model value must be a Date object - currently it is a ' + typeof date);
                    }
                    return date;
                });

                ngModelCtrl.$render = function () {
                    $(element).datepicker('setDate', ngModelCtrl.$viewValue);
                };

                // Watch for changes to the directives options
                scope.$watch(attrs.datePicker, setUpDatePicker, true);
            }
        };
    })
// Requires jQuery and jQueryUI
    .value('uiSliderConfig', {}).directive('uiSlider', ['uiSliderConfig', '$timeout', function (uiSliderConfig, $timeout) {
        uiSliderConfig = uiSliderConfig || {};
        return {
            require: 'ngModel',
            compile: function () {
                return function (scope, elm, attrs, ngModel) {

                    function parseNumber(n, decimals) {
                        return (decimals) ? parseFloat(n) : parseInt(n);
                    }

                    // Copying the options allows multiple sliders to use the same slider options definition
                    var options = angular.copy(options);
                    options = angular.extend(scope.$eval(attrs.uiSlider) || {}, uiSliderConfig);

                    // Object holding range values
                    var prevRangeValues = {
                        min: null,
                        max: null
                    };

                    // convenience properties
                    var properties = ['min', 'max', 'step'];
                    var useDecimals = (!angular.isUndefined(attrs.useDecimals)) ? true : false;

                    var init = function () {
                        // When ngModel is assigned an array of values then range is expected to be true.
                        // Warn user and change range to true else an error occurs when trying to drag handle
                        if (angular.isArray(ngModel.$viewValue) && options.range !== true) {
                            console.warn('Change your range option of ui-slider. When assigning ngModel an array of values then the range option should be set to true.');
                            options.range = true;
                        }

                        // Ensure the convenience properties are passed as options if they're defined
                        // This avoids init ordering issues where the slider's initial state (eg handle
                        // position) is calculated using widget defaults
                        // Note the properties take precedence over any duplicates in options
                        angular.forEach(properties, function (property) {
                            if (angular.isDefined(attrs[property])) {
                                options[property] = parseNumber(attrs[property], useDecimals);
                            }
                        });

                        elm.slider(options);
                        init = angular.noop;
                    };

                    // Find out if decimals are to be used for slider
                    angular.forEach(properties, function (property) {
                        // support {{}} and watch for updates
                        attrs.$observe(property, function (newVal) {
                            if (!!newVal) {
                                init();
                                elm.slider('option', property, parseNumber(newVal, useDecimals));
                            }
                        });
                    });
                    attrs.$observe('disabled', function (newVal) {
                        init();
                        elm.slider('option', 'disabled', !!newVal);
                    });

                    // Watch ui-slider (byVal) for changes and update
                    scope.$watch(attrs.uiSlider, function (newVal) {
                        init();
                        if (newVal !== undefined) {
                            elm.slider('option', newVal);
                        }
                    }, true);

                    // Late-bind to prevent compiler clobbering
                    $timeout(init, 0, true);

                    // Update model value from slider
                    elm.bind('slide', function (event, ui) {
                        ngModel.$setViewValue(ui.values || ui.value);
                        scope.$apply();
                    });

                    // Update slider from model value
                    ngModel.$render = function () {
                        init();
                        var method = options.range === true ? 'values' : 'value';

                        if (isNaN(ngModel.$viewValue) && !(ngModel.$viewValue instanceof Array)) {
                            ngModel.$viewValue = 0;
                        }

                        // Do some sanity check of range values
                        if (options.range === true) {

                            // Check outer bounds for min and max values
                            if (angular.isDefined(options.min) && options.min > ngModel.$viewValue[0]) {
                                ngModel.$viewValue[0] = options.min;
                            }
                            if (angular.isDefined(options.max) && options.max < ngModel.$viewValue[1]) {
                                ngModel.$viewValue[1] = options.max;
                            }

                            // Check min and max range values
                            if (ngModel.$viewValue[0] >= ngModel.$viewValue[1]) {
                                // Min value should be less to equal to max value
                                if (prevRangeValues.min >= ngModel.$viewValue[1]) {
                                    ngModel.$viewValue[0] = prevRangeValues.min;
                                }
                                // Max value should be less to equal to min value
                                if (prevRangeValues.max <= ngModel.$viewValue[0]) {
                                    ngModel.$viewValue[1] = prevRangeValues.max;
                                }
                            }

                            // Store values for later user
                            prevRangeValues.min = ngModel.$viewValue[0];
                            prevRangeValues.max = ngModel.$viewValue[1];

                        }
                        elm.slider(method, ngModel.$viewValue);
                    };

                    scope.$watch(attrs.ngModel, function () {
                        if (options.range === true) {
                            ngModel.$render();
                        }
                    }, true);

                    function destroy() {
                        elm.slider('destroy');
                    }

                    elm.bind('$destroy', destroy);
                };
            }
        };
    }])

    // Requires jquery.ui.selectable
    .directive('uiSelectable', function () {
        return {
            scope: {
                onSelected: '&',
                onUnselected: '&'
            },
            link: function (scope, elm, attrs) {
                var options = scope.$eval(attrs.uiSelectable) || {};
                options = angular.extend(options,
                    {
                        selected: function (evt, ui) {
                            if (scope.onSelected) {
                                scope.onSelected({event: evt, ui: ui});
                            }
                        },
                        unselected: function (evt, ui) {
                            if (scope.onUnselected) {
                                scope.onUnselected({event: evt, ui: ui});
                            }
                        }
                    });
                elm.selectable(options);

                scope.$on('$destroy', function () {
                    elm.selectable('destroy');
                });
            }
        };
    })
;
