'use strict';

const angular = require('angular');
const moment = require('moment');

export default angular.module('freshideas.services.date', [])
    .factory('DateService', [function () {
        var service = {
            locale: 'en_US',

            setLocale: function (locale) {
                this.locale = locale;
            },
            // format a locale-specific string using moment.js
            formatMoment: function (dateString, format) {
                if (dateString) {
                    return moment(dateString).lang(this.locale).format(format);
                }
                return dateString;
            },
            // convert a moment.js locale-aware format into a suitable format for the angular bootstrap datepicker,
            // which uses the angular standard format.
            datePickerFormat: function (momentFormat) {
                var format = moment.langData(this.locale).longDateFormat(momentFormat);
                // moment uses a different format standard than angular
                format = format.replace(/Y/g, 'y');
                format = format.replace(/D/g, 'd');
                return format;
            }
        };
        return service;
    }])
    .filter('localizedDate', ['DateService', function (DateService) {
        return function (dateString, format) {
            return DateService.formatMoment(dateString, format);
        };
    }])
    .filter('datepickerFormat', ['DateService', function(DateService) {
        return function (format) {
            return DateService.datePickerFormat(format);
        };
    }]);
