'use strict';


module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ProductGeneralListCtrl', [
        '$scope',
        'ProductsService',
        'title',
        'entity',
        'attributes',
        function ($scope, ProductsService, title, entity, attributes) {

            $scope.title = title;

            $scope.entity = entity;
            $scope.attributes = attributes;

            _.each($scope.attributes, function (attribute) {
                attribute.type = Array.isArray(attribute.options) ? 'list' : attribute.options;

                // `attribute.identifier` dynamically identifies the field in `entity` that needs binding.
                // However, the template cannot bind to it using bracket notation if `attribute.identifier`
                // referenes a nested field. To work around it, traverse through the nested properties and
                // save the reference and the remaining identifier name for later uses.
                var path = attribute.identifier.split('.');
                var obj = entity;
                while (path.length > 1) {
                    obj = obj[path.shift()];
                }
                attribute.model = obj;
                attribute.modelField = path.shift();
            });

            $scope.select = function (attribute, option) {
                if (attribute.model[attribute.modelField] === option[attribute.optionIdentifier]) {
                    attribute.model[attribute.modelField] = undefined;
                } else {
                    attribute.model[attribute.modelField] = option[attribute.optionIdentifier];
                }
            };
        }]);
};
