'use strict';


module.exports = function (freshideasProducts) {
    freshideasProducts.controller('MasterAssociationCtrl', [
        '$scope',
        'menus',
        function (
            $scope,
            menus) {

            $scope.menus = {
                associated: menus,
                menuToCopyFrom: {}
            };

            $scope.closeModal = () => {
                $scope.$close();
            };

            $scope.saveMenu = () => {
                $scope.$close($scope.menus.menuToCopyFrom);
            };
        }]);
};
