'use strict';


module.exports = function (freshideasSystem) {

    freshideasSystem.factory('SystemServerService', [
        '$rootScope',
        '$timeout',
        'SystemService',
        '$translate',
        'CommonOfflineCache',
        function ($rootScope, $timeout, SystemService, $translate, CommonOfflineCache) {
            var MESSAGE_STATUS = {
                HIDDEN: 0,
                MINIMIZED: 1,
                SHOWN: 2
            };
            // internal message status
            var messageStatus = MESSAGE_STATUS.HIDDEN;
            var billingErrorMsg = null;

            var newestServerRefresh = 0;
            var onSystemStatusUpdated = function (event, status) {
                if (status.hasOwnProperty('billingError')) {
                    billingErrorMsg = status.billingError || $translate.instant('smb.pos.billingError');
                } else {
                    billingErrorMsg = null;
                }

                $rootScope.$broadcast('system.billingError', billingErrorMsg);

                newestServerRefresh = status.lastStart;
                verifyServerRefresh();

            };
            $rootScope.$on('system.status', onSystemStatusUpdated);

            const verifyServerRefresh = async () => {
                const lastClientRefresh = await CommonOfflineCache.getLastStart() || 0;
                if (newestServerRefresh && newestServerRefresh > lastClientRefresh) {
                    await CommonOfflineCache.saveLastStart(newestServerRefresh);
                    showMessage();
                }

                refreshMessage();
            };

            $rootScope.$on('system.canShowMessage', verifyServerRefresh);

            var refreshMessage = function () {
                if (SystemService.canShowMessage) {
                    server.messageStatus = messageStatus;
                } else {
                    server.messageStatus = 0;
                }
            };

            var hideMessage = function () {
                messageStatus = MESSAGE_STATUS.HIDDEN;
                refreshMessage();

                stopCountdown();
            };
            var minimizeMessage = function () {
                messageStatus = MESSAGE_STATUS.MINIMIZED;
                refreshMessage();

                startCountdown();
            };
            var showMessage = function () {
                messageStatus = MESSAGE_STATUS.SHOWN;
                refreshMessage();

                startCountdown();
            };

            var countdown;
            var stopCountdown = function () {
                $timeout.cancel(countdown);
                server.countdownCounter = 0;
            };
            var startCountdown = function (wait) {
                if (countdown) {
                    return;
                }

                wait = wait || 60; // by default 60 seconds

                server.countdownCounter = wait;
                runCountdown();
            };
            var runCountdown = function () {
                countdown = $timeout(_decrementCountdown, 1000);
            };
            var _decrementCountdown = function () {
                if (SystemService.canShowMessage) {
                    server.countdownCounter--;
                }

                if (server.countdownCounter > 0) {
                    runCountdown();

                    if (server.countdownCounter % 15 === 0) {
                        showMessage();
                    }
                } else {
                    $timeout.cancel(countdown);
                    location.reload(true);
                }
            };


            var server = {
                messageStatus: 0,
                hideMessage: hideMessage,
                minimizeMessage: minimizeMessage,
                showMessage: showMessage,
                countdownCounter: 0,
                refreshTerminal: function () {
                    if (SystemService.isWebkit) {
                        var message = {
                            type: 'refreshPage'
                        };
                        window.webkit.messageHandlers.bridge.postMessage(message);
                    } else {
                        location.reload(true);
                    }
                }
            };

            return server;
        }
    ]);

    freshideasSystem.controller('SystemServerCtrl', [
        '$scope',
        'SystemService',
        'SystemServerService',
        function ($scope, SystemService, SystemServerService) {

            $scope.server = SystemServerService;

            $scope.refreshTerminal = function () {
                if (SystemService.isWebkit) {
                    var message = {
                        type: 'refreshPage'
                    };
                    window.webkit.messageHandlers.bridge.postMessage(message);
                } else {
                    location.reload(true);
                }
            };

            $scope.wait = function () {
                SystemServerService.minimizeMessage();

                SystemServerService.countdownCounter = Math.max(SystemServerService.countdownCounter, 15);
            };
            $scope.maximize = function () {
                SystemServerService.showMessage();
            };
        }
    ]);

};
