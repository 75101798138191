'use strict';

const Decimal = require('decimal.js').default;

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosReceiptCtrl', [
        '$scope',
        '$rootScope',
        'CashierShift',
        'SmbPosService',
        'KioskService',
        'KioskModalService',
        'PosStatusService',
        'GatewayFiit',
        '$translate',
        'CurrentSession',
        'CompanyAttributesService',
        'SharedDataService',
        'SharedFunctionService',
        function ($scope, $rootScope, CashierShift, SmbPosService, KioskService, KioskModalService, PosStatusService, GatewayFiit, $translate, CurrentSession, CompanyAttributesService, SharedDataService, SharedFunctionService) {

            const getTipAmount = (tipMultiplier) => {
                const subTotal = new Decimal($scope.posData.currentOrderBalance.subTotalAmount);
                const tip = subTotal.times(new Decimal(tipMultiplier));
                return Number(tip.toNearest(SharedDataService.baseDollar));
            };

            const calculateTipOptions = () => {
                $scope.tipOptions = {
                    'NOTIP': {'label': 'No Tip', 'value': 0},
                    'TIP10': {'label': '10%', 'value': getTipAmount(0.1)},
                    'TIP15': {'label': '15%', 'value': getTipAmount(0.15)},
                    'TIP20': {'label': '20%', 'value': getTipAmount(0.2)}
                };
            };

            calculateTipOptions();

            if (KioskService.isKiosk()) {
                $scope.isLoyalty = (!!$scope.patron && !!$scope.patron.patronId);
                // In the kiosk, you can only edit the items in the checkout page.
                // This controller needs to know whenever the receipt is edited.
                $scope.$on('kiosk::items-modified', function () {
                    $scope.calculateModifierSubtotals();
                    calculateTipOptions();
                });

                $scope.$on('kiosk::go-home', function () {
                    $scope.$dismiss();
                });

                // Include modifier subtotals in the item subtotal
                $scope.calculateModifierSubtotals = function () {
                    $scope.modifierSubtotals = {};
                    $scope.receipt.forEach(function (item) {
                        if (item.level !== 0 && (['discount', 'loyalty', 'attachment'].indexOf(item.subtype) === -1)) {
                            if (!$scope.modifierSubtotals[item.index]) {
                                $scope.modifierSubtotals[item.index] = 0;
                            }
                            $scope.modifierSubtotals[item.index] += item.price;
                        }
                    });
                };

                KioskService.resetTipAmount(); // reset tip amount in Kiosk service
                $scope.selectTip = (tipOption) => {
                    let tipAmount = 0;

                    if ($scope.selectedTip && $scope.selectedTip === tipOption) {
                        // unselect tip
                        $scope.selectedTip = null;
                    } else {
                        $scope.selectedTip = tipOption;
                        tipAmount = tipOption.value;
                    }

                    KioskService.setTipAmountInDollars($scope.adjustments, $scope.tenderAmounts.transactionUuid, tipAmount);
                };

                $scope.calculateModifierSubtotals();
                $scope.appName = CurrentSession.getOrganizationAppName();
                $scope.showScanAppOnKiosk = CompanyAttributesService.showScanAppOnKiosk();
                $scope.allowKioskTipping = CompanyAttributesService.allowKioskTipping();
            }

            $scope.isGatewayFiitEnabled = GatewayFiit.isEnabled();


            $scope.isOffline = function () {
                return PosStatusService.isOffline();
            };

            $scope.$watch(function () {
                return $scope.receipt.length;
            }, function (length) {
                if (length < 1) {
                    $scope.tryExit();
                }
            });

            $scope.removeReceiptItem = function (receiptItem) {
                var modalParams = {
                    title: 'Are you sure you want to remove this item from your cart?',
                    modalCallback: function () {
                        $scope.$parent.removeReceiptItem(receiptItem);
                    }
                };
                KioskModalService.showModalByName('confirmation', modalParams);
            };

            $scope.modifyItemQuantity = function (receiptItem, option) {
                $scope.$parent.modifyItemQuantity(receiptItem, option);
            };

            $scope.showEmailForm = function () {
                $scope.view = 'email';
            };
            $scope.showReceipt = function () {
                $scope.view = 'receipt';
            };

            $scope.onScan = (qrCode) => {
                if (KioskService.isKiosk() && !$scope.isLoyalty) {
                    addPatronToOrder(qrCode);
                }
            };

            const addPatronToOrder = (qrCode) => {
                $scope.loadingPatron = true;
                let showErrorModal = false;

                SmbPosService.fetchLucovaPatron(qrCode)
                .then(async (patrons) => {
                    if (!patrons || !patrons.length) {
                        showErrorModal = true;
                        return;
                    }

                    const patron = patrons[0];

                    if (!patron.lucovaUser) {
                        showErrorModal = true;
                        return;
                    }

                    const fiitPatron = await SmbPosService.linkFiitPatron(patron.lucovaUser);
                    let patronObj = {
                        lucovaUser: angular.copy(patron.lucovaUser),
                        fiitPatron: angular.copy(fiitPatron),
                        isNearby: patron.isNearby
                    };

                    if (patron.lucovaUser.nownGiftCards
                        && patron.lucovaUser.nownGiftCards.length > 0) {
                        patronObj.lucovaUser.nownGiftCards = patron.lucovaUser.nownGiftCards;
                    }

                    patronObj = SmbPosService.transformPatron(patronObj);

                    $scope.patron = patronObj;
                    $scope.isLoyalty = true;
                    $rootScope.$broadcast('kiosk::patron-added', patronObj);
                })
                .catch(() => {
                    showErrorModal = true;
                })
                .finally(() => {
                    $scope.loadingPatron = false;

                    if (showErrorModal) {
                        var kioskModalParams = {
                            title: $translate.instant('general.error.oops.ttl'),
                            subtitle: $translate.instant('kiosk.error.invalidAppCodeCheckout'),
                            timeOut: 5
                        };
                        KioskModalService.showModalByName('alert', kioskModalParams);
                    }
                });
            };
        }
    ]);
};
