'use strict';

const freshideasPatronsLoyalty = angular.module('freshideas.customersLoyalty', [])
.controller('CustomersLoyaltyCtrl',
    ['$scope',
    'Organization',
    'CurrentSession',
    'SharedDataService',
    'SmbPosService',
    'Menu',
    '$modal',
    'PosAlertService',
    '$timeout',
    'FileUploader',
    function CustomersLoyaltyCtrl (
        $scope,
        Organization,
        CurrentSession,
        SharedDataService,
        SmbPosService,
        Menu,
        $modal,
        PosAlertService,
        $timeout,
        FileUploader) {

            const MAX_STEPS = $scope.MAX_STEPS = 5;
            const LOYALTY_STEP_IMAGE_UPLOAD_URL = '/freshideas/web/organization/upload/loyalty-step-image';
            const IMAGE_WIDTH = 800; // px
            const IMAGE_HEIGHT = 500; // px

            $scope.isRootCompany = CurrentSession.isRootCompany();
            $scope.selectedMenu = {};
            let loyaltyStepItemsMap = {};
            $scope.savingChanges = false;
            $scope.wereSettingsUpdated = false;
            $scope.loyaltyStepImagesToUpload = {};

            const settingsUpdated = () => {
                $scope.wereSettingsUpdated = true;
                $timeout(() => {
                    $scope.wereSettingsUpdated = false;
                }, 2000);
            };

            const assignItemsToSteps = () => {
                $scope.loyaltySteps.forEach((step) => {
                    step.items = loyaltyStepItemsMap[step.id] || [];
                });
            };

            const disableAllEdits = () => {
                $scope.loyaltySteps.forEach((step) => {
                    step.canEdit = false;
                });
            };

            const getItems = () => {
                return Menu.getLoyaltyStepItems({
                    menuId: $scope.selectedMenu.menuId,
                    languageId: SharedDataService.preferredLanguage.languageId,
                    cacheTimestamp: 0
                }).$promise;
            };

            $scope.reloadItems = () => {
                $scope.savingChanges = true;
                getItems()
                .then((map)=> {
                    loyaltyStepItemsMap = map;
                    assignItemsToSteps();
                })
                .finally(() => $scope.savingChanges = false);
            };

            $scope.addStep = () => {
              if ($scope.loyaltySteps >= MAX_STEPS) return;
              disableAllEdits();
              $scope.loyaltySteps.push({pointsRequired: 0, enabled: true, canEdit: true, items: []});
            };

            $scope.editStep = (step) => {
                const currentlyCanEdit = step.canEdit;
                disableAllEdits();

                if (!CurrentSession.isRootCompany()) {
                    return;
                }

                step.canEdit = !currentlyCanEdit;
            };

            $scope.showItems = (step) => {
                $modal.open({
                    templateUrl: 'common/modals/modalLoyaltyStepItems.tpl.html',
                    animation: true,
                    backdrop: true,
                    controller: [
                        '$scope',
                        'items',
                        'pointsRequired',
                        function ($scope, items, pointsRequired) {
                            $scope.items = items;
                            $scope.pointsRequired = pointsRequired;
                        }],
                    resolve: {
                        items: () => step.items,
                        pointsRequired: () => step.pointsRequired
                    }
                });
            };

            const createLoyaltyStepImageUploader = () => {
                const uploaderConfig = {url: LOYALTY_STEP_IMAGE_UPLOAD_URL, removeAfterUpload: true};
                const uploader = new FileUploader(uploaderConfig);

                uploader.filters.push({
                    name: 'imageFilter',
                    fn: function (item) {
                        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                        return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
                    }
                });
                return uploader;
            };
            const loyaltyStepImageUploader = $scope.loyaltyStepImageUploader = createLoyaltyStepImageUploader();

            loyaltyStepImageUploader.onAfterAddingFile = function (fileItem) {
                const loyaltyStepId = fileItem.loyaltyStepId;

                // check image size
                const uploadedImg = new Image();
                uploadedImg.src = URL.createObjectURL(fileItem._file);
                uploadedImg.onload = () => {
                    $timeout(() => {
                        if (uploadedImg.width !== IMAGE_WIDTH || uploadedImg.height !== IMAGE_HEIGHT) {
                            loyaltyStepImageUploader.removeFromQueue(fileItem);
                            PosAlertService.showAlertByName('loyalty-step-image-wrong-dimensions');
                        } else {
                            $scope.loyaltyStepImagesToUpload[loyaltyStepId] = {src: uploadedImg.src, fileItem};

                            // remove previously selected images for the same step
                            const prvImageExists = loyaltyStepImageUploader.queue.filter((item) => item.loyaltyStepId === loyaltyStepId).length > 1;
                            if (prvImageExists) {
                                const prvImage = loyaltyStepImageUploader.queue.find((item) => item.loyaltyStepId === loyaltyStepId);
                                loyaltyStepImageUploader.removeFromQueue(prvImage);
                            }

                            // this will prevent the backend from deleting the old image before the new one is uploaded
                            const step = $scope.loyaltySteps.find((step) => step.id === loyaltyStepId);
                            step.imageURL = step.originalImageURL;
                        }
                    }, 0);
                };
            };

            loyaltyStepImageUploader.onWhenAddingFileFailed = function () {
                PosAlertService.showAlertByName('general-error', {message: 'general.error.image-upload-type-error.msg'});
            };

            loyaltyStepImageUploader.onCompleteItem = function (fileItem, response, status, headers) {
                if (status < 400) {
                    const step = $scope.loyaltySteps.find((step) => step.id === response.id);
                    step.imageURL = response.imageURL;
                    delete $scope.loyaltyStepImagesToUpload[response.id];
                }
            };

            loyaltyStepImageUploader.onBeforeUploadItem = function (item) {
                item.formData.push({
                    loyaltyStepId: item.loyaltyStepId,
                });
            };

            loyaltyStepImageUploader.onCompleteAll = () => {
                settingsUpdated();
            };

            loyaltyStepImageUploader.onErrorItem = (fileItem, response, status, headers) => {
                /**
                 * 413: Entity too large
                 */
                if (status == 413) {
                    PosAlertService.showAlertByName('file-upload-failed-large-file');
                } else {
                    PosAlertService.showAlertByName('general-error');
                }

            };

            $scope.undoImageUpload = (stepId) => {
                if (!$scope.loyaltyStepImagesToUpload[stepId]) return;
                loyaltyStepImageUploader.removeFromQueue($scope.loyaltyStepImagesToUpload[stepId].fileItem);
                delete $scope.loyaltyStepImagesToUpload[stepId];
            };

            $scope.clearSavedImage = (step) => {
                if (!step) return;
                step.imageURL = null;
            };

            $scope.restoreOriginalImage = (step) => {
                if (!step) return;
                step.imageURL = step.originalImageURL;
            };

            $scope.updateSteps = () => {
                if (!$scope.loyaltySteps || !$scope.loyaltySteps.length || $scope.loyaltySteps.length > MAX_STEPS) return;

                $scope.savingChanges = true;
                Organization.updateLoyaltySteps($scope.loyaltySteps).$promise
                .then((response) => {
                    $scope.loyaltySteps = response || [];
                    assignItemsToSteps();

                    if (loyaltyStepImageUploader.queue.length) {
                        loyaltyStepImageUploader.uploadAll();
                    } else {
                        settingsUpdated();
                    }
                })
                .catch((err) => {
                    let message = null;

                    // duplicate points
                    if (err.status === 409) {
                        message = 'patrons.loyalty.page.error.duplicate';
                    }

                    PosAlertService.showAlertByName('oops-general', {
                        message
                    }, true);
                })
                .finally(() => {
                    $scope.savingChanges = false;
                    disableAllEdits();
                });
            };

            $scope.init = () => {
                $timeout(async () => {
                    $scope.loyaltySteps = await Organization.getLoyaltySteps({organizationId: CurrentSession.getCompany().organizationId}).$promise || [];
                    $scope.loyaltySteps.forEach((step) => step.originalImageURL = step.imageURL);
                    $scope.menus = await SmbPosService.loadFormattedMenus({getAllMenusByOrganization: true}) || [];
                }, 0);
            };

            $scope.init();
        }
    ]
);

export default freshideasPatronsLoyalty;
