'use strict';

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('KeyBindingCtrl', ['$scope', '$modal', 'bindings',
        function ($scope, $modal, bindings) {
            // bindings must have shape {'ACTION_NAME': {label: string, defaultBind: KeyboardEvent.code, customBind: KeyboardEvent.code}, ...}
            $scope.bindings = bindings;
            const keysInUse = new Set(Object.values($scope.bindings).map((action) => action.customBind || action.defaultBind));

            $scope.changeBind = (action) => {
                if (!action || !$scope.bindings[action]) return;

                const modalInstance = $modal.open({
                    template: `
                        <div class="close-button" ng-click="$close()">
                            <i class="fa fa-close"></i>
                        </div>
                        <h3 style="display: flex; justify-content: center; padding: 32px">
                            <div ng-if="!keyInUse">{{'keyBind.pressAnyKey' | translate}}</div>
                            <div ng-if="keyInUse">{{'keyBind.keyInUse' | translate}}</div>
                        </h3>
                    `,
                    controller: ['$scope', 'currentKeyCode', function ($scope, currentKeyCode) {
                        const recordKey = (event) => {
                            event.preventDefault();
                            event.stopPropagation();

                            if (event.code === 'Escape') {
                                $scope.$close();
                                return;
                            }

                            if (keysInUse.has(event.code)) {
                                $scope.keyInUse = true;
                                $scope.$apply();
                            } else {
                                keysInUse.delete(currentKeyCode);
                                keysInUse.add(event.code);
                                $scope.$close(event.code);
                            }
                        };

                        document.addEventListener('keydown', recordKey);
                        $scope.$on('$destroy', function () {
                            document.removeEventListener('keydown', recordKey);
                        });
                    }],
                    animation: false,
                    resolve: {currentKeyCode: () => $scope.bindings[action].customBind || $scope.bindings[action].defaultBind},
                    backdrop: true
                });

                modalInstance.result.then(function (code) {
                    if (!code) {
                        return;
                    }

                    if (code === $scope.bindings[action].defaultBind) {
                        delete $scope.bindings[action].customBind;
                        return;
                    }

                    $scope.bindings[action].customBind = code;
                });
            };

            $scope.resetAllBinds = () => {
                keysInUse.clear();
                Object.values($scope.bindings).forEach((action) => {
                    delete action.customBind;
                    keysInUse.add(action.defaultBind);
                });
            };
        }
    ]);
};
