'use strict';

const angular = require('angular');
const moment = require('moment');

module.exports = function (freshideasReports) {
    freshideasReports.controller('PatronActivityReportCtrl', [
        '$scope',
        '$timeout',
        'Reports',
        'Export',
        'Lookup',
        'Meals',
        'Security',
        'Calculate',
        function ($scope, $timeout, Reports, Export, Lookup, Meals, Security, Calculate) {
            var refreshLocations = function (companies) {
                if (companies) {
                    $scope.locations = _.flatten(_.map(companies, function (company) {
                        return company.children;
                    }), true);
                }
            };
            var refreshMealPeriods = function (locations) {
                if (locations) {
                    $scope.mealPeriods = _.flatten(_.map(locations, function (location) {
                        return _.filter(location.children, function (child) {
                            return child.type === 'mealPeriod';
                        });
                    }), true);
                }
            };
            var tick = function () {
                $scope.currentDateTime = moment();
                $timeout(tick, 60 * 1000);
            };
            $timeout(tick, 60 * 1000);
            $scope.$watch('reportSearch.companyId', function (companyId) {
                if (companyId) {
                    var foundCompany = _.find($scope.companyHierarchy, function (company) {
                        return company.id === companyId;
                    });
                    if (foundCompany) {
                        refreshLocations([foundCompany]);
                    }
                } else {
                    var companyHierarchy = angular.copy($scope.companyHierarchy);
                    refreshLocations(companyHierarchy);
                }
            });
            $scope.$watch('reportSearch.locationId', function (locationId) {
                if (locationId) {
                    var foundLocation;
                    _.each($scope.companyHierarchy, function (company) {
                        var l = _.find(company.children, function (location) {
                            return location.id === locationId;
                        });
                        if (l) {
                            foundLocation = l;
                        }
                    });
                    if (foundLocation) {
                        refreshMealPeriods([foundLocation]);
                    }
                } else {
                    refreshMealPeriods($scope.locations);
                }
            });
            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };
            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };
            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };
            $scope.clearFilters = function (isAdvancedSearch) {
                $scope.reportSearch = {isAdvancedSearch: !!isAdvancedSearch};
            };
            $scope.clearFilters();
            $scope.lookupEntity = function (type, id) {
                if (!$scope.baseSettings) {
                    return;
                }
                var entities = $scope.baseSettings[type] || [];
                var foundEntity = _.find(entities, function (entity) {
                    return entity.id === id;
                }) || {};
                return foundEntity.name || '';
            };
            $scope.lookupMealPlan = function (id) {
                if (!$scope.mealPlans) {
                    return;
                }
                var foundMealPlan = _.find($scope.mealPlans, function (mealPlan) {
                    return mealPlan.mealPlanId === id;
                }) || {};
                return foundMealPlan.name || '';
            };
            $scope.lookupCompanyHierarchy = function () {
                Lookup.companyHierarchy({}, function (response) {
                    $scope.companyHierarchy = response;
                    $scope.baseSettings = {};
                    $scope.baseSettings.companies = $scope.companyHierarchy;
                    $scope.baseSettings.locations = [];
                    _.each($scope.baseSettings.companies, function (company) {
                        var locations = company.children || [];
                        $scope.baseSettings.locations = $scope.baseSettings.locations.concat(locations);
                    });
                    $scope.baseSettings.mealPeriods = [];
                    $scope.baseSettings.poses = [];
                    _.each($scope.baseSettings.locations, function (location) {
                        _.each(location.children, function (child) {
                            if (child.type === 'mealPeriod') {
                                $scope.baseSettings.mealPeriods.push(child);
                            } else if (child.type === 'pos') {
                                $scope.baseSettings.poses.push(child);
                            }
                        });
                    });
                    var companyHierarchy = angular.copy($scope.companyHierarchy);
                    $scope.companies = companyHierarchy;
                    refreshLocations($scope.companies);
                    refreshMealPeriods($scope.locations);
                    Meals.getMealPlans({}, function (response) {
                        $scope.mealPlans = response.entries;
                        $scope.getPatronActivity();
                    });
                });
            };
            var convertTransactions = function (transactions) {
                var transactionActivities = [];
                _.each(transactions, function (transaction) {
                    var datetime;
                    if (transaction.timestamp) {
                        datetime = moment(transaction.timestamp).format('YYYY-MM-DD HH:mm');
                    } else {
                        datetime = '';
                    }

                    var activity = {
                        datetime: datetime,
                        cashAmount: transaction.cashAmount,
                        mealPlanCount: transaction.mealPlanCount,
                        dcbAmount: transaction.dcbAmount,
                        chargeAmount: transaction.chargeAmount,
                        creditCardAmount: transaction.creditCardAmount,
                        debitCardAmount: transaction.debitCardAmount,
                        otherAmount: transaction.otherAmount,
                        taxAmount: transaction.taxAmount,
                        locationName: transaction.locationName,
                        type: 'transaction'
                    };
                    transactionActivities.push(activity);
                });

                return transactionActivities;
            };
            var convertDepositRefunds = function (depositRefundActivities) {
                var depositActivities = [];
                var refundActivities = [];

                _.each(depositRefundActivities, function (depositRefundActivity) {
                    var activity = {
                        datetime: moment(depositRefundActivity.eventTime).format('YYYY-MM-DD HH:mm'),
                        cashAmount: 0,
                        mealPlanCount: 0,
                        dcbAmount: 0,
                        chargeAmount: 0,
                        creditCardAmount: 0,
                        otherAmount: 0,
                        taxAmount: 0,
                        locationName: depositRefundActivity.eventType,
                        type: (depositRefundActivity.eventType === 'DEPOS')? 'deposit' : 'refund',
                        mealPlanId: depositRefundActivity.mealPlanId
                    };

                    var depositRefundFactor = (depositRefundActivity.eventType === 'DEPOS')? 1 : -1;
                    switch (depositRefundActivity.planType) {
                        case 'MEAL':
                            activity.mealPlanCount = depositRefundActivity.mealBalanceChange;
                            break;
                        case 'DCB':
                            activity.dcbAmount = depositRefundFactor * depositRefundActivity.dcbBalanceChange * 100;
                            break;
                        case 'ICB':
                            activity.icbAmount = depositRefundFactor * depositRefundActivity.icbBalanceChange * 100;
                            break;
                    }

                    switch (depositRefundActivity.eventType) {
                        case 'DEPOS':
                            activity.locationName = 'Meal Plan Deposit';
                            depositActivities.push(activity);
                            break;
                        case 'REFUND':
                            activity.locationName = 'Meal Plan Refund';
                            refundActivities.push(activity);
                            break;
                    }
                });

                return {
                    depositActivities: depositActivities,
                    refundActivities: refundActivities
                };
            };
            var computeActivityTotals = function (transactions, deposits, refunds) {
                var baseTotals = {
                    cashAmount: 0,
                    mealPlanCount: 0,
                    dcbAmount: 0,
                    chargeAmount: 0,
                    creditCardAmount: 0,
                    debitCardAmount: 0,
                    otherAmount: 0,
                    taxAmount: 0
                };
                var transactionTotals = angular.copy(baseTotals);
                var depositTotals = angular.copy(baseTotals);
                var refundTotals = angular.copy(baseTotals);

                _.each(transactions, function (transaction) {
                    transactionTotals.cashAmount += transaction.cashAmount || 0;
                    transactionTotals.mealPlanCount += transaction.mealPlanCount || 0;
                    transactionTotals.dcbAmount += transaction.dcbAmount || 0;
                    transactionTotals.chargeAmount += transaction.chargeAmount || 0;
                    transactionTotals.creditCardAmount += transaction.creditCardAmount || 0;
                    transactionTotals.debitCardAmount += transaction.debitCardAmount || 0;
                    transactionTotals.otherAmount += transaction.otherAmount || 0;
                    transactionTotals.taxAmount += transaction.taxAmount || 0;
                });
                _.each(deposits, function (deposit) {
                    depositTotals.cashAmount += deposit.cashAmount || 0;
                    depositTotals.mealPlanCount += deposit.mealPlanCount || 0;
                    depositTotals.dcbAmount += deposit.dcbAmount || 0;
                    depositTotals.chargeAmount += deposit.chargeAmount || 0;
                    depositTotals.creditCardAmount += deposit.creditCardAmount || 0;
                    depositTotals.otherAmount += deposit.otherAmount || 0;
                    depositTotals.taxAmount += deposit.taxAmount || 0;
                });
                _.each(refunds, function (refund) {
                    refundTotals.cashAmount += refund.cashAmount || 0;
                    refundTotals.mealPlanCount += refund.mealPlanCount || 0;
                    refundTotals.dcbAmount += refund.dcbAmount || 0;
                    refundTotals.chargeAmount += refund.chargeAmount || 0;
                    refundTotals.creditCardAmount += refund.creditCardAmount || 0;
                    refundTotals.otherAmount += refund.otherAmount || 0;
                    refundTotals.taxAmount += refund.taxAmount || 0;

                    transactionTotals.cashAmount += refund.cashAmount || 0;
                    transactionTotals.mealPlanCount += refund.mealPlanCount || 0;
                    transactionTotals.dcbAmount += refund.dcbAmount || 0;
                    transactionTotals.chargeAmount += refund.chargeAmount || 0;
                    transactionTotals.creditCardAmount += refund.creditCardAmount || 0;
                    transactionTotals.otherAmount += refund.otherAmount || 0;
                    transactionTotals.taxAmount += refund.taxAmount || 0;
                });

                return {
                    transactionTotals: transactionTotals,
                    depositTotals: depositTotals,
                    refundTotals: refundTotals
                };
            };
            var parseMealPlans = function (mealPlans, depositRefunds) {
                _.each(mealPlans, function (mealPlan) {
                    mealPlan.mealPlanId = mealPlan.mealPlanID;
                    delete mealPlan.mealPlanID;

                    // populate missing meal plan type
                    var foundMealPlan = _.find($scope.mealPlans, function (mp) {
                        return mp.mealPlanId === mealPlan.mealPlanId;
                    });
                    if (foundMealPlan) {
                        mealPlan.mealPlanType = foundMealPlan.mealPlanType;
                        mealPlan.mealPlanName = foundMealPlan.name;
                    }

                    mealPlan.depositChange = 0;
                    mealPlan.refundChange = 0;
                    var mealPlanDepositRefunds = _.filter(depositRefunds, function (depositRefund) {
                        return depositRefund.mealPlanId === mealPlan.mealPlanId;
                    });

                    var depositRefundFields = {
                        'MEAL': 'mealBalanceChange',
                        'DCB': 'dcbBalanceChange',
                        'ICB': 'icbBalanceChange'
                    };

                    _.each(mealPlanDepositRefunds, function (mealPlanDepositRefund) {
                        var depositRefundField = depositRefundFields[mealPlanDepositRefund.planType];
                        var unitFactor = (depositRefundField === 'MEAL')? 1 : 100;

                        if (depositRefundField) {
                            switch (mealPlanDepositRefund.eventType) {
                                case 'DEPOS':
                                    mealPlan.depositChange += mealPlanDepositRefund[depositRefundField] * unitFactor;
                                    break;
                                case 'REFUND':
                                    mealPlan.refundChange += mealPlanDepositRefund[depositRefundField] * unitFactor;
                                    break;
                            }
                        }
                    });
                });


            };
            var convertReport = function (report, depositRefunds) {
                report.transactionActivities = [];
                report.depositActivities = [];

                report.transactionActivities = convertTransactions(report.transactions);

                var depositRefundActivities = convertDepositRefunds(depositRefunds);
                report.depositActivities = depositRefundActivities.depositActivities;
                report.refundActivities = depositRefundActivities.refundActivities;

                var actitivyTotals = computeActivityTotals(report.transactionActivities, report.depositActivities, report.refundActivities);
                report.transactionTotals = actitivyTotals.transactionTotals;
                report.refundTotals = actitivyTotals.refundTotals;
                report.depositTotals = actitivyTotals.depositTotals;

                parseMealPlans(report.mealPlans, depositRefunds);

                report.transactionActivities = report.transactionActivities.concat(report.refundActivities);

                return report;
            };
            $scope.getPatronActivity = function (isAdvancedSearch) {
                var reportSearch = {};
                reportSearch.patronKey = $scope.reportSearch.patronKey;
                if (reportSearch.patronKey) {
                    reportSearch.startDateTime = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                    reportSearch.endDateTime = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                    if ($scope.reportSearch.mealPeriodId) {
                        reportSearch.servicePeriodId = $scope.reportSearch.mealPeriodId;
                    }
                    if ($scope.reportSearch.mealPlanId) {
                        reportSearch.mealPlanId = $scope.reportSearch.mealPlanId;
                    }
                    Reports.getPatronActivity(reportSearch, function (response) {
                        var patronSummary = response.patronSummary || {
                            mealPlans: [],
                            transactions: [],
                            patronInfo: {}
                        };

                        var depositRefundSearch = {
                            startDateTime: reportSearch.startDateTime,
                            endDateTime: reportSearch.endDateTime,
                            offset: 0,
                            limit: 0,
                            sortBy: 'eventTime',
                            ascending: false,
                            patronKey: reportSearch.patronKey
                        };
                        Reports.getDepositRefundReport(depositRefundSearch, function (result) {
                            var depositRefundActivities = result.entries;

                            $scope.result = convertReport(patronSummary, depositRefundActivities);

                            var currentReportSearch = {
                                patronKey: $scope.reportSearch.patronKey,
                                fromDate: $scope.reportSearch.fromDate,
                                toDate: $scope.reportSearch.toDate,
                                mealPeriodName: $scope.lookupEntity('mealPeriods', $scope.reportSearch.mealPeriodId),
                                mealPlanName: $scope.lookupMealPlan($scope.reportSearch.mealPlanId),
                                isAdvancedSearch: isAdvancedSearch
                            };
                            currentReportSearch = _.pick(currentReportSearch, function (value) {
                                return !!value;
                            });
                            if (_.isEmpty(currentReportSearch)) {
                                $scope.currentReportSearch = undefined;
                            } else {
                                $scope.currentReportSearch = currentReportSearch;
                            }
                            $scope.reportSearch = $scope.reportSearch || {};
                            $scope.reportSearch.isAdvancedSearch = isAdvancedSearch;
                        });
                    });
                } else {
                    $scope.currentReportSearch = undefined;
                    $scope.result = undefined;
                }
            };
            $scope.init = function () {
                $scope.reportSearch = {
                    fromDate: moment().startOf('week').toDate(),
                    toDate: moment().endOf('day').toDate(),
                    isAdvancedSearch: true
                };
                $scope.lookupCompanyHierarchy();
            };
            $scope.init();
            $scope.exportToPdf = function (tableId) {
                Export.tableToPdf(tableId, 'portrait');
            };
        }
    ]);
};
