'use strict';

const angular = require('angular');
const ngResources = require('angular-resource');

export default angular.module('freshideas.resources.organization', [ngResources])
    .service('Organization', ['$resource', 'EnvConfig', function ($resource, EnvConfig) {
        return $resource(EnvConfig.host + '/freshideas/web/organization', {}, {
            'getAllCurrenciesForOrganization': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/organization/currency/list',
                isArray: true
            },
            'addCurrencyToOrganization': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/organization/currency',
                isArray: false
            },
            'updateCurrencyAndLanguage': {
                method: 'PUT',
                url: EnvConfig.host + '/freshideas/web/organization/currencyAndLanguage',
                isArray: false
            },
            'getOrganizationSettings': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/organization/settings',
                isArray: false
            },
            'updateOrganizationSettings': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/organization/settings',
                isArray: false
            },
            'updateOrCreateOffer': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/organization/offer',
                isArray: false
            },
            'updateOrCreateOfferAvailabilityPeriod': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/organization/offer/availability',
                isArray: false
            },
            'getOfferById': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/organization/offer',
                isArray: false
            },
            'getAllOffers': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/organization/offers',
                isArray: true
            },
            'getOfferStatistics': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/organization/offer/statistics',
                isArray: true
            },
            'getNewPatronCount': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/organization/offer/newPatronCount',
                responseType: 'text',
                transformResponse: (data) => {
                    return {newPatronCount: data};
                }
            },
            'getAllLanguagesForOrganization': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/organization/language/list',
                isArray: true
            },
            'addLanguageToOrganization': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/organization/language',
                isArray: false
            },
            'createOrUpdatePatronGroup': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/organization/patron-group',
                isArray: false
            },
            'getPatronGroups': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/organization/patron-group',
                isArray: true
            },
            'deletePatronGroup': {
                method: 'DELETE',
                url: EnvConfig.host + '/freshideas/web/organization/patron-group',
                isArray: false
            },
            'saveMealCard': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/organization/save-fiit-meal-card'
            },
            'getSavedMealCards': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/organization/saved-fiit-meal-card',
                isArray: true
            },
            'emailSavedMealCards': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/organization/email-saved-meal-card',
            },
            'getLoyaltySteps': {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/organization/loyalty-steps',
                isArray: true
            },
            'updateLoyaltySteps': {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/organization/loyalty-steps',
                isArray: true
            },
        });
    }]);
