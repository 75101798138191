'use strict';

const angular = require('angular');

module.exports = function (freshideasLocations) {

    freshideasLocations.controller('EditLocationPosStationCtrl', [
        '$scope',
        '$modalInstance',
        '$modal',
        '$log',
        '$translate',
        '$timeout',
        'Locations',
        'Menu',
        'location',
        'selectedCompanyId',
        'isWizardMode',
        'notificationTranslationHelper',
        'CompanyAttributesService',
        'ConfirmModalService',
        'PosStatusService',
        'PrintService',
        'PrinterModelService',
        'PosAlertService',
        'BridgedPromise',
        'ElectronPrinter',
        'Platform',
        'Users',
        'Lookup',
        'EnvConfig',
        '$http',
        'isMevBoxCompany',
        'srmDevices',
        'REQUEST_STATES',
        'Pure',
        'Security',
        'USER_ROLE_TYPE',
        function (
            $scope,
            $modalInstance,
            $modal,
            $log,
            $translate,
            $timeout,
            Locations,
            Menu,
            location,
            selectedCompanyId,
            isWizardMode,
            notificationTranslationHelper,
            CompanyAttributesService,
            ConfirmModalService,
            PosStatusService,
            PrintService,
            PrinterModelService,
            PosAlertService,
            BridgedPromise,
            ElectronPrinter,
            Platform,
            Users,
            Lookup,
            EnvConfig,
            $http,
            isMevBoxCompany,
            srmDevices,
            REQUEST_STATES,
            Pure,
            Security,
            USER_ROLE_TYPE) {

            $scope.isElectron = Platform.isElectron();
            $scope.isIosWebkit = Platform.isIosWebkit();

            // Import supported manufacturers and printers
            $scope.tcpIpManufacturerList = PrinterModelService.tcpIpManufacturers;
            $scope.bluetoothManufacturerList = PrinterModelService.bluetoothManufacturers;
            $scope.usbManufacturerList = PrinterModelService.usbManufacturers;
            // possible tspl settings
            $scope.tsplFontSizesList = ['Small', 'Medium', 'Large'];
            $scope.tsplDirectionsList = ['Normal', 'Inverted'];

            $scope.toShow = {};
            $scope.toShow.tcpIpEntry = false;

            $scope.REQUEST_STATES = REQUEST_STATES;

            $scope.PosStatusService = PosStatusService;
            $scope.location = location;
            $scope.addTcpIpForm = {
                ipAddress: '',
                port: null,
                error: false
            };

            $scope.inputTypes = {
                TCPIP: 'tcpIp'
            };

            $scope.isMevBoxCompany = isMevBoxCompany;

            $scope.openNewTabOauth = function (selectedTerminal) {

                if ($scope.selectedPosStation.cardTerminalProperties.type == 'clover_cloud') {

                    var host = EnvConfig.env === 'production' ? 'www.clover.com' : 'sandbox.dev.clover.com';
                    var nownCloverId = EnvConfig.env === 'production' ? '06JDJAZBQ0N34' : '61FGVHGXG5BAG';

                    var url = 'https://' + host + '/oauth/authorize?client_id=' + nownCloverId;

                    // $scope.modal = 'autoSettle';
                    var modifyModal = $modal.open({
                        templateUrl: 'pos/smb/templates/ok.cancel.popup.tpl.html',
                        controller: 'OkCancelPopupCtrl',
                        windowClass: 'smb-pos__end-shift-auto-settle-modal',
                        animation: false,
                        backdrop: 'static',
                        resolve: {
                            message: function () {
                                return 'You will need to allow NownPOS semi-integrated on the Clover dashboard. Press OK to continue.';
                            }
                        }
                    }, function (error) {});


                    return modifyModal.result.then(function (response) {
                           window.open(url, '_blank');
                    }, function () {
                    });

                }
            };

            $scope.cardTerminalChanged = function () {
                var tmpCardPropObj = $scope.cardTerminalTypes.find(function (terminalTypeObj) {
                    return terminalTypeObj.type == $scope.selectedPosStation.cardTerminalProperties.type;
                });

                if (tmpCardPropObj) {
                    $scope.selectedPosStation.cardTerminalProperties.config = tmpCardPropObj.config;
                    $scope.selectedPosStation.cardTerminalProperties.name = tmpCardPropObj.name;
                    // make request to get list of devices
                    if ($scope.selectedPosStation.cardTerminalProperties.config == 'oauth') {
                        lookupCloverDevices($scope.selectedPosStation.cardTerminalProperties);
                    }
                }
            };


            var lookupCloverDevices = function (cardPropertiesObj) {
                return Lookup.companyAttributeSecure({key: 'clover_oauth_access_token'}).$promise.then(function (response) {
                    // curl https://sandbox.dev.clover.com/v3/merchants/CZAKV0HSPD901/devices\?access_token\=12bf280c-4480-e4ee-9685-22e5f548c4ff | pp_json
                    var merchantId = response.attributeValue.merchantId;
                    var accessToken = response.attributeValue.accessToken;

                    var host = EnvConfig.env === 'production' ? 'www.clover.com' : 'sandbox.dev.clover.com';
                    var url = 'https://'+host+'/v3/merchants/'+merchantId+'/devices?access_token=' + accessToken;

                    $scope.selectedPosStation.cardTerminalProperties.accessToken = accessToken;
                    $http.get(url).then(function (response) {
                        if (response && response.data && response.data.elements) {
                            $scope.availableCloverTerminals = response.data.elements.map((elem) => {
                                return {
                                    deviceId: elem.id,
                                    merchantId: elem.merchant.id,
                                    serial: elem.serial,
                                };
                            });

                            $scope.selectedPosStation.cardTerminalProperties.cloverConfig = $scope.availableCloverTerminals.find((elem) => {
                                return elem.deviceId == cardPropertiesObj.deviceId;
                            });
                        }

                    }, function (error) {

                    });
                }, function (error) {

                });
            };


            $scope.cardTerminalTypes = [
                {
                    name: 'Moneris (ICT 250)',
                    type: 'moneris',
                    config: 'ipport',
                },
                {
                    name: 'Moneris',
                    type: 'moneriscore',
                    config: 'ipport',
                },
                {
                    name: 'TD | Global Payments (Desk 5000)',
                    type: 'globalpay',
                    config: 'ipport',
                },
                {
                    name: 'Heartland',
                    type: 'heartland',
                    config: 'ipport',
                },
                {
                    name: 'First Data',
                    type: 'ingenico',
                    config: 'ipport',
                },
                {
                    name: 'Pax',
                    type: 'pax',
                    config: 'ipport',
                },
                {
                    name: 'Clover',
                    type: 'clover',
                    config: 'ipport',
                },
                {
                    name: 'Clover Cloud',
                    type: 'clover_cloud',
                    config: 'oauth',
                },
                {
                    name: 'Equinox-EPOS (TD Luxe)',
                    type: 'equinox-epos',
                    config: 'epos',
                },
                {
                    name: 'Manual',
                    type: 'manual',
                    config: 'manual',
                }
            ];

            let unassignPrinterIds = [];

            if (location.selectedPosStation) {
                $scope.selectedPosStation = location.selectedPosStation;

                // 1. Wipe printer IDs from posPrinters
                // This is because backend overwrites existing references when updating pos printers,
                // so it is better to treat every pos printer as a new object

                // 2. Assign select dropdowns to their correct values
                // (based on printerManufacturer and printerModel)
                if ($scope.selectedPosStation.posPrinters) {
                    for (var i = 0; i < $scope.selectedPosStation.posPrinters.length; i++) {
                        if ($scope.selectedPosStation.posPrinters[i].protocol === 'tcp') {
                            $scope.selectedPosStation.posPrinters[i].selectedManufacturer =
                                _.find($scope.tcpIpManufacturerList, {
                                    value: $scope.selectedPosStation.posPrinters[i].printerManufacturer
                                });
                        } else if ($scope.selectedPosStation.posPrinters[i].protocol === 'bt') {
                            $scope.selectedPosStation.posPrinters[i].selectedManufacturer =
                                _.find($scope.bluetoothManufacturerList, {
                                    value: $scope.selectedPosStation.posPrinters[i].printerManufacturer
                                });
                        } else if ($scope.selectedPosStation.posPrinters[i].protocol === 'usb') {
                            $scope.selectedPosStation.posPrinters[i].selectedManufacturer =
                                _.find($scope.usbManufacturerList, {
                                    value: $scope.selectedPosStation.posPrinters[i].printerManufacturer
                                });
                        }

                        if ($scope.selectedPosStation.posPrinters[i].selectedManufacturer) {
                            $scope.selectedPosStation.posPrinters[i].selectedModel =
                                _.find($scope.selectedPosStation.posPrinters[i].selectedManufacturer.printers, {
                                    value: $scope.selectedPosStation.posPrinters[i].printerModel
                                });
                        }
                    }
                }


               if ($scope.selectedPosStation.cardTerminalProperties) {
                    var tmpCardPropObj = $scope.cardTerminalTypes.find(function (terminalTypeObj) {
                        return terminalTypeObj.type == $scope.selectedPosStation.cardTerminalProperties.type;
                    });

                    if (tmpCardPropObj) {

                        $scope.selectedPosStation.cardTerminalProperties.name = tmpCardPropObj.name;
                        $scope.selectedPosStation.cardTerminalProperties.type = tmpCardPropObj.type;
                        $scope.selectedPosStation.cardTerminalProperties.config = tmpCardPropObj.config;

                        if (tmpCardPropObj.config == 'oauth') {
                            lookupCloverDevices($scope.selectedPosStation.cardTerminalProperties);
                        }
                    }
                }
            }

            $scope.srmDevices = srmDevices;
            $scope.selectedSrmDevice;
            $scope.srmDeviceChanged = (selectedSrmDevice) => {
                $scope.selectedSrmDevice = $scope.srmDevices.find((srmDevice) => {
                    return srmDevice.id == selectedSrmDevice.id;
                });
                $scope.selectedPosStation.locationSrmId = $scope.selectedSrmDevice.id;
            };

            $scope.init = function () {
                $scope.loadStations();
                loadUsers();

                const currentUser = Security.getUser() || {};
                $scope.isManager = currentUser.roleType === USER_ROLE_TYPE.MANAGER
                || currentUser.permission === 'SITEADMIN'
                || currentUser.permission === 'FULLADMIN';

                if ($scope.isMevBoxCompany) {
                    if ($scope.selectedPosStation.isNew) {
                        // set a default srm
                        $scope.selectedSrmDevice = _.find($scope.srmDevices, (srmDevice) => {
                            return srmDevice.defaultDevice;
                        });

                        $scope.selectedPosStation.locationSrmId = ($scope.selectedSrmDevice) ? $scope.selectedSrmDevice.id : null;
                    } else {
                        // fetch the assigned srm for this station
                        $scope.selectedSrmDevice = _.find($scope.srmDevices, (srmDevice) => {
                            return srmDevice.id === $scope.selectedPosStation.locationSrmId;
                        });
                    }
                }
            };

            // TCP/IP Printers
            $scope.addTcpIpPrinter = function () {
                if (Pure.ipTest($scope.addTcpIpForm.ipAddress)
                    && $scope.addTcpIpForm.port
                    && $scope.addTcpIpForm.port >= 0
                    && $scope.addTcpIpForm.port <= 65535) {
                    $scope.addTcpIpForm.error = false;
                    // Add TCP/IP printer to list
                    if (!$scope.selectedPosStation.posPrinters) {
                        $scope.selectedPosStation.posPrinters = [];
                    }
                    $scope.selectedPosStation.posPrinters.push({
                        printerIpAddress: $scope.addTcpIpForm.ipAddress,
                        printerPort: $scope.addTcpIpForm.port,
                        printerIpPort: $scope.addTcpIpForm.ipAddress + ':' + $scope.addTcpIpForm.port,
                        posStationId: $scope.selectedPosStation.posStationId,
                        locationId: $scope.location.locationId,
                        receiptPrinter: false,
                        kitchenPrinter: false,
                        defaultKitchenPrinter: false,
                        protocol: 'tcp'
                    });
                    $scope.toShow.tcpIpEntry = false;

                    // Reset form
                    $scope.addTcpIpForm.ipAddress = '';
                    $scope.addTcpIpForm.port = null;
                } else {
                    $scope.addTcpIpForm.error = true;
                }
            };

            $scope.deletePrinter = function (printer) {
                PosAlertService.showAlertByName('general-alert', {
                    title: 'general.confirmation.delete.printer.ttl',
                    modalCallback: () => {
                        let index = _.findIndex($scope.selectedPosStation.posPrinters, printer);
                        $scope.selectedPosStation.posPrinters.splice(index, 1);
                    }
                });
            };

            // Bluetooth Printers
            $scope.scanForBluetoothPrinters = function () {
                BridgedPromise.dispatch('getBluetoothPrinters', {}).then(function (data) {
                    var printerList = JSON.parse(data.bluetoothPrinters);

                    var btBackupPrinters = [];
                    if (!$scope.selectedPosStation.posPrinters) {
                        $scope.selectedPosStation.posPrinters = [];
                    } else {
                        // Backup and remove 'bt' protocol printers
                        btBackupPrinters = _.filter(
                            $scope.selectedPosStation.posPrinters, function (element) {
                            return element.protocol === 'bt';
                        });

                        $scope.selectedPosStation.posPrinters = _.filter(
                            $scope.selectedPosStation.posPrinters, function (element) {
                            return element.protocol !== 'bt';
                        });
                    }

                    for (var i = 0; i < printerList.length; i++) {
                        var matchingBackupPrinters = _.filter(btBackupPrinters, {
                            bluetoothName: printerList[i]
                        });

                        // Found existing printers with same BT name
                        if (matchingBackupPrinters.length > 0) {
                            $scope.selectedPosStation.posPrinters.push(...matchingBackupPrinters);
                        // Did not find, push new printer to list
                        } else {
                            $scope.selectedPosStation.posPrinters.push({
                                bluetoothName: printerList[i],
                                posStationId: $scope.selectedPosStation.posStationId,
                                locationId: $scope.location.locationId,
                                receiptPrinter: false,
                                kitchenPrinter: false,
                                defaultKitchenPrinter: false,
                                protocol: 'bt'
                            });
                        }
                    }

                    if (!$scope.$$phase) {
                        $scope.$apply();
                    }
                }).catch(function (error) {
                    $log.error('Error scanning for printers:', error);
                });
            };

            // USB Printers
            $scope.scanForUsbPrinters = function () {
                ElectronPrinter.scanForUsbPrinters().then(function (printerObjectList) {
                    var usbBackupPrinters = [];
                    if (!$scope.selectedPosStation.posPrinters) {
                        $scope.selectedPosStation.posPrinters = [];
                    } else {
                        // Backup and remove 'usb' protocol printers
                        usbBackupPrinters = _.filter(
                            $scope.selectedPosStation.posPrinters, function (element) {
                            return element.protocol === 'usb';
                        });

                        $scope.selectedPosStation.posPrinters = _.filter(
                            $scope.selectedPosStation.posPrinters, function (element) {
                            return element.protocol !== 'usb';
                        });
                    }

                    for (var i = 0; i < printerObjectList.length; i++) {
                        var matchingBackupPrinters = _.filter(usbBackupPrinters, {
                            usbVid: printerObjectList[i].VID,
                            usbPid: printerObjectList[i].PID
                        });

                        // Found existing printers with same USB PID and VID
                        if (matchingBackupPrinters.length > 0) {
                            $scope.selectedPosStation.posPrinters.push(...matchingBackupPrinters);
                        // Did not find, push new printer to list
                        } else {
                            $scope.selectedPosStation.posPrinters.push({
                                usbName: printerObjectList[i].productName,
                                usbVid: printerObjectList[i].VID,
                                usbPid: printerObjectList[i].PID,
                                posStationId: $scope.selectedPosStation.posStationId,
                                locationId: $scope.location.locationId,
                                receiptPrinter: false,
                                kitchenPrinter: false,
                                defaultKitchenPrinter: false,
                                protocol: 'usb'
                            });
                        }
                    }

                    if (!$scope.$$phase) {
                        $scope.$apply();
                    }
                }).catch(function (error) {
                    $log.error('Error scanning for printers:', error);
                });
            };

            // TCP IP Printers
            $scope.scanForTcpPrinters = function () {
                $scope.toShow.tcpIpEntry = false;
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalScanForTCPPrinters.html',
                    controller: 'ScanForTCPPrinters',
                    windowClass: 'smb-pos products2',
                    animation: false,
                    backdrop: 'static'
                });

                modalInstance.result.then(function (result) {
                    if (result && result.ip) {
                        $scope.addTcpIpForm.ipAddress = result.ip;
                        $scope.addTcpIpForm.port = result.port;
                        $scope.addTcpIpPrinter();
                    }
                });
            };

            // Card Terminals
            $scope.scanForCardTerminals = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalScanForCardTerminals.html',
                    controller: 'ScanForCardTerminalsController',
                    windowClass: 'smb-pos products2',
                    animation: false,
                    backdrop: 'static'
                });

                modalInstance.result.then(function (result) {
                    if (result && result.ip) {
                        $scope.selectedPosStation.cardTerminalProperties.ip = result.ip;
                        $scope.selectedPosStation.cardTerminalProperties.port = result.port;
                    }
                });
            };

            $scope.toggleReceiptPrinter = function (printer) {
                printer.receiptPrinter = !printer.receiptPrinter;
            };

            $scope.toggleKitchenPrinter = function (printer) {
                let newKitcherPrinterStatus = !printer.kitchenPrinter;
                // Enable as kitchen printer and show prompt to ask
                // whether to assign it as a default printer or not

                if (newKitcherPrinterStatus) {
                    var defaultPrinter = $scope.selectedPosStation.posPrinters.find((posPrinter) => {
                        return posPrinter.kitchenPrinter && posPrinter.defaultKitchenPrinter
                            && (!posPrinter.posPrinterId || (posPrinter.posPrinterId != printer.posPrinterId));
                    });

                    printer.kitchenPrinter = newKitcherPrinterStatus;

                    if (!defaultPrinter) {
                        printer.defaultKitchenPrinter = true;
                        PosAlertService.showAlertByName('general', {
                            title: 'general.alert.default.kitchen.printer.ttl',
                            message: 'general.alert.default.kitchen.printer.msg'
                        });
                    } else {
                        printer.defaultKitchenPrinter = false;
                        PosAlertService.showAlertByName('set-all-items-kitchen-printer', {
                            title: 'general.confirmation.default.kitchen.printer.ttl',
                            message: 'general.confirmation.default.kitchen.printer.msg',
                            modalCallback: function () {
                                $scope.selectedPosStation.posPrinters.forEach((posPrinter) => posPrinter.defaultKitchenPrinter = false);
                                printer.defaultKitchenPrinter = true;
                                unassignPrinterIds = unassignPrinterIds.filter((id) => id != printer.posPrinterId);
                            }
                        });
                    }
                } else {
                    // If it is not a default printer, disable it immediately
                    // else prompt user to confirm that they are disabling default kitchen printer
                    let title = 'general.error.set-all-items-kitchen-printer-disable.ttl';
                    let nextDefaultPrinter = $scope.selectedPosStation.posPrinters.find((posPrinter) => {
                        return posPrinter.kitchenPrinter
                            && ((printer.defaultKitchenPrinter && !posPrinter.defaultKitchenPrinter) || (!printer.defaultKitchenPrinter && posPrinter.defaultKitchenPrinter))
                            && (!posPrinter.posPrinterId || (posPrinter.posPrinterId != printer.posPrinterId));
                    });

                    let message = (nextDefaultPrinter) ? 'general.error.set-all-items-kitchen-printer-disable1.msg'
                            : 'general.error.set-all-items-kitchen-printer-disable2.msg';
                    if (printer.defaultKitchenPrinter) {
                        message = (nextDefaultPrinter) ? 'general.error.set-all-items-kitchen-printer-disable-default1.msg'
                            : 'general.error.set-all-items-kitchen-printer-disable-default2.msg';
                    }
                    PosAlertService.showAlertByName('set-all-items-kitchen-printer-unbind', {
                        'title': title,
                        'message': $translate.instant(message, {
                            defaultKitchenPrinterName: (nextDefaultPrinter) ? nextDefaultPrinter.printerName : ''
                        }),
                        'buttonTitleOk': 'general.error.set-all-items-kitchen-printer-disable.btnTitleOk',
                        'modalCallback': function () {
                            printer.kitchenPrinter = newKitcherPrinterStatus;
                            printer.defaultKitchenPrinter = false;
                            unassignPrinterIds.push(printer.posPrinterId);
                            if (nextDefaultPrinter) {
                                nextDefaultPrinter.defaultKitchenPrinter = true;
                            }
                        }
                    });
                }
            };

            $scope.toggleLabelPrinter = function (printer) {
                printer.labelPrinter = !printer.labelPrinter;
            };

            $scope.displayInput = function (inputType) {
                if (inputType == $scope.inputTypes.TCPIP) {
                    $scope.toShow.tcpIpEntry = true;
                }
            };

            $scope.loadStations = function () {
                if (!isWizardMode) {
                    Locations.getLocationPOSStations(
                        {
                            'locationId': location.locationId,
                        },
                        function (response) {
                            $scope.posStations = response.entries;
                            $scope.deselectPosStation();
                        }, function (error) {
                            notificationTranslationHelper.notifyError(
                                error.data.error, null, false);
                        }
                    );
                }
            };

            var getNewPosStation = function () {
                return {
                    isNew: true,
                    locationId: $scope.location.locationId,
                    posStationName: '',
                    printerIpAddress: '',
                    printerPort: undefined,
                };
            };
            $scope.addPosStation = function () {
                $scope.selectedPosStation = getNewPosStation();
            };
            $scope.selectPosStation = function (posStation) {
                $scope.selectedPosStation = posStation;
            };
            $scope.deselectPosStation = function () {
                $scope.selectedPosStation = undefined;
            };

            $scope.saveSelectedPosStation = function () {
                // Reset/Initialize error status
                $scope.selectedPosStation.errors = {};
                var isError = false; // Set to true if there is an issue with any of the forms

                // Check if a station name exists
                if (!$scope.selectedPosStation.posStationName) {
                    $scope.selectedPosStation.errors.posStationName = true;
                    isError = true;
                }

                let terminalConfig = $scope.selectedPosStation.cardTerminalProperties;

                // Check card terminal fields for errors
                if ($scope.selectedPosStation.cardTerminalProperties.config == 'manual') {
                    // ip and port should be blank
                    if ($scope.selectedPosStation.cardTerminalProperties.ip) {
                        $scope.selectedPosStation.cardTerminalProperties.ip = null;
                    }

                    if ($scope.selectedPosStation.cardTerminalProperties.port) {
                        $scope.selectedPosStation.cardTerminalProperties.port = null;
                    }
                } else if ($scope.selectedPosStation.cardTerminalProperties.config == 'oauth') {
                    if ($scope.selectedPosStation.cardTerminalProperties.ip) {
                        delete $scope.selectedPosStation.cardTerminalProperties.ip;
                    }
                    if ($scope.selectedPosStation.cardTerminalProperties.port) {
                        delete $scope.selectedPosStation.cardTerminalProperties.port;
                    }

                    if ($scope.selectedPosStation.cardTerminalProperties.cloverConfig) {
                        var cloverConfig = $scope.selectedPosStation.cardTerminalProperties.cloverConfig;
                        delete $scope.selectedPosStation.cardTerminalProperties.cloverConfig;

                        $scope.selectedPosStation.cardTerminalProperties.deviceId = cloverConfig.deviceId;
                        $scope.selectedPosStation.cardTerminalProperties.merchantId = cloverConfig.merchantId;
                    }
                } else if (terminalConfig.config == 'ipport') {

                    if (!Pure.ipTest(terminalConfig.ip)) {
                        $scope.selectedPosStation.errors.cardTerminalIp = true;
                        isError = true;
                    }

                    if (!terminalConfig.port || terminalConfig.port < 0 || terminalConfig.port > 65535) {
                        $scope.selectedPosStation.errors.cardTerminalPort = true;
                        isError = true;
                    }

                    if (!terminalConfig.printErrorReceipt) {
                        terminalConfig.printErrorReceipt = false;
                    }

                    if (terminalConfig.merchantId) {
                        delete terminalConfig.merchantId;
                    }

                    if (terminalConfig.accessToken) {
                        delete terminalConfig.accessToken;
                    }

                    if (terminalConfig.deviceId) {
                        delete terminalConfig.deviceId;
                    }
                    delete terminalConfig.cloverConfig;
                } else if ($scope.selectedPosStation.cardTerminalProperties.config == 'epos') {
                    let terminalConfig = $scope.selectedPosStation.cardTerminalProperties;

                    if (terminalConfig) {
                        // Invalid card terminal IP
                        if (!Pure.ipTest(terminalConfig.ip)) {
                            $scope.selectedPosStation.errors.cardTerminalIp = true;
                            isError = true;
                        }

                        if (!$scope.selectedPosStation.cardTerminalProperties.port) {
                            $scope.selectedPosStation.errors.cardTerminalPort = true;
                            isError = true;
                        }

                        if (!terminalConfig.username) {
                            $scope.selectedPosStation.errors.cardTerminalEposUsername = true;
                            isError = true;
                        }

                        if (!terminalConfig.password) {
                            $scope.selectedPosStation.errors.cardTerminalEposPassword = true;
                            isError = true;
                        }

                        if (!terminalConfig.reportPrintout) {
                            $scope.selectedPosStation.cardTerminalProperties.reportPrintout = false;
                        }

                    }
                }

                // Check POS Printers for errors
                if ($scope.selectedPosStation.posPrinters) {
                    for (let i = 0; i < $scope.selectedPosStation.posPrinters.length; i++) {
                        // Reset/Initialize error status
                        $scope.selectedPosStation.posPrinters[i].errors = {};

                        // Check for errors
                        // Manufacturer
                        if (!$scope.selectedPosStation.posPrinters[i].selectedManufacturer) {
                            $scope.selectedPosStation.posPrinters[i].errors.manufacturer = true;
                            $scope.selectedPosStation.posPrinters[i].errors.model = true;
                            isError = true;
                        }

                        // Model
                        var manufacturerIndex = -1;
                        // Verify model exists in selected manufacturer printers
                        // This also verifies whether a valid model was selected
                        if ($scope.selectedPosStation.posPrinters[i].selectedManufacturer &&
                            $scope.selectedPosStation.posPrinters[i].selectedModel) {
                            manufacturerIndex =
                                _.findIndex($scope.selectedPosStation.posPrinters[i].selectedManufacturer.printers,
                                    $scope.selectedPosStation.posPrinters[i].selectedModel);
                        }
                        if (manufacturerIndex === -1) {
                            $scope.selectedPosStation.posPrinters[i].errors.model = true;
                            isError = true;
                        }

                        // Printer Name
                        if (!$scope.selectedPosStation.posPrinters[i].printerName) {
                            $scope.selectedPosStation.posPrinters[i].errors.printerName = true;
                            isError = true;
                        }

                        if ($scope.selectedPosStation.posPrinters[i].printerIpPort) {
                            const [ip, port] = $scope.selectedPosStation.posPrinters[i].printerIpPort.split(':');
                            if (Pure.ipTest(ip) && parseInt(port) && port >= 0 && port <= 65535) {
                                $scope.selectedPosStation.posPrinters[i].printerIpAddress = ip;
                                $scope.selectedPosStation.posPrinters[i].printerPort = parseInt(port);
                            } else {
                                isError = true;
                            }
                        }
                    }
                }

                // Check mevbox errors
                if ($scope.isMevBoxCompany) {
                    // if there are no selected SRMs
                    if (!$scope.selectedPosStation.locationSrmId) {
                        isError = true;
                    }
                }

                // Stop here if an error occured
                if (isError) {
                    return false;
                }

                // Copy and assign relevant fields for sending to backend
                if ($scope.selectedPosStation.errors) {
                    delete $scope.selectedPosStation.errors;
                }
                var posStationCopy = angular.copy($scope.selectedPosStation);
                if (posStationCopy.posPrinters) {
                    for (let i = 0; i < $scope.selectedPosStation.posPrinters.length; i++) {
                        // Assign the rest of the properties used in DTO
                        Object.assign(posStationCopy.posPrinters[i], {
                            printerManufacturer: posStationCopy.posPrinters[i].selectedManufacturer.value,
                            printerModel: posStationCopy.posPrinters[i].selectedModel.value,
                            printerEmulation: posStationCopy.posPrinters[i].selectedModel.emulation,
                            printerPortSettings: posStationCopy.posPrinters[i].selectedModel.portSettings,
                            receiptSize: posStationCopy.posPrinters[i].selectedModel.receiptSize,
                            customAttributes: JSON.stringify(posStationCopy.posPrinters[i].customAttributes) // JSON stringify can produce a "null" string
                        });

                        // let cashDrawerType = posStationCopy.posPrinters[i].selectedModel.cashDrawerType;
                        // if (cashDrawerType) {
                        //     posStationCopy.posPrinters[i].cashDrawerType = cashDrawerType;
                        // }

                        // Remove fields that are not used by DTO (from copy only)
                        delete posStationCopy.posPrinters[i].selectedManufacturer;
                        delete posStationCopy.posPrinters[i].selectedModel;
                        delete posStationCopy.posPrinters[i].printerIpPort;
                        if (posStationCopy.posPrinters[i].errors) {
                            delete posStationCopy.posPrinters[i].errors;
                        }
                        // JSON stringify can produce a "null" string
                        if (posStationCopy.posPrinters[i].customAttributes === 'null') {
                            delete posStationCopy.posPrinters[i].customAttributes;
                        }
                    }
                }


                const callBack = (response) => {
                    $scope.loadStations();
                    if (isWizardMode) {
                        $scope.$close(response);
                    }
                };

                let posStationPromise;
                if (posStationCopy.isNew) {
                    posStationPromise = Locations.addPOSStation({}, posStationCopy).$promise;
                } else {
                    posStationPromise = Locations.updatePOSStation({}, posStationCopy).$promise;
                }

                posStationPromise.then((response) => {
                    let unassignPrinterIdPromise;
                    if (unassignPrinterIds && unassignPrinterIds.length) {
                        if (CompanyAttributesService.hasMenuV2Enabled()) {
                            const payload = {
                                printerIds: unassignPrinterIds,
                                companyId: selectedCompanyId
                            };
                            unassignPrinterIdPromise = Menu.unassignPrintersFromAssociatedItems({}, payload).$promise;
                        } else {
                            let promisesArr = [];
                            unassignPrinterIds.forEach((id) => {
                                promisesArr.push(Locations.unassignPrinterFromItems({
                                    printerId: null,
                                    locationId: $scope.location.locationId,
                                    assignAll: false,
                                    assignFrom: id
                                }).$promise);

                                unassignPrinterIdPromise = Promise.all(promisesArr);
                            });
                        }

                        unassignPrinterIdPromise.then((unassignResponse) => {
                            callBack(response);
                        }).catch((unassignErr) => {
                            PosAlertService.showAlertByName('general-error', {
                                title: 'general.error.unassign.printer.ttl'
                            });
                        });
                    } else {
                        callBack(response);
                    }
                }).catch((err) => {
                    PosAlertService.showAlertByName('general-error', {
                        title: 'general.error.save.station.ttl'
                    });
                });
            };

            $scope.deleteSelectedPosStation = function () {
                ConfirmModalService.openConfirmModal(
                    'Delete POS Station',
                    'Do you wish to delete the POS Station named \'' +
                        $scope.selectedPosStation.posStationName + '\'?',
                    confirmDeleteSelectedPosStation,
                    $scope.selectedPosStation,
                    $scope,
                    true
                );
            };
            $scope.testPrinter = function () {
                // prints out a test receipt with the url (ip and port)
                var printUrlArray = PrintService.buildPrinterObjArray(
                    $scope.selectedPosStation.posPrinters,
                    false);

                // Preivously calling `PrintService.printTestReceipt`. `testPrinter` looks like the new
                // updated version of `printTestReceipt` but this is not tested yet.
                PrintService.testPrinter(printUrlArray);
            };

            var confirmDeleteSelectedPosStation = function () {
                Locations.deletePOSStation({
                  stationId: $scope.selectedPosStation.posStationId,
                }, {}, function (response) {
                    $scope.loadStations();

                    if (isWizardMode) {
                        $scope.close();
                    }
                });
            };

            var loadUsers = function () {
                $scope.isLoadingUsers = true;
                Users.listUsers({companyId: selectedCompanyId, limit: -1}, function (response) {
                    $scope.isLoadingUsers = false;
                    $scope.users = _.filter(response.entries, function (user) {
                        return (
                            (user.email != 'api@lucova.com' && user.email != 'lucova.logging@lucova.com' && (user.roleName == 'Station' || user.roleName == 'Kiosk'))
                            || (user.firstname !== 'API USER' && user.lastname !== 'DONT DELETE' && (user.roleName == 'Station' || user.roleName == 'Kiosk'))
                            || (!user.autoCreated && (user.roleName == 'Station' || user.roleName == 'Kiosk'))
                        );
                    });
                }, function (error) {
                    $scope.isLoadingUsers = false;
                });
            };

            $scope.toggleNoSale = function (posStation) {
                posStation.allowNoSale = !posStation.allowNoSale;
            };

            $scope.init();
        },
    ]).value('isWizardMode', false);
};
