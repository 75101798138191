'use strict';


export function electronUpdater (freshideas) {

    freshideas.factory('NodeFs', ['Platform', function (Platform) {
        if (Platform.isElectron()) {
            let fs = nodeRequire('fs');
            return fs;
        } else {
            return null;
        }
    }]);

    freshideas.factory('NodePath', ['Platform', function (Platform) {
        if (Platform.isElectron()) {
            let path = nodeRequire('path');
            return path;
        } else {
            return null;
        }
    }]);

    freshideas.factory('NodeHttps', ['Platform', function (Platform) {
        if (Platform.isElectron()) {
            let https = nodeRequire('https');
            return https;
        } else {
            return null;
        }
    }]);

    freshideas.factory('Electron', ['Platform', function (Platform) {
        if (Platform.isElectron()) {
            let electron = nodeRequire('electron');
            return electron;
        } else {
            return null;
        }
    }]);

    freshideas.factory('VersionCompare', [function () {

        /**
         * 0 if the versions are equal
         * a negative integer if v1 < v2
         * a positive integer if v1 > v2
         * https://gist.github.com/TheDistantSea/8021359 (modified)
         */
        var compare = (v1, v2) => {
            var v1parts = v1.split('.');
            var v2parts = v2.split('.');

            function isValidPart (x) {
                return /^\d+$/.test(x);
            }

            if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
                return NaN;
            }

            for (var i = 0; i < v1parts.length; ++i) {
                if (v2parts.length == i) {
                    return 1;
                }

                if (v1parts[i] == v2parts[i]) {
                    continue;
                } else if (v1parts[i] > v2parts[i]) {
                    return 1;
                } else {
                    return -1;
                }
            }

            if (v1parts.length != v2parts.length) {
                return -1;
            }

            return 0;
        };
        return {
            compare
        };
    }]);

    freshideas.factory('ElectronUpdater', [
        'Logger',
        'NodeFs',
        'NodePath',
        'NodeHttps',
        'NodeOs',
        'Electron',
        'Admin',
        'VersionCompare',
        'Platform',
        function (
            Logger,
            NodeFs,
            NodePath,
            NodeHttps,
            NodeOs,
            Electron,
            Admin,
            VersionCompare,
            Platform) {

            const scheduleDownload = (url, appDestination) => {
                let millis = Math.floor((Math.random() * 20) + 1) * (1000 * 60);

                setTimeout(async () => {
                    try {
                        await download(url, appDestination);
                        Logger.info('app update downloaded successfully');
                    } catch (error) {
                        Logger.error(error);
                    }
                }, millis);
                Logger.info('Scheduled update for:' + new Date(Date.now() + millis).toString());
            };

            const downloadAppUpdate = async () => {
                if (!Platform.isElectron()) {
                    return;
                }
                try {
                    let arch = NodeOs.arch();
                    let platform = NodeOs.platform();
                    let appVersion = Electron.remote.app.getVersion();
                    const {denom, version, url} = await getPlatformUpdate({arch, platform, appVersion});

                    if (!version || !url) {
                        return;
                    }
                    let appDataDir = NodePath.join(Electron.remote.app.getPath('appData'), 'Lucova Inc', 'nown pos');
                    let appDestination = NodePath.join(appDataDir, 'app.zip');

                    if (version && VersionCompare.compare(appVersion, version) < 0 && canUpdate(denom || 1)) {
                        scheduleDownload(url, appDestination);
                    }
                } catch (err) {
                    Logger.error(err);
                }
            };

            const canUpdate = (denom) => {
                let num = Math.floor((Math.random() * 100) + 1);
                return !(num % denom);
            };

            const download = (url, dest) => {
                return new Promise((resolve, reject) => {
                    if (!Platform.isElectron()) {
                        reject();
                    }
                    try {
                        var file = NodeFs.createWriteStream(dest);
                        NodeHttps.get(url, (response) => {
                            if (response.statusCode !== 200) {
                                file.close();
                                return;
                            }
                            response.pipe(file);
                            file.on('finish', () => {
                                resolve();
                            });
                        }).on('error', (err) => {
                            NodeFs.unlink(dest);
                            reject(err.message);
                        });
                    } catch (err) {
                        Logger.error(err);
                        reject(err);
                    }
                });
            };

            const getPlatformUpdate = (config) => {
                return Admin.getPlatformUpdateSettings(config).$promise;
            };

            if (!Platform.isElectron()) {
                console.log('Running in non-electron environment. Electron Updater will be disabled');
            } else if (!Electron || !Electron.remote.getGlobal('exports')) {
                console.log('Running in electron without the right config file. disabling auto update');
            }
            return {
                downloadAppUpdate
            };
        }]);
}
