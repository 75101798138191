'use strict';

module.exports = function (freshideasProducts) {
    freshideasProducts.controller('PrintLabelCtrl', [
        '$scope',
        'item',
        function ($scope, item) {

            $scope.name = item.name || 'Unknown';
            $scope.price = item.vatIncludedPrice || item.price || 0;
            $scope.sku = item.upc;
            $scope.bc = {
                format: 'CODE128',
                lineColor: '#000000',
                width: 1,
                height: 15,
                displayValue: false,
                fontOptions: '',
                font: 'Open Sans',
                textAlign: 'center',
                textPosition: 'bottom',
                textMargin: 2,
                fontSize: 18,
                background: '#ffffff',
                margin: 0,
                marginTop: undefined,
                marginBottom: undefined,
                marginLeft: undefined,
                marginRight: undefined,
                valid: function (valid) {
                }
            };

            $scope.print = () => {
                let config = {
                    addGlobalStyles: true,
                    stylesheet: null,
                    rejectWindow: true,
                    noPrintSelector: '.no-print',
                    iframe: true,
                    append: null,
                    prepend: null
                };
                $.print('#LabelMaker', config);
            };

        }
    ]);
};
