'use strict';


const angular = require('angular');

export default angular.module('freshideas.services.breadcrumbs', [])

    .constant('BreadcrumbLabels',
    {
        settings: 'freshideas.general.settings',
        general: 'app.settings.settingsGeneral.generalSettings',
        dashboard: 'app.sidebar.dashboard',
        controls: 'sidebar.controls',
        systemInfo: 'app.sidebar.systemInfo'
    })

    .factory('breadcrumbs', ['$transitions', '$location', 'BreadcrumbLabels', function ($transitions, $location, BreadcrumbLabels) {

        var breadcrumbs = [];
        var breadcrumbsService = {};

        // we want to update breadcrumbs only when a route is actually changed
        // as $location.path() will get updated immediately (even if route change fails!)
        $transitions.onSuccess({}, function (transition) {
            var pathElements = $location.path().split('/'), results = [], i;
            var breadcrumbPath = function (index) {
                return '/' + (pathElements.slice(0, index + 1)).join('/');
            };

            pathElements.shift();
            for (i = 0; i < pathElements.length; i++) {
                var name = BreadcrumbLabels[pathElements[i]] || pathElements[i];
                var result = {
                    name: name,
                    path: breadcrumbPath(i)
                };

                if (i === pathElements.length - 1) {
                    var currentStateData = transition.router.stateService.current.data || {};
                    result.displayName = currentStateData.viewName || '';
                }

                results.push(result);
            }

            breadcrumbs = results;
        });

        breadcrumbsService.getAll = function () {
            return breadcrumbs;
        };

        breadcrumbsService.getFirst = function () {
            return breadcrumbs[0] || {};
        };

        return breadcrumbsService;
    }]);
