'use strict';

const Sortable = require('sortablejs').default;
const Swap = require('sortablejs').Swap;

Sortable.mount(new Swap());

module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ProductPageOrderPromptCtrl', [
        '$scope',
        'ProductsService',
        'selectedMenu',
        function (
            $scope,
            ProductsService,
            selectedMenu
        ) {
            let sortable;
            let label = '';

            $scope.currentPageOrder = {};
            $scope.pageLables = ['1st Page', '2nd Page', '3rd Page', '4th Page'];
            _.each(ProductsService.products, function (page, index) {
                let key = `pageName${index}`;

                label = !page.name ? `page.${page.menuOrderId - 4}` : page.name;
                $scope.currentPageOrder[label] = selectedMenu[key];
            });

            function intiailizeSortableModifierList () {
                let el = document.getElementById('page-group-list');

                if (!el) {
                    return;
                }

                sortable = Sortable.create(el, {
                    swap: true,
                    animation: 150,
                    onEnd: function (event) {
                        $scope.newPageOrder = [];

                        _.each(event.target.children, function (el, index) {
                            $scope.newPageOrder.push(el.getAttribute('data-value'));
                        });
                    }
                });

                $scope.$on('$destroy', function () {
                    sortable.destroy();
                });
            }

            $scope.cancel = function () {
                $scope.$close({
                    action: 'cancel'
                });
            };
            $scope.save = function () {
                $scope.$close({
                    newPageOrder: $scope.newPageOrder,
                    currentPageOrder: $scope.currentPageOrder,
                    action: 'save'
                });
            };

            $scope.init = function () {
                intiailizeSortableModifierList();
            };
        }]);
};
