
module.exports = function (freshideas) {
    freshideas.controller('GenericListController', [
        '$scope',
        'title',
        'items',
        'itemDisplayField',
        function (
            $scope,
            title,
            items,
            itemDisplayField) {

            $scope.title = title;
            $scope.itemDisplayField = itemDisplayField || 'name';

            $scope.items = _.map(items, function (item) {
                if (_.isObject(item)) {
                    return item;
                } else {
                    let mappedItem = {};
                    mappedItem[$scope.itemDisplayField] = item;
                }
            });

            $scope.setSelection = function (item) {
                deselectAllItems();
                item.selected = true;

                $scope.$close();
            };
            var deselectAllItems = function () {
                for (var item of $scope.items) {
                    item.selected = false;
                }
            };

            var originalClose = $scope.$close;
            $scope.$close = function () {
                var selectedItem = _.findWhere($scope.items, {selected: true});
                console.log(selectedItem);
                originalClose(selectedItem);
            };

        }]);
};
