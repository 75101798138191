'use strict';


module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosEndShiftSettleTransCtrl', [
        '$scope',
        '$timeout',
        '$window',
        '$log',
        '$modalInstance',
        'manualTerminal',
        function ($scope,
            $timeout,
            $window,
            $log,
            $modalInstance,
            manualTerminal) {

            $scope.manualTerminal = manualTerminal;

            // Navigation
            $scope.goBack = function (checkmarkString = false) {
                $modalInstance.dismiss();
            };

            $scope.autoSettle = function () {
                $modalInstance.close();
            };
        }
    ]);
};
