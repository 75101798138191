/* eslint-disable guard-for-in */

'use strict';

const moment = require('moment');

module.exports = function (freshideasReports) {

    freshideasReports.controller('AdvancedItemSalesReport', [
        '$scope',
        '$location',
        'Reports',
        'Utils',
        'Lookup',
        'Export',
        'Security',
        'Platform',
        'DateRangeService',
        'USER_ROLE_TYPE',
        function ($scope, $location, Reports, Utils, Lookup, Export, Security, Platform, DateRangeService, USER_ROLE_TYPE) {
            var currentUser = Security.getUser() || {};
            $scope.isManager = currentUser.roleType === USER_ROLE_TYPE.MANAGER;
            $scope.isAccountant = currentUser.roleType === USER_ROLE_TYPE.ACCOUNTANT;
            $scope.isSiteAdmin = currentUser.permission === 'SITEADMIN';
            $scope.searchDisabled = false;
            var allowRetry;
            $scope.isFullAdmin = (currentUser.permission === 'FULLADMIN');
            $scope.isIosWebkit = Platform.isIosWebkit();
            $scope.isElectron = Platform.isElectron();
            $scope.searchBar = {
                text: ''
            };

            $scope.init = function () {
                if (DateRangeService.getFromDate()) {
                    $scope.advancedItemSalesSearch.startDateTime = moment(DateRangeService.getFromDate()).startOf('day').toDate();
                }
                if (DateRangeService.getToDate()) {
                    $scope.advancedItemSalesSearch.endDateTime = moment(DateRangeService.getToDate()).endOf('day').toDate();
                }
                loadCompanyHierarchy().$promise.then(function () {
                    $scope.loadAdvancedItemSales();
                });
            };

            function loadCompanyHierarchy () {
                return Lookup.locationsByCompany({isAdvancedSearch: true}, function (response) {
                    $scope.company = _.findWhere(response, {id: currentUser.companyId});
                    $scope.locations = [];
                    let children = ($scope.company && $scope.company.children) ? $scope.company.children : [];

                    if (children.length) {
                        if (children.length > 1) {
                            $scope.locations.push({
                                id: null,
                                name: 'All Locations'
                            });
                        }
                        _.each(children, function (location) {
                            $scope.locations.push(location);
                        });
                        $scope.advancedItemSalesSearch.locationId = currentUser.company.locationId;
                    }
                });
            }

            $scope.setSortByField = function (sortBy) {
                $scope.currentReportSearch.sortBy = sortBy;
                $scope.currentReportSearch.ascending = !$scope.currentReportSearch.ascending;

                $scope.categorySalesData.sort(sortByFieldFunction(sortBy));

                _.each($scope.categorySalesData, function (categoryItemSalesDatum) {
                    categoryItemSalesDatum.items.sort(sortByFieldFunction(sortBy));

                    _.each(categoryItemSalesDatum.items, function (item) {
                        item.modifiers.sort(sortByFieldFunction(sortBy));
                    });
                });
            };

            var sortByFieldFunction = function (sortBy) {
                var greater = $scope.currentReportSearch.ascending ? 1 : -1;
                var less = $scope.currentReportSearch.ascending ? -1 : 1;

                return (a, b) => {
                    let valueA = a[sortBy];
                    let valueB = b[sortBy];

                    if (typeof valueA === 'string' || valueA instanceof String) {
                        valueA = valueA.toLowerCase();
                    }

                    if (typeof valueB === 'string' || valueB instanceof String) {
                        valueB = valueB.toLowerCase();
                    }

                    if (valueA > valueB) {
                        return greater;
                    }
                    if (valueA < valueB) {
                        return less;
                    }
                    return 0;
                };
            };

            $scope.resetFilters = function () {
                $scope.advancedItemSalesSearch.startDateTime = moment().startOf('day').toDate();
                $scope.advancedItemSalesSearch.endDateTime = moment().endOf('day').toDate();
                $scope.advancedItemSalesSearch.isAdvancedSearch = true;
                $scope.advancedItemSalesSearch.sortBy = 'itemName';
                $scope.advancedItemSalesSearch.ascending = true;
                $scope.advancedItemSalesSearch.offSet = 0;
                $scope.advancedItemSalesSearch.limit = 50;
                $scope.advancedItemSalesSearch.location = undefined;
                $scope.advancedItemSalesSearch.locationId = currentUser.company.locationId;

                $scope.loadAdvancedItemSales();
            };

            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };
            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };

            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };

            $scope.advancedItemSalesSearch = {
                isAdvancedSearch: true,
                startDateTime: moment().startOf('week').toDate(),
                endDateTime: moment().endOf('day').toDate(),
                sortBy: 'itemName',
                ascending: true,
                offSet: 0,
                limit: 50,
            };
            $scope.saveSelectedFromDate = function () {
                var selectedFromDate = moment($scope.advancedItemSalesSearch.startDateTime).startOf('day').valueOf();
                DateRangeService.setFromDate(selectedFromDate);
            };
            $scope.saveSelectedToDate = function () {
                var selectedToDate = moment($scope.advancedItemSalesSearch.endDateTime).endOf('day').valueOf();
                DateRangeService.setToDate(selectedToDate);
            };

            function resetPagingOptions () {
                $scope.pagingOptions = {
                    startRecord: 0,
                    pageSize: 10,
                    currentPage: 1,
                };
            }
            resetPagingOptions();

            $scope.pageUp = function () {
                var start = $scope.pagingOptions.startRecord;
                var newStart = start + $scope.pagingOptions.pageSize;
                if ($scope.categorySalesData.length > newStart) {
                    $scope.pagingOptions.startRecord = newStart;
                    $scope.pagingOptions.currentPage++;
                }
            };

            $scope.pageDown = function () {
                var start = $scope.pagingOptions.startRecord;
                var newIndex = start - $scope.pagingOptions.pageSize;
                if (start > 0) {
                    if (newIndex < 0) {
                        $scope.pagingOptions.startRecord = 0;
                    } else {
                        $scope.pagingOptions.startRecord = newIndex;
                    }
                    $scope.pagingOptions.currentPage--;
                }
            };

            $scope.pagesAvailable = function () {
                var itemLen = $scope.categorySalesData.length;
                var pageSize = $scope.pagingOptions.pageSize;
                return (itemLen > pageSize) ? Math.ceil(itemLen/pageSize) : 1;
            };

            function reduceCategoriesList (list) {
                let retval = [];
                Object.keys(list).forEach((key) => {
                    var item = list[key].reduce((acu, cur) => {
                        acu.quantity = acu.quantity || 0;
                        acu.amountCents = acu.amountCents || 0;

                        acu.categoryName = cur.categoryName || '';
                        acu.quantity += cur.quantity || 0;
                        acu.amountCents += cur.amountCents || 0;

                        acu.items = acu.items || [];
                        acu.items.push(cur);

                        acu.displayName = acu.categoryName;

                        acu.tagNames = cur.tagNames;

                        return acu;
                    }, {});
                    retval.push(item);
                });
                return retval;
            }

            function reduceItemsList (list) {
                let retval = [];
                Object.keys(list).forEach((key) => {
                    var item = list[key].reduce((acu, cur) => {
                        acu.quantity = acu.quantity || 0;
                        acu.amountCents = acu.amountCents || 0;

                        acu.menuItemName = cur.menuItemName || '';
                        acu.quantity += cur.quantity || 0;
                        acu.amountCents += cur.amountCents || 0;
                        // acu.namedComposition = cur.namedComposition || '';

                        acu.modifiers = acu.modifiers || [];
                        acu.modifiers.push(cur);

                        acu.displayName = acu.menuItemName;

                        acu.tagNames = cur.tagNames;

                        return acu;
                    }, {});
                    retval.push(item);
                });
                return retval;
            }

            function reduceModifiersList (list) {
                let retval = [];
                Object.keys(list).forEach((key) => {
                    var item = list[key].reduce((acu, cur) => {
                        acu.quantity = acu.quantity || 0;
                        acu.amountCents = acu.amountCents || 0;

                        acu.menuItemName = cur.menuItemName || '';
                        acu.composition = cur.composition || '';
                        acu.namedComposition = cur.namedComposition || '';

                        acu.quantity += cur.quantity || 0;
                        acu.amountCents += cur.amountCents || 0;

                        acu.tagNames = cur.tagNames;

                        return acu;
                    }, {});
                    retval.push(item);
                });
                return retval;
            }

            $scope.filterItemSales = function (data) {
                if (data.length > 0) {
                    $scope.isLoading = false;

                    var categorySalesData = _.filter(data, function (item) {
                        return item.quantity > 0;
                    });

                    var groupedCategories = reduceCategoriesList(_.groupBy(categorySalesData, 'categoryName'));

                    groupedCategories.forEach((category) => {
                        category.items = category.items || [];
                        category.items = reduceItemsList(_.groupBy(category.items, 'menuItemName'));

                        category.categoryName = category.categoryName || 'Uncategorized';
                        category.displayName = category.categoryName;

                        category.items.forEach((item) => {
                            var itemHasModifier = false;

                            item.modifiers = reduceModifiersList(_.groupBy(item.modifiers, 'composition'));
                            item.modifiers = _.sortBy(item.modifiers, 'composition');

                            item.modifiers.forEach((modifier) => {
                                modifier.displayName = $scope.parseNamedComposition(modifier.namedComposition);
                                if (modifier.displayName !== '') {
                                    itemHasModifier = true;
                                }
                            });

                            if (!itemHasModifier) {
                                item.modifiers.length = 0;
                            }
                        });
                    });

                    $scope.categorySalesData = groupedCategories;
                    $scope.originalSalesData = $scope.categorySalesData;

                    $scope.currentReportSearch = angular.copy($scope.advancedItemSalesSearch);
                    $scope.searchAction();
                } else {
                    $scope.isLoading = false;
                    $scope.categorySalesData = [];
                    $scope.categorySalesDataSize = 0;
                }
            };

            $scope.loadAdvancedItemSales = function () {
                $scope.searchDisabled = true;
                clearTimeout(allowRetry);
                allowRetry = setTimeout(function () {
                    $scope.searchDisabled = false;
                }, 30000);
                $scope.advancedItemSalesSearch.startDateTime = moment($scope.advancedItemSalesSearch.startDateTime).startOf('day').valueOf();
                $scope.advancedItemSalesSearch.endDateTime = moment($scope.advancedItemSalesSearch.endDateTime).endOf('day').valueOf();

                $scope.categorySalesData = [];
                $scope.categorySalesDataSize = 0;
                $scope.isLoading = true;

                Reports.getAdvancedItemSalesReport($scope.advancedItemSalesSearch, function (response) {
                    $scope.filterItemSales(response);
                }, function (error) {
                    $scope.isLoading = false;
                });

                var reportSearch = angular.copy($scope.advancedItemSalesSearch);
                reportSearch.startDateTime = moment($scope.advancedItemSalesSearch.startDateTime).startOf('day').format('YYYY-MM-DD');
                reportSearch.endDateTime = moment($scope.advancedItemSalesSearch.endDateTime).endOf('day').format('YYYY-MM-DD');
                Reports.getOverview(reportSearch, function (response) {
                    $scope.overview = response[0];
                });
            };

            /*
            Commented by Nick Simone 2021/11/12
            We should avoid doing things like this on the front end in the future.
            I am adding this as a quick bug fix to remain consistent with the other
            reports and it should be removed in Reports V2.
            */
            function calculateTotalSales () {
                $scope.totalSales = 0;
                $scope.categorySalesData.forEach((category) => {
                    $scope.totalSales += category.amountCents || 0;
                });
            }

            $scope.getDownloadUrl = function () {
                if ($scope.isIosWebkit || $scope.isElectron) {
                    return;
                }
                var reportSearch = {};
                reportSearch.startDateTime = moment($scope.advancedItemSalesSearch.startDateTime).startOf('day').valueOf();
                reportSearch.endDateTime = moment($scope.advancedItemSalesSearch.endDateTime).endOf('day').valueOf();
                if ($scope.advancedItemSalesSearch.locationId) {
                    reportSearch.locationId = $scope.advancedItemSalesSearch.locationId;
                }

                var downloadUrl = $location.protocol() + '://' + $location.host() + ':' + $location.port()
                 + '/freshideas/web/reports/advancedItemSales/export';

                var queryString = jQuery.param(reportSearch);
                return downloadUrl + '?' + queryString;
            };

            $scope.setBaseItemsFromSales = function (datedItemSales) {
                // Base item list contains base items only
                var baseItemList = datedItemSales.filter((itemSalesIterate) => {
                    return itemSalesIterate.locationServicePeriodMenuId === itemSalesIterate.origin;
                });

                // Build modifiers of each base item into baseItem.modifiers
                baseItemList.forEach((baseItem) => {
                    baseItem.modifiers = datedItemSales.filter((itemSalesIterate) => {
                        if (itemSalesIterate.locationServicePeriodMenuId !== itemSalesIterate.origin
                            && itemSalesIterate.origin === baseItem.locationServicePeriodMenuId) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                });
                return baseItemList;
            };

            $scope.toggleShowCategoryItems = function (category) {
                if (!category.showItems) {
                    category.showItems = true;
                } else {
                    category.showItems = false;
                }
            };
            $scope.toggleShowItemModifiers = function (item) {
                if (!item.showModifiers) {
                    item.showModifiers = true;
                } else {
                    item.showModifiers = false;
                }
            };

            $scope.parseNamedComposition = function (namedComposition) {
                namedComposition = namedComposition || '{}';
                var compositionObject = JSON.parse(namedComposition);

                var modifierLabels = [];

                // guard-for-in eslint is disabled to allow this parsing to work. guard-for-in
                // is meant to protect unwanted property names getting parsed in an object. But
                // this object's property names are all dynamic and the structure of the object
                // is controlled by us, so it is acceptable to disable guard-fo-in for this
                // block of code
                for (var itemName in compositionObject) {
                    var modifierGroups = compositionObject[itemName];
                    if (!Array.isArray(modifierGroups)) {
                        continue;
                    }
                    for (var modifierGroup of modifierGroups) {
                        for (var modifierGroupName in modifierGroup) {
                            var modifiers = modifierGroup[modifierGroupName];
                            if (!Array.isArray(modifiers)) {
                                continue;
                            }
                            for (var modifier of modifiers) {
                                for (var modifierName in modifier) {
                                    var modifierLabel = modifierName + ' x' + modifier[modifierName];
                                    modifierLabels.push(modifierLabel);
                                }
                            }
                        }
                    }
                }

                return modifierLabels.join(', ');
            };

            $scope.setAllShowModifiers = function (expanded) {
                $scope.categorySalesData.forEach((category) => {
                    category.showItems = expanded;

                    if (category.items) {
                        category.items.forEach((item) => {
                            item.showModifiers = expanded;
                        });
                    }
                });
            };

            $scope.searchAction = function () {
                // Search filter
                if ($scope.searchBar.text !== '') {
                    var searchTextLowerCase = $scope.searchBar.text.toLowerCase();
                    var filteredArray = [];

                    $scope.originalSalesData.forEach((baseItemSale) => {
                        var baseItemName = baseItemSale.displayName.toLowerCase();
                        if (baseItemName.indexOf(searchTextLowerCase) !== -1) {
                            filteredArray.push(baseItemSale);
                        } else if (baseItemSale.items.length > 0) {
                            var pushedBaseItem = false;
                            baseItemSale.items.forEach((modifierItemSale) => {
                                var modifierItemName = modifierItemSale.displayName.toLowerCase();
                                if (modifierItemName.indexOf(searchTextLowerCase) !== -1) {
                                    if (!pushedBaseItem) {
                                        pushedBaseItem = Object.assign(
                                            {}, baseItemSale);
                                        pushedBaseItem.items = [];
                                        pushedBaseItem.items.push(modifierItemSale);
                                        filteredArray.push(pushedBaseItem);
                                    } else {
                                        pushedBaseItem.items.push(modifierItemSale);
                                    }
                                } else if (modifierItemSale.tagNames && modifierItemSale.tagNames.length) {
                                    for (var tagName of modifierItemSale.tagNames) {
                                        tagName = tagName.toLowerCase();
                                        if (tagName.indexOf(searchTextLowerCase) !== -1) {
                                            if (!pushedBaseItem) {
                                                pushedBaseItem = Object.assign(
                                                    {}, baseItemSale);
                                                pushedBaseItem.items = [];
                                                pushedBaseItem.items.push(modifierItemSale);
                                                filteredArray.push(pushedBaseItem);
                                            } else {
                                                pushedBaseItem.items.push(modifierItemSale);
                                            }

                                            break;
                                        }
                                    }
                                }
                            });
                        }
                    });

                    $scope.categorySalesData = filteredArray;
                    $scope.setAllShowModifiers(false);
                } else {
                    $scope.categorySalesData = $scope.originalSalesData;
                }
                calculateTotalSales();
                resetPagingOptions();
            };

            $scope.clearSearch = function () {
                $scope.searchBar.text = '';
                $scope.categorySalesData = $scope.originalSalesData;
            };

            $scope.exportToPdf = function (tableId) {
                Export.tableToPdf(tableId, 'landscape');
            };

            $scope.init();

            $scope.$on('$destroy', function () {
                clearTimeout(allowRetry);
            });

            $scope.$watch('advancedItemSalesSearch.locationId', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('advancedItemSalesSearch.startDateTime', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('advancedItemSalesSearch.endDateTime', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });
        }
    ]);

};
