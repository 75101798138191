'use strict';


module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ProductMenuCreateCtrl', [
        '$scope',
        '$translate',
        '$log',
        'CurrentSession',
        'Lookup',
        'PosAlertService',
        'MenuService',
        'menus',
        'currentPage',
        'isNew',
        function (
            $scope,
            $translate,
            $log,
            CurrentSession,
            Lookup,
            PosAlertService,
            MenuService,
            menus,
            currentPage,
            isNew) {

            const PAGE_TYPE = Object.freeze({
                CREATE: 'createPage',
                EDIT: 'editPage'
            });

            $scope.menus = angular.copy(menus);
            $scope.currentPage = currentPage;
            $scope.allLocationsToggle = {
                selected: false
            };
            $scope.isCreatingMenu = false;
            $scope.isNew = isNew;

            $scope.switchPage = (page) => {
                $scope.currentPage = page;
            };

            $scope.selectAll = (boolean) => {
                $scope.locations.forEach((location) => {
                    location.selected = boolean;
                });
            };

            // @PARAM selected: boolean
            const validateSelectAll = (selected) => {
                selected ? $scope.selectedCount++ : $scope.selectedCount--;
                if (!selected && $scope.allLocationsToggle.selected) {
                    // unselect the 'select all' checkbox if:
                    // 1. this location is unselected
                    // 2. 'select all' has been previously selected
                    $scope.allLocationsToggle.selected = false;
                } else if (selected && !$scope.allLocationsToggle.selected) {
                    // select the 'select all' checkbox if:
                    // 1. after this selected location, all locations are selected
                    // 2. 'select all' isn't checked
                    if ($scope.selectedCount === $scope.locations.length) {
                        $scope.allLocationsToggle.selected = true;
                    }
                }
            };

            // this function checks all children when a parent is selected.
            // this function is being called recursively to select children of children as well.
            const checkChildren = (parentLocation) => {
                parentLocation.children.forEach((childLocation) => {
                    childLocation.selected = parentLocation.selected;
                    if (childLocation.children.length) {
                        checkChildren(childLocation);
                    }
                });
            };

            $scope.selectedCount = 0;
            $scope.selectLocation = (location) => {
                validateSelectAll(location.selected);
                checkChildren(location);
            };

            $scope.closeModal = (newMenuCreated = false) => {
                // remove the blank menu we added on init
                // otherwise a 'blank menu' option will carryover to menu page.
                $scope.menus.all.shift();
                $scope.$close(newMenuCreated);
            };

            $scope.createMenu = () => {
                // return if already creating one to avoid multiple clicks.
                if ($scope.isCreatingMenu) {
                    return;
                }

                // a name must exist.
                if (!$scope.menus.toEdit.newName || $scope.menus.toEdit.newName === '') {
                    PosAlertService.showAlertByName('general-error', {
                        title: 'product.menu.create.error.ttl',
                        message: 'product.menu.create.error.menuName.desc'
                    });
                    return;
                }

                // a new name cannot be the same as an existing menu.
                const existingMenu = $scope.menus.all.find((menu) => {
                    // case insensitive.
                    return menu.menuName.toUpperCase() === $scope.menus.toEdit.newName.toUpperCase();
                });
                if (existingMenu) {
                    PosAlertService.showAlertByName('general-error', {
                        title: 'product.menu.create.error.ttl',
                        message: 'product.menu.create.error.menuName.desc'
                    });
                    return;
                }

                $scope.isCreatingMenu = true;

                // filter and map to selectedCompanyIds if selected.
                const selectedCompanyIds = $scope.locations.reduce((filtered, location) => {
                    if (location.selected) {
                        filtered.push(location.companyId);
                    }
                    return filtered;
                }, []);

                const newMenu = {
                    menuId: null,
                    organizationId: CurrentSession.getCompany().organizationId,
                    menuName: $scope.menus.toEdit.newName,
                    menuTemplateId: $scope.menus.toEdit.menuId || null,
                    associatedCompanyIds: selectedCompanyIds
                };

                MenuService.createNewMenu(newMenu)
                .then(() => {
                    $scope.isCreatingMenu = false;
                    $scope.closeModal(true);
                }).catch((err) => {
                    $scope.isCreatingMenu = false;
                    $log.error(err);
                    PosAlertService.showAlertByName('general-error', {
                        title: 'product.menu.create.error.ttl',
                        message: 'product.menu.create.error.desc'
                    });
                });
            };

            $scope.editMenu = () => {
                // return if already editing one to avoid multiple clicks.
                if ($scope.isCreatingMenu) {
                    return;
                }

                // a name must exist.
                if (!$scope.menus.toEdit.newName || $scope.menus.toEdit.newName === '') {
                    PosAlertService.showAlertByName('general-error', {
                        title: 'product.menu.create.error.ttl',
                        message: 'product.menu.create.error.menuName.desc'
                    });
                    return;
                }

                $scope.isCreatingMenu = true;

                // filter and map to selectedCompanyIds if selected.
                const selectedCompanyIds = $scope.locations.reduce((filtered, location) => {
                    if (location.selected) {
                        filtered.push(location.companyId);
                    }
                    return filtered;
                }, []);

                const menuToEdit = {
                    menuId: $scope.menus.toEdit.menuId,
                    organizationId: CurrentSession.getCompany().organizationId,
                    menuName: $scope.menus.toEdit.newName,
                    associatedCompanyIds: selectedCompanyIds
                };

                MenuService.editMenu(menuToEdit)
                .then(() => {
                    $scope.isCreatingMenu = false;
                    $scope.closeModal(true);
                    MenuService.updateMobileMenu(CurrentSession.getCompany().locationId);
                }).catch((err) => {
                    $scope.isCreatingMenu = false;
                    $log.error(err);
                    PosAlertService.showAlertByName('general-error', {
                        title: 'product.menu.create.error.ttl',
                        message: 'product.menu.create.error.desc'
                    });
                });
            };

            const selectAssociatedCompanies = (company) => {
                const companyIds = company.associatedCompanyIds;

                // if companyIds doesn't exist or is 0, simply return.
                if (!companyIds || !companyIds.length) {
                    return;
                }

                companyIds.forEach((companyId) => {
                    const selectedLocation = $scope.locations.find((location) => {
                        return location.companyId === companyId;
                    });
                    // It's possible to not find the company or location if they have been deleted
                    if (selectedLocation) {
                        selectedLocation.selected = true;
                    }
                });
            };

            // Helper function for buildLocationHierarchy.
            // This function is used recursively to build out the children nodes for a given location.
            // @param currentNode: the node that children is required to be built in
            // @param level: how many levels deep this currently is
            const buildChildrenForLocation = (currentNode, level = 0) => {
                // find all children of currentNode
                let matchedLocations = $scope.locations.filter((location) => {
                    if (location.data && location.data.parentCompanyId) {
                        return location.data.parentCompanyId === currentNode.companyId;
                    }
                });

                level++;

                // loop through the children and push them to currentNode.children
                for (let matchedLocation of matchedLocations) {
                    matchedLocation.showChildren = false;
                    matchedLocation.level = level;

                    // add matchedLocation to the currentNode
                    currentNode.children.push(matchedLocation);

                    // build matchedLocation's child
                    buildChildrenForLocation(matchedLocation, level);
                }
            };


            $scope.locationHierarchy = [];
            const buildLocationHierarchy = (response) => {
                // find current company and get locations.
                const company = _.findWhere(response, {id: CurrentSession.getCompany().companyId});
                let locations = (company && company.children) ? company.children : [];

                if (locations.length) {
                    if (locations.length === 1) {
                        locations[0].level = 0;
                        $scope.locationHierarchy.push(locations[0]);
                    } else {
                        let rootLocation = locations.find((location) => {
                            var data = location.data || {};
                            var parentCompanyId = data.parentCompanyId || undefined;

                            return !parentCompanyId;
                        });

                        rootLocation.showChildren = true;
                        rootLocation.level = 0;

                        buildChildrenForLocation(rootLocation);
                        $scope.locationHierarchy.push(rootLocation);
                    }
                }
            };

            const loadCompanyHierarchy = () => {
                $scope.locations = [];

                Lookup.locationsByCompany({}, (response) => {
                    $scope.company = response.find((company) => {
                        return company.id === CurrentSession.getCompany().companyId;
                    });
                    let children = ($scope.company && $scope.company.children) ? $scope.company.children : [];

                    if (children.length) {
                        $scope.locations.push(...children);
                    }

                    if ($scope.currentPage === PAGE_TYPE.EDIT && !$scope.isNew) {
                        selectAssociatedCompanies($scope.menus.selected);
                    }

                    buildLocationHierarchy(response);
                });
            };

            $scope.displayChildren = (location) => {
                location.showChildren = !location.showChildren;
            };

            const addBlankMenu = () => {
                // add a blank menu option at the start of array.
                $scope.menus.all.unshift({
                    menuId: null,
                    menuName: $translate.instant('product.menu.create.blankMenu.label')
                });
            };

            const setMenu = () => {
                // set the selected menu from product page
                // as the toEdit menu.
                $scope.menus.toEdit = $scope.menus.selected;

                // if editing a menu, use the existing menuName as the 'newName'
                // because the input element is shared between both creates/edits
                if (!$scope.isNew) {
                    $scope.menus.toEdit.newName = $scope.menus.toEdit.menuName;
                } else {
                    $scope.menus.toEdit.newName = '';
                }
            };

            $scope.init = () => {
                loadCompanyHierarchy();
                addBlankMenu();
                setMenu();
            };

            $scope.init();
        }]);
};
