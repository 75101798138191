'use strict';

(function () {
    var FiitMealPlanCalculationService = (function () {
        var FiitMealPlanCalculationService = function () {};

        const SharedDataService = require('./pos.data-service.js');
        const Decimal = require('decimal.js').default;
        const lodash = require('lodash');
        const _ = require('underscore');
        const AppConstants = require('../common/freshideas/app-constants.js');
        const UI_TENDER_TYPE = AppConstants.uiTenderTypeConstants;

        const OVERRIDE = FiitMealPlanCalculationService.prototype.OVERRIDE = 'OVERRIDE';
        FiitMealPlanCalculationService.prototype.AUTOCALCULATE = 'AUTOCALCULATE';
        FiitMealPlanCalculationService.prototype.MODIFY = 'MODIFY';

        function sortPlansByFiitPriority (a, b) {
            return a.priority - b.priority;
        }

        /**
        *** Commented By Akash Mehta
        *** This is a custom sorting of tenders used whenever the useFiitPriority is passed as false.
        *** The priority used in sorting below is as follows:
        *** 1.) Any recently updated/overriden plan (Here update means, changing the requested amount to be duducted from a plan)
        *** 2.) All NON - GUEST Meal Plans of type 'MEAL' sorted by their FIIT priority
        *** 3.) All GUEST Meal Plans of type 'MEAL' sorted by their FIIT priority.
        *** 4.) All Tax Free Meal Plans of type 'DCB' sorted by their FIIT priority.
        *** 5.) All Taxable Meal Plans of type 'DCB' sorted by their FIIT priority.
        ***
        *** NOTE: The reason for this sorting (combined with the custom item sorting) is to ensure
        *** that we exhaust a Student's DCB as much as possible
        ***
        ***/
        function sortPlansByDefaultPriority (a, b) {
            if (a.modificationType) {
                return -1;
            } else if (b.modificationType) {
                return 1;
            }

            if (!a.mealPlanType && !b.mealPlanType) {
                return 0;
            }

            if (!a.mealPlanType) {
                return 1;
            }

            if (!b.mealPlanType) {
                return -1;
            }

            if (a.mealPlanType == 'MEAL' && b.mealPlanType == 'MEAL') {
                return (a.guestPlan === b.guestPlan)? sortPlansByFiitPriority(a, b) : (a.guestPlan ? 1 : -1);
            }

            if (a.mealPlanType == 'MEAL' && b.mealPlanType != 'MEAL') {
                return -1;
            }

            if (a.mealPlanType != 'MEAL' && b.mealPlanType == 'MEAL') {
                return 1;
            }

            if (a.mealPlanType == 'DCB' && b.mealPlanType == 'DCB') {
                return (a.taxFree === b.taxFree)? sortPlansByFiitPriority(a, b) : (a.taxFree ? -1 : 1);
            }

            if (a.mealPlanType == 'DCB' && b.mealPlanType != 'DCB') {
                return -1;
            }

            if (a.mealPlanType != 'DCB' && b.mealPlanType == 'DCB') {
                return 1;
            }

            return 0;
        }

        /**
        *** Commented By Akash Mehta
        *** This is a custom sorting of items used for meal Plans of type 'MEAL' or if the plans are meal Equivalency Enabled.
        *** The sorting follows the 4 steps as below:
        *** 1.) Sort all items as per item level (0 means the top item, Anything greater than 0 means modifiers).
        *** 2.) Sort all top level items as per their tax inclusive price lowest to highest.
        *** 3.) Sort all items again such that every top item is followed by its set of modifiers.
        *** 4.) Sort all modifiers as per their tax inclusive price lowest to highest.
        ***
        *** NOTE: The reason for this sorting (combined with the custom tender sorting) is to ensure
        *** that we exhaust a Student's DCB as much as possible
        ***/
        function sortReceiptForCustomMealUnitsPriority (applicableItems) {
            applicableItems.sort(function (a, b) {
                return a.level - b.level;
            });

            applicableItems.sort(function (a, b) {
                if (a.level == 0 && b.level == 0) {
                    return a.remainingPriceTaxIn.minus(b.remainingPriceTaxIn).toNumber();
                }
            });

            applicableItems.sort(function (a, b) {
                if (a.level == 0 & b.level == 0) {
                    return 0;
                }

                if (a.level != 0 || b.level != 0) {
                    return a.parentItemIndex - b.parentItemIndex;
                }
            });

            applicableItems.sort(function (a, b) {
                if (a.level == 0 || b.level == 0) {
                    return 0;
                }

                return a.remainingPriceTaxIn.minus(b.remainingPriceTaxIn).toNumber();
            });
        }

        /**
        *** Commented By Akash Mehta
        *** This is a custom sorting of items used for meal Plans of type 'DCB'.
        *** The sorting follows the 4 steps as below:
        *** 1.) Sort all items as per item level (0 means the top item, Anything greater than 0 means modifiers).
        *** 2.) Sort all top level items in the following order : Taxable (highest to lowest) followed by Non-Taxable (highest to Lowest).
        *** 3.) Sort all items again such that every top item is followed by its set of modifiers.
        *** 4.) Sort all modifiers in the following order : Taxable (highest to lowest) followed by Non-Taxable (highest to Lowest).
        ***
        *** NOTE: The reason for this sorting (combined with the custom tender sorting) is to ensure
        *** that we exhaust a Student's DCB as much as possible
        ***/
        function sortDCBApplicableItems (applicableItems) {
            applicableItems.sort(function (a, b) {
                return a.level - b.level;
            });

            applicableItems.sort(function (a, b) {
                if (a.level == 0 && b.level == 0) {
                    var isATaxable = !!a.taxRate;
                    var isBTaxable = !!b.taxRate;

                    if (isATaxable && !isBTaxable) {
                        return -1;
                    }

                    if (!isATaxable && isBTaxable) {
                        return 1;
                    }

                    return b.remainingPriceTaxIn.minus(a.remainingPriceTaxIn).toNumber();
                }
            });

            applicableItems.sort(function (a, b) {
                if (a.level == 0 & b.level == 0) {
                    return 0;
                }

                if (a.level != 0 || b.level != 0) {
                    return a.parentItemIndex - b.parentItemIndex;
                }
            });

            applicableItems.sort(function (a, b) {
                if (a.level == 0 || b.level == 0) {
                    return 0;
                }

                var isATaxable = !!a.taxRate;
                var isBTaxable = !!b.taxRate;

                if (isATaxable && !isBTaxable) {
                    return -1;
                }

                if (!isATaxable && isBTaxable) {
                    return 1;
                }
                return b.remainingPriceTaxIn.minus(a.remainingPriceTaxIn).toNumber();
            });
        }

        /**
        *** Commented By Akash Mehta
        *** This function is called before starting the calculations. The purpose of this function, is to ensure that
        *** if a top level item is Meal Equivalency Enabled, all the child items become meal plan eligible as well.
        *** We need this function because unlike FIIT, one cannot set Meal Equivalency Enabled on a modifier level.
        ***/
        function populateAvailableItemsForMealEquivalency (applicableItems) {
            var topLevelItems = applicableItems.filter((item) => item.level == 0);
            var mealEquivalentItems = {};
            for (var topItem of topLevelItems) {
                if (topItem.mealEquivalencyEnabled) {
                    mealEquivalentItems[topItem.index] = topItem.mealEquivalencyEnabled;
                }
            }

            _.each(applicableItems, function (item) {
                if (item.level != 0 && mealEquivalentItems[item.index]) {
                    item.mealEquivalencyEnabled = true;
                }
            });
        }

        /**
        *** Commented By Akash Mehta
        *** This function is called by Unit tests to test the custom item sorting as per the plan type.
        ***/
        FiitMealPlanCalculationService.prototype.testItemSortingUsingCustomPriority = function (availableItems, type) {
            if (type === 'DCB') {
                sortDCBApplicableItems(availableItems);
                return availableItems;
            }

            if (type === 'MEAL') {
                sortReceiptForCustomMealUnitsPriority(availableItems);
                return availableItems;
            }

            return availableItems;
        };

        /**
        *** Commented By Akash Mehta
        *** This function is called by Unit tests to test the custom plan sorting.
        ***/
        FiitMealPlanCalculationService.prototype.testPlanSortingUsingCustomPriority = function (plans) {
            plans.sort(sortPlansByDefaultPriority);
            return plans;
        };

        function combineIds (units) {
            var idSet = new Set();
            for (var i = units.length - 1; i >= 0; i--) {
                var obj = units[i];
                idSet.add(obj.id);
            }
            var arr = Array.from(idSet);
            return arr.join(',');
        }
        function combineRemainingMealUnit (a, b) {
            return {'remainingMealUnits': a.remainingMealUnits + b.remainingMealUnits};
        }
        function combineUnit (a, b) {
            return {'unit': a.unit.plus(b.unit).toNearest(SharedDataService.baseDollar)};
        }
        function combineMealUnit (a, b) {
            return {'unit': a.unit + b.unit};
        }
        function combineMealUnitDollarValue (a, b) {
            return {'dollarValue': a.dollarValue.plus(b.dollarValue).toNearest(SharedDataService.baseDollar)};
        }
        function combineTax (a, b) {
            return {'tax': a.tax.plus(b.tax).toNearest(SharedDataService.baseDollar)};
        }
        function combineRemainingPrice (a, b) {
            return {'remainingPrice': a.remainingPrice.plus(b.remainingPrice).toNearest(SharedDataService.baseDollar)};
        }
        function combineRemainingPriceTaxIn (a, b) {
            return {'remainingPriceTaxIn': a.remainingPriceTaxIn.plus(b.remainingPriceTaxIn).toNearest(SharedDataService.baseDollar)};
        }
        function combineOriginalPrice (a, b) {
            return {'originalPrice': a.originalPrice.plus(b.originalPrice).toNearest(SharedDataService.baseDollar)};
        }
        function combineOriginalPriceTaxIn (a, b) {
            return {'originalPriceTaxIn': a.originalPriceTaxIn.plus(b.originalPriceTaxIn).toNearest(SharedDataService.baseDollar)};
        }

        /**
        *** Commented By Akash Mehta
        *** This is a new function we are adding to calculate how many meal units were deducted from
        *** a particular meal plan. Till now, we were only concerned with the total meal units used, but
        *** going forward, we want to know how many meal units are deducted from which meal plan.
        *** This function does as following:
        *** 1. We first calculate the total dollar value used against every plan.
        *** 2. Then we iterate over every plan and divide the newly calculated dollar value by the allowed meal
        ***    equivalencyDollar amount.
        *** 3. Finally we ceil the calculated meal units from the above step to the nearest whole number giving
        ***    us the final number of meal units used per plan.
        ***
        *** This logic is similar to the basic workflow of using meal units for meal equivalency. If anything changes
        *** in that workflow, we need to update this logic as well.
        ***/
        function convertDollarAmountOfMealEquivalencyToMealUnits (paymentsObj, allowedMealEquivalencyDollar) {
            var mealUnitsUsedForMealEq = paymentsObj.mealEqUnits.reduce(function (r, a) {
                r[a.id] = r[a.id] || {
                    id: a.id,
                    mealPlanName: a.mealPlanName,
                    mealPlanType: a.mealPlanType,
                    unit: new Decimal(0),
                    tax: new Decimal(0),
                    taxable: a.taxable,
                    guestPlan: a.guestPlan,
                    isDiscount: a.isDiscount,
                    dollarValue: new Decimal(0),
                    itemIdArr: []
                };

                r[a.id].unit = r[a.id].unit.plus(a.unit);
                r[a.id].tax = r[a.id].tax.plus(a.tax);
                r[a.id].dollarValue = r[a.id].dollarValue.plus(a.dollarValue);
                r[a.id].itemIdArr.push(a.itemId);
                return r;
            }, Object.create(null));

            if (mealUnitsUsedForMealEq) {
                Object.keys(mealUnitsUsedForMealEq).forEach(function (mealPlanId) {
                    var mealPlan = mealUnitsUsedForMealEq[mealPlanId];
                    if (mealPlan && mealPlan.id) {
                        mealPlan.unit = mealPlan.unit.div(new Decimal(allowedMealEquivalencyDollar)).ceil();
                        mealPlan.itemIds = mealPlan.itemIdArr.join();
                        delete mealPlan.itemIdArr;
                        paymentsObj.mealUnitsUsedForMealEq.push(mealPlan);
                    }
                });
            }
        }

        /**
        *** Commented By Akash Mehta
        *** This is a new function we are adding to validate 2 things:
        *** 1.) There is no item in the basket with the effictive subtotal (inclusive of all modifiers & discounts) less than 0.
        *** 2.) The effective subtotal of the basket is not less than 0.
        ***
        *** The logic to calculate the effective subtotals & totals is pretty straightforward and is highly depended on
        *** our actual basket calculations. If anything changes in that workflow, we need to update this logic as well.
        ***/
        function validateTransaction (applicableItems, adjustments) {
            var topLevelItems = applicableItems.filter((item) => item.level == 0);
            var effectiveTransactionSubtotal = new Decimal(0);
            var effectiveTransactionTotal = new Decimal(0);
            for (var item of topLevelItems) {
                var effectiveItemSubtotal = new Decimal(item.price);
                var effectiveItemTotal = new Decimal(item.total);
                var allModifiers = applicableItems.filter((appItem) => appItem.level != 0 && appItem.index == item.index);
                _.each(allModifiers, function (modifierItem) {
                    effectiveItemSubtotal = effectiveItemSubtotal.plus(modifierItem.price);
                    effectiveItemTotal = effectiveItemTotal.plus(modifierItem.total);
                });

                if (effectiveItemSubtotal.lessThan(0) || effectiveItemTotal.lessThan(0)) {
                    return {
                        isValid: false,
                        items: [item.name || ''],
                        errorMsg: 'patron.popup.tender.negative.item'
                    };
                }

                effectiveTransactionSubtotal = effectiveTransactionSubtotal.plus(effectiveItemSubtotal);
                effectiveTransactionTotal = effectiveTransactionTotal.plus(effectiveItemTotal);
            }

            effectiveItemSubtotal = effectiveTransactionSubtotal.minus(adjustments.dollarDiscount || new Decimal(0));


            if (effectiveTransactionSubtotal.lessThan(0) || effectiveTransactionTotal.lessThan(0)) {
                return {
                    isValid: false,
                    errorMsg: 'patron.popup.tender.negative.transaction'
                };
            }

            return {
                isValid: true
            };
        }

        /**
        *** Commented By Akash Mehta
        *** This is a function to process the adjustments object and ensure that all required fields in the adjustments object
        *** are initialized
        ***/
        var processAdjustments = FiitMealPlanCalculationService.prototype.processAdjustments = function (adjustments) {
            var adjust = adjustments || {
                dcbAdjustment: 0,
                cashAdjustment: 0,
                creditCardAdjustment: 0,
                debitCardAdjustment: 0,
                percentDiscount: 0,
                dollarDiscount: 0,
                mealEquivalencyAdjustment: false
            };

            adjust.dcbAdjustment = adjust.dcbAdjustment || 0;
            adjust.cashAdjustment = adjust.cashAdjustment || 0;
            adjust.creditCardAdjustment = adjust.creditCardAdjustment || 0;
            adjust.debitCardAdjustment = adjust.debitCardAdjustment || 0;
            adjust.percentDiscount = adjust.percentDiscount || 0;
            adjust.dollarDiscount = adjust.dollarDiscount || 0;
            adjust.mealEquivalencyAdjustment = adjust.mealEquivalencyAdjustment || false;
            adjust.giftCardAdjustment = adjust.giftCardAdjustment || 0;

            return adjust;
        };

        /**
        *** Commented By Akash Mehta
        *** This is a new function we are adding to parse the original response into a minified one for the patron Modal.
        *** This was basically done to avoid major code change, since we added this file after the patron modal was redesigned
        *** and develop.
        ***/
        FiitMealPlanCalculationService.prototype.repackageRawCalculationResponse = function (response) {

            var gatewayResponse = {};

            if (response.error_code) {
                gatewayResponse.exception = {
                    code: response.error_code,
                    message: response.error_msg,
                    data: response.error_data
                },
                gatewayResponse.items = response.items;
                gatewayResponse.error = true;

                return gatewayResponse;
            }

            gatewayResponse.items = response.items;
            gatewayResponse.payments = response.payments;
            gatewayResponse.approvedAmount = new Decimal(response.tenderAmounts.totalApprovedTenderAmount || 0).toNearest(SharedDataService.baseDollar).toNumber();
            gatewayResponse.approvedDCBAmount = new Decimal(response.tenderAmounts.dcbAmount || 0).toNearest(SharedDataService.baseDollar).toNumber();
            gatewayResponse.approvedMealEqAmount = new Decimal(response.tenderAmounts.mealEqAmount || 0).toNearest(SharedDataService.baseDollar).toNumber();
            gatewayResponse.approvedMeal = new Decimal(response.tenderAmounts.mealPlanCount || 0).toNearest(SharedDataService.baseDollar).toNumber();
            gatewayResponse.approvedGiftCardAmount = new Decimal(response.tenderAmounts.giftCardAmount || 0).toNearest(SharedDataService.baseDollar).toNumber();
            gatewayResponse.outstandingAmount = new Decimal(response.tenderAmounts.remainingBalance || 0).toNearest(SharedDataService.baseDollar).toNumber();
            gatewayResponse.partiallyApproved = (gatewayResponse.outstandingAmount != 0.00) ? true : false;
            gatewayResponse.tenderAmounts = response.tenderAmounts;

            return gatewayResponse;
        };

        /**
        *** Commented By Akash Mehta
        *** This function is used to initialize the payments object.
        ***/
        function initPaymentsObject () {
            return {
                'mealUnits': [],
                'mealEqUnits': [],
                'dcbUnits': [],
                'icbUnits': [],
                'discountUnits': [],
                'totalMeals': 0,
                'subTotalAmount': new Decimal(0),
                'totalAmount': new Decimal(0),
                'taxAmount': new Decimal(0),
                'totalDiscount': new Decimal(0),
                'unaccounted': new Decimal(0),
                'mealUnitsUsedForMealEq': [],
                'giftCardUnits': []
            };
        }

        /**
        *** Commented By Akash Mehta on June 22 2020
        *** This function checks if an item can be deferred for meal units or not.
        *** This step is required because in the case of FIIT priority, a DCB plan can come before a Meal Unit Plan.
        *** In this case, the item and/or its modifiers eligible for meal exchange might get deducted with the high priority 'DCB' plan.
        *** In order to avoid that, the below functions checks if the said item can actually be deferred or not.
        *** The criteria involved in order to declare an item as deferrable is as follows:
        *** 1. The available plans must have meal units available.
        *** 2. The requiredUnits array must have total remaining meal units to be deducted greater than 0
        *** 3. The total remainingMealUnits should be less than equal to the total availableMealUnits
        *** 4. The length of deferred items array should be less than the available meal units.
        ***
        *** If a top level item (level = 0) satisfies the above criteria and itself has meal units remaining, we defer the said item
        *** and add its itemIndex to the deferred item array.
        *** if a child level item (level != 0) satisfies the above criteria and if its respective parentItemIndex is in the deferred array,
        *** we defer the said item BUT in this case, we DO NOT append the child itemIndex to the deferred item array.
        ***
        *** Else the item is considered as non-deferrable towards MealExchange/Meal Equivalency and we proceed ahead with iteration.
        ***/
        function canDeferMealUnit (availableUnits, requiredUnits, posData, item) {
            var remainingMealUnits = requiredUnits.mealUnits.length > 0 ? requiredUnits.mealUnits.reduce(combineRemainingMealUnit).remainingMealUnits : 0;
            var availableMealUnits = availableUnits.mealUnits;

            // Commented By Akash Mehta on 26th October 2020
            // We had an issue where an meal exchangeable item with modifiers gets
            // deferred to a meal unit (even if their DCB plan has higher priority)
            // However, if the required meal units in the entire cart/basket is greater
            // than the available meal units, it charges DCB for the modifiers of the last
            // meal exchangeable item to be deferred. Cleaning up the deferrred logic, helps out here
            if (item.level == 0 && item.remainingMealUnits > 0
                && availableMealUnits > 0 && remainingMealUnits > 0
                && (remainingMealUnits <= availableMealUnits || posData.deferred.length < availableMealUnits)) {
                posData.deferred.push(item.itemIndex);
                return true;
            } else if (item.level != 0 && posData.deferred.includes(item.parentItemIndex)) {
                return true;
            }

            return false;
        }

        /**
        *** Commented By Akash Mehta
        *** This is a new function we are adding to deduct all Modifiers for a meal EXCHANGED Item.
        *** So the new flow established in Nown is that if an item is Meal Exchanged, all the modifiers are considered to be meal
        *** exchanged as well for the SAME UNIT , irrepsective of the total price of the item.
        *** So, for eg. a meal exchangeable Item worth 2$ and having modifiers worth 40$ is still exchangeable for one meal unit
        *** (assuming the item requires only one meal unit)
        *** Also , we have started tracking the dollar value of an item for every payment. Here even though the payment has 0 units for modifiers,
        *** it still has a dollar value associated with it.
        *** NOTE: The mapping between item is established in the function `calculateRequiredUnits`
        ***/
        function deductMealUnitsForAllModifiersForItem (unitPlan, applicableItems, deductAmount, parentItem, payments) {
            var childItems = applicableItems.filter((item) => item.parentItemIndex == parentItem.itemIndex && item.level != 0);

            _.each(childItems, function (item, index) {
                if (item.level != 0) {
                    var originalRemainingPrice = new Decimal(item.remainingPrice);
                    item.remainingPrice = item.remainingPrice.dividedBy(parentItem.remainingMealUnits).toNearest(SharedDataService.baseDollar);
                    item.remainingPriceTaxIn = item.remainingPriceTaxIn.dividedBy(parentItem.remainingMealUnits).toNearest(SharedDataService.baseDollar);
                    var remainingMealUnits = parentItem.remainingMealUnits - deductAmount;
                    item.remainingPrice = item.remainingPrice.times(remainingMealUnits).toNearest(SharedDataService.baseDollar);
                    item.remainingPriceTaxIn = item.remainingPriceTaxIn.times(remainingMealUnits).toNearest(SharedDataService.baseDollar);
                    var newRemainingPrice = new Decimal(item.remainingPrice);

                    var paymentItem = {
                        'id': unitPlan.mealPlanId,
                        'unit': 0,
                        'tax': 0,
                        'taxable': false,
                        'mealPlanName': unitPlan.name,
                        'mealPlanType': unitPlan.mealPlanType,
                        'guestPlan': !!unitPlan.guestPlan,
                        'isDiscount': item.isDiscount,
                        'itemId': item.id,
                        'itemIndex': item.itemIndex,
                        'dollarValue': originalRemainingPrice.minus(newRemainingPrice).toNearest(SharedDataService.baseDollar)
                    };

                    payments['mealUnits'].push(paymentItem);

                    item.cost.subtotal = item.cost.subtotal.plus(paymentItem.dollarValue);

                    if (item.isDiscount) {
                        item.cost.discountAmount = item.cost.discountAmount.plus(paymentItem.dollarValue);
                    }

                    item.cost.nonTaxableAmount = item.cost.nonTaxableAmount.plus(paymentItem.dollarValue);

                }
            });

            /**
            *** Commented By Akash Mehta
            *** This loop is necessary because:
            *** We filter the objects above into a new array. Hence all the modifications made in the code block above
            *** are not reflected on the original array of items. The code block below handles the carrying over of the changes
            *** from the new array of items to the original array of items.
            ***/
            _.each(applicableItems, function (item) {
                var modifiedChildItem = childItems.find((childItem) => childItem.itemIndex === item.itemIndex);
                if (modifiedChildItem) {
                    item.remainingPrice = modifiedChildItem.remainingPrice;
                    item.remainingPriceTaxIn = modifiedChildItem.remainingPriceTaxIn;
                    item.cost = modifiedChildItem.cost;
                }
            });
        }

        /**
        *** Commented By Akash Mehta
        *** This is a new function we are adding to process all Negative Modifiers for a parent Item.
        *** So the new flow established in Nown is that if an item has negative modifiers or a discount modifier associated with it,
        *** we process them first before we process the item itself.
        *** This is a better way of handling negative modifiers and discounts.
        *** NOTE: The mapping between item is established in the function `calculateRequiredUnits`
        ***/
        function processAllNegativeModifiersForDCB (planId, unitPlan, unitsToDeduct, taxFree, payments, unitProperty, adjustments, applicableItems, parentItem) {
            var childItems = [];
            // If it is a main item, get all discounts as well as negative modifiers mapped to the item
            // else get only discounts as a modifier cannot have child modifiers
            if (parentItem.level === 0) {
                childItems = applicableItems.filter((item) => item.parentItemIndex == parentItem.itemIndex && item.level != 0 && item.remainingPriceTaxIn.lessThan(0));
                // The below filtering is required as we need to get only discounts associated with the parent/top level item
                // The discounts of the modifiers will be handled in the else block below
                childItems = childItems.filter((item) => item.id != -2 || (item.id == -2 && item.parentItemId == parentItem.id));
            } else {
                childItems = applicableItems.filter((item) => (item.id == -2 && item.parentItemId == parentItem.id && item.parentItemIndex == parentItem.parentItemIndex && item.remainingPriceTaxIn.lessThan(0)));
            }


            _.each(childItems, function (item, index) {
                if (item.level != 0 && item.remainingPrice.lessThan(0)
                    && item.remainingPriceTaxIn.lessThan(0) && unitsToDeduct.greaterThan(0)) {

                    var unitAmount = new Decimal(item.remainingPrice);
                    var unitAmountTaxIn = new Decimal(item.remainingPriceTaxIn);
                    var deductAmount = taxFree ? unitAmount : unitAmountTaxIn;

                    unitsToDeduct = unitsToDeduct.minus(deductAmount).toNearest(SharedDataService.baseDollar);

                    var taxAmount = deductAmount.minus(unitAmount).toNearest(SharedDataService.baseDollar);
                    var paymentItem = {
                        'id': planId,
                        'unit': deductAmount.toNearest(SharedDataService.baseDollar),
                        'tax': taxAmount.toNearest(SharedDataService.baseDollar),
                        'taxable': !taxFree,
                        'mealPlanName': unitPlan.name,
                        'mealPlanType': unitPlan.mealPlanType,
                        'guestPlan': !!unitPlan.guestPlan,
                        'isDiscount': item.isDiscount,
                        'itemId': item.id,
                        'itemIndex': item.itemIndex,
                        'dollarValue': deductAmount.toNearest(SharedDataService.baseDollar)
                    };

                    /**
                    *** Commented By Akash Mehta
                    *** Deduct the max allowed meal equivalency for this transaction
                    *** i.e., totalMealUnitsAllowed * dollar value of one meal Unit.
                    *** This is needed else, we will be using the above calculated value
                    *** for every meal plan with the type 'MEAL'.
                    *** For eg, Consider the scenario:
                    *** - Transaction total: $15 + Tax
                    *** - Allowed Meal Equivalency: $10 (2 units * 5$)
                    *** - Available Plans: 2 plans of type 'MEAL' (first one with balance 1 & second one with balance 10)
                    *** If we did not have this check, our code will pass $10 allowed equivalency for both plans.
                    *** The first plan will apply 1 unit and the 2nd plan will apply 2 units whereas we should have only allowed 2 units to be used in total
                    ***/
                    if (unitProperty === 'mealEqUnits') {
                        adjustments.maxAllowedMealEquivalencyDollarAmount = new Decimal(adjustments.maxAllowedMealEquivalencyDollarAmount).minus(deductAmount).toNearest(SharedDataService.baseDollar).toNumber();
                    }

                    payments[unitProperty].push(paymentItem);

                    item.remainingPrice = item.remainingPrice.minus(unitAmount);
                    item.remainingPriceTaxIn = item.remainingPriceTaxIn.minus(unitAmountTaxIn);
                    item.remainingMealUnits = 0;

                    item.cost.subtotal = item.cost.subtotal.plus(unitAmount);
                    item.cost.tax = item.cost.tax.plus(taxAmount);


                    if (unitProperty == 'discountUnits' || item.isDiscount) {
                        if (unitAmount.greaterThanOrEqualTo(0)) {
                            item.cost.discountAmount = item.cost.discountAmount.plus(unitAmount.times(-1));
                        } else {
                            item.cost.discountAmount = item.cost.discountAmount.plus(unitAmount);
                        }
                    }

                    if (unitProperty != 'discountUnits') {
                        if (taxFree) {
                            item.cost.nonTaxableAmount = item.cost.nonTaxableAmount.plus(unitAmount);
                        } else {
                            item.cost.taxableAmount = item.cost.taxableAmount.plus(unitAmount);
                        }
                    }
                }
            });

            /**
            *** Commented By Akash Mehta
            *** This loop is necessary because:
            *** We filter the objects above into a new array. Hence all the modifications made in the code block above
            *** are not reflected on the original array of items. The code block below handles the carrying over of the changes
            *** from the new array of items to the original array of items.
            ***/
            _.each(applicableItems, function (item) {
                var modifiedChildItem = childItems.find((childItem) => childItem.itemIndex === item.itemIndex);
                if (modifiedChildItem) {
                    item.remainingPrice = modifiedChildItem.remainingPrice;
                    item.remainingPriceTaxIn = modifiedChildItem.remainingPriceTaxIn;
                    item.cost = modifiedChildItem.cost;
                }
            });

            return unitsToDeduct;
        }

        function deductMealUnit (unitPlan, unitsToProcess, requiredUnits, availableUnits, adjustments, payments, posData, requestedUnits, useFiitPriority) {
            var id = unitPlan.mealPlanId;
            var applicableItems = requiredUnits.mealUnits;

            sortReceiptForCustomMealUnitsPriority(applicableItems);


            var unitsToDeduct = new Decimal(unitsToProcess).toNumber();

            if (requestedUnits != undefined && new Decimal(unitsToDeduct).greaterThanOrEqualTo(new Decimal(requestedUnits))) {
                unitsToDeduct = new Decimal(requestedUnits).toDecimalPlaces(0).toNumber();
            }

            // Loop through applicable items
            _.each(applicableItems, function (item, index) {
                // If the applicable item has any remaining meal units to process, proceed
                if (item.level == 0 && item.remainingMealUnits > 0 && unitsToDeduct > 0) {

                    var unitMeals = item.remainingMealUnits;
                    var deductAmount = Math.min(unitsToDeduct, unitMeals);

                    if (unitsToDeduct > 0) {

                        unitsToDeduct = Math.max(unitsToDeduct - deductAmount, 0);

                        deductMealUnitsForAllModifiersForItem(unitPlan, applicableItems, deductAmount, item, payments);

                        var originalRemainingPrice = new Decimal(item.remainingPrice);
                        item.remainingPrice = item.remainingPrice.dividedBy(item.remainingMealUnits).toNearest(SharedDataService.baseDollar);
                        item.remainingPriceTaxIn = item.remainingPriceTaxIn.dividedBy(item.remainingMealUnits).toNearest(SharedDataService.baseDollar);
                        item.remainingMealUnits = item.remainingMealUnits - deductAmount;
                        item.remainingPrice = item.remainingPrice.times(item.remainingMealUnits).toNearest(SharedDataService.baseDollar);
                        item.remainingPriceTaxIn = item.remainingPriceTaxIn.times(item.remainingMealUnits).toNearest(SharedDataService.baseDollar);
                        var newRemainingPrice = new Decimal(item.remainingPrice);

                        var paymentItem = {
                            'id': id,
                            'unit': deductAmount,
                            'tax': 0,
                            'taxable': false,
                            'mealPlanName': unitPlan.name,
                            'mealPlanType': unitPlan.mealPlanType,
                            'guestPlan': !!unitPlan.guestPlan,
                            'isDiscount': false,
                            'itemId': item.id,
                            'itemIndex': item.itemIndex,
                            'dollarValue': originalRemainingPrice.minus(newRemainingPrice).toNearest(SharedDataService.baseDollar)
                        };

                        availableUnits['mealUnits'] = availableUnits['mealUnits'] - deductAmount;

                        payments['mealUnits'].push(paymentItem);

                        item.cost.subtotal = item.cost.subtotal.plus(paymentItem.dollarValue);
                        item.cost.nonTaxableAmount = item.cost.nonTaxableAmount.plus(paymentItem.dollarValue);
                        item.cost.mealPlanCount += deductAmount;
                    }
                }
            });

            if (unitPlan.mealEquivalencyEnabled) {
                /**
                *** Commented By Akash Mehta
                *** Here we get the  meal Equivalent amount based on the available units to process for the meal plan
                *** and the dollar value per unit.
                *** Then we do a min between the calculated  meal Equivalent amount and the remaining value of the maximum allowed
                *** meal equivalency for the entire transaction.
                *** This is to ensure that the correct meal equivalency is applied always.
                *** For eg, Consider the scenario:
                *** - Transaction total: $15 + Tax
                *** - Allowed Meal Equivalency: $10 (2 units * 5$)
                *** - Available Plans: 2 plans of type 'MEAL' (first one with balance 1 & second one with balance 10)
                *** If we did not track the maximum allowed meal equivalency for the entire transaction, our code will pass $10 allowed equivalency for both plans.
                *** The first plan will apply 1 unit and the 2nd plan will apply 2 units whereas we should have only allowed 2 units to be used in total
                ***/
                var mealEqAmount = new Decimal(unitsToDeduct).times(posData.allowedMealEquivalencyDollar).toNearest(SharedDataService.baseDollar).toNumber();
                var mealEqAllowedDollar = Math.min(mealEqAmount, adjustments.maxAllowedMealEquivalencyDollarAmount);

                if (mealEqAllowedDollar > 0 && posData.allowedMealEquivalency === true && (adjustments && adjustments.mealEquivalencyAdjustment === true)) {
                    deductUnit(unitPlan, true, mealEqAllowedDollar, 'mealEqUnits', 'mealEqUnits', requiredUnits, availableUnits, adjustments, payments, posData, null, useFiitPriority, posData.allowedMealEquivalencyDollar);
                }
            }
        }

        function deductUnit (unitPlan, taxFree, unitsToProcess, unitProperty, unitTaxProperty, requiredUnits, availableUnits, adjustments, payments, posData, requestedUnits, useFiitPriority) {
            var id = unitPlan.mealPlanId;

            var applicableItems = requiredUnits.cashUnits.concat(requiredUnits.mealUnits);

            if (unitProperty === 'mealEqUnits') {
                sortReceiptForCustomMealUnitsPriority(applicableItems);
            } else {
                sortDCBApplicableItems(applicableItems);
            }

            var unitsToDeduct = new Decimal(unitsToProcess);

            if (requestedUnits != undefined && unitsToDeduct.greaterThanOrEqualTo(new Decimal(requestedUnits))) {
                unitsToDeduct = new Decimal(requestedUnits);
            }

            _.each(applicableItems, function (item, index) {
                if (item.remainingPrice.greaterThan(0) && unitsToDeduct.greaterThan(0)) {
                    if (unitProperty === 'mealEqUnits' && !item.mealEquivalencyEnabled) {
                        return;
                    }

                    if (useFiitPriority
                        && unitProperty != 'discountUnits' && unitProperty != 'giftCardUnits'
                        && canDeferMealUnit(availableUnits, requiredUnits, posData, item)) {
                        return;
                    }

                    var taxRateMulti = new Decimal(1).plus(new Decimal(item.taxRate));
                    var unitAmount = new Decimal(item.remainingPrice);
                    var unitAmountTaxIn = new Decimal(item.remainingPriceTaxIn);
                    var deductAmount = taxFree ? unitAmount : unitAmountTaxIn;

                    unitsToDeduct = processAllNegativeModifiersForDCB(id, unitPlan, unitsToDeduct, taxFree, payments, unitProperty, adjustments, applicableItems, item);

                    if (unitsToDeduct.lessThan(deductAmount)) {
                        unitAmount = taxFree ? unitsToDeduct : unitsToDeduct.dividedBy(taxRateMulti);
                        unitAmountTaxIn = taxFree ? unitsToDeduct.times(taxRateMulti) : unitsToDeduct;
                        deductAmount = taxFree ? unitAmount : unitAmountTaxIn;
                    }


                    if (unitsToDeduct.greaterThan(0)) {
                        unitsToDeduct = (unitsToDeduct.greaterThanOrEqualTo(deductAmount)) ? unitsToDeduct.minus(deductAmount).toNearest(SharedDataService.baseDollar) : new Decimal(0);

                        var taxAmount = deductAmount.minus(unitAmount).toNearest(SharedDataService.baseDollar);
                        var paymentItem = {
                            'id': id,
                            'unit': deductAmount.toNearest(SharedDataService.baseDollar),
                            'tax': taxAmount.toNearest(SharedDataService.baseDollar),
                            'taxable': !taxFree,
                            'mealPlanName': unitPlan.name,
                            'mealPlanType': unitPlan.mealPlanType,
                            'guestPlan': !!unitPlan.guestPlan,
                            'isDiscount': unitProperty === 'discountUnits',
                            'itemId': item.id,
                            'itemIndex': item.itemIndex,
                            'dollarValue': deductAmount.toNearest(SharedDataService.baseDollar)
                        };

                        if (unitProperty !== 'mealEqUnits') {
                            if (taxFree) {
                                availableUnits[unitProperty] = availableUnits[unitProperty].minus(deductAmount);
                            } else {
                                availableUnits[unitTaxProperty] = availableUnits[unitTaxProperty].minus(deductAmount);
                            }
                        } else {
                            /**
                            *** Commented By Akash Mehta
                            *** Deduct the max allowed meal equivalency for this transaction
                            *** i.e., totalMealUnitsAllowed * dollar value of one meal Unit.
                            *** This is needed else, we will be using the above calculated value
                            *** for every meal plan with the type 'MEAL'.
                            *** For eg, Consider the scenario:
                            *** - Transaction total: $15 + Tax
                            *** - Allowed Meal Equivalency: $10 (2 units * 5$)
                            *** - Available Plans: 2 plans of type 'MEAL' (first one with balance 1 & second one with balance 10)
                            *** If we did not have this check, our code will pass $10 allowed equivalency for both plans.
                            *** The first plan will apply 1 unit and the 2nd plan will apply 2 units whereas we should have only allowed 2 units to be used in total
                            ***/
                            adjustments.maxAllowedMealEquivalencyDollarAmount = new Decimal(adjustments.maxAllowedMealEquivalencyDollarAmount).minus(deductAmount).toNearest(SharedDataService.baseDollar).toNumber();
                            availableUnits['mealUnits'] = availableUnits['mealUnits'] - 1;
                        }

                        payments[unitProperty].push(paymentItem);

                        item.remainingPrice = item.remainingPrice.minus(unitAmount);
                        item.remainingPriceTaxIn = item.remainingPriceTaxIn.minus(unitAmountTaxIn);
                        item.remainingMealUnits = 0;

                        item.cost.subtotal = item.cost.subtotal.plus(unitAmount);
                        item.cost.tax = item.cost.tax.plus(taxAmount);

                        if (unitProperty == 'discountUnits' || item.isDiscount) {
                            if (unitAmount.greaterThanOrEqualTo(0)) {
                                item.cost.discountAmount = item.cost.discountAmount.plus(unitAmount.times(-1));
                            } else {
                                item.cost.discountAmount = item.cost.discountAmount.plus(unitAmount);
                            }
                        }

                        if (unitProperty != 'discountUnits') {
                            if (taxFree) {
                                item.cost.nonTaxableAmount = item.cost.nonTaxableAmount.plus(unitAmount);
                            } else {
                                item.cost.taxableAmount = item.cost.taxableAmount.plus(unitAmount);
                            }
                        }
                    }
                } else if (item.originalPrice.equals(0) && unitsToDeduct.greaterThan(0) && item.level === 0) {
                    /**
                    *** Commented By Akash Mehta on 23rd October 2020
                    *** Adding a 0$ payment item for consistency
                    *** Ensuring that we set the required/remaining meal units for the item
                    *** to 0 to ensure that we don't deduct a meal unit for the required item.
                    *** There was a bug, we were charging dcb & meal for a 0$ item with non-zero modifiers
                    ***/
                    if (useFiitPriority
                        && unitProperty != 'discountUnits' && unitProperty != 'giftCardUnits'
                        && canDeferMealUnit(availableUnits, requiredUnits, posData, item)) {
                        return;
                    }

                    var zeroDollarPaymentItem = {
                        'id': id,
                        'unit': new Decimal(0),
                        'tax': new Decimal(0),
                        'taxable': false,
                        'mealPlanName': unitPlan.name,
                        'mealPlanType': unitPlan.mealPlanType,
                        'guestPlan': !!unitPlan.guestPlan,
                        'isDiscount': unitProperty === 'discountUnits',
                        'itemId': item.id,
                        'dollarValue': new Decimal(0)
                    };

                    payments[unitProperty].push(zeroDollarPaymentItem);
                    item.remainingMealUnits = 0;

                    /**
                    *** Commented By Akash Mehta
                    *** This code block is added to handle the case where the price of the top Level Item is 0 from the get go.
                    *** If that is the case it will never enter the if code block above and then we end up with a negative remaining balance
                    *** We need to still process that there are no negative modifiers associated for the top item. If there are any, we should
                    *** process them.
                    ***/
                    unitsToDeduct = processAllNegativeModifiersForDCB(id, unitPlan, unitsToDeduct, taxFree, payments, unitProperty, adjustments, applicableItems, item);
                }
            });
        }

        function processUnaccounted (requiredUnits) {

            var items = requiredUnits.cashUnits.concat(requiredUnits.mealUnits);
            var len = items.length;

            var itemsWithOutDiscount = items.filter((item) => !item.isDiscount);
            var discountItems = items.filter((item) => item.isDiscount);

            var result = {
                items: items,
                subTotalCost: len > 0 ? items.reduce(combineRemainingPrice).remainingPrice.toNearest(SharedDataService.baseDollar).toNumber() : 0,
                totalCost: len > 0 ? items.reduce(combineRemainingPriceTaxIn).remainingPriceTaxIn.toNearest(SharedDataService.baseDollar).toNumber() : 0,
                subTotalCostWithoutDiscounts: itemsWithOutDiscount.length > 0 ?
                        itemsWithOutDiscount.reduce(combineRemainingPrice).remainingPrice.toNearest(SharedDataService.baseDollar).toNumber() : 0,
                discounts: discountItems.length > 0 ? discountItems.reduce(combineRemainingPrice).remainingPrice.toNearest(SharedDataService.baseDollar).toNumber() : 0
            };

            var totalTaxable = new Decimal(0);
            var totalNonTaxable = new Decimal(0);
            _.each(items, function (item) {
                item.cost = item.cost || {
                    mealPlanCount: 0,
                    subtotal: new Decimal(0),
                    taxableAmount: new Decimal(0),
                    nonTaxableAmount: new Decimal(0),
                    tax: new Decimal(0),
                    taxRate: item.taxRate
                };

                var itemRemainingPrice = item.remainingPrice;
                var itemRemainingTax = item.remainingPriceTaxIn.minus(item.remainingPrice);

                item.cost.subtotal = item.cost.subtotal.plus(itemRemainingPrice);
                item.cost.tax = item.cost.tax.plus(itemRemainingTax);

                if (item.taxRate > 0) {
                    totalTaxable = totalTaxable.plus(item.remainingPrice);
                    item.cost.taxableAmount = item.cost.taxableAmount.plus(item.remainingPrice);
                } else {
                    totalNonTaxable = totalNonTaxable.plus(item.remainingPrice);
                    item.cost.nonTaxableAmount = item.cost.nonTaxableAmount.plus(item.remainingPrice);
                }
            });
            result.taxable = totalTaxable.toNearest(SharedDataService.baseDollar).toNumber();
            result.untaxable = totalNonTaxable.toNearest(SharedDataService.baseDollar).toNumber();

            return result;
        }

        function processGiftCardAmounts (requiredUnits, payments) {

            var giftCardPurchasedItemIndices = payments.giftCardUnits.map((payment) => {
                return payment.itemIndex;
            });

            var items = (requiredUnits.cashUnits.concat(requiredUnits.mealUnits)).filter((item) => {
                return giftCardPurchasedItemIndices.includes(item.itemIndex);
            });

            var itemsWithOutDiscount = items.filter((item) => !item.isDiscount);
            var discountItems = items.filter((item) => item.isDiscount);

            var subTotalCost = 0;
            var totalCost = 0;
            var subTotalCostWithoutDiscounts = 0;
            var discounts = 0;

            if (items.length > 0) {
                subTotalCost = items.reduce(combineOriginalPrice).originalPrice.minus(
                    items.reduce(combineRemainingPrice).remainingPrice).toNearest(SharedDataService.baseDollar).toNumber();
                totalCost = items.reduce(combineOriginalPriceTaxIn).originalPriceTaxIn.minus(
                    items.reduce(combineRemainingPriceTaxIn).remainingPriceTaxIn).toNearest(SharedDataService.baseDollar).toNumber();
            }

            if (itemsWithOutDiscount.length > 0) {
                subTotalCostWithoutDiscounts = itemsWithOutDiscount.reduce(combineOriginalPrice).originalPrice.minus(
                    itemsWithOutDiscount.reduce(combineRemainingPrice).remainingPrice).toNearest(SharedDataService.baseDollar).toNumber();
            }

            if (discountItems.length > 0) {
                discounts = discountItems.reduce(combineOriginalPrice).originalPrice.minus(
                    discountItems.reduce(combineRemainingPrice).remainingPrice).toNearest(SharedDataService.baseDollar).toNumber();
            }

            var result = {
                items: items,
                subTotalCost: subTotalCost,
                totalCost: totalCost,
                subTotalCostWithoutDiscounts: subTotalCostWithoutDiscounts,
                discounts: discounts
            };

            var totalTaxable = new Decimal(0);
            var totalNonTaxable = new Decimal(0);
            _.each(items, function (item) {
                var itemPrice = item.originalPrice.minus(item.remainingPrice);

                if (item.taxRate > 0) {
                    totalTaxable = totalTaxable.plus(itemPrice);
                } else {
                    totalNonTaxable = totalNonTaxable.plus(itemPrice);
                }
            });
            result.taxable = totalTaxable.toNearest(SharedDataService.baseDollar).toNumber();
            result.untaxable = totalNonTaxable.toNearest(SharedDataService.baseDollar).toNumber();

            return result;
        }

        function processTendered (posData, payments, adjust, unaccounted, giftCardItemAmounts) {

            var totalMealEquivalencyDollarUsed = payments.mealEqUnits.length > 0 ? Number(payments.mealEqUnits.reduce(combineUnit).unit.toFixed(2)) : 0;
            var totalMealEquivalencyUnitUsed = posData.allowedMealEquivalencyDollar > 0 ? Math.ceil(totalMealEquivalencyDollarUsed / posData.allowedMealEquivalencyDollar) : 0;

            var taxCost = 0;
            var totalCost = 0;
            var totalApprovedTenderAmount = 0;
            var totalMealUnitDollarValue = 0;

            // Comment Added By Akash Mehta on April 1 2020
            // This if block is added for enabling credit card transactions
            // on the calculation server for mobile pre-order. If credit card is used,
            // we need to add it to the total approved amount
            if (adjust.creditCardAdjustment) {
                totalApprovedTenderAmount += new Decimal(adjust.creditCardAdjustment).toNearest(SharedDataService.baseDollar).toNumber();
            }

            if (payments.mealUnits.length > 0) {
                payments.totalMeals = payments.mealUnits.reduce(combineMealUnit).unit;
                var combinedMealDollarValue = payments.mealUnits.reduce(combineMealUnitDollarValue).dollarValue.toNearest(SharedDataService.baseDollar).toNumber();
                totalApprovedTenderAmount += combinedMealDollarValue;
                totalMealUnitDollarValue += combinedMealDollarValue;
            }


            if (payments.mealEqUnits.length > 0) {
                var combinedDollarValue = payments.mealEqUnits.reduce(combineUnit).unit.toNearest(SharedDataService.baseDollar).toNumber();
                totalApprovedTenderAmount += combinedDollarValue;
                totalMealUnitDollarValue += combinedDollarValue;
            }

            if (payments.dcbUnits.length > 0) {
                var combinedDcbValue = payments.dcbUnits.reduce(combineUnit).unit.toNearest(SharedDataService.baseDollar).toNumber();
                totalCost += combinedDcbValue;
                taxCost += payments.dcbUnits.reduce(combineTax).tax.toNearest(SharedDataService.baseDollar).toNumber();

                totalApprovedTenderAmount += combinedDcbValue;
            }

            if (payments.giftCardUnits.length > 0) {
                var combinedGiftCardValue = payments.giftCardUnits.reduce(combineUnit).unit.toNearest(SharedDataService.baseDollar).toNumber();
                totalCost += combinedGiftCardValue;
                taxCost += payments.giftCardUnits.reduce(combineTax).tax.toNearest(SharedDataService.baseDollar).toNumber();

                totalApprovedTenderAmount += combinedGiftCardValue;
            }

            if (payments.discountUnits.length > 0) {
                taxCost += payments.discountUnits.reduce(combineTax).tax.toNearest(SharedDataService.baseDollar).toNumber();
            }

            payments.unaccounted = unaccounted.totalCost;
            payments.taxAmount = new Decimal(taxCost).plus(new Decimal(unaccounted.totalCost).minus(new Decimal(unaccounted.subTotalCost))).toNearest(SharedDataService.baseDollar).toNumber();
            payments.totalAmount = totalCost + unaccounted.totalCost;
            payments.subTotalAmount = (new Decimal(totalCost)).minus(new Decimal(taxCost)).plus(new Decimal(unaccounted.subTotalCost))
                    .toNearest(SharedDataService.baseDollar).toNumber();

            var tenderAmounts = {
                guestTransaction: posData.guestTransaction,
                locationId: lodash.cloneDeep(posData.shiftLocationId),
                mealPlanCount: payments.mealUnits.length > 0 ? Number(payments.mealUnits.reduce(combineMealUnit).unit) + totalMealEquivalencyUnitUsed : 0 + totalMealEquivalencyUnitUsed,
                mealEqAmount: totalMealEquivalencyDollarUsed,
                chargeAmount: payments.icbUnits.length > 0 ? Number(payments.icbUnits.reduce(combineUnit).unit.toFixed(2)) : 0,
                dcbAmount: payments.dcbUnits.length > 0 ? Number(payments.dcbUnits.reduce(combineUnit).unit.toFixed(2)) : 0,
                cashAmount: payments.giftCardUnits.length > 0 ? (Number(payments.giftCardUnits.reduce(combineUnit).unit) + Number(adjust.cashAdjustment.toFixed(2))) : Number(adjust.cashAdjustment.toFixed(2)),
                creditCardAmount: Number(adjust.creditCardAdjustment.toFixed(2)),
                debitCardAmount: Number(adjust.debitCardAdjustment.toFixed(2)),
                remainingBalance: Number((unaccounted.totalCost - adjust.cashAdjustment - adjust.creditCardAdjustment.toFixed(2) - adjust.debitCardAdjustment).toFixed(2)),
                totalTaxes: Number(payments.taxAmount.toFixed(2)),
                totalSales: Number(unaccounted.totalCost.toFixed(2)) + Number(giftCardItemAmounts.totalCost.toFixed(2)),
                totalTaxable: Number(unaccounted.taxable.toFixed(2)) + Number(giftCardItemAmounts.taxable.toFixed(2)),
                totalNonTaxable: Number(unaccounted.untaxable.toFixed(2)) + Number(giftCardItemAmounts.untaxable.toFixed(2)),
                creditCardAuthCode: '',
                receiptItems: lodash.cloneDeep(posData.receipt),
                mealPlanActions: [],
                mealPlanIds: combineIds(payments.mealUnits),
                dcbMealPlanIds: combineIds(payments.dcbUnits),
                icbMealPlanIds: combineIds(payments.icbUnits),
                totalDiscount: 0,
                dollarDiscount: Number(adjust.dollarDiscount.toFixed(2)),
                totalApprovedTenderAmount: totalApprovedTenderAmount, // Not stored in FIIT
                totalMealUnitDollarValue: totalMealUnitDollarValue, // Not stored in FIIT
                giftCardAmount: payments.giftCardUnits.length > 0 ? (Number(payments.giftCardUnits.reduce(combineUnit).unit)) : 0 // Not stored in FIIT
            };

            return tenderAmounts;
        }

        /**
        *** Commented By Akash Mehta
        *** This is the function which calculates the units required for this transaction.
        *** It breaks down the receiptItems to a unit Item and maps every unit item quantity of a top level item to with all possible unit quantity
        *** of child items (modifiers, negative modifiers, discounts). This mapping is really necessary for deductions of meal units & quantity.
        *** The reason being, in Nown if a top level item is meal exchanged, all modifiers are included in the same meal unit. Also, -ve modifiers
        *** and item discounts come in as a receipt item. So for DCB/ Meal Equivalency, we have to process a -ve child item first before processing
        *** the actual item. It performs the following steps:
        *** 1.) Iterate over all the receipt items.
        *** 2.) If item level !== 0 (not a top level item) or if item is included (kept from FIIT), go to the next item else follow the process below
        *** 3.) For every receipt item, iterate over the quantity of every receipt item.
        *** 4.) For every single unit of item, get all the child items from the receipt.
        *** 5.) Then loop over all child items and for every child item iterate over every quantity of a chilld item
        *** 6.) So basically what the above 5 steps give us is a mapping between every unit quantity of a top item with all possible unit quantity
        ***     of child items. For eg, An item with quantity 2 & a 2x modifier will be mapped as :
        ***     - Item 1
        ***         - Modifier 1
        ***         - Modifier 1
        ***     - Item 1
        ***         - Modifier 1
        ***         - Modif1er 1
        *** 7.) Once we have all the mappings done, if an item is meal exchangeable we add it to the meal units, else we add it to cash units (This
        ***     logic was carry forwared from FIIT).
        ***
        *** Commented By Akash Mehta on 29th June 2020
        *** We have added a section which adds delivery fee (if applied) as a required unit
        *** We want students to pay for delivery using their DCB balances if possible. Hence, this is a mandatory
        *** step.
        ***/
        function calculateRequiredUnits (receiptList, adjustments) {
            var requiredUnits = {
                'cashUnits': [],
                'mealUnits': []
            };

            var itemCounter = 0;
            _.each(receiptList, function (listItem, index) {
                if (listItem && listItem.level == 0 && (!listItem.included || listItem.included === false)) {
                    var originalItemQuantity = listItem.quantity;
                    for (var i = listItem.quantity - 1; i >= 0; i--) {
                        var taxRateMultiplicand = 1 + listItem.taxRate;
                        var itemPrice = new Decimal(listItem.itemPrice);
                        var itemTaxPrice = itemPrice.times(taxRateMultiplicand);

                        var newItem = {
                            'id': listItem.locationServicePeriodMenuId,
                            'remainingMealUnits': listItem.mealPlanCount,
                            'originalPrice': itemPrice.toNearest(SharedDataService.baseDollar), // This field is added to handle the case for DCB & Meal Equivalency where the original price of the item is 0
                            'originalPriceTaxIn': itemTaxPrice.toNearest(SharedDataService.baseDollar), // This field is added to handle reconciliation for preorders using gift cards.
                            'remainingPrice': itemPrice.toNearest(SharedDataService.baseDollar),
                            'remainingPriceTaxIn': itemTaxPrice.toNearest(SharedDataService.baseDollar),
                            'taxRate': listItem.taxRate,
                            'mealEquivalencyEnabled': listItem.mealEquivalencyEnabled,
                            'mealPlanEligible': listItem.mealPlanEligible,
                            'itemIndex': itemCounter,
                            'parentItemIndex': itemCounter,
                            'level': listItem.level,
                            'isItemTaxable': !!listItem.taxRate,
                            'receiptIndex': listItem.receiptIndex,
                            'cost': {
                                mealPlanCount: 0,
                                subtotal: new Decimal(0),
                                taxableAmount: new Decimal(0),
                                nonTaxableAmount: new Decimal(0),
                                discountAmount: new Decimal(0),
                                tax: new Decimal(0),
                                taxRate: 0
                            }
                        };

                        var childItems = receiptList.filter((receiptListItem) => receiptListItem.index == listItem.index && receiptListItem.level != 0);

                        var childItemsArr = [];
                        for (var childItem of childItems) {
                            var childItemQuantity = new Decimal(childItem.quantity).div(originalItemQuantity).toNearest(1).toNumber();
                            for (var childQtyCtr = childItemQuantity -1; childQtyCtr >= 0; childQtyCtr--) {

                                var childItemPercentTaxRateMultiplicand = 1 + childItem.taxRate;

                                var childItemPrice = new Decimal(childItem.itemPrice);

                                var childItemTaxPrice = childItemPrice.times(childItemPercentTaxRateMultiplicand);

                                itemCounter++;

                                var newChildItem = {
                                    'id': childItem.locationServicePeriodMenuId,
                                    'remainingMealUnits': childItem.mealPlanCount,
                                    'originalPrice': childItemPrice, // This field is added to handle the case for DCB & Meal Equivalency where the original price of the item is 0
                                    'originalPriceTaxIn': childItemTaxPrice, // This field is added to handle reconciliation for preorders using gift cards.
                                    'remainingPrice': childItemPrice,
                                    'remainingPriceTaxIn': childItemTaxPrice,
                                    'taxRate': childItem.taxRate,
                                    'mealEquivalencyEnabled': childItem.mealEquivalencyEnabled,
                                    'mealPlanEligible': childItem.mealPlanEligible,
                                    'itemIndex': itemCounter,
                                    'parentItemIndex': newItem.itemIndex,
                                    'level': childItem.level,
                                    'isItemTaxable': !!childItem.taxRate,
                                    'parentItemId': childItem.parentItemId, // This field only is populated if the child item is a discount
                                    'receiptIndex': childItem.receiptIndex,
                                    'isDiscount': childItem.locationServicePeriodMenuId == -2,
                                    'cost': {
                                        mealPlanCount: 0,
                                        subtotal: new Decimal(0),
                                        taxableAmount: new Decimal(0),
                                        nonTaxableAmount: new Decimal(0),
                                        discountAmount: new Decimal(0),
                                        tax: new Decimal(0),
                                        taxRate: 0
                                    }
                                };

                                childItemsArr.push(newChildItem);
                            }
                        }

                        if (newItem.remainingMealUnits > 0) {
                            requiredUnits.mealUnits.push(newItem);
                            requiredUnits.mealUnits.push(...childItemsArr);
                        } else {
                            requiredUnits.cashUnits.push(newItem);
                            requiredUnits.cashUnits.push(...childItemsArr);
                        }

                        itemCounter++;
                    }
                }
            });

            if (adjustments && adjustments.deliveryFee) {
                var taxRateMultiplicand = new Decimal(1).add(new Decimal(adjustments.deliveryTaxRate || 0));
                var deliveryPrice = new Decimal(adjustments.deliveryFee);
                var deliveryTaxInPrice = deliveryPrice.times(taxRateMultiplicand);

                var newItem = {
                    'id': -9998,
                    'remainingMealUnits': 0,
                    'originalPrice': deliveryPrice.toNearest(SharedDataService.baseDollar), // This field is added to handle the case for DCB & Meal Equivalency where the original price of the item is 0
                    'remainingPrice': deliveryPrice.toNearest(SharedDataService.baseDollar),
                    'remainingPriceTaxIn': deliveryTaxInPrice.toNearest(SharedDataService.baseDollar),
                    'taxRate': adjustments.deliveryTaxRate,
                    'mealEquivalencyEnabled': false,
                    'mealPlanEligible': false,
                    'itemIndex': itemCounter,
                    'parentItemIndex': itemCounter,
                    'level': 99999,
                    'isItemTaxable': !!adjustments.deliveryTaxRate,
                    'receiptIndex': 99999,
                    'cost': {
                        mealPlanCount: 0,
                        subtotal: new Decimal(0),
                        taxableAmount: new Decimal(0),
                        nonTaxableAmount: new Decimal(0),
                        discountAmount: new Decimal(0),
                        tax: new Decimal(0),
                        taxRate: 0
                    }
                };

                requiredUnits.cashUnits.push(newItem);

                itemCounter++;
            }

            return requiredUnits;
        }

        /**
        *** Commented By Akash Mehta on April 2 2020
        *** This function reduces the list of unified payment entry and groups them by a meal Plan
        *** into a single list - 'fiitTenders', which is then returned.
        *** Every entry in the input unifiedind list icates which meal plan has been deducted against
        *** which item and what was the deducted amount.
        *** However, the output reduced list contains a list of all unique meal plans and the amount
        *** deducted against each of those meal plans.
        ***
        *** NOTE: We are not storing the reduced payments list in the fiit transaction payload itself
        *** This is because we store the payload as a json string in our response table and want to conserve the original payments
        *** Hence there is a same reduce function on the Fiit backend as well.
        *** So if any logic changes in this reduce function, ensure those changes are also reflected on the fiit backend
        ***/
        var reduceFiitPaymentsList = function (payments) {
            var fiitTendersMap = {};
            for (var payment of payments) {
                var tender = fiitTendersMap[payment.id] || {
                    fiitMealPlanId: payment.id,
                    fiitMealPlanName: payment.mealPlanName,
                    fiitMealPlanType: payment.mealPlanType,
                    unitsUsed: new Decimal(0), // This is inclusive of tax
                    equivalentDollarValue: new Decimal(0), // This is inclusive of tax
                    tax: new Decimal(0),
                    taxablePlan: payment.taxable

                };
                tender.unitsUsed = tender.unitsUsed.plus(new Decimal(payment.unit));
                tender.equivalentDollarValue = tender.equivalentDollarValue.plus(new Decimal(payment.dollarValue));
                tender.tax = tender.tax.plus(new Decimal(payment.tax));

                fiitTendersMap[tender.fiitMealPlanId] = tender;
            }

            var fiitTenders = Object.values(fiitTendersMap);
            for (var fiitTender of fiitTenders) {
                fiitTender.unitsUsed = fiitTender.unitsUsed.toNumber();
                fiitTender.equivalentDollarValue = fiitTender.equivalentDollarValue.toNumber();
                fiitTender.tax = fiitTender.tax.toNumber();
            }

            return fiitTenders;
        };

        /**
        *** Commented By Akash Mehta on April 2 2020
        *** This function updates the selected tender object with the fiit calculation response
        *** This step is necessary as we need to pouplate the selected tender object correctly in order to store the
        *** tender details in the database. There is another function call here which reduces the list of indiviual
        *** payments and groups them by meal plans - 'fiitTenders'
        *** into a new list called 'fiitTenders', which we then store in the Nown database.
        ***/
        function updateSelectedNownTenderWithFiitTransactionObj (nownSelectedTender, fiitMockResponse, fiitTransactionPayload) {
            nownSelectedTender.transactionTenderDetail.otherResponse = JSON.stringify(fiitTransactionPayload);
            nownSelectedTender.transactionTenderDetail.fiitCalculationResponseObj = fiitMockResponse;

            // Comment By Akash Mehta on April 2, 2020
            // The only reason why we are not reducing the payment lists
            // in the function 'generateFiitTransactionPayloadAndUpdateNownTender' itself
            // is because we still want to store the original resposne from fiit calculations
            // Hence there is a same reduce function on the FIIT backend as well.
            var payments = JSON.parse(JSON.stringify(fiitTransactionPayload.payments)) || [];
            var fiitTenders = reduceFiitPaymentsList(payments);
            nownSelectedTender.fiitTenders = fiitTenders;
            // Please do not change this mapping. If anyone changes this, there could
            // be serious repercussions
            nownSelectedTender.amount = fiitMockResponse.approvedAmount;
            nownSelectedTender.meal = fiitMockResponse.approvedMeal;
        }

        /**
        *** Commented By Akash Mehta on April 1 2020
        *** This function generates updates the payload (if present in fiitMockResponse) / generates a payload (based on the input nown payload)
        *** that can be sent to the fiit backend. This step is idempotent and can be called as many times as required.
        *** If the fiitMockResponse is passed and if it has a traasaction payload:
        ***   1. We use that payload going forward.
        ***   2. In the fiit Mock response, we have a section of payments consisting of mealUnitPayments, dcbPayments, mealEqPayments, etc.
        ***      and each payment (irrespective of the type) indicates which meal plan has been deducted against which item and what was
        ***      the deducted amount. This is an imporatant step which unifies the different types of payments in the fiit calculation
        ***      response into a single list. This step is also necessary as the fiit backend expects a list of individual payments
        ***      which it later reduces itself. (NOTE : Going down the line we can remove this reduction step from FIIT. We also need this
        ***      list reduce it in one of the functions below to populate the tender object correctly in order to store the tender details
        ***      in the database.)
        *** Else : We generate a basic fiit payload based on the nownTransactionPayload passed in
        ***
        *** Once we a fiitTransactionPayload, we update its certain characteristics from the nown transaction payload (like cash, card amounts
        *** transaction_uuid, etc.). This is necessary to support idempotency
        ***
        *** NOTE: this step is required in the case of Nown POS, as there is a possibility of update to the payment methods and hence we
        *** might need to regenerate the fiit transaction payload.
        ***
        ***/
        FiitMealPlanCalculationService.prototype.generateFiitTransactionPayloadAndUpdateNownTender = function (nownTransactionObj,
            {fiitMockResponse, fiitPatronId, nownTendersUsed = []} = {}) {
            let fiitTransactionPayload = null;
            var totalOtherAmount = new Decimal(nownTransactionObj.otherAmount || 0);
            var remainingOtherAmount = totalOtherAmount;

            if (fiitMockResponse && fiitMockResponse.tenderAmounts) {
                fiitTransactionPayload = JSON.parse(JSON.stringify(fiitMockResponse.tenderAmounts));

                var payments = [];
                payments.push(...fiitMockResponse.payments.dcbUnits);
                payments.push(...fiitMockResponse.payments.mealUnits);
                payments.push(...fiitMockResponse.payments.mealUnitsUsedForMealEq);

                for (var payment of payments) {
                    payment.unit = new Decimal(payment.unit).toNumber();
                    payment.tax = new Decimal(payment.tax).toNumber();
                }

                fiitTransactionPayload.payments = payments;
                if (nownTendersUsed) {
                    var gatewayTender = nownTendersUsed.find((tender) => tender.type == UI_TENDER_TYPE.GATEWAY);
                    if (gatewayTender) {
                        updateSelectedNownTenderWithFiitTransactionObj(gatewayTender, fiitMockResponse, fiitTransactionPayload);
                        if (totalOtherAmount.greaterThan(0)) {
                            remainingOtherAmount = totalOtherAmount.minus(fiitMockResponse.approvedAmount || 0)
                                .toNearest(SharedDataService.baseDollar);
                        }
                    }
                }
            }

            fiitTransactionPayload = fiitTransactionPayload || {
                guestTransaction: nownTransactionObj.guestTransaction,
                transactionUuid: nownTransactionObj.transactionUuid,
                locationId: nownTransactionObj.fiitLocationId,
                mealPlanCount: nownTransactionObj.fiitMealPlanCount || 0,
                mealEqAmount: nownTransactionObj.fiitMealEqAmount || 0,
                chargeAmount: 0,
                dcbAmount: nownTransactionObj.fiitDcbAmount || 0,
                cashAmount: nownTransactionObj.cashAmount || 0,
                creditCardAmount: nownTransactionObj.creditCardAmount || 0,
                debitCardAmount: nownTransactionObj.debitCardAmount || 0,
                remainingBalance: nownTransactionObj.remainingBalance || 0,
                totalTaxes: nownTransactionObj.totalTaxes || 0,
                totalSales: nownTransactionObj.totalTaxes || 0,
                totalTaxable: nownTransactionObj.totalTaxable || 0,
                totalNonTaxable: nownTransactionObj.totalNonTaxable || 0,
                creditCardAuthCode: '',
                receiptItems: lodash.cloneDeep(nownTransactionObj.receipt),
                mealPlanActions: [],
                mealPlanIds: '',
                dcbMealPlanIds: '',
                icbMealPlanIds: '',
                totalDiscount: 0,
                dollarDiscount: 0,
                totalApprovedTenderAmount: 0 // Not stored in FIIT
            };

            fiitTransactionPayload.cashAmount = nownTransactionObj.cashAmount + nownTransactionObj.giftCardAmount + remainingOtherAmount.toNumber();
            fiitTransactionPayload.totalTaxes = nownTransactionObj.totalTaxes;
            fiitTransactionPayload.totalSales = nownTransactionObj.totalSales;
            fiitTransactionPayload.totalTaxable = nownTransactionObj.totalTaxable;
            fiitTransactionPayload.totalNonTaxable = nownTransactionObj.totalNonTaxable;
            fiitTransactionPayload.remainingBalance = nownTransactionObj.remainingBalance;
            fiitTransactionPayload.changeAmount = nownTransactionObj.changeAmount;
            fiitTransactionPayload.creditCardAmount = nownTransactionObj.creditCardAmount;
            fiitTransactionPayload.debitCardAmount = nownTransactionObj.debitCardAmount;
            fiitTransactionPayload.transactionUuid = nownTransactionObj.transactionUuid;

            /**
            *** Commented By Akash Mehta on May 19 2020
            *** Fiit patron id can comes as -1 when it is a guest transaction
            *** We fell into this issue on the old FIIT preorder workflow.
            *** Hence adding a check to be safe.
            *** Also please do not remove the else clause here. We have fallen
            *** into issues where guest transaction cna sometimes be set as false
            *** even when there is no fiit patron id. The else clause here ensures
            *** we do not fall into this issue. Please do not remove the else block.
            ***/
            if (fiitPatronId && fiitPatronId > 0) {
                fiitTransactionPayload.patronId = fiitPatronId;
                fiitTransactionPayload.guestTransaction = false;
            } else {
                fiitTransactionPayload.guestTransaction = true;
            }

            return fiitTransactionPayload;
        };

        FiitMealPlanCalculationService.prototype.testCalculateRequiredUnits = function (receiptList, adjustments) {
            return calculateRequiredUnits(receiptList, adjustments);
        };

        /**
        *** Commented By Akash Mehta on April 1 2020
        *** This function provides information on whether the input flat item list includes any meal unit
        *** eligble item or not. Basically, here we check if the top level item is one of the following or not:
        *** 1.) Meal Equivalency Eligible
        *** 2.) Meal Exchange Eligible
        *** 3.) Required meal plan count of the item is > 0 (This is another indicator for meal exchange eligible)
        *** We return a boolean value for this function
        ***/
        FiitMealPlanCalculationService.prototype.isReceiptmealUnitDeductionEligible = function (receiptList) {
            if (receiptList && receiptList.length) {
                var topLevelItems = receiptList.filter((receiptItem) => receiptItem.level == 0);
                for (var item of topLevelItems) {
                    if (item.mealEquivalencyEnabled || item.mealPlanEligible || item.mealPlanCount) {
                        return true;
                    }
                }
            }

            return false;
        };

        /**
        *** Commented By Akash Mehta on 30th Mar 2020
        *** This function is required as the lucova backend does not send the field itemPrice (which is the price of an unit quantity of the item ),
        *** So the code below ensures we always get the correct itemPrice
        ***/
        FiitMealPlanCalculationService.prototype.populateRequiredItemFields = function populateRequiredItemFields (receipt) {
            for (var receiptItem of receipt) {
                var totalItemPrice = new Decimal(receiptItem.price).toNearest(SharedDataService.baseDollar);
                var itemQuantity = new Decimal(receiptItem.quantity || 1).toNearest(1); // 1 because we do not support decimal item quantities
                receiptItem.itemPrice = totalItemPrice.div(itemQuantity).toNearest(SharedDataService.baseDollar).toNumber();
            }
        };

        function addAdditionalFieldsToTransactionPayload (tenderAmounts, itemCosts) {
            var nonTaxableSubtotal = new Decimal(0);
            var taxableSubtotal = new Decimal(0);
            var discountAmount = new Decimal(0);
            var deliveryItemCost = itemCosts.find((unit) => unit.id == -9998);
            itemCosts = itemCosts.filter((unit) => unit.id != -9998);
            itemCosts.forEach(function (cost) {
                nonTaxableSubtotal = nonTaxableSubtotal.plus(cost.nonTaxableAmount).toNearest(SharedDataService.baseDollar);
                taxableSubtotal = taxableSubtotal.plus(cost.taxableAmount).toNearest(SharedDataService.baseDollar);
                discountAmount = discountAmount.plus(cost.discountAmount).toNearest(SharedDataService.baseDollar);
            });

            tenderAmounts.totalDiscount = discountAmount.toNumber();
            tenderAmounts.subtotalNonTaxable = nonTaxableSubtotal.toNumber(); // Not stored in FIIT
            tenderAmounts.subtotalTaxable = taxableSubtotal.toNumber(); // Not stored in FIIT
            if (deliveryItemCost) {
                tenderAmounts.deliveryFee = deliveryItemCost.price;
                tenderAmounts.deliveryTax = deliveryItemCost.tax;
            } else {
                tenderAmounts.deliveryFee = 0;
                tenderAmounts.deliveryTax = 0;
            }
        }

        function processItemCosts (requiredUnits) {
            var cashCosts = requiredUnits.cashUnits || [];
            var mealCosts = requiredUnits.mealUnits || [];

            var units = [];
            units = units.concat(cashCosts);
            units = units.concat(mealCosts);

            var costs = _.map(units, function (unit) {
                var c = {};
                c.id = unit.id;
                c.index = unit.receiptIndex;

                var cost = unit.cost;
                c.mealPlanCount = cost.mealPlanCount;
                c.price = cost.subtotal.toNumber() || 0;
                c.taxableAmount = cost.taxableAmount.toNumber() || 0;
                c.nonTaxableAmount = cost.nonTaxableAmount.toNumber() || 0;
                c.taxRate = cost.taxRate;
                c.discountAmount = cost.discountAmount.toNumber() || 0;
                c.tax = cost.tax.toNumber() || 0;

                return c;
            });

            // In `adjustDCBOffset`, a non-menu-item cashUnit is added to requiredUnits.cashUnits
            // to achieve DCB adjustment. This should be removed here to return costs of actual
            // menu items.
            costs = _.chain(costs)
                .sortBy('receiptIndex')
                .filter(function (c) {
                    return c && !isNaN(c.index);
                })
                .value();

            return costs;
        }

        FiitMealPlanCalculationService.prototype.packageTransactionForLucova = function packageTransactionForLucova (fiitTransaction, appFid, $cookies) {
            var posObj = lodash.cloneDeep(fiitTransaction);
            posObj.sourceId = 1; // Set flag to indicate mobile transaction
            posObj.creditCardAmount = posObj.remainingBalance;
            posObj.remainingBalance = 0;

            if (posObj.creditCardAmount > 0) {
                posObj.creditCardAuthCode = 'LUCOVA';
            }

            var posHeaders = {'JSESSIONID': $cookies.JSESSIONID};
            var obj = {
                'pin': '123',
                'pos_obj': posObj,
                'pos_obj_type': 'fiit',
                'pos_headers': posHeaders,
                'override_dc': 'true',
                'app_fid': appFid
            };
            obj.scv_verified = true;

            return obj;
        };

        /**
        *** Commented By Akash Mehta on April 2 2020
        *** This function updates the adjustments object passed as per the fiit calculation response
        *** This step is necessary as we pass this updated adjustments object when recalculating the nown transaction
        *** amounts which inturn leads to a complete Nown transaction payload with required fiit fields as well (like fiit_dcb_amount,
        *** fiit_meal_eq_amount)
        ***/
        FiitMealPlanCalculationService.prototype.updateNownAdjustmentsAsPerFiitCalculationResponse = function (fiitCalculationResponse, adjustments) {
            if (!adjustments) {
                return;
            }

            adjustments.deliverySettings = adjustments.deliverySettings || {};

            if (new Decimal(fiitCalculationResponse.tenderAmounts.deliveryTax || 0).equals(0)) {
                adjustments.deliverySettings.defaultTaxRate = 0;
            }

            adjustments.fiit = {
                mealEqAmount: fiitCalculationResponse.tenderAmounts.mealEqAmount || 0,
                mealPlanCount: fiitCalculationResponse.tenderAmounts.mealPlanCount || 0,
                mealUnitDollarValue: fiitCalculationResponse.tenderAmounts.totalMealUnitDollarValue || 0,
                dcbAmount: fiitCalculationResponse.tenderAmounts.dcbAmount || 0,
                subtotalTaxable: fiitCalculationResponse.tenderAmounts.subtotalTaxable || 0,
                subtotalNonTaxable: fiitCalculationResponse.tenderAmounts.subtotalNonTaxable || 0
            };

            adjustments.lockPrice = true;
        };

        /**
        *** Commented By Akash Mehta on April 2 2020
        *** This function updates the nown flat list of items as per the response received from fiit Calculations.
        *** The main purpose is to update the tax applied per item and the item total based on the fiit calculations.
        ***/
        FiitMealPlanCalculationService.prototype.updateNownItemReceiptFromFiitResponse = function (nownFlatItemList, fiitCalculationResponse) {
            var fiitResponseItems = fiitCalculationResponse.items || [];

            var fiitResponseItemMap = {};
            for (let fiitResponseItem of fiitResponseItems) {
                var index = fiitResponseItem.index;
                var foundResponseItem = fiitResponseItemMap[index];

                if (!foundResponseItem) {
                    foundResponseItem = {
                        price: 0,
                        tax: 0,
                        mealPlanCount: 0
                    };
                    fiitResponseItemMap[index] = foundResponseItem;
                }

                foundResponseItem.tax = new Decimal(foundResponseItem.tax)
                    .plus(new Decimal(fiitResponseItem.tax))
                    .toNumber();
                foundResponseItem.mealPlanCount = foundResponseItem.mealPlanCount + fiitResponseItem.mealPlanCount;
            }

            for (let index of Object.keys(fiitResponseItemMap)) {
                let fiitResponseItem = fiitResponseItemMap[index];
                let receiptItem = nownFlatItemList[index];

                if (!receiptItem) {
                    continue;
                }

                receiptItem.taxAmount = fiitResponseItem.tax || 0;

                receiptItem.total = new Decimal(receiptItem.price)
                    .plus(new Decimal(receiptItem.taxAmount))
                    .toNumber();
                receiptItem.mealPlanAmount = fiitResponseItem.mealPlanCount;
                receiptItem.mealPlanCount = fiitResponseItem.mealPlanCount;
            }
        };

        /**
        *** Commented By Akash Mehta
        *** This function calculates the available balanaces for every plan. It performes the following steps :
        *** 1.) It initializes the available units object
        *** 2.) If there is an 'OVVERIDE' modificiation to  a plan, we consider the total available balance, else the service period balance of the plan
        ***
        *** NOTE : The object avaialbleUnits & availableBalances can be removed. Please try removing them in the future.
        ***/
        var calculateServicePeriodAvailableBalances = FiitMealPlanCalculationService.prototype.calculateServicePeriodAvailableBalances = function (plans, adjustments) {
            var availableUnits = {
                'mealUnits': 0,
                'mealEqUnits': new Decimal(0),
                'dcbUnits': new Decimal(0),
                'dcbTaxInUnits': new Decimal(0),
                'icbUnits': new Decimal(0),
                'icbTaxInUnits': new Decimal(0),
                'hasMealPlan': false,
                'dcbIndividualUnits': [],
                'dcbTaxInclIndividualUnits': [],
                'mealIndividualUnits': [],
                'giftCardUnits': new Decimal(0)
            };
            _.each(plans, function (plan) {
                var individualUnit = {
                    'id': plan.mealPlanId,
                    'balance': new Decimal(0)
                };
                var mealUnitsToAdd = (plan.modificationType == OVERRIDE) ? plan.currentMealPlanBalance : plan.remainingServicePeriodMeals;
                if (plan.mealPlanType === 'MEAL') {
                    individualUnit.balance = mealUnitsToAdd;
                    availableUnits.mealIndividualUnits.push(individualUnit);
                    availableUnits.mealUnits += mealUnitsToAdd;
                    availableUnits.hasMealPlan = true;
                } else if (plan.mealPlanType === 'DCB') {
                    var dcbAmountToAdd = new Decimal((plan.modificationType == OVERRIDE) ? plan.currentDcbBalance : plan.remainingServicePeriodDcb);
                    individualUnit.balance = dcbAmountToAdd;
                    if (plan.taxFree) {
                        availableUnits.dcbIndividualUnits.push(individualUnit);
                        availableUnits.dcbUnits = availableUnits.dcbUnits.plus(dcbAmountToAdd);
                    } else {
                        availableUnits.dcbTaxInclIndividualUnits.push(individualUnit);
                        availableUnits.dcbTaxInUnits = availableUnits.dcbTaxInUnits.plus(dcbAmountToAdd);
                    }
                }
            });

            if (adjustments && adjustments.giftCardAdjustment) {
                availableUnits.giftCardUnits = new Decimal(adjustments.giftCardAdjustment);
            }

            return availableUnits;
        };


        /**
        *** Commented By Akash Mehta
        *** This is the main function which controls the flow to calculate which meal plans to deduct & by how much
        *** It performs the following steps:
        *** 1.) Sort the tenders either by Fiit Priority or Custom Priority.
        *** 2.) Calculate all the available service period balances
        *** 3.) Calculate all the required units per item
        *** 4.) Always Use dollar discount as the first tender, if possible.
        *** 5.) Loop through the sorted plans and then for each plan, try deducting the units.
        *** 6.) Process all unaccounted items
        *** 7.) Process all tendered items (also honoring the unaccounted items)
        *** 8.) Process all the item costs
        *** 9.) Add additional fields like subtotal taxable, subtotal nontaxable,etc. to the tenderAmounts payload from step 8 based on the
        ***     item costs calculated in the previous step.
        *** 10.) Finally, populate the meal unit value against the dollar valued of meal equivalency units.
        ***/
        function calculateBalancesv3 (receiptList, availablePlanList, adjustments, posData, useFiitPriority) {
            var availablePlans = lodash.cloneDeep(availablePlanList);
            if (useFiitPriority) {
                availablePlans.sort(sortPlansByFiitPriority);
            } else {
                availablePlans.sort(sortPlansByDefaultPriority);
            }

            var availableBalances = calculateServicePeriodAvailableBalances(availablePlans, adjustments);
            var requiredUnits = calculateRequiredUnits(receiptList, adjustments);
            var payments = initPaymentsObject();
            var discountDollar = adjustments.dollarDiscount || 0;
            var giftCardAdjustment = adjustments.giftCardAdjustment || 0;

            adjustments.maxAllowedMealEquivalencyDollarAmount = new Decimal(posData.allowedMealEquivalencyPerCycle || 0).times(posData.allowedMealEquivalencyDollar || 0).toNearest(SharedDataService.baseDollar).toNumber();

            posData.deferred = [];

            if (discountDollar > 0) {
                availableBalances['discountUnits'] = new Decimal(discountDollar);
                deductUnit({mealPlanId: 'discount'}, false, discountDollar, 'discountUnits', 'discountUnits', requiredUnits, availableBalances, adjustments, payments, posData, undefined, useFiitPriority);
            }

            if (adjustments.giftCardAdjustment > 0) {
                deductUnit({mealPlanId: 'giftCard'}, false, giftCardAdjustment, 'giftCardUnits', 'giftCardUnits', requiredUnits, availableBalances, adjustments, payments, posData, undefined, useFiitPriority);
            }

            // This loop is necessary for honoring the sorting of plans as per priority
            _.each(availablePlans, function (plan) {
                var tmpAvailablePlanBalance, tmpAvailablePlan;
                if (plan.mealPlanType === 'MEAL') {
                    tmpAvailablePlan = availableBalances.mealIndividualUnits.find((mp) => mp.id === plan.mealPlanId);
                    tmpAvailablePlanBalance = (tmpAvailablePlan) ? tmpAvailablePlan.balance : plan.remainingServicePeriodMeals;
                    deductMealUnit(plan, tmpAvailablePlanBalance, requiredUnits, availableBalances, adjustments, payments, posData, plan.requestedUnits, useFiitPriority);
                } else if (plan.mealPlanType === 'DCB') {
                    if (plan.taxFree) {
                        tmpAvailablePlan = availableBalances.dcbIndividualUnits.find((mp) => mp.id === plan.mealPlanId);
                    } else {
                        tmpAvailablePlan = availableBalances.dcbTaxInclIndividualUnits.find((mp) => mp.id === plan.mealPlanId);
                    }

                    tmpAvailablePlanBalance = (tmpAvailablePlan) ? tmpAvailablePlan.balance : plan.remainingServicePeriodDcb;
                    deductUnit(plan, plan.taxFree, tmpAvailablePlanBalance, 'dcbUnits', 'dcbTaxInUnits', requiredUnits, availableBalances, adjustments, payments, posData, plan.requestedUnits, useFiitPriority);
                }
            });

            var adjust = processAdjustments(adjustments);

            var unaccounted = processUnaccounted(requiredUnits);
            var giftCardItemAmounts = processGiftCardAmounts(requiredUnits, payments);

            var tenderAmounts = processTendered(posData, payments, adjust, unaccounted, giftCardItemAmounts);

            if (tenderAmounts.remainingBalance < 0) {
                return {
                    error_code: 500,
                    error_msg: 'patron.popup.tender.negative.remainingBalance',
                    error_data: tenderAmounts
                };
            }

            var itemCosts = processItemCosts(requiredUnits);

            addAdditionalFieldsToTransactionPayload(tenderAmounts, itemCosts);

            /**
            *** Commented By Akash Mehta
            *** The step below is to calculate the meal units used for the equivalent dollar value of all MealEqUnits.
            *** The logic of this calculation is explained in the comments above.
            *** Note: This logic of this function is similar to the basic workflow of using meal units for meal equivalency.
            *** If anything changes in that workflow, we need to update the logic of this function as well.
            ***/
            convertDollarAmountOfMealEquivalencyToMealUnits(payments, posData.allowedMealEquivalencyDollar);

            return {
                payments: payments,
                tenderAmounts: tenderAmounts,
                error_code: 0,
                items: itemCosts
            };
        }

        /**
        *** Commented By Akash Mehta on April 2 2020
        *** The function below is to filter the applicable meal plans from the input patron meal plans
        *** The logic of this filtering is as follows :
        *** 1.) First get all plans that have the field 'isAvailable' set to true (This comes from the Fiit backend)
        *** 2.) Filtering based on the parameter  - 'useFiitPriority'
        ***    -   If we are NOT using fiit priority then filter the result from the previous step and get only those plans
        ***        that have requestedUnits set. (This logic is as per the flow decided for the Patron Tender Modal)
        ***    -   Else we filter the result from the previous step and get all non-guest meal plans
        ***
        *** NOTE: This is also used by the back-end endpoints on the calculation server. Please ensure that any change
        *** in the logic here is backwards compatible.
        ***/
        FiitMealPlanCalculationService.prototype.filterFiitPatronMealPlans = function (availablePlans, useFiitPriority = true) {
            if (!availablePlans) {
                return [];
            }

            var availableMealPlans = availablePlans.filter((plan) => plan.isAvailable);

            if (!useFiitPriority) {
                availableMealPlans = availableMealPlans.filter((plan) => plan.requestedUnits);
            } else {
                availableMealPlans = availableMealPlans.filter((plan) => !plan.guestPlan);
            }

            return availableMealPlans;
        };

        /**
        *** Commented By Akash Mehta
        *** This is the entry point in the file for calculating balances. It performes the following steps :
        *** 1.) It tries to validate the transaction coming through. If not valid, it throws an error
        *** 2.) It modifies and add new fields in the receiptItem list
        *** 3.) Makes all modifiers in the receiptItem list eligible for meal equivalency
        *** 4.) Initializes the required data and performes validity check
        *** 5.) Calls the actual function to perform calculations - calculateBalancesv3
        ***/
        FiitMealPlanCalculationService.prototype.calculateBalances = function (receiptList, availablePlanList, inAdjustments, fiitLocationId, inMealEquivalencyOption, useFiitPriority) {
            var adjustments = inAdjustments || {
                dcbAdjustment: 0,
                cashAdjustment: 0,
                creditCardAdjustment: 0,
                debitCardAdjustment: 0,
                dollarDiscount: 0,
                mealEquivalencyAdjustment: false,
                percentDiscount: 0,
            };

            // Comment Added By Akash Mehta on April 1 2020
            // This if block is a temporary solution to enable credit card
            // transactions on the calculation server. When a transaction has credit card usage
            // we ensure that the available plan list is empty,  so that we skip the calcultion part
            // and return the expected FIIT transaction object
            if (adjustments.creditCardAdjustment || adjustments.cashAdjustment) {
                availablePlanList = [];
            }

            var transactionValidity = validateTransaction(receiptList, adjustments);

            if (!transactionValidity.isValid) {
                return {
                    error_code: 500,
                    items: transactionValidity.items,
                    error_msg: transactionValidity.errorMsg
                };
            }

            // This block of code is taken from fiit where it tries to
            // bridge the gap between Backend PosReceiptItem and frontend receiptItem structure
            // Comment by Akash Mehta: I added new code which tracks the receipt Index in order to map items back to the original Nown reciept
            var receiptIndex = 0;
            for (var receiptItem of receiptList) {
                receiptItem.mealPlanCount = receiptItem.mealPlanCount || 0;
                receiptItem.receiptIndex = receiptIndex;
                receiptIndex++;
            }

            populateAvailableItemsForMealEquivalency(receiptList);

            var availablePlans = availablePlanList;

            var locationId = fiitLocationId;
            var receipt = receiptList;

            if (!availablePlans && !locationId && !receipt) {
                return {
                    error_code: 500,
                    items: [],
                    error_msg: 'patron.popup.tender.missing.information'
                };
            }
            var currentOrderBalance = {
                totalMeals: 0,
                subTotalAmount: 0,
                totalAmount: 0,
                taxAmount: 0,
                totalDiscount: 0
            };

            var mealEquivalencyOption = inMealEquivalencyOption || {};

            var posData = {
                receipt: receipt,
                deferred: [],
                allowedMealEquivalencyPerCycle: mealEquivalencyOption.allowedPerCycle || 0,
                allowedMealEquivalency: !!mealEquivalencyOption.enabled,
                allowedMealEquivalencyDollar: mealEquivalencyOption.allowedDollarAmount || 0,
                guestTransaction: false,
                shiftLocationId: locationId,
                currentOrderBalance: currentOrderBalance,
            };

            return calculateBalancesv3(receiptList, availablePlans, adjustments, posData, useFiitPriority);
        };

        return FiitMealPlanCalculationService;
    })();

    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = new FiitMealPlanCalculationService();
    } else {
        window.FiitMealPlanCalculationService = new FiitMealPlanCalculationService();
    }
})();
