'use strict';

const angular = require('angular');
const moment = require('moment');

export default angular.module('freshideas.services.errorLogging', [])
.factory('ErrorLoggingService', [
    'ErrorLogging',
    function (ErrorLogging) {

        var log = function (e) {
            var errorObj = {
                createdAt: moment().valueOf()
            };

            if (e.message && e.data) {
                errorObj.message = e.message.toString();
                errorObj.data = e.data.toString();
            } else {
                errorObj.message = e.toString();
            }

            ErrorLogging.log(errorObj);
        };

        return {
            log: log
        };
    }
]);
