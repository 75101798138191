'use strict';

const moment = require('moment');

export function cardTerminalIngenico (freshideas) {

    freshideas.factory('IngenicoCommon', [
        '$timeout',
        '$log',
        'TerminalEvent',
        function ($timeout, $log, TerminalEvent) {
        var _self = {};

        var printResult;
        var responses;

        const HEARTBEAT = '\x11';
        const FS = '\x1c';

        const TRANSACTION_TYPE = '100';
        const TRANSACTION_STATUS = '101'; // missing

        const TRANSACTION_DATE = '102';
        const TRANSACTION_TIME = '103';

        const HOST_RESPONSE_CODE = '401';

        const TRANSACTION_TYPE_PURCHASE = '00';
        const TRANSACTION_TYPE_REFUND = '03';
        const TRANSACTION_TYPE_VOID = '05';
        const TRANSACTION_TYPE_AUTO_SETTLE = '21';
        const LAST_TRANSACTION_STATUS = '91';

        const DATA_TAG_TRANSACTION_AMOUNT = '\x1c001';
        const DATA_TAG_ECR_REFERENCE_NUMBER = '\x1c006';

        // tender type not required in void transaction
        const TRANSACTION_AMOUNT = '104';
        const TIP_AMOUNT = '105';
        const TOTAL_AMOUNT = '109';
        const INVOICE_NUMBER = '110';
        const REFERENCE_NUM = '112';
        const TRANSACTION_SEQUENCE_NUM = '113';
        const CARD_TYPE = '300';
        const CARD_BRAND = '301';
        const MASKED_CARD_NUMBER = '302';
        const DEBIT_ACCOUNT_TYPE = '305';
        const ENTRY_METHOD = '306';
        const EMV_AID = '308';
        const EMV_TVR = '309';
        const EMV_TSI = '310';
        const EMV_APP_LABEL = '311';
        const CVM_RESULT = '312';
        const BALANCE_DUE = '317'; // missing

        const AUTHORIZATION_NUM = '400';
        // const SUCCESS = '401';
        const HOST_RESPONSE_ISO_CODE = '403';
        // const PARTIAL_APPROVED_AMOUNT = '420'; // missing
        const BATCH_NUM = '500';
        const DEMO_MODE = '600';
        const TERMINAL_ID = '601';
        const MERCHANT_ID = '602';

        var cardEntryMode = {
            '0': 'Magnetic Stripe',
            '1': 'Insert (Chip)',
            '2': 'Tap (Contactless)',
            '3': 'Manual Entry',
            '4': 'Chip Fallback to Swipe',
            '5': 'Chip Fallback to Manual',
            '6': 'Card Not Present Manual',
        };

        var customerAccountType = {
            '0': 'default',
            '1': 'savings',
            '2': 'chequing',
        };

        /*
        100:"00"
        102:"170724"
        103:"165334"
        104:"2"
        109:"2"
        112:"19"
        300:"02"
        302:"************4111"
        306:"2"
        400:"99999999"
        401:"000"
        402:"APPROVED"
        403:"00"
        600:"1"
        601:"VARTST04"
        */

        /* Debit print request
        {
            "100": "00",
            "101": "00",
            "102": "170725",
            "103": "101626",
            "104": "2",
            "109": "2",
            "112": "23",
            "300": "00",
            "301": "Debit",
            "302": "************1933",
            "303": "0",
            "305": "2",
            "306": "1",
            "308": "A0000002771010",
            "309": "0000000000",
            "310": "2000",
            "311": "Interac",
            "312": "2",
            "400": "99999999",
            "401": "000",
            "402": "APPROVED",
            "403": "00",
            "500": "1",
            "600": "1",
            "601": "VARTST04",
            "602": "351395",
            "700": "GPC TEST",
            "701": "123456",
            "707": "Thank You",
            "708": "Please Come Again",
            "730": "Z1"
        }
        */

        _self.init = function () {
            printResult = null;
            responses = [];
        };

        var splitFields = function (fieldStr) {
            if (fieldStr[0] != FS) {
                throw new Error('Fields does not start with correct character');
            }

            // remove leading FS
            fieldStr = fieldStr.substring(1);

            var result = {};
            var fields = fieldStr.split(FS);
            for (var i of Object.keys(fields)) {
                var field = fields[i];
                var tag = field.substring(0, 3);
                var data = field.substring(3);
                result[tag] = data;
            }

            return result;
        };

        var parseReceived = function (str) {
            var tranStatus = str.substring(0, 2);
            var multiTran = str[2];
            var fields = str.substring(3);

            var result = fields ? splitFields(fields) : {};

            // tranStatus 91 means we recalled the transaction info from the terminal
            // in this case we want to set the tranStatus what we would normally receive
            // if we had not recalled from the terminal
            if (tranStatus == '91') {
                if (result['101']) {
                    tranStatus = result['101'];
                    multiTran = '0';
                } else {
                    throw new Error('Recall last transaction without transaction status!');
                }
            }

            return [tranStatus, multiTran, result];
        };

        var transactionTypeMap = {
            '00': 'purchase',
            // '01': 'Preauth', //not supported
            // '02': 'Preauth Completion', //not supported
            '03': 'refund',
            '05': 'void',
            '06': 'cardBalanceInquiry',
            '20': 'settlement',
        };

        var toTransactionType = function (code) {
            return transactionTypeMap[code];
        };

        var toTransactionDateTime = function (date, time) {
            if (date && time) {
                var year, month, day, hour, minute, second;

                [year, month, day] = date.match(/.{1,2}/g);
                [hour, minute, second] = time.match(/.{1,2}/g);

                return '20'+year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second;
            } else {
                // for failed transactions, it is possible the terminal does not
                // return any datetime to us. In this case we will make it ourselves.
                return moment().format('YYYY-MM-DD HH:mm:ss');
            }
        };

        _self.ingenicoTerminalResponse = function (transactionStatus, result) {
            /*
            We're using this parser to process both Moneris and GlobalPayment responses.
            There're minor differences:

            - Moneris signature line TAG(713)
                0 – Do not print signature line
                1 – Print Cardholder Signature line
                2 – Print Merchant Signature line
            -  Moneris returns single digit strings for CARD_TYPE
            */

            var isDebit = result[CARD_TYPE] === '00';
            var isCredit = ['01', '02', '03', '04'].indexOf(result[CARD_TYPE]) !== -1;

            var transactionType = toTransactionType(result[TRANSACTION_TYPE]);

            // we are showing signature lines for all refunds/voids as per
            // Real Fruit's request
            var showSignatureLine = (isCredit && (result[CVM_RESULT] === '2' || result[CVM_RESULT] === '3'))
                    || transactionType == 'void'
                    || transactionType == 'refund';


            var verifiedByPin = result[CVM_RESULT] === '1' || result[CVM_RESULT] === '3';

            var response = {
                paymentProcessor: 'globalpayments',

                terminalId: result[TERMINAL_ID],
                transactionId: result[REFERENCE_NUM],

                transactionType: transactionType,
                cardType: result[CARD_TYPE],
                cardName: result[CARD_BRAND],
                maskedCardNumber: result[MASKED_CARD_NUMBER],
                cardNumber: result[MASKED_CARD_NUMBER],
                success: result[TRANSACTION_STATUS] == '00',
                // approvedWithMalfunc: (result[TRANSACTION_STATUS] == '01'),
                balanceDue: result[BALANCE_DUE] ? (result[BALANCE_DUE]/100).toFixed(2) : 0.0,
                batchNum: result[BATCH_NUM],
                tipAmount: result[TIP_AMOUNT] ? (result[TIP_AMOUNT]/100).toFixed(2) : 0.0,
                signatureBase64Png: null,
                debitAccountType: customerAccountType[result[DEBIT_ACCOUNT_TYPE]],
                emvAid: result[EMV_AID],
                emvTvr: result[EMV_TVR],
                emvTsi: result[EMV_TSI],
                emvAppLabel: result[EMV_APP_LABEL],
                cvmResult: result[CVM_RESULT],
                hostResponseCode: result[HOST_RESPONSE_CODE],
                hostResponseIsoCode: result[HOST_RESPONSE_ISO_CODE],
                demoMode: result[DEMO_MODE] === '1',
                isCredit: isCredit,
                isDebit: isDebit, // is debit
                showCustomerSignatureLine: showSignatureLine,

                customerLanguage: 'en',
                merchantId: result[MERCHANT_ID],
                transactionAmount: result[TRANSACTION_AMOUNT] ? (result[TRANSACTION_AMOUNT]/100).toFixed(2) : '0.00',
                partiallyApproved: false,
                transactionTypeCode: result[TRANSACTION_TYPE],
                surchargeAmount: null,
                totalAmount: result[TOTAL_AMOUNT] ? (result[TOTAL_AMOUNT]/100).toFixed(2) : '0.00',
                approvedAmount: result[TOTAL_AMOUNT] ? (result[TOTAL_AMOUNT]/100).toFixed(2) : '0.00',
                cardBalance: null,
                transactionDateTime: toTransactionDateTime(result[TRANSACTION_DATE], result[TRANSACTION_TIME]),
                referenceNumber: result[REFERENCE_NUM],
                cardEntryMethod: cardEntryMode[result[ENTRY_METHOD]],
                authorizationNumber: result[AUTHORIZATION_NUM],
                emvAppPreferredName: result[EMV_APP_LABEL],
                transactionStatus: result[TRANSACTION_STATUS] || transactionStatus,
                verifiedByPin: verifiedByPin,
                invoiceNumber: result[INVOICE_NUMBER],
                formFactor: null,
                transactionSequenceNum: result[TRANSACTION_SEQUENCE_NUM],
            };

            return response;
        };

        function trimHeartbeats (text) {
            text = text.replace(new RegExp('^(['+HEARTBEAT+']*)', 'g'), '');
            text = text.replace(new RegExp('(['+HEARTBEAT+']*)$', 'g'), '');
            return text;
        }

        _self.onDataReceived = function (data, callback) {
            var raw = trimHeartbeats(data.toString());
            if (raw.length > 0) {
                var arr = parseReceived(raw);
                var multiTran = arr[1];

                if (multiTran === '0') {
                    responses.push(arr);
                    allDataReceived(responses, callback);
                    responses = [];
                }
            }
        };

        function allDataReceived (responses, callback) {
            var success = true;

            for (var i of Object.keys(responses)) {
                var arr = responses[i];

                var transactionStatus = arr[0];
                var result = arr[2];

                if (transactionStatus === '99') {
                    printResult = result;
                    callback(TerminalEvent.PRINT);

                    return;
                } else if (['00', '01'].includes(transactionStatus)) {
                    if (!printResult) {
                        // We receive '00' or '01' as a final message from the
                        // terminal after a successful transaction. If for some
                        // reason the terminal did *not* send us a printResult
                        // before this, then we need to use the current result
                        // as the printResult. printResult has more information
                        // such as the printable information (card name etc.)
                        // but it appears we can make do without that...
                        printResult = result;
                        $log.error('Transaction success without printResult');
                    }
                } else if (transactionStatus === '15') {
                    callback(TerminalEvent.BATCH_EMPTY);

                    return;
                } else if (['10', '11', '12', '13', '14', '16', '17', '18'].includes(transactionStatus)) {
                    success = false;
                } else if (transactionStatus === '95') {
                    callback(TerminalEvent.BUSY);

                    return;
                } else {
                    success = false;
                }
            }

            // TODO only storing last result for partial transaction!!
            if (success) {
                callback(TerminalEvent.SUCCESS, printResult, transactionStatus);
            } else {
                callback(TerminalEvent.FAILED, printResult, transactionStatus);
            }
        }

        // Command Builders
        _self.creditSaleCmd = function (amount) {
            return TRANSACTION_TYPE_PURCHASE
                        + DATA_TAG_TRANSACTION_AMOUNT
                        + Math.round(Number(amount)*100, 10);
        };

        _self.lastTransactionStatus = function () {
            return LAST_TRANSACTION_STATUS;
        };

        _self.creditVoidCmd = function (transactionId) {
            return TRANSACTION_TYPE_VOID
                        + DATA_TAG_ECR_REFERENCE_NUMBER
                        + transactionId;
        };

        _self.creditReturnCmd = function (amount) {
            return TRANSACTION_TYPE_REFUND
                        + '\x1c001'
                        + Math.round(Number(amount)*100, 10);
        };

        _self.autoSettleCmd = function () {
            return TRANSACTION_TYPE_AUTO_SETTLE;
        };

        _self.getPrintResult = function () {
            return printResult;
        };

        var saleInProgress = false;
        _self.timeoutWrapper = function (f, lastTransactionStatus) {
            return function (res, rej) {
                var timeout = null;
                var numStatusAttempts = 0;

                if (saleInProgress) {
                    $log.error('Multiple sales initiated in parallel!');
                }
                saleInProgress = true;

                var createTimeout = function () {
                    if (timeout) {
                        cancelTimeout();
                    }
                    timeout = $timeout(function () {
                        getLastTransactionStatus();
                    }, 25000);
                };

                var getLastTransactionStatus = function () {
                    numStatusAttempts += 1;
                    lastTransactionStatus()
                        .then(function (data) {
                            resolve(data);
                        })
                        .catch(function (error) {
                            // get here if the terminal was busy
                            if (error && error.type == 'busy' && numStatusAttempts < 6) {
                                $timeout(getLastTransactionStatus, 5000);
                            } else {
                                reject(error);
                            }
                        });
                };

                var cancelTimeout = function () {
                    if (timeout) {
                        $timeout.cancel(timeout);
                        timeout = null;
                    }
                };

                var resolve = function (x) {
                    cancelTimeout();
                    saleInProgress = false;
                    res(x);
                };

                var reject = function (x) {
                    cancelTimeout();
                    saleInProgress = false;
                    rej(x);
                };

                var onData = function (x) {
                    createTimeout();
                };

                var onConnected = function () {
                    createTimeout();
                };

                try {
                    f(resolve, reject, onData, onConnected);
                } catch (e) {
                    $log.error(e);
                    reject(e);
                }
            };
        };

        return _self;
    }]);


    freshideas.factory('iOSCardTerminalIngenico', [
        '$log',
        'IosTcpClient',
        'IngenicoCommon',
        'TerminalEvent',
        function (
            $log,
            IosTcpClient,
            IngenicoCommon,
            TerminalEvent) {
            var config;

            var init = function (terminalConfig) {
                config = terminalConfig;

                IngenicoCommon.init();
            };

            var respondTo = function (client, event, result, transactionStatus, successCallback, errorCallback) {
                if (event == TerminalEvent.PRINT) {
                    setTimeout(function () {
                        client.write('990');
                    }, 1500);
                } else if (event == TerminalEvent.SUCCESS) {
                    client.disconnect();

                    successCallback([
                        IngenicoCommon.ingenicoTerminalResponse(transactionStatus, result),
                        result
                    ]);
                } else if (event == TerminalEvent.FAILED) {
                    client.disconnect();
                    if (_.isEmpty(result)) {
                        errorCallback(
                            {
                                data: null,
                                message: 'FAILED transaction with no payload'
                            }
                        );
                    } else {
                        errorCallback({
                            data: [
                                IngenicoCommon.ingenicoTerminalResponse(transactionStatus, result),
                                result
                        ]});
                    }
                } else if (event == TerminalEvent.BUSY) {
                    client.disconnect();
                    errorCallback({
                        data: null,
                        message: 'Terminal is busy'
                    });
                } else {
                    client.disconnect();
                    if (_.isEmpty(result)) {
                        errorCallback(
                            {
                                data: null,
                                message: 'Unknown event: ' + event
                            }
                        );
                    } else {
                        errorCallback({
                            data: [
                                IngenicoCommon.ingenicoTerminalResponse(transactionStatus, result),
                                result
                            ]
                        });
                    }
                }
            };

            var lastTransactionStatus = function () {
                return new Promise(function (resolve, reject) {
                  IosTcpClient.connect(config.port, config.ip).then(function (client) {

                    var defaultMessage = IngenicoCommon.lastTransactionStatus();

                    // Listen to the stream of data from the terminal
                    client.onData(function (data) {
                        try {
                            IngenicoCommon.onDataReceived(data, function (event, result, transactionStatus) {
                                respondTo(client, event, result, transactionStatus, function (response) {
                                    resolve(response);
                                }, function (error) {
                                    reject(error);
                                });
                            });
                        } catch (e) {
                            $log.error(e);
                            $log.error({
                                request: defaultMessage,
                                response: data
                            });
                            reject();
                        }
                    });

                    client.write(defaultMessage);
                }).catch(function (error) {
                    $log.error(error);
                    reject({message: error.toString(), type: 'busy'});
                });
              });
            };

            var creditSale = function (amount) {
                var trace = [];
                return new Promise(IngenicoCommon.timeoutWrapper(function (resolve, reject, onData, onConnected) {
                    IosTcpClient.connect(config.port, config.ip).then(function (client) {
                        onConnected();

                        var defaultMessage = IngenicoCommon.creditSaleCmd(amount);

                        // Listen to the stream of data from the terminal
                        client.onData(function (data) {
                            try {
                                onData();
                                trace.push(
                                    {
                                        type: 'read',
                                        data: data ? data.toString() : 'NA',
                                        printResult: IngenicoCommon.getPrintResult(),
                                    }
                                );

                                IngenicoCommon.onDataReceived(data, function (event, result, transactionStatus) {
                                    respondTo(client, event, result, transactionStatus, function (response) {
                                        resolve(response);
                                    }, function (error) {
                                        $log.error(trace);
                                        reject(error);
                                    });
                                });
                            } catch (e) {
                                $log.error(e);
                                $log.error(trace);
                                $log.error({
                                    request: defaultMessage,
                                    response: data
                                });
                                reject({message: e.toString()});
                            }
                        });

                        trace.push(
                            {
                                type: 'write',
                                data: defaultMessage,
                            }
                        );
                        client.write(defaultMessage);
                    }).catch(function (error) {
                        $log.error(error);
                        $log.error(trace);
                        reject({message: error && error.toString()});
                    });
                }, lastTransactionStatus));
            };

            var creditVoid = function (transactionId) {
                return new Promise(function (resolve, reject) {
                    IosTcpClient.connect(config.port, config.ip).then(function (client) {
                        // Listen to the stream of data from the terminal
                        client.onData(function (data) {
                            IngenicoCommon.onDataReceived(data, function (event, result, transactionStatus) {
                                respondTo(client, event, result, transactionStatus, function (response) {
                                    resolve(response);
                                }, function (error) {
                                    reject(error);
                                });
                            });
                        });

                        var defaultMessage = IngenicoCommon.creditVoidCmd(transactionId);

                        client.write(defaultMessage);
                    }).catch(function (error) {
                        $log.error(error);
                        reject();
                    });
                });
            };

            var creditReturn = function (amount) {
                return new Promise(function (resolve, reject) {
                    IosTcpClient.connect(config.port, config.ip).then(function (client) {
                        // Listen to the stream of data from the terminal
                        client.onData(function (data) {
                            IngenicoCommon.onDataReceived(data, function (event, result, transactionStatus) {
                                respondTo(client, event, result, transactionStatus, function (response) {
                                    resolve(response);
                                }, function (error) {
                                    reject(error);
                                });
                            });
                        });

                        var defaultMessage = IngenicoCommon.creditReturnCmd(amount);

                        client.write(defaultMessage);
                    }).catch(function (error) {
                        $log.error(error);
                        reject();
                    });
                });
            };

            var autoSettle = function () {
                return new Promise(function (resolve, reject) {
                    IosTcpClient.connect(config.port, config.ip).then(function (client) {
                        // Listen to the stream of data from the terminal
                        client.onData(function (data) {
                            IngenicoCommon.onDataReceived(data, function (event, result, transactionStatus) {
                                if (event == TerminalEvent.SUCCESS
                                    || event == TerminalEvent.BATCH_EMPTY) {
                                    resolve(result);
                                } else {
                                    $log.error([event, result]);
                                    reject();
                                }

                                client.disconnect();
                            });
                        });

                        var cmd = IngenicoCommon.autoSettleCmd();

                        client.write(cmd);
                    }).catch(function (error) {
                        $log.error(error);
                        reject();
                    });
                });
            };

            var debitSale = function (amount) {
                return creditSale(amount);
            };

            var debitReturn = function (amount) {
                return creditReturn(amount);
            };

            var testSale = function () {
                return creditSale(0.01);
            };

            var parseResponse = function (response) {
                return IngenicoCommon.ingenicoTerminalResponse(null, response);
            };

            return {
                init: init,
                creditSale: creditSale,
                creditVoid: creditVoid,
                creditReturn: creditReturn,
                debitSale: debitSale,
                debitReturn: debitReturn,
                testSale: testSale,
                autoSettle: autoSettle,
                parseResponse: parseResponse
            };
        }]);


    freshideas.factory('CardTerminalIngenico', [
        '$log',
        'IngenicoCommon',
        'TerminalEvent',
        function (
            $log,
            IngenicoCommon,
            TerminalEvent) {
            var config;
            var client;

            var init = function (terminalConfig) {
                if (typeof window !== 'undefined') {
                    // running from browser
                    if (!window.process || !window.process.type) {
                        throw new Error('Running in non-electron environment. Terminal will be disabled');
                    }
                }

                var net = nodeRequire('net');
                client = new net.Socket();

                IngenicoCommon.init();

                config = terminalConfig;
            };

            var respondTo = function (client, event, result, transactionStatus, successCallback, errorCallback) {
                if (event == TerminalEvent.PRINT) {
                    setTimeout(function () {
                        client.write('990');
                    }, 1500);
                } else if (event == TerminalEvent.SUCCESS) {
                    successCallback([
                        IngenicoCommon.ingenicoTerminalResponse(transactionStatus, result),
                        result
                    ]);
                } else if (event == TerminalEvent.FAILED) {
                    if (_.isEmpty(result)) {
                        errorCallback(
                            {
                                data: null,
                                message: 'FAILED transaction with no payload'
                            }
                        );
                    } else {
                        errorCallback({
                            data: [
                                IngenicoCommon.ingenicoTerminalResponse(transactionStatus, result),
                                result
                        ]});
                    }
                } else if (event == TerminalEvent.BUSY) {
                    errorCallback({
                        data: null,
                        message: 'Terminal is busy'
                    });
                } else {
                    if (_.isEmpty(result)) {
                        errorCallback(
                            {
                                data: null,
                                message: 'Unknown event: ' + event
                            }
                        );
                    } else {
                        errorCallback({
                            data: [
                                IngenicoCommon.ingenicoTerminalResponse(transactionStatus, result),
                                result
                            ]
                        });
                    }
                }
            };

            var lastTransactionStatus = function () {
                return new Promise(function (resolve, reject) {
                    client.connect({timeout: 20000, port: config.port, host: config.ip}, function () {
                        var defaultMessage = IngenicoCommon.lastTransactionStatus();

                        client.on('data', function (data) {
                            try {
                                // remove leading '91\xfc'
                                data = data.slice(3);
                                IngenicoCommon.onDataReceived(data, function (event, result, transactionStatus) {
                                    try {
                                        respondTo(client, event, result, transactionStatus, function (response) {
                                            resolve(response);
                                        }, function (error) {
                                            reject(error);
                                        });
                                    } catch (e) {
                                        $log.error(e);
                                        $log.error(result);
                                        throw e;
                                    }
                                });
                            } catch (e) {
                                $log.error(e);
                                $log.error({
                                    request: defaultMessage,
                                    response: data
                                });
                                reject();
                            }
                        });

                        client.write(defaultMessage);
                    });

                    client.on('error', function (error) {
                        reject({message: error.toString(), type: 'busy'});
                    });

                    client.on('close', function () {
                    });
                });
            };

            var creditSale = function (amount) {
                var connectAttempts = 0;
                var didConnect = false;
                return new Promise(IngenicoCommon.timeoutWrapper(function (resolve, reject, onData, onConnected) {
                    var connect = function () {
                        connectAttempts++;

                        client.connect({timeout: 20000, port: config.port, host: config.ip}, function () {
                            onConnected();
                            didConnect = true;
                            var defaultMessage = IngenicoCommon.creditSaleCmd(amount);

                            client.on('data', function (data) {
                                try {
                                    onData();

                                    IngenicoCommon.onDataReceived(data, function (event, result, transactionStatus) {
                                        respondTo(client, event, result, transactionStatus, function (response) {
                                            resolve(response);
                                        }, function (error) {
                                            $log.error(error);
                                            reject(error);
                                        });
                                    });
                                } catch (e) {
                                    $log.error(e);
                                    $log.error({
                                        request: defaultMessage,
                                        response: data
                                    });
                                    reject({message: e.toString()});
                                }
                            });

                            client.write(defaultMessage);
                        });
                    };
                    connect();

                    client.on('error', function (error) {
                        if (!didConnect && connectAttempts < 3) {
                            setTimeout(connect, 1000);
                        } else {
                            reject({message: error.toString()});
                        }
                    });

                    client.on('close', function () {
                    });
                }, lastTransactionStatus));
            };

            var creditVoid = function (transactionId) {
                return new Promise(function (resolve, reject) {
                    client.connect({timeout: 20000, port: config.port, host: config.ip}, function () {
                        var defaultMessage = IngenicoCommon.creditVoidCmd(transactionId);

                        client.write(defaultMessage);
                    });

                    client.on('error', function (error) {
                        reject();
                    });

                    client.on('data', function (data) {
                        IngenicoCommon.onDataReceived(data, function (event, result, transactionStatus) {
                            respondTo(client, event, result, transactionStatus, function (response) {
                                resolve(response);
                            }, function (error) {
                                reject(error);
                            });
                        });
                    });
                });
            };

            var creditReturn = function (amount) {
                return new Promise(function (resolve, reject) {
                    client.connect({timeout: 20000, port: config.port, host: config.ip}, function () {
                        var defaultMessage = IngenicoCommon.creditReturnCmd(amount);

                        client.write(defaultMessage);
                    });

                    client.on('error', function (error) {
                        reject();
                    });

                    client.on('data', function (data) {
                        IngenicoCommon.onDataReceived(data, function (event, result, transactionStatus) {
                            respondTo(client, event, result, transactionStatus, function (response) {
                                resolve(response);
                            }, function (error) {
                                reject(error);
                            });
                        });
                    });
                });
            };

            var autoSettle = function () {
                return new Promise(function (resolve, reject) {
                    client.connect({timeout: 20000, port: config.port, host: config.ip}, function () {
                        client.write(IngenicoCommon.autoSettleCmd());
                    });

                    client.on('error', function (error) {
                        reject();
                    });

                    client.on('data', function (data) {
                        IngenicoCommon.onDataReceived(data, function (event, result, transactionStatus) {
                            if (event == TerminalEvent.SUCCESS
                                || event == TerminalEvent.BATCH_EMPTY) {
                                resolve(result);
                            } else {
                                $log.error([event, result]);
                                // reject();
                                resolve();
                            }
                        });
                    });
                });
            };

            var debitSale = function (amount) {
                return creditSale(amount);
            };

            var debitReturn = function (amount) {
                return creditReturn(amount);
            };

            var testSale = function () {
                return creditSale(0.01);
            };

            var parseResponse = function (response) {
                return IngenicoCommon.ingenicoTerminalResponse(null, response);
            };

            return {
                init: init,
                creditSale: creditSale,
                creditVoid: creditVoid,
                creditReturn: creditReturn,
                debitSale: debitSale,
                debitReturn: debitReturn,
                testSale: testSale,
                autoSettle: autoSettle,
                parseResponse: parseResponse
            };
        }
    ]);
}
