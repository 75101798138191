'use strict';

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('EventAttendees', [
        '$scope',
        'SmbPosService',
        'patronKeys',
        'patrons',
        function (
            $scope,
            SmbPosService,
            patronKeys,
            patrons) {

            $scope.patrons = patrons || [];

            const findFiitPatrons = (patronKeys = []) => {
                if (!patronKeys.length) {
                    return Promise.resolve([]);
                }

                $scope.loadingPatrons = true;
                const promises = patronKeys.map((patronKey) => SmbPosService.fetchPatronFromFiitBackend(undefined, patronKey, {}, {}));
                return Promise.allSettled(promises);
            };

            findFiitPatrons(patronKeys)
            .then((res) => {
                res.forEach((patronResult) => {
                    if (patronResult.status === 'fulfilled') {
                        const {fullName, fiitMpsAccount: {patronKey}} = patronResult.value;
                        $scope.patrons.push({patronKey, fullName});
                    }
                });
            })
            .finally(() => {
                $scope.loadingPatrons = false;
                $scope.$apply(); // we need to update view manually, since patrons and loadingPatrons are not being watched
            });
        }
    ]);
};
