'use strict';


const angular = require('angular');
const ngResource = require('angular-resource');

export default angular.module('freshideas.resources.inventory', [ngResource])
.service('Inventory', ['$resource', 'EnvConfig', function ($resource, EnvConfig) {
    return $resource(EnvConfig.host + '/freshideas/web/inventory', {}, {
        'getInvoice': {method: 'GET', url: EnvConfig.host + '/freshideas/web/inventory/invoice'},
        'getDefault': {method: 'GET', url: EnvConfig.host + '/freshideas/web/inventory/default'},
        'getInvoices': {method: 'GET', cache: false, url: EnvConfig.host + '/freshideas/web/inventory/list'},
        'update': {method: 'POST', url: EnvConfig.host + '/freshideas/web/inventory/invoice'},
        'add': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/inventory/invoice'},
        'putInventory': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/inventory/inventory'},
        'deleteInvoice': {method: 'DELETE', url: EnvConfig.host + '/freshideas/web/inventory/invoice'},
        'inventoryList': {method: 'GET', url: EnvConfig.host + '/freshideas/web/inventory/inventoryList'},
        'inventoryByLocation': {method: 'GET', url: EnvConfig.host + '/freshideas/web/inventory/inventoryByLocation', isArray: true},
        'updateInventory': {method: 'POST', url: EnvConfig.host + '/freshideas/web/inventory/inventory'},
        'getMenuItem': {
            method: 'GET',
            url: EnvConfig.host + '/freshideas/web/inventory/menuItem/:menuItemId'
        },
        'addMenuItem': {
            method: 'POST',
            url: EnvConfig.host + '/freshideas/web/inventory/menuItem'
        },
        'updateMenuItem': {
            method: 'PUT',
            url: EnvConfig.host + '/freshideas/web/inventory/menuItem/:menuItemId'
        },
        'updateMenuItemVariationPrinterSettings': {
            method: 'PUT',
            url: EnvConfig.host + '/freshideas/web/inventory/menuItem/:menuItemId/updateVariationPrinterSettings'
        },
        'deleteMenuItem': {
            method: 'DELETE',
            url: EnvConfig.host + '/freshideas/web/inventory/menuItem/:menuItemId'
        },
        'getMenuItemCategories': {
            method: 'GET',
            url: EnvConfig.host + '/freshideas/web/inventory/menuItem/:menuItemId/categories',
            isArray: true
        },
        'getMenuItemImages': {
            method: 'GET',
            url: EnvConfig.host + '/freshideas/web/inventory/menuItem/:menuItemId/images',
            isArray: true
        },
        'addMenuItemImages': {
            method: 'POST',
            url: EnvConfig.host + '/freshideas/web/inventory/menuItem/:menuItemId/images'
        },
        'updateMenuItemImages': {
            method: 'PUT',
            url: EnvConfig.host + '/freshideas/web/inventory/menuItem/:menuItemId/images'
        },
        'deleteMenuItemImages': {
            method: 'DELETE',
            url: EnvConfig.host + '/freshideas/web/inventory/menuItem/:menuItemId/delete/images'
        },
        'getMenuItemTagNames': {
            method: 'GET',
            url: EnvConfig.host + '/freshideas/web/inventory/menuItem/:menuItemId/tagNames',
            isArray: true
        },
        'updateMenuItemAvailability': {
            method: 'PUT',
            url: EnvConfig.host + '/freshideas/web/inventory/menuItem/:menuItemId/available'
        },
        'updateMenuItemPriceReplacedBy': {
            method: 'PUT',
            url: EnvConfig.host + '/freshideas/web/inventory/menuItem/:menuItemId/priceReplacedBy'
        },
        'moveMenuItem': {
            method: 'PUT',
            url: EnvConfig.host + '/freshideas/web/inventory/menuItem/move',
        },
        'batchMoveMenuItem': {
            method: 'POST',
            url: EnvConfig.host + '/freshideas/web/inventory/menuItem/batchMove',
            isArray: true
        },
        'searchMenuItem': {
            method: 'GET',
            url: EnvConfig.host + '/freshideas/web/inventory/menuItem/search'
        },
        'searchActiveTagNames': {
            method: 'GET',
            url: EnvConfig.host + '/freshideas/web/inventory/tags/active',
            isArray: true
        },

        'getStocks': {
            method: 'GET',
            url: EnvConfig.host + '/freshideas/web/inventory/stock/list',
            isArray: true
        },
        'stock': {
            method: 'GET',
            url: EnvConfig.host + '/freshideas/web/inventory/stock/:menuItemId'
        },

        'warehouseList': {
            method: 'GET',
            url: EnvConfig.host + '/freshideas/web/inventory/warehouse/list',
            isArray: true
        },
        'addWarehouse': {
            method: 'POST',
            url: EnvConfig.host + '/freshideas/web/inventory/warehouse/create'
        },

        'getIncomingOrders': {
            method: 'GET',
            url: EnvConfig.host + '/freshideas/web/inventory/getIncomingOrders' // TODO
        },
        'stashOrder': {
            method: 'POST',
            url: EnvConfig.host + '/freshideas/web/inventory/stashOrder'
        },
        'receiveOrder': {
            method: 'POST',
            url: EnvConfig.host + '/freshideas/web/inventory/receiveOrder'
        }
    });
}]);
