'use strict';


// const Pure = require('./pure');
const SharedDataService = require('./pos.data-service.js');
const TaxRuleService = require('./tax-rule-service.js');
const LoyaltyRedemption = require('./loyalty/redemption.js');
const LoyaltyCollection = require('./loyalty/collection.js');

(function () {
    var SharedFunctionService = (function () {
        var SharedFunctionService = function () {};

        const Decimal = require('decimal.js').default;
        const lodash = require('lodash');
        const _ = require('underscore');

        function sortPlans (a, b) {
            return a.priority - b.priority;
        }
        function sortReceiptByMealRev (a, b) {
            if (a.remainingMealUnits === b.remainingMealUnits) {
                return a.remainingPriceTaxIn.minus(b.remainingPriceTaxIn).toNumber();
            } else {
                return b.remainingMealUnits - a.remainingMealUnits;
            }
        }
        function sortReceiptByMeal (a, b) {
            if (a.remainingMealUnits === b.remainingMealUnits) {
                return a.remainingPriceTaxIn.minus(b.remainingPriceTaxIn).toNumber();
            } else {
                return a.remainingMealUnits - b.remainingMealUnits;
            }
        }
        function combineIds (units) {
            var idSet = new Set();
            for (var i = units.length - 1; i >= 0; i--) {
                var obj = units[i];
                idSet.add(obj.id);
            }
            var arr = Array.from(idSet);
            return arr.join(',');
        }
        function combineRemainingMealUnit (a, b) {
            return {'remainingMealUnits': a.remainingMealUnits + b.remainingMealUnits};
        }
        function combineUnit (a, b) {
            return {'unit': a.unit.plus(b.unit)};
        }
        function combineMealUnit (a, b) {
            return {'unit': a.unit + b.unit};
        }
        function combineTax (a, b) {
            return {'tax': a.tax.plus(b.tax)};
        }
        // function combineRemainingPrice (a, b) {
        //     return {'remainingPrice': a.remainingPrice.plus(b.remainingPrice)};
        // }
        // function combineRemainingPriceTaxIn (a, b) {
        //     return {'remainingPriceTaxIn': a.remainingPriceTaxIn.plus(b.remainingPriceTaxIn)};
        // }
        // Commented out for now since there isn't an easy way to use these two methods without
        // introducing bugs
        /* function combineRemainingPriceTaxable(a, b) {
            var taxableA = (a.aggregated || a.taxRate > 0)? a.remainingPrice : new Decimal(0);
            var taxableB = (b.taxRate > 0)? b.remainingPrice : new Decimal(0);

            return {
                remainingPrice: taxableA.plus(taxableB),
                aggregated: true
            };
            // return b.taxRate > 0 ? { 'remainingPriceTaxIn': a.remainingPriceTaxIn.plus(b.remainingPriceTaxIn) } : { 'remainingPriceTaxIn': a.remainingPriceTaxIn };
        }
        function combineRemainingPriceUntaxable(a, b) {
            var unTaxableA = (a.aggregated || a.taxRate === 0)? a.remainingPrice : new Decimal(0);
            var unTaxableB = (b.taxRate === 0)? b.remainingPrice : new Decimal(0);

            return {
                remainingPrice: unTaxableA.plus(unTaxableB),
                aggregated: true
            };

            // return b.taxRate === 0 ? { 'remainingPrice': a.remainingPrice.plus(b.remainingPrice) } : { 'remainingPriceTaxIn': a.remainingPrice };
        } */

        function getTransactionTax (mu, posData) {
            if (typeof mu === 'undefined') {
                mu = 0;
            }
            var val = 0;
            var mealsDiscounted = 0;
            if (posData.receipt !== undefined && posData.receipt !== null) {
                for (var i = 0; i < posData.receipt.length; i++) {
                    var tempItem = posData.receipt[i];
                    for (var a = 0; a < tempItem.quantity; a++) {
                        if (mealsDiscounted >= mu) {
                            val += Number(tempItem.itemTaxAmount);
                        } else {
                            mealsDiscounted++;
                        }
                    }
                }
            }
            return val;
        }
        function getTransactionSubTotal (mu, posData) {
            if (typeof mu === 'undefined') {
                mu = 0;
            }
            var val = 0;
            var mealsDiscounted = 0;
            if (posData.receipt !== undefined && posData.receipt !== null) {
                for (var i = 0; i < posData.receipt.length; i++) {
                    var tempItem = posData.receipt[i];
                    for (var a = 0; a < tempItem.quantity; a++) {
                        if (mealsDiscounted >= mu) {
                            val += Number(tempItem.itemPrice);
                        } else {
                            mealsDiscounted++;
                        }
                    }
                }
            }
            return val;
        }

        SharedFunctionService.prototype.createPosData = function createPosData (receipt, patron, isGuestTransaction,
            shiftLocationId, currentOrderBalance, organizationSettings,
            {lucovaUser = null, company = null, location = null} = {}) {
            var isLucovaUser = (!!lucovaUser) || (!!(patron && patron.lucovaUser));

            return {
                receipt: receipt,
                allowedMealEquivalencyPerCycle: 999999999, // allowedMealEquivalencyPerCycle,
                allowedMealEquivalency: true, // allowedMealEquivalency,
                allowedMealEquivalencyDollar: 0.01, // allowedMealEquivalencyDollar,
                guestTransaction: isGuestTransaction,
                shiftLocationId: shiftLocationId,
                currentOrderBalance: currentOrderBalance,
                isLucovaUser: isLucovaUser,
                organizationSettings: organizationSettings,
                company: company || SharedDataService.company,
                location: location || SharedDataService.location
            };
        };

        SharedFunctionService.prototype.addOrUpdateTips = function (adjustments, tenderUuid, tipAmount) {
            if (!adjustments || !adjustments.tipsInCents || !tenderUuid) {
                throw new Error('Invalid Paramters to add a tip ');
            }

            adjustments.tipsInCents[tenderUuid] = new Decimal(tipAmount || 0).times(100).toNearest(
                SharedDataService.baseDollar).toNumber();
            return adjustments;
        };

        var getNownAdjustmentsObject = SharedFunctionService.prototype.getNownAdjustmentsObject = function () {
            return {
                dcbAdjustment: 0,
                cashAdjustment: 0,
                creditCardAdjustment: 0,
                debitCardAdjustment: 0,
                otherAdjustment: 0,
                giftCardAdjustment: 0,
                percentDiscount: 0,
                dollarDiscount: 0,
                mealEquivalencyAdjustment: false,
                cashCardAdjustment: 0,
                tipsInCents: {}
            };
        };

        function ensureAdjustmentInitialized (adjustments) {
            var adjust = adjustments || getNownAdjustmentsObject();

            adjust.dcbAdjustment = adjust.dcbAdjustment || 0;
            adjust.cashAdjustment = adjust.cashAdjustment || 0;
            adjust.creditCardAdjustment = adjust.creditCardAdjustment || 0;
            adjust.debitCardAdjustment = adjust.debitCardAdjustment || 0;
            adjust.otherAdjustment = adjust.otherAdjustment || 0;
            adjust.giftCardAdjustment = adjust.giftCardAdjustment || 0;
            adjust.percentDiscount = adjust.percentDiscount || 0;
            adjust.dollarDiscount = adjust.dollarDiscount || 0;
            adjust.cashCardAdjustment = adjust.cashCardAdjustment || 0;
            adjust.tipsInCents = adjust.tipsInCents || {};
            adjust.mealEquivalencyAdjustment = adjust.mealEquivalencyAdjustment || false;

            return adjust;
        }
        function initPaymentsObject () {
            return {
                'mealUnits': [],
                'mealEqUnits': [],
                'dcbUnits': [],
                'icbUnits': [],
                'discountUnits': [],
                'totalMeals': 0,
                'subTotalAmount': new Decimal(0),
                'totalAmount': new Decimal(0),
                'taxAmount': new Decimal(0),
                'totalDiscount': new Decimal(0),
                'unaccounted': new Decimal(0),
                'deliveryAmount': new Decimal(0),
                'deliveryTaxAmount': new Decimal(0)
            };
        }
        function canDeferMealUnit (availableUnits, requiredUnits, posData) {
            var remainingMealUnits = requiredUnits.mealUnits.length > 0 ? requiredUnits.mealUnits.reduce(combineRemainingMealUnit).remainingMealUnits : 0;
            var availableMealUnits = availableUnits.mealUnits;
            if (availableMealUnits > 0 && remainingMealUnits > 0 && (remainingMealUnits <= availableMealUnits || posData.deferred < availableMealUnits)) {
                posData.deferred++;
                return true;
            }
            return false;
        }
        function deductMealUnit (id, mealEquivalencyEnabled, unitsToProcess, requiredUnits, availableUnits, adjustments, payments, posData) {
            // If the plan is of the DCB type
            var unitsToDeduct = unitsToProcess;
            var applicableItems = requiredUnits.mealUnits.sort(sortReceiptByMealRev);
            // Loop through applicable items
            _.each(applicableItems, function (item, index) {
                // If the applicable item has any remaining meal units to process, proceed
                if (item.remainingMealUnits > 0) {

                    // Calculate the tax rate to be applied for tax-in values
                    // var taxRateMulti = new Decimal(1 + item.taxRate);
                    var unitMeals = item.remainingMealUnits;
                    // var unitAmount = new Decimal(item.remainingPrice);
                    // var unitAmountTaxIn = new Decimal(item.remainingPriceTaxIn);
                    var deductAmount = Math.min(unitsToDeduct, unitMeals);

                    if (unitsToDeduct > 0) {
                        unitsToDeduct = Math.max(unitsToDeduct - deductAmount, 0);

                        var paymentItem = {
                            'id': id,
                            'unit': deductAmount,
                            'tax': 0
                        };

                        availableUnits['mealUnits'] = availableUnits['mealUnits'] - deductAmount;

                        payments['mealUnits'].push(paymentItem);
                        item.remainingPrice = item.remainingPrice.dividedBy(item.remainingMealUnits).toNearest(SharedDataService.baseDollar);
                        item.remainingPriceTaxIn = item.remainingPriceTaxIn.dividedBy(item.remainingMealUnits).toNearest(SharedDataService.baseDollar);
                        item.remainingMealUnits = item.remainingMealUnits - deductAmount;
                        item.remainingPrice = item.remainingPrice.times(item.remainingMealUnits).toNearest(SharedDataService.baseDollar);
                        item.remainingPriceTaxIn = item.remainingPriceTaxIn.times(item.remainingMealUnits).toNearest(SharedDataService.baseDollar);
                    }
                }
            });

            if (mealEquivalencyEnabled) { // Replace with meal plan flag
                var mealEqRemaining = unitsToDeduct > posData.allowedMealEquivalencyPerCycle ? posData.allowedMealEquivalencyPerCycle : unitsToDeduct;
                var mealEqAllowedDollar = mealEqRemaining * posData.allowedMealEquivalencyDollar;

                if (mealEqAllowedDollar > 0 && posData.allowedMealEquivalency === true && adjustments && adjustments.mealEquivalencyAdjustment === true) {
                    // For "taxFree" paramter, FIIT had this hardcoded `true` for reasons that can't be remembered.
                    // In Nown, this is hardcoded to `false` for now so that points are deducted AFTER tax is calculated.
                    // We will have to look into whether this should be a hardcoded global setting or by meal plan.
                    deductUnit(id, false, mealEqAllowedDollar, 'mealEqUnits', 'mealEqUnits', requiredUnits, availableUnits, adjustments, payments, posData);
                }
            }
        }

        function deductUnit (id, taxFree, unitsToProcess, unitProperty, unitTaxProperty, requiredUnits, availableUnits, adjustments, payments, posData) {

            var unitsToDeduct = new Decimal(unitsToProcess);
            var applicableItems = requiredUnits.cashUnits.concat(requiredUnits.mealUnits).sort(sortReceiptByMeal);

            _.each(applicableItems, function (item, index) {
                if (item.remainingPrice.greaterThan(0)) {

                    if (unitProperty === 'mealEqUnits' && !item.mealEquivalencyEnabled) {
                        return;
                    }

                    var taxRateMulti = new Decimal(1 + item.taxRate);
                    // var unitMeals = item.remainingMealUnits;
                    var unitAmount = new Decimal(item.remainingPrice);
                    var unitAmountTaxIn = new Decimal(item.remainingPriceTaxIn);
                    var deductAmount = taxFree ? unitAmount : unitAmountTaxIn;

                    if (unitsToDeduct.lessThan(deductAmount)) {
                        unitAmount = taxFree ? unitsToDeduct : unitsToDeduct.dividedBy(taxRateMulti);
                        unitAmountTaxIn = taxFree ? unitsToDeduct.times(taxRateMulti) : unitsToDeduct;
                        deductAmount = taxFree ? unitAmount : unitAmountTaxIn;
                    }

                    if (canDeferMealUnit(availableUnits, requiredUnits, posData) && item.remainingMealUnits > 0) {
                        return;
                    }

                    if (unitsToDeduct.greaterThan(0)) {

                        unitsToDeduct = (unitsToDeduct.greaterThanOrEqualTo(deductAmount)) ? unitsToDeduct.minus(deductAmount) : new Decimal(0);

                        var taxAmount = deductAmount.minus(unitAmount);
                        var paymentItem = {
                            'id': id,
                            'unit': deductAmount,
                            'tax': taxAmount
                        };

                        if (unitProperty !== 'mealEqUnits') {
                            if (taxFree) {
                                availableUnits[unitProperty] = availableUnits[unitProperty].minus(deductAmount);
                            } else {
                                availableUnits[unitTaxProperty] = availableUnits[unitTaxProperty].minus(deductAmount);
                            }
                        } else {
                            availableUnits['mealUnits'] = availableUnits['mealUnits'] - 1;
                        }

                        payments[unitProperty].push(paymentItem);
                        item.remainingPrice = item.remainingPrice.minus(unitAmount);
                        item.remainingPriceTaxIn = item.remainingPriceTaxIn.minus(unitAmountTaxIn);
                        item.remainingMealUnits = 0;
                    }
                }
            });
        }
        function sumTotals (requiredUnits, adjustments) {
            var items = requiredUnits.cashUnits.concat(requiredUnits.mealUnits);
            // var len = items.length;

            // Calculate percent discount amount by comparing discounted and non-discounted subtotals
            // to avoid any rounding precision issue
            var allSubtotal = new Decimal(0);
            var allTotal = new Decimal(0);
            var nonDiscountSubtotal = new Decimal(0);
            var nonDiscountTax = new Decimal(0);
            var nonDiscountTotal = new Decimal(0);

            var totalTaxable = new Decimal(0);
            var totalNonTaxable = new Decimal(0);

            _.each(items, function (item) {
                if (item.taxRate > 0) {
                    totalTaxable = totalTaxable.plus(item.remainingPrice);
                } else {
                    totalNonTaxable = totalNonTaxable.plus(item.remainingPrice);
                }

                allSubtotal = allSubtotal.plus(new Decimal(item.remainingPrice));
                allTotal = allTotal.plus(new Decimal(item.remainingPriceTaxIn));

                if (item.remainingPrice >= 0) {
                    nonDiscountSubtotal = nonDiscountSubtotal.plus(new Decimal(item.remainingPrice));
                    nonDiscountTotal = nonDiscountTotal.plus(new Decimal(item.remainingPriceTaxIn));
                }
            });

            nonDiscountTax = nonDiscountTotal.minus(nonDiscountSubtotal);


            var deliveryObj = calculateDelivery(adjustments, allSubtotal, null);

            var deliveryFee = deliveryObj.deliveryFee;
            var deliveryFeeTaxInclusive = deliveryObj.deliveryFeeTaxInclusive;
            var deliveryTax = deliveryObj.deliveryTax;

            var remainingPriceWithoutTax = allSubtotal;
            var remainingPriceWithTax = allTotal.plus(deliveryFeeTaxInclusive);
            var tax = remainingPriceWithTax.minus(remainingPriceWithoutTax).minus(deliveryFee);

            /**
             * `prepaidAmounts` is a list of dcb patron meal plans. This
             * calculation block takes a list of dcb plans if available
             * and deducts the remaining price including taxes from meal
             * plans with available balance
             */
            if (adjustments && adjustments.prepaidAmounts) {
                var cashCardAdjustment = new Decimal(0);
                var balance = new Decimal(remainingPriceWithTax);
                var balanceWithoutTax = new Decimal(remainingPriceWithoutTax);

                for (let discount of adjustments.prepaidAmounts) {
                    var availableDiscountAmount = new Decimal(discount.balance || 0);
                    var amountToDeduct = new Decimal(Math.min(balance, availableDiscountAmount));
                    var amountToDeductWithoutTax = amountToDeduct.minus(tax);

                    if (amountToDeduct === 0) {
                        continue;
                    }
                    balance = balance.minus(amountToDeduct);
                    balanceWithoutTax = balanceWithoutTax.minus(amountToDeductWithoutTax);

                    discount.amountToDeduct = amountToDeduct.toNumber();

                    cashCardAdjustment = cashCardAdjustment.plus(amountToDeduct);
                }

                adjustments.cashCardAdjustment = cashCardAdjustment;
            }

            if (adjustments && adjustments.dollarDiscount) {
                var dollarDiscount = new Decimal(adjustments.dollarDiscount);
                remainingPriceWithoutTax = remainingPriceWithoutTax.minus(dollarDiscount);
            }

            var result = {
                items: items,
                subTotalCost: remainingPriceWithoutTax.toNearest(SharedDataService.baseDollar).toNumber(),
                totalCost: remainingPriceWithTax.toNearest(SharedDataService.baseDollar).toNumber(),
                tax: tax.toNearest(SharedDataService.baseDollar).toNumber(),
                nonDiscountSubtotalCost: nonDiscountSubtotal.toNearest(SharedDataService.baseDollar).toNumber(),
                nonDiscountTax: nonDiscountTax.toNearest(SharedDataService.baseDollar).toNumber(),
                nonDiscountTotalCost: nonDiscountTotal.toNearest(SharedDataService.baseDollar).toNumber(),
                deliveryCost: deliveryFee.toNumber(),
                deliveryTax: deliveryTax.toNumber()
            };

            result.taxable = totalTaxable.toNearest(SharedDataService.baseDollar).toNumber();
            result.untaxable = totalNonTaxable.toNearest(SharedDataService.baseDollar).toNumber();

            return result;
        }

        function processTendered (posData, adjust, unaccounted, payments) {

            var totalMealEquivalencyDollarUsed = payments.mealEqUnits.length > 0
                ? new Decimal(payments.mealEqUnits.reduce(combineUnit).unit).toNearest(SharedDataService.baseDollar).toNumber()
                : 0;
            var totalMealEquivalencyUnitUsed = posData.allowedMealEquivalencyDollar > 0 ? Math.ceil(totalMealEquivalencyDollarUsed / posData.allowedMealEquivalencyDollar) : 0;

            var taxCost = 0;
            var totalCost = 0;

            if (payments.mealUnits.length > 0) {
                payments.totalMeals = payments.mealUnits.reduce(combineMealUnit).unit;
            }
            if (payments.dcbUnits.length > 0) {
                totalCost += payments.dcbUnits.reduce(combineUnit).unit.toNearest(SharedDataService.baseDollar).toNumber();
                taxCost += payments.dcbUnits.reduce(combineTax).tax.toNearest(SharedDataService.baseDollar).toNumber();
            }
            if (payments.icbUnits.length > 0) {
                totalCost += payments.icbUnits.reduce(combineUnit).unit.toNearest(SharedDataService.baseDollar).toNumber();
                taxCost += payments.icbUnits.reduce(combineTax).tax.toNearest(SharedDataService.baseDollar).toNumber();
            }

            payments.unaccounted = unaccounted.totalCost;
            payments.taxAmount = taxCost + (unaccounted.totalCost - unaccounted.subTotalCost);
            payments.totalAmount = totalCost + unaccounted.totalCost;
            payments.subTotalAmount = totalCost - taxCost + unaccounted.subTotalCost;
            payments.totalDiscount = payments.discountUnits.length > 0
                ? new Decimal(payments.discountUnits.reduce(combineUnit).unit).toNearest(SharedDataService.baseDollar).toNumber()
                : 0;

            var decimalTotalTaxes = new (payments.taxAmount).toNearest(SharedDataService.baseDollar);
            var decimalTotalSales = new (unaccounted.totalCost).toNearest(SharedDataService.baseDollar);
            var decimalTotalTaxable = new (unaccounted.taxable).toNearest(SharedDataService.baseDollar);
            var decimalTotalNonTaxable = new (unaccounted.untaxable).toNearest(SharedDataService.baseDollar);
            var decimalTotalDiscount = new (payments.totalDiscount).toNearest(SharedDataService.baseDollar);
            var decimalDollarDiscount = new (adjust.dollarDiscount).toNearest(SharedDataService.baseDollar);

            var decimalCashAdjustment = new Decimal(adjust.cashAdjustment).toNearest(SharedDataService.baseDollar);
            var decimalCreditCardAdjustment = new Decimal(adjust.creditCardAdjustment).toNearest(SharedDataService.baseDollar);
            var decimalDebitCardAdjustment = new Decimal(adjust.debitCardAdjustment).toNearest(SharedDataService.baseDollar);
            var decimalOtherAdjustment = new Decimal(adjust.otherAdjustment).toNearest(SharedDataService.baseDollar);
            var decimalRemainingBalance = decimalTotalSales
                .minus(decimalCashAdjustment)
                .minus(decimalCreditCardAdjustment)
                .minus(decimalDebitCardAdjustment)
                .minus(decimalOtherAdjustment);

            var tenderAmounts = {
                guestTransaction: posData.guestTransaction,
                locationId: lodash.cloneDeep(posData.shiftLocationId),
                mealPlanCount: payments.mealUnits.length > 0 ? Number(payments.mealUnits.reduce(combineMealUnit).unit) + totalMealEquivalencyUnitUsed : 0 + totalMealEquivalencyUnitUsed,
                mealEqAmount: totalMealEquivalencyDollarUsed,
                chargeAmount: payments.icbUnits.length > 0
                    ? new Decimal(payments.icbUnits.reduce(combineUnit).unit).toNearest(SharedDataService.baseDollar)
                    : 0,
                dcbAmount: payments.dcbUnits.length > 0
                    ? new Decimal(payments.dcbUnits.reduce(combineUnit).unit).toNearest(SharedDataService.baseDollar)
                    : 0,
                cashAmount: decimalCashAdjustment.toNumber(),
                creditCardAmount: decimalCreditCardAdjustment.toNumber(),
                debitCardAmount: decimalDebitCardAdjustment.toNumber(),
                otherAmount: decimalOtherAdjustment.toNumber(),
                remainingBalance: decimalRemainingBalance.toNumber(),
                totalTaxes: decimalTotalTaxes.toNumber(),
                totalSales: decimalTotalSales.toNumber(),
                totalTaxable: decimalTotalTaxable.toNumber(),
                totalNonTaxable: decimalTotalNonTaxable.toNumber(),
                creditCardAuthCode: '',
                receiptItems: lodash.cloneDeep(posData.receipt),
                mealPlanActions: [],
                mealPlanIds: combineIds(payments.mealUnits),
                dcbMealPlanIds: combineIds(payments.dcbUnits),
                icbMealPlanIds: combineIds(payments.icbUnits),
                totalDiscount: decimalTotalDiscount.toNumber(),
                dollarDiscount: decimalDollarDiscount.toNumber(),
                tipAmount: 0.0
            };

            var subtotal = tenderAmounts.totalSales - tenderAmounts.totalTaxes;

            // To-Do: pass current loyalty level as parameter
            tenderAmounts.loyaltyEarned = LoyaltyCollection.calculatePointsToCollect(subtotal);

            processCashTenders(tenderAmounts, adjust);

            return [tenderAmounts, payments];
        }
        function calculateRequiredUnits (receiptList, discountPercentage = 0, discountBeforeTax = true, adjustments) {
            var requiredUnits = {
                'cashUnits': [],
                'mealUnits': [],
            };

            _.each(receiptList, function (listItem) {
                // we dont want to calculate required units on an already discounted item.
                // prevents coupon items from being used in points/ mealeq calculation
                if (listItem.isFullyDiscounted) {
                    return;
                }
                if (!listItem.included || listItem.included === false) {
                    if (adjustments.lockPrice) {
                        let itemOverallPrice = new Decimal(listItem.price);
                        let itemOverallTotal = new Decimal(listItem.total);

                        let newItem = {
                            'id': listItem.locationServicePeriodMenuId,
                            'remainingMealUnits': listItem.mealPlanAmount,
                            'remainingPrice': itemOverallPrice.toNearest(SharedDataService.baseDollar),
                            // Ensure item-level tax rounding
                            'remainingPriceTaxIn': itemOverallTotal.toNearest(SharedDataService.baseDollar),
                            'taxRate': listItem.taxRate,
                            'mealEquivalencyEnabled': listItem.mealEquivalencyEnabled
                        };

                        if (newItem.remainingMealUnits > 0) {
                            requiredUnits.mealUnits.push(newItem);
                        } else {
                            requiredUnits.cashUnits.push(newItem);
                        }
                    } else {
                        // Push units one by one to total cost
                        // To support fractional quantities (e.g. 2.5, 3.5)
                        // The last iteration of the loop takes care of the overflow
                        // i.e. for 2.5, it will push 1, 1, then 0.5 cost units
                        for (var i = listItem.quantity; i > 0; i--) {
                            let multiplicationFactor = Math.min(1, i);
                            let percent = 1 - discountPercentage;

                            let discountedItemPrice = (new Decimal(listItem.itemPrice)).times(percent).times(multiplicationFactor);
                            let discountedItemTotalPrice = (new Decimal(listItem.itemTotalPrice)).times(percent).times(multiplicationFactor);

                            let newItem = {
                                'id': listItem.locationServicePeriodMenuId,
                                'remainingMealUnits': listItem.mealPlanAmount,
                                'remainingPrice': discountedItemPrice.toNearest(SharedDataService.baseDollar),
                                // Ensure item-level tax rounding
                                'remainingPriceTaxIn': discountedItemTotalPrice.toNearest(SharedDataService.baseDollar),
                                'taxRate': listItem.taxRate,
                                'mealEquivalencyEnabled': listItem.mealEquivalencyEnabled
                            };

                            if (newItem.remainingMealUnits > 0) {
                                requiredUnits.mealUnits.push(newItem);
                            } else {
                                requiredUnits.cashUnits.push(newItem);
                            }
                        }
                    }
                }
            });
            return requiredUnits;
        }

        function processCashTenders (tenderAmounts, adjustment) {
            var toProcessCashTenders = tenderAmounts.cashAmount || adjustment.useCash;
            var defaultMinimumDenomination = SharedDataService.minimumDenomination || 1;
            var minimumDenomination = (toProcessCashTenders)? defaultMinimumDenomination : 1;

            var decimalRemainingBalancCents = new Decimal(tenderAmounts.remainingBalance).times(new Decimal(100));
            var decimalExistingCashAmountCents = new Decimal(tenderAmounts.cashAmount).times(new Decimal(100));

            var decimalAllPotentialCashAmountCents = decimalRemainingBalancCents.plus(decimalExistingCashAmountCents);
            var allPotentialCashAmountCents = decimalAllPotentialCashAmountCents.round().toNumber();

            var roundedAllPotentialCashAmountCents = Math.round(allPotentialCashAmountCents / minimumDenomination) * minimumDenomination;
            var cashRoundingCents = allPotentialCashAmountCents - roundedAllPotentialCashAmountCents;

            tenderAmounts.cashRounding = new Decimal(cashRoundingCents)
                .dividedBy(new Decimal(100))
                .toNearest(SharedDataService.baseDollar)
                .toNumber();
            tenderAmounts.remainingBalance = new Decimal(tenderAmounts.remainingBalance)
                .minus(new Decimal(tenderAmounts.cashRounding))
                .toNearest(SharedDataService.baseDollar)
                .toNumber();
        }
        // function areTendersCashOnly (tenderAmounts) {
        //     return tenderAmounts.cashAmount
        //             && !tenderAmounts.dcbAmount
        //             && !tenderAmounts.chargeAmount
        //             && !tenderAmounts.creditCardAmount
        //             && !tenderAmounts.debitCardAmount
        //             && !tenderAmounts.mealPlanCount; // TODO: might have to revisit this?
        // }

        SharedFunctionService.prototype.packageTransactionForLucova = function packageTransactionForLucova (fiitTransaction, appFid, $cookies) {
            var posObj = lodash.cloneDeep(fiitTransaction);
            posObj.sourceId = 1; // Set flag to indicate mobile transaction
            posObj.creditCardAmount = posObj.remainingBalance;
            posObj.remainingBalance = 0;

            if (posObj.creditCardAmount > 0) {
                posObj.creditCardAuthCode = 'LUCOVA';
            }

            var posHeaders = {'JSESSIONID': $cookies.get('JSESSIONID')};
            var obj = {
                'pin': '123',
                'pos_obj': posObj,
                'pos_obj_type': 'fiit',
                'pos_headers': posHeaders,
                'override_dc': 'true',
                'app_fid': appFid
            };
            obj.scv_verified = true;

            return obj;
        };

        /**
        *** Commented By Akash Mehta on 30th Mar 2020
        *** The assumption here is that every item has a single unit of quantity.
        ***/
        var populateTotalPriceOfItem;
        SharedFunctionService.prototype.populateTotalPriceOfItem = populateTotalPriceOfItem = function (receipt) {
            for (var receiptItem of receipt) {
                var taxAmount = new Decimal(0);
                var itemPrice = new Decimal(receiptItem.price).toNearest(SharedDataService.baseDollar);
                if (receiptItem.taxRate) {
                    var itemTaxRate = new Decimal(receiptItem.taxRate).toNearest(0.000001);
                    taxAmount = itemPrice.times(itemTaxRate).toNearest(SharedDataService.baseDollar);
                }

                receiptItem.total = itemPrice.plus(taxAmount).toNearest(SharedDataService.baseDollar).toNumber();
                receiptItem.taxAmount = taxAmount.toNumber();
                receiptItem.itemPrice = itemPrice.toNumber();
                receiptItem.itemTaxAmount = taxAmount.toNumber();
                receiptItem.itemTotalPrice = receiptItem.total;
                receiptItem.itemOriginalPrice = itemPrice.toNumber();
            }
        };

        // NOTE: very similar concept as `calculateAvailableBalances` in `external/pos.controller.js`
        // Should consider merging that into this to keep all transaction balance calculation into
        // the same class
        SharedFunctionService.prototype.calculateServicePeriodAvailableBalances = function calculateServicePeriodAvailableBalances (plans) {
            var availableUnits = {
                'mealUnits': 0,
                'mealEqUnits': new Decimal(0),
                'dcbUnits': new Decimal(0),
                'dcbTaxInUnits': new Decimal(0),
                'icbUnits': new Decimal(0),
                'icbTaxInUnits': new Decimal(0),
                'hasMealPlan': false,
            };
            _.each(plans, function (plan) {
                if (plan.mealPlanType === 'MEAL') {
                    availableUnits.mealUnits += plan.remainingServicePeriodMeals;
                    availableUnits.hasMealPlan = true;
                } else if (plan.mealPlanType === 'DCB') {
                    if (plan.taxFree) {
                        availableUnits.dcbUnits = availableUnits.dcbUnits.plus(plan.remainingServicePeriodDcb);
                    } else {
                        availableUnits.dcbTaxInUnits = availableUnits.dcbTaxInUnits.plus(plan.remainingServicePeriodDcb);
                    }
                } else if (plan.mealPlanType === 'ICB') {
                    if (plan.taxFree) {
                        availableUnits.icbUnits = availableUnits.icbUnits.plus(plan.remainingServicePeriodCharge);
                    } else {
                        availableUnits.icbTaxInUnits = availableUnits.icbTaxInUnits.plus(plan.remainingServicePeriodCharge);
                    }
                }
            });
            return availableUnits;
        };


        /**
        *** Commented By Akash Mehta on June 29th 2020
        *** The reason why delivery settings have been passed in and added to adjustments
        *** and not stored in SharedDataService, is because the calculation server does not
        *** support the SharedDateService. Every preorder on the calculation server passes in its
        *** own set of delivery Settings.
        ***
        *** NOTE : The default tax rate is not the actual percentage value (13%) but instead
        ***        the percentage value divided by 100 (0.13).
        ***
        ***/
        SharedFunctionService.prototype.addDeliveryFeeToTransaction = function (deliverySettings, defaultTaxRate = 0, adjustments) {
            adjustments.deliverySettings = deliverySettings || {};
            adjustments.deliverySettings.defaultTaxRate = defaultTaxRate;
            adjustments.isDeliveryOrder = true;
        };

        /**
        *** Commented By Akash Mehta on July 13th 2020
        *** This function is required to remove the delivery settings from the adjustments object
        *** and it will also set the delivery order to false.
        ***/
        SharedFunctionService.prototype.removeDeliveryFromTransaction = function (adjustments) {
            delete adjustments.deliverySettings;
            adjustments.isDeliveryOrder = false;
        };

        /**
        *** Commented By Akash Mehta on June 29th 2020
        *** The reason why we need a simple mapping function is because the settings object
        *** coming in from the Lucova Backend differs from the one coming in from the Nown Backend.
        *** The attributes are named differently and hence it is safer to have a mapping function
        *** like below which returns the object in the format desired by further calculations.
        ***/
        SharedFunctionService.prototype.generateDeliverySettingsObject = function (deliveryEnabled, deliveryRadiusInKms, deliveryFee,
                                                                                   smallOrderDeliveryFee, smallOrderThreshold,
                                                                                   largeOrderDeliveryFee, largeOrderThreshold,
                                                                                   preClockInChecklist, deliveryTaxable, deliveryFixedLocations) {
            return {
                deliveryEnabled: deliveryEnabled,
                deliveryRadiusInKms: deliveryRadiusInKms,
                deliveryFee: deliveryFee,
                smallOrderDeliveryFee: smallOrderDeliveryFee,
                smallOrderThreshold: smallOrderThreshold,
                largeOrderDeliveryFee: largeOrderDeliveryFee,
                largeOrderThreshold: largeOrderThreshold,
                preClockInChecklist: preClockInChecklist,
                deliveryTaxable: deliveryTaxable,
                deliveryFixedLocations: deliveryFixedLocations
            };
        };

        /**
        *** Commented By Akash Mehta on June 29th 2020
        *** This function decides the delivery fee to be applied based on the delivery settings
        *** i.e. deliveryFee, small Order Delivery Fee & threshold as well as large Order delivery
        *** fee & threshold.
        *** Based on the values passed in the settings we apply a delivery fee.
        *** 1.) If Subtotal < small order threshold - return small order delivery fee
        *** 2.) If Subtotal > large order threshold - return large order delivery fee
        *** 3.) Else return regular delivery fee
        ***
        *** If anything changes in the workflow above, please update the comments accordingly
        ***/
        var getDeliveryFee = function (deliverySettings, subtotal) {
            // Other non calculation values to be sent to backend
            deliverySettings = deliverySettings || {};
            var transactionSubtotal = new Decimal(subtotal || 0);
            var finalDeliveryFee = new Decimal(0);
            if (deliverySettings.deliveryEnabled) {
                var deliveryFee = new Decimal(deliverySettings.deliveryFee || 0);
                var smallOrderDeliveryFee = new Decimal(deliverySettings.smallOrderDeliveryFee || 0);
                var smallOrderThresholdSetting = new Decimal(deliverySettings.smallOrderThreshold || 0);
                var largeOrderDeliveryFee = new Decimal(deliverySettings.largeOrderDeliveryFee || 0);
                var largeOrderThresholdSetting = new Decimal(deliverySettings.largeOrderThreshold || 0);
                if (!smallOrderThresholdSetting.eq(0) && transactionSubtotal.lessThan(smallOrderThresholdSetting)) {
                    finalDeliveryFee = smallOrderDeliveryFee;
                } else if (!largeOrderThresholdSetting.eq(0) && transactionSubtotal.greaterThan(largeOrderThresholdSetting)) {
                    finalDeliveryFee = largeOrderDeliveryFee;
                } else {
                    finalDeliveryFee = deliveryFee;
                }
            }

            return finalDeliveryFee.toNearest(SharedDataService.baseDollar).toNumber();
        };


        /**
        *** Commented By Akash Mehta on June 29th 2020
        *** This function calculates the delivery fee and the respective taxes to be applied based on the
        *** delivery settings as well as the subtotal. Based on the delivery fee returned by the function
        *** `getDeliveryFee`, the default Tax rate being passed and the tax configurations, we calculate
        *** the delivery taxes to be applied and return the calculated values
        ***/
        var calculateDelivery = function (adjustments, transactionSubtotal, overridenDeliveryFee = null) {

            var deliveryFee = new Decimal(0);
            var deliveryFeeTaxInclusive = new Decimal(0);
            var deliveryTax = new Decimal(0);

            if (adjustments.isDeliveryOrder) {
                var deliverySettings = adjustments.deliverySettings || {};
                var deliveryItemPrice = new Decimal(0);
                if (overridenDeliveryFee != null) {
                    deliveryItemPrice = new Decimal(overridenDeliveryFee).toNearest(SharedDataService.baseDollar);
                } else {
                    deliveryItemPrice = new Decimal(getDeliveryFee(deliverySettings, transactionSubtotal));
                }

                var defaultTaxRate = 0;
                if (deliverySettings.deliveryTaxable) {
                    defaultTaxRate = deliverySettings.defaultTaxRate || 0;
                }

                var deliveryItem = {
                    taxRate: defaultTaxRate,
                };

                // Commented By Akash Mehta on June 18 2020
                // This needs to be verified for preorders.
                // Currently on the calcultions server, there
                // is no way to set the  data in SharedDataService,
                // based on a company. So we need to find a better way for the calculation
                // server atleast.
                if (SharedDataService.taxIncludedInPrice) {
                    var decimalPreTaxPrice = deliveryItemPrice
                        .dividedBy(1 + defaultTaxRate)
                        .toNearest(SharedDataService.baseDollar);

                    deliveryItem.price = decimalPreTaxPrice.toNumber();
                } else {
                    deliveryItem.price = deliveryItemPrice.toNumber();
                }

                var deliveryItemArr = [deliveryItem];
                populateTotalPriceOfItem(deliveryItemArr);
                deliveryItem = deliveryItemArr[0];


                deliveryFee = new Decimal(deliveryItem.price).toNearest(SharedDataService.baseDollar);
                deliveryFeeTaxInclusive = new Decimal(deliveryItem.total).toNearest(SharedDataService.baseDollar);
                deliveryTax = deliveryFeeTaxInclusive.minus(deliveryFee).toNearest(SharedDataService.baseDollar);
            }

            return {
                deliveryFee: deliveryFee,
                deliveryFeeTaxInclusive: deliveryFeeTaxInclusive,
                deliveryTax: deliveryTax
            };
        };

        SharedFunctionService.prototype.getTransactionAmount = function getTransactionAmount (mu, posData) {
            if (typeof mu === 'undefined') {
                mu = 0;
            }
            return getTransactionSubTotal(mu, posData) + getTransactionTax(mu, posData);
        };

        SharedFunctionService.prototype.calculateTotalPlanUnits = function calculateTotalPlanUnits (plans) {
            var availableUnits = {
                'mealUnits': 0,
                'dcbUnits': new Decimal(0),
                'icbUnits': new Decimal(0),
                'servicePeriodMealUnits': 0,
                'servicePeriodDcbUnits': new Decimal(0),
                'servicePeriodIcbUnits': new Decimal(0),
                'hasMealPlan': false,
            };

            _.each(plans, function (plan) {
                if (plan.mealPlanType === 'MEAL') {
                    availableUnits.mealUnits += plan.currentMealPlanBalance;
                    availableUnits.servicePeriodMealUnits += plan.remainingServicePeriodMeals;
                } else if (plan.mealPlanType === 'DCB') {
                    availableUnits.dcbUnits = availableUnits.dcbUnits.plus(plan.currentDcbBalance);
                    availableUnits.servicePeriodDcbUnits = availableUnits.servicePeriodDcbUnits.plus(plan.remainingServicePeriodDcb);
                } else if (plan.mealPlanType === 'ICB') {
                    availableUnits.icbUnits = availableUnits.icbUnits.plus(plan.currentChargeBalance);
                    availableUnits.servicePeriodIcbUnits = availableUnits.servicePeriodIcbUnits.plus(plan.remainingServicePeriodCharge);
                }
            });
            return availableUnits;
        };

        SharedFunctionService.prototype.calculateBalances = function calculateBalances (receiptList, availablePlanList, adjustments, posData, patron = null) {
            return this.calculateBalancesv4(receiptList, availablePlanList, adjustments, posData, patron);
        };

        SharedFunctionService.prototype.calculateBalancesv3 = function calculateBalancesv3 (receiptList, availablePlanList, adjustments, posData) {
            TaxRuleService.runAll(
                posData.company,
                posData.location,
                receiptList
            );

            var availablePlans = lodash.cloneDeep(availablePlanList).sort(sortPlans);
            var availableBalances = this.calculateServicePeriodAvailableBalances(availablePlans);
            var percentDiscount = adjustments.percentDiscount || 0;
            var discountBeforeTax = true;
            var requiredUnits = calculateRequiredUnits(receiptList, percentDiscount, discountBeforeTax);
            // var errorCode = 0;
            var discountDollar = adjustments.dollarDiscount || 0;

            var payments = initPaymentsObject();

            posData.currentOrderBalance.mealError = (requiredUnits.mealUnits.length > availableBalances.mealUnits && availableBalances.hasMealPlan && !posData.guestTransaction);
            posData.deferred = 0;

            if (discountDollar > 0) {
                availableBalances['discountUnits'] = new Decimal(discountDollar);
                deductUnit('discount', discountBeforeTax, discountDollar, 'discountUnits', 'discountUnits', requiredUnits, availableBalances, adjustments, payments, posData);
            }

            // here we sum the costs of all current items (subtotal, tax etc.).
            // This is not necessarily the price the user will pay, since we
            // have not considered to use of points yet! Regardless this
            // calculation is necessary because we need subtotal to be
            // calculated before points are applied
            var preTotals = sumTotals(requiredUnits);

            // This loop is necessary for plan priority, availablePlans are sorted by priority
            _.each(availablePlans, function (plan) {
                if (plan.mealPlanType === 'MEAL') {
                    // line bellow is specific to nown pos
                    posData.allowedMealEquivalencyPerCycle = LoyaltyRedemption.getPointsRedeemable(preTotals.totalCost, availableBalances['mealUnits']);
                    deductMealUnit(plan.mealPlanId, plan.mealEquivalencyEnabled, plan.remainingServicePeriodMeals, requiredUnits, availableBalances, adjustments, payments, posData);
                } else if (plan.mealPlanType === 'DCB') {
                    deductUnit(plan.mealPlanId, plan.taxFree, plan.remainingServicePeriodDcb, 'dcbUnits', 'dcbTaxInUnits', requiredUnits, availableBalances, adjustments, payments, posData);
                } else if (plan.mealPlanType === 'ICB') {
                    deductUnit(plan.mealPlanId, plan.taxFree, plan.remainingServicePeriodCharge, 'icbUnits', 'icbTaxInUnits', requiredUnits, availableBalances, adjustments, payments, posData);
                }
            });

            var adjust = ensureAdjustmentInitialized(adjustments);
            // adjustDCBOffset(requiredUnits, payments, adjust);


            // Any items that weren't accounted for with DCB/ICB/Meal,
            // calculate cash or credit owed.
            var unaccounted = sumTotals(requiredUnits);

            // summarize all calculations across all tenders. For example sum
            // up all taxes, totalCost etc across cash, credit, meals.
            // "pre" refers to before any meals/points were applied
            // "post" refers to after any meals/points were applied
            let [preTenderAmounts, prePayments] = processTendered(posData, adjust, preTotals, initPaymentsObject());
            let [postTenderAmounts, postPayments] = processTendered(posData, adjust, unaccounted, payments);

            postPayments.subTotalAmount = prePayments.subTotalAmount;
            postPayments.taxAmount = prePayments.taxAmount;

            postTenderAmounts.totalSales = preTenderAmounts.totalSales;
            postTenderAmounts.totalTaxes = preTenderAmounts.totalTaxes;

            return {
                payments: postPayments,
                tenderAmounts: postTenderAmounts,
                error_code: 0
            };
        };

        SharedFunctionService.prototype.calculateBalancesv4 = function calculateBalancesv4 (receiptList, availablePlanList, adjustments, posData, patron) {
            // Special tax rules should be performed first so that the balance
            // calculation can be calculated on the correct tax rates
            adjustments = ensureAdjustmentInitialized(adjustments);

            if (!adjustments.lockPrice) {
                applyTaxRulesOnReceipt(receiptList, posData);
            }

            // var percentDiscount = adjustments.percentDiscount || 0;
            var discountBeforeTax = true;
            var requiredUnits = calculateRequiredUnits(receiptList, 0, discountBeforeTax, adjustments);
            var requiredTotals = sumTotals(requiredUnits, adjustments);

            var loyaltyRedeemed = calculateLoyalty(adjustments.mealEquivalencyAdjustment, posData, availablePlanList, receiptList, requiredTotals, adjustments);
            var payments = calculatePayments(requiredTotals, loyaltyRedeemed, adjustments);
            var tenderAmounts = calculateTenderAmounts(posData, payments, requiredTotals, adjustments, loyaltyRedeemed, receiptList);

            const availableOffers = patron && patron.offers ? patron.offers : [];

            const pointsEarnedBeforeOffer = tenderAmounts.loyaltyEarned || 0;
            const [newTotalPoints, pointOffersUsedLite] = calculatePointsEarnedAfterOffer(pointsEarnedBeforeOffer, availableOffers, tenderAmounts, receiptList);
            const discountOffersUsedLite = getDiscountOffersUsed(availableOffers);

            tenderAmounts.loyaltyEarned = newTotalPoints;
            tenderAmounts.offersUsed.push(...pointOffersUsedLite, ...discountOffersUsedLite);

            return {
                payments: payments,
                tenderAmounts: tenderAmounts,
                error_code: 0
            };
        };

        var initializeTenderAmounts = function (posData) {
            return {
                guestTransaction: posData.guestTransaction,
                locationId: posData.shiftLocationId,
                mealPlanCount: 0,
                dcbAmount: 0.0,
                cashAmount: 0.0,
                creditCardAmount: 0.0,
                debitCardAmount: 0.0,
                remainingBalance: 0.0,
                subtotal: 0.0,
                totalTaxes: 0.0,
                totalSales: 0.0,
                totalTaxable: 0.0,
                totalNonTaxable: 0.0,
                creditCardAuthCode: '',
                receiptItems: lodash.cloneDeep(posData.receipt),
                mealPlanActions: [],
                mealPlanIds: '',
                dcbMealPlanIds: '',
                icbMealPlanIds: '',
                totalDiscount: 0.0, // deprecated
                dollarDiscountAmount: 0.0, // in dollar
                percentDiscountAmount: 0.0, // in dollar
                percentDiscount: 0, // in percentage (0 - 1)
                tipAmount: 0.0,
                loyaltyEarned: 0,
                deliveryFee: 0,
                deliveryTax: 0,
                offersUsed: []
            };
        };

        var applyTaxRulesOnReceipt = function (receiptList, posData) {
            _.each(receiptList, function (receiptItem) {
                receiptItem.taxRulesApplied = '';
            });

            TaxRuleService.runAll(
                posData.company,
                posData.location,
                receiptList
            );
        };

        var calculateLoyalty = function (isLoyaltyApplied, posData, availablePlanList, receiptList, requiredTotals, adjustments) {
            var loyaltyRedeemed = {
                points: 0,
                value: 0
            };
            return loyaltyRedeemed;
        };

        var calculatePayments = function (requiredTotals, loyaltyRedeemed, adjustments) {
            var payments = initPaymentsObject();

            payments.unaccounted = 0; // TODO: not sure why this is needed when there is tenderAmounts.remainingBalance

            payments.subTotalAmount = requiredTotals.subTotalCost;
            payments.taxAmount = requiredTotals.tax;
            payments.deliveryAmount = requiredTotals.deliveryCost;
            payments.deliveryTaxAmount = requiredTotals.deliveryTax;

            // recalculate using subtotal and tax so that dollar discount does
            // not discount the tax portion of the total
            var decimalTotalAmount = new Decimal(payments.subTotalAmount)
                .plus(new Decimal(payments.deliveryAmount))
                .plus(new Decimal(payments.taxAmount)) // tax amount is inclusive of the delivery Tax amount
                .minus(new Decimal(loyaltyRedeemed.value));

            var totalAmount = decimalTotalAmount.toNearest(SharedDataService.baseDollar).toNumber();

            payments.totalAmount = totalAmount;
            payments.totalDiscount = adjustments.dollarDiscount;

            return payments;
        };

        var calculateTenderAmounts = function (posData, payments, requiredTotals, adjustments, loyaltyRedeemed, receiptList = []) {
            var tenderAmounts = initializeTenderAmounts(posData);

            var decimalTotalSales = new Decimal(payments.totalAmount).toNearest(SharedDataService.baseDollar);
            var decimalCashAdjustment = new Decimal(adjustments.cashAdjustment).toNearest(SharedDataService.baseDollar);
            var decimalCreditCardAdjustment = new Decimal(adjustments.creditCardAdjustment).toNearest(SharedDataService.baseDollar);
            var decimalDebitCardAdjustment = new Decimal(adjustments.debitCardAdjustment).toNearest(SharedDataService.baseDollar);
            var decimalOtherAdjustment = new Decimal(adjustments.otherAdjustment).toNearest(SharedDataService.baseDollar);
            var decimalGiftCardAdjustment = new Decimal(adjustments.giftCardAdjustment).toNearest(SharedDataService.baseDollar);
            var decimalCashCardAdjustment = new Decimal(adjustments.cashCardAdjustment).toNearest(SharedDataService.baseDollar);

            var decimalRemainingBalance = decimalTotalSales
                .minus(decimalCashAdjustment)
                .minus(decimalCreditCardAdjustment)
                .minus(decimalDebitCardAdjustment)
                .minus(decimalOtherAdjustment)
                .minus(decimalGiftCardAdjustment)
                .minus(decimalCashCardAdjustment);

            payments.cashCard = decimalCashCardAdjustment.toNumber();
            tenderAmounts.cashAmount = decimalCashAdjustment.toNumber();
            tenderAmounts.creditCardAmount = decimalCreditCardAdjustment.toNumber();
            tenderAmounts.debitCardAmount = decimalDebitCardAdjustment.toNumber();
            tenderAmounts.otherAmount = decimalOtherAdjustment.plus(decimalCashCardAdjustment).toNumber();
            tenderAmounts.giftCardAmount = decimalGiftCardAdjustment.toNumber();
            tenderAmounts.remainingBalance = decimalRemainingBalance.toNumber();

            tenderAmounts.totalTaxes = payments.taxAmount;
            tenderAmounts.totalSales = payments.totalAmount;
            tenderAmounts.totalTaxable = requiredTotals.taxable;
            tenderAmounts.totalNonTaxable = requiredTotals.untaxable;
            tenderAmounts.subtotal = payments.subTotalAmount;
            tenderAmounts.deliveryFee = payments.deliveryAmount;
            tenderAmounts.deliveryTax = payments.deliveryTaxAmount;

            tenderAmounts.loyaltyEarned = calculateLoyaltyEarned(tenderAmounts, posData, receiptList);

            tenderAmounts.dollarDiscountAmount = adjustments.dollarDiscount;
            tenderAmounts.percentDiscount = adjustments.percentDiscount;

            // var percentDiscountAmount = new Decimal(requiredTotals.totalCost).minus(new Decimal(requiredTotals.nonDiscountTotalCost)).toNumber() * 100;
            if (tenderAmounts.percentDiscount) {
                tenderAmounts.percentDiscountAmount = -new Decimal(requiredTotals.subTotalCost)
                    .minus(new Decimal(requiredTotals.nonDiscountSubtotalCost))
                    .toNearest(SharedDataService.baseDollar)
                    .toNumber();
            } else {
                tenderAmounts.percentDiscountAmount = 0;
            }

            const loyaltyStepItem = receiptList.find((item) => item.loyaltyStepCost === adjustments.loyaltyStepAdjustment);

            if (adjustments.loyaltyStepAdjustment && loyaltyStepItem) {
                tenderAmounts.mealEqAmount = new Decimal(loyaltyStepItem.price).toNearest(SharedDataService.baseDollar).toNumber();
                tenderAmounts.mealPlanCount = adjustments.loyaltyStepAdjustment;
            } else if (adjustments.mealEquivalencyAdjustment) {
                tenderAmounts.mealEqAmount = tenderAmounts.percentDiscountAmount || 0;
                tenderAmounts.mealPlanCount = new Decimal(tenderAmounts.mealEqAmount).times(SharedDataService.loyaltyRedemptionPerDollar).round().toNumber();
            } else {
                tenderAmounts.mealEqAmount = tenderAmounts.mealEqAmount || 0;
                tenderAmounts.mealPlanCount = tenderAmounts.mealPlanCount || 0;
            }

            var totalTipAmountInCents = Object.keys(adjustments.tipsInCents).reduce(function (updatedValue, arrElement) {
                return updatedValue + adjustments.tipsInCents[arrElement];
            }, 0);

            tenderAmounts.tipAmount = new Decimal(totalTipAmountInCents).div(100).toNearest(SharedDataService.baseDollar).toNumber();

            if (adjustments.fiit) {
                tenderAmounts.fiitMealEqAmount = adjustments.fiit.mealEqAmount || 0;
                tenderAmounts.fiitMealPlanCount = adjustments.fiit.mealPlanCount || 0;
                tenderAmounts.fiitDcbAmount = adjustments.fiit.dcbAmount || 0;
                tenderAmounts.fiitMealDollarAmount = adjustments.fiit.mealUnitDollarValue || 0;
                tenderAmounts.totalTaxable = adjustments.fiit.subtotalTaxable || 0;
                tenderAmounts.totalNonTaxable = adjustments.fiit.subtotalNonTaxable || 0;
            }

            tenderAmounts.isDelivery = !!(adjustments.isDeliveryOrder);

            processCashTenders(tenderAmounts, adjustments);

            return tenderAmounts;
        };

        /**
         * Applies offers which have loyalty points value
         * offer type: EXTRA_POINTS, POINTS_MULTIPLIER
         * returns [pointsEarned, offersUsed]
         * @param {*} pointsEarnedBeforeOffer
         * @param {*} availableOffers
         * @param {*} tenderAmounts
         * @param {*} receiptList
         */
        const calculatePointsEarnedAfterOffer = (pointsEarnedBeforeOffer, _availableOffers, tenderAmounts, receiptList) => {
            if (!_availableOffers || _availableOffers.length < 1 || !pointsEarnedBeforeOffer) {
                return [pointsEarnedBeforeOffer, []];
            }
            // create copy of the offers object
            const availableOffers = JSON.parse(JSON.stringify(_availableOffers));
            const types = ['EXTRA_POINTS', 'POINTS_MULTIPLIER'];
            const invalidOffer = (offer) => {
                let enabled = offer.autoApply || offer.selected;
                let validType = types.indexOf(offer.offerType) > -1;
                let validValue = parseInt(offer.offerValue) >= 0;
                let hasValidBasketSize = tenderAmounts.subtotal > offer.minBasketAmountCents;
                let hasValidItemId = true;
                if (offer.itemId) {
                    let item = receiptList.find((item) => item.locationServicePeriodMenuId === offer.itemId);
                    hasValidItemId = !!item;
                }

                return enabled && validValue && validType && hasValidItemId && hasValidBasketSize;
            };
            const validOffers = availableOffers.filter(invalidOffer);
            let extraPoints = 0;
            let pointsMultiplier = 0;
            let offersUsed = [];

            for (let o of validOffers) {
                let value = parseInt(o.offerValue);
                if (!value || isNaN(value)) {
                    continue;
                }
                if (o.offerType === 'EXTRA_POINTS') {
                    extraPoints += value;
                    o.pointsIssued = value;
                    offersUsed.push(o);
                } else if (o.offerType === 'POINTS_MULTIPLIER') {
                    pointsMultiplier += value;
                    o.pointsIssued = (pointsEarnedBeforeOffer * (value || 1)) - pointsEarnedBeforeOffer;
                    offersUsed.push(o);
                }
            }
            const offersUsedLite = offersUsed.map((o) => {
                return {
                    id: o.id,
                    name: o.name,
                    type: o.offerType,
                    pointsIssued: o.pointsIssued,
                };
            });

            const newTotalPoints = (pointsEarnedBeforeOffer * (pointsMultiplier || 1)) + extraPoints;
            return [newTotalPoints, offersUsedLite];
        };

        const getDiscountOffersUsed = (offers) => {
            const OFFER_TYPES = ['PERCENT_DISCOUNT', 'AMOUNT_DISCOUNT_CENTS'];
            const availableOffers = [...offers];

            const invalidOffer = (offer) => {
                let enabled = offer.selected;
                let validType = OFFER_TYPES.indexOf(offer.offerType) > -1;
                let validValue = parseInt(offer.offerValue) >= 0;
                return enabled && validValue && validType;
            };

            const offersUsed = availableOffers.filter(invalidOffer);
            const offersUsedLite = offersUsed.map((o) => {
                return {
                    id: o.id,
                    name: o.name,
                    type: o.offerType
                };
            });

            return offersUsedLite;
        };

        var calculateLoyaltyEarned = function (tenderAmounts, posData, receiptList) {
            // NOTE: this method calculates loyaltyEarned that can be "potentially" earned with each transaciton. Whether
            // this amount is actually earned is determined by the backend whether there is any patron associated the
            // transaction. This is because it is possible for a guest (who cannot earn loyalty points) to be converted to
            // an actual customer at the end of a transaction and earn the points they should've earned.

            var subtotal = new Decimal(tenderAmounts.subtotal);
            var giftCardPurchaseAmount = new Decimal(0);
            _.each(receiptList, function (item) {
                var ignore = ['giftcard.create', 'giftcard.reload', 'loyalty.reload', 'loyalty.create'];
                if (ignore.indexOf(item.subtype) >= 0) {
                    var amount = new Decimal(item.price);
                    giftCardPurchaseAmount = giftCardPurchaseAmount.plus(amount);
                }
            });

            var rewardableAmount = subtotal.minus(giftCardPurchaseAmount);
            var pointsDollarEquivalent = undefined;
            if (posData.organizationSettings && posData.organizationSettings.loyaltyPointsPerDollar >= 0) {
                pointsDollarEquivalent = posData.organizationSettings.loyaltyPointsPerDollar;
            }
            var loyaltyEarned = LoyaltyCollection.calculatePointsToCollect(
                rewardableAmount.toNearest(SharedDataService.baseDollar).toNumber(),
                pointsDollarEquivalent);

            return Math.max(loyaltyEarned, 0);
        };

        SharedFunctionService.prototype.populateTenderAmounts = function (tenderAmounts, manualCoupon, patron) {
            // Other non calculation values to be sent to backend
            if (manualCoupon) {
                tenderAmounts.couponCode = manualCoupon.manualCouponCode;
            }
            tenderAmounts.offline = false;
            tenderAmounts.posStationId = SharedDataService.posStationId;

            if (typeof patron !== 'undefined' && typeof patron.patronId !== 'undefined') {
                tenderAmounts.patronId = patron.patronId;
                tenderAmounts.patronKey = patron.patronKey;
            }

            // Generated values that aren't being used yet
            delete tenderAmounts.icbMealPlanIds;

            return tenderAmounts;
        };

        // Index must be consistent with JS ie. sunday => 0, monday => 1
        var daysOfTheWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

        SharedFunctionService.prototype.daysOfTheWeek = daysOfTheWeek;

        SharedFunctionService.prototype.currentDay = function () {
            var day = new Date().getDay();

            return daysOfTheWeek[day];
        };

        return SharedFunctionService;
    })();

    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = new SharedFunctionService();
    } else {
        window.SharedFunctionService = new SharedFunctionService();
    }
})();
