'use strict';

const Decimal = require('decimal.js').default;

module.exports = function (posModule) {

    posModule.controller('PosModifyPrice', [
        '$scope',
        '$modal',
        '$modalInstance',
        'item',
        'SharedDataService',
        function (
            $scope,
            $modal,
            $modalInstance,
            item,
            SharedDataService) {

            $scope.itemCopy = Object.assign({}, item);
            $scope.categories = $scope.itemCopy.children;

            var mainMenuItemId = $scope.itemCopy.locationServicePeriodMenuId;

            /** Function to save the changes of the modal
            **  Calls the function to check if the price is changed or not
            **  and closes the modal
            **/
            $scope.save = function () {
                checkIfPriceChanged();
                $modalInstance.close($scope.itemCopy);
            };

            /**
            **  Function to check  if the price of the main Item or any
            **  of the modifiers has been changed or not
            **/
            var checkIfPriceChanged = function () {
                if (!$scope.itemCopy.originalPrice.equals($scope.itemCopy.price)) {
                    $scope.itemCopy.isPriceChanged = true;
                    return;
                }

                if ($scope.categories && $scope.categories.length > 0) {
                    for (var category of $scope.categories) {
                        for (var modifier of category.children) {
                            if (!modifier.originalPrice.equals(modifier.price)) {
                                $scope.itemCopy.isPriceChanged = true;
                                return;
                            }
                        }
                    }
                }

                $scope.itemCopy.isPriceChanged = false;
            };

            /**
            **  Function to edit the price of the item clicked
            **  Called when the person clicks on the price box next
            **  to the item
            **/
            $scope.editItemPrice = function (itemToEdit) {
                var tempAmount = (SharedDataService.taxIncludedInPrice)
                    ? itemToEdit.total
                    : itemToEdit.price;

                var negPinPad = true;
                if (itemToEdit.locationServicePeriodMenuId == mainMenuItemId) {
                    negPinPad = false;
                }
                return showPinPad('currency', tempAmount, onEditPrice, undefined, itemToEdit, negPinPad);
            };

            /**
            **  Function to edit the price of the item clicked
            **  Called when the person clicks on the price box next
            **  to the item
            **/
            var onEditPrice = function (editedValue, itemToEdit) {
                var amount = parseFloat(editedValue);
                if (SharedDataService.taxIncludedInPrice) {
                    var decimalPreTaxPrice = new Decimal(amount)
                        .dividedBy(1 + itemToEdit.taxRate)
                        .toNearest(SharedDataService.baseDollar);

                    itemToEdit.price = decimalPreTaxPrice.toNumber();
                } else {
                    itemToEdit.price = amount;
                }

                calculateItemTotalFromPrice(itemToEdit);

                if (new Decimal(itemToEdit.originalPrice).equals(itemToEdit.price)) {
                    $scope.itemCopy.isPriceChanged = false;
                } else {
                    $scope.itemCopy.isPriceChanged = true;
                }
            };

            var calculateItemTotalFromPrice = function (itemToEdit) {
                var decimalTax = new Decimal(itemToEdit.price).times(itemToEdit.taxRate).toNearest(SharedDataService.baseDollar);
                itemToEdit.total = new Decimal(itemToEdit.price).plus(decimalTax).toNumber();
            };

            /**
            ** Init function for the controller
            **/
            $scope.init = function () {
                calculateItemTotalFromPrice($scope.itemCopy);
            };

            /**
            ** Function to open pinpad
            **/
            var showPinPad = function (type, model, callback, validator, modelObject, negPinPad) {
                var modelWatch = {on: undefined};
                if (negPinPad) {
                    var negPinPadInstance = $modal.open({
                        templateUrl: 'common/freshideas/numpad.tpl.html',
                        controller: 'NumpadCtrl',
                        animation: false,
                        windowClass: 'modal-numpad modal-fullscreen-transparent modal-right',
                        resolve: {
                            initialValue: function () {
                                return model;
                            },
                            type: function () {
                                return type;
                            },
                            modelWatch: function () {
                                return modelWatch;
                            }
                        },
                        backdrop: true
                    });
                    return negPinPadInstance.result.then(function (value) {
                        if (callback) {
                            callback(value.modelValue, modelObject);
                        }
                    });
                } else {
                    var pinPadInstance = $modal.open({
                        templateUrl: 'pos/pos.numpad.tpl.html',
                        controller: 'PosNumpadCtrl',
                        animation: false,
                        windowClass: 'modal-numpad modal-fullscreen-transparent modal-right',
                        resolve: {
                            initial: function () {
                                return model;
                            },
                            type: function () {
                                return type;
                            }
                        },
                        backdrop: true
                    });
                    return pinPadInstance.result.then(function (value) {
                        if (callback) {
                            callback(value, modelObject);
                        }
                    });
                }

            };

            $scope.init();

        }
    ]);
};
