'use strict';


module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosStartMessagingCtrl', [
        '$scope',
        'LucovaWebSocket',
        'SmbPosService',
        function ($scope, LucovaWebSocket, SmbPosService) {
            $scope.smbPos = SmbPosService;
            $scope.LucovaWebSocket = LucovaWebSocket;

            $scope.options = {
                carousel: {
                    interval: 15000,
                    noWrap: false,
                }
            };

            $scope.init = function () {
                $scope.messaging = SmbPosService.getMessaging();
            };

            $scope.init();
        }
    ]);
};
