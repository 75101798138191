'use strict';

module.exports = function (freshideasSetup) {

    freshideasSetup.controller('EmployeeModifyCtrl', [
    '$scope',
    '$modal',
    '$translate',
    '$filter',
    '$timeout',
    'Users',
    'Security',
    'Settings',
    'PosAlertService',
    'selectedUser',
    'currentPage',
    'showEditor',
    function ($scope, $modal, $translate, $filter, $timeout, Users, Security, Settings, PosAlertService, selectedUser, currentPage, showEditor) {
        $scope.isFullAdmin = (Security.getUser().permission === 'FULLADMIN');
        $scope.permissionTypeOptions = [];
        $scope.selectRole = {chosen: undefined};
        $scope.statusToggle={};
        $scope.originalUser = {};
        $scope.isSaveValid = true; // Change to false when restarting the validator
        $scope.pinInvalid = false;
        $scope.roleInvalid = false;
        $scope.currentPage = '';
        $scope.allLocationsByCompanyCopy = angular.copy($scope.allLocationsByCompany);
        $scope.isLocationChanged = false;

        // only fulladmin can create a siteadmin
        if (Security.getUser().permission === 'FULLADMIN') {
            $scope.permissionTypeOptions.push({
                id: 'SITEADMIN',
                name: 'user.siteadmin.option'
            });
        }
        $scope.permissionTypeOptions.push({
            id: 'NOBODY',
            name: 'user.role.option'
        });

        $scope.loadRolesForCompany = function (companyId, selectPermissionsAsOldCompany = false) {
            var loadingTimer = $timeout(function () {
                $scope.isLoadingRoles = false;
                if (selectPermissionsAsOldCompany) {
                    $scope.selectRole.chosen = undefined;
                }
            }, 5000);
            $scope.isLoadingRoles = true;
            Settings.getRoles({companyId: companyId}, function (response) {
                $timeout.cancel(loadingTimer);
                $scope.roleTypes = _.map(response, function (e) {
                    return {
                        id: e.roleId,
                        name: e.roleName
                    };
                });
                $scope.isLoadingRoles = false;
                if (selectPermissionsAsOldCompany) {
                    if ($scope.toEdit.roleId != null) {
                        var respectivePermission = _.find($scope.roleTypes, function (role) {
                            return role.name == $scope.toEdit.roleName;
                        });
                        if (respectivePermission) {
                            $scope.selectRole.chosen.id = respectivePermission.id;
                        } else {
                            $scope.selectRole.chosen = undefined;
                        }
                    }
                    $scope.checkIfUserEdited();
                }
            }, function () {
                if (selectPermissionsAsOldCompany) {
                    $scope.selectRole.chosen = undefined;
                }
            });
        };

        $scope.init = function (user, page) {
            $scope.hourlyWageString;
            $scope.currentPage = page;
            $scope.isEdit = !(user.isNew);
            $scope.selectedCompanyId = user.companyId;
            $scope.loadRolesForCompany($scope.selectedCompanyId);
            if (!$scope.isEdit) {
                Users.getDefaultUser(function (response) {
                    $scope.toEdit = response;
                    $scope.toEdit.companyId = $scope.selectedCompanyId;

                    if ($scope.toEdit.roleId != null) {
                        $scope.selectRole.chosen = {};
                        $scope.selectRole.chosen.id = $scope.toEdit.roleId;
                        $scope.selectRole.chosen.name = $scope.toEdit.roleName;
                    }
                });
            } else {
                $scope.toEdit = angular.copy(user);
                $scope.hourlyWageString = $scope.toEdit.hourlyWage / 100;
                $scope.selectRole.chosen = {};
                $scope.selectRole.chosen.id = $scope.toEdit.roleId;
                $scope.selectRole.chosen.name = $scope.toEdit.roleName;
                var currLocation = _.filter($scope.allLocationsByCompanyCopy, function (location) {
                    return location.companyId == $scope.toEdit.companyId;
                });
                currLocation[0].isSelected = true;
                $scope.originalUser = user;
                $scope.toEdit.oldStatus = $scope.toEdit.status;
                if ($scope.toEdit.status == 'ACTIVE') {
                    $scope.statusToggle['value'] = true;
                } else {
                    $scope.statusToggle['value'] = false;
                }
            }
        };

        $scope.getAccessDetails = function (type) {
            if (!$scope.toEdit) {
                return '';
            }
            if (type === 'login') {
                var hasPermission = Security.hasPermissionForSpecificUser('login', $scope.toEdit);
                // if ($scope.selectRole.chosen && $scope.toEdit.roleName.toLowerCase() != 'cashier') {
                if (hasPermission) {
                    return 'Chosen Location';
                } else {
                    return 'No Login Access';
                }
            } else if (type === 'clockin') {
                if ($scope.companyHierarchy.id === $scope.toEdit.companyId) {
                        return 'All Locations';
                } else {
                    return 'Chosen Location';
                }
            }
        };

        $scope.deleteUser = function () {
            var updatedUser = angular.copy($scope.toEdit);

            Users.deleteUser({}, updatedUser, function (success) {
                $scope.$parent.returnToUsersPage('employee-setup', true);
            }, function (error) {
                var data = error.data || {};
                var exception = data.exception || {};

                PosAlertService.showAlertByName('delete-user-fail', {
                    message: exception.message || ''
                });
            });
        };

        $scope.toggleUserStatus = function () {
            if ($scope.toEdit.status == 'ACTIVE') {
                var modalInstance = $modal.open({
                    templateUrl: 'setup/modals/wizardEdit.confirmation.html',
                    animation: false,
                    backdrop: 'static',
                    controller: 'SetupConfirmationModalCtrl',
                    windowClass: 'setup_confirm_modal',
                    keyboard: false,
                    resolve: {
                        modalType: function () {
                            return 'CONFIRM_EMPLOYEE_DEACTIVATE';
                        }
                    }
                    });
                    modalInstance.result.then(function (response) {
                        $scope.toEdit.status = 'INACTIVE';
                        $scope.statusToggle['value'] = false;
                    }, function () {
                        $scope.statusToggle['value'] = true;
                    });

            } else {
                $scope.toEdit.status = 'ACTIVE';
                $scope.statusToggle['value'] = true;
            }

            $scope.checkIfUserEdited();
        };

        $scope.selectLocationsForUser = function () {
            var modalInstance = $modal.open({
                    templateUrl: 'setup/modals/setup.select.location.tpl.html',
                    controller: 'SetupSelectLocationCtrl',
                    windowClass: 'mods-price-modal',
                    resolve: {
                        locations: function () {
                            return $scope.allLocationsByCompanyCopy;
                        }
                    },
                    backdrop: 'static'
                });
                modalInstance.result.then(function (responseLocation) {
                    _.each(responseLocation, function (location) {
                        if (location.isSelected) {
                            var companyId = location.companyId || $scope.companyHierarchy.id;
                            if ($scope.toEdit.companyId != companyId) {
                                $scope.loadRolesForCompany(companyId, true);
                            }
                            $scope.toEdit.companyId = companyId;
                        }
                    });
                    $scope.allLocationsByCompanyCopy = responseLocation;
                });
        };

        $scope.filterRoleId = function (role) {
            return role.id == $scope.toEdit.roleId;
        };

        $scope.invalid = function () {
            /* return true;
             if ((!$scope.toEdit.roleId && $scope.toEdit.roleId != 0)|| !$scope.toEdit.firstname || !$scope.toEdit.lastname) {
                return false;
            }

            if ($scope.selectRole.chosen) {
                if ($scope.toEdit.roleName.toLowerCase() != 'cashier' && (!$scope.toEdit.email || !$scope.toEdit.username)) {
                    return false;
                }
            } else {
                return false;
            }*/
            var missingInfo = {};
            var invalid = false;
            var allZeroRegEx = RegExp('^[0]*$');
            if (!$scope.toEdit.pin || allZeroRegEx.test($scope.toEdit.pin)) {
                missingInfo.pin = true;
                invalid = true;
            }
            if (!$scope.selectRole.chosen) {
                missingInfo.role = true;
                invalid = true;
            }
            if (invalid === true) {
                return missingInfo;
            }
            return false;
        };

        var isSavingEmployee = false;
        $scope.saveUser = function () {
            if (isSavingEmployee) {
                return;
            }

            $scope.pinInvalid = false;
            $scope.roleInvalid = false;
            $scope.toEdit.hourlyWage = +($scope.hourlyWageString * 100).toFixed();

            var invalidInfo = $scope.invalid();
            if (invalidInfo) {
                $timeout(function () {
                    $scope.pinInvalid = invalidInfo.pin;
                    $scope.roleInvalid = invalidInfo.role;
                }, 0);
                return;
            }

            isSavingEmployee = true;

            var timerToCancel = $timeout(function () {
                isSavingEmployee = false;
            }, 5000);


            if (!$scope.isEdit) {
                $scope.addUser($scope.currentPage, timerToCancel);
            } else {
                $scope.updateUser(false, $scope.currentPage, timerToCancel);
            }
        };

        $scope.addUser = function (currentPage, timerToCancel) {
            var updatedUser = angular.copy($scope.toEdit);
            // Commented by Akash Mehta
            // We have added a new field (roleType) to the user
            // We need to stop assigning roleNames to Permissions
            // and assign to roleType. Also we need to remove isManager.
            switch ($scope.selectRole.chosen.name) {
                case 'Cashier':
                    updatedUser.permission = 'CASHIER';
                    break;
                case 'KDS':
                    updatedUser.permission = 'KDS';
                    break;
                case 'Station':
                    updatedUser.permission = 'STATION';
                    break;
                case 'Manager':
                    updatedUser.permission = 'MANAGER';
                    break;
            }
            if (updatedUser.pin != null) {
                updatedUser.pin = updatedUser.pin + '';
            }

            Users.add({}, updatedUser, function (success) {
                isSavingEmployee = false;
                if (timerToCancel) {
                    $timeout.cancel(timerToCancel);
                }
                if (showEditor) {
                    $scope.originalUser.pin = $scope.toEdit.pin;
                }
                if ($scope.currentPage == 'employee-setup') {
                    $scope.$parent.returnToUsersPage('employee-setup', true);
                }
                $scope.$close();
            }, function (error) {
                isSavingEmployee = false;
                if (timerToCancel) {
                    $timeout.cancel(timerToCancel);
                }
                var data = error.data || {};
                var exception = data.exception || {};

                if (exception.appCode === 414) {
                    PosAlertService.showAlertByName('add-user-username-exists');
                } else {
                    PosAlertService.showAlertByName('add-user-fail', {
                        message: exception.message || ''
                    });
                }
            });
        };


        var updateUsersToBackend = function (toggleStatus, updatedUser, timerToCancel) {
            Users.update({}, updatedUser, function (success) {
                isSavingEmployee = false;
                if (timerToCancel) {
                    $timeout.cancel(timerToCancel);
                }
                if (!toggleStatus) {
                    if (showEditor) {
                        $scope.originalUser.pin = $scope.toEdit.pin;
                    }
                    if ($scope.currentPage == 'employee-setup') {
                        $scope.$parent.returnToUsersPage('employee-setup', true);
                    }
                } else {
                    if ($scope.toEdit.status == 'ACTIVE') {
                        $scope.$parent.employees.statusBar.text = 'Employee reactivated';
                    } else {
                        $scope.$parent.employees.statusBar.text = 'Employee deactivated';
                    }
                    $scope.$parent.employees.statusBar.toShow = true;
                    $scope.$parent.employees.statusBar.status = $scope.toEdit.status;
                }
                $scope.$close();
            }, function (error) {
                isSavingEmployee = false;
                if (timerToCancel) {
                    $timeout.cancel(timerToCancel);
                }
                var data = error.data || {};
                var exception = data.exception || {};

                if (exception.appCode === 402 || exception.appCode === 410) {
                    PosAlertService.showAlertByName('edit-user-fail', {
                        title: 'general.error.oops.ttl',
                        message: 'general.error.location.change.error'
                    });
                } else {
                    PosAlertService.showAlertByName('edit-user-fail', {
                        message: exception.message || ''
                    });
                }
            });
        };

        $scope.updateUser = function (toggleStatus = false, currentPage, timerToCancel) {
            var updatedUser = angular.copy($scope.toEdit);
            switch ($scope.selectRole.chosen.name) {
                // Commented by Akash Mehta
                // We have added a new field (roleType) to the user
                // We need to stop assigning roleNames to Permissions
                // and assign to roleType. Also we need to remove isManager.
                case 'Cashier':
                    updatedUser.permission = 'CASHIER';
                    break;
                case 'KDS':
                    updatedUser.permission = 'KDS';
                    break;
                case 'Station':
                    updatedUser.permission = 'STATION';
                    break;
                case 'Manager':
                    updatedUser.permission = 'MANAGER';
                    break;
            }
            if (updatedUser.pin != null) {
                updatedUser.pin = updatedUser.pin + '';
            }

            if ($scope.toEdit.oldStatus != $scope.toEdit.status) {
                updatedUser.toggleStatus = true;
            }

            updatedUser.hasChangedLocation = $scope.isLocationChanged;

            updateUsersToBackend(toggleStatus, updatedUser);
        };

        $scope.cancelModal = function () {
           // $modalInstance.dismiss('cancel');
        };

        $scope.generateRandomPin = function () {
            var max = 9;
            var pinLength = 4;
            var pin = '';
            for (var counter = 0; counter < pinLength; counter++) {
                pin += Math.floor(Math.random() * max);
            }
            $scope.toEdit.pin = pin;
            $scope.checkIfUserEdited();

        };

        $scope.updatePincodeOnPopup = function () {
            var message = 'Update Pincode';
            var actionName = 'Update Pincode';
            var cancelActionName = 'Cancel Update Pincode';
            var requestedPermission = '';

            var modalInstance = $modal.open({
                templateUrl: 'common/freshideas/pincodeAuthentication.tpl.html',
                controller: 'EditUserPinCtrl',
                windowClass: 'pincode-authentication-modal',
                modal: true,
                resolve: {
                    message: function () {
                        return message;
                    },
                    actionName: function () {
                        return actionName;
                    },
                    cancelActionName: function () {
                        return cancelActionName;
                    },
                    requestedPermission: function () {
                        return requestedPermission;
                    },
                    verifyAllUserPinsForCompany: function () {
                        return false;
                    }
                }
            });

            modalInstance.result.then(function (response) {
                $scope.toEdit.pin = response;
                $scope.checkIfUserEdited();
            }, function (error) {

            });
        };

        $scope.checkIfUserEdited = function () {
            if ($scope.toEdit.username != $scope.originalUser.username || $scope.toEdit.status != $scope.originalUser.status ||
                $scope.toEdit.firstname != $scope.originalUser.firstname || $scope.toEdit.lastname != $scope.originalUser.lastname ||
                $scope.toEdit.email != $scope.originalUser.email || $scope.toEdit.pin != $scope.originalUser.pin
                || $scope.toEdit.companyId != $scope.originalUser.companyId || $scope.toEdit.roleId != $scope.originalUser.roleId) {
                $scope.$parent.employees.hasChanged = true;
                $scope.isLocationChanged = false;

                if ($scope.toEdit.companyId != $scope.originalUser.companyId ) {
                    $scope.isLocationChanged = true;
                }
            }

            if ($scope.selectRole.chosen) {
                $scope.toEdit.roleId = $scope.selectRole.chosen.id;
                $scope.toEdit.roleName = $scope.selectRole.chosen.name;
            }

            if ($scope.isEdit && $scope.toEdit.permission == 'SITEADMIN') {
                $scope.toEdit.roleId = 0;
                $scope.toEdit.roleName = '';
            }

            $scope.isSaveValid = !$scope.invalid();
        };

        $scope.hourlyWageInput = function (e) {
            e.preventDefault();
            if ((e.key >= '0' && e.key <= '9') || e.key == '.') {
                e.target.value += e.key;
                $scope.hourlyWageString = e.target.value;
            }
        };

        $scope.init(selectedUser, currentPage);
    }
]);

};
