'use strict';

const moment = require('moment');

export function cardTerminalPax (freshideas) {
    freshideas.factory('PaxCommon', [
        '$timeout',
        'ErrorLoggingService',
        function (
            $timeout,
            ErrorLoggingService) {

            let _self = {};

            // default protocol version
            const VS1_PRTL_VERSION = '1.28';

            // device control characters
            // Util Obj format: [k, v] => [control character, hex code]
            /**
             * See {@link pax_response_example.proto} for further description of reading terminal responses.
             */
            const CTRL_CHAR_CODE = {
                'STX': '\x02',
                'ETX': '\x03',
                'ACK': '\x06',
                'NAK': '\x15',
                'ENQ': '\x05',
                'FS': '\x1c',
                'US': '\x1f',
                'GS': '\x1d',
                'RS': '\x1e',
                'EOT': '\x04',
                'COMMA': '\x2c',
                'COLON': '\x3a',
                'GROUP_SEPARATOR': '\x7c'
            };

            const REQUEST_COMMAND = {
                'INITIALIZE': 'A00',
                'CANCEL': 'A14',
                'RESET': 'A16',
                'SHOW_MESSAGE': 'A24',
                'DO_CREDIT': 'T00'
            };

            const TRANSACTION_TYPE = {
                'SALE': '01',
                'REFUND': '02',
                'VOID': '16',
                'VOID_SALE': '17',
                'VOID_RETURN': '18'
            };

            const CARD_ENTRY_MODE = {
                '0': 'Manual',
                '1': 'Swipe',
                '2': 'Contactless',
                '3': 'Scanner',
                '4': 'Chip',
                '5': 'Chip Fall Back Swipe'
            };

            const CARD_TYPE = {
                '01': 'Visa',
                '02': 'MasterCard',
                '03': 'AMEX',
                '04': 'Discover',
                '07': 'JCB',
                '17': 'Interac',
                '32': 'OTHER',
            };

            // keeping it for future reference if we ever wanted to support HTTP/HTTPS connection after resolving preflight issue
/*
            // hex to base64
            function hexToBase64 (str) {
                return btoa(String.fromCharCode.apply(null, str.replace(/\r|\n/g, '').replace(/([\da-fA-F]{2}) ?/g, '0x$1 ').replace(/ +$/, '').split(' ')));
            }

            // base64 to hex
            function base64ToHex (str) {
              for (var i = 0, bin = atob(str), hex = []; i < bin.length; ++i) {
                let tmp = bin.charCodeAt(i).toString(16);

                if (tmp.length === 1) tmp = '0' + tmp;
                hex[hex.length] = tmp;
              }
              return hex.join(' ');
            }
*/
            // String to Hex
            function StringToHex (response) {
                let hexedResponse = '';

                for (let i = 0; i < response.length; i++) {
                    if (hexedResponse == '') {
                        hexedResponse = response.charCodeAt(i).toString(16).length < 2 ? '0' + response.charCodeAt(i).toString(16) : response.charCodeAt(i).toString(16);
                    } else {
                        hexedResponse += response.charCodeAt(i).toString(16).length < 2 ? ' ' + '0' + response.charCodeAt(i).toString(16) : ' ' + response.charCodeAt(i).toString(16);
                    }
                }
                return hexedResponse;
            }

            // hex to string
            function HexToString (response) {
                let responseHex = '';
                let arr = response.split(' ');

                for (let i = 0; i < arr.length; i++) {
                    if (arr[i] == '') {
                        continue;
                    }
                    responseHex += String.fromCharCode(parseInt(arr[i], 16));
                }
                return responseHex;
            }

            // LRC calculation (excludes STX - packet start but includes ETX - packet end) steps:
            // 1. Set LRC = 0
            // 2. For each character c in the string or complete hex code do LRC = LRC XOR c
            // 3. return final LRC value or 0 if none
            let calculateLRC = function (packet) {
                let LRC = 0;

                // excluding STX - packet start (packet[0])
                for (let i = 1; i < packet.length; i++) {
                    let typeOf = typeof(packet[i]);

                    if (typeOf == 'string') {
                        let element = packet[i].split('');

                        for (let j = 0; j < element.length; j++) {
                            LRC ^= element[j].charCodeAt(0);
                        }
                    } else {
                        LRC ^= packet[i];
                    }
                }

                return (LRC > 0) ? String.fromCharCode(LRC) : 0;
            };

            let buildAdditionalInfo = function (additionalInfo) {
                let object = {};

                // console.log('add', additionalInfo, !additionalInfo);

                if (!additionalInfo) {
                    object.EDCTYPE, object.HRef, object.GLOBALUID, object.CVM, object.APPLAB, object.AID, object.TVR, object.TSI, object.userLanguageStatus = '';

                    return object;
                }

                additionalInfo.forEach(function (item, index) {
                    let splitItem = item.split('=');

                    object[splitItem[0]] = splitItem[1];
                });

                return object;
            };

            let toTransactionTime = function (transactionDateTime) {
                // transactionDateTime returns time in 12 hours without AM/PM
                let correctHours = (new Date(Date.now()).getHours() >= 12) ? 12 : -12;

                if (transactionDateTime) {
                    let dateTimeString = transactionDateTime.substring(0, 4) + '-' // YYYY
                                        + transactionDateTime.substring(4, 6) + '-' // MM
                                        + transactionDateTime.substring(6, 8) + ' ' // DD
                                        + (Number(transactionDateTime.substring(8, 10)) + correctHours) + ':' // HH
                                        + transactionDateTime.substring(10, 12) + ':' // MM
                                        + transactionDateTime.substring(12, 14); // SS
                    // console.log('time: ', dateTimeString, moment(dateTimeString).format('YYYY-MM-DD hh:mm:ss A'));
                    return moment(dateTimeString).format('YYYY-MM-DD hh:mm:ss A');
                } else {
                    // for failed transactions, it is possible the terminal does not
                    // return any datetime to us. In this case we will make it ourselves.
                    return moment().format('YYYY-MM-DD HH:mm:ss');
                }
            };

            // destructuring control characters
            const {STX, ETX, FS, US} = CTRL_CHAR_CODE;

            _self.CTRL_CHAR_CODE = CTRL_CHAR_CODE;

            _self.initTerminalCmd = function () {
                const initCommand = [STX, REQUEST_COMMAND.INITIALIZE, FS, VS1_PRTL_VERSION, ETX];
                const requestPacket = initCommand.join('') + calculateLRC(initCommand);

                return requestPacket;
            };

            _self.creditSaleCmd = function (amount) {
                const epochTime = Date.now().toString(); // unique number within 16 digit limit

                const creditCommand = [STX, REQUEST_COMMAND.DO_CREDIT, FS, VS1_PRTL_VERSION, FS, TRANSACTION_TYPE.SALE, FS,
                                    parseFloat(amount * 100).toFixed(0), US, FS, FS,
                                    epochTime, US,
                                    FS, FS, FS, FS, FS, 'TIPREQ=1', FS, ETX];
                const requestPacket = creditCommand.join('') + calculateLRC(creditCommand);

                return requestPacket;
            };

            _self.creditVoidCmd = function (transactionId) {
                const epochTime = Date.now().toString(); // unique number within 16 digit limit

                const voidCommand = [STX, REQUEST_COMMAND.DO_CREDIT, FS, VS1_PRTL_VERSION, FS, TRANSACTION_TYPE.VOID, FS, FS, FS,
                                    epochTime, US, US, US,
                                    transactionId, US, FS, FS, FS, FS, FS,
                                    FS, ETX];
                const requestPacket = voidCommand.join('') + calculateLRC(voidCommand);

                return requestPacket;
            };

            _self.creditReturnCmd = function (amountToRefund) {
                const epochTime = Date.now().toString(); // unique number within 16 digit limit

                const returnCommand = [STX, REQUEST_COMMAND.DO_CREDIT, FS, VS1_PRTL_VERSION, FS, TRANSACTION_TYPE.REFUND, FS,
                                    parseFloat(amountToRefund * 100).toFixed(0), US, FS, FS,
                                    epochTime, US,
                                    FS, FS, FS, FS, FS, FS, ETX];
                const requestPacket = returnCommand.join('') + calculateLRC(returnCommand);

                return requestPacket;
            };

            _self.cancelCmd = function () {
                const cancelCommand = [STX, REQUEST_COMMAND.CANCEL, FS, VS1_PRTL_VERSION, ETX];
                const requestPacket = cancelCommand.join('') + calculateLRC(cancelCommand);

                return requestPacket;
            };

            _self.resetCmd = function () {
                const resetCommand = [STX, REQUEST_COMMAND.RESET, FS, VS1_PRTL_VERSION, ETX];
                const requestPacket = resetCommand.join('') + calculateLRC(resetCommand);

                return requestPacket;
            };

            _self.messagePromptCmd = function (title, message, iconType) {
                const promptCommand = [STX, REQUEST_COMMAND.SHOW_MESSAGE, FS, VS1_PRTL_VERSION, FS, title, FS, message, FS, FS, '100', FS, FS, iconType, ETX];
                const requestPacket = promptCommand.join('') + calculateLRC(promptCommand);

                return requestPacket;
            };

            _self.getResponseArray = function (response = '') {
                let hexedResponseArray = StringToHex(response).slice(' ').split(/02|1c|03/);
                // remove STX empty entry at the beginning
                hexedResponseArray.shift();

                let responseArray = [];

                hexedResponseArray.forEach((value, index) => {
                    if (value.split(/1f/).length > 1) {
                        let subPacket = [];

                        value.split(/1f/).forEach((sub) => {
                            subPacket.push(HexToString(sub));
                        });

                        responseArray.push(subPacket);
                    } else {
                        responseArray.push(HexToString(value));
                    }
                });

                return responseArray;
            };

            _self.getPrintObj = function (response) {
                let responseArray = _self.getResponseArray(response);

                // status = responseArray[0],
                // command = responseArray[1],
                // version = responseArray[2],
                const responseCode = responseArray[3];
                // responseMessage = responseArray[4];

                let hostInformation, transactionType, amountInformation, accountInformation, traceInformation, additionalInformation = '';
                // avsInformation, commercialInformation, eCommerceInformation;

                let authorizationNumber, referenceNumber, cardNumber, maskedCardNumber, cardEntryMethod, cardName, cardholderName,
                    transactionId, transactionDateTime, href, transactionSequenceNum, cvmResult, emvAppLabel,
                    emvAppPreferredName, emvAid, emvTvr, emvTsi, emvCryptogramType, emvCryptogram = '';

                let transactionAmount, approvedAmount, balanceDue, tipAmount, surchargeAmount, totalAmount = 0;

                let isCredit, isDebit, showCustomerSignatureLine, showMerchantSignatureLine, partiallyApproved,
                    verifiedByPin = false;

                let customerLanguage = 'en';

                if (responseArray.length > 6) {
                    hostInformation = responseArray[5],
                    transactionType = responseArray[6],
                    amountInformation = responseArray[7],
                    accountInformation = responseArray[8],
                    traceInformation = responseArray[9],
                    // avsInformation = responseArray[10],
                    // commercialInformation = responseArray[11],
                    // eCommerceInformation = responseArray[12],
                    additionalInformation = buildAdditionalInfo(responseArray[13]);

                    authorizationNumber = hostInformation[2];
                    referenceNumber = hostInformation[3];
                    transactionAmount = parseFloat((Number(amountInformation[0]) - Number(amountInformation[2])) / 100).toFixed(2);
                    approvedAmount = parseFloat(amountInformation[0] / 100).toFixed(2);
                    balanceDue = parseFloat(amountInformation[1] / 100).toFixed(2);
                    tipAmount = parseFloat(amountInformation[2] / 100).toFixed(2);
                    surchargeAmount = parseFloat(amountInformation[4] / 100).toFixed(2);
                    totalAmount = parseFloat(amountInformation[0] / 100).toFixed(2);

                    cardNumber = '************' + accountInformation[0];
                    maskedCardNumber = cardNumber;
                    cardEntryMethod = CARD_ENTRY_MODE[accountInformation[1]];
                    cardName = CARD_TYPE[accountInformation[6]];
                    cardholderName = accountInformation[7];

                    transactionId = traceInformation[0];
                    transactionDateTime = toTransactionTime(traceInformation[2]);

                    isCredit = (additionalInformation.EDCTYPE == 'CREDIT');
                    isDebit = (additionalInformation.EDCTYPE == 'DEBIT');
                    href = additionalInformation.HRef;
                    transactionSequenceNum = additionalInformation.GLOBALUID;
                    showCustomerSignatureLine = ['0', '1', '3', '4', '5', '6'].includes(additionalInformation.CVM);
                    showMerchantSignatureLine = ['0', '1', '3', '4', '5', '6'].includes(additionalInformation.CVM);
                    cvmResult = additionalInformation.CVM;
                    verifiedByPin = ['1', '2', '4'].includes(additionalInformation.CVM);
                    emvAppLabel = additionalInformation.APPLAB;
                    emvAppPreferredName = additionalInformation.APPN;
                    emvAid = additionalInformation.AID;
                    emvTvr = additionalInformation.TVR;
                    emvTsi = additionalInformation.TSI;
                    emvCryptogramType = (!additionalInformation.TC) ? 'ARQC:' : additionalInformation.TC;
                    emvCryptogram = additionalInformation.ARQC;
                    customerLanguage = additionalInformation.userLanguageStatus != '1' ? 'fr': 'en';

                    partiallyApproved = (responseCode === '000000' && hostInformation[0] === '10');
                }

                // const totalAmount = parseFloat((Number(amountInformation[0]) + Number(amountInformation[2])) / 100).toFixed(2);

                return {
                    paymentProcessor: 'heartland',

                    success: responseCode === '000000' && (hostInformation[0] === '00' || hostInformation[0] === '10'), // OK with Partial Approval is success
                    cancelledByUser: responseCode === '100002',
                    authorizationNumber: authorizationNumber,
                    referenceNumber: referenceNumber,

                    transactionTypeCode: transactionType,
                    transactionType: Object.keys(TRANSACTION_TYPE).find((type) => TRANSACTION_TYPE[type] === transactionType),

                    transactionAmount: transactionAmount,
                    approvedAmount: approvedAmount,
                    balanceDue: balanceDue,
                    tipAmount: tipAmount,
                    surchargeAmount: surchargeAmount,
                    totalAmount: totalAmount,

                    cardNumber: cardNumber,
                    maskedCardNumber: maskedCardNumber,
                    cardEntryMethod: cardEntryMethod,
                    cardName: cardName,
                    cardholderName: cardholderName,

                    transactionId: transactionId,
                    transactionDateTime: transactionDateTime,
                    cardBalance: null,
                    demoMode: false,
                    formFactor: null,

                    isCredit: isCredit,
                    isDebit: isDebit,
                    href: href,
                    transactionSequenceNum: transactionSequenceNum,
                    showCustomerSignatureLine: showCustomerSignatureLine,
                    showMerchantSignatureLine: showMerchantSignatureLine,
                    cvmResult: cvmResult,
                    verifiedByPin: verifiedByPin,
                    emvAppLabel: emvAppLabel,
                    emvAppPreferredName: emvAppPreferredName,
                    emvAid: emvAid,
                    emvTvr: emvTvr,
                    emvTsi: emvTsi,
                    emvCryptogramType: emvCryptogramType,
                    emvCryptogram: emvCryptogram,
                    customerLanguage: customerLanguage,

                    partiallyApproved: partiallyApproved,
                };
            };

            let saleInProgress = false;
            _self.timeoutWrapper = function (f, timedOut) {
                return function (res, rej) {
                    let timeout = null;

                    if (saleInProgress) {
                        console.error('Multiple sales initiated in parallel!');
                    }
                    saleInProgress = true;

                    let createTimeout = function () {
                        if (timeout) {
                            cancelTimeout();
                        }
                        timeout = $timeout(function () {
                            timedOut(resolve, reject);
                        }, 30000);
                    };

                    let cancelTimeout = function () {
                        if (timeout) {
                            $timeout.cancel(timeout);
                            timeout = null;
                        }
                    };

                    let resolve = function (x) {
                        cancelTimeout();
                        saleInProgress = false;
                        res(x);
                    };

                    let reject = function (x) {
                        cancelTimeout();
                        saleInProgress = false;
                        rej(x);
                    };

                    let onData = function (x) {
                        createTimeout();
                    };

                    let onConnected = function () {
                        createTimeout();
                    };

                    try {
                        f(resolve, reject, onData, onConnected);
                    } catch (e) {
                        ErrorLoggingService.log(e);
                        reject(e);
                    }
                };
            };

            _self.onDataReceived = function (response, resolve, reject) {
                let responseArray = _self.getResponseArray(response);
                let parsedResponse = _self.getPrintObj(response);

                if (responseArray[3] !== '000000') {
                    ErrorLoggingService.log({message: 'CardTerminal Response', data: ['Parsed response: ', JSON.stringify(parsedResponse), 'Raw response: ', response]});
                    reject({data: [parsedResponse, response]});
                } else {
                    resolve([parsedResponse, response]);
                }
            };

            return _self;
        }
    ]);

    freshideas.factory('CardTerminalPax', [
        '$timeout',
        'PaxCommon',
        'ErrorLoggingService',
        function (
            $timeout,
            PaxCommon,
            ErrorLoggingService) {

            let config;

            let init = async function (terminalConfig) {
                // kept it for future TCP connection if ever terminal wanted to be worked on TCP
                if (typeof window !== 'undefined') {
                    // running from browser
                    if (!window.process || !window.process.type) {
                        throw new Error('Running in non-electron environment. Terminal will be disabled');
                    }
                }

                config = terminalConfig;
            };

            let contactTerminal = function (requestPacket) {
                let net = nodeRequire('net');
                let client = new net.Socket();

                return new Promise(function (resolve, reject) {
                    client.connect({port: config.port, host: config.ip}, function () {
                        client.write(Buffer.from(requestPacket));
                    });

                    client.on('data', function (data) {
                        if (data.length > 1) {
                            resolve(data.toString());
                            client.write(Buffer.from(PaxCommon.CTRL_CHAR_CODE.ACK));
                        }
                    });

                    client.setTimeout(30000);
                    client.on('timeout', function () {
                        reject({timedOut: true, title: 'POS Timeout', message: 'Did not receive response in time', iconType: 2});
                    });

                    client.on('error', function () {
                        console.error('terminal connection error');
                        reject({title: 'Error', message: 'There was an error connecting to terminal', iconType: 2});
                        client = null;
                    });

                    client.on('end', () => {
                        // console.log('terminal connection ended');
                        client = null;
                    });

                    client.on('close', () => {
                        // console.log('terminal connection closed');
                        client = null;
                    });
                });
            };

            // This has to be first command before any transaction command see low level spec for details
            let initializeTerminal = function () {
                const requestPacket = PaxCommon.initTerminalCmd();

                return new Promise(function (resolve, reject) {
                    contactTerminal(requestPacket).then(function (response) {
                        resolve(response);
                    }, function (rej) {
                        console.error('Terminal Initialize Rejected');
                        ErrorLoggingService.log({message: 'Terminal Initialize Rejected', data: JSON.stringify(rej)});
                        reject(rej);
                    });
                });
            };

            let processRequest = async function (command, ...params) {
                const initTerminal = await initializeTerminal();
                let responseArray = PaxCommon.getResponseArray(initTerminal);

                const responseCode = responseArray[3];
                const responseMessage = responseArray[4];

                let requestPacket;

                switch (command) {
                    case 'creditSale':
                        requestPacket = PaxCommon.creditSaleCmd(params[0]);
                    break;
                    case 'creditVoid':
                        requestPacket = PaxCommon.creditVoidCmd(params[0]);
                    break;
                    case 'creditReturn':
                        requestPacket = PaxCommon.creditReturnCmd(params[0]);
                    break;
                    default:
                        console.error('Terminal command not support');
                }

                return new Promise(function (resolve, reject) {
                    if (responseCode !== '000000') {
                        reject();
                        console.error(`Terminal Initialization error - responseCode: ${responseCode}, responseMessage: ${responseMessage}`);
                    } else {
                        contactTerminal(requestPacket).then(function (response) {
                            responseArray = PaxCommon.getResponseArray(response);
                            PaxCommon.onDataReceived(response, resolve, reject);

                            // show message on the terminal
                            messagePrompt(responseArray);
                            // reset();
                        }, function (rejObj) {
                            console.error('credit promise rejected: ', rejObj);
                            ErrorLoggingService.log({message: 'CreditSale Rejected', data: JSON.stringify(rejObj)});
                            reject(rejObj);

                            // show message on the terminal
                            messagePrompt([], rejObj.title, rejObj.message, rejObj.iconType);
                        });
                    }
                });
            };

            let messagePrompt = function (responseArray, ...params) {
                let title, message, iconType;
                // **** iconType ****
                // 0: No icon is displayed (Default)
                // 1: Approved icon is displayed
                // 2: Declined icon is displayed

                // title, message can't exceed limit of 36, 60 characters respectively
                if (responseArray.length >= 4) {
                    title = responseArray[4];
                    message = responseArray.length > 6 ? responseArray[5][1] : responseArray[3];
                    iconType = responseArray[3] !== '000000' ? '2': '1';
                } else {
                    title = params[0];
                    message = params[1];
                    iconType = params[2];
                }

                // console.log('mess: ', title, message, iconType);
                const requestPacket = PaxCommon.messagePromptCmd(title, message, iconType);

                // console.log('mess: ', requestPacket);
                contactTerminal(requestPacket);

            };

            let creditSale = function (amount) {
                return processRequest('creditSale', amount);
            };

            let creditVoid = function (transactionId, transactionObj) {
                return processRequest('creditVoid', transactionId);
            };

            let creditReturn = function (amountToRefund, params) {
                return processRequest('creditReturn', amountToRefund);
            };

            let debitSale = function (amount) {
                return creditSale(amount);
            };

            let debitReturn = function (amount) {
                return creditReturn(amount);
            };

            let testSale = function () {
                return creditSale(0.01);
            };

            let autoSettle = function () {
                return new Promise.resolve();
            };

/*
            var cancel = function () {
                let requestPacket = PaxCommon.cancelCmd();

                return new Promise((resolve, reject) => {
                    contactTerminal(requestPacket).then((response) => {
                        resolve(response);
                        console.log('cancel: ', response);
                    }).catch((error) => {
                        reject(error);
                        console.log('cancel err: ', error);
                    });
                });
            };
*/
            let reset = function () {
                let requestPacket = PaxCommon.resetCmd();

                return new Promise((resolve, reject) => {
                    contactTerminal(requestPacket).then((response) => {
                        resolve(response);
                    }).catch((error) => {
                        reject(error);
                        console.log('reset err: ', error);
                    });
                });
            };

            let parseResponse = function (response) {
                return PaxCommon.getPrintObj(response);
            };

            let isCancellableFromPos = function () {
                return false;
            };

            let cancelFromPos = async function () {
                await reset();
                return Promise.resolve();
            };

            return {
                init: init,
                creditSale: creditSale,
                creditVoid: creditVoid,
                creditReturn: creditReturn,
                debitSale: debitSale,
                debitReturn: debitReturn,
                testSale: testSale,
                autoSettle: autoSettle,
                parseResponse: parseResponse,
                isCancellableFromPos: isCancellableFromPos,
                cancelFromPos: cancelFromPos
            };
        }
    ]);

    freshideas.factory('iOSCardTerminalPax', [
        'IosTcpClient',
        'TerminalEvent',
        'PaxCommon',
        'ErrorLoggingService',
        function (
            IosTcpClient,
            TerminalEvent,
            PaxCommon,
            ErrorLoggingService) {

            let config;

            let init = function (terminalConfig) {
                config = terminalConfig;
            };

            let contactTerminal = function (requestPacket) {
                let didResolveOrReject = false;

                let timedOut = function (resolve, reject) {
                    if (!didResolveOrReject) {
                        // the connection closed before we know the state of the transaction!!
                        // let's get the lastTransaction status
                        // lastTransactionStatus().then(resolve, reject);
                        reject({timedOut: true, title: 'POS Timeout', message: 'Did not receive response in time', iconType: 2});
                    }
                };

                return new Promise(PaxCommon.timeoutWrapper(function (resolve, reject, onData, onConnected) {
                    IosTcpClient.connect(config.port, config.ip).then(function (client) {
                        onConnected();

                        client.onData(function (data) {
                            try {
                                onData();

                                // not ACK, NAK but response
                                if (data[0] !== 6 && data.length > 1) {
                                    didResolveOrReject = true;
                                    resolve(data.toString());
                                    client.write(Buffer.from(PaxCommon.CTRL_CHAR_CODE.ACK));
                                } else if (data[0] == 4) { // on EOT terminate the connection
                                    client.disconnect();
                                }
                            } catch (error) {
                                didResolveOrReject = true;
                                ErrorLoggingService.log(error);
                                console.error('Terminal read error');
                                reject(error.toString());
                            }
                        });

                        client.write(requestPacket);
                    }).catch(function (error) {
                        ErrorLoggingService.log({message: 'CardTerminal Error', data: JSON.stringify(error)});
                        didResolveOrReject = true;
                        console.error('CardTerminal Error: ', error);
                        reject({message: error && error.toString()});
                    });
                }, timedOut));
            };

            // This has to be first command before any transaction command see low level spec for details
            let initializeTerminal = function () {
                const requestPacket = PaxCommon.initTerminalCmd();

                return new Promise(function (resolve, reject) {
                    contactTerminal(requestPacket).then(function (response) {
                        resolve(response);
                    }, function (rej) {
                        console.error('Terminal Initialize Rejected');
                        ErrorLoggingService.log({message: 'Terminal Initialize Rejected', data: JSON.stringify(rej)});
                        reject(rej);
                    });
                });
            };

            let processRequest = async function (command, ...params) {
                const initTerminal = await initializeTerminal();
                let responseArray = PaxCommon.getResponseArray(initTerminal);

                const responseCode = responseArray[3];
                const responseMessage = responseArray[4];

                let requestPacket;

                switch (command) {
                    case 'creditSale':
                        requestPacket = PaxCommon.creditSaleCmd(params[0]);
                    break;
                    case 'creditVoid':
                        requestPacket = PaxCommon.creditVoidCmd(params[0]);
                    break;
                    case 'creditReturn':
                        requestPacket = PaxCommon.creditReturnCmd(params[0]);
                    break;
                    default:
                        console.error('Terminal command not support');
                }

                return new Promise(function (resolve, reject) {
                    if (responseCode !== '000000') {
                        reject();
                        console.error(`Terminal Initialization error - responseCode: ${responseCode}, responseMessage: ${responseMessage}`);
                    } else {
                        contactTerminal(requestPacket).then(function (response) {
                            responseArray = PaxCommon.getResponseArray(response);
                            PaxCommon.onDataReceived(response, resolve, reject);

                            // show message on the terminal
                            messagePrompt(responseArray);
                            // reset();
                        }, function (rejObj) {
                            console.error('credit promise rejected: ', rejObj);
                            ErrorLoggingService.log({message: 'CreditSale Rejected', data: JSON.stringify(rejObj)});
                            reject(rejObj);

                            // show message on the terminal
                            messagePrompt([], rejObj.title, rejObj.message, rejObj.iconType);
                        });
                    }
                });
            };

            let messagePrompt = function (responseArray, ...params) {
                let title, message, iconType = '';
                // **** iconType ****
                // 0: No icon is displayed (Default)
                // 1: Approved icon is displayed
                // 2: Declined icon is displayed

                // title, message can't exceed limit of 36, 60 characters respectively
                if (responseArray.length >= 4) {
                    title = responseArray[4];
                    message = responseArray.length > 6 ? responseArray[5][1] : responseArray[3];
                    iconType = responseArray[3] !== '000000' ? '2': '1';
                } else {
                    title = params[0];
                    message = params[1];
                    iconType = params[2];
                }

                // console.log('mess: ', title, message, iconType);
                const requestPacket = PaxCommon.messagePromptCmd(title, message, iconType);

                // console.log('mess: ', requestPacket);
                contactTerminal(requestPacket);

            };

            let creditSale = function (amount) {
                return processRequest('creditSale', amount);
            };

            let creditVoid = function (transactionId, transactionObj) {
                return processRequest('creditVoid', transactionId);
            };

            let creditReturn = function (amountToRefund, params) {
                return processRequest('creditReturn', amountToRefund);
            };

            let debitSale = function (amount) {
                return creditSale(amount);
            };

            let debitReturn = function (amount) {
                return creditReturn(amount);
            };

            let testSale = function () {
                return creditSale(0.01);
            };

            let autoSettle = function () {
                return new Promise.resolve();
            };

            let reset = function () {
                let requestPacket = PaxCommon.resetCmd();

                return new Promise((resolve, reject) => {
                    contactTerminal(requestPacket).then((response) => {
                        resolve(response);
                    }).catch((error) => {
                        reject(error);
                        console.log('reset err: ', error);
                    });
                });
            };

            let parseResponse = function (response) {
                return PaxCommon.getPrintObj(response);
            };

            let isCancellableFromPos = function () {
                return false;
            };

            let cancelFromPos = async function () {
                await reset();
                return Promise.resolve();
            };

            return {
                init: init,
                creditSale: creditSale,
                creditVoid: creditVoid,
                creditReturn: creditReturn,
                debitSale: debitSale,
                debitReturn: debitReturn,
                testSale: testSale,
                autoSettle: autoSettle,
                parseResponse: parseResponse,
                isCancellableFromPos: isCancellableFromPos,
                cancelFromPos: cancelFromPos
            };

        }
    ]);

}
