'use strict';

const moment = require('moment');

module.exports = function (freshideasSetup) {

    freshideasSetup.controller('EmployeeTimecardsModifyCtrl', [
    '$scope',
    '$modal',
    '$translate',
    '$filter',
    '$timeout',
    'Users',
    'Security',
    'Settings',
    'PosAlertService',
    'Reports',
    'Pure',
    'selectedTimeCard',
    function ($scope, $modal, $translate, $filter, $timeout, Users, Security, Settings, PosAlertService, Reports, Pure, selectedTimeCard) {
        $scope.isFullAdmin = (Security.getUser().permission === 'FULLADMIN');
        $scope.permissionTypeOptions = [];
        $scope.selectRole = {chosen: undefined};
        $scope.statusToggle={};
        $scope.originalTimecard = {};
        $scope.isSaveValid = true; // Change to false when restarting the validator

        $scope.hstep = 1;
        $scope.mstep = 1;

        $scope.timestamps = {};

        $scope.init = function (timeCard) {
            $scope.isEdit = !(timeCard.isNew);
            $scope.selectedCompanyId = timeCard.companyId;

            if (!$scope.isEdit) {
                $scope.toEdit = angular.copy(timeCard);
            } else {
                $scope.toEdit = angular.copy(timeCard);
                $scope.timecards.label = $scope.toEdit.user.firstname + ' ' + $scope.toEdit.user.lastname + '\'s Timecard';
                $scope.originalTimecard = timeCard;
                var shiftStartTime = {};
                shiftStartTime.originalTimestamp = $scope.toEdit.startTime;
                shiftStartTime.newTimestamp = angular.copy($scope.toEdit.startTime);
                shiftStartTime.timeInHrsMins = $filter('date')(shiftStartTime.newTimestamp, 'hh:mm');
                shiftStartTime.timestampPeriod = $filter('date')(shiftStartTime.newTimestamp, 'a');
                shiftStartTime.errorLabel = 'Clock In Time';
                shiftStartTime.label = 'Clocked In';
                $scope.timestamps['startTime'] = shiftStartTime;

                var shiftEndTime = {};
                if ($scope.toEdit.shiftClosed) {
                    shiftEndTime.originalTimestamp = $scope.toEdit.endTime;
                    shiftEndTime.newTimestamp = angular.copy($scope.toEdit.endTime);
                    shiftEndTime.timeInHrsMins = $filter('date')(shiftEndTime.newTimestamp, 'hh:mm');
                    shiftEndTime.timestampPeriod = $filter('date')(shiftEndTime.newTimestamp, 'a');
                }
                shiftEndTime.errorLabel = 'Clock Out Time';
                shiftEndTime.label = 'Clocked Out';
                $scope.timestamps['endTime'] = shiftEndTime;
                $scope.totalHours = $scope.toEdit.hours;
                $scope.isLoadingHistory = true;
                Users.getTimecardEditHistory({timeCardId: $scope.toEdit.id}, function (response) {
                    $scope.editHistory = response;
                    $scope.isLoadingHistory = false;
                });
            }
        };

        $scope.isValid = function () {
            if (!$scope.toEdit.startTime) {
                 PosAlertService.showAlertByName('invalid-date-range', {
                        message: 'Invalid Clock In Time'
                    });
                return false;
            }

            if ($scope.toEdit.isEndTimeEdited && !$scope.toEdit.endTime) {
                PosAlertService.showAlertByName('invalid-date-range', {
                        message: 'Invalid Clock Out Time'
                    });
                return false;
            }

            if (($scope.toEdit.isEndTimeEdited || $scope.toEdit.shiftClosed) && $scope.toEdit.endTime) {
                if ($scope.toEdit.endTime < $scope.toEdit.startTime) {
                    PosAlertService.showAlertByName('invalid-date-range', {
                        message: '"Clock Out Time" must be later than "Clock In Time"'
                    });
                    return false;
                }
            }

            return true;
        };

        var populateTimestamps = function () {
            /* eslint-disable guard-for-in */
            for (var type in $scope.timestamps) {
                try {
                    if (!$scope.timestamps[type].newTimestamp) {
                        continue;
                    }
                    var tmpTime = $scope.timestamps[type].timeInHrsMins + ' ' + $scope.timestamps[type].timestampPeriod;
                    var tmpDateString = moment($scope.timestamps[type].newTimestamp).format('MM/DD/YYYY');
                    var newDate = moment(tmpDateString+ ' ' + tmpTime).valueOf();
                    var originalTimestampWithoutSeconds = moment($scope.timestamps[type].originalTimestamp).seconds(0).milliseconds(0).valueOf();
                    var isEdited = (newDate == originalTimestampWithoutSeconds) ? false : true;

                    if (type == 'startTime') {
                        $scope.toEdit.originalStartTime = $scope.toEdit.startTime;
                        $scope.toEdit.startTime = newDate;
                        $scope.toEdit.isStartTimeEdited = isEdited;
                    } else if (type == 'endTime') {
                        $scope.toEdit.originalEndTime = $scope.toEdit.endTime;
                        $scope.toEdit.endTime = newDate;
                        $scope.toEdit.isEndTimeEdited = isEdited;
                    }
                } catch (error) {
                    PosAlertService.showAlertByName('invalid-date-range', {
                        message: 'Invalid '+ $scope.timestamps[type].errorLabel
                    });
                    return;
                }
            }
            /* eslint-enable */
        };

        var isSavingUser = false;

        $scope.saveUser = function () {
            if (isSavingUser) {
                return;
            }

            populateTimestamps();
            if (!$scope.isValid()) {
                return;
            }

            isSavingUser = true;
            var timerToCancel = $timeout(function () {
                isSavingUser = false;
            }, 5000);

            if (!$scope.isEdit) {
                $scope.addTimecard(timerToCancel);
            } else {
                $scope.updateTimecard(timerToCancel);
            }
        };

        $scope.updateTimecard = function (timerToCancel) {
            var updatedUser = angular.copy($scope.toEdit);
            Users.updateTimecard({}, updatedUser, function (success) {
                var timeCard = success;
                isSavingUser = false;
                if (timerToCancel) {
                    $timeout.cancel(timerToCancel);
                }

                var userId = $scope.toEdit.user.userId;
                var startTime = $scope.toEdit.startTime;
                var endTime = $scope.toEdit.endTime;
                var duration = moment.duration(endTime - startTime);
                var newMinutes = Math.floor(duration.as('minutes'));

                var labourWeekSearch = {
                    userId: userId,
                    startDateTime: moment(startTime).startOf('week').valueOf(),
                    endDateTime: moment(startTime).endOf('week').valueOf(),
                };

                Reports.getLabourReport(labourWeekSearch, function (response) {
                    var newLabourData = Pure.calculateNewLabour(userId, newMinutes, timeCard, response, true);
                    Users.addLabour(newLabourData);
                });

                $scope.$parent.returnToUsersPage('employee-timecards', true);
                $scope.$close();
            }, function (error) {
                isSavingUser = false;
                if (timerToCancel) {
                    $timeout.cancel(timerToCancel);
                }
                var data = error.data || {};
                var exception = data.exception || {};

                PosAlertService.showAlertByName('edit-user-fail', {
                    message: exception.message || ''
                });
            });
        };
        $scope.cancelModal = function () {
           // $modalInstance.dismiss('cancel');
        };

        $scope.checkIfTimecardEdited = function () {
            var newDiffHours= 0;
            /* eslint-disable guard-for-in */
            for (var type in $scope.timestamps) {
                try {
                    if (!$scope.timestamps[type].newTimestamp) {
                        continue;
                    }
                    var tmpTime = $scope.timestamps[type].timeInHrsMins + ' ' + $scope.timestamps[type].timestampPeriod;
                    var tmpDateString = moment($scope.timestamps[type].newTimestamp).format('MM/DD/YYYY');
                    var newDate = moment(tmpDateString+ ' ' + tmpTime).valueOf();
                    var originalTimestampWithoutSeconds = moment($scope.timestamps[type].originalTimestamp).seconds(0).milliseconds(0).valueOf();
                    var isEdited = (newDate == originalTimestampWithoutSeconds) ? false : true;

                    if (isEdited && !$scope.$parent.timecards.hasChanged) {
                        $scope.$parent.timecards.hasChanged = isEdited;
                    }

                    if (type == 'startTime') {
                        newDiffHours -= newDate;
                    } else if (type == 'endTime') {
                        newDiffHours += newDate;
                    }
                } catch (error) {
                    $scope.$parent.timecards.hasChanged = true;
                }
            }

            /* eslint-enable */

            $scope.totalHours = $scope.getTimeInHoursAndMinutes(newDiffHours);
        };
        $scope.init(selectedTimeCard);
    }
]);

};
