'use strict';


export function bluetoothHelper (freshideas) {

    freshideas.factory('BluetoothHelper', [
        'Logger',
        function (Logger) {
            var parseResponse = function (buffer) {

                let returnArr = [];
                let packets = [];
                let err;
                const HEADER_SIZE = 3;

                getPackets(buffer);
                packets.forEach((packet) => {
                    switch (packet.eventCode) {
                        case 0x00FF:
                            returnArr.push({
                                err: err,
                                body: parseHCILEExtEvent(packet.body)
                            });
                            break;
                        case 0x000E:
                            returnArr.push({
                                err: err,
                                body: parseHCICommandCompleteEvent(packet.body)
                            });
                            break;
                        default:
                            break;
                    }
                });

                function getPackets (data) {
                    if (data.length < HEADER_SIZE) {
                        return;
                    }

                    var header = data.slice(0, HEADER_SIZE);
                    var responseType = header[0];
                    var eventCode = header[1];
                    var length = header[2];
                    var body = data.slice(HEADER_SIZE, HEADER_SIZE+length);
                    if (body.length < length) {
                        return;
                    }

                    let packet = {
                        responseType,
                        eventCode,
                        length,
                        body
                    };

                    // remove headers once we've verified we have the entire
                    // packet
                    data.splice(0, HEADER_SIZE + length);
                    packets.push(packet);

                    getPackets(data);
                }

                function parseHCILEExtEvent (dataPacket) {
                    let opcode = parseInt(
                        concatenateDigits([dataPacket[1], dataPacket[0]]),
                        16
                    );
                    let retVal = {status: 0, type: '', prop: {}};
                    switch (opcode) {
                        case 0x0600: // GAP_DeviceInitDone
                            retVal.status = dataPacket[2];
                            retVal.type = 'GAP_DeviceInitDone';
                            retVal.prop = {deviceAddress: arrayToDeviceAddr(dataPacket.slice(3, 9).reverse())};
                            break;
                        case 0x041D: // HCIExt_ResetSystemDone
                            retVal.status = dataPacket[2];
                            retVal.type = 'HCIExt_ResetSystemDone';
                            break;
                        case 0x067f: // GAP_HCI_ExtentionCommandStatus
                            retVal.status = dataPacket[2];
                            retVal.type = 'GAP_HCI_ExtentionCommandStatus';
                            break;
                        default:
                            retVal.status = dataPacket[2];
                            retVal.type = 'default';
                            break;
                    }
                    return retVal;
                }

                function parseHCICommandCompleteEvent (dataPacket) {
                    return null;
                }

                function concatenateDigits (digits) {
                    let s = '';
                    for (let i of digits) {
                        let hex = i.toString(16).toUpperCase();
                        if (hex.length < 2) {
                            s = s + ('0' + hex);
                        } else {
                            s = s + hex;
                        }
                    }
                    return s;
                }
                return returnArr;
            };

            const USB_NAME = 'Texas Instruments';
            const COMMANDS = {
                HCI_ReadBdAddr: [0x01, 0x09, 0x10, 0x00],
                HCI_EXT_ChipReset: [0x01, 0x1D, 0xFC, 0x01, 0x00],
                HCI_EXT_SoftReset: [0x01, 0x1D, 0xFC, 0x01, 0x01],
                GAP_DeviceInit: [0x01, 0x00, 0xFE, 0x26, 0x09, 0x0A, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
                    0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
                    0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x01, 0x00, 0x00, 0x00],
                GATT_AddService: [0x01, 0xFC, 0xFD, 0x04, 0x00, 0x28, 0x02, 0x00],
                GATT_AddAttribute: [0x01, 0xFE, 0xFD, 0x11, 0x90, 0x08, 0xB4, 0xC3, 0xFF, 0xB7, 0x43, 0x9A, 0x7B, 0x4D, 0x90, 0xD8, 0x35, 0xF3, 0x88, 0x00, 0x03],
                GAP_UpdateAdvLucova: [0x01, 0x07, 0xFE, 0x17, 0x01, 0x15, 0x02, 0x01, 0x02, 0x11, 0x06, 0x90, 0x08, 0xB4, 0xC3, 0xFF, 0xB7, 0x43, 0x9A, 0x7B,
                    0x4D, 0x90, 0xD8, 0x35, 0xF3, 0x88, 0x00],
                GAP_UpdateAdvIbeacon: [
                    0x01,
                    0x07, 0xFE, 0x20, 0x01, 0x1E, // 6
                    0x02, // Number of bytes that follow in first AD structure
                    0x01, // Flags AD type
                    0x1A, // Flags value 0x1A = 000011010
                    0x1A, // Number of bytes that follow in second (and last) AD structure
                    0xFF, // Manufacturer specific data AD type
                    0x4C, 0x00, // Company identifier code (0x004C == Apple)
                    0x02, // Byte 0 of iBeacon advertisement indicator
                    0x15, // Byte 1 of iBeacon advertisement indicator
                    // proximity uuid
                    0x00, 0x88, 0xF3, 0x35, 0xD8, 0x90, 0x4D, 0x7B, 0x9A, 0x43, 0xB7, 0xFF, 0xC3, 0xB4, 0x08, 0x90, // 16
                    0x01, 0x00, // ibeacon major
                    0x01, 0x00, // ibeacon minor
                    // tx power calibration
                    0xC5],
                GAP_MakeDiscoverable: [0x01, 0x06, 0xFE, 0x0A, 0x02, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x07, 0x00],
                ATT_FindInfoReq: [0x01, 0x04, 0xFD, 0x06, 0xFE, 0xFF, 0x01, 0x00, 0xFF, 0xFF],
                GAP_DeviceDiscovery: [0x01, 0x04, 0xFE, 0x03, 0x03, 0x01, 0x00]
            };

            // Create a ble scan response packet
            var createScanRspPacket = function (bleId) {
                let bytes = stringToAsciiByteArray(bleId);
                return [0x01, 0x07, 0xfe, 0x0c, 0x00, 0x0a, 0x09, 0x09,
                    bytes[0], bytes[1], bytes[2], bytes[3], bytes[4],
                    bytes[5], bytes[6], bytes[7]];
            };

            // Create a ble ibeacon packet
            var createIbeaconAdvPacket = function (ibeacon) {
                let outBuf = COMMANDS.GAP_UpdateAdvIbeacon;

                let uuid = hexToBytes(ibeacon.uuid.replace(/-/g, ''));
                let major = hexToBytes(int16ToHex(ibeacon.major));
                let minor = hexToBytes(int16ToHex(ibeacon.minor));

                let n = 0;
                for (let i = 15; i <= 30; i++) {
                    outBuf[i] = uuid[n];
                    n++;
                }
                outBuf[31] = major[0];
                outBuf[32] = major[1];
                outBuf[33] = minor[0];
                outBuf[34] = minor[1];
                return outBuf;
            };

            var arrayToDeviceAddr = function (val) {
                for (var addr = '', c = 0; c < val.length; c++) {
                    addr += val[c].toString(16).toUpperCase();
                    if (c < val.length - 1) addr += ':';
                }
                return addr;
            };

            // Convert int16 to hex
            var int16ToHex = function (val) {
                val &= 0xFFFF;
                var hex = val.toString(16).toUpperCase();
                return ('0000' + hex).slice(-4);
            };

            // Convert a hex string to a byte array
            var hexToBytes = function (hex) {
                for (var bytes = [], c = 0; c < hex.length; c += 2) {
                    bytes.push(parseInt(hex.substr(c, 2), 16));
                }
                return bytes;
            };

            // Convert String to ASCII bytes
            var stringToAsciiByteArray = function (str) {
                var bytes = [];
                for (var i = 0; i < str.length; ++i) {
                    var charCode = str.charCodeAt(i);
                    if (charCode > 0xFF) { // char > 1 byte since charCodeAt returns the UTF-16 value
                        throw new Error('Character ' + String.fromCharCode(charCode) + ' can\'t be represented by a US-ASCII byte.');
                    }
                    bytes.push(charCode);
                }
                return bytes;
            };

            var helper = {
                parseResponse,
                COMMANDS,
                USB_NAME,
                createScanRspPacket,
                int16ToHex,
                hexToBytes,
                createIbeaconAdvPacket,
                stringToAsciiByteArray
            };

            return helper;
        }
    ]);
}
