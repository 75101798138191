'use strict';

const angular = require('angular');
const freshideasResourcesLookup = require('../resources/lookup.js').default;
const AppConstants = require('../../common/freshideas/app-constants.js');
const moment = require('moment');

export default angular.module('freshideas.home.tiles', [freshideasResourcesLookup.name]).controller('NavbarCtrl', [
    '$state',
    '$stateParams',
    '$scope',
    '$rootScope',
    '$timeout',
    '$modal',
    '$interval',
    'Utils',
    'Security',
    'PosStatusService',
    'Platform',
    'Pure',
    'Users',
    'PosAlertService',
    'PosUsersService',
    'CompanyAttributesService',
    'CommonOfflineCache',
    'CurrentSession',
    '$transitions',
    'LucovaWebSocket',
    'SmbPosService',
    'KioskService',
    'MevBoxService',
    'SystemService',
    'AudioService',
    'HelpService',
    'SystemServerService',
    'CashierShift',
    'EnvConfig',
    function (
        $state,
        $stateParams,
        $scope,
        $rootScope,
        $timeout,
        $modal,
        $interval,
        Utils,
        Security,
        PosStatusService,
        Platform,
        Pure,
        Users,
        PosAlertService,
        PosUsersService,
        CompanyAttributesService,
        CommonOfflineCache,
        CurrentSession,
        $transitions,
        LucovaWebSocket,
        SmbPosService,
        KioskService,
        MevBoxService,
        SystemService,
        AudioService,
        HelpService,
        SystemServerService,
        CashierShift,
        EnvConfig) {

        $scope.isLogoHidden =
            ($scope.printPendingOrders && $scope.printPendingOrders.length > 0) ||
            ($scope.patrons && $scope.patrons.preorder && $scope.patrons.preorder.length > 0) ||
            ($scope.isMevBoxCompany && $scope.mevboxStatus !== 1) ||
            $scope.lowConnectivity || $scope.offline;

        $scope.setUserCountry = async () => {
            if (!angular.isDefined($scope.user)) {
                $scope.user = await Security.getUser();
            }

            // set user's country
            let usrCountryRaw = await $scope.user.company.businessAddress.country;
            if (usrCountryRaw) {
                if (usrCountryRaw.includes('Canada')) {
                    $scope.userCountry = 'CAD';
                } else {
                    $scope.userCountry = 'USD';
                }
            } else {
                // there are a couple companies with null country; these are older US campuses
                $scope.userCountry = 'USD';
            }
        };

        const WHATS_NEW_MODAL_DELAY = 1000; // ms

        // this is used to indicate which mevbox modal state
        // to display.
        const MEVBOX_ERROR_TYPE = Object.freeze({
            'SRM_SETUP': 'srm-setup',
            'SRM_RECONNECT_BYPASS_MODE_OFF': 'srm-connect',
            'SRM_RECONNECT_BYPASS_MODE_ON': 'srm-bypassed'
        });

        $scope.isMevBoxCompany = CompanyAttributesService.hasMevBox();
        $scope.mevboxStatus = {};

        // we initialize an interval to check mevbox status every 20 seconds.
        var mevboxStatusInterval;
        const initializeMevboxStatusInterval = () => {
            mevboxStatusInterval = $interval(async () => {
                $scope.mevboxStatus = await MevBoxService.getStatus();
            }, 20000);
        };
        $scope.navIsOpen = false;
        $scope.isKiosk = KioskService.isKiosk();
        $scope.isKioskNavOpen = false;

        $scope.setIsKioskNavOpen = function (param) {
            $scope.isKioskNavOpen = param;
        };

        $scope.openKioskNav = function (param) {
            var requestedPermission = 'pos';
            var params = {
                callback: function () {
                    $scope.setIsKioskNavOpen(param);
                },
                errorCallback: function (error) {
                    if (error) {
                        var exception = error.exception || {};
                        if (exception.appCode === 412) {
                            PosAlertService.showAlertByName('pincode-fail', {}, true);
                        } else {
                            PosAlertService.showAlertByName('oops-general', {
                                message: exception.message || ''
                            }, true);
                        }
                    }
                },
                requestedPermission: requestedPermission,
                message: 'smb.pos.kiosk.nav-bar.title',
                verifyAllUserPinsForCompany: true
            };

            $scope.$emit('PincodeAuthentication:Required', params);
        };

        $scope.openNav = (param) => {
            $scope.navIsOpen = param;
        };
        $scope.state = $state;
        if (KioskService.isKiosk()) {
            $scope.keyBuffer = [];

            // keylogger to watch for logout command
            var logKey = function (e) {
                $scope.keyBuffer.push = function (...args) {
                    if (this.length >= 3) {
                        this.shift();
                    }
                    return Array.prototype.push.apply(this, args);
                };
                $scope.keyBuffer.push(e.code);
                if ($scope.keyBuffer.length === 3 &&
                    $scope.keyBuffer[0] === 'ControlLeft' &&
                    $scope.keyBuffer[1] === 'KeyC' &&
                    $scope.keyBuffer[2] === 'KeyL') {
                    $scope.keyBuffer = [];
                    $scope.toLogout();
                }
            };
            document.addEventListener('keydown', logKey);
        }

        // This is used for verifying leaving smb-pos
        // When pos confirms with this event, transition to destination
        var unbindTransitionConfirmListener =
            $rootScope.$on('pos::transition::confirm', function (event, destination) {
                $scope.changeView(destination, true);
            });

        var unbindSetupCompanyLoaded = $rootScope.$on('setup::companyLoaded', function (event, args) {
            Security.loadUser();
            $scope.user = Security.getUser();
        });

        $scope.isAltLogoExit = false;
        const executeExitAnime = async () => {
            $scope.isAltLogoExit = true;
        };

        $scope.isAltLogoVisible = false;
        let intervalAltLogo;
        // Once started, this function will run in a loop
        // to alternate between nown logo and alt logo
        // until stopped by calling this function with true value
        const showAlternateLogo = function (toStop) {
            const intervalSwitch = 10000; // milliseconds between each call
            const intervalExit = 1900; // milliseconds between each call
            const numRepeat = 0; // num times to repeat before stopping; 0 = dont stop
            const checkModelDirty = true; // false if to skips model dirty checking (default true)

            if (angular.isDefined(intervalAltLogo)) {
                if (toStop) {
                    if ($scope.isAltLogoVisible === true) {
                        $interval.cancel(intervalAltLogo);
                        intervalAltLogo = undefined;
                        $scope.isAltLogoVisible = true;
                        executeExitAnime().then( () => {
                            $scope.isAltLogoExit = false;
                            $scope.isAltLogoVisible = false;
                        });
                    } else {
                        $scope.isAltLogoVisible = false;
                        $interval.cancel(intervalAltLogo);
                        intervalAltLogo = undefined;
                    }
                    return false;
                } else {
                    return $scope.isAltLogoVisible;
                }
            } else if (toStop) {
                if ($scope.isAltLogoVisible === true) {
                    executeExitAnime().then(() => {
                        $interval( () => {
                            $scope.isAltLogoVisible = false;
                            $scope.isAltLogoExit = false;
                        }, intervalExit, 1);
                    });
                } else {
                    $scope.isAltLogoVisible = false;
                    $interval.cancel(intervalAltLogo);
                    intervalAltLogo = undefined;
                }
                return false;
            } else {
                intervalAltLogo = $interval( () => {
                    if ($scope.isAltLogoVisible === true) {
                        executeExitAnime().then(() => {
                            $interval( () => {
                                $scope.isAltLogoVisible = false;
                                $scope.isAltLogoExit = false;
                            }, intervalExit, 1);
                        });
                    } else {
                        $scope.isAltLogoVisible = true;
                    }
                    return !$scope.isAltLogoVisible;
                }, intervalSwitch, numRepeat, checkModelDirty);
            }
        };

        let intervalCheckBday;
        // This function will run in a loop indefinitely
        // at interval of 1 day unless stopped by calling this function with true value
        const checkDailyIfCountryBday = function (toStop) {
            const interval = 86400000; // milliseconds between each call
            const numRepeat = 0; // num times to repeat before stopping; 0 = dont stop
            const checkModelDirty = true; // false if to skips model dirty checking (default true)
            const executeCheck = () => {
                let today = moment().startOf('day');
                let curYear = today.get('year').toString();
                let holidayStart;
                let holidayEnd;
                if ($scope.userCountry && $scope.userCountry === 'CAD') {
                    holidayStart = moment(curYear.concat('-07-01'), 'YYYY-MM-DD').startOf('day');
                    holidayEnd = moment(curYear.concat('-07-02'), 'YYYY-MM-DD').endOf('day');
                } else if ($scope.userCountry && $scope.userCountry === 'USD') {
                    holidayStart = moment(curYear.concat('-07-04'), 'YYYY-MM-DD').startOf('day');
                    holidayEnd = moment(curYear.concat('-07-05'), 'YYYY-MM-DD').endOf('day');
                } else {
                    holidayStart = undefined;
                    holidayEnd = undefined;
                }
                let isHoliday = today.isSameOrAfter(holidayStart, 'date') && today.isSameOrBefore(holidayEnd, 'date');

                if (isHoliday) {
                    // start altLogo visibility
                    showAlternateLogo(false);
                } else {
                    // stop altLogo visibility
                    showAlternateLogo(true);
                }
            };

            // main check
            if (angular.isDefined(intervalAltLogo)) {
                if (toStop) {
                    $interval.cancel(intervalCheckBday);
                    intervalCheckBday = undefined;
                } else {
                    executeCheck();
                    return;
                }
            } else {
                // run a first check
                executeCheck();
                // then setup daily check
                intervalCheckBday = $interval(executeCheck, interval, numRepeat, checkModelDirty);
            }
        };

        // This function is called from DOM when user click's logo
        //   to enable/disable alt logo
        $scope.onAltLogoClick = function () {
            if ($scope.isAltLogoVisible === true) {
                showAlternateLogo(true);
            } else {
                $scope.isAltLogoVisible = true;
            }
        };

        $scope.$on('$destroy', function (event) {
            $scope.destroying = true;
            modalOpenListener();
            modalCloseListener();

            // Cancel active scope intervals
            $interval.cancel(mevboxStatusInterval);

            // Unbind rootScope listeners
            unbindTransitionConfirmListener();
            unbindSetupCompanyLoaded();

            // stop intervals
            checkDailyIfCountryBday(true);
            showAlternateLogo(true);
        });

        $scope.jumpTo = function (pageNum) {
            $state.go('freshideas.posStart', {
                page: pageNum
            });
        };
        $scope.shiftStarted = PosStatusService.shiftStarted;
        $scope.showPosNavigations = false;
        $scope.$watch(function () {
            return PosStatusService.shiftStarted;
        }, function (value) {
            $scope.shiftStarted = value;
        });

        var checkShowPosNavigations = function (toState) {
            toState = toState || $state.current;

            if (toState.name === 'freshideas.posStart') {
                $scope.showPosNavigations = true;
            } else {
                $scope.showPosNavigations = false;
            }
        };
        checkShowPosNavigations();

        var deregisterStateChangeSuccessListener = $transitions.onSuccess({}, function () {
            checkShowPosNavigations();
        });
        $scope.$on('$destroy', function () {
            deregisterStateChangeSuccessListener();
        });

        $scope.kdsEnabled = CompanyAttributesService.hasKDSEnabled();
        $scope.hideCustomers = CompanyAttributesService.toHideCustomersPage();
        $scope.toHideProducts = function () {
            var sessionCompany = CurrentSession.getCompany();
            if (sessionCompany.companyId !== sessionCompany.organization.rootCompanyId) {
                return CompanyAttributesService.toHideProductsPage();
            }

            return false;
        };
        var isElectron = Platform.isElectron();

        $scope.$watch(function () {
            return Security.getUser().company.attributes.kds_enabled;
        }, function (value) {
            $scope.kdsEnabled = Security.getUser().company.attributes.kds_enabled === 'true';
        });

        var navBtns = [{
                'url': 'pos',
                'icon': 'lnr-store',
                'translate': 'general.pos',
                'permission': 'pos&&pos:start_end_shift',
                'position': 1,
                'onlyOnline': true,
                'companyTypes': ['CAMPUS'],
                'disableIfElectron': false,
                'cypressId': 'fiitHomeBtn'
            },
            {
                'url': 'smb-pos',
                'icon': 'lnr-store',
                'translate': 'general.smbPos',
                'permission': 'pos&&pos:start_end_shift',
                'position': 1,
                'onlyOnline': false,
                'companyTypes': ['SMB'],
                'disableIfElectron': false,
                'cypressId': 'nownHomeBtn'
            },
            {
                'url': 'reports',
                'icon': 'lnr-pie-chart',
                'translate': 'general.reports',
                'permission': 'reports',
                'position': 2,
                'onlyOnline': true,
                'companyTypes': ['CAMPUS', 'SMB'],
                'disableIfElectron': false,
                'cypressId': 'reportsBtn'
            },
            {
                'url': 'meals',
                'icon': 'lnr-store',
                'translate': 'general.mealPlans',
                'permission': 'meals',
                'position': 3,
                'onlyOnline': true,
                'companyTypes': ['CAMPUS'],
                'disableIfElectron': false,
                'cypressId': 'fiitMealPlanBtn'
            },
            {
                'url': 'products',
                'icon': 'lnr-map',
                'translate': 'general.menuInventory',
                'permission': 'inventory',
                'disabled': $scope.toHideProducts(),
                'position': 4,
                'onlyOnline': true,
                'companyTypes': ['CAMPUS', 'SMB'],
                'disableIfElectron': true,
                'cypressId': 'nownProductsBtn'
            },
            {
                'url': 'locations',
                'icon': 'lnr-store',
                'translate': 'general.locations',
                'permission': 'locations',
                'position': 5,
                'onlyOnline': true,
                'companyTypes': ['CAMPUS'],
                'disableIfElectron': false,
                'cypressId': 'fiitLocationsBtn'
            },
            {
                'url': 'refunds',
                'icon': 'lnr-book',
                'translate': 'general.refunds',
                'permission': 'pos&&pos:refunds',
                'position': 6,
                'onlyOnline': true,
                'companyTypes': ['SMB'],
                'disableIfElectron': false,
                'cypressId': 'refundsBtn'
            },
            {
                'url': 'kds',
                'icon': 'lnr-tablet',
                'translate': 'general.kds',
                'permission': 'kds',
                'disabled': !$scope.kdsEnabled,
                'position': 8,
                'onlyOnline': true,
                'companyTypes': ['SMB'],
                'provider': true,
                'showOnSetup': true,
                'disableIfElectron': false,
                'cypressId': 'nownKdsBtn'
            },
            {
                'url': 'customers',
                'icon': 'lnr-users',
                'translate': 'general.patrons',
                'permission': 'users_patrons:patrons||users_patrons:patrons:view',
                'disabled': $scope.hideCustomers,
                'position': 9,
                'onlyOnline': true,
                'companyTypes': ['CAMPUS', 'SMB'],
                'disableIfElectron': false,
                'cypressId': 'patronsBtn'
            },
            {
                'url': 'settings',
                'icon': 'lnr-cog',
                'translate': 'general.settings',
                'permission': 'settings||users_patrons:users',
                'position': 10,
                'onlyOnline': true,
                'companyTypes': ['CAMPUS'],
                'disableIfElectron': false,
                'cypressId': 'fiitSettingsBtn'
            },
            {
                'url': 'setup',
                'icon': 'lnr-cog',
                'translate': 'general.setup',
                'permission': 'settings',
                'position': 11,
                'onlyOnline': true,
                'companyTypes': ['SMB'],
                'provider': true,
                'showOnSetup': true,
                'disableIfElectron': false,
                'cypressId': 'nownSetupBtn'
            }
            /* ,
            {
                'url': 'logout',
                'icon': 'lnr-exit',
                'translate': 'general.logout',
                'permission': 'logout',
                'position': 12,
                'onlyOnline': true,
                'companyTypes': ['CAMPUS', 'SMB'],
                'provider': true,
                'showOnSetup': true,
                'disableIfElectron': false
            } */
        ];
        Security.loadRolePermissions().then(function () {
            $scope.navBtns = _.filter(navBtns, (navBtn) => {
                const result = isNavBtnVisible(navBtn);

                // this will allow cashiers to access settings if other__allow_station_edit is toggled on
                if (navBtn['url'] === 'setup' && !result) {
                    return allowStationEdit();
                }
                return result;
            });
        });

        $scope.toLogout = function () {
            var isManagerOverrideRequired = CompanyAttributesService.enablePinOnLogout() &&
                Security.isManagerOverrideRequired('logout');
            var params = {};
            params.requestedPermission = 'logout';
            params.callback = function (pinResponse) {
                $scope.logout();
            };
            params.errorCallback = function (error) {
                var message = '';
                if (error) {
                    var exception = error.exception || {};
                    if (exception.appCode === 412) {
                        message = 'general.error.cashier-discout-fail-invalid-manager-pin.msg';
                    } else {
                        message = exception.message || '';
                    }
                    PosAlertService.showAlertByName('cashier-discout-fail', {
                        message: message,
                        title: 'general.error.logout.failed.ttl'
                    });
                }
            };
            params.verifyAllUserPinsForCompany = true;

            if (isManagerOverrideRequired) {
                $scope.$emit('PincodeAuthentication:Required', params);
            } else {
                params.callback();
            }
        };

        $scope.openCashierOptions = function () {
            $scope.$emit('kioskNavBar:openCashierOptions', {});
        };

        var isNavBtnVisible = function (navBtn) {
            var doesUserHavePermission = Security.hasPermissionForUser(navBtn.permission);
            var isMatchingCompanyType = $scope.matchCompanyType(navBtn.companyTypes);
            var isProviderAccount = (!Security.isProvider() || navBtn.provider);
            var isSetupComplete = $scope.user.company.setupComplete || navBtn.showOnSetup;
            var disabledBecauseOfElectron = navBtn.disableIfElectron && isElectron;

            return !!(doesUserHavePermission &&
                isMatchingCompanyType &&
                isProviderAccount &&
                isSetupComplete &&
                !disabledBecauseOfElectron);
        };

        // Different workflow for the support button since it is a popup
        // and not a full page change like the others
        $scope.isSupportButtonVisible = () => {
            return Security.hasPermissionForUser('support');
        };

        $scope.security = Security;
        $scope.changeView = function (view, override = false) {
            // If on smb-pos with a started shift, then verify with smb-pos controllers to see
            // if we can change views
            if ($state.current.name === 'freshideas.smb-pos' && !override &&
                PosStatusService.shiftStarted) {
                $rootScope.$emit('pos::transition::request', view);
                return;
            }
            var params = {};
            switch (view) {
                case 'users':
                    $state.go('freshideas.users');
                    break;
                case 'customers':
                    $state.current.name === 'freshideas.patrons' ?
                        $state.reload() : $state.go('freshideas.patrons');
                    break;
                case 'meals':
                    $state.go('freshideas.meals');
                    break;
                case 'menuInventory':
                    $state.go('freshideas.menuInventory');
                    break;
                case 'products':
                    HelpService.setQueryText('products');

                    params.requestedPermission = 'inventory';
                    params.callback = function (pinResponse) {
                        if (CompanyAttributesService.hasMenuV2Enabled()) {
                            $state.go('freshideas.products3');
                        } else {
                            $state.go('freshideas.products2');
                        }
                    };
                    params.errorCallback = function (error) {
                        if (error) {
                            var exception = error.exception || {};
                            if (exception.appCode === 412) {
                                PosAlertService.showAlertByName('manager-pin-invalid', {
                                    title: 'general.error.tiles.products.manager-pin.ttl'
                                });
                            } else {
                                PosAlertService.showAlertByName('manager-pin-invalid', {
                                    title: 'general.error.tiles.products.manager-pin.ttl',
                                    message: exception.message || ''
                                });
                            }
                        }
                    };
                    params.verifyAllUserPinsForCompany = true;
                    params.forceCheckIfManagerLogin = true;
                    params.message = 'general.popup.manager-pin.ttl';
                    $scope.$emit('PincodeAuthentication:Required', params);
                    break;
                case 'locations':
                    $state.go('freshideas.locations');
                    break;
                case 'refunds':
                    $state.go('freshideas.refunds');
                    break;
                case 'settings':
                    $state.go('freshideas.settings');
                    break;
                case 'setup':
                    params.requestedPermission = 'settings';
                    params.callback = function (pinResponse) {
                        if ($state.current.name.includes('freshideas.setup')) {
                            $state.reload();
                        } else {
                            $state.go('freshideas.setup');
                        }
                    };
                    params.errorCallback = function (error) {
                        if (error) {
                            var exception = error.exception || {};
                            if (exception.appCode === 412) {
                                PosAlertService.showAlertByName('manager-pin-invalid', {
                                    title: 'general.error.tiles.setup.manager-pin.ttl'
                                });
                            } else {
                                PosAlertService.showAlertByName('manager-pin-invalid', {
                                    title: 'general.error.tiles.setup.manager-pin.ttl',
                                    message: exception.message || ''
                                });
                            }
                        }
                    };
                    params.verifyAllUserPinsForCompany = true;
                    params.forceCheckIfManagerLogin = true;
                    params.message = 'general.popup.manager-pin.ttl';
                    $scope.$emit('PincodeAuthentication:Required', params);
                    break;
                case 'kds':
                    if ($state.current.name !== 'freshideas.kds') {
                        $state.go('freshideas.kds', {view: PosUsersService.getLastKDSView()});
                    }
                    break;
                case 'pos':
                    HelpService.setQueryText('');
                    $state.go('freshideas.posStart', {
                        page: 1
                    });
                    break;
                case 'smb-pos':
                    HelpService.setQueryText('');
                    // Can't reload in offline mode or it will go back to Open Shift page.
                    if (!$scope.offline && $state.current.name === 'freshideas.smb-pos') {
                        $state.transitionTo($state.current, $stateParams, {
                            reload: true,
                            inherit: false,
                            notify: true
                        });
                    } else {
                        $state.go('freshideas.smb-pos');
                    }
                    break;
                case 'reports':
                    $state.go('freshideas.reports');
                    break;
                case 'transaction-report':
                    $state.go('freshideas.reports.transaction');
                    break;
                case 'logout':
                    $scope.logout();
                    break;
                default:
                    $state.go('freshideas.home');
            }
        };
        const init = function () {
            $scope.pagingOptions = {
                pageSize: 20,
                pageSizes: Utils.buildDefaultPageSizes()
            };

            $scope.patrons = SmbPosService.patrons;

            $scope.user = Security.getUser();
            $scope.realTimeRefreshInterval = 2000;
            // Refresh interval for real time line charts
            $scope.modalOpen = false;

            // set user's country
            $scope.setUserCountry().then( () => {
                // start holiday check
                checkDailyIfCountryBday(false);
            });

            // only initialize the mevbox interval if necessary.
            if ($scope.isMevBoxCompany) {
                initializeMevboxStatusInterval();
            }

            if (EnvConfig.env !== 'production') return;

            CashierShift.whatsNew().$promise
            .then((articleData) => {
                if (!articleData || !articleData.id) return;

                const lastViewedChangelog = JSON.parse(localStorage.getItem('lastViewedChangelog') || '{}');
                const alreadyViewed =
                    !!lastViewedChangelog.id
                    && lastViewedChangelog.id == articleData.id
                    && new Date(lastViewedChangelog.viewedAt).valueOf() > new Date(articleData.updatedAt).valueOf();

                if (alreadyViewed) return;

                articleData.viewedAt = new Date();
                localStorage.setItem('lastViewedChangelog', JSON.stringify(articleData));

                $timeout(() => $rootScope.$emit('showWhatsNew', articleData.publicUrl), WHATS_NEW_MODAL_DELAY);
            }).catch(() => {
                // NOOP
            });
        };

        $scope.printPendingOrders = [];
        // Returns all pending preorders (haven't been printed yet)
        // Also used for receiving new mobile orders
        $scope.getPrintPendingOrders = function () {
            var printPendingOrders = _.filter(LucovaWebSocket.getMobileOrderUsers(), (user) => {
                var preorder = user.preorder;
                preorder = _.filter(preorder, (order) => {
                    return order.status === 'print_pending';
                });
                if (preorder.length) {
                    return user;
                }
            });

            $scope.printPendingOrders = printPendingOrders;
            return printPendingOrders;
        };

        var processLucovaUsers = function (patrons) {
            var processedPatrons = [];

            _.each(patrons, (patron) => {
                var newPatron = {
                    lucovaUser: null
                };

                newPatron.lucovaUser = patron;
                processedPatrons.push(newPatron);
            });

            return processedPatrons;
        };

        $scope.openMobileOrderModal = function () {
            $modal.open({
                templateUrl: 'common/modals/modal.mobileOrder.patron.tpl.html',
                animation: false,
                backdrop: 'static',
                controller: 'MobileOrderPatronModalCtrl',
                windowClass: 'mobile-order-screen',
                keyboard: false,
                resolve: {
                    activeIndex: function () {
                        return 0;
                    },
                    mobileOrderPatrons: function () {
                        var newMobileOrderPatrons;
                        if ($scope.shiftStarted) {
                            newMobileOrderPatrons = _.filter($scope.patrons.preorder, (patron) => {
                                var preorder = patron.lucovaUser.preorder;
                                preorder = _.filter(preorder, (order) => {
                                    return order.status === 'print_pending';
                                });
                                if (preorder.length) {
                                    return patron;
                                }
                            });
                        } else {
                            newMobileOrderPatrons = processLucovaUsers($scope.printPendingOrders);
                        }

                        return newMobileOrderPatrons;
                    },
                    modalType: function () {
                        return 'NEW';
                    },
                    activeUserPreorderId: function () {
                        return 'NEW';
                    }
                }
            });
        };

        $scope.matchCompanyType = function (targetCompanyTypes) {
            var userCompanyType = $scope.user.company.companyType;
            if (!userCompanyType || userCompanyType === 'UNKNOWN') {
                userCompanyType = 'CAMPUS';
            }

            return targetCompanyTypes.indexOf(userCompanyType) > -1;
        };

        $scope.showHelp = function (action) {
        };

        $scope.showTutorial = function (action) {
            PosStatusService.setIsTutorial(true);
        };

        $scope.offline = PosStatusService.isOffline();
        $scope.lowConnectivity = PosStatusService.lowConnectivity();

        $scope.$watch(function () {
            return PosStatusService.isOffline();
        }, function (value) {
            $scope.offline = value;
        });
        $scope.$watch(function () {
            return PosStatusService.lowConnectivity();
        }, function (value) {
            $scope.lowConnectivity = value;
        });
        $scope.$watch(function () {
            return PosStatusService.offlineShiftInQueue();
        }, function (offlineShiftInQueue) {
            if (!offlineShiftInQueue) {
                $scope.processingOfflineShift = false;
            } else {
                $scope.processingOfflineShift = true;
            }
        });

        /**
         * Toggle refresh interval so real-time widgets stop when modal pops
         */
        var modalOpenListener = $rootScope.$on('freshideas_modal_open', function () {
            $scope.modalOpen = true;
            $scope.realTimeRefreshInterval = 0;
        });
        /**
         * Toggle refresh interval so real-time widgets start when modal closes
         */
        var modalCloseListener = $rootScope.$on('freshideas_modal_close', function () {
            $scope.modalOpen = false;
            $scope.realTimeRefreshInterval = 2000;
        });

        $scope.openTimeTracking = function (event) {
            event.stopPropagation();
            var modalInstance = $modal.open({
                templateUrl: 'pos/smb/templates/pos.time-tracking.tpl.html',
                animation: false,
                backdrop: true,
                controller: 'SmbPosTimeTrackingCtrl',
                windowClass: 'smb-pos smb-pos-popup',
                keyboard: false
            });

            modalInstance.result.finally(refreshClockedInUsers);
        };

        $scope.clockedIn = PosUsersService.getClockedInUsers;
        const refreshClockedInUsers = function () {
            if (!$scope.offline) {
                Users.getClockedInUsers().$promise.then(function (response) {
                    PosUsersService.setClockedInUsers(response);
                    CommonOfflineCache.saveClockedInUsers(response);
                });
            } else {
                CommonOfflineCache.getClockedInUsers().then(function (response) {
                    PosUsersService.setClockedInUsers(response);
                });
            }
        };

        $scope.showOfflineAlert = function () {
            PosAlertService.showAlertByName('offline');
        };

        $scope.setMevBoxStatus = async () => {
            $scope.mevboxStatus = await MevBoxService.getStatus();
        };

        $scope.showMevboxModal = (type) => {
            PosAlertService.showAlertByName(type, {
                modalCallback: function () {
                    // mevbox has been setup, but has issues with the connection and bypass mode has been turned on.
                    if (type === MEVBOX_ERROR_TYPE.SRM_RECONNECT_BYPASS_MODE_ON) {
                        MevBoxService.setMevBoxBypass(false);
                        $scope.setMevBoxStatus();
                    }
                },
                dismissModalCallback: function () {
                    // we give the cashier the option to enter bypass srm mode
                    // if srm connection has an issue.
                    // this is intentially where the 'cancel' button usually is,
                    // as we should be discouraging bypass mode.
                    if (type === MEVBOX_ERROR_TYPE.SRM_RECONNECT_BYPASS_MODE_OFF) {
                        MevBoxService.setMevBoxBypass(true);
                        $scope.setMevBoxStatus();
                    }
                }
            });
        };

        $scope.canceledMobileOrdersQueue = [];

        const allowStationEdit = () => {
            return Security.getUser().company.attributes.other__allow_station_edit === 'true';
        };

        const playErrorSound = () => {
            if (Security.getUser().company.attributes.other__pos_alert_sounds === 'true') {
                AudioService.errorSound();
            }
        };

        const findUserByPreorderId = (_id) => {
            return _.find(LucovaWebSocket.getMobileOrderUsers(), (user) => {
                return _.find(user.preorder, {
                    '_id': _id
                });
            });
        };

        const wasPreorderAcknowledged = (_id) => {
            const user = findUserByPreorderId(_id);
            const preorder = _.find(user.preorder, {
                '_id': _id
            });
            return _.find(preorder.status_history, {
                'status': 'acknowledged'
            });
        };

        const openOrderCancelledModal = (_id) => {
            const user = findUserByPreorderId(_id);
            const name = user.full_name + '\'s';
            PosAlertService.showAlertByName('general-error', {
                title: name + ' order was cancelled',
                message: 'There was an issue with ' + name + ' mobile order and it had to be cancelled. Payment has not been collected.'
            });
        };

        const onMobileOrderCancelled = (event, payload) => {
            const _id = payload._id;
            // alert only if the order was NOT cancelled by a cashier (due to an unexpected reason such as payment declining)
            const validReason = payload.cancel_reason_code !== AppConstants.mobileOrderCancelReasonCode.CANCELLED_BY_CASHIER;
            if (validReason && wasPreorderAcknowledged(_id)) {
                if (SystemService.canShowMessage) {
                    openOrderCancelledModal(_id);
                    playErrorSound();
                } else {
                    $scope.canceledMobileOrdersQueue.push(_id);
                }
            }
        };

        // we use a queue in case multiple orders get cancelled in the middle of a transaction.
        const processCanceledMobileOrdersQueue = () => {
            if (SystemService.canShowMessage && $scope.canceledMobileOrdersQueue.length > 0) {
                playErrorSound();
                while ($scope.canceledMobileOrdersQueue.length > 0) {
                    openOrderCancelledModal($scope.canceledMobileOrdersQueue.pop());
                }
            }
        };

        const showKioskNavBar = function (event, data) {
            let openNavBar = (data.enableNavBar === true) ? true : false;

            if (openNavBar) {
                $scope.openKioskNav(openNavBar);
            } else {
                $scope.setIsKioskNavOpen(openNavBar);
            }
        };

        const unbindCancelled = $rootScope.$on('pos.mobileOrder.cancelled', onMobileOrderCancelled);
        const unbindCanShow = $rootScope.$on('system.canShowMessage', processCanceledMobileOrdersQueue);
        const unbindShowKioskNavBar = $rootScope.$on('kioskNavbar:open', showKioskNavBar);

        $scope.$on('$destroy', () => {
            unbindCancelled();
            unbindCanShow();
            unbindShowKioskNavBar();
        });

        $scope.openRefreshModal = function () {
            var modalInstance = $modal.open({
                templateUrl: 'setup/modals/wizardRefreshConfirmation.tpl.html',
                animation: false,
                backdrop: 'static',
                controller: 'SetupConfirmationModalCtrl',
                windowClass: 'setup_confirm_modal',
                keyboard: false,
                resolve: {
                    modalType: function () {
                        return 'CONFIRM_EMPLOYEE_DEACTIVATE';
                    }
                }
            });

            // Handle the promise returned by the modal instance
            modalInstance.result.then(function (response) {
                // If the user confirms, execute the refresh action
                SystemServerService.refreshTerminal();
            });
        };

        $scope.organizationLanguages = SmbPosService.data.organizationLanguages;

        $scope.openLanguageSelectionModal = function (event) {
            event.preventDefault();
            event.stopPropagation();

            $modal.open({
                templateUrl: 'pos/smb/templates/kiosk.pos.order.language.selection.tpl.html',
                animation: false,
                backdrop: true,
                controller: 'KioskLanguageSelectionCtrl',
                windowClass: 'modal-60',
                keyboard: false,
                resolve: {}
            });
        };

        init();
    }
]);
