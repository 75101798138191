
module.exports = function (freshideasProducts) {
    freshideasProducts.controller('GiftCardController', [
        '$scope',
        '$modal',
        '$modalInstance',
        '$timeout',
        'Lucova',
        'CashierShift',
        'PrintService',
        'SmbPosService',
        'CurrentSession',
        'PosAlertService',
        'SharedDataService',
        'KioskService',
        'Platform',
        'BridgedPromise',
        function ($scope,
            $modal,
            $modalInstance,
            $timeout,
            Lucova,
            CashierShift,
            PrintService,
            SmbPosService,
            CurrentSession,
            PosAlertService,
            SharedDataService,
            KioskService,
            Platform,
            BridgedPromise) {

            $scope.hasIosScanner = false;
            $scope.page = 0;

            if (Platform.isIosWebkit()) {
                BridgedPromise.dispatch('hasQRScanner', {}).then(({hasQRScanner}) => {
                    $scope.hasIosScanner = !!hasQRScanner;
                }).catch((error) => {
                    console.error('IOS container does not support qr scanner. Disabling feature');
                });
            }

            $scope.initIosScanner = () => {
                if (!$scope.hasIosScanner) {
                    return;
                }
                window.webkit.messageHandlers.bridge.postMessage({'type': 'initBarcodeScanner'});
            };

            // Navigation
            $scope.close = function () {
                $modalInstance.close();
            };

            $scope.switchPage = function (page) {
                $scope.page = page;
            };

            $scope.getGiftCardMaxCents = function () {
                var company = CurrentSession.getCompany();
                return SharedDataService.getGiftCardBalanceLimit(company.baseCurrencyCode);
            };

            $scope.switchBack = function () {
                if ($scope.giftCard && $scope.giftCard.id) {
                    if ($scope.page === 2) {
                        $scope.page = 1;
                    } else {
                        reset();
                        $scope.page = 0;
                    }
                } else {
                    reset();
                    $scope.page = 0;
                }
            };

            // Page 0
            // Scan Gift Card
            // See: giftCardKeypressDetector directive @ freshideas-angular-directives.js
            $scope.giftCard = {
                code: '',
                amountToReload: 0,
                subtype: ''
            };

            $scope.toggleGCCode = function (isGCCodeVisible = false) {
                let timer;

                if (!isGCCodeVisible) {
                    var requestedPermission = 'pos';
                    var params = {
                        callback: function () {
                            $scope.giftCard.isGCCodeVisible = !isGCCodeVisible;

                            if (!timer) {
                                timer = $timeout(function () {
                                    $scope.giftCard.isGCCodeVisible = !!isGCCodeVisible;
                                }, 3000);
                            }
                        },
                        errorCallback: function (error) {
                            if (error) {
                                var exception = error.exception || {};
                                if (exception.appCode === 412) {
                                    PosAlertService.showAlertByName('pincode-fail', {}, true);
                                } else {
                                    PosAlertService.showAlertByName('oops-general', {
                                        message: exception.message || ''
                                    }, true);
                                }
                            }
                        },
                        requestedPermission: requestedPermission,
                        message: 'smb.pos.giftcard.code.reveal.description',
                        verifyAllUserPinsForCompany: true
                    };

                    $scope.$emit('PincodeAuthentication:Required', params);
                }
            };

            var reset = function () {
                $scope.giftCard.code = '';
            };

            var isProcessing = false;
            $scope.selectGiftCard = function (code) {
                $scope.processGiftCard(code);
            };


            $scope.reprintActivationCode = function (item) {
                PrintService.printGiftCardPurchaseStub(item, SmbPosService.shift.posStation.posPrinters);
            };

            $scope.openIdCheckModal = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modal.id.check.tpl.html',
                    animation: false,
                    backdrop: 'static',
                    controller: 'PatronIdCheckModalCtrl',
                    windowClass: 'id-check-modal',
                    keyboard: false,
                    resolve: {
                        idChecked: function () {
                            return $scope.idChecked;
                        },
                        selectedPatron: function () {
                            return $scope.selectedPatron;
                        }
                    }
                });

                modalInstance.result.then(function (resolved) {
                    $scope.idChecked = resolved.idChecked;

                    if (!$scope.idChecked) {
                        $modalInstance.close();
                    }
                });
            };

            var fetchGiftCard = function (cardNumber) {
                if (!cardNumber) {
                    isProcessing = false;
                    return;
                }

                var params = {
                    currencyId: CurrentSession.getCompany().baseCurrencyId,
                    cashierShiftId: SmbPosService.shift.cashierShiftId
                };

                // digital gift cards should be in the following format:
                // e.g. 'gc:12345:1a2b3c45d6789e12f3g123456'
                // where the first set of numbers (12345) is the gift card id in nown,
                // and the second set of numbers (1a2b3c45d6789e12f3g123456) is the gift card id in the payments server
                if (cardNumber.indexOf('gc:') !== -1) {
                    $scope.isDigitalGiftCard = true;
                    // search by id if digital gift card
                    params.giftCardId = cardNumber.slice(3, cardNumber.indexOf(':', 3));
                    params.code = null;
                } else {
                    if (cardNumber.length > 25) {
                        isProcessing = false;
                        return;
                    }
                    // search by cardNumber if physical gift card
                    params.id = null;
                    params.code = cardNumber;
                }

                CashierShift.lookupGiftCard(params).$promise.then(function (response) {
                    isProcessing = false;

                    // if response contains an id, it is an existing giftcard
                    // fetch the info necessary, and show id check modal if it is also a digital gift card
                    // if response does not contain an id, a new gift card is being purchased
                    // and we need to set its code and balance

                    if (response.id) {

                        // if it is a digital gift card,
                        // fetch giftcard information from payments server
                        if ($scope.isDigitalGiftCard) {
                            let index = cardNumber.indexOf(':', 3);
                            if (Lucova.isConnected()) {
                                Lucova.manager().getGiftCardById({
                                    id: cardNumber.slice(index + 1)
                                }, function (response) {
                                    // payments server may return success = false
                                    // if gift card id cannot be found
                                    if (response.success) {
                                        $scope.selectedPatron = {
                                            email: response.card.recipient_email,
                                            fullName: response.card.recipient_name
                                        };

                                        if (!KioskService.isKiosk()) {
                                            // setting idChecked to false by default.
                                            // if cashier selects 'back' in the patron loyalty screen,
                                            // id would need to be rechecked due to possibility that they scan another giftcard
                                            $scope.idChecked = false;
                                            $scope.openIdCheckModal();
                                        } else {
                                            $scope.error = true;
                                        }
                                    } else {
                                        PosAlertService.showAlertByName('general', {
                                            title: 'general.error.no-digital-gift-card.ttl',
                                            message: 'general.error.no-digital-gift-card.desc'
                                        });
                                        $modalInstance.dismiss();
                                        return;
                                    }
                                });
                            }
                        }

                        // Gift card found, display current balance
                        $scope.giftCardCurrentBalance = response.currentBalanceCents / 100;
                        $scope.giftCard = response;
                        $scope.giftCard.subtype = 'giftcard.reload';
                        $scope.giftCard.isGCCodeVisible = false;
                        $scope.switchPage(1);
                    } else {
                        $scope.giftCard.code = cardNumber;
                        $scope.giftCardCurrentBalance = 0;
                        $scope.giftCard.subtype = 'giftcard.create';
                        $scope.switchPage(2);
                    }

                }, function (error) {
                    isProcessing = false;
                    var message = 'general.error.giftcard.lookup.msg';
                    if (error && error.data && error.data.exception && error.data.exception.appCode == 444) {
                        message = 'general.error.giftcard.noexchangerate.msg';
                    }

                    PosAlertService.showAlertByName('manager-pin-invalid', {
                        title: 'general.error.giftcard.lookup.ttl',
                        message: message
                    });

                    delete $scope.giftCard.code;
                });
            };

            $scope.processGiftCard = function (code) {
                if (!code || isProcessing || $scope.page != 0) { // undefined/null/(empty string)
                    return;
                }
                isProcessing = true;
                SmbPosService.fetchLucovaPatron(code).then((patrons) => {
                    try {
                        let patron = patrons[0];
                        let lucovaUser = patron.lucovaUser;
                        let giftCardNumber = (lucovaUser.nown && lucovaUser.nown.nown_gift_nums) ? lucovaUser.nown.nown_gift_nums[0] : null;
                        if (giftCardNumber) {
                            fetchGiftCard(giftCardNumber);
                        } else {
                            fetchGiftCard(code);
                        }
                    } catch (error) {
                        fetchGiftCard(code);
                    }
                }).catch((error) => {
                    fetchGiftCard(code);
                });
            };

            $scope.reloadCard = function () {
                $scope.switchPage(2);
            };

            // Page 2
            $scope.selectAmount = function (amount) {
                // Reset if already selected
                if ($scope.giftCard.amountToReload === amount) {
                    $scope.giftCard.amountToReload = 0;
                    return;
                }

                $scope.giftCard.amountToReload = amount;
            };

            $scope.reloadGiftCardToCart = function () {
                $modalInstance.close($scope.giftCard);
            };
        }]);
};
