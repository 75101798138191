'use strict';

export function errorUtilService (freshideas) {
    freshideas.factory('ErrorUtil', [
        function () {

            var errorUtil = {};

            errorUtil.parseError = function (error) {
                if (angular.isDefined(error.data.errors[0].businessResult)) {
                    if (angular.isDefined(error.data.errors[0].businessResult.messageArgs) && error.data.errors[0].businessResult.messageArgs !== null) {
                        var messageArgs;
                        if (error.data.errors[0].businessResult.messageArgs.length > 1) {
                            messageArgs = {
                                nameOne: error.data.errors[0].businessResult.messageArgs[0],
                                nameTwo: error.data.errors[0].businessResult.messageArgs[1],
                                nameThree: error.data.errors[0].businessResult.messageArgs[2] ? error.data.errors[0].businessResult.messageArgs[2] : 'undefined'
                            };
                        } else {
                            messageArgs = {name: error.data.errors[0].businessResult.messageArgs[0]};
                        }
                        return {
                            key: error.data.errors[0].businessResult.message,
                            args: angular.isDefined(messageArgs) ? messageArgs : 'undefined'
                        };
                    } else {
                        return error.data.errors[0].businessResult.message;
                    }
                } else if (angular.isDefined(error.data.errors[0].message)) {
                    return error.data.errors[0].message;
                } else if (angular.isDefined(error.data.errors[0].messageKey)) {
                    return error.data.errors[0].messageKey;
                } else {
                    return error.data.errors[0];
                }
            };

            return errorUtil;
        }
    ]);
}
