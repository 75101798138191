'use strict';

/* eslint-disable max-len, comma-dangle, key-spacing, object-curly-spacing */

const angular = require('angular');
const ngResources = require('angular-resource');

export default angular.module('freshideas.resources.shopify', [ngResources])
    .service('Shopify', ['$resource', 'EnvConfig', function ($resource, EnvConfig) {
        return $resource(EnvConfig.host + '/freshideas/web/shopify', {}, {
            'getInstallationUrl' : {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/shopify/installation-url'
            },
            'getStores' : {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/shopify/stores',
                isArray: true
            },
            'getProducts' : {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/shopify/products',
            },
            'updateProducts' : {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/shopify/products',
            },
        });
    }]);
