'use strict';


module.exports = function (freshideasPosInventoryV2) {
    freshideasPosInventoryV2.controller('InventoryV2ItemStorageScanCtrl', [
        '$scope',
        'InventoryV2Service',
        'item',
        function ($scope, InventoryV2Service, item) {
            $scope.item = angular.copy(item);
            $scope.onItemLocationScanned = function (storageLocation) {
                // TODO: this will depend on what the barcode value is on each label - this can be either
                // - id: this will require a network request to fetch the storage location name for UI display
                // - name: no changes needed
                $scope.item.storageLocation = storageLocation;
                $scope.$digest();
            };

            $scope.save = function () {
                var locationArr = [
                                    $scope.item.storageLocationText,
                                    $scope.item.storageBinText || '',
                                    $scope.item.storageShelfText || ''
                                ];

                $scope.item.storageLocation = locationArr.join(',');
                $scope.$close($scope.item);
            };

            $scope.init = function () {
                var itemStorage = $scope.item.storageLocation;

                if (itemStorage) {
                    var itemStorageArr = itemStorage.split(',');
                    $scope.item.storageLocationText = itemStorageArr[0];
                    $scope.item.storageBinText = itemStorageArr[1];
                    $scope.item.storageShelfText = itemStorageArr[2];
                }
            };

            $scope.init();
        }
    ]);
};
