'use strict';

module.exports = function (posModule) {
    posModule.controller('MealErrorCtrl', [
        '$scope',
        '$modal',
        '$modalInstance',
        '$translate',
        'servicePeriodName',
        'quickCharge',
        'Security',
        'Utils',
        'Patrons',
        'notificationTranslationHelper',
        'PosAlertService',
        'ErrorUtil',
        'title',
        'patron_Id',
        'revealTenderModal',
        function ($scope, $modal, $modalInstance, $translate, servicePeriodName, quickCharge, Security, Utils, Patrons, notificationTranslationHelper, PosAlertService, ErrorUtil, title, patronId, revealTenderModal) {

            $scope.servicePeriodName = servicePeriodName;
            $scope.quickCharge = quickCharge;

            $scope.title = title;
            $scope.patron_Id = patronId;
            $scope.revealTenderModal = revealTenderModal;

            $scope.patronPagingOptions = {
                // initializing the paging for pagination
                currentRowNumber: 0,
                currentPage: 1,
                pageSize: 10,
                startRecord: 0,
                endRecord: 10,
                pageSizeOptions: Utils.buildDefaultPageSizes()
            };
            // Pulling the patron transaction log from the database
            $scope.viewPatronLog = function () {
                $scope.isPatrons = false;
                $scope.isLog = true;
                $scope.transactionSearch = {patronDetailId: 0};
                $scope.selectedPatronId = Security.getUser().companyId;
                $scope.transactionSearch.patronDetailId = $scope.patron_Id;
                $scope.transactionSearch.offset = Utils.serverSidePagingRowNumber($scope.patronPagingOptions.currentPage, $scope.patronPagingOptions.pageSize);
                $scope.transactionSearch.limit = $scope.patronPagingOptions.pageSize;
                Patrons.getPatronHistory($scope.transactionSearch, function (response) {
                    $scope.transactions = response;
                    $scope.patronPagingOptions.totalItems = Utils.serverSideTotalItems(response.length, $scope.patronPagingOptions.currentPage, $scope.patronPagingOptions.pageSize);
                    $scope.patronPagingOptions.endRecord = Utils.serverSideEndRecord(response.length, $scope.patronPagingOptions.currentPage, $scope.patronPagingOptions.pageSize);
                    var modalInstance = $modal.open({
                        templateUrl: 'common/modals/modalCashierShiftViewTransactionDetails.tpl.html',
                        controller: 'viewCashierShiftTransactionDetailsCtrl',
                        windowClass: 'modal-90',
                        animation: false,
                        resolve: {
                            transactions: function () {
                                return $scope.transactions;
                            },
                            title: function () {
                                return $scope.title;
                            }
                        },
                        backdrop: 'static'
                    });
                    modalInstance.result.then();
                }, function (error) {
                    PosAlertService.showError('error', ErrorUtil.parseError(error));
                });
            };
        }
    ]);
};
