'use strict';

/* global Hammer */

const moment = require('moment');

module.exports = function (freshideasSetup) {

    var sanitizeHour = function (hour) {
        var interval = 0.25;
        var numOfIntevals = 1 / interval; // 4

        var result = Math.round(hour * numOfIntevals) / numOfIntevals;
        return Math.min(Math.max(result, 0), 30);
    };

    freshideasSetup.directive('scheduler', [
        '$timeout',
        'ConfirmModalService',
        function ($timeout, ConfirmModalService) {
            return {
                scope: {
                    days: '=schedule',
                    formatHours: '&',
                    updateDay: '&',
                },
                templateUrl: 'setup/directives/scheduler.tpl.html',
                controller: ['$scope', function ($scope) {
                    $scope.config = {
                        days: [1, 2, 3, 4, 5, 6, 7]
                    };

                    $scope.onHourUpdate = function (day, index, newValue, save, callback) {
                        var hours = $scope.days[day].hours;

                        if (newValue) {
                            if (newValue.start <= newValue.end) {
                                hours[index] = hours[index] || {};
                                hours[index].start = newValue.start;
                                hours[index].end = newValue.end;
                                $scope.days[day].hours = mergeOverlappingHours(hours);
                            }
                            $scope.updateDay()($scope.days[day], !!save);
                        } else {
                            // delete
                            // $scope.deleteHour(day, index, callback);
                        }

                    };

                    $scope.deleteHour = function (day, index, callback) {
                        var hours = $scope.days[day].hours;

                        ConfirmModalService.openConfirmModal(
                            'setupWizard.merchant.hour.delete',
                            'setupWizard.merchant.hour.delete.confirmation',
                            undefined, // `clickAction` callback
                            {
                                dayName: moment().isoWeekday(day).format('dddd')
                            },
                            undefined, // `scope`
                            true // `allowCancel`
                        ).then(function () {
                            confirmDeleteHour(day, hours, index);
                            $scope.updateDay()($scope.days[day], true);
                            callback(true);
                        }).catch(function () {
                            callback(false);
                        });
                    };
                    var confirmDeleteHour = function (day, hours, index) {
                        hours.splice(index, 1);
                    };

                    var mergeOverlappingHours = function (hours) {
                        if (hours.length <= 1) {
                            return hours;
                        }

                        var newHours = [];

                        hours = _.sortBy(hours, function (hour) {
                            return hour.start;
                        });
                        for (var index of Object.keys(hours)) {
                            var current = hours[index];
                            var last = newHours[newHours.length - 1];
                            if (last) {
                                if (last.end >= current.start) {
                                    last.end = Math.max(last.end, current.end);
                                } else {
                                    newHours.push(current);
                                }
                            } else {
                                newHours.push(current);
                            }
                        }

                        return newHours;
                    };

                    $scope.canAddHour = function (day) {
                        var dailySchedule = $scope.days[day] || {};
                        var hours = dailySchedule.hours || [];

                        // for now, allow only 1 hour-range per day
                        return hours.length === 0;
                    };
                    $scope.addHour = function (day, newValue) {
                        $scope.days[day].hours = $scope.days[day].hours || [];
                        var newIndex = $scope.days[day].hours.length;

                        $scope.onHourUpdate(day, newIndex, newValue, true);
                    };

                    this.toggleServiceDay = function (day, callback) {
                        let dailyScheduleCopy = angular.copy($scope.days[day]);
                        let toggleValue = !(dailyScheduleCopy.isEnabled);
                        dailyScheduleCopy.isEnabled = toggleValue;

                        ConfirmModalService.openConfirmModal(
                            toggleValue ? 'setupWizard.merchant.hour.enable' : 'setupWizard.merchant.hour.disable',
                            toggleValue ? 'setupWizard.merchant.hour.enable.confirmation' : 'setupWizard.merchant.hour.disable.confirmation',
                            undefined, // `clickAction` callback
                            {
                                dayName: moment().isoWeekday(day).format('dddd')
                            },
                            undefined, // `scope`
                            true // `allowCancel`
                        ).then(function () {
                            $scope.updateDay()(dailyScheduleCopy, true);
                            callback(toggleValue);
                        }).catch(function () {
                            callback($scope.days[day].isEnabled);
                        });
                    };

                    this.daysList = function () {
                        return $scope.days;
                    };

                    var init = function () {
                        // placeholder
                    };
                    init();
                }],
                restrict: 'E',
                link: function (scope, element, attrs) {
                    var isDragging = false;
                    var startX = 0;

                    $timeout(function () {
                        var periods = element.find('.scheduler__periods');
                        periods.each(function (index) {
                            var day = index + 1;

                            $(this).on('mousedown', function (e) {
                                e.preventDefault();
                                e.stopPropagation();

                                if (!scope.canAddHour(day)) {
                                    return;
                                }

                                isDragging = true;
                                startX = e.pageX;
                                var current = $(e.target);

                                var offset = e.offsetX;
                                var containerWidth = current.width();

                                var startHour = sanitizeHour(offset / containerWidth * 30.00);
                                var endHour = startHour + 4;
                                var css = {
                                    position: 'absolute',
                                    top: 0,
                                    left: startHour / 30 * 100 + '%',
                                    right: (1 - endHour / 30) * 100 + '%'
                                };

                                var elemNewPeriod = $('<div class="scheduler__period"></div>');
                                elemNewPeriod.css(css);
                                current.append(elemNewPeriod);

                                $(document).mousemove(function (e) {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    if (isDragging) {
                                        var offset = e.pageX - startX;
                                        var offsetHour = offset / containerWidth * 30.00;

                                        endHour = sanitizeHour(startHour + offsetHour);
                                        var css = {
                                            left: startHour / 30 * 100 + '%',
                                            right: (1 - endHour / 30) * 100 + '%'
                                        };
                                        elemNewPeriod.css(css);
                                    }
                                });

                                $(document).mouseup(function (e) {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    isDragging = false;
                                    elemNewPeriod.remove();

                                    scope.addHour(day, {
                                        start: startHour,
                                        end: endHour
                                    });

                                    $(document).off('mousemove');
                                    $(document).off('mouseup');
                                });
                            });

                        });
                    }, 0);

                    scope.$on('$destroy', function () {
                        element.find('.scheduler__periods').off('mousedown');
                    });
                }
            };
        }
    ]).directive('schedulerPeriod', [
        '$timeout',
        function ($timeout) {
            return {
                scope: {
                    day: '=periodDay',
                    index: '=periodDayIndex',
                    hour: '=',
                    onHourUpdate: '='
                },
                require: ['^scheduler', 'ngModel'],
                controller: ['$scope', function ($scope) {

                }],
                restrict: 'EA',
                link: function (scope, element, attrs, ctrls) {
                    let schedulerCtrl = ctrls[0];
                    var ngModelCtrl = ctrls[1];

                    var resizerHandler = function (resizerType) {
                        var isDragging = false;
                        var oldValue = {};

                        return function (e) {
                            var target = e.target;

                            if (!isDragging) {
                                $(element).find('.scheduler__period-resizers').addClass('active');
                                $(target).addClass('active');

                                isDragging = true;
                                oldValue = {
                                    start: ngModelCtrl.$viewValue.start,
                                    end: ngModelCtrl.$viewValue.end
                                };
                            }

                            var parentWidth = $(element).parent().width();
                            var offsetHour = e.deltaX / parentWidth * 30.00;

                            var newValue = oldValue;
                            if (resizerType === 0) {
                                var newStartHour = oldValue.start + offsetHour;
                                newValue = {
                                    start: sanitizeHour(newStartHour),
                                    end: sanitizeHour(oldValue.end)
                                };
                            } else if (resizerType === 1) {
                                var newEndHour = oldValue.end + offsetHour;
                                newValue = {
                                    start: sanitizeHour(oldValue.start),
                                    end: sanitizeHour(newEndHour)
                                };
                            }

                            ngModelCtrl.$setViewValue(newValue);
                            ngModelCtrl.$render();

                            scope.$apply();

                            scope.onHourUpdate(scope.day, scope.index, newValue, false);
                            if (e.isFinal) {
                                $(element).find('.scheduler__period-resizers').removeClass('active');
                                $(target).removeClass('active');

                                isDragging = false;
                                scope.onHourUpdate(scope.day, scope.index, newValue, true);
                            }
                        };
                    };

                    $timeout(function () {
                        var resizers = element.find('.scheduler__period-resizer');
                        var hmResizerStart = new Hammer(resizers[0]);
                        var hmResizerEnd = new Hammer(resizers[1]);

                        hmResizerStart.get('press').set({
                            time: 100
                        });
                        hmResizerEnd.get('press').set({
                            time: 100
                        });

                        hmResizerStart.on('press', function (e) {
                            var target = e.target;

                            $(element).find('.scheduler__period-resizers').addClass('active');
                            $(target).addClass('active');
                        });
                        hmResizerStart.on('pressup', function (e) {
                            var target = e.target;

                            $(element).find('.scheduler__period-resizers').removeClass('active');
                            $(target).removeClass('active');
                        });
                        hmResizerEnd.on('press', function (e) {
                            var target = e.target;

                            $(element).find('.scheduler__period-resizers').addClass('active');
                            $(target).addClass('active');
                        });
                        hmResizerEnd.on('pressup', function (e) {
                            var target = e.target;

                            $(element).find('.scheduler__period-resizers').removeClass('active');
                            $(target).removeClass('active');
                        });


                        hmResizerStart.on('pan', resizerHandler(0));
                        hmResizerEnd.on('pan', resizerHandler(1));

                    }, 0);

                    $timeout(function () {
                        let days = schedulerCtrl.daysList();
                        var period = element.find('.scheduler__period-body');
                        var hmPeriod = new Hammer(period[0]);
                        hmPeriod.get('pan').set({
                            direction: Hammer.DIRECTION_HORIZONTAL,
                            threshold: 0
                        });

                        var isDragging = false;
                        var oldValue = {};

                        hmPeriod.on('pan', function (e) {
                            if (!isDragging) {
                                isDragging = true;
                                oldValue = {
                                    start: ngModelCtrl.$viewValue.start,
                                    end: ngModelCtrl.$viewValue.end
                                };
                            }

                            var parentWidth = $(element).parent().width();
                            var offsetHour = e.deltaX / parentWidth * 30.00;

                            var duration = oldValue.end - oldValue.start;
                            var newStartHour = sanitizeHour(oldValue.start + offsetHour);
                            var newEndHour = sanitizeHour(oldValue.end + offsetHour);

                            if (newStartHour === 0) {
                                newEndHour = Math.max(newEndHour, newStartHour + duration);
                            }
                            if (newEndHour === 30) {
                                newStartHour = Math.min(newStartHour, newEndHour - duration);
                            }

                            var newValue = {
                                start: newStartHour,
                                end: newEndHour
                            };
                            ngModelCtrl.$setViewValue(newValue);
                            ngModelCtrl.$render();
                            scope.$apply();

                            scope.onHourUpdate(scope.day, scope.index, newValue, false);

                            if (e.isFinal) {
                                isDragging = false;
                                scope.onHourUpdate(scope.day, scope.index, newValue, true);
                            }
                        });

                        hmPeriod.on('doubletap', function (e) {
                            schedulerCtrl.toggleServiceDay(scope.day, function (toggleValue) {
                                days[scope.day].isEnabled = toggleValue;
                            });
                        });
                    }, 0);

                    ngModelCtrl.$render = function () {
                        var hour = ngModelCtrl.$viewValue;
                        var css = {
                            left: hour.start / 30 * 100 + '%',
                            right: (1 - hour.end / 30) * 100 + '%'
                        };

                        element.css(css);
                    };

                    scope.$on('$destroy', function () {
                        var resizers = element.find('.scheduler__period-resizer');
                        _.each(resizers, function (resizer) {
                            var hmResizer = new Hammer(resizer);
                            hmResizer.off('press pressup pan');
                        });

                        var period = element.find('.scheduler__period-body');
                        var hmPeriod = new Hammer(period[0]);
                        hmPeriod.off('pan doubletap');
                    });
                }
            };
        }
    ]);
};
