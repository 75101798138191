'use strict';

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosEndShiftCtrl', [
        '$scope',
        '$timeout',
        '$window',
        '$log',
        '$modal',
        '$filter',
        'SmbPosService',
        'CardTerminal',
        'CashierShift',
        'PrintService',
        'SharedDataService',
        'PosAlertService',
        'CurrentSession',
        'requestingUser',
        'logCashDrawerActivityFunction',
        'createCashDrawerOpenEntryFunction',
        'shiftData',
        'menuOptions',
        'Locations',
        'Platform',
        'CompanyAttributesService',
        function ($scope,
            $timeout,
            $window,
            $log,
            $modal,
            $filter,
            SmbPosService,
            CardTerminal,
            CashierShift,
            PrintService,
            SharedDataService,
            PosAlertService,
            CurrentSession,
            requestingUser,
            logCashDrawerActivityFunction,
            createCashDrawerOpenEntryFunction,
            shiftData,
            menuOptions,
            Locations,
            Platform,
            CompanyAttributesService) {
            // For reference:
            // $scope.checkmarks: {
            //     endingBalance: false,
            //     autoSettle: false
            // }
            $scope.currentDate = $filter('date')(Date.now(), 'MMMM dd yyyy, hh:mm a');
            $scope.checkmarks = Object.assign({}, SharedDataService.endShiftCheckboxes); // Checkmarks
            $scope.data = SharedDataService.endShiftData; // Data provided in checkmarks
            $scope.cashDrawerOpen = false;
            $scope.requestedByUser = requestingUser;
            $scope.menuOptions = menuOptions;
            $scope.shift = shiftData;
            $scope.disableCashierTipPayout = CompanyAttributesService.hasCashierTipPayoutDisabled();

            $scope.disabledStep = {
                endingBalance: false,
                autoSettle: false,
                tipsPayout: (!$scope.shift.totalTip || $scope.disableCashierTipPayout) ? true : false
            };

            $scope.errors = {
                endingBalance: false,
                endingBalanceText: false,
                autoSettle: false,
                autoSettleText: false,
                tipsPayout: false,
                tipsPayoutText: false
            };

            $scope.tipsValueToDisplay = 0;
            $scope.tipsValueExtension = '';

            // Initialize navigation to checklist
            $scope.modal = 'checklist';

            var settleTransactionButtonClicked = false;

            // Navigation
            $scope.markStepAsCompleted = function (checkmarkString = false, disableStep = false) {
                if (checkmarkString) {
                    $scope.checkmarks[checkmarkString] = true;

                    if (disableStep) {
                        $scope.disabledStep[checkmarkString] = true;
                    }
                }
                // $scope.modal = 'checklist';
            };

            $scope.openEndingBalance = function () {
                // $scope.modal = 'endingBalance';
                var modifyModal = $modal.open({
                    templateUrl: 'pos/smb/templates/pos.ending.balance.modal.tpl.html',
                    controller: 'SmbPosEndingBalanceCtrl',
                    windowClass: 'smb-pos__end-shift-ending-bal-modal',
                    animation: false,
                    resolve: {
                        shift: function () {
                            return $scope.shift;
                        }
                    },
                    backdrop: 'static'
                }, function (error) {});
                return modifyModal.result.then(function (response) {
                    $scope.shift = response;
                    $scope.saveBalance();
                }, function () {});
            };

            $scope.getLocationPrinters = function () {
                return new Promise(function (resolve, reject) {
                    Locations.getLocationPrinters({'locationId': SmbPosService.shift.locationId}, function (response) {
                        resolve(response);
                    }, function (error) {
                        if (error.status === -1 || error.status > 500) {
                            // if offline mode, assume no printers
                            resolve({
                                entries: [],
                                posStation: {}
                            });
                        } else {
                            reject();
                        }
                    });

                });
            };

            var isCardTerminalConfigured = function () {
                return new Promise(function (resolve, reject) {
                    $scope.getLocationPrinters().then(async function (printerResponse) {
                        resolve(printerResponse.posStation.cardTerminalProperties
                            && printerResponse.posStation.cardTerminalProperties.type
                            && printerResponse.posStation.cardTerminalProperties.type != 'manual');
                    });
                });
            };

            $scope.openAutoSettle = async function () {
                var isUsingCardTerminal = await isCardTerminalConfigured();

                if ($scope.disabledStep.autoSettle) {
                    return;
                }
                // $scope.modal = 'autoSettle';
                var modifyModal = $modal.open({
                    templateUrl: 'pos/smb/templates/pos.endshift.settle.transactions.tpl.html',
                    controller: 'SmbPosEndShiftSettleTransCtrl',
                    windowClass: 'smb-pos__end-shift-auto-settle-modal',
                    animation: false,
                    backdrop: 'static',
                    resolve: {
                        manualTerminal: function () {
                            return !isUsingCardTerminal;
                        }
                    }
                }, function (error) {});
                return modifyModal.result.then(function (response) {
                    $scope.autoSettle();
                }, function () {
                     $scope.markStepAsCompleted('autoSettle');
                });
            };

            $scope.openTipsPayout = function () {
                if ($scope.disabledStep.tipsPayout) {
                    return;
                }
                // $scope.modal = 'autoSettle';
                var modifyModal = $modal.open({
                    templateUrl: 'pos/smb/templates/pos.endshift.tips.payout.tpl.html',
                    controller: 'SmbPosEndShiftTipsPayoutCtrl',
                    windowClass: 'smb-pos__end-shift-auto-settle-modal',
                    animation: false,
                    backdrop: 'static',
                    resolve: {
                        shift: function () {
                            return $scope.shift;
                        }
                    }
                }, function (error) {});
                return modifyModal.result.then(function () {
                    $scope.tipsValueToDisplay = $scope.shift.totalTip;
                    $scope.tipsValueExtension = 'YES';
                    $scope.markStepAsCompleted('tipsPayout');
                }, function () {
                    $scope.tipsValueToDisplay = 0;
                    $scope.tipsValueExtension = 'NO';
                     $scope.markStepAsCompleted('tipsPayout');
                });
            };


            // $scope.openPrintEndShiftReport = function () {
            //     $scope.modal = 'printEndShiftReport';
            // };

            $scope.getNetCash = function () {
                $scope.shift.netCash = $scope.shift.cashCollected - $scope.shift.cashRefunded
                    - $scope.shift.totalPayout + $scope.shift.cashAdded;
            };

            // Balance
            $scope.getBalanceDifference = function () {
                return parseFloat($scope.shift.endingBalance)
                    - $scope.shift.netCash
                    - $scope.shift.startingBalance;
            };

            $scope.getBalanceColour = function () {
                if ($scope.getBalanceDifference() > 0.1) {
                    return 'red';
                } else if ($scope.getBalanceDifference() < -0.1) {
                    return 'red';
                } else {
                    return 'green';
                }
            };
            var endingShift = false;
            // Actions
            $scope.endShift = function () {
                if (endingShift) {
                    return;
                }
                if (!$scope.checkmarks.endingBalance || !$scope.checkmarks.autoSettle || ($scope.shift.totalTip && !$scope.checkmarks.tipsPayout && !$scope.disableCashierTipPayout)) {
                    $scope.errors.endingBalance = false;
                    $scope.errors.autoSettle = false;
                    $scope.errors.tipsPayout = false;
                    if (!$scope.$$phase) {
                        $scope.$apply();
                    }

                    $timeout(function () {
                        if (!$scope.checkmarks.endingBalance) {
                            $scope.errors.endingBalance = true;
                            $scope.errors.endingBalanceText = true;
                        }

                        if (!$scope.checkmarks.autoSettle) {
                            $scope.errors.autoSettle = true;
                            $scope.errors.autoSettleText = true;
                        }

                        if ($scope.shift.totalTip && !$scope.checkmarks.tipsPayout && !$scope.disableCashierTipPayout) {
                            $scope.errors.tipsPayout = true;
                            $scope.errors.tipsPayoutText = true;
                        }
                    }, 10);
                    return;
                }
                endingShift = true;
                SmbPosService.endShift($scope.shift.endingBalance, $scope.requestedByUser).then(function (response) {
                    // Ask if shift report should be printed
                    $scope.shift.shiftId = response.cashierShiftId;
                    SharedDataService.resetEndShiftCheckboxes();

                    // Auto open drawer and print end of shift report
                    $scope.printEndShiftReport();
                    $scope.$close();
                    $scope.openTerminalReportPrintOut();
                }).catch(function (error) {
                    endingShift = false;
                    var params = {
                        'title': 'smb.pos.shift.endShift.error.ttl',
                        'message': (error.data && error.data.error) ? error.data.error :'smb.pos.shift.endShift.error.msg',
                    };
                    PosAlertService.showAlertByName('closestation-fail', params);
                });
            };

            $scope.openTerminalReportPrintOut = function () {
                let terminalConfig = SmbPosService.shift.posStation.cardTerminalProperties;
                let terminal = CardTerminal.init(terminalConfig);
                let terminalReportPrintout = (terminalConfig.reportPrintout && terminalConfig.reportPrintout.toLowerCase() === 'true');

                if ((Platform.isElectron() || Platform.isIosWebkit())
                    && terminal
                    && terminalReportPrintout) {
                    $modal.open({
                        templateUrl: 'pos/smb/templates/pos.endshift.terminal.printout.tpl.html',
                        controller: 'EndshiftTerminalPrintOut',
                        windowClass: 'smb-pos smb-pos__end-shift-modal',
                        animation: false,
                        backdrop: 'static',
                        resolve: {
                            terminal: function () {
                                return terminal;
                            },
                        }
                    });
                }
            };

            $scope.saveBalance = function () {
                $scope.checkmarks.endingBalance = true;
                $scope.updateActualNetCash();
                $scope.markStepAsCompleted('endingBalance');
            };


            $scope.updateActualNetCash = function () {
                $scope.shift.actualNetCash = $scope.shift.endingBalance - $scope.shift.startingBalance;
            };

            $scope.updateCashOwedToTheHouse = function () {
                $scope.shift.cashOwedToTheHouse = $scope.shift.netCash - $scope.shift.totalTip;
            };


            $scope.autoSettle = function () {
                var modalInstance = undefined;
                if (settleTransactionButtonClicked) {
                    return;
                }

                settleTransactionButtonClicked = true;
                var terminal = CardTerminal.init(
                    SmbPosService.shift.posStation.cardTerminalProperties);

                var btnClickTimeout = $timeout(function () {
                    settleTransactionButtonClicked = false;
                    if (modalInstance) {
                        modalInstance.close();
                    }
                    PosAlertService.showAlertByName('auto-settle-error');
                }, 60000);

                var onError = function () {
                    $timeout.cancel(btnClickTimeout);
                    settleTransactionButtonClicked = false;
                    if (modalInstance) {
                        modalInstance.close();
                    }
                    PosAlertService.showAlertByName('auto-settle-error');
                };

                if (terminal && terminal.autoSettle) {
                    modalInstance = $modal.open({
                            templateUrl: 'common/modals/modalSettleCardTerminal.tpl.html',
                            controller: 'ScanCardTerminalModalController',
                            windowClass: 'smb-pos products2 smb-pos_terminal_settle',
                            animation: false,
                            backdrop: 'static',
                            resolve: {
                                terminal: function () {
                                    return terminal;
                                },
                            }
                        }, function (error) {
                    });

                    terminal.autoSettle().then(async function (data) {

                        if (data && data.isBalanced != undefined) {
                            $scope.$emit('cardTerminal::autoSettle', data);
                            $timeout.cancel(btnClickTimeout);

                            let result = await modalInstance.result;
                            settleTransactionButtonClicked = false;

                            if (result.isBalanced) {
                                $scope.data.autoSettle = true;
                            }

                            $scope.markStepAsCompleted('autoSettle');
                            $scope.endShift();

                            return;
                        }

                        if (modalInstance) {
                            modalInstance.close();
                        }

                        $scope.data.autoSettle = true;
                        settleTransactionButtonClicked = false;
                        $timeout.cancel(btnClickTimeout);
                        $scope.markStepAsCompleted('autoSettle', true);
                        $scope.endShift();

                    }).catch(function () {
                        // Display error modal
                        onError();
                    });
                } else {
                    onError();
                }
            };

            $scope.printEndShiftReport = function () {
                SmbPosService.openDrawer();
                SmbPosService.emailAndPrintEndOfShiftReport(
                    $scope.shift.shiftId,
                    $scope.shift.posStation
                );
            };

            $scope.getNetCash();
            $scope.updateCashOwedToTheHouse();

            $scope.openCashDrawer = function () {
                var cashDrawerOpenReason = {
                    description: 'Counting Cash For Closing Station'
                };

                var cashDrawerOpenEntry = createCashDrawerOpenEntryFunction(cashDrawerOpenReason,
                        $scope.requestedByUser, SmbPosService.shift.cashierShiftId, $scope.menuOptions[2].type);

                    var callBackSuccess = function (reportResponse) {
                        SmbPosService.loadStatus();
                        $scope.checkmarks.openCashDrawer = true;
                    };
                    var errorBackSuccess = function (error) {
                        SmbPosService.loadStatus();
                        $log.error({
                            message: 'Error while logging Cash Drawer Activity on close station',
                            context: {
                                activity: 'logCashDrawerOpenActivity',
                                posStation: SmbPosService.shift.posStation.name,
                                location: SmbPosService.shift.location,
                                pinEntered: $scope.requestedByUser.pin,
                                user: CurrentSession.getUser()
                            }
                        });
                    };

                    SmbPosService.openDrawerAsPromise().then(function () {
                        logCashDrawerActivityFunction(cashDrawerOpenEntry, callBackSuccess, errorBackSuccess);
                    }, function () {
                        cashDrawerOpenEntry.description = '[Open Failed] ' + cashDrawerOpenEntry.description;
                        logCashDrawerActivityFunction(cashDrawerOpenEntry, callBackSuccess, errorBackSuccess);
                    });
            };
        }
    ]);
};
