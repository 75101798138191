'use strict';


module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SetupConfirmationModalCtrl', [
        '$scope',
        '$timeout',
        'modalType',
        function ($scope, $timeout, modalType) {

            $scope.modal = modalType;
        }
    ]);
};
