module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ScanForCardTerminalsController', [
        '$scope',
        '$modal',
        '$modalInstance',
        'BridgedPromise',
        'ProductsService',
        'Platform',
        'IpScanner',
        function ($scope,
            $modal,
            $modalInstance,
            BridgedPromise,
            ProductsService,
            Platform,
            IpScanner) {
            $scope.selectedCardTerminal = {
                ip: null,
                port: 5555
            };

            $scope.cardTerminalIpList = [];
            $scope.isDoneScanning = false;
            $scope.isError = false;

            $scope.close = function (cancel) {
                if ($scope.selectedCardTerminal.ip && !cancel) {
                    $modalInstance.close($scope.selectedCardTerminal);
                } else {
                    $modalInstance.close();
                }
            };

            // Send to native to check for card terminals
            var scanForCardTerminals = function () {
                var promise;
                if (Platform.isElectron()) {
                    promise = IpScanner.scan(5555);
                } else {
                    promise = BridgedPromise.dispatch('discoverCardTerminal', {
                        port: 5555
                    });
                }
                try {
                    promise.then(function (response) {
                        $scope.cardTerminalIpList = JSON.parse(response.cardTerminals);
                        $scope.isDoneScanning = true;
                    }).catch(function (error) {
                        $scope.isDoneScanning = true;
                        $scope.isError = true;
                    });
                } catch (error) {
                    $scope.isDoneScanning = true;
                    $scope.isError = true;
                }
            };
            scanForCardTerminals();

            $scope.addCardTerminal = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalAddCardTerminal.html',
                    controller: 'AddCardTerminalController',
                    windowClass: 'smb-pos products2',
                    animation: false,
                    backdrop: true
                });

                modalInstance.result.then(function (result) {
                    if (result) {
                        $scope.cardTerminalIpList.push(result.ip);
                        $scope.isError = false;
                    }
                });
            };

            $scope.selectCardTerminal = function (ipAddress) {
                if ($scope.selectedCardTerminal.ip === ipAddress) {
                    // Already selected
                    $scope.selectedCardTerminal.ip = null;
                } else {
                    $scope.selectedCardTerminal.ip = ipAddress;
                }
            };
        }]);
};

