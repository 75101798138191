'use strict';

module.exports = function (freshideasReports) {

    freshideasReports.controller('GiftCardReportSubtable', [
        '$scope',
        '$location',
        '$modal',
        'Reports',
        'Utils',
        'Lookup',
        'Export',
        'Security',
        'Platform',
        'DateRangeService',
        'PosAlertService',
        function ($scope, $location, $modal, Reports, Utils, Lookup, Export, Security, Platform, DateRangeService, PosAlertService) {
            $scope.init = function (giftCardActivity, isExport = false) {
                $scope.selectedGiftCardActivity = giftCardActivity;
                $scope.isExport = isExport;

                $scope.transactionResultSearch = {};
                if ($scope.selectedGiftCardActivity) {
                    $scope.setSortTransactionsByField('transactionId');
                }
            };

            $scope.setSortTransactionsByField = function (sortBy) {
                if ($scope.transactionResultSearch.sortBy === sortBy) {
                    $scope.transactionResultSearch.ascending = !$scope.transactionResultSearch.ascending;
                } else {
                    $scope.transactionResultSearch.sortBy = sortBy;
                    // always sort by descending by default if sortBy = transactionId when switching columns
                    $scope.transactionResultSearch.ascending = sortBy === 'transactionId' ? false : true;
                }

                var greater = $scope.transactionResultSearch.ascending ? 1 : -1;
                var less = $scope.transactionResultSearch.ascending ? -1 : 1;
                $scope.selectedGiftCardActivity.transactions.sort((a, b) => {
                    if (a[sortBy] > b[sortBy]) {
                        return greater;
                    }
                    if (a[sortBy] < b[sortBy]) {
                        return less;
                    }
                    return 0;
                });
            };

            $scope.opened = {};

            function resetTransactionsPagingOptions () {
                $scope.transactionsPagingOptions = {
                    startRecord: 0,
                    pageSize: 10,
                    currentPage: 1,
                };
            }
            resetTransactionsPagingOptions();

            $scope.transactionsPageUp = function () {
                var start = $scope.transactionsPagingOptions.startRecord;
                var newStart = start + $scope.transactionsPagingOptions.pageSize;
                if ($scope.selectedGiftCardActivity.transactions.length > newStart) {
                    $scope.transactionsPagingOptions.startRecord = newStart;
                    $scope.transactionsPagingOptions.currentPage++;
                }
            };

            $scope.transactionsPageDown = function () {
                var start = $scope.transactionsPagingOptions.startRecord;
                var newIndex = start - $scope.transactionsPagingOptions.pageSize;
                if (start > 0) {
                    if (newIndex < 0) {
                        $scope.transactionsPagingOptions.startRecord = 0;
                    } else {
                        $scope.transactionsPagingOptions.startRecord = newIndex;
                    }
                    $scope.transactionsPagingOptions.currentPage--;
                }
            };

            $scope.transactionsPagesAvailable = function () {
                var itemLen = $scope.selectedGiftCardActivity.transactions.length;
                var pageSize = $scope.transactionsPagingOptions.pageSize;
                return (itemLen > pageSize) ? Math.ceil(itemLen/pageSize) : 1;
            };

            var modalPromise;
            var unsetModal = $scope.unsetModal = function () {
                modalPromise = undefined;
            };
            var setModal = $scope.setModal = function (config) {
                if (modalPromise && !modalPromise.isPending) {
                    return Promise.reject();
                }

                var modalInstance = $modal.open(config, function (error) {
                    unsetModal();
                });
                modalPromise = modalInstance.result;

                return modalPromise;
            };

            $scope.showTransaction = (transaction) => {

                // in the case transaction id doesn't exist
                // ideally this shouldn't happen, but it's better to add this alert
                // so UI doesn't look broken.
                if (!transaction.transactionId) {
                    PosAlertService.showAlertByName('general', {
                        title: 'general.error.cannot.find.transaction.ttl',
                        message: 'general.error.cannot.find.transaction.msg'
                    });
                } else {
                    return setModal({
                        templateUrl: 'reports/reports.singleTransaction.tpl.html',
                        controller: 'SingleTransactionCtrl',
                        windowClass: 'single-transaction-modal modal-80 products2 modal--pull-right',
                        animation: true,
                        backdrop: true,
                        resolve: {
                            selectedTransaction: function () {
                                return transaction;
                            },
                            searchStartDateTime: function () {
                                return $scope.currentReportSearch.startDateTime;
                            },
                            searchEndDateTime: function () {
                                return $scope.currentReportSearch.endDateTime;
                            },
                            currencyCode: function () {
                                return 'CAD';
                            }
                        }
                    }).then(function (itemSaved) {
                        unsetModal();
                        return itemSaved;
                    }).catch(function (error) {
                        unsetModal();
                    });
                }
            };
        }
    ]);

};
