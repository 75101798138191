'use strict';

/* globals ngGridFlexibleHeightPlugin */

module.exports = function (posModule) {
    posModule.controller('PosMealPlansCtrl', [
        '$scope',
        '$modal',
        '$translate',
        'patron',
        'currentLocation',
        'CashierShift',
        'notificationTranslationHelper',
        'ErrorUtil',
        'NgGridSortService',
        'NgGridTemplateService',
        'CommonOfflineCache',
        function ($scope, $modal, $translate, patron, currentLocation, CashierShift, notificationTranslationHelper, ErrorUtil, NgGridSortService, NgGridTemplateService, CommonOfflineCache) {
            $scope.patron = patron;
            $scope.gridOptions = {
                columnDefs: [
                    {
                        field: 'active',
                        displayName: 'Active',
                        width: '72px',
                        cellTemplate: NgGridTemplateService.activityTemplate,
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        headerClass: 'text-center',
                        cellClass: 'text-center'
                    },
                    {
                        field: 'name',
                        displayName: 'mealPlan.name',
                        width: '**',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'mealsPerPeriod',
                        displayName: 'mealPlan.initialBalance',
                        width: '*',
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{COL_FIELD | number:0}}</span></div>',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'currentMealPlanBalance',
                        displayName: 'mealPlan.currentBalance',
                        width: '*',
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{COL_FIELD | number:0}}</span></div>',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'remainingServicePeriodMeals',
                        displayName: 'mealPlan.currentMealBalance',
                        width: '*',
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{COL_FIELD | number:0}}</span></div>',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'currentChargeBalance',
                        displayName: 'mealPlan.chargeBalance',
                        width: '*',
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{COL_FIELD | currency:"$"}}</span></div>',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'currentChargeBalance',
                        displayName: 'mealPlan.currentChargeBalance',
                        width: '*',
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{COL_FIELD | currency:"$"}}</span></div>',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'currentDcbBalance',
                        displayName: 'mealPlan.initialDCBBalance',
                        width: '*',
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{COL_FIELD | currency:"$"}}</span></div>',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'remainingServicePeriodDcb',
                        displayName: 'mealPlan.currentDCBBalance',
                        width: '*',
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{COL_FIELD | currency:"$"}}</span></div>',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    }
                ],
                rowTemplate: NgGridTemplateService.activityRowTemplate,
                plugins: [new ngGridFlexibleHeightPlugin({minHeight: 250})],
                showFooter: false,
                footerRowHeight: 30,
                multiSelect: false,
                showSelectionCheckbox: false,
                enableSorting: true,
                selectWithCheckboxOnly: false,
                enableHighlighting: true,
                data: 'mealPlans'
            };

            var currentLocationMealPlanIds = currentLocation.mealPlanIds || [];

            var isPatronStatusAllowed = (patron.status === 0);

            let setMealPlans = function (mealPlans) {
                var currentTime = new Date().getTime();
                _.each(mealPlans, function (mealPlan) {
                    var isMealPlanAtCurrentLocation = currentLocationMealPlanIds.indexOf(mealPlan.mealPlanId) > -1;
                    var isMealPlanEnabled = mealPlan.enabled && mealPlan.priority > -1;

                    // TODO: Need to implement logic for Meal Plans with `mealPlanCyclePeriod` of "DAILY", "WEEKLY", and "MONTHLY"
                    var isMealPlanRunning = true;
                    if (mealPlan.mealPlanCyclePeriod === 'ACADEMIC_TERM' || mealPlan.mealPlanCyclePeriod === 'CALENDAR_YEAR') {
                        isMealPlanRunning = mealPlan.mealPlanResetDateMillis > currentTime;
                    }

                    var isMealPlanActive = isPatronStatusAllowed && isMealPlanAtCurrentLocation && isMealPlanEnabled && isMealPlanRunning;
                    mealPlan.active = isMealPlanActive;
                });

                $scope.mealPlans = _.sortBy(mealPlans, function (mealPlan) {
                    var mealPlanActive = (mealPlan.active) ? 1 : 0;
                    var mealPlanName = mealPlan.name;
                    // To sort meal plans by active status (active first, inactive second) and by name (alphabetical)
                    return (1 - mealPlanActive) + ' - ' + mealPlanName;
                });
            };

            $scope.init = function () {
                CashierShift.lookupPatronMealPlans({'patronId': $scope.patron.patronId}, function (response) {
                    let mealPlans = response.entries;
                    setMealPlans(mealPlans);
                }, function (error) {
                    let patronsPromise = CommonOfflineCache.getPatronsFromCache();
                    patronsPromise.then(function (patrons) {
                        let mealPlans = CommonOfflineCache.getMealPlans($scope.patron.patronId, patrons);
                        setMealPlans(mealPlans);
                    });

                });
            };

            if (currentLocationMealPlanIds.length === 0) {

                let currentLocationPromise = CommonOfflineCache.getCurrentLocation();
                currentLocationPromise.then(function (locationFromCache) {
                    currentLocationMealPlanIds = locationFromCache.mealPlanIds;
                    $scope.init();
                });
            } else {
                $scope.init();
            }
        }
    ]);
};
