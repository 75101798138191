'use strict';

const Controller = new (require('../external/pos.controller.js'))();
const Decimal = require('decimal.js').default;
const moment = require('moment');
const AppConstants = require('../common/freshideas/app-constants.js');

module.exports = function (posModule) {
    posModule.controller('PosTenderTransactionCtrl', [
        '$scope',
        'campaignDiscount',
        'manualCoupon',
        '$cookies',
        '$translate',
        '$timeout',
        '$modal',
        '$modalInstance',
        '$log',
        '$q',
        'SharedDataService',
        'SharedFunctionService',
        'cashierShiftId',
        'locationId',
        'servicePeriodId',
        'sendToKdsAndBackend',
        'SecondaryDisplay',
        'posData',
        'patron',
        'quickCharge',
        'verified',
        'available',
        'receipt',
        'fullReceipt',
        'transactionAmount',
        'LucovaWebSocket',
        'CashierShift',
        'Locations',
        'PrintService',
        'PosStatusService',
        'PosAlertService',
        'SmbPosService',
        'notificationTranslationHelper',
        'EnvConfig',
        'Lucova',
        'OfflineTransactionService',
        'AudioService',
        'CommonOfflineCache',
        'CardTerminal',
        'Pure',
        'PromiseRetry',
        'TRANSACTION_STATUS',
        'PosBuilderService',
        'OfflineTransactionService',
        'isOffline',
        'PrintType',
        'PrintReceiptType',
        'currentOrder',
        'suspendedOrder',
        'ReceiptBuilderService',
        'initialAdjustments',
        'initialTenders',
        'applyTransactionPercentageDiscount',
        'CurrentSession',
        'cancelledItemsCount',
        'cancelledItemsAmount',
        'cancelledItemsUserId',
        'solinkCancelledItems',
        'GatewayFiit',
        'GatewayAlphaPay',
        'guestLabel',
        'GuestLabelService',
        'originalTransactionId',
        'refundTransactionForExchange',
        'modifyExistingItem',
        'removeReceiptItem',
        'modifyItemQuantity',
        'KioskService',
        'ticketPrePrint',
        'FiitMealPlanCalculationService',
        'TransactionTenderService',
        'buzzerCode',
        'TransactionService',
        'KioskModalService',
        'Platform',
        'SolinkService',
        'SystemService',
        '$rootScope',
        function (
            $scope,
            campaignDiscount,
            manualCoupon,
            $cookies,
            $translate,
            $timeout,
            $modal,
            $modalInstance,
            $log,
            $q,
            SharedDataService,
            SharedFunctionService,
            cashierShiftId,
            locationId,
            servicePeriodId,
            sendToKdsAndBackend,
            SecondaryDisplay,
            posData,
            patron,
            quickCharge,
            verified,
            available,
            receipt,
            fullReceipt,
            transactionAmount,
            LucovaWebSocket,
            CashierShift,
            Locations,
            PrintService,
            PosStatusService,
            PosAlertService,
            SmbPosService,
            notificationTranslationHelper,
            EnvConfig,
            Lucova,
            OfflineCache,
            AudioService,
            CommonOfflineCache,
            CardTerminal,
            Pure,
            PromiseRetry,
            TRANSACTION_STATUS,
            PosBuilderService,
            OfflineTransactionService,
            isOffline,
            PrintType,
            PrintReceiptType,
            currentOrder,
            suspendedOrder,
            ReceiptBuilderService,
            initialAdjustments,
            initialTenders,
            applyTransactionPercentageDiscount,
            CurrentSession,
            cancelledItemsCount,
            cancelledItemsAmount,
            cancelledItemsUserId,
            solinkCancelledItems,
            GatewayFiit,
            GatewayAlphaPay,
            guestLabel,
            GuestLabelService,
            originalTransactionId,
            refundTransactionForExchange,
            modifyExistingItem,
            removeReceiptItem,
            modifyItemQuantity,
            KioskService,
            ticketPrePrint,
            FiitMealPlanCalculationService,
            TransactionTenderService,
            buzzerCode,
            TransactionService,
            KioskModalService,
            Platform,
            SolinkService,
            SystemService,
            $rootScope
        ) {

            /**
            *** Commented By Akash Mehta on 10th Mar 2020
            *** Adding this if check so that the watchers
            *** get registered only if it is in Kiosk mode
            ***/
            if (KioskService.isKiosk()) {
                $scope.$on('kiosk::items-modified', function () {
                    $scope.init();
                });
                $scope.locationIsInOntario = false;
                var locationDetail = SharedDataService.location;
                $scope.locationIsInOntario = (locationDetail.region === 'ON');
            }

            $scope.openSupportModal = function (event) {
                event.stopPropagation();
                $scope.showSupportModal = true;
            };

            GuestLabelService.setGuestLabel(guestLabel);

            $scope.tendering = false;
            $scope.patron = patron;
            $scope.setPatron = function (p) {
                $scope.patron = p;
            };

            $scope.verified = verified;
            $scope.receipt = receipt;
            $scope.fullReceipt = fullReceipt;
            $scope.transactionAmount = transactionAmount;
            $scope.available = available;
            $scope.quickCharge = quickCharge;
            $scope.Lucova = Lucova;
            $scope.servicePeriodId = servicePeriodId;
            $scope.tenderPage = TRANSACTION_STATUS.INITIAL;
            $scope.semiIntegratedTerminal = false;
            $scope.TRANSACTION_STATUS = TRANSACTION_STATUS;
            $scope.isIosWebkit = Platform.isIosWebkit();
            $scope.isElectron = Platform.isElectron();
            var envConfig = EnvConfig;
            $scope.tenderedWithQuickCharge = false;
            $scope.isOffline = isOffline;
            $scope.buzzerCode = buzzerCode;

            $scope.fixedCampaignDiscount = {};
            $scope.totalAmountFromPosData = posData.currentOrderBalance.totalAmount;
            $scope.lastTransaction = PosStatusService.lastTransaction;
            $scope.$watch(function () {
                return PosStatusService.lastTransaction;
            }, function (value) {
                $scope.lastTransaction = value;
            });
            $scope.sendToKdsAndBackend = sendToKdsAndBackend;
            $scope.posData = posData;
            $scope.currentOrder = currentOrder;
            $scope.suspendedOrder = suspendedOrder;
            $scope.cancelledItemsCount = cancelledItemsCount;
            $scope.cancelledItemsAmount = cancelledItemsAmount;
            $scope.cancelledItemsUserId = cancelledItemsUserId;
            $scope.solinkCancelledItems = solinkCancelledItems;

            $scope.$watch(function () {
                return LucovaWebSocket.getTransactionStatus();
            }, function () {
                setTenderPage(LucovaWebSocket.getTransactionStatus());
            });

            // get uuid incoming and and generate a new one as a backup in case we don't have it
            var transactionUuid;
            if (initialAdjustments.transactionUuid) {
                transactionUuid = initialAdjustments.transactionUuid;
            } else {
                transactionUuid = Pure.generateUuid();
                // In the case of an exchange, there is no error as the UUID will not be generated prior
                // to the transaction being started (it does not open and close, it happens all at once).
                if (!refundTransactionForExchange) {
                    $log.error('Failed to get initially generated transaction UUID');
                }
            }

            $scope.transactionUuid = transactionUuid;
            var transactionStartTime = new Date();

            var currentSessionCompany = CurrentSession.getCompany();

            var checkIfDecimalQuantity = function (receiptItems) {
                try {
                    _.each(receiptItems, function (item) {
                        var decimalRegex = RegExp('^[0-9]*[.][0-9]*$');
                        if (decimalRegex.test(item.quantity)) {
                            var errorObj = {
                                message: 'Decimal Quantity found when trying to tender !',
                                context: {
                                    item: item,
                                    fullReceipt: $scope.fullReceipt
                                }
                            };
                            $log.error(errorObj);
                        }
                    });
                } catch (ex) {
                    $log.error(ex);
                }
            };

            $scope.tenderData = TransactionService.generateTenderData();

            var storeCreditData = {
                storeCredit: undefined,
                storeCreditPrinted: false // this flag is to track whether a store credit refund tx is already printed to prevent unnecesary printing
            };

            $scope.toggleSplitTender = function () {
                if ($scope.tenderData.transactionId) {
                    return;
                }

                $scope.tenderData.isSplit = !$scope.tenderData.isSplit;
            };

            $scope.addToCurrentTender = function (currentTender) {
                try {
                    TransactionService.addSelectedTender($scope.tenderData, currentTender, $scope.adjustments);
                } catch (ex) {
                    $log.error({
                        message: '[FATAL] Error while adding a tender : ',
                        context: {
                            user: (CurrentSession.getUser()) ? CurrentSession.getUser().username : '',
                            selectedTender: currentTender,
                            error: ex
                        }
                    });
                }

                $scope.balanceChange();
            };
            var removeCurrentTender = $scope.removeCurrentTender = function () {
                if (!$scope.tenderData.currentTender || $scope.tenderData.currentTender._isFixed) {
                    return;
                }

                var tenderIndex = $scope.tenderData.allTenders.indexOf($scope.tenderData.currentTender);

                if (tenderIndex > -1) {
                    $scope.tenderData.allTenders.splice(tenderIndex, 1);
                }

                var adjustmentField = $scope.tenderData.currentTender.field;
                if (!adjustmentField) {
                    return;
                }

                var currentAdjustmentAmout = new Decimal($scope.tenderData.currentTender.amount);
                var originalAdjustmentAmount = new Decimal($scope.adjustments[adjustmentField]);
                var newAdjustmentAmount = originalAdjustmentAmount.minus(currentAdjustmentAmout);
                $scope.adjustments[adjustmentField] = newAdjustmentAmount.toNearest(SharedDataService.baseDollar).toNumber();
                $scope.balanceChange();

                $scope.tenderData.currentTender = undefined;

                // if the current tender is removed, it usually means the tender screen should go back to the
                // initial state.

                // Disabled for kiosk functionaliy.
                setTenderPage(TRANSACTION_STATUS.INITIAL);
            };
            var updateCurrentTenderAmount = $scope.updateCurrentTenderAmount = function (updatedAmount) {
                if (!$scope.tenderData.currentTender) {
                    return;
                }

                try {
                    TransactionService.updateSelectedTenderAmount($scope.tenderData.allTenders, $scope.tenderData.currentTender,
                        $scope.adjustments, updatedAmount);
                } catch (error) {
                    $log.error({
                        message: '[FATAL] Error while updating tender amount: ',
                        context: {
                            user: (CurrentSession.getUser()) ? CurrentSession.getUser().username : '',
                            selectedTender: $scope.tenderData.currentTender,
                            error: error
                        }
                    });
                }

                $scope.balanceChange();
            };
            $scope.markTendersProcessed = function (tenders) {
                for (var tender of tenders) {
                    tender._isProcessed = true;
                }
            };

            // Delete suspended orders on transaction completion
            var checkAndDeleteSuspended = function () {
                if ($scope.patron.suspended) {
                    Locations.deleteSuspend({
                        locationId: $scope.suspendedOrder.locationId,
                        suspendId: $scope.suspendedOrder.suspendId
                    });
                }
            };

            const ERRORED_TENDER_PAGES = [
                TRANSACTION_STATUS.CANCELLED,
                TRANSACTION_STATUS.FAILED,
                TRANSACTION_STATUS.CARD_TERMINAL_CANCELLED,
                TRANSACTION_STATUS.CARD_TERMINAL_INIT_FAILED,
                TRANSACTION_STATUS.ALPHAPAY_CANCELLED,
                TRANSACTION_STATUS.ALPHAPAY_ERROR,
                TRANSACTION_STATUS.ALPHAPAY_TIMED_OUT
            ];

            var setTenderPage = $scope.setTenderPage = function (value, params) {
                $scope.tenderPage = value;
                params = params || {};

                // Load completedTransaction if we swapped page to 'completed'
                // Used for displaying tip amount and new total in receipt page

                delete $scope.tenderData.error;
                if ($scope.tenderPage === 'completed') {
                    $scope.completedTransaction = PosStatusService.completedTransaction;
                    if ($scope.completedTransaction && $scope.completedTransaction.tip_amount && $scope.completedTransaction.tip_amount > 0) {
                        $scope.tenderAmounts.tip_amount = $scope.completedTransaction.tip_amount;
                    }
                } else if (ERRORED_TENDER_PAGES.indexOf($scope.tenderPage) > -1) {
                    $scope.tenderData.error = params.error || {
                        code: -1,
                        message: ''
                    };

                    KioskService.disableTimeoutModal(false);
                }

                $rootScope.$broadcast('pos::tenderStatusChanged', value, params);
            };

            // tipFields should contain `full_name` (customer full name), `profile_picture` (customer profile picture),
            // `tip_amount`, and `full_amount` (total sales, without tip).
            // TODO: consider using camel case instead of snake case for these fields
            var showTipPopup = function (fullName, profilePicture, tipAmount, fullAmount) {
                tipAmount = tipAmount || 0;

                if (tipAmount <= 0 || KioskService.isKiosk()) {
                    return;
                }

                var tipFields = {
                    full_name: fullName,
                    profile_picture: profilePicture,
                    tip_amount: tipAmount,
                    full_amount: fullAmount
                };

                $modal.open({
                    templateUrl: 'common/modals/modalTips.html',
                    controller: 'TipsController',
                    windowClass: 'modal-50 smb-pos__tip-modal',
                    animation: false,
                    backdrop: true,
                    resolve: {
                        tipFields: function () {
                            return tipFields;
                        }
                    }
                });
            };

            $scope.openNumpad = function (type, initial) {
                var numType = type;
                var modalInstance = $modal.open({
                    templateUrl: 'pos/pos.numpad.tpl.html',
                    controller: 'PosNumpadCtrl',
                    animation: false,
                    windowClass: 'modal-numpad modal-fullscreen-transparent modal-right',
                    resolve: {
                        initial: function () {
                            return Math.floor(initial * 100.0);
                        },
                        type: function () {
                            return 'currency';
                        }
                    },
                    backdrop: true
                });
                modalInstance.result.then(function (amount) {
                    var amt = parseFloat(amount);

                    switch (numType) {
                        case 'cash':
                            $scope.adjustments.cashAdjustment = amt;
                            $scope.balanceChange();
                            break;
                        case 'credit':
                            $scope.adjustments.creditCardAdjustment = amt;
                            $scope.balanceChange();
                            break;
                        case 'debit':
                            $scope.adjustments.debitCardAdjustment = amt;
                            $scope.balanceChange();
                            break;
                        case 'dcb':
                            $scope.adjustments.dcbAdjustment = amt;
                            $scope.adjustDCB();
                            break;
                        case 'other':
                            $scope.adjustments.otherAdjustment = amt;
                            $scope.balanceChange();
                            break;
                        case 'tip':
                            // $scope.adjustments.tipAmount = amt;
                            $scope.tenderData.currentTender.tipAmount = amt;
                            break;
                    }
                });
            };
            $scope.getRemainingAuthChars = function () {
                var creditCardAuthCode = $scope.tenderAmounts.creditCardAuthCode || '';
                return 6 - creditCardAuthCode.length;
            };
            var amountCents = 0;
            var amountPercent = null;
            var campaignDiscountId = null;
            if (campaignDiscount !== null && campaignDiscount !== undefined) {
                if (campaignDiscount.amount_cents) {
                    amountCents = campaignDiscount.amount_cents / 100;
                }
                if (campaignDiscount.amount_percent) {
                    amountPercent = campaignDiscount.amount_percent;
                }
                if (campaignDiscount.id) {
                    campaignDiscountId = campaignDiscount.id;
                }
            }
            $scope.fixedCampaignDiscount.amount_cents = amountCents;
            $scope.fixedCampaignDiscount.amount_percent = amountPercent;
            $scope.fixedCampaignDiscount.id = campaignDiscountId;

            // keeps a reference to `adjustments` object from order screen
            // because the discount/loyalty related adjustment logic modifies
            // the `adjustments` object in the order screen
            $scope.adjustments = initialAdjustments;

            $scope.tenderAmounts = undefined;
            $scope.availableBalances = undefined;
            $scope.calculatedRemaining = 0;

            SharedDataService.locationId = SharedDataService.locationId || locationId;
            $scope.isTenderingQuickcharge = false;
            $scope.init = function () {
                $scope.availableBalances = Controller.calculateAvailableBalances($scope.available);
                var calculated = SharedFunctionService.calculateBalances($scope.receipt, $scope.available, $scope.adjustments, posData, $scope.patron);
                $scope.updateTenderAmounts(calculated);

                /**
                *** Commented By Akash Mehta on 6th August 2020
                *** The concept of initial tenders was introduced by Tony to support store credit & exchanges workflow
                ***
                *** Now this concept is being utilized by Fahrenheit as well to add certain cash cards as tender at the beginning
                *** of a transaction.
                ***/
                if (initialTenders) {
                    for (var initialTender of initialTenders) {
                        initialTender._isFixed = true;
                        var existingTender = $scope.tenderData.allTenders.find((tender) => tender.uuid == initialTender.uuid);
                        if (!existingTender) {
                            $scope.addToCurrentTender(initialTender);
                        }
                    }

                    if (initialTenders.length) {
                        $scope.tenderData.isSplit = true;
                        $scope.tenderData.canToggleSplit = false;
                    }
                }

                try {
                    checkIfDecimalQuantity($scope.tenderAmounts.receiptItems || []);
                } catch (error) {
                    $log.error(error);
                }

                $scope.adjustments.lockPrice = true;
            };
            $scope.adjustDCB = function () {
                if (angular.isDefined($scope.adjustments.dcbAdjustment) && $scope.adjustments.dcbAdjustment >= 0) {
                    var calculated = SharedFunctionService.calculateBalances($scope.receipt, $scope.available, $scope.adjustments, posData, $scope.patron);
                    $scope.updateTenderAmounts(calculated);
                }
            };
            $scope.adjustMealEquivalency = function () {
                if (angular.isDefined($scope.adjustments.mealEquivalencyAdjustment)) {
                    $scope.adjustments.mealEquivalencyAdjustment = !$scope.adjustments.mealEquivalencyAdjustment;
                    var calculated = SharedFunctionService.calculateBalances($scope.receipt, $scope.available, $scope.adjustments, posData, $scope.patron);
                    $scope.updateTenderAmounts(calculated);
                }
            };

            $scope.restoreTenderAmounts = function (tenderAmounts) {
                $scope.tenderAmounts = tenderAmounts;
            };
            $scope.updateTenderAmounts = function (calculated) {
                $scope.calculatedRemaining = SharedFunctionService.getTransactionAmount(undefined, posData);
                $scope.tenderAmounts = SharedFunctionService.populateTenderAmounts(
                    calculated.tenderAmounts,
                    manualCoupon,
                    $scope.patron);
                $scope.tenderAmounts.transactionUuid = transactionUuid;

                // keeping track and log when tender modal is open in the backend to debug for potential duplicated modal bug
                $scope.tenderAmounts._transactionStartTime = transactionStartTime;
            };
            $scope.updateLucovaPatron = function (lucovaPatron) {
                $scope.lucovaUser = lucovaPatron;
            };
            $scope.balanceChange = function () {
                var calculated = SharedFunctionService.calculateBalances($scope.receipt, $scope.available, $scope.adjustments, posData, $scope.patron);
                $scope.updateTenderAmounts(calculated);
                $scope.checkHasBalance();
            };
            // $scope.hasBalance is a flag to determine if there is still balance left to be settled when tendering
            //  a transaction. It, however, only has a 500ms delay to wait for a human input to finish typing
            //  the whole balance amount (eg. updates only when "12.50" is all enetered), rather than updating instantly
            //  while a human input is not completed yet (eg. updates right when "1" of "12.50" is entered and always results
            //  in displaying warning message)
            // $scope.hasBalance can take three values: -1 = uninitialized, 0 = no balance, 1 = has balance
            $scope.hasBalance = -1;
            var checkHasBalance;
            $scope.checkHasBalance = function () {
                $timeout.cancel(checkHasBalance);
                checkHasBalance = $timeout(function () {
                    $scope.hasBalance = ($scope.tenderAmounts.remainingBalance > 0) ? 1 : 0;
                }, 500);
            };
            $scope.isNumberKey = function (event) {
                var charCode = event.charCode;
                if (charCode > 31 && charCode !== 46 && (charCode < 48 || charCode > 57)) {
                    event.preventDefault();
                }
                return true;
            };

            $scope.getLocationPrinters = function () {
                return new Promise(function (resolve, reject) {
                    Locations.getLocationPrinters({'locationId': locationId}, function (response) {
                        resolve(response);
                    }, function (error) {
                        if (error.status === -1 || error.status > 500) {
                            // if offline mode, assume no printers
                            resolve({
                                entries: [],
                                posStation: SharedDataService.posStation
                            });
                        } else {
                            var data = error.data || {};
                            notificationTranslationHelper.notifyError(data.error, null, false);
                            reject();
                        }
                    });

                });
            };

            var openReceiptModal = function () {
                var receiptsModal = $modal.open({
                    templateUrl: 'common/modals/modalTenderReceipts.tpl.html',
                    controller: 'PosTenderReceiptsCtrl',
                    windowClass: 'modal-tender',
                    animation: false,
                    resolve: {
                        merchantOnly: function () {
                            return function () {
                                return $scope.tenderReceipt(true, PrintType.MERCHANT);
                            };
                        },
                        customerOnly: function () {
                            return function () {
                                return $scope.tenderReceipt(true, PrintType.CUSTOMER);
                            };
                        },
                        printAll: function () {
                            return function () {
                                return $scope.tenderReceipt(true, PrintType.ALL);
                            };
                        }
                    },
                    backdrop: 'static'
                });

                receiptsModal.result.then(function (tender) {

                });
            };

            $scope.back = function () {
                $modalInstance.close();
            };

            $scope.tenderWithReceipt = function () {
                openReceiptModal();
            };
            $scope.tender = function () {
                $scope.tenderReceipt(false);
            };

            var markTransactionCancelled = function (error) {
                $scope.tendering = false;
                $timeout(function () {
                    setTenderPage(TRANSACTION_STATUS.CANCELLED, {
                        error: error
                    });
                });
            };
            var failedLucovaTransaction = function (error) {
                $scope.tendering = false;
                $log.error({
                    message: 'Error while processing a lucova transaction',
                    context: {
                        error: error,
                        user: CurrentSession.getUser().username,
                    }
                });
                LucovaWebSocket.setTransactionStatus(TRANSACTION_STATUS.FAILED);
                $timeout(function () {
                    setTenderPage(TRANSACTION_STATUS.FAILED, {
                        error: error
                    });
                });
            };

            var playSuccessSound = function (tipAmount = 0) {
                // temporarily disable sounds from playing (Martin Dec 3rd, 2018)
                var toPlaySound = CurrentSession.getCompany().attributes.other__pos_alert_sounds === 'true';
                if (toPlaySound) {
                    if (tipAmount > 0 && !KioskService.isKiosk()) {
                        AudioService.tipSound();
                    } else {
                        AudioService.transactionSuccess();
                    }
                }

            };

            var cardTerminalData = {
                receiptFieldsArr: [],
                cardTerminalResponses: []
            };

            var TenderUtil = $scope.TenderUtil = {
                queueOfflineLucovaTransaction: function (lucovaUser, receipt, fullReceipt, adjustments, servicePeriodId, locationId, cashierShiftId) {
                    return OfflineTransactionService.pushLucovaTransaction({
                        username: lucovaUser.user_name,
                        receiptItems: receipt,
                        fullReceiptItems: fullReceipt,
                        adjustments: adjustments,
                        servicePeriodId: servicePeriodId,
                        locationId: locationId,
                        cashierShiftId: cashierShiftId
                    });
                },
                // purpose of this function is to let Lucova backend know
                // about each and every FIIT transaction (even non-mobile
                // transactions).  Currently this is used to handle the
                // case where a user is checked-in via bluetooth, but pays
                // with their physical card. We want them to disappear from
                // the "nearby" list.
                notifyTransactionToLucovaBackend: function (posObj, appFid, cookies) {
                    var packagedTransaction = SharedFunctionService.packageTransactionForLucova(posObj, appFid, cookies);
                    try {
                        Lucova.manager().postUserTransaction({}, packagedTransaction, function () {
                            LucovaWebSocket.getUsers();
                        });
                    } catch (e) {
                        $log.info(e);
                    }
                },
                semiIntegratedTerminal: function (printerResponse, amountToBeCharged, tenderType) {
                    // we are running in a semi-integrated environment,
                    // meaning that the cashier doesn't need to key
                    // in the card amount manually. Instead
                    // we will communicate with the card terminal,
                    // and wait for its approval.

                    // semiIntegratedTerminal is called using await, which
                    // executes outside of Angular digest loop. In order for
                    // changes to `$scope.tenderPage` to be reflected in the
                    // UI, $scope.$apply is needed;
                    $scope.$apply(function () {
                        setTenderPage(TRANSACTION_STATUS.CARD_TERMINAL_PENDING);
                    });

                    return new Promise(function (resolve, reject) {
                        $timeout(function () {
                            $scope.semiIntegratedTerminal = true;

                            var terminalProperties = printerResponse.posStation.cardTerminalProperties;
                            var terminalInterface = CardTerminal.init(terminalProperties);

                            if (terminalInterface) {
                                if (terminalInterface.isCancellableFromPos()) {
                                    $scope.tenderData.currentTender.isCancellableFromPos = true;
                                    $scope.tenderData.currentTender.cancelFromPos = terminalInterface.cancelFromPos;
                                }

                                var paymentApproved = async function (result) {
                                    result.printErrorReceipt = terminalProperties.printErrorReceipt;
                                    $scope.processingCard = false;
                                    try {
                                        // stringify terminal's response which we will store
                                        // in the backend database for audit purposes

                                        var receiptFields = result[0];
                                        var raw = result[1];
                                        let extraInfo = result[2];

                                        if (receiptFields.signatureBase64Png) {
                                            await $modal.open({
                                                template: '<img src="data:image/png;base64,{{receiptFields.signatureBase64Png}}"><div ng-click="close()">Close</div>',
                                                controller: 'CardTerminalSummaryController',
                                                windowClass: 'modal-50',
                                                backdrop: 'static',
                                                animation: false,
                                                resolve: {
                                                    receiptFields: function () {
                                                        return receiptFields;
                                                    }
                                                }
                                            }).result;
                                        }

                                        // get multi-transaction data add it to receipt data to print on same receipt
                                        if (extraInfo && extraInfo.length > 0) {
                                            extraInfo.alreadyNotified = true;
                                            extraInfo.approvedAmount = 0;

                                            for (let info of extraInfo) {
                                                let receiptData = info.receiptData;
                                                let raw = info.raw;

                                                cardTerminalData.receiptFieldsArr.push(receiptData);
                                                cardTerminalData.cardTerminalResponses.push({
                                                    cardTransactionId: receiptData.transactionId,
                                                    cardTerminalResponse: JSON.stringify(raw),
                                                    processorType: terminalProperties.type
                                                });

                                                extraInfo.approvedAmount += Number(info.receiptData.transactionAmount);
                                            }
                                        }

                                        cardTerminalData.receiptFieldsArr.push(receiptFields);
                                        cardTerminalData.cardTerminalResponses.push({
                                            cardTransactionId: receiptFields.transactionId,
                                            cardTerminalResponse: JSON.stringify(raw),
                                            processorType: terminalProperties.type
                                        });

                                        $scope.tenderAmounts.cardTerminalResponses = cardTerminalData.cardTerminalResponses;
                                        $scope.tenderAmounts.cardReceiptFields = cardTerminalData.receiptFieldsArr;

                                        var tipAmount = parseFloat(receiptFields.tipAmount);
                                        tipAmount = (isNaN(tipAmount)) ? 0 : tipAmount;

                                        $scope.tenderAmounts.tipAmount = $scope.tenderAmounts.tipAmount || 0;
                                        $scope.tenderAmounts.tipAmount += tipAmount;

                                        $scope.tenderData.currentTender.terminalResponse = JSON.stringify(raw);
                                        $scope.tenderData.currentTender.receiptFields = (receiptFields) ? [receiptFields] : [];

                                        let currentTenderTipAmount = (tipAmount > 0) ? tipAmount : $scope.tenderAmounts.tipAmount;
                                        $scope.tenderData.currentTender.tipAmount = currentTenderTipAmount;
                                        $scope.tenderData.currentTender.creditCardName = receiptFields.cardName;

                                        var approvedAmount = parseFloat(receiptFields.approvedAmount);

                                        // for globalpayment and moneris terminals
                                        // override receiptarr to print both responses
                                        // for multi-transaction and partial approvals
                                        if (extraInfo && extraInfo.length > 0) {
                                            $scope.tenderData.currentTender.receiptFields = cardTerminalData.receiptFieldsArr;
                                            approvedAmount += extraInfo.approvedAmount;
                                            approvedAmount = parseFloat(approvedAmount).toFixed(2);
                                        }

                                        approvedAmount = (isNaN(approvedAmount)) ? 0 : approvedAmount;

                                        var salesAmount = new Decimal(approvedAmount)
                                            .minus(new Decimal(currentTenderTipAmount))
                                            .toNearest(SharedDataService.baseDollar)
                                            .toNumber();
                                        if (salesAmount < 0) {
                                            salesAmount = 0;
                                            $log.error({
                                                messsage: 'approvedAmount is less than tipAmount',
                                                context: {
                                                    terminalResponse: raw
                                                }
                                            });
                                        }

                                        updateCurrentTenderAmount(salesAmount);

                                        if (receiptFields.partiallyApproved) {
                                            $scope.tenderData.isSplit = true;
                                        }

                                        if (extraInfo && extraInfo.length > 0 && !extraInfo.alreadyNotified) {
                                            PosAlertService.showAlertByName(
                                                'terminal-payment-error',
                                                {showHelpLink: true, message: extraInfo.notifyUser}
                                            );
                                        }

                                        resolve({
                                            actualAmountCharged: receiptFields.approvedAmount,
                                            partialAuthorization: receiptFields.partiallyApproved,
                                        });
                                    } catch (err) {
                                        paymentRejected({unknownError: err});
                                    }
                                };

                                var paymentRejected = function (result) {
                                    $scope.processingCard = false;
                                    $scope.tendering = false;

                                    if (!result.unknownError) {
                                        result.printErrorReceipt = (terminalProperties.printErrorReceipt === 'true');
                                        if (terminalProperties.type == 'moneriscore') {
                                            // we need to find out why certain transactions being
                                            // marked as successful by the moneris terminal are
                                            // showing as declined on the POS. This is temporary
                                            // logging so we can see what the terminal is returning
                                            // in these cases.
                                            if (result.status === '451') {
                                                $log.info(result);
                                            } else {
                                                $log.error(result);
                                            }
                                        }

                                        if (result.timedOut || result.timeout) {
                                            // {timeout: true} -> doesn't retry transaction
                                            // we don't know the result of the transaction!!! Could
                                            // have passed or failed, we need to go into a "manual" mode
                                            // where we ask the cashier if the transaction passed/failed
                                            reject(result);

                                            // return right away to make sure the currentTender is not removed
                                            // so that it can be used for terminal recovery workflow
                                            return;
                                        } else if (result.data && result.printErrorReceipt) {
                                            var receiptFields = result.data[0];
                                            var raw = result.data[1];
                                            let extraInfo = result.data[2];

                                            // globalpayment and moneris terminal include multi-transaction data
                                            if (extraInfo && extraInfo.isMulitTrx) {
                                                let receiptData = extraInfo.receiptData;
                                                let multiTrxRaw = extraInfo.multiTrxRaw;

                                                cardTerminalData.receiptFieldsArr.push(receiptData);
                                                cardTerminalData.cardTerminalResponses.push({
                                                    cardTransactionId: receiptData.transactionId,
                                                    cardTerminalResponse: JSON.stringify(multiTrxRaw),
                                                    processorType: terminalProperties.type
                                                });
                                            }

                                            cardTerminalData.receiptFieldsArr.push(receiptFields);
                                            cardTerminalData.cardTerminalResponses.push({
                                                cardTransactionId: receiptFields.transactionId,
                                                cardTerminalResponse: JSON.stringify(raw),
                                                processorType: terminalProperties.type
                                            });

                                            result.data.cardTerminalResponses = cardTerminalData.cardTerminalResponses;
                                            result.data.cardReceiptFields = cardTerminalData.receiptFieldsArr;

                                            removeCurrentTender();
                                            reject(result);
                                            return;
                                        }

                                        if (result.notifyUser) {
                                            reject(result);
                                            return;
                                        }

                                    } else {
                                        // for when an unexpected error occurs, reenable if logging is needed in the future
                                        // $log.error('Unknown payment rejection', result.unknownError);
                                    }

                                    // this payment has not gone through
                                    try {
                                        SolinkService.sendPaymentFailed($scope.transactionUuid, $scope.fullReceipt, $scope.solinkCancelledItems);
                                    } catch (err) {
                                        $log.error('Failed to send event to Solink', err);
                                    }

                                    removeCurrentTender();
                                    reject();
                                };

                                var terminalInterfaceTimer, terminalInterfaceSalePromise;

                                const terminalInterfaceTimeoutPromise = new Promise(function (resolve, reject) {
                                    terminalInterfaceTimer = $timeout(
                                        function () {
                                            if (terminalInterfaceTimer) {
                                                $timeout.cancel(terminalInterfaceTimer);
                                            }

                                            reject({timeout: true});
                                        },
                                        TRANSACTION_STATUS.DEFAULT_TERMINAL_INTERFACE_TIMEOUT
                                    );
                                });

                                if (tenderType === 'credit') {
                                    $scope.processingCard = true;
                                    terminalInterfaceSalePromise = terminalInterface.creditSale(amountToBeCharged, {transactionUuid: transactionUuid});
                                } else {
                                    terminalInterfaceSalePromise = terminalInterface.debitSale(amountToBeCharged);
                                }

                                Promise.race([
                                    terminalInterfaceSalePromise,
                                    terminalInterfaceTimeoutPromise
                                ]).then(paymentApproved, paymentRejected)
                                .catch((err) => {
                                    paymentRejected({unknownError: err});
                                });
                            } else {
                                if (KioskService.isKiosk()) {
                                    setTenderPage(TRANSACTION_STATUS.CARD_TERMINAL_INIT_FAILED);
                                    $scope.failedKioskCardTerminalInit = true;
                                } else {
                                    setTenderPage(TRANSACTION_STATUS.CARD_TERMINAL_CANCELLED);
                                }
                                if (!$scope.isElectron && !$scope.isIosWebkit) {
                                    PosAlertService.showAlertByName('terminal-init-error-browser');
                                } else {
                                    PosAlertService.showAlertByName('terminal-init-error');
                                }
                                reject({terminalInitFailed: true});
                            }

                        }, 100);
                    });
                },
                beginTransactionWorkflow: async function (posObj, printerResponse) {
                    // TODO: move FIITMPS integration to here too
                    try {
                        await TenderUtil.captureAlphaPayTender(posObj, printerResponse);
                    } catch (e) {
                        $scope.tendering = false;

                        let status = TRANSACTION_STATUS.ALPHAPAY_ERROR;
                        let errorData = e.data || {};
                        let errorMessage = errorData.errorMessage || 'smb.pos.tender.alphapay.error.description';
                        errorMessage = $translate.instant(errorMessage);

                        let errorCode = errorData.errorCode || status;

                        removeCurrentTender();

                        setTenderPage(status, {
                            error: {
                                code: errorCode,
                                message: errorMessage,
                            }
                        });

                        return {
                            initializeOnly: $scope.tenderData.isSplit,
                            promise: $q.resolve({
                                success: false
                            })
                        };
                    }

                    return TenderUtil.captureCreditDebitTransaction(posObj, printerResponse);
                },
                captureAlphaPayTender: async function (posObj, printerResponse) {
                    if ($scope.tenderData.currentTender.type === 'other'
                        && $scope.tenderData.currentTender.transactionTenderDetail
                        && $scope.tenderData.currentTender.transactionTenderDetail.otherType === 'alphapay') {
                        let orderUuid = $scope.tenderData.currentTender.uuid;

                        await TenderUtil._createAlphaPayOrder(orderUuid);
                        return await TenderUtil._monitorAlphaPayOrderStatus(orderUuid);
                    } else {
                        return $q.resolve();
                    }
                },
                _createAlphaPayOrder: async function (orderUuid) {
                    let modalInstance = $modal.open({
                        templateUrl: 'pos/smb/templates/pos.tender.alphapay.tpl.html',
                        controller: 'SmbPosTenderAlphaPayCtrl',
                        windowClass: 'smb-pos smb-pos__lucova-tender-modal',
                        animation: false,
                        backdrop: 'static',
                        keyboard: false,
                        resolve: {}
                    });

                    let authCode = '';
                    try {
                        authCode = await modalInstance.result;
                    } catch (e) {
                        return $q.reject({
                            status: TRANSACTION_STATUS.ALPHAPAY_CANCELLED
                        });
                    }

                    let decimalPrice = new Decimal($scope.tenderData.currentTender.amount).times(new Decimal(100));

                    let order = {
                        locationId: $scope.tenderAmounts.locationId,
                        posStationId: $scope.tenderAmounts.posStationId,
                        price: decimalPrice.toNearest(SharedDataService.baseCent).toNumber(),
                        currency: 'CAD',
                        authCode: authCode,
                        createdAt: moment().format(),
                        orderUuid: orderUuid
                    };

                    setTenderPage(TRANSACTION_STATUS.ALPHAPAY_PENDING);

                    let orderResponse = await PromiseRetry.start({
                        fn: function () {
                            return GatewayAlphaPay.order(order).$promise;
                        },
                        timeout: 10000,
                        maxRetry: 2
                    });

                    if (!orderResponse.success) {
                        return $q.reject({
                            status: TRANSACTION_STATUS.ALPHAPAY_ERROR,
                            data: orderResponse
                        });
                    }

                    return orderResponse;
                },
                _monitorAlphaPayOrderStatus: async function (orderUuid) {
                    try {
                        let orderStatusResponse = await PromiseRetry.start({
                            fn: function () {
                                return GatewayAlphaPay.syncOrder(orderUuid).$promise.then(function (response) {
                                    var data = response.data;
                                    let resultCode = data.result_code;

                                    let errorResponse = {
                                        timeout: false,
                                        data: response
                                    };

                                    if (!response.success) {
                                        throw errorResponse;
                                    }

                                    switch (resultCode) {
                                        case 'CREATE_FAIL':
                                        case 'CLOSED':
                                        case 'PAY_FAIL':
                                            throw errorResponse;
                                        case 'PAYING':
                                            errorResponse.timeout = true;
                                            throw errorResponse;
                                        case 'PAY_SUCCESS':
                                            $scope.tenderData.currentTender.transactionTenderDetail.otherResponse = JSON.stringify(data);
                                            return response;
                                    }
                                });
                            },
                            timeout: 5000,
                            // 3 seconds * 40 times = 2 minute wait time in total
                            maxRetry: 40,
                            interval: 3000
                        });

                        return orderStatusResponse;
                    } catch (e) {
                        if (e.timeout) {
                            return $q.reject({
                                status: TRANSACTION_STATUS.ALPHAPAY_TIMED_OUT,
                                data: e.data
                            });
                        } else {
                            return $q.reject({
                                status: TRANSACTION_STATUS.ALPHAPAY_ERROR,
                                data: e
                            });
                        }
                    }
                },
                captureCreditDebitTransaction: async function (posObj, printerResponse) {
                    var isCreditDebit = TenderUtil._isCreditDebitTender($scope.tenderData.currentTender);
                    var isUsingTerminal = TenderUtil._isCardTerminalConfigured(printerResponse);
                    var hasError = false;
                    var hasTimeout = false;
                    var terminalInitFailed = false;
                    var notifyTerminalError = false;

                    if (isCreditDebit) {
                        if (isUsingTerminal) {
                            setTenderPage(TRANSACTION_STATUS.PENDING);

                            // card terminal configuration detected. We will proceed
                            // with communicating with the terminal.
                            if (!hasError
                                && $scope.tenderData.currentTender.type === 'card'
                                && $scope.tenderData.currentTender.amount > 0) {
                                try {
                                    let chargeAmount = $scope.tenderData.currentTender.amount;

                                    await TenderUtil.semiIntegratedTerminal(printerResponse, chargeAmount, 'credit');
                                    posObj.creditCardAmount = $scope.tenderAmounts.creditCardAmount;
                                    posObj.remainingBalance = $scope.tenderAmounts.remainingBalance;
                                } catch (err) {
                                    if (err && err.timedOut) {
                                        // we are not ready to declare a failure/error just yet.
                                        // A timeout happened, so let the cashier decide whether
                                        // a success/fail happened.
                                        hasTimeout = true;
                                    } else if (err && err.terminalInitFailed && KioskService.isKiosk()) {
                                        terminalInitFailed = true;
                                        TenderUtil.notifyException(err);
                                    } else if (err && err.notifyUser) {
                                        notifyTerminalError = err;

                                        if (err.printReceipt && err.printErrorReceipt) {
                                            TenderUtil.printErrorReceipt();
                                        }

                                        TenderUtil.notifyException(err);
                                    } else if (err && err.data) {
                                        let cardTerminalResponses = err.data.cardTerminalResponses;
                                        let cardReceiptFields = err.data.cardReceiptFields;

                                        hasError = true;
                                        if (err.printReceipt && err.printErrorReceipt) {
                                            TenderUtil.printErrorReceipt(cardTerminalResponses, cardReceiptFields);
                                        }
                                    } else {
                                        hasError = true;
                                        /* Note: Don't inline with 'printErrorReceipt' from terminal settings
                                            because we need to know what went wrong if not handled above */
                                        TenderUtil.printErrorReceipt();

                                        if (err) {
                                            TenderUtil.notifyException(err);
                                        }
                                    }
                                }
                            }
                        } else {
                            $scope.printerResponse = printerResponse;
                            setTenderPage(TRANSACTION_STATUS.CARD_TERMINAL_MANUAL);
                        }
                    }

                    if (hasTimeout) {
                        var terminalProperties = printerResponse.posStation.cardTerminalProperties;
                        var terminalInterface = CardTerminal.init(terminalProperties);

                        $scope.tenderData.isRecoveringCardData = true;

                        await TenderUtil.recoverSemiIntegratedTerminal(terminalInterface).then(async function (data) {
                            $scope.tenderData.currentTender.manuallyRecorded = true;
                            if (KioskService.isKiosk() || (data && data.isManual)) {
                                KioskService.disableTimeoutModal(false);
                                // don't remove current tender from here instead trigger error state has below
                                // removing tender here compromises transaction data integrity
                                hasError = (data && data.hasError);

                                return;
                            }

                            try {
                                return TenderUtil._paymentApproved(data, terminalProperties);
                            } catch (e) {
                                $log.error(e);
                                hasError = true;
                            }
                        }).catch(function () {
                            hasError = true;
                        });

                        $scope.tenderData.isRecoveringCardData = false;

                        if (!hasError) {
                            return;
                        }
                    }

                    if (notifyTerminalError.notifyUser) {
                        let terminalProperties = printerResponse.posStation.cardTerminalProperties;
                        let terminalInterface = CardTerminal.init(terminalProperties);
                        let transactionId = notifyTerminalError.data[0].attributeTranId;

                        if (!notifyTerminalError.multiPart) {
                            $scope.tendering = false;

                            PosAlertService.showAlertByName('terminal-payment-error', {showHelpLink: true, message: notifyTerminalError.notifyUser});
                            setTenderPage(TRANSACTION_STATUS.CARD_TERMINAL_CANCELLED);

                            return;
                        }

                        await TenderUtil.recoverSemiIntegratedTerminal(terminalInterface, notifyTerminalError).then(async function (lastTransactionData) {
                            try {
                                if (lastTransactionData && lastTransactionData.completeMultiPartTrx) {
                                    let data = {};

                                    data.result = await terminalInterface.completeMultiPartTransaction({transactionId: transactionId});

                                    return TenderUtil._paymentApproved(data, terminalProperties);
                                }

                                // cancel multi part transaction
                                await terminalInterface.completeMultiPartTransaction({transactionId: transactionId}, lastTransactionData.completeMultiPartTrx);
                            } catch (e) {
                                $log.error(e);
                                hasError = true;

                                if (e.data) {
                                    let receiptFields = e.data[0];
                                    let raw = e.data[1];

                                    cardTerminalData.receiptFieldsArr.push(receiptFields);
                                    cardTerminalData.cardTerminalResponses.push({
                                        cardTransactionId: receiptFields.transactionId,
                                        cardTerminalResponse: JSON.stringify(raw),
                                        processorType: terminalProperties.type
                                    });

                                    // print error receipt for cancellation
                                    if (e.printErrorReceipt) {
                                        TenderUtil.printErrorReceipt(cardTerminalData.cardTerminalResponses, cardTerminalData.receiptFieldsArr);
                                    }
                                }
                            }
                        }).catch(function () {
                            hasError = true;
                        });
                    }

                    if (terminalInitFailed) {
                        $scope.tendering = false;
                        removeCurrentTender();
                        setTenderPage(TRANSACTION_STATUS.CARD_TERMINAL_INIT_FAILED);
                        return;
                    }

                    if (hasError) {
                        if (KioskService.isKiosk()) {
                            $scope.tendering = false;
                            setTenderPage(TRANSACTION_STATUS.CARD_TERMINAL_CANCELLED);
                            return;
                        }

                        PosAlertService.showAlertByName('terminal-payment-error', {showHelpLink: true});
                        $scope.tendering = false;

                        removeCurrentTender();
                        setTenderPage(TRANSACTION_STATUS.CARD_TERMINAL_CANCELLED);

                        return;
                    }

                    if (isCreditDebit && !isUsingTerminal) {
                        // Credit/debit manual transaction
                        $scope.tendering = false;
                        $scope.printerResponse = printerResponse;
                        setTenderPage(TRANSACTION_STATUS.CARD_TERMINAL_MANUAL);
                    } else {
                        return TenderUtil.shouldInitializeOrCreateTransaction(posObj, printerResponse);
                    }
                },
                recoverSemiIntegratedTerminal: async function (terminalInterface, multiPartData = {}) {
                    if (KioskService.isKiosk()) {
                        KioskModalService.showModalByName('oops', {
                            subtitle: 'smb.pos.error.connection.problem',
                            modalCallback: function () {
                                // TODO: Investigate why adjustments aren't resetting after tender failure
                                // $scope.$dismiss();
                            },
                            buttonTitleOk: 'general.error.okay'
                        });

                        return {hasError: true};
                    }

                    var modalInstance = await $modal.open({
                        templateUrl: 'pos/smb/templates/pos.tender.terminal-recovery.tpl.html',
                        controller: 'SmbPosTenderTerminalRecoveryCtrl',
                        windowClass: 'smb-pos smb-pos-popup smb-pos-popup__confirm',
                        animation: false,
                        resolve: {
                            tender: function () {
                                return $scope.tenderData.currentTender;
                            },
                            terminalInterface: function () {
                                return terminalInterface;
                            },
                            multiPartData: function () {
                                return multiPartData;
                            },
                        },
                        backdrop: false
                    });

                    return modalInstance.result;
                },
                _paymentApproved: async function (data, terminalProperties) {
                    // stringify terminal's response which we will store
                    // in the backend database for audit purposes
                    var result = data.result;
                    var receiptFields = result[0];
                    var raw = result[1];

                    if (receiptFields.signatureBase64Png) {
                        await $modal.open({
                            template: '<img src="data:image/png;base64,{{receiptFields.signatureBase64Png}}"><div ng-click="close()">Close</div>',
                            controller: 'CardTerminalSummaryController',
                            windowClass: 'modal-50',
                            backdrop: 'static',
                            animation: false,
                            resolve: {
                                receiptFields: function () {
                                    return receiptFields;
                                }
                            }
                        }).result;
                    }

                    cardTerminalData.receiptFieldsArr.push(receiptFields);
                    cardTerminalData.cardTerminalResponses.push({
                        cardTransactionId: receiptFields.transactionId,
                        cardTerminalResponse: JSON.stringify(raw),
                        processorType: terminalProperties.type
                    });

                    $scope.tenderAmounts.cardTerminalResponses = cardTerminalData.cardTerminalResponses;
                    $scope.tenderAmounts.cardReceiptFields = cardTerminalData.receiptFieldsArr;

                    var tipAmount = parseFloat(receiptFields.tipAmount);
                    tipAmount = (isNaN(tipAmount)) ? 0 : tipAmount;

                    $scope.tenderAmounts.tipAmount = $scope.tenderAmounts.tipAmount || 0;
                    $scope.tenderAmounts.tipAmount += tipAmount;
                    $scope.tenderData.currentTender.terminalResponse = JSON.stringify(raw);
                    $scope.tenderData.currentTender.receiptFields = (receiptFields) ? [receiptFields] : [];

                    let currentTenderTipAmount = (tipAmount > 0) ? tipAmount : $scope.tenderAmounts.tipAmount;
                    $scope.tenderData.currentTender.tipAmount = currentTenderTipAmount;
                    $scope.tenderData.currentTender.creditCardName = receiptFields.cardName;

                    var approvedAmount = parseFloat(receiptFields.approvedAmount);
                    approvedAmount = (isNaN(approvedAmount)) ? 0 : approvedAmount;

                    var salesAmount = new Decimal(approvedAmount)
                        .minus(new Decimal(currentTenderTipAmount))
                        .toNearest(SharedDataService.baseDollar)
                        .toNumber();

                    if (salesAmount < 0) {
                        salesAmount = 0;
                        $log.error({
                            messsage: 'approvedAmount is less than tipAmount',
                            context: {
                                terminalResponse: raw
                            }
                        });
                    }

                    updateCurrentTenderAmount(salesAmount);

                    if (receiptFields.partiallyApproved) {
                        $scope.tenderData.isSplit = true;
                    }

                    return {
                        actualAmountCharged: receiptFields.approvedAmount,
                        partialAuthorization: receiptFields.partiallyApproved,
                    };
                },
                _isCreditTransaction: function (posObj) {
                    return (posObj.creditCardAmount > 0);
                },
                _isDebitTransaction: function (posObj) {
                    return (posObj.debitCardAmount > 0);
                },
                _isCreditDebitTender: function (tender) {
                    return ((tender.field === 'creditCardAdjustment' || tender.field === 'debitCardAdjustment')
                        && tender.amount > 0);
                },
                _isCardTerminalConfigured: function (printerResponse) {
                    return printerResponse.posStation.cardTerminalProperties
                        && printerResponse.posStation.cardTerminalProperties.type
                        && printerResponse.posStation.cardTerminalProperties.type != 'manual';
                },
                consolidateTenderTips: function () {
                    $scope.tenderAmounts.tipAmount = 0;
                    _.each($scope.tenderData.allTenders, function (tender) {
                        $scope.tenderAmounts.tipAmount += (tender.tipAmount || 0);
                    });
                },
                shouldInitializeOrCreateTransaction: async function (posObj, printers) {
                    TenderUtil.consolidateTenderTips();

                    // Appends employeeId. This is only populated when a pinpad is displayed
                    // before transaction. otherwise the backend uses the cashier shift employeeId
                    if ($scope.currentOrder.employee && $scope.currentOrder.employee.employeeId) {
                        posObj.employeeId = $scope.currentOrder.employee.employeeId;
                    }

                    var storeCredit = undefined;
                    if (refundTransactionForExchange && !storeCreditData.storeCredit) {
                        try {
                            storeCredit = await CashierShift.createStoreCredit({}, refundTransactionForExchange).$promise;
                            $scope.tenderData.allTenders[0].giftCardId = storeCredit.id;
                            $scope.tenderData.allTenders[0].giftCard = storeCredit;
                            storeCreditData.storeCredit = storeCredit;

                            var exchangedFromRefund = storeCredit.exchangedFromRefund;
                            posObj.exchangedFromRefundId = exchangedFromRefund.refundTransactionId;
                        } catch (e) {
                            /**
                            *** Commented By Akash Mehta 10th Mar 2020
                            *** We need to close the tender modal if there was an error during refund itself.
                            *** But modalInstance.dismiss does not halt the execution. Hence a return is required
                            *** as well.
                            ***/
                            $scope.tendering = false;
                            $modalInstance.dismiss({
                                refundFailed: true,
                                error: e
                            });
                            return {
                                success: false
                            };
                        }
                    }

                    if (!$scope.tenderData.isSplit) {
                        var result = {
                            initializeOnly: false,
                            // this return a boolean indicating whether there is any balance left - this should be false at all time
                            promise: TenderUtil.createTransaction(posObj, printers)
                        };

                        return result;
                    } else {
                        var result2 = {
                            initializeOnly: true,
                            // this return a boolean indicating whether there is any balance left
                            // promise: TenderUtil.createSplitTenderTransaction(posObj, $scope.fullReceipt, printers)
                        };

                        var processPromise;
                        if ($scope.tenderData.currentTender.type === 'gateway') {
                            processPromise = TenderUtil.processGatewayTransaction(posObj, printers, true);
                        } else {
                            processPromise = TenderUtil.createSplitTenderTransaction(posObj, $scope.fullReceipt, printers);
                        }
                        result2.promise = processPromise;

                        $scope.tendering = false;
                        setTenderPage(TRANSACTION_STATUS.INITIAL);

                        return result2;
                    }
                },
                createSplitTenderTransaction: function (posObj, fullReceipt, printers) {
                    var posObjCopy = angular.copy(posObj);
                    if (KioskService.isKiosk()) {
                        posObjCopy.serviceMode = AppConstants.serviceModes.KIOSK;
                    }
                    var isTransactionAlreadyInitialized = false;
                    if ($scope.tenderData.transactionId) {
                        isTransactionAlreadyInitialized = true;
                    }

                    let serviceResponse = null;
                    try {
                        serviceResponse = TransactionService.tenderTransaction(posObjCopy, fullReceipt, $scope.tenderData.allTenders,
                            $scope.quickCharge, originalTransactionId, $scope.patron,
                            ticketPrePrint, $scope.buzzerCode, {
                            isSplitTransaction: true,
                            isTransactionAlreadyInitialized: isTransactionAlreadyInitialized,
                            adjustments: $scope.adjustments
                        });
                    } catch (error) {
                        serviceResponse = null;
                    }

                    if (serviceResponse == null) {
                        removeCurrentTender();
                        $scope.tendering = false;
                        setTenderPage(TRANSACTION_STATUS.CANCELLED, {});
                        return {
                            success: false,
                            hasBalance: false // true means keep looping
                        };
                    }

                    return serviceResponse.promise.then(function (tenderTransactionResponse) {
                        try {
                            SolinkService.sendPaymentSuccess($scope.transactionUuid,
                                $scope.fullReceipt,
                                $scope.solinkCancelledItems,
                                getSolinkPaymentObj(tenderTransactionResponse.transactionId,
                                    solinkTenders, tenderTransactionResponse.transactionTax,
                                    tenderTransactionResponse.cashRounding)
                            );
                        } catch (err) {
                            $log.error('Failed to send event to Solink', err);
                        }
                        // Adding `isSplit` and `isTransactionAlreadyInitialized` so that MEV
                        // box service can do other required tasks
                        tenderTransactionResponse.isSplit = true;
                        tenderTransactionResponse.isTransactionAlreadyInitialized = isTransactionAlreadyInitialized;

                        $scope.kioskReceiptCounter = tenderTransactionResponse.receiptCounter;
                        // Check for Remaining balance. If full balance is settled down and it is split tansaction, then only print reciept
                        if (tenderTransactionResponse.remainingBalance <= 0) {
                            TenderUtil.printTenderReceipts(tenderTransactionResponse, printers);
                        }

                        $scope.tenderData.transactionId = tenderTransactionResponse.receiptId;
                        $scope.tenderData.canToggleSplit = false;

                        $scope.tenderData.currentTender = undefined;
                        $scope.tenderData.lastTenderResponse = tenderTransactionResponse;

                        $scope.markTendersProcessed($scope.tenderData.allTenders);

                        _.each(tenderTransactionResponse.giftCardUsage, function (usage) {
                            $scope.tenderData.giftCardUsage = $scope.tenderData.giftCardUsage || {};
                            $scope.tenderData.giftCardUsage[usage.id] = usage;
                        });

                        if (tenderTransactionResponse.remainingBalance <= 0) {
                            try {
                                TenderUtil.completeGatewayTransaction(posObj, tenderTransactionResponse);
                            } catch (ex) {
                                $log.error({
                                    message: 'Error while posting transaction to FIIT !',
                                    context: {
                                        error: ex
                                    }
                                });
                            }

                            try {
                                addTendersToSolink(tenderTransactionResponse.tenders);
                                SolinkService.sendCompletedTransaction(
                                    $scope.transactionUuid,
                                    $scope.adjustments.transactionStartTime,
                                    $scope.fullReceipt,
                                    $scope.solinkCancelledItems,
                                    getSolinkPaymentObj(tenderTransactionResponse.transactionId, solinkTenders,
                                        tenderTransactionResponse.transactionTax, tenderTransactionResponse.cashRounding),
                                );
                            } catch (err) {
                                $log.error('Failed to send event to Solink', err);
                            }

                            TenderUtil.showTenderSummaryScreen(tenderTransactionResponse, printers);

                            if (!$scope.tenderAmounts.guestTransaction) {
                                TenderUtil.notifyTransactionToLucovaBackend(posObj, EnvConfig.appFid, $cookies);
                            }

                            return {
                                success: true,
                                hasBalance: false
                            };
                        } else {
                            return {
                                success: true,
                                hasBalance: true // true means keep looping
                            };
                        }
                    }).catch(function (error) {
                        $log.error({
                            message: 'Error while doing a split tender transaction',
                            context: {
                                error: error,
                                user: CurrentSession.getUser().username,
                                isExchange: !!(refundTransactionForExchange && storeCreditData && storeCreditData.storeCredit)
                            }
                        });
                        removeCurrentTender();
                        $scope.tendering = false;

                        setTenderPage(TRANSACTION_STATUS.CANCELLED, {});
                        if (error && error.data && error.data.error) {
                            notificationTranslationHelper.notifyError(error.data.error, null, false);
                        } else {
                            notificationTranslationHelper.notifyError('Could not process transaction', null, false);
                        }

                        return {
                            success: false,
                            hasBalance: false // true means keep looping
                        };
                    });
                },
                createTransaction: function (posObj, printers) {
                    if (KioskService.isKiosk()) {
                        posObj.serviceMode = AppConstants.serviceModes.KIOSK;
                    }
                    if (!posObj.locationId) {
                        return CommonOfflineCache.getLocationId().then(function (locationId) {
                            posObj.locationId = locationId;
                            return TenderUtil.processTransaction(posObj, printers);
                        });
                    } else {
                        return TenderUtil.processTransaction(posObj, printers);
                    }
                },
                processTransaction: function (posObj, printers) {
                    var processPromise;
                    if ($scope.tenderData.currentTender.type === 'gateway') {
                        processPromise = TenderUtil.processGatewayTransaction(posObj, printers);
                    } else {
                        processPromise = TenderUtil.sendTransaction(posObj, $scope.fullReceipt, printers);
                    }

                    return processPromise;
                },

                estimateGatewayTransaction: function (posObj, patron, requestAmount, useMealEquivalency, adjustments, useFiitPriority) {
                    var posObjCopy = angular.copy(posObj);

                    var fiitAdjustments = FiitMealPlanCalculationService.processAdjustments();
                    fiitAdjustments.mealEquivalencyAdjustment = !!useMealEquivalency;
                    fiitAdjustments.dollarDiscount = adjustments.dollarDiscount || 0;
                    fiitAdjustments.deliveryFee = posObj.deliveryFee || 0;
                    fiitAdjustments.deliveryTaxRate = (adjustments.deliverySettings) ? adjustments.deliverySettings.defaultTaxRate : 0;

                    var availableMealPlans = FiitMealPlanCalculationService.filterFiitPatronMealPlans(patron.fiitMpsAccount.mealPlans, useFiitPriority);

                    var fiitResult = FiitMealPlanCalculationService.calculateBalances(posObjCopy.receiptItems,
                        availableMealPlans, fiitAdjustments, patron.fiitMpsAccount.fiitLocationId, patron.fiitMpsAccount.applicableMealEquivalency, useFiitPriority);

                    var minimizedResponse = FiitMealPlanCalculationService.repackageRawCalculationResponse(fiitResult);

                    if (!minimizedResponse.error) {
                        return $q.resolve(minimizedResponse);
                    } else {
                        return $q.reject(minimizedResponse);
                    }
                },

                completeGatewayTransaction: function (posObj, tenderTransactionResponse) {
                    if ($scope.tenderData.allTenders.length < 1 || !GatewayFiit.isEnabled()) {
                        return;
                    }

                    TransactionService.postTransactionToFiit(posObj, $scope.tenderData.allTenders, $scope.patron, tenderTransactionResponse.receiptId);
                },
                processGatewayTransaction: function (posObj, printers, isSplit) {
                    var response = {
                        success: true,
                        offline: false
                    };

                    var parseError = function (exception, responseObj) {
                        $log.error(exception);
                        markTransactionCancelled(exception);
                        responseObj.success = false;
                        delete responseObj.offline;
                        return $q.resolve(responseObj);
                    };

                    try {
                        var data = $scope.tenderData.currentTender.transactionTenderDetail.fiitCalculationResponseObj;

                        if (!data || !Object.keys(data).length) {
                            var error = {
                                error: 'No valid FIIT response found !'
                            };
                            throw error;
                        }

                        try {
                            TransactionService.updateNownCartFromFiitTransactionMock($scope.receipt, data,
                                $scope.adjustments);

                            $scope.updateCurrentTenderAmount(data.approvedAmount);
                            $scope.tenderData.currentTender.meal = data.approvedMeal;
                            $scope.tenderAmounts.guestTransaction = true;
                            response.updatedPosObj = $scope.tenderAmounts;
                            response.updatedPosObj.hasFiitTender = true;

                            var updatedPosObj = response.updatedPosObj || posObj;

                            if ($scope.tenderData.isSplit) {
                                return TenderUtil.createSplitTenderTransaction(updatedPosObj, $scope.fullReceipt, printers);
                            } else {
                                return TenderUtil.sendTransaction(updatedPosObj, $scope.fullReceipt, printers);
                            }
                        } catch (error) {
                            return parseError(error, response);
                        }
                    } catch (exception) {
                        return parseError(exception, response);
                    }
                },
                captureLucovaTransaction: function (posObj, printers) {
                    var lucovaTransactionObj = PosBuilderService.buildClientProcessingLucovaTransaction(
                        $scope.lucovaUser.user_name, posObj, $scope.lucovaUser.nownGiftCards, verified);

                    return PromiseRetry.start({
                        fn: function () {
                            return $scope.Lucova.manager().beginDirect(lucovaTransactionObj).$promise;
                        },
                        timeout: 10000,
                        maxRetry: 0 // No retry for now because the backend doesn't handle retry yet
                    }).then(function (response) {
                        if (response.success) {
                            response.transaction.callback = function (updatedTransaction) {
                                TenderUtil.processLucovaTransaction(posObj, printers, updatedTransaction)
                                    .then(function (transactionResponse) {
                                        return TenderUtil.onLucovaTransactionConfirmed(posObj, transactionResponse);
                                    })
                                    .catch(function (error) {
                                        $scope.removeCurrentTender();
                                        failedLucovaTransaction(error);
                                    });
                            };

                            LucovaWebSocket.setTransaction(response.transaction);

                            // it is possible that when performing a direct transaction, that the response
                            // returns a completed transaction (eg. auto confirm). In this case, perform
                            // the callback immediately.
                            if (response.transaction.status === 'completed') {
                                response.transaction.callback(response.transaction);
                                LucovaWebSocket.setTransactionStatus(TRANSACTION_STATUS.COMPLETED);
                            }
                        } else {
                            // {"success":false,"message":"No credit card!","error_code":106}
                            $scope.removeCurrentTender();
                            failedLucovaTransaction({
                                message: response.message,
                                code: response.error_code
                            });
                        }
                    });
                },
                processLucovaTransaction: function (posObj, printers, lucovaResponse) {
                    $scope.tenderData.currentTender.amount = new Decimal(lucovaResponse.amount_cents)
                        .dividedBy(new Decimal(100))
                        .toNearest(SharedDataService.baseDollar)
                        .toNumber();

                    $scope.tenderData.currentTender.tipAmount = new Decimal(lucovaResponse.tip_cents)
                        .dividedBy(new Decimal(100))
                        .toNearest(SharedDataService.baseDollar)
                        .toNumber();

                    // For now Lucova transcation has to be non-split, meaning only 1 tender only
                    let lucovaTenderAmountWithoutTips = (lucovaResponse.tip_cents > 0) ? (lucovaResponse.total_cents - lucovaResponse.tip_cents) : lucovaResponse.total_cents;

                    posObj.creditCardAmount = new Decimal(lucovaTenderAmountWithoutTips)
                        .dividedBy(new Decimal(100))
                        .toNearest(SharedDataService.baseDollar)
                        .toNumber();
                    posObj.tipAmount = new Decimal(lucovaResponse.tip_cents)
                        .dividedBy(new Decimal(100))
                        .toNearest(SharedDataService.baseDollar)
                        .toNumber();
                    // add lucova's short_id as a reference to mobile orders/app payments
                    if (lucovaResponse.short_id) {
                        posObj.shortId = lucovaResponse.short_id.trim();
                    }

                    let serviceResponse = TransactionService.tenderTransaction(posObj, $scope.fullReceipt,
                        $scope.tenderData.allTenders, $scope.quickCharge, originalTransactionId, $scope.patron,
                        ticketPrePrint, $scope.buzzerCode, {
                        adjustments: $scope.adjustments
                    });

                    return serviceResponse.promise;
                },
                onLucovaTransactionConfirmed: function (posObj, responseData) {
                    // This callback is only called when transaction is successful/completed
                    /* var receiptData = updatedTransaction.receipt_data;
                    var responseData = _.find(receiptData, function (datum) {
                        return ['fiit', 'nown_gift'].indexOf(datum.gateway) > -1;
                    }); */

                    try {
                        responseData.patronName = responseData.patronName || $scope.lucovaUser.full_name || '';
                        PosStatusService.lastTransaction = {
                            transaction: responseData,
                            full_name: $scope.lucovaUser.full_name,
                            printReceipt: function () {
                                PrintService.printReceipt(responseData, $scope.receipt, undefined, true);
                            },
                            patron: $scope.patron,
                            /* lucovaData: {
                                lucovaUser: $scope.lucovaUser,
                                lucovaTransaction: updatedTransaction
                            }, */
                            isQuickCharge: $scope.tenderedWithQuickCharge,
                            isRefundable: ($scope.patron && $scope.patron.patronId)
                                && isTenderedWithMealPlanOnly($scope.tenderAmounts)
                        };

                        if ($scope.patron) {
                            getPatronBalance($scope.patron.patronId);
                        }

                        if (responseData) {
                            try {
                                TenderUtil.completeGatewayTransaction(posObj, responseData);
                            } catch (ex) {
                                $log.error({
                                    message: 'Error while posting transaction to FIIT !',
                                    context: {
                                        error: ex
                                    }
                                });
                            }

                            // Print receipt
                            $scope.getLocationPrinters().then(function (printerResponse) {
                                var shouldPrint = currentSessionCompany.attributes.receipt__auto_print_after_tx === 'true';
                                if (shouldPrint) {
                                    PrintService.printReceipt(responseData,
                                        $scope.receipt,
                                        [],
                                        false,
                                        PrintType.CUSTOMER);
                                }


                                if (!ticketPrePrint || !ticketPrePrint.receiptCounter) {
                                    try {
                                        if ($scope.currentOrder) {
                                            SmbPosService.printNewItemsToKitchen($scope.receipt, responseData, {buzzerCode: $scope.buzzerCode, orderName: $scope.currentOrder.orderName});
                                        } else {
                                            SmbPosService.printNewItemsToKitchen($scope.receipt, responseData, {buzzerCode: $scope.buzzerCode});
                                        }
                                    } catch (error) {
                                        console.error(error);
                                    }
                                }
                            });

                            // Send to KDS and backend
                            sendToKdsAndBackend(null, responseData.receiptCounter);
                            if ($scope.cancelledItemsCount > 0) {
                                SmbPosService.cancelItemsLogToBackend($scope);
                            }
                            checkAndDeleteSuspended();

                            playSuccessSound($scope.tenderAmounts.tipAmount);
                        }
                        LucovaWebSocket.setTransactionStatus(TRANSACTION_STATUS.COMPLETED);
                        showTipPopup(
                            $scope.patron.fullName,
                            $scope.lucovaUser.asset_url,
                            responseData.tipAmount,
                            responseData.transactionTotal
                        );
                    } catch (error) {
                        console.error(error);
                        $scope.tendering = false;
                        LucovaWebSocket.setTransactionStatus(TRANSACTION_STATUS.COMPLETED);

                        // Send to KDS and backend
                        sendToKdsAndBackend(null, responseData.receiptCounter);
                        if ($scope.cancelledItemsCount > 0) {
                            SmbPosService.cancelItemsLogToBackend($scope);
                        }
                        checkAndDeleteSuspended();
                    }
                },
                sendTransaction: function (posObj, fullReceipt, printers) {
                    let serviceResponse = null;
                    try {
                        serviceResponse = TransactionService.tenderTransaction(posObj, fullReceipt, $scope.tenderData.allTenders,
                            $scope.quickCharge, originalTransactionId, $scope.patron, ticketPrePrint, $scope.buzzerCode, {
                            adjustments: $scope.adjustments
                        });
                    } catch (error) {
                        serviceResponse = null;
                    }

                    if (serviceResponse == null) {
                        removeCurrentTender();
                        $scope.tendering = false;
                        setTenderPage(TRANSACTION_STATUS.CANCELLED, {});
                        return {
                            success: false
                        };
                    }

                    var transactionPayload = angular.copy(serviceResponse.transactionPayload);
                    return serviceResponse.promise.then(function (tenderTransactionResponse) {

                        // Commented By Akash Mehta on Sept 9 2021
                        // This below 2 lines were added 2 years ago when we were introducing
                        // alphaPay. We are not sure if removing the below code will impact alphaPay
                        // in any way. But this code doesn't seem to be useful and we should remove it
                        // if possible.
                        $scope.tendering = false;
                        setTenderPage(TRANSACTION_STATUS.CANCELLED, {});

                        // return {success: false};
                        console.log('Transaction tendered successfully');
                        try {
                            addTendersToSolink(tenderTransactionResponse.tenders);
                            SolinkService.sendPaymentSuccess($scope.transactionUuid,
                                $scope.fullReceipt,
                                $scope.solinkCancelledItems,
                                getSolinkPaymentObj(tenderTransactionResponse.transactionId, solinkTenders,
                                    tenderTransactionResponse.transactionTax, tenderTransactionResponse.cashRounding),
                            );
                            SolinkService.sendCompletedTransaction($scope.transactionUuid,
                                $scope.adjustments.transactionStartTime,
                                $scope.fullReceipt,
                                $scope.solinkCancelledItems,
                                getSolinkPaymentObj(tenderTransactionResponse.transactionId, solinkTenders,
                                    tenderTransactionResponse.transactionTax, tenderTransactionResponse.cashRounding),
                            );
                        } catch (err) {
                            $log.error('Failed to send event to Solink', err);
                        }

                        $scope.kioskReceiptCounter = tenderTransactionResponse.receiptCounter;
                        // Guest Transaction
                        if (!tenderTransactionResponse.patronName) {
                            GuestLabelService.setGuestLabel(guestLabel);
                        }

                        try {
                            TenderUtil.completeGatewayTransaction(posObj, tenderTransactionResponse);
                        } catch (ex) {
                            $log.error({
                                message: 'Error while posting transaction to FIIT !',
                                context: {
                                    error: ex
                                }
                            });
                        }

                        if (!$scope.quickCharge) {
                            /*
                             * Commented by Nick Simone on April 20, 2021
                             * Here we are setting the uuid to ensure the QR code is printed on a non-mobile
                             * order.
                             */
                            tenderTransactionResponse.transactionUuid = $scope.transactionUuid;
                            TenderUtil.printTenderReceipts(tenderTransactionResponse, printers.entries);
                        }

                        TenderUtil.showTenderSummaryScreen(tenderTransactionResponse, printers.entries);

                        if (!$scope.tenderAmounts.guestTransaction) {
                            TenderUtil.notifyTransactionToLucovaBackend(posObj, EnvConfig.appFid, $cookies);
                        }

                        return {success: true};
                    }).catch(function (error) {
                        $log.error({
                            message: 'Error while doing tender transaction',
                            context: {
                                error: error,
                                user: CurrentSession.getUser().username,
                            }
                        });
                        // Offline workflow.
                        // If the tx request times out (ie. error.timeout = true),
                        // we can also treat it as offline so that the POS can
                        // continue moving forward while the tx gets recovered
                        // later. Chances that a timed-out transactions should've
                        // failed is very very slim.

                        /**
                         * June 11/19
                         * At this point the transaction has already been captured
                         * either by card or cash and we failed to record it. but
                         * the transaction did occur, so we will move on to show
                         * transaction summary
                         */
                        SmbPosService.populateReceiptCounter(transactionPayload);
                        OfflineTransactionService.pushTenderTransaction(transactionPayload);

                        /*
                        Commented by Nick Simone 2022/03/09
                        Usually we wait for the transaction to be sent to the Nown backend
                        before attempting to send it to FIIT. Here, we will send it regardless
                        because as it says above, we have already considered this transaction to
                        be done so we must record it.
                        */
                        try {
                            TenderUtil.completeGatewayTransaction(posObj, transactionPayload);
                        } catch (ex) {
                            // This is expected to fail since we are offline
                        }

                        // Send this to Solink now so we sync correctly with the cameras
                        try {
                            addTendersToSolink(transactionPayload.tenders);
                            SolinkService.sendCompletedTransaction(
                                $scope.transactionUuid,
                                $scope.adjustments.transactionStartTime,
                                $scope.fullReceipt,
                                $scope.solinkCancelledItems,
                                // We don't have the transaction ID in offline mode, so use undefined here
                                getSolinkPaymentObj(undefined, solinkTenders,
                                    transactionPayload.totalTaxes, transactionPayload.cashRounding)
                            );
                        } catch (err) {
                            $log.error('Failed to send event to Solink', err);
                        }

                        /**
                         * In order to print a decent receipt while offline
                         */
                        transactionPayload.posPrinters = printers.posStation.posPrinters;
                        transactionPayload.transactionTax = transactionPayload.totalTaxes;
                        transactionPayload.transactionSubTotal = transactionPayload.subtotal;
                        transactionPayload.transactionTotal = transactionPayload.totalSales;
                        transactionPayload.mealPlansRedeemed = transactionPayload.mealPlanCount;

                        var company = CurrentSession.getCompany();

                        transactionPayload.location = {
                            street: company.operatingAddress.street,
                            city: company.operatingAddress.city,
                            region: company.operatingAddress.region
                        };
                        transactionPayload.locationName = company.companyName || '';
                        transactionPayload.companyName = company.companyName || '';
                        transactionPayload.change = Math.abs(transactionPayload.remainingBalance);
                        transactionPayload.stationName = transactionPayload.posStationId;

                        try {
                            TenderUtil.printTenderReceipts(transactionPayload, printers.entries);
                            TenderUtil.showTenderSummaryScreen(transactionPayload, printers.entries);
                        } catch (error) {
                            $log.error(error);
                        }
                        return {success: true}; // no balance
                    });
                },
                printTenderReceipts: function (tenderTransactionResponse, printers) {
                    // Force print if signature is required
                    var creditCardResponses = [];
                    if ($scope.tenderData.currentTender
                        && $scope.tenderData.currentTender.receiptFields
                        && $scope.tenderData.currentTender.receiptFields.length) {
                        creditCardResponses = creditCardResponses.concat($scope.tenderData.currentTender.receiptFields);
                    }

                    var shouldPrintMerchantReceipt = false;
                    for (var i of Object.keys(creditCardResponses)) {
                        var cardReceipt = creditCardResponses[i];
                        if (cardReceipt.success &&
                            cardReceipt.showCustomerSignatureLine) {
                            shouldPrintMerchantReceipt = true;
                        }
                    }

                    if (shouldPrintMerchantReceipt) {
                        PrintService.printReceipt(tenderTransactionResponse,
                            $scope.receipt,
                            creditCardResponses,
                            false,
                            PrintType.MERCHANT
                        );
                    }

                    var shouldPrintCustomerReceipt = currentSessionCompany.attributes.receipt__auto_print_after_tx === 'true';
                    if (shouldPrintCustomerReceipt) {
                        PrintService.printReceipt(tenderTransactionResponse,
                            $scope.receipt,
                            creditCardResponses,
                            false,
                            PrintType.CUSTOMER
                        );
                    }

                    if (!shouldPrintMerchantReceipt && !shouldPrintCustomerReceipt) {
                        if (tenderTransactionResponse.cashAmount && tenderTransactionResponse.cashAmount > 0) {
                            var printUrlArray = PrintService.buildPrinterObjArray(tenderTransactionResponse.posPrinters, false);
                            PrintService.openCashDrawer(printUrlArray);
                        }
                    }

                    if (refundTransactionForExchange
                        && storeCreditData.storeCredit
                        && !storeCreditData.storeCreditPrinted) {
                        var originalTransaction = storeCreditData.storeCredit.exchangedFromOriginal;
                        if (originalTransaction) {
                            $scope.reprintTransaction(originalTransaction);
                        }
                    }

                    storeCreditData.storeCreditPrinted = true;
                },
                showTenderSummaryScreen: function (tenderTransactionResponse, printers) {
                    PosStatusService.lastTransaction = {
                        transaction: tenderTransactionResponse,
                        full_name: $scope.patron.firstName + ' ' + $scope.patron.lastName,
                        printReceipt: function () {
                            PrintService.printReceipt(
                                tenderTransactionResponse,
                                $scope.receipt,
                                $scope.tenderAmounts.cardReceiptFields,
                                true,
                                PrintType.CUSTOMER
                            );
                        },
                        patron: $scope.patron,
                        isQuickCharge: $scope.tenderedWithQuickCharge,
                        isRefundable: ($scope.patron && $scope.patron.patronId)
                            && isTenderedWithMealPlanOnly($scope.tenderAmounts)
                    };

                    if ($scope.patron) {
                        // async request that will auto-update the UI when
                        // response received.
                        getPatronBalance($scope.patron.patronId);
                    }

                    SecondaryDisplay.sendReceiptId(tenderTransactionResponse.receiptId);
                    // Send to KDS and backend
                    sendToKdsAndBackend(null, tenderTransactionResponse.receiptCounter);

                    if ($scope.cancelledItemsCount > 0) {
                        SmbPosService.cancelItemsLogToBackend($scope);
                    }
                    checkAndDeleteSuspended();

                    try {
                        if (!ticketPrePrint || !ticketPrePrint.receiptCounter) {
                            if ($scope.currentOrder) {
                                SmbPosService.printNewItemsToKitchen($scope.receipt, tenderTransactionResponse, {buzzerCode: $scope.buzzerCode, orderName: $scope.currentOrder.orderName});
                            } else {
                                SmbPosService.printNewItemsToKitchen($scope.receipt, tenderTransactionResponse, {buzzerCode: $scope.buzzerCode});
                            }
                        }

                        playSuccessSound($scope.tenderAmounts.tipAmount);
                    } catch (e) {
                        console.error(e);
                    } finally {
                        $scope.tendering = false;
                        setTenderPage(TRANSACTION_STATUS.SUMMARY, {
                            tenderResponse: tenderTransactionResponse
                        });
                        showTipPopup(
                            $scope.patron.fullName,
                            '',
                            tenderTransactionResponse.tipAmount,
                            tenderTransactionResponse.transactionTotal
                        );
                    }
                },
                rollbackTerminalTransactions: function (printerResponse, cardType) {
                    var terminalInterface = CardTerminal.init(
                        printerResponse.posStation.cardTerminalProperties
                    );

                    if (cardType === 'credit') {
                        for (var i of Object.keys($scope.tenderAmounts.cardReceiptFields)) {
                            var obj = $scope.tenderAmounts.cardReceiptFields[i];

                            terminalInterface.creditVoid(obj.transactionId);
                        }
                    } else if (cardType === 'debit') {
                        // the way this function is called, we don't need to void debit right now..
                        // terminalInterface.debitVoid(transactionId);
                    }
                },
                notifyException: function (error) {
                    $log.error({
                        message: 'Card Terminal Transaction Failed',
                        context: {
                            transaction: $scope.tenderAmounts,
                            error: error
                        }
                    });
                },
                printErrorReceipt: function (cardTerminalResponses = '', cardReceiptFields = '') {
                    Locations.getLocationPrinters({'locationId': locationId}).$promise.then(function (response) {
                        if (cardTerminalResponses && cardReceiptFields) {
                            $scope.tenderAmounts.cardTerminalResponses = cardTerminalResponses;
                            $scope.tenderAmounts.cardReceiptFields = cardReceiptFields;
                        }

                        if ($scope.tenderAmounts.cardTerminalResponses) {
                            var cardReceiptArr = ReceiptBuilderService.buildCardReceipt(
                                $scope.tenderAmounts.cardTerminalResponses);
                            let printData = $scope.tenderAmounts;
                            printData.posPrinters = response.posStation.posPrinters;
                            printData.locationName = currentSessionCompany.companyName;
                            printData.location = currentSessionCompany.operatingAddress;

                            PrintService.printReceipt(
                                printData,
                                $scope.tenderAmounts.cardReceiptFields,
                                cardReceiptArr,
                                false,
                                PrintType.ALL
                            );
                        }
                    });
                }
            };

            // Continue with manual transaction, triggered by a button in the
            // TRANSACTION_STATUS.CARD_TERMINAL_MANUAL page
            $scope.manuallyInitializeOrCreateTransaction = function () {
                return TenderUtil.shouldInitializeOrCreateTransaction($scope.tenderAmounts, $scope.printerResponse);
            };


            $scope.setTransactionTip = function () {
                $scope.openNumpad('tip', 0);
            };

            $scope.setTendering = function (tendering) {
                $scope.tendering = tendering;
            };

            $scope.tenderReceipt = function (shouldPrint, printType) {
                if ($scope.tendering) {
                    return;
                }
                $scope.tendering = true;

                // Appends employeeId. This is only populated when a pinpad is displayed
                // before transaction. otherwise the backend uses the cashier shift employeeId
                if ($scope.currentOrder.employee && $scope.currentOrder.employee.employeeId) {
                    $scope.tenderAmounts.employeeId = $scope.currentOrder.employee.employeeId;
                }

                if ($scope.currentOrder.serviceMode) {
                    $scope.tenderAmounts.serviceMode = $scope.currentOrder.serviceMode;
                }

                if (angular.isDefined($scope.lucovaUser) && $scope.lucovaUser !== null) {
                    // Queue Lucova Transaction
                    if ($scope.isOffline) {
                        TenderUtil.queueOfflineLucovaTransaction(
                            $scope.lucovaUser.user_name,
                            $scope.receipt,
                            $scope.fullReceipt,
                            $scope.adjustments,
                            $scope.servicePeriodId,
                            $scope.tenderAmounts.locationId,
                            cashierShiftId
                        ).then(function () {
                            if ($scope.patron) {
                                CommonOfflineCache.refreshPatron($scope.tenderAmounts);
                            }

                            $scope.tendering = false;
                            setTenderPage(TRANSACTION_STATUS.SUMMARY);
                        });

                        return;
                    }

                    $scope.getLocationPrinters().then(function (printers) {
                        $scope.currentTender = {
                            type: 'card',
                            field: 'creditCardAdjustment',
                            amount: $scope.tenderAmounts.totalSales, // non-split
                            uuid: Pure.generateUuid()
                        };
                        $scope.addToCurrentTender($scope.currentTender);

                        // We need to send the lucova user if it exists
                        // This is because, we use the below lucova user to collect
                        // points against a patron.
                        if ($scope.lucovaUser) {
                            $scope.tenderAmounts.guestTransaction = true;
                            $scope.tenderAmounts.patronName = $scope.lucovaUser.full_name;
                            $scope.tenderAmounts.lucovaUser = {
                                username: $scope.lucovaUser.user_name,
                                firstName: $scope.lucovaUser.full_name,
                                email: $scope.lucovaUser.email
                            };
                        }

                        var posObj = $scope.tenderAmounts; // angular.copy($scope.tenderAmounts);
                        posObj.sourceId = 1;

                        TenderUtil.captureLucovaTransaction(posObj, printers);
                    });
                } else {
                    return $scope.getLocationPrinters().then(async function (printerResponse) {
                        // Restore lucovaUser if it exists
                        // This is necessary because reference is lost by proceeding
                        // to manual guest transaction workflow from lucova user workflow
                        // (such as if there is no payment method)
                        if ($scope.patron.lucovaUser) {
                            $scope.tenderAmounts.guestTransaction = true;
                            $scope.tenderAmounts.patronName = $scope.patron.lucovaUser.full_name;
                            $scope.tenderAmounts.lucovaUser = {
                                username: $scope.patron.lucovaUser.user_name,
                                firstName: $scope.patron.lucovaUser.full_name,
                                email: $scope.patron.lucovaUser.email
                            };
                        }

                        return TenderUtil.beginTransactionWorkflow($scope.tenderAmounts, printerResponse);
                    }, function (error) {
                        $scope.tendering = false;

                        if (error) {
                            $log.error(error);
                        } else {
                            $log.error('$scope.getLocationPrinters(): Unknown error occurred');
                        }

                        if ($scope.tenderPage === TRANSACTION_STATUS.INITIAL || $scope.tenderPage === TRANSACTION_STATUS.PENDING) {
                            setTenderPage(TRANSACTION_STATUS.CANCELLED);
                        }
                    });
                }
            };

            $scope.showNonMobileFlow = function () {
                LucovaWebSocket.setTransactionStatus(TRANSACTION_STATUS.RETRY);

                // set lucovaUser to null because we are going to the non-mobile
                // workflow.
                $scope.lucovaUser = null;

                removeCurrentTender();
            };
            $scope.retryTransaction = function () {
                $scope.tenderPage = TRANSACTION_STATUS.INITIAL;

                if (KioskService.isKiosk()) {
                    $scope.failedKioskCardTerminalInit = false;
                }

                removeCurrentTender();
            };

            $scope.getUserPhoto = function (photoUrl) {
                return envConfig.lucovaHost + photoUrl;
            };

            var takedownTransaction = function (toDismiss, goToHome = false) {
                $scope.tendering = false;

                if (toDismiss) {
                    var errorResponse;
                    if (goToHome) {
                        errorResponse = {
                            goToHome: true
                        };
                    }
                    $scope.closeModal(errorResponse);
                }
            };

            $scope.cancelTransaction = function (toDismiss, goToHome = false) {
                $scope.removeCurrentTender();
                if (LucovaWebSocket.getTransaction()) {
                    $scope.cancelLucovaTransaction(toDismiss);
                } else {
                    takedownTransaction(toDismiss, goToHome);
                }
            };
            $scope.cancelLucovaTransaction = function (toDismiss) {
                var obj = {
                    'transaction_id': LucovaWebSocket.getTransaction()._id,
                    'accepted': 'false'
                };

                $scope.Lucova.manager().cancelDirect(obj, function (response) {
                    LucovaWebSocket.setTransaction(null);
                    takedownTransaction(toDismiss);
                }, function (error) {
                    takedownTransaction(toDismiss);
                });
            };
            $scope.finishLucovaTransaction = function () {
                // Clear completedtransaction from PosStatusService
                PosStatusService.completedTransaction = undefined;
                // Clear guestLabel from GuestLabelService
                GuestLabelService.setGuestLabel(undefined);
                $scope.$close('done');
            };
            $scope.closeModal = function (errorResponse) {
                $scope.$dismiss(errorResponse);
            };

            $scope.populateBalance = function (type) {
                var remainingBalance = $scope.tenderAmounts.remainingBalance;
                var cashRounding = $scope.tenderAmounts.cashRounding || 0;
                if (type && remainingBalance > 0) {
                    if (type === 'cash') {
                        $scope.adjustments.cashAdjustment = $scope.adjustments.cashAdjustment + remainingBalance;
                    } else if (type === 'credit') {
                        $scope.adjustments.creditCardAdjustment = $scope.adjustments.creditCardAdjustment + remainingBalance - cashRounding;
                    } else if (type === 'debit') {
                        $scope.adjustments.debitCardAdjustment = $scope.adjustments.debitCardAdjustment + remainingBalance - cashRounding;
                    } else if (type === 'giftCard') {
                        $scope.adjustments.giftCardAdjustment = $scope.adjustments.giftCardAdjustment + remainingBalance - cashRounding;
                    } else if (type === 'other') {
                        $scope.adjustments.otherAdjustment = $scope.adjustments.otherAdjustment + remainingBalance - cashRounding;
                    }
                    $scope.balanceChange();
                }
            };

            $scope.initiateRefundLastTransaction = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'pos/refund/pos.transaction.refund.tpl.html',
                    controller: 'PosTransactionRefundCtrl',
                    windowClass: 'smb-pos',
                    animation: false,
                    resolve: {
                        patron: function () {
                            return $scope.patron;
                        },
                        lucovaData: function () {
                            return $scope.lastTransaction.lucovaData;
                        },
                        transaction: function () {
                            return $scope.lastTransaction.transaction;
                        },
                        description: function () {
                            if ($scope.lastTransaction.isQuickCharge) {
                                return $translate('transactions.refund.quickCharge.default');
                            } else {
                                return $translate('transactions.refund.regular.default');
                            }
                        },
                        mustRefundServicePeriod: function () {
                            return true;
                        }
                    },
                    backdrop: true
                });

                modalInstance.result.then(function () {
                    $scope.lastTransaction.transaction.refunded = true;
                    $scope.finishLucovaTransaction();
                });
            };

            var getPatronBalance = function (patronId) {
                CashierShift.lookupPatronMealPlans({'patronId': patronId}, function (response) {
                    // ensure patron.mealPlans' balances are updated after a transaction
                    $scope.patron.mealPlans = response.entries;
                    $scope.patronBalance = SharedFunctionService.calculateTotalPlanUnits(response.entries);
                }, function (error) {
                    let patronsPromise = CommonOfflineCache.getPatronsFromCache();
                    patronsPromise.then(function (patrons) {
                        let mealPlans = CommonOfflineCache.getMealPlans($scope.patron.patronId, patrons);
                        $scope.patron.mealPlans = mealPlans;
                        $scope.patronBalance = SharedFunctionService.calculateTotalPlanUnits(mealPlans);
                    });

                });
            };

            var isTenderedWithMealPlanOnly = function (tenderAmounts) {
                return tenderAmounts
                    && (tenderAmounts.dcbAmount || tenderAmounts.mealPlanCount)
                    && (tenderAmounts.cashAmount
                        + tenderAmounts.creditCardAmount
                        + tenderAmounts.debitCardAmount
                        + tenderAmounts.giftCardAmount
                        + tenderAmounts.otherAmount === 0);
            };

            let solinkTenders = [];

            const addTendersToSolink = (tenders) => {
                let amountDollars;
                Array.prototype.forEach.call(tenders, (tender) => {
                    try {
                        amountDollars = tender.amountCents / 100;
                    } catch (e) {
                        amountDollars = 0.0;
                    }
                    solinkTenders.push({
                        type: 'payment',
                        time: Date.now() - SystemService.getSystemTimeOffsetMilliseconds(),
                        quantity: 1.0,
                        description: tender.transactionType,
                        unitPrice: amountDollars,
                    });
                });
            };

            const getSolinkPaymentObj = (receiptNumber, tenders, taxAmount, cashRoundingAmount) => {
                return {
                    receiptNumber: receiptNumber,
                    tenders: tenders,
                    tax: {
                        amount: taxAmount,
                        timeApplied: Date.now() - SystemService.getSystemTimeOffsetMilliseconds(),
                    },
                    cashRounding: {
                        amount: cashRoundingAmount,
                        timeApplied: Date.now() - SystemService.getSystemTimeOffsetMilliseconds(),
                    },
                };
            };

            $scope.reprintTransaction = function (transaction) {
                SmbPosService.reprintReceipt(transaction, PrintReceiptType.TRANSACTION, true);
            };

            $scope.modifyExistingItem = function (receiptItem) {
                modifyExistingItem(receiptItem);
            };

            $scope.removeReceiptItem = function (receiptItem) {
                removeReceiptItem(receiptItem);
            };

            $scope.modifyItemQuantity = function (receiptItem, option) {
                modifyItemQuantity(receiptItem, option);
            };

            $scope.init();

            $scope.applyTransactionPercentageDiscount = function (percentage, isLoyalty) {
                applyTransactionPercentageDiscount(percentage, isLoyalty);
                $scope.balanceChange();
            };

            $scope.$on('$destroy', function () {
                KioskService.disableTimeoutModal(false);
            });
        }
    ])
        .value('initialAdjustments', {})
        .value('initialTenders', [])
        .value('applyReceiptItemChangeFunction', undefined)
        .value('originalTransactionId', undefined)
        .value('refundTransactionForExchange', undefined)
        .value('ticketPrePrint', undefined);
};
