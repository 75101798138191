'use strict';


export function okCancelPopup (freshideas) {
    freshideas.controller('OkCancelPopupCtrl', [
        '$scope',
        '$timeout',
        '$window',
        '$log',
        '$modalInstance',
        'message',
        function ($scope,
            $timeout,
            $window,
            $log,
            $modalInstance,
            message) {

            $scope.message = message;

            // Navigation
            $scope.cancel = function () {
                $modalInstance.dismiss();
            };

            $scope.ok = function () {
                $modalInstance.close();
            };
        }
    ]);
}
