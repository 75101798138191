'use strict';

const Decimal = require('decimal.js').default;

module.exports = function (posModule) {

    posModule.controller('PosCashierOptionsCtrl', [
        '$scope',
        '$rootScope',
        '$state',
        '$modal',
        '$modalInstance',
        '$translate',
        '$timeout',
        '$log',
        '$filter',
        'Security',
        'SmbPosService',
        'CashierShift',
        'CurrentSession',
        'PosAlertService',
        'SharedDataService',
        'PrintService',
        'KioskService',
        'Company',
        'CompanyAttributesService',
        'MevBoxService',
        'CommonOfflineCache',
        'GatewayFiit',
        // 'Drivers',
        'user',
        'logCashDrawerActivityFunction',
        'createCashDrawerOpenEntryFunction',
        'menuOptions',
        'cashDrawerOptions',
        'shift',
        'parentLoadAllFunction',
        'parentDisplayOpenShiftPageFunction',
        'parentDisplayClockInPageFunction',
        'menuType',
        'isMevBoxCompany',
        'optionalParams',
        'MEV_STATUS',
        function (
            $scope,
            $rootScope,
            $state,
            $modal,
            $modalInstance,
            $translate,
            $timeout,
            $log,
            $filter,
            Security,
            SmbPosService,
            CashierShift,
            CurrentSession,
            PosAlertService,
            SharedDataService,
            PrintService,
            KioskService,
            Company,
            CompanyAttributesService,
            MevBoxService,
            CommonOfflineCache,
            GatewayFiit,
            // Drivers,
            user,
            logCashDrawerActivityFunction,
            createCashDrawerOpenEntryFunction,
            menuOptions,
            cashDrawerOptions,
            shift,
            parentLoadAllFunction,
            parentDisplayOpenShiftPageFunction,
            parentDisplayClockInPageFunction,
            menuType,
            isMevBoxCompany,
            optionalParams,
            MEV_STATUS) {

            $scope.currentDate = $filter('date')(Date.now(), 'MMMM dd yyyy, hh:mm a');
            $scope.userEditing = Object.assign({}, user);
            $scope.pageTitle = $translate.instant('smb.pos.open.cash.drawer.mainMenu.ttl');
            $scope.pageSubTitle = $translate.instant('smb.pos.open.cash.drawer.mainMenu.subttl');
            $scope.page = 'mainMenu';

            // due to mevbox/srm, we perform a deep copy here.
            // if simply setting scope.menuOptions = menuOptions
            // the toShow of menuOptions gets saved in pos.js
            // this is not ideal as srm buttons should only show after the status
            // is confirmed in scope.init

            $scope.menuOptions = angular.copy(menuOptions);
            $scope.cashDrawerOpenReason = {};
            $scope.placeholders = {};
            $scope.menuType = menuType;
            $scope.company = CurrentSession.getCompany();
            $scope.fiitServicePeriods = GatewayFiit.getServicePeriods();
            $scope.isFiitEnabled = GatewayFiit.isEnabled();
            $scope.selectedFiitServicePeriod = {};
            // Commented by chris lo, Feb 23rd, 2021
            // These fields are primarly used to temporarily disable to the mobile features of the POS
            // in the case where the location may not have the capacity to fulfill all orders
            // The two booleans are saved in NownPOS db and are sent to payments server via the job.
            $scope.mobileFeatures = {
                'preorder': {
                    enabled: $scope.company.hasPreorder,
                    initialValue: !$scope.company.disablePreorderTemporary,
                    value: !$scope.company.disablePreorderTemporary
                },
                'delivery': {
                    enabled: CompanyAttributesService.deliveryEnabled(),
                    initialValue: !$scope.company.disableDeliveryTemporary,
                    value: !$scope.company.disableDeliveryTemporary
                }
            };

            $scope.saveMobileFeatures = () => {
                const params = {
                    id: $scope.company.companyId,
                    disablePreorderTemporary: !$scope.mobileFeatures.preorder.value,
                    disableDeliveryTemporary: !$scope.mobileFeatures.delivery.value
                };

                Company.updateMobileFeatureToggles(params).$promise
                    .then(() => {
                        $scope.company.disablePreorderTemporary = params.disablePreorderTemporary;
                        $scope.company.disableDeliveryTemporary = params.disableDeliveryTemporary;

                        // CurrentSession's company obj must be updated.
                        // This ensures everywhere else using CurrentSession.getCompany() will see the correct values on these fields
                        const currentUser = Object.assign({}, Security.getUser());
                        currentUser.company = $scope.company;
                        Security.updateUser(currentUser);
                        $modalInstance.close();
                    })
                    .catch((err) => {
                        PosAlertService.showAlertByName('general', {
                            title: 'smb.pos.cashdrawer.toggle.error.label',
                            message: 'general.error.try.again.later'
                        });

                        // send a honeybadger log if an issue occurs with saving mobile features.
                        $log.error('Failed to save mobile features', err);
                        $modalInstance.close();
                    });
            };

            $scope.isMevBoxCompany = isMevBoxCompany;

            var openEditCurrentResourcesModal = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalEditCurrentResources.tpl.html',
                    controller: ['$scope',
                        'CurrentSession',
                        'Lucova',
                        'SmbPosService',
                        'PosAlertService',
                        function (
                            $scope,
                            CurrentSession,
                            Lucova,
                            SmbPosService,
                            PosAlertService) {

                            $scope.loading = false;

                            $scope.increaseWorkerCounter = function () {
                                $scope.workerCounter++;
                            };

                            $scope.decreaseWorkerCounter = function () {
                                if ($scope.workerCounter == 1) {
                                    return;
                                }
                                $scope.workerCounter--;
                            };

                            var savingWorkerCapacity = false;
                            $scope.workerCounter = 1;

                            $scope.save = function () {
                                if (savingWorkerCapacity) {
                                    return;
                                }
                                savingWorkerCapacity = true;
                                Lucova.manager().updateNode({
                                    work_capacity: $scope.workerCounter
                                }, function () {
                                    savingWorkerCapacity = false;
                                    $scope.$close();
                                }, function () {
                                    savingWorkerCapacity = false;
                                    PosAlertService.showAlertByName('general', {
                                        title: 'pos.worker.capacity.update.failed.title',
                                        message: 'general.error.try.again.later'
                                    });
                                });
                            };

                            $scope.cancel = function () {
                                $scope.$close();
                            };

                            var loadWorkerCount = function () {
                                $scope.loading = true;
                                Lucova.manager().getNode({}, function (response) {
                                    $scope.loading = false;
                                    $scope.workerCounter = response.work_capacity;
                                }, function (err) {
                                    $scope.loading = false;
                                    PosAlertService.showAlertByName('general', {
                                        title: 'pos.worker.capacity.fetch.failed.title',
                                        message: 'general.error.try.again.later'
                                    });
                                });
                            };

                            loadWorkerCount();
                        }],
                    animation: false,
                    windowClass: 'edit-current-resources-modal',
                    resolve: {
                        page: function () {
                            return 'itemSearchPage';
                        },
                        searchResults: function () {
                            return [];
                        }
                    },
                    backdrop: true
                });
                return modalInstance.result.then(function (value) {
                });
            };

            /**
            **  Function to select a menu option
            **  Based on the type of option selected, it populates
            **  the respective modal page
            **/
            $scope.selectMenuOption = function (option, value) {
                // Unlike the other options this one opens a new modal so the usual workflow is skipped.
                if (option == 'editCurrentResources') {
                    openEditCurrentResourcesModal();
                    return;
                }
                $timeout(function () {
                    $scope.page = option;
                    $scope.pageTitle = value;
                    $scope.pageSubTitle = '';
                    if (option == 'payout') {
                        $scope.pageSubTitle = $translate.instant('smb.pos.open.cash.drawer.payout.subttl');
                        $scope.placeholders.amount = $translate.instant('smb.pos.open.cash.drawer.payout.placeholders.amount');
                        $scope.placeholders.description = $translate.instant('smb.pos.open.cash.drawer.payout.placeholders.description');
                        $scope.placeholders.supplier = $translate.instant('smb.pos.open.cash.drawer.payout.placeholders.supplier');
                        $scope.placeholders.invoice_no = $translate.instant('smb.pos.open.cash.drawer.payout.placeholders.invoice_no');
                    } else if (option == 'addcash') {
                        $scope.pageSubTitle = $translate.instant('smb.pos.open.cash.drawer.addcash.subttl');
                        $scope.placeholders.amount = $translate.instant('smb.pos.open.cash.drawer.addcash.placeholders.amount');
                        $scope.placeholders.description = $translate.instant('smb.pos.open.cash.drawer.addcash.placeholders.description');
                    } else if (option == 'other') {
                        $scope.pageSubTitle = $translate.instant('smb.pos.open.cash.drawer.other.subttl');
                        $scope.placeholders.description = $translate.instant('smb.pos.open.cash.drawer.other.placeholders.description');
                    } else if (option == 'endShift') {
                        $scope.page = 'mainMenu';
                        endShift();
                    } else if (option == 'itemAvailability') {
                        // managers and above
                        const canOverride = Security.hasPermissionForUser('MANAGER') || Security.getUser().permission === 'MANAGER';

                        if (CompanyAttributesService.itemAvailabilityDisabled() && !canOverride) {
                            $modalInstance.close();
                            PosAlertService.showAlertByName('feature-not-available', {
                                title: $translate.instant('general.error.feature-not-available.ttl'),
                                message: $translate.instant('general.error.feature-not-available.msg')
                            });
                        } else {
                            $scope.page = 'mainMenu';
                            openItemAvailabilityModal();
                        }
                    } else if (option == 'switchMenuPeriod') {
                        $scope.pageTitle = $translate.instant('smb.pos.cashier.options.menuPeriodSwitch.ttl');
                        $scope.pageSubTitle = $translate.instant('smb.pos.cashier.options.menuPeriodSwitch.subttl');
                        openSwitchMenuPeriodPage();
                    } else if (option == 'switchMenu') {
                        if ($scope.automaticModal) {
                            $scope.pageTitle = $translate.instant('smb.pos.cashier.options.menuChoose.ttl');
                            $scope.pageSubTitle = $translate.instant('smb.pos.cashier.options.menuChoose.subttl');
                        } else {
                            $scope.pageTitle = $translate.instant('smb.pos.cashier.options.menuSwitch.ttl');
                            $scope.pageSubTitle = $translate.instant('smb.pos.cashier.options.menuSwitch.subttl');
                        }
                        openSwitchMenuPage();
                    } else if (option == 'driverPreview') {
                        $scope.page = 'mainMenu';
                        // openDriverPreviewModal();
                    } else if (option == 'preorderDashboard') {
                        $modalInstance.close();
                        openPreorderDashboard();
                    } else if (option == 'reconnectSrm') {
                        $modalInstance.close();
                        reconnectSrm();
                    } else if (option == 'bypassSrm') {
                        $modalInstance.close();
                        bypassSrm();
                    }
                }, 0);
            };

            /**
            **  Function to go to Main Menu
            **  It resets the modal page to main menu
            **/
            $scope.goToMainMenu = function () {
                $scope.page = 'mainMenu';
                $scope.pageTitle = $translate.instant('smb.pos.open.cash.drawer.mainMenu.ttl');
                $scope.pageSubTitle = $translate.instant('smb.pos.open.cash.drawer.mainMenu.subttl');
            };

            /** Function to save the changes of the modal
            **  Calls the parent function to log the cash drawer activity to be performed
            **  If success opens the drawer, and if failed , logs into honeybadger but does not open drawer
            **  and finally closes the modal.
            **/
            $scope.save = function () {
                if (!$scope.checkIfValid()) {
                    return;
                }

                if ($scope.page == 'switchMenuPeriod') {

                    switchMenuPeriod();
                    $modalInstance.close();
                } else if ($scope.page == 'switchMenu') {
                    SmbPosService.updateShiftMenu($scope.selectedMenu.menuId).then(function () {
                        $modalInstance.close();
                    });
                } else {
                    var cashDrawerOpenEntry = createCashDrawerOpenEntryFunction($scope.cashDrawerOpenReason,
                        $scope.userEditing, SmbPosService.shift.cashierShiftId, $scope.page);

                    var callBackSuccess = function (reportResponse) {
                        SmbPosService.loadStatus();
                        $modalInstance.close(cashDrawerOpenEntry);
                    };
                    var errorBackSuccess = function (error) {
                        SmbPosService.loadStatus();
                        $log.error({
                            message: 'Error while logging Cash Drawer Activity',
                            context: {
                                activity: 'logCashDrawerOpenActivity',
                                posStation: SmbPosService.shift.posStation.name,
                                location: SmbPosService.shift.location,
                                pinEntered: $scope.userEditing.pin,
                                user: CurrentSession.getUser()
                            }
                        });
                        $modalInstance.dismiss();
                    };

                    SmbPosService.openDrawerAsPromise().then(function () {
                        if (cashDrawerOpenEntry.cashDrawerActivityType != 'other') {
                            PrintService.printOpenDrawerReceipt(cashDrawerOpenEntry,
                                SmbPosService.shift.posStation, SharedDataService.location);
                        }
                        logCashDrawerActivityFunction(cashDrawerOpenEntry, callBackSuccess, errorBackSuccess);
                    }, function () {
                        cashDrawerOpenEntry.description = '[Open Failed] ' + cashDrawerOpenEntry.description;
                        logCashDrawerActivityFunction(cashDrawerOpenEntry, callBackSuccess, errorBackSuccess);
                    });
                }
            };

            /**
            **  Function to check  if the cash drawer entr object
            **  is valid or not
            **/
            $scope.checkIfValid = function () {
                if ($scope.page == 'switchMenuPeriod') {
                    return !!(($scope.selectedMenuPeriod && $scope.selectedMenuPeriod.menuPeriodId)
                    || ($scope.selectedFiitServicePeriod && $scope.selectedFiitServicePeriod.servicePeriodId));
                }

                if ($scope.page == 'switchMenu') {
                    return !!($scope.selectedMenu && $scope.selectedMenu.menuId);
                }

                if (($scope.page == 'payout' || $scope.page == 'addcash' || $scope.page == 'other')
                    && !$scope.cashDrawerOpenReason.description) {
                    return false;
                }

                if ($scope.page == 'payout' || $scope.page == 'addcash') {
                    if ($scope.cashDrawerOpenReason.amount == undefined) {
                        return false;
                    }

                    if ($scope.page == 'payout') {
                        // if (!$scope.cashDrawerOpenReason.invoice_no) {
                        //     return false;
                        // }
                        // if (!$scope.cashDrawerOpenReason.supplier) {
                        //     return false;
                        // }
                    }
                }

                return true;
            };

            /**
            **  Function to edit the amount field of the form
            **  Called when the person clicks on the amount box
            **/
            $scope.editAmount = function () {
                var tempAmount = $scope.cashDrawerOpenReason.amount;
                return showPinPad('currency', tempAmount, onEditPrice);
            };

            /**
            **  Function to edit the price of the item clicked
            **  Called when the person clicks on the price box next
            **  to the item
            **/
            var onEditPrice = function (editedValue) {
                $timeout(function () {
                    $scope.cashDrawerOpenReason.amount = new Decimal(editedValue).toNearest(SharedDataService.baseDollar).toNumber();
                }, 0);
            };

            $scope.setFiitServicePeriodId = (menuPeriodId) => {
                const menuPeriod = $scope.menuPeriods.find((period) => period.menuPeriodId == menuPeriodId);
                if (menuPeriod && menuPeriod.servicePeriodId) {
                    $scope.showFiitServicePeriodSelector = false;
                    $scope.selectedFiitServicePeriod.servicePeriodId = menuPeriod.servicePeriodId;
                } else {
                    $scope.showFiitServicePeriodSelector = true;
                    $scope.selectedFiitServicePeriod.servicePeriodId = null;
                }
            };

            const switchMenuPeriod = function () {
                const menuPeriod = $scope.menuPeriods.find((period) => period.menuPeriodId == $scope.selectedMenuPeriod.menuPeriodId);
                const servicePeriodId = menuPeriod.servicePeriodId || $scope.selectedFiitServicePeriod.servicePeriodId;
                if (servicePeriodId) {
                    SmbPosService.updateShiftFiitServicePeriod(servicePeriodId)
                        .catch((error) => {
                            PosAlertService.showAlertByName('general', {
                                title: 'general.error',
                                message: 'general.fiitServicePeriod.saveError'});
                        });
                }
                SmbPosService.switchMenuPeriod(menuPeriod);

                if ($scope.isFiitEnabled) {
                    GatewayFiit.reloadLocation();
                }
            };

            var openSwitchMenuPeriodPage = function () {
                $scope.menuPeriods = [];
                $scope.selectedMenuPeriod = {};
                loadAllMenuPeriods();
            };


            var callbackFunction = () => {
                if (!$scope.menus.length) {
                    loadAllMenus({
                        getAllMenusByOrganization: true
                    });
                }
            };

            var openSwitchMenuPage = function () {
                $scope.menus = [];
                $scope.selectedMenu = {};
                loadAllMenus({callbackFn: callbackFunction});
            };

            var loadAllMenuPeriods = function () {
                SmbPosService.loadFormattedMenuPeriods().then(function (formattedMenuPeriods) {
                    $scope.menuPeriods = formattedMenuPeriods || [];
                });
            };

            var loadAllMenus = function ({callbackFn, getAllMenusByOrganization = false} = {}) {
                SmbPosService.loadFormattedMenus({getAllMenusByOrganization: getAllMenusByOrganization}).then(function (formattedMenus) {
                    $scope.menus = formattedMenus || [];
                    if (callbackFn) {
                        callbackFn();
                    }
                });
            };

            var openPreorderDashboard = function () {
                $state.go('freshideas.preorderDashboard', {prevPage: 'home'});
            };

            var openItemAvailabilityModal = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalItemAvailability.tpl.html',
                    controller: 'ItemAvailabilityCtrl',
                    animation: false,
                    windowClass: 'item-avail-screen',
                    resolve: {
                        page: function () {
                            return 'itemSearchPage';
                        },
                        searchResults: function () {
                            return [];
                        }
                    },
                    backdrop: true
                });
                return modalInstance.result.then(function (value) {
                });
            };

            var reconnectSrm = function () {
                MevBoxService.setMevBoxBypass(false);
            };

            var bypassSrm = function () {
                MevBoxService.setMevBoxBypass(true);
            };

            /**
            ** Init function for the controller
            **/
            $scope.init = function () {

                if (optionalParams && optionalParams.openMenuSwitchModal) {
                    let menuModalOption = $scope.menuOptions.find((option) => {
                        return option.type == 'switchMenu';
                    });

                    $scope.automaticModal = true;


                    $scope.selectMenuOption(menuModalOption.type, menuModalOption.value);

                } else {
                    $scope.automaticModal = false;
                    // if it's a mevbox enabled company, check status when cashier options is clicked on.
                    if ($scope.isMevBoxCompany) {
                        MevBoxService.getStatus().then((res) => {
                            $scope.mevboxStatus = res;
                        });
                    }

                    // we do not want the cashier options modal to have a slow load time from fetching the mevbox status.
                    // therefore set a time out of 2 seconds, and have the appropriate mevbox button popup when available.
                    setTimeout(() => {
                        _.each($scope.menuOptions, (option) => {
                            if (option.type === 'bypassSrm') {
                                option.toShow = ($scope.mevboxStatus === MEV_STATUS.UNAVAILABLE);
                            } else if (option.type === 'reconnectSrm') {
                                option.toShow = ($scope.mevboxStatus === MEV_STATUS.BYPASS);
                            }
                        });
                    }, 2000);

                }
            };

            /**
            ** Function to open pinpad
            **/
            var showPinPad = function (type, model, callback, validator) {
                var pinPadInstance = $modal.open({
                    templateUrl: 'pos/pos.numpad.tpl.html',
                    controller: 'PosNumpadCtrl',
                    animation: false,
                    windowClass: 'modal-numpad modal-fullscreen-transparent modal-right',
                    resolve: {
                        initial: function () {
                            return model;
                        },
                        type: function () {
                            return type;
                        }
                    },
                    backdrop: true
                });
                return pinPadInstance.result.then(function (value) {
                    if (callback) {
                        callback(value);
                    }
                });

            };

            $scope.init();

            var closeShiftModalOpen = false;
            var endShift = function () {
                // Bypass pin auth
                if (KioskService.isKiosk()) {
                    openEndShiftModal(CurrentSession.getUser());
                    return;
                }

                if (closeShiftModalOpen || !shift.started) {
                    return;
                }

                closeShiftModalOpen = true;
                var requestedPermission = 'pos:start_end_shift';
                var params = {
                    callback: function (pinObj) {
                        openEndShiftModal(pinObj.user);
                    },
                    errorCallback: function (error) {
                        if (error) {
                            var exception = error.exception || {};
                            if (exception.appCode === 412) {
                                PosAlertService.showAlertByName('closestation-invalid-pin');
                            } else {
                                PosAlertService.showAlertByName('closestation-fail', {
                                    message: exception.message || ''
                                });
                            }
                        }
                        closeShiftModalOpen = false;
                    },
                    requestedPermission: requestedPermission,
                    message: 'smb.pos.closestation.description'
                };

                $scope.$emit('PincodeAuthentication:Required', params);
            };

            var openEndShiftModal = function (userObject) {

                var shiftReportSearch = {
                    'shiftId': SmbPosService.shift.cashierShiftId
                };

                var currentMenuPeriod = CommonOfflineCache.getCurrentMenuPeriod();
                if (currentMenuPeriod && currentMenuPeriod.menuPeriodId) {
                    shiftReportSearch.menuPeriodId = currentMenuPeriod.menuPeriodId;
                }

                var quickChargeSession = CommonOfflineCache.getQuickChargeSession();
                if (quickChargeSession && quickChargeSession.quickChargeSessionId) {
                    shiftReportSearch.quickChargeSessionId = quickChargeSession.quickChargeSessionId;
                }

                let scanningModalInstance = $modal.open({
                    templateUrl: 'common/modals/modalScanningGeneric.tpl.html',
                    controller: 'GenericScanningModalCtrl',
                    windowClass: 'smb-pos products2',
                    animation: false,
                    backdrop: 'static',
                    resolve: {
                        message: function () {
                            return 'general.scan.cashier.shift.details.ttl';
                        }
                    }
                }, function (error) { });

                CashierShift.shiftReport(shiftReportSearch, function (response) {
                    if (scanningModalInstance) {
                        scanningModalInstance.close();
                    }

                    let shiftData = {
                        startingBalance: SmbPosService.shift.shiftStartBalance,
                        cashCollected: response.totalCashCollected || 0,
                        cashRefunded: response.refunds.breakdowns.CASH && response.refunds.breakdowns.CASH.amountCents / 100 || 0,
                        totalPayout: response.totalPayout || 0,
                        cashAdded: response.totalCashAdded || 0,
                        totalTip: new Decimal(response.totalTipAmount || 0).toNearest(SharedDataService.baseDollar).toNumber(),
                        endingBalance: 0,
                        isActive: true,
                        shiftId: ''
                    };

                    var endShiftModal = $modal.open({
                        templateUrl: 'pos/smb/templates/pos.end.shift.tpl.html',
                        animation: false,
                        backdrop: 'static',
                        controller: 'SmbPosEndShiftCtrl',
                        windowClass: 'smb-pos smb-pos__end-shift-modal',
                        keyboard: false,
                        resolve: {
                            requestingUser: function () {
                                return userObject;
                            },
                            logCashDrawerActivityFunction: function () {
                                return logCashDrawerActivityFunction;
                            },
                            menuOptions: function () {
                                return cashDrawerOptions;
                            },
                            createCashDrawerOpenEntryFunction: function () {
                                return createCashDrawerOpenEntryFunction;
                            },
                            shiftData: function () {
                                return shiftData;
                            }
                        }
                    });

                    endShiftModal.result.then(function (resolved) {
                        if (CompanyAttributesService.enhanceSecurityMeasures()) {
                            parentLoadAllFunction();
                            parentDisplayClockInPageFunction();
                        } else {
                            parentLoadAllFunction();
                            parentDisplayOpenShiftPageFunction();
                        }
                        if (KioskService.isKiosk()) {
                            $modalInstance.close('show-kiosk');
                        } else {
                            $modalInstance.close();
                        }
                        closeShiftModalOpen = false;
                    }, function (dismissed) {
                        closeShiftModalOpen = false;
                    });

                }, function (error) {
                    if (scanningModalInstance) {
                        scanningModalInstance.close();
                    }

                    PosAlertService.showAlertByName('general-error', {
                        title: 'general.scan.cashier.shift.details.error.ttl',
                        message: 'general.scan.cashier.shift.details.error.msg'
                    });
                });

            };
        }
    ]);
};
