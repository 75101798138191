'use strict';

const angular = require('angular');
const freshideasResourcesLocations = require('../../common/resources/location.js').default;
const freshideasProducts3 = angular.module('freshideas.products3', [freshideasResourcesLocations.name, 'ui.router', 'pascalprecht.translate', 'angular-barcode']);

freshideasProducts3
    .config([
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider.state('freshideas.products3', {
                rootUrl: 'products',
                url: '/products3',
                templateUrl: 'products/menuV2/templates/products.tpl.html',
                controller: 'Products3Ctrl'
            });
        }
    ]);


require('../../external/pos.data-service.js');
require('../products.service.js')(freshideasProducts3);
require('./services/menu.service.js')(freshideasProducts3);

require('./controllers/products.ctrl.js')(freshideasProducts3);
require('./controllers/product.menu.ctrl.js')(freshideasProducts3);
require('./controllers/product.category.ctrl.js')(freshideasProducts3);
require('./controllers/product.item.ctrl.js')(freshideasProducts3);

require('./controllers/product.modifier.ctrl.js')(freshideasProducts3);
require('./controllers/product.modifier.reuse.ctrl.js')(freshideasProducts3);

require('./controllers/product.menu.create.ctrl.js')(freshideasProducts3);
require('./controllers/product.menu.export.ctrl.js')(freshideasProducts3);

require('../product.save.prompt.ctrl.js')(freshideasProducts3);
require('../product.rename.prompt.ctrl.js')(freshideasProducts3);
require('../product.page.order.prompt.ctrl.js')(freshideasProducts3);

require('../product.general.list.ctrl.js')(freshideasProducts3);
require('../product.shopify.items.ctrl.js')(freshideasProducts3);
require('../product.print.label.ctrl.js')(freshideasProducts3);
require('../../common/controllers/dateTimeModifier.ctrl.js')(freshideasProducts3);

require('./controllers/product.master.edit.ctrl.js')(freshideasProducts3);
require('./controllers/product.master.association.ctrl.js')(freshideasProducts3);

export default freshideasProducts3;


