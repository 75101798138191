'use strict';


const angular = require('angular');
const ngResources = require('angular-resource');

export default angular.module('freshideas.resources.meals', [ngResources])
    .service('Meals', ['$resource', 'EnvConfig', function ($resource, EnvConfig) {
        return $resource(EnvConfig.host + '/freshideas/web/meals', {}, {
            'getDefault': {method: 'GET', url: EnvConfig.host + '/freshideas/web/meal/default'},
            'getMealPlans': {method: 'GET', url: EnvConfig.host + '/freshideas/web/meal/lite'},
            'getActiveMealPlans': {method: 'GET', url: EnvConfig.host + '/freshideas/web/meal/active'},
            'addMealPlan': {method: 'PUT', cache: false, url: EnvConfig.host + '/freshideas/web/meal'},
            'updateMealPlan': {method: 'POST', cache: false, url: EnvConfig.host + '/freshideas/web/meal'},
            'deleteMealPlan': {method: 'DELETE', cache: false, url: EnvConfig.host + '/freshideas/web/meals'},
            'getPatronsByMealPlanId': {method: 'GET', url: EnvConfig.host + '/freshideas/web/meal/patrons'},
            'updatePatronMealPlan': {method: 'POST', cache: false, url: EnvConfig.host + '/freshideas/web/meal/updatePatronMealPlan'},
        });
    }]);
