'use strict';

const angular = require('angular');

module.exports = function (freshIdeasGatewayService) {
    freshIdeasGatewayService
        .factory('GatewayAlphaPay', [
            '$rootScope',
            'CurrentSession',
            'AlphaPayResource',
            function (
                $rootScope,
                CurrentSession,
                AlphaPayResource) {

                const TYPE = 'alphaPay';
                var service = {};

                service.getType = function () {
                    return TYPE;
                };

                service.isEnabled = function () {
                    if (!service.alphaPay) {
                        return false;
                    }

                    return !!service.alphaPay.isEnabled;
                };

                service.isAvailable = function () {
                    if (!service.alphaPay) {
                        return false;
                    }

                    return service.alphaPay.partner_code && service.alphaPay.credential_code;
                };

                service.get = function () {
                    return AlphaPayResource.get().$promise
                        .then(function (response) {
                            onRefresh(response);
                        });
                };

                service.sync = function () {
                    return AlphaPayResource.sync().$promise
                        .then(function (response) {
                            onRefresh(response);
                        });
                };

                service.link = function (request) {
                    return AlphaPayResource.link({}, request).$promise
                        .then(function (response) {
                            onRefresh(response);
                        });
                };

                service.unlink = function () {
                    return AlphaPayResource.unlink().$promise
                        .then(function (response) {
                            onRefresh(response);
                        });
                };

                var onRefresh = function (response) {
                    if (response.gatewayType === 'ALPHAPAY') {
                        service.alphaPay = response.attributes;
                        service.alphaPay.isEnabled = service.alphaPay.isEnabled === 'true';
                    } else {
                        service.alphaPay = undefined;
                    }
                    CurrentSession.setGateway('alphaPay', service.alphaPay);

                    return service.alphaPay;
                };

                service.order = function (params) {
                    var orderObj = angular.copy(params);
                    return AlphaPayResource.order({}, orderObj);
                };
                service.syncOrder = function (orderUuid) {
                    return AlphaPayResource.syncOrder({
                        orderUuid: orderUuid
                    }, {});
                };


                service.query = function (orderUuid) {
                    return AlphaPayResource.query({
                        orderUuid: orderUuid
                    });
                };

                service.void = function (params) {
                    return AlphaPayResource.void({}, params);
                };
                service.refund = function (params) {
                    return AlphaPayResource.refund({}, params);
                };

                var init = function () {
                    service.get();
                };
                init();

                $rootScope.$on('auth-loginConfirmed', function (event, user) {
                    init();
                });

                return service;
            }
        ]);
};
