'use strict';

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosOrderTaxPopupCtrl', [
        '$scope',
        'adjustments',
        'showPinPad',
        'Security',
        'PosAlertService',
        'CashierShift',
        'CurrentSession',
        'CompanyAttributesService',
        function (
            $scope,
            adjustments,
            showPinPad,
            Security,
            PosAlertService,
            CashierShift,
            CurrentSession,
            CompanyAttributesService) {

            $scope.isLoading = {};

            var checkIfPincodeRequired = function (selectedTaxRate) {
                var params = {};
                params.requestedPermission = 'pos:change_tax_rate';
                params.callback = function (pinResponse) {
                    if (pinResponse && pinResponse.user) {
                        selectedTaxRate.userId = pinResponse.user.userId;
                    }
                };
                params.errorCallback = function (error) {
                    var message = '';
                    if (error) {
                        var exception = error.exception || {};
                        if (exception.appCode === 412) {
                            message = 'general.error.cashier-discout-fail-invalid-pin.msg';
                        } else {
                            message = exception.message || '';
                        }
                        PosAlertService.showAlertByName('cashier-discout-fail', {
                            message: message,
                            title: 'general.error.tax-change-fail.ttl'
                        });
                    }
                    selectedTaxRate.isSelected = false;
                };
                params.forceCheckIfManagerLogin = true;
                params.message = 'general.popup.manager-pin.ttl';

                $scope.$emit('PincodeAuthentication:Required', params);
            };

            $scope.applyTaxes = function () {
                var selectedTaxRatesArr = [];
                _.each($scope.allTaxRates, function (taxRate) {
                    if (taxRate.isSelected) {
                        selectedTaxRatesArr.push(taxRate);
                    }
                });

                $scope.$close({selectedTaxRates: selectedTaxRatesArr});
            };

            $scope.init = function () {
                $scope.loadAllTaxRates();
            };

            $scope.selectTaxRate = function (selectedTaxRateOption) {
                if (!selectedTaxRateOption.isSelected) {
                    _.each($scope.allTaxRates, function (taxRate) {
                        if (taxRate.taxRateId == selectedTaxRateOption.taxRateId) {
                            selectedTaxRateOption.isSelected = true;
                        } else {
                            taxRate.isSelected = false;
                            delete taxRate.userId;
                        }
                    });
                    checkIfPincodeRequired(selectedTaxRateOption);
                }
            };


            $scope.deselectTaxRate = function (selectedTaxRateOption) {
                if (selectedTaxRateOption.isSelected) {
                    selectedTaxRateOption.isSelected = false;
                }
            };

            $scope.loadAllTaxRates = function () {
                $scope.isLoading.taxRates = true;
                return CashierShift.getAllTaxRates({companyId: CurrentSession.getCompany().companyId}, function (response) {
                    var taxRates = response;
                    $scope.allTaxRates = taxRates;
                    console.log($scope.allTaxRates);
                    if (adjustments.selectedTaxRates) {
                        _.each(adjustments.selectedTaxRates, function (selectedTaxRate) {
                            var taxRateToModify = $scope.allTaxRates.find(function (taxRate) {
                                return taxRate.taxRateId === selectedTaxRate.taxRateId;
                            });

                            if (taxRateToModify) {
                                taxRateToModify.isSelected = true;
                                if (selectedTaxRate.userId) {
                                    taxRateToModify.userId = selectedTaxRate.userId;
                                }
                            }
                        });
                    }
                    $scope.isLoading.taxRates = false;
                }, function (error) {
                    $scope.isLoading.taxRates = false;
                });
            };
            $scope.init();
        }
    ]);

};
