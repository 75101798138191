export default angular.module('freshideas.services.kiosk', [])
.factory('KioskService', [
    'SharedFunctionService',
    'CompanyAttributesService',
    function (SharedFunctionService, CompanyAttributesService) {
        var isKiosk;
        var totalAmountToCharge;
        var kioskTimeoutInitiated = false;
        var disableTimeoutModal;
        var tipAmountInDollars = 0;
        var menuPageIndex = 0;

        return {
            setIsKiosk: function (value) {
                isKiosk = value;
            },
            isKiosk: function () {
                return isKiosk;
            },
            setTotalAmountToCharge: function (total) {
                totalAmountToCharge = total;
            },
            totalAmountToCharge: function () {
                return totalAmountToCharge + tipAmountInDollars;
            },
            disableTimeoutModal: function (disable) {
                disableTimeoutModal = disable;
            },
            timeoutModalIsDisabled: function () {
                return disableTimeoutModal;
            },
            setTipAmountInDollars: function (adjustments, tenderUuid, tipAmount = 0) {
                tipAmountInDollars = tipAmount;
                SharedFunctionService.addOrUpdateTips(adjustments, tenderUuid, tipAmount);
            },
            getTipAmountInDollars: function () {
                return tipAmountInDollars;
            },
            resetTipAmount: function () {
                tipAmountInDollars = 0;
            },
            getMenuPageIndex: function () {
                const pageIndex = CompanyAttributesService.getDefaultKioskMenuPage();

                if (pageIndex) {
                    menuPageIndex = pageIndex;
                }

                return menuPageIndex;
            },
            setTimeoutInitiated: function (timeoutInitiated = false) {
                kioskTimeoutInitiated = timeoutInitiated;
            },
            isKioskTimeoutInitiated: function () {
                return kioskTimeoutInitiated;
            }
        };
    }
]);
