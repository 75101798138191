'use strict';

const moment = require('moment');

module.exports = function (freshideasSetup) {

    freshideasSetup.controller('DateTimeModifierCtrl', [
    '$scope',
    '$modal',
    '$timeout',
    'PosAlertService',
    function ($scope, $modal, $timeout, PosAlertService) {
        $scope.timePeriods = ['PM', 'AM'];
        $scope.dateFieldRequired = true;

        // Commented By Akash Mehta on November 9 2020
        // dataCyPrefix is a prefix used for appending to the data-cy attribute in the html
        // which is used by Cypress tests
        // timeObj is an object consisting of the following fields:
        // 1. timePeriod : The period(AM/PM) of input object
        // 2. label: The label of the field
        // 3. timeInHrsMins: String representation of time in a 12-hour format ('hh:mm')
        // 4. newTimestamp: A timestamp used by the date-picker on the html

        /**
         * This function is only called from product.menu.tpl.html
         */
        $scope.init = function (timeObj, dateRequired = true, dataCyPrefix = '') {
            $scope.dateFieldRequired = dateRequired;
            $scope.dataCyPrefix = dataCyPrefix;

            timeObj = timeObj || {};
            timeObj.inputMoment = timeObj.inputMoment || moment();
            timeObj.newTimestamp = timeObj.inputMoment.valueOf();
            timeObj.timeInHrsMins = timeObj.inputMoment.format('hh:mm');
            timeObj.timePeriod = timeObj.inputMoment.format('A');
            $scope.timeObj = timeObj;
            populateTime($scope.timeObj);
        };

        $scope.datePickerOptions = {
            formatYear: 'yy',
            startingDay: 1,
            closeOnDateSelection: true,
            placement: 'left-bottom',
            popupPlacement: 'left-bottom'
        };

        var populateTime = function (timeObj) {
            try {
                timeObj.outputMoment = moment(timeObj.timeInHrsMins + ' ' + timeObj.timePeriod, 'hh:mm A').seconds(0).milliseconds(0);
            } catch (error) {
                PosAlertService.showAlertByName('invalid-date-range', {
                    message: 'Invalid '+ timeObj.errorLabel
                });
            }
        };

        $scope.propagateTimeChange = function () {
            populateTime($scope.timeObj);
            // Commented By Akash Mehta on November 17 2020
            // Propogate event to close the date picker modal automatically
            $timeout( function () {
                $scope.opened.dataDate = false;
            }, 0);
        };

        $scope.onClickTime = function () {
            var timeValidator = function (oldVal, newVal) {
                var value = oldVal + '' + newVal;
                if (value.length > 4) {
                    return false;
                }

                // Checking for hours
                if (value.length > 3) {
                    if (value.charAt(0) > 1 ) {
                        return false;
                    }
                    if (value.charAt(1) > 2) {
                        return false;
                    }

                    if (value.charAt(2) > 5) {
                        return false;
                    }
                }

                // Checking for minutes
                if (value.length > 2 ) {
                    if (value.charAt(1) > 5) {
                        return false;
                    }
                }

                return true;
            };

            var callback = function (editedValue) {
                $scope.timeObj.timeInHrsMins = editedValue;
                $scope.propagateTimeChange();
            };


            return showPinPad('time', $scope.timeObj.timeInHrsMins, callback, timeValidator);
        };

        var showPinPad = function (type, model, callback, validator, note) {
            var pinPadInstance = $modal.open({
                templateUrl: 'pos/pos.numpad.tpl.html',
                controller: 'PosNumpadCtrl',
                animation: false,
                windowClass: 'modal-numpad modal-fullscreen-transparent modal-right',
                resolve: {
                    initial: function () {
                        return model;
                    },
                    type: function () {
                        return type;
                    },
                    note: function () {
                        return note;
                    }
                },
                backdrop: true
            });
            pinPadInstance.lucovaValidator = validator;
            return pinPadInstance.result.then(function (value) {
                if (callback) {
                    callback(value);
                }
            });
        };


        $scope.opened = {};
        $scope.toggleDatePicker = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();
            var status = !!$scope.opened.dataDate;
            var newStatus = !status;
            $scope.opened.dataDate = newStatus;
        };
    }]);
};
