'use strict';

const angular = require('angular');

/** Pincode Authentication
  *
  * To trigger pincode authentication, emit a 'PincodeAuthentication:Required' event on $rootScope like this:
  *   ```
  *     $rootScope.$emit('PincodeAuthentication:Required', function(callback, message, actionName) {} )
  *   ```
  * The event accepts a function, which accepts the following parameters:
  *   - callback (optional, but recommended): the function to trigger after pincode authentication is successful
  *   - message (optional): a customized message in the pincode window; defaulted to 'Please enter your pincode.''
  *   - actionName (optional): a customized name for the submit button in the pincode window; defaulted to 'Confirm'
**/

let freshideasSecurityPincode = angular.module('freshideas.security.pincode', [])
    .run(['$rootScope', '$modal', 'SmbPosService', 'CurrentSession', 'CompanyAttributesService', 'EnvConfig',
        function ($rootScope, $modal, SmbPosService, CurrentSession, CompanyAttributesService, EnvConfig) {

        $rootScope.$on('PincodeAuthentication:Required', function (event, params) {
            var callback = params.callback; // callback if pincode is successful
            var errorCallback = params.errorCallback; // callback if pincode fails
            var requestedPermission = params.requestedPermission; // name of permission
            var message = params.message; // messaging for entering pincode
            var actionName = params.actionName; // action label to submit pincode
            var cancelActionName = params.cancelActionName; // action label to cancel submitting pincode
            var verifyAllUserPinsForCompany = params.verifyAllUserPinsForCompany;
            var forceCheckIfManagerLogin = params.forceCheckIfManagerLogin;
            var forceCheckIfSiteAdmin = params.forceCheckIfSiteAdmin;
            var forceAuthenticate = params.forceAuthenticate;
            if ((!CompanyAttributesService.enhanceSecurityMeasures() || EnvConfig.env === 'test') &&
                !forceAuthenticate) {
                callback({user: CurrentSession.getUser(), pin: CurrentSession.getUser().pin});
            } else {
                var modalInstance = $modal.open({
                    templateUrl: 'common/freshideas/pincodeAuthentication.tpl.html',
                    controller: 'PincodeAuthenticationController',
                    windowClass: 'pincode-authentication-modal',
                    modal: true,
                    backdrop: 'static',
                    resolve: {
                        message: function () {
                            return message;
                        },
                        actionName: function () {
                            return actionName;
                        },
                        cancelActionName: function () {
                            return cancelActionName;
                        },
                        requestedPermission: function () {
                            return requestedPermission;
                        },
                        verifyAllUserPinsForCompany: function () {
                            return verifyAllUserPinsForCompany;
                        },
                        forceCheckIfManagerLogin: function () {
                            return forceCheckIfManagerLogin;
                        },
                        forceCheckIfSiteAdmin: function () {
                            return forceCheckIfSiteAdmin;
                        }
                    }
                });

                modalInstance.result.then(function (response) {
                    if (callback) {
                        callback(response);
                    }
                }, function (error) {
                    if (errorCallback) {
                        errorCallback(error);
                    }
                });
            }
        });

    }]);

freshideasSecurityPincode.service('PincodeAuthentication', [
    '$http',
    '$q',
    'Security',
    function ($http, $q, Security) {
        return {
            authenticate: function (pincode, requestedPermission, verifyAllUserPinsForCompany, forceCheckIfManagerLogin,
                forceCheckIfSiteAdmin) {
                var managerAuthenticationRequired = Security.isManagerOverrideRequired(requestedPermission);
                var authenticationUrl = (forceCheckIfSiteAdmin) ?
                    '/freshideas/web/users/validateAdminPin' :
                    ((forceCheckIfManagerLogin) ?
                        '/freshideas/web/users/validateManagerPin' :
                        (managerAuthenticationRequired ?
                            '/freshideas/web/users/validateManagerPin' :
                            '/freshideas/web/users/validatePin'));

                var delay = $q.defer();
                var credentials = {
                    pin: pincode,
                    requestedPermission: requestedPermission,
                    clockedInOnly: (verifyAllUserPinsForCompany) ? false : true
                };

                $http.post(authenticationUrl, credentials)
                    .success(function (user) {
                        var pinObj = {
                            pin: pincode,
                            user: user
                        };
                        delay.resolve(pinObj);
                    })
                    .error(function (error) {
                        delay.reject(error);
                    });

                return delay.promise;
            }
        };
    }]);

freshideasSecurityPincode.controller('PincodeAuthenticationController', [
    '$scope',
    '$timeout',
    'PincodeAuthentication',
    'Security',
    'PosAlertService',
    'message',
    'actionName',
    'cancelActionName',
    'requestedPermission',
    'verifyAllUserPinsForCompany',
    'forceCheckIfManagerLogin',
    'forceCheckIfSiteAdmin',
    'PosStatusService',
    'CommonOfflineCache',
    function ($scope,
        $timeout,
        PincodeAuthentication,
        Security,
        PosAlertService,
        message,
        actionName,
        cancelActionName,
        requestedPermission,
        verifyAllUserPinsForCompany,
        forceCheckIfManagerLogin,
        forceCheckIfSiteAdmin,
        PosStatusService,
        CommonOfflineCache) {
        $scope.managerAuthenticationRequired = Security.isManagerOverrideRequired(requestedPermission);

        $scope.authenticationForm = {};
        $scope.authenticationForm.pincode = '';

        $scope.message = message || ($scope.managerAuthenticationRequired ?
                'general.popup.manager-pin.ttl' : 'Please enter your pincode.');
        $scope.actionName = actionName || 'Confirm';
        $scope.cancelActionName = cancelActionName || 'Cancel';

        $scope.authenticationStatus = 0;

        let timeOutId;
        var startTimer = function () {
            let timeOutInterval = 5000;

            timeOutId = $timeout(function () {
                $scope.$dismiss();
            }, timeOutInterval);
        };

        var resetTimer = function () {
            if (angular.isDefined(timeOutId)) {
                $timeout.cancel(timeOutId);
            }

            startTimer();
        };

        startTimer();
        $(window).on('mousemove mousedown keypress DOMMouseScroll mousewheel touchmove MSPointerMove', resetTimer);

        var isAuthenticating = false;
        $scope.authenticate = function () {
            if (isAuthenticating) {
                return;
            }

            isAuthenticating = true;
            var timerToCancel = $timeout(function () {
                isAuthenticating = false;
            }, 3000);

            $scope.authenticationFail = 0;

            if (PosStatusService.isOffline()) {
                /*
                Since the only offline features that require PIN is opening cash drawer and clockin/clockout,
                we do not need to check if the cached PIN belongs to a manager or site admin.
                */
                CommonOfflineCache.getUserByPin($scope.authenticationForm.pincode, verifyAllUserPinsForCompany).then(function (response) {
                    isAuthenticating = false;
                    if (timerToCancel) {
                        $timeout.cancel(timerToCancel);
                    }
                    if (response) {
                        $scope.authenticationStatus = 1;
                        $scope.$close(response);
                    }
                }, function (error) {
                    isAuthenticating = false;
                    if (timerToCancel) {
                        $timeout.cancel(timerToCancel);
                    }
                    if (error && error.exception && error.exception.appCode === 430) {
                        PosAlertService.showAlertByName('oops-not-clocked-in');
                    } else if (error) {
                        $scope.$dismiss(error);
                    }
                    $scope.$dismiss();
                });
                return;
            }

            PincodeAuthentication.authenticate($scope.authenticationForm.pincode, requestedPermission, verifyAllUserPinsForCompany,
                forceCheckIfManagerLogin, forceCheckIfSiteAdmin)
            .then(function (response) {
                isAuthenticating = false;
                if (timerToCancel) {
                    $timeout.cancel(timerToCancel);
                }

                $scope.authenticationStatus = 1;
                $scope.$close(response);
            }, function (error) {
                isAuthenticating = false;
                if (timerToCancel) {
                    $timeout.cancel(timerToCancel);
                }

                if (error && error.exception && error.exception.appCode === 430) {
                    PosAlertService.showAlertByName('oops-not-clocked-in');
                    $scope.$dismiss();
                } else {
                    $scope.$dismiss(error);
                }
            });
        };

        $scope.enterPin = function (number) {
            var pincode = $scope.authenticationForm.pincode || '';
            pincode = pincode + '' + number;

            $scope.authenticationForm.pincode = pincode;
        };

        $scope.clearPin = function () {
            $scope.authenticationForm.pincode = '';
        };

        $scope.backspacePin = function () {
            var pincode = $scope.authenticationForm.pincode || '';
            pincode = pincode.slice(0, -1);

            $scope.authenticationForm.pincode = pincode;
        };

        $scope.pincodeCallback = function (code) {
            $scope.authenticationForm.pincode = code;
            $scope.authenticate();
        };

    }]);

export default freshideasSecurityPincode;

