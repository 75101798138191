'use strict';

module.exports = function (freshideasLocation) {

    freshideasLocation
    .directive('locationAddCategory', [
        function () {
            return {
                restrict: 'E',
                templateUrl: 'locations/location-side-content/location-add-category/location-add-category.tpl.html',
                controller: 'LocationAddCategoryCtrl',
                scope: {
                    item: '='
                },
                link: function (scope, element, attr) {
                    console.log('location add category');
                }
            };
        }
        ])
    .controller('LocationAddCategoryCtrl', [
        '$scope',
        '$modal',
        '$q',
        '$translate',
        '$location',
        'LocationFactory',
        'Locations',
        'Company',
        'notificationTranslationHelper',
        'ErrorUtil',
        function ($scope, $modal, $q, $translate, $location, LocationFactory, Locations, Company, notificationTranslationHelper, ErrorUtil) {

            $scope.tab = 0;
            $scope.stepValue = 1;

            $scope.item = {'active': true, 'color': '#48BB91'};

            var init = function () {
                console.log('Started Menu List');
            };

            $scope.setTab = function (index) {
                $scope.tab = index;
            };

            var getCloseCallback = function () {
                var callback = LocationFactory.sideContent.callback || {};
                return callback.onClose;
            };

            $scope.cancel = function () {
                var onCloseCallback = getCloseCallback();
                if (onCloseCallback) {
                    onCloseCallback.call(LocationFactory);
                } else {
                    LocationFactory.dismissSideContent();
                }
            };

            $scope.save = function () {

                $scope.item.forceTopLevel = true;
                $scope.item.allowMultipleSelection = true;
                $scope.item.minSelection = -1;
                $scope.item.maxSelection = -1;
                $scope.item.includes = 0;


                LocationFactory.addCategory($scope.item).then(function () {
                    var onCloseCallback = getCloseCallback();
                    if (onCloseCallback) {
                        onCloseCallback.call(LocationFactory);
                    }
                });
            };

            init();
        }
        ]);
};
