'use strict';

const angular = require('angular');

export default angular.module('freshideas.services.notifications', []).factory('notifications', ['$rootScope', 'growl', function ($rootScope, growl) {

    var notifications = {
        MESSAGES: [],
        ERRORS: []
    };
    var notificationService = {};

    notificationService.pushMessage = function (notification) {
        growl.success(notification.message, {ttl: 5000});
        notifications.MESSAGES.push(notification);
    };

    notificationService.pushError = function (notification) {
        growl.error(notification.message);
        notifications.ERRORS.push(notification);
    };

    notificationService.getMessageNotifications = function () {
        return notifications.MESSAGES;
    };

    notificationService.getErrorNotifications = function () {
        return notifications.ERRORS;
    };

    notificationService.remove = function (notification) {
        angular.forEach(notifications, function (notificationsByType) {
            var idx = notificationsByType.indexOf(notification);
            if (idx>-1) {
                notificationsByType.splice(idx, 1);
            }
        });
    };

    notificationService.removeAll = function () {
        angular.forEach(notifications, function (notificationsByType) {
            notificationsByType.length = 0;
        });
    };

    return notificationService;
}]);
