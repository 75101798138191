'use strict';

const freshideasSetup = angular.module('freshideas.setup', ['mgo-angular-wizard', 'angularFileUpload']);
freshideasSetup.controller('SetupCtrl', [
    '$scope',
    '$state',
    'Lookup',
    'CurrentSession',
    'PosAlertService',
    'EnvConfig',
    function ($scope, $state, Lookup, CurrentSession, PosAlertService, EnvConfig) {
        $scope.constants = {
            defaultLocale: 'en-CA',
            countries: [
                {id: 'CA', name: 'Canada', locale: 'en-CA'},
                {id: 'US', name: 'United States', locale: 'en-US'},
                {id: 'JP', name: 'Japan', locale: 'ja-JP'},
                {id: 'GB', name: 'United Kingdom', locale: 'en-GB'}
            ],
            provinces: [
                {id: 'AB', name: 'setupWizard.merchant.address.province.ab.name', taxRate: 5, country: 'Canada'},
                {id: 'BC', name: 'setupWizard.merchant.address.province.bc.name', taxRate: 12, country: 'Canada'},
                {id: 'MB', name: 'setupWizard.merchant.address.province.mb.name', taxRate: 13, country: 'Canada'},
                {id: 'NB', name: 'setupWizard.merchant.address.province.nb.name', taxRate: 15, country: 'Canada'},
                {id: 'NL', name: 'setupWizard.merchant.address.province.nl.name', taxRate: 15, country: 'Canada'},
                {id: 'NS', name: 'setupWizard.merchant.address.province.ns.name', taxRate: 15, country: 'Canada'},
                {id: 'NT', name: 'setupWizard.merchant.address.province.nt.name', taxRate: 5, country: 'Canada'},
                {id: 'NU', name: 'setupWizard.merchant.address.province.nu.name', taxRate: 5, country: 'Canada'},
                {id: 'ON', name: 'setupWizard.merchant.address.province.on.name', taxRate: 13, country: 'Canada'},
                {id: 'PE', name: 'setupWizard.merchant.address.province.pe.name', taxRate: 15, country: 'Canada'},
                {id: 'QC', name: 'setupWizard.merchant.address.province.qc.name', taxRate: 14.975, country: 'Canada'},
                {id: 'SK', name: 'setupWizard.merchant.address.province.sk.name', taxRate: 11, country: 'Canada'},
                {id: 'YT', name: 'setupWizard.merchant.address.province.yt.name', taxRate: 5, country: 'Canada'},
                {id: 'TO', name: 'setupWizard.merchant.address.province.to.name', taxRate: 10, country: 'Japan'},
                {id: 'JS', name: 'setupWizard.merchant.address.province.js.name', taxRate: 10, country: 'United Kingdom'},
                {id: 'AL', name: 'Alabama', country: 'United States'},
                {id: 'AK', name: 'Alaska', country: 'United States'},
                {id: 'AZ', name: 'Arizona', country: 'United States'},
                {id: 'AR', name: 'Arkansas', country: 'United States'},
                {id: 'CA', name: 'California', country: 'United States'},
                {id: 'CO', name: 'Colorado', country: 'United States'},
                {id: 'CT', name: 'Connecticut', country: 'United States'},
                {id: 'DE', name: 'Delaware', country: 'United States'},
                {id: 'FL', name: 'Florida', country: 'United States'},
                {id: 'GA', name: 'Georgia', country: 'United States'},
                {id: 'HI', name: 'Hawaii', country: 'United States'},
                {id: 'ID', name: 'Idaho', country: 'United States'},
                {id: 'IL', name: 'Illinois', country: 'United States'},
                {id: 'IN', name: 'Indiana', country: 'United States'},
                {id: 'IA', name: 'Iowa', country: 'United States'},
                {id: 'KS', name: 'Kansas', country: 'United States'},
                {id: 'KY', name: 'Kentucky', country: 'United States'},
                {id: 'LA', name: 'Louisiana', country: 'United States'},
                {id: 'ME', name: 'Maine', country: 'United States'},
                {id: 'MD', name: 'Maryland', country: 'United States'},
                {id: 'MA', name: 'Massachusetts', country: 'United States'},
                {id: 'MI', name: 'Michigan', country: 'United States'},
                {id: 'MN', name: 'Minnesota', country: 'United States'},
                {id: 'MS', name: 'Mississippi', country: 'United States'},
                {id: 'MO', name: 'Missouri', country: 'United States'},
                {id: 'MT', name: 'Montana', country: 'United States'},
                {id: 'NE', name: 'Nebraska', country: 'United States'},
                {id: 'NV', name: 'Nevada', country: 'United States'},
                {id: 'NH', name: 'New Hampshire', country: 'United States'},
                {id: 'NJ', name: 'New Jersey', country: 'United States'},
                {id: 'NM', name: 'New Mexico', country: 'United States'},
                {id: 'NY', name: 'New York', country: 'United States'},
                {id: 'NC', name: 'North Carolina', country: 'United States'},
                {id: 'ND', name: 'North Dakota', country: 'United States'},
                {id: 'OH', name: 'Ohio', country: 'United States'},
                {id: 'OK', name: 'Oklahoma', country: 'United States'},
                {id: 'OR', name: 'Oregon', country: 'United States'},
                {id: 'PA', name: 'Pennsylvania', country: 'United States'},
                {id: 'RI', name: 'Rhode Island', country: 'United States'},
                {id: 'SC', name: 'South Carolina', country: 'United States'},
                {id: 'SD', name: 'South Dakota', country: 'United States'},
                {id: 'TN', name: 'Tennessee', country: 'United States'},
                {id: 'TX', name: 'Texas', country: 'United States'},
                {id: 'UT', name: 'Utah', country: 'United States'},
                {id: 'VT', name: 'Vermont', country: 'United States'},
                {id: 'VA', name: 'Virginia', country: 'United States'},
                {id: 'WA', name: 'Washington', country: 'United States'},
                {id: 'WV', name: 'West Virginia', country: 'United States'},
                {id: 'WI', name: 'Wisconsin', country: 'United States'},
                {id: 'WY', name: 'Wyoming', country: 'United States'},
            ],
            days: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
            monthlyFees: [20, 40, 60, 80, 100],
            gateways: [
                {id: 'realex', name: 'Realex', actualFieldName: 'realex_lucova_vault'},
                {id: 'paysafe', name: 'Paysafe', actualFieldName: 'paysafe'},
                {id: 'stripe', name: 'Stripe', actualFieldName: 'stripelucovavault'},
                {id: 'moneris', name: 'Moneris', actualFieldName: 'monerislucovavault'},
                {id: 'payeezy', name: 'Payeezy', actualFieldName: 'payeezylucovavault'},
            ],
            fsp: [
                {id: 'other', name: 'Other', fsp_logo: null},
                {id: 'freshideas_management', name: 'FreshIdeas Management', fsp_logo: 'https://nown-assets.s3.amazonaws.com/fresh_ideas_logo_sm.png'},
                {id: 'creative_dining_service', name: 'Creative Dining Service', fsp_logo: 'https://nown-assets.s3.amazonaws.com/creative_logo_sm.png'},
                {id: 'parkhurst_dining_service', name: 'Parkhurst Dining Service', fsp_logo: ' https://nown-assets.s3.amazonaws.com/parkhurst_logo_sm.png'},
                {id: 'avi_food_systems', name: 'AVI FoodSystems', fsp_logo: 'https://nown-assets.s3.amazonaws.com/avi_logo_sm.jpeg'},
                {id: 'american_dining_service', name: 'American Dining Service', fsp_logo: 'https://nown-assets.s3.amazonaws.com/ads_logo_sm.png'},
            ],
            orgTypes: [
                'CAMPUS',
                'COUNTER_SERVE',
                'TABLE_SERVE',
                'SELF_SERVE',
                'RETAIL',
                'WHOLESALE'
            ],
        };

        if (EnvConfig.env !== 'production') {
            $scope.constants.gateways.push({id: 'test', name: 'Stripe (test mode)', actualFieldName: 'stripe'});
        }

        $scope.loadCompany = function (isReload = false) {
            return Lookup.company({}, function (company) {
                if (!company.isSetupComplete &&
                    CurrentSession.getUser().permission !== 'SITEADMIN' && CurrentSession.getUser().permission !== 'FULLADMIN'
                    && !isReload) {
                    PosAlertService.showAlertByName('oops-setup-not-complete');
                }

                $scope.company = company;

                $scope.isRootCompany = ($scope.company.companyId == $scope.company.organization.rootCompanyId);
                $scope.isSubsidiary = $scope.company.isSubsidiary;

                $scope.loadAttributes($scope.company.attributes);

            });
        };

        $scope.loadAttributes = function (attributes) {
            _.each(attributes, function (value, key) {
                // TODO: find better parsing to cover more cases
                if (value === 'true') {
                    attributes[key] = true;
                } else if (value === 'false') {
                    attributes[key] = false;
                } else {
                    attributes[key] = value;
                }
            });
        };

        $scope.checkSetupPermission = function (isRecheck = false) {
            // var currentUser = Security.getUser();
            // var permission = currentUser.permission;

            $scope.loadCompany(isRecheck).$promise.then(function (company) {
                if (company.companyType === 'SMB') {
                    if (company.isProvider) {
                        $state.go('freshideas.setup.paymentProcessor');
                    } else {
                        $state.go('freshideas.setup.merchant');
                        // This check is to only restrict broadcast when a child controller is
                        // accessed. If we always broadcast this event, it will be loaded twice.
                        // If we broadcast before listener is registered, it will not execute.
                        // Hence we will always have to broadcast it on the recheck.
                        if (isRecheck) {
                            $scope.$broadcast('setup::companyLoaded');
                        }
                    }
                } else {
                    $state.go('freshideas.settings');
                }
            });
        };

        $scope.hideSetupWizard = function () {
            return (!CurrentSession.getCompany().isSetupComplete &&
                    CurrentSession.getUser().permission !== 'SITEADMIN' && CurrentSession.getUser().permission !== 'FULLADMIN');
        };

        $scope.checkSetupPermission();
    }]);

require('./setup.payment-processor.js')(freshideasSetup);
require('./setup.merchant.js')(freshideasSetup);
require('./setup.merchant.fiit-mps.js')(freshideasSetup);
require('./setup.merchant.alpha-pay.js')(freshideasSetup);
require('./setup.confirmation.modal.js')(freshideasSetup);
require('./setup.netsuite.modal.js')(freshideasSetup);
require('./setup.employees.modify.js')(freshideasSetup);
require('./setup.employees.timecards.modify.js')(freshideasSetup);
require('./setup.timestamp.modifier.js')(freshideasSetup);
require('./setup.discounts.modify.js')(freshideasSetup);
require('./setup.select.location.js')(freshideasSetup);
require('./importFile.js')(freshideasSetup);
require('./directives/scheduler.js')(freshideasSetup);
require('./directives/ng-thumb.js')(freshideasSetup);
require('./directives/scroll-to-top-when.js')(freshideasSetup);
require('../common/controllers/scanForCardTerminals.ctrl.js')(freshideasSetup);
require('../common/controllers/addCardTerminal.ctrl.js')(freshideasSetup);
require('./setup.email.confirmation.ctrl')(freshideasSetup);
require('../common/controllers/scanForTCPPrinters.ctrl.js')(freshideasSetup);
require('./setup.srm.modal.js')(freshideasSetup);

export default freshideasSetup;
