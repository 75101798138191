'use strict';

const angular = require('angular');

export default angular
    .module('freshideas.resources.alphaPay', [])
    .factory('AlphaPayResource', [
        '$http',
        '$q',
        '$resource',
        'CurrentSession',
        'EnvConfig',
        function (
            $http,
            $q,
            $resource,
            CurrentSession,
            EnvConfig) {
            var AlphaPay = $resource(EnvConfig.fiitHost + '/freshideas/web/gateway/alphapay', {}, {
                'get': {method: 'GET', url: EnvConfig.host + '/freshideas/web/gateway/alphapay'},
                'sync': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/gateway/alphapay/sync'},
                'link': {method: 'POST', url: EnvConfig.host + '/freshideas/web/gateway/alphapay/link'},
                'unlink': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/gateway/alphapay/unlink'},

                'order': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/gateway/alphapay/order'},
                'syncOrder': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/gateway/alphapay/order/:orderUuid/sync'},
                'query': {method: 'GET', url: EnvConfig.host + '/freshideas/web/gateway/alphapay/order/:orderUuid'},
                'void': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/gateway/alphapay/order/void'},
                'refund': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/gateway/alphapay/order/refund'}
            });

            return AlphaPay;
        }]);
