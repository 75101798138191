// How to use this component:
// <rounded-btn name="save" click="save()" width="117px" height="48px" color="#efe13b"></rounded-btn>

module.exports = (angularModule) => {
      angularModule.component('roundedBtn', {
            selector: 'roundedBtn',
            bindings: {
                  name: '@',
                  width: '@',
                  height: '@',
                  click: '&',
                  color: '@'
            },
            template: `<div 
                            lucova-ripple
                            ng-click="$ctrl.click()"
                            class="rounded-btn-component"
                            ng-style="{'height': $ctrl.height ? $ctrl.height: '', 'width': $ctrl.width ? $ctrl.width: '','background-color': $ctrl.color? $ctrl.color: '#F4F5F0'}">
                            {{ $ctrl.name }}
                       </div>`,
            controller: () => {}
      });
};
