'use strict';

const clover = require('remote-pay-cloud');

export function cardTerminalClover (freshideas) {

    freshideas.factory('CardTerminalClover', [
        '$timeout',
        '$log',
        'TerminalEvent',
        'PosAlertService',
        'Locations',
        'SharedDataService',
        'EnvConfig',
        'KioskService',
        function ($timeout, $log, TerminalEvent, PosAlertService, Locations, SharedDataService, EnvConfig, KioskService) {

            var authToken;
            var cloverConnector;
            var cardNameMap = {
              MC: 'MASTERCARD',
              VISA: 'VISA',
              AMEX: 'AMERICAN EXPRESS',
              DEBIT: 'DEBIT',
              INTERAC: 'INTERAC',
              JCB: 'JCB',
              DISCOVER: 'DISCOVER',
              UNIONPAY: 'UNIONPAY'
            };

            var toTransactionDateTime = function (time) {
                var leftPad = function (val) {
                    return ('0' + val).slice(-2);
                };

                var d;
                if (time) {
                    d = new Date(time);
                } else {
                    d = new Date();
                }

                return d.getFullYear()+'-'+ leftPad(d.getMonth()+1) + '-'+ leftPad(d.getDate())
                    + ' ' + leftPad(d.getHours()) + ':' + leftPad(d.getMinutes()) + ':' + leftPad(d.getSeconds());
            };

            var translateEntryMethod = function (key) {
                var obj = {
                    EMV_CONTACTLESS: 'CONTACTLESS',
                    EMV_CONTACT: 'EMV',
                    SWIPED: 'SWIPED',
                };
                return obj[key] || key || '';
            };

            // var translateCvm = function (key) {
                // var obj = {
                    // SIGNATURE: 'SIGNATURE',
                    // PIN: 'PIN',
                    // NO_CVM_REQUIRED: 'NO CVM REQUIRED',
                // };
                // return obj[key] || key || '';
            // };

            var translateTransactionType = function (key) {
                var obj = {
                    NAKED_REFUND: 'REFUND',
                    AUTH: 'SALE',
                };
                return obj[key] || key || '';
            };

            var dig = function (result, ...arr) {
                let pointer = result;

                try {
                    for (let val of arr) {
                        pointer = pointer[val];

                        if (pointer == null) {
                            return null;
                        }
                    }
                } catch (e) {
                    return null;
                }

                return pointer;
            };

            var mapCardNameToNownCardNames = function (result) {
                var cardName = dig(result, 'payment', 'cardTransaction', 'cardType');
                if (cardName) {
                  if (cardNameMap[cardName]) {
                    cardName = cardNameMap[cardName];
                  } else {
                    $log.error({
                      message: 'Card Name cannot be mapped as per local map name',
                      context: {
                          activity: 'cloverCardTransaction',
                          cardName: cardName
                      }
                    });
                  }
                }

                return cardName;
            };

            var parseResponse = function (result) {
                try {

                    let tipAmount = dig(result, 'payment', 'tipAmount') || 0;
                    let amount = dig(result, 'payment', 'amount') || 0;

                    let totalAmount = tipAmount + amount;

                    return {
                        paymentProcessor: 'clover',

                        // terminalId: result[TERMINAL_ID],
                        transactionId: dig(result, 'payment', 'id'),

                        transactionType: translateTransactionType(dig(result, 'payment', 'cardTransaction', 'type')),
                        cardType: dig(result, 'payment', 'tender', 'label'),
                        cardName: mapCardNameToNownCardNames(result),
                        maskedCardNumber: '****' + dig(result, 'payment', 'cardTransaction', 'last4'),
                        cardNumber: '****' + dig(result, 'payment', 'cardTransaction', 'last4'),
                        success: dig(result, 'success'),
                        // balanceDue: result[BALANCE_DUE] ? (result[BALANCE_DUE]/100).toFixed(2) : '0.00',
                        // batchNum: result[BATCH_NUM],
                        tipAmount: (tipAmount/100).toFixed(2),
                        signatureBase64Png: null,
                        // debitAccountType: customerAccountType[result[DEBIT_ACCOUNT_TYPE]],
                        emvAid: dig(result, 'payment', 'cardTransaction', 'extra', 'applicationIdentifier'),
                        // emvTvr: result[EMV_TVR],
                        // emvTsi: result[EMV_TSI],
                        emvAppLabel: dig(result, 'payment', 'cardTransaction', 'extra', 'applicationLabel'),
                        cvmResult: dig(result, 'payment', 'cardTransaction', 'extra', 'cvmResult'),
                        // hostResponseCode: result[HOST_RESPONSE_CODE],
                        // hostResponseIsoCode: result[HOST_RESPONSE_ISO_CODE],
                        isCredit: dig(result, 'payment', 'tender', 'labelKey') == 'com.clover.tender.credit_card',
                        isDebit: dig(result, 'payment', 'tender', 'labelKey') == 'com.clover.tender.debit_card',
                        showCustomerSignatureLine: false,

                        customerLanguage: 'en',
                        // merchantId: result[MERCHANT_ID],
                        transactionAmount: (totalAmount/100).toFixed(2),
                        partiallyApproved: false,
                        // transactionTypeCode: result[TRANSACTION_TYPE],
                        surchargeAmount: null,
                        totalAmount: (totalAmount/100).toFixed(2),
                        approvedAmount: (totalAmount/100).toFixed(2),
                        cardBalance: null,
                        transactionDateTime: toTransactionDateTime(dig(result, 'payment', 'createdTime')),
                        referenceNumber: dig(result, 'payment', 'cardTransaction', 'referenceId'),
                        cardEntryMethod: translateEntryMethod(dig(result, 'payment', 'cardTransaction', 'entryType')),
                        authorizationNumber: dig(result, 'payment', 'cardTransaction', 'authCode'),
                        emvAppPreferredName: dig(result, 'payment', 'cardTransaction', 'extra', 'applicationLabel'),
                        // transactionStatus: result[TRANSACTION_STATUS] || transactionStatus,
                        // verifiedByPin: verifiedByPin,
                        invoiceNumber: dig(result, 'payment', 'order', 'id'),
                        formFactor: null,
                        // transactionSequenceNum: result[TRANSACTION_SEQUENCE_NUM],
                    };
                } catch (e) {
                    $log.error(e);
                    throw e;
                }
            };

            var creditSale = function (amount) {
                return new Promise(function (resolve, reject) {

                    var connected = new Promise(function (connectResolve, connectReject) {
                        var saleListener = Object.assign({}, getDefaultCloverConnectorListener(connectResolve, connectReject), {
                            onSaleResponse: function (response) {
                                var authCode = dig(response, 'payment', 'cardTransaction', 'authCode');
                                try {
                                    if (response.success) {
                                        var parsedResponse = parseResponse(response);

                                        if (Math.round(amount*100) > Math.round(parseFloat(parsedResponse.approvedAmount)*100)) {
                                            if (KioskService.isKiosk()) {
                                                // we don't want to perform a partial auth on Kiosks, and Clover
                                                // has no way to disable them. We will need to void this transaction.
                                                var params = {
                                                    transaction: {
                                                        terminalResponses: [
                                                            {
                                                                cardTerminalResponse: response
                                                            }
                                                        ]
                                                    }
                                                };
                                                creditVoid(parsedResponse.transactionId, params).then(() => {
                                                    cloverConnector.showMessage('Insufficient funds');
                                                    reject();
                                                }).catch((err) => {
                                                    $log.error(err);
                                                    cloverConnector.showMessage('Insufficient funds');
                                                    reject();
                                                });
                                            } else {
                                                parsedResponse.partiallyApproved = true;
                                                cloverConnector.showMessage('Partial Payment Successful\n\n\nAuth #: ' + authCode);
                                            }
                                        } else {
                                            cloverConnector.showMessage('Payment Successful\n\n\nAuth #: ' + authCode);
                                        }

                                        resolve(
                                            [
                                                parsedResponse,
                                                response
                                            ]
                                        );
                                    } else {
                                        cloverConnector.showMessage('Payment Failed');
                                        // avoid clogging HB with canceled payments
                                        if (response && response.result !== 'CANCEL' && !response.message.includes('Invalid state transition:')) {
                                            $log.error(JSON.stringify(response));
                                        }
                                        reject();
                                    }
                                    setTimeout(() => cloverConnector.showWelcomeScreen(), 20000);

                                } catch (e) {
                                    $log.error(e);
                                    throw e;
                                }
                            },

                           onConfirmPaymentRequest: function (request) {
                               try {
                                   cloverConnector.acceptPayment(request.getPayment());
                               } catch (e) {
                                   $log.error(e);
                                   throw e;
                               }
                           },

                           onVerifySignatureRequest: function (request) {
                               try {
                                   cloverConnector.acceptSignature(request);
                               } catch (e) {
                                   $log.error(e);
                                   throw e;
                               }
                           }
                       });

                       cloverConnector.addCloverConnectorListener(saleListener);
                       cloverConnector.initializeConnection();
                   });

                   connected.then(function () {
                       try {
                           const saleRequest = new clover.remotepay.SaleRequest();
                           saleRequest.setExternalId(clover.CloverID.getNewId());
                           saleRequest.setAmount(Math.round(amount*100));
                           saleRequest.setAutoAcceptSignature(false);
                           saleRequest.setDisableCashback(true);
                           // saleRequest.setCardEntryMethods(34824 | 33025);

                           // Perform the sale.
                           cloverConnector.sale(saleRequest);
                        } catch (e) {
                            $log.error(e);
                            throw e;
                        }
                    }).catch(function () {
                        reject('Failed to connect');
                    });
                });
            };

            var creditVoid = function (transactionId, params={}) {
                return new Promise(function (resolve, reject) {
                    var transaction = params.transaction;

                    var cloverTransaction = JSON.parse(transaction.terminalResponses[0].cardTerminalResponse);

                    // lets configure connection callbacks
                    // configure voidPaymentResponse
                    // call initializeConnection inside a promise
                    // call void payment

                    var connected = new Promise(function (connectResolve, connectReject) {
                        var voidListener = Object.assign({}, getDefaultCloverConnectorListener(connectResolve, connectReject), {
                           onVoidPaymentResponse: function (response) {
                               if (response.success) {
                                   resolve([
                                       parseResponse(response),
                                       response
                                   ]);
                               } else {
                                   reject();
                               }
                           }
                        });
                        cloverConnector.addCloverConnectorListener(voidListener);
                        cloverConnector.initializeConnection();
                    });

                    connected.then(function () {
                        const voidRequest = new clover.remotepay.VoidPaymentRequest();
                        // voidRequest.setEmployeeId();
                        voidRequest.setOrderId(cloverTransaction.payment.order.id);
                        voidRequest.setPaymentId(transactionId);
                        voidRequest.setVoidReason('USER_CANCEL');
                        cloverConnector.voidPayment(voidRequest);
                    }).catch(function () {
                        reject('Failed to connect');
                    });

                });
            };

            var debitSale = function (amount) {
                return creditSale(amount);
            };

            var creditReturn = function (amount) {
                return new Promise(function (resolve, reject) {

                    var connected = new Promise(function (connectResolve, connectReject) {
                        var refundListener = Object.assign({}, getDefaultCloverConnectorListener(connectResolve, connectReject), {
                           onRefundResponse: function (response) {
                               console.log('onRefundResponse');
                               console.log(response);
                           },
                           onManualRefundResponse: function (response) {
                               console.log('onManualRefundResponse');
                               console.log(response);
                               if (response.success) {
                                   // hack
                                   response.payment = response.credit;
                                   resolve([
                                       parseResponse(response),
                                       response
                                   ]);
                               } else {
                                   reject();
                               }
                           }
                        });
                        cloverConnector.addCloverConnectorListener(refundListener);
                        cloverConnector.initializeConnection();
                    });

                    connected.then(function () {
                        const refundRequest = new clover.remotepay.ManualRefundRequest();
                        refundRequest.setAmount(Math.round(amount*100));
                        refundRequest.setExternalId(Math.round((Math.random()*10000000000000)));
                        cloverConnector.manualRefund(refundRequest);
                    }).catch(function () {
                        reject('Failed to connect');
                    });

                });
            };

            var debitReturn = function (amount) {
                return creditReturn(amount);
            };

            var testSale = function (amount) {
                return creditSale(0.01);
            };

            var modal;

            var cleanup = function () {
                if (cloverConnector) {
                    cloverConnector.dispose();
                    cloverConnector = null;
                }
            };

            var init = function (terminalConfig) {
                cleanup();

                var onPairingCode = function (code) {
                    modal = PosAlertService.showAlertByName('general',
                        {
                            title: `Enter the code ${code} on your clover terminal`,
                            message: 'Your clover terminal needs to be paired. Please enter the above code on your clover terminal to connect it to Nōwn.',
                        },
                        true);
                };

                var onPairingSuccess = function (newAuthToken) {
                    if (modal) {
                        modal.dismiss();
                        modal = null;
                    }

                    authToken = newAuthToken;
                    Locations.updatePOSStationAttributes(
                        {},
                        {
                            posStationId: SharedDataService.posStationId,
                            locationId: SharedDataService.locationId,
                            cardTerminalProperties: {
                                authToken: newAuthToken
                            }
                        },
                        function (response) {
                        },
                        function (error = {}) {
                            if (!error.message || !error.message.includes('TypeError:')) {
                                $log.error('Failed to save Clover auth token for location: ' + SharedDataService.locationId + ' error: ' + error);
                            }
                        }
                    );
                };


// curl https://sandbox.dev.clover.com/v3/merchants/CZAKV0HSPD901/devices\?access_token\=be8d6637-1846-9c14-924e-911f744240a9 | pp_json
  // % Total    % Received % Xferd  Average Speed   Time    Time     Time  Current
                                 // Dload  Upload   Total   Spent    Left  Speed
// 100   545  100   545    0     0   2202      0 --:--:-- --:--:-- --:--:--  2206
// {
    // "elements": [
        // {
            // "href": "https://sandbox.dev.clover.com/v3/merchants/CZAKV0HSPD901/devices/1747e763-9c3c-1722-b0e1-7f6bc2332e62",
            // "id": "1747e763-9c3c-1722-b0e1-7f6bc2332e62",
            // "model": "Clover_C401U",
            // "merchant": {
                // "id": "CZAKV0HSPD901"
            // },
            // "terminalPrefix": 0,
            // "serial": "C042UQ83430125",
            // "secureId": "e960859e45c2e6b4",
            // "buildType": "USER",
            // "deviceTypeName": "BAYLEAF",
            // "productName": "Flex",
            // "pinDisabled": false,
            // "offlinePayments": true,
            // "offlinePaymentsAll": false
        // }
    // ],
    // "href": "http://sandbox.dev.clover.com/v3/merchants/CZAKV0HSPD901/devices"
// }


                var raid = EnvConfig.env === 'production' ? 'PX71DTC31KJRM.06JDJAZBQ0N34' : 'WS39W7B4YTXQA.61FGVHGXG5BAG';
                var host = EnvConfig.env === 'production' ? 'www.clover.com' : 'sandbox.dev.clover.com';
                var pairedConfiguration;
                if (terminalConfig.type == 'clover_cloud') {
                    const cloudConfigurationBuilder = new clover.WebSocketCloudCloverDeviceConfigurationBuilder(
                      raid, // RAID
                      terminalConfig.deviceId, // deviceId (returned from clover when devices are queried)
                      terminalConfig.merchantId, // merchantId   (in companyattributesecure)
                      terminalConfig.accessToken, // (in companyattributesecure)
                    );

                    // const cloudConfigurationBuilder = new clover.WebSocketCloudCloverDeviceConfigurationBuilder(
                      // 'WS39W7B4YTXQA.61FGVHGXG5BAG', // RAID
                      // '1747e763-9c3c-1722-b0e1-7f6bc2332e62', // deviceId (returned from clover when devices are queried)
                      // 'CZAKV0HSPD901', // merchantId   (in companyattributesecure)
                      // 'c78567e0-8c5c-d935-2321-6293c5ae8ba1', // (in companyattributesecure)
                    // );

                    const connectionConfiguration = {
                        cloverServer: 'https://' + host,
                        friendlyId: 'NownPOS',
                    };

                    pairedConfiguration = cloudConfigurationBuilder.setCloverServer(connectionConfiguration.cloverServer)
                        .setFriendlyId(connectionConfiguration.friendlyId)
                        .build();
                } else {
                    var url = 'wss://' + terminalConfig.ip + ':' + terminalConfig.port + '/remote_pay';
                    authToken = authToken || terminalConfig.authToken;

                    const networkConfigurationBuilder = new clover.WebSocketPairedCloverDeviceConfigurationBuilder(
                      raid, // applicationId
                      url,
                      'NownPOS', // posName
                      'Register_1', // serialNumber
                      authToken,
                      onPairingCode,
                      onPairingSuccess
                    );

                    pairedConfiguration = networkConfigurationBuilder.build();
                }

                var builderConfiguration = {};
                builderConfiguration[clover.CloverConnectorFactoryBuilder.FACTORY_VERSION] = clover.CloverConnectorFactoryBuilder.VERSION_12;

                var cloverConnectorFactory = clover.CloverConnectorFactoryBuilder.createICloverConnectorFactory(builderConfiguration);
                cloverConnector = cloverConnectorFactory.createICloverConnector(pairedConfiguration);
            };

            var getDefaultCloverConnectorListener = function (resolve, reject) {
                return Object.assign({}, clover.remotepay.ICloverConnectorListener.prototype, {
                   onDeviceReady: function (merchantInfo) {
                       console.log({message: 'Device Ready to process requests!', merchantInfo: merchantInfo});
                       resolve();
                   },
                   onDeviceDisconnected: function () {
                       console.log({message: 'Disconnected'});
                       reject();
                   },
                   onDeviceConnected: function () {
                       console.log({message: 'Connected, but not available to process requests'});
                   },
                   onDeviceError: function (error) {
                       console.log({message: error});
                       reject();
                   },
                });
            };

            var isCancellableFromPos = function () {
                return false;
            };

            return {
                init: init,
                creditSale: creditSale,
                creditVoid: creditVoid,
                creditReturn: creditReturn,
                debitSale: debitSale,
                debitReturn: debitReturn,
                testSale: testSale,
                // autoSettle: autoSettle,
                parseResponse: parseResponse,
                isCancellableFromPos: isCancellableFromPos,
            };

    }]);

}
