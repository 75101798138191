'use strict';

module.exports = function (freshideasSetup) {

    freshideasSetup.controller('MerchantSetupFiitMpsCtrl', [
        '$scope',
        'GatewayService',
        'Security',
        'GatewayFiit',
        'Locations',
        '$modal',
        function (
            $scope,
            GatewayService,
            Security,
            GatewayFiit,
            Locations,
            $modal
        ) {

            var currentCompany = Security.getUser().company;
            var FiitMpsService = GatewayService.init('fiitmps');

            $scope.disabledFiitMenuImport = currentCompany.attributes.disable_fiit_menu_import === 'true';
            $scope.hasFiitMps = GatewayFiit.isEnabled();

            $scope.locationServicePeriods = [];
            $scope.locationMealPlans = [];
            $scope.overrideAllowed = false;

            $scope.model = {};

            $scope.forms = {
                linkToFiitMpsForm: {}
            };

            var LINK_STATUS = {
                'UNLINKED': 'unlinked',
                'LINKED': 'linked',
                'NOT_APPLICABLE': 'not applicable'
            };
            $scope.linkStatus = LINK_STATUS.UNLINKED;

            $scope.getLinkStatus = function () {
                if (canConnectToFiit()) {
                    FiitMpsService
                        .get(onLinkStatusRetrieved)
                        .catch(onLinkStatusError);
                } else {
                    $scope.linkStatus = LINK_STATUS.NOT_APPLICABLE;
                }
            };
            var onLinkStatusRetrieved = function (response) {
                if (FiitMpsService.fiitMps && FiitMpsService.fiitMps.locationId) {
                    $scope.linkStatus = LINK_STATUS.LINKED;
                    $scope.fiitMps = FiitMpsService.fiitMps;
                    $scope.hasFiitMps = GatewayFiit.isEnabled();

                    let location = FiitMpsService.getLocation();
                    if (location) {
                        $scope.locationServicePeriods.push(...location.servicePeriods || []);
                        $scope.locationMealPlans.push(...location.mealPlans || []);
                        $scope.overrideAllowed = location.overrideAllowed;
                    }
                } else {
                    $scope.linkStatus = LINK_STATUS.UNLINKED;
                    $scope.fiitMps = undefined;
                }
            };
            var onLinkStatusError = function (error) {
                var data = error.data || {};
                var message = data.error || '';

                $scope.linkErrors.push({
                    type: message
                });
            };

            var canConnectToFiit = function () {
                return !Security.isTrial();
            };

            $scope.linkSetup = {
                credential: {
                    accessToken: '',
                    url: ''
                },
                linking: false
            };
            var resetLinkSetup = function () {
                $scope.linkSetup.credential = {
                    accessToken: '',
                    url: ''
                };
                $scope.linkSetup.linking = false;
            };

            // Errors from Nown
            $scope.linkErrors = [];
            // Errors from Lucova
            // NOTE: This will generally only show if the Nown linking process was successful
            // because the Lucova linking only occurs after a successful Nown linking
            $scope.lucovaErrorMessages = [];
            $scope.linkToFiitMps = function () {
                $scope.linkErrors = [];
                $scope.lucovaErrorMessages = [];
                $scope.linkSetup.linking = true;

                var validationErrors = FiitMpsService.validateLink($scope.linkSetup.credential);
                if (validationErrors && validationErrors.length) {
                    $scope.linkErrors = validationErrors;
                    $scope.linkSetup.linking = false;
                    return;
                }

                return FiitMpsService.link($scope.linkSetup.credential)
                    .then(onFiitMpsLinked)
                    .catch(onFiitMpsLinkError)
                    .then(function () {
                        $scope.linkSetup.linking = false;
                    });
            };
            var onFiitMpsLinked = function (response) {
                // Show errors with Lucova linking process if any occured
                if (response.attributes && response.attributes.lucovaError) {
                    $scope.lucovaErrorMessages.push(response.attributes.lucovaErrorMessage);
                }

                resetLinkSetup();
                $scope.getLinkStatus();
            };
            var onFiitMpsLinkError = function (error = {}) {
                var data = error.data || {};
                var message = data.error || '';

                if (error.status === -1) {
                    message = 'setupWizard.merchant.linkToFiitMps.cannotConnect.description';
                }

                $scope.linkErrors.push({
                    type: message
                });
            };

            $scope.syncToFiitMps = function () {
                $scope.linkErrors = [];
                $scope.lucovaErrorMessages = [];
                $scope.linkSetup.linking = true;

                return FiitMpsService.sync()
                    .then(onFiitMpsLinked)
                    .catch(onFiitMpsSyncError)
                    .then(function () {
                        $scope.linkSetup.linking = false;
                    });
            };
            var onFiitMpsSyncError = function (error = {}) {
                var data = error.data || {};
                var message = data.error || '';

                if (error.status === -1) {
                    message = 'setupWizard.merchant.linkToFiitMps.cannotSync.description';
                }

                $scope.linkErrors.push({
                    type: message
                });
            };

            $scope.unlinkFromFiitMps = function () {
                $scope.linkErrors = [];
                $scope.lucovaErrorMessages = [];

                return FiitMpsService.unlink()
                    .then(onFiitMpsUnlink)
                    .catch(onFiitMpsUnlinkError)
                    .then(function () {
                        $scope.linkSetup.linking = false;
                    });
            };
            var onFiitMpsUnlink = function (response) {
                onLinkStatusRetrieved(response);
            };
            var onFiitMpsUnlinkError = function (error) {
                var data = error.data || {};
                var message = data.error || '';

                $scope.linkErrors.push({
                    type: message
                });
            };

            $scope.pendingFiitMenuImport = null;

            async function loadPendingFiitMenuImport () {
                if (!$scope.hasFiitMps) {
                    return;
                }
                const pendingImport = await Locations.getPendingFiitMenuImport().$promise;
                $scope.pendingFiitMenuImport = pendingImport;
            }

            $scope.importMenuFromFiit = function () {
                if ($scope.disabledFiitMenuImport || $scope.pendingFiitMenuImport.status) {
                    return;
                }
                showEmailPrompt().then((response) => {
                    if (!response) {
                        return;
                    }
                    const job = {
                        statusRecipients: response
                    };
                    Locations.enqueueFiitMenuImportJob(job).$promise
                        .then((result) => {
                            $scope.pendingFiitMenuImport = result;
                        });
                });
            };

            function showEmailPrompt () {
                return new Promise((resolve, reject) => {
                    var modalInstance = $modal.open({
                        templateUrl: 'products/templates/product.enter.email.html',
                        controller: 'ProductEmailPromptCtrl',
                        windowClass: 'modal-50 products2',
                        animation: false,
                    });

                    modalInstance.result.then(function (response) {
                        resolve(response);
                    });
                });
            }

            $scope.init = function () {
                $scope.getLinkStatus();
                loadPendingFiitMenuImport();
            };
            $scope.init();
        }
    ]);

};
