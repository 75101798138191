'use strict';

module.exports = function (freshideasLocation) {

    freshideasLocation.controller('LocationMenuList', [
        '$scope',
        '$modal',
        '$q',
        '$translate',
        '$location',
        '$document',
        'CashierShift',
        'LocationFactory',
        'Locations',
        'Company',
        'notificationTranslationHelper',
        'ErrorUtil',
        function ($scope, $modal, $q, $translate, $location, $document, CashierShift, LocationFactory, Locations, Company, notificationTranslationHelper, ErrorUtil) {

            $scope.locationFactory = LocationFactory;

            var init = function () {
                console.log('Started Menu List');
            };

            $scope.addItem = function () {
                $scope.locationFactory.showAddItem();
            };

            $scope.editListItem = function (ev, item) {
                ev.stopPropagation();

                if (item.type === 'category') {
                    $scope.locationFactory.showEditCategory(item);

                } else if (item.type === 'modifier') {
                    $scope.locationFactory.showEditModifier(item);

                } else if (item.type === 'item') {
                    $scope.locationFactory.showEditItem(item);
                }
            };

            $scope.showItemList = function (ev, item) {

                LocationFactory.navigateTree(item);
                ev.stopPropagation();
            };
            init();
        }
        ]);
};
