'use strict';

const angular = require('angular');

var freshIdeasGatewayService = angular.module('freshideas.services.gateway', []);
freshIdeasGatewayService.factory('GatewayService', [
        'CurrentSession',
        'GatewayFiit',
        'GatewayAlphaPay',
        function (
            CurrentSession,
            GatewayFiit,
            GatewayAlphaPay) {

            var service = {};
            service.get = function () {};
            service.sync = function () {};
            service.link = function () {};
            service.unlink = function () {};
            service.validateLink = function () {};

            service.getInfo = function () {

            };

            service.getAccount = function () {

            };

            service.init = function (type) {
                switch (type) {
                    case 'fiitmps':
                        return GatewayFiit;
                    case 'alphaPay':
                        return GatewayAlphaPay;
                }

                return null;
            };

            return service;
        }
    ]);

require('./gateway-fiit.js')(freshIdeasGatewayService);
require('./gateway-alphapay.js')(freshIdeasGatewayService);

export default freshIdeasGatewayService;
