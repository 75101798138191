'use strict';


module.exports = function (freshideasPosInventoryV2) {
    freshideasPosInventoryV2.controller('InventoryV2OrderCtrl', [
        '$scope',
        '$modal',
        '$q',
        '$rootScope',
        '$timeout',
        'ORDER_STATUS',
        'CashierShift',
        'ConfirmModalService',
        'InventoryV2Service',
        'PosAlertService',
        'CurrentSession',
        'order',
        function ($scope, $modal, $q, $rootScope, $timeout, ORDER_STATUS, CashierShift, ConfirmModalService, InventoryV2Service, PosAlertService, CurrentSession, order) {
            $scope.ORDER_STATUS = ORDER_STATUS;
            $scope.order = angular.copy(order);
            $scope.order.items = $scope.order.items || [];

            var initializeOrderItems = function () {
                for (var item of $scope.order.items) {
                    item.receivedQuantity = item.receivedQuantity || 0;
                    item.diff = item.receivedQuantity - item.expectedQuantity;

                    if (item.storageLocation) {
                        var locationArr = item.storageLocation.split(',');
                        item.storageLocationDisplay = locationArr[0];
                    }
                }
            };

            var VIEWS = $scope.VIEWS = {
                MAIN: 'main',
                STASH: 'stash',
                SCAN_ITEMS: 'scan_items',
                SCAN_LOCATION: 'scan_location'
            };

            $scope.currentView = $scope.VIEWS.MAIN;
            $scope.switchView = function (view) {
                $scope.currentView = view;
            };

            $scope.stashOrder = function (order) {
                var modalInstance = $modal.open({
                    templateUrl: 'inventory/v2/templates/inventory.order.stash.tpl.html',
                    controller: 'InventoryV2OrderStashCtrl',
                    windowClass: 'inventory-modal',
                    resolve: {
                        order: function () {
                            return order;
                        }
                    }
                });

                modalInstance.result.then(function (result) {
                   $scope.$close(result);
                });
            };

            $scope.startItemScans = function () {
               /* return fetchMenuItemsInOrder()
                    .then(function (result) {
                        matchOrderItemsToMenuItems(result);
                        $scope.switchView(VIEWS.SCAN_ITEMS);
                    });*/
                    $scope.switchView(VIEWS.SCAN_ITEMS);
            };


            // Skipping this step as validation is done on the backend while inserting an order
            /* var fetchMenuItemsInOrder = function () {
                // The idea here is to pre-fetch all the SKUs in the order and the
                // corresponding menu items, rather than fetch the item on every scan
                // (which can slow down the scanning). This can probably also be done at
                // the end of the scan but there might be more benefits doing a pre-fetch
                // (eg. validation, etc)
                var upcs = _.map($scope.order.items, function (item) {
                    return item.sku;
                });
                var params = {
                    upcs: upcs
                };

                return CashierShift.lookupUpcs(params);
            };*/

            // Skipping this step as validation is done on the backend while inserting an order
            /* var matchOrderItemsToMenuItems = function (menuItems) {
                var menuItemMap = {};
                for (var menuItem of menuItems) {
                    if (menuItem.upc) {
                        menuItemMap[menuItem.upc] = menuItem;
                    }
                }

                for (var orderItem of $scope.order.items) {
                    var orderItemSku = orderItem.sku;
                    if (menuItemMap[orderItemSku]) {
                        orderItem.data = orderItem.data || {};
                        orderItem.data.menuItem = menuItemMap[orderItemSku];
                    }
                    // TODO: consider throwing error/preventing user from going to next
                    // step when not all order items can be mapped.
                }
            };*/

            $scope.startLocationScans = function () {
                checkIfHasDiscrepancies();
                $scope.switchView(VIEWS.SCAN_LOCATION);
            };

            $scope.onScanned = function (code) {
                switch ($scope.currentView) {
                    case VIEWS.SCAN_ITEMS:
                        $scope.onSkuScanned(code);
                        break;
                    case VIEWS.SCAN_LOCATION:
                        $scope.onLocationScanned($scope.currentItem, code);
                        break;
                    default:
                        // DO NOTHING
                        break;
                }
            };

            var hasChanges = false;

            var isScanningItem = false;
            $scope.onSkuScanned = function (code) {
                if (isScanningItem) {
                    return;
                }

                var timerToCancel = $timeout(function () {
                    isScanningItem = false;
                }, 6000);

                var foundItem = _.findWhere($scope.order.items, {sku: code});
                isScanningItem = true;
                if (!foundItem) {
                    var modalInstance = $modal.open({
                            templateUrl: 'common/modals/modalScanningItem.tpl.html',
                            controller: 'ScanningItemModalCtrl',
                            windowClass: 'smb-pos products2',
                            animation: false,
                            backdrop: 'static',
                            resolve: {
                                sku: function () {
                                    return code;
                                }
                            }
                        }, function (error) {});

                    var itemToSearch = {
                        upc: code,
                        companyId: CurrentSession.getCompany().companyId
                    };
                    InventoryV2Service.searchMenuItem(itemToSearch).then(function (response) {
                        if (timerToCancel) {
                            $timeout.cancel(timerToCancel);
                        }

                        if (modalInstance) {
                            modalInstance.close();
                        }

                        foundItem = {
                            diff: 0,
                            expectedQuantity: 0,
                            id: undefined,
                            inventoryOrderId: order.orderId,
                            menuItemId: response.locationServicePeriodMenuId,
                            name: response.name,
                            receivedQuantity: 0,
                            sku: code,
                            storageLocation: null
                        };

                        $scope.order.items.push(foundItem);
                        isScanningItem = false;
                        incrementItemCount(foundItem, code);
                    }, function (error) {
                        if (timerToCancel) {
                            $timeout.cancel(timerToCancel);
                        }
                        if (modalInstance) {
                            modalInstance.close();
                        }
                        isScanningItem = false;
                        PosAlertService.showAlertByName('pincode-fail', {
                            message: 'Item for this SKU could not be found. Please contact your IT support.',
                            title: 'Item Search Failed'
                        });
                    });
                } else {
                    isScanningItem = false;
                    incrementItemCount(foundItem, code);
                }
            };

            var incrementItemCount = $scope.incrementItemCount = function (foundItem, code, manual) {
                if (foundItem) {
                    $timeout(function () {
                        foundItem.receivedQuantity = foundItem.receivedQuantity || 0;
                        foundItem.receivedQuantity++;

                        foundItem.diff = foundItem.receivedQuantity - foundItem.expectedQuantity;

                        $scope.lastScanned = angular.copy(foundItem);
                        hasChanges = true;
                        checkIfHasDiscrepancies();
                    }, 0);
                }

            };

            $scope.decrementItemCount = function (foundItem, code, manual) {
                if (foundItem) {
                    $timeout(function () {
                        foundItem.receivedQuantity = foundItem.receivedQuantity || 0;
                        foundItem.receivedQuantity--;
                        foundItem.receivedQuantity = Math.max(0, foundItem.receivedQuantity);

                        foundItem.diff = foundItem.receivedQuantity - foundItem.expectedQuantity;

                        $scope.lastScanned = angular.copy(foundItem);
                        hasChanges = true;
                        checkIfHasDiscrepancies();
                    }, 0);
                }
            };

            $scope.resetItemCount = function () {
                ConfirmModalService.openDarkConfirmModal(
                    'Reset Item Scan',
                    'You will lose all the item received quantity counts. Are you sure?',
                    confirmResetItemCount,
                    {},
                    $scope,
                    true
                );
            };
            var confirmResetItemCount = function () {
                for (var item of $scope.order.items) {
                    item.receivedQuantity = 0;
                    // TODO: remove manually added items;
                }
                $scope.lastScanned = undefined;
                hasChanges = false;
                checkIfHasDiscrepancies();
            };

            $scope.exit = function () {
                if (hasChanges) {
                    ConfirmModalService.openDarkConfirmModal(
                        'Exit Shipment Order',
                        'You will lose all the progress. Are you sure?',
                        confirmExit,
                        {},
                        $scope,
                        true
                    );
                } else {
                    confirmExit();
                }
            };
            var confirmExit = function () {
                $scope.$dismiss();
            };

            $scope.startItemLocationScan = function (item) {
                var modalInstance = $modal.open({
                    templateUrl: 'inventory/v2/templates/inventory.item.storageScan.tpl.html',
                    controller: 'InventoryV2ItemStorageScanCtrl',
                    windowClass: 'inventory-modal',
                    resolve: {
                        item: function () {
                            return item;
                        }
                    }
                });

                modalInstance.result.then(function (result) {
                    item.storageLocation = result.storageLocation;
                    item.storageLocationDisplay = result.storageLocationText;
                });
            };

            var checkIfHasDiscrepancies = function () {
                $scope.hasDiscrepancies = false;
                var totalReceivedInventory = 0;
                if ($scope.order.items && $scope.order.items.length) {
                    var items = $scope.order.items;
                    for (var x = 0; x < items.length; x++) {
                        totalReceivedInventory += items[x].receivedQuantity;
                        if (items[x].diff) {
                            $scope.hasDiscrepancies = true;
                        }
                    }
                }

                if (totalReceivedInventory != parseInt($scope.order.quantity)) {
                    $scope.hasDiscrepancies = true;
                }

                return;
            };

            $scope.completeOrder = function () {
                var params = {
                    callback: confirmCompleteOrder,
                    errorCallback: pinVerificationFailed,
                    forceAuthenticate: true,
                    requestedPermission: ''
                };
                $rootScope.$emit('PincodeAuthentication:Required', params);
            };
            var confirmCompleteOrder = function () {
                var orderObj = Object.assign({}, $scope.order);
                InventoryV2Service.receiveOrder(orderObj).then(function (response) {
                    $scope.$close();
                }, completeOrderFailed);
            };
            var pinVerificationFailed = function (error) {
                var data = error.data || {};
                var exception = data.exception || {};

                PosAlertService.showAlertByName('pincode-fail', {
                    message: exception.message || ''
                });
            };

            var completeOrderFailed = function (error) {
                var data = error.data || {};
                var exception = data.exception || {};

                PosAlertService.showAlertByName('pincode-fail', {
                    message: exception.message || 'general.error.default',
                    title: 'Save Order Failed'
                });
            };
            $scope.init = function () {
                initializeOrderItems();
            };

            $scope.init();
        }
    ]);
};
