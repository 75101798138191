'use strict';

export function breadCrum (freshideas) {
    freshideas.controller('BreadCrumbCtrl', [
        '$scope',
        'breadcrumbs',
        function ($scope, breadcrumbs) {
            $scope.breadcrumbs = breadcrumbs;
        }
    ]);
}
