'use strict';

const angular = require('angular');

export default angular
    .module('freshideas.resources.ios-tcp-client-v2', [])
    .factory('IosTcpClientV2', [
        'BridgedPromise',
        'Platform',
        '$log',
        '$q',
        function (
            BridgedPromise,
            Platform,
            $log,
            $q) {
        var _self = {};

        // have a function that just listens for tcp events

        // need a way to receive the continuous stream of responses
        // but also send messages at any point

        if (Platform.isIosWebkit()) {
            _self.connect = function (port, ip) {
                var dataCallback;
                var connected = false;
                var closeCallback;
                var endCallback;

                var _retryAction = function (action, numTries) {
                    return $q.when()
                        .then(action)
                        .catch(function (error) {
                            if (numTries <= 0) {
                                throw error;
                            }
                            return _retryAction(action, numTries - 1);
                        });
                };

                var onData = function (callback) {
                    dataCallback = callback;
                    var message = {
                        type: 'TcpProxyOnData'
                    };

                    window.addEventListener('TcpProxyOnData', dataHandler);

                    window.webkit.messageHandlers.bridge.postMessage(message);
                };

                var dataHandler = function (response) {
                    // convert from base64

                    var base64 = response.detail.data;
                    var data = atob(base64);

                    if (dataCallback) {
                        dataCallback(data, base64);
                    }
                };

                var end = function () {

                    BridgedPromise.dispatch('TcpProxyDisconnect', {}).then(function () {
                    });
                    removeAllListeners('data');
                    connected = false;
                    if (endCallback) {
                        endCallback();
                    }
                    if (closeCallback) {
                        closeCallback();
                    }
                };

                var write = function (data) {
                    // convert to base64
                    data = btoa(data);
                    return BridgedPromise.dispatch('TcpProxyWrite', {data: data});
                };

                var isConnected = function () {
                    return connected;
                };

                var on = function (event, callback) {
                    if (event === 'data') {
                        onData(callback);
                    // } else if (event === 'error') {
                    } else if (event === 'close') {
                        closeCallback = callback;
                    } else if (event === 'end') {
                        endCallback = callback;
                    // } else if (event === 'timeout') {
                    }
                };
                var removeAllListeners = function (event) {
                    if (event === 'data') {
                        window.removeEventListener('TcpProxyOnData', dataHandler);
                        dataCallback = null;
                    // } else if (event === 'error') {
                    } else if (event === 'close') {
                        closeCallback = null;
                    } else if (event === 'end') {
                        endCallback = null;
                    // } else if (event === 'timeout') {
                    }
                };

                var promise = new Promise(function (resolve, reject) {

                    // Retry connecting to card terminal up to five times
                    // An exception will get thrown and reach the catch block below
                    // upon the fifth failure.
                    _retryAction(
                        function () {
                            return BridgedPromise.dispatch('TcpProxyConnect', {
                                host: ip,
                                port: port
                            });
                        },
                        5
                    ).then(function () {
                        connected = true;

                        resolve({
                            write: write,
                            on: on,
                            end: end,
                            removeAllListeners: removeAllListeners,
                            isConnected: isConnected,
                        });
                    }).catch(function () {
                        // This message should get logged by Honeybadger higher up
                        // WARNING: Do not reject with empty payload because
                        // cardterminal.moneris, cardterminal.globalpay etc.
                        // all expect some kind of valid error object to be passed into
                        // their respective catch blocks.
                        //
                        // This string will work as well
                        reject('TCP: iOS failed to connect to card terminal 5 times');
                    });
                });

                return promise;
            };
        } else if (Platform.isTizen()) {
            _self.connect = function (port, ip) {
                var dataCallback;
                var connected = false;
                var closeCallback;
                var endCallback;

                var _retryAction = function (action, numTries) {
                    return $q.when()
                        .then(action)
                        .catch(function (error) {
                            if (numTries <= 0) {
                                throw error;
                            }
                            return _retryAction(action, numTries - 1);
                        });
                };

                var onData = function (callback) {
                    dataCallback = callback;

                    window.addEventListener('paymentResponse', dataHandler);
                };

                var dataHandler = function (response) {
                    // convert from base64

                    // var base64 = response.detail.data;
                    // var data = atob(base64);

                    var data = response.detail.data;
                    var base64 = data;

                    if (dataCallback) {
                        dataCallback(data, base64);
                    }
                };

                var end = function () {
                    window.parent.postMessage(
                        {
                            msgType: 'paymentDisconnect',
                            message: {
                                ip: ip,
                                port: port,
                            }
                        }, '*');

                    removeAllListeners('data');
                    connected = false;
                    if (endCallback) {
                        endCallback();
                    }
                    if (closeCallback) {
                        closeCallback();
                    }
                };

                var write = function (data) {
                    // convert to base64
                    // data = btoa(data);

                    window.parent.postMessage(
                        {
                            msgType: 'paymentCmd',
                            message: {
                                ip: ip,
                                port: port,
                                message: data,
                            }
                        }, '*');
                };

                var isConnected = function () {
                    return connected;
                };

                var on = function (event, callback) {
                    if (event === 'data') {
                        onData(callback);
                    // } else if (event === 'error') {
                    } else if (event === 'close') {
                        closeCallback = callback;
                    } else if (event === 'end') {
                        endCallback = callback;
                    // } else if (event === 'timeout') {
                    }
                };

                var removeAllListeners = function (event) {
                    if (event === 'data') {
                        window.removeEventListener('paymentResponse', dataHandler);
                        dataCallback = null;
                    // } else if (event === 'error') {
                    } else if (event === 'close') {
                        closeCallback = null;
                    } else if (event === 'end') {
                        endCallback = null;
                    // } else if (event === 'timeout') {
                    }
                };

                var promise = new Promise(function (resolve, reject) {

                    // Retry connecting to card terminal up to five times
                    // An exception will get thrown and reach the catch block below
                    // upon the fifth failure.
                    _retryAction(
                        function () {
                            return Promise.resolve();
                        },
                        5
                    ).then(function () {
                        connected = true;

                        resolve({
                            write: write,
                            on: on,
                            end: end,
                            removeAllListeners: removeAllListeners,
                            isConnected: isConnected,
                        });
                    }).catch(function () {
                        // This message should get logged by Honeybadger higher up
                        // WARNING: Do not reject with empty payload because
                        // cardterminal.moneris, cardterminal.globalpay etc.
                        // all expect some kind of valid error object to be passed into
                        // their respective catch blocks.
                        //
                        // This string will work as well
                        reject('TCP: iOS failed to connect to card terminal 5 times');
                    });
                });

                return promise;
            };
        }

        return _self;
    }]);
