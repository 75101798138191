'use strict';

export function eventListenerService (freshideas) {

    freshideas.factory('EventListener', [
        '$rootScope',
        'CashierShift',
        'notificationTranslationHelper',
        'PrintService',
        'SharedDataService',
        'SmbPosService',
        '$log',
        'PRINTOUT_TYPE',
        'CurrentSession',
        function ($rootScope, CashierShift, notificationTranslationHelper, PrintService, SharedDataService, SmbPosService, $log, PRINTOUT_TYPE, CurrentSession) {
            var service = {};
            var removeMobileOrderPrintListener = undefined;

            // recursively rebuilds preorder item obj and its children to mock nown item obj
            const extractRequiredDataForPreorderItems = (item) => {
                item.printoutType = PRINTOUT_TYPE.KITCHEN_SHEET;

                if (item.original) {
                    item.locationServicePeriodMenuId = item.original.locationServicePeriodMenuId;
                }

                if (item.children) {
                    item.children.forEach((child) => {
                        extractRequiredDataForPreorderItems(child);
                    });
                }
            };

            const generatePreorderReceiptData = function (preorder) {
                var currentPosStation = SharedDataService.posStation;
                var locationPosStations = SharedDataService.locationPosStations || [currentPosStation];
                var locationPrinters = [];

                locationPosStations.map((posStation) => {
                    locationPrinters.push(...(posStation.posPrinters || []));
                });

                // remove duplicate printer objects
                locationPrinters = locationPrinters.filter((value, index, printers) => {
                    let foundIndex = printers.findIndex((printer) => (printer.posPrinterId === value.posPrinterId));
                    return foundIndex === index;
                });

                var currentCompany = CurrentSession.getCompany();

                // Print a receipt of all the items for the mobile order
                var preorderData = {};
                preorderData.locationName = preorder.location.node_name || '(Location)';
                preorderData.cashierName = 'Preorder';
                preorderData.stationName = currentPosStation.posStationName || '(POS Station)';
                preorderData.companyName = currentCompany.companyName || '(Company)';
                preorderData.receiptId = preorder.short_id;
                preorderData.transactionDateTime = preorder.transaction_opened * 1000;
                preorderData.status = preorder.status;
                preorderData.notes = (preorder.notes || preorder.note);

                var patronName;

                if (preorder.first_name && preorder.last_name && preorder.last_name.length > 0) {
                    patronName = preorder.first_name + ' ' + preorder.last_name.charAt(0).toUpperCase();
                } else {
                    patronName = preorder.full_name || preorder.first_name;
                }

                preorderData.patronName = patronName;
                preorderData.pickup_phone_number = preorder.pickup_phone_number;
                preorderData.appFid = preorder.app_fid;
                preorderData.transactionId = preorder._id;
                preorderData.pickupTime = preorder.estimated_completion * 1000;

                if (preorder.options && preorder.options.delivery) {
                    preorderData.deliveryTime = preorder.estimated_completion * 1000;
                    preorderData.delivery = preorder.options.delivery;
                }

                // NOWNPOS-DELIVERY
                if (preorder.is_delivery && !preorder.is_third_party_preorder) {
                    preorderData.delivery = preorder.preorder_details;
                }

                // order type.
                preorderData.is_delivery = preorder.is_delivery;
                preorderData.is_pickup = preorder.is_pickup;

                // THIRD PARTY INTEGRATION
                if (preorder.is_third_party_preorder) {
                    preorderData.is_third_party_preorder = true;
                    preorderData.preorderDetails = preorder.preorder_details;
                    preorderData.notes = (preorder.notes || preorder.note);
                }

                preorderData.costBreakdown = preorder.cost_breakdown;
                preorderData.totalCents = preorder.total_cents;
                preorderData.transactionUuid = preorder.transaction_uuid;

                // order type.
                preorderData.is_delivery = preorder.is_delivery;
                preorderData.is_pickup = preorder.is_pickup;
                preorderData.preorderDetails = preorder.preorder_details;

                // THIRD PARTY INTEGRATION
                if (preorder.is_third_party_preorder) {
                    preorderData.is_third_party_preorder = true;
                    preorderData.notes = (preorder.notes || preorder.note);
                }

                const backUpLocationPrinterArr = (locationPrinters[0]) ? [locationPrinters[0]] : [];
                // If this pos station does not have it's own printer, we'll try to use the
                // first printer in the list from the other stations
                // this will not work for bluetooth and usb printers
                let posPrinters = currentPosStation.posPrinters && currentPosStation.posPrinters.length
                    ? currentPosStation.posPrinters : backUpLocationPrinterArr;

                var printUrlArray = PrintService.buildPrinterObjArray(posPrinters, false);
                preorderData.printUrlArray = printUrlArray;

                let receiptItems = angular.copy(preorder.items);
                receiptItems.forEach((item) => {
                    extractRequiredDataForPreorderItems(item);
                });

                return {
                    data: preorderData,
                    receiptItems: receiptItems,
                };
            };

            service.listenForNewMobileOrder = function () {
                if (removeMobileOrderPrintListener) {
                    removeMobileOrderPrintListener();
                    removeMobileOrderPrintListener = undefined;
                }

                if (!removeMobileOrderPrintListener) {
                    // acknowledges order (if status = print_pending), prints receipt and sends to KDS.
                    removeMobileOrderPrintListener = $rootScope.$on('pos.mobileOrder.print.receipt', function (event, preorder, successCallback, errorCallback) {
                        if (preorder.status === 'print_pending') {
                            $log.info('New Mobile Order - #' + preorder.short_id + ' - received');
                        }

                        // generate receipt data and receipt items
                        let receiptDetails = generatePreorderReceiptData(preorder);

                        if (preorder.status === 'print_pending') {
                            $log.info('New Mobile Order - #' + preorder.short_id + ' - preorderData= ' + JSON.stringify(receiptDetails));
                        }

                        // Print the full receipt to the printer associated with the
                        // current pos station
                        PrintService.printMobileOrderReceipt(receiptDetails.data, receiptDetails.receiptItems, function (response) {
                            // successCallback could be undefined
                            if (successCallback) {
                                successCallback(response);
                            }

                            SmbPosService.printKitchenReceipts(receiptDetails.receiptItems, receiptDetails.data, {buzzerCode: null, isPreorder: true});
                        }, errorCallback);
                    });
                }
            };

            service.generatePreorderReceiptData = generatePreorderReceiptData;

            return service;
        }]);
}
