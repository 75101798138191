'use strict';

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('EndshiftTerminalPrintOut', [
        '$scope',
        '$q',
        '$log',
        '$modal',
        '$timeout',
        'terminal',
        'PrintService',
        'SharedDataService',
        'PosAlertService',
        function (
            $scope,
            $q,
            $log,
            $modal,
            $timeout,
            terminal,
            PrintService,
            SharedDataService,
            PosAlertService
        ) {
            $scope.modal = 'checklist';
            $scope.terminalBusy = true;

            $scope.checkmarks = {
                businessDayClose: true,
                balancing: false,
                totalTips: false,
                totalTipsByCard: false,
                subTotal: false
            };

            $scope.selectReportType = function (type) {
                $scope.checkmarks[type] = !$scope.checkmarks[type];
            };

            $scope.waitFor = 30; // seconds

            let timeOut;
            let checkIfTerminalIsBusy = function () {
                timeOut = $timeout(function () {
                    $scope.waitFor--;
                    // at the beginning wait for 10 seconds to see terminal is restarting
                    if (($scope.waitFor == 0 || $scope.waitFor == 20) && $scope.terminalBusy) {
                        // reset timer to 20 more seconds
                        $scope.waitFor = 20;
                        let response = terminal.lastTransactionStatus().then(function () {
                            if (response && !response.isBusy) {
                                $scope.terminalBusy = false;
                                $timeout.cancel(timeOut);
                                return;
                            }
                        });
                    }

                    checkIfTerminalIsBusy();
                }, 1000);
            };

            let reportsToPrint = [];
            let promiseList = [];
            $scope.printTerminalReport = function () {
                let hasSelection = verifySelection();

                if (!hasSelection) {
                    return;
                }

                for (const selection in $scope.checkmarks) {
                    if ($scope.checkmarks[selection]) {
                        switch (selection) {
                            case 'businessDayClose':
                                reportsToPrint.push('DayCloseReport');
                            break;
                            case 'balancing':
                                reportsToPrint.push('BALANCING');
                            break;
                            case 'subTotals':
                                reportsToPrint.push('SUBTOTALS');
                            break;
                            case 'tipTotals':
                                reportsToPrint.push('TIP_TOTALS');
                            break;
                            case 'tipTotalsByCard':
                                reportsToPrint.push('TIP_TOTALS_BY_CARD');
                            break;
                        }
                    }
                }

                queuePrintOutRequest(reportsToPrint);
            };

            function verifySelection () {
                let selection = Object.values($scope.checkmarks).filter((x) => x);
                let hasSelection = true;

                if (!selection.length) {
                    let params = {
                        'title': 'Please select atleast one report type to print',
                        'message': '',
                    };

                    PosAlertService.showAlertByName('oops-general', params);
                    hasSelection = false;
                }

                return hasSelection;
            }

            function queuePrintOutRequest (queue) {
                if (!queue.length) {
                    $q.all(promiseList).then(function (results) {
                        for (const result of results) {
                            if (result.length) {
                                PrintService.printTerminalReport(result, SharedDataService.posStation).then(function (response) {
                                    PrintService.clearSession();
                                }, function (err) {
                                    $log.error('terminal report printing error', err);
                                });
                            }
                        }
                    }, function (err) {
                        $log.error(err);
                    });

                    $scope.$close();

                    return;
                }

                let index = 0;
                let promise = terminal.dayReport(queue[index]);

                promiseList.push(
                    promise.then(function (result) {
                        queuePrintOutRequest(queue);

                        return result;
                    }, function (err) {
                        $log.error('terminal report printing error', err);
                    })
                );

                queue.shift();
            }

            $scope.close = function () {
                $timeout.cancel(timeOut);
                $scope.$close();
            };

            $scope.init = function () {
                checkIfTerminalIsBusy();
            };

            $scope.init();
        }
    ]);
};
