'use strict';


module.exports = function (freshideasProducts) {
    freshideasProducts.controller('Products3Ctrl', [
        '$scope',
        '$modal',
        'ProductsService',
        'MenuService',
        'CurrentSession',
        'Organization',
        function (
            $scope,
            $modal,
            ProductsService,
            MenuService,
            CurrentSession,
            Organization) {

            $scope.isRootCompany = CurrentSession.isRootCompany();
            $scope.isCampus = CurrentSession.isCampus();
            var modalPromise;
            var setModal = $scope.setModal = function (config) {
                if (modalPromise && !modalPromise.isPending) {
                    return Promise.reject();
                }

                var modalInstance = $modal.open(config, function (error) {
                    unsetModal();
                });
                modalPromise = modalInstance.result;

                return modalPromise;
            };
            var unsetModal = $scope.unsetModal = function () {
                modalPromise = undefined;
            };

            $scope.isModalActive = function () {
                return !!modalPromise;
            };

            const loadLanguages = () => {
                $scope.organizationLanguages = [];
                var organizationId = CurrentSession.getCompany().organizationId;
                Organization.getAllLanguagesForOrganization({organizationId: organizationId}, (res) => {
                    $scope.organizationLanguages = res;
                    MenuService.organizationLanguages = res;
                });
            };

            $scope.shopifyStores = [];

            $scope.syncShopifyProducts = function () {
                if ($scope.shopifyStores.size < 1) {
                    return;
                }
                ProductsService.getShopifyProducts($scope.shopifyStores[0].storeId).then((response) => {
                    return setModal({
                        templateUrl: 'products/templates/product.shopify.items.tpl.html',
                        controller: 'ShopifyItemsCtrl',
                        windowClass: 'modal-80 products2 modal--pull-right',
                        animation: true,
                        backdrop: 'static',
                        resolve: {
                            shopifyProducts: function () {
                                return response.products;
                            }
                        }
                    }).then(function (itemsToImport) {
                        unsetModal();
                        if (itemsToImport && itemsToImport.length > 0) {
                            save(itemsToImport);
                        }
                    }).catch(function (error) {
                        unsetModal();
                    });
                }).catch((err) => {
                    console.error('Shopify Product Error', err);
                });
            };

            function save (products) {
                ProductsService.importFromShopify(products).then(function (response) {
                }).catch(function (error) {
                });
            }

            $scope.getShopifyStores = function () {
                ProductsService.getShopifyStores().then((response) =>{
                    if (response) {
                        $scope.shopifyStores = response;
                    }
                }).catch(function (err) {
                    console.error(err);
                });
            };

            $scope.init = function () {
                loadLanguages();

                $scope.products = ProductsService.products;

                $scope.allItems = ProductsService.allItems;
                $scope.allCategories = ProductsService.allCategories;
                $scope.allModifiers = ProductsService.allModifiers;

                $scope.unsortedItems = ProductsService.unsortedItems;

                $scope.getShopifyStores();
            };

            $scope.init();

        }]);
};
