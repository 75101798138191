'use strict';

module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ProductEmailPromptCtrl', [
        '$scope',
        '$translate',
        function ($scope, $translate) {
            $scope.model = {
                email: ''
            };
            $scope.errorMessage = null;

            function validateEmails (emails) {
                emails = emails.replace(/\s/g, '').split(',');
                let isValid = true;

                let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                for (var i = 0; i < emails.length; i++) {
                    if (emails[i] == '' || ! regex.test(emails[i])) {
                        isValid = false;
                    }
                }
                return isValid;
            }

            $scope.save = function () {
                let email = $scope.model.email;

                if (!validateEmails(email)) {
                    $scope.errorMessage = $translate.instant('pos.products.import.email.prompt.invalid.email');
                    return;
                }
                $scope.$close(email);
            };
        }]);
};
