'use strict';

const angular = require('angular');

var ngTouchstart = angular.module('ngTouchstart', []);

ngTouchstart.directive('ngTouchstart', [function () {
    return {
        controller: [
            '$scope', '$element', '$attrs',
            function ($scope, $element, $attrs) {

                var lastEvent = '';

                $element.bind('touchstart mousedown', onTouchStart);
                function onTouchStart (event) {

                    // Event Order
                    // 1.touchstart
                    // 2.touchmove
                    // 3.touchend
                    // 4.mouseover
                    // 5.mousemove
                    // 6.mousedown
                    // 7.mouseup
                    // 8.click

                    if (lastEvent === 'touchstart'
                        && event.type === 'mousedown') {
                        lastEvent = event.type;
                        return;
                    }

                    lastEvent = event.type;

                    var method = $element.attr('ng-touchstart');
                    $scope.$event = event;
                    $scope.$apply(method);
                }
            },
        ]};
}]);

export default ngTouchstart;
