'use strict';


const angular = require('angular');

export default angular.module('freshideas.services.lookup', []).factory('LookupService', [
    'Lookup',
    '$log',
    '$q',
    '$modal',
    '$interval',
    '$location',
    '$timeout',
    '$window',
    function (Lookup, $log, $q, $modal, $interval, $location, $timeout, $window) {
        // The list of permissions and privileges
        var roleTypes;
        // The list of server attributes
        var serverAttributes;
        function loadRoleTypes () {
            roleTypes = Lookup.roleTypes().$promise;
        }
        function loadServerAttributes () {
            serverAttributes = Lookup.serverAttributes().$promise;
        }
        /**
         * This is the public API for LookupService
         *
         * @type {{}}
         */
        var service = {
            getRoleTypes: function () {
                if (!roleTypes) {
                    loadRoleTypes();
                }
                return $q.when(roleTypes);
            },
            getServerAttributes: function () {
                if (!serverAttributes) {
                    loadServerAttributes();
                }
                return $q.when(serverAttributes);
            },
            getRolePermissions: function () {
                return Lookup.rolePermissions().$promise;
            },
            getRolePermissionsForCompany: function () {
                return Lookup.rolePermissionsByCompany().$promise;
            }
        };
        return service;
    }
]);
