'use strict';

module.exports = function (freshideasDirectivesCommon) {
    freshideasDirectivesCommon.directive('badge', [
        '$translate',
        'PosAlertService',
        function (
            $translate,
            PosAlertService
        ) {
            return {
                restrict: 'E',
                template: '<div class="smb-pos__badge"><span>{{ label }}</span></div>',
                scope: {
                    label: '@',
                    disableClick: '=?'
                },
                link: function (scope, element, attributes, controllerList) {
                    const IS_CLICK_DISABLED = (scope.disableClick == true);

                    if (!IS_CLICK_DISABLED && element && Object.keys(element).length) {
                        element.context.addEventListener('click', function (event) {
                            event.stopPropagation();
                            PosAlertService.showAlertByName('general-alert', {
                                title: $translate.instant('general.alert.org.login.ttl'),
                                message: $translate.instant('general.alert.org.login.msg'),
                                buttonType: 'ok',
                            });
                        });
                    }
                }
            };
        }]
    );
};
