'use strict';

/* globals ngGridFlexibleHeightPlugin */

const angular = require('angular');
// const $ = require('jquery');

const freshideasResourcesInventory = require('../common/resources/inventory.js').default;

const freshideasInventory = angular.module('freshideas.inventory', [
    freshideasResourcesInventory.name,
    'mgo-angular-wizard'
]).controller('EditInvoiceCtrl', [
    '$scope',
    '$modalInstance',
    '$modal',
    '$q',
    '$timeout',
    '$filter',
    'Inventory',
    'Locations',
    'invoice',
    'isEdit',
    'selectedCompanyId',
    'Lookup',
    'Security',
    '$translate',
    'notificationTranslationHelper',
    'ErrorUtil',
    'EnvConfig',
    'Utils',
    'NgGridSortService',
    'NgGridTemplateService',
    function ($scope, $modalInstance, $modal, $q, $timeout, $filter, Inventory, Locations, invoice, isEdit, selectedCompanyId, Lookup, Security, $translate, notificationTranslationHelper, ErrorUtil, EnvConfig, Utils, NgGridSortService, NgGridTemplateService) {
        $scope.editMode = isEdit;
        $scope.selectedInvoiceItems = [];
        $scope.selectedCompanyId = selectedCompanyId;
        if ($scope.editMode && angular.isDefined(invoice)) {
            $scope.invoice = angular.copy(invoice);
        }
        $scope.addInvoiceItemEntry = function (tmpInvoiceItem) {
            $scope.invoice.invoiceItems.push(angular.copy(tmpInvoiceItem));
            tmpInvoiceItem.invoiceItemId = undefined;
            tmpInvoiceItem.vendorProductId = undefined;
            tmpInvoiceItem.purchaseUnit = undefined;
            tmpInvoiceItem.purchaseUnitCost = undefined;
            tmpInvoiceItem.sellUnit = undefined;
            tmpInvoiceItem.sellUnitsperPurchaseUnit = undefined;
            tmpInvoiceItem.sellUnitCost = undefined;
            tmpInvoiceItem.sellUnitPrice = undefined;
            tmpInvoiceItem.inventoryQuantity = undefined;
            tmpInvoiceItem.upc = undefined;
            tmpInvoiceItem.name = undefined;
            tmpInvoiceItem.description = undefined;
            tmpInvoiceItem.mealIndicator = false;
            tmpInvoiceItem.taxRateId = null;
            this.invoiceItemForm.$setPristine();
        };
        $scope.addInvoice = function () {
            Inventory.add({}, $scope.invoice, function (response) {
                $modalInstance.close(response);
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            });
        };
        // function for edit inventory
        $scope.editInventory = function () {
            Inventory.updateInventory({}, $scope.invoice, function (response) {
                $modalInstance.close(response);
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            });
        };
        $scope.editInvoice = function () {
            Inventory.update({}, $scope.invoice, function (response) {
                $modalInstance.close(response);
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            });
        };
        $scope.invoiceItemsGridOptions = {
            columnDefs: [
                {
                    field: 'vendorProductId',
                    displayName: 'invoice.invoiceItem.vendorProductId',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'upc',
                    displayName: 'invoice.invoiceItem.upc',
                    width: '**',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'name',
                    displayName: 'invoice.invoiceItem.name',
                    width: '**',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'sellUnitCost',
                    displayName: 'invoice.purchaseUnitCount',
                    width: '**',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'purchaseUnitCost',
                    displayName: 'invoice.invoiceItem.purchaseUnitCost',
                    width: '**',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'inventoryQuantity',
                    displayName: 'invoice.invoiceItem.inventoryQuantity',
                    width: '**',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'sellUnitCost',
                    displayName: 'invoice.invoiceItem.sellUnitCost',
                    width: '**',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'sellUnitPrice',
                    displayName: 'invoice.invoiceItem.sellUnitPrice',
                    width: '**',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: '',
                    displayName: 'general.actions',
                    width: '80',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text><div class="action-buttons">' + '<a class="blue" ng-click="editInvoice(row.entity)" has-perm resource="inventory:invoice_edit"><i class="fa fa-pencil bigger-130"></i></a>' + '<a class="red" confirm-modal confirm-message="{{\'invoice.confirmDelete\' | translate}}" confirm-title="{{\'invoice.confirmDelete.title\' | translate}}" on-confirm="deleteInvoice(row.entity)" has-perm resource="inventory:delete_invoice"><i class="fa fa-trash-o bigger-130"></i></a>' + '</div></span></div>'
                }
            ],
            plugins: [new ngGridFlexibleHeightPlugin({minHeight: 520})],
            showFooter: true,
            footerRowHeight: 30,
            multiSelect: true,
            showSelectionCheckbox: true,
            selectedItems: $scope.selectedInvoiceItems,
            enableSorting: true,
            selectWithCheckboxOnly: true,
            enableHighlighting: true,
            footerTemplate: '<div class="grid-footer"><div class="col-xs-4"></div><div class="col-xs-4"><pager align="false" ng-model="invoicePagingOptions.currentPage" ' + 'total-items="invoicePagingOptions.totalItems" items-per-page="invoicePagingOptions.pageSize" ng-change="loadInvoices();"></pager>' + '</div><div class="col-xs-4 align-right"><span translate="general.records"/> {{invoicePagingOptions.startRecord}} - {{invoicePagingOptions.endRecord}}</div></div>',
            data: 'invoice.invoiceItems'
        };
        $scope.init = function () {
            if ($scope.editMode === false) {
                Inventory.getDefault(function (response) {
                    $scope.invoice = response;
                });
            }
            Locations.getLocations({'companyId': $scope.selectedCompanyId}, function (response) {
                $scope.locations = response.entries;
            });
        };
        $scope.init();
    }
]).controller('ImportUPCCtrl', [
    '$scope',
    '$rootScope',
    '$modal',
    '$modalInstance',
    '$translate',
    'FileUploader',
    'companyId',
    'notificationTranslationHelper',
    'Utils',
    'ErrorUtil',
    'NgGridTemplateService',
    'PosAlertService',
    function ($scope, $rootScope, $modal, $modalInstance, $translate, FileUploader, companyId, notificationTranslationHelper, Utils, ErrorUtil, NgGridTemplateService, PosAlertService) {
        var uploader = $scope.uploader = new FileUploader({
            url: '/freshideas/web/inventory/import',
            queueLimit: 1,
            queue: [],
            headers: {'Accept': 'application/json'} // wont work unless we manually define this header
        });
        uploader.onWhenAddingFileFailed = function (item, filter, options) {
        };
        uploader.onAfterAddingFile = function (fileItem) {
        };
        uploader.onAfterAddingAll = function (addedFileItems) {
        };
        uploader.onBeforeUploadItem = function (item) {
        };
        uploader.onProgressItem = function (fileItem, progress) {
        };
        uploader.onProgressAll = function (progress) {
        };
        uploader.onSuccessItem = function (fileItem, response, status, headers) {
            notificationTranslationHelper.notifyMessage('invoice.import.fileSuccessfullyUploaded'); // console.info('onSuccessItem', fileItem,
                                                                                                       // response, status, headers);
        };
        uploader.onErrorItem = function (fileItem, response, status, headers) {
        };
        uploader.onCancelItem = function (fileItem, response, status, headers) {
        };
        uploader.onCompleteItem = function (fileItem, response, status, headers) {
            $scope.invalidUPCImport = response;
            $modalInstance.close();
            if ($scope.invalidUPCImport.length !== 0) {
                if ($scope.invalidUPCImport.error !== null) {
                    PosAlertService.showAlertByName('import-patron-invalid', {'message': $scope.invalidUPCImport.error});
                } else {
                    PosAlertService.showAlertByName('import-patron', {'message': $scope.invalidUPCImport});
                }
            }
            $rootScope.loadInventories();
        };
        uploader.onCompleteAll = function () {
        };
    }
]);

require('./inventory.inventoryItem.edit.js')(freshideasInventory);

export default freshideasInventory;


