'use strict';


(function () {
    var SharedDataService = (function () {
        const DEFAULT_CURRENCY_UNIT = {
            currencyCode: 'CAD',
            baseDollar: 0.01,
            baseCent: 1,
            giftCardMaxAmount: 1000
        };
        const CURRENCY_CODE_UNITS = {
            'CAD': {
                currencyCode: 'CAD',
                baseDollar: 0.01,
                baseCent: 1,
                giftCardMaxAmount: 1000,
                taxIncludedInPrice: false
            },
            'USD': {
                currencyCode: 'USD',
                baseDollar: 0.01,
                baseCent: 1,
                giftCardMaxAmount: 1000,
                taxIncludedInPrice: false
            },
            'JPY': {
                currencyCode: 'JPY',
                baseDollar: 1,
                baseCent: 100,
                giftCardMaxAmount: 100000,
                taxIncludedInPrice: true
            },
            'GBP': {
                currencyCode: 'GBP',
                baseDollar: 0.01,
                baseCent: 1,
                giftCardMaxAmount: 1000,
                taxIncludedInPrice: true
            }
        };

        var Tender = {
            patron: '',
            lucovaUser: '',
            guestTransaction: '',
            company: {},
            locationId: 0,
            preferredLanguage: {},
            receipt: '',
            available: '',
            transactionAmount: '',
            errorPopupCounter: 0,
            location: 0,
            mealPlanCount: 0,
            mealPlanIds: [],
            mealPlanIdsToString: '',
            // dcbMealPlanIds: [],
            dcbMealPlanIdsToString: '',
            posStationId: 0,
            hasExtraMeals: false,
            posMenu: {},
            posMenuAll: {},
            menuPeriodToSwitchTo: {},
            minimumDenomination: 1,
            printers: [],
            endShiftCheckboxes: {
                openCashDrawer: false,
                endingBalance: false,
                printReport: false,
                emailReport: false,
                autoSettle: false,
                tipsPayout: false
            },
            endShiftData: {
                endingBalance: '0.00',
                printReport: false,
                emailReport: false,
                autoSettle: false
            },
            cachedProducts: {},
            cachedProductsTimestamp: {},
            currencyCode: 'CAD',
            baseDollar: DEFAULT_CURRENCY_UNIT.baseDollar,
            baseCent: DEFAULT_CURRENCY_UNIT.baseCent,
            loyaltyRedemptionPerDollar: 100,
            menuItems: [],
            menuItemsMap: {},
            cachedShiftMenu: {},
            cachedLoyaltySteps: [], // keep sorted by points
            cachedLoyaltyStepItemsMap: {}, // {languageId: {stepId: items[], ...}}
            allMenus: [],
        };

        Tender.setAllMenus = function (menus) {
            this.allMenus = [...menus];
        };

        Tender.getDefaultMenu = function () {
            let defaultMenu = this.allMenus.find((menu) => menu.defaultMenu);

            return defaultMenu;
        };

        Tender.resetEndShiftCheckboxes = function () {
            this.endShiftCheckboxes = {
                openCashDrawer: false,
                endingBalance: false,
                printReport: false,
                emailReport: false,
                autoSettle: false,
                tipsPayout: false
            };

            this.endShiftData = {
                endingBalance: '0.00',
                printReport: false,
                emailReport: false,
                autoSettle: false
            };
        };

        Tender.clearMenuCache = function () {
            this.posMenu = {};
            this.posMenuAll = {};

            this.cachedProducts = {};
            this.cachedProductsTimestamp = {};
        };

        Tender.addToCachedProducts = function (data) {
            let menuIdKeys = Object.keys(data.cachedProducts);

            for (let menuIdKey of menuIdKeys) {
                let menuExists = Object.keys(this.cachedProducts).includes(menuIdKey);

                if (!menuExists) {
                    this.cachedProducts = data.cachedProducts;
                    this.cachedProductsTimestamp = data.cachedProductsTimestamp;
                } else {
                    let languageId = Object.keys(data.cachedProducts[menuIdKey])[0];

                    this.cachedProducts[menuIdKey][languageId] = data.cachedProducts[menuIdKey][languageId];
                    this.cachedProductsTimestamp[menuIdKey][languageId] = data.cachedProductsTimestamp[menuIdKey][languageId];
                }
            }
        };

        Tender.setCurrencyCode = function (currencyCode) {
            let currencyUnits = _getBaseCurrencyUnits(currencyCode);

            Tender.currencyCode = currencyUnits.currencyCode;
            _setBaseUnits(currencyUnits);
        };
        var _getBaseCurrencyUnits = function (code) {
            let units = CURRENCY_CODE_UNITS[code];
            units = units || DEFAULT_CURRENCY_UNIT;

            return units;
        };

        Tender.getGiftCardBalanceLimit = function (code) {
            let units = CURRENCY_CODE_UNITS[code];
            units = units || DEFAULT_CURRENCY_UNIT;

            return units.giftCardMaxAmount;
        };

        var _setBaseUnits = function (obj) {
            let isObject = obj != null && obj.constructor.name === 'Object';
            if (!isObject) {
                let error = {
                    message: '[SharedDataService.setBaseUnits] `obj` is not an object'
                };
                throw error;
            }

            if (isNaN(obj.baseDollar) || !obj.baseDollar) {
                let error = {
                    message: '[SharedDataService.setBaseUnits] `obj.baseDollar` is not a valid number'
                };
                throw error;
            }

            if (isNaN(obj.baseCent) || !obj.baseCent) {
                let error = {
                    message: '[SharedDataService.setBaseUnits] `obj.baseCent` is not a valid number'
                };
                throw error;
            }

            Tender.baseDollar = obj.baseDollar;
            Tender.baseCent = obj.baseCent;
            Tender.taxIncludedInPrice = !!obj.taxIncludedInPrice;
        };

        return Tender;
    })();

    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = SharedDataService;
    } else {
        window.SharedDataService = SharedDataService;
    }
})();
