'use strict';

const moment = require('moment');

export function cardTerminalGlobalPay (freshideas) {

    freshideas.factory('MonerisCommon', [
        '$log',
        '$timeout',
        'TerminalEvent',
        'ErrorLoggingService',
        function ($log, $timeout, TerminalEvent, ErrorLoggingService) {
        var _self = {};

        var printResult;
        var extraPrintResult;
        var terminalReceiptData;
        var responses;

        const HEARTBEAT = '\x11';
        const FS = '\x1c';

        const TRANSACTION_TYPE = '100';
        const TRANSACTION_STATUS = '101';

        const TRANSACTION_DATE = '102';
        const TRANSACTION_TIME = '103';

        const HOST_RESPONSE_CODE = '401';

        const TRANSACTION_TYPE_PURCHASE = '00';
        const TRANSACTION_TYPE_REFUND = '03';
        const TRANSACTION_TYPE_VOID = '05';
        const TRANSACTION_TYPE_AUTO_SETTLE = '20';
        const LAST_TRANSACTION_STATUS = '91';

        const DATA_TAG_TRANSACTION_AMOUNT = '\x1c001';
        const DATA_TAG_TENDER_TYPE = '\x1c002';
        const DATA_TAG_AUTH_NO = '\x1c005';

        // this differs from TOTAL_AMOUNT which also includes cashback.
        // this does not include cashback amount
        const TRANSACTION_AMOUNT = '104';
        const TIP_AMOUNT = '105';
        const DEBIT_SURCHARGE_AMOUNT = '107';
        const TOTAL_AMOUNT = '109';
        const INVOICE_NUMBER= '110';

        // const CREDIT_TRANSACTION_REFERENCE_NUM = '111';
        const DEBIT_TRANSACTION_RECEIPT_NUM = '712';
        const TRANSACTION_SEQUENCE_NUM = '112';

        const CARD_TYPE = '300';
        const CARD_NAME = '301';
        const MASKED_CARD_NUMBER = '302';
        const CUSTOMER_LANGUAGE = '303';
        const DEBIT_ACCOUNT_TYPE = '304';

        const ENTRY_METHOD = '305';
        const EMV_AID = '306';

        const EMV_TVR1 = '307';
        const EMV_TVR2 = '308';
        const EMV_TSI1 = '309';
        const EMV_TSI2 = '310';
        const EMV_APP_LABEL = '311';
        const EMV_APP_PREFERRED_NAME = '312';
        const EMV_TC= '316';

        // 0 None
        // 1 Pin
        // 2 Signature
        // 3 Pin + Signature
        const CVM_METHOD = '315';
        const BALANCE_DUE = '317';
        const TTQ = '318';
        const AAC = '319';

        const AUTHORIZATION_NUM = '400';
        const HOST_RESPONSE_ISO_CODE = '403';
        const CARD_BALANCE = '406';
        const PARTIAL_APPROVED_AMOUNT = '420';
        const BATCH_NUM = '500';
        const DEMO_MODE = '600';
        const TERMINAL_ID = '601';

        const MERCHANT_NAME = '602';
        const MONERIS_ADDRESS_LINE_1 = '603';
        const MONERIS_ADDRESS_LINE_2 = '604';

        // 0 do not print
        // 1 print cardholder signature line
        // 2 print merchant signature line
        const MONERIS_PRINT_SIGNATURE = '713';
        const FORM_FACTOR = '717';

        var customerAccountType = {
            '0': 'default',
            '1': 'savings',
            '2': 'chequing',
        };

        var transactionType = {
            '00': 'purchase',
            // '01': 'Preauth', //not supported
            // '02': 'Preauth Completion', //not supported
            '03': 'refund',
            '05': 'purchaseCorrection',
            '06': 'refundCorrection',
            '20': 'batchClose',
            '60': 'balanceInquiry',
            '91': 'recallMessage',
        };

        var toTransactionType = function (code) {
            return transactionType[code];
        };

        _self.init = function (terminalConfig) {
            printResult = null;
            extraPrintResult = [];
            terminalReceiptData = [];
            responses = [];
        };

        var isASCII = function (str) {
            return /^[\x00-\x7F]*$/.test(str);
        };

        var toTransactionDateTime = function (date, time) {
            if (date && time) {
                var year, month, day, hour, minute, second;

                [year, month, day] = date.match(/.{1,2}/g);
                [hour, minute, second] = time.match(/.{1,2}/g);

                return '20'+year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second;
            } else {
                // for failed transactions, it is possible the terminal does not
                // return any datetime to us. In this case we will make it ourselves.
                return moment().format('YYYY-MM-DD HH:mm:ss');
            }
        };

        var splitFields = function (fieldStr) {
            if (fieldStr[0] != FS) {
                throw new Error('Fields does not start with correct character');
            }

            // remove leading FS
            fieldStr = fieldStr.substring(1);

            var result = {};
            var fields = fieldStr.split(FS);
            for (var i of Object.keys(fields)) {
                var field = fields[i];
                var tag = field.substring(0, 3);
                var data = field.substring(3);
                result[tag] = data;
            }

            return result;
        };

        var parseReceived = function (str) {
            var tranStatus = str.substring(0, 2);
            var multiTran = str[2];
            var fields = str.substring(3);

            var result = fields ? splitFields(fields) : {};

            if (!result[TRANSACTION_STATUS]) {
                result[TRANSACTION_STATUS] = tranStatus;
            }

            // tranStatus 91 means we recalled the transaction info from the terminal
            // in this case we want to set the tranStatus what we would normally receive
            // if we had not recalled from the terminal
            if (tranStatus == '91') {
                if (result['101']) {
                    tranStatus = result['101'];
                    multiTran = '0';
                } else {
                    throw new Error('Recall last transaction without transaction status!');
                }
            }

            return [tranStatus, multiTran, result];
        };

        _self.ingenicoTerminalResponse = function (transactionStatus, result) {
            /*
            We're using this parser to process both Moneris and GlobalPayment responses.
            There're minor differences:

            - Moneris signature line TAG(713)
                0 – Do not print signature line
                1 – Print Cardholder Signature line
                2 – Print Merchant Signature line
            -  Moneris returns single digit strings for CARD_TYPE
            */

            var isCredit = result[CARD_TYPE] == '1';
            var isDebit = result[CARD_TYPE] == '2';

            var showCustomerSignatureLine = result[MONERIS_PRINT_SIGNATURE] === '1';
            var showMerchantSignatureLine = result[MONERIS_PRINT_SIGNATURE] === '2';

            var verifiedByPin = result[CVM_METHOD] === '1' || result[CVM_METHOD] === '3';

            var isReversal = ['19', '20', '21', '22'].includes(result[TRANSACTION_STATUS]);

            var formFactor = result[FORM_FACTOR];
/*
100:"00" # transaction type
101:"00" # transaction status
102:"180814" # transaction date
103:"133044" # transaction time
104:"196" # transaction amount
109:"196" # total amount
112:"0010012550" # transaction sequence
300:"2" # customer tender type
301:"INTERAC" # customer card name
302:"************0454" #customer account number
303:"0" # customer language
304:"2" # customer account type
305:"C" # customer card entry mode !!!!!!!!!!
306:"A0000002771010" # EMV AID (MONERIS HAS THIS 306 AS ENTRY METHOD, and 308 and EMV AID!!)
307:"8000008000" # EMV 1
309:"6800" # EMV 2
311:"Interac" # EMV application level
312:"" # EMV preferred name
313:"743DF3E1C8C0F120" #EMV AROC
314:"FCDE5AB10F5F46F0" #EMV final cryptogram
315:"1" #CVM indicator
400:"453094  " # authorization #
401:"001"  # HOST RESPONSE CODE
403:"00"  # HOST RESPONSE ISO CODE
601:"84013690" # 601 TERMINAL ID
602:"LUCOVA"  # 602 MERCHANT NAME
603:"88 QUEENS QUAY W - 17TH F" # ADDRESS 1
604:"TORONTO      ON" # ADDRESS 2
712:"C84013690-001-001-255-0"  # 712 RECEIPT #
*/

            var printObj = {
                amount: result[TRANSACTION_AMOUNT],
                merchantName: result[MERCHANT_NAME],
                addressLine1: result[MONERIS_ADDRESS_LINE_1],
                addressLine2: result[MONERIS_ADDRESS_LINE_2],
                transactionType: toTransactionType(result[TRANSACTION_TYPE]),
                transactionTypeCode: result[TRANSACTION_TYPE],
                cardName: result[CARD_NAME],
                transactionAmount: result[TRANSACTION_AMOUNT] ? (result[TRANSACTION_AMOUNT]/100).toFixed(2) : '0.00',
                balanceDue: result[BALANCE_DUE] ? (result[BALANCE_DUE]/100).toFixed(2) : '0.00',
                tipAmount: result[TIP_AMOUNT] ? (result[TIP_AMOUNT]/100).toFixed(2) : '0.00',
                totalAmount: result[TOTAL_AMOUNT] ? (result[TOTAL_AMOUNT]/100).toFixed(2) : '0.00',
                approvedAmount: result[TOTAL_AMOUNT] ? (result[TOTAL_AMOUNT]/100).toFixed(2) : '0.00',
                cardNumber: result[MASKED_CARD_NUMBER],
                transactionDateTime: toTransactionDateTime(result[TRANSACTION_DATE], result[TRANSACTION_TIME]),
                cardEntryMethod: result[ENTRY_METHOD],
                authorizationNumber: result[AUTHORIZATION_NUM],
                emvAppLabel: result[EMV_APP_LABEL],
                emvAid: result[EMV_AID],
                emvTvr: result[EMV_TVR2] || result[EMV_TVR1],
                emvTsi: result[EMV_TSI2] || result[EMV_TSI1],
                emvTc: result[EMV_TC],
                ttq: result[TTQ],
                aac: result[AAC],

                transactionStatus: result[TRANSACTION_STATUS] || transactionStatus,
                success: ['00', '01'].includes(result[TRANSACTION_STATUS]),
                approvedWithMalfunc: result[TRANSACTION_STATUS] == '01',
                declinedByCardOnline: result[TRANSACTION_STATUS] == '11',
                cancelledByUser: result[TRANSACTION_STATUS] == '13',
                cardRemoved: result[TRANSACTION_STATUS] == '19',
                partialApprovalCancelled: result[TRANSACTION_STATUS] == '23',

                invoiceNumber: result[INVOICE_NUMBER],
                hostResponseCode: result[HOST_RESPONSE_CODE],
                hostResponseIsoCode: result[HOST_RESPONSE_ISO_CODE],
                terminalTimeout: false,
                isReversal: isReversal,
                formFactor: formFactor,
                cardBalance: result[CARD_BALANCE],
                customerLanguage: result[CUSTOMER_LANGUAGE] === '1' ? 'fr': 'en',

                terminalId: result[TERMINAL_ID],
                transactionId: result[TRANSACTION_SEQUENCE_NUM],
                maskedCardNumber: result[MASKED_CARD_NUMBER],
                batchNum: result[BATCH_NUM],
                signatureBase64Png: null,
                transactionSequenceNum: result[TRANSACTION_SEQUENCE_NUM],
                cvmResult: result[CVM_METHOD],
                demoMode: result[DEMO_MODE] === '1',
                isCredit: isCredit,
                isCreditCard: isCredit,
                isDebit: isDebit, // is debit
                showMerchantSignatureLine: showMerchantSignatureLine,
                showCustomerSignatureLine: showCustomerSignatureLine,
                verifiedByPin: verifiedByPin,
            };

            printObj.referenceNumber = result[DEBIT_TRANSACTION_RECEIPT_NUM];
            if (isDebit) {
                printObj.debitAccountType = customerAccountType[result[DEBIT_ACCOUNT_TYPE]];
                printObj.surchargeAmount = result[DEBIT_SURCHARGE_AMOUNT] ? (result[DEBIT_SURCHARGE_AMOUNT]/100).toFixed(2) : '0.00';
            }

            if (result[PARTIAL_APPROVED_AMOUNT]) {
                printObj.partiallyApproved = (result[PARTIAL_APPROVED_AMOUNT]/100).toFixed(2);
            }

            if (isASCII(result[EMV_APP_PREFERRED_NAME])) {
                printObj.emvAppPreferredName = result[EMV_APP_PREFERRED_NAME];
            }

            return printObj;
        };

        function trimHeartbeats (text) {
            text = text.replace(new RegExp('^(['+HEARTBEAT+']*)', 'g'), '');
            text = text.replace(new RegExp('(['+HEARTBEAT+']*)$', 'g'), '');
            return text;
        }

        _self.onDataReceived = function (data, callback) {
            var raw = trimHeartbeats(data.toString());
            if (raw.length > 0) {
                var arr = parseReceived(raw);
                let transactionStatus = arr[0];
                var multiTran = arr[1];
                let result = arr[2];

                responses.push(arr);

                if (transactionStatus === '99' && result[TRANSACTION_STATUS] === '01') {
                    terminalReceiptData.push(result);
                }


                if (multiTran === '0') {
                    allDataReceived(responses, callback);
                    responses = [];
                }
            }
        };

        function allDataReceived (responses, callback) {

            var success = true;

            for (var i of Object.keys(responses)) {
                var arr = responses[i];

                var transactionStatus = arr[0];
                let isMulitTrx = arr[1] === '1';
                var result = arr[2];
                let reference = result[AUTHORIZATION_NUM];

                if (transactionStatus === '99') {
                    printResult = result;
                    callback(TerminalEvent.PRINT, printResult, transactionStatus);

                    return;
                } else if (['00', '01'].includes(transactionStatus)) {
                    if (!printResult) {
                        // We receive '00' or '01' as a final message from the terminal
                        // after a successful transaction. If for some reason the terminal
                        // did *not* send us a printResult before this, then we need to use
                        // the current result as the printResult. The two have a similar
                        printResult = result;
                        // $log.error('Transaction success without printResult');
                    }

                    // print partial approval response
                    if (isMulitTrx) {
                        extraPrintResult.push({'arr': arr, 'terminalReceiptData': getReceiptData(reference)});
                    }
                } else if (transactionStatus === '15') {
                    callback(TerminalEvent.BATCH_EMPTY);

                    return;
                } else if (['10', '11', '12', '13',
                    '14', '16', '17', '18', '19', '20', '21',
                    '22', '23'].includes(transactionStatus)) {
                    if (!printResult) {
                        // We receive '00' or '01' as a final message from the terminal
                        // after a successful transaction. If for some reason the terminal
                        // did *not* send us a printResult before this, then we need to use
                        // the current result as the printResult. The two have a similar
                        printResult = result;
                    }

                    // set mulit-transaction or partial approval result to print
                    if (isMulitTrx) {
                        extraPrintResult.push({'arr': arr, 'terminalReceiptData': getReceiptData(reference)});
                    }

                    success = false;
                } else if (transactionStatus === '95') {
                    callback(TerminalEvent.BUSY);

                    return;
                } else {
                    // callback(TerminalEvent.FAILED, result);
                    success = false;
                }
            }

            // TODO only storing last result for partial transaction!!
            if (success) {
                callback(TerminalEvent.SUCCESS, printResult, transactionStatus, extraPrintResult);
            } else {
                callback(TerminalEvent.FAILED, printResult, transactionStatus, extraPrintResult);
            }

            // reset extraPrintResult
            extraPrintResult = [];
            terminalReceiptData = [];
        }

        function getReceiptData (reference) {
            return terminalReceiptData.filter((el) => el[AUTHORIZATION_NUM] === reference)[0];
        }

        // different between GlobalPayments and Moneris
        _self.creditSaleCmd = function (amount) {
            return TRANSACTION_TYPE_PURCHASE
                    + DATA_TAG_TRANSACTION_AMOUNT + Math.round(Number(amount)*100)
                    + DATA_TAG_TENDER_TYPE + '0';
        };

        _self.lastTransactionStatus = function () {
            return LAST_TRANSACTION_STATUS;
        };

        _self.autoSettleCmd = function () {
            return TRANSACTION_TYPE_AUTO_SETTLE;
        };

        _self.parseResponse = function (transactionStatus, result) {
            return _self.ingenicoTerminalResponse(transactionStatus, result);
        };

        _self.buildResponse = function (transaction, cardTransactionId) {
            var origResponseObj = transaction.terminalResponses.find(function (e) {
              return e.cardTransactionId == cardTransactionId;
            });

            var response = JSON.parse(origResponseObj.cardTerminalResponse);

            return _self.parseResponse(null, response);
        };

        _self.getPrintResult = function () {
            return printResult;
        };

        // different between GlobalPayments and Moneris
        // _self.creditVoidCmd = function (totalAmount, authNum) {
        _self.creditVoidCmd = function (transactionId, params) {

            if (!params.transaction) {
                return Promise.reject('transaction provided by Moneris is required.');
            }
            var transaction = params.transaction;
            var response = _self.buildResponse(transaction, transactionId);
            var totalAmount = response.totalAmount;
            var authNum = response.authorizationNumber.trim();

            return TRANSACTION_TYPE_VOID
                    + DATA_TAG_TRANSACTION_AMOUNT + Math.round(Number(totalAmount)*100)
                    + DATA_TAG_TENDER_TYPE + '0'
                    + DATA_TAG_AUTH_NO + authNum;
        };

        _self.creditReturnCmd = function (amount, params) {

            if (!params.transaction) {
                return Promise.reject('transaction provided by Moneris is required.');
            }
            if (!params.transactionId) {
                return Promise.reject('transactionId provided by Moneris is required.');
            }

            var transaction = params.transaction;
            var response = _self.buildResponse(transaction, params.transactionId);
            var authNum = response.authorizationNumber.trim();

            return TRANSACTION_TYPE_REFUND
                + '\x1c001' + Math.round(Number(amount)*100)
                    + DATA_TAG_TENDER_TYPE + '0'
                    + DATA_TAG_AUTH_NO + authNum;
        };


        var saleInProgress = false;
        _self.timeoutWrapper = function (f, timedOut) {
            return function (res, rej) {
                var timeout = null;

                if (saleInProgress) {
                    $log.error('Multiple sales initiated in parallel!');
                }
                saleInProgress = true;

                var createTimeout = function () {
                    if (timeout) {
                        cancelTimeout();
                    }
                    timeout = $timeout(function () {
                        timedOut(resolve, reject);
                    }, 60000);
                };

                var cancelTimeout = function () {
                    if (timeout) {
                        $timeout.cancel(timeout);
                        timeout = null;
                    }
                };

                var resolve = function (x) {
                    cancelTimeout();
                    saleInProgress = false;
                    res(x);
                };

                var reject = function (x) {
                    cancelTimeout();
                    saleInProgress = false;
                    rej(x);
                };

                var onData = function (x) {
                    createTimeout();
                };

                var onConnected = function () {
                    createTimeout();
                };

                try {
                    f(resolve, reject, onData, onConnected);
                } catch (e) {
                    ErrorLoggingService.log(e);
                    reject(e);
                }
            };
        };

        return _self;
    }]);

    freshideas.factory('GlobalPayCommon', [
        '$timeout',
        '$log',
        'TerminalEvent',
        'ErrorLoggingService',
        function ($timeout, $log, TerminalEvent, ErrorLoggingService) {
        var _self = {};

        var config;
        var printResult;
        var extraPrintResult;
        var responses;
        var terminalReceiptData;

        const HEARTBEAT = '\x11';
        const FS = '\x1c';

        const TRANSACTION_TYPE = '100';
        const TRANSACTION_STATUS = '101'; // missing

        const TRANSACTION_DATE = '102';
        const TRANSACTION_TIME = '103';

        const HOST_RESPONSE_CODE = '401';

        const TRANSACTION_TYPE_PURCHASE = '00';
        const TRANSACTION_TYPE_REFUND = '03';
        const TRANSACTION_TYPE_VOID = '05';
        const TRANSACTION_TYPE_AUTO_SETTLE = '21';
        const LAST_TRANSACTION_STATUS = '91';

        const DATA_TAG_TRANSACTION_AMOUNT = '\x1c001';

        // first data uses this for void ID
        const DATA_TAG_ECR_REFERENCE_NUMBER = '\x1c006';
        // global payments uses this for void ID
        const DATA_TAG_REFERENCE_ID = '\x1c011';

        // tender type not required in void transaction
        const TRANSACTION_AMOUNT = '104';
        const TIP_AMOUNT = '105';
        const TOTAL_AMOUNT = '109';
        const INVOICE_NUMBER = '110';
        const REFERENCE_NUM = '112';
        const TRANSACTION_SEQUENCE_NUM = '113';
        const CARD_TYPE = '300';
        const CARD_BRAND = '301';
        const MASKED_CARD_NUMBER = '302';
        const CUSTOMER_LANGUAGE = '303';
        const DEBIT_ACCOUNT_TYPE = '305';
        const ENTRY_METHOD = '306';
        const EMV_AID = '308';
        const EMV_TVR = '309';
        const EMV_TSI = '310';
        const EMV_APP_LABEL = '311';
        const EMV_TC= '316';
        const CVM_RESULT = '312';
        const BALANCE_DUE = '317'; // missing
        const TTQ = '318';
        const AAC = '319';

        const AUTHORIZATION_NUM = '400';
        // const SUCCESS = '401';
        const HOST_RESPONSE_ISO_CODE = '403';
        const PARTIAL_APPROVED_AMOUNT = '420';
        const BATCH_NUM = '500';
        const DEMO_MODE = '600';
        const TERMINAL_ID = '601';
        const MERCHANT_ID = '602';

        var cardEntryMode = {
            '0': 'Magnetic Stripe',
            '1': 'Insert (Chip)',
            '2': 'Tap (Contactless)',
            '3': 'Manual Entry',
            '4': 'Chip Fallback to Swipe',
            '5': 'Chip Fallback to Manual',
            '6': 'Card Not Present Manual',
        };

        var customerAccountType = {
            '0': 'default',
            '1': 'savings',
            '2': 'chequing',
        };

        /*
        100:"00"
        102:"170724"
        103:"165334"
        104:"2"
        109:"2"
        112:"19"
        300:"02"
        302:"************4111"
        306:"2"
        400:"99999999"
        401:"000"
        402:"APPROVED"
        403:"00"
        600:"1"
        601:"VARTST04"
        */

        /* Debit print request
        {
            "100": "00",
            "101": "00",
            "102": "170725",
            "103": "101626",
            "104": "2",
            "109": "2",
            "112": "23",
            "300": "00",
            "301": "Debit",
            "302": "************1933",
            "303": "0",
            "305": "2",
            "306": "1",
            "308": "A0000002771010",
            "309": "0000000000",
            "310": "2000",
            "311": "Interac",
            "312": "2",
            "400": "99999999",
            "401": "000",
            "402": "APPROVED",
            "403": "00",
            "500": "1",
            "600": "1",
            "601": "VARTST04",
            "602": "351395",
            "700": "GPC TEST",
            "701": "123456",
            "707": "Thank You",
            "708": "Please Come Again",
            "730": "Z1"
        }
        */

        _self.init = function (terminalConfig) {
            config = terminalConfig;
            printResult = null;
            extraPrintResult = [];
            responses = [];
            terminalReceiptData = [];
        };

        var splitFields = function (fieldStr) {
            if (fieldStr[0] != FS) {
                throw new Error('Fields does not start with correct character');
            }

            // remove leading FS
            fieldStr = fieldStr.substring(1);

            var result = {};
            var fields = fieldStr.split(FS);
            for (var i of Object.keys(fields)) {
                var field = fields[i];
                var tag = field.substring(0, 3);
                var data = field.substring(3);
                result[tag] = data;
            }

            return result;
        };

        var parseReceived = function (str) {
            var tranStatus = str.substring(0, 2);
            var multiTran = str[2];
            var fields = str.substring(3);

            var result = fields ? splitFields(fields) : {};

            if (!result[TRANSACTION_STATUS]) {
                result[TRANSACTION_STATUS] = tranStatus;
            }

            // tranStatus 91 means we recalled the transaction info from the terminal
            // in this case we want to set the tranStatus what we would normally receive
            // if we had not recalled from the terminal
            if (tranStatus == '91') {
                if (result['101']) {
                    tranStatus = result['101'];
                    multiTran = '0';
                } else {
                    throw new Error('Recall last transaction without transaction status!');
                }
            }

            return [tranStatus, multiTran, result];
        };

        var transactionTypeMap = {
            '00': 'purchase',
            // '01': 'Preauth', //not supported
            // '02': 'Preauth Completion', //not supported
            '03': 'refund',
            '05': 'void',
            '06': 'cardBalanceInquiry',
            '20': 'settlement',
        };

        var toTransactionType = function (code) {
            return transactionTypeMap[code];
        };

        var toTransactionDateTime = function (date, time) {
            if (date && time) {
                var year, month, day, hour, minute, second;

                [year, month, day] = date.match(/.{1,2}/g);
                [hour, minute, second] = time.match(/.{1,2}/g);

                return '20'+year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second;
            } else {
                // for failed transactions, it is possible the terminal does not
                // return any datetime to us. In this case we will make it ourselves.
                return moment().format('YYYY-MM-DD HH:mm:ss');
            }
        };

        _self.ingenicoTerminalResponse = function (transactionStatus, result) {
            /*
            We're using this parser to process both Moneris and GlobalPayment responses.
            There're minor differences:

            - Moneris signature line TAG(713)
                0 – Do not print signature line
                1 – Print Cardholder Signature line
                2 – Print Merchant Signature line
            -  Moneris returns single digit strings for CARD_TYPE
            */

            var isDebit = result[CARD_TYPE] === '00';
            // 00 - Debit
            // 09 - Gift Card/ Non-Bank Card
            // 10 - Cash
            // 12 - UnionPay Debit
            // not present in this list, then it's a credit card
            var isCredit = ['00', '09', '10', '12'].indexOf(result[CARD_TYPE]) == -1;

            var transactionType = toTransactionType(result[TRANSACTION_TYPE]);

            // we are showing signature lines for all refunds/voids as per
            // Real Fruit's request
            var showSignatureLine = (isCredit && (result[CVM_RESULT] === '2' || result[CVM_RESULT] === '3'))
                    || transactionType == 'void'
                    || transactionType == 'refund';


            var verifiedByPin = result[CVM_RESULT] === '1' || result[CVM_RESULT] === '3';

            var response = {
                paymentProcessor: 'globalpayments',

                terminalId: result[TERMINAL_ID],
                transactionId: result[REFERENCE_NUM],

                transactionType: transactionType,
                cardType: result[CARD_TYPE],
                cardName: result[CARD_BRAND],
                maskedCardNumber: result[MASKED_CARD_NUMBER],
                cardNumber: result[MASKED_CARD_NUMBER],
                success: result[TRANSACTION_STATUS] == '00' || result[TRANSACTION_STATUS] == '01',
                // approvedWithMalfunc: (result[TRANSACTION_STATUS] == '01'),
                balanceDue: result[BALANCE_DUE] ? (result[BALANCE_DUE]/100).toFixed(2) : 0.0,
                batchNum: result[BATCH_NUM],
                tipAmount: result[TIP_AMOUNT] ? (result[TIP_AMOUNT]/100).toFixed(2) : 0.0,
                signatureBase64Png: null,
                debitAccountType: customerAccountType[result[DEBIT_ACCOUNT_TYPE]],
                emvAid: result[EMV_AID],
                emvTvr: result[EMV_TVR],
                emvTsi: result[EMV_TSI],
                emvAppLabel: result[EMV_APP_LABEL],
                emvTc: result[EMV_TC],
                ttq: result[TTQ],
                aac: result[AAC],
                cvmResult: result[CVM_RESULT],
                hostResponseCode: result[HOST_RESPONSE_CODE],
                hostResponseIsoCode: result[HOST_RESPONSE_ISO_CODE],
                demoMode: result[DEMO_MODE] === '1',
                isCredit: isCredit,
                isDebit: isDebit, // is debit
                showCustomerSignatureLine: showSignatureLine,

                customerLanguage: result[CUSTOMER_LANGUAGE] === '1' ? 'fr': 'en',
                merchantId: result[MERCHANT_ID],
                transactionAmount: result[TRANSACTION_AMOUNT] ? (result[TRANSACTION_AMOUNT]/100).toFixed(2) : '0.00',
                partiallyApproved: false,
                transactionTypeCode: result[TRANSACTION_TYPE],
                surchargeAmount: null,
                totalAmount: result[TOTAL_AMOUNT] ? (result[TOTAL_AMOUNT]/100).toFixed(2) : '0.00',
                approvedAmount: result[TOTAL_AMOUNT] ? (result[TOTAL_AMOUNT]/100).toFixed(2) : '0.00',
                cardBalance: null,
                transactionDateTime: toTransactionDateTime(result[TRANSACTION_DATE], result[TRANSACTION_TIME]),
                referenceNumber: result[REFERENCE_NUM],
                cardEntryMethod: cardEntryMode[result[ENTRY_METHOD]],
                authorizationNumber: result[AUTHORIZATION_NUM],
                emvAppPreferredName: result[EMV_APP_LABEL],
                transactionStatus: result[TRANSACTION_STATUS] || transactionStatus,
                verifiedByPin: verifiedByPin,
                invoiceNumber: result[INVOICE_NUMBER],
                formFactor: null,
                transactionSequenceNum: result[TRANSACTION_SEQUENCE_NUM],
            };

            if (result[PARTIAL_APPROVED_AMOUNT]) {
                response.partiallyApproved = (result[PARTIAL_APPROVED_AMOUNT]/100).toFixed(2);
            }

            return response;
        };

        function trimHeartbeats (text) {
            text = text.replace(new RegExp('^(['+HEARTBEAT+']*)', 'g'), '');
            text = text.replace(new RegExp('(['+HEARTBEAT+']*)$', 'g'), '');
            return text;
        }

/*
 * example of receiving multiple messages and prints. The first print essentially
 * tells us the user tapped their card, but this was rejected, they need to insert chip.
[
{"type":"write","data":"00\u001c001902"},
{"type":"read","data":"\u0011","printResult":null},
{"type":"read","data":"\u0011","printResult":null},
{"type":"read","data":"\u0011","printResult":null},
{"type":"read","data":"\u0011","printResult":null},
{"type":"read","data":"990\u001c10000\u001c10110\u001c102190424\u001c103172614\u001c104902\u001c109902\u001c11220\u001c1130480010010200\u001c30000\u001c301Debit\u001c302************5002\u001c3030\u001c3050\u001c3062\u001c308A0000002771010\u001c3098000008000\u001c311INTERAC\u001c401983\u001c402983 INSERT CARD\u001c40389\u001c50048\u001c601C8022554\u001c6028022554\u001c700REAL FRUIT BUBBLE TEA\u001c70125 PEEL CENTRE DR\u001c702UNIT 527A\u001c703BRAMALEA CITY CENTRE\u001c704BRAMPTON, ON L6T3R5\u001c705(888) 896-1829\u001c73089","printResult":null},
{"type":"read","data":"\u0011","printResult":{"100":"00","101":"10","102":"190424","103":"172614","104":"902","109":"902","112":"20","113":"0480010010200","300":"00","301":"Debit","302":"************5002","303":"0","305":"0","306":"2","308":"A0000002771010","309":"8000008000","311":"INTERAC","401":"983","402":"983 INSERT CARD","403":"89","500":"48","601":"C8022554","602":"8022554","700":"REAL FRUIT BUBBLE TEA","701":"25 PEEL CENTRE DR","702":"UNIT 527A","703":"BRAMALEA CITY CENTRE","704":"BRAMPTON, ON L6T3R5","705":"(888) 896-1829","730":"89"}},
{"type":"read","data":"\u0011","printResult":{"100":"00","101":"10","102":"190424","103":"172614","104":"902","109":"902","112":"20","113":"0480010010200","300":"00","301":"Debit","302":"************5002","303":"0","305":"0","306":"2","308":"A0000002771010","309":"8000008000","311":"INTERAC","401":"983","402":"983 INSERT CARD","403":"89","500":"48","601":"C8022554","602":"8022554","700":"REAL FRUIT BUBBLE TEA","701":"25 PEEL CENTRE DR","702":"UNIT 527A","703":"BRAMALEA CITY CENTRE","704":"BRAMPTON, ON L6T3R5","705":"(888) 896-1829","730":"89"}},
{"type":"read","data":"\u0011","printResult":{"100":"00","101":"10","102":"190424","103":"172614","104":"902","109":"902","112":"20","113":"0480010010200","300":"00","301":"Debit","302":"************5002","303":"0","305":"0","306":"2","308":"A0000002771010","309":"8000008000","311":"INTERAC","401":"983","402":"983 INSERT CARD","403":"89","500":"48","601":"C8022554","602":"8022554","700":"REAL FRUIT BUBBLE TEA","701":"25 PEEL CENTRE DR","702":"UNIT 527A","703":"BRAMALEA CITY CENTRE","704":"BRAMPTON, ON L6T3R5","705":"(888) 896-1829","730":"89"}},
{"type":"read","data":"\u0011","printResult":{"100":"00","101":"10","102":"190424","103":"172614","104":"902","109":"902","112":"20","113":"0480010010200","300":"00","301":"Debit","302":"************5002","303":"0","305":"0","306":"2","308":"A0000002771010","309":"8000008000","311":"INTERAC","401":"983","402":"983 INSERT CARD","403":"89","500":"48","601":"C8022554","602":"8022554","700":"REAL FRUIT BUBBLE TEA","701":"25 PEEL CENTRE DR","702":"UNIT 527A","703":"BRAMALEA CITY CENTRE","704":"BRAMPTON, ON L6T3R5","705":"(888) 896-1829","730":"89"}},
{"type":"read","data":"\u0011","printResult":{"100":"00","101":"10","102":"190424","103":"172614","104":"902","109":"902","112":"20","113":"0480010010200","300":"00","301":"Debit","302":"************5002","303":"0","305":"0","306":"2","308":"A0000002771010","309":"8000008000","311":"INTERAC","401":"983","402":"983 INSERT CARD","403":"89","500":"48","601":"C8022554","602":"8022554","700":"REAL FRUIT BUBBLE TEA","701":"25 PEEL CENTRE DR","702":"UNIT 527A","703":"BRAMALEA CITY CENTRE","704":"BRAMPTON, ON L6T3R5","705":"(888) 896-1829","730":"89"}},
{"type":"read","data":"990\u001c10000\u001c10100\u001c102190424\u001c103172653\u001c104902\u001c109902\u001c11221\u001c1130480010010210\u001c30000\u001c301Debit\u001c302************5002\u001c3030\u001c3052\u001c3061\u001c308A0000002771010\u001c3098080008000\u001c3106800\u001c311INTERAC\u001c3121\u001c400628123\u001c401001\u001c402APPROVED 628123\u001c40300\u001c50048\u001c601C8022554\u001c6028022554\u001c700REAL FRUIT BUBBLE TEA\u001c70125 PEEL CENTRE DR\u001c702UNIT 527A\u001c703BRAMALEA CITY CENTRE\u001c704BRAMPTON, ON L6T3R5\u001c705(888) 896-1829\u001c707Thank You!\u001c73000","printResult":{"100":"00","101":"10","102":"190424","103":"172614","104":"902","109":"902","112":"20","113":"0480010010200","300":"00","301":"Debit","302":"************5002","303":"0","305":"0","306":"2","308":"A0000002771010","309":"8000008000","311":"INTERAC","401":"983","402":"983 INSERT CARD","403":"89","500":"48","601":"C8022554","602":"8022554","700":"REAL FRUIT BUBBLE TEA","701":"25 PEEL CENTRE DR","702":"UNIT 527A","703":"BRAMALEA CITY CENTRE","704":"BRAMPTON, ON L6T3R5","705":"(888) 896-1829","730":"89"}},
{"type":"read","data":"101\u001c10000\u001c102190424\u001c103172614\u001c104902\u001c109902\u001c30000\u001c302************5002\u001c3062\u001c401983\u001c402983 INSERT CARD\u001c40389\u001c601C8022554","printResult":{"100":"00","101":"00","102":"190424","103":"172653","104":"902","109":"902","112":"21","113":"0480010010210","300":"00","301":"Debit","302":"************5002","303":"0","305":"2","306":"1","308":"A0000002771010","309":"8080008000","310":"6800","311":"INTERAC","312":"1","400":"628123","401":"001","402":"APPROVED 628123","403":"00","500":"48","601":"C8022554","602":"8022554","700":"REAL FRUIT BUBBLE TEA","701":"25 PEEL CENTRE DR","702":"UNIT 527A","703":"BRAMALEA CITY CENTRE","704":"BRAMPTON, ON L6T3R5","705":"(888) 896-1829","707":"Thank You!","730":"00"}},
{"type":"read","data":"000\u001c10000\u001c102190424\u001c103172653\u001c104902\u001c109902\u001c11221\u001c1130480010010210\u001c30000\u001c302************5002\u001c3061\u001c400628123\u001c401001\u001c402APPROVED 628123\u001c40300\u001c601C8022554","printResult":{"100":"00","101":"00","102":"190424","103":"172653","104":"902","109":"902","112":"21","113":"0480010010210","300":"00","301":"Debit","302":"************5002","303":"0","305":"2","306":"1","308":"A0000002771010","309":"8080008000","310":"6800","311":"INTERAC","312":"1","400":"628123","401":"001","402":"APPROVED 628123","403":"00","500":"48","601":"C8022554","602":"8022554","700":"REAL FRUIT BUBBLE TEA","701":"25 PEEL CENTRE DR","702":"UNIT 527A","703":"BRAMALEA CITY CENTRE","704":"BRAMPTON, ON L6T3R5","705":"(888) 896-1829","707":"Thank You!","730":"00"}}
]
 * */

        _self.onDataReceived = function (data, callback) {
            var dataStr = data.toString();
            try {
                var raw = trimHeartbeats(dataStr);
                if (raw.length > 0) {
                    var arr = parseReceived(raw);
                    let transactionStatus = arr[0];
                    var multiTran = arr[1];
                    let result = arr[2];

                    responses.push(arr);

                    if (transactionStatus === '99' && result[TRANSACTION_STATUS] === '01') {
                        terminalReceiptData.push(result);
                    }

                    if (multiTran === '0') {
                        allDataReceived(responses, callback);
                        responses = [];
                    }
                }
            } catch (error) {
                ErrorLoggingService.log(
                    {
                        message: error && error.toString(),
                        data: dataStr
                    }
                );
                throw error;
            }
        };

        function allDataReceived (responses, callback) {
            var success = true;

            for (var i of Object.keys(responses)) {
                var arr = responses[i];

                var transactionStatus = arr[0];
                let isMulitTrx = arr[1] === '1';
                var result = arr[2];
                let reference = result[REFERENCE_NUM];

                if (transactionStatus === '99') {
                    printResult = result;
                    callback(TerminalEvent.PRINT, printResult, transactionStatus);

                    return;
                } else if (['00', '01'].includes(transactionStatus)) {
                    success = true;
                    if (!printResult) {
                        // We receive '00' or '01' as a final message from the
                        // terminal after a successful transaction. If for some
                        // reason the terminal did *not* send us a printResult
                        // before this, then we need to use the current result
                        // as the printResult. printResult has more information
                        // such as the printable information (card name etc.)
                        // but it appears we can make do without that...
                        printResult = result;
                    }

                    // print partial approval response
                    if (isMulitTrx) {
                        extraPrintResult.push({'arr': arr, 'terminalReceiptData': getReceiptData(reference)});
                    }
                } else if (transactionStatus === '15') {
                    callback(TerminalEvent.BATCH_EMPTY);

                    return;
                } else if (['10', '11', '12', '13', '14', '16', '17', '18'].includes(transactionStatus)) {
                    success = false;
                    if (!printResult) {
                        // We receive '00' or '01' as a final message from the terminal
                        // after a successful transaction. If for some reason the terminal
                        // did *not* send us a printResult before this, then we need to use
                        // the current result as the printResult. The two have a similar
                        printResult = result;
                    }

                    // set mulit-transaction or partial approval result to print
                    if (isMulitTrx) {
                        extraPrintResult.push({'arr': arr, 'terminalReceiptData': getReceiptData(reference)});
                    }
                } else if (transactionStatus === '95') {
                    callback(TerminalEvent.BUSY);

                    return;
                } else {
                    success = false;
                }
            }

            // TODO only storing last result for partial transaction!!
            if (success) {
                callback(TerminalEvent.SUCCESS, printResult, transactionStatus, extraPrintResult);
            } else {
                callback(TerminalEvent.FAILED, printResult, transactionStatus, extraPrintResult);
            }

            // reset extraPrintResult && terminalReceiptData
            extraPrintResult = [];
            terminalReceiptData = [];
        }

        function getReceiptData (reference) {
            return terminalReceiptData.filter((el) => el[REFERENCE_NUM] === reference)[0];
        }

        // Command Builders
        _self.creditSaleCmd = function (amount) {
            return TRANSACTION_TYPE_PURCHASE
                        + DATA_TAG_TRANSACTION_AMOUNT
                        + Math.round(Number(amount)*100, 10);
        };

        _self.lastTransactionStatus = function () {
            return LAST_TRANSACTION_STATUS;
        };

        _self.creditVoidCmd = function (transactionId) {
            var voidField = null;
            if (config.type == 'globalpay') {
                voidField = DATA_TAG_REFERENCE_ID;
            } else if (config.type == 'ingenico') {
                voidField = DATA_TAG_ECR_REFERENCE_NUMBER;
            } else {
                throw new Error('Unknown terminal type: ' + config.type);
            }

            return TRANSACTION_TYPE_VOID
                        + voidField
                        + transactionId;
        };

        _self.creditReturnCmd = function (amount) {
            return TRANSACTION_TYPE_REFUND
                        + '\x1c001'
                        + Math.round(Number(amount)*100, 10);
        };

        _self.autoSettleCmd = function () {
            return TRANSACTION_TYPE_AUTO_SETTLE;
        };

        _self.getPrintResult = function () {
            return printResult;
        };

        var saleInProgress = false;
        _self.timeoutWrapper = function (f, timedOut) {
            return function (res, rej) {
                var timeout = null;

                if (saleInProgress) {
                    $log.error('Multiple sales initiated in parallel!');
                }
                saleInProgress = true;

                var createTimeout = function () {
                    if (timeout) {
                        cancelTimeout();
                    }
                    timeout = $timeout(function () {
                        timedOut(resolve, reject);
                    }, 60000);
                };

                var cancelTimeout = function () {
                    if (timeout) {
                        $timeout.cancel(timeout);
                        timeout = null;
                    }
                };

                var resolve = function (x) {
                    cancelTimeout();
                    saleInProgress = false;
                    res(x);
                };

                var reject = function (x) {
                    cancelTimeout();
                    saleInProgress = false;
                    rej(x);
                };

                var onData = function (x) {
                    createTimeout();
                };

                var onConnected = function () {
                    createTimeout();
                };

                try {
                    f(resolve, reject, onData, onConnected);
                } catch (e) {
                    ErrorLoggingService.log(e);
                    reject(e);
                }
            };
        };

        return _self;
    }]);

    freshideas.factory('TerminalCommon', [
        'GlobalPayCommon',
        'MonerisCommon',
        function (GlobalPayCommon, MonerisCommon) {
            var terminalFactory;

            var init = function (terminalConfig) {
                if (terminalConfig.type == 'globalpay') {
                    terminalFactory = GlobalPayCommon;
                } else if (terminalConfig.type == 'moneris') {
                    terminalFactory = MonerisCommon;
                } else {
                    terminalFactory = GlobalPayCommon;
                }

                terminalFactory.init(terminalConfig);
            };

            var getInstance = function () {
                return terminalFactory;
            };

            return {
                init: init,
                getInstance: getInstance
            };
        }
    ]);

    freshideas.factory('iOSCardTerminalGlobalPayEast', [
        '$log',
        'IosTcpClient',
        'TerminalCommon',
        'TerminalEvent',
        'ErrorLoggingService',
        function (
            $log,
            IosTcpClient,
            TerminalCommon,
            TerminalEvent,
            ErrorLoggingService) {
            var config;

            var init = function (terminalConfig) {
                config = terminalConfig;

                TerminalCommon.init(terminalConfig);
            };

            var respondTo = function (client, event, result, transactionStatus, extraPrintResult, successCallback, errorCallback) {
                let extraInfo = [];

                if (extraPrintResult && extraPrintResult.length > 0) {
                    for (let item of extraPrintResult) {
                        let trxStatus = item.arr[0];
                        let isMulitTrx = (item.arr[1] === '1');
                        let isPartialApproval = (trxStatus === '01');
                        let trxRaw = (isPartialApproval && item.terminalReceiptData) ? item.terminalReceiptData : item.arr[2];

                        extraInfo.push({
                            receiptData: TerminalCommon.getInstance().ingenicoTerminalResponse(trxStatus, trxRaw),
                            raw: trxRaw,
                            isMulitTrx: isMulitTrx,
                            isPartialApproval: isPartialApproval,
                            alreadyNotified: true
                        });
                    }
                }

                if (event == TerminalEvent.PRINT) {
                    setTimeout(function () {
                        client.write('990');
                    }, 1500);
                } else if (event == TerminalEvent.SUCCESS) {
                    client.disconnect();

                    successCallback([
                        TerminalCommon.getInstance().ingenicoTerminalResponse(transactionStatus, result),
                        result,
                        extraInfo
                    ]);
                } else if (event == TerminalEvent.FAILED) {
                    client.disconnect();
                    if (_.isEmpty(result)) {
                        errorCallback(
                            {
                                data: null,
                                message: 'FAILED transaction with no payload'
                            }
                        );
                    } else {
                        errorCallback({
                            data: [
                                TerminalCommon.getInstance().ingenicoTerminalResponse(transactionStatus, result),
                                result,
                                extraInfo
                        ]});
                    }
                } else if (event == TerminalEvent.BUSY) {
                    client.disconnect();
                    errorCallback({
                        data: null,
                        message: 'Terminal is busy'
                    });
                } else {
                    client.disconnect();
                    if (_.isEmpty(result)) {
                        errorCallback(
                            {
                                data: null,
                                message: 'Unknown event: ' + event
                            }
                        );
                    } else {
                        errorCallback({
                            data: [
                                TerminalCommon.getInstance().ingenicoTerminalResponse(transactionStatus, result),
                                result
                            ]
                        });
                    }
                }
            };

            var lastTransactionStatus = function () {
                return new Promise(function (resolve, reject) {
                  IosTcpClient.connect(config.port, config.ip).then(function (client) {

                    var defaultMessage = TerminalCommon.lastTransactionStatus();

                    // Listen to the stream of data from the terminal
                    client.onData(function (data) {
                        try {
                            TerminalCommon.getInstance().onDataReceived(data, function (event, result, transactionStatus, extraPrintResult = null) {
                                try {
                                    respondTo(client, event, result, transactionStatus, extraPrintResult, function (response) {
                                        resolve(response);
                                    }, function (error) {
                                        reject(error);
                                    });
                                } catch (e) {
                                    ErrorLoggingService.log(e);
                                    ErrorLoggingService.log(result);
                                    throw e;
                                }
                            });
                        } catch (e) {
                            ErrorLoggingService.log(e);
                            ErrorLoggingService.log({
                                message: defaultMessage,
                                data: data
                            });
                            reject();
                        }
                    });

                    client.write(defaultMessage);
                }).catch(function (error) {
                    ErrorLoggingService.log(error);
                    reject({message: error.toString(), type: 'busy'});
                });
              });
            };

            var creditSale = function (amount) {
                var trace = [];
                var didResolveOrReject = false;

                var timedOut = function (resolve, reject) {
                    if (!didResolveOrReject) {
                        // the connection closed before we know the state of the transaction!!
                        // let's get the lastTransaction status
                        // lastTransactionStatus().then(resolve, reject);
                        reject({timedOut: true});
                    }
                };

                return new Promise(TerminalCommon.getInstance().timeoutWrapper(function (resolve, reject, onData, onConnected) {
                    IosTcpClient.connect(config.port, config.ip).then(function (client) {
                        onConnected();

                        var defaultMessage = TerminalCommon.getInstance().creditSaleCmd(amount);

                        // Listen to the stream of data from the terminal
                        client.onData(function (data) {
                            try {
                                onData();
                                trace.push(
                                    {
                                        type: 'read',
                                        data: data ? data.toString() : 'NA',
                                        printResult: TerminalCommon.getInstance().getPrintResult(),
                                    }
                                );

                                TerminalCommon.getInstance().onDataReceived(data, function (event, result, transactionStatus, extraPrintResult = null) {
                                    respondTo(client, event, result, transactionStatus, extraPrintResult, function (response) {
                                        didResolveOrReject = true;
                                        resolve(response);
                                    }, function (error) {
                                        ErrorLoggingService.log(trace);
                                        reject(error);
                                    });
                                });
                            } catch (e) {
                                ErrorLoggingService.log(e);
                                ErrorLoggingService.log(trace);
                                ErrorLoggingService.log({
                                    message: defaultMessage,
                                    data: data
                                });
                                didResolveOrReject = true;
                                reject({message: e.toString()});
                            }
                        });

                        trace.push(
                            {
                                type: 'write',
                                data: defaultMessage,
                            }
                        );
                        client.write(defaultMessage);
                    }).catch(function (error) {
                        ErrorLoggingService.log(error);
                        ErrorLoggingService.log(trace);
                        didResolveOrReject = true;
                        reject({message: error && error.toString()});
                    });
                }, timedOut));
            };

            var creditVoid = function (transactionId, params={}) {
                return new Promise(function (resolve, reject) {
                    IosTcpClient.connect(config.port, config.ip).then(function (client) {
                        // Listen to the stream of data from the terminal
                        client.onData(function (data) {
                            TerminalCommon.getInstance().onDataReceived(data, function (event, result, transactionStatus, extraPrintResult = null) {
                                respondTo(client, event, result, transactionStatus, extraPrintResult, function (response) {
                                    resolve(response);
                                }, function (error) {
                                    reject(error);
                                });
                            });
                        });

                        var defaultMessage = TerminalCommon.getInstance().creditVoidCmd(transactionId, params);

                        client.write(defaultMessage);
                    }).catch(function (error) {
                        ErrorLoggingService.log(error);
                        reject();
                    });
                });
            };

            var creditReturn = function (amount, params={}) {
                return new Promise(function (resolve, reject) {
                    IosTcpClient.connect(config.port, config.ip).then(function (client) {
                        // Listen to the stream of data from the terminal
                        client.onData(function (data) {
                            TerminalCommon.getInstance().onDataReceived(data, function (event, result, transactionStatus, extraPrintResult = null) {
                                respondTo(client, event, result, transactionStatus, extraPrintResult, function (response) {
                                    resolve(response);
                                }, function (error) {
                                    reject(error);
                                });
                            });
                        });

                        var defaultMessage = TerminalCommon.getInstance().creditReturnCmd(amount, params);

                        client.write(defaultMessage);
                    }).catch(function (error) {
                        ErrorLoggingService.log(error);
                        reject();
                    });
                });
            };

            var autoSettle = function () {
                return new Promise(function (resolve, reject) {
                    IosTcpClient.connect(config.port, config.ip).then(function (client) {
                        // Listen to the stream of data from the terminal
                        client.onData(function (data) {
                            TerminalCommon.getInstance().onDataReceived(data, function (event, result, transactionStatus, extraPrintResult = null) {
                                if (event == TerminalEvent.SUCCESS
                                    || event == TerminalEvent.BATCH_EMPTY) {
                                    resolve(result);
                                } else {
                                    ErrorLoggingService.log({
                                        message: event,
                                        data: result
                                    });
                                    reject();
                                }

                                client.disconnect();
                            });
                        });

                        var cmd = TerminalCommon.getInstance().autoSettleCmd();

                        client.write(cmd);
                    }).catch(function (error) {
                        ErrorLoggingService.log(error);
                        reject();
                    });
                });
            };

            var debitSale = function (amount, params) {
                return creditSale(amount, params);
            };

            var debitReturn = function (amount, params) {
                return creditReturn(amount, params);
            };

            var testSale = function () {
                return creditSale(0.01);
            };

            var parseResponse = function (response) {
                return TerminalCommon.getInstance().ingenicoTerminalResponse(null, response);
            };

            var isCancellableFromPos = function () {
                return false;
            };

            return {
                init: init,
                creditSale: creditSale,
                creditVoid: creditVoid,
                creditReturn: creditReturn,
                debitSale: debitSale,
                debitReturn: debitReturn,
                testSale: testSale,
                autoSettle: autoSettle,
                parseResponse: parseResponse,
                isCancellableFromPos: isCancellableFromPos,
                lastTransactionStatus: lastTransactionStatus
            };
        }]);


    freshideas.factory('CardTerminalGlobalPayEast', [
        '$log',
        'TerminalCommon',
        'TerminalEvent',
        'ErrorLoggingService',
        function (
            $log,
            TerminalCommon,
            TerminalEvent,
            ErrorLoggingService) {
            var config;
            var client;

            if (typeof nodeRequire !== 'undefined') {
                var net = nodeRequire('net');
            }

            var init = function (terminalConfig) {
                if (typeof window !== 'undefined') {
                    // running from browser
                    if (!window.process || !window.process.type) {
                        throw new Error('Running in non-electron environment. Terminal will be disabled');
                    }
                }

                client = new net.Socket();

                TerminalCommon.init(terminalConfig);

                config = terminalConfig;
            };

            var respondTo = function (client, event, result, transactionStatus, extraPrintResult, successCallback, errorCallback) {
                let extraInfo = [];

                if (extraPrintResult && extraPrintResult.length > 0) {
                    for (let item of extraPrintResult) {
                        let trxStatus = item.arr[0];
                        let isMulitTrx = (item.arr[1] === '1');
                        let isPartialApproval = (trxStatus === '01');
                        let trxRaw = (isPartialApproval && item.terminalReceiptData) ? item.terminalReceiptData : item.arr[2];

                        extraInfo.push({
                            receiptData: TerminalCommon.getInstance().ingenicoTerminalResponse(trxStatus, trxRaw),
                            raw: trxRaw,
                            isMulitTrx: isMulitTrx,
                            isPartialApproval: isPartialApproval,
                            alreadyNotified: true
                        });
                    }
                }

                if (event == TerminalEvent.PRINT) {
                    setTimeout(function () {
                        client.write('990');
                    }, 1500);
                } else if (event == TerminalEvent.SUCCESS) {
                    successCallback([
                        TerminalCommon.getInstance().ingenicoTerminalResponse(transactionStatus, result),
                        result,
                        extraInfo
                    ]);
                } else if (event == TerminalEvent.FAILED) {
                    if (_.isEmpty(result)) {
                        errorCallback(
                            {
                                data: null,
                                message: 'FAILED transaction with no payload'
                            }
                        );
                    } else {
                        errorCallback({
                            data: [
                                TerminalCommon.getInstance().ingenicoTerminalResponse(transactionStatus, result),
                                result,
                                extraInfo
                        ]});
                    }
                } else if (event == TerminalEvent.BUSY) {
                    errorCallback({
                        data: null,
                        message: 'Terminal is busy'
                    });
                } else {
                    if (_.isEmpty(result)) {
                        errorCallback(
                            {
                                data: null,
                                message: 'Unknown event: ' + event
                            }
                        );
                    } else {
                        errorCallback({
                            data: [
                                TerminalCommon.getInstance().ingenicoTerminalResponse(transactionStatus, result),
                                result
                            ]
                        });
                    }
                }
            };

            var lastTransactionStatus = function () {
                var retryClient;
                return new Promise(function (resolve, reject) {
                    var connected = function () {
                        var defaultMessage = TerminalCommon.getInstance().lastTransactionStatus();

                        retryClient.on('data', function (data) {
                            try {
                                var status = data.toString().substring(0, 2);
                                if (status === '95') {
                                    // setTimeout(connect, 5000);
                                    // retryClient.end();
                                    reject({terminalBusy: true});
                                    return;
                                } else if (status === '91') {
                                    // remove leading '91\xfc'
                                    data = data.slice(3);
                                }

                                TerminalCommon.getInstance().onDataReceived(data, function (event, result, transactionStatus, extraPrintResult = null) {
                                    try {
                                        respondTo(retryClient, event, result, transactionStatus, extraPrintResult, function (response) {
                                            resolve(response);
                                        }, function (error) {
                                            reject(error);
                                        });
                                    } catch (e) {
                                        ErrorLoggingService.log(e);
                                        ErrorLoggingService.log(result);
                                        throw e;
                                    }
                                });
                            } catch (e) {
                                ErrorLoggingService.log(e);
                                ErrorLoggingService.log({
                                    message: defaultMessage,
                                    data: data
                                });
                                reject();
                            }
                        });

                        retryClient.write(defaultMessage);
                    };

                    var connect = function () {
                        retryClient = new net.Socket();
                        retryClient.connect({timeout: 5000, port: config.port, host: config.ip}, connected);

                        retryClient.on('error', function (error) {
                            /*
                            if (error && error.errno == 'ECONNREFUSED') {
                                // terminal is still busy - try connecting
                                // again later
                                setTimeout(connect, 5000);
                            } else {
                                reject({message: error.toString()});
                            }
                            */
                            reject({terminalBusy: true});
                        });
                    };

                    connect();
                });
            };

            var creditSale = function (amount) {
                var trace = [];
                var connectAttempts = 0;
                var didConnect = false;
                var didResolveOrReject = false;

                var timedOut = function (resolve, reject) {
                    if (client) {
                        client.end();
                    }
                    client = null;

                    if (!didResolveOrReject) {
                        didResolveOrReject = true;

                        reject({timedOut: true});
                    }
                };

                return new Promise(TerminalCommon.getInstance().timeoutWrapper(function (resolve, reject, onData, onConnected) {
                    var connect = function () {
                        connectAttempts++;

                        client.connect({timeout: 20000, port: config.port, host: config.ip}, function () {
                            onConnected();
                            didConnect = true;
                            var defaultMessage = TerminalCommon.getInstance().creditSaleCmd(amount);

                            client.on('data', function (data) {
                                try {
                                    onData();

                                    trace.push(
                                        {
                                            type: 'read',
                                            data: data ? data.toString() : 'NA',
                                            printResult: TerminalCommon.getInstance().getPrintResult(),
                                        }
                                    );

                                    TerminalCommon.getInstance().onDataReceived(data, function (event, result, transactionStatus, extraPrintResult = null) {
                                        respondTo(client, event, result, transactionStatus, extraPrintResult, function (response) {
                                            didResolveOrReject = true;
                                            resolve(response);
                                        }, function (error) {
                                            throw error;
                                        });
                                    });
                                } catch (e) {
                                    ErrorLoggingService.log(e);
                                    ErrorLoggingService.log(trace);
                                    didResolveOrReject = true;
                                    reject(e);
                                }
                            });

                            trace.push(
                                {
                                    type: 'write',
                                    data: defaultMessage,
                                }
                            );
                            client.write(defaultMessage);
                        });
                    };
                    connect();

                    client.on('error', function (error) {
                        if (!didConnect && connectAttempts < 3) {
                            setTimeout(connect, 1000);
                        } else {
                            if (didConnect) {
                                // only log errors after successful connect
                                ErrorLoggingService.log(error);
                                ErrorLoggingService.log(trace);

                                timedOut(resolve, reject);
                            } else {
                                didResolveOrReject = true;
                                reject({message: error.toString()});
                            }
                        }
                    });

                    client.on('close', function () {
                    });

                    client.on('end', function () {
                    });

                    client.on('timeout', function () {
                    });

                }, timedOut));
            };

            var creditVoid = function (transactionId, params={}) {
                return new Promise(function (resolve, reject) {
                    client.connect({timeout: 20000, port: config.port, host: config.ip}, function () {
                        var defaultMessage = TerminalCommon.getInstance().creditVoidCmd(transactionId, params);

                        client.write(defaultMessage);
                    });

                    client.on('error', function (error) {
                        reject();
                    });

                    client.on('data', function (data) {
                        TerminalCommon.getInstance().onDataReceived(data, function (event, result, transactionStatus, extraPrintResult = null) {
                            respondTo(client, event, result, transactionStatus, extraPrintResult, function (response) {
                                resolve(response);
                            }, function (error) {
                                reject(error);
                            });
                        });
                    });
                });
            };

            var creditReturn = function (amount, params={}) {
                return new Promise(function (resolve, reject) {
                    client.connect({timeout: 20000, port: config.port, host: config.ip}, function () {
                        var defaultMessage = TerminalCommon.getInstance().creditReturnCmd(amount, params);

                        client.write(defaultMessage);
                    });

                    client.on('error', function (error) {
                        reject();
                    });

                    client.on('data', function (data) {
                        TerminalCommon.getInstance().onDataReceived(data, function (event, result, transactionStatus, extraPrintResult = null) {
                            respondTo(client, event, result, transactionStatus, extraPrintResult, function (response) {
                                resolve(response);
                            }, function (error) {
                                reject(error);
                            });
                        });
                    });
                });
            };

            var autoSettle = function () {
                return new Promise(function (resolve, reject) {
                    client.connect({timeout: 20000, port: config.port, host: config.ip}, function () {
                        client.write(TerminalCommon.getInstance().autoSettleCmd());
                    });

                    client.on('error', function (error) {
                        reject();
                    });

                    client.on('data', function (data) {
                        TerminalCommon.getInstance().onDataReceived(data, function (event, result, transactionStatus, extraPrintResult = null) {
                            if (event == TerminalEvent.SUCCESS
                                || event == TerminalEvent.BATCH_EMPTY) {
                                resolve(result);
                            } else {
                                ErrorLoggingService.log({
                                    message: event,
                                    data: result
                                });
                                // reject();
                                resolve();
                            }
                        });
                    });
                });
            };

            var debitSale = function (amount) {
                return creditSale(amount);
            };

            var debitReturn = function (amount) {
                return creditReturn(amount);
            };

            var testSale = function () {
                return creditSale(0.01);
            };

            var parseResponse = function (response) {
                return TerminalCommon.getInstance().ingenicoTerminalResponse(null, response);
            };

            var isCancellableFromPos = function (response) {
                return false;
            };

            return {
                init: init,
                creditSale: creditSale,
                creditVoid: creditVoid,
                creditReturn: creditReturn,
                debitSale: debitSale,
                debitReturn: debitReturn,
                testSale: testSale,
                autoSettle: autoSettle,
                parseResponse: parseResponse,
                isCancellableFromPos: isCancellableFromPos,
                lastTransactionStatus: lastTransactionStatus,
            };
        }
    ]);
}
