'use strict';

(function () {
    var srmReceiptFactory = function () {
        // correspond to amount of characters that can be safely printer on 3" paper
        const PAPER_WIDTH = 40;
        var sections = [];

        var printer = {
            initialize: function () {
                return printer;
            },
            cut: function () {
                return printer;
            },
            section: function () {
                return printer;
            },
            align: function (alignment) {
                alignment = alignment || 'left';

                return printer;
            },
            title: function (elements, emphasis, styles) {
                var data = '';

                elements = elements || '';
                _.each(elements, function (element) {
                    data += (element + '');
                });

                data = '!' + data.replace(/\n/g, '') + '!  ';

                styles = styles || {};
                styles.width = styles.width || 1;
                styles.height = styles.height || 2;
                styles.invert = styles.invert || false;
                styles.emphasis = emphasis;
                styles.align = 'center';

                var row = {
                    data: [data],
                    styles: styles
                };
                sections.push(row);
                return printer;
            },
            titleJustified: function (elements, emphasis, styles, stylesRight) {
                var leftString = elements[0] + '';
                var rightString = elements[1] + '';

                var row = [leftString, rightString];

                sections.push({data: row});
                return printer;
            },
            text: function (elements, emphasis, styles) {
                var data = '';

                elements = elements || '';
                _.each(elements, function (element) {
                    data += (element + '');
                });

                data = data.replace(/\n/g, '');

                var row = [data];

                sections.push({data: row});
                return printer;
            },
            textForNewReciept: function (elements, emphasis, styles) {
                return printer.text(elements, emphasis, styles);
            },
            textJustified: function (elements, emphasis, styles, stylesRight, filler) {
                var leftString = elements[0] + '';
                var rightString = elements[1] + '';

                var row = [leftString, rightString];

                sections.push({data: row});
                return printer;
            },
            textJustifiedAndWrapped: function (elements, emphasis, styles, stylesRight, filler) {
                return printer.textJustified(elements, emphasis, styles, stylesRight, filler);
            },
            newLine: function (count) {
                let rows = Array(count).fill(['']);

                sections.push({data: rows});
                return printer;
            },
            divider: function (thin) {
                var divider = thin ? '-'.repeat(PAPER_WIDTH+1) : '='.repeat(PAPER_WIDTH);
                var row = {
                    data: [divider],
                };
                sections.push(row);
                return printer;
            },
            addSections: function (section) {
                sections.push(section);
            },
            output: function () {
                if (sections && sections.length) {
                    let request = '';

                    for (var section of sections) {
                        if (!Array.isArray(section.data)) {
                            let rowLen = section.data.length;
                            if (section.data !== '' && rowLen < PAPER_WIDTH) {
                                // center the text
                                let dif = Math.floor((PAPER_WIDTH - rowLen)/2);
                                let pad = ' '.repeat(dif === 0 ? 1 : dif);
                                section.data = [pad + section.data + pad];
                            } else {
                                section.data = [section.data];
                            }
                        } else {
                            if (section.data.length > 1) {
                                let rowLen = section.data[0].length + section.data[1].length;
                                if (rowLen > 0 && rowLen < PAPER_WIDTH) {
                                    let dif = PAPER_WIDTH - rowLen;
                                    let pad = ' '.repeat(dif);
                                    section.data[0] = section.data[0] + pad;
                                }
                            } else {
                                let rowLen = section.data[0].length;
                                if (section.data[0] !== '' && rowLen < PAPER_WIDTH) {
                                    // center the text
                                    let dif = Math.floor((PAPER_WIDTH - rowLen)/2);
                                    let pad = ' '.repeat(dif === 0 ? 1 : dif);
                                    section.data[0] = pad + section.data[0] + pad;
                                }
                            }
                        }
                        try {
                            request += '\n' + section.data.join(' ');
                        } catch (err) {
                            console.log(err);
                        }
                    }
                    return request;
                }
            }
        };

        return printer;
    };

    module.exports = srmReceiptFactory;
})();
