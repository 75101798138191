'use strict';


// const angular = require('angular');
module.exports = function (freshideasReports) {
    freshideasReports.controller('ReportsSidenav', [
        '$scope',
        '$state',
        'Security',
        'CompanyAttributesService',
        function ($scope, $state, Security, CompanyAttributesService) {

            $scope.state = $state;

            var init = function () {
                //
            };

            var reportsNavBtns = [
                {
                    // Overview
                    'url': 'freshideas.reports',
                    'icon': 'fa-desktop',
                    'translate': 'general.reportsOverview',
                    'permission': 'reports:overview',
                    'position': 0
                },
                {
                    // End of Shift
                    'url': 'freshideas.report.endOfShift',
                    'icon': 'fa-bell-o',
                    'translate': 'general.endOfShift',
                    'permission': 'reports:reprint_end_of_shift_report',
                    'position': 1
                },
                {
                    // Employee Shifts
                    'url': 'freshideas.report.employeeShifts',
                    'icon': 'fa-calendar-o',
                    'translate': 'Employee Shifts Report',
                    'permission': 'reports:employee_shifts_report',
                    'position': 2
                },
                {
                    // Location Revenue
                    'url': 'freshideas.report.locationRevenue',
                    'icon': 'fa-usd',
                    'translate': 'general.locationRevenue',
                    'permission': 'reports:location_revenue_report',
                    'position': 3
                },
                {
                    // Items Quantity Summary Reports
                    'url': 'freshideas.report.itemSales',
                    'icon': 'fa-shopping-basket',
                    'permission': 'reports:item_sales_report',
                    'translate': 'Items Quantity Summary Report',
                    'position': 4
                },
                {
                    // Items Quantity Summary Reports
                    'url': 'freshideas.report.advancedItemSales',
                    'icon': 'fa-shopping-cart',
                    'permission': 'reports:item_sales_report',
                    'translate': 'Advanced Items Quantity Summary Report',
                    'position': 5
                },
                {
                    // Sales Per 15 Minutes
                    'url': 'freshideas.report.patronCounts',
                    'icon': 'fa-users',
                    'translate': 'general.patronCounts',
                    'permission': 'reports:patron_count_per_15_min',
                    'position': 6
                },
                {
                    // Summary Report
                    'url': 'freshideas.report.summaryReport',
                    'icon': 'fa-bar-chart',
                    'translate': 'general.summaryReport',
                    'permission': 'reports:sales_summary_report',
                    'position': 7
                },
                {
                    // Sales Report
                    'url': 'freshideas.report.salesReport',
                    'icon': 'fa-file-text-o',
                    'translate': 'general.salesReport',
                    'permission': 'reports:sales_summary_report',
                    'position': 8
                },
                {
                    // Discounts Report
                    'url': 'freshideas.report.discounts',
                    'icon': 'fa-percent',
                    'translate': 'general.discountsReport',
                    'permission': 'reports:discounts_report',
                    'position': 9
                },
                {
                    // Gift Card Report
                    'url': 'freshideas.report.giftCard',
                    'icon': 'fa-gift',
                    'translate': 'general.giftCardReport',
                    'permission': 'reports:sales_summary_report',
                    'position': 10
                },
                {
                    // Inventory Stock Report
                    'url': 'freshideas.report.inventoryStock',
                    'icon': 'fa-truck',
                    'translate': 'general.inventoryStockReport',
                    'permission': 'reports:sales_summary_report',
                    'position': 24,
                    'disabled': CompanyAttributesService.hasMenuV2Enabled()
                },
                {
                    // DISABLED: Daily Sales and Meals Served
                    'url': 'freshideas.report.dailySalesMealsServed',
                    'icon': 'fa-calendar',
                    'translate': 'general.dailySalesMealsServed',
                    'permission': 'reports:daily_sales_and_meals_served',
                    'position': 11,
                    'disabled': !$scope.showMeals
                },
                {
                    // DISABLED: Meal Plan Usage
                    'url': 'freshideas.report.mealPlanUsage',
                    'icon': 'fa-cutlery',
                    'translate': 'general.mealPlanUsage',
                    'permission': 'reports:meal_plan_usage',
                    'position': 12,
                    'disabled': !$scope.showMeals
                },
                {
                    // DISABLED: Active Patrons
                    'url': 'freshideas.report.activePatrons',
                    'icon': 'fa-address-card-o',
                    'translate': 'general.activePatrons',
                    'permission': 'reports:active_patron_on_meal_plans',
                    'position': 13,
                    'disabled': !$scope.showMeals
                },
                {
                    // DISABLED: Patron Activity
                    'url': 'freshideas.report.patronActivity',
                    'icon': 'fa-user',
                    'translate': 'general.patronActivity',
                    'permission': 'reports:patron_activity_statement',
                    'position': 14,
                    'disabled': !$scope.showMeals // for now, until patron activity search by email is implemented
                },
                {
                    // DISABLED: Refund Report
                    'url': 'freshideas.report.depositRefund',
                    'icon': 'fa-money',
                    'translate': 'general.depositRefundReport',
                    'permission': 'reports:deposit_refund_report',
                    'position': 15,
                    'disabled': !$scope.showMeals
                },
                {
                    // Mobile Transactions
                    'url': 'freshideas.report.mobileTransactions',
                    'icon': 'fa-mobile',
                    'permission': 'reports:mobile_transactions_report',
                    'translate': 'general.mobileTransactionsReport',
                    'position': 16
                },
                {
                    // Transaction History
                    'url': 'freshideas.report.transactions',
                    'icon': 'fa-paperclip',
                    'translate': 'general.transactionHistory',
                    'permission': 'reports:transaction_report',
                    'position': 17
                },
                {
                    // DISABLED: Patron Meal Plan HIstory
                    'url': 'freshideas.report.patronMealPlanHistory',
                    'icon': 'fa-briefcase',
                    'permission': 'reports:patron_meal_plan_history_report',
                    'translate': 'general.patronMealPlanHistory',
                    'position': 18,
                    'disabled': !$scope.showMeals
                },
                {
                    // DISABLED: Patron Spend Report
                    'url': 'freshideas.report.patronSpend',
                    'icon': 'fa-calculator',
                    'permission': 'reports:patron_spend_report',
                    'translate': 'general.patronSpendReport',
                    'position': 19,
                    'disabled': !$scope.showMeals
                },
                {
                    // DISABLED: Participation Report
                    'url': 'freshideas.report.participationRate',
                    'icon': 'fa-bullhorn',
                    'permission': 'reports:participation_rate_report',
                    'translate': 'general.participationRateReport',
                    'position': 20,
                    'disabled': !$scope.showMeals
                },
                {
                    // DISABLED: Meal Plan Sales
                    'url': 'freshideas.report.mealPlanSales',
                    'icon': 'fa-columns',
                    'permission': 'reports:meal_plan_sales_report',
                    'translate': 'general.mealPlanSalesReport',
                    'position': 21,
                    'disabled': !$scope.showMeals
                },
                {
                    // Labour Report
                    'url': 'freshideas.report.labour',
                    'icon': 'fa-money',
                    'translate': 'general.labourReport',
                    'permission': 'reports:labour_report',
                    'position': 22
                },
                {
                    // Loss Prevention Report
                    'url': 'freshideas.report.lossPrevention',
                    'icon': 'fa-trash',
                    'translate': 'general.lossPreventionReport',
                    'permission': 'reports:loss_prevention_report',
                    'position': 23
                }
            ];

            $scope.changeView = function (view) {
                $state.go(view);
            };

            Security.loadRolePermissions().then(function () {
                $scope.reportsNavBtns = _.filter(reportsNavBtns, function (e) {
                    if (e.url == 'freshideas.report.lossPrevention' && Security.hasPermissionForUser(e.permission)) {
                        if (CompanyAttributesService.enhanceSecurityMeasures()) {
                            return true;
                        }
                        return false;
                    } else {
                        return Security.hasPermissionForUser(e.permission);
                    }
                });
            });

            init();
        }
    ]);
};
