'use strict';


const angular = require('angular');

export default angular.module('freshideas.services.guestLabel', [])
.factory('GuestLabelService', [
    '$timeout',
    '$window',
    '$log',
    function (
        $timeout,
        $window,
        $log) {

        var guestLabel;

        var getGuestLabel = function () {
            return guestLabel;
        };

        var setGuestLabel = function (label) {
            guestLabel = label;
        };

        return {
            getGuestLabel: getGuestLabel,
            setGuestLabel: setGuestLabel
        };
    }
]);
