'use strict';

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosTenderAlphaPayCtrl', [
        '$scope',
        '$modalInstance',
        function (
            $scope,
            $modalInstance) {

            $scope.scanning = false;
            $scope.reset = function () {
                $scope.scanning = false;
            };

            $scope.didReceiveQrCode = function (qrCode) {
                $scope.scanning = true;

                $scope.$close(qrCode);
            };
        }
    ]);
};
