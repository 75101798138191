'use strict';

/**
 * This two-part directive allows image lazy loading, where an image using
 * `lazySrc` directive does not load its src image until it is scrolled
 * into the view of its main container (using `lazySrcObserver`). The underlying
 * view detection mechanism uses IntersectionObserver API, which allows much
 * easier detection logic, and better performance over older way of listening
 * on a DOM scroll event. However, the API is relatively new. It is implemented on
 * Chrome 51+, Edge 15+, Firefox 55, but not on Safari until March 2019 release of
 * MacOS and iOS. An IntersectionObserver polyfill is added to work around the limitation.
 */

module.exports = function (freshIdeasDirectiveCommon) {

    freshIdeasDirectiveCommon.directive('lazySrcObserver', [
        '$filter',
        function ($filter) {
            var lazyLoadImage = function (target) {
                target.src = target.getAttribute('lazy-src');
            };

            return {
                restrict: 'A',
                controller: ['$scope', function ($scope) {
                    this.observe = function (target) {
                        $scope.observer.observe(target);
                    };
                }],
                link: function (scope, elem, attrs) {
                    var onIntersection = function (entries) {
                        entries.forEach(function (entry) {
                            if (entry.intersectionRatio > 0) {
                                observer.unobserve(entry.target);
                                lazyLoadImage(entry.target);
                            }
                        });
                    };
                    var config = {}; // use default config for now

                    var observer = new IntersectionObserver(onIntersection, config);
                    scope.observer = observer;
                }
            };
        }
    ]);

    freshIdeasDirectiveCommon.directive('lazySrc', [
        '$filter',
        function ($filter) {
            return {
                restrict: 'A',
                require: '^^lazySrcObserver',
                link: function (scope, elem, attrs, imgLazyLoadingObserver) {
                    imgLazyLoadingObserver.observe(elem[0]);
                }
            };
        }
    ]);
};
