'use strict';
// TODO check if this is still being used
module.exports = function (freshideasLocation) {
    freshideasLocation
    .controller('LocationTopnav', [
        '$scope',
        '$modal',
        '$q',
        '$translate',
        '$location',
        'LocationFactory',
        'Locations',
        'Company',
        'notificationTranslationHelper',
        'ErrorUtil',
        'PosAlertService',
        function (
            $scope,
            $modal,
            $q,
            $translate,
            $location,
            LocationFactory,
            Locations, Company,
            notificationTranslationHelper,
            ErrorUtil,
            PosAlertService) {

            $scope.isSearch = false;
            $scope.locationFactory = LocationFactory;

            $scope.options = [
            {'name': 'New Item', 'action': 'new-item'},
            {'name': 'New Category', 'action': 'new-category'},
            {'name': 'New Modifier', 'action': 'new-modifier'}
            ];

            $scope.activeOption = $scope.options[0];

            var init = function () {
            };

            $scope.toggleSearch = function () {
                $scope.isSearch = !$scope.isSearch;
            };

            $scope.toggleModifiers = function () {

                if ($scope.locationFactory.sideContent.type === 'modifiers') {
                    this.locationFactory.dismissSideContent();
                } else {
                    this.locationFactory.showModifiers();
                }
            };

            $scope.back = function () {

                $scope.locationFactory.back();
            };

            $scope.showAddCategory = function () {
                $scope.locationFactory.sideContent = {
                    type: 'add-category',
                    data: undefined
                };
            };

            $scope.showAddItem = function () {

                $scope.locationFactory.sideContent = {
                    type: 'add-item',
                    data: undefined
                };
            };

            $scope.showList = function () {

                $scope.locationFactory.listType = 'bar-items';
            };

            $scope.showGrid = function () {

                $scope.locationFactory.listType = 'box-items';
            };

            $scope.showCategories = function () {

                $scope.locationFactory.sideContent = {};
                $scope.locationFactory.listType = 'category-items';
            };

            $scope.selectedOption = function (item) {
                $scope.activeOption = item;

                switch (item.action) {
                    case 'new-item':

                        var parent = this.locationFactory.getLastCrumb();
                        if (!parent || parent.type !== 'item') {
                            this.locationFactory.showAddItem();
                        } else {
                            PosAlertService.showError('error', 'Can\'t Create Item', '', 'You cannot add an item inside another item. To do this you\'ll need to first place it inside a modifier.');
                        }
                        break;
                    case 'new-category':
                        this.locationFactory.showAddCategory();
                        break;
                    case 'new-modifier':
                        this.locationFactory.showAddModifier();
                        break;
                }
            };

            init();
        }
        ]);
};
