'use strict';


// const angular = require('angular');
module.exports = function (freshideasLocation) {
  freshideasLocation
.directive('locationDropdown', function () {
    return {
        restrict: 'E',
        transclude: true,
        scope: {
            alignment: '@',
            items: '=',
            onselect: '&'
        },
        controller: ['$scope', function LocationDropdownController ($scope) {

            $scope.isOpen = false;

            $scope.toggle = function () {
                if (!$scope.alignment) {
                    $scope.alignment = 'left';
                }

                $scope.isOpen = !$scope.isOpen;
            };

            $scope.selected = function (e, item) {
                $scope.toggle();
                $scope.onselect({'item': item});
                e.stopPropagation();
            };

        }],
        link: function (scope, element, attr) {

            $(document).bind('click.location-dropdown', function (event) {
                var isClickedElementChildOfPopup = element
                .find(event.target)
                .length > 0 || element[0] === event.target;

                if (isClickedElementChildOfPopup) {
return;
}

                scope.$apply(function () {
                    scope.isOpen = false;
                });
            });

            element.on('click', function () {
                scope.toggle();
            });

            scope.$on('$destroy', function () {
                $(document).off('click.location-dropdown');
                element.off('click');
            });
        },
        templateUrl: 'locations/common/form-elements/dropdown.html'
    };
})
.directive('locationToggle', ['PosAlertService', '$timeout', function (PosAlertService, $timeout) {
    return {
        restrict: 'E',
        scope: {
            toggleValue: '=toggleValue',
            negation: '=',
            canActivate: '=?',
            item: '=',
            canActivateMessage: '@?',
            onToggle: '&',
            onToggleCancellable: '&',
            noText: '@',
            textColor: '@',
            onEnabled: '&',
            toDisable: '=?'
        },
        controller: ['$scope', function LocationToggleController ($scope) {

            $scope.canActivateMessage = $scope.canActivateMessage && $scope.canActivateMessage === '' ? $scope.canActivateMessage : 'This toggle cannot be activated.';

            // validate toggle value and convert to boolean
            if (typeof $scope.toggleValue === 'string') {
                $scope.toggleValue = ($scope.toggleValue === 'true' || $scope.toggleValue === 1);
            }

            $scope.toggle = function (ev) {

                ev.stopPropagation();

                if ($scope.onToggleCancellable) {
                    var toCancel = $scope.onToggleCancellable({'enabled': !$scope.toggleValue});
                    if (toCancel) {
                        return;
                    }
                }

                if ($scope.toDisable === true) {
                    return;
                }

                if ($scope.canActivate === false && (!$scope.toggleValue || $scope.toggleValue === false)) {
                    // PosAlertService.showError('error', 'Invalid Selection', '', $scope.canActivateMessage);
                    return;
                }

                $scope.toggleValue = !$scope.toggleValue;

                if ($scope.toggleValue && $scope.onEnabled) {
                    $scope.onEnabled();
                }

                if ($scope.onToggle) {

                    // This timeout ensures that the onToggle callback is run AFTER the actual toggle value is updated.
                    $timeout(function () {
                        var value = $scope.toggleValue;
                        if ($scope.negation) {
                            value = !value;
                        }

                        $scope.onToggle({'enabled': value});
                    });
                }
            };

        }],
        templateUrl: 'locations/common/form-elements/toggle.html'
    };
}])
.directive('locationTabbed', function () {
    return {
        restrict: 'E',
        scope: {
            tabs: '=tabs',
            selectedTab: '=selectedTab',
            onselect: '&'
        },
        controller: ['$scope', function LocationTabbedController ($scope) {

            $scope.selectTab = function (tab) {

                $scope.selectedTab = tab.id;
            };

        }],
        templateUrl: 'locations/common/form-elements/tabbed.html'
    };
})
.directive('numberStepper', function () {
    return {
        restrict: 'E',
        scope: {
            stepperValue: '=stepperValue',
            stepValue: '=stepValue',
            min: '=min',
            max: '=max'
        },
        controller: ['$scope', function LocationNumberStepperController ($scope) {

            $scope.stepperValue = 0;
            $scope.stepValue = 0.5;

            $scope.increment = function () {
                var newVal = $scope.stepperValue + $scope.stepValue;
                if (!$scope.max || newVal <= $scope.max) {
                    $scope.stepperValue = newVal;
                }
            };

            $scope.decrement = function () {
                var newVal = $scope.stepperValue - $scope.stepValue;
                if (!$scope.min || newVal >= $scope.min) {
                    $scope.stepperValue = newVal;
                }
            };

        }],
        templateUrl: 'locations/common/form-elements/number-stepper.html'
    };
})
.directive('contenteditable', function () {
    return {
        restrict: 'A',
        scope: {
            contentValue: '=contentValue'
        },
        link: function (scope, element, attrs, ngModel) {
            if (!attrs.contentValue) return;

                // Listen for change events to enable binding
                element.on('blur keyup change', function () {
                    scope.contentValue = parseFloat(element.html());
                });

                scope.$on('$destroy', function () {
                    element.off('blur keyup change');
                });
            }
        };
    });
};
