'use strict';


module.exports = function (freshideasPosInventoryV2) {
    freshideasPosInventoryV2.service('InventoryV2Service', [
        '$rootScope',
        'Inventory',
        function (
            $rootScope,
            Inventory
        ) {

            $rootScope.$on('auth-loginConfirmed', function (event, user) {
                reset();
            });

            /* var _resetArr = function (array) {
                array.length = 0;
            };
            var _resetObj = function (obj) {
                for (const prop of Object.keys(obj)) {
                    delete obj[prop];
                }
            };*/
            var reset = function () {
            };

            var data = {};

            var getIncomingOrders = function (params) {
                return Inventory.getIncomingOrders(params).$promise;
            };

            var searchMenuItem = function (params) {
                return Inventory.searchMenuItem(params).$promise;
            };

            var getStocks = function () {
                return Inventory.getStocks().$promise;
            };

            var receiveStocks = function (stocks, warehouse) {
                var groupedStocks = _.groupBy(stocks, 'locationServicePeriodMenuId');

                var itemStocks = [];
                for (var menuItemId in groupedStocks) {
                    if (menuItemId) {
                        var itemStock = {
                            menuItemId: menuItemId,
                            warehouse: warehouse,
                            quantityChange: groupedStocks[menuItemId].length
                        };

                        itemStocks.push(itemStock);
                    }
                }

                var params = {
                    entries: itemStocks
                };

                return Inventory.receiveStocks({}, params).$promise;
            };

            var getWarehouses = function () {
                return Inventory.warehouseList().$promise;
            };
            var addWarehouse = function (params) {
                return Inventory.addWarehouse({}, params).$promise;
            };

            var stashOrder = function (order) {
                return Inventory.stashOrder(order).$promise;
            };

            var receiveOrder = function (order) {
                return Inventory.receiveOrder(order).$promise;
            };

            return {
                data: data,

                getIncomingOrders: getIncomingOrders,

                searchMenuItem: searchMenuItem,

                getStocks: getStocks,
                receiveStocks: receiveStocks,

                getWarehouses: getWarehouses,
                addWarehouse: addWarehouse,
                stashOrder: stashOrder,
                receiveOrder: receiveOrder
            };
        }
    ]);
};
