'use strict';


const angular = require('angular');
const freshideasResourcesCompany = require('../resources/company.js').default;

export default angular.module('freshideas.services.locations', [freshideasResourcesCompany.name])
    .factory('LocationService', ['$rootScope', '$q', '$log', '$modal', 'Company', 'Locations', 'Security', 'EnvConfig',
        function ($rootScope, $q, $log, $modal, Company, Locations, Security, EnvConfig) {

        // The list of companies
        var companies;

        // Selected company
        var selectedCompany;

        // The current set of locations based upon the selected company
        var companyLocations;

        function loadAllCompanies () {
            $log.debug('Loading all companies... ');
            companies = Company.liteCompanies({'offset': 0, 'limit': 1000}).$promise;
        }

        function loadCompanyLocations () {
            $log.debug('Loading all company locations');
            companyLocations = Locations.getLocations({'companyId': selectedCompany, 'offset': 0, 'limit': 1000}).$promise;
        }

        function updateSelectedCompany (company) {
            $log.debug('Updating selected company');
            selectedCompany = company;
            loadCompanyLocations();
        }

        $rootScope.$on('auth-loginConfirmed', function (event, user) {
            $log.debug('Setting default reporting group: ' + user.reportingGroup);
            if (angular.isDefined(user) && user.permission !== 'FULLADMIN') {
                selectedCompany = user.companyId;
                loadAllCompanies();
                loadCompanyLocations();
            } else {
                loadAllCompanies();
                companyLocations = undefined;
            }
        });

        var service = {
            selectedCompanyChanged: 'selectedCompanyChanged',

            fireSelectedCompanyChangedBroadcast: function () {
                companyLocations = undefined;
                $rootScope.$broadcast(this.selectedCompanyChanged);
            },

            getLocations: function () {
                if (!companyLocations) {
                    loadCompanyLocations();
                }
                return $q.when(companyLocations);
            },

            setSelectedCompany: function (selectedCompany) {
                if (selectedCompany) {
                    updateSelectedCompany(selectedCompany);
                }
            },
            getCompanies: function () {
                if (!companies) {
                    loadAllCompanies();
                }
                return $q.when(companies);
            }
        };

        return service;
    }]);
