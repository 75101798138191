/* Used in pos-service.js to store shifts created in offline mode into Local Storage.
   An interval is set to keep retrying and send this shift to the backend, until it is successful.*/

'use strict';

const angular = require('angular');
const INTERVAL = 1000 * 15;
const WORKER_INTERVAL = 1000 * 10;

export default angular
    .module('freshideas.resources.offline-shift', [])
    .constant('SHIFT_ACTION', {
        OPEN_SHIFT: 'open',
        CLOSE_SHIFT: 'close',
        OPEN_AND_CLOSE_SHIFT: 'complete'
    }).factory('OfflineShift', [
        '$rootScope',
        'Users',
        'SHIFT_ACTION',
        '$log',
        'PosStatusService',
        'PosUsersService',
        'CommonOfflineCache',
        'Reports',
        'CashierShift',
        'PosAlertService',
        function ($rootScope, Users, SHIFT_ACTION, $log, PosStatusService, PosUsersService, CommonOfflineCache, Reports, CashierShift, PosAlertService) {
            var offline = PosStatusService.isOffline();
            $rootScope.$watch(function () {
                return PosStatusService.isOffline();
            }, function (value) {
                offline = value;
                if (!offline) {
                    service.beginProcessingShifts(true);
                }
            });

            var service = {};

            var pushShift = function (payload, callBack) {
                // inject variables
                payload.attempts = 0;
                payload.nextRetry = Date.now(); + INTERVAL;

                var storedShift = service.get('pos.cashierShift');
                var foundActiveShift;
                if (storedShift) {
                    foundActiveShift = storedShift.find(function (shift) {
                        return (shift.employeeId == payload.employeeId && !shift.shiftEndTime);
                    });
                }
                if (foundActiveShift) {
                    var filteredShifts = storedShift.filter(function (shift) {
                        return (shift.employeeId != payload.employeeId || shift.shiftEndTime);
                    });
                    foundActiveShift['SHIFT_ACTION'] = SHIFT_ACTION.OPEN_AND_CLOSE_SHIFT;
                    foundActiveShift.shiftEndTime = payload.shiftEndTime;
                    filteredShifts.push(foundActiveShift);
                    service.put('pos.cashierShift', filteredShifts);
                } else {
                    service.push('pos.cashierShift', payload);
                }
                PosStatusService.setOfflineShiftInQueue(true);
                if (callBack != undefined) {
                    callBack();
                }
                return payload;
            };

            service.open = function (payload, callback) {
                payload['SHIFT_ACTION'] = SHIFT_ACTION.OPEN_SHIFT;
                return pushShift(payload, callback);
            };

            service.close = function (payload, callback) {
                payload['SHIFT_ACTION'] = SHIFT_ACTION.CLOSE_SHIFT;
                return pushShift(payload, callback);
            };

            service.put = function (key, val) {
                localStorage.setItem(key, JSON.stringify(val));
            };

            service.get = function (key) {
                var value = localStorage.getItem(key);
                return value && JSON.parse(value);
            };

            service.delete = function (key) {
                return localStorage.removeItem(key);
            };

            service.push = function (key, value) {
                var arr = service.get(key);
                if (!arr || !(arr instanceof Array)) {
                    arr = [];
                }
                arr.push(value);
                service.put(key, arr);
            };

            service.pop = function (key) {
                var arr = service.get(key);
                var element = arr.shift();

                service.put(key, arr);

                return element;
            };

            service.beginProcessingShifts = function (ignoreNextRetry) {
                var shifts = service.get('pos.cashierShift');

                if (!shifts || !shifts.length) {
                    return;
                }
                service.delete('pos.cashierShift');

                shifts.forEach((shift) => {
                    if (shift == undefined) {
                        return;
                    }
                    if (!ignoreNextRetry && shift.nextRetry > Date.now()) {
                        service.push('pos.cashierShift', shift);
                        return;
                    }
                    switch (shift.SHIFT_ACTION) {
                        case 'open':
                            openShift(shift);
                            break;
                        case 'close':
                            closeShift(shift);
                            break;
                        case 'complete':
                            completeShift(shift);
                            break;
                    }
                });
            };

            service.handleError = function (payload, error) {
                // If active shift found then delete the offline shift.
                if (error && error.data && error.data.exception && error.data.exception.appCode == 402) {
                    service.delete('pos.cashierShift');
                    PosStatusService.setOfflineShiftInQueue(false);
                    return;
                }
                payload.attempts = payload.attempts ? payload.attempts + 1 : 1;
                payload.nextRetry = Date.now() + INTERVAL;

                if (payload.attempts == 3) {
                    $log.error({
                        message: 'Offline shift processing failed 3 times',
                        context: {
                            shift: payload,
                            error: error || {}
                        }
                    });
                }
                service.push('pos.cashierShift', payload);
            };

            var openShift = function (payload) {
                /* This shouldn't be necessary but somehow the below promise can throw an error
                sometimes despite the request going through to the backend. */
                if (PosStatusService.isOffline()) {
                    service.handleError(payload, null);
                    return;
                }
                // These property aren't used in our backend DTO. Temporarily re-set what's needed if the request fails.
                var attemptCount = payload.attempts;
                var shiftAction = payload.SHIFT_ACTION;
                delete payload.shiftStarted;
                delete payload.SHIFT_ACTION;
                delete payload.attempts;
                delete payload.nextRetry;
                var promise = CashierShift.startShift({}, payload).$promise;

                return promise.then(function (response) {
                    PosStatusService.setOfflineShiftInQueue(false);
                    PosAlertService.showAlertByName('offline-shift-processing-success', {
                        modalCallback: function () {
                            // Do nothing for now
//                            location.reload();
                        }
                    });
                }).catch(function (error) {
                    payload.attempts = attemptCount;
                    payload.SHIFT_ACTION = shiftAction;
                    service.handleError(payload, error);
                });
            };

            /* Since we don't want to grant the user the ability to close a shift while offline
               this function isn't currently being used. */
            var closeShift = function (payload) {
                var promise = CashierShift.endShift({}, payload).$promise;

                return promise.then(function (response) {
                }).catch(function (error) {
                    service.handleError(payload, error);
                });
            };

            /* Only needed if the user is able to close shift while offline. Supposed to send a complete shift
               with both start time and end time in the case that an offline shift is ended before it can be pushed
               to the backend. */
            var completeShift = function (payload) {
                // TODO: Create new endpoint that inserts a complete shift with start time and end time into cashier_shift.
                // TODO: Make a POST request to this endpoint with the shift payload.
            };

            setInterval(() => {
                service.beginProcessingShifts(false);
            }, WORKER_INTERVAL);

            return service;
        }]);
