'use strict';


const angular = require('angular');

export default angular.module('freshideas.services.posAlert', [])
    .factory('PosAlertService', ['$modal', '$http', '$templateCache', 'KioskService',
    function ($modal, $http, $templateCache, KioskService) {

        const RESULT_TYPES = Object.freeze({
            OK: 'ok',
            CANCEL: 'cancel',
            NEVER: 'never'
        });

        var alerts = {
            'feature-not-available': {
                'type': 'alert',
                'title': 'general.error.feature-not-available.ttl',
                'message': 'general.error.feature-not-available.msg',
                'buttonTitleOk': null
            },
            'missing-information': {
                'type': 'alert',
                'title': 'Missing Required Information',
                'message': 'Please fill in all required fields to continue.'
            },
            'tax-rate-associated-error': {
                'type': 'alert',
                'title': 'Unable to delete tax rate',
                'subtitle': '',
                'message': 'Tax rate is associated with menu or inventory items.',
                'detail': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'auto-settle-error': {
                'type': 'alert',
                'title': 'Unable to settle terminal',
                'subtitle': '',
                'message': 'Please ensure the terminal switched on and try again.',
                'detail': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'barcode-impossible': {
                'type': 'alert',
                'title': 'Unable To Read Barcode',
                'subtitle': '',
                'message': 'Please try again',
                'detail': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'general': {
                'type': 'alert',
                'title': 'There was an error',
                'subtitle': '',
                'message': 'Please try again',
                'detail': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'general-error': {
                'type': 'error',
                'title': 'There was an error',
                'subtitle': '',
                'message': 'Please try again',
                'detail': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'no-service': {
                'type': 'alert',
                'title': 'No Mobile Service',
                'subtitle': '',
                'message': 'Ensure that you have a working internet connection',
                'detail': 'To get help: Talk to someone on your IT team',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'contact-nown': {
                'type': 'success',
                'title': 'Contact Update',
                'subtitle': '',
                'message': '',
                'detail': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'no-usb': {
                'type': 'alert',
                'title': 'Sorry, USB is Not Connected',
                'subtitle': '',
                'message': 'Please make sure the USB is securely connected to the system',
                'detail': 'To get help: Talk to someone on your IT team',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'terminal-init-error': {
                'type': 'alert',
                'title': 'general.error.terminal-init-error.ttl',
                'subtitle': '',
                'message': '',
                'detail': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'terminal-init-error-browser': {
                'type': 'alert',
                'title': 'general.error.terminal-init-error.ttl',
                'subtitle': '',
                'message': 'general.error.terminal-init-error.sbttl',
                'detail': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'terminal-payment-error': {
                'type': 'alert',
                'title': 'general.error.terminal-payment-error.ttl',
                'subtitle': '',
                'message': 'general.error.terminal-payment-error.dtl',
                'detail': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'connecting-service': {
                'type': 'alert',
                'title': 'Connecting to Mobile Service',
                'subtitle': '',
                'message': 'Check that you have a stable working internet connection.',
                'detail': 'To get help: Talk to someone on your IT team',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'no-patrons': {
                'type': 'alert',
                'title': 'No Patrons Found',
                'subtitle': '',
                'message': 'If a patron is not getting checked in, please have them turn on their app.',
                'detail': 'Make sure they have both Bluetooth, and GPS enabled',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'quick-charge-queued': {
                'type': 'success',
                'title': 'general.error.quick-charge-queued.ttl',
                'subtitle': '',
                'message': 'general.error.quick-charge-queued.msg',
                'detail': 'general.error.quick-charge-queued.dtl',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': 'general.error.quick-charge-queued.btnTitle',
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'active-shift': {
                'type': 'alert',
                'title': 'general.error.active-shift.ttl',
                'subtitle': '',
                'message': 'general.error.active-shift.msg',
                'detail': 'general.error.active-shift.dtl',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'no-active-shift': {
                'type': 'alert',
                'title': 'general.error.no-active-shift.ttl',
                'subtitle': '',
                'message': 'general.error.no-active-shift.msg',
                'detail': 'general.error.no-active-shift.dtl',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'patron-status': {
                'type': 'error',
                'title': 'general.error.patron-status.ttl',
                'subtitle': '',
                'message': '',
                'detail': 'general.error.patron-status.dtl',
                'visuals': null,
                'buttonType': 'okcancel',
                'buttonTitle': null,
                'buttonTitleOk': 'general.error.patron-status.btnTitleOk',
                'buttonTitleCancel': 'general.error.patron-status.btnTitleCancel'
            },
            'patron-id-missing': {
                'type': 'error',
                'title': 'general.error.patron-id-missing.ttl',
                'subtitle': '',
                'message': 'general.error.patron-id-missing.msg',
                'detail': 'general.error.patron-id-missing.dtl',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'patron-qrcode-missing': {
                'type': 'error',
                'title': 'general.error.patron-qrcode-missing.ttl',
                'subtitle': '',
                'message': 'general.error.patron-qrcode-missing.msg',
                'detail': 'general.error.patron-qrcode-missing.dtl',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'no-upc': {
                'type': 'error',
                'title': 'general.error.no-upc.ttl',
                'subtitle': '',
                'message': 'general.error.no-upc.msg',
                'detail': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'empty-order': {
                'type': 'error',
                'title': 'general.error.empty-order.ttl',
                'subtitle': '',
                'message': 'general.error.empty-order.msg',
                'detail': 'general.error.empty-order.dtl',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'no-internet': {
                'type': 'error',
                'title': 'general.error.no-internet.ttl',
                'subtitle': '',
                'message': 'general.error.no-internet.msg',
                'detail': 'general.error.no-internet.dtl',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'import-patron-invalid': {
                'type': 'error',
                'title': 'general.error.import-patron-invalid.ttl',
                'subtitle': '',
                'message': '',
                'detail': 'general.error.import-patron-invalid.dtl',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'import-patron': {
                'type': 'alert',
                'title': 'general.error.import-patron.ttl',
                'subtitle': '',
                'message': '',
                'detail': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'import-user-invalid': {
                'type': 'error',
                'title': 'setupWizard.merchant.employees.import.error.invalid.ttl',
                'subtitle': '',
                'message': '',
                'detail': 'setupWizard.merchant.employees.import.error.invalid.dtl',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'import-user': {
                'type': 'alert',
                'title': 'setupWizard.merchant.employees.import.error.ttl',
                'subtitle': '',
                'message': '',
                'detail': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'no-transaction-history': {
                'type': 'error',
                'title': 'general.error.no-transaction-history.ttl',
                'subtitle': '',
                'message': 'general.error.no-transaction-history.msg',
                'detail': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'cancel-existing': {
                'type': 'error',
                'title': 'general.error.cancel-existing.ttl',
                'subtitle': '',
                'message': 'general.error.cancel-existing.msg',
                'detail': 'general.error.cancel-existing.dtl',
                'visuals': null,
                'buttonType': 'okcancel-negation',
                'buttonTitle': 'general.error.cancel-existing.btnTitle',
                'buttonTitleOk': 'general.error.cancel-existing.btnTitleOk',
                'buttonTitleCancel': 'general.error.cancel-existing.btnTitleCancel'
            },
            'cash-drawer-uninitialized': {
                'type': 'error',
                'title': 'general.error.cash-drawer-uninitialized.ttl',
                'subtitle': '',
                'message': 'general.error.cash-drawer-uninitialized.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'cash-drawer-disconnected': {
                'type': 'error',
                'title': 'general.error.cash-drawer-disconnected.ttl',
                'subtitle': '',
                'message': 'general.error.cash-drawer-disconnected.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'printer-disconnected': {
                'type': 'error',
                'title': 'The Printer is Disconnected',
                'subtitle': '',
                'message': 'Please make sure the POS is connected to the receipt printer',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'archive-patron-fail': {
                'type': 'error',
                'title': 'general.error.archive-patron-fail.ttl',
                'subtitle': '',
                'message': 'general.error.archive-patron-fail.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'patron-details-update-fail': {
                'type': 'error',
                'title': 'general.error.patron-details-update-fail.ttl',
                'subtitle': '',
                'message': 'general.error.patron-details-update-fail.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'patron-add-fail': {
                'type': 'error',
                'title': 'general.error.patron-add-fail.ttl',
                'subtitle': '',
                'message': 'general.error.patron-add-fail.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'patron-edit-fail': {
                'type': 'error',
                'title': 'general.error.patron-edit-fail.ttl',
                'subtitle': '',
                'message': 'general.error.patron-edit-fail.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'patron-import-success': {
                'type': 'success',
                'title': 'general.error.patron-import-success.ttl',
                'subtitle': '',
                'message': 'general.error.patron-import-success.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'patron-import-fail': {
                'type': 'error',
                'title': 'general.error.patron-import-fail.ttl',
                'subtitle': '',
                'message': 'general.error.patron-import-fail.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'delete-suspend': {
                'type': 'alert',
                'title': 'general.error.delete-suspend.ttl',
                'subtitle': '',
                'message': 'general.error.delete-suspend.msg',
                'visuals': null,
                'buttonType': 'okcancel',
                'buttonTitle': null,
                'buttonTitleOk': 'general.error.delete-suspend.btnTitleOk',
                'buttonTitleCancel': 'general.error.delete-suspend.btnTitleCancel'
            },
            'set-all-items-kitchen-printer': {
                'type': 'alert',
                'title': 'general.error.set-all-items-kitchen-printer.ttl',
                'subtitle': '',
                'message': 'general.error.set-all-items-kitchen-printer.msg',
                'visuals': null,
                'buttonType': 'okcancel',
                'buttonTitle': null,
                'buttonTitleOk': 'general.error.set-all-items-kitchen-printer.btnTitleOk',
                'buttonTitleCancel': 'general.error.set-all-items-kitchen-printer.btnTitleCancel'
            },
            'set-all-items-kitchen-printer-unbind': {
                'type': 'alert',
                'title': 'general.error.set-all-items-kitchen-printer-unbind.ttl',
                'subtitle': '',
                'message': 'general.error.set-all-items-kitchen-printer-unbind.msg',
                'visuals': null,
                'buttonType': 'okcancel',
                'buttonTitle': null,
                'buttonTitleOk': 'general.error.set-all-items-kitchen-printer-unbind.btnTitleOk',
                'buttonTitleCancel': 'general.error.set-all-items-kitchen-printer-unbind.btnTitleCancel'
            },
            'logout': {
                'type': 'alert',
                'title': 'general.error.logout.ttl',
                'subtitle': '',
                'message': 'general.error.logout.msg',
                'visuals': null,
                'buttonType': 'okcancel',
                'buttonTitle': null,
                'buttonTitleOk': 'general.error.logout.btnTitleOk',
                'buttonTitleCancel': 'general.error.logout.btnTitleCancel'
            },
            'general-alert': {
                'type': 'alert',
                'title': 'general.alert.ttl',
                'subtitle': '',
                'message': '',
                'visuals': null,
                'buttonType': 'okcancel',
                'buttonTitle': null,
                'buttonTitleOk': 'general.error.logout.btnTitleOk',
                'buttonTitleCancel': 'general.error.logout.btnTitleCancel'
            },
            'general-alert-never': {
                'type': 'alert',
                'title': 'general.alert.ttl',
                'subtitle': '',
                'message': '',
                'visuals': null,
                'buttonType': 'okCancelNever',
                'buttonTitle': null,
                'buttonTitleOk': 'general.yes',
                'buttonTitleCancel': 'general.no',
                'buttonTitleNever': 'general.no.neverAskAgain'
            },
            'lucova-login-failed': {
                'type': 'alert',
                'title': 'general.error.lucova-login-failed.ttl',
                'subtitle': '',
                'message': 'general.error.lucova-login-failed.msg',
                'visuals': null,
                'buttonType': 'ok',
                'buttonTitle': null,
                'buttonTitleOk': 'general.error.lucova-login-failed.btnTitleOk',
                'buttonTitleCancel': 'general.error.lucova-login-failed.btnTitleCancel'
            },
            'insufficient-meals': {
                'type': 'alert',
                'title': 'general.error.insufficient-meals.ttl',
                'subtitle': '',
                'message': 'general.error.insufficient-meals.msg',
                'visuals': null,
                'buttonType': 'okcancel',
                'buttonTitle': null,
                'buttonTitleOk': 'general.error.insufficient-meals.btnTitleOk',
                'buttonTitleCancel': 'general.error.insufficient-meals.btnTitleCancel'
            },
            'transaction-void-success': {
                'type': 'success',
                'title': 'general.error.transaction-void-success.ttl',
                'subtitle': '',
                'message': 'general.error.transaction-void-success.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'transaction-void-fail': {
                'type': 'success',
                'title': 'general.error.transaction-void-fail.ttl',
                'subtitle': '',
                'message': 'general.error.transaction-void-fail.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'transaction-refund-success': {
                'type': 'success',
                'title': 'general.error.transaction-refund-success.ttl',
                'subtitle': '',
                'message': 'general.error.transaction-refund-success.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'transaction-refund-fail': {
                'type': 'success',
                'title': 'general.error.transaction-refund-fail.ttl',
                'subtitle': '',
                'message': 'general.error.transaction-refund-fail.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'transaction-quick-charge-server-busy': {
                'type': 'error',
                'title': 'general.error.transaction-quick-charge-server-busy.ttl',
                'subtitle': '',
                'message': 'general.error.transaction-quick-charge-server-busy.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'transaction-mobile-no-payment-method': {
                'type': 'success',
                'title': 'general.error.transaction-no-payment-method.ttl',
                'subtitle': '',
                'message': 'general.error.transaction-no-payment-method.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'pincode-fail': {
                'type': 'error',
                'title': 'general.error.pincode-fail.ttl',
                'subtitle': '',
                'message': 'general.error.pincode-fail.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'clockin-success': {
                'type': 'success',
                'title': 'general.error.clockin-success.ttl',
                'subtitle': '',
                'message': 'general.error.clockin-success.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'clockin-fail': {
                'type': 'error',
                'title': 'general.error.clockin-fail.ttl',
                'subtitle': '',
                'message': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'clockin-invalid-pin': {
                'type': 'error',
                'title': 'general.error.clockin-invalid-pin.ttl',
                'subtitle': '',
                'message': 'general.error.clockin-invalid-pin.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'clockin-already-clockedin': {
                'type': 'error',
                'title': 'general.error.clockin-already-clockedin.ttl',
                'subtitle': '',
                'message': 'general.error.clockin-already-clockedin.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'takeABreak-success': {
                'type': 'success',
                'title': 'general.error.takeABreak-success.ttl',
                'subtitle': '',
                'message': 'general.error.takeABreak-success.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'takeABreak-fail': {
                'type': 'error',
                'title': 'general.error.takeABreak-fail.ttl',
                'subtitle': '',
                'message': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'takeABreak-invalid-pin': {
                'type': 'error',
                'title': 'general.error.takeABreak-invalid-pin.ttl',
                'subtitle': '',
                'message': 'general.error.takeABreak-invalid-pin.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'endBreak-success': {
                'type': 'success',
                'title': 'general.error.endBreak-success.ttl',
                'subtitle': '',
                'message': 'general.error.endBreak-success.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'endBreak-fail': {
                'type': 'error',
                'title': 'general.error.endBreak-fail.ttl',
                'subtitle': '',
                'message': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'endBreak-invalid-pin': {
                'type': 'error',
                'title': 'general.error.endBreak-invalid-pin.ttl',
                'subtitle': '',
                'message': 'general.error.endBreak-invalid-pin.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'clockout-success': {
                'type': 'success',
                'title': 'general.error.clockout-success.ttl',
                'subtitle': '',
                'message': 'general.error.clockout-success.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'clockout-fail': {
                'type': 'error',
                'title': 'general.error.clockout-fail.ttl',
                'subtitle': '',
                'message': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'clockout-invalid-pin': {
                'type': 'error',
                'title': 'general.error.clockout-invalid-pin.ttl',
                'subtitle': '',
                'message': 'general.error.clockout-invalid-pin.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'cancelitems-success': {
                'type': 'success',
                'title': 'general.error.cancelitems-success.ttl',
                'subtitle': '',
                'message': 'general.error.cancelitems-success.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'cancelitems-fail': {
                'type': 'error',
                'title': 'general.error.cancelitems-fail.ttl',
                'subtitle': '',
                'message': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'cancelitems-invalid-pin': {
                'type': 'error',
                'title': 'general.error.cancelitems-invalid-pin.ttl',
                'subtitle': '',
                'message': 'general.error.cancelitems-invalid-pin.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'closestation-fail': {
                'type': 'error',
                'title': 'general.error.closestation-fail.ttl',
                'subtitle': '',
                'message': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'closestation-invalid-pin': {
                'type': 'error',
                'title': 'general.error.closestation-fail.ttl',
                'subtitle': '',
                'message': 'general.error.closestation-invalid-pin.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'opendrawer-success': {
                'type': 'success',
                'title': 'general.error.opendrawer-success.ttl',
                'subtitle': '',
                'message': 'general.error.opendrawer-success.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'opendrawer-fail': {
                'type': 'error',
                'title': 'general.error.opendrawer-fail.ttl',
                'subtitle': '',
                'message': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'opendrawer-invalid-pin': {
                'type': 'error',
                'title': 'general.error.opendrawer-invalid-pin.ttl',
                'subtitle': '',
                'message': 'general.error.opendrawer-invalid-pin.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'manager-pin-invalid': {
                'type': 'error',
                'title': 'general.error',
                'subtitle': '',
                'message': 'general.error.tiles.manager-invalid-pin.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'welcome-offer-settings-unsuccessful': {
                'type': 'error',
                'title': 'general.error',
                'subtitle': '',
                'message': 'general.error.tiles.welcomeOffer.unsuccessful-save',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'customer-grow-statistics-unsuccessful': {
                'type': 'error',
                'title': 'general.error',
                'subtitle': '',
                'message': 'general.error.tiles.customerGrow.unsuccessful-statistics',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'welcome-offer-load-unsuccessful': {
                'type': 'error',
                'title': 'general.error',
                'subtitle': '',
                'message': 'general.error.tiles.welcomeOffer.unsuccessful-load',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'welcome-offer-settings-invalid-value': {
                'type': 'error',
                'title': 'general.error.tiles.welcomeOffer.invalid-settings',
                'subtitle': '',
                'message': 'general.error.tiles.welcomeOffer.invalid-value',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'welcome-offer-settings-invalid-startDate': {
                'type': 'error',
                'title': 'general.error.tiles.welcomeOffer.invalid-settings',
                'subtitle': '',
                'message': 'general.error.tiles.welcomeOffer.invalid-startDate',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'welcome-offer-settings-invalid-endDate': {
                'type': 'error',
                'title': 'general.error.tiles.welcomeOffer.invalid-settings',
                'subtitle': '',
                'message': 'general.error.tiles.welcomeOffer.invalid-endDate',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'welcome-offer-settings-invalid-text': {
                'type': 'error',
                'title': 'general.error.tiles.welcomeOffer.invalid-settings',
                'subtitle': '',
                'message': 'general.error.tiles.welcomeOffer.emptyText',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'cashier-discout-fail': {
                'type': 'error',
                'title': 'general.error.cashier-discout-fail.ttl',
                'subtitle': '',
                'message': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'oops-not-clocked-in': {
                'type': 'error',
                'title': 'general.error.oops-not-clocked-in.ttl',
                'subtitle': '',
                'message': 'general.error.oops-not-clocked-in.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'oops-general': {
                'type': 'error',
                'title': 'general.error.oops.ttl',
                'subtitle': '',
                'message': 'general.error.oops.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'oops-setup-not-complete': {
                'type': 'error',
                'title': 'general.error.oops-not-clocked-in.ttl',
                'subtitle': '',
                'message': 'general.error.oops-setup-not-complete.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'add-user-fail': {
                'type': 'error',
                'title': 'general.error.add-user-fail.ttl',
                'subtitle': '',
                'message': 'general.error.add-user-fail.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'add-user-username-exists': {
                'type': 'error',
                'title': 'general.error.add-user-username-exists.ttl',
                'subtitle': '',
                'message': 'general.error.add-user-username-exists.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'edit-user-fail': {
                'type': 'error',
                'title': 'general.error.edit-user-fail.ttl',
                'subtitle': '',
                'message': 'general.error.edit-user-fail.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'delete-user-fail': {
                'type': 'error',
                'title': 'general.error.delete-user-fail.ttl',
                'subtitle': '',
                'message': 'general.error.delete-user-fail.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'duplicated-item-on-page': {
                'type': 'error',
                'title': 'general.error.duplicated-item-on-page.ttl',
                'subtitle': '',
                'message': ' ',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'duplicated-item-in-category': {
                'type': 'error',
                'title': 'general.error.duplicated-item-in-category.ttl',
                'subtitle': '',
                'message': ' ',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'invalid-date-range': {
                'type': 'error',
                'title': 'general.error.invalid-date-range.ttl',
                'subtitle': '',
                'message': 'general.error.invalid-date-range.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'invalid-future-date-time': {
                'type': 'error',
                'title': 'general.error.invalid-future-date-time.ttl',
                'subtitle': '',
                'message': 'general.error.invalid-future-date-time.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'file-upload-failed': {
                'type': 'error',
                'title': 'general.error.file-upload-failed.ttl',
                'subtitle': '',
                'message': 'general.error.file-upload-failed.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'file-upload-failed-large-file': {
                'type': 'error',
                'title': 'general.error.file-upload-failed.ttl',
                'subtitle': '',
                'message': 'general.error.invalid-file-size.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'file-upload-failed-wrong-dimentions': {
                'type': 'error',
                'title': 'general.error.file-upload-failed.ttl',
                'subtitle': '',
                'message': 'general.error.invalid-file-dimensions.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'loyalty-step-image-wrong-dimensions': {
                'type': 'error',
                'title': 'general.error.file-upload-failed.ttl',
                'subtitle': '',
                'message': 'general.error.invalid-loyalty-step-image-dimensions.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'shopify-app-install-prompt': {
                'type': 'alert',
                'title': 'Complete the installation by copying and pasting the link below',
                'subtitle': '',
                'message': '',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'offline': {
                'type': 'alert',
                'title': 'general.error.offline.ttl',
                'subtitle': '',
                'message': 'general.error.offline.msg',
                'visuals': null,
                'buttonType': 'ok',
                'buttonTitle': null,
                'buttonTitleOk': 'general.error.lucova-login-failed.btnTitleOk',
                'buttonTitleCancel': 'general.error.lucova-login-failed.btnTitleCancel'
            },
            'offline-shift-processing-timeout': {
                'type': 'alert',
                'title': 'general.error.offline.shift.processing.timeout.ttl',
                'subtitle': '',
                'message': 'general.error.offline.shift.processing.timeout.msg',
                'visuals': null,
                'buttonType': 'ok',
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null,
                'keyboard': false
            },
            'offline-shift-processing-success': {
                'type': 'alert',
                'title': 'general.offline.shift.processing.success.ttl',
                'subtitle': '',
                'message': 'general.offline.shift.processing.success.msg',
                'visuals': null,
                'buttonType': 'ok',
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null,
                'keyboard': false
            },
            'giftcard-auth-invalid-pin': {
                'type': 'error',
                'title': 'general.error.invalidpin.ttl',
                'subtitle': '',
                'message': 'general.error.invalidpin.msg',
                'visuals': null,
                'buttonType': null,
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null
            },
            'general-loading': {
                'type': 'alert',
                'title': 'general.loading',
                'subtitle': '',
                'message': '',
                'visuals': null,
                'buttonType': 'okOptional',
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': null,
                'keyboard': false,
                'loading': true
            },
            // srm not setup
            'srm-setup': {
                'type': 'alert',
                'title': 'general.error.mevbox.srm.setup.ttl',
                'subtitle': '',
                'message': 'general.error.mevbox.srm.setup.msg',
                'visuals': null,
                'buttonType': 'okcancel',
                'buttonTitle': null,
                'buttonTitleOk': 'general.error.mevbox.srm.setup.btnTitleOk',
                'buttonTitleCancel': null
            },
            // srm setup, but not connected
            'srm-connect': {
                'type': 'alert',
                'title': 'general.error.mevbox.srm.connection.ttl',
                'subtitle': '',
                'message': 'general.error.mevbox.srm.connection.msg',
                'visuals': null,
                'buttonType': 'okcancel',
                'buttonTitle': null,
                'buttonTitleOk': null,
                'buttonTitleCancel': 'general.error.mevbox.srm.connection.btnTitleCancel'
            },
            // srm setup, not connected, but manually bypassed
            'srm-bypassed': {
                'type': 'alert',
                'title': 'general.error.mevbox.srm.reconnect.ttl',
                'subtitle': '',
                'message': 'general.error.mevbox.srm.reconnect.msg',
                'visuals': null,
                'buttonType': 'okcancel',
                'buttonTitle': null,
                'buttonTitleOk': 'general.error.mevbox.srm.reconnect.btnTitleOk',
                'buttonTitleCancel': null
            },
        };


        function showAlertByName (name, params) {

            // Prevent customization from overriding the default alert params
            var obj = angular.copy(alerts[name]);

            if (obj) {

                if (params) {

                    obj['type'] = params['type'] || obj['type'];
                    obj['title'] = params['title'] || obj['title'];
                    obj['subtitle'] = params['subtitle'] || obj['subtitle'];
                    obj['message'] = params['message'] || obj['message'];
                    obj['detail'] = params['detail'] || obj['detail'];
                    obj['visuals'] = params['visuals'] || obj['visuals'];
                    obj['buttonType'] = params['buttonType'] || obj['buttonType'];
                    obj['buttonTitle'] = params['buttonTitle'] || obj['buttonTitle'];
                    obj['buttonTitleOk'] = params['buttonTitleOk'] || obj['buttonTitleOk'];
                    obj['buttonTitleCancel'] = params['buttonTitleCancel'] || obj['buttonTitleCancel'];
                    obj['buttonTitleNever'] = params['buttonTitleNever'] || obj['buttonTitleNever'];
                    obj['modalCallback'] = params['modalCallback'];
                    obj['dismissModalCallback'] = params['dismissModalCallback'];
                    obj['neverModalCallback'] = params['neverModalCallback'];
                    obj['keyboard'] = params['keyboard'] || obj['keyboard'];
                    obj['loading'] = params['loading'] || obj['loading'];
                    obj['showHelpLink'] = params['showHelpLink'] || obj['showHelpLink'];
                }

                return showError(
                    obj['type'],
                    obj['title'],
                    obj['subtitle'],
                    obj['message'],
                    obj['detail'],
                    obj['visuals'],
                    obj['modalCallback'],
                    obj['buttonType'],
                    obj['buttonTitle'],
                    obj['buttonTitleOk'],
                    obj['buttonTitleCancel'],
                    obj['buttonTitleNever'],
                    obj['dismissModalCallback'],
                    obj['neverModalCallback'],
                    obj['keyboard'],
                    obj['loading'],
                    obj['showHelpLink']);
            }
        }

        // The keyboard parameter is used to determine whether or not we want a modal to allow keyboard interactions, such as closing the modal with 'Esc' key.
        function showError (
            type,
            title,
            subtitle,
            message,
            detail,
            visuals,
            modalCallback,
            buttonType,
            buttonTitle,
            buttonTitleOk,
            buttonTitleCancel,
            buttonTitleNever,
            dismissModalCallback,
            neverModalCallback,
            keyboard = true,
            loading,
            showHelpLink) {

            var modalInstance = $modal.open({
                templateUrl: 'common/modals/modalError.tpl.html',
                controller: [
                    '$scope',
                    '$modal',
                    '$modalInstance',
                    '$translate',
                    'type',
                    'title',
                    'subtitle',
                    'message',
                    'detail',
                    'visuals',
                    'buttonType',
                    'buttonTitle',
                    'buttonTitleOk',
                    'buttonTitleCancel',
                    'buttonTitleNever',
                    'loading',
                    'showHelpLink',
                    function (
                        $scope,
                        $modal,
                        $modalInstance,
                        $translate,
                        type,
                        title,
                        subtitle,
                        message,
                        detail,
                        visuals,
                        buttonType,
                        buttonTitle,
                        buttonTitleOk,
                        buttonTitleCancel,
                        buttonTitleNever,
                        loading,
                        showHelpLink
                        ) {
                        $scope.type = type;
                        $scope.title = title;
                        $scope.subtitle = subtitle;
                        $scope.message = message;
                        $scope.detail = detail;
                        $scope.visuals = visuals;
                        $scope.buttonType = buttonType;
                        $scope.buttonTitle = buttonTitle;
                        $scope.buttonTitleOk = buttonTitleOk;
                        $scope.buttonTitleCancel = buttonTitleCancel;
                        $scope.buttonTitleNever = buttonTitleNever;
                        $scope.showHelpLink = showHelpLink;

                        $scope.iconName = 'fa-exclamation-circle';
                        $scope.loading = loading;

                        $scope.closeModal = function () {
                            if ($scope.type === 'success' || $scope.type === 'alert') {
                                $scope.$close();
                            } else {
                                $scope.$dismiss();
                            }
                        };
                    }
                ],
                windowClass: 'modal-error',
                animation: false,
                resolve: {
                    type: () => {
                        return type || 'error';
                    },
                    title: () => {
                        return title || 'general.error.oops';
                    },
                    subtitle: () => {
                        return subtitle || '';
                    },
                    message: () => {
                        return message || 'general.error.default';
                    },
                    detail: () => {
                        return detail || '';
                    },
                    visuals: () => {
                        return visuals || [];
                    },
                    buttonType: () => {
                        return buttonType || 'ok';
                    },
                    buttonTitle: () => {
                        return buttonTitle;
                    },
                    buttonTitleOk: () => {
                        return buttonTitleOk;
                    },
                    buttonTitleCancel: () => {
                        return buttonTitleCancel;
                    },
                    buttonTitleNever: () => {
                        return buttonTitleNever;
                    },
                    loading: () => {
                        return loading;
                    },
                    showHelpLink: function () {
                        return showHelpLink;
                    },
                },
                backdrop: 'static',
                keyboard: keyboard
            }, function (error) {
                //
            });

            modalInstance.result.then(function (result) {
                if (result === RESULT_TYPES.NEVER && neverModalCallback) {
                    neverModalCallback();
                } else if (modalCallback) {
                    modalCallback();
                }
            }, function () {
                if (dismissModalCallback) {
                    dismissModalCallback();
                }
            });

            return modalInstance;
        }

        // This ensure modalError template is available even when offline, since template files by default are retrieved only as needed
        $http.get('common/modals/modalError.tpl.html', {cache: $templateCache});

        return {
            showError: function (type, title, subtitle, message, detail, visuals, modalCallback, buttonType, buttonTitle, buttonTitleOk, buttonTitleCancel, buttonTitleNever, dismissModalCallback) {
                showError(type, title, subtitle, message, detail, visuals, modalCallback, buttonType, buttonTitle, buttonTitleOk, buttonTitleCancel, buttonTitleNever, dismissModalCallback);
            },

            showAlertByName: function (name, params, allowOnKiosk = false) {

                // TODO: This whitelist is deprecated. If we want to alert to
                // appear, use the "allowOnKiosk" parameter
                const whitelist = [
                    'logout',
                    'manager-pin-invalid',
                    'clockin-already-clockedin',
                    'clockin-invalid-pin',
                    'closestation-invalid-pin',
                ];

                if (KioskService.isKiosk() && whitelist.indexOf(name) < 0 && !allowOnKiosk) {
                    return;
                }
                return showAlertByName(name, params);
            }
        };


    }]);
