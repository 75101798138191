'use strict';


const angular = require('angular');

module.exports = function (freshideasSetup) {

    freshideasSetup.controller('ImportFileCtrl', [
        '$scope',
        '$window',
        '$modal',
        '$modalInstance',
        '$translate',
        '$location',
        'Locations',
        'FileUploader',
        'Company',
        'notificationTranslationHelper',
        'ErrorUtil',
        'PosAlertService',
        '$timeout',
        '$filter',
        'Users',
        function ($scope, $window, $modal, $modalInstance, $translate, $location, Locations, FileUploader, Company, notificationTranslationHelper, ErrorUtil, PosAlertService, $timeout, $filter, Users) {

            $scope.init = function () {

            };
            var uploader = $scope.uploader = new FileUploader({
                url: '/freshideas/web/users/import',
                queueLimit: 1,
                queue: [],
                headers: {'Accept': 'application/json'}, // wont work unless we manually define this header
                alias: 'usersFile'
            });
            uploader.onWhenAddingFileFailed = function (item, filter, options) {
            };
            uploader.onAfterAddingFile = function (fileItem) {
                uploader.uploadItem(fileItem);
            };
            uploader.onAfterAddingAll = function (addedFileItems) {
            };
            uploader.onBeforeUploadItem = function (item) {
            };
            uploader.onProgressItem = function (fileItem, progress) {
            };
            uploader.onProgressAll = function (progress) {
            };
            uploader.onSuccessItem = function (fileItem, response, status, headers) {
                notificationTranslationHelper.notifyMessage('invoice.import.fileSuccessfullyUploaded');
                uploader.removeFromQueue(fileItem);
                $modalInstance.close();
            };
            uploader.onErrorItem = function (fileItem, response, status, headers) {
            };
            uploader.onCancelItem = function (fileItem, response, status, headers) {
            };
            uploader.onCompleteItem = function (fileItem, response, status, headers) {
                $scope.invalidUserImport = response;
                uploader.removeFromQueue(fileItem);
                $modalInstance.dismiss();
                if ($scope.invalidUserImport.length !== 0) {
                    if ($scope.invalidUserImport.error !== null) {
                        PosAlertService.showAlertByName('import-user-invalid', {'message': $scope.invalidUserImport.error});
                    } else {
                        PosAlertService.showAlertByName('import-user', {'message': $scope.invalidUserImport});
                    }
                }
            };
            uploader.onCompleteAll = function () {
            };

            $scope.downloadTemplate = function () {
                $window.location.href = '/freshideas/web/users/import/getTemplate';
            };


            $scope.toUpload = function () {
                $timeout(function () {
                    angular.element('#hiddenUsersFileInput').trigger('click');
                });
            };

            $scope.init();
        }
    ]);
};
