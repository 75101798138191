'use strict';

const moment = require('moment');

module.exports = function (freshideasReports) {
    freshideasReports.controller('PatronCountsReportCtrl', [
        '$scope',
        'Reports',
        'Export',
        'Lookup',
        'Security',
        'DateRangeService',
        'USER_ROLE_TYPE',
        function ($scope, Reports, Export, Lookup, Security, DateRangeService, USER_ROLE_TYPE) {
            $scope.searchDisabled = false;
            var allowRetry;
            var currentUser = Security.getUser();
            $scope.isManager = currentUser.roleType === USER_ROLE_TYPE.MANAGER;
            $scope.isAccountant = currentUser.roleType === USER_ROLE_TYPE.ACCOUNTANT;
            $scope.isSiteAdmin = currentUser.permission === 'SITEADMIN';
            $scope.isFullAdmin = (currentUser.permission === 'FULLADMIN');
            var filters = $scope.filters = {
                transactionCount: 'Transaction Count',
                rewardCount: 'Reward Count',
                salesTotal: 'Sales total',
                // labourTransaction: 'Labour vs Transaction',
                // labourSales: 'Labour vs Sales',
                topSellingItems: 'Top Selling Item'
            };

            var daysOfWeek = $scope.daysOfWeek = moment.weekdaysShort();
            var daysTotal = $scope.daysTotal = {};
            daysOfWeek.forEach((day) => {
                daysTotal[day] = 0;
            });
            daysTotal.grandTotal = 0;
            daysTotal.type = 'decimal';

            var heatMapColors = {
                pink: '#f8baf8',
            };
            var itemColors = $scope.itemColors = ['#facdfa', '#ccf0a3', '#87eef8', '#fad3bf', '#e1c2fb', '#fdb4cd'];
            var hoursOfDay = $scope.hoursOfDay = getHoursOfDay();

            var gridCells = [];
            var grid = $scope.grid = buildGrid();
            $scope.filterGrid = filterGrid;

            function calculateLegendsColor (hex = heatMapColors.pink) {
                let percents = [0.9, 0.6, 0.3, 0];
                let colors = [];
                percents.forEach((percent) => {
                    let rgbComponents = shadeHexColor(hex, percent);
                    colors.push('rgb(' + rgbComponents.r + ', ' + rgbComponents.g + ', ' + rgbComponents.b + ')');
                });
                return colors;
            }

            function convertHexToRgb (hex) {
                let match = hex.replace(/#/, '').match(/.{1,2}/g);
                return {
                    r: parseInt(match[0], 16),
                    g: parseInt(match[1], 16),
                    b: parseInt(match[2], 16)
                };
            }

            function findColorBetween (lowShade, highShade, percentage) {
                let newColor = {};
                let components = ['r', 'g', 'b'];
                for (var i = 0; i < components.length; i++) {
                    let c = components[i];
                    newColor[c] = Math.round(lowShade[c] + (highShade[c] - lowShade[c]) * percentage / 100);
                }
                return 'rgb(' + newColor.r + ', ' + newColor.g + ', ' + newColor.b + ')';
            }

            function shadeHexColor (color, percent) {
                let newColor = {};
                let rgb = convertHexToRgb(color);
                let t = percent < 0 ? 0 : 255;
                let p = percent < 0 ? percent * -1 : percent;
                let components = ['r', 'g', 'b'];
                for (var i = 0; i < components.length; i++) {
                    let c = components[i];
                    let val = rgb[c];
                    newColor[c] = Math.round((t - val) * p) + val;
                }
                return newColor;
            }

            function getMean (data, field) {
                if (!data || !data.length) {
                    return;
                }
                return data.map((v) => {
                    return v[field];
                }).reduce((a, b) => {
                    return Number(a) + Number(b);
                }) / data.length;
            }

            function getSD (data, field) {
                if (!data || !data.length) {
                    return;
                }
                let m = getMean(data, field);
                return Math.sqrt(data.map((v) => {
                    return v[field];
                }).reduce((sq, n) => {
                    return sq + Math.pow(n - m, 2);
                }, 0) / (data.length - 1));
            }

            function getHoursOfDay () {
                let hoursPerDay = 24;
                let time = [];
                for (let i = 0; i < hoursPerDay; i++) {
                    time.push(moment().hour(i).format('hA'));
                }
                return time;
            }

            function filterGrid () {
                let startHour = parseInt(moment($scope.reportSearch.startHour, ['hA']).format('HH'));
                let endHour = parseInt(moment($scope.reportSearch.endHour, ['hA']).format('HH'));
                grid.forEach((row) => {
                    var hour = row.id;
                    if (hour >= startHour && hour <= endHour) {
                        row.active = true;
                    } else {
                        row.active = false;
                    }
                });
            }

            function buildGrid () {
                var grid = [];
                gridCells.length = 0;
                hoursOfDay.forEach((hour) => {
                    var row = [];
                    daysOfWeek.forEach((day) => {
                        let value = {
                            type: 'decimal', // decimal, dollar
                            value: 0,
                            toolTip: undefined,
                            color: '#ffffff'
                        };
                        gridCells.push(value);
                        row.push(value);
                    });
                    grid.push({
                        name: hour,
                        id: parseInt(moment(hour, ['hA']).format('HH')),
                        cells: row,
                        active: true,
                        total: 0,
                        type: 'decimal'
                    });
                });
                return grid;
            }

            function resetGridCells () {
                // Reset all pixes in grid
                gridCells.map((cell) => {
                    cell.type = 'decimal';
                    cell.value = 0;
                    cell.toolTip = undefined;
                    cell.color = '#ffffff';
                });

                // Reset day totals
                Object.keys(daysTotal).map((key) => {
                    daysTotal[key] = 0;
                });
                daysTotal.grandTotal = 0;
                daysTotal.type = 'decimal';

                // Reset hour interval totals
                grid.map((hour) => {
                    hour.total = 0;
                    hour.type = 'decimal';
                });
            }

            function convertRange (value, r1, r2) {
                return (value - r1[0]) * (r2[1] - r2[0]) / (r1[1] - r1[0]) + r2[0];
            }

            function lookupCompanyHierarchy () {
                return Lookup.locationsByCompany({}, function (response) {
                    $scope.company = _.findWhere(response, {id: currentUser.companyId});
                    $scope.locations = [];
                    let children = ($scope.company && $scope.company.children) ? $scope.company.children : [];

                    if (children.length) {
                        if (children.length > 1) {
                            $scope.locations.push({
                                id: null,
                                name: 'All Locations'
                            });
                        }
                        _.each(children, function (location) {
                            $scope.locations.push(location);
                        });
                        $scope.reportSearch.locationId = currentUser.company.locationId;
                    }
                });
            }

            function activatePixesOnGrid (pixels, highShade = heatMapColors.pink) {
                let values = [];
                resetGridCells();
                $scope.legends = calculateLegendsColor(highShade);
                Object.keys(pixels).forEach((i) => {
                    let index = parseInt(i);
                    let inputRow = pixels[i];
                    let row = grid[index];

                    Object.keys(inputRow).forEach((j) => {
                        let index = parseInt(j);
                        let col = inputRow[j];

                        switch ($scope.reportSearch.selectedFilter) {
                            case filters.transactionCount:
                                row.total += (col.transactionCount || 0);
                                row.cells[index].value = col.transactionCount || 0;
                                break;
                            case filters.rewardCount:
                                row.total += (col.loyaltyCount || 0);
                                row.cells[index].value = col.loyaltyCount || 0;
                                break;
                            case filters.salesTotal:
                                row.total += col.totalSalesCents || 0;
                                row.type = 'currency';
                                daysTotal.type = 'currency';
                                row.cells[index].type = 'currency';
                                row.cells[index].value = col.totalSalesCents || 0;
                                break;
                            case filters.labourTransaction:
                                row.total += col.transactionCount || 0;
                                row.cells[index].toolTip = ['Transaction', 'Staff'];
                                row.cells[index].value = col.transactionCount || 0;
                                break;
                            case filters.labourSales:
                                row.total += col.transactionCount || 0;
                                row.cells[index].value = col.transactionCount || 0;
                                break;
                            case filters.topSellingItems:
                                row.total += col.itemsQuantity || 0;
                                row.cells[index].value = col.itemsQuantity || 0;
                                break;
                        }
                        values.push(row.cells[index]);
                        daysTotal[daysOfWeek[index]] += row.cells[index].value;
                    });
                    daysTotal.grandTotal += row.total;
                });

                let standardDiv = getSD(values, 'value');
                let deviations = [];
                Object.keys(pixels).forEach((i) => {
                    let index = parseInt(i);
                    let row = pixels[i];
                    let rowInGrid = grid[index].cells;

                    Object.keys(row).forEach((j) => {
                        let index = parseInt(j);
                        let deviationFromMean = Math.floor(rowInGrid[index].value - standardDiv);
                        deviations.push(deviationFromMean);
                        rowInGrid[index].deviationFromMean = deviationFromMean;
                    });
                });
                deviations = deviations.sort((a, b) => {
                    return a - b;
                });
                let min = deviations[0];
                let max = deviations[deviations.length -1];
                Object.keys(pixels).forEach((i) => {
                    let index = parseInt(i);
                    let row = pixels[i];
                    let rowInGrid = grid[index].cells;

                    Object.keys(row).forEach((j) => {
                        let index = parseInt(j);
                        if (rowInGrid[index].value > 0) {
                            let value = rowInGrid[index].deviationFromMean;
                            let scaled = Math.floor(convertRange(value, [min, max], [1, 10]));

                            let lowShade = shadeHexColor(highShade, 1);
                            let percent = (scaled/10)*100;
                            rowInGrid[index].color = findColorBetween(lowShade, convertHexToRgb(highShade), percent);
                        }
                    });
                });
                filterGrid();
            }

            function getTopItems () {
                var itemSalesSearch = {};
                itemSalesSearch.startDateTime = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                itemSalesSearch.endDateTime = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                itemSalesSearch.sortBy = 'quantity';
                itemSalesSearch.limit = 6;
                itemSalesSearch.locationId = $scope.reportSearch.locationId;

                $scope.isLoading = true;
                // Calculate the top five selling items for display
                return Reports.getOverviewItemSalesReport(itemSalesSearch).$promise.then((response) => {
                    $scope.isLoading = false;
                    $scope.topFiveItems = response;
                    if (response && response.length) {
                        $scope.selectItem(response[0], 0);
                    }
                }).catch((error) => {
                    $scope.isLoading = false;
                });
            }

            function getPatronCounts () {
                if ($scope.isLoading) {
                    return;
                }
                var reportSearch = {};
                $scope.searchDisabled = true;
                clearTimeout(allowRetry);
                allowRetry = setTimeout(function () {
                    $scope.searchDisabled = false;
                }, 30000);
                reportSearch.startDateTime = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                reportSearch.endDateTime = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                if ($scope.reportSearch.companyId) {
                    reportSearch.companyId = $scope.reportSearch.companyId;
                }
                if ($scope.reportSearch.locationId) {
                    reportSearch.locationId = $scope.reportSearch.locationId;
                }
                if ($scope.reportSearch.startHour) {
                    reportSearch.startHour = moment($scope.reportSearch.startHour, ['hA']).format('HH');
                }
                if ($scope.reportSearch.endHour) {
                    reportSearch.endHour = moment($scope.reportSearch.endHour, ['hA']).format('HH');
                }
                $scope.isLoading = true;
                $scope.results = null;
                Reports.getPatronCounts(reportSearch).$promise.then(function (response) {
                    $scope.isLoading = false;
                    if (Object.keys(response.entries).length) {
                        $scope.results = response.entries;
                        activatePixesOnGrid($scope.results);
                    }
                    var currentReportSearch = {
                        fromDate: $scope.reportSearch.fromDate,
                        toDate: $scope.reportSearch.toDate,
                    };
                    currentReportSearch = _.pick(currentReportSearch, function (value) {
                        return !!value;
                    });
                    if (_.isEmpty(currentReportSearch)) {
                        $scope.currentReportSearch = undefined;
                    } else {
                        $scope.currentReportSearch = currentReportSearch;
                    }
                }).catch(function (err) {
                    $scope.isLoading = false;
                });
            }

            $scope.selectItem = function (item, index) {
                $scope.selectedItem = item;

                if (item.details) {
                    activatePixesOnGrid(item.details, itemColors[index]);
                } else {
                    if ($scope.isLoading) {
                        return;
                    }
                    var itemSalesSearch = {};
                    itemSalesSearch.startDateTime = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                    itemSalesSearch.endDateTime = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                    itemSalesSearch.locationId = $scope.reportSearch.locationId;
                    itemSalesSearch.locationServicePeriodMenuId = item.locationServicePeriodMenuId;
                    itemSalesSearch.itemName = item.name;

                    $scope.isLoading = true;
                    Reports.getItemSalesTimeBreakdown(itemSalesSearch).$promise.then((response) => {
                        $scope.isLoading = false;
                        item.details = response.entries;
                        activatePixesOnGrid(item.details, itemColors[index]);
                    }).catch((err) => {
                        $scope.isLoading = false;
                    });
                }

            };

            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };
            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };
            $scope.clearFilters = function (isAdvancedSearch) {
                $scope.reportSearch = {isAdvancedSearch: !!isAdvancedSearch};
                $scope.reportSearch.fromDate = moment().startOf('week').toDate();
                $scope.reportSearch.toDate = moment().endOf('day').toDate();
                $scope.reportSearch.startHour = $scope.hoursOfDay[7];
                $scope.reportSearch.endHour = $scope.hoursOfDay[21];
                $scope.reportSearch.locationId = currentUser.company.locationId;
                $scope.reportSearch.selectedFilter = $scope.filters.transactionCount;
            };
            $scope.clearFilters();

            $scope.recalculate = function (force) {
                if (force) {
                    getPatronCounts();
                    return;
                }
                if (filters.topSellingItems === $scope.reportSearch.selectedFilter) {
                    getTopItems();
                    return;
                }
                if ($scope.results && Object.keys($scope.results).length) {
                    activatePixesOnGrid($scope.results);
                }
            };

            $scope.saveSelectedFromDate = function () {
                var selectedFromDate = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                DateRangeService.setFromDate(selectedFromDate);
            };
            $scope.saveSelectedToDate = function () {
                var selectedToDate = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                DateRangeService.setToDate(selectedToDate);
            };
            $scope.init = function () {
                var fromDate;
                var toDate;
                if (DateRangeService.getFromDate()) {
                    fromDate = moment(DateRangeService.getFromDate()).startOf('day').toDate();
                } else {
                    fromDate = moment().startOf('week').toDate();
                }
                if (DateRangeService.getToDate()) {
                    toDate = moment(DateRangeService.getToDate()).endOf('day').toDate();
                } else {
                    toDate = moment().endOf('day').toDate();
                }

                $scope.reportSearch = {
                    fromDate: fromDate,
                    toDate: toDate,
                    startHour: null,
                    endHour: null,
                    isAdvancedSearch: true,
                    selectedFilter: $scope.filters.transactionCount
                };
                lookupCompanyHierarchy();
            };
            $scope.init();
            $scope.exportToPdf = function (tableId) {
                Export.tableToPdf(tableId, 'portrait');
            };
            $scope.$on('$destroy', function () {
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.locationId', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.fromDate', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.toDate', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });
        }
    ]);
};
