module.exports = function (posModule) {
    posModule.controller('PosTransactionRefundProcessingCtrl', [
        '$scope',
        'tender',
        'refundType',
        function (
            $scope,
            tender,
            refundType) {
            $scope.tender = tender; // { amountCents, _identifier }
            $scope.refundType = refundType; // void/refund
        }]);
};

