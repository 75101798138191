'use strict';

var AppConstants = (function () {
    var AppConstants = function () { };

    AppConstants.prototype.organizationTypes = {
        CAMPUS: 'CAMPUS',
        COUNTER_SERVE: 'COUNTER_SERVE',
        SELF_SERVE: 'SELF_SERVE',
        TABLE_SERVER: 'TABLE_SERVER',
        RETAIL: 'RETAIL',
        WHOLESALE: 'WHOLESALE'
    };

    AppConstants.prototype.uiTenderTypeConstants = {
        CASH: 'cash',
        CARD: 'card',
        OTHER: 'other',
        GIFTCARD: 'giftcard',
        MANUAL_MOBILE: 'manualMobile',
        GATEWAY: 'gateway',
        MOBILE: 'mobile'
    };

    AppConstants.prototype.transactionSourceConstants = {
        NOWN_POS: 0,
        LUCOVA_PAYMENT: 1,
        PREORDER: 2
    };

    AppConstants.prototype.menuItemTypeConstants = {
        CATEGORY: 'category',
        ITEM: 'item',
        MODIFIER: 'modifier'
    };

    AppConstants.prototype.menuItemSubTypeConstants = {
        PAGE: 'page',
        MODIFIER_OPTION: 'modifier_option',
        GIFTCARD_CREATE: 'giftcard.create',
        GIFTCARD_RELOAD: 'giftcard.reload'
    };

    // payments server sends over the labels in all caps.
    AppConstants.prototype.thirdPartyChannels = {
        DELIVERECT: {
            displayName: 'Deliverect',
            appIcon: '../../images/third_party_apps/deliverect-icon.png'
        },
        FOODORA: {
            displayName: 'Foodora',
            appIcon: '../../images/third_party_apps/foodora-icon.png'
        },
        UBER_EATS: {
            displayName: 'UberEats',
            appIcon: '../../images/third_party_apps/ubereats-icon.png'
        },
        SKIP_THE_DISHES: {
            displayName: 'SkipTheDishes',
            appIcon: '../../images/third_party_apps/skip-icon.png'
        },
        RITUAL: {
            displayName: 'Ritual',
            appIcon: '../../images/third_party_apps/ritual-icon.png'
        },
        ZOMATO: {
            displayName: 'Zomato',
            appIcon: '../../images/third_party_apps/zomato-icon.png'
        },
        CAVIAR: {
            displayName: 'Caviar',
            appIcon: '../../images/third_party_apps/caviar-icon.png'
        },
        POSTMATES: {
            displayName: 'Postmates',
            appIcon: '../../images/third_party_apps/postmates-icon.png'
        },
        NOWN: {
            displayName: 'Nōwn',
            appIcon: '../../images/apps/nown-app-icon.png'
        }
    };

    AppConstants.prototype.locales = {
        'en-US': 'English (US)',
        'en-GB': 'English (UK)',
        'en-CA': 'English (CA)',
        'ja-JP': 'Japanese (JP)',
        'fr-CA': 'French (CA)',
        'zh': 'Chinese (CN)'
    };


    AppConstants.prototype.requestStates = {
        LOADING: 'loading',
        SUCCESS: 'success',
        ERROR: 'error',
        NA: 'na'
    };

    AppConstants.prototype.mobileOrderCancelReasonCode = {
        CANCELLED_BY_CASHIER: 0
    };

    AppConstants.prototype.serviceModes = {
        DINE_IN: 'Dine-In',
        TAKE_OUT: 'Take-Out',
        DELIVERY: 'Delivery',
        KIOSK: 'Kiosk'
    };

    AppConstants.prototype.companyAttributesList = [
        'receipt__auto_print_after_tx',
        'receipt__restrict_open_cash_drawer',
        'receipt__separate_items_on_secondary',
        'receipt__print_eos_report',
        'receipt__ticket_pre_print',
        'receipt__include_patron_name_bottom',
        'receipt__include_patron_phone_qr',
        'other__enhance_security_measures',
        'other__allow_station_edit',
        'other__hide_open_drawer_button',
        'other__enable_other_tender_option',
        'other__pos_alert_sounds',
        'other__other_tender_labels',
        'other__enable_pin_on_logout',
        'use_transaction_receipt_v1_image',
        'kitchen_print_text_size',
        'receipt_footer',
        'receipt_tax_registration_details',
        'disable_dcb_quickcharge',
        'enable_dcb_quickcharge_confirmation',
        'receipt__print_card_terminal_response',
        'enable_service_mode_selector',
        'disable_cashier_tip_payout',
        'kiosk__creditcard_option_disabled',
        'kiosk__giftcard_option_disabled',
        'allow_kiosk_tipping',
        'allow_saving_meal_cards',
        'kiosk__default_menu_page_index',
        'suspend_enabled',
        'hide_misc_item',
        'kds_enabled',
        'buzzer_enabled',
        'delivery_enabled',
        'discounts__enable_transaction_level_open',
        'discounts__enable_item_level_open',
        'other__activate_cashier_discount_limit',
        'other__cashier_discount_limit',
        'physical_giftcard__discountable',
        'request_pin_on_cancel_orders',
        'request_pin_on_nosale',
        'start_order_button_text',
        'require_pin_to_open_station',
        'kiosk_language_selection_enabled',
        'cashier_tip_payout__include_cc_tips'
    ];

    AppConstants.prototype.defaultBumpBarKeyBinds = {
        'SELECT_TICKET_1': 'Digit1',
        'SELECT_TICKET_2': 'Digit2',
        'SELECT_TICKET_3': 'Digit3',
        'SELECT_TICKET_4': 'Digit4',
        'SELECT_TICKET_5': 'Digit5',
        'SELECT_TICKET_6': 'Digit6',
        'SELECT_TICKET_7': 'Digit7',
        'SELECT_TICKET_8': 'Digit8',
        'SELECT_TICKET_9': 'Digit9',
        'SELECT_TICKET_10': 'Digit0',
        'UP': 'ArrowUp',
        'DOWN': 'ArrowDown',
        'LEFT': 'ArrowLeft',
        'RIGHT': 'ArrowRight',
        'CLICK': 'Space',
        'BUMP_TICKET': 'KeyB'
    };

    AppConstants.prototype.companyServices = {
        'endOfShiftReport': {translation: 'setupWizard.merchant.services.endOfShiftReport'},
        'lowStockReport': {translation: 'setupWizard.merchant.services.lowStockReport'},
        'giftCardReport': {translation: 'setupWizard.merchant.services.giftCardReport', root: true},
    };

    return AppConstants;
})();


if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = new AppConstants();
} else {
    window.AppConstants = new AppConstants();
}
