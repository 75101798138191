'use strict';


export function cardTerminalOpenEdge (freshideas) {

    freshideas.factory('CardTerminalOpenEdge', [
        '$http',
        '$log',
        function ($http, $log) {

            var url = 'https://localsystem.paygateway.com:21113/RcmService.svc/Initialize';
            var config;

            var init = function (terminalConfig) {
                config = terminalConfig;
            };

            var creditSale = function (amount, promptSignature) {
                // Invoke a Credit Sale transaction using XML parameters

                var paramString = '<XLINKEMVREQUEST>' +
                          '  <TRANSACTIONTYPE>CREDITSALE</TRANSACTIONTYPE>' +
                          '  <XWEBAUTHKEY>' + config.webAuthKey + '</XWEBAUTHKEY>' +
                          '  <XWEBTERMINALID>' + config.webTerminalId + '</XWEBTERMINALID>' +
                          '  <XWEBID>' + config.webId + '</XWEBID>' +
                          '  <ALLOWDUPLICATES />' +
                          '  <SUPPRESSUI />' +
                          '  <AMOUNT>'+amount+'</AMOUNT>' +
                          '  <PROMPTSIGNATURE>' + (promptSignature ? 'True' : 'False') + '</PROMPTSIGNATURE>' +
                          '  <SIGNATUREFILEFORMAT>PNG</SIGNATUREFILEFORMAT>' +
                          '  <RETURNSIGNATUREFORMAT>BASE64</RETURNSIGNATUREFORMAT>' +
                          // '  <PARTIALAPPROVALSUPPORT>TRUE</PARTIALAPPROVALSUPPORT>' +
                          '</XLINKEMVREQUEST>';


                return new Promise(function (resolve, reject) {
                    $http({
                      method: 'GET',
                      url: url,
                      params: {xl2Parameters: paramString}
                    }).then(function (response) {
                        var rcmResponse = JSON.parse(response.data.RcmResponse);
                        if (rcmResponse && rcmResponse.XLinkEMVResult && rcmResponse.XLinkEMVResult.RESULT === '0') {

                            var fields = rcmResponse.XLinkEMVResult;
                            resolve(
                                [
                                {
                                    terminalId: config.webTerminalId,
                                    transactionId: fields.TRANSACTIONID,
                                    transactionType: 'PURCHASE',
                                    cardType: fields.CARDTYPE,
                                    cardBrand: fields.CARDBRAND,
                                    maskedCardNumber: fields.ACCOUNT,
                                    entryMethod: fields.ENTRYTYPE,
                                    approvalCode: fields.APPROVALCODE,
                                    approvedAmount: fields.APPROVEDAMOUNT,
                                    success: fields.RESULT === '0',
                                    batchNum: fields.BATCHNO,
                                    tipAmount: 0,
                                    signatureBase64Png: fields.SIGNATUREIMAGE,
                                    transactionSequenceNum: null,
                                    debitAccountType: null,
                                    emvAid: null,
                                    emvTvr: null,
                                    emvTsi: null,
                                    emvAppLabel: null,
                                    cvmResult: null,
                                    hostResponseCode: null,
                                    hostResponseIsoCode: null,
                                    demoMode: null,
                                    isCredit: null,
                                    isDebit: null,
                                    showSignatureLine: true
                                },
                                response.data
                                ]
                            );
                        } else {
                            reject();
                        }
                    }, function (error) {
                        reject();
                    });
                });
            };

            var creditVoid = function (transactionId) {
                // Invoke a Credit Sale transaction using XML parameters
                var paramString = '<XLINKEMVREQUEST>' +
                          '  <TRANSACTIONTYPE>CREDITVOID</TRANSACTIONTYPE>' +
                          '  <XWEBAUTHKEY>' + config.webAuthKey + '</XWEBAUTHKEY>' +
                          '  <XWEBTERMINALID>' + config.webTerminalId + '</XWEBTERMINALID>' +
                          '  <XWEBID>' + config.webId + '</XWEBID>' +
                          '  <SUPPRESSUI />' +
                          '  <TRANSACTIONID>'+transactionId+'</TRANSACTIONID>' +
                          '</XLINKEMVREQUEST>';

                return new Promise(function (resolve, reject) {
                    $http({
                      method: 'GET',
                      url: url,
                      params: {xl2Parameters: paramString}
                    }).then(function (response) {
                        var rcmResponse = JSON.parse(response.data.RcmResponse);
                        if (rcmResponse && rcmResponse.XLinkEMVResult && rcmResponse.XLinkEMVResult.RESULT === '0') {
                            var fields = rcmResponse.XLinkEMVResult;
                            resolve(
                                [
                                {
                                    terminalId: config.webTerminalId,
                                    transactionId: fields.TRANSACTIONID,
                                    transactionType: 'PURCHASE VOID',
                                    cardType: fields.CARDTYPE,
                                    cardBrand: fields.CARDBRAND,
                                    maskedCardNumber: fields.ACCOUNT,
                                    entryMethod: fields.ENTRYTYPE,
                                    approvalCode: fields.APPROVALCODE,
                                    approvedAmount: fields.APPROVEDAMOUNT,
                                    success: fields.RESULT === '0',
                                    batchNum: fields.BATCHNO,
                                    tipAmount: 0,
                                    signatureBase64Png: fields.SIGNATUREIMAGE,
                                },
                                response.data
                                ]
                            );
                        } else {
                            reject();
                        }
                    }, function (error) {
                        reject();
                    });
                });
            };

            var creditReturn = function (amount) {
                // Invoke a Credit Sale transaction using XML parameters
                var paramString = '<XLINKEMVREQUEST>' +
                          '  <TRANSACTIONTYPE>CREDITRETURN</TRANSACTIONTYPE>' +
                          '  <XWEBAUTHKEY>' + config.webAuthKey + '</XWEBAUTHKEY>' +
                          '  <XWEBTERMINALID>' + config.webTerminalId + '</XWEBTERMINALID>' +
                          '  <XWEBID>' + config.webId + '</XWEBID>' +
                          '  <SUPPRESSUI />' +
                          '  <AMOUNT>'+amount+'</AMOUNT>' +
                          '</XLINKEMVREQUEST>';

                return new Promise(function (resolve, reject) {
                    $http({
                      method: 'GET',
                      url: url,
                      params: {xl2Parameters: paramString}
                    }).then(function (response) {
                        var rcmResponse = JSON.parse(response.data.RcmResponse);
                        if (rcmResponse && rcmResponse.XLinkEMVResult && rcmResponse.XLinkEMVResult.RESULT === '0') {
                            var fields = rcmResponse.XLinkEMVResult;
                            resolve(
                                [
                                {
                                    terminalId: config.webTerminalId,
                                    transactionId: fields.TRANSACTIONID,
                                    transactionType: 'PURCHASE REFUND',
                                    cardType: fields.CARDTYPE,
                                    cardBrand: fields.CARDBRAND,
                                    maskedCardNumber: fields.ACCOUNT,
                                    entryMethod: fields.ENTRYTYPE,
                                    approvalCode: fields.APPROVALCODE,
                                    approvedAmount: fields.APPROVEDAMOUNT,
                                    success: fields.RESULT === '0',
                                    batchNum: fields.BATCHNO,
                                    tipAmount: 0,
                                    signatureBase64Png: fields.SIGNATUREIMAGE,
                                },
                                response.data
                                ]
                            );
                        } else {
                            reject();
                        }
                    }, function (error) {
                        reject();
                    });
                });
            };

            var debitSale = function (amount) {
                // Invoke a Credit Sale transaction using XML parameters
                var paramString = '<XLINKEMVREQUEST>' +
                          '  <TRANSACTIONTYPE>DEBITSALE</TRANSACTIONTYPE>' +
                          '  <XWEBAUTHKEY>' + config.webAuthKey + '</XWEBAUTHKEY>' +
                          '  <XWEBTERMINALID>' + config.webTerminalId + '</XWEBTERMINALID>' +
                          '  <XWEBID>' + config.webId + '</XWEBID>' +
                          '  <ALLOWDUPLICATES />' +
                          '  <SUPPRESSUI />' +
                          '  <AMOUNT>' + amount + '</AMOUNT>' +
                          '</XLINKEMVREQUEST>';


                return new Promise(function (resolve, reject) {
                    $http({
                      method: 'GET',
                      url: url,
                      params: {xl2Parameters: paramString}
                    }).then(function (response) {
                        var rcmResponse = JSON.parse(response.data.RcmResponse);
                        if (rcmResponse && rcmResponse.XLinkEMVResult && rcmResponse.XLinkEMVResult.RESULT === '0') {
                            var fields = rcmResponse.XLinkEMVResult;
                            resolve(
                                [
                                {
                                    terminalId: config.webTerminalId,
                                    transactionId: fields.TRANSACTIONID,
                                    transactionType: 'DEBIT PURCHASE',
                                    cardType: fields.CARDTYPE,
                                    cardBrand: fields.CARDBRAND,
                                    maskedCardNumber: fields.ACCOUNT,
                                    entryMethod: fields.ENTRYTYPE,
                                    approvalCode: fields.APPROVALCODE,
                                    approvedAmount: fields.APPROVEDAMOUNT,
                                    success: fields.RESULT === '0',
                                    batchNum: fields.BATCHNO,
                                    tipAmount: 0,
                                    signatureBase64Png: fields.SIGNATUREIMAGE,
                                },
                                response.data
                                ]
                            );
                        } else {
                            reject();
                        }
                    }, function (error) {
                        reject();
                    });
                });
            };

            var debitReturn = function (amount) {
                // Invoke a Credit Sale transaction using XML parameters
                var paramString = '<XLINKEMVREQUEST>' +
                          '  <TRANSACTIONTYPE>DEBITRETURN</TRANSACTIONTYPE>' +
                          '  <XWEBAUTHKEY>' + config.webAuthKey + '</XWEBAUTHKEY>' +
                          '  <XWEBTERMINALID>' + config.webTerminalId + '</XWEBTERMINALID>' +
                          '  <XWEBID>' + config.webId + '</XWEBID>' +
                          '  <SUPPRESSUI />' +
                          '  <AMOUNT>'+amount+'</AMOUNT>' +
                          '</XLINKEMVREQUEST>';

                return new Promise(function (resolve, reject) {
                    $http({
                      method: 'GET',
                      url: url,
                      params: {xl2Parameters: paramString}
                    }).then(function (response) {
                        var rcmResponse = JSON.parse(response.data.RcmResponse);
                        if (rcmResponse && rcmResponse.XLinkEMVResult && rcmResponse.XLinkEMVResult.RESULT === '0') {
                            var fields = rcmResponse.XLinkEMVResult;
                            resolve(
                                [
                                {
                                    terminalId: config.webTerminalId,
                                    transactionId: fields.TRANSACTIONID,
                                    transactionType: 'DEBIT REFUND',
                                    cardType: fields.CARDTYPE,
                                    cardBrand: fields.CARDBRAND,
                                    maskedCardNumber: fields.ACCOUNT,
                                    entryMethod: fields.ENTRYTYPE,
                                    approvalCode: fields.APPROVALCODE,
                                    approvedAmount: fields.APPROVEDAMOUNT,
                                    success: fields.RESULT === '0',
                                    batchNum: fields.BATCHNO,
                                    tipAmount: 0,
                                    signatureBase64Png: fields.SIGNATUREIMAGE,
                                },
                                response.data
                                ]
                            );
                        } else {
                            reject();
                        }
                    }, function (error) {
                        reject();
                    });
                });
            };

            var autoSettle = function () {
                $log.error({
                    message: 'Implement auto settle open edge',

                });
            };

            var parseResponse = function () {
                $log.error({
                    message: 'Implement parse response open edge',
                });

                throw new Error('Not implemented');
            };

            var isCancellableFromPos = function () {
                return false;
            };

            var factory = {
                init: init,
                creditSale: creditSale,
                creditVoid: creditVoid,
                creditReturn: creditReturn,
                debitSale: debitSale,
                debitReturn: debitReturn,
                autoSettle: autoSettle,
                parseResponse: parseResponse,
                isCancellableFromPos: isCancellableFromPos,
            };
            return factory;
        }
    ]);
}
