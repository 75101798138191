'use strict';

/* globals ngGridFlexibleHeightPlugin */

const angular = require('angular');
const moment = require('moment');

module.exports = function (freshideasReports) {
    freshideasReports.controller('TransactionReportsCtrl', [
        '$scope',
        '$location',
        '$modal',
        'Reports',
        'Lookup',
        'Meals',
        'Security',
        'notificationTranslationHelper',
        'Utils',
        'ErrorUtil',
        'NgGridSortService',
        'NgGridTemplateService',
        'PosAlertService',
        'DateRangeService',
        'Platform',
        'USER_ROLE_TYPE',
        function ($scope, $location, $modal, Reports, Lookup, Meals, Security, notificationTranslationHelper,
                Utils, ErrorUtil, NgGridSortService, NgGridTemplateService, PosAlertService, DateRangeService, Platform, USER_ROLE_TYPE) {
            $scope.isIosWebkit = Platform.isIosWebkit();
            $scope.isElectron = Platform.isElectron();
            $scope.searchDisabled = false;
            var allowRetry;
            var currentUser = Security.getUser() || {};
            $scope.isManager = currentUser.roleType === USER_ROLE_TYPE.MANAGER;
            $scope.isAccountant = currentUser.roleType === USER_ROLE_TYPE.ACCOUNTANT;
            $scope.isSiteAdmin = currentUser.permission === 'SITEADMIN';
            var currentCompanyId = currentUser.companyId;
            $scope.showMeals = (currentUser.company.companyType !== 'SMB');

            $scope.init = function () {
                if (DateRangeService.getFromDate()) {
                    $scope.transactionSearch.startDateTime = moment(DateRangeService.getFromDate()).startOf('day').toDate();
                }
                if (DateRangeService.getToDate()) {
                    $scope.transactionSearch.endDateTime = moment(DateRangeService.getToDate()).endOf('day').toDate();
                }
                lookupCompanyHierarchy().$promise.then($scope.loadTransactions);
            };
            var refreshLocations = function (companies) {
                if (companies) {
                    $scope.locations = _.flatten(_.map(companies, function (company) {
                        return company.children;
                    }), true);
                }
            };
            var refreshMealPeriods = function (locations) {
                if (locations) {
                    $scope.mealPeriods = _.flatten(_.map(locations, function (location) {
                        return _.filter(location.children, function (child) {
                            return child.type === 'mealPeriod';
                        });
                    }), true);
                    var currentMealPeriodId = $scope.transactionSearch.mealPeriodId;
                    var foundMealPeriod = _.find($scope.mealPeriods, function (mealPeriod) {
                        return mealPeriod.id === currentMealPeriodId;
                    });
                    if (!foundMealPeriod) {
                        $scope.transactionSearch.mealPeriodId = undefined;
                    }
                }
            };
            var refreshPoses = function (locations) {
                if (locations) {
                    $scope.poses = _.flatten(_.map(locations, function (location) {
                        return _.filter(location.children, function (child) {
                            return child.type === 'pos';
                        });
                    }), true);
                    var currentPosId = $scope.transactionSearch.posId;
                    var foundPos = _.find($scope.poses, function (pos) {
                        return pos.id === currentPosId;
                    });
                    if (!foundPos) {
                        $scope.transactionSearch.posId = undefined;
                    }
                }
            };
            $scope.$watch('transactionSearch.companyId', function (companyId) {
                if (companyId) {
                    var foundCompany = _.find($scope.companyHierarchy, function (company) {
                        return company.id === companyId;
                    });
                    if (foundCompany) {
                        refreshLocations([foundCompany]);
                    }
                } else {
                    var companyHierarchy = angular.copy($scope.companyHierarchy);
                    refreshLocations(companyHierarchy);
                }
            });
            $scope.$watch('reportSearch.locationId', function (locationId) {
                if (locationId) {
                    var foundLocation;
                    _.each($scope.companyHierarchy, function (company) {
                        var l = _.find(company.children, function (location) {
                            return location.id === locationId;
                        });
                        if (l) {
                            foundLocation = l;
                        }
                    });
                    if (foundLocation) {
                        refreshMealPeriods([foundLocation]);
                        refreshPoses([foundLocation]);
                    }
                } else {
                    refreshMealPeriods($scope.locations);
                    refreshPoses($scope.locations);
                }
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });
            $scope.$watch('transactionSearch.mealPlanId', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });
            $scope.$watch('reportSearch.startDateTime', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });
            $scope.$watch('reportSearch.endDateTime', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };
            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };
            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };
            $scope.saveSelectedFromDate = function () {
                var selectedFromDate = moment($scope.transactionSearch.startDateTime).startOf('day').valueOf();
                DateRangeService.setFromDate(selectedFromDate);
            };
            $scope.saveSelectedToDate = function () {
                var selectedToDate = moment($scope.transactionSearch.endDateTime).endOf('day').valueOf();
                DateRangeService.setToDate(selectedToDate);
            };
            $scope.transactionSearch = {
                isAdvancedSearch: true,
                mealPeriodId: '0',
                startDateTime: moment().startOf('week').toDate(),
                endDateTime: moment().endOf('day').toDate(),
                employeeId: 0,
                transactionId: undefined,
                patronCardNumber: undefined,
                patronStudentId: undefined,
                firstName: undefined,
                lastName: undefined,
                guestTransactionsOnly: false,
                sortBy: 'transaction_id',
                ascending: false,
                mealPlanIds: '',
                dcbMealPlanIds: '',
                locationId: '',
                posStationId: '',
                mealPlanType: '',
                mealPlanId: '0',
                offset: 0,
                limit: 50,
                mobileTransactionsOnly: false
            };
            function lookupCompanyHierarchy () {
                return Lookup.locationsByCompany({}, function (response) {
                    $scope.company = _.findWhere(response, {id: currentUser.companyId});
                    $scope.locations = [];
                    let children = ($scope.company && $scope.company.children) ? $scope.company.children : [];

                    if (children.length) {
                        if (children.length > 1) {
                            $scope.locations.push({
                                id: null,
                                name: 'All Locations'
                            });
                        }
                        _.each(children, function (location) {
                            $scope.locations.push(location);
                        });
                        $scope.transactionSearch.locationId = currentUser.company.locationId;
                    }
                });
            }
            $scope.loadTransactions = function (preserveOffset) {
                $scope.searchDisabled = true;
                clearTimeout(allowRetry);
                allowRetry = setTimeout(function () {
                    $scope.searchDisabled = false;
                }, 30000);
                if (preserveOffset) {
                    // `preserveOffset` is useful for cases such as going to next/previous page because the filters remain the same
                    $scope.transactionSearch.offset = Utils.serverSidePagingRowNumber($scope.pagingOptions.currentPage, $scope.pagingOptions.pageSize);
                } else {
                    // By default, always show first page of results because it makes sense that users expect the first set of results
                    //  when setting a different filter
                    $scope.transactionSearch.offset = 0;
                    $scope.pagingOptions.currentPage = 1;
                }

                $scope.transactionSearch.limit = 50;
                if ($scope.transactionSearch.mealPlanId !== 0) {
                    angular.forEach($scope.mealPlans, function (value, key) {
                        if (value.id === $scope.transactionSearch.mealPlanId) {
                            $scope.transactionSearch.mealPlanType = value.mealPlanType;
                        }
                    });
                }

                $scope.transactionSearch.companyId = $scope.transactionSearch.companyId || currentCompanyId;
                if (!$scope.transactionSearch.locationId) {
                    $scope.transactionSearch.locationId = null;
                }
                var transactionSearch = angular.copy($scope.transactionSearch);
                transactionSearch.startDateTime = moment(transactionSearch.startDateTime).startOf('day').valueOf();
                transactionSearch.endDateTime = moment(transactionSearch.endDateTime).endOf('day').valueOf();
                $scope.loadingTransactions = true;
                Reports.getTransactionHistory(transactionSearch, function (response) {
                    if (response.length > 0) {
                        $scope.transactions = response;
                        $scope.pagingOptions.fromDate = angular.copy(transactionSearch.startDateTime);
                        $scope.pagingOptions.toDate = angular.copy(transactionSearch.endDateTime);
                        $scope.pagingOptions.totalItems = Utils.serverSideTotalItems(response.length, $scope.pagingOptions.currentPage, $scope.pagingOptions.pageSize);
                        $scope.pagingOptions.startRecord = Utils.serverSideStartRecord($scope.pagingOptions.currentPage, $scope.pagingOptions.pageSize);
                        $scope.pagingOptions.endRecord = Utils.serverSideEndRecord(response.length, $scope.pagingOptions.currentPage, $scope.pagingOptions.pageSize);
                    }

                    $scope.pagingOptions.hasTransactions = response.length > 0;
                    $scope.loadingTransactions = false;
                });
            };
            $scope.loadPosStations = function () {
                $scope.locationPOS = {locationId: $scope.transactionSearch.locationId};
                Lookup.locationPOSStations($scope.locationPOS, function (response) {
                    $scope.posStation = response;
                });
            };
            $scope.resetFilters = function () {
                $scope.transactionSearch = {
                    isAdvancedSearch: true,
                    mealPeriodId: '0',
                    startDateTime: moment().startOf('week').toDate(),
                    endDateTime: moment().endOf('day').toDate(),
                    employeeId: 0,
                    transactionId: undefined,
                    patronCardNumber: undefined,
                    patronStudentId: undefined,
                    firstName: undefined,
                    lastName: undefined,
                    guestTransactionsOnly: false,
                    sortBy: 'transaction_id',
                    ascending: false,
                    mealPlanIds: '',
                    dcbMealPlanIds: '',
                    posStationId: '',
                    mealPlanType: '',
                    mealPlanId: '0',
                    offset: 0,
                    limit: 50,
                    locationId: currentUser.company.locationId
                };
                Lookup.allMealPlans(function (response) {
                    $scope.mealPlans = response;
                    $scope.loadTransactions();
                });
            };
            $scope.loadLocations = function () {
                if ($scope.transactionSearch.companyId === '0') {
                    $scope.posStation = [];
                    $scope.locations = [];
                    Lookup.allMealPlans(function (response) {
                        $scope.mealPlans = response;
                    });
                } else {
                    $scope.loadCompanyLocations = {
                        companyId: $scope.transactionSearch.companyId,
                        archived: true
                    };
                    Lookup.companyLocations($scope.loadCompanyLocations, function (response) {
                        $scope.locations = response;
                    });
                    Lookup.companyMealPlans($scope.loadCompanyLocations, function (response) {
                        $scope.mealPlans = response;
                    });
                }
            };
            $scope.getDownloadUrl = function () {
                if ($scope.isIosWebkit || $scope.isElectron) {
                    return;
                }
                // make it so all transactions are downloaded.
                var transactionSearch = angular.copy($scope.transactionSearch);
                transactionSearch.offset = 0;
                transactionSearch.limit = -1;
                transactionSearch.startDateTime = moment(transactionSearch.startDateTime).startOf('day').valueOf();
                transactionSearch.endDateTime = moment(transactionSearch.endDateTime).endOf('day').valueOf();

                var downloadUrl = $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/freshideas/web/reports/transactionHistory/export';
                var queryString = jQuery.param(transactionSearch);
                return downloadUrl + '?' + queryString;
            };
            $scope.showTransactionReport = function () {
                $scope.changeView('transaction-report-download');
            };
            $scope.webGridSortCriteriaMap = {
                'transactionId': 'transaction_id',
                'mealPeriod': 't.service_period_id',
                'locationName': 'l.location_name',
                'patronCardNumber': 'p.patron_key',
                'name': 'p.last_name',
                'patronStudentId': 'p.patron_company_key'
            };
            $scope.webGridSortOptions = {
                fields: ['transactionId'],
                directions: ['desc']
            };
            $scope.$watch('webGridSortOptions', function (newVal, oldVal) {
                if (newVal.fields[0] !== oldVal.fields[0] || newVal.directions[0] !== oldVal.directions[0]) {
                    $scope.transactionSearch.sortBy = $scope.webGridSortCriteriaMap[$scope.webGridSortOptions.fields[0]];
                    $scope.transactionSearch.ascending = $scope.webGridSortOptions.directions[0] === 'asc' ? true : false;
                    $scope.loadTransactions();
                }
            }, true);
            $scope.pagingOptions = {
                currentPage: 1,
                totalItems: 51,
                pageSize: 50,
                startRecord: 1,
                endRecord: 50
            };
            $scope.gridOptions = {
                columnDefs: [
                    {
                        field: 'transactionId',
                        displayName: 'transaction.transactionId',
                        width: '*',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'mealPeriod',
                        displayName: 'transaction.mealPeriod',
                        width: '**',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        visible: $scope.showMeals
                    },
                    {
                        field: 'locationName',
                        displayName: 'transaction.locationName',
                        width: '**',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        visible: $scope.showMeals
                    },
                    {
                        field: 'patronCardNumber',
                        displayName: 'transaction.patronCardNumber',
                        width: '*',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        visible: $scope.showMeals
                    },
                    {
                        field: 'patronStudentId',
                        displayName: 'transaction.patronStudentId',
                        width: '*',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        visible: $scope.showMeals
                    },
                    {
                        field: 'name',
                        displayName: 'transaction.patronName',
                        width: '**',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'transactionDateTime',
                        displayName: 'transaction.transactionDateTime',
                        width: '**',
                        sortable: false,
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{COL_FIELD | date:\'yyyy-MM-dd HH:mm:ss\' : \'+0000\'}}</span><div>'
                    },
                    {
                        field: 'mealPlanIds',
                        displayName: 'transaction.mealPlanIds',
                        width: '*',
                        sortable: false,
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        visible: $scope.showMeals
                    },
                    {
                        field: 'mealPlanCount',
                        displayName: 'transaction.mealPlanCount',
                        width: '*',
                        sortable: false,
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        visible: $scope.showMeals
                    },
                    {
                        field: 'dcbMealPlanIds',
                        displayName: 'transaction.dcbMealPlanIds',
                        width: '*',
                        sortable: false,
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        visible: $scope.showMeals
                    },
                    {
                        field: 'dcbAmount',
                        displayName: 'transaction.dcbAmount',
                        width: '*',
                        sortable: false,
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{ COL_FIELD | currency }}</span><div>',
                        visible: $scope.showMeals
                    },
                    {
                        field: 'cashAmount',
                        displayName: 'transaction.cashAmount',
                        width: '*',
                        sortable: false,
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{ COL_FIELD | currency }}</span><div>'
                    },
                    {
                        field: 'creditCardAmount',
                        displayName: 'transaction.creditCardAmount',
                        width: '*',
                        sortable: false,
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{ COL_FIELD | currency }}</span><div>'
                    },
                    {
                        field: 'debitCardAmount',
                        displayName: 'transaction.debitCardAmount',
                        width: '*',
                        sortable: false,
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{ COL_FIELD | currency }}</span><div>'
                    },
                    {
                        field: 'tipAmount',
                        displayName: 'transaction.tipAmount',
                        width: '*',
                        sortable: false,
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{ COL_FIELD | currency }}</span><div>'
                    },
                    {
                        field: 'loyaltyAmount',
                        displayName: 'transaction.loyaltyAmount',
                        width: '*',
                        sortable: false,
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{ (COL_FIELD < 0 ? -COL_FIELD / 100 : 0)  | currency }}</span><div>'
                    },
                    {
                        field: 'otherAmount',
                        displayName: 'transaction.otherAmount',
                        width: '*',
                        sortable: false,
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{ COL_FIELD | currency }}</span><div>'
                    },
                    // third party only.
                    {
                        field: 'shippingPrice',
                        displayName: 'transaction.deliveryFeeAmount',
                        width: '*',
                        sortable: false,
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{ COL_FIELD | currency }}</span><div>'
                    },
                    {
                        field: 'serviceCharge',
                        displayName: 'transaction.serviceChargeAmount',
                        width: '*',
                        sortable: false,
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{ COL_FIELD | currency }}</span><div>'
                    },
                    {
                        field: 'discountAmount',
                        displayName: 'transaction.discountAmount',
                        width: '*',
                        sortable: false,
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{ COL_FIELD | currency }}</span><div>'
                    },
                    {
                        field: 'label',
                        displayName: 'transaction.label',
                        width: '**',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                ],
                plugins: [new ngGridFlexibleHeightPlugin({minHeight: 520})],
                showFooter: false,
                multiSelect: false,
                showSelectionCheckbox: false,
                enableSorting: true,
                enableColumnResize: true,
                enablePaging: true,
                sortInfo: $scope.webGridSortOptions,
                useExternalSorting: true,
                enableHighlighting: false,
                data: 'transactions'
            };
            $scope.init();

            $scope.$on('$destroy', function () {
                clearTimeout(allowRetry);
            });

            $scope.$watch('transactionSearch.locationId', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('transactionSearch.startDateTime', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('transactionSearch.endDateTime', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('transactionSearch.firstName', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('transactionSearch.lastName', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('transactionSearch.transactionId', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('transactionSearch.guestTransactionsOnly', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('transactionSearch.mobileTransactionsOnly', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('transactionSearch.posStationId', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('transactionSearch.mealPlanId', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });
        }
    ]);
};
