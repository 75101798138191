'use strict';


module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ProductRenamePromptCtrl', [
        '$scope',
        'selectedMenu',
        'currentPageName',
        function ($scope, selectedMenu, currentPageName) {
            $scope.selectedMenu = selectedMenu;
            $scope.currentPageName = currentPageName;

            $scope.pageName = {
                text: ''
            };

            $scope.cancel = function () {
                $scope.$close({
                    action: 'cancel'
                });
            };
            $scope.save = function () {
                $scope.$close({
                    pageName: $scope.pageName.text,
                    action: 'save'
                });
            };
        }]);
};
