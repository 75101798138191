'use strict';

/* globals StarWebPrintBuilder */
const fabric = require('fabric').fabric;
const Pure = require('../../../external/pure');
const QRCode = require('qrcode');

(function () {
    var imageReceiptFactory = function (EnvConfig, receiptSize, type = 'receipt', outputFormat = 'image/jpeg') {
        const PAPER_WIDTH = (receiptSize == 2) ? 372 : 558; // correspond to paper width in pixel - 558 seems to work the best for 3" paper
        let FONT_SIZE = (type === 'receipt') // correspond to font size in px
            ? 26 // 26 seems to work the best for transaction receipts
            : 22; // 22 seems to work the best for reports printout
        let LINE_HEIGHT = 28;
        const FONT_FAMILY = (type === 'receipt') // corresponds to font for transactions or reports
            ? 'Nunito, Lato, sans-serif'
            : 'Roboto Mono, Monaco, OCR-A, OCR-B, monospace';
        const DIVIDER_THIN_STROKE = (type === 'receipt') // corresponds to "thin" divider stroke width in px
            ? 0 // using 0 for the stroke width for a cleaner look on transaction receipts
            : 1; // using 1 for better legibility on report printouts

        var canvas = undefined;
        var pointer = 0;
        var textAlign = 'left';

        var sections = [];

        var isIosWebkit = function () {
            if (window.webkit && window.webkit.messageHandlers) {
                // Running from WkWebview
                return true;
            }

            return false;
        };

        var printer = {
            initialize: function (textScaleFactor = 1) {
                printer._destroyAll();
                printer._createCanvas();

                FONT_SIZE = Math.ceil(FONT_SIZE * textScaleFactor);
                LINE_HEIGHT = Math.ceil(LINE_HEIGHT * textScaleFactor);

                return printer;
            },
            cut: function () {
                // do nothing
                return printer;
            },
            _createCanvas: function () {
                pointer = 0;
                canvas = new fabric.Canvas('receipt_' + Pure.generateUuid(), {
                    backgroundColor: 'white'
                });
            },
            _destroyCanvas: function (canvas) {
                canvas.dispose();
            },
            _destroyAll: function () {
                if (canvas) {
                    printer._destroyCanvas(canvas);
                }
                canvas = undefined;

                for (let section of sections) {
                    printer._destroyCanvas(section.canvas);
                }
                sections.length = 0;
            },
            section: function () {
                // create a new section by recording the current context (eg. canvas, height)
                // as a new section, and internally reset the context for further rendering
                sections.push({
                    height: pointer,
                    canvas: canvas
                });

                printer._createCanvas();

                return printer;
            },
            align: function (alignment) {
                alignment = alignment || 'left';

                textAlign = alignment;

                return printer;
            },
            title: function (elements, emphasis, styles) {
                var data = '';

                elements = elements || '';
                _.each(elements, function (element) {
                    data += (element + '');
                });

                styles = styles || {};
                styles.width = styles.width || 1;
                styles.height = styles.height || 2;
                styles.invert = styles.invert || false;
                styles.linethrough = styles.linethrough || false;

                if (!styles.keepNewLine) {
                    data = data.replace(/\n/g, '');
                }

                // styles.width corresponds width of each character on the printout. On monospaced/dot-matrix printer
                // this corresponds to actually making a character taking up, for example, 2 spaces if a width of 2
                // is specified. This scaling doesn't not directly translate to font size and the below formula seems
                // to emulate the scaling well.
                var fontSizeFactor = (styles.width > 1)
                    ? (1 + (styles.width - 1) / 2)
                    : styles.width;

                var fontSize = (FONT_SIZE * fontSizeFactor);
                var fontWeight = (emphasis) ? '700' : '400';

                var backgroundColor = (styles.invert) ? '#000000' : '#ffffff';
                var color = (styles.invert) ? '#ffffff' : '#000000';

                // This line is supposed to simulate a 0.5em margin on the top of the text.
                pointer += LINE_HEIGHT * fontSizeFactor / 2;
                var textbox = new fabric.Textbox(data, {
                    top: pointer,
                    left: 0,
                    width: PAPER_WIDTH,
                    textAlign: textAlign,
                    fontSize: fontSize,
                    fontFamily: FONT_FAMILY,
                    fontWeight: fontWeight,
                    fill: color,
                    backgroundColor: backgroundColor,
                    borderColor: 'white',
                    linethrough: styles.linethrough,
                });
                canvas.add(textbox);
                // This line is supposed to simulate a 0.5em margin on the bottom of the text.
                pointer += LINE_HEIGHT * fontSizeFactor / 2;

                var textboxLines = textbox._textLines.length;
                pointer += LINE_HEIGHT * fontSizeFactor * textboxLines;

                return printer;
            },
            titleJustified: function (elements, emphasis, styles, stylesRight) {
                var leftString = elements[0] + '';
                var rightString = elements[1] + '';

                styles = styles || {};
                styles.width = styles.width || 1;
                styles.height = styles.height || 2;
                styles.invert = styles.invert || false;

                var fontSizeFactor = (styles.width > 1)
                    ? (1 + (styles.width - 1) / 2)
                    : styles.width;
                var fontSize = (FONT_SIZE * fontSizeFactor);

                var backgroundColor = (styles.invert) ? '#000000' : '#ffffff';
                var color = (styles.invert) ? '#ffffff' : '#000000';

                var fontWeight = '400';
                var rightFontWeight = '400';

                if (emphasis || (styles && styles.fontWeight === '700')) {
                    fontWeight = '700';
                }
                if (emphasis || (stylesRight && stylesRight.fontWeight === '700')) {
                    rightFontWeight = '700';
                }

                var textOption = {
                    top: pointer,
                    fontSize: fontSize,
                    fontFamily: FONT_FAMILY,
                    fontWeight: fontWeight,
                    fill: color,
                    backgroundColor: backgroundColor,
                    borderColor: 'white'
                };

                var rightTextOption = {
                    top: pointer,
                    fontSize: fontSize,
                    fontFamily: FONT_FAMILY,
                    fontWeight: rightFontWeight,
                    stroke: color,
                    backgroundColor: backgroundColor,
                    borderColor: 'white'
                };

                var textboxOptions = printer._computeJustifiedTextBoxOptions(leftString, rightString, textOption, rightTextOption);
                var leftboxOption = textboxOptions.left;
                var rightboxOption = textboxOptions.right;

                var leftTextbox = new fabric.Textbox(leftString, leftboxOption);
                var rightTextbox = new fabric.Textbox(rightString, rightboxOption);

                // This line is supposed to simulate a 0.5em margin above the text.
                pointer += LINE_HEIGHT * fontSizeFactor / 2;
                canvas.add(leftTextbox);
                canvas.add(rightTextbox);
                // This line is supposed to simulate a 0.5em margin on the bottom of the text.
                pointer += LINE_HEIGHT * fontSizeFactor / 2;

                var leftTextboxLines = leftTextbox._textLines.length;
                var rightTextboxLines = leftTextbox._textLines.length;
                var numOfLines = Math.max(leftTextboxLines, rightTextboxLines);

                pointer += LINE_HEIGHT * fontSizeFactor * numOfLines;

                return printer;
            },
            text: function (elements, emphasis, styles) {
                var data = '';

                elements = elements || '';
                _.each(elements, function (element) {
                    data += (element + '');
                });

                data = data.replace(/\n/g, '');

                styles = styles || {};
                styles.width = styles.width || 1;
                styles.height = styles.height || 1;
                styles.invert = styles.invert || false;
                styles.linethrough = styles.linethrough || false;

                var fontSizeFactor = styles.textScale || 1;
                var fontSize = (FONT_SIZE * fontSizeFactor);
                var fontWeight = (emphasis) ? '700' : '400';

                var backgroundColor = (styles.invert) ? '#000000' : '#ffffff';
                var color = (styles.invert) ? '#ffffff' : '#000000';

                var textbox = new fabric.Textbox(data, {
                    top: pointer,
                    left: 0,
                    width: PAPER_WIDTH,
                    textAlign: textAlign,
                    fontSize: fontSize,
                    fontFamily: FONT_FAMILY,
                    fontWeight: fontWeight,
                    fill: color,
                    backgroundColor: backgroundColor,
                    borderColor: 'white',
                    linethrough: styles.linethrough,
                });

                canvas.add(textbox);

                var textboxLines = textbox._textLines.length;
                pointer += LINE_HEIGHT * fontSizeFactor * textboxLines;

                return printer;
            },
            textForNewReciept: function (elements, emphasis, styles) {
                return printer.text(elements, emphasis, styles);
            },
            textJustified: function (elements, emphasis, styles, stylesRight, filler) {
                var leftString = elements[0] + '';
                var rightString = elements[1] + '';

                styles = styles || {};
                styles.width = styles.width || 1;
                styles.height = styles.height || 1;
                styles.invert = styles.invert || false;
                styles.linethrough = styles.linethrough || false;

                var fontSizeFactor = styles.textScale || 1;
                var fontSize = (FONT_SIZE * fontSizeFactor);

                var backgroundColor = (styles.invert) ? '#000000' : '#ffffff';
                var color = (styles.invert) ? '#ffffff' : '#000000';

                var fontWeight = '400';
                var rightFontWeight = '400';
                if (emphasis || (styles && styles.fontWeight === '700')) {
                    fontWeight = '700';
                }
                if (emphasis || (stylesRight && stylesRight.fontWeight === '700')) {
                    rightFontWeight = '700';
                }

                var textOption = {
                    top: pointer,
                    fontSize: fontSize,
                    fontFamily: FONT_FAMILY,
                    fontWeight: fontWeight,
                    fill: color,
                    backgroundColor: backgroundColor,
                    borderColor: 'white',
                    linethrough: styles.linethrough,
                };

                var rightTextOption = {
                    top: pointer,
                    fontSize: fontSize,
                    fontFamily: FONT_FAMILY,
                    fontWeight: rightFontWeight,
                    fill: color,
                    backgroundColor: backgroundColor,
                    borderColor: 'white',
                    linethrough: styles.linethrough,
                };

                var textboxOptions = printer._computeJustifiedTextBoxOptions(leftString, rightString, textOption, rightTextOption);
                var leftboxOption = textboxOptions.left;
                var rightboxOption = textboxOptions.right;

                var leftTextbox = new fabric.Textbox(leftString, leftboxOption);
                var rightTextbox = new fabric.Textbox(rightString, rightboxOption);

                canvas.add(leftTextbox);
                canvas.add(rightTextbox);

                var leftTextboxLines = leftTextbox._textLines.length;
                var rightTextboxLines = leftTextbox._textLines.length;
                var numOfLines = Math.max(leftTextboxLines, rightTextboxLines);

                pointer += LINE_HEIGHT * fontSizeFactor * numOfLines;

                return printer;
            },
            _computeJustifiedTextBoxOptions: function (leftString, rightString, textOption, rightTextOption) {
                var leftText = new fabric.Text(leftString, textOption);
                var rightText = new fabric.Text(rightString, textOption);

                var leftTextBaseWidth = leftText.width;
                var rightTextBaseWidth = rightText.width;

                // This is a very rudimentary way to distribute the width of the 2-column text
                // and can definitely be improved upon in the future. For now, assume the narrower
                // column has a fixed width, and the wider column takes up the rest of the paper
                // width.
                var leftTextWidth, rightTextWidth = 0;
                if (leftTextBaseWidth > rightTextBaseWidth) {
                    rightTextWidth = rightTextBaseWidth + FONT_SIZE;
                    leftTextWidth = PAPER_WIDTH - rightTextWidth;
                } else {
                    leftTextWidth = leftTextBaseWidth + FONT_SIZE;
                    rightTextWidth = PAPER_WIDTH - leftTextWidth;
                }

                rightTextOption = rightTextOption || textOption;

                var leftboxOption = Object.assign({}, textOption);
                leftboxOption.width = leftTextWidth;
                leftboxOption.left = 0;
                leftboxOption.textAlign = 'left';

                var rightboxOption = Object.assign({}, rightTextOption);
                rightboxOption.width = rightTextWidth;
                rightboxOption.left = leftTextWidth;
                rightboxOption.textAlign = 'right';

                return {
                    left: leftboxOption,
                    right: rightboxOption
                };
            },
            textJustifiedAndWrapped: function (elements, emphasis, styles, stylesRight, filler) {
                return printer.textJustified(elements, emphasis, styles, stylesRight, filler);
            },
            newLine: function (count) {
                count = count || 1;
                pointer += count * LINE_HEIGHT;

                return printer;
            },
            divider: function (thin) {
                pointer += LINE_HEIGHT / 2;

                var strokeWidth = (thin) ? DIVIDER_THIN_STROKE : 2;

                var linePoints = [0, pointer, PAPER_WIDTH, pointer];
                var lineOption = {
                    strokeWidth: strokeWidth,
                    stroke: 'black'
                };

                var line = new fabric.Line(linePoints, lineOption);
                canvas.add(line);

                pointer += LINE_HEIGHT / 2;

                return printer;
            },
            output: function (screenPrint = false) {
                if (pointer > 0) {
                    // if there is any remaining canvas that has not been added to
                    // the section list (ie. pointer = 0), make sure it's part of
                    // the section list
                    printer.section();
                }

                if (sections && sections.length) {
                    var format = (outputFormat === 'image/png') ? 'png' : 'jpeg';

                    let builder = new StarWebPrintBuilder();
                    let request = builder.createInitializationElement();
                    let w = undefined;

                    for (var section of sections) {
                        let sectionCanvas = section.canvas;
                        let sectionHeight = section.height;

                        sectionCanvas.renderAll();

                        let imageData = sectionCanvas.toDataURL({
                            format: format,
                            width: PAPER_WIDTH,
                            top: 0,
                            height: sectionHeight
                        });

                        if ((screenPrint || EnvConfig.env == 'development') && !isIosWebkit()) {
                            let image = new Image();
                            image.src = imageData;

                            if (!w) {
                                w = window.open('');
                            }
                            w.document.write('<div>' + image.outerHTML + '</div>');
                        }

                        request += builder.createRawDataElement({data: imageData});
                    }

                    printer._destroyAll();

                    return request;
                }
            },
            _getImageFromURL: function (data) {
                return new Promise((resolve, reject) => {
                    var options = {
                        top: pointer,
                        left: 0
                    };
                    QRCode.toDataURL(data).then((url) => {
                        fabric.Image.fromURL(url, function (oImg) {
                            resolve(oImg);
                        }, options);
                    }).catch((err) => {
                        reject(err);
                    });
                });
            },
            createQrCodeElement: async function (data) {
                var img = await printer._getImageFromURL(data);
                img.left = (PAPER_WIDTH / 2) - (img.width / 2); // This centers the qrcode
                canvas.add(img);
                pointer += img.height + LINE_HEIGHT;
                return printer;
            },
            createImageElement: async function (url) {
                const options = {
                    top: pointer,
                    left: 0
                };

                const loadImg = () => {
                    return new Promise((resolve, reject) => {
                        fabric.util.loadImage(url, function (img) {
                            if (img == null) {
                                reject();
                            } else {
                                let image = new fabric.Image(img);
                                resolve(image);
                            }
                        }, options, {crossOrigin: 'anonymous'});
                    });
                 };

                var img = await loadImg();
                img.left = (PAPER_WIDTH / 2) - (img.width / 2); // This centers the image
                canvas.add(img);
                pointer += img.height + LINE_HEIGHT;
                return printer;
            }
        };

        return printer;
    };

    module.exports = imageReceiptFactory;
})();
