'use strict';

/* eslint-disable max-len, no-tabs, comma-dangle, semi */
/* eslint-disable space-before-function-paren, space-before-blocks */
/* eslint-disable object-curly-spacing, keyword-spacing */
/* eslint-disable brace-style, no-unused-vars, no-console */

const Sortable = require('sortablejs').default;
const angular = require('angular');

module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ShopifyItemsCtrl', [
        '$scope',
        '$modal',
        '$q',
        '$timeout',
        'ConfirmModalService',
        'EventListenerService',
        'Inventory',
        'ProductsService',
        'PosStatusService',
        'shopifyProducts',
        function ($scope, $modal, $q, $timeout, ConfirmModalService, EventListenerService, Inventory, ProductsService, PosStatusService, shopifyProducts) {
            $scope.init = function () {
            };

            $scope.searchText = {
                text: ''
            };

            $scope.shopifyProductsRange = { index: 0, count: 0 };

            $scope.shopifyProducts = {
                get: function (index, count, success) {
                    $scope.shopifyProductsRange.index = index;
                    $scope.shopifyProductsRange.count = count;

                    var result = [];
                        for (var i = index; i <= index + count - 1; i++) {
                            if (shopifyProducts[i - 1]) {
                                var foundArchivedItem = shopifyProducts[i - 1]
                                result.push(shopifyProducts[i - 1]);
                            }
                        }
                    success(result);
                }
            }

            $scope.toggleProductEnabled = function (product) {
                if (!product) {
                    return;
                }
                product.enabled = !product.enabled;
            }

            $scope.importItems = function () {
                var toImport = shopifyProducts.filter((product) => {
                    return product.enabled;
                });
                $scope.$close(toImport);
            }

            $scope.cancel = function () {
                $scope.$close();
            }

            $scope.init();
        }]);
};
