'use strict';

/* globals ngGridFlexibleHeightPlugin */

module.exports = function (freshideasLocation) {

    freshideasLocation.controller('LocationInventory', [
        '$scope',
        '$modal',
        '$q',
        '$translate',
        '$location',
        'Locations',
        'Company',
        'Inventory',
        'notificationTranslationHelper',
        'UiGridTemplateService',
        'ErrorUtil',
        'Utils',
        function ($scope, $modal, $q, $translate, $location, Locations, Company, Inventory, notificationTranslationHelper, UiGridTemplateService, ErrorUtil, Utils) {

            var init = function () {
                console.log('Started Inventory');
            };

            $scope.$watch('locationFactory.location', function (location) {
                if (location) {
                    $scope.loadInventories();
                } else {
                    $scope.locationInventory = [];
                }
            });

            $scope.inventoryPagingOptions = {
                currentPage: 1,
                pageSize: 0,
                startRecord: 0,
                endRecord: 0,
                pageSizeOptions: Utils.buildDefaultPageSizes()
            };
            $scope.loadInventories = function () {
                Inventory.inventoryByLocation({'locationId': $scope.locationFactory.location.id}, function (response) {
                    $scope.locationInventory = response;
                    $scope.inventoryPagingOptions.pagesize = response.length;
                    $scope.inventoryPagingOptions.totalItems = Utils.serverSideTotalItems(response.length, $scope.inventoryPagingOptions.currentPage, $scope.inventoryPagingOptions.pagesize);
                    $scope.inventoryPagingOptions.startRecord = Utils.serverSideStartRecord($scope.inventoryPagingOptions.currentPage, $scope.inventoryPagingOptions.pagesize);
                    $scope.inventoryPagingOptions.endRecord = Utils.serverSideEndRecord(response.length, $scope.inventoryPagingOptions.currentPage, $scope.inventoryPagingOptions.pagesize);
                    // $scope.loadLocationPrinters();
                });
            };

            $scope.inventoryUiGridOptions = {
                columnDefs: [
                    {
                        field: 'upc',
                        displayName: 'invoice.invoiceItem.upc',
                        width: '*',
                        headerCellTemplate: UiGridTemplateService.translateHeaderTemplate
                    },
                    {
                        field: 'name',
                        displayName: 'invoice.invoiceItem.name',
                        width: '**',
                        headerCellTemplate: UiGridTemplateService.translateHeaderTemplate
                    },
                    {
                        field: 'description',
                        displayName: 'invoice.invoiceItem.description',
                        width: '**',
                        headerCellTemplate: UiGridTemplateService.translateHeaderTemplate
                    },
                    {
                        field: 'targetQuantity',
                        displayName: 'invoice.invoiceItem.targetQuantity',
                        width: '**',
                        headerCellTemplate: UiGridTemplateService.translateHeaderTemplate
                    },
                    {
                        field: 'currentQuantity',
                        displayName: 'invoice.invoiceItem.currentQuantity',
                        width: '**',
                        headerCellTemplate: UiGridTemplateService.translateHeaderTemplate
                    },
                    {
                        field: 'unitCost',
                        displayName: 'invoice.invoiceItem.unitCost',
                        width: '**',
                        headerCellTemplate: UiGridTemplateService.translateHeaderTemplate,
                        cellFilter: 'currency'
                    },
                    {
                        field: 'unitPrice',
                        displayName: 'invoice.invoiceItem.unitPrice',
                        width: '**',
                        headerCellTemplate: UiGridTemplateService.translateHeaderTemplate,
                        cellFilter: 'currency'
                    },
                    {
                        field: 'action',
                        displayName: 'general.actions',
                        width: '80',
                        headerCellTemplate: UiGridTemplateService.translateHeaderTemplate,
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text><div class="action-buttons">' + '<a class="blue" ng-click="grid.appScope.editInventory(row.entity)" has-perm resource="inventory:maintenance:edit_item"><i class="fa fa-pencil bigger-130"></i></a>' + '</span></div>'
                    }
                ],
                plugins: [new ngGridFlexibleHeightPlugin({minHeight: 520})],
                showGridFooter: true,
                autoResize: true,
                gridFooterHeight: 32,
                multiSelect: true,
                showSelectionCheckbox: true,
                enableColumnResize: true,
                selectedItems: $scope.selectedInvoices,
                enableSorting: true,
                selectWithCheckboxOnly: true,
                enableHighlighting: true,
                gridFooterTemplate: '<div class="grid-footer"><div class="col-xs-4"></div><div class="col-xs-4"><pager align="false" ng-model="grid.appScope.inventoryPagingOptions.currentPage" ' + 'total-items="grid.appScope.inventoryPagingOptions.totalItems" items-per-page="grid.appScope.inventoryPagingOptions.pagesize" ng-change="grid.appScope.loadInventory();"></pager>' + '</div><div class="col-xs-4 align-right"><span translate="general.records"/> {{grid.appScope.inventoryPagingOptions.startRecord}} - {{grid.appScope.inventoryPagingOptions.endRecord}}</div></div>',
                data: 'locationInventory'
            };


            $scope.addNewInventory = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'inventory/inventory.inventoryItem.edit.tpl.html',
                    controller: 'InventoryItemEditCtrl',
                    windowClass: 'modal-fullscreen',
                    resolve: {
                        selectedInventoryItem: function () {
                            return undefined;
                        },
                        selectedCompanyId: function () {
                            return $scope.locationFactory.companyId;
                        },
                        location: function () {
                            return $scope.locationFactory.location;
                        },
                        isEdit: function () {
                            return false;
                        },
                        printers: function () {
                            return $scope.locationFactory.printers;
                        },
                        taxRates: function () {
                            return $scope.locationFactory.taxRates;
                        }
                    },
                    backdrop: 'static'
                });
                modalInstance.result.then(function (location) {
                    notificationTranslationHelper.notifyMessage('inventory.addInventory.success');
                    $scope.loadInventories();
                });
            };
            $scope.editInventory = function (inventoryItem) {
                var modalInstance = $modal.open({
                    templateUrl: 'inventory/inventory.inventoryItem.edit.tpl.html',
                    controller: 'InventoryItemEditCtrl',
                    windowClass: 'modal-fullscreen',
                    resolve: {
                        selectedInventoryItem: function () {
                            return inventoryItem;
                        },
                        selectedCompanyId: function () {
                            return $scope.locationFactory.companyId;
                        },
                        location: function () {
                            return $scope.locationFactory.location;
                        },
                        isEdit: function () {
                            return true;
                        },
                        printers: function () {
                            return $scope.locationFactory.printers;
                        },
                        taxRates: function () {
                            return $scope.locationFactory.taxRates;
                        }
                    },
                    backdrop: 'static'
                });
                modalInstance.result.then(function (invoice) {
                    notificationTranslationHelper.notifyMessage('invoice.editInvoice.success', {name: inventoryItem.id});
                    $scope.loadInventories();
                });
            };
            $scope.importUPC = function (invoice) {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalUPCImport.tpl.html',
                    controller: 'ImportUPCCtrl',
                    resolve: {
                        companyId: function () {
                            return $scope.companyId;
                        }
                    },
                    windowClass: 'modal-50',
                    backdrop: 'static'
                });
                modalInstance.result.then(function (invoice) {
                    $scope.loadInventories();
                });
            };


            init();
        }
    ]);
};
