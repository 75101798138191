'use strict';


const angular = require('angular');

export default angular.module('freshideas.services.import', []).factory('Import', [
    function () {
        return {
            /*
             *  Compare two objects by each attribute and return the differences as a hash indexed by the attribute name.
             *
             *  This is useful to check if an attribute has changed between two versions of an entity, and if so, how the
             *  the attribute has changed.
             */
            diff: function (oldObject, newObject, attrs) {
                attrs = attrs || _.keys(oldObject);

                var diffs = {};
                _.each(attrs, function (attr) {
                    var oldValue = oldObject[attr];
                    var newValue = newObject[attr];

                    if (oldValue != newValue) {
                        diffs[attr] = {
                            old: oldValue,
                            new: newValue,
                        };
                    }
                });

                return diffs;
            }
        };
    }
]);
