'use strict';


const angular = require('angular');

export default angular.module('freshideas.services.conversionUtil', [])

    .factory('MultiSelectDropdownUtil', ['$translate', function ($translate) {

        var service = {
            /**
             * Sets the list of translation options for the language setting
             * @return {{code: string, name: string, image: string, flag: string, ticked: boolean}[]}
             */
            fromSelectLocaleSetting: function () {
                var localePlace =
                    [
                        {code: 'en_US', name: 'English (United States)', image: '<img src="common/i18n/images/United-States.png" />', flag: 'common/i18n/images/United-States.png', ticked: false},
                        {code: 'es_ES', name: 'Spanish/Español', image: '<img src="common/i18n/images/Spain.png" />', flag: 'common/i18n/images/Spain.png', ticked: false}
                    ];
                return localePlace;
            },
            /**
             * Grabs the selected language to be translated from the multi-select dropdown and returns it
             * @param {*} selectedLocale
             * @return {*}
             */
            toSelectLocaleSetting: function (selectedLocale) {
                var localeCode;
                for (var i = 0; i < selectedLocale.length; i++) {
                    if (selectedLocale[i].ticked) {
                        localeCode = selectedLocale[i].code;
                    }
                }
                return localeCode;
            }
        };
        return service;
    }
]);
