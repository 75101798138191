'use strict';


module.exports = {
    convertDollarToCents: (dollarAmount) => {
        return dollarAmount*100;
    },
    convertCentsToDollar: (centsAmount) => {
        return (centsAmount/100).toFixed(2);
    },
    generateUuid: function () {
        var d = new Date().getTime();

        if (typeof window !== 'undefined' && window.performance && typeof window.performance.now === 'function') {
            d += performance.now(); // use high-precision timer if available
        }

        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random()*16)%16 | 0;
            d = Math.floor(d/16);
            return (c=='x' ? r : (r&0x3|0x8)).toString(16);
        });
        return uuid;
    },
    doTimeout: function (timeout) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                resolve();
            }, timeout);
        });
    },
    filterObject: function (obj, whitelist) {
        var filtered = {};
        for (var i in obj) {
            if (whitelist.includes(i)) {
                filtered[i] = obj[i];
            }
        }
        return filtered;
    },
    buildUrl: function (base, path) {
        let urlObject = new URL(path, base);
        return urlObject.href;
    },
    calculateNewLabour: function (userId, newMinutes, timeCard, labourResponse, isEdit) {
        var newLabourData = {
            timeCardId: timeCard.id,
            regularMinutes: 0,
            otMinutes: 0,
            hourlyWage: timeCard.user.hourlyWage
        };
        // Each element in labourResponse is a single employee's minutes clocked in for the current
        // week. However, in this search only 1 element should exist since we included a userId in
        // the search criteria. If this employee's total hours exceed 40, any extra minutes on their
        // labour will be saved as overtime minutes instead.
        var totalMinutes = 0;
        if (labourResponse.length) {
            var existingLabour = labourResponse[0];
            if (existingLabour) {
                // Get data only associated with the searched userId.
                var oldLabour = existingLabour.labourSummary.find(function (labourData) {
                    return labourData.userId == userId;
                });
                if (oldLabour) {
                    var oldLabourData = oldLabour.labour;
                    _.each(oldLabourData, function (labour) {
                        if (isEdit && labour.timeCardId == timeCard.id) {
                            // Don't count old hours if this is the data being edited.
                            return;
                        }
                        totalMinutes += labour.regularMinutes + labour.otMinutes;
                    });
                }
            }
        }

        if (totalMinutes >= 2400) {
            newLabourData.otMinutes = newMinutes;
        } else if ((totalMinutes + newMinutes) > 2400) {
            var regularMinutes = 2400 - totalMinutes;
            var otMinutes = newMinutes - regularMinutes;
            newLabourData.regularMinutes = regularMinutes;
            newLabourData.otMinutes = otMinutes;
        } else {
            newLabourData.regularMinutes = newMinutes;
        }
        return newLabourData;
    },
    // we only require the first preorder (for now) for mobile order
    // if future release requires or allows multiple preorders per patron, this can be safely removed
    // with tweaks required in mobile order modal & pos.start.patrons.js
    splicePreorderArrayFromPatrons: function (patrons) {
        _.each(patrons, (patron) => {
            var preorderArray = patron.lucovaUser.preorder;
            if (preorderArray.length) {
                preorderArray.splice(1);
            }
        });
    },
    ipTest: function (ip) {
        return /^(([1-9]?\d|1\d\d|2[0-4]\d|25[0-5])(\.(?!$)|(?=$))){4}$/.test(ip || '');
    }
};
