'use strict';

const moment = require('moment');
const convert = require('xml-js');

export function cardTerminalEquinoxEPOS (freshideas) {

    // --------- Data Defination ------------
    // "ResponseCode" from the terminal
    const RESPONSE_CODES = {
        '000': 'Transaction Completed Normally',
        '050': 'Invalid Request – Format Error',
        '051': 'Invalid Request – Missing Mandatory Data',
        '052': 'Invalid Request – Unsupported Type',
        '053': 'Invalid Request – Unsupported version',
        '054': 'Invalid Request – Invalid Data',
        '055': 'Terminal Busy',
        '099': 'Invalid Request – Other',
        '100': 'Transaction not accepted due to terminal conditions',
        '101': 'Transaction Not Completed due to communication failures',
        '102': 'Transaction Not Completed due to other reasons',
        '103': 'Transaction/Function cancelled or timed out',
        '109': 'Transaction not completed due to open Pre-Auth',
        '150': 'No transactions found for the requested report',
        '201': 'Transaction Online Declined',
        '202': 'Transaction Offline Declined',
        '204': 'Transaction Reversed Due To Premature Card Removal',
        '205': 'Transaction Reversed due to MAC failure',
        '210': 'Transaction Not Completed due to final amount exceeding pre-authorized amount',
        '250': 'Request Rejected – No Matching Record',
        '251': 'Required additional input was not provided EPOS (Transaction was terminated)',
        '252': 'Required additional EPOS input was provided but invalid (Transaction was terminated)',
        '999': 'Additional EPOS Input is required before the transaction can be completed',
    };

    // "AppResponseCode" from terminal, is a hex triplet (3-byte binary).
    // Each bit in a byte indicates specific application/software status
    // Below are some common error messages, Other non-zero values would imply
    // some configuration/software problems
    const APP_STATUS_CODE = {
        '000001': 'The terminal has not been initialized',
        '000002': 'New software has been downloaded and is ready for installation',
        '000004': 'New software upgrade not completed',
        '000008': 'Failed to download Host 1 parameters',
        '000010': 'Failed to download Host 2 parameters',
        '000020': 'Terminal is buzy',
        '000080': 'Downloading/upgrading software',
        '000018': 'Failed to download Host 1 and Host 2 parameters',
        '000100': 'Failure during Business Day Close',
        '000200': 'Reached the limit for the current batch or business day. Please do Business Day Close',
        '000400': 'Terminal\'s Credit/Debit batch totals are different from the host\'s records',
        '000800': 'Gift card\'s batch close didn\'t succeed',
        '000500': 'Failure during Business Day Close. Credit/Debit batch totals are different from the host\'s records',
    };

    const CRLF = '\r\n';

    const ACCOUNT_TYPE = {
        'S': 'Savings Account',
        'C': 'Chequing Account'
    };

    const CARD_BIN = {
        'VI': 'Visa',
        'MC': 'MasterCard',
        'AM': 'American Express',
        'DP': 'Interac',
        'DS': 'Discover',
        'UP': 'UnionPay',
    };

    // NSR: No Signature Required
    const CARD_ENTRY_MENTHOD = {
        'M': 'Manual mag card',
        'S': 'Swiped',
        'SN': 'Swiped NSR',
        'CN': 'Chip card NSR',
        'SC': 'Swiped chip card',
        'MC': 'Manual chip card',
        'C': 'Online chip card',
        'RF': 'RFID (contactless)'
    };

    const TRANSACTION_TYPE = {
        PURCHASE: 'Purchase',
        VOID: 'Void',
        REFUND: 'Refund',
        LAST_TRANSACTION: 'LastTransaction',
        DAY_CLOSE: 'DayClose',
        DAY_CLOSE_REPORT: 'DayCloseReport',
        DAY_REPORT: 'DayReport',
    };

    const REPORT_TYPE = {
        // DETAILS: '001',
        BALANCING: '002',
        SUBTOTALS: '003',
        TIP_TOTALS: '006',
        // TIP_DETAILS: '009',
        TIP_TOTALS_BY_CARD: '010',
        OUTSTANDING_SAF: '007',
        CASHBACK_TOTALS: '008',
    };

    const RESOURCE = '/cgi-bin/cardpayment';

    // ---------- end of Data Defination ------------
    // common utility functions start here
    // Date Format: YYYYMMDD, Time Format: HHMMSS
    // Date and Time are the terminal's local date and local time synchronized with the financial host
    var toTransactionDateTime = function (date, time) {
        let dateTime = '';
        let dateTimeFormat = 'YYYY-MM-DD hh:mm:ss A';

        if (!date || !time) {
            dateTime = moment().format(dateTimeFormat);
            return dateTime;
        }

        let formatedDate = `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
        let formatedTime = `${time.substring(0, 2)}:${time.substring(2, 4)}:${time.substring(4, 6)}`;

        dateTime = moment(Date.parse(`${formatedDate}T${formatedTime}`)).format(dateTimeFormat);
        return dateTime;
    };

    var requestBuilder = function (payload, config) {
        const REQUEST_LINE = `POST ${RESOURCE} HTTP/1.1${CRLF}`;

        let requestHeaders = 'Host: ' + config.ip + CRLF
            + 'Authorization: Basic ' + config.base64EncodedToken + CRLF
            + 'Content-Type: text/xml' + CRLF
            + 'Content-Length: ' + payload.length + CRLF;

        return `${REQUEST_LINE}${requestHeaders}${CRLF}${payload}${CRLF}`;
    };

    var chunkedData = '';
    var totalBytes = 0;
    var responseContentLength = 0;

    var resetSharedFields = function () {
        chunkedData = '';
        totalBytes = 0;
    };

    var getAllBytes = function (buffer) {
        let raw = buffer.toString();
        let size = buffer.length;
        totalBytes += size;

        // for TCP connection
        // parseHttpResponse(raw);
        chunkedData += raw;

        if (totalBytes >= responseContentLength) {
            return chunkedData;
        }

        return '';
    };

    var makeCompleteData = function (requestPayload, responseData) {
        let header = '<?xml version="1.0" encoding="utf-8"?>';
        let completeData = '';

        completeData = header
            + '<Log>'
                + requestPayload.split(CRLF).slice(1).join('') // remove request xml header
                + responseData.split('\n').slice(1).join('') // remove response xml header
            + '</Log>';

        return completeData;
    };

    // for TCP connection
    var parseHttpResponse = function (rawResponse, payload = '') {
        let parsedResponse = {
            statusLine: '',
            statusCode: '',
            statusMessage: '',
            responseHeaders: {},
            data: ''
        };
        let header = '<?xml version="1.0" encoding="utf-8"?>';

        let splitResponse = rawResponse.split(CRLF.repeat(2));

        // get response headers
        if (splitResponse.length > 1) {
            let statusLine = rawResponse.split('\n')[0];
            parsedResponse.statusCode = statusLine.split(' ')[1];
            parsedResponse.statusMessage = statusLine.split(' ')[2];
            parsedResponse.statusLine = statusLine;

            splitResponse[0].split('\n').slice(1).forEach(function (header, index, array) {
                let key = header.split(':')[0];
                let val = header.split(':')[1];

                parsedResponse.responseHeaders[key] = val;
            });

            responseContentLength = Number(parsedResponse.responseHeaders['Content-Length'].trim());
            parsedResponse.data = header + '<Log>' + payload.split(CRLF).slice(1).join('') + splitResponse[1].split('\n').slice(1).join('') + '</Log>';
        }

        return parsedResponse;
    };

    var extractPrintCopyData = function (receiptCopyData = '') {
        let arr = [];

        if (receiptCopyData) {
            arr = receiptCopyData.replace(/\n/g, '').split(/\^\d/g).filter((x) => x.trim());

            // remove header (first 3 lines)
            arr.splice(0, 3);
            // remove receipt type from footer (last 1 lines)
            arr.splice(-1, 1);

            arr.forEach(function (x, index) {
                let line = x.trim();

                let space = ' ';
                let el = {
                    format: ['center'],
                    data: ['', '']
                };

                let splitData, leftString, rightString, align;

                if (line) {
                    splitData = line.split(space.repeat(3));

                    leftString = splitData[0] || '';
                    rightString = (splitData.length > 1) ? splitData.splice(1).join('') : '';
                    align = (!rightString.trim() || rightString.includes('*'.repeat(3))) ? 'center' : 'justify';

                    el.format[0] = align;
                    el.data[0] = leftString.trim();
                    el.data[1] = rightString.trim();
                }

                arr[index] = el;
            });
        }

        return arr;
    };

    var getLastTransactionRaw = function (rawResponse) {
        let lastRawResponse = '';
        let header = '<?xml version="1.0" encoding="utf-8"?>' + CRLF;
        let rawData = rawResponse.split('<RawData>');

        if (rawData.length > 1) {
            lastRawResponse = header + rawData[1].split('</RawData>')[0];
        }

        return lastRawResponse;
    };

    var parseResponse = function (xml) {
        let xml2JsonObj = convert.xml2js(xml, {compact: true});

        return {
            paymentProcessor: 'equinox-epos',

            terminalId: extract(xml2JsonObj, ['Log', 'Response', 'TerminalID'], '_text'),
            transactionId: extract(xml2JsonObj, ['Log', 'Response', 'Trace'], '_text'),
            transactionDateTime: toTransactionDateTime(extract(xml2JsonObj, ['Log', 'Response', 'Date'], '_text'), extract(xml2JsonObj, ['Log', 'Response', 'Time'], '_text')),
            transactionAmount: extract(xml2JsonObj, ['Log', 'Response', 'Amount'], '_text') ? parseFloat(Number(extract(xml2JsonObj, ['Log', 'Response', 'Amount'], '_text')) / 100).toFixed(2) : '0.00',
            totalAmount: extract(xml2JsonObj, ['Log', 'Response', 'TotalAmount'], '_text') ? parseFloat(Number(extract(xml2JsonObj, ['Log', 'Response', 'TotalAmount'], '_text')) / 100).toFixed(2) : '0.00',
            approvedAmount: extract(xml2JsonObj, ['Log', 'Response', 'TotalAmount'], '_text') ? parseFloat(Number(extract(xml2JsonObj, ['Log', 'Response', 'TotalAmount'], '_text')) / 100).toFixed(2) : '0.00',

            transactionType: extract(xml2JsonObj, ['Log', 'Response', '_attributes'], 'Type'),
            attributeTranId: extract(xml2JsonObj, ['Log', 'Response', '_attributes'], 'TranID'),
            // cardType: extract(xml2JsonObj, ['Log', 'Response', 'CardBrand'], '_text'),
            cardName: extract(xml2JsonObj, ['Log', 'Response', 'CardBrand'], '_text') ? CARD_BIN[extract(xml2JsonObj, ['Log', 'Response', 'CardBrand'], '_text')] : '',
            maskedCardNumber: extract(xml2JsonObj, ['Log', 'Response', 'AccountNumber'], '_text'),
            cardNumber: extract(xml2JsonObj, ['Log', 'Response', 'AccountNumber'], '_text'),
            success: (extract(xml2JsonObj, ['Log', 'Response', 'ResponseCode'], '_text') == '000'),
            batchNum: -1,

            isCredit: !extract(xml2JsonObj, ['Log', 'Response', 'AccountType'], '_text'),
            isDebit: !!extract(xml2JsonObj, ['Log', 'Response', 'AccountType'], '_text'),
            debitAccountType: extract(xml2JsonObj, ['Log', 'Response', 'AccountType'], '_text') ? ACCOUNT_TYPE[extract(xml2JsonObj, ['Log', 'Response', 'AccountType'], '_text')] : '',

            tipAmount: extract(xml2JsonObj, ['Log', 'Response', 'TipAmount'], '_text') ? parseFloat(Number(extract(xml2JsonObj, ['Log', 'Response', 'TipAmount'], '_text')) / 100).toFixed(2) : '0.00',
            cvmResult: extract(xml2JsonObj, ['Log', 'Response', 'EmvCVMR'], '_text'),
            emvAid: extract(xml2JsonObj, ['Log', 'Response', 'EmvAID'], '_text'),
            emvTvr: extract(xml2JsonObj, ['Log', 'Response', 'EmvTVR'], '_text'),
            emvTsi: extract(xml2JsonObj, ['Log', 'Response', 'EmvTSI'], '_text'),
            emvAppLabel: extract(xml2JsonObj, ['Log', 'Response', 'EmvAppLabel'], '_text'),
            emvAppPreferredName: extract(xml2JsonObj, ['Log', 'Response', 'EmvAppName'], '_text'),
            emvCryptogram: extract(xml2JsonObj, ['Log', 'Response', 'EmvAC'], '_text'),
            emvCryptogramType: extract(xml2JsonObj, ['Log', 'Response', 'EmvAcType'], '_text'),

            hostResponseCode: extract(xml2JsonObj, ['Log', 'Response', 'HostResponseCode'], '_text'),
            statusLine: extract(xml2JsonObj, ['Log', 'Response', 'TranStatusMerch'], '_text'),
            cardBalance: null,
            demoMode: false,
            formFactor: null,
            showCustomerSignatureLine: (extract(xml2JsonObj, ['Log', 'Response', 'SignatureRequired'], '_text') == 'Y'),

            customerLanguage: (extract(xml2JsonObj, ['Log', 'Response', 'CardLanguageCode'], '_text') != '1') ? 'fr' : 'en',
            merchantId: extract(xml2JsonObj, ['Log', 'Response', 'MerchantNumber'], '_text'),
            referenceNumber: extract(xml2JsonObj, ['Log', 'Response', 'RRN'], '_text'),
            cardEntryMethod: extract(xml2JsonObj, ['Log', 'Response', 'CardEntryMethod'], '_text') ? CARD_ENTRY_MENTHOD[extract(xml2JsonObj, ['Log', 'Response', 'CardEntryMethod'], '_text').trim()] : '',
            authorizationNumber: extract(xml2JsonObj, ['Log', 'Response', 'AuthCode'], '_text'),
            transactionSequenceNum: extract(xml2JsonObj, ['Log', 'Response', 'RefNumber'], '_text'),
            verifiedByPin: extract(xml2JsonObj, ['Log', 'Response', 'PinVerified'], '_text') == 'Y',

            // error checking purpose
            ResponseCode: extract(xml2JsonObj, ['Log', 'Response', 'ResponseCode'], '_text'),
            AppStatusCode: extract(xml2JsonObj, ['Log', 'Response', 'AppStatusCode'], '_text'),

            partiallyApproved: '',
            balanceDue: '0.00',
            customerCopy: extractPrintCopyData(extract(xml2JsonObj, ['Log', 'Response', 'CustomerReceiptText'], '_text')),
            merchantCopy: extractPrintCopyData(extract(xml2JsonObj, ['Log', 'Response', 'MerchantReceiptText'], '_text')),

            // autoSettle data
            batchStatus: extract(xml2JsonObj, ['Log', 'Response', 'BatchStatus'], '_text'), // 0 – balanced, 1 – out of balance, 2 – unknown
            hostCount: extract(xml2JsonObj, ['Log', 'Response', 'HostCount'], '_text') ? extract(xml2JsonObj, ['Log', 'Response', 'HostCount'], '_text') : 0,
            hostNet: extract(xml2JsonObj, ['Log', 'Response', 'HostNet'], '_text') ? extract(xml2JsonObj, ['Log', 'Response', 'HostNet'], '_text') : 0,
            terminalCount: extract(xml2JsonObj, ['Log', 'Response', 'TerminalCount'], '_text') ? extract(xml2JsonObj, ['Log', 'Response', 'TerminalCount'], '_text') : 0,
            terminalNet: extract(xml2JsonObj, ['Log', 'Response', 'TerminalNet'], '_text') ? extract(xml2JsonObj, ['Log', 'Response', 'TerminalNet'], '_text') : 0,

            // terminal reports printout text
            printOutText: extract(xml2JsonObj, ['Log', 'Response', 'PrintOutText'], '_text'),
            isBusy: extract(xml2JsonObj, ['Log', 'Response', 'ResponseCode'], '_text') == '055',
            // partiallyApproved: !(extract(xml2JsonObj, ['Log', 'Response', 'HostResponseCode'], '_text') != '010')
        };
    };

    var extract = function (xml2JsonObj, depth = [], type) {
        let property = xml2JsonObj;

        for (let i = 0; i < depth.length; i++) {
            let key = depth[i];

            if (!property[key]) {
                return '';
            }

            property = property[key];
        }

        return property[type];
    };

    var randomStringGenerator = function (length, chars) {
        let result = '';
        const ALPHABET_CHAR = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

        let alpCharsExists = /[a-zA-z]/.test(chars);
        chars = alpCharsExists ? chars : (chars + ALPHABET_CHAR);

        for (let i = 0; i < length; i++) {
            result += chars[Math.floor(Math.random() * chars.length)];
        }

        return result;
    };

    var EquinoxEPOS = {
        purchasePayload: function (amountInCents, transactionId, printReceipt, refNum) {
            let payload = '<?xml version="1.0" encoding="utf-8"?>' + CRLF
                    + '<Request Type="' + TRANSACTION_TYPE.PURCHASE + '" TranID="' + transactionId + '" ' + 'Version="1.0">' + CRLF
                        + '<Amount>' + amountInCents + '</Amount>' + CRLF
                        + '<Invoice></Invoice>' + CRLF
                        + '<OperatorID></OperatorID>' + CRLF
                        + '<OrderID>' + transactionId + '</OrderID>' + CRLF
                        + '<Receipt>' + printReceipt + '</Receipt>' + CRLF
                        + '<RefNumber>' + refNum + '</RefNumber>' + CRLF
                    + '</Request>';

            return payload;
        },
        voidPayload: function (transactionId, trace, last4Digits, printReceipt, refNum) {
            let payload = '<?xml version="1.0" encoding="utf-8"?>' + CRLF
                    + '<Request Type="' + TRANSACTION_TYPE.VOID + '" TranID="' + transactionId + '" ' + 'Version="1.0">' + CRLF
                        + '<Trace>' + trace + '</Trace>' + CRLF
                        + '<Last4DigitsOfPan>' + last4Digits + '</Last4DigitsOfPan>' + CRLF
                        + '<Receipt>' + printReceipt + '</Receipt>' + CRLF
                        + '<RefNumber>' + refNum + '</RefNumber>' + CRLF
                    + '</Request>';

            return payload;
        },
        returnPayload: function (transactionId, amountInCents, printReceipt, refNum) {
            let payload = '<?xml version="1.0" encoding="utf-8"?>' + CRLF
                    + '<Request Type="' + TRANSACTION_TYPE.REFUND + '" TranID="' + transactionId + '" ' + 'Version="1.0">' + CRLF
                        + '<Amount>' + amountInCents + '</Amount>' + CRLF
                        + '<Receipt>' + printReceipt + '</Receipt>' + CRLF
                        + '<RefNumber>' + refNum + '</RefNumber>' + CRLF
                    + '</Request>';

            return payload;
        },
        lastTransaction: function () {
            let payload = '<?xml version="1.0" encoding="utf-8"?>' + CRLF
                + '<Request Type="' + TRANSACTION_TYPE.LAST_TRANSACTION + '" ' + 'Version="1.0">' + CRLF
                        // + '<RefNumber>' + refNum + '</RefNumber>' + CRLF // optional field
                + '</Request>';

            return payload;
        },
        switchToContactChip: function (transactionId, complete) {
            let inputStatus = complete ? 1 : 0;
            let payload = '<?xml version="1.0" encoding="utf-8"?>' + CRLF
                + '<Request Type="' + TRANSACTION_TYPE.PURCHASE + '" ' + 'TranID="' + transactionId + '" ' + 'Version="1.0">' + CRLF
                    + '<PosInput Reason="SwitchToContactChip">' + CRLF
                        + '<PosInputStatus>' + inputStatus + '</PosInputStatus>' + CRLF
                        + '<InputTimeout>60</InputTimeout>' + CRLF
                    + '</PosInput>' + CRLF
                + '</Request>';

            return payload;
        },
        dayClose: function (forceClose) {
            let inputStatus = !forceClose ? 1 : 0;
            let payload = '<?xml version="1.0" encoding="utf-8"?>' + CRLF
            + '<Request Type="' + TRANSACTION_TYPE.DAY_CLOSE + '" ' + 'Version="1.0">' + CRLF
                + '<CloseOption>' + inputStatus + '</CloseOption>' + CRLF
            + '</Request>';

            return payload;
        },
        dayCloseReport: function () {
            let payload = '<?xml version="1.0" encoding="utf-8"?>' + CRLF
            + '<Request Type="' + TRANSACTION_TYPE.DAY_CLOSE_REPORT + '" ' + 'Version="1.0">' + CRLF
                + '<PrintOption>0</PrintOption>' + CRLF
            + '</Request>';

            return payload;
        },
        dayReport: function (type) {
            let payload = '<?xml version="1.0" encoding="utf-8"?>' + CRLF
            + '<Request Type="' + TRANSACTION_TYPE.DAY_REPORT + '" ' + 'Version="1.0">' + CRLF
                + '<ReportType>' + type + '</ReportType>' + CRLF
                + '<PrintOption>0</PrintOption>' + CRLF // 0 - don't print on terminal, 1 - print on terminal
            + '</Request>';

            return payload;
        },
    };

    freshideas.factory('CardTerminalEquinoxEPOS', [
        '$q',
        'Base64',
        'ErrorLoggingService',
        'EnvConfig',
        function (
            $q,
            Base64,
            ErrorLoggingService,
            EnvConfig
        ) {
            let config = {};
            let alreadyNotified = false;
            // let tcpClient;

            let init = function (terminalConfig) {
                if (!terminalConfig) {
                    console.error('Card Terminal is not configured properly');
                }

                /* terminalConfig => {
                    config: "epos",
                    ip: "10.0.0.228",
                    port: "80", // defaults: 80 (HTTP) & 443 (HTTPS)
                    name: "Equinox-EPOS (TD Luxe)",
                    password: "EpgoyUg3",
                    port: undefined,
                    type: "equinox-epos",
                    username: "noire",
                }
                */
                config = terminalConfig;
                config.base64EncodedToken = Base64.encode(`${config.username}:${config.password}`);
                config.printReceipt = (config.printReceipt) ? config.printReceipt : false;
                config.isTesting = (config.isTesting) ? config.isTesting : false;
            };

            // commented is TCP connection code
            // let initializeSocket = function () {
            //     const net = nodeRequire('net');

            //     if (!tcpClient) {
            //         tcpClient = new net.Socket();

            //         tcpClient.on('end', function () {
            //             console.log('[equinox-epos][end] connection ended');
            //             tcpClient = null;
            //         });

            //         tcpClient.on('close', function (hadErrors) {
            //             console.log('[equinox-epos][close] had any transmission errors!!', hadErrors);
            //             tcpClient = null;
            //         });

            //         return new Promise(function (resolve, reject) {
            //             tcpClient.connect({port: config.port, host: config.ip}, function () {
            //                 console.log('[equinox-epos][connect] connection successful!!');
            //                 tcpClient.setTimeout(60000);
            //                 resolve();
            //             });

            //             tcpClient.on('error', function (err) {
            //                 console.error('[equinox-epos][error] there was an error:', err);
            //                 tcpClient = null;
            //                 reject(err);
            //             });
            //         });
            //     }

            //     return Promise.resolve();
            // };

            // let sendRequest = function (payload, type = '') {
            //     let request = requestBuilder(payload, config);
            //     resetSharedFields();// reset variable

            //     return new Promise(function (resolve, reject) {
            //         tcpClient.write(Buffer.from(request));

            //         tcpClient.on('data', function (data) {
            //             let response = getAllBytes(data);

            //             if (response.length > 0) {
            //                 let parsedResponse = parseHttpResponse(response, payload);

            //                 if (Number(parsedResponse.statusCode) != 200) {
            //                     reject({});
            //                 } else {
            //                     let xmlParsedResponse;

            //                     try {
            //                         if (type === TRANSACTION_TYPE.LAST_TRANSACTION) {
            //                             let lastTxnRawData = getLastTransactionRaw(parsedResponse.data);
            //                             parsedResponse.data = lastTxnRawData;
            //                         }

            //                         xmlParsedResponse = parseResponse(parsedResponse.data);
            //                     } catch (e) {
            //                         console.error('[equinox-epos] error while parsing xml2json', e);
            //                         console.error('[equinox-epos] raw response: ', response);
            //                         // On parsing error we don't know whether transaction is approved or not
            //                         // So, let cashier confirm the transaction as approved or rejected
            //                         reject({timedOut: true});
            //                     }

            //                     resolve([xmlParsedResponse, parsedResponse.data]);
            //                 }
            //             }

            //         });

            //         tcpClient.on('timeout', function () {
            //             console.error('Terminal timeout');
            //             reject({timedOut: true});
            //         });
            //     });
            // };

            let sendRequest = function (payload, type = '') {
                let https = nodeRequire('https');
                let http = nodeRequire('http');

                // for testing just enable "isTesting" flag in DB and updated corresponding port
                let socket = (config.port !== '443') ? http : https;
                let options = {
                    host: config.ip,
                    port: config.port,
                    path: RESOURCE,
                    method: 'POST', // always
                    headers: {
                        'Authorization': 'Basic ' + config.base64EncodedToken,
                        'Content-Type': 'text/xml',
                        'Content-Length': payload.length
                    },
                };

                // for testing accept self-signed certificate from server
                if (config.isTesting) {
                    options.rejectUnauthorized = false;
                }

                resetSharedFields(); // reset variable

                return $q(function (resolve, reject) {
                    let request = socket.request(options, function (response) {
                        let completeData = '';
                        responseContentLength = response.headers['content-length'];

                        response.on('data', function (DataBuffer) {
                            let allResponseData = getAllBytes(DataBuffer);

                            if (allResponseData.length) {
                                if (response.statusCode != 200) {
                                    reject({});
                                } else {
                                    let xmlParsedResponse;

                                    try {
                                        if (type === TRANSACTION_TYPE.LAST_TRANSACTION) {
                                            let lastTxnRawData = getLastTransactionRaw(allResponseData);
                                            allResponseData = lastTxnRawData; // override with last response
                                        }

                                        // add request payload to response data
                                        completeData = makeCompleteData(payload, allResponseData);

                                        xmlParsedResponse = parseResponse(completeData);
                                        xmlParsedResponse.terminalResponse = allResponseData;
                                    } catch (e) {
                                        console.error('[equinox-epos] error while parsing xml2json', e);
                                        console.error('[equinox-epos] raw response: ', allResponseData);
                                        // On parsing error we don't know whether transaction is approved or not
                                        // So, let cashier confirm the transaction as approved or rejected
                                        reject({timedOut: true});
                                    }

                                    resolve([xmlParsedResponse, completeData]);
                                }
                            }

                        });
                    });

                    request.on('error', function (err) {
                        console.error('[equinox-epos][error] there was an error connecting to terminal:', err);
                        reject(err);
                        request.destroy();
                    });

                    request.on('timeout', function () {
                        console.error('[equinox-epos][error] terminal connection timeout');
                        reject({timedOut: true});
                        request.destroy();
                    });

                    request.on('close', function () {
                        request.removeAllListeners();
                        resetSharedFields(); // reset variable
                    });

                    request.write(payload);
                    request.end();
                });
            };

            let lastTransactionStatus = async function () {
                // await initializeSocket();

                let payload = EquinoxEPOS.lastTransaction();

                return $q(function (resolve, reject) {
                    sendRequest(payload, TRANSACTION_TYPE.LAST_TRANSACTION).then(function (responseArray) {
                        let parsedResponse = responseArray[0];

                        if (parsedResponse.ResponseCode != '000') {
                            parsedResponse.approvedAmount = '0.00';
                            parsedResponse.tipAmount = '0.00';
                        }

                        resolve(responseArray);
                    }, function (err) {
                        console.error('[equinox-epos][lastTransactionStatus] error while getting last transaction status: ', err);
                        reject(err);
                    });
                });
            };

            let creditSale = function (amount, transactionObj) {
                // for TCP Connnection
                // await initializeSocket();

                let amountInCents = parseFloat(amount * 100).toFixed(0);
                let transactionId = randomStringGenerator(20, `${transactionObj.transactionId}${Date.now()}${amountInCents}`);
                let refNum = transactionId.replace(/[^a-zA-Z0-9]/g, '');
                let printReceipt = config.printReceipt;

                // generate credit sale request payload
                let payload = EquinoxEPOS.purchasePayload(amountInCents, transactionId, printReceipt, refNum);

                return $q(function (resolve, reject) {
                    sendRequest(payload).then(function (responseArray) {
                        let parsedResponse = responseArray[0];
                        let printReceipt = (parsedResponse.merchantCopy.length || parsedResponse.customerCopy.length) ? true : false;

                        if (parsedResponse.ResponseCode && parsedResponse.ResponseCode != '000') {
                            // console.error('[equinox-epos][creditSale] error response code: ' + parsedResponse.ResponseCode
                            //     + ', message: ' + RESPONSE_CODES[parsedResponse.ResponseCode]);

                            ErrorLoggingService.log({
                                message: RESPONSE_CODES[parsedResponse.ResponseCode],
                                data: responseArray[1]
                            });

                            switch (parsedResponse.ResponseCode) {
                                // handling multi part transaction
                                case '999':
                                    // console.log('[equinox-epos][creditSale] multi part transaction, response: ', responseArray[1]);
                                    reject({notifyUser: 'Swipe or Insert the card to complete transaction', data: responseArray, multiPart: true, printReceipt: printReceipt});
                                break;
                                default:
                                    reject({notifyUser: RESPONSE_CODES[parsedResponse.ResponseCode], data: responseArray, printReceipt: printReceipt});
                            }

                        } else if (parsedResponse.AppStatusCode && parsedResponse.AppStatusCode != '000000') {
                            // console.error('[equinox-epos][creditSale] terminal response: ', responseArray);
                            ErrorLoggingService.log({
                                message: APP_STATUS_CODE[parsedResponse.AppStatusCode],
                                data: responseArray[1]
                            });

                            responseArray.push({notifyUser: APP_STATUS_CODE[parsedResponse.AppStatusCode], alreadyNotified: alreadyNotified});

                            resolve(responseArray);
                            alreadyNotified = true;
                        } else {
                            resolve(responseArray);
                        }

                    }, function (err) {
                        // console.error('[equinox-epos][creditSale] error while performing credit sale: ', err);
                        reject(err);
                    });
                });
            };

            let creditVoid = function (trace, transactionObj) {
                // for TCP connection
                // await initializeSocket();

                let tenderToVoid = transactionObj.tenderToVoid;
                let parsedResponse = tenderToVoid._parsedResponse;
                let last4Digits = parsedResponse.maskedCardNumber.slice(-4);

                let transactionId = randomStringGenerator(20, `${trace}${Date.now()}${last4Digits}`);
                let refNum = transactionId.replace(/[^a-zA-Z0-9]/g, '');
                let printReceipt = config.printReceipt;

                let payload = EquinoxEPOS.voidPayload(transactionId, trace, last4Digits, printReceipt, refNum);

                return $q(function (resolve, reject) {
                    sendRequest(payload).then(function (responseArray) {
                        let parsedResponse = responseArray[0];
                        let printReceipt = (parsedResponse.merchantCopy.length || parsedResponse.customerCopy.length) ? true : false;

                        if (parsedResponse.ResponseCode && parsedResponse.ResponseCode != '000') {
                            let errorMessage = '[equinox-epos][creditVoid] error response code: ' + parsedResponse.ResponseCode
                            + ', message: ' + RESPONSE_CODES[parsedResponse.ResponseCode];

                            ErrorLoggingService.log({
                                message: RESPONSE_CODES[parsedResponse.ResponseCode],
                                data: responseArray[1]
                            });

                            switch (parsedResponse.ResponseCode) {
                                // handling multi part transaction
                                case '999':
                                    console.log('[equinox-epos][creditVoid] multi part transaction, response: ', responseArray[1]);
                                    reject({notifyUser: 'Swipe or Insert the card to complete transaction', data: responseArray, multiPart: true, printReceipt: printReceipt});
                                break;
                                default:
                                    reject(new Error(errorMessage));
                            }

                        } else if (parsedResponse.AppStatusCode && parsedResponse.AppStatusCode != '000000') {
                            let errorMessage = '[equinox-epos][creditVoid] error response code: ' + parsedResponse.AppStatusCode
                            + ', message: ' + APP_STATUS_CODE[parsedResponse.ResponseCode];

                            ErrorLoggingService.log({
                                message: APP_STATUS_CODE[parsedResponse.ResponseCode],
                                data: responseArray[1]
                            });

                            console.error(errorMessage);
                            responseArray.push({notifyUser: APP_STATUS_CODE[parsedResponse.AppStatusCode], alreadyNotified: alreadyNotified});

                            resolve(responseArray);
                            alreadyNotified = true;
                        } else {
                            resolve(responseArray);
                        }

                    }, function (err) {
                        console.error('[equinox-epos][creditVoid] error while performing void sale: ', err);
                        reject(err);
                    });
                });
            };

            let creditReturn = function (amountToRefund, transactionObj) {
                // for TCP connection
                // await initializeSocket();

                let amountInCents = parseFloat(amountToRefund * 100).toFixed(0);

                let transactionId = randomStringGenerator(20, `${Date.now()}${amountInCents}`);
                let refNum = transactionId.replace(/[^a-zA-Z0-9]/g, '');
                let printReceipt = config.printReceipt;

                let payload = EquinoxEPOS.returnPayload(transactionId, amountInCents, printReceipt, refNum);

                return $q(function (resolve, reject) {
                    sendRequest(payload).then(function (responseArray) {
                        let parsedResponse = responseArray[0];
                        let printReceipt = (parsedResponse.merchantCopy.length || parsedResponse.customerCopy.length) ? true : false;

                        if (parsedResponse.ResponseCode && parsedResponse.ResponseCode != '000') {
                            let errorMessage = '[equinox-epos][creditReturn] error response code: ' + parsedResponse.ResponseCode
                            + ', message: ' + RESPONSE_CODES[parsedResponse.ResponseCode];

                            ErrorLoggingService.log({
                                message: RESPONSE_CODES[parsedResponse.ResponseCode],
                                data: responseArray[1]
                            });

                            switch (parsedResponse.ResponseCode) {
                                // handling multi part transaction
                                case '999':
                                    console.log('[equinox-epos][creditReturn] multi part transaction, response: ', responseArray[1]);
                                    reject({notifyUser: 'Swipe or Insert the card to complete transaction', data: responseArray, multiPart: true, printReceipt: printReceipt});
                                break;
                                default:
                                    reject(new Error(errorMessage));
                            }

                        } else if (parsedResponse.AppStatusCode && parsedResponse.AppStatusCode != '000000') {
                            let errorMessage = '[equinox-epos][creditReturn] error response code: ' + parsedResponse.AppStatusCode
                            + ', message: ' + APP_STATUS_CODE[parsedResponse.ResponseCode];

                            ErrorLoggingService.log({
                                message: APP_STATUS_CODE[parsedResponse.ResponseCode],
                                data: responseArray[1]
                            });

                            console.error(errorMessage);
                            responseArray.push({notifyUser: APP_STATUS_CODE[parsedResponse.AppStatusCode], alreadyNotified: alreadyNotified});

                            resolve(responseArray);
                            alreadyNotified = true;
                        } else {
                            resolve(responseArray);
                        }

                    }, function (err) {
                        console.error('[equinox-epos][creditReturn] error while performing credit return sale: ', err);
                        reject(err);
                    });
                });
            };

            let debitSale = function (amount) {
                let amountInCents = parseFloat(amount * 100).toFixed(0);

                return creditSale(amountInCents, {transactionUuid: ''});
            };

            let testSale = function (amount = '1.00') {
                return creditSale(amount, {transactionUuid: ''});
            };

            let isCancellableFromPos = function () {
                return false;
            };

            let autoSettle = function (forceClose = true) {
                let payload = EquinoxEPOS.dayClose(forceClose);

                return $q(function (resolve, reject) {
                    sendRequest(payload).then(function (responseArray) {
                        let parsedResponse = responseArray[0];
                        let result = {
                            isBalanced: (parsedResponse.batchStatus === '0' || parsedResponse.ResponseCode === '000'),
                            data: [
                                {label: 'Host Transaction Count', value: parsedResponse.hostCount},
                                {label: 'Host Net Amount', value: '$' + parseFloat(parsedResponse.hostNet / 100).toFixed(2)},
                                {label: 'Terminal Transaction Count', value: parsedResponse.terminalCount},
                                {label: 'Terminal Net Amount', value: '$' + parseFloat(parsedResponse.terminalNet / 100).toFixed(2)}
                            ]
                        };

                        if (parsedResponse.ResponseCode && parsedResponse.ResponseCode != '000') {
                            let errorMessage = '[equinox-epos][autoSettle] error response code: ' + parsedResponse.ResponseCode
                                + ', message: ' + RESPONSE_CODES[parsedResponse.ResponseCode];

                            ErrorLoggingService.log({
                                message: RESPONSE_CODES[parsedResponse.ResponseCode],
                                data: responseArray[1]
                            });

                            console.error(errorMessage);
                        } else if (parsedResponse.AppStatusCode && parsedResponse.AppStatusCode != '000000') {
                            let errorMessage = '[equinox-epos][autoSettle] error response code: ' + parsedResponse.AppStatusCode
                                + ', message: ' + APP_STATUS_CODE[parsedResponse.AppStatusCode];

                            ErrorLoggingService.log({
                                message: APP_STATUS_CODE[parsedResponse.AppStatusCode],
                                data: responseArray[1]
                            });

                            console.error(errorMessage);
                            result.notifyUser = APP_STATUS_CODE[parsedResponse.AppStatusCode];
                        }

                        resolve(result);
                    }, function (err) {
                        reject(err);
                        console.error('[equinox-epos][autoSettle] error settling terminal: ', err);
                    });
                });
            };

            let completeMultiPartTransaction = function (transactionObj, complete = true) {
                let transactionId = transactionObj.transactionId;
                let payload = EquinoxEPOS.switchToContactChip(transactionId, complete);

                return $q(function (resolve, reject) {
                    sendRequest(payload).then(function (responseArray) {
                        let parsedResponse = responseArray[0];
                        let printReceipt = (parsedResponse.merchantCopy.length || parsedResponse.customerCopy.length) ? true : false;

                        if (parsedResponse.ResponseCode && parsedResponse.ResponseCode != '000') {
                            console.error('[equinox-epos][completeMultiPartTransaction] error response code: ' + parsedResponse.ResponseCode
                                + ', message: ' + RESPONSE_CODES[parsedResponse.ResponseCode]);

                            ErrorLoggingService.log({
                                message: RESPONSE_CODES[parsedResponse.ResponseCode],
                                data: responseArray[1]
                            });

                            switch (parsedResponse.ResponseCode) {
                                // handling multi part transaction
                                case '999':
                                    console.log('[equinox-epos][completeMultiPartTransaction] multi part transaction, response: ', responseArray[1]);
                                    reject({notifyUser: 'Swipe or Insert the card to complete transaction', data: responseArray, multiPart: true, printReceipt: printReceipt});
                                break;
                                default:
                                    reject({notifyUser: RESPONSE_CODES[parsedResponse.ResponseCode], data: responseArray, printReceipt: printReceipt});
                            }

                        } else if (parsedResponse.AppStatusCode && parsedResponse.AppStatusCode != '000000') {
                            console.error('[equinox-epos][completeMultiPartTransaction] error response code: ' + parsedResponse.AppStatusCode
                                + ', message: ' + APP_STATUS_CODE[parsedResponse.AppStatusCode]);

                            ErrorLoggingService.log({
                                message: APP_STATUS_CODE[parsedResponse.AppStatusCode],
                                data: responseArray[1]
                            });

                            resolve(responseArray, {notifyUser: APP_STATUS_CODE[parsedResponse.AppStatusCode], alreadyNotified: alreadyNotified});
                            alreadyNotified = true;
                        } else {
                            resolve(responseArray);
                        }

                    }, function (err) {
                        console.error('[equinox-epos][completeMultiPartTransaction] error while completing multi part transaction: ', err);
                        reject(err);
                    });
                });
            };

            // terminal reports
            let dayReport = function (type = 'SUBTOTALS') {
                let payload;

                payload = (type != 'DayCloseReport') ? EquinoxEPOS.dayReport(REPORT_TYPE[type]) : EquinoxEPOS.dayCloseReport();

                return $q(function (resolve, reject) {
                    sendRequest(payload).then(function (responseArray) {
                        let parsedResponse = responseArray[0];
                        let result = extractPrintOutText(parsedResponse.terminalResponse);

                        if (parsedResponse.ResponseCode && parsedResponse.ResponseCode != '000') {
                            let errorMessage = '[equinox-epos][dayReport] error response code: ' + parsedResponse.ResponseCode
                                + ', message: ' + RESPONSE_CODES[parsedResponse.ResponseCode];

                            ErrorLoggingService.log({
                                message: RESPONSE_CODES[parsedResponse.ResponseCode],
                                data: responseArray[1]
                            });

                            console.error(errorMessage);
                        } else if (parsedResponse.AppStatusCode && parsedResponse.AppStatusCode != '000000') {
                            let errorMessage = '[equinox-epos][dayReport] error app status code: ' + parsedResponse.AppStatusCode
                                + ', message: ' + APP_STATUS_CODE[parsedResponse.AppStatusCode];

                            ErrorLoggingService.log({
                                message: APP_STATUS_CODE[parsedResponse.AppStatusCode],
                                data: responseArray[1]
                            });

                            console.error(errorMessage);
                        }

                        resolve(result);
                    }, function (err) {
                        console.error('[equinox-epos][dayReport] error while getting day report from terminal: ', err);
                        reject(err);
                    });
                });
            };

            let extractPrintOutText = function (xmlData) {
                let result = [];
                let space = ' ';
                let printOutText = extract(convert.xml2js(xmlData, {compact: true}), ['Response', 'PrintOutText'], '_text');

                let processed = printOutText.split('\n').map((x) => {
                    let line = {
                        format: ['center'],
                        data: ['', '']
                    };

                    if (x) {
                        let data = x.split(space.repeat(5)).filter((x) => x.trim());
                        line.format[0] = (data.length > 1) ? 'justify' : 'center';

                        // more than 2 element then format to two elements
                        if (data.length > 2) {
                            let lastTwoElems = data.splice(-2).join(space.repeat(2));
                            // remaining elemets joins and make into one string
                            let firstElems = data.splice(0).join(space.repeat(2));

                            data[0] = firstElems;
                            data[1] = lastTwoElems;
                        }

                        line.data[0] = data[0] ? data[0] : '';
                        line.data[1] = data[1] ? data[1] : '';

                        return line;
                    }
                });

                result.push(...processed.filter((x) => x));
                return result;
            };

            return {
                init: init,
                creditSale: creditSale,
                creditVoid: creditVoid,
                creditReturn: creditReturn,
                debitSale: debitSale,
                testSale: testSale,
                autoSettle: autoSettle,
                parseResponse: parseResponse,
                isCancellableFromPos: isCancellableFromPos,
                lastTransactionStatus: lastTransactionStatus,
                completeMultiPartTransaction: completeMultiPartTransaction,
                dayReport: dayReport,
                // cancelFromPos: cancelFromPos
            };
        }
    ]);

    freshideas.factory('iOSCardTerminalEquinoxEpos', [
        '$q',
        '$timeout',
        'Base64',
        'IosTcpClientV2',
        'ErrorLoggingService',
        function (
            $q,
            $timeout,
            Base64,
            IosTcpClientV2,
            ErrorLoggingService
        ) {
            let config;
            let client = null;

            let init = function (terminalConfig) {
                if (!terminalConfig) {
                    console.error('Card Terminal is not configured properly');
                }

                /* terminalConfig => {
                    config: "epos",
                    ip: "10.0.0.228",
                    port: "80", // defaults: 80 (HTTP) & 443 (HTTPS)
                    name: "Equinox-EPOS (TD Luxe)",
                    password: "EpgoyUg3",
                    port: undefined,
                    type: "equinox-epos",
                    username: "noire"
                }
                */
                config = terminalConfig;
                config.base64EncodedToken = Base64.encode(`${config.username}:${config.password}`);
                config.printReceipt = (config.printReceipt) ? config.printReceipt : false;
            };

            let saleInProgress = false;
            let timeOutWrapper = function (f, timedOut) {
                return function (res, rej) {
                    let timeout = null;

                    if (saleInProgress) {
                        console.error('Multiple sales initiated in parallel!');
                    }
                    saleInProgress = true;

                    let resolve = function (x) {
                        cancelTimeout();
                        saleInProgress = false;
                        res(x);
                    };

                    let reject = function (x) {
                        cancelTimeout();
                        saleInProgress = false;
                        rej(x);
                    };

                    let createTimeout = function () {
                        if (timeout) {
                            cancelTimeout();
                        }

                        timeout = $timeout(function () {
                            timedOut(reject);
                        }, 60000);
                    };

                    let cancelTimeout = function () {
                        if (timeout) {
                            $timeout.cancel(timeout);
                            timeout = null;
                        }
                    };

                    try {
                        f(resolve, reject, createTimeout);
                    } catch (e) {
                        ErrorLoggingService.log(e);
                        reject(e);
                    }
                };
            };

            let initializeSocket = function () {
                let didResolveOrReject = false;
                let timedOut = function (reject) {
                    if (!didResolveOrReject) {
                        reject({timedOut: true});
                    }
                    // if resolved or rejected nothing to do
                };

                if (!client) {
                    return new Promise(timeOutWrapper(function (resolve, reject, createTimeout) {
                        IosTcpClientV2.connect(config.port, config.ip).then(function (iosTcpClient) {
                            didResolveOrReject = true;
                            createTimeout();

                            client = iosTcpClient;

                            client.on('close', function () {
                                client = null;
                            });

                            client.on('end', function () {
                                client = null;
                            });

                            resolve(client);
                        }).catch(function (error) {
                            didResolveOrReject = true;

                            ErrorLoggingService.log(error);
                            console.error('Terminal read error');

                            reject(error.toString());
                        });
                    }, timedOut));
                }

                return Promise.resolve();
            };

            let sendRequest = function (payload, type = '') {
                let request = requestBuilder(payload, config);
                resetSharedFields();// reset variable

                let didResolveOrReject = false;
                let timedOut = function (reject) {
                    if (!didResolveOrReject) {
                        reject({timedOut: true});
                    }
                    // if resolved or rejected nothing to do
                };

                return new Promise(timeOutWrapper(function (resolve, reject, createTimeout) {
                    client.write(Buffer.from(request));

                    client.on('data', function (data, base64Encoded) {
                        createTimeout();

                        try {
                            let response = getAllBytes(data);

                            if (response.length > 0) {
                                let parsedResponse = parseHttpResponse(response, payload);

                                if (Number(parsedResponse.statusCode) != 200) {
                                    didResolveOrReject = true;

                                    reject({});
                                } else {
                                    let xmlParsedResponse;

                                    if (type === TRANSACTION_TYPE.LAST_TRANSACTION) {
                                        let lastTxnRawData = getLastTransactionRaw(parsedResponse.data);
                                        parsedResponse.data = lastTxnRawData;
                                    }

                                    xmlParsedResponse = parseResponse(parsedResponse.data);

                                    didResolveOrReject = true;
                                    resolve([xmlParsedResponse, parsedResponse.data]);
                                }
                            }

                        } catch (error) {
                            didResolveOrReject = true;

                            ErrorLoggingService.log(error);
                            console.error('Terminal read error');

                            reject(error.toString());
                        }
                    });
                }, timedOut));
            };

            let lastTransactionStatus = async function () {
                await initializeSocket();

                let payload = EquinoxEPOS.lastTransaction();

                return $q(function (resolve, reject) {
                    sendRequest(payload, TRANSACTION_TYPE.LAST_TRANSACTION).then(function (responseArray) {
                        let parsedResponse = responseArray[0];

                        if (parsedResponse.ResponseCode != '000') {
                            parsedResponse.approvedAmount = '0.00';
                            parsedResponse.tipAmount = '0.00';
                        }

                        console.log('[equinox-epos][lastTransactionStatus] last transaction response: ' + parsedResponse);
                        resolve(responseArray);
                    }, function (err) {
                        console.error('[equinox-epos][lastTransactionStatus] error while getting last transaction status: ', err);
                        reject(err);
                    });
                });
            };

            let creditSale = async function (amount, transactionObj) {
                await initializeSocket();

                let amountInCents = parseFloat(amount * 100).toFixed(0);
                let transactionId = randomStringGenerator(20, `${transactionObj.transactionId}${Date.now()}${amountInCents}`);
                let refNum = transactionId.replace(/[^a-zA-Z0-9]/g, '');
                let printReceipt = config.printReceipt;

                // generate credit sale request payload
                let payload = EquinoxEPOS.purchasePayload(amountInCents, transactionId, printReceipt, refNum);

                return $q(function (resolve, reject) {
                    sendRequest(payload).then(function (responseArray) {
                        let parsedResponse = responseArray[0];

                        if (parsedResponse.ResponseCode && parsedResponse.ResponseCode != '000') {
                            console.error('[equinox-epos][creditSale] error response code: ' + parsedResponse.ResponseCode
                                + ', message: ' + RESPONSE_CODES[parsedResponse.ResponseCode]);

                            ErrorLoggingService.log({
                                message: RESPONSE_CODES[parsedResponse.ResponseCode],
                                data: responseArray[1]
                            });

                            switch (parsedResponse.ResponseCode) {
                                // handling multi part transaction
                                case '999':
                                    console.log('[equinox-epos][creditSale] multi part transaction, response: ', responseArray[1]);
                                    reject({notifyUser: 'Swipe or Insert the card to complete transaction', data: responseArray, multiPart: true});
                                break;
                                default:
                                    reject({notifyUser: RESPONSE_CODES[parsedResponse.ResponseCode], data: responseArray});
                            }

                        } else if (parsedResponse.AppStatusCode && parsedResponse.AppStatusCode != '000000') {
                            console.error('[equinox-epos][creditSale] error response code: ' + parsedResponse.AppStatusCode
                                + ', message: ' + APP_STATUS_CODE[parsedResponse.AppStatusCode]);
                            reject({notifyUser: RESPONSE_CODES[parsedResponse.AppStatusCode], data: responseArray});
                        } else {
                            resolve(responseArray);
                        }

                    }, function (err) {
                        console.error('[equinox-epos][creditSale] error while performing credit sale: ', err);
                        ErrorLoggingService.log(err);
                        reject(err);
                    });
                });
            };

            let creditVoid = async function (trace, transactionObj) {
                await initializeSocket();

                let tenderToVoid = transactionObj.tenderToVoid;
                let parsedResponse = tenderToVoid._parsedResponse;
                let last4Digits = parsedResponse.maskedCardNumber.slice(-4);

                let transactionId = randomStringGenerator(20, `${Date.now()}${last4Digits}`);
                let refNum = transactionId.replace(/[^a-zA-Z0-9]/g, '');
                let printReceipt = config.printReceipt;

                let payload = EquinoxEPOS.voidPayload(transactionId, trace, last4Digits, printReceipt, refNum);

                return $q(function (resolve, reject) {
                    sendRequest(payload).then(function (responseArray) {
                        let parsedResponse = responseArray[0];

                        if (parsedResponse.ResponseCode && parsedResponse.ResponseCode != '000') {
                            let errorMessage = '[equinox-epos][creditVoid] error response code: ' + parsedResponse.ResponseCode
                            + ', message: ' + RESPONSE_CODES[parsedResponse.ResponseCode];

                            switch (parsedResponse.ResponseCode) {
                                // handling multi part transaction
                                case '999':
                                    console.log('[equinox-epos][creditVoid] multi part transaction, response: ', responseArray[1]);
                                    reject({notifyUser: 'Swipe or Insert the card to complete transaction', data: responseArray, multiPart: true});
                                break;
                                default:
                                    reject(new Error(errorMessage));
                            }

                        } else if (parsedResponse.AppStatusCode && parsedResponse.AppStatusCode != '000000') {
                            let errorMessage = '[equinox-epos][creditVoid] error response code: ' + parsedResponse.AppStatusCode
                            + ', message: ' + APP_STATUS_CODE[parsedResponse.ResponseCode];

                            // console.error(errorMessage);
                            reject(new Error(errorMessage));
                        } else {
                            resolve(responseArray);
                        }
                    }, function (err) {
                        console.error('[equinox-epos][creditVoid] error while performing void sale: ', err);
                        ErrorLoggingService.log(err);
                        reject(err);
                    });
                });
            };

            let creditReturn = async function (amountToRefund, transactionObj) {
                await initializeSocket();

                let amountInCents = parseFloat(amountToRefund * 100).toFixed(0);

                let transactionId = randomStringGenerator(20, `${Date.now()}${amountInCents}`);
                let refNum = transactionId.replace(/[^a-zA-Z0-9]/g, '');
                let printReceipt = config.printReceipt;

                let payload = EquinoxEPOS.returnPayload(transactionId, amountInCents, printReceipt, refNum);

                return $q(function (resolve, reject) {
                    sendRequest(payload).then(function (responseArray) {
                        let parsedResponse = responseArray[0];

                        if (parsedResponse.ResponseCode && parsedResponse.ResponseCode != '000') {
                            let errorMessage = '[equinox-epos][creditReturn] error response code: ' + parsedResponse.ResponseCode
                            + ', message: ' + RESPONSE_CODES[parsedResponse.ResponseCode];

                            switch (parsedResponse.ResponseCode) {
                                // handling multi part transaction
                                case '999':
                                    console.log('[equinox-epos][creditReturn] multi part transaction, response: ', responseArray[1]);
                                    reject({notifyUser: 'Swipe or Insert the card to complete transaction', data: responseArray, multiPart: true});
                                break;
                                default:
                                    reject(new Error(errorMessage));
                            }

                        } else if (parsedResponse.AppStatusCode && parsedResponse.AppStatusCode != '000000') {
                            let errorMessage = '[equinox-epos][creditReturn] error response code: ' + parsedResponse.AppStatusCode
                            + ', message: ' + APP_STATUS_CODE[parsedResponse.ResponseCode];

                            reject(new Error(errorMessage));
                        } else {
                            resolve(responseArray);
                        }

                    }, function (err) {
                        console.error('[equinox-epos][creditReturn] error while performing credit return sale: ', err);
                        ErrorLoggingService.log(err);
                        reject(err);
                    });
                });
            };

            let debitSale = function (amount) {
                let amountInCents = parseFloat(amount * 100).toFixed(0);

                return creditSale(amountInCents, {transactionUuid: ''});
            };

            let testSale = function (amount = '1') {
                let amountInCents = parseFloat(amount * 100).toFixed(0);

                return creditSale(amountInCents, {transactionUuid: ''});
            };

            let isCancellableFromPos = function () {
                return false;
            };

            let autoSettle = function () {
                return new Promise.resolve();
            };

            return {
                init: init,
                creditSale: creditSale,
                creditVoid: creditVoid,
                creditReturn: creditReturn,
                debitSale: debitSale,
                testSale: testSale,
                autoSettle: autoSettle,
                parseResponse: parseResponse,
                isCancellableFromPos: isCancellableFromPos,
                lastTransactionStatus: lastTransactionStatus,
                // cancelFromPos: cancelFromPos
            };
        }
    ]);
}
