'use strict';


module.exports = function (freshideasPosInventoryV2) {
    freshideasPosInventoryV2.controller('InventoryV2ListCtrl', [
        '$scope',
        'InventoryV2Service',
        function ($scope, InventoryV2Service) {

            $scope.stocks = [];

            $scope.getStocks = function () {
                return InventoryV2Service.getStocks()
                    .then($scope.onStocksRetrieved);
            };
            $scope.onStocksRetrieved = function (result) {
                var stockMap = {};
                for (var stock of result) {
                    var menuItem = stock.menuItem || {};
                    var menuItemId = menuItem.locationServicePeriodMenuId;

                    if (!menuItemId) {
                        continue;
                    }

                    stockMap[menuItemId] = stockMap[menuItemId] || {
                        menuItem: menuItem,
                        warehouses: []
                    };

                    stockMap[menuItemId].warehouses.push(stock);
                }

                var stocks = [];
                for (var menuItemIdKey in stockMap) {
                    if (menuItemIdKey) {
                        stocks.push(stockMap[menuItemIdKey]);
                    }
                }

                $scope.stocks = stocks;
            };

            $scope.init = function () {
                $scope.getStocks();
            };
            // n$scope.init();
        }
    ]);
};
