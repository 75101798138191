'use strict';

const angular = require('angular');
const Decimal = require('decimal.js').default;
const SharedDataService = require('../../external/pos.data-service.js');

export default angular
    .module('freshideas.services.pos', [])
    .factory('PosBuilderService', [
        'CashierShift',
        'CommonOfflineCache',
        '$cookies',
        'Pure',
        function (
            CashierShift,
            CommonOfflineCache,
            $cookies,
            Pure
            ) {

            var service = {};

            service.createPosData = function (receipt, locationId,
                day, guestTransaction=false) {
                return {
                    receipt: receipt,
                    deferred: 0,
                    allowedMealEquivalencyPerCycle: 999999999, // day.allowedPerCycle ? day.allowedPerCycle : 0,
                    allowedMealEquivalency: true, // day.enabled && day.active ? true : false,
                    allowedMealEquivalencyDollar: 0.01, // day.allowedDollarAmount ? day.allowedDollarAmount : 0,
                    guestTransaction: guestTransaction,
                    shiftLocationId: locationId,
                    currentOrderBalance: {
                        mealError: false,
                        subTotalAmount: 0,
                        taxAmount: 0,
                        totalAmount: 0,
                        totalDiscount: 0,
                        totalMeals: 0
                    }
                };
            };

            // returns promise
            service.createQuickChargePosData = function (patron, servicePeriodId, locationId) {
                var promise = new Promise(function (resolve, reject) {
                    // Sometimes $scope.shiftStatus is not ready when quick charge is run. In this case,
                    // alert the user and abort the transaction without auto-retry with non-quick-charge
                    // workflow (by setting `skip`: `true` in reject response)
                    if ((!locationId || !servicePeriodId)) {
                        reject({
                            skip: true,
                            alertName: 'transaction-quick-charge-server-busy'
                        });
                    }

                    CashierShift.prepareQuickChargeTransaction({
                        patronId: patron.patronId,
                        servicePeriodId: servicePeriodId
                    }).$promise.then(function (response) {
                        // there should be only 1 item for quick charge transaction
                        var menuItem = response[0];
                        var payload = service.createQuickChargeReceiptItem(menuItem, locationId);

                        if (menuItem) {
                            resolve(payload);
                        } else {
                            reject({});
                        }

                    }).catch(function (error) {
                        var data = error.data || {};
                        var exception = data.exception || {};
                        var appCode = exception.appCode;

                        switch (appCode) {
                            case 407:
                                reject({
                                    skip: true,
                                    alertName: 'no-active-shift'
                                }); // to prevent auto-retry
                                break;
                            default:
                                reject({});
                        }
                    });
                });

                return promise;
            };

            service.createQuickChargeReceiptItem = function (menuItem, locationId) {
                var quantity = 1;

                var price = quantity * menuItem.price;
                var taxAmount = 0;
                var itemTaxAmount = 0;

                if (menuItem.taxRate) {
                    taxAmount = price * menuItem.taxRate;
                    itemTaxAmount = menuItem.price * menuItem.taxRate;
                }

                var receiptItem = {
                    'quantity': quantity,
                    'locationServicePeriodMenuId': menuItem.locationServicePeriodMenuId,
                    'name': menuItem.name,
                    'upc': menuItem.upc,
                    'taxRate': menuItem.taxRate,
                    'taxAmount': taxAmount,
                    'price': price,
                    'total': price + taxAmount,
                    'itemTaxAmount': itemTaxAmount,
                    'itemPrice': menuItem.price,
                    'itemTotalPrice': menuItem.price + itemTaxAmount,
                    'mealPlanAmount': menuItem.mealPlanCount,
                    'mealEquivalencyEnabled': menuItem.mealEquivalencyEnabled,
                    'mealPlanCount': menuItem.mealPlanCount * quantity,
                    'printerId': menuItem.printerId
                };

                return {
                    receipt: [receiptItem],
                    deferred: 0, // default - will be re-calculated using SharedFunctionService.calculateBalances
                    allowedMealEquivalencyPerCycle: false, // assume not allowing meal equivalency for quick charge
                    allowedMealEquivalency: false, // assume not allowing meal equivalency for quick charge
                    allowedMealEquivalencyDollar: 0, // assume not allowing meal equivalency for quick charge
                    guestTransaction: false,
                    shiftLocationId: locationId,
                    currentOrderBalance: {
                        mealError: false, // default - will be re-calculated using SharedFunctionService.calculateBalances
                        subTotalAmount: 0,
                        taxAmount: 0,
                        totalAmount: 0,
                        totalDiscount: 0,
                        totalMeals: 1
                    }
                };
            };

            service.getCachedPatron = function (patronKey) {
                return new Promise(function (resolve, reject) {
                    CommonOfflineCache.getPatronsFromCache().then(function (patrons) {
                        return CommonOfflineCache.getPatron(patronKey, patrons);
                    }).then(function (matchedPatron) {
                        if (matchedPatron) {
                            resolve(matchedPatron);
                        } else {
                            reject({});
                        }
                    }).catch(function (error) {
                        reject(error);
                    });
                });
            };

            service.getCachedBalances = function (patron) {
                // Here we have custom caching logic where we load the data from
                // the browser database instead of the service worker cache. The
                // browser database has a more recent representation of the user's
                // meal balances since we can write to it while offline.
                return new Promise(function (resolve, reject) {
                    CommonOfflineCache.getCurrentLocation().then(function (locationFromCache) {
                        service.getCachedPatron(patron.patronId).then(function (patron) {
                            let mealPlansAfterFilter = [];
                            _.each(patron.mealPlans, function (mealPlan) {
                                let isMealPlanAtCurrentLocation = locationFromCache.mealPlanIds.indexOf(mealPlan.mealPlanId) > -1;
                                let isMealPlanEnabled = mealPlan.enabled && mealPlan.priority > -1;
                                let isPatronStatusAllowed = (patron.status === 0);
                                if (isPatronStatusAllowed && isMealPlanAtCurrentLocation && isMealPlanEnabled) {
                                    mealPlansAfterFilter.push(mealPlan);
                                }
                            });

                            resolve(mealPlansAfterFilter);
                        });
                    }).catch(function (error) {
                        reject(error);
                    });
                });
            };

            // returns promise
            service.getAvailableBalances = function (patron, locationId) {
                var promise = new Promise(function (resolve, reject) {
                    CashierShift.reconcileAvailableBalancesv1({
                        'patronId': patron.patronId,
                        'locationId': locationId
                    }).$promise.then(function (response) {
                        resolve(response);
                    }).catch(function (error) {
                        service.getCachedBalances(patron, locationId).then(function (balances) {
                            resolve(balances);
                        }).catch(function (error) {
                            reject(error);
                        });
                    });
                });

                return promise;
            };

            service.buildLucovaTransaction = function (
                username,
                posObj,
                giftCards,
                verified,
                transactionUuid) {

                if (!transactionUuid) {
                    transactionUuid = Pure.generateUuid();
                }
                // Set flag to indicate mobile transaction
                posObj.sourceId = 1;

                // Calculate gift card amounts
                var giftCardAmountDecimal = new Decimal(0);
                var remainingBalanceDecimal = new Decimal(posObj.remainingBalance);
                posObj.tenders = []; // For gift cards

                if (giftCards) {
                    for (var i = 0; i < giftCards.length; i++) {
                        // Round gift card balance to dollars (it is provided in cents)
                        var giftCardBalanceDecimal = new Decimal(giftCards[i].currentBalanceCents).dividedBy(100);
                        if (giftCardBalanceDecimal.greaterThan(remainingBalanceDecimal)) {
                            // Gift card can fully cover remaining balance
                            posObj.tenders.push({
                                transactionType: 'GIFTCARD',
                                order: i,
                                giftCardId: giftCards[i].id,
                                amountCents: remainingBalanceDecimal.times(100).toNearest(SharedDataService.baseCent).toNumber(),
                                tipAmountCents: 0
                            });

                            giftCardAmountDecimal = giftCardAmountDecimal.add(remainingBalanceDecimal);
                            remainingBalanceDecimal = new Decimal(0);
                            break;
                        } else {
                            // Gift card cannot fully cover remaining balance
                            posObj.tenders.push({
                                transactionType: 'GIFTCARD',
                                order: i,
                                giftCardId: giftCards[i].id,
                                amountCents: giftCardBalanceDecimal.times(100).toNearest(SharedDataService.baseCent).toNumber(),
                                tipAmountCents: 0
                            });

                            giftCardAmountDecimal = giftCardAmountDecimal.add(giftCardBalanceDecimal);
                            remainingBalanceDecimal = remainingBalanceDecimal.sub(giftCardBalanceDecimal);
                        }
                    }
                }

                // Remaining amount goes to credit card
                posObj.giftCardAmount = giftCardAmountDecimal.toNearest(SharedDataService.baseDollar).toNumber();
                posObj.creditCardAmount = remainingBalanceDecimal.toNearest(SharedDataService.baseDollar).toNumber();
                posObj.remainingBalance = 0;

                if (posObj.creditCardAmount > 0) {
                    posObj.creditCardAuthCode = 'LUCOVA';
                }

                var posHeaders = {'JSESSIONID': $cookies.get('JSESSIONID')};
                var obj = {
                    'user_name': username,
                    'pin': '123',
                    'pos_obj': posObj,
                    'pos_obj_type': 'fiit',
                    'pos_headers': posHeaders,
                    'transaction_uuid': transactionUuid,
                    'override_dc': 'true'
                };
                if (verified === true) {
                    obj.scv_verified = true;
                }

                return obj;
            };

            // This method is similar to `service.buildLucovaTransaction` but is built specifically for
            // Nown-to-FIIT workflow where Lucova Payment server is treated as a credit card only
            // payment processor, and the actual transaction request is performed on the client side.
            service.buildClientProcessingLucovaTransaction = function (
                username,
                posObj,
                giftCards,
                verified,
                transactionUuid) {

                if (!transactionUuid) {
                    transactionUuid = Pure.generateUuid();
                }
                // Set flag to indicate mobile transaction
                posObj.sourceId = 1;

                if (posObj.creditCardAmount > 0) {
                    posObj.creditCardAuthCode = 'LUCOVA';
                }

                var posHeaders = {'JSESSIONID': $cookies.get('JSESSIONID')};
                var obj = {
                    'user_name': username,
                    'pin': '123',
                    'pos_obj': posObj,
                    'pos_obj_type': 'fiit',
                    'pos_headers': posHeaders,
                    'transaction_uuid': transactionUuid,
                    'override_dc': 'true',
                    // This flag tells Lucova payment to simply process only the credit card amount and do not contact
                    // Nown server to tender the transaction
                    'simple_client_side_processing': true
                };
                if (verified === true) {
                    obj.scv_verified = true;
                }

                return obj;
            };

            return service;
        }]);
