'use strict';

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosLoyaltyCtrl', [
        '$scope',
        'GatewayFiit',
        'PromiseRetry',
        'SmbPosService',
        'patron',
        'Patrons',
        'location',
        'goHome',
        'adjustments',
        'ConfirmModalService',
        'CashierShift',
        'CurrentSession',
        'Pure',
        'PosAlertService',
        'Security',
        'USER_ROLE_TYPE',
        'SharedDataService',
        'CompanyAttributesService',
        function (
            $scope,
            GatewayFiit,
            PromiseRetry,
            SmbPosService,
            patron,
            Patrons,
            location,
            goHome,
            adjustments,
            ConfirmModalService,
            CashierShift,
            CurrentSession,
            Pure,
            PosAlertService,
            Security,
            USER_ROLE_TYPE,
            SharedDataService,
            CompanyAttributesService
        ) {
            $scope.patron = patron;
            $scope.location = location;
            $scope.adjustments = adjustments;
            let internalDiscounts = [];

            $scope.filteredMealPlans = [];
            $scope.unitCardFilter = {
                searchString: ''
            };

            $scope.loyaltyStepsEnabled = CompanyAttributesService.loyaltyStepsEnabled();
            $scope.loyaltySteps = SharedDataService.cachedLoyaltySteps;
            const loyaltyStepItemsMap =
                SharedDataService.cachedLoyaltyStepItemsMap[SharedDataService.preferredLanguage.languageId];
            $scope.loyaltySteps.forEach((step) => {
                step.items = loyaltyStepItemsMap[step.id] || [];
            });
            $scope.selectedLoyaltyStepItem = null;

            $scope.selectLoyaltyStepItem = (item, pointsRequired) => {
                if (!$scope.patron || !item || !pointsRequired) return;

                if (
                    $scope.selectedLoyaltyStepItem &&
                    $scope.selectedLoyaltyStepItem.item.locationServicePeriodMenuId === item.locationServicePeriodMenuId
                ) {
                    // unselect item
                    $scope.selectedLoyaltyStepItem = null;
                    return;
                }

                $scope.selectedLoyaltyStepItem = {item, pointsRequired};
            };

            const loadDiscountsIfNeeded = async () => {
                if (!$scope.patron.offers || $scope.patron.offers.length === 0) {
                    return;
                }

                const shouldLoadDiscounts = _.any($scope.patron.offers, {autoApply: false});
                if (shouldLoadDiscounts) {
                    const discounts = await CashierShift.getLabelledDiscounts({
                        companyId: CurrentSession.getCompany().companyId
                    }).$promise;
                    internalDiscounts = _.filter(
                        discounts,
                        (discount) => discount.transactionLevel && discount.internalName
                    );
                }
            };

            if ($scope.patron && (!$scope.patron.offers || $scope.patron.offers.length === 0)) {
                $scope.patron.offers = [];
            } else {
                loadDiscountsIfNeeded();
            }

            // This will return in the format 2021-02-26
            const getCurrentDate = () => {
                const date = new Date();
                const currentYear = date.getFullYear();
                const currentMonth = ('0' + (date.getMonth() + 1)).slice(-2);
                const currentDay = ('0' + date.getDate()).slice(-2);
                return `${currentYear}-${currentMonth}-${currentDay}`;
            };

            $scope.currentDate = getCurrentDate();

            $scope.goHome = function () {
                if ($scope.addMode) {
                    return ($scope.addMode = false);
                }
                goHome();

                // Changed from $close() to $dismiss() because this $close() brings us to the order menu with the new
                // logic. Currently $dismiss() simply closes the modal without doing anything more.
                $scope.$dismiss();
            };

            $scope.gateway = {
                fiitMps: GatewayFiit.isEnabled()
            };

            $scope.addMode = false;

            const currentUser = Security.getUser() || {};
            $scope.isUserManager = currentUser.roleType === USER_ROLE_TYPE.MANAGER;
            $scope.isUserSiteAdmin = currentUser.permission === 'SITEADMIN';
            $scope.isUserFullAdmin = currentUser.permission === 'FULLADMIN';

            // dummy data
            $scope.patron.promos = [
                {
                    name: 'Free Coffee',
                    description: 'As a thank you to our premium members, we\'re giving away a free coffee once a month',
                    expiry: 'Expires May 31/18',
                    action: {
                        type: 'item',
                        data: {
                            name: 'Free Coffee', // item name to be shown
                            locationServicePeriodMenuId: 10313,
                            discount: {
                                type: 'percentage',
                                value: 100
                            }
                        }
                    }
                }
            ];

            $scope.constants = {
                loyaltyLevels: [
                    {
                        level: 0,
                        value: 'one',
                        name: 'smb.pos.loyalty.levels.one'
                    },
                    {
                        level: 1,
                        value: 'two',
                        name: 'smb.pos.loyalty.levels.two'
                    },
                    {
                        level: 2,
                        value: 'three',
                        name: 'smb.pos.loyalty.levels.three'
                    }
                ]
            };

            var processPatron = function () {
                var groupedCoupons = _.groupBy($scope.patron.coupons, function (coupon, index) {
                    return Math.floor(index / 2);
                });
                $scope.patron.groupedCoupons = groupedCoupons;
            };
            processPatron();

            $scope.getPatronPhoto = function (photoUrl, isCompleteUrl) {
                return SmbPosService.getPatronPhoto(photoUrl, isCompleteUrl);
            };

            $scope.isGuestTransaction = function (user) {
                return SmbPosService.isGuestTransaction(user);
            };

            $scope.applyMealPlan = function ($event, plan, reload = false) {
                // stops the event from reaching the click listener on parent div
                if ($event) {
                    $event.stopPropagation();
                    $event.preventDefault();
                }

                plan.reload = reload;
                $scope.$close({
                    mealPlan: plan
                });
            };

            $scope.addCouponToOrder = function (coupon) {
                $scope.$close({
                    coupon: coupon
                });
            };

            $scope.showMealPlanList = function () {
                return ($scope.addMode = true);
            };

            $scope.purchaseMealPlan = function (mealPlan) {
                if (mealPlan.hasMealPlan) {
                    return;
                }
                ConfirmModalService.openConfirmModal(
                    'Add Loyalty Card',
                    'Are you sure you want to enroll ' + $scope.patron.fullName + ' to this Loyalty Plan?',
                    undefined,
                    undefined,
                    undefined,
                    true
                )
                    .then(() => {
                        $scope.$close({addMealPlan: mealPlan});
                    })
                    .catch(() => {
                        $scope.$close();
                    });
            };

            $scope.selectedItemCount = 0;
            var computeSelectedItemCount = function () {
                var items = $scope.patron.topItems || {};

                var selectedItems = _.where(items, {selected: true});
                $scope.selectedItemCount = selectedItems.length;
            };

            $scope.toggleItem = function (item) {
                if (!item.active) {
                    return;
                }
                item.selected = !item.selected;
                computeSelectedItemCount();
            };

            $scope.continueToMenu = function () {
                const payload = {};
                if ($scope.selectedItemCount > 0) {
                    const items = $scope.patron.topItems || {};
                    const selectedItems = _.where(items, {selected: true});
                    payload.items = selectedItems;
                }

                if ($scope.discountOfferToUse) {
                    payload.discountOfferToUse = $scope.discountOfferToUse;
                }

                if ($scope.selectedLoyaltyStepItem) {
                    payload.loyaltyStepItemData = $scope.selectedLoyaltyStepItem;
                }

                $scope.$close(payload);
            };

            var loadPatronGatewayAccounts = function () {
                if ($scope.gateway.fiitMps) {
                    var accountRequestObj;
                    if (
                        $scope.patron.lucovaUser &&
                        $scope.patron.lucovaUser.fiit &&
                        $scope.patron.lucovaUser.fiit.patron_id
                    ) {
                        accountRequestObj = {
                            patronId: $scope.patron.lucovaUser.fiit.patron_id,
                            patronKey: $scope.patron.lucovaUser.fiit.patron_key
                        };
                    }

                    if (accountRequestObj) {
                        var accountRequest = PromiseRetry.start({
                            fn: function () {
                                return SmbPosService.fetchPatronFromFiitBackend(
                                    accountRequestObj.patronId,
                                    accountRequestObj.patronKey
                                );
                            },
                            timeout: 5000,
                            maxRetry: 0
                        });

                        accountRequest
                            .then(function (response) {
                                patron.fiitMpsAccount = response.fiitMpsAccount;
                                patron.photoUrl = patron.fiitMpsAccount.photoUrl;
                            })
                            .catch(function (error) {
                                if (
                                    error &&
                                    error.fiitMpsAccount &&
                                    error.fiitMpsAccount.error &&
                                    patron.fiitMpsAccount
                                ) {
                                    patron.fiitMpsAccount.error = error.fiitMpsAccount.error;
                                } else {
                                    patron.fiitMpsAccount = GatewayFiit.getOfflineAccount(accountRequestObj.patronId);
                                }
                            });
                    }
                }
            };

            const sumGiftCardBalance = (giftCards) => {
                let sum = 0;
                for (let card of giftCards) {
                    if (card.currentBalanceCents) {
                        sum += +card.currentBalanceCents;
                    }
                }
                return Pure.convertCentsToDollar(sum);
            };

            var loadMealPlans = function () {
                SmbPosService.fetchNonLoyaltyMealPlans().then((mealPlans) => {
                    // flag meal plans that patron already has
                    mealPlans.forEach((mp) => {
                        let patronMp = $scope.patron.mealPlans.find((pmp) => pmp.mealPlanId == mp.mealPlanId);
                        if (patronMp) {
                            mp.hasMealPlan = true;
                        }
                    });
                    $scope.mealPlans = mealPlans;
                    $scope.filteredMealPlans = [...mealPlans];
                });
            };

            const getGiftCardSumAndCount = (giftCards) => {
                // We should use a SET to track unique gift cards
                //  since there maybe duplicate ids in the payload
                const uniqueGiftCardIds = new Set();
                let uniqueCount = 0;
                let uniqueSum = 0;

                giftCards.forEach((giftCard) => {
                    const {id, currentBalanceCents} = giftCard;

                    if (!uniqueGiftCardIds.has(id)) {
                        // First occurrence of the gift card, update count and sum
                        uniqueGiftCardIds.add(id);
                        uniqueCount += 1;
                        uniqueSum += currentBalanceCents;
                    }
                });
                return {sum: Pure.convertCentsToDollar(uniqueSum), count: uniqueCount};
            };

            const fetchGiftCards = function (lucovaGiftCards) {
                if (!lucovaGiftCards || !lucovaGiftCards.length) {
                    return;
                }
                // Ideally we should send one request to get all the gift cards but since we do not expect a
                // customer to have more than 2-3 giftcards it is acceptable here to send a request per gift card
                const giftCardPromises = [];
                lucovaGiftCards.forEach((lucovaGiftCard) => {
                    giftCardPromises.push(
                        CashierShift.lookupGiftCard({
                            giftCardId: lucovaGiftCard.id,
                            code: lucovaGiftCard.code,
                            currencyId: CurrentSession.getCompany().baseCurrencyId,
                            cashierShiftId: SmbPosService.shift.cashierShiftId
                        }).$promise
                    );
                });

                return Promise.all(giftCardPromises).then(
                    function (giftCards) {
                        // displaying number of available gift cards and
                        // total balance of these cards on frontend.
                        // We need to ensure we are counting all unique giftcards
                        //  since it should be assumed that the browser may initiate multiple
                        //  requests of the same card and therefore we may have duplicates
                        //  in our returned promise.
                        $scope.availableGiftCards = giftCards;
                        const uniqueGiftCardBalance = getGiftCardSumAndCount(giftCards);
                        const sumGiftCards = uniqueGiftCardBalance.sum;
                        $scope.numberOfGiftCards = uniqueGiftCardBalance.count;
                        $scope.totalAvailableGiftCardsBalance = sumGiftCards > 0 ? sumGiftCards : '0.00';

                        return {
                            success: true
                        };
                    },
                    function (error) {
                        $scope.error = {
                            giftCard: true
                        };

                        if (error && error.data && error.data.exception && error.data.exception.appCode == 444) {
                            $scope.error.foreignGiftCard = true;
                        }

                        return {
                            success: false,
                            error: error
                        };
                    }
                );
            };

            const giftCardFromLucovaPatron = function () {
                if (!patron || !patron.lucovaUser) {
                    return;
                }
                let lucovaUser = patron.lucovaUser;
                let lucovaGiftCards =
                    lucovaUser.nown && lucovaUser.nown.nown_gift_cards ? lucovaUser.nown.nown_gift_cards : [];

                // ensuring backwards compatibility as older versions of the mobile apps may send nown_gift_nums instead of
                // nown_gift_cards
                let lucovaGiftNums =
                    lucovaUser.nown && lucovaUser.nown.nown_gift_nums ? lucovaUser.nown.nown_gift_nums : [];
                _.each(lucovaGiftNums, (num) => {
                    let giftCard = {
                        code: num
                    };
                    lucovaGiftCards.push(giftCard);
                });

                fetchGiftCards(lucovaGiftCards);
            };

            const giftCardFromFiitPatron = () => {
                if (!$scope.patron || !$scope.patron.fiitPatron) {
                    return;
                }

                const patron = $scope.patron.fiitPatron;
                Patrons.getAllAvailableGCs({patronId: patron.patronId})
                    .$promise.then((giftCards) => {
                        $scope.numberOfGiftCards = giftCards.length;
                        const sumGiftCards = sumGiftCardBalance(giftCards);
                        $scope.totalAvailableGiftCardsBalance = sumGiftCards > 0 ? sumGiftCards : '0.00';
                    })
                    .catch(() => {
                        // NOOP
                    });
            };

            var loadPatronTopItems = function () {
                if (patron.fiitPatron && patron.fiitPatron.patronId) {
                    var patronId = patron.fiitPatron.patronId;
                    SmbPosService.loadPatronTopItems(patronId).then(
                        function (items) {
                            parsePatronTopItems(items);
                        },
                        function () {
                            $scope.patron.topItems = $scope.patron.topItems || [];
                            $scope.patron.topItems.length = 0;
                        }
                    );
                } else {
                    $scope.patron.topItems = $scope.patron.topItems || [];
                    $scope.patron.topItems.length = 0;
                }
            };

            const loadPatronOffers = () => {
                if (
                    !patron ||
                    !patron.fiitPatron ||
                    !patron.fiitPatron.patronId ||
                    ($scope.patron.offers && $scope.patron.offers.length > 0)
                ) {
                    return;
                }

                let patronId = patron.fiitPatron.patronId;
                const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                SmbPosService.loadPatronOffers(patronId, timezone)
                    .then((offers) => {
                        $scope.patron.offers = offers;
                        loadDiscountsIfNeeded();
                    })
                    .catch(console.error);
            };

            var parsePatronTopItems = function (items) {
                var patronTopItems = _.map(items, function (item) {
                    return {
                        name: item.name,
                        data: item
                    };
                });

                $scope.patron.topItems = _.forEach(patronTopItems, function (topItem) {
                    topItem.active = checkIfItemActive(topItem.data);
                });
            };

            var checkIfItemActive = function (item) {
                if (!item.active) {
                    return false;
                }

                if (item.children && item.children.length) {
                    for (var cntr = 0; cntr < item.children.length; cntr++) {
                        if (!checkIfItemActive(item.children[cntr])) {
                            return false;
                        }
                    }
                }

                return true;
            };

            const getPatronPinnedNotes = () => {
                Patrons.getPatronPinnedNotes(
                    {patronId: $scope.patron.patronId},
                    (res) => ($scope.patronPinnedNotes = res)
                ),
                    (err) => PosAlertService.showAlertByName('general');
            };

            const canDiscountOfferBeUsed = (offer, associatedDiscount) => {
                // AMOUNT_DISCOUNT_CENTS is currently incompatible with discounts
                const OFFER_TYPES = ['PERCENT_DISCOUNT'];

                const isOfferValid = (offer) => {
                    // offers can also be auto applied, but DISCOUNT offers should only apply when selected
                    const isAuto = offer.autoApply;
                    const validType = OFFER_TYPES.indexOf(offer.offerType) > -1;
                    const validValue = parseInt(offer.offerValue) >= 0;
                    return !isAuto && validType && validValue;
                };

                if (!isOfferValid(offer)) {
                    return false;
                }

                if (offer.internalName != associatedDiscount.internalName) {
                    return false;
                }

                const offerValue = parseInt(offer.offerValue);
                if (!offerValue || isNaN(offerValue)) {
                    return false;
                }

                if (offerValue > 0 && offerValue != parseInt(associatedDiscount.discountPercentage * 100)) {
                    return false;
                }

                return true;
            };

            $scope.toggleOffer = async (offerIndex) => {
                // should only toggle non-auto-applied offers
                if ($scope.patron.offers[offerIndex].autoApply) {
                    return;
                }

                // cannot be used with loyalty step items
                if ($scope.adjustments.loyaltyStepAdjustment || $scope.selectedLoyaltyStepItem) {
                    return;
                }

                // only one discount offer should be selected
                _.each($scope.patron.offers, (offer, idx) => {
                    // skip auto-applied offers
                    if (offer.autoApply) {
                        return;
                    }

                    if (idx !== offerIndex) {
                        // toggle all other offers off
                        offer.selected = false;
                    } else if (!offer.selected) {
                        // check for associated discount when toggling on
                        const associatedDiscounts = _.filter(internalDiscounts, {internalName: offer.internalName});
                        if (associatedDiscounts.length === 1 && canDiscountOfferBeUsed(offer, associatedDiscounts[0])) {
                            offer.selected = true;
                            $scope.discountOfferToUse = {
                                offer,
                                associatedDiscount: associatedDiscounts[0]
                            };
                            $scope.selectedLoyaltyStepItem = null;
                        } else {
                            PosAlertService.showAlertByName('general', {
                                message: 'smb.pos.loyalty.noAssociatedDiscount'
                            });
                        }
                    } else {
                        offer.selected = false;
                    }
                });
            };

            $scope.isOfferSelected = () => !!$scope.patron.offers.find((offer) => offer.selected);

            $scope.filterUnitCard = () => {
                const searchString = $scope.unitCardFilter.searchString;
                let re = new RegExp(`${searchString}`, 'ig');

                $scope.filteredMealPlans = $scope.mealPlans.filter((mp) => mp.name.match(re));
            };

            $scope.init = function () {
                loadPatronGatewayAccounts();
                loadPatronTopItems();
                loadMealPlans();
                loadPatronOffers();
                giftCardFromLucovaPatron();
                giftCardFromFiitPatron();
                getPatronPinnedNotes();
            };

            $scope.init();
        }
    ]);
};
