'use strict';

const TaxRuleService = require('../external/tax-rule-service.js');
const debouncePromise = require('debounce-promise');

module.exports = function (freshideasProducts) {
    freshideasProducts.factory('ProductsService', [
        '$q',
        '$rootScope',
        '$log',
        'CashierShift',
        'Inventory',
        'Locations',
        'Lookup',
        'PosAlertService',
        'PosStatusService',
        'Security',
        'SharedDataService',
        'Shopify',
        'CurrentSession',
        'CompanyAttributesService',
        'Menu',
        function (
            $q,
            $rootScope,
            $log,
            CashierShift,
            Inventory,
            Locations,
            Lookup,
            PosAlertService,
            PosStatusService,
            Security,
            SharedDataService,
            Shopify,
            CurrentSession,
            CompanyAttributesService,
            Menu) {

            var _resetArr = function (array) {
                array.length = 0;
            };
            var _resetObj = function (obj) {
                if (!obj) {
                    return;
                }

                for (const prop of Object.keys(obj)) {
                    delete obj[prop];
                }
            };
            var reset = function () {
                _company = Security.getUser().company;
                _companyId = Security.getUser().companyId;

                _locationId = undefined;

                _resetObj(_location);
                _resetObj(_locationDetail);
                _resetArr(_servicePeriods);
                _resetObj(_servicePeriod);
                _resetObj(_user);

                _resetArr(products);
                _resetArr(productCategories);
                _resetArr(productModifiers);
                _resetObj(productModifierMap);

                _resetArr(allItems);
                _resetArr(allCategories);
                _resetArr(allModifiers);
                _resetArr(unsortedItems);

                _resetObj(currentMenuPage);
                _resetObj(currentMenUPageParent);

                _resetArr(taxRates);
                _resetArr(taxRules);

                _resetArr(printers);
                _resetArr(tags);
                _resetArr(menuArr);
            };

            var _company = Security.getUser().company;
            var _companyId = Security.getUser().companyId;

            var _location, _locationId, _locationDetail = {};
            var _servicePeriods = [];
            var _servicePeriod;
            var _user;

            var products = [];
            var productCategories = [];
            var productModifiers = [];
            var productModifierMap = {};

            var allItems = [];
            var allCategories = [];
            var allModifiers = [];
            var unsortedItems = [];

            var currentMenuPage = {};
            var currentMenUPageParent = {};

            var taxRates = [];
            var taxRules = [];

            var printers = [];
            var tags = [];
            var menuArr = [];

            var isMasterMenu = false;

            var selectedMenuId;

            // TODO: explore way to get product related data (ie. products, allItems, allCategories, allModifiers)
            // in one call.

            var setSelectedMenuId = (menuId) => {
                selectedMenuId = menuId;
            };

            var getSelectedMenuId = () => {
                return selectedMenuId;
            };

            var loadProducts = function ({filterInactive = false, topLevel = false, limit = 4, useCache = false} = {}) {
                let productsPromise;
                if (CompanyAttributesService.hasMenuV2Enabled()) {
                    productsPromise = Menu.getMenuGrid({menuId: selectedMenuId, useAndUpdateCache: false});
                } else {
                    productsPromise = Locations.getLocationHierarchy({locationId: _locationId}, useCache, false);
                }

                return productsPromise.then(function (response) {
                    var menus = _.sortBy(response.posMenuItemEntries, function (menu) {
                        return menu.servicePeriodId;
                    });

                    products.length = 0;

                    if (response.posMenuItemEntries && response.posMenuItemEntries.length > 0) {
                        var entries = _.filter(menus[0].entries, function (entry) {
                            return entry.menuOrderId > 0;
                        });

                        /* Commented by Harish Reddy, August 10, 2022
                           sorting these entries in backend slows down menu loading and item search
                           sorts pages according to 'menuOrderId' set to page according to page name,
                           ex:(page.1 -> menuOrderId: 1, ...)
                        */
                        var sortedEntries = _.sortBy(entries, function (entry) {
                            return entry.menuOrderId;
                        });

                        _.each(sortedEntries, function (entry) {
                            if (entry.companyId != _companyId) {
                                entry._inherited = true;
                            }
                        });

                        products.push(...sortedEntries);
                    }

                    parseProductCategories(products);
                    parseProductModifiers(products);

                    return products;
                }).catch(function (error) {
                    products.length = 0;
                    return [];
                });
            };

            var bufferedLoadProducts = debouncePromise(loadProducts, 5000, {leading: true});

            var parseProductCategories = function (products, parent) {
                if (!parent) {
                    productCategories.length = 0;
                }

                _.each(products, function (product) {
                    if (product.companyId != _companyId) {
                        product._inherited = true;
                    }

                    if (product.type === 'category' && _isProductOnMenu(product)) {
                        if (parent && parent.subtype !== 'page') {
                            var parentParentIds = parent.parentIds || [];
                            var productParentIds = [parent.locationServicePeriodMenuId];
                            product.parentIds = parentParentIds.concat(productParentIds);

                            var parentParentNames = parent.parentNames || [];
                            var productParentNames = [parent.name];
                            product.parentNames = parentParentNames.concat(productParentNames);
                        }
                        productCategories.push(product);

                        if (product.children) {
                            parseProductCategories(product.children, product);
                        }
                    }


                });
            };
            var parseProductModifiers = function (products, parent) {
                var isTopLevel = !parent;

                if (isTopLevel) {
                    _resetObj(productModifierMap);
                }

                _.each(products, function (product) {
                    if (product.companyId != _companyId) {
                        product._inherited = true;
                    }

                    if (product.type === 'category' && _isProductOnMenu(product)) {
                        if (product.children) {
                            parseProductModifiers(product.children, product);
                        }
                    }

                    if (product.type === 'item' && _isProductOnMenu(product)) {
                        if (product.children) {
                            _.each(product.children, function (modifier) {
                                var modifierId = modifier.locationServicePeriodMenuId;
                                productModifierMap[modifierId] = productModifierMap[modifierId] || angular.copy(modifier);
                                productModifierMap[modifierId].linkedItemMap = productModifierMap[modifierId].linkedItemMap || {};
                                productModifierMap[modifierId].linkedItemMap[product.locationServicePeriodMenuId] = product;
                            });
                        }
                    }
                });

                if (isTopLevel) {
                    productModifiers.length = 0;
                    _.each(productModifierMap, function (modifier) {
                        var linkedItems = [];
                        _.each(modifier.linkedItemMap, function (linkedItem) {
                            linkedItems.push(linkedItem);
                        });
                        modifier.linkedItems = linkedItems;

                        var linkedItemNames = _.map(modifier.linkedItems, function (linkedItem) {
                            return linkedItem.name;
                        });
                        var linkedItemNameString = linkedItemNames.join(', ');
                        modifier.linkedItemName = linkedItemNameString;

                        productModifiers.push(modifier);
                    });
                }
            };

            var _isProductOnMenu = function (product) {
                return (product.currentCategoryId)? (product.currentCategoryIndex >= 0) : (product.menuOrderId >= 1);
            };

            var loadMenus = function () {
                var organizationId = CurrentSession.getCompany().organizationId;
                return Locations.getMenusByOrganization({'organizationId': organizationId}).$promise.then(function (response) {
                    _resetArr(menuArr);
                    menuArr.push(...response);
                    SharedDataService.setAllMenus(response);

                    return menuArr;
                });
            };

            var loadAllItems = function (useCache = true) {
                return $q.resolve()
                .then(loadAllCategories)
                .then(loadAllModifiers);
            };
            var bufferedLoadAllItems = debouncePromise(loadAllItems, 5000, {leading: true});

            var loadAllCategories = function () {
                allCategories.length = 0;
                return Locations.getLocationCategories({'locationId': _locationId}).$promise.then(function (response) {
                    // NOTE: `allCategories` doesn't seem to be anymore. Considering removing this.
                    allCategories.push(...response.entries);
                });
            };
            var loadAllModifiers = function () {
                allModifiers.length = 0;
                return Locations.getLocationModifiers({'locationId': _locationId}).$promise.then(function (response) {
                    // NOTE: `allModifiers` doesn't seem to be anymore. Considering removing this.
                    allModifiers.push(...response.entries);
                });
            };

            /* var _setAllItems = function (newAllItems) {
                allItems.length = 0;
                allItems.push(...newAllItems);

                var unsorted = _.filter(allItems, function (item) {
                    return !item.forceTopLevel || !item.active;
                });

                _.each(allItems, function (item) {
                    if (item.companyId != _companyId) {
                        item._inherited = true;
                    }
                });

                unsortedItems.length = 0;
                unsortedItems.push(...unsorted);
            };*/

            var loadLocations = function (companyId, forceRefresh = false) {
                var companyIdToLookUp = companyId || _companyId;
                if (forceRefresh || (!_location || !_locationId)) {
                    return Lookup.companyLocations({'companyId': companyIdToLookUp}).$promise.then(function (response) {
                        var locations = _.map(response, function (location) {
                            location.id = parseInt(location.id);
                            return location;
                        });

                        _location = locations[0];
                        _locationId = _location.id;
                        _companyId = companyIdToLookUp;


                        loadTaxRates();
                        loadLocationPrinters();
                        loadLocationDetail(companyIdToLookUp); // load additional detail related to the location in the background
                        return {};
                    });
                } else {
                    var deferred = $q.defer();
                    deferred.resolve();

                    return deferred.promise;
                }
            };
            var loadLocationDetail = function (companyId) {
                Locations.getLocations({'companyId': companyId}, function (response) {
                    // named `$scope.locationDetail` to avoid confusion with `location`
                    Object.assign(_locationDetail, response.entries[0]);
                });
            };

            var loadTaxRates = function () {
                return new Promise(function (resolve) {
                    Lookup.taxRatesByOrganization({organizationId: CurrentSession.getOrganization().organizationId, getDefaultTaxRates: true}).$promise.then(function (response) {
                        var result = _.map(response, function (taxRate) {
                            var taxRateId = parseInt(taxRate.id);
                            taxRate.id = (isNaN(taxRateId))? null : taxRateId;

                            return taxRate;
                        });

                        taxRates.length = 0;
                        taxRates.push(...result);
                        resolve();
                    });
                });
            };

            var loadLocationPrinters = function () {
                return Locations.getSecondaryPosPrinters({'locationId': _locationId}).$promise.then(function (response) {
                    var posPrinters = response || [];

                    _.each(posPrinters, function (posPrinter) {
                        var printerName = (posPrinter.printerName || '').trim();
                        var modelName = (posPrinter.bluetoothName)
                                            ? posPrinter.bluetoothName
                                            : posPrinter.printerModel || '';

                        var displayName;
                        if (printerName + modelName === '') {
                            displayName = posPrinter.printerIpAddress + ':' + posPrinter.printerPort;
                        } else {
                            var displayNameArray = [printerName, modelName].filter(function (name) {
                                return name !== '';
                            });
                            displayName = displayNameArray.join(' - ');
                        }

                        posPrinter._displayName = displayName;
                    });

                    printers.length = 0;
                    printers.push(...posPrinters);
                });
            };

            var loadTags = function () {
                return Inventory.searchActiveTagNames().$promise.then(function (results) {
                    tags.length = 0;

                    results = _.map(results, function (result) {
                        return {
                            name: result
                        };
                    });

                    tags.push(...results);

                    return tags;
                });
            };

            var loadMenuItem = function (menuItemId) {
                return Inventory.getMenuItem({
                    menuItemId: menuItemId,
                    locationId: _locationId
                }).$promise;
            };

            var addMenuItem = function (item, hasCategory) {
                if (!item.name) {
                    return $q.reject('Item name cannot be blank');
                }

                var itemToSave = angular.copy(item);

                // These fields can be too big to transfer and are not handled by edit anyway
                delete itemToSave.children;
                delete itemToSave.categories;

                itemToSave.companyId = _companyId;
                itemToSave.forceTopLevel = false; // with the new structure item cannot be at top level anymore
                itemToSave.loyaltyEnabled = true; // TODO-nown-fiit

                return Inventory.addMenuItem({}, itemToSave, function (response) {
                    if (response && response.locationServicePeriodMenuId) {
                        if (!hasCategory) {
                            products.push(response);
                            _refreshProducts();

                            updateMenuMobile(_locationId); // don't care if this updates successfully?
                        }

                        return response;
                    } else {
                        PosAlertService.showError('error', 'Error Creating Item', '', 'The item could not be created.');
                    }
                }).$promise;
            };
            var addMenuItemToCategories = function (item, categoryIds) {
                var associations = {
                    locationId: _locationId,
                    parentIds: categoryIds,
                    childIds: [item.locationServicePeriodMenuId]
                };

                return Locations.addAssociations(associations).$promise.then(function (associationResponse) {
                    updateMenuMobile(_locationId);

                    return associationResponse;
                });
            };
            var removeMenuItemFromCategories = function (item, categoryIds) {
                var associations = {
                    locationId: _locationId,
                    parentIds: categoryIds,
                    childIds: [item.locationServicePeriodMenuId]
                };

                return Locations.removeAssociations(associations).$promise.then(function (associationResponse) {
                    updateMenuMobile(_locationId);

                    return associationResponse;
                    /* return loadProducts().then(loadAllItems).then(function () {
                        return associationResponse;
                    }); */
                });
            };


            var editMenuItem = function (item, showAlert = false, hasCategory) {
                if (!item.name) {
                    return $q.reject('Item name cannot be blank');
                }

                // These fields can be too big to transfer and are not handled by edit anyway
                delete item.children;
                delete item.categories;
                delete item._parent;
                delete item.compressedImage;

                return Inventory.updateMenuItem({menuItemId: item.locationServicePeriodMenuId}, item)
                    .$promise
                    .then(function (response) {
                        if (showAlert) {
                            PosAlertService.showError('success', 'Updated Item', '', 'This item has been updated.');
                        }

                        if (!hasCategory) {
                            var index = _.findIndex(products, function (product) {
                                return product.locationServicePeriodMenuId === item.locationServicePeriodMenuId;
                            });

                            if (index > -1) {
                                products.splice(index, 1, item);
                                _refreshProducts();
                            }

                            updateMenuMobile(_locationId);
                            loadAllItems();
                        }

                        return response;
                    })
                    .catch(function (error) {
                        PosAlertService.showError('error', 'Error Editing Item', '', 'The item could not be edited.');
                        $log.error(error);
                    });
            };

            var moveMenuItem = function (item, targetCategory) {
                // only passing the essentials to allow both item/category to be moved
                var itemToSave = {};
                itemToSave.locationServicePeriodMenuId = item.locationServicePeriodMenuId;
                itemToSave.companyId = item.companyId;
                itemToSave.servicePeriodIds = item.servicePeriodIds;
                itemToSave.menuOrderId = item.menuOrderId;
                itemToSave.currentCategoryId = item.currentCategoryId;
                itemToSave.currentCategoryIndex = item.currentCategoryIndex;

                return Inventory.moveMenuItem({menuItemId: item.locationServicePeriodMenuId}, itemToSave)
                    .$promise
                    .then(function (itemResponse) {
                        updateMenuMobile(itemToSave.locationId);

                        return bufferedLoadProducts().then(bufferedLoadAllItems).then(function () {
                            if (targetCategory) {
                                var updatedCategory = _.findWhere(allCategories, {
                                    locationServicePeriodMenuId: targetCategory.locationServicePeriodMenuId
                                });

                                targetCategory.children.length = 0;
                                targetCategory.children.push(...updatedCategory.children);
                            }

                            return itemResponse;
                        });
                    })
                    .catch(function (error) {
                        console.error(error);
                    });

            };

            var batchMoveMenuItem = function (items) {
                return Inventory.batchMoveMenuItem({'items': items})
                    .$promise;
            };

            var editMenuItemPriceReplacedBy = function (item, replacedBy) {
                var itemToSave = {};
                itemToSave.companyId = item.companyId;
                itemToSave.locationServicePeriodMenuId = item.locationServicePeriodMenuId;
                itemToSave.companyId = item.companyId;
                itemToSave.priceReplacedBy = replacedBy;

                return Inventory.updateMenuItemPriceReplacedBy({menuItemId: item.locationServicePeriodMenuId}, itemToSave)
                    .$promise
                    .then(function (response) {
                        return response;
                    })
                    .catch(function (error) {
                        PosAlertService.showError('error', 'Error Editing Item', '', 'The item could not be edited.');
                        $log.error(error);
                    });
            };

            var deleteMenuItem = function (item) {
                return Inventory.deleteMenuItem({menuItemId: item.locationServicePeriodMenuId})
                    .$promise
                    .then(function (response) {
                        return loadProducts().then(loadAllItems);
                    });
            };

            var getMenuItemCategoryIds = function (menuItem) {
                if (!menuItem.locationServicePeriodMenuId) {
                    return $q.reject();
                }

                return Inventory.getMenuItemCategories({
                    menuItemId: menuItem.locationServicePeriodMenuId
                })
                .$promise
                .then(function (categoryIds) {
                    return categoryIds;
                    /* return _.filter(productCategories, function (productCategory) {
                        return categoryIds.indexOf(productCategory.locationServicePeriodMenuId) > -1;
                    }); */
                });
            };

            var addMenuCategory = function (category, hasCategory) {
                category.allowMultipleSelection = true;
                category.minSelection = -1;
                category.maxSelection = -1;
                category.includes = 0;
                category.companyId = _companyId;
                category.forceTopLevel = false; // with new structure, no item/category/modifier can be top level

                return Locations.addCategory({}, category, function (response) {
                    if (response && response.locationServicePeriodMenuId) {
                        if (!hasCategory) {
                            products.push(response);
                            _refreshProducts();

                            updateMenuMobile(_locationId); // don't care if this updates successfully?
                        }

                        return response;
                    } else {
                        PosAlertService.showError('error', 'Error Creating Category', '', 'The category could not be created.');
                    }
                }).$promise;
            };
            var addMenuCategoryToCategory = function (category, targetCategory) {
                return addMenuCategory(category, true).then(function (categoryResponse) {
                    var parentIds = [targetCategory.locationServicePeriodMenuId];
                    var childIds = [categoryResponse.locationServicePeriodMenuId];

                    var associations = {
                        locationId: _locationId,
                        parentIds: parentIds,
                        childIds: childIds
                    };

                    return Locations.addAssociations(associations).$promise.then(function (associationResponse) {
                        updateMenuMobile(_locationId);

                        return loadProducts().then(loadAllItems).then(function () {
                            var updatedCategory = _.findWhere(allCategories, {
                                locationServicePeriodMenuId: targetCategory.locationServicePeriodMenuId
                            });

                            targetCategory.children.length = 0;
                            targetCategory.children.push(...updatedCategory.children);

                            return categoryResponse;
                        });
                    }).catch(function (error) {
                        // PosAlertService.showError('error', 'Invalid Item', '', 'This item cannot be added as a sub-item of itself, please try a different menu item.');
                    });
                }, function (error) {

                });
            };
            var editMenuCategory = function (category, shouldAlert) {
                var categoryToSave = angular.copy(category);
                categoryToSave.allowMultipleSelection = true;
                categoryToSave.minSelection = -1;
                categoryToSave.maxSelection = -1;
                categoryToSave.includes = 0;
                categoryToSave.companyId = _companyId;

                // TODO: with `children` the /menuCategory path cannot even be reached
                // Find out why?
                delete categoryToSave.children;
                delete categoryToSave._parent;

                return Locations.updateCategory(categoryToSave, function (response) {
                    if (shouldAlert) {
                        PosAlertService.showError('success', 'Updated Category', '', 'This category has been updated.');
                    }

                    var index = _.findIndex(products, function (product) {
                        return product.locationServicePeriodMenuId === category.locationServicePeriodMenuId;
                    });

                    if (index > -1) {
                        products.splice(index, 1, category);
                        _refreshProducts();
                    }

                    updateMenuMobile(_locationId);
                    loadAllItems();

                    return category;
                }, function (error) {
                    PosAlertService.showError('error', 'Error Editing Category', '', 'The catgory could not be edited.');
                }).$promise;
            };

            var addModifier = function (modifier, parentItem) {
                if (!parentItem) {
                    return $q.reject('No parent item to add modifier to');
                }

                if (!modifier.name) {
                    return $q.reject();
                }

                modifier.companyId = _companyId;
                modifier.active = true;

                _.each(modifier.children, function (modifierOption) {
                    modifierOption.companyId = _companyId;
                    modifierOption.mealEquivalencyEligible = true;
                });

                var modifierSaved;

                return Locations.addModifier(modifier).$promise
                    .then(function (modifierResponse) {
                        modifierSaved = modifierResponse;

                        var parentIds = [parentItem.locationServicePeriodMenuId];
                        var childIds = [modifierSaved.locationServicePeriodMenuId];

                        var associations = {
                            locationId: _locationId,
                            parentIds: parentIds,
                            childIds: childIds
                        };

                        return Locations.addAssociations(associations).$promise;
                    })
                    .then(function (associationResponse) {
                        if (associationResponse) {
                            allModifiers.push(modifierSaved);
                        } else {
                            PosAlertService.showError('error', 'Error Creating Modifier', '', 'The modifier could not be created.');
                        }

                        return updateModifierOptions(modifierSaved, parentItem);
                    })
                    .then(function () {
                        updateMenuMobile(_locationId);
                        return modifierSaved;
                    });
            };

            var editModifier = function (modifier, parentItem, shouldAlert = false) {
                if (!modifier.name) {
                    return $q.reject();
                }

                modifier.companyId = _companyId;
                modifier.active = true;

                _.each(modifier.children, function (modifierOption) {
                    modifierOption.companyId = _companyId;
                });

                return Locations.updateModifier(modifier, function (response) {
                    if (shouldAlert) {
                        PosAlertService.showError('success', 'Updated Modifier', '', 'This modifier has been updated.');
                    }

                    var index = _.findIndex(allModifiers, function (m) {
                        return m.locationServicePeriodMenuId === modifier.locationServicePeriodMenuId;
                    });

                    if (index > -1) {
                        allModifiers.splice(index, 1, modifier);
                    }
                    return response;
                }).$promise.then(function () {
                    return updateModifierOptions(modifier, parentItem);
                }).then(function () {
                    updateMenuMobile(_locationId);
                    return modifier;
                });
            };

            var attachModifier = function (modifiers, parentItem) {
                var childIds = _.map(modifiers, function (modifier) {
                    return modifier.locationServicePeriodMenuId;
                });
                var parentIds = [parentItem.locationServicePeriodMenuId];

                var associations = {
                    locationId: _locationId,
                    parentIds: parentIds,
                    childIds: childIds
                };

                return Locations.addAssociations(associations, function (response) {
                    return response;
                }).$promise;
            };

            var removeModifiers = function (modifiers, parentItem) {
                var childIds = _.map(modifiers, function (modifier) {
                    return modifier.locationServicePeriodMenuId;
                });
                var parentIds = [parentItem.locationServicePeriodMenuId];

                var associations = {
                    locationId: _locationId,
                    parentIds: parentIds,
                    childIds: childIds
                };

                return Locations.removeAssociations(associations, function (response) {
                    return response;
                }).$promise;
            };


            var searchMenuItemsByUpc = function (upc) {
                if (!upc) {
                    return $q.reject({errorCode: -1, error: 'Invalid Upc'});
                }

                let payload = {
                    upc: upc,
                    serviceLocation: _locationId,
                    itemsOnly: true
                };

                let resourceObj = CashierShift;
                if (CompanyAttributesService.hasMenuV2Enabled()) {
                    resourceObj = Menu;
                }

                return resourceObj.lookupUpc(payload).$promise.then(function (response) {
                    var deferred = $q.defer();
                    deferred.resolve(response);
                    return deferred.promise;
                }, function (error) {
                    if (PosStatusService.isOffline() || error.status <= 0 || error.status > 500) {
                        return $q.reject({errorCode: -1, error: error});
                    } else if (error && error.data && error.data.exception && error.data.exception.appCode == 401) {
                        return $q.reject({errorCode: 1, error: error});
                    } else {
                        // Conmmented By Akash Mehta on 7th July 2020
                        // This else clause means that an item was not found and
                        // does not exist on the backend. If we need to add any other
                        // checks, please add before the else clause.
                        return $q.reject({errorCode: 0, error: error});
                    }
                });
            };

            var updateModifierOptions = function (modifier, parentItem) {
                var originalModifier = _.findWhere(parentItem.children, {
                    locationServicePeriodMenuId: modifier.locationServicePeriodMenuId
                }) || {};
                var changes = _checkModifierOptionChanges(modifier, originalModifier);

                var allChangesPromises = [];

                if (changes.deleted.length) {
                    var childIds = _.map(changes.deleted, function (option) {
                        return option.locationServicePeriodMenuId;
                    });
                    var associations = {
                        locationId: _locationId,
                        parentIds: [modifier.locationServicePeriodMenuId],
                        childIds: childIds
                    };

                    allChangesPromises.push(Locations.removeAssociations(associations).$promise.then(function () {
                    }));
                }

                if (changes.updated.length) {
                    var updatedPromise = [];
                    _.each(changes.updated, function (option) {
                        updatedPromise.push(
                            Inventory.updateMenuItem({menuItemId: option.locationServicePeriodMenuId}, option).$promise
                        );
                    });

                    allChangesPromises.push($q.all(updatedPromise));
                }

                if (changes.added.length) {
                    var addedPromise = [];
                    _.each(changes.added, function (option) {
                        option.subtype = 'modifier_option';

                        addedPromise.push(Inventory.addMenuItem({}, option).$promise);
                    });

                    var associationPromise = $q.all(addedPromise)
                        .then(function (responses) {
                            var childIds = _.map(responses, function (response) {
                                return response.locationServicePeriodMenuId;
                            });

                            var associations = {
                                locationId: _locationId,
                                parentIds: [modifier.locationServicePeriodMenuId],
                                childIds: childIds
                            };

                            return Locations.addAssociations(associations).$promise;
                        });

                    allChangesPromises.push(associationPromise.then(function () {
                    }));
                }

                return $q.all(allChangesPromises);
            };
            var _checkModifierOptionChanges = function (modifier, originalModifier) {
                var originalOptions = originalModifier.children || [];
                var newOptions = modifier.children || [];

                var changes = {
                    deleted: [],
                    updated: [],
                    added: []
                };

                _.each(originalOptions, function (option) {
                    var newOption = _.findWhere(newOptions, {locationServicePeriodMenuId: option.locationServicePeriodMenuId});

                    if (newOption) {
                        if (newOption.name != option.name
                            || newOption.price != option.price
                            || newOption.taxRateId != option.taxRateId
                            || newOption.taxRules != option.taxRules) {
                            changes.updated.push(newOption);
                        }
                    } else {
                       changes.deleted.push(option);
                    }
                });

                changes.added = _.filter(newOptions, function (newOption) {
                    return !newOption.locationServicePeriodMenuId;
                });

                return changes;
            };

            var getMenuItemImages = function (menuItem) {
                if (!menuItem.locationServicePeriodMenuId) {
                    return $q.reject();
                }

                return Inventory.getMenuItemImages({
                    menuItemId: menuItem.locationServicePeriodMenuId
                })
                .$promise
                .then(function (images) {
                    return images;
                });
            };

            var addMenuItemImages = function (menuItem) {
                if (!menuItem.locationServicePeriodMenuId) {
                    return $q.reject();
                }

                return Inventory.addMenuItemImages({
                    menuItemId: menuItem.locationServicePeriodMenuId
                })
                .$promise
                .then(function (images) {
                    return images;
                });
            };

            var updateMenuItemImages = function (menuItem) {
                if (!menuItem.locationServicePeriodMenuId) {
                    return $q.reject();
                }

                return Inventory.updateMenuItemImages({
                    menuItemId: menuItem.locationServicePeriodMenuId
                })
                .$promise
                .then(function (images) {
                    return images;
                });
            };

            var deleteMenuItemImages = function (menuItem) {
                if (!menuItem.locationServicePeriodMenuId) {
                    return $q.reject();
                }

                return Inventory.deleteMenuItemImages({
                    menuItemId: menuItem.locationServicePeriodMenuId
                })
                .$promise
                .then(function (images) {
                    return images;
                });
            };


            var getMenuItemTagNames = function (menuItem, isNew = false) {
                if (!menuItem.locationServicePeriodMenuId) {
                    // Commented By Akash Mehta on December 8 2020
                    // If its a new item, we don't need to reject as the item
                    // will not have a locationServicePeriodMenuId
                    return (isNew) ? $q.resolve() : $q.reject();
                }

                return Inventory.getMenuItemTagNames({
                    menuItemId: menuItem.locationServicePeriodMenuId
                })
                .$promise
                .then(function (tagNames) {
                    return tagNames;
                });
            };

            var _refreshProducts = function () {
                var p = angular.copy(products);
                products.length = 0;
                products.push(...p);
            };

            var updateMenuMobile = function (locationId) {
                // Update mobile order menu on backend
                Locations.updateMenuMobile({
                    locationId: locationId
                });
            };

            var isTaxRuleEnabled = function (name) {
                return TaxRuleService.isEnabled(name, _company, _locationDetail);
            };
            var getTaxRule = function (name) {
                return TaxRuleService.get(name);
            };

            var updateLocationAttributes = function (index, pageName) {
                var attributeObject = {};
                attributeObject['page' + index] = pageName;

                return Locations.updateLocationAttributes({
                    attributes: attributeObject,
                    locationId: _locationId
                }).$promise.then(function (response) {
                    Object.assign(_locationDetail, response);
                });
            };

            $rootScope.$on('auth-loginConfirmed', function (event, user) {
                reset();
            });

            var getShopifyProducts = function (storeId) {
                return Shopify.getProducts({storeId: storeId}, function (response) {
                    return response;
                }).$promise;
            };

            var importFromShopify = function (products) {
                return Shopify.updateProducts({},
                    {
                        products: products,
                        locationId: _locationId
                    }).$promise;
            };

            var getShopifyStores = function () {
                return Shopify.getStores({}, function (response) {
                    return response;
                }).$promise;
            };


            var assignPrinterToItem = function (item) {
                if (!item || !item.locationServicePeriodMenuId) {
                    PosAlertService.showAlertByName('general-error', {
                        message: 'Error while assiging printer to items'
                    });
                    return Promise.reject();
                }

                var itemToSave = angular.copy(item);
                itemToSave.locationIdToUpdateFor = _locationId;

                return Inventory.updateMenuItemVariationPrinterSettings({menuItemId: item.locationServicePeriodMenuId}, itemToSave).$promise
                    .then(function (response) {
                        return Promise.resolve(response);
                    }, function (error) {
                        PosAlertService.showAlertByName('general-error', {
                            message: 'Error while assiging printer to items'
                        });

                        return Promise.reject(error);
                    });
            };

            // These constants are from the backend
            // TaxRate entity. If the strings change there
            // please do update them here as well.
            const taxRateFidsConsts = {
                DEFAULT: 'DEFAULT',
                NOTAX: 'NOTAX'
            };

            return {
                locationDetail: _locationDetail,

                products: products,
                productCategories: productCategories,
                productModifiers: productModifiers,

                allItems: allItems,
                allCategories: allCategories,
                allModifiers: allModifiers,
                unsortedItems: unsortedItems,

                loadLocations: loadLocations,
                loadProducts: loadProducts,
                loadAllItems: loadAllItems,
                loadTaxRates: loadTaxRates,
                loadLocationPrinters: loadLocationPrinters,
                loadTags: loadTags,
                taxRates: taxRates,
                taxRules: taxRules,
                printers: printers,
                tags: tags,
                loadMenus: loadMenus,
                menus: menuArr,

                loadMenuItem: loadMenuItem,
                addMenuItem: addMenuItem,
                addMenuItemToCategories: addMenuItemToCategories,
                removeMenuItemFromCategories: removeMenuItemFromCategories,
                editMenuItem: editMenuItem,
                moveMenuItem: moveMenuItem,
                batchMoveMenuItem: batchMoveMenuItem,
                editMenuItemPriceReplacedBy: editMenuItemPriceReplacedBy,
                deleteMenuItem: deleteMenuItem,
                getMenuItemCategoryIds: getMenuItemCategoryIds,

                addMenuCategory: addMenuCategory,
                addMenuCategoryToCategory: addMenuCategoryToCategory,
                editMenuCategory: editMenuCategory,

                addModifier: addModifier,
                editModifier: editModifier,
                attachModifier: attachModifier,
                removeModifiers: removeModifiers,

                getMenuItemImages: getMenuItemImages,
                addMenuItemImages: addMenuItemImages,
                updateMenuItemImages: updateMenuItemImages,
                deleteMenuItemImages: deleteMenuItemImages,

                getMenuItemTagNames: getMenuItemTagNames,

                isTaxRuleEnabled: isTaxRuleEnabled,
                getTaxRule: getTaxRule,

                getShopifyProducts: getShopifyProducts,
                getShopifyStores: getShopifyStores,
                importFromShopify: importFromShopify,
                updateLocationAttributes: updateLocationAttributes,
                isMasterMenu: isMasterMenu,
                taxRateFidsConsts: taxRateFidsConsts,
                updateMenuMobile: updateMenuMobile,
                searchMenuItemsByUpc: searchMenuItemsByUpc,
                assignPrinterToItem: assignPrinterToItem,
                setSelectedMenuId: setSelectedMenuId,
                getSelectedMenuId: getSelectedMenuId
            };
        }]);
};
