module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ScanCardTerminalModalController', [
        '$scope',
        '$modal',
        '$modalInstance',
        '$rootScope',
        'terminal',
        function ($scope,
            $modal,
            $modalInstance,
            $rootScope,
            terminal) {

            $scope.isDoneScanning = false;
            $scope.isError = false;

            $rootScope.$on('cardTerminal::autoSettle', function (event, result) {
                event.stopPropagation();
                $scope.autoSettleData = result.data;

                if (!result.isBalanced) {
                    $scope.isDoneScanning = true;
                    return;
                }

                $scope.$close(result);
            });

            $scope.forceSettle = function () {
                let forceClose = true;
                $scope.isDoneScanning = false;

                terminal.autoSettle(forceClose).then(function (result) {
                    result.forceClose = forceClose;
                    $scope.$close(result);
                }, function (err) {
                    $scope.$dismiss();
                });
            };
        }]);
};

