'use strict';

module.exports = function (freshideasSetup) {

    freshideasSetup.controller('EditSrmDeviceCtrl', [
    '$scope',
    '$modal',
    '$modalInstance',
    '$translate',
    'PosAlertService',
    'Locations',
    'isNew',
    'currentSrmDevice',
    'setDefault',
    function ($scope,
        $modal,
        $modalInstance,
        $translate,
        PosAlertService,
        Locations,
        isNew,
        currentSrmDevice,
        setDefault) {

            $scope.isNew = isNew;

            $scope.currentSrmDevice = {
                'id': currentSrmDevice.id || null,
                'defaultDevice': setDefault,
                'companyId': currentSrmDevice.companyId,
                'name': currentSrmDevice.name || '',
                'userPassword': '',
                'ipAddress': currentSrmDevice.ipAddress || '',
                'createdAt': currentSrmDevice.createdAt || null
            };

            $scope.init = () => {
            };

            $scope.errorMsg = '';
            $scope.saveSrmDevice = () => {
                // show an error if no ip address.
                if (!$scope.currentSrmDevice.ipAddress || !$scope.currentSrmDevice.name) {
                    $scope.errorMsg = $translate.instant('setupWizard.merchant.mevbox.error.required.label');
                    return;
                }

                Locations.updateSrmDevice($scope.currentSrmDevice).$promise
                .then((result) => {
                    $scope.errorMsg = '';
                    $modalInstance.close(result);
                })
                .catch((error) => {
                    if (error.data) {
                        // display an error msg to users.
                        $scope.errorMsg = $translate.instant('setupWizard.merchant.mevbox.error.generic.label');
                    }
                });
            };

            const confirmDeleteSrmDevice = () => {
                Locations.deleteSrmDevice({'id': $scope.currentSrmDevice.id}).$promise
                .then((result) => {
                    $scope.errorMsg = '';
                    $modalInstance.close(result);
                })
                .catch((error) => {
                    if (error.data) {
                        // display an error msg to users.
                        $scope.errorMsg = $translate.instant('setupWizard.merchant.mevbox.error.generic.label');
                    }
                });
            };
            $scope.deleteSrmDevice = () => {
                // this should never occur as the delete btn should only show if an id exists.
                // As an extra layer of check, I've added it just in case.
                if (!$scope.currentSrmDevice.id) {
                    return;
                }
                PosAlertService.showAlertByName('general-alert', {
                    title: $translate.instant('setupWizard.merchant.mevbox.delete.title'),
                    message: $translate.instant(
                        'setupWizard.merchant.mevbox.delete.confirmation',
                        {srmName: $scope.currentSrmDevice.name}
                    ),
                    modalCallback: function () {
                        confirmDeleteSrmDevice();
                    }
                });
            };

            $scope.init();
    }]);
};
