'use strict';


module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosStartMobileOrderCtrl', [
        '$scope',
        '$log',
        '$rootScope',
        'CurrentSession',
        'Lucova',
        'LucovaWebSocket',
        'SmbPosService',
        'KdsService',
        'Pure',
        'PosStatusService',
        'PosAlertService',
        function ($scope, $log, $rootScope, CurrentSession, Lucova, LucovaWebSocket, SmbPosService, KdsService, Pure, PosStatusService, PosAlertService) {
            $scope.smbPos = SmbPosService;
            $scope.LucovaWebSocket = LucovaWebSocket;

            $scope.options = {
                carousel: {
                    interval: 5000,
                    noWrap: false,
                }
            };

            $scope.init = function () {
            };

            $scope.getPrintPendingOrders = function () {
                return SmbPosService.getPrintPendingOrders();
            };

            $scope.clearPreorder = function () {
                SmbPosService.clearPreorder();
            };

            $scope.hasSelectedPreorder = function () {
                return SmbPosService.hasSelectedPreorder();
            };

            $scope.hasStatus = function (statuses, preorder) {
                var states = _.filter(statuses, function (status) {
                    if (preorder) {
                        return preorder.status === status;
                    }
                 });

                return states.length > 0;
            };

            $scope.hasActiveRequest = false;
            var timeout;

            function acquireLock () {
                if ($scope.hasActiveRequest) {
                    return false;
                }
                $scope.hasActiveRequest = true;
                timeout = setTimeout(function () {
                    timeout = undefined;
                    $scope.hasActiveRequest = false;
                }, 5000);

                return true;
            }

            function unlockButtons () {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = undefined;
                }
                $scope.hasActiveRequest = false;
            }

            var pushKdsMessage = function (payload) {
                // Send update through Socket.IO
                try {
                    Lucova.manager().pushMessage({
                        terminalId: Lucova.getTerminalId(),
                        data: payload
                    },
                        function (response) { },
                        function (error) { }
                    );
                } catch (e) {
                    console.log(e);
                }
            };

            $scope.acceptMobileOrder = function (mobileOrderUser) {
                if (!acquireLock()) {
                    return;
                }

                var preorder = mobileOrderUser.lucovaUser.preorder[0];

                LucovaWebSocket.acceptPreorder(preorder,
                    function (response) {
                        unlockButtons();
                        var preorderIndex = SmbPosService.preorders.indexOf(mobileOrderUser);
                        if (preorderIndex > -1) {
                            SmbPosService.preorders.splice(preorderIndex, 1);
                        }

                        $scope.clearPreorder();
                        SmbPosService.loadLucovaUsers();
                    },
                    function (err) {
                        unlockButtons();
                    });

                var currentCompany = CurrentSession.getCompany();
                if (!currentCompany) {
                    $log.error('Failed to get company from CurrentSession when accepting order.');
                    return;
                }

                var isKdsEnabled = currentCompany.attributes.kds_enabled === 'true';
                if (!isKdsEnabled) {
                    return;
                }

                if (!currentCompany.locationId) {
                    $log.error('Failed to get location ID from CurrentSession when accepting order.');
                    return;
                }

                var locationId = currentCompany.locationId;
                var receiptItems = [];
                for (var item of preorder.items) {
                    var receiptItem = {
                        itemName: item.name,
                        itemQuantity: item.quantity,
                        itemNotes: item.note,

                    };
                    receiptItems.push(receiptItem);
                }
                var kdsDataObject = {
                    uuid: Pure.generateUuid(),
                    guestTransaction: false,
                    locationId: locationId,
                    patronName: preorder.first_name,
                    patronPhotoUrl: mobileOrderUser.lucovaUser.photo_url,
                    status: 0,
                    receipt: receiptItems,
                    timestamp: new Date().getTime(),
                    lastUpdated: new Date().getTime(),
                    //  TODO: Get the actual receipt counter from Lucova backend
                    receiptCounter: preorder.receipt_counter || 0
                };
                KdsService.addReceiptFiit(kdsDataObject).then(function (response) {
                    pushKdsMessage(kdsDataObject);
                }, function (error) {
                    pushKdsMessage(kdsDataObject);
                    console.log(error);
                });
            };

            $scope.cancelMobileOrder = function (mobileOrderUser) {
                if (!acquireLock()) {
                    return;
                }

                var preorder = mobileOrderUser.lucovaUser.preorder[0];
                var id = preorder._id;

                Lucova.manager().cancelMobileOrder({}, {
                        preorder_id: id
                    }, function (response) {
                        unlockButtons();

                        var preorderIndex = SmbPosService.preorders.indexOf(mobileOrderUser);
                        if (preorderIndex > -1) {
                            SmbPosService.preorders.splice(preorderIndex, 1);
                        }
                        preorder.status = 'cancelled';

                        $scope.clearPreorder();
                        SmbPosService.loadLucovaUsers();
                    }, function (error) {
                        unlockButtons();
                });
            };

            $scope.restartMobileOrder = function (id) {
                if (!acquireLock()) {
                    return;
                }
                Lucova.manager().acknowledgeMobileOrder({}, {
                        preorder_id: id
                    }, function (response) {
                        unlockButtons();
                        LucovaWebSocket.getUsers();
                    }, function (error) {
                        unlockButtons();
                });
            };

            $scope.completeMobileOrder = function (id) {
                if (PosStatusService.isOffline()) {
                    PosAlertService.showAlertByName('general', {
                        title: 'general.error.offline.ttl',
                        message: 'pos.mobile.order.offline.description'
                    });
                    return;
                }
                if (!acquireLock()) {
                    return;
                }
                Lucova.manager().completeMobileOrder({}, {
                    preorder_id: id
                }, function (response) {
                    unlockButtons();

                    $scope.clearPreorder();
                    SmbPosService.loadLucovaUsers();
                }, function (error) {
                    unlockButtons();
                });
            };

            $scope.getPatronPhoto = function (photoUrl) {
                return SmbPosService.getPatronPhoto(photoUrl);
            };

            $scope.init();
        }
    ]);
};
