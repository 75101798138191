'use strict';

const angular = require('angular');

export default angular
    .module('freshideas.resources.bridged-promise', [])
    .factory('BridgedPromise', ['Pure', function (
        Pure) {
        var _self = {};
        var initialized = false;

        var initEventListener = function () {
            if (initialized) {
                return;
            }
            initialized = true;

            window.addEventListener('bridgedMessage', function (response) {
                console.log('Received Bridge Message:', response);

                var data = response.detail;

                window.sendEventData(data.message.bridgeId, data);
            });
        };

        _self.dispatch = function (type, payload) {
            console.log('Dispatching ' + type + ' event,', 'Payload:', payload);

            initEventListener();

            var message = {
                payload: payload,
                type: type,
                bridgeId: 'bridge_' + Pure.generateUuid()
            };

            var promise = new Promise(function (resolve, reject) {
                window.addEventListener(message.bridgeId, function (response) {
                    console.log('Received Bridge Message:', response);
                    var data = response.detail.data;

                    if (data.success) {
                        resolve(data.message);
                    } else {
                        reject();
                    }

                    window.removeEventListener(message.bridgeId);
                });

                window.webkit.messageHandlers.bridge.postMessage(message);
            });


            return promise;
        };

        return _self;
    }]);
