'use strict';

/* eslint-disable max-len, no-tabs, semi, comma-dangle, quotes */
/* eslint-disable no-mixed-spaces-and-tabs, no-unused-vars */
/* eslint-disable space-before-function-paren, no-redeclare */
/* eslint-disable object-curly-spacing */

const angular = require('angular');
const Decimal = require('decimal.js').default;
const SharedDataService = require('../../external/pos.data-service.js');

module.exports = function (posModule) {

    posModule
        .controller('PosTransactionRefundTenderCtrl', [
        	'$scope',
            '$modal',
            'transaction',
        	function ($scope, $modal, transaction) {
                $scope.transaction = transaction;
                $scope.availableTenders = {};

                var loadTenders = function () {
                    $scope.proceedToReview = false;
                    for (var tender of $scope.transaction.tenders) {
                        var tenderItem = {};
                        tenderItem.transactionTenderId = tender.transactionTenderId;
                        tenderItem.transactionType = tender.transactionType;
                        if ($scope.availableTenders[tender.transactionType] != undefined || $scope.availableTenders[tender.transactionType] != null) {
                            var previousAmt = $scope.availableTenders[tender.transactionType].amountCents;
                            tenderItem.amountCents = tender.amountCents + previousAmt;
                        } else {
                            tenderItem.amountCents = tender.amountCents;
                        }
                        $scope.availableTenders[tender.transactionType] = tenderItem;
                    }

                    var usedTenders = {};
                    _.each($scope.transaction.refundTransactions, function(refTrans) {
                        _.each(refTrans.tenders, function(tender) {
                            if (usedTenders[tender.transactionType] != null) {
                                usedTenders[tender.transactionType] += tender.amountCents;
                            } else {
                                usedTenders[tender.transactionType] = tender.amountCents;
                            }
                        });
                    });

                    _.each($scope.availableTenders, function(tender) {
                        if (usedTenders[tender.transactionType] != undefined || usedTenders[tender.transactionType] != null) {
                            $scope.availableTenders[tender.transactionType].amountCents -= usedTenders[tender.transactionType];
                        }
                        $scope.availableTenders[tender.transactionType].isSelected = false;
                        $scope.availableTenders[tender.transactionType].selectedAmount = '0.00';
                        $scope.availableTenders[tender.transactionType].amount = new Decimal(tender.amountCents / 100)
                            .toNearest(SharedDataService.baseDollar)
                            .toNumber()
                    });
                };

                $scope.tenderSelected = function (tender) {
                    if ($scope.availableTenders[tender.transactionType].isSelected != true) {
                        $scope.availableTenders[tender.transactionType].isSelected = true;
                        var refundAmtCents = transaction.refundAmount * 100;
                        if (refundAmtCents < $scope.availableTenders[tender.transactionType].amountCents) {
                            $scope.availableTenders[tender.transactionType].selectedAmount = new Decimal(refundAmtCents / 100)
                                .toNearest(SharedDataService.baseDollar)
                                .toNumber();
                            } else {
                            $scope.availableTenders[tender.transactionType].selectedAmount = new Decimal($scope.availableTenders[tender.transactionType].amountCents / 100)
                                .toNearest(SharedDataService.baseDollar)
                                .toNumber();
                            }
                        $scope.proceedToReview = true;
                    } else {
                        $scope.availableTenders[tender.transactionType].isSelected = false;
                        $scope.availableTenders[tender.transactionType].selectedAmount = '0.00';
                        $scope.proceedToReview = false;
                        _.each($scope.availableTenders, function(tender) {
                            if (tender.isSelected == true) {
                                $scope.proceedToReview = true;
                            }
                        });
                    }

                    validateTendersSelected();
                };

                $scope.editTender = function(tender) {

                    return showPinPad('currency', tender.selectedAmount, function (editedValue) {
                        tender.selectedAmount = editedValue;
                        tender.isSelected = (tender.selectedAmount > 0.00);

                        validateTendersSelected();
                    });
                };

                function validateTendersSelected () {
                    var decimalTotalSelectedAmount = new Decimal(0.00);
                    _.each($scope.availableTenders, function (tender) {
                        if (tender.isSelected == true) {
                            var decimalSelectedAmount = new Decimal(tender.selectedAmount);
                            decimalTotalSelectedAmount = decimalTotalSelectedAmount
                                .plus(decimalSelectedAmount);
                        }
                    })

                    var totalSelectedAmount = decimalTotalSelectedAmount.toNearest(SharedDataService.baseDollar).toNumber();
                    if (totalSelectedAmount < transaction.refundAmount) {
                        $scope.proceedToReview = false;
                    } else if (totalSelectedAmount > transaction.refundAmount) {
                        $scope.proceedToReview = false;
                    } else {
                        $scope.proceedToReview = true;
                    }
                }

                function showPinPad (type, model, callback, validator, note) {
                    var modalInstance = $modal.open({
                        templateUrl: 'pos/pos.numpad.tpl.html',
                        controller: 'PosNumpadCtrl',
                        animation: false,
                        windowClass: 'modal-numpad modal-fullscreen-transparent modal-right',
                        resolve: {
                            initial: function () {
                                return model;
                            },
                            type: function () {
                                return type;
                            },
                            note: function () {
                                return note;
                            }
                        },
                        backdrop: true
                    });
                    modalInstance.lucovaValidator = validator;

                    return modalInstance.result.then(function (value) {
                        if (callback) {
                            callback(value);
                        }
                    });
                }

                $scope.transactionDiscount = {
                    percentage: 0
                };

                $scope.reviewRefundTransaction = function() {
                    var selectedTenders = {};
                    _.each($scope.availableTenders, function (tender) {
                        if (tender.isSelected == true) {
                            selectedTenders[tender.transactionType] = tender;
                            selectedTenders[tender.transactionType].selectedAmountCents = (tender.selectedAmount * 100);
                        }
                    })
                    $scope.$close(selectedTenders);
                }

                $scope.init = function () {
                    loadTenders();
                };

                $scope.init();
        	}
        ]);
};
