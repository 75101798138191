'use strict';

/* globals ngGridFlexibleHeightPlugin */

module.exports = function (freshideasLocation) {

    freshideasLocation.controller('LocationInvoice', [
        '$scope',
        '$modal',
        '$q',
        '$translate',
        '$location',
        'Locations',
        'Company',
        'Inventory',
        'notificationTranslationHelper',
        'UiGridTemplateService',
        'ErrorUtil',
        'Utils',
        function ($scope, $modal, $q, $translate, $location, Locations, Company, Inventory, notificationTranslationHelper, UiGridTemplateService, ErrorUtil, Utils) {

            var init = function () {
                console.log('Started Inventory');
            };

            $scope.$watch('locationFactory.location', function (location) {
                if (location) {
                    $scope.loadInvoices();
                } else {
                    $scope.invoices = [];
                }
            });

            $scope.invoicePagingOptions = {
                currentRowNumber: 0,
                currentPage: 1,
                pageSize: 10,
                startRecord: 0,
                endRecord: 0,
                pageSizeOptions: Utils.buildDefaultPageSizes()
            };
            $scope.loadInvoices = function () {
                var offset = Utils.serverSidePagingRowNumber($scope.invoicePagingOptions.currentPage, $scope.invoicePagingOptions.pageSize);
                var limit = $scope.invoicePagingOptions.pageSize;
                Inventory.getInvoices({
                    'locationId': $scope.locationFactory.location.id,
                    'offset': offset,
                    'limit': limit
                }, function (response) {
                    $scope.invoicePagingOptions.totalItems = Utils.serverSideTotalItems(response.entries.length, $scope.invoicePagingOptions.currentPage, $scope.invoicePagingOptions.pageSize);
                    $scope.invoicePagingOptions.startRecord = Utils.serverSideStartRecord($scope.invoicePagingOptions.currentPage, $scope.invoicePagingOptions.pageSize);
                    $scope.invoicePagingOptions.endRecord = Utils.serverSideEndRecord(response.entries.length, $scope.invoicePagingOptions.currentPage, $scope.invoicePagingOptions.pageSize);
                    $scope.invoices = response.entries;
                });
            };

            $scope.invoiceUiGridOptions = {
                columnDefs: [
                    {
                        field: 'invoiceId',
                        displayName: 'invoice.invoiceId',
                        width: '*',
                        headerCellTemplate: UiGridTemplateService.translateHeaderTemplate
                    },
                    {
                        field: 'vendor',
                        displayName: 'invoice.vendor',
                        width: '**',
                        headerCellTemplate: UiGridTemplateService.translateHeaderTemplate
                    },
                    {
                        field: 'invoiceDate',
                        displayName: 'invoice.invoiceDate',
                        width: '*',
                        headerCellTemplate: UiGridTemplateService.translateHeaderTemplate,
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{COL_FIELD | date:\'short\'}}</span></div>'
                    },
                    {
                        field: 'invoiceNumber',
                        displayName: 'invoice.invoiceNumber',
                        width: '**',
                        headerCellTemplate: UiGridTemplateService.translateHeaderTemplate
                    },
                    {
                        field: 'action',
                        displayName: 'general.actions',
                        width: '80',
                        headerCellTemplate: UiGridTemplateService.translateHeaderTemplate,
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text><div class="action-buttons">' + '<a class="blue" ng-click="grid.appScope.editInvoice(row.entity)" has-perm resource="inventory:edit_invoice"><i class="fa fa-pencil bigger-130"></i></a>' + '<a class="red" confirm-modal confirm-message="{{\'invoice.confirmDelete\' | translate}}" confirm-title="{{\'invoice.confirmDelete.title\' | translate}}" on-confirm="deleteInvoice(row.entity)" has-perm resource="inventory:delete_invoice"><i class="fa fa-trash-o bigger-130"></i></a>' + '</div></span></div>'
                    }
                ],
                plugins: [new ngGridFlexibleHeightPlugin({minHeight: 520})],
                showGridFooter: true,
                autoResize: true,
                gridFooterHeight: 32,
                multiSelect: true,
                showSelectionCheckbox: true,
                enableColumnResize: true,
                selectedItems: $scope.selectedInvoices,
                enableSorting: true,
                selectWithCheckboxOnly: true,
                enableHighlighting: true,
                gridFooterTemplate: '<div class="grid-footer"><div class="col-xs-4"></div><div class="col-xs-4"><pager align="false" ng-model="grid.appScope.invoicePagingOptions.currentPage" ' + 'total-items="grid.appScope.invoicePagingOptions.totalItems" items-per-page="grid.appScope.inventoryPagingOptions.pagesize" ng-change="grid.appScope.loadInvoices();"></pager>' + '</div><div class="col-xs-4 align-right"><span translate="general.records"/> {{grid.appScope.inventoryPagingOptions.startRecord}} - {{grid.appScope.inventoryPagingOptions.endRecord}}</div></div>',
                data: 'invoices'
            };

            $scope.addNewInvoice = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalEditInvoice.tpl.html',
                    controller: 'EditInvoiceCtrl',
                    windowClass: 'modal-90',
                    resolve: {
                        invoice: function () {
                            return undefined;
                        },
                        selectedCompanyId: function () {
                            return $scope.locationFactory.companyId;
                        },
                        isEdit: function () {
                            return false;
                        }
                    },
                    backdrop: 'static'
                });
                modalInstance.result.then(function (location) {
                    notificationTranslationHelper.notifyMessage('invoice.addInvoice.success');
                    $scope.loadInvoices();
                });
            };
            $scope.editInvoice = function (invoice) {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalEditInvoice.tpl.html',
                    controller: 'EditInvoiceCtrl',
                    windowClass: 'modal-90',
                    resolve: {
                        invoice: function () {
                            return invoice;
                        },
                        selectedCompanyId: function () {
                            return $scope.selectedCompanyId;
                        },
                        isEdit: function () {
                            return true;
                        }
                    },
                    backdrop: 'static'
                });
                modalInstance.result.then(function (invoice) {
                    notificationTranslationHelper.notifyMessage('invoice.editInvoice.success', {name: invoice.invoiceNumber});
                    $scope.loadInvoices();
                });
            };

            init();
        }
    ]);
};
