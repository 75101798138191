'use strict';

const Decimal = require('decimal.js').default;

module.exports = function (freshideasSetup) {

    freshideasSetup.controller('DiscountsModifyCtrl', [
    '$scope',
    '$modal',
    '$translate',
    '$timeout',
    '$filter',
    'Users',
    'Security',
    'Settings',
    'PosAlertService',
    'discounts',
    'selectedDiscount',
    'CompanyAttributesService',
    function ($scope, $modal, $translate, $timeout, $filter, Users, Security, Settings, PosAlertService, discounts, selectedDiscount, CompanyAttributesService) {
        $scope.isSaveValid = true;
        $scope.originalDiscount = {};
        $scope.enhanceSecurityMeasures = CompanyAttributesService.enhanceSecurityMeasures();

        $scope.init = function (discount) {
            $scope.isEdit = !(discount.isNew);
            $scope.selectedCompanyId = discount.companyId;

            if (!$scope.isEdit) {
                $scope.toEdit = angular.copy(discount);

            } else {
                $scope.toEdit = angular.copy(discount);
                $scope.originalDiscount = discount;
                $scope.percentDisplayValue = new Decimal($scope.toEdit.discountPercentage)
                    .mul(100)
                    .toDecimalPlaces(2)
                    .toNumber() + '%';
            }

            $scope.isLoadingRoles = true;
            Settings.getRoles({companyId: $scope.selectedCompanyId}, function (response) {
                $scope.roleTypes = _.map(response, function (e) {
                    return {
                        id: e.roleId,
                        name: e.roleName
                    };
                });
                if (!$scope.isEdit) {
                    _.each($scope.roleTypes, function (roleType) {
                        roleType.isEnabled = true;
                    });
                    $scope.isLoadingRoles = false;
                } else {
                    _.each($scope.toEdit.permissions, function (permission) {
                        var role = $scope.roleTypes.find(function (role) {
                            return role.id == permission.roleId;
                        });
                        if (role) {
                            role.isEnabled = permission.isEnabled;
                        }
                    });
                    $scope.isLoadingRoles = false;
                }
            });
        };

        $scope.isValid = function () {
            if (!$scope.toEdit.discountName) {
                PosAlertService.showAlertByName('add-user-fail', {
                    message: 'general.error.invalid-discount-name.ttl',
                    title: 'general.error.add-discount-fail.ttl'
                });
                return false;
            }

            if (!$scope.toEdit.discountPercentage) {
                PosAlertService.showAlertByName('add-user-fail', {
                    message: 'general.error.invalid-discount-value.ttl',
                    title: 'general.error.add-discount-fail.ttl'
                });
                return false;
            }

            return true;
        };

        var isSavingDiscount = false;
        $scope.saveDiscount = function () {
            if (isSavingDiscount) {
                return;
            }

            if (!$scope.isValid()) {
                return;
            }

            isSavingDiscount = true;
            var timerToCancel = $timeout(function () {
                isSavingDiscount = false;
            }, 5000);

            if (!$scope.isEdit) {
                $scope.addDiscount(timerToCancel);
            } else {
                $scope.updateDiscount(timerToCancel);
            }
        };

        $scope.toggleDiscountStatus = function () {
            if (!$scope.toEdit.active) {
                var modalInstance = $modal.open({
                    templateUrl: 'setup/modals/wizardEdit.confirmation.html',
                    animation: false,
                    backdrop: 'static',
                    controller: 'SetupConfirmationModalCtrl',
                    windowClass: 'setup_confirm_modal',
                    keyboard: false,
                    resolve: {
                        modalType: function () {
                            return 'CONFIRM_DISCOUNT_DEACTIVATE';
                        }
                    }
                    });
                    modalInstance.result.then(function (response) {
                        $scope.toEdit.status = 'INACTIVE';
                        $scope.toEdit.active = false;
                        $scope.checkIfDiscountEdited();
                    }, function () {
                        $scope.toEdit.active = true;
                        $scope.checkIfDiscountEdited();
                    });

            } else {
                $scope.toEdit.status = 'ACTIVE';
                $scope.toEdit.active = true;
                $scope.checkIfDiscountEdited();
            }
        };

        $scope.toggleTransactionLevel = function () {
            $scope.toEdit.transactionLevel = !$scope.toEdit.transactionLevel;
        };
        $scope.toggleItemLevel = function () {
            $scope.toEdit.itemLevel = !$scope.toEdit.itemLevel;
        };

        $scope.togglePinRequired = function () {
            $scope.checkIfDiscountEdited();
        };

        $scope.addDiscount = function (timerToCancel) {
            var updatedDiscount = angular.copy($scope.toEdit);
            delete updatedDiscount.isNew;
            var permissions = [];
            _.each($scope.roleTypes, function (roleType) {
                var permission = {};
                permission.roleId = roleType.id;
                permission.isEnabled = roleType.isEnabled;
                permissions.push(permission);
            });

            updatedDiscount.permissions = permissions;
            Settings.addDiscount({}, updatedDiscount, function (success) {
                isSavingDiscount = false;
                if (timerToCancel) {
                    $timeout.cancel(timerToCancel);
                }
                $scope.$close();
            }, function (error) {
                isSavingDiscount = false;
                if (timerToCancel) {
                    $timeout.cancel(timerToCancel);
                }
                var data = error.data || {};
                var exception = data.exception || {};
                PosAlertService.showAlertByName('add-user-fail', {
                    message: exception.message || 'general.error.add-discount-fail.msg',
                    title: 'general.error.add-discount-fail.ttl'
                });
            });
        };

        $scope.updateDiscount = function (timerToCancel) {
            var updatedDiscount = angular.copy($scope.toEdit);
            delete updatedDiscount.isNew;
            delete updatedDiscount.status;
            var permissions = updatedDiscount.permissions;
            _.each($scope.roleTypes, function (roleType) {
                var permission = permissions.find(function (perm) {
                    return perm.roleId == roleType.id;
                });
                if (permission) {
                    permission.isEnabled = roleType.isEnabled;
                } else {
                    var newPermission = {};
                    newPermission.roleId = roleType.id;
                    newPermission.isEnabled = roleType.isEnabled || false;
                    permissions.push(newPermission);
                }
            });
            updatedDiscount.permissions = permissions;
            Settings.updateDiscount({}, updatedDiscount, function (success) {
                isSavingDiscount = false;
                if (timerToCancel) {
                    $timeout.cancel(timerToCancel);
                }
                $scope.$close();
            }, function (error) {
                isSavingDiscount = false;
                if (timerToCancel) {
                    $timeout.cancel(timerToCancel);
                }
                var data = error.data || {};
                var exception = data.exception || {};
                PosAlertService.showAlertByName('add-user-fail', {
                    message: exception.message || 'general.error.add-discount-fail.msg',
                    title: 'general.error.add-discount-fail.ttl'
                });
            });
        };

        $scope.checkIfDiscountEdited = function () {
            /* eslint-disable guard-for-in */
            discounts.hasChanged = false;

            if (!angular.equals($scope.toEdit, $scope.originalDiscount)) {
                discounts.hasChanged = true;
            }

            /* eslint-enable */
        };

        $scope.togglePermission = function (role) {
            role.isEnabled = !role.isEnabled;
            $scope.checkIfDiscountEdited();
        };

        $scope.disableToggle = function () {
            return true;
        };

        /**
        ** Function to change the value of the discount text area
        ** This function is called whenever any discount type button is clicked
        ** Based on the discount type selected, it will open the respective numpad and
        ** on saving the amount, will build the discount again
        **/
        $scope.editDiscount = function () {
            var percentValidator = function (oldVal, newVal) {
                if (parseInt(oldVal + '' + newVal) > 100) {
                    return false;
                }
                return true;
            };

            var callback = function (editedValue) {
                $scope.toEdit.discountPercentage = new Decimal(editedValue).toDecimalPlaces(2).toNumber();
                $scope.percentDisplayValue = new Decimal(editedValue).mul(100).toDecimalPlaces(2).toNumber() + '%';
                $scope.checkIfDiscountEdited();
            };

            return showPinPad('percentage', $scope.toEdit.discountPercentage, callback, percentValidator);
        };

        /**
        ** Function to open pinpad
        **/
        var showPinPad = function (type, model, callback, validator, note) {
            var pinPadInstance = $modal.open({
                templateUrl: 'pos/pos.numpad.tpl.html',
                controller: 'PosNumpadCtrl',
                animation: false,
                windowClass: 'modal-numpad modal-fullscreen-transparent modal-right',
                resolve: {
                    initial: function () {
                        return model;
                    },
                    type: function () {
                        return type;
                    },
                    note: function () {
                        return note;
                    }
                },
                backdrop: true
            });
            pinPadInstance.lucovaValidator = validator;
            return pinPadInstance.result.then(function (value) {
                if (callback) {
                    callback(value);
                }
            });
        };

        $scope.init(selectedDiscount);
    }
]);

};
