'use strict';

const angular = require('angular');
const moment = require('moment');

module.exports = function (freshideasReports) {
    freshideasReports.controller('SessionAuditReportCtrl', [
          '$scope',
          '$timeout',
          'Reports',
          'Export',
          'Lookup',
          'Meals',
          'Security',
          'Calculate',
          function ($scope, $timeout, Reports, Export, Lookup, Meals, Security, Calculate) {
              var refreshLocations = function (companies) {
                  if (companies) {
                      $scope.locations = _.flatten(_.map(companies, function (company) {
                          return company.children;
                      }), true);
                  }
              };
              var refreshPoses = function (locations) {
                  if (locations) {
                      $scope.poses = _.flatten(_.map(locations, function (location) {
                          return _.filter(location.children, function (child) {
                              return child.type === 'pos';
                          });
                      }), true);
                      var currentPosId = $scope.reportSearch.posId;
                      var foundPos = _.find($scope.poses, function (pos) {
                          return pos.id === currentPosId;
                      });
                      if (!foundPos) {
                          $scope.reportSearch.posId = undefined;
                      }
                  }
              };
              var tick = function () {
                  $scope.currentDateTime = moment();
                  $timeout(tick, 60 * 1000);
              };
              $timeout(tick, 60 * 1000);
              $scope.onCompanyChange = function () {
                  var companyId = $scope.reportSearch.companyId;
                  if (companyId) {
                      var foundCompany = _.find($scope.companyHierarchy, function (company) {
                          return company.id === companyId;
                      });
                      if (foundCompany) {
                          refreshLocations([foundCompany]);
                      }
                  } else {
                      var companyHierarchy = angular.copy($scope.companyHierarchy);
                      refreshLocations(companyHierarchy);
                  }
              };
              $scope.onLocationChange = function () {
                  var locationId = $scope.reportSearch.locationId;
                  if (locationId) {
                      var foundLocation;
                      _.each($scope.companyHierarchy, function (company) {
                          var l = _.find(company.children, function (location) {
                              return location.id === locationId;
                          });
                          if (l) {
                              foundLocation = l;
                          }
                      });
                      if (foundLocation) {
                          refreshPoses([foundLocation]);
                      }
                  } else {
                      refreshPoses($scope.locations);
                  }
              };
              $scope.opened = {};
              $scope.toggleFromDatePicker = function ($event) {
                  $event.preventDefault();
                  $event.stopPropagation();
                  var status = !!$scope.opened.from;
                  var newStatus = !status;
                  $scope.opened.from = newStatus;
                  if (newStatus) {
                      $scope.opened.to = false;
                  }
              };
              $scope.toggleToDatePicker = function ($event) {
                  $event.preventDefault();
                  $event.stopPropagation();
                  var status = !!$scope.opened.to;
                  var newStatus = !status;
                  $scope.opened.to = newStatus;
                  if (newStatus) {
                      $scope.opened.from = false;
                  }
              };
              $scope.dateOptions = {
                  formatYear: 'yy',
                  startingDay: 1
              };
              $scope.clearFilters = function (isAdvancedSearch) {
                  $scope.reportSearch = {isAdvancedSearch: !!isAdvancedSearch};
              };
              $scope.clearFilters();
              $scope.lookupEntity = function (type, id) {
                  if (!$scope.baseSettings) {
                      return;
                  }
                  var entities = $scope.baseSettings[type] || [];
                  var foundEntity = _.find(entities, function (entity) {
                      return entity.id === id;
                  }) || {};
                  return foundEntity.name || '';
              };
              $scope.lookupCompanyHierarchy = function () {
                  Lookup.companyHierarchy({}, function (response) {
                      $scope.companyHierarchy = response;
                      $scope.baseSettings = {};
                      $scope.baseSettings.companies = $scope.companyHierarchy;
                      $scope.baseSettings.locations = [];
                      _.each($scope.baseSettings.companies, function (company) {
                          var locations = company.children || [];
                          $scope.baseSettings.locations = $scope.baseSettings.locations.concat(locations);
                      });
                      $scope.baseSettings.mealPeriods = [];
                      $scope.baseSettings.poses = [];
                      _.each($scope.baseSettings.locations, function (location) {
                          _.each(location.children, function (child) {
                              if (child.type === 'mealPeriod') {
                                  $scope.baseSettings.mealPeriods.push(child);
                              } else if (child.type === 'pos') {
                                  $scope.baseSettings.poses.push(child);
                              }
                          });
                      });
                      var companyHierarchy = angular.copy($scope.companyHierarchy);
                      $scope.companies = companyHierarchy;
                      refreshLocations($scope.companies);
                      refreshPoses($scope.locations);
                  });
              };
              var convertReport = function (rawReport) {
                  var report = angular.copy(rawReport);
                  report = _(report).chain().sortBy(function (shift) {
                      return shift.locationId;
                  }).sortBy(function (shift) {
                      return shift.campusId;
                  }).value();
                  return report;
              };
              $scope.getSessionAuditReport = function (isAdvancedSearch) {
                  var reportSearch = {};
                  reportSearch.startDateTime = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                  reportSearch.endDateTime = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                  if ($scope.reportSearch.companyId) {
                      reportSearch.companyId = $scope.reportSearch.companyId;
                  }
                  if ($scope.reportSearch.locationId) {
                      reportSearch.locationId = $scope.reportSearch.locationId;
                  }
                  if ($scope.reportSearch.posId) {
                      reportSearch.posStationId = $scope.reportSearch.posId;
                  }
                  reportSearch.employeeId = $scope.reportSearch.employeeId;
                  $scope.searching = true;
                  Reports.getSessionAuditReport(reportSearch, function (response) {
                      var summary = response.sessionAuditSummary || {};
                      var results = summary.shifts || [];
                      $scope.results = convertReport(results);
                      var currentReportSearch = {
                          fromDate: $scope.reportSearch.fromDate,
                          toDate: $scope.reportSearch.toDate,
                          employeeId: $scope.reportSearch.employeeId,
                          companyName: $scope.lookupEntity('companies', $scope.reportSearch.companyId),
                          locationName: $scope.lookupEntity('locations', $scope.reportSearch.locationId),
                          posName: $scope.lookupEntity('poses', $scope.reportSearch.posId),
                          isAdvancedSearch: isAdvancedSearch
                      };
                      currentReportSearch = _.pick(currentReportSearch, function (value) {
                          return !!value;
                      });
                      if (_.isEmpty(currentReportSearch)) {
                          $scope.currentReportSearch = undefined;
                      } else {
                          $scope.currentReportSearch = currentReportSearch;
                      }
                      $scope.reportSearch = $scope.reportSearch || {};
                      $scope.reportSearch.isAdvancedSearch = isAdvancedSearch;
                      $scope.initialSearch = false;
                      $scope.searching = false;
                  });
              };
              $scope.init = function () {
                  $scope.reportSearch = {
                      fromDate: moment().startOf('week').toDate(),
                      toDate: moment().endOf('day').toDate(),
                      isAdvancedSearch: true
                  };
                  $scope.lookupCompanyHierarchy();
                  $scope.getSessionAuditReport(true);
              };
              $scope.init();
              $scope.exportToPdf = function (tableId) {
                  Export.tableToPdf(tableId, 'portrait');
              };
              $scope.initialSearch = true;
              $scope.searching = false;
          }
    ]);
};
