'use strict';
module.exports = function (freshideasDirectivesCommon) {
    freshideasDirectivesCommon.directive('loadingIndicator', function () {

        /**
         * While create a modal with a spinner and block the UI
         * Usage: <loading-indicator timeout=2000 show-indicator="loading"></loading-indicator>
         */
        return {
            restrict: 'E',
            scope: {
                timeout: '=',
                showIndicator: '='
            },
            controller: ['$scope', '$attrs', '$modal', function ($scope, $attrs, $modal) {
                let timeoutId;
                let modalInstance;
                let timeout = !isNaN($scope.timeout) ? Math.max(5000, $scope.timeout) : 5000;

                $scope.$watch(() => {
                    return $scope.showIndicator;
                }, (loading) => {
                    if ($scope.showIndicator === undefined || $scope.showIndicator === null) {
                        return;
                    }
                    if (loading === true) {
                        startLoading();
                    } else {
                        endLoading();
                    }
                });

                let endLoading = function () {
                    if (timeoutId) {
                        clearTimeout(timeoutId);
                        timeoutId = undefined;
                    }
                    if (modalInstance) {
                        modalInstance.close();
                        modalInstance = undefined;
                    }
                };

                let startLoading = function () {
                    if (modalInstance) {
                        return;
                    }
                    modalInstance = $modal.open({
                        templateUrl: 'common/components/loading_indicator/loading_indicator.html',
                        animation: false,
                        backdrop: 'static',
                    });

                    if (modalInstance) {
                        modalInstance.opened.then(() => {
                            timeoutId = setTimeout(() => {
                                endLoading();
                            }, timeout);
                        });
                    }
                };
            }]
        };
    });
};
