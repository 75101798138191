'use strict';

const angular = require('angular');

export default angular.module('freshideas-ng-grid-templates', [])

    .factory('NgGridTemplateService', [function () {

        var service = {

            filterHeaderTemplate: '<div class="ngHeaderSortColumn {{col.headerClass}}" ng-style="{\'cursor\': col.cursor}" ng-class="{ \'ngSorted\': !noSortVisible }">' +
            '<div ng-click="col.sort($event)"ng-class="\'colt\' + col.index" class="ngHeaderText">{{col.displayName | translate}}</div>' +
            '<div class="ngSortButtonDown" ng-show="col.showSortButtonDown()"></div>' +
            '<div class="ngSortButtonUp" ng-show="col.showSortButtonUp()"></div>' +
            '<div class="ngSortPriority">{{col.sortPriority}}</div>' +
            '<div ng-class="{ ngPinnedIcon: col.pinned, ngUnPinnedIcon: !col.pinned }" ng-click="togglePin(col)" ng-show="col.pinnable"></div>' +
            '</div>' +
            '<input type="text" ng-click="stopClickProp($event)" placeholder="{{col.displayName}}..." ng-model="col.filterText" ng-style="{ \'width\' : col.width - 14 + \'px\' }" class="input-sm" style="position:absolute;top:30px;bottom:0;left:0;margin:0 5px;"/>' +
            '<div ng-show="col.resizable" class="ngHeaderGrip" ng-click="col.gripClick($event)" ng-mousedown="col.gripOnMouseDown($event)"></div>"',

            translateHeaderTemplate: '<div class="ngHeaderSortColumn {{col.headerClass}}" ng-style="{\'cursor\': col.cursor}" ng-class="{ \'ngSorted\': !noSortVisible }">' +
            '<div ng-click="col.sort($event)" ng-class="\'colt\' + col.index" class="ngHeaderText">{{col.displayName | translate}}</div>' +
            '<div ng-show="col.resizable" class="ngHeaderGrip" ng-click="col.gripClick($event)" ng-mousedown="col.gripOnMouseDown($event)"></div>"',

            yesNoCellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{COL_FIELD | yesNo | translate}}</span></div>',

            // Put a mailto link
            emailCellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text><a ng-href="{{\'mailto:\' + COL_FIELD}}" target="_blank">{{COL_FIELD}}</a></span></div>',

            // Put a popover over a big chunk of text
            textPopoverTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><a href="" ng-cell-text popover-trigger="mouseenter" popover-append-to-body="true" popover="{{COL_FIELD | wraptext:45:\' \':true}}">{{COL_FIELD | truncate:80}}</a></div>',

            // Open a new browser window using the COL_FIELD as an href and include the initial scheme
            blankHttpHrefCellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text><a ng-href="http://{{COL_FIELD}}" target="_blank">{{COL_FIELD}}</a></span></div>',

            // Open a new browser window using the COL_FIELD as an href
            blankHrefCellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text><a ng-href="{{COL_FIELD}}" target="_blank">{{COL_FIELD}}</a></span></div>',

            // Check mark
            activityTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text><i class="fa fa-lg fa-check-circle" ng-class="{\'status-active\': COL_FIELD, \'status-inactive\': !COL_FIELD}"></i></span></div>',

            activityRowTemplate: '<div style="height: 100%" ng-class="{inactive: !row.getProperty(\'active\')}">' +
                    '<div ng-repeat="col in renderedColumns" ng-class="col.colIndex()" class="ngCell ">' +
                        '<div class="ngVerticalBar" ng-style="{height: rowHeight}" ng-class="{ ngVerticalBarVisible: !$last }">&nbsp;</div>' +
                        '<div ng-cell></div>' +
                    '</div>' +
                 '</div>'
        };
        return service;
    }])

    .factory('NgGridSortService', [function () {

        var service = {

            sortByIPAddress: function (a, b) {
                var ipIntA = parseInt(a.split('.').join(''));
                var ipIntB = parseInt(b.split('.').join(''));
                return (ipIntA > ipIntB) ? 1 : (ipIntA < ipIntB) ? -1 : 0;
            }
        };
        return service;
    }])

;
