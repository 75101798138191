'use strict';


const moment = require('moment');

module.exports = function (freshideasReports) {

    freshideasReports.controller('DiscountsReports', [
        '$scope',
        '$location',
        'Reports',
        'Utils',
        'Lookup',
        'Export',
        'Security',
        'Platform',
        'DateRangeService',
        'USER_ROLE_TYPE',
        function ($scope, $location, Reports, Utils, Lookup, Export, Security, Platform, DateRangeService, USER_ROLE_TYPE) {
            $scope.searchDisabled = false;
            var allowRetry;
            var currentUser = Security.getUser();
            $scope.isManager = currentUser.roleType === USER_ROLE_TYPE.MANAGER;
            $scope.isAccountant = currentUser.roleType === USER_ROLE_TYPE.ACCOUNTANT;
            $scope.isSiteAdmin = currentUser.permission === 'SITEADMIN';
            $scope.isFullAdmin = (currentUser.permission === 'FULLADMIN');
            $scope.isIosWebkit = Platform.isIosWebkit();
            $scope.searchBar = {
                text: ''
            };

            $scope.init = function () {
                if (DateRangeService.getFromDate()) {
                    $scope.discountsSearch.startDateTime = moment(DateRangeService.getFromDate()).startOf('day').toDate();
                }
                if (DateRangeService.getToDate()) {
                    $scope.discountsSearch.endDateTime = moment(DateRangeService.getToDate()).endOf('day').toDate();
                }
                $scope.discountsSearchFilter = angular.copy($scope.discountsSearch);
                lookupCompanyHierarchy().$promise.then(function () {
                    $scope.loadDiscountsApplied();
                });
            };

            function lookupCompanyHierarchy () {
                return Lookup.locationsByCompany({}, function (response) {
                    $scope.company = _.findWhere(response, {id: currentUser.companyId});
                    $scope.locations = [];
                    let children = ($scope.company && $scope.company.children) ? $scope.company.children : [];

                    if (children.length) {
                        if (children.length > 1) {
                            $scope.locations.push({
                                id: null,
                                name: 'All Locations'
                            });
                        }
                        _.each(children, function (location) {
                            $scope.locations.push(location);
                        });
                        $scope.discountsSearch.selectedLocation = currentUser.company.locationId;
                    }
                });
            }

            $scope.setSortByField = function (sortBy) {
                $scope.currentReportSearch.sortBy = sortBy;
                $scope.currentReportSearch.ascending = !$scope.currentReportSearch.ascending;

                var greater = $scope.currentReportSearch.ascending ? 1 : -1;
                var less = $scope.currentReportSearch.ascending ? -1 : 1;
                $scope.discountsAppliedData.sort((a, b) => {
                    if (a[sortBy] > b[sortBy]) {
                        return greater;
                    }
                    if (a[sortBy] < b[sortBy]) {
                        return less;
                    }
                    return 0;
                });
            };

            $scope.resetFilters = function () {
                $scope.discountsSearch.startDateTime = moment().startOf('week').toDate();
                $scope.discountsSearch.endDateTime = moment().endOf('day').toDate();
                $scope.discountsSearch.isAdvancedSearch = true;
                $scope.discountsSearch.sortBy = '';
                $scope.discountsSearch.ascending = true;
                $scope.discountsSearch.offSet = 0;
                $scope.discountsSearch.limit = 50;
                $scope.discountsSearch.selectedLocation = currentUser.company.locationId;
                delete $scope.discountsSearch.locationId;

                $scope.discountsSearchFilter = angular.copy($scope.discountsSearch);
                $scope.loadDiscountsApplied();
            };

            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };
            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };

            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };

            $scope.discountsSearch = {
                isAdvancedSearch: true,
                startDateTime: moment().startOf('week').toDate(),
                endDateTime: moment().endOf('day').toDate(),
                sortBy: '',
                ascending: true,
                offSet: 0,
                limit: 50,
                selectedLocation: currentUser.company.locationId
            };
            $scope.discountsSearchFilter = angular.copy($scope.discountsSearch);

            $scope.saveSelectedFromDate = function () {
                var selectedFromDate = moment($scope.discountsSearchFilter.startDateTime).startOf('day').valueOf();
                DateRangeService.setFromDate(selectedFromDate);
            };
            $scope.saveSelectedToDate = function () {
                var selectedToDate = moment($scope.discountsSearchFilter.endDateTime).endOf('day').valueOf();
                DateRangeService.setToDate(selectedToDate);
            };

            function resetPagingOptions () {
                $scope.pagingOptions = {
                    startRecord: 0,
                    pageSize: 10,
                    currentPage: 1,
                };
            }
            resetPagingOptions();

            $scope.pageUp = function () {
                var start = $scope.pagingOptions.startRecord;
                var newStart = start + $scope.pagingOptions.pageSize;
                if ($scope.discountsAppliedData.length > newStart) {
                    $scope.pagingOptions.startRecord = newStart;
                    $scope.pagingOptions.currentPage++;
                }
            };

            $scope.pageDown = function () {
                var start = $scope.pagingOptions.startRecord;
                var newIndex = start - $scope.pagingOptions.pageSize;
                if (start > 0) {
                    if (newIndex < 0) {
                        $scope.pagingOptions.startRecord = 0;
                    } else {
                        $scope.pagingOptions.startRecord = newIndex;
                    }
                    $scope.pagingOptions.currentPage--;
                }
            };

            $scope.pagesAvailable = function () {
                var itemLen = $scope.discountsAppliedData.length;
                var pageSize = $scope.pagingOptions.pageSize;
                return (itemLen > pageSize) ? Math.ceil(itemLen/pageSize) : 1;
            };

            var sortDiscountsObject = function (a, b) {
                if ( a.discountId - b.discountId == 0) {
                    return a.discountType - b.discountType;
                } else {
                    return a.discountId - b.discountId;
                }
            };

            $scope.filterDiscountsApplied = function (data) {
                if (data.length > 0) {
                    $scope.isLoading = false;

                    data.sort(sortDiscountsObject);

                    $scope.discountsAppliedData = data;
                    $scope.originalDiscountsData = $scope.discountsAppliedData;

                    $scope.currentReportSearch = angular.copy($scope.discountsSearch);
                    $scope.searchAction();
                } else {
                    $scope.isLoading = false;
                    $scope.discountsAppliedData = [];
                    $scope.discountsAppliedDataSize = 0;
                }
            };

            $scope.loadDiscountsApplied = function () {
                $scope.searchDisabled = true;
                clearTimeout(allowRetry);
                allowRetry = setTimeout(function () {
                    $scope.searchDisabled = false;
                }, 30000);
                $scope.discountsSearch.startDateTime = moment($scope.discountsSearchFilter.startDateTime).startOf('day').valueOf();
                $scope.discountsSearch.endDateTime = moment($scope.discountsSearchFilter.endDateTime).endOf('day').valueOf();
                $scope.discountsSearch.selectedLocation = angular.copy($scope.discountsSearchFilter.selectedLocation);

                var transactionSearch = {
                    startDateTime: $scope.discountsSearch.startDateTime,
                    endDateTime: $scope.discountsSearch.endDateTime,
                    sortBy: '',
                    limit: 60,
                    locationId: $scope.discountsSearch.selectedLocation
                };

                $scope.discountsAppliedData = [];
                $scope.discountsAppliedDataSize = 0;
                $scope.isLoading = true;

                Reports.getDiscountsReport(transactionSearch, function (response) {
                    $scope.filterDiscountsApplied(response);
                }, function (error) {
                    $scope.isLoading = false;
                });
            };

            $scope.loadTransactionsForDiscount = function (discount) {
                var transactionSearch = {
                    startDateTime: $scope.discountsSearch.startDateTime,
                    endDateTime: $scope.discountsSearch.endDateTime,
                    discountId: discount.discountId,
                    discountType: discount.discountType,
                    discountName: encodeURIComponent(discount.discountName),
                    sortBy: '',
                    locationId: $scope.discountsSearch.selectedLocation,
                    limit: 60
                };

                if (!transactionSearch.discountType) {
                    delete transactionSearch.discountType;
                }

                discount.transactions = [];
                discount.transactionsSize = 0;
                discount.isLoadingTransactions = true;

                Reports.getDiscountTransactions(transactionSearch, function (response) {
                    discount.transactions = response;
                    discount.transactionsSize = response.length;
                    discount.isLoadingTransactions = false;
                    _.each(response, function (transaction) {
                        if (discount.discountId == 0) {
                            if (discount.discountType == 1 || discount.discountType == 2) {
                                transaction.authorizedBy = 'GENERAL';
                            } else if (discount.discountType == 3) {
                                transaction.authorizedBy = 'Mobile App';
                            }
                        } else {
                            if (!transaction.authorizedByUser) {
                                transaction.authorizedBy = 'No PIN';
                            } else {
                                transaction.authorizedBy = transaction.authorizedByUser.firstname
                                        + ' '+ transaction.authorizedByUser.lastname;
                            }
                        }
                    });
                    // $scope.filterDiscountsApplied(response);
                }, function (error) {
                    $scope.isLoadingTransactions = false;
                });
            };

            $scope.loadTransactionItemsForDiscount = function (discount) {
                var transactionItemSearch = {
                    startDateTime: $scope.discountsSearch.startDateTime,
                    endDateTime: $scope.discountsSearch.endDateTime,
                    discountId: discount.discountId,
                    discountType: discount.discountType,
                    discountName: encodeURIComponent(discount.discountName),
                    isPromo: discount.isPromo,
                    sortBy: '',
                    locationId: $scope.discountsSearch.selectedLocation,
                    limit: 60
                };

                if (!transactionItemSearch.discountType) {
                    delete transactionItemSearch.discountType;
                }

                discount.items = [];
                discount.itemsSize = 0;
                discount.isLoading = true;

                Reports.getDiscountTransactionItems(transactionItemSearch, function (response) {
                    discount.items = response;
                    discount.itemsSize = response.length;
                    discount.isLoading = false;
                    _.each(response, function (item) {
                        if (discount.discountId == 0) {
                            if (discount.discountType == 1 || discount.discountType == 2) {
                                item.authorizedBy = 'GENERAL';
                            } else if (discount.discountType == 3) {
                                item.authorizedBy = 'Mobile App';
                            }
                        } else {
                            if (!item.authorizedByUser) {
                                item.authorizedBy = 'No PIN';
                            } else {
                                item.authorizedBy = item.authorizedByUser.firstname
                                        + ' '+ item.authorizedByUser.lastname;
                            }
                        }
                    });
                    // $scope.filterDiscountsApplied(response);
                }, function (error) {
                    discount.isLoading = false;
                });
            };

            $scope.getDownloadUrl = function () {
                if ($scope.isIosWebkit) {
                    return;
                }
                var reportSearch = {};
                reportSearch.startDateTime = moment($scope.discountsSearch.startDateTime).startOf('day').valueOf();
                reportSearch.endDateTime = moment($scope.discountsSearch.endDateTime).endOf('day').valueOf();
                if ($scope.discountsSearch.locationId) {
                    reportSearch.locationId = $scope.discountsSearch.locationId;
                }

                /* var downloadUrl = $location.protocol() + '://' + $location.host() + ':' + $location.port()
                 + '/freshideas/web/reports/itemSales/export';

                var queryString = jQuery.param(reportSearch);
                return downloadUrl + '?' + queryString;*/
            };

            $scope.toggleShowDiscountDetail = function (discount) {
                // DISCOUNT_TYPE_TRANSACTION_LABEL_PERCENT = 0;
                // DISCOUNT_TYPE_TRANSACTION_PERCENT = 1;
                // DISCOUNT_TYPE_TRANSACTION_DOLLAR = 2;
                // DISCOUNT_TYPE_REWARDS_REDEMPTION = 3;
                // DISCOUNT_TYPE_ITEM_LABEL_PERCENT = 10;
                // DISCOUNT_TYPE_OPEN_ITEM_LEVEL_LABEL = 11;
                // if Discount Type = 11, need not show individual transactions therefore added this check here
                if (discount.discountType != 11) {
                    if (discount.discountType != 10) {
                        $scope.toggleShowDiscountTransactions(discount);
                    } else {
                        $scope.toggleShowDiscountItems(discount);
                    }
                }
            };

            $scope.toggleShowDiscountTransactions = function (discount) {
                if (discount.discountCount > 0) {
                    if (!discount.showTransactions) {
                        discount.showTransactions = true;
                        $scope.loadTransactionsForDiscount(discount);
                    } else {
                        discount.showTransactions = false;
                    }
                }
            };

            $scope.toggleShowDiscountItems = function (discount) {
                if (discount.discountCount > 0) {
                    if (!discount.showTransactions) {
                        discount.showTransactions = true;
                        $scope.loadTransactionItemsForDiscount(discount);
                    } else {
                        discount.showTransactions = false;
                    }
                }
            };

            $scope.setAllShowTransactions = function (expanded) {
                $scope.discountsAppliedData.forEach((item) => {
                    if (item.discountCount > 0 && item.discountType !== 11) {
                        item.showTransactions = expanded;
                        $scope.loadTransactionsForDiscount(item);
                    }
                });
            };

            function calculateTotals () {
                $scope.totalDiscounts = 0;
                $scope.totalCount = 0;

                $scope.discountsAppliedData.forEach((item) => {
                    $scope.totalDiscounts += item.totalDiscountAmount || 0;
                    $scope.totalCount += item.discountCount || 0;
                });
            }

            $scope.searchAction = function () {
                // Search filter
                if ($scope.searchBar.text !== '') {
                    var searchTextLowerCase = $scope.searchBar.text.toLowerCase();
                    var filteredArray = [];

                    $scope.originalDiscountsData.forEach((discountApplied) => {
                        var discountName = discountApplied.discountName.toLowerCase();
                        if (discountName.indexOf(searchTextLowerCase) !== -1) {
                            filteredArray.push(discountApplied);
                        } /* else if (baseItemSale.modifiers.length > 0) {
                            var pushedBaseItem = false;
                            baseItemSale.modifiers.forEach((modifierItemSale) => {
                                var modifierItemName = modifierItemSale.itemName.toLowerCase();
                                if (modifierItemName.indexOf(searchTextLowerCase) !== -1) {
                                    if (!pushedBaseItem) {
                                        pushedBaseItem = Object.assign(
                                            {}, baseItemSale);
                                        pushedBaseItem.modifiers = [];
                                        pushedBaseItem.modifiers.push(modifierItemSale);
                                        filteredArray.push(pushedBaseItem);
                                    } else {
                                        pushedBaseItem.modifiers.push(modifierItemSale);
                                    }
                                }
                            });
                        }*/
                    });

                    $scope.discountsAppliedData = filteredArray;
                } else {
                    $scope.discountsAppliedData = $scope.originalDiscountsData;
                }
                resetPagingOptions();
                calculateTotals();
            };

            $scope.clearSearch = function () {
                $scope.searchBar.text = '';
                $scope.discountsAppliedData = $scope.originalDiscountsData;
                calculateTotals();
            };

            $scope.exportToPdf = function (tableId) {
                Export.tableToPdf(tableId, 'landscape');
            };

            $scope.init();

            $scope.$on('$destroy', function () {
                clearTimeout(allowRetry);
            });

            $scope.$watch('discountsSearch.locationId', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('discountsSearch.startDateTime', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('discountsSearch.endDateTime', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });
        }
    ]);

};
