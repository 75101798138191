'use strict';

module.exports = function (freshideasSetup) {

    freshideasSetup.controller('NetsuiteSetupCtrl', [
    '$scope',
    '$modal',
    '$translate',
    '$filter',
    '$timeout',
    'Users',
    'Security',
    'Settings',
    'PosAlertService',
    'Netsuite',
    'netSuiteConnection',
    function ($scope,
        $modal,
        $translate,
        $filter,
        $timeout,
        Users,
        Security,
        Settings,
        PosAlertService,
        Netsuite,
        netSuiteConnection) {
        $scope.selectedConnection = Object.assign({}, netSuiteConnection);
        $scope.subsidiaries = [];

        $scope.init = function () {
            Netsuite.getCompanyHierarchy({connectionId: $scope.selectedConnection.connectionId}, function (response) {
                $scope.subsidiaries.length = 0;
                Object.keys(response).forEach(function (key) {
                    $scope.subsidiaries.push(response[key]);
                });
                $scope.subsidiaries[0].isSelected = true;
            }, function (error) {
                // /Code to throw error
            });

        };


        var isImportingHierarchy = false;
        $scope.importSubsidiariesFromNetsuite = function () {
            if (isImportingHierarchy) {
                return;
            }

            var subsidiariesToImport = [];
            for (var subsidiary of $scope.subsidiaries) {
                var subsidiaryToAdd = Object.assign({}, subsidiary);
                subsidiaryToAdd.locations = [];
                if (subsidiary.isSelected && subsidiary.locations) {
                    Object.keys(subsidiary.locations).forEach(function (key) {
                        var locationToAdd = subsidiary.locations[key];
                        // if (locationToAdd.isSelected) {
                        if (locationToAdd.internalId == '1' || locationToAdd.internalId == '2' || locationToAdd.internalId == '429') {
                            subsidiaryToAdd.locations.push(locationToAdd);
                        }
                    });

                    delete subsidiaryToAdd.price;
                    subsidiariesToImport.push(subsidiaryToAdd);
                }
            }

            isImportingHierarchy = true;

            var importCompanyObj = {
                subsidiaries: subsidiariesToImport
            };

            Netsuite.importCompanyHierarchyFromNetsuite(importCompanyObj, function () {
                isImportingHierarchy = false;
            }, function (error) {
                // Log error
                isImportingHierarchy = false;
            });
        };
        $scope.init();
    }
]);

};
