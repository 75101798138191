'use strict';

const moment = require('moment');

export function generalFilters (freshideas) {
    freshideas.filter('unsafe', ['$sce', function ($sce) {
        return function (val) {
            return $sce.trustAsHtml(val);
        };
    }]).filter('capitalize', function () {
        return function (str) {
            // http://stackoverflow.com/questions/196972/convert-string-to-title-case-with-javascript/196991#196991
            return str.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        };
    }).filter('splitCamelCase', function () {
        return function (str) {
            var result = str.replace(/([A-Z])/g, ' $1');
            var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
            return finalResult;
        };
    }).filter('moment', function () {
        return function (datetime, format, invalidDateString) {
            format = format || 'YY-MM-DD HH:mm';
            var momentDateTime = moment(datetime);

            if (momentDateTime.isValid()) {
                return momentDateTime.format(format);
            } else {
                return invalidDateString || '';
            }

        };
    }).filter('timeAgo', function () {

        var timeAgo = function (datetime, invalidDateString) {
            var momentDateTime = moment(datetime);

            if (momentDateTime.isValid()) {
                return momentDateTime.fromNow();
            } else {
                return invalidDateString || '';
            }
        };

        timeAgo.$stateful = true;

        return timeAgo;

    }).filter('duration', function () {
        return function (milliseconds) {
            var duration = moment.duration(milliseconds);
            var hours = Math.floor(duration.as('hours')); // .as('hours') shows total number of hours
            var minutes = duration.minutes(); // `.minutes()` shows remaining minutes (after hours)

            var hourString = hours + ' ' + ((hours === 1)? 'hour' : 'hours');
            var minuteString = minutes + ' ' + ((minutes === 1)? 'minute' : 'minutes');
            var durationString = hourString + ' ' + minuteString;

            return durationString;
        };
    }).filter('currentDate', ['$filter', function ($filter) {

        var currentDate = function (format, invalidDateString) {

            format = format && format !== '' ? format : 'MMMM Do YYYY, h:mm:ss a';
            var momentDateTime = moment();

            if (momentDateTime.isValid()) {
                return momentDateTime.format(format);
            } else {
                return invalidDateString || '';
            }
        };

        currentDate.$stateful = true;

        return currentDate;

    }]).filter('MilitaryHourToAmPm', function () {
        return function (hour) {
            var convertedHour = hour % 12 || 12;
            var ampm = (hour < 12 || hour === 24) ? 'am' : 'pm';
            return convertedHour + ampm;
        };
    }).filter('centToDollar', ['$filter', function ($filter) {
        return function (cent, symbol, fractionSize) {
            cent = cent || 0;
            var amount = cent / 100;
            return $filter('currency')(amount);
        };
    }]).filter('centToCurrency', ['$filter', function ($filter) {
        return function (cent, currency) {
            cent = cent || 0;
            var amount = cent / 100;
            return $filter('currency')(amount, currency);
        };
    }]).filter('pick', function () {
        return function (array, field) {
            return _.map(array, function (element) {
                return element[field];
            });
        };
    }).filter('join', function () {
        return function (array, delimiter) {
            delimiter = delimiter || ', ';
            return array.join(delimiter);
        };
    }).filter('decimals', function () {
        return function (value, numOfDecimals) {
            value = value || 0;
            numOfDecimals = numOfDecimals === undefined ? 2 : numOfDecimals;
            return value.toFixed(numOfDecimals);
        };
    }).filter('discountCurrency', ['$filter', function ($filter) {
        return function (value, numOfDecimals) {
            value = value || 0;
            numOfDecimals = numOfDecimals === undefined ? 2 : numOfDecimals;

            return $filter('currency')(value);
        };
    }]).filter('mealPlanBalance', [
        '$filter',
        function ($filter) {
        return function (amount, mealPlanType) {
            switch (mealPlanType) {
                case 'MEAL':
                    return amount + ' meal' + ((amount === 1)? '' : 's');
                case 'DCB':
                    return $filter('currency')(amount);
                case 'ICB':
                    return $filter('currency')(amount);
            }
            return amount;
        };
    }]).filter('range', [
        function () {
            return function (n) {
                var range = [];
                for (var i = 0; i < n; i++) {
                    range.push(i);
                }
                return range;
            };
        }
    ]).filter('displayPermission', [
        '$translate',
        function ($translate) {
            var siteAdminName = $translate.instant('users.user.permission.siteadmin');

            return function (permission) {
                return (permission === 'SITEADMIN')? siteAdminName : permission;
            };
        }
    ]).filter('giftCardMask', [
        function () {
            return function (code) {
                // showing only 1 * for demo purpose
                var lastDigits = code.slice(-4);
                return '****' + lastDigits;

                // TODO: re-enable for production
                /* return code.replace(/\w(?=\w{4})/g, "*"); */
            };
        }
    ]).filter('stringToInt', function () {
        return function (intString) {
            var value = parseInt(intString);

            if (isNaN(value)) {
                return 0;
            } else {
                return value;
            }
        };
    }).filter('diff', [
        function () {
            return function (diff) {
                if (diff > 0) {
                    return '+' + diff;
                } else if (diff < 0) {
                    return '' + diff;
                } else {
                    return '-';
                }
            };
        }
    ]).filter('itemDisplayPrice', [
        'SharedDataService',
        function (SharedDataService) {
            return function (item, taxExcludedField, taxIncludedField) {
                let taxIncludedInPrice = !!SharedDataService.taxIncludedInPrice;
                taxExcludedField = taxExcludedField || 'price';
                taxIncludedField = taxIncludedField || 'total';

                if (taxIncludedInPrice) {
                    return item[taxIncludedField];
                } else {
                    return item[taxExcludedField];
                }
            };
        }
    ]).filter('points', [
        function () {
            return function (points) {
                return points + ' pts.';
            };
        }
    ]).filter('emptyToEnd', function () {
        return function (array, key) {
            if (!angular.isArray(array)) {
                return;
            }
            var present = array.filter(function (item) {
                return item[key];
            });
            var empty = array.filter(function (item) {
                return !item[key];
            });
            return present.concat(empty);
        };
    });
}
