'use strict';


module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ProductCategoryCreateCtrl', [
        '$scope',
        '$timeout',
        'ConfirmModalService',
        'Inventory',
        'ProductsService',
        'category',
        'index',
        'targetCategory',
        function ($scope, $timeout, ConfirmModalService, Inventory, ProductsService, category, index, targetCategory) {
            $scope.ProductCategoryCreateForm = {};

            $scope.category = angular.copy(category) || {active: true, color: '#40d0ad'};
            $scope.isNew = !$scope.category.locationServicePeriodMenuId;

            if (index >= 0 && $scope.isNew) {
                $scope.category.menuOrderId = index + 1;
            }

            $scope.taxRule = function (name) {
                return ProductsService.getTaxRule(name);
            };

            var highlightInputsFn;
            var runHighlightInputs = function () {
                if (highlightInputsFn) {
                    return;
                }

                $scope.highlightInputs = true;
                highlightInputsFn = $timeout(function () {
                    $scope.highlightInputs = false;
                    highlightInputsFn = undefined;
                }, 1000);
            };

            var isCategoryValid = function () {
                if (!$scope.ProductCategoryCreateForm.$valid) {
                    runHighlightInputs();
                    return false;
                }

                return true;
            };


            $scope.saveCategory = function () {
                if (!isCategoryValid()) {
                    return;
                }

                $scope.savingCategory = true;
                var categoryToSave = angular.copy($scope.category);

                if ($scope.isNew) {
                    if (targetCategory) {
                        ProductsService.addMenuCategoryToCategory(categoryToSave, targetCategory).then(function (categorySaved) {
                            $scope.savingCategory = false;
                            $scope.$close(categorySaved);
                        });
                    } else {
                        ProductsService.addMenuCategory(categoryToSave).then(function (categorySaved) {
                            $scope.savingCategory = false;
                            $scope.$close(categorySaved);
                        });
                    }
                } else {
                    ProductsService.editMenuCategory(categoryToSave).then(function (categorySaved) {
                        $scope.savingCategory = false;
                        $scope.$close(categorySaved);
                    });
                }
            };

            $scope.colorOptions = [
                '#e6e9ed',
                '#ffcf47',
                '#40d0ad',
                '#69b5f8',
                '#ee85c1',
                '#ff585b',

                '#ddcfc7',
                '#f7efcb',
                '#b3e0b3',
                '#a2d5ff',
                '#9e88dd',
                '#ffaa8e'
            ];
            $scope.toggleColorOptions = function () {
                $scope.showColorOptions = !$scope.showColorOptions;
            };
            $scope.setColor = function (color) {
                $scope.category.color = color;
            };

            $scope.deleteCategory = function () {
                ConfirmModalService.openConfirmModal(
                    'product.category.delete.title',
                    'product.category.delete.confirmation',
                    undefined, // `clickAction` callback
                    {
                        categoryName: $scope.category.name
                    },
                    undefined, // `scope`
                    true // `allowCancel`
                ).then(function () {
                    return confirmDeleteCategory();
                }).catch(function () {

                });
            };
            var confirmDeleteCategory = function () {
                return ProductsService.deleteMenuItem($scope.category)
                        .then(function () {
                            $scope.category.deleted = true;
                            $scope.$close($scope.category);
                        });
            };
        }])
        .value('category', undefined)
        .value('index', -1)
        .value('targetCategory', undefined);
};
