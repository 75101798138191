'use strict';


module.exports = function (freshideasReports) {

    freshideasReports.controller('ActivePatronsReportCtrl', [
        '$scope',
        'Reports',
        'Security',
        'notificationTranslationHelper',
        'Export',
        'Meals',
        'Platform',
        function ($scope, Reports, Security, notificationTranslationHelper, Export, Meals, Platform) {
            var currentUser = Security.getUser() || {};
            var currentCompanyId = currentUser.companyId;

            $scope.isIosWebkit = Platform.isIosWebkit();
            $scope.isElectron = Platform.isElectron();
            $scope.patronChunkSize = 1;
            $scope.patronSortBy = 'displayedName';
            $scope.patronSortOrder = false; // false = ascending, true = descending
            $scope.currentReportSearch = {};
            $scope.reportSearch = {};

            var parseMealPatrons = function (meals) {
                _.each(meals, function (meal) {
                    _.each(meal.patrons, function (patron) {
                        patron.displayedName = patron.patronLastName + ', ' + patron.patronFirstName;
                    });
                });
            };
            $scope.loadActivePatrons = function () {
                var mealPlanIds = _.map($scope.mealPlans, function (mealPlan) {
                    return (mealPlan.selected)? mealPlan.mealPlanId : undefined;
                });
                $scope.reportSearch.mealPlanIds = _.compact(mealPlanIds);

                Reports.getActivePatrons($scope.reportSearch, function (response) {
                    delete response.$promise;
                    delete response.$resolved;

                    var meals = response;
                    parseMealPatrons(meals);
                    $scope.meals = meals;

                    $scope.currentReportSearch = $scope.reportSearch;
                    if ($scope.currentReportSearch.mealPlanIds.length < $scope.mealPlans.length) {
                        var matchedMealPlans = _.filter($scope.mealPlans, function (mealPlan) {
                            return $scope.currentReportSearch.mealPlanIds.indexOf(mealPlan.mealPlanId) > -1;
                        }) || {};
                        $scope.currentReportSearch.mealPlanNames = _.map(matchedMealPlans, function (mealPlan) {
                            return mealPlan.name;
                        });
                    } else {
                        $scope.currentReportSearch.mealPlanNames = ['All Meal Plans'];
                    }
                });
            };
            $scope.loadMealPlans = function (selectAllMealPlans) {
                return Meals.getMealPlans({companyId: currentCompanyId}).$promise.then(function (response) {
                    $scope.mealPlans = response.entries;
                    if (selectAllMealPlans) {
                        $scope.anyMealPlanSelected = true;
                        _.each($scope.mealPlans, function (mealPlan) {
                            mealPlan.selected = true;
                        });
                    }
                });
            };
            $scope.init = function () {
                if (currentCompanyId) {
                    $scope.reportSearch = {companyId: currentCompanyId};
                    $scope.loadMealPlans(true).then($scope.loadActivePatrons);
                }
            };
            $scope.init();

            $scope.setPatronSortBy = function (patronSortBy) {
                var oldPatronSortBy = $scope.patronSortBy;
                $scope.patronSortBy = patronSortBy;

                if (oldPatronSortBy === patronSortBy) {
                    $scope.patronSortOrder = !$scope.patronSortOrder;
                }
            };
            $scope.clearFilters = function (isAdvancedSearch) {
                $scope.reportSearch = {isAdvancedSearch: !!isAdvancedSearch};
            };
            $scope.toggleMealPlan = function (mealPlan) {
                mealPlan.selected = !mealPlan.selected;

                var anyMealPlanSelected = _.some($scope.mealPlans, function (mp) {
                    return mp.selected;
                });

                $scope.anyMealPlanSelected = anyMealPlanSelected;
            };
            $scope.exportToPdf = function (tableId) {
                Export.tableToPdf(tableId, 'portrait');
            };
        }
    ]);

};
