'use strict';

const io = require('socket.io-client');
const moment = require('moment');
export function websocketService (freshideas) {
    freshideas.factory('NownWebsocket', [
        '$rootScope',
        'Locations',
        'PosStatusService',
        'Pure',
        function ($rootScope, Locations, PosStatusService, Pure) {
            var socket = null;
            var socketConnected = false;

            var pongTimeoutId = null;
            // var poll15min = null;

            var honeyBadgerTimeoutAlerts = {};

            function clearAllWebsocketStatusCheck () {
                Object.keys(honeyBadgerTimeoutAlerts).forEach(function (messageId, index) {
                    clearTimeout(honeyBadgerTimeoutAlerts[messageId]['timeout']);
                });

                honeyBadgerTimeoutAlerts = {};
            }

            function clearWebsocketStatusCheck (messageId) {
                if (honeyBadgerTimeoutAlerts[messageId]) {
                    clearTimeout(honeyBadgerTimeoutAlerts[messageId]['timeout']);

                    delete honeyBadgerTimeoutAlerts[messageId];
                }
            }

            function webSocketOnMessage ({messageId, type, payload}) {

                if (PosStatusService.isMessageSeen(messageId)) {
                    clearWebsocketStatusCheck(messageId);
                    return;
                }

                PosStatusService.addSeenMessage(messageId);

                confirmMessageReceived(messageId);
                try {
                    if (type === 'c_profile') {
                        $rootScope.$emit('customer.profile', payload);
                    }
                } catch (e) {
                    console.error(e);
                }
            }

            function connect (socketioUrl, socketioChannel) {
                if (typeof (socketioUrl) !== 'string' || typeof (socketioChannel) !== 'string') {
                    throw Error('Invalid parameter');
                }

                socket = createSocket(socketioUrl, socketioChannel);

                pollEvery15Min();
            }

            function createSocket (socketioUrl, socketioChannel) {
                if (socket) {
                    console.log('Already Connected');
                    return socket;
                }

                console.log('Connecting to ' + socketioUrl);

                var ioSocket = io(socketioUrl, {query: `room=${socketioChannel}`});
                ioSocket.on('message', (msg) => {
                    try {
                        $rootScope.$apply(() => {
                            webSocketOnMessage(msg);
                        });
                    } catch (e) {
                        console.log(e);
                    }
                });
                ioSocket.on('connect', () => {
                    socketConnected = true;
                    console.log('WebSocket opened to ' + socketioUrl);
                });
                ioSocket.on('connect_error', (error) => {
                    console.error(error);
                });
                ioSocket.on('reconnect_error', (error) => {
                    console.error(error);
                });
                ioSocket.on('connect_timeout', (error) => {
                    console.error('Connect timeout');
                });
                ioSocket.on('disconnect', (msg) => {
                    socketConnected = false;
                    poll();
                });
                ioSocket.on('ping', (msg) => {
                    // currently doing a ping every 5 seconds. If we don't hear back
                    // from the server, within 5 seconds, let's assume the websocket
                    // connection is down temporarily and do a manual poll instead

                    if (!pongTimeoutId) {
                        pongTimeoutId = setTimeout(() => {
                            pongTimeoutId = null;
                            poll();
                        }, 5000);
                    }
                });
                ioSocket.on('pong', (responseTime) => {
                    if (pongTimeoutId) {
                        clearTimeout(pongTimeoutId);
                        pongTimeoutId = null;
                    }
                });
                ioSocket.on('reconnect_attempt', (msg) => {
                    poll();
                });

                return ioSocket;
            }

            function confirmMessageReceived (messageId) {
                Locations.confirmPush({
                    messageId: messageId
                }).$promise.catch((error) => console.log('Unable to confirm push message'));
            }

            var poll = function () {
                // if (PosStatusService.shiftStarted) {
                //     Lucova.manager().pushPoll(
                //         {},
                //         {},
                //         function (response) {
                //             _.each(response.messages, function (message) {
                //                 webSocketOnMessage(message, 'poll');
                //             });
                //         },
                //         function (error) {}
                //     );
                // }
            };

            function disconnect () {
                if (socket) {
                    socket.disconnect();
                    socket = null;
                    socketConnected = false;
                }
                clearAllWebsocketStatusCheck();
            }

            function pollEvery15Min () {
                // if (poll15min) {
                //     return;
                // }

                // poll();
                // poll15min = setTimeout(() => {
                //     poll15min = null;
                //     pollEvery15Min();
                // }, 300000);
            }

            return {
                isConnected: socketConnected,
                connect: connect,
                disconnect: disconnect,
            };
        }
    ]);

    freshideas.factory('LucovaWebSocket', [
        '$rootScope',
        '$timeout',
        '$log',
        'Lucova',
        'Pure',
        'AudioService',
        'PosStatusService',
        'TRANSACTION_STATUS',
        'CurrentSession',
        'CompanyAttributesService',
        'KdsService',
        'PreorderService',
        function (
            $rootScope,
            $timeout,
            $log,
            Lucova,
            Pure,
            AudioService,
            PosStatusService,
            TRANSACTION_STATUS,
            CurrentSession,
            CompanyAttributesService,
            KdsService,
            PreorderService
        ) {
            var socket = null;
            var socketConnected = false;
            var socketioParams = null;

            var allUsers = [];
            var nearbyUsers = [];
            var mobileOrderUsers = [];
            var ungroupedPreorderUsers = [];
            var pendingTransaction = null;
            var transactionStatus = TRANSACTION_STATUS.INITIAL;

            // 0 - Disconnected, 1 - Connected
            var timestamp = 0;
            var lastScrollTimestamp = null;
            var UIretryInterval = 500; // ms

            // the amount of time after scroll begins to unlock WebSocket UI updates
            var wsLockoutInterval = 2000; // ms
            var timeout = null;
            var pendingNotificationInterval;
            var pongTimeoutId = null;

            function setLastScrollTimestamp (data) {
                lastScrollTimestamp = data;
            }

            function updateRssi (userName, rssi) {
                var user = findElement(nearbyUsers, 'user_name', userName);
                if (user) {
                    user.rssi = parseFloat(rssi);
                }
            }

            var _processIncomingPreorders = function (incomingPreordersArr, ungroupedPreorderUsersArr, allUsersArr) {
                _.each(incomingPreordersArr, function (preorderUser) {
                    if (preorderUser.preorder) {
                        _.each(preorderUser.preorder, (currentPreorder) => {
                            // set app icon, formatted estimated time and the delivery service provider (if delivery order)
                            PreorderService.transformPreorder(currentPreorder);

                            var modifiedUser = angular.copy(preorderUser);

                            // empty preorder array
                            modifiedUser.preorder = [];
                            modifiedUser.preorder.push(currentPreorder);
                            ungroupedPreorderUsersArr.push(modifiedUser);
                        });

                        preorderUser.preorder = _.sortBy(preorderUser.preorder, function (preorder) {
                            return -preorder.transaction_opened;
                        });

                        var latestPreorder = preorderUser.preorder[0];
                        if (latestPreorder) {
                            preorderUser.isPreorderCompleted = latestPreorder.status === 'completed';
                            // `preorderPickupTime` - for orders that are not completed, `preorderPickupTime` is an estimate based on when an order is placed
                            // (eg. when the transaction is opened, or when the transaction is placed (`aand default prep time . For orders
                            // that are completed, it is based on the actual time an order is closed (ie. `transaction_closed`).
                            preorderUser.preorderPickupTime = latestPreorder.estimated_completion;
                            preorderUser.show = (moment().valueOf() / 1000 - preorderUser.preorderPickupTime) < 15 * 60;
                        }
                    }
                    var user = _.findWhere(allUsersArr, {user_name: preorderUser.user_name});
                    if (user) {
                        // for now, keep all mobile orders (even completed ones)
                        user.preorder = preorderUser.preorder;
                        user.isPreorderCompleted = preorderUser.isPreorderCompleted;
                        user.preorderPickupTime = preorderUser.preorderPickupTime;
                        user.show = preorderUser.show;
                    } else {
                        allUsersArr.push(preorderUser);
                    }
                });
            };

            var populateUsers = function (userTypes, manualRefresh) {
                if (!manualRefresh && lastScrollTimestamp && Date.now() - lastScrollTimestamp <= wsLockoutInterval) {
                    // can't continue UI is locked
                    clearTimeout(timeout);

                    timeout = setTimeout(function () {
                        populateUsers(userTypes);
                    }, UIretryInterval);

                    return;
                }

                PosStatusService.currentTimestamp = Date.now();

                var direct = angular.copy(userTypes.direct) || [];
                var preorder = angular.copy(userTypes.preorder) || [];

                clearUsers();
                _.each(direct, function (directUser) {
                    directUser.rssi = parseFloat(directUser.rssi);
                    allUsers.push(directUser);
                });

                _processIncomingPreorders(preorder, ungroupedPreorderUsers, allUsers);

                _.each(allUsers, function (user, i) {
                    // set appearance order of users based on this order:
                    // - Nearby users
                    // - Non-nearby users with current preorder
                    // - Non-nearby users without current preorder
                    var posOrder = (user.rssi && user.rssi < 0) ? 2 : (user.isPreorderCompleted) ? 0 : 1;
                    user.posOrder = posOrder;
                    if (user.rssi) {
                        nearbyUsers.push(user);
                    }
                    if (user.preorder) {
                        mobileOrderUsers.push(user);
                    }
                });

                _.each(mobileOrderUsers, function (user) {
                    _.each(user.preorder, function (preorder) {
                        if (preorder.status === 'print_pending') {
                            if (!processedPrintPendingOrders.has(preorder._id)) {
                                processedPrintPendingOrders.add(preorder._id);
                                // preorders should only be auto accepted if:
                                // 1. backend flag enabled
                                // 2. a station has been designated for auto accepting
                                // - this flag is in current session, and expires after a logout/refresh...etc.
                                if (CompanyAttributesService.hasPreorderAutoConfirm() && CurrentSession.isAutoAcceptPreorders()) {
                                    // We should register a success and error callback when calling
                                    // acceptPreorder. Or explain why we dont need to act upon these
                                    // callbacks
                                    acceptPreorder(preorder);

                                    try {
                                        KdsService.sendPreorderToKds(user, preorder);
                                    } catch (error) {
                                        $log.error('Failed to send preorder to KDS', error);
                                    }
                                }

                                playNewPreorderSound();

                                // this function depends on mobileOrderUsers being
                                // populated above
                                initializePeriodicSound();
                            }
                        } else if (preorder.status === 'completed') {
                            if (!processedCompletedOrders.has(preorder._id)) {
                                processedCompletedOrders.add(preorder._id);
                                $rootScope.$emit('pos.mobileOrder.completed', preorder);
                            }
                        }
                    });
                });
                $rootScope.$emit('pos.users.update');
            };

            function processTransaction (trans) {
                if (trans === null) {
                    pendingTransaction = null;
                    LucovaWebSocket.setTransactionStatus(TRANSACTION_STATUS.CANCELLED);
                } else if (trans.status === 'completed') {
                    // Set useful contents from payload into PosStatusService.completedTransaction
                    // Used for displaying tip amount and new total in receipt page
                    /* PosStatusService.completedTransaction = {
                        total_amount: trans.amount_cents / 100,
                        charge_card_amount: trans.charge_card_cents / 100,
                        tip_amount: trans.tip_cents / 100
                    }; */

                    if (pendingTransaction && pendingTransaction.callback) {
                        pendingTransaction.callback(trans);
                    }
                    pendingTransaction = null;
                    /* LucovaWebSocket.setTransactionStatus(TRANSACTION_STATUS.COMPLETED); */
                } else if (trans.status === 'cancelled') {
                    pendingTransaction = null;
                    LucovaWebSocket.setTransactionStatus(TRANSACTION_STATUS.CANCELLED);
                } else if (trans.status === 'pending' || trans.status === 'init') {
                    // A direct transaction should have a callback
                    // function when the transaction is initiated (see
                    // $scope.tenderReceipt in PosTenderTransactionCtrl).
                    // The callback is to be executed when the transaction
                    // is completed, and should be passed along as the
                    // transaction progresses to ensure the callback is
                    // eventuallly executed.
                    if ((pendingTransaction && trans) && (pendingTransaction._id === trans._id) && pendingTransaction.callback) {
                        trans.callback = pendingTransaction.callback;
                    }
                    pendingTransaction = trans;
                    LucovaWebSocket.setTransactionStatus(TRANSACTION_STATUS.PENDING);
                }
            }
            var getUsers = function (directUsersCallback, manualRefresh) {
                PosStatusService.lucovaUsersLastUpdated = Date.now();

                if (Lucova.isConnected()) {
                    return Lucova.manager().getUsers(function (response) {
                        populateUsers(response, manualRefresh);
                        if (directUsersCallback) {
                            directUsersCallback(allUsers);
                        }
                        return response;
                    }, function (error) {
                        clearUsers();
                    }).$promise;
                } else {
                    $log.warn('[LucovaWebSocket.getUsers] Lucova.manager not initialized');
                    return Promise.reject([]);
                }
            };

            var getUngroupedScheduledPreorders = function (directUsersCallback, manualRefresh) {
                if (Lucova.isConnected()) {
                    return Lucova.manager().getScheduledPreorders().$promise.then((response) => {
                        var preordersArr = angular.copy(response.preorders) || [];
                        var ungroupedPendingPreorderUsers = [];
                        var allPendingOrderUsers = [];
                        _processIncomingPreorders(preordersArr, ungroupedPendingPreorderUsers, allPendingOrderUsers);
                        return Promise.resolve(ungroupedPendingPreorderUsers);
                    }).catch((error) => {
                        return Promise.reject(error);
                    });
                } else {
                    $log.warn('[LucovaWebSocket.getUngroupedScheduledPreorders] Lucova.manager not initialized');
                    return Promise.reject([]);
                }
            };

            function findElement (arr, propName, propValue) {
                for (var i = 0; i < arr.length; i++) {
                    if (arr[i][propName] === propValue) {
                        return arr[i];
                    }
                } // will return undefined if not found; you could return a default instead
            }

            var processedPrintPendingOrders = new Set();
            var processedCompletedOrders = new Set();
            function acceptPreorder (preorder, successCallback, errorCallback) {
                // acknowledges order and prints receipt
                $rootScope.$emit('pos.mobileOrder.print.receipt', preorder, successCallback, errorCallback);
            }

            function playNewPreorderSound () {
                var toPlaySound = CurrentSession.getCompany().attributes.other__pos_alert_sounds === 'true';
                if (toPlaySound) {
                    AudioService.mobileOrder();
                }
            }

            function initializePeriodicSound () {
                var toPlaySound = CurrentSession.getCompany().attributes.other__pos_alert_sounds === 'true';
                if (pendingNotificationInterval) {
                    clearInterval(pendingNotificationInterval);
                }

                pendingNotificationInterval = setInterval(() => {
                    let hasPendingOrder = false;
                    mobileOrderUsers.forEach((user) => {
                        if (user.preorder.length > 0 && user.preorder[0].status === 'print_pending') {
                            hasPendingOrder = true;
                        }
                    });
                    if (hasPendingOrder && toPlaySound) {
                        AudioService.mobileOrder();
                    }
                }, 5000);
            }

            var honeyBadgerTimeoutAlerts = {};
            function prepareWebsocketStatusCheck (res) {
                var messageId = res.message_id;
                honeyBadgerTimeoutAlerts[messageId] = {};
                honeyBadgerTimeoutAlerts[messageId]['timestamp'] = Date.now();
                honeyBadgerTimeoutAlerts[messageId]['timeout'] = setTimeout(function () {

                    delete honeyBadgerTimeoutAlerts[messageId];

                    // reconnect websocket
                    LucovaWebSocket.connect(true);

                }, 30000);
            }

            function clearAllWebsocketStatusCheck () {
                Object.keys(honeyBadgerTimeoutAlerts).forEach(function (messageId, index) {
                    clearTimeout(honeyBadgerTimeoutAlerts[messageId]['timeout']);
                });

                honeyBadgerTimeoutAlerts = {};
            }

            function clearWebsocketStatusCheck (messageId) {
                if (honeyBadgerTimeoutAlerts[messageId]) {
                    clearTimeout(honeyBadgerTimeoutAlerts[messageId]['timeout']);

                    delete honeyBadgerTimeoutAlerts[messageId];
                }
            }

            var webSocketOnMessage = function (res, source) {

                var messageId = res.message_id;
                if (PosStatusService.isMessageSeen(messageId)) {
                    clearWebsocketStatusCheck(messageId);
                    return;
                }

                PosStatusService.addSeenMessage(messageId);

                if (source === 'poll') {
                    // we received a message via polling instead of websocket???
                    // reconnect the websocket:
                    prepareWebsocketStatusCheck(res);
                }
                confirmMessageReceived(messageId);

                try {
                    var msgType = res.type;
                    var payload = res.payload;
                    // determine if sound is enabled for the company
                    var toPlaySound = CurrentSession.getCompany().attributes.other__pos_alert_sounds === 'true';

                    if (msgType === 'U') {
                        getUsers();
                    } else if (msgType === 'R') {
                        // update RSSI
                        updateRssi(res.payload.user_name, res.payload.rssi);
                    } else if (msgType === 'P') {
                        timestamp = new Date().getTime();
                        getUsers();
                        // Websocket updates transaction
                    } else if (msgType === 'transaction_update') {
                        if (payload.status === 'completed' || payload.status === 'cancelled') {
                            processTransaction(payload);
                        }
                    } else if (msgType === 'preorder') {
                        getUsers();
                    } else if (msgType === 'preorder_status') {
                        getUsers();

                        if (payload.status === 'cancelled') {
                            // Purposely using event pattern to make sure LucovaWebsocket
                            // is as independent and decoupled from other codes. The listener
                            // may also have to be in a controller, and event pattern is
                            // the only way to pass this info
                            $rootScope.$emit('pos.mobileOrder.cancelled', payload);
                        } else if (payload.status === 'completed') {
                            $rootScope.$emit('pos.mobileOrder.manual.completed', payload);
                        }
                    } else if (msgType === 'preorder_chat' || msgType === 'preorder_chat_read') {
                        if (toPlaySound && msgType === 'preorder_chat') {
                            // we do not need to play sound if it is a read receipt
                            AudioService.newChatMessage();
                        }

                        if (pendingNotificationInterval) {
                            clearInterval(pendingNotificationInterval);
                        }

                        pendingNotificationInterval = setInterval(() => {
                            let hasNewMessage = false;
                            mobileOrderUsers.forEach((user) => {
                                if (user.preorder.length > 0 && user.preorder[0].chat_channel && user.preorder[0].chat_channel.terminal_unread > 0) {
                                    hasNewMessage = true;
                                }
                            });
                            if (hasNewMessage && toPlaySound) AudioService.newChatMessage();
                        }, 30000);

                        $rootScope.$emit('pos.mobileOrder.homescreen.chat.update', payload);
                        $rootScope.$emit('pos.mobileOrder.dashboard.chat.update', payload);
                        $rootScope.$emit('pos.mobileOrder.modal.chat.update', payload);
                    } else if (msgType === 'kds') {
                        $rootScope.$emit('kds.message', payload);
                    }
                } catch (e) {
                    res = {
                        'username': 'anonymous',
                        'message': event.data
                    };
                }
            };

            function createSocket () {
                if (socket) {
                    console.log('Already Connected');
                    return socket;
                }

                var socketioUrl = socketioParams.socketioUrl;
                console.log('Connecting to ' + socketioUrl);

                var ioSocket = io(
                    socketioUrl,
                    {
                        query: 'room=' + socketioParams.socketioChannel
                    }
                );
                ioSocket.on('message', function (msg) {
                    try {
                        $rootScope.$apply(function () {
                            var res = JSON.parse(msg);
                            webSocketOnMessage(res, 'ws');
                        });
                    } catch (e) {
                        console.log(e);
                    }
                });
                ioSocket.on('connect', function () {
                    socketConnected = true;
                    console.log('WebSocket opened to ' + socketioUrl);
                });
                ioSocket.on('connect_error', function (error) {
                    console.error(error);
                });
                ioSocket.on('reconnect_error', function (error) {
                    console.error(error);
                });
                ioSocket.on('connect_timeout', function (error) {
                    console.error('Connect timeout');
                });
                ioSocket.on('disconnect', function (msg) {
                    socketConnected = false;
                    poll();
                });
                ioSocket.on('ping', function (msg) {
                    // currently doing a ping every 5 seconds. If we don't hear back
                    // from the server, within 5 seconds, let's assume the websocket
                    // connection is down temporarily and do a manual poll instead

                    if (!pongTimeoutId) {
                        pongTimeoutId = $timeout(function () {
                            pongTimeoutId = null;
                            poll();
                        }, 5000);
                    }
                });
                ioSocket.on('pong', function (responseTime) {
                    if (pongTimeoutId) {
                        $timeout.cancel(pongTimeoutId);
                        pongTimeoutId = null;
                    }
                });
                ioSocket.on('reconnect_attempt', function (msg) {
                    poll();
                });

                return ioSocket;
            }
            var confirmMessageReceived = function (messageId) {
                Lucova.manager().confirmMessageReceived(
                    {
                        message_id: messageId
                    },
                    function (response) { },
                    function (error) { }
                );
            };
            var sendDiagnostic = function (level, message) {
                Lucova.manager().sendMerchantEvent({type: 'fiit_diagnostic', level: level, message: message}, function (response) {
                }, function (error) {
                    console.error(error); // using console.log instead of $log to prevent accidental loop
                });
            };

            var poll = function () {
                if (PosStatusService.shiftStarted) {
                    Lucova.manager().pushPoll(
                        {},
                        {},
                        function (response) {
                            _.each(response.messages, function (message) {
                                webSocketOnMessage(message, 'poll');
                            });
                        },
                        function (error) { }
                    );
                }
            };

            var clearUsers = function () {
                allUsers.length = 0;
                nearbyUsers.length = 0;
                mobileOrderUsers.length = 0;
                ungroupedPreorderUsers.length = 0;
            };

            var poll15min = null;
            var pollEvery15Min = function () {
                if (poll15min) {
                    return;
                }

                poll();
                poll15min = $timeout(function () {
                    poll15min = null;
                    pollEvery15Min();
                }, 300000);
            };

            var LucovaWebSocket = {
                allUsers: allUsers,
                nearbyUsers: nearbyUsers,
                mobileOrderUsers: mobileOrderUsers,
                ungroupedPreorderUsers: ungroupedPreorderUsers,
                timestamp: timestamp,
                init: function (socketioUrl, userName, authToken, terminalId, socketioChannel) {
                    var params = {
                        'userName': userName,
                        'authToken': authToken,
                        'socketioUrl': socketioUrl,
                        'socketioChannel': socketioChannel,
                        'lucova-terminal-version': '1.0',
                    };
                    socketioParams = params;
                },
                isConnected: function () {
                    return socketConnected;
                },
                connect: function (forceNewConnection) {
                    if (socketioParams) {
                        socket = createSocket();
                    } else {
                        console.log('Connection attempt initiated without calling LucovaWebSocket.init()');
                    }

                    pollEvery15Min();
                },
                getUsers: getUsers,
                acceptPreorder: acceptPreorder,
                getUngroupedScheduledPreorders: getUngroupedScheduledPreorders,
                setTransaction: function (transaction) {
                    processTransaction(transaction);
                },
                getTransaction: function () {
                    return pendingTransaction;
                },
                getTransactionStatus: function () {
                    return transactionStatus;
                },
                setTransactionStatus: function (tranStatus) {
                    transactionStatus = tranStatus;
                },
                getAllUsers: function () {
                    return allUsers;
                },
                getMobileOrderUsers: function () {
                    return mobileOrderUsers;
                },
                getUngroupedPreorderUsers: function () {
                    return ungroupedPreorderUsers;
                },
                sendDiagnostic: sendDiagnostic,
                updateRssi: updateRssi,
                disconnect: function () {
                    if (socket) {
                        socket.disconnect();
                        socket = null;
                        socketConnected = false;
                    }
                    clearUsers();
                    clearAllWebsocketStatusCheck();
                },
                setLastScrollTimestamp: setLastScrollTimestamp
            };
            return LucovaWebSocket;
        }
    ]);
}
