'use strict';

const moment = require('moment');

module.exports = function (freshideasReports) {

    freshideasReports.controller('InventoryStockReport', [
        '$scope',
        'EnvConfig',
        'Security',
        'Lookup',
        'Reports',
        'DateRangeService',

        function ($scope, EnvConfig, Security, Lookup, Reports, DateRangeService) {

            let currentUser = Security.getUser() || {};

            $scope.saveSelectedFromDate = function () {
                var selectedFromDate = moment($scope.inventoryStockSearch.fromDate).startOf('day').valueOf();
                DateRangeService.setFromDate(selectedFromDate);
            };
            $scope.saveSelectedToDate = function () {
                var selectedToDate = moment($scope.inventoryStockSearch.toDate).endOf('day').valueOf();
                DateRangeService.setToDate(selectedToDate);
            };

            $scope.init = function () {
                var fromDate;
                var toDate;
                if (DateRangeService.getFromDate()) {
                    fromDate = moment(DateRangeService.getFromDate()).startOf('day').toDate();
                } else {
                    fromDate = moment().startOf('week').toDate();
                }
                if (DateRangeService.getToDate()) {
                    toDate = moment(DateRangeService.getToDate()).endOf('day').toDate();
                } else {
                    toDate = moment().endOf('day').toDate();
                }
                $scope.inventoryStockSearch = {
                    fromDate: fromDate,
                    toDate: toDate
                };
                $scope.company = currentUser.company;
                $scope.locationIdToSearch = currentUser.company.locationId;
                loadCompanyHierarchy();
            };

            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };
            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };

            $scope.getDownloadUrl = function () {
                if ($scope.isIosWebkit || $scope.isElectron) {
                    return;
                }

                var startDateTime = moment($scope.inventoryStockSearch.fromDate).startOf('day').valueOf();
                var endDateTime = moment($scope.inventoryStockSearch.endOfDate).endOf('day').valueOf();
                let url = EnvConfig.host + '/freshideas/web/reports/inventoryStockReport/export';

                url = url + '?locationId=' + $scope.locationIdToSearch + '&startDateTime=' + startDateTime + '&endDateTime=' + endDateTime;
                return url;
            };

            let loadCompanyHierarchy = function () {
                return Lookup.locationsByCompany({isAdvancedSearch: true}, function (response) {
                    $scope.company = _.findWhere(response, {id: $scope.company.companyId});
                    $scope.locations = [];
                    let children = ($scope.company && $scope.company.children) ? $scope.company.children : [];

                    if (children.length) {
                        _.each(children, function (location) {
                            $scope.locations.push(location);
                        });
                    }
                });
            };

            $scope.resetFilters = function () {
                $scope.inventoryStockSearch.fromDate = moment().startOf('week').toDate();
                $scope.inventoryStockSearch.toDate = moment().endOf('day').toDate();
                $scope.locationIdToSearch = currentUser.company.locationId;
            };

            $scope.init();
            $scope.resetFilters();
        }
    ]);
};
