'use strict';

const Controller = new (require('../../external/pos.controller.js'))();
const Decimal = require('decimal.js').default;
const SharedDataService = require('../../external/pos.data-service.js');

module.exports = function (posModule) {

    posModule.controller('PosTransactionExchangeCtrl', [
        '$scope',
        '$state',
        '$timeout',
        '$modal',
        '$translate',
        'SmbPosService',
        'PosAlertService',
        'SharedFunctionService',
        'SecondaryDisplay',
        'CashierShift',
        'CurrentSession',
        'Locations',
        'transaction',
        'alteredTransactionItems',
        'refundTransaction',
        'refundTenders',
        'LucovaWebSocket',
        'TRANSACTION_STATUS',
        'Pure',
        'GuestLabelService',
        'userObj',
        'CompanyAttributesService',
        function ($scope,
            $state,
            $timeout,
            $modal,
            $translate,
            SmbPosService,
            PosAlertService,
            SharedFunctionService,
            SecondaryDisplay,
            CashierShift,
            CurrentSession,
            Locations,
            transaction,
            alteredTransactionItems,
            refundTransaction,
            refundTenders,
            LucovaWebSocket,
            TRANSACTION_STATUS,
            Pure,
            GuestLabelService,
            userObj,
            CompanyAttributesService) {

            $scope.originalTransaction = transaction;

            $scope.alteredTransactionItems = alteredTransactionItems;

            $scope.modal = 'scanExchangeItems';

            $scope.isRefundComplete = false;

            $scope.fullReceipt = [];
            $scope.receipt = [];

            $scope.searchBar = {
                text: ''
            };

            var isScanningItem = false;

            $scope.hasExchangePriceLimitEnabled = function () {
                return CompanyAttributesService.hasExchangePriceLimitEnabled();
            };

            $scope.showTaxOverrideModal = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'pos/smb/templates/pos.order.tax.popup.tpl.html',
                    controller: 'SmbPosOrderTaxPopupCtrl',
                    windowClass: 'smb-pos smb-pos-popup smb-pos-popup__discount',
                    animation: false,
                    backdrop: 'static',
                    resolve: {
                        adjustments: function () {
                            return angular.copy(tenderData.adjustments);
                        },
                        showPinPad: function () {
                            return showPinPad;
                        }
                    }
                });

                modalInstance.result.then(function (modalResponse) {
                    if (modalResponse.selectedTaxRates) {
                        tenderData.adjustments.selectedTaxRates = modalResponse.selectedTaxRates;
                    } else {
                        delete tenderData.adjustments.selectedTaxRates;
                    }
                    rebuildReceipt();
                });
            };

            $scope.showDiscountOptions = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'pos/smb/templates/pos.order.discount.tpl.html',
                    controller: 'SmbPosOrderDiscountCtrl',
                    windowClass: 'smb-pos smb-pos-popup smb-pos-popup__discount',
                    animation: false,
                    backdrop: 'static',
                    resolve: {
                        adjustments: function () {
                            return SmbPosService.createDiscountAdjustments(tenderData.adjustments);
                        },
                        showPinPad: function () {
                            return showPinPad;
                        }
                    }
                });

                modalInstance.result.then(function (discountObj) {
                    SmbPosService.populateAdjustmentDiscount(discountObj, tenderData.adjustments);
                    rebuildReceipt();
                });
            };

            $scope.onScanned = function (upc) {
                if (isScanningItem) {
                    return;
                }

                if (!upc) {
                    return;
                }

                var timerToCancel = $timeout(function () {
                    isScanningItem = false;
                }, 6000);

                var foundItem = _.findWhere($scope.fullReceipt, {upc: upc});
                isScanningItem = true;

                if (!foundItem) {
                    var modalInstance = $modal.open({
                        templateUrl: 'common/modals/modalScanningItem.tpl.html',
                        controller: 'ScanningItemModalCtrl',
                        windowClass: 'smb-pos products2',
                        animation: false,
                        backdrop: 'static',
                        resolve: {
                            sku: function () {
                                return upc;
                            }
                        }
                    }, function (error) { });

                    var upcLookupObj = {
                        upc: upc,
                        serviceLocation: SmbPosService.shift.locationId,
                        itemsOnly: true
                    };
                    SmbPosService.searchMenuItemsByUpc(upcLookupObj).then(function (response) {
                        if (timerToCancel) {
                            $timeout.cancel(timerToCancel);
                        }

                        if (modalInstance) {
                            modalInstance.close();
                        }

                        var quantity = 1;
                        var total = Number(quantity) * response.price;
                        var taxAmount = 0;
                        var itemTaxAmount = 0;
                        var totalItemAmount = response.price;
                        if (response.taxRate) {
                            taxAmount = total * response.taxRate;
                            itemTaxAmount = response.price * response.taxRate;
                            totalItemAmount += itemTaxAmount;
                        }
                        var resultUpcItem = {
                            'locationServicePeriodMenuId': response.locationServicePeriodMenuId,
                            'quantity': quantity,
                            'name': response.name,
                            'taxAmount': taxAmount,
                            'price': response.price,
                            'total': total + taxAmount,
                            'upc': response.upc,
                            'itemPrice': response.price,
                            'itemTaxAmount': itemTaxAmount,
                            'itemTotalPrice': totalItemAmount,
                            'taxRate': response.taxRate,
                            'taxRateId': response.taxRateId,
                            'taxRules': response.taxRules,
                            'mealPlanAmount': response.mealPlanIndicator ? 1 : 0,
                            'mealPlanCount': ((response.mealPlanIndicator) ? Number(quantity) : 0),
                            'type': 'item',
                            'printoutType': response.printoutType
                        };

                        $scope.fullReceipt.push(resultUpcItem);
                        isScanningItem = false;

                        rebuildReceipt();
                    }, function (error) {
                        if (timerToCancel) {
                            $timeout.cancel(timerToCancel);
                        }
                        if (modalInstance) {
                            modalInstance.close();
                        }
                        isScanningItem = false;

                        if (error.data && error.data.exception && error.data.exception.appCode == 401) {
                            PosAlertService.showAlertByName('pincode-fail', {
                                message: 'smb.pos.exchange.item.scan.cannot.process',
                                title: 'smb.pos.exchange.item.search.failed'
                            });
                        } else {
                            PosAlertService.showAlertByName('pincode-fail', {
                                message: $translate.instant('smb.pos.exchange.item.scan.notvalid', {
                                    upc: upc
                                }),
                                title: 'smb.pos.exchange.item.search.failed'
                            });
                        }
                    });
                } else {
                    $timeout(function () {
                        foundItem.quantity += 1;
                        rebuildReceipt();
                    }, 0);

                    isScanningItem = false;
                }
            };

            $scope.removeExchangedItemFromFullReceipt = function (receiptItem) {
                var fullReceiptItem = $scope.fullReceipt[receiptItem.index];
                if (!fullReceiptItem) {
                    return;
                }

                $scope.fullReceipt.splice(receiptItem.index, 1);

                rebuildReceipt();
            };

            $scope.toDisableCheckout = function () {
                /** Commented By Akash Mehta by 11th Mar 2020
                *** The below check on isExchanging is to prevent double tap
                ***/
                if ($scope.isExchanging) {
                    return true;
                }

                if (!$scope.fullReceipt.length) {
                    return true;
                }

                if ($scope.availableCredit.toNumber() > 0.10 && $scope.hasExchangePriceLimitEnabled()) {
                    return true;
                }


                return false;
            };


            $scope.calculateAvailableCredit = function () {
                var totalRefundPrice = new Decimal(0.0);
                var totalTransactionPrice = new Decimal(0.0);

                _.each($scope.alteredTransactionItems, function (item) {
                    totalRefundPrice = totalRefundPrice.plus(new Decimal(item.total));
                });

                _.each($scope.receipt, function (item) {
                    totalTransactionPrice = totalTransactionPrice.plus(new Decimal(item.total));
                });

                $scope.availableCredit = totalRefundPrice.minus(totalTransactionPrice);
            };


            $scope.getQuantityToBeRefunded = function (item) {
                if (!$scope.alteredTransactionItems) {
                    return 0;
                }

                var originalQuantity = _.find($scope.originalTransaction.receipt, {
                    itemIndex: item.itemIndex
                }).quantity;

                return originalQuantity - item.quantity;
            };

            var isAddingItem = false;
            $scope.addItemToFullReciept = function (item) {
                var itemToAdd = angular.copy(item.item);
                if (isAddingItem) {
                    return;
                }
                isAddingItem = true;

                $scope.searchBar.text = '';
                $scope.archivedItemsReactive.length = 0;

                var foundItem = _.findWhere($scope.fullReceipt, {locationServicePeriodMenuId: itemToAdd.locationServicePeriodMenuId});
                if (!foundItem) {
                    var quantity = 1;
                    var total = Number(quantity) * itemToAdd.price;
                    var taxAmount = 0;
                    var itemTaxAmount = 0;
                    var totalItemAmount = itemToAdd.price;
                    if (itemToAdd.taxRate) {
                        taxAmount = total * itemToAdd.taxRate;
                        itemTaxAmount = itemToAdd.price * itemToAdd.taxRate;
                        totalItemAmount += itemTaxAmount;
                    }
                    var resultUpcItem = {
                        'locationServicePeriodMenuId': itemToAdd.locationServicePeriodMenuId,
                        'quantity': quantity,
                        'name': itemToAdd.name,
                        'taxAmount': taxAmount,
                        'price': itemToAdd.price,
                        'total': total + taxAmount,
                        'upc': itemToAdd.upc,
                        'itemPrice': itemToAdd.price,
                        'itemTaxAmount': itemTaxAmount,
                        'itemTotalPrice': totalItemAmount,
                        'taxRate': itemToAdd.taxRate,
                        'taxRateId': itemToAdd.taxRateId,
                        'taxRules': itemToAdd.taxRules,
                        'mealPlanAmount': itemToAdd.mealPlanIndicator ? 1 : 0,
                        'mealPlanCount': ((itemToAdd.mealPlanIndicator) ? Number(quantity) : 0),
                        'type': 'item',
                        'printoutType': itemToAdd.printoutType
                    };
                    $scope.fullReceipt.push(resultUpcItem);
                    isAddingItem = false;

                    rebuildReceipt();
                } else {
                    $timeout(function () {
                        foundItem.quantity += 1;
                        rebuildReceipt();
                    }, 0);

                    isAddingItem = false;
                }
            };

            $scope.archivedItemsReactive = [];
            var timer;
            $scope.isSearchingItems = false;

            $scope.searchItems = function () {
                $scope.isSearchingItems = true;
                if (timer) {
                    $timeout.cancel(timer);
                }
                let timeout = timer ? 500 : 0;
                timer = $timeout(function () {
                    let search = {
                        searchQuery: $scope.searchBar.text,
                        locationId: SmbPosService.shift.locationId,
                        itemsOnly: true
                    };
                    SmbPosService.searchItems(search).$promise.then((response) => {
                        var sp = response.posMenuItemEntries[0];
                        var archivedItems = sp.entries.map((e) => {
                            return {item: e};
                        });
                        $scope.archivedItemsReactive.length = 0;
                        $scope.archivedItemsReactive.push(...archivedItems);
                        $scope.isSearchingItems = false;
                    });
                }, timeout);
            };

            $scope.clearSearch = function () {
                if (timer) {
                    $timeout.cancel(timer);
                }
                $scope.searchBar.text = '';
            };

            $scope.updateExchangedItemQuantity = function (receiptItem, modification) {
                var fullReceiptItem = $scope.fullReceipt[receiptItem.index];

                if (!fullReceiptItem) {
                    return;
                }

                if (modification === 'minus') {
                    if (fullReceiptItem.quantity > 1) {
                        fullReceiptItem.quantity--;
                        rebuildReceipt();
                    }
                }

                if (modification === 'plus') {
                    fullReceiptItem.quantity++;
                    rebuildReceipt();
                }
            };

            var currentOrder = SmbPosService.startOrder({patron: {}});
            var tenderData = $scope.tenderData = {
                receipt: $scope.receipt,
                fullReceipt: $scope.fullReceipt,
                available: [],
                adjustments: {
                    dcbAdjustment: 0,
                    cashAdjustment: 0,
                    creditCardAdjustment: 0,
                    debitCardAdjustment: 0,
                    otherAdjustment: 0,
                    dollarDiscount: 0,
                    mealEquivalencyAdjustment: false,
                    percentDiscount: 0
                },
                currentOrderBalance: {
                    totalMeals: 0,
                    subTotalAmount: 0,
                    totalAmount: 0,
                    taxAmount: 0,
                    totalDiscount: 0,
                    mealError: false
                },
                totalDiscount: 0,
                tenderAmounts: {},
                initialTenders: [],
                deferred: false,
                lucovaUser: false // TODO
            };

            var createPosData = function () {
                return SharedFunctionService.createPosData(tenderData.receipt, currentOrder.patron, true,
                    SmbPosService.shift.locationId, tenderData.currentOrderBalance,
                    CurrentSession.getCompany().organization.settings, {
                    lucovaUser: tenderData.lucovaUser
                });
            };

            var rebuildReceipt = function () {
                tenderData.totalDiscount = SmbPosService.applyBasketDiscountAndTaxOverrides(
                    tenderData.fullReceipt,
                    tenderData.adjustments
                );

                tenderData.receipt.length = 0;
                var updatedFlatReceipt = Controller.rebuildReceipt(tenderData.fullReceipt);
                tenderData.receipt.push(...updatedFlatReceipt);
                $scope.calculateAvailableCredit();

                var calculated = SharedFunctionService.calculateBalances(tenderData.receipt, tenderData.available, tenderData.adjustments, createPosData(), $scope.patron);
                Controller.copyCalculations(calculated.payments, tenderData.currentOrderBalance);

                var manualCoupon = {};
                tenderData.tenderAmounts = SharedFunctionService.populateTenderAmounts(calculated.tenderAmounts, manualCoupon, $scope.patron);
                // SecondaryDisplay.updateTransaction($scope.receipt, $scope.currentOrderBalance, $scope.tenderAmounts, $scope.order.patron);
            };

            /** Commented By Akash Mehta by 11th Mar 2020
            *** The below check on isExchanging is to prevent double tap
            ***/
            $scope.isExchanging = false;
            $scope.initiateExchange = function (tenderData) {

                /** Commented By Akash Mehta by 11th Mar 2020
                *** The below check on isExchanging is to prevent double tap.
                *** If the value is true, means the exchange is already executing and we return
                *** Else we intiate a new exchange and set value to true.
                ***/
                if ($scope.isExchanging) {
                    return;
                }
                $scope.isExchanging = true;


                var creditAmountCents = 0;
                for (var refundTender of refundTenders) {
                    creditAmountCents += refundTender.amountCents || 0;
                }

                /**
                *** Commented By Akash Mehta on July 6th 2020
                *** Adding changes to ensure that delivery fee and tax is not credited
                *** to the store credits. Since this is an exchange, we do not need to refund
                *** the delivery fee and tax.
                ***/
                var deliveryFeeCents = ($scope.originalTransaction.deliveryFee || 0) * 100;
                var deliveryTaxCents = ($scope.originalTransaction.deliveryTax || 0) * 100;

                creditAmountCents -= (deliveryFeeCents + deliveryTaxCents);

                refundTransaction.refundAmountCents = creditAmountCents;
                refundTransaction.requestingUser = userObj;

                var creditAmount = new Decimal(creditAmountCents)
                    .dividedBy(new Decimal(100))
                    .toNearest(SharedDataService.baseDollar)
                    .toNumber();
                var exchangeSaleAmount = tenderData.tenderAmounts.totalSales;
                var creditAmountToApply = Math.min(creditAmount, exchangeSaleAmount);

                var initialTender = {
                    type: 'giftcard',
                    field: 'giftCardAdjustment',
                    amount: creditAmountToApply,
                    uuid: Pure.generateUuid(),
                    giftCardId: undefined,
                    giftCard: {
                        organizationId: CurrentSession.getCompany().organizationId,
                        source: 1 // internal/store credit
                    }
                };
                tenderData.initialTenders = [initialTender];

                $scope.openTenderForExchangeTransaction(refundTransaction);
            };

            $scope.openTenderForExchangeTransaction = function (refundTransaction) {
                var posData = createPosData();

                currentOrder.patron.guest = !transaction.patronName;
                currentOrder.patron.fullName = transaction.guestLabel;

                LucovaWebSocket.setTransactionStatus(TRANSACTION_STATUS.INITIAL);
                var tenderModal = $modal.open({
                    templateUrl: 'pos/smb/templates/pos.tender.tpl.html',
                    controller: 'PosTenderTransactionCtrl',
                    windowClass: 'smb-pos__tender-modal',
                    animation: false,
                    resolve: {
                        sendToKdsAndBackend: function () {
                            return function (suspendedTransaction, orderNumber) {
                                // sendToKdsAndBackend(suspendedTransaction, orderNumber);
                            };
                        },
                        posData: function () {
                            return posData;
                        },
                        patron: function () {
                            return currentOrder.patron;
                        },
                        lucovaUser: function () {
                            return currentOrder.patron.lucovaUser;
                        },
                        currentOrder: function () {
                            return currentOrder;
                        },
                        verified: function () {
                            return $scope.verified; // TODO?
                        },
                        guestTransaction: function () {
                            // return $scope.guestTransaction; // TODO?
                            return currentOrder.guest;
                        },
                        cashierShiftId: function () {
                            console.log(SmbPosService.shift.cashierShiftId);
                            return SmbPosService.shift.cashierShiftId;
                        },
                        locationId: function () {
                            console.log(SmbPosService.shift.locationId);
                            return SmbPosService.shift.locationId;
                        },
                        receipt: function () {
                            return tenderData.receipt;
                        },
                        fullReceipt: function () {
                            return tenderData.fullReceipt;
                        },
                        available: function () {
                            return []; // angular.copy($scope.available);
                        },
                        servicePeriodId: function () {
                            return SmbPosService.shift.servicePeriodId;
                        },
                        transactionAmount: function () {
                            return tenderData.tenderAmounts.remainingAmount;
                        },
                        quickCharge: function () {
                            return false;
                        },
                        campaignDiscount: function () {
                            return {}; // campaignLargerDiscount; // TODO?
                        },
                        manualCoupon: function () {
                            return {};
                        },
                        TRANSACTION_STATUS: function () {
                            return TRANSACTION_STATUS;
                        },
                        isOffline: function () {
                            return false;
                        },
                        suspendedOrder: function () {
                            return undefined;
                        },
                        initialAdjustments: function () {
                            // Important to copy the adjustments so that if the user backs out from tender screen,
                            // the changes to adjustments (due to store credit that is initially applied) is not
                            // modifying this adjustments.
                            return angular.copy(tenderData.adjustments);
                        },
                        initialTenders: function () {
                            return tenderData.initialTenders;
                        },
                        applyTransactionPercentageDiscount: function () {
                            return 0; // $scope.applyTransactionPercentageDiscount;
                        },
                        cancelledItemsCount: function () {
                            return 0;
                        },
                        cancelledItemsAmount: function () {
                            return 0;
                        },
                        cancelledItemsUserId: function () {
                            return undefined;
                        },
                        solinkCancelledItems: function () {
                            return [];
                        },
                        guestLabel: function () {
                            return currentOrder.patron.fullName;
                        },
                        originalTransactionId: function () {
                            return transaction.receiptId;
                        },
                        refundTransactionForExchange: function () {
                            return refundTransaction;
                        },
                        /* The below injections are empty because they aren't being used, but we still need to inject them
                           to satisfy PosTenderTransactionCtrl's listed injections. */
                        modifyExistingItem: function () {
                            return function () { };
                        },
                        removeReceiptItem: function () {
                            return function () { };
                        },
                        modifyItemQuantity: function () {
                            return function () { };
                        },
                        ticketPrePrint: function () {
                            return undefined;
                        },
                        buzzerCode: function () {
                            return undefined;
                        }
                    },
                    backdrop: 'static',
                    keyboard: false
                }, function (error) {
                    $scope.tendering = undefined;
                });

                tenderModal.result.then(function (tender) {

                    /** Commented By Akash Mehta by 11th Mar 2020
                    *** Unsetting isExchanging to remove the double tap check.
                    *** Not really required on succes response, but it
                    *** is always good to be explicit
                    ***/
                    $scope.isExchanging = false;
                    if (tender === 'done') {
                        $scope.isDone = true;
                    }

                    $scope.tendering = undefined;
                    LucovaWebSocket.setTransactionStatus(TRANSACTION_STATUS.DEAD);
                    $scope.$close();
                }, function (errorResponse) {

                    /** Commented By Akash Mehta by 11th Mar 2020
                    *** Unsetting isExchanging to remove the double tap check.
                    ***/
                    $scope.isExchanging = false;

                    $scope.tendering = undefined;
                    if (errorResponse && errorResponse.refundFailed) {

                        var message = 'smb.pos.exchange.failed.msg';

                        var errorObj = errorResponse.error || {};

                        if (errorObj.data && errorObj.data.exception && errorObj.data.exception.message) {
                            message = errorObj.data.exception.message;
                        }

                        PosAlertService.showAlertByName('oops-general', {
                            title: 'smb.pos.exchange.failed.title',
                            message: message,
                        });
                    } else {
                        PosAlertService.showAlertByName('oops-general', {
                            title: 'smb.pos.exchange.failed.title',
                            message: 'smb.pos.exchange.failed.msg',
                        });
                    }
                });

            };

            var showPinPad = function (type, model, callback, validator, note) {
                var modalInstance = $modal.open({
                    templateUrl: 'pos/pos.numpad.tpl.html',
                    controller: 'PosNumpadCtrl',
                    animation: false,
                    windowClass: 'modal-numpad modal-fullscreen-transparent modal-right',
                    resolve: {
                        initial: function () {
                            return model;
                        },
                        type: function () {
                            return type;
                        },
                        note: function () {
                            return note;
                        }
                    },
                    backdrop: true
                });
                modalInstance.lucovaValidator = validator;

                return modalInstance.result.then(function (value) {
                    if (callback) {
                        callback(value);
                    }
                });
            };

            $scope.init = function () {
                $scope.availableCredit = new Decimal(0.0);
                $scope.calculateAvailableCredit();
            };

            $scope.init();
        }
    ]);
};

