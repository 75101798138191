'use strict';

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosOrderCtrl', [
        '$scope',
        '$modal',
        '$rootScope',
        'SmbPosService',
        'SystemService',
        'ConfirmModalService',
        'SecondaryDisplay',
        'KioskService',
        '$timeout',
        function ($scope, $modal, $rootScope, SmbPosService, SystemService, ConfirmModalService, SecondaryDisplay, KioskService, $timeout) {
            SystemService.setCanShowMessage(false);

            var shouldOpenLoyaltyPopup = function () {
                if (KioskService.isKiosk()) {
                    return false;
                }
                var doesPatronHaveLoyalty = hasLoyalty();
                var isSuspended = !!($scope.order.patron && $scope.order.patron.suspended);

                // Open the popup only when
                // - the patron has loyalty
                // - the order is not a held order (to prevent both the loyalty popup and held order popup to show at the same time)
                return doesPatronHaveLoyalty && !isSuspended;
            };

            /**
            *** Commented By Akash Mehta on 27th Mar 2020
            *** This function is used to check if we should open the patron loyalty popup or not. We want it to open
            *** even when you scan a phyiscal FIIT meal card. In order to support this additional functionality, I have
            *** added and extra 'OR' check, to check for a new field - 'fiitMpsAccount'.
            *** This is on the assumption that when you scan a phyiscal FIIT meal card on the home page,
            *** it sends a patron object containing only the fiit mps account object (No lucova user, no fiit patron).
            *** Hence, If there is any changes to the incoming object, please ensure to update this check as well to maintain
            *** code cleanliness
            **/
            var hasLoyalty = function () {
                return !!($scope.order.patron
                    && ($scope.order.patron.fiitPatron || $scope.order.patron.lucovaUser || $scope.order.patron.fiitMpsAccount));
            };

            /**
             * This function may be cleaned up in the future.
             * Currently we require this when applying an item-specific
             * loyalty card. The item-specific loyalty card only specifies
             * the item id (potentially child item) of which the loyalty
             * card is applicable for. With this in mind, the code tries
             * to build out any parent item relationship that exists above
             * this item.
             * The structure does not get returned until the page level is reached.
             *
             * Proceed with caution!!
             */
            function buildItem (item) {
                if (item.currentCategoryId && !item.forceTopLevel) {
                    var _parentItem = SmbPosService.findMenuItem(undefined, item.currentCategoryId, $scope.currentOrder);
                    _parentItem = angular.copy(_parentItem); // Very important, only modify a copy of the item
                    if (_parentItem && _parentItem.subtype == 'page') {
                        return item;
                    }
                    if (_parentItem) {
                        let _item = _parentItem.children.find((_item) => _item.locationServicePeriodMenuId == item.locationServicePeriodMenuId);
                        _item = angular.copy(_item); // Very important, only modify a copy of the item
                        if (item.isFullyDiscounted) {
                            _item.selected = true;
                            _item.quantity = 1;
                        }
                        _item.isFullyDiscounted = true;
                        _parentItem.isFullyDiscounted = true;
                    }
                    return buildItem(_parentItem);
                } else {
                    return item;
                }
            }

            // gets called when loyalty modal controller (SmbPosLoyaltyCtrl) is exited
            function processLoyaltyModalResponse (response) {
                if (!response) {
                    return;
                }
                if (response.items) {
                    var items = response.items;

                    _.each(items, function (item) {
                        $scope.$broadcast('pos::item::add', item.data);
                    });
                }
                if (response.loyaltyStepItemData) {
                    $scope.$broadcast('pos::loyaltyStepItem::add', response.loyaltyStepItemData);
                }
                if (response.coupon) {
                    var coupon = response.coupon.action;

                    switch (coupon.type) {
                        case 'item':
                            var locationServicePeriodMenuId = coupon.data.locationServicePeriodMenuId;

                            var couponItem = SmbPosService.findMenuItem(undefined, locationServicePeriodMenuId, $scope.currentOrder);
                            if (couponItem) {
                                couponItem = angular.copy(couponItem);
                                /* couponItem.originalPrice = couponItem.price;
                                couponItem.price = unitPrice;
                                couponItem.isFullyDiscounted = true; */


                                $scope.$broadcast('pos::coupon::add', 'item', coupon, couponItem);
                            }
                            break;
                        case 'transaction':
                            $scope.$broadcast('pos::coupon::add', 'transaction', coupon);
                    }
                }
                if (response.mealPlan) {
                    let mealPlan = response.mealPlan;
                    let itemId = mealPlan.locationServicePeriodMenuId;

                    // Show Reload Modal
                    let dcbReload = mealPlan.mealPlanType === 'DCB' && mealPlan.currentDcbBalance === 0;
                    let mealUnitReload = mealPlan.mealPlanType === 'MEAL' && mealPlan.currentMealPlanBalance === 0;

                    if (dcbReload || mealUnitReload || mealPlan.reload) {
                        let reloadMessage = mealPlan.mealPlanType == 'MEAL' ?
                            `This loyalty card will be reloaded with ${mealPlan.initialBalance} units` :
                            `This loyalty card will be reloaded with $ ${mealPlan.initialBalance}`;
                        ConfirmModalService.openConfirmModal(
                            'Reload Loyalty Card',
                            reloadMessage,
                            undefined,
                            undefined,
                            undefined,
                            true
                        ).then(() => {
                            reloadMealPlan(mealPlan);
                        });
                        return;
                    }
                    if (itemId && mealPlan.mealPlanType === 'MEAL') {
                        /**
                         * Temporary deduction from mealplan because the source of truth
                         * only gets updated after a transaction. When an item is removed
                         * from the basket, we do the reverse in pos.order.parent.ctrl.js:1180
                         */
                        mealPlan.currentMealPlanBalance--;
                        // Selecting a item specific meal plan will automatically add this
                        // item to the basket
                        var item = SmbPosService.findMenuItem(undefined, mealPlan.locationServicePeriodMenuId, $scope.currentOrder);
                        // Apply the loyalty card discount to a copy of the item and not the original item itself
                        var itemCopy = angular.copy(item);
                        itemCopy.isFullyDiscounted = true;

                        var parentItem = buildItem(itemCopy);
                        parentItem.preCalculatedTender = {
                            transactionType: 'OTHER',
                            patronMealPlanId: mealPlan.patronMealPlanId,
                            patronId: mealPlan.patronId,
                            mealPlanId: mealPlan.mealPlanId,
                            transactionTenderDetail: {
                                otherType: mealPlan.name,
                                otherDisplayName: 'Loyalty Item Unit'
                            }
                        };

                        /**
                         * Zero out the item price because this item was already paid for
                         * by a prepaid loyalty card. The item is only added to the cart
                         * for recording purpose.
                         */
                        parentItem.itemPrice = 0;
                        parentItem.itemTaxAmount = 0;
                        parentItem.itemTotalPrice = 0;
                        parentItem.price = 0;
                        parentItem.taxAmount = 0;
                        parentItem.taxRate = 0;
                        parentItem.total = 0;
                        parentItem.taxRateId = null;

                        $scope.$broadcast('pos::item::add', parentItem);
                    } else if (mealPlan.mealPlanType === 'DCB') {
                        // Apply DCB to basket
                        var dcbRedeemObject = {
                            id: mealPlan.mealPlanId,
                            patronId: mealPlan.patronId,
                            patronMealPlanId: mealPlan.patronMealPlanId,
                            name: mealPlan.name,
                            label: `**Redeeming ${mealPlan.name}`,
                            balance: mealPlan.currentDcbBalance
                        };
                        $scope.$broadcast('pos::cash-card-discount', dcbRedeemObject);
                    }
                }
                if (response.addMealPlan) {
                    let mealPlan = response.addMealPlan;
                    var mealPlanItem = {
                        name: mealPlan.name,
                        taxRate: 0,
                        children: [],
                        type: 'item',
                        subtype: 'loyalty.create',
                        upc: mealPlan.mealPlanId,
                        mealEquivalencyEnabled: false,
                        loyaltyEnabled: false, // Disallow loyalty card from loyalty purchase
                    };
                    let price = mealPlan.mealPlanAttribute ? mealPlan.mealPlanAttribute.price : 0;
                    $scope.$broadcast('pos::custom-item::add', mealPlanItem, price);
                }
            }

            $scope.handleCustomerLoyaltyModalResponse = function () {
                // This function is unrelated to loyalty popup but falls under the same conditions.
                if (!shouldOpenLoyaltyPopup()) {
                    return;
                }
                var response = SmbPosService.getCustomerLoyaltyModalResponse();
                SmbPosService.setCustomerLoyaltyModalResponse(null);
                processLoyaltyModalResponse(response);
            };

            $scope.$on('pos::open-customer-loyalty', function (event, args) {
                $scope.openCustomerLoyalty(args);
            });

            $scope.openCustomerLoyalty = function (adjustments = {}) {
                if (shouldOpenLoyaltyPopup()) {
                    var modalInstance = $modal.open({
                        templateUrl: 'pos/smb/templates/pos.loyalty.tpl.html',
                        animation: false,
                        backdrop: true,
                        controller: 'SmbPosLoyaltyCtrl',
                        windowClass: 'smb-pos smb-pos__loyalty-modal',
                        resolve: {
                            patron: function () {
                                return $scope.order.patron;
                            },
                            location: function () {
                                return $scope.location;
                            },
                            goHome: function () {
                                return function () {
                                    $scope.switchView('start');
                                };
                            },
                            adjustments: function () {
                                return adjustments;
                            }
                        }
                    });

                    modalInstance.result.then(function (response) {
                        let offer, associatedDiscount;
                        if (response && response.discountOfferToUse) {
                            offer = response.discountOfferToUse.offer;
                            associatedDiscount = response.discountOfferToUse.associatedDiscount;
                        }

                        $scope.$broadcast('pos::applyDiscountOffer', offer, associatedDiscount);
                        processLoyaltyModalResponse(response);
                    });
                }
            };

            $scope.$on('pos::updateOffersUsedDisplayValue', (event, value) => {
                $scope.offersUsedDisplayValue = value;
            });

            function reloadMealPlan (mealPlan) {
                var reloadItem = {
                    name: mealPlan.name + ' *Reload (' + mealPlan.patronMealPlanId + ')',
                    taxRate: 0,
                    children: [],
                    type: 'item',
                    subtype: 'loyalty.reload',
                    upc: mealPlan.patronMealPlanId,
                    mealEquivalencyEnabled: false,
                    loyaltyEnabled: false, // Disallow loyalty card from loyalty purchase
                };
                $scope.$broadcast('pos::custom-item::add', reloadItem, mealPlan.price);
            }

            $scope.triggerApplyTransactionPercentageDiscount = function () {
                $scope.$broadcast('pos::transcation::applyPercentageDiscounts');
            };

            var destroyCustomerProfileListener = $rootScope.$on('customer.profile', function (event, p) {
                let tempP = {
                    fiitPatron: p,
                    lucovaUser: null
                };
                let patron = SmbPosService.transformPatron(tempP);
                $scope.order.patron = patron;
                $scope.updateOrderPatron(patron);
            });

            /**
            *** Commented By Akash Mehta on August 27 2020
            *** This function generates data-cy values which are used by cypress tests.
            *** Please ensure everything still works if you are changing anything in the function
            *** below.
            ***/
            $scope.generateDataCy = function (item) {
                if (item) {
                    var itemName = (item.name || '').replace(' ', '_').toLowerCase();
                    return item.type + '_' + itemName;
                }
            };

            $scope.init = function () {
                if (SmbPosService.shift) {
                    $scope.order = $scope.currentOrder;
                    $scope.location = SmbPosService.shift.location;

                    SecondaryDisplay.beginTransaction($scope.order.patron);

                    /* `$scope.$broadcast('pos::item::add', item.data)` won't work unless the child controller,
                    `SmbPosOrderMenuCtrl` is initialized. Timeout is used to delay the broadcast to give the child
                    controller more time to initialize. Preferrably we find a way to detect when a child controller
                    is initialized. */
                    $timeout(() => {
                        $scope.handleCustomerLoyaltyModalResponse();
                    }, 500);

                }
            };

            $scope.init();

            $scope.$on('$destroy', function () {
                SystemService.setCanShowMessage(true);
                destroyCustomerProfileListener();
            });
        }
    ]);
};
