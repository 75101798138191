'use strict';


const angular = require('angular');
const AppConstants = require('../common/freshideas/app-constants.js');
const freshideasResourcesLocations = require('../common/resources/location.js').default;
const freshideasKds = angular.module('freshideas.kds', [freshideasResourcesLocations.name]);

freshideasKds.controller('KdsCtrl', [
    '$scope',
    '$location',
    '$interval',
    '$timeout',
    '$anchorScroll',
    '$state',
    '$stateParams',
    '$rootScope',
    '$translate',
    'SmbPosService',
    'KdsService',
    'CurrentSession',
    'Locations',
    'SharedDataService',
    'PrintService',
    'EnvConfig',
    '$sce',
    '$modal',
    'AudioService',
    'CompanyAttributesService',
    'PosUsersService',
    function (
        $scope,
        $location,
        $interval,
        $timeout,
        $anchorScroll,
        $state,
        $stateParams,
        $rootScope,
        $translate,
        SmbPosService,
        KdsService,
        CurrentSession,
        Locations,
        SharedDataService,
        PrintService,
        EnvConfig,
        $sce,
        $modal,
        AudioService,
        CompanyAttributesService,
        PosUsersService) {

        /* ----------*
         * Variables *
         *-----------*/
        $scope.isTransitionKds = false; // Whether or not transition is local to KDS

        $scope.status = {
            isopen: false
        };

        // Listen for KDS events from SocketIO
        var rootscopeKdsListener = $rootScope.$on('kds.message', function (event, payload) {
            // KdsService.processKdsEvent(payload);
            let timestamp = Date.now();
            KdsService.getReceiptsFromFiit(function () {
                KdsService.cleanReceipts(timestamp);
                kdsTimer = kdsDelay;
            });
        });

        /* ------------*
         * Main Timer *
         *------------*/
        // This interval ticks once every second
        // During each tick it updates the current timestamp (for display on template)
        // and counts down the kdsTimer. When kdsTimer gets to 0, we do a KDS poll,
        // and reset kdsTimer to 30 (kdsDelay).

        const kdsDelay = 10; // Base polling interval for KDS timer
        var kdsTimer = 2; // Time left before poll (first poll after 2 seconds)
        var timestampInterval;

        var initializeTimestampInterval = function () {
            timestampInterval = $interval(function () {
                // Update current timestamp
                $scope.currentTimestamp = Date.now();

                kdsTimer--;
                if (kdsTimer === 0) {
                    // Poll backend for KDS receipts
                    KdsService.getReceiptsFromFiit(function () {
                        KdsService.cleanReceipts($scope.currentTimestamp);
                        if (_.some($scope.receipts, {status: KdsService.ReceiptStatusEnum.INCOMING})) {
                            playNewOrderSound();
                        }
                        kdsTimer = kdsDelay;
                    });
                }
            }, 1000);
        };

        // Delays kdsTimer polling to execute x seconds later
        // This is incremented for UI interactive tasks so user interacting with UI does
        // not get interrupted by a random polling event.
        var delayKdsFiitPoll = function (delay) {
            kdsTimer += delay;

            // Cannot delay further than kdsDelay
            // (30 seconds)
            if (kdsTimer >= kdsDelay) {
                kdsTimer = kdsDelay;
            }
        };

        $scope.$on('$destroy', function (event) {
            // Cancel KDS rootscope listener
            rootscopeKdsListener();

            // Clear receipts if transition is not local to KDS
            if (!event.targetScope.isTransitionKds) {
                KdsService.clearReceipts();
            }

            // Cancel active scope intervals
            $interval.cancel(timestampInterval);
        });

        /* -----------*
         * Navigation *
         *------------*/
        $scope.toggleCompletedView = function () {
            $scope.hideCustomerDisplay = true;
            $scope.hideExpoScreen = true;
            $scope.isTransitionKds = true;
            if ($stateParams.view === 'incomplete') {
                PosUsersService.setLastKDSView('complete');
                $state.transitionTo($state.current, {
                    view: 'complete'
                });
            } else {
                PosUsersService.setLastKDSView('incomplete');
                $state.transitionTo($state.current, {
                    view: 'incomplete'
                });
            }

            if (!$scope.$$phase) {
                $scope.$apply();
            }
        };

        $scope.getView = function () {
            return $stateParams.view;
        };

        $scope.getViewName = () => {
            switch ($scope.getView()) {
                case 'complete':
                return 'Completed Orders';
                case 'incomplete':
                    return 'Incoming Orders';
                case 'expoScreen':
                    return 'Expo';
                default:
                    return '';
            }
        };

        // Scroll to tile
        $scope.scrollToTile = function (index) {
            $location.hash('kds-receipt-tile-container-' + index);
            $anchorScroll();
        };

        /**
         * Receipt Modification Functions
         */
        // Toggles an item to complete
        // NETWORK BOUND
        $scope.toggleItem = function (receipt, item) {
            item.selected = !item.selected;

            // Set status to preparing if it's incoming
            if (receipt.status === KdsService.ReceiptStatusEnum.INCOMING) {
                receipt.status = KdsService.ReceiptStatusEnum.PREPARE;
            }

            KdsService.updateReceiptFiit(receipt);
        };

        // Set receipt to "Prepare" status
        // NETWORK BOUND
        $scope.prepareReceipt = function (receipt) {
            receipt.status = KdsService.ReceiptStatusEnum.PREPARE;
            KdsService.updateReceiptFiit(receipt);
        };

        // Prepare an already completed receipt
        // Reset time and push back in as a new order
        // NETWORK BOUND
        $scope.prepareReceiptFromCompleted = function (receipt) {
            KdsService.prepareReceiptFromCompleted(receipt);
            KdsService.updateReceiptFiit(receipt);

            if (!$scope.$$phase) {
                $scope.$apply();
            }
        };

        /* --------------------------*
         * Receipt Animation Effects *
         *---------------------------*/
        // Pan receipt item (swipe to remove item)
        $scope.panReceiptItem = function (event, receipt) {
            delayKdsFiitPoll(5);

            if (receipt.isClosing) {
                return false;
            }

            // Should not scroll up
            if (event.deltaY < 0) {
                event.deltaY = 0;
            }

            receipt.isMoving = true;
            receipt.deltaY = event.deltaY;
        };

        // Reset receipt item position on end of pan
        // if it was not swiped off
        $scope.panReceiptItemReset = function (event, receipt) {
            setTimeout(function (receipt) {
                if (receipt.isClosing) {
                    return false;
                }
                receipt.isMoving = false;
                receipt.deltaY = 0;
                $scope.$apply();
            }, 0, receipt);
        };

        var printKDSReceipts = function (suspendedTransaction) {
            if (!suspendedTransaction) {
                return;
            }
            try {
                var posPrinters = SmbPosService.shift.posStation.posPrinters;
                var receiptItemsFiltered = JSON.parse(suspendedTransaction.receiptJson);
                var receiptItemsPrinterGrouped = _.groupBy(receiptItemsFiltered, 'printerId');

                var firstPosSecondaryPrinter = _.findWhere(posPrinters, {labelPrinter: true}) || {printerId: 'null'};

                for (var key of Object.keys(receiptItemsPrinterGrouped)) {
                    var receiptItems = receiptItemsPrinterGrouped[key];
                    var posPrinter = _.filter(posPrinters, {
                        'posPrinterId': parseInt(key),
                    });

                    // if pos printer cannot be found on this station, assign it to the first
                    // secondary printer instead;
                    if (posPrinter.length === 0) {
                        posPrinter = [firstPosSecondaryPrinter];
                    }

                    var response = {};
                    response.locationName = SmbPosService.shift.location.name || '(Location)';
                    response.location = SmbPosService.shift.location.address;
                    response.cashierName = ''; // NOTE: doesn't seem to be used and also no data is available immediately in the frontend
                    response.stationName = SmbPosService.shift.posStation.name || '(POS Station)';
                    response.companyName = SharedDataService.company.companyName || '(Company)';
                    response.receiptId = 0;
                    response.transactionDateTime = Date.now();
                    response.patronName = suspendedTransaction.orderName;
                    response.posPrinters = SmbPosService.shift.posStation.posPrinters;
                    response.patronName = suspendedTransaction.orderName;

                    response.heldOrder = {
                        suspendId: suspendedTransaction.suspendId,
                        orderName: suspendedTransaction.orderName,
                        orderEmail: suspendedTransaction.orderEmail,
                        orderPhoneNumber: suspendedTransaction.orderPhoneNumber
                    };
                    var mobileOrder = false;
                    var separateItems = false;

                    PrintService.printLabels(response, receiptItems, posPrinter, mobileOrder, separateItems);
                }
            } catch (e) {
                console.error(e);
            }
        };

        // Set receipt to "Completed" status, then check again 3 seconds later
        // If it hasn't been cancelled, remove receipt from list
        // NETWORK BOUND
        $scope.removeReceiptItemDelayed = function (receipt) {
            delayKdsFiitPoll(5);
            receipt.isCompleting = true;

            var timeoutCountdown = function (number) {
                if (number === 0 && receipt.isCompleting === true) {
                    receipt.status = KdsService.ReceiptStatusEnum.COMPLETE;
                    receipt.timestamp = Date.now();
                    KdsService.updateReceiptFiit(receipt);

                    // If this receipt originated from an Held order, print summary when in complete state
                    if (receipt.suspendId) {
                        Locations.getSuspend({'locationId': receipt.locationId}).$promise
                            .then((response) => {
                                const {entries} = response;
                                if (entries) {
                                    let suspendedTransaction = entries.find((e) => {
                                        return e.suspendId === receipt.suspendId;
                                    });
                                    printKDSReceipts(suspendedTransaction);
                                }
                            });
                    }

                    $scope.$apply();
                    return;
                }

                receipt.countdownText = number;
                receipt.timeoutPromise = $timeout(function () {
                    timeoutCountdown(number - 1);
                }, 1000);
            };

            // Delay kdsFiitPollingTimeout so they do not conflict with each other
            timeoutCountdown(3);
        };

        $scope.removeReceiptItemFromExpoDelayed = function (receipt) {
            delayKdsFiitPoll(5);
            receipt.isCompletingExpo = true;

            var timeoutCountdown = function (number) {
                if (number === 0 && receipt.isCompletingExpo === true) {
                    receipt.status = KdsService.ReceiptStatusEnum.EXPOCOMPLETE;
                    receipt.timestamp = Date.now();
                    KdsService.updateReceiptFiit(receipt);

                    $scope.$apply();
                    return;
                }

                receipt.countdownText = number;
                receipt.timeoutPromise = $timeout(function () {
                    timeoutCountdown(number - 1);
                }, 1000);
            };

            // Delay kdsFiitPollingTimeout so they do not conflict with each other
            timeoutCountdown(3);
        };

        // Swipe removes item from cart
        // NETWORK BOUND
        $scope.removeReceiptItemFlyDown = function (receipt) {
            delayKdsFiitPoll(5);
            if (receipt.isClosing) {
                return false;
            }

            receipt.isClosing = true;
            setTimeout(function () {
                receipt.status = KdsService.ReceiptStatusEnum.COMPLETE;
                receipt.timestamp = Date.now();
                KdsService.updateReceiptFiit(receipt);
                $scope.$apply();
            }, 400);
        };

        // Set receipt to "Prepare" status (undo)
        // Cancel receipt delete while its getting deleted
        $scope.undoReceipt = function (receipt) {
            if (receipt.timeoutPromise) {
                $timeout.cancel(receipt.timeoutPromise);
            }
            receipt.isCompleting = false;
        };

        $scope.undoReceiptExpo = function (receipt) {
            if (receipt.timeoutPromise) {
                $timeout.cancel(receipt.timeoutPromise);
            }
            receipt.isCompletingExpo = false;
        };

        // Miscellaneous Functions
        $scope.getPatronPhoto = function (photoUrl) {
            return SmbPosService.getPatronPhoto(photoUrl);
        };

        var init = function () {
            $scope.company = CurrentSession.getCompany();
            $scope.receipts = KdsService.receipts;
            $scope.kdsSettings = {
                enableKdsAudio: localStorage.hasOwnProperty('enableKdsAudio') ? JSON.parse(localStorage.getItem('enableKdsAudio')) : false,
                enableBumpBarSupport: localStorage.hasOwnProperty('enableBumpBarSupport') ? JSON.parse(localStorage.getItem('enableBumpBarSupport')) : false
            };

            // Initialize current timestamp
            $scope.currentTimestamp = Date.now();

            // Initialize timestamp interval
            initializeTimestampInterval();
            var isExpoScreenEnabled = CompanyAttributesService.hasExpoScreenEnabled();
            $scope.optionItems = $scope.optionItems.filter(function (option) {
                return isExpoScreenEnabled || option.label !== 'Expo Screen'; // Keep items that do not meet the condition
            });
            applyCustomBumpBarKeyBinds();
        };

        $scope.toggleKdsAudio = () => {
            localStorage.setItem('enableKdsAudio', JSON.stringify($scope.kdsSettings.enableKdsAudio));
        };

        $scope.toggleKdsBumpBarSupport = () => {
            localStorage.setItem('enableBumpBarSupport', JSON.stringify($scope.kdsSettings.enableBumpBarSupport));
        };

        const playNewOrderSound = () => {
            if ($scope.kdsSettings.enableKdsAudio) {
                AudioService.mobileOrder();
            }
        };

        $scope.switchToReceivingInventory = function () {
            $state.go('freshideas.inventoryV2');
        };

        $scope.hideCustomerDisplay = true;
        $scope.hideExpoScreen = true;

        $scope.displaySettings = {
            posStation: null
        };
        $scope.selectPosStation = function (station) {
            $scope.displaySettings.posStation = station;
        };
        $scope.saveSettings = function () {
            if (!$scope.displaySettings.posStation || !$scope.displaySettings.posStation.posStationId) {
                return;
            }
            localStorage.setItem('customerDisplayStationId', $scope.displaySettings.posStation.posStationId);
            showCustomerDisplay();
        };

        $scope.posStations = [];
        var isInteractive = false;
        async function showCustomerDisplay () {
            $scope.hideCustomerDisplay = false;
            let locationId = CurrentSession.getCompany().locationId;

            if (!localStorage.getItem('customerDisplayStationId')) {
                $scope.posStations.size = 0;
                let stations = await Locations.getLocationPOSStations({locationId: locationId}).$promise;
                $scope.posStations.push(...stations.entries);
            } else {
                // Cancel KDS functions before entering secondary display
                // Cancel KDS rootscope listener
                rootscopeKdsListener();

                // Cancel active scope intervals
                $interval.cancel(timestampInterval);

                let customerDisplayModal = $modal.open({
                    templateUrl: 'common/components/customer_display/customer_display.html',
                    animation: false,
                    backdrop: 'static',
                    windowClass: 'customer__display-modal',
                    controller: ['$scope', '$sce', function ($scope, $sce) {
                        $scope.getUrl = function () {
                            let customerDisplayUrl = EnvConfig.customerDisplayHost;
                            let wsUrl = EnvConfig.nownWsHost;
                            let nownHost = EnvConfig.nown_protocol + EnvConfig.nown_host + '/freshideas/web';
                            let selected = localStorage.getItem('customerDisplayStationId');
                            let url = `${customerDisplayUrl}?`
                                + `location_id=${locationId}`
                                + `&pos_station_id=${selected}`
                                + `&ws_url=${wsUrl}`
                                + `&nown_host=${nownHost}`
                                + `&interactive=${isInteractive}`;
                            return $sce.trustAsResourceUrl(url);
                        };
                    }]
                });

                customerDisplayModal.result.then(function (response) {
                    $scope.hideCustomerDisplay = true;

                    // Restore KDS functions
                    rootscopeKdsListener = $rootScope.$on('kds.message', function (event, payload) {
                        // KdsService.processKdsEvent(payload);
                        let timestamp = Date.now();
                        KdsService.getReceiptsFromFiit(function () {
                            KdsService.cleanReceipts(timestamp);
                            kdsTimer = kdsDelay;
                        });
                    });

                    $scope.company = CurrentSession.getCompany();
                    $scope.receipts = KdsService.receipts;

                    // Initialize current timestamp
                    $scope.currentTimestamp = Date.now();

                    // Initialize timestamp interval
                    initializeTimestampInterval();
                });
            }
        }

        function showNonInteractiveCustomerDisplay () {
            isInteractive = false;
            showCustomerDisplay();
        }

        function showInteractiveCustomerDisplay () {
            isInteractive = true;
            showCustomerDisplay();
        }

        function showPreorderDashboard () {
            $state.go('freshideas.preorderDashboard', {prevPage: 'ods'});
        }

        let bumpBarKeyBinds = {
            'SELECT_TICKET_1': {label: 'Select Ticket 1', defaultBind: AppConstants.defaultBumpBarKeyBinds.SELECT_TICKET_1},
            'SELECT_TICKET_2': {label: 'Select Ticket 2', defaultBind: AppConstants.defaultBumpBarKeyBinds.SELECT_TICKET_2},
            'SELECT_TICKET_3': {label: 'Select Ticket 3', defaultBind: AppConstants.defaultBumpBarKeyBinds.SELECT_TICKET_3},
            'SELECT_TICKET_4': {label: 'Select Ticket 4', defaultBind: AppConstants.defaultBumpBarKeyBinds.SELECT_TICKET_4},
            'SELECT_TICKET_5': {label: 'Select Ticket 5', defaultBind: AppConstants.defaultBumpBarKeyBinds.SELECT_TICKET_5},
            'SELECT_TICKET_6': {label: 'Select Ticket 6', defaultBind: AppConstants.defaultBumpBarKeyBinds.SELECT_TICKET_6},
            'SELECT_TICKET_7': {label: 'Select Ticket 7', defaultBind: AppConstants.defaultBumpBarKeyBinds.SELECT_TICKET_7},
            'SELECT_TICKET_8': {label: 'Select Ticket 8', defaultBind: AppConstants.defaultBumpBarKeyBinds.SELECT_TICKET_8},
            'SELECT_TICKET_9': {label: 'Select Ticket 9', defaultBind: AppConstants.defaultBumpBarKeyBinds.SELECT_TICKET_9},
            'SELECT_TICKET_10': {label: 'Select Ticket 10', defaultBind: AppConstants.defaultBumpBarKeyBinds.SELECT_TICKET_10},
            'UP': {label: 'Move Selection Up', defaultBind: AppConstants.defaultBumpBarKeyBinds.UP},
            'DOWN': {label: 'Move Selection Down', defaultBind: AppConstants.defaultBumpBarKeyBinds.DOWN},
            'LEFT': {label: 'Select Left Ticket', defaultBind: AppConstants.defaultBumpBarKeyBinds.LEFT},
            'RIGHT': {label: 'Select Right Ticket', defaultBind: AppConstants.defaultBumpBarKeyBinds.RIGHT},
            'CLICK': {label: 'Action', defaultBind: AppConstants.defaultBumpBarKeyBinds.CLICK},
            'BUMP_TICKET': {label: 'Bump Ticket', defaultBind: AppConstants.defaultBumpBarKeyBinds.BUMP_TICKET},
        };

        const applyCustomBumpBarKeyBinds = () => {
            $scope.kdsSettings.bumpBarKeyBinds = {};
            for (const action in bumpBarKeyBinds) {
                if (bumpBarKeyBinds[action]) {
                    const keyCode = bumpBarKeyBinds[action].customBind || bumpBarKeyBinds[action].defaultBind;
                    $scope.kdsSettings.bumpBarKeyBinds[keyCode] = action;
                }
            }
        };

        try {
            let customBumpBarKeyBinds = JSON.parse(localStorage.getItem('bumpBarKeyBinds') || 'null') || null;

            if (customBumpBarKeyBinds) {
                bumpBarKeyBinds = customBumpBarKeyBinds;
            }
        } catch (e) {
            // NOOP
        }

        function editBumpBarBindings () {
            const modalInstance = $modal.open({
                templateUrl: 'pos/smb/templates/pos.key.binding.tpl.html',
                animation: true,
                backdrop: true,
                controller: 'KeyBindingCtrl',
                resolve: {
                    bindings: () => angular.copy(bumpBarKeyBinds)
                }
            });

            modalInstance.result.then(function (newBindings) {
                if (newBindings) {
                    bumpBarKeyBinds = newBindings;
                    applyCustomBumpBarKeyBinds();
                    localStorage.setItem('bumpBarKeyBinds', JSON.stringify(bumpBarKeyBinds));
                }
            });
        }

        function showExpoScreen () {
            $scope.hideCustomerDisplay = true;
            PosUsersService.setLastKDSView('expoScreen');
            $state.transitionTo($state.current, {
                view: 'expoScreen'
            });

            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }

        $scope.filterByPrepareAndIncoming = function (receipt) {
            return receipt.status === 0 || receipt.status === 1;
        };

        $scope.filterByPrepareAndCompleted = function (receipt) {
            return receipt.status === 1 || receipt.status === 2;
        };

        $scope.isOverdue = function (receipt) {
            if ((Date.now() - receipt.timestamp) / 1000 / 60 % 60 >= 5) {
                return true;
            } else {
                return false;
            }
        };

        $scope.isGuest = function (receipt) {
            if (!receipt.patronName || receipt.patronName === 'Guest') {
                return '#' + receipt.receiptCounter;
            } else {
                return receipt.patronName;
            }
        };

        $scope.optionItems = [
            {label: '', callbackFunc: $scope.toggleCompletedView},
            {label: $translate.instant('kds.options.expoScreen'), callbackFunc: showExpoScreen},
            {label: $translate.instant('kds.options.customerDisplay'), callbackFunc: showNonInteractiveCustomerDisplay},
            {label: $translate.instant('kds.options.interactiveCustomerDisplay'), callbackFunc: showInteractiveCustomerDisplay},
            {label: $translate.instant('kds.options.preorderDashboard'), callbackFunc: showPreorderDashboard},
            {label: $translate.instant('keyBind.configure'), callbackFunc: editBumpBarBindings}
        ];

        if (localStorage.getItem('customerDisplayStationId')) {
            $scope.optionItems.push({
                label: 'Unlink Customer Display',
                callbackFunc: () => {
                    localStorage.removeItem('customerDisplayStationId');
                    var options = $scope.optionItems.filter((option) => {
                        return option.label !== 'Unlink Customer Display';
                    });
                    $scope.optionItems.length = 0;
                    $scope.optionItems = options;
                }
            });
        }

        if ($scope.getView() === 'complete' || $scope.getView() === 'expoScreen') {
            $scope.optionItems[0].label = 'Show Incomplete Orders';
        } else {
            $scope.optionItems[0].label = 'Show Completed Orders';
        }


        $scope.executeOption = function (choice) {
            if (choice.callbackFunc) {
                choice.callbackFunc();
            }
        };

        $scope.footerButtonAction = (receipt, index, receiptsCount) => {
            if (!receipt.isCompleting && receipt.status === 0 && index >= receiptsCount - 3) {
                $scope.prepareReceipt(receipt);
                return;
            }

            if (receipt.isCompleting) {
                $scope.undoReceipt(receipt);
                return;
            }

            if (!receipt.isCompleting && (receipt.status === 1 || (receipt.status === 0 && index < receiptsCount - 3))) {
                $scope.removeReceiptItemDelayed(receipt);
                return;
            }
        };

        init();
    }
]);

export default freshideasKds;
