'use strict';

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('PreorderDashboardHelpCtrl', [
        '$scope',
        '$modal',
        '$modalInstance',
        'GatewayFiit',
        function (
            $scope,
            $modal,
            $modalInstance,
            GatewayFiit) {

            $scope.fiitEnabled = GatewayFiit.isEnabled();
            $scope.openEditCurrentResourcesModal = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalEditCurrentResources.tpl.html',
                    controller: ['$scope',
                                 'Lucova',
                                 'PosAlertService',
                                 function (
                                    $scope,
                                    Lucova,
                                    PosAlertService) {

                        $scope.loading = false;

                        $scope.increaseWorkerCounter = function () {
                            $scope.workerCounter++;
                        };

                        $scope.decreaseWorkerCounter = function () {
                            if ($scope.workerCounter == 1) {
                                return;
                            }
                            $scope.workerCounter--;
                        };

                        var savingWorkerCapacity = false;
                        $scope.workerCounter = 1;

                        $scope.save = function () {
                            if (savingWorkerCapacity) {
                                return;
                            }
                            savingWorkerCapacity = true;
                            Lucova.manager().updateNode({
                                work_capacity: $scope.workerCounter
                            }, function () {
                                savingWorkerCapacity = false;
                                $scope.$close();
                            }, function () {
                                savingWorkerCapacity = false;
                                PosAlertService.showAlertByName('general', {
                                    title: 'pos.worker.capacity.update.failed.title',
                                    message: 'general.error.try.again.later'
                                });
                            });
                        };

                        $scope.cancel = function () {
                            $scope.$close();
                        };

                        var loadWorkerCount = function () {
                            $scope.loading = true;
                            Lucova.manager().getNode({}, function (response) {
                                $scope.loading = false;
                                $scope.workerCounter = response.work_capacity;
                            }, function (err) {
                                $scope.loading = false;
                                PosAlertService.showAlertByName('general', {
                                    title: 'pos.worker.capacity.fetch.failed.title',
                                    message: 'general.error.try.again.later'
                                });
                            });
                        };

                        loadWorkerCount();
                    }],
                    animation: false,
                    windowClass: 'edit-current-resources-modal',
                    resolve: {
                        page: function () {
                            return 'itemSearchPage';
                        },
                        searchResults: function () {
                            return [];
                        }
                    },
                    backdrop: true
                });
                return modalInstance.result.then(function (value) {
                });
            };

            $scope.dismiss = function () {
                $modalInstance.dismiss();
            };
        }
    ]);
};
