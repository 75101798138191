'use strict';


const moment = require('moment');

module.exports = function (freshideasReports) {

    freshideasReports.controller('LabourReport', [
        '$scope',
        '$location',
        'Reports',
        'Utils',
        'Lookup',
        'Export',
        'Security',
        'Platform',
        'DateRangeService',
        'USER_ROLE_TYPE',
        function ($scope, $location, Reports, Utils, Lookup, Export, Security, Platform, DateRangeService, USER_ROLE_TYPE) {
            var currentUser = Security.getUser() || {};
            $scope.isManager = currentUser.roleType === USER_ROLE_TYPE.MANAGER;
            $scope.isAccountant = currentUser.roleType === USER_ROLE_TYPE.ACCOUNTANT;
            $scope.isSiteAdmin = currentUser.permission === 'SITEADMIN';
            $scope.searchDisabled = false;
            var allowRetry;
            $scope.currentUser = Security.getUser();
            $scope.isFullAdmin = ($scope.currentUser.permission === 'FULLADMIN');
            $scope.isIosWebkit = Platform.isIosWebkit();
            $scope.searchBar = {
                text: ''
            };

            $scope.barChartOptions = {
                chart: {
                    'type': 'discreteBarChart',
                    'height': 420,
                    'width': 700,
                    'margin': {
                      'top': 20,
                      'right': 20,
                      'bottom': 50,
                      'left': 55
                    },
                    'showValues': true,
                    'duration': 500,
                    'xAxis': {
                      'axisLabel': 'Hour Type'
                    },
                    'yAxis': {
                      'axisLabel': 'Hours',
                      'axisLabelDistance': -10
                    },
                    'dispatch': {
                        renderEnd: function (e) {
                            d3.selectAll('#barChart svg')
                            .attr('width', null)
                            .attr('height', null)
                            .attr('viewBox', '0 0 700 420')
                            .style('width', '40vw')
                            .style('height', '20vw');
                        }
                    }
                }
            };

            $scope.pieChartOptions = {
                chart: {
                    'type': 'pieChart',
                    'width': 100,
                    'height': 100,
                    'x': function (d) {
                        return d.key;
                    },
                    'y': function (d) {
                        return d.y;
                    },
                    'duration': 500,
                    'labelThreshold': 0.01,
                    'labelType': 'key',
                    'labelSunbeamLayout': false,
                    'transitionDuration': 500,
                    'showLegend': false,
                    'showLabels': false,
                    'legend': {
                        'margin': {
                            'top': 0,
                            'right': 0,
                            'bottom': 0,
                            'left': 0
                        }
                    },
                    'pie': {
                        'margin': {
                            'top': 0,
                            'right': 0,
                            'bottom': 0,
                            'left': 0
                        }
                    },
                    'margin': {
                        'right': 0,
                        'left': 0,
                        'top': 0,
                        'bottom': 0
                    },
                    'dispatch': {
                        renderEnd: function (e) {
                            d3.selectAll('#pieChart svg')
                            .attr('width', null)
                            .attr('height', null)
                            .attr('viewBox', '0 0 100 100')
                            .style('width', '16vw')
                            .style('height', '16vw');
                        }
                    }
                }
            };

            $scope.pieData = [
                {
                    key: 'Regular Hours',
                    y: 0,
                    hours: 0,
                    color: '#C3E4D7',
                    type: 'unit'
                },
                {
                    key: 'OT Hours',
                    y: 0,
                    hours: 0,
                    color: '#49BD99',
                    type: 'unit'
                }
            ];

            $scope.barData = [
                {
                    values: [
                        {
                            x: 'Reg. Hours',
                            y: 0,
                            color: '#C3E4D7'
                        },
                        {
                            x: 'OT Hours',
                            y: 0,
                            color: '#49BD99'
                        }
                    ]
                }
            ];

            $scope.init = function () {
                if (DateRangeService.getFromDate()) {
                    $scope.labourSearch.startDateTime = moment(DateRangeService.getFromDate()).startOf('day').toDate();
                }
                if (DateRangeService.getToDate()) {
                    $scope.labourSearch.endDateTime = moment(DateRangeService.getToDate()).endOf('day').toDate();
                }
                $scope.labourSearchFilter = angular.copy($scope.labourSearch);

                lookupCompanyHierarchy().$promise.then(function () {
                    $scope.loadLabour();
                });
            };

            function lookupCompanyHierarchy () {
                return Lookup.locationsByCompany({}, function (response) {
                    $scope.company = _.findWhere(response, {id: currentUser.companyId});
                    $scope.locations = [];
                    let children = ($scope.company && $scope.company.children) ? $scope.company.children : [];

                    if (children.length) {
                        if (children.length > 1) {
                            $scope.locations.push({
                                id: null,
                                name: 'All Locations'
                            });
                        }
                        _.each(children, function (location) {
                            $scope.locations.push(location);
                        });
                        $scope.labourSearch.locationId = currentUser.company.locationId;
                    }
                });
            }

            $scope.setSortByField = function (sortBy) {
                $scope.currentReportSearch.sortBy = sortBy;
                $scope.currentReportSearch.ascending = !$scope.currentReportSearch.ascending;

                var greater = $scope.currentReportSearch.ascending ? 1 : -1;
                var less = $scope.currentReportSearch.ascending ? -1 : 1;

                $scope.labourData.sort((a, b) => {
                    if (a[sortBy] > b[sortBy]) {
                        return greater;
                    }
                    if (a[sortBy] < b[sortBy]) {
                        return less;
                    }
                    return 0;
                });
            };

            $scope.resetFilters = function () {
                $scope.labourSearch.startDateTime = moment().startOf('week').toDate();
                $scope.labourSearch.endDateTime = moment().endOf('day').toDate();
                $scope.labourSearch.isAdvancedSearch = true;
                $scope.labourSearch.sortBy = '';
                $scope.labourSearch.ascending = true;
                $scope.labourSearch.offSet = 0;
                $scope.labourSearch.limit = 50;
                $scope.labourSearch.locationId = currentUser.company.locationId;

                $scope.labourSearchFilter = angular.copy($scope.labourSearch);
                $scope.loadLabour();
            };

            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };
            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };

            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };

            $scope.labourSearch = {
                isAdvancedSearch: true,
                startDateTime: moment().startOf('week').toDate(),
                endDateTime: moment().endOf('day').toDate(),
                sortBy: '',
                ascending: true,
                offSet: 0,
                limit: 50,
                locationId: null
            };

            $scope.saveSelectedFromDate = function () {
                var selectedFromDate = moment($scope.labourSearchFilter.startDateTime).startOf('day').valueOf();
                DateRangeService.setFromDate(selectedFromDate);
            };
            $scope.saveSelectedToDate = function () {
                var selectedToDate = moment($scope.labourSearchFilter.endDateTime).endOf('day').valueOf();
                DateRangeService.setToDate(selectedToDate);
            };

            function resetPagingOptions () {
                $scope.pagingOptions = {
                    startRecord: 0,
                    pageSize: 10,
                    currentPage: 1,
                };
            }
            resetPagingOptions();

            $scope.pageUp = function () {
                var start = $scope.pagingOptions.startRecord;
                var newStart = start + $scope.pagingOptions.pageSize;
                if ($scope.labourData.length > newStart) {
                    $scope.pagingOptions.startRecord = newStart;
                    $scope.pagingOptions.currentPage++;
                }
            };

            $scope.pageDown = function () {
                var start = $scope.pagingOptions.startRecord;
                var newIndex = start - $scope.pagingOptions.pageSize;
                if (start > 0) {
                    if (newIndex < 0) {
                        $scope.pagingOptions.startRecord = 0;
                    } else {
                        $scope.pagingOptions.startRecord = newIndex;
                    }
                    $scope.pagingOptions.currentPage--;
                }
            };

            $scope.pagesAvailable = function () {
                var itemLen = $scope.labourData.length;
                var pageSize = $scope.pagingOptions.pageSize;
                return (itemLen > pageSize) ? Math.ceil(itemLen/pageSize) : 1;
            };

            $scope.loadLabour = function () {
                $scope.searchDisabled = true;
                clearTimeout(allowRetry);
                allowRetry = setTimeout(function () {
                    $scope.searchDisabled = false;
                }, 30000);
                $scope.labourSearch.startDateTime = moment($scope.labourSearchFilter.startDateTime).startOf('day').valueOf();
                $scope.labourSearch.endDateTime = moment($scope.labourSearchFilter.endDateTime).endOf('day').valueOf();
                $scope.currentReportSearch = angular.copy($scope.labourSearch);


                var transactionSearch = {
                    startDateTime: $scope.labourSearch.startDateTime,
                    endDateTime: $scope.labourSearch.endDateTime,
                    sortBy: '',
                    limit: 60,
                    locationId: $scope.labourSearch.locationId
                };

                $scope.loadingLabourReport = true;

                Reports.getLabourReport(transactionSearch, function (response) {
                    labourTotals(response);
                    Reports.getLabourSubSales(transactionSearch, function (response) {
                        $scope.allTotals.netSales = response[0].netSales;
                        $scope.loadingLabourReport = false;
                    });

                    if ($scope.allTotals) {
                        $scope.pieData[0].y = $scope.allTotals.regularHours;
                        $scope.pieData[0].hours = $scope.allTotals.regularHours;

                        $scope.pieData[1].y = $scope.allTotals.otHours;
                        $scope.pieData[1].hours = $scope.allTotals.otHours;

                        $scope.barData[0].values[0].y = $scope.allTotals.regularHours;
                        $scope.barData[0].values[1].y = $scope.allTotals.otHours;
                    }
                }, function (error) {
                    $scope.loadingLabourReport = false;
                });
            };

            var labourTotals = function (labourResults) {
                var labourSummaryList = labourResults[0].labourSummary;
                $scope.labourData = [];
                $scope.allTotals = {
                    labourHours: 0,
                    regularHours: 0,
                    otHours: 0,
                    labourCost: 0
                };
                _.each(labourSummaryList, function (item) {
                    var employeeRegularMinutes = 0;
                    var employeeOtMinutes = 0;
                    var employeeRegularPay = 0;
                    var employeeOtPay = 0;
                    var employeeGrossPay = 0;
                    _.each(item.labour, function (labour) {
                        employeeRegularMinutes += labour.regularMinutes;
                        employeeOtMinutes += labour.otMinutes;
                        employeeRegularPay += labour.regularPay;
                        employeeOtPay += labour.otPay;
                        employeeGrossPay += labour.grossPay;
                    });
                    var employeeLabour = {
                        employeeName: item.employeeName,
                        regularHours: +(employeeRegularMinutes / 60).toFixed(2),
                        otHours: +(employeeOtMinutes / 60).toFixed(2),
                        regularPay: employeeRegularPay,
                        otPay: employeeOtPay,
                        grossPay: employeeGrossPay,
                        labour: item.labour
                    };
                    $scope.labourData.push(employeeLabour);
                    $scope.allTotals.labourHours += employeeRegularMinutes + employeeOtMinutes;
                    $scope.allTotals.regularHours += employeeRegularMinutes;
                    $scope.allTotals.otHours += employeeOtMinutes;
                    $scope.allTotals.labourCost += employeeLabour.grossPay;
                });
                $scope.allTotals.labourHours = +($scope.allTotals.labourHours / 60).toFixed(2);
                $scope.allTotals.regularHours = +($scope.allTotals.regularHours / 60).toFixed(2);
                $scope.allTotals.otHours = +($scope.allTotals.otHours / 60).toFixed(2);

                $scope.originalLabourData = $scope.labourData;
            };

            $scope.getDownloadUrl = function () {
                if ($scope.isIosWebkit) {
                    return;
                }
                var reportSearch = {};
                reportSearch.startDateTime = moment($scope.labourSearch.startDateTime).startOf('day').valueOf();
                reportSearch.endDateTime = moment($scope.labourSearch.endDateTime).endOf('day').valueOf();
                if ($scope.labourSearch.locationId) {
                    reportSearch.locationId = $scope.labourSearch.locationId;
                }

                /* var downloadUrl = $location.protocol() + '://' + $location.host() + ':' + $location.port()
                 + '/freshideas/web/reports/itemSales/export';

                var queryString = jQuery.param(reportSearch);
                return downloadUrl + '?' + queryString;*/
            };

            $scope.toggleShowLabour = function (labourReport) {
                if (!labourReport.showModifiers) {
                    labourReport.showModifiers = true;
                } else {
                    labourReport.showModifiers = false;
                }
            };

            $scope.setAllShowTransactions = function (expanded) {
                $scope.labourData.forEach((item) => {
                    item.showModifiers = expanded;
                });
            };

            $scope.searchAction = function () {
                // Search filter
                if ($scope.searchBar.text !== '') {
                    var searchTextLowerCase = $scope.searchBar.text.toLowerCase();
                    var filteredArray = [];
                    $scope.originalLabourData.forEach((labour) => {
                        var employeeName = labour.employeeName.toLowerCase();
                        if (employeeName.indexOf(searchTextLowerCase) !== -1) {
                            filteredArray.push(labour);
                        }
                    });

                    $scope.labourData = filteredArray;
                } else {
                    $scope.labourData = $scope.originalLabourData;
                }
                resetPagingOptions();
            };

            $scope.clearSearch = function () {
                $scope.searchBar.text = '';
                $scope.labourData = $scope.originalLabourData;
            };

            $scope.exportToPdf = function (tableId) {
                Export.tableToPdf(tableId, 'landscape');
            };

            $scope.init();

            $scope.$on('$destroy', function () {
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.locationId', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.startDateTime', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.endDateTime', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });
        }
    ]);

};
