'use strict';


/**
 * This is application specific security. There is a lot of commonality between this and MDM.
 * TODO: Extract common logic.
 */

const angular = require('angular');
const $ = require('jquery');

const freshideasSecurityInterceptor = require('../freshideas/freshideas-security-interceptor.js').default;
const freshideasSecurityUsers = require('./freshideas-security-user.js').default;
const freshideasBase64 = require('../freshideas/freshideas-angular-base64.js').default;
const ngStrap = require('angular-strap');

var security = angular.module('freshideas.security', [
    freshideasSecurityInterceptor.name,
    freshideasSecurityUsers.name,
    freshideasBase64.name,
    ngStrap
]);
security.run([
    '$rootScope',
    '$location',
    '$log',
    'Security',
    function ($rootScope, $location, $log, Security) {
        // Pop for login if user isn't authed
        $rootScope.$on('auth-loginRequired', function (event) {
            Security.showLogin();
        });
    }
]);
// Configure Interceptor for appending viewId
security.config([
    '$httpProvider',
    function ($httpProvider) {
        var interceptor = [
            '$q',
            '$rootScope',
            '$injector',
            function ($q, $rootScope, $injector) {
                return {
                    'request': function (config) {
                        // var securityService = $injector.get('Security');
                        // if (securityService.isAuthenticated()) {
                        // }
                        return config || $q.when(config);
                    }
                };
            }
        ];
        return $httpProvider.interceptors.push(interceptor);
    }
]);
security.factory('Security', [
    '$http',
    '$resource',
    '$q',
    '$location',
    '$modal',
    '$log',
    '$translate',
    '$timeout',
    'AuthService',
    'SecurityUsers',
    'Base64',
    '$rootScope',
    'EventListener',
    'PosStatusService',
    'LookupService',
    'LucovaBluetooth',
    'CurrentSession',
    'SharedDataService',
    'CommonOfflineCache',
    'Homebase',
    'SolinkService',
    'Lookup',
    '$cookies',
    function ($http, $resource, $q, $location, $modal, $log, $translate, $timeout, AuthService,
       SecurityUsers, Base64, $rootScope, EventListener, PosStatusService,
       LookupService, LucovaBluetooth, CurrentSession, SharedDataService, CommonOfflineCache,
       Homebase, SolinkService, Lookup, $cookies) {
        // Private user reference.
        var currentUser;
        // Login modal logic
        var modalInstance = null;
        function closeLoginModal (user) {
            if (modalInstance) {
                modalInstance.close();
                modalInstance = null;
            }
        }
        // Set the basic header
        function setCredentials (username, password) {
            $http.defaults.headers.common.Authorization = 'Basic ' + Base64.encode(username + ':' + password);
        }

        // Clear the basic header
        function clearCredentials () {
            $http.defaults.headers.common.Authorization = null;

            // Do cleanup of cookies
            let cookies = $cookies.getAll();
            let hasCookies = cookies && Object.keys(cookies).length > 0;

            if (hasCookies) {
                for (let cookieKey of Object.keys(cookies)) {
                    $cookies.remove(cookieKey, {path: '/'});
                }
            }
        }

        // Set the internal user and freeze it
        // When coming back from offline mode, overwrite the offline user object with the signed in user
        function setUser (user) {
            if (currentUser && currentUser.userId >= 0) {
                return;
            }

            currentUser = user;
            if (angular.isDefined(user)) {
                Object.freeze(currentUser);

                CurrentSession.setUser(user);
                CurrentSession.setCompany(user.company);
                CurrentSession.setOrganization(user.company.organization);
                $timeout(function () {
                    $translate.use(CurrentSession.getCompany().locale).then(function () {
                        $translate.refresh();
                    });
                }, 0);

                if (user.company) {
                    SharedDataService.setCurrencyCode(user.company.baseCurrencyCode);
                }

                SharedDataService.loyaltyRedemptionPerDollar = user.company.organization.settings.loyaltyRedemptionPerDollar || 100;

                if (!currentUser.seenTutorial && !currentUser.company.trial) {
                    PosStatusService.showTutorial();
                }

                if (currentUser.expired) {
                    PosStatusService.showExpired();
                }

                PosStatusService.setFreeTrialEnabled(currentUser.company.isTrial);

                AuthService.loginConfirmed(user);
                EventListener.listenForNewMobileOrder();

                if (user.company) {
                    Lookup.statusCheck({}).$promise.then(function (response) {
                        // Let Solink know that a user has logged in to the POS
                        try {
                            SolinkService.sendLogin(user.company.companyId, response.currentUnix);
                        } catch (err) {
                            $log.error('Failed to send event to Solink', err);
                        }
                    });
                }
            }
        }
        var attributeInfo = [{
                name: 'CLOUD_BACKUP_SUBSCRIPTION_ACTIVE',
                key: 'cloudBackupSubscriptionActive'
            }];
        function hasServerAttributes (serverAttributesToCheck, serverAttributes) {
            serverAttributesToCheck = serverAttributesToCheck.split(',');
            var hasAttributes = true;
            var attributeFound = false;
            var negate = false;
            for (var i = 0; i < attributeInfo.length; i++) {
                if (serverAttributesToCheck.indexOf(attributeInfo[i].name) > -1 || serverAttributesToCheck.indexOf('!' + attributeInfo[i].name) > -1) {
                    negate = serverAttributesToCheck.indexOf('!' + attributeInfo[i].name) > -1 ? true : false;
                    if (negate) {
                        hasAttributes = hasAttributes && !serverAttributes[attributeInfo[i].key];
                    } else {
                        hasAttributes = hasAttributes && serverAttributes[attributeInfo[i].key];
                    }
                    attributeFound = true;
                }
            }
            if (!attributeFound) {
                hasAttributes = false;
                $log.error('Invalid server attribute check: ' + serverAttributesToCheck);
            }
            return hasAttributes;
        }
        /**
             * The public API of the Security service
             */
        var service = {
            roleTypes: undefined,
            serverAttributes: undefined,
            rolePermissions: undefined,
            companyRolePermissions: undefined,

            // Show the login modal
            showLogin: function (passwordToken, downloadPrompt=false) {
                setUser(undefined);
                location.href = location.protocol + '//' + location.host + '/' + location.hash;
            },
            useOfflineMode: function (username, password) {
                setCredentials(username, password);
                var currentCompanyId = CurrentSession.getCompany().companyId;
                return SecurityUsers.login({offlineCompanyId: currentCompanyId}, function (response) {
                    PosStatusService.setLoggedIn(true);
                    setUser(response);
                    clearCredentials();
                    closeLoginModal(response);
                });
            },
            forgotPassword: function (username) {
                return SecurityUsers.forgotPassword(null, {'username': username}, function (response) {
                });
            },
            logout: function () {
                LucovaBluetooth.stop();
                SecurityUsers.logout().$promise.then(function () {
                    clearCredentials();
                    currentUser = undefined;
                    service.rolePermissions = undefined;
                    service.companyRolePermissions = undefined;
                    service.showLogin();
                });
            },
            invalidateTab: function () {
                clearCredentials();
                currentUser = undefined;
                service.showLogin();
            },
            // Are we authenticated?
            isAuthenticated: function () {
                return angular.isDefined(currentUser);
            },
            // Load the user from the backend
            // This should only be called once from the app module rule (app.js)
            loadUser: function () {
                var parameters = $location.search();
                var loginToken;
                var securityUserGetSuccess = function (response) {
                    if (response) {
                        setUser(response);
                    }
                };

                if (angular.isDefined(parameters)) {
                    // not 100% sure, but this appears to be code for allowing
                    // free trial login = Martin Aug 5, 2021
                    loginToken = parameters.activate;
                }
                if (angular.isDefined(loginToken)) {
                    SecurityUsers.getWithToken({token: loginToken}, function (response) {
                        PosStatusService.setLoggedIn(true);
                        securityUserGetSuccess(response);
                    }, function (error) {
                        service.showLogin();
                    });
                } else {
                    SecurityUsers.getUser({}, function (response) {
                        CommonOfflineCache.cacheAllUsersInCompany();
                        CommonOfflineCache.saveLastUser(response);
                        PosStatusService.setLoggedIn(true);
                        securityUserGetSuccess(response);
                    }, function (error) {
                        PosStatusService.setLoggedIn(false);
                        // Status codes to verify that the error is caused by a network problem.
                        // 502: Server is down
                        // 0 or lower: Client is offline
                        if (error.status == 502 || error.status <= 0) {
                            PosStatusService.setOffline(true);
                            return CommonOfflineCache.getLastUser().then(function (user) {
                                /* Create a user object for use in Offline Mode. We only need the company data
                                   from the last user. Sets userId to -1 to indicate offline user. */
                                var offlineUser = {
                                    userId: -1,
                                    company: user.company,
                                    companyId: user.companyId,
                                    seenTutorial: true
                                };
                                setUser(offlineUser);
                                clearCredentials();
                                closeLoginModal(user);
                            });
                        } else {
                            service.showLogin();
                        }
                    });
                }
            },
            /**
                * This function is a tad complicated for the following reason: There are 2 modes of security
                * for swg: no-auth and auth. In the case of no-auth, there is no credentials and the user is
                * just logged in by default. In this case, a call to SecurityUsers.getUser() will return
                * successfully and the currentUser will be returned. In the case of auth, the call to
                * SecurityUsers.getUser() will return a 401, which will be intercepted by the security interceptor
                * and the login flow will happen. The initial SecurityUsers.getUser() call will be queued up and
                * retried once the login completes, however at that point the currentUser will already be set
                * and annotated with the sessionId and the uid. In this case, we do NOT want to overwrite the
                * currentUser, but simply return a promise to it.
                * @return {*}
            */
            getUser: function () {
                return currentUser;
            },
            // Update the user with the incoming. Use Cautiously as this causes an app refresh
            updateUser: function (user) {
                setUser(user);
                if (user.company && user.company.companyId) {
                    CurrentSession.setCompany(user.company);
                }
            },
            // Update the user with the incoming. Use Cautiously as this causes an app refresh
            updateCompanyAttributes: function (companyAttributes) {
                if (currentUser && companyAttributes) {
                    currentUser.company.companyAttributes = companyAttributes;
                }

                let sessionCompany = CurrentSession.getCompany();
                sessionCompany.companyAttributes = companyAttributes;
                CurrentSession.setCompany(sessionCompany);

            },
            hasPermissionForUser: function (requiredPermissions) {
                if (!currentUser) {
                    return false;
                }

                // currently this is looking at the user "type" (cashier, siteadmin, fulladmin) and deciding whether they have permission based on that type.
                // We need to add another layer of indirection/abstraction by looking at whether that "type" has permission.
                var privilege = currentUser.permission;
                var fullAdmin = privilege === 'FULLADMIN' || privilege === 'SITEADMIN';
                if (fullAdmin) {
                    return true;
                } else if (requiredPermissions.indexOf('||') !== -1) {
                    return _.some(requiredPermissions.split('||'), service.hasRolePermission);
                } else if (requiredPermissions.indexOf('&&') !== -1) {
                    return _.all(requiredPermissions.split('&&'), service.hasRolePermission);
                } else {
                    return service.hasRolePermission(requiredPermissions);
                }
            },
            hasPermissionForSpecificUser: function (requiredPermissions, otherUser) {
                if (!otherUser) {
                    return false;
                }

                var predicateFunction = function (permission) {
                    service.hasRolePermissionForRole(permission, otherUser.roleId);
                };

                // currently this is looking at the user "type" (cashier, siteadmin, fulladmin) and deciding whether they have permission based on that type.
                // We need to add another layer of indirection/abstraction by looking at whether that "type" has permission.
                var privilege = otherUser.permission;
                var fullAdmin = privilege === 'FULLADMIN' || privilege === 'SITEADMIN';
                if (fullAdmin) {
                    return true;
                } else if (requiredPermissions.indexOf('||') !== -1) {
                    return _.some(requiredPermissions.split('||'), predicateFunction);
                } else if (requiredPermissions.indexOf('&&') !== -1) {
                    return _.all(requiredPermissions.split('&&'), predicateFunction);
                } else {
                    return service.hasRolePermissionForRole(requiredPermissions, otherUser.roleId);
                }
            },
            hasServerAttributesForUser: function (serverAttributesToCheck) {
                var userServerAttributes = service.serverAttributes;
                $log.debug('Checking has serverAttribute: [serverAttribute=' + serverAttributesToCheck + '] for server attributes: [userServerAttributes=' + angular.toJson(userServerAttributes));
                serverAttributesToCheck = serverAttributesToCheck.split('||');
                var hasAttributes = false;
                for (var i = 0; i < serverAttributesToCheck.length; i++) {
                    hasAttributes = hasAttributes || hasServerAttributes(serverAttributesToCheck[i], userServerAttributes);
                }
                return hasAttributes;
            },
            getResourcePermission: function (resource) {
                // unravel the array going deeper and deeper into the "rolePermissions" array
                // so we can see if we have permission to access.
                var arr = resource.trim().split(':');
                var tempObj = {permissions: service.rolePermissions};
                try {
                    for (var i of Object.keys(arr)) {
                        tempObj = tempObj.permissions[arr[i]];
                    }
                    return tempObj;
                } catch (err) {
                    return false;
                }
            },
            getResourcePermissionForRole: function (resource, roleId) {
                // unravel the array going deeper and deeper into the "rolePermissions" array
                // so we can see if we have permission to access.
                var arr = resource.trim().split(':');
                var tempObj = {permissions: service.companyRolePermissions[roleId]};
                try {
                    for (var i of Object.keys(arr)) {
                        tempObj = tempObj.permissions[arr[i]];
                    }
                    return tempObj;
                } catch (err) {
                    return false;
                }
            },
            hasRolePermission: function (resource) {
                var permission = service.getResourcePermission(resource);
                if (permission) {
                    return permission.enabled;
                } else {
                    return false;
                }
            },
            hasRolePermissionForRole: function (resource, roleId) {
                var permission = service.getResourcePermissionForRole(resource, roleId);
                if (permission) {
                    return permission.enabled;
                } else {
                    return false;
                }
            },
            isManagerOverrideRequired: function (resource) {
                var permission = service.getResourcePermission(resource);
                if (permission) {
                    return permission.managerOverrideRequired || false;
                } else {
                    return false;
                }
            },
            loadRolePermissions: function () {
                service.loadRolePermissionsOfCompany();
                if (service.rolePermissions === undefined) {
                    return LookupService.getRolePermissions().then(function (response) {
                        service.rolePermissions = response;
                    });
                } else {
                    return $q.when();
                }
            },
            loadRolePermissionsOfCompany: function () {
                if (service.companyRolePermissions === undefined) {
                    return LookupService.getRolePermissionsForCompany().then(function (response) {
                        service.companyRolePermissions = response;
                    });
                } else {
                    return $q.when();
                }
            },
            isProvider: function () {
                if (!currentUser) {
                    return false;
                }

                return currentUser.company.isProvider;
            },
            isTrial: function () {
                if (!currentUser) {
                    return false;
                }

                return currentUser.company.isTrial;
            },
            loadIsHomebase: function () {
                Homebase.getHomebaseConnection(function (response) {
                    CurrentSession.setIsHomebase(response.connected);
                }, function (error) {
                    $log.error(error);
                });
            }
        };
        return service;
    }
]);
security.directive('hasPerm', [
    'Security',
    function (Security) {
        return {
            link: function (scope, element, attrs) {
                Security.loadRolePermissions().then(function () {
                    if (Security.hasPermissionForUser(attrs.resource)) {
                        $(element).show();
                    } else {
                        $(element).hide();
                    }
                }, function (error) {
                    $(element).hide();
                });
            }
        };
    }
]);

export default security;
