'use strict';

/* globals ngGridFlexibleHeightPlugin */


module.exports = function (posModule) {
    posModule.controller('viewCashierShiftTransactionDetailsCtrl', [
        '$scope',
        '$rootScope',
        'Patrons',
        'transactions',
        'title',
        '$modalInstance',
        '$translate',
        'notificationTranslationHelper',
        'Utils',
        'ErrorUtil',
        'NgGridTemplateService',
        function ($scope, $rootScope, Patrons, transactions, title, $modalInstance, $translate, notificationTranslationHelper, Utils, ErrorUtil, NgGridTemplateService) {
            $scope.patronPagingOptions = {
                currentRowNumber: 0,
                currentPage: 1,
                pageSize: 10,
                startRecord: 0,
                endRecord: 10,
                pageSizeOptions: Utils.buildDefaultPageSizes()
            };
            $scope.init = function () {
                $scope.transactions = transactions;
                $scope.isLog = false;
                $scope.isPatrons = true;
                $scope.title = title;
                // VIEW TRANSACTION DETAILS TABLE IN MODAL
                $scope.patronLogsColDef = [


                    {
                        field: 'transactionDateTime',
                        displayName: 'patronLog.table.transactionDate',
                        width: '**',
                        sortable: false,
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{COL_FIELD | date:\'yyyy-MM-dd HH:mm:ss\'}}</span><div>'
                    },
                    {
                        field: 'mealPeriod',
                        displayName: 'patronLog.table.mealPeriod',
                        width: '**',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'locationName',
                        displayName: 'patronLog.table.posLocation',
                        width: '**',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'mealPlanCount',
                        displayName: 'patronLog.table.mealPlanCount',
                        width: '**',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'chargeAmount',
                        displayName: 'patronLog.table.chargeAmount',
                        width: '**',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'dcbAmount',
                        displayName: 'patronLog.table.dcbAmount',
                        width: '**',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'cashAmount',
                        displayName: 'patronLog.table.cashAmount',
                        width: '**',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    },
                    {
                        field: 'creditCardAmount',
                        displayName: 'patronLog.table.creditCardAmount',
                        width: '**',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                    }
                ];
                $scope.patronLogGridOptions = {
                    columnDefs: $scope.patronLogsColDef,
                    plugins: [new ngGridFlexibleHeightPlugin({minHeight: 520})],
                    showFooter: true,
                    footerRowHeight: 30,
                    multiSelect: true,
                    showSelectionCheckbox: false,
                    selectedItems: $scope.selectedTransaction,
                    enableSorting: true,
                    selectWithCheckboxOnly: true,
                    enableHighlighting: true,
                    footerTemplate: '<div class="grid-footer"><div class="col-xs-4"></div><div class="col-xs-4"><pager align="false" ng-model="patronPagingOptions.currentPage" ' + 'total-items="patronPagingOptions.totalItems" items-per-page="patronPagingOptions.pageSize" ng-change="loadPatrons();"></pager>' + '</div><div class="col-xs-4 align-right"><span translate="general.records"/> {{patronPagingOptions.startRecord}} - {{patronPagingOptions.endRecord}}</div></div>',
                    data: 'transactions'
                };
            };
            $scope.cancelModal = function () {
                $modalInstance.dismiss('cancel');
            };
            $scope.init();
        }
    ]);
};
