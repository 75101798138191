'use strict';

/* eslint-disable max-len, comma-dangle, key-spacing, object-curly-spacing */

const angular = require('angular');
const ngResources = require('angular-resource');

export default angular.module('freshideas.resources.netsuite', [ngResources])
    .service('Netsuite', ['$resource', 'EnvConfig', function ($resource, EnvConfig) {
        return $resource(EnvConfig.host + '/freshideas/web/netsuite', {}, {
            'getNetsuiteConnections' : {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/netsuite/connection/list',
                isArray: true
            },
            'getCompanyHierarchy' : {
                method: 'GET',
                url: EnvConfig.host + '/freshideas/web/netsuite/companyHierarchy',
                isArray: false
            },
            'upsertNetsuiteConnection' : {
                method: 'PUT',
                url: EnvConfig.host + '/freshideas/web/netsuite/connection',
                isArray: false
            },
            'importCompanyHierarchyFromNetsuite' : {
                method: 'POST',
                url: EnvConfig.host + '/freshideas/web/netsuite/importCompanyHierarchy',
                isArray: false
            }
        });
    }]);
