'use strict';

module.exports = function (freshideasLocation) {

    freshideasLocation
    .directive('locationAddItem', [
        function () {
            return {
                restrict: 'E',
                templateUrl: 'locations/location-side-content/location-add-item/location-add-item.tpl.html',
                controller: 'LocationAddItemCtrl',
                scope: {
                    item: '='
                },
                link: function (scope, element, attr) {
                    console.log('location add item');
                }
            };
        }
        ])
    .controller('LocationAddItemCtrl', [
        '$scope',
        '$modal',
        '$q',
        '$translate',
        '$location',
        'LocationFactory',
        'Locations',
        'Inventory',
        'Company',
        'PosAlertService',
        'notificationTranslationHelper',
        'ErrorUtil',
        function ($scope, $modal, $q, $translate, $location, LocationFactory, Locations, Inventory, Company, PosAlertService, notificationTranslationHelper, ErrorUtil) {

            $scope.locationFactory = LocationFactory;

            $scope.item = {'active': true, 'color': '#C2DAD1', 'mealPlanCount': 1};

            $scope.tab = 0;
            $scope.stepValue = 1;

            $scope.item.priceTab = 'custom';

            $scope.tabs = [{
                'id': 'custom',
                'name': 'Custom'
            },
            {
                'id': 'free',
                'name': 'Free'
            }, {
                'id': 'dynamic',
                'name': 'Dynamic'
            }];

            var init = function () {
                $scope.locationFactory.loadServicePeriods();
                $scope.locationFactory.loadTaxRates();
                $scope.locationFactory.loadLocationPrinters();
            };

            $scope.setTab = function (index) {
                $scope.tab = index;
            };

            $scope.cancel = function () {
                LocationFactory.dismissSideContent();
            };

            $scope.save = function () {

                if (validate()) {
                    if ($scope.item.priceTab === 'free') {
                        $scope.item.mealPlanEligible = false;
                        $scope.item.mealPlanCount = 0;
                        $scope.item.mealEquivalencyEnabled = false;
                        $scope.item.customPriceEnabled = false;
                        $scope.item.isFree = true;
                        $scope.item.price = 0;
                    } else if ($scope.item.priceTab === 'dynamic') {
                        $scope.item.mealPlanEligible = false;
                        $scope.item.mealPlanCount = 0;
                        $scope.item.mealEquivalencyEnabled = false;
                        $scope.item.customPriceEnabled = true;
                        $scope.item.isFree = false;
                        $scope.item.price = 0;
                    } else {
                        $scope.item.customPriceEnabled = false;
                        $scope.item.isFree = false;
                    }

                    console.log($scope.item.isFree);

                    // remove for now until there is a way to specify the service period to add to
                    // $scope.item.servicePeriodId = $scope.locationFactory.servicePeriod.servicePeriodId;

                    $scope.item.locationId = $scope.locationFactory.locationId;
                    LocationFactory.addMenuItem($scope.item);
                }
            };

            var validate = function () {
                if ((!$scope.item.price || $scope.item.price === 0) && !$scope.item.isFree && $scope.item.priceTab === 'custom') {

                    PosAlertService.showError('error', 'Invalid Price', '', 'Please enter a valid price for this item or Mark as Free.');
                    return false;
                }

                if ((!$scope.item.mealPlanCount || $scope.item.mealPlanCount === 0) && (!$scope.isFree && $scope.item.mealPlanEligible)) {

                    PosAlertService.showError('error', 'Invalid Meal Unit', '', 'Please enter a valid meal unit count when enabling meal plan eligible.');
                    return false;
                }

                return true;
            };

            init();
        }
        ]);
};
