'use strict';

const angular = require('angular');
const moment = require('moment');
const Decimal = require('decimal.js').default;

const TaxRuleService = require('../external/tax-rule-service.js');

module.exports = function (freshideasReports) {
    freshideasReports.controller('SalesReportCtrl', [
        '$scope',
        '$location',
        'Reports',
        'Export',
        'Lookup',
        '$timeout',
        '$sce',
        '$filter',
        '$translate',
        'DateRangeService',
        'Security',
        'Platform',
        'GatewayFiit',
        'USER_ROLE_TYPE',
        'Locations',
        'CurrentSession',
        'CompanyAttributesService',
        function (
            $scope,
            $location,
            Reports,
            Export,
            Lookup,
            $timeout,
            $sce,
            $filter,
            $translate,
            DateRangeService,
            Security,
            Platform,
            GatewayFiit,
            USER_ROLE_TYPE,
            Locations,
            CurrentSession,
            CompanyAttributesService) {

            const IS_ROOT_COMPANY = CurrentSession.isRootCompany();

            $scope.isFiitEnabled = GatewayFiit.isEnabled();
            $scope.isIosWebkit = Platform.isIosWebkit();
            $scope.isElectron = Platform.isElectron();
            $scope.searchDisabled = false;
            var currentUser = Security.getUser() || {};
            $scope.isManager = currentUser.roleType === USER_ROLE_TYPE.MANAGER;
            $scope.isAccountant = currentUser.roleType === USER_ROLE_TYPE.ACCOUNTANT;
            $scope.isSiteAdmin = currentUser.permission === 'SITEADMIN';

            const getStartHoursOfDay = () => {
                let hoursPerDay = 24;
                let time = [];
                for (let i = 0; i < hoursPerDay; i++) {
                    time.push(moment(i, 'hh').format('hh:mm A'));
                }
                return time;
            };
            $scope.startHoursOfDay = getStartHoursOfDay();

            const getEndHoursOfDay = () => {
                let hoursPerDay = 24;
                let time = [];
                for (let i = 0; i < hoursPerDay; i++) {
                    time.push(moment(i+'59', 'hmm').format('hh:mm A'));
                }
                return time;
            };
            $scope.endHoursOfDay = getEndHoursOfDay();

            var allowRetry;
            var refreshLocations = function (companies) {
                if (companies) {
                    $scope.locations = _.flatten(_.map(companies, function (company) {
                        return company.children;
                    }), true);
                }
            };
            var refreshMealPeriods = function (locations) {
                if (locations) {
                    $scope.mealPeriods = _.flatten(_.map(locations, function (location) {
                        return _.filter(location.children, function (child) {
                            return child.type === 'mealPeriod';
                        });
                    }), true);
                    var currentMealPeriodId = $scope.reportSearch.mealPeriodId;
                    var foundMealPeriod = _.find($scope.mealPeriods, function (mealPeriod) {
                        return mealPeriod.id === currentMealPeriodId;
                    });
                    if (!foundMealPeriod) {
                        $scope.reportSearch.mealPeriodId = undefined;
                    }
                }
            };
            var refreshPoses = function (locations) {
                if (locations) {
                    $scope.poses = _.flatten(_.map(locations, function (location) {
                        return _.filter(location.children, function (child) {
                            return child.type === 'pos';
                        });
                    }), true);
                    var currentPosId = $scope.reportSearch.posId;
                    var foundPos = _.find($scope.poses, function (pos) {
                        return pos.id === currentPosId;
                    });
                    if (!foundPos) {
                        $scope.reportSearch.posId = undefined;
                    }
                }
            };

            const refreshMenuPeriods = function (menuPeriodList, locationToFilter) {
                $scope.menuPeriods = [];

                // Default selection - 'All Locations'
                if (locationToFilter < 0) {
                    return;
                }

                if (menuPeriodList && menuPeriodList.length) {
                    $scope.menuPeriods = _.filter(menuPeriodList, function (menuPeriod) {
                        return menuPeriod.companyIdList.includes(locationToFilter.companyId);
                    });
                }
            };

            var tick = function () {
                $scope.currentDateTime = moment();
                $timeout(tick, 60 * 1000);
            };

            $scope.hasMenuPeriodsEnabled = function () {
                return CompanyAttributesService.hasMenuPeriodsEnabled();
            };
            $timeout(tick, 60 * 1000);
            $scope.$watch('reportSearch.companyId', function (companyId) {
                if (companyId) {
                    var foundCompany = _.find($scope.companyHierarchy, function (company) {
                        return company.id === companyId;
                    });
                    if (foundCompany) {
                        refreshLocations([foundCompany]);
                    }
                } else {
                    var companyHierarchy = angular.copy($scope.companyHierarchy);
                    refreshLocations(companyHierarchy);
                }
            });
            $scope.$watch('reportSearch.locationId', function (newDummyLocationId) {
                if (!newDummyLocationId) {
                    return;
                }
                let locationObj = $scope.locations.find((location) => {
                    return location.id == newDummyLocationId;
                });

                let locationId = (locationObj) ? locationObj.id : null;

                if (locationId > 0) {
                    var foundLocation;
                    _.each($scope.companyHierarchy, function (company) {
                        var l = _.find(company.children, function (location) {
                            return location.id === locationId;
                        });
                        if (l) {
                            foundLocation = l;
                        }
                    });

                    if (foundLocation) {
                        refreshMenuPeriods($scope.allMenuPeriods, foundLocation);
                        refreshMealPeriods([foundLocation]);
                        refreshPoses([foundLocation]);
                    }

                } else {
                    refreshMenuPeriods($scope.allMenuPeriods, locationId);
                    refreshMealPeriods($scope.locations);
                    refreshPoses($scope.locations);
                }
            });
            $scope.getDownloadUrl = function () {
                // make it so all transactions are downloaded.
                if ($scope.isIosWebkit || $scope.isElectron) {
                    return;
                }
                var reportSearch = {};
                if ($scope.reportSearch.companyId) {
                    reportSearch.companyId = $scope.reportSearch.companyId;
                }
                if ($scope.reportSearch.locationId) {
                    let locationObj = $scope.locations.find((location) => {
                        return location.id == $scope.reportSearch.locationId;
                    });

                    reportSearch.locationId = (locationObj) ? locationObj.originalLocationId : null;
                    reportSearch.specificLocationOnly = !!locationObj.specificLocationOnly;
                }
                if ($scope.reportSearch.menuPeriodId) {
                    reportSearch.menuPeriodId = $scope.reportSearch.menuPeriodId;
                }
                if ($scope.reportSearch.posId) {
                    reportSearch.posStationId = $scope.reportSearch.posId;
                }

                // Here we are combining start/end date with start/end hour to send the time string as
                // url params to the server this is how the time params looks like: "21-04-20T10:00:00"

                const startHourStr = moment($scope.reportSearch.startHour, ['hh:mm A']).format('HH:mm:ss');
                reportSearch.startDateTime = `${moment($scope.reportSearch.fromDate).format('YYYY-MM-DD')}T${startHourStr}`;

                const endHourStr = moment($scope.reportSearch.endHour, ['hh:mm A']).add(60, 'seconds').format('HH:mm:ss');
                if (endHourStr === '00:00:00') {
                    reportSearch.endDateTime = `${moment($scope.reportSearch.toDate).add(1, 'day').format('YYYY-MM-DD')}T${endHourStr}`;
                } else {
                    reportSearch.endDateTime = `${moment($scope.reportSearch.toDate).format('YYYY-MM-DD')}T${endHourStr}`;
                }

                var downloadUrl = $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/freshideas/web/reports/downloadSalesSummary';
                var queryString = jQuery.param(reportSearch);
                return downloadUrl + '?' + queryString;
            };
            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };
            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };
            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };
            $scope.clearFilters = function (isAdvancedSearch) {
                $scope.reportSearch = {isAdvancedSearch: !!isAdvancedSearch};
                $scope.reportSearch.fromDate = moment().startOf('week').toDate();
                $scope.reportSearch.toDate = moment().endOf('day').toDate();
                $scope.reportSearch.locationId = -1; // -1 corresponds to the 'All locations' option
                $scope.reportSearch.startHour = $scope.startHoursOfDay[0];
                $scope.reportSearch.endHour = $scope.endHoursOfDay[23];
            };
            $scope.clearFilters();
            $scope.lookupEntity = function (type, id) {
                if (!$scope.baseSettings) {
                    return;
                }
                var entities = $scope.baseSettings[type] || [];
                var foundEntity = _.find(entities, function (entity) {
                    return entity.id === id;
                }) || {};
                return foundEntity.name || '';
            };

            function lookupCompanyHierarchy () {
                return Lookup.locationsByCompany({}, function (response) {
                    $scope.companyHierarchy = response;
                    parseCompanyLocations(response);
                });
            }

            $scope.menuPeriods = [];
            $scope.allMenuPeriods = [];
            function loadAllMenuPeriods () {
                Locations.getMenuPeriods({companyId: CurrentSession.getCompany().companyId, orgLevel: IS_ROOT_COMPANY}, function (response) {
                    $scope.allMenuPeriods.length = 0; // flush array to override
                    Array.prototype.push.apply($scope.allMenuPeriods, response); // backward compatable alternative for spread operator
                });
            }

            /**
             * This populates the location dropdown list and displays the
             * location in a more readable, tree-like format that makes it
             * easier to tell hiearchical significance of each location.
             * The dropdown should look like this:
             * ```
             * Fresh Cafe Headquarter
             * -- Fresh Cafe Canada Region
             * ---- Fresh Cafe Eaton Centre
             * ---- Fresh Cafe Yorkdale
             * -- Fresh Cafe US Region
             * ---- Fresh Cafe New York
             * ---- Fresh Cafe Los Angeles
             * ```
            */
            var parseCompanyLocations = function (response) {
                $scope.company = _.findWhere(response, {id: currentUser.companyId});
                $scope.locations = [];
                let locations = ($scope.company && $scope.company.children) ? $scope.company.children : [];

                if (locations.length) {
                    // This code block handles single location organizations and leaf/child locations in a multi-location organization
                    if (locations.length === 1) {
                        locations[0].originalLocationId = locations[0].id; // originalLocationId is the actual location id
                        locations[0].id = -1; // We set the id to -1 so that this option is selected by default
                        $scope.locations.push(locations[0]);
                    } else {
                        let rootLocation = _.find(locations, function (location) {
                            var data = location.data || {};
                            var parentCompanyId = data.parentCompanyId || undefined;

                            return !parentCompanyId;
                        });

                        // 'all locations' filter to $scope.location
                        $scope.locations.push({
                            id: -1, // this id is a custom id which could be the location id or a modified id. -1 corresponds to the 'All locations' option
                            name: $translate.instant('transaction.filter.option.allLocations'),
                            originalName: $translate.instant('transaction.filter.option.allLocations'),
                            originalLocationId: (rootLocation) ? rootLocation.id : CurrentSession.getCompany().locationId, // originalLocationId is the actual location id
                            specificLocationOnly: false
                        });

                        // We only want to add the HQ Only option for the root location
                        // Hence we will skip the if block for regions since when you log into a region
                        // rootLocation will be null
                        if (rootLocation) {
                            $scope.locations.push({
                                id: rootLocation.id, // this id is a custom id which could be the location id or a modified id. -1 corresponds to the 'All locations' option
                                name: $translate.instant('transaction.filter.option.hq.only', {
                                    locationName: rootLocation.name
                                }),
                                originalName: $translate.instant('transaction.filter.option.hq.only', {
                                    locationName: rootLocation.name
                                }),
                                originalLocationId: rootLocation.id, // originalLocationId is the actual location id
                                specificLocationOnly: true
                            });
                        } else {
                            // Assigning the current location as the root location to avoid further issues
                            rootLocation = locations.find((location) => {
                                return location.companyId === CurrentSession.getCompany().companyId;
                            });
                        }

                        const result = buildLocationList(locations, [], rootLocation);
                        $scope.locations.push(...result);
                    }
                    $scope.reportSearch.locationId = -1; // -1 corresponds to the 'All locations' option
                }
            };

            /**
             * Helper function for `parseCompanyLocations` which recursively traverses
             * the location list to generate the hiearchical location structure.
             * Note that this does not include the root location.
             */
            var buildLocationList = function (original, result, currentNode, level = 0) {
                let matchedLocations = _.filter(original, function (location) {
                    var data = location.data || {};
                    var parentCompanyId = data.parentCompanyId || undefined;

                    return parentCompanyId === currentNode.companyId;
                });

                level++;

                for (let matchedLocation of matchedLocations) {
                    matchedLocation.originalName = matchedLocation.name;
                    matchedLocation.name = '--'.repeat(level) + ' ' + matchedLocation.name;
                    matchedLocation.specificLocationOnly = false;
                    matchedLocation.originalLocationId = matchedLocation.id; // originalLocationId is the actual location id
                    result.push(matchedLocation);

                    buildLocationList(original, result, matchedLocation, level);
                }

                return result;
            };

            var addToTaxBreakdowns = function (taxBreakdowns, newTaxBreakdown, currencyRateObj) {
                var identifier = {
                    dateString: newTaxBreakdown.dateString,
                    taxRate: newTaxBreakdown.taxRate,
                    taxRuleName: newTaxBreakdown.taxRuleName,
                    displayName: computeTaxBreakdownDisplayName(newTaxBreakdown)
                };

                var foundTaxBreakdown = _.findWhere(taxBreakdowns, identifier);
                if (!foundTaxBreakdown) {
                    foundTaxBreakdown = angular.copy(identifier);
                    foundTaxBreakdown.amountCents = 0;
                    foundTaxBreakdown.count = 0;
                    foundTaxBreakdown.convertedAmountCents = 0;

                    taxBreakdowns.push(foundTaxBreakdown);
                }

                foundTaxBreakdown.amountCents += newTaxBreakdown.amountCents;
                foundTaxBreakdown.count += newTaxBreakdown.count;

                foundTaxBreakdown.convertedAmountCents += convertAmountToCurrency(newTaxBreakdown.amountCents, currencyRateObj.rate);
            };


            var computeTaxBreakdownDisplayName = function (taxBreakdown) {
                var displayName = '';

                if (taxBreakdown.taxRuleName) {
                    // assume only one can be applied at the moment
                    var rule = TaxRuleService.findByName(taxBreakdown.taxRuleName);
                    if (rule) {
                        displayName = $translate.instant(
                            'taxRules.' + rule.key + '.tax.label'
                        );
                    }
                } else {
                    if (taxBreakdown.taxRateName) {
                        var taxRatePercentageDisp = $filter('percentage')(taxBreakdown.taxRate * 100);
                        displayName = taxBreakdown.taxRateName + ' - ' + taxRatePercentageDisp;
                    }

                    /* if (taxBreakdown.taxRateId) {
                        var taxRate = $scope.taxRateMap[taxBreakdown.taxRateId];

                        if (taxRate) {
                            displayName = '"' + taxRate.taxRateName + '"';
                        }
                    }*/
                }

                // if display name cannot be computed based on tax rule or
                // tax rate id, generate one using just the tax rate
                if (!displayName) {
                    var unknownTaxLabel = $translate.instant('reports.salesReport.tax.unknown.label');
                    var taxRatePercentage = $filter('percentage')(taxBreakdown.taxRate * 100);
                    displayName = unknownTaxLabel + ' - ' + taxRatePercentage;
                }

                return displayName;
            };

            var addToOtherTenderBreakdowns = function (otherTenderBreakdowns, otherTenderBreakdown, ignoreDate, currencyRateObj) {
                var identifier = {
                    label: otherTenderBreakdown.label
                };

                if (!ignoreDate) {
                    identifier.dateString = otherTenderBreakdown.dateString;
                }

                var foundOtherTenderBreakdown = _.findWhere(otherTenderBreakdowns, identifier);
                if (!foundOtherTenderBreakdown) {
                    foundOtherTenderBreakdown = angular.copy(identifier);
                    foundOtherTenderBreakdown.amountCents = 0;
                    foundOtherTenderBreakdown.count = 0;
                    foundOtherTenderBreakdown.convertedAmountCents = 0;

                    otherTenderBreakdowns.push(foundOtherTenderBreakdown);
                }

                foundOtherTenderBreakdown.amountCents += otherTenderBreakdown.amountCents;
                foundOtherTenderBreakdown.count += otherTenderBreakdown.count;

                foundOtherTenderBreakdown.convertedAmountCents += convertAmountToCurrency(
                    otherTenderBreakdown.amountCents, currencyRateObj.rate);
            };


            var addToDiscountBreakdowns = function (discountBreakdowns, discountBreakdown, ignoreDate, currencyRateObj) {
                var identifier = {
                    label: discountBreakdown.discountName,
                    id: discountBreakdown.discountId,
                    type: discountBreakdown.discountType
                };

                if (!ignoreDate) {
                    identifier.dateString = discountBreakdown.dateString;
                }

                var foundDiscountBreakdown = _.findWhere(discountBreakdowns, identifier);
                if (!foundDiscountBreakdown) {
                    foundDiscountBreakdown = angular.copy(identifier);
                    foundDiscountBreakdown.amountCents = 0;
                    foundDiscountBreakdown.count = 0;
                    foundDiscountBreakdown.convertedAmountCents = 0;

                    discountBreakdowns.push(foundDiscountBreakdown);
                }

                var discountAmountCents = new Decimal(discountBreakdown.totalDiscountAmount).times(new Decimal(100)).round().toNumber();
                foundDiscountBreakdown.amountCents += discountAmountCents;
                foundDiscountBreakdown.count += discountBreakdown.discountCount;

                foundDiscountBreakdown.convertedAmountCents += convertAmountToCurrency(
                    discountAmountCents, currencyRateObj.rate);
            };

            var addToExchangeTransactionBreakdowns = function (exchangeTransactionBreakdowns, exchangeTransactionBreakdown, ignoreDate, currencyRateObj) {
                var identifier = {
                    label: exchangeTransactionBreakdown.label
                };

                if (!ignoreDate) {
                    identifier.dateString = exchangeTransactionBreakdown.dateString;
                }

                var foundExchangeTransactionBreakdown = _.findWhere(exchangeTransactionBreakdowns, identifier);
                if (!foundExchangeTransactionBreakdown) {
                    foundExchangeTransactionBreakdown = angular.copy(identifier);
                    foundExchangeTransactionBreakdown.amountCents = 0;
                    foundExchangeTransactionBreakdown.count = 0;
                    foundExchangeTransactionBreakdown.convertedAmountCents = 0;

                    exchangeTransactionBreakdowns.push(foundExchangeTransactionBreakdown);
                }

                foundExchangeTransactionBreakdown.amountCents += exchangeTransactionBreakdown.amountCents;
                foundExchangeTransactionBreakdown.count += exchangeTransactionBreakdown.count;

                foundExchangeTransactionBreakdown.convertedAmountCents += convertAmountToCurrency(
                    exchangeTransactionBreakdown.amountCents, currencyRateObj.rate);
            };

            var convertReports = function (reports, currencyRates) {
                var baseSummary = {
                    mealPlan: {count: 0},
                    dcb: {
                        count: 0,
                        amount: 0
                    },
                    charge: {
                        count: 0,
                        amount: 0
                    },
                    cash: {
                        count: 0,
                        amount: 0
                    },
                    creditCard: {
                        count: 0,
                        amount: 0
                    },
                    debitCard: {
                        count: 0,
                        amount: 0
                    },
                    sales: {
                        amount: 0,
                        count: 0
                    },
                    meals: {
                        count: 0
                    },
                    mealEquivalency: {
                        amount: 0,
                        count: 0
                    },
                    other: {
                        amount: 0,
                        count: 0
                    },
                    subtotalTaxable: {
                        amount: 0
                    },
                    subtotalNonTaxable: {
                        amount: 0
                    },
                    fiitDcb: {
                        amount: 0,
                        count: 0
                    },
                    fiitMeal: {
                        amount: 0,
                        units: 0,
                        count: 0
                    },
                    fiitMealEq: {
                        amount: 0,
                        count: 0
                    },
                    otherTenderBreakdowns: [],
                    giftCard: {
                        amount: 0,
                        count: 0
                    },
                    tx: {
                        amount: 0,
                        count: 0
                    },
                    tip: {
                        amount: 0,
                        count: 0
                    },
                    cashRounding: {
                        amount: 0,
                        count: 0
                    },
                    remainingBalance: {
                        amount: 0,
                        count: 0
                    },
                    subtotal: {
                        amount: 0,
                        count: 0
                    },
                    tax: {
                        amount: 0,
                        count: 0
                    },
                    total: {
                        amount: 0,
                        count: 0
                    },
                    giftCardActivation: {
                        amount: 0,
                        count: 0
                    },
                    giftCardReload: {
                        amount: 0,
                        count: 0
                    },
                    giftCardPromo: {
                        amount: 0,
                        count: 0
                    },
                    discount: {
                        amount: 0,
                        count: 0
                    }
                };
                $scope.currenciesApplied = [];

                var results;
                _.each(reports, function (report, campusIndex) {
                    var campus = report.campus;
                    if (!campus) {
                        return;
                    }
                    results = results || {};
                    results[campus.campusName] = {
                        campusName: campus.campusName,
                        campusResults: []
                    };

                    if (!results.allTotals) {
                        results.allTotals = angular.copy(baseSummary);
                        results.allTotals.converted = angular.copy(baseSummary);

                        results.allTotals.refundSubtotal = 0;
                        results.allTotals.refundTax = 0;
                        results.allTotals.refundCashRounding = 0;
                        results.allTotals.refundOther = 0;
                        results.allTotals.exchangeTotal = 0;

                        results.allTotals.converted.refundSubtotal = 0;
                        results.allTotals.converted.refundTax = 0;
                        results.allTotals.converted.refundCashRounding = 0;
                        results.allTotals.converted.refundOther = 0;
                        results.allTotals.converted.exchangeTotal = 0;

                        results.allTotals.taxBreakdowns = [];
                        results.allTotals.otherTenderBreakdowns = [];
                        results.allTotals.discountBreakdowns = [];
                        results.allTotals.exchangeTransactionBreakdowns = [];
                    }

                    _.each(campus.dates, function (date, dateIndex) {
                        var dateSummary = angular.copy(baseSummary);
                        var dateRefundSummary = angular.copy(baseSummary);
                        var dateCollectedSummary = angular.copy(baseSummary);

                        var dateRefund = {
                            refundSubtotal: 0,
                            refundTax: 0,
                            refundCashRounding: 0,
                            exchangeTotal: 0
                        };

                        var dateTaxBreakdowns = []; // angular.copy(baseTaxbreakdown);
                        var dateOtherTenderBreakdowns = [];
                        var dateDiscountBreakdowns = [];
                        var dateExchangeTransactionBreakdowns = [];

                        var currencyRateObj = getApplicableCurrencyRate(campus.baseCurrencyCode, date.dateString);

                        var campusDateResults = [];
                        _.each(date.locations, function (location, locationIndex) {
                            var locationSummary = angular.copy(baseSummary);
                            var locationChildrenCount = 1;
                            _.each(location.mealPeriods, function (mealPeriod, mealPeriodIndex) {
                                var mealPeriodChildrenCount = mealPeriod.poses.length + 1;
                                locationChildrenCount += mealPeriodChildrenCount;
                            });
                            _.each(location.mealPeriods, function (mealPeriod, mealPeriodIndex) {
                                var mealPeriodSummary = angular.copy(baseSummary);
                                var mealPeriodChildrenCount = mealPeriod.poses.length + 1;
                                _.each(mealPeriod.poses, function (pos, posIndex) {
                                    var trackableOtherTenderBreakdowns = [];
                                    _.each(pos.otherTenderLabelBreakdowns, function (otherTenderBreakdown) {
                                        if (otherTenderBreakdown.label !== 'fiitmps') {
                                            trackableOtherTenderBreakdowns.push(otherTenderBreakdown);
                                        }
                                    });

                                    var result = {
                                        type: 'pos',
                                        aggregated: false,
                                        posName: pos.posName,
                                        summary: {
                                            mealPlan: pos.mealPlan,
                                            dcb: pos.dcb,
                                            charge: pos.charge,
                                            cash: pos.cash,
                                            creditCard: pos.creditCard,
                                            debitCard: pos.debitCard,
                                            mealEquivalency: pos.mealEquivalency,
                                            other: pos.other,
                                            fiitDcb: pos.fiitDcb,
                                            fiitMeal: pos.fiitMeal,
                                            fiitMealEq: pos.fiitMealEq,
                                            otherTenderBreakdowns: [],
                                            giftCard: pos.giftCard,
                                            tip: pos.tip,
                                            cashRounding: pos.cashRounding,
                                            remainingBalance: pos.remainingBalance,
                                            subtotal: pos.subtotal,
                                            tax: pos.tax,
                                            total: pos.total,
                                            giftCardPromo: pos.giftCardPromo
                                        }
                                    };
                                    if (dateIndex === 0 && locationIndex === 0 && mealPeriodIndex === 0 && posIndex === 0) {
                                        result.campusName = campus.campusName;
                                    }
                                    if (locationIndex === 0 && mealPeriodIndex === 0 && posIndex === 0) {
                                        result.dateString = date.dateString;
                                    }
                                    if (mealPeriodIndex === 0 && posIndex === 0) {
                                        result.locationName = location.locationName;
                                        result.locationChildrenCount = locationChildrenCount;
                                    }
                                    if (posIndex === 0) {
                                        result.mealPeriodName = mealPeriod.mealPeriodName;
                                        result.mealPeriodChildrenCount = mealPeriodChildrenCount;
                                    }

                                    campusDateResults.push(result);

                                    mealPeriodSummary.mealPlan.count += pos.mealPlan.count;
                                    mealPeriodSummary.dcb.count += pos.dcb.count;
                                    mealPeriodSummary.dcb.amount += pos.dcb.amount;
                                    mealPeriodSummary.charge.count += pos.charge.count;
                                    mealPeriodSummary.charge.amount += pos.charge.amount;
                                    mealPeriodSummary.cash.count += pos.cash.count;
                                    mealPeriodSummary.cash.amount += pos.cash.amount;
                                    mealPeriodSummary.creditCard.count += pos.creditCard.count;
                                    mealPeriodSummary.creditCard.amount += pos.creditCard.amount;
                                    mealPeriodSummary.debitCard.count += pos.debitCard.count;
                                    mealPeriodSummary.debitCard.amount += pos.debitCard.amount;
                                    mealPeriodSummary.mealEquivalency.count += pos.mealEquivalency.count;
                                    mealPeriodSummary.mealEquivalency.amount += pos.mealEquivalency.amount;
                                    mealPeriodSummary.other.count += pos.other.count;
                                    mealPeriodSummary.other.amount += pos.other.amount;
                                    mealPeriodSummary.subtotalTaxable.amount += pos.subtotalTaxable.amount;
                                    mealPeriodSummary.subtotalNonTaxable.amount += pos.subtotalNonTaxable.amount;
                                    mealPeriodSummary.fiitDcb.count += pos.fiitDcb.count;
                                    mealPeriodSummary.fiitDcb.amount += pos.fiitDcb.amount;
                                    mealPeriodSummary.fiitMeal.count += pos.fiitMeal.count;
                                    mealPeriodSummary.fiitMeal.amount += pos.fiitMeal.amount;
                                    mealPeriodSummary.fiitMeal.units += pos.fiitMeal.units;
                                    mealPeriodSummary.fiitMealEq.count += pos.fiitMealEq.count;
                                    mealPeriodSummary.fiitMealEq.amount += pos.fiitMealEq.amount;
                                    mealPeriodSummary.giftCard.count += pos.giftCard.count;
                                    mealPeriodSummary.giftCard.amount += pos.giftCard.amount;
                                    mealPeriodSummary.tip.count += pos.tip.count;
                                    mealPeriodSummary.tip.amount += pos.tip.amount;
                                    mealPeriodSummary.cashRounding.count += pos.cashRounding.count;
                                    mealPeriodSummary.cashRounding.amount += pos.cashRounding.amount;
                                    mealPeriodSummary.remainingBalance.count += pos.remainingBalance.count;
                                    mealPeriodSummary.remainingBalance.amount += pos.remainingBalance.amount;
                                    mealPeriodSummary.subtotal.count += pos.subtotal.count;
                                    mealPeriodSummary.subtotal.amount += pos.subtotal.amount;
                                    mealPeriodSummary.tax.count += pos.tax.count;
                                    mealPeriodSummary.tax.amount += pos.tax.amount;
                                    mealPeriodSummary.total.count += pos.total.count;
                                    mealPeriodSummary.total.amount += pos.total.amount;

                                    mealPeriodSummary.giftCardActivation.count += pos.giftCardActivation.count;
                                    mealPeriodSummary.giftCardActivation.amount += pos.giftCardActivation.amount;
                                    mealPeriodSummary.giftCardReload.count += pos.giftCardReload.count;
                                    mealPeriodSummary.giftCardReload.amount += pos.giftCardReload.amount;
                                    mealPeriodSummary.giftCardPromo.count += pos.giftCardPromo.count;
                                    mealPeriodSummary.giftCardPromo.amount += pos.giftCardPromo.amount;

                                    mealPeriodSummary.otherTenderBreakdowns = [];

                                    if (pos.refund) {
                                        var refundSubtotalCents = new Decimal(pos.refund.subtotal).times(100).toDecimalPlaces(2).toNumber();
                                        var refundTaxCents = new Decimal(pos.refund.tax).times(100).toDecimalPlaces(2).toNumber();
                                        var refundCashRoundingCents = new Decimal(pos.refund.cashRounding).times(100).toDecimalPlaces(2).toNumber();

                                        dateRefund.refundSubtotal += refundSubtotalCents;
                                        dateRefund.refundTax += refundTaxCents;
                                        dateRefund.refundCashRounding -= refundCashRoundingCents;
                                    }

                                    if (pos.refundTenderBreakdown) {
                                        dateRefundSummary.cash.count += pos.refundTenderBreakdown.cashRefundCount;
                                        dateRefundSummary.cash.amount -= pos.refundTenderBreakdown.cashRefundAmountCents;
                                        dateRefundSummary.creditCard.count += pos.refundTenderBreakdown.cardRefundCount;
                                        dateRefundSummary.creditCard.amount -= pos.refundTenderBreakdown.cardRefundAmountCents;
                                        dateRefundSummary.giftCard.count += pos.refundTenderBreakdown.giftCardRefundCount;
                                        dateRefundSummary.giftCard.amount -= pos.refundTenderBreakdown.giftCardRefundAmountCents;
                                        dateRefundSummary.other.count += pos.refundTenderBreakdown.otherRefundCount;
                                        dateRefundSummary.other.amount -= pos.refundTenderBreakdown.otherRefundAmountCents;

                                        results.allTotals.refundOther -= pos.refundTenderBreakdown.otherRefundAmountCents;
                                    }

                                    _.each(pos.taxBreakdowns, function (taxBreakdown) {
                                        addToTaxBreakdowns(dateTaxBreakdowns, taxBreakdown, currencyRateObj);
                                        addToTaxBreakdowns(results.allTotals.taxBreakdowns, taxBreakdown, currencyRateObj);
                                    });

                                    _.each(trackableOtherTenderBreakdowns, function (otherTenderBreakdown) {
                                        addToOtherTenderBreakdowns(dateOtherTenderBreakdowns, otherTenderBreakdown, false, currencyRateObj);
                                        addToOtherTenderBreakdowns(results.allTotals.otherTenderBreakdowns, otherTenderBreakdown, true, currencyRateObj);
                                    });

                                    _.each(pos.discountBreakdowns, function (discountBreakdown) {
                                        addToDiscountBreakdowns(dateDiscountBreakdowns, discountBreakdown, false, currencyRateObj);
                                        addToDiscountBreakdowns(results.allTotals.discountBreakdowns, discountBreakdown, true, currencyRateObj);

                                        var discountAmountCents = new Decimal(discountBreakdown.totalDiscountAmount).times(new Decimal(100)).round().toNumber();
                                        var discountCount = discountBreakdown.discountCount;

                                        dateSummary.discount.amount += discountAmountCents;
                                        dateSummary.discount.count += discountCount;
                                    });

                                    _.each(pos.exchanges, function (exchangeTransactionBreakdown) {
                                        addToExchangeTransactionBreakdowns(dateExchangeTransactionBreakdowns, exchangeTransactionBreakdown, false, currencyRateObj);
                                        addToExchangeTransactionBreakdowns(results.allTotals.exchangeTransactionBreakdowns, exchangeTransactionBreakdown, true, currencyRateObj);
                                    });
                                });
                                campusDateResults.push({
                                    type: 'mealPeriod',
                                    aggregated: true,
                                    posName: 'Service Period Total',
                                    summary: mealPeriodSummary
                                });
                                locationSummary.mealPlan.count += mealPeriodSummary.mealPlan.count;
                                locationSummary.dcb.count += mealPeriodSummary.dcb.count;
                                locationSummary.dcb.amount += mealPeriodSummary.dcb.amount;
                                locationSummary.charge.count += mealPeriodSummary.charge.count;
                                locationSummary.charge.amount += mealPeriodSummary.charge.amount;
                                locationSummary.cash.count += mealPeriodSummary.cash.count;
                                locationSummary.cash.amount += mealPeriodSummary.cash.amount;
                                locationSummary.creditCard.count += mealPeriodSummary.creditCard.count;
                                locationSummary.creditCard.amount += mealPeriodSummary.creditCard.amount;
                                locationSummary.debitCard.count += mealPeriodSummary.debitCard.count;
                                locationSummary.debitCard.amount += mealPeriodSummary.debitCard.amount;
                                locationSummary.mealEquivalency.count += mealPeriodSummary.mealEquivalency.count;
                                locationSummary.mealEquivalency.amount += mealPeriodSummary.mealEquivalency.amount;
                                locationSummary.other.count += mealPeriodSummary.other.count;
                                locationSummary.other.amount += mealPeriodSummary.other.amount;
                                locationSummary.subtotalTaxable.amount += mealPeriodSummary.subtotalTaxable.amount;
                                locationSummary.subtotalNonTaxable.amount += mealPeriodSummary.subtotalNonTaxable.amount;
                                locationSummary.fiitDcb.count += mealPeriodSummary.fiitDcb.count;
                                locationSummary.fiitDcb.amount += mealPeriodSummary.fiitDcb.amount;
                                locationSummary.fiitMeal.count += mealPeriodSummary.fiitMeal.count;
                                locationSummary.fiitMeal.amount += mealPeriodSummary.fiitMeal.amount;
                                locationSummary.fiitMeal.units += mealPeriodSummary.fiitMeal.units;
                                locationSummary.fiitMealEq.count += mealPeriodSummary.fiitMealEq.count;
                                locationSummary.fiitMealEq.amount += mealPeriodSummary.fiitMealEq.amount;
                                locationSummary.giftCard.count += mealPeriodSummary.giftCard.count;
                                locationSummary.giftCard.amount += mealPeriodSummary.giftCard.amount;
                                locationSummary.tip.count += mealPeriodSummary.tip.count;
                                locationSummary.tip.amount += mealPeriodSummary.tip.amount;
                                locationSummary.cashRounding.count += mealPeriodSummary.cashRounding.count;
                                locationSummary.cashRounding.amount += mealPeriodSummary.cashRounding.amount;
                                locationSummary.remainingBalance.count += mealPeriodSummary.remainingBalance.count;
                                locationSummary.remainingBalance.amount += mealPeriodSummary.remainingBalance.amount;
                                locationSummary.subtotal.count += mealPeriodSummary.subtotal.count;
                                locationSummary.subtotal.amount += mealPeriodSummary.subtotal.amount;
                                locationSummary.tax.count += mealPeriodSummary.tax.count;
                                locationSummary.tax.amount += mealPeriodSummary.tax.amount;
                                locationSummary.total.count += mealPeriodSummary.total.count;
                                locationSummary.total.amount += mealPeriodSummary.total.amount;

                                locationSummary.giftCardActivation.count += mealPeriodSummary.giftCardActivation.count;
                                locationSummary.giftCardActivation.amount += mealPeriodSummary.giftCardActivation.amount;
                                locationSummary.giftCardReload.count += mealPeriodSummary.giftCardReload.count;
                                locationSummary.giftCardReload.amount += mealPeriodSummary.giftCardReload.amount;
                                locationSummary.giftCardPromo.count += mealPeriodSummary.giftCardPromo.count;
                                locationSummary.giftCardPromo.amount += mealPeriodSummary.giftCardPromo.amount;
                            });
                            campusDateResults.push({
                                type: 'location',
                                aggregated: true,
                                mealPeriodName: 'Location Total',
                                summary: locationSummary,
                                mealPeriodChildrenCount: 1
                            });
                            dateSummary.mealPlan.count += locationSummary.mealPlan.count;
                            dateSummary.dcb.count += locationSummary.dcb.count;
                            dateSummary.dcb.amount += locationSummary.dcb.amount;
                            dateSummary.charge.count += locationSummary.charge.count;
                            dateSummary.charge.amount += locationSummary.charge.amount;
                            dateSummary.cash.count += locationSummary.cash.count;
                            dateSummary.cash.amount += locationSummary.cash.amount;
                            dateSummary.creditCard.count += locationSummary.creditCard.count;
                            dateSummary.creditCard.amount += locationSummary.creditCard.amount;
                            dateSummary.debitCard.count += locationSummary.debitCard.count;
                            dateSummary.debitCard.amount += locationSummary.debitCard.amount;
                            dateSummary.mealEquivalency.count += locationSummary.mealEquivalency.count;
                            dateSummary.mealEquivalency.amount += locationSummary.mealEquivalency.amount;
                            dateSummary.other.count += locationSummary.other.count;
                            dateSummary.other.amount += locationSummary.other.amount;
                            dateSummary.subtotalTaxable.amount += locationSummary.subtotalTaxable.amount;
                            dateSummary.subtotalNonTaxable.amount += locationSummary.subtotalNonTaxable.amount;
                            dateSummary.fiitDcb.count += locationSummary.fiitDcb.count;
                            dateSummary.fiitDcb.amount += locationSummary.fiitDcb.amount;
                            dateSummary.fiitMeal.count += locationSummary.fiitMeal.count;
                            dateSummary.fiitMeal.amount += locationSummary.fiitMeal.amount;
                            dateSummary.fiitMeal.units += locationSummary.fiitMeal.units;
                            dateSummary.fiitMealEq.count += locationSummary.fiitMealEq.count;
                            dateSummary.fiitMealEq.amount += locationSummary.fiitMealEq.amount;
                            dateSummary.giftCard.count += locationSummary.giftCard.count;
                            dateSummary.giftCard.amount += locationSummary.giftCard.amount;
                            dateSummary.tip.count += locationSummary.tip.count;
                            dateSummary.tip.amount += locationSummary.tip.amount;
                            dateSummary.cashRounding.count += locationSummary.cashRounding.count;
                            dateSummary.cashRounding.amount += locationSummary.cashRounding.amount;
                            dateSummary.remainingBalance.count += locationSummary.remainingBalance.count;
                            dateSummary.remainingBalance.amount += locationSummary.remainingBalance.amount;
                            dateSummary.subtotal.count += locationSummary.subtotal.count;
                            dateSummary.subtotal.amount += locationSummary.subtotal.amount;
                            dateSummary.tax.count += locationSummary.tax.count;
                            dateSummary.tax.amount += locationSummary.tax.amount;
                            dateSummary.total.count += locationSummary.total.count;
                            dateSummary.total.amount += locationSummary.total.amount;

                            dateSummary.giftCardActivation.count += locationSummary.giftCardActivation.count;
                            dateSummary.giftCardActivation.amount += locationSummary.giftCardActivation.amount;
                            dateSummary.giftCardReload.count += locationSummary.giftCardReload.count;
                            dateSummary.giftCardReload.amount += locationSummary.giftCardReload.amount;
                            dateSummary.giftCardPromo.count += locationSummary.giftCardPromo.count;
                            dateSummary.giftCardPromo.amount += locationSummary.giftCardPromo.amount;

                            dateCollectedSummary.cash.count = dateSummary.cash.count + dateRefundSummary.cash.count;
                            dateCollectedSummary.cash.amount = dateSummary.cash.amount + dateRefundSummary.cash.amount;
                            dateCollectedSummary.creditCard.count = dateSummary.creditCard.count + dateRefundSummary.creditCard.count;
                            dateCollectedSummary.creditCard.amount = dateSummary.creditCard.amount + dateRefundSummary.creditCard.amount;
                            dateCollectedSummary.debitCard.count += dateSummary.debitCard.count + dateRefundSummary.debitCard.count;
                            dateCollectedSummary.debitCard.amount += dateSummary.debitCard.amount + dateRefundSummary.debitCard.amount;
                            dateCollectedSummary.other.count = dateSummary.other.count + dateRefundSummary.other.count;
                            dateCollectedSummary.other.amount = dateSummary.other.amount + dateRefundSummary.other.amount;
                            dateCollectedSummary.subtotalTaxable.amount += dateSummary.subtotalTaxable.amount;
                            dateCollectedSummary.subtotalNonTaxable.amount += dateSummary.subtotalNonTaxable.amount;
                            dateCollectedSummary.fiitDcb.count = dateSummary.fiitDcb.count + dateRefundSummary.fiitDcb.count;
                            dateCollectedSummary.fiitDcb.amount = dateSummary.fiitDcb.amount + dateRefundSummary.fiitDcb.amount;
                            dateCollectedSummary.fiitMeal.count = dateSummary.fiitMeal.count + dateRefundSummary.fiitMeal.count;
                            dateCollectedSummary.fiitMeal.amount = dateSummary.fiitMeal.amount + dateRefundSummary.fiitMeal.amount;
                            dateCollectedSummary.fiitMeal.units = dateSummary.fiitMeal.units + dateRefundSummary.fiitMeal.units;
                            dateCollectedSummary.fiitMealEq.count = dateSummary.fiitMealEq.count + dateRefundSummary.fiitMealEq.count;
                            dateCollectedSummary.fiitMealEq.amount = dateSummary.fiitMealEq.amount + dateRefundSummary.fiitMealEq.amount;
                            dateCollectedSummary.giftCard.count = dateSummary.giftCard.count + dateRefundSummary.giftCard.count;
                            dateCollectedSummary.giftCard.amount = dateSummary.giftCard.amount + dateRefundSummary.giftCard.amount;
                            dateCollectedSummary.dcb.count = dateSummary.dcb.count + dateRefundSummary.dcb.count;
                            dateCollectedSummary.dcb.amount = dateSummary.dcb.amount + dateRefundSummary.dcb.amount;
                            dateCollectedSummary.mealPlan.count = dateSummary.mealPlan.count + dateRefundSummary.mealPlan.count;
                            dateCollectedSummary.mealEquivalency.count = dateSummary.mealEquivalency.count + dateRefundSummary.mealEquivalency.count;
                            dateCollectedSummary.mealEquivalency.amount = dateSummary.mealEquivalency.amount + dateRefundSummary.mealEquivalency.amount;
                        });
                        dateSummary.currencyRate = currencyRateObj;

                        results.allTotals.mealPlan.count += dateCollectedSummary.mealPlan.count;
                        results.allTotals.dcb.count += dateCollectedSummary.dcb.count;
                        results.allTotals.dcb.amount += dateCollectedSummary.dcb.amount;
                        results.allTotals.converted.dcb.amount += convertAmountToCurrency(dateCollectedSummary.dcb.amount, currencyRateObj.rate);
                        results.allTotals.charge.count += dateCollectedSummary.charge.count;
                        results.allTotals.charge.amount += dateCollectedSummary.charge.amount;
                        results.allTotals.converted.charge.amount += convertAmountToCurrency(dateCollectedSummary.charge.amount, currencyRateObj.rate);
                        results.allTotals.cash.count += dateCollectedSummary.cash.count;
                        results.allTotals.cash.amount += dateCollectedSummary.cash.amount;
                        results.allTotals.converted.cash.amount += convertAmountToCurrency(dateCollectedSummary.cash.amount, currencyRateObj.rate);
                        results.allTotals.creditCard.count += dateCollectedSummary.creditCard.count;
                        results.allTotals.creditCard.amount += dateCollectedSummary.creditCard.amount;
                        results.allTotals.converted.creditCard.amount += convertAmountToCurrency(dateCollectedSummary.creditCard.amount, currencyRateObj.rate);
                        results.allTotals.debitCard.count += dateCollectedSummary.debitCard.count;
                        results.allTotals.debitCard.amount += dateCollectedSummary.debitCard.amount;
                        results.allTotals.converted.debitCard.amount += convertAmountToCurrency(dateCollectedSummary.debitCard.amount, currencyRateObj.rate);
                        results.allTotals.mealEquivalency.count += dateCollectedSummary.mealEquivalency.count;
                        results.allTotals.mealEquivalency.amount += dateCollectedSummary.mealEquivalency.amount;
                        results.allTotals.converted.mealEquivalency.amount += convertAmountToCurrency(dateCollectedSummary.mealEquivalency.amount, currencyRateObj.rate);
                        results.allTotals.other.count += dateCollectedSummary.other.count;
                        results.allTotals.other.amount += dateCollectedSummary.other.amount;
                        results.allTotals.converted.other.amount += convertAmountToCurrency(dateCollectedSummary.other.amount, currencyRateObj.rate);
                        results.allTotals.subtotalTaxable.amount += dateCollectedSummary.subtotalTaxable.amount;
                        results.allTotals.converted.subtotalTaxable.amount += convertAmountToCurrency(dateCollectedSummary.subtotalTaxable.amount, currencyRateObj.rate);
                        results.allTotals.subtotalNonTaxable.amount += dateCollectedSummary.subtotalNonTaxable.amount;
                        results.allTotals.converted.subtotalNonTaxable.amount += convertAmountToCurrency(dateCollectedSummary.subtotalNonTaxable.amount, currencyRateObj.rate);
                        results.allTotals.fiitDcb.count += dateCollectedSummary.fiitDcb.count;
                        results.allTotals.fiitDcb.amount += dateCollectedSummary.fiitDcb.amount;
                        results.allTotals.converted.fiitDcb.amount += convertAmountToCurrency(dateCollectedSummary.fiitDcb.amount, currencyRateObj.rate);
                        results.allTotals.fiitMeal.count += dateCollectedSummary.fiitMeal.count;
                        results.allTotals.fiitMeal.amount += dateCollectedSummary.fiitMeal.amount;
                        results.allTotals.converted.fiitMeal.amount += convertAmountToCurrency(dateCollectedSummary.fiitMeal.amount, currencyRateObj.rate);
                        results.allTotals.fiitMeal.units += dateCollectedSummary.fiitMeal.units;
                        results.allTotals.fiitMealEq.count += dateCollectedSummary.fiitMealEq.count;
                        results.allTotals.fiitMealEq.amount += dateCollectedSummary.fiitMealEq.amount;
                        results.allTotals.converted.fiitMealEq.amount += convertAmountToCurrency(dateCollectedSummary.fiitMealEq.amount, currencyRateObj.rate);
                        results.allTotals.giftCard.count += dateCollectedSummary.giftCard.count;
                        results.allTotals.giftCard.amount += dateCollectedSummary.giftCard.amount;
                        results.allTotals.converted.giftCard.amount += convertAmountToCurrency(dateCollectedSummary.giftCard.amount, currencyRateObj.rate);

                        results.allTotals.cashRounding.count += dateSummary.cashRounding.count;
                        results.allTotals.cashRounding.amount += dateSummary.cashRounding.amount;
                        results.allTotals.converted.cashRounding.amount += convertAmountToCurrency(dateSummary.cashRounding.amount, currencyRateObj.rate);
                        results.allTotals.remainingBalance.count += dateSummary.remainingBalance.count;
                        results.allTotals.remainingBalance.amount += dateSummary.remainingBalance.amount;
                        results.allTotals.converted.remainingBalance.amount += convertAmountToCurrency(dateSummary.remainingBalance.amount, currencyRateObj.rate);
                        results.allTotals.subtotal.count += dateSummary.subtotal.count;
                        results.allTotals.subtotal.amount += dateSummary.subtotal.amount;
                        results.allTotals.converted.subtotal.amount += convertAmountToCurrency(dateSummary.subtotal.amount, currencyRateObj.rate);
                        results.allTotals.tax.count += dateSummary.tax.count;
                        results.allTotals.tax.amount += dateSummary.tax.amount;
                        results.allTotals.converted.tax.amount += convertAmountToCurrency(dateSummary.tax.amount, currencyRateObj.rate);
                        results.allTotals.total.count += dateSummary.total.count;
                        results.allTotals.total.amount += dateSummary.total.amount;
                        results.allTotals.converted.total.amount += convertAmountToCurrency(dateSummary.total.amount, currencyRateObj.rate);
                        results.allTotals.tip.count += dateSummary.tip.count;
                        results.allTotals.tip.amount += dateSummary.tip.amount;
                        results.allTotals.converted.tip.amount += convertAmountToCurrency(dateSummary.tip.amount, currencyRateObj.rate);

                        results.allTotals.giftCardActivation.count += dateSummary.giftCardActivation.count;
                        results.allTotals.giftCardActivation.amount += dateSummary.giftCardActivation.amount;
                        results.allTotals.converted.giftCardActivation.amount += convertAmountToCurrency(dateSummary.giftCardActivation.amount, currencyRateObj.rate);
                        results.allTotals.giftCardReload.count += dateSummary.giftCardReload.count;
                        results.allTotals.giftCardReload.amount += dateSummary.giftCardReload.amount;
                        results.allTotals.converted.giftCardReload.amount += convertAmountToCurrency(dateSummary.giftCardReload.amount, currencyRateObj.rate);
                        results.allTotals.giftCardPromo.count += dateSummary.giftCardPromo.count;
                        results.allTotals.giftCardPromo.amount += dateSummary.giftCardPromo.amount;
                        results.allTotals.converted.giftCardPromo.amount += convertAmountToCurrency(dateSummary.giftCardPromo.amount, currencyRateObj.rate);

                        var dailySalesAmount = dateSummary.dcb.amount + dateSummary.charge.amount + dateSummary.cash.amount
                            + dateSummary.creditCard.amount + dateSummary.debitCard.amount
                            + dateSummary.other.amount + dateSummary.giftCard.amount;

                        results.allTotals.sales.amount += dailySalesAmount;
                        results.allTotals.converted.sales.amount += convertAmountToCurrency(dailySalesAmount, currencyRateObj.rate);
                        results.allTotals.sales.count +=
                            dateSummary.dcb.count + dateSummary.charge.count+ dateSummary.cash.count
                            + dateSummary.creditCard.count + dateSummary.debitCard.count
                            + dateSummary.other.count + dateSummary.giftCard.count;
                        results.allTotals.meals.count += dateSummary.mealPlan.count;

                        results.allTotals.refundSubtotal += dateRefund.refundSubtotal;
                        results.allTotals.converted.refundSubtotal += convertAmountToCurrency(dateRefund.refundSubtotal, currencyRateObj.rate);
                        results.allTotals.refundTax += dateRefund.refundTax;
                        results.allTotals.converted.refundTax += convertAmountToCurrency(dateRefund.refundTax, currencyRateObj.rate);
                        results.allTotals.refundCashRounding -= dateRefund.refundCashRounding;
                        results.allTotals.converted.refundCashRounding -= convertAmountToCurrency(dateRefund.refundCashRounding, currencyRateObj.rate);

                        results.allTotals.discount.count += dateSummary.discount.count;
                        results.allTotals.discount.amount += dateSummary.discount.amount;
                        results.allTotals.converted.discount.amount += convertAmountToCurrency(dateSummary.discount.amount, currencyRateObj.rate);

                        results.allTotals.currencyRates = results.allTotals.currencyRates || [];
                        addToCurrenciesApplied(currencyRateObj);

                        var currencyCodesApplied = _.chain($scope.currenciesApplied)
                            .pluck('fromCurrencyCode')
                            .uniq()
                            .value();

                        results.allTotals.distinctFromCurrency = (currencyCodesApplied.length === 1)
                            ? currencyCodesApplied[0]
                            : '';

                        campusDateResults.push({
                            type: 'date',
                            aggregated: true,
                            locationName: 'Total Tender',
                            summary: dateSummary,
                            locationChildrenCount: 1,
                            // hack to make table look good
                            mealPeriodChildrenCount: 1
                        });
                        campusDateResults.push({
                            type: 'date',
                            aggregated: true,
                            locationName: 'Total Refund',
                            summary: dateRefundSummary,
                            locationChildrenCount: 1,
                            // hack to make table look good
                            mealPeriodChildrenCount: 1
                        });
                        campusDateResults.push({
                            type: 'date',
                            aggregated: true,
                            locationName: 'Total Collected',
                            summary: dateCollectedSummary,
                            locationChildrenCount: 1,
                            // hack to make table look good
                            mealPeriodChildrenCount: 1
                        });

                        results[campus.campusName].campusResults.push({
                            dateString: date.dateString,
                            dateResults: campusDateResults,
                            taxBreakdowns: dateTaxBreakdowns,
                            otherTenderBreakdowns: dateOtherTenderBreakdowns,
                            discountBreakdowns: dateDiscountBreakdowns,
                            exchangeTransactionBreakdowns: dateExchangeTransactionBreakdowns,
                            refund: dateRefund,
                            rate: currencyRateObj
                        });
                    });
                });

                $scope.currenciesApplied = _.chain($scope.currenciesApplied)
                    .sortBy('fromCurrencyCode')
                    .sortBy('appliedDate')
                    .value();

                return results;
            };
    /*        var convertReports = function (reports) {
                var baseSummary = {
                    mealPlan: { count: 0 },
                    dcb: {
                        count: 0,
                        amount: 0
                    },
                    charge: {
                        count: 0,
                        amount: 0
                    },
                    cash: {
                        count: 0,
                        amount: 0
                    },
                    creditCard: {
                        count: 0,
                        amount: 0
                    },
                    debitCard: {
                        count: 0,
                        amount: 0
                    }
                };
                var results;
                _.each(reports, function (report, campusIndex) {
                    var campus = report.campus;
                    if (!campus) {
                        return;
                    }
                    results = results || {};
                    results[campus.campusName] = {
                        campusName: campus.campusName,
                        campusResults: []
                    };
                    results.allTotals = angular.copy(baseSummary);
                    _.each(campus.dates, function (date, dateIndex) {
                        var dateSummary = angular.copy(baseSummary);
                        var campusDateResults = [];
                        _.each(date.locations, function (location, locationIndex) {
                            var locationSummary = angular.copy(baseSummary);
                            var locationChildrenCount = 1;
                            _.each(location.mealPeriods, function (mealPeriod, mealPeriodIndex) {
                                var mealPeriodChildrenCount = mealPeriod.poses.length + 1;
                                locationChildrenCount += mealPeriodChildrenCount;
                            });
                            _.each(location.mealPeriods, function (mealPeriod, mealPeriodIndex) {
                                var mealPeriodSummary = angular.copy(baseSummary);
                                var mealPeriodChildrenCount = mealPeriod.poses.length + 1;
                                _.each(mealPeriod.poses, function (pos, posIndex) {
                                    var result = {
                                        type: 'pos',
                                        aggregated: false,
                                        posName: pos.posName,
                                        summary: {
                                            mealPlan: pos.mealPlan,
                                            dcb: pos.dcb,
                                            charge: pos.charge,
                                            cash: pos.cash,
                                            creditCard: pos.creditCard,
                                            debitCard: pos.debitCard
                                        }
                                    };
                                    if (dateIndex === 0 && locationIndex === 0 && mealPeriodIndex === 0 && posIndex === 0) {
                                        result.campusName = campus.campusName;
                                    }
                                    if (locationIndex === 0 && mealPeriodIndex === 0 && posIndex === 0) {
                                        result.dateString = date.dateString;
                                    }
                                    if (mealPeriodIndex === 0 && posIndex === 0) {
                                        result.locationName = location.locationName;
                                        result.locationChildrenCount = locationChildrenCount;
                                    }
                                    if (posIndex === 0) {
                                        result.mealPeriodName = mealPeriod.mealPeriodName;
                                        result.mealPeriodChildrenCount = mealPeriodChildrenCount;
                                    }
                                    campusDateResults.push(result);
                                    mealPeriodSummary.mealPlan.count += pos.mealPlan.count;
                                    mealPeriodSummary.dcb.count += pos.dcb.count;
                                    mealPeriodSummary.dcb.amount += pos.dcb.amount;
                                    mealPeriodSummary.charge.count += pos.charge.count;
                                    mealPeriodSummary.charge.amount += pos.charge.amount;
                                    mealPeriodSummary.cash.count += pos.cash.count;
                                    mealPeriodSummary.cash.amount += pos.cash.amount;
                                    mealPeriodSummary.creditCard.count += pos.creditCard.count;
                                    mealPeriodSummary.creditCard.amount += pos.creditCard.amount;
                                    mealPeriodSummary.debitCard.count += pos.debitCard.count;
                                    mealPeriodSummary.debitCard.amount += pos.debitCard.amount;
                                });
                                campusDateResults.push({
                                    type: 'mealPeriod',
                                    aggregated: true,
                                    posName: 'Service Period Total',
                                    summary: mealPeriodSummary
                                });
                                locationSummary.mealPlan.count += mealPeriodSummary.mealPlan.count;
                                locationSummary.dcb.count += mealPeriodSummary.dcb.count;
                                locationSummary.dcb.amount += mealPeriodSummary.dcb.amount;
                                locationSummary.charge.count += mealPeriodSummary.charge.count;
                                locationSummary.charge.amount += mealPeriodSummary.charge.amount;
                                locationSummary.cash.count += mealPeriodSummary.cash.count;
                                locationSummary.cash.amount += mealPeriodSummary.cash.amount;
                                locationSummary.creditCard.count += mealPeriodSummary.creditCard.count;
                                locationSummary.creditCard.amount += mealPeriodSummary.creditCard.amount;
                                locationSummary.debitCard.count += mealPeriodSummary.debitCard.count;
                                locationSummary.debitCard.amount += mealPeriodSummary.debitCard.amount;

                            });
                            campusDateResults.push({
                                type: 'location',
                                aggregated: true,
                                mealPeriodName: 'Location Total',
                                summary: locationSummary,
                                mealPeriodChildrenCount: 1
                            });
                            dateSummary.mealPlan.count += locationSummary.mealPlan.count;
                            dateSummary.dcb.count += locationSummary.dcb.count;
                            dateSummary.dcb.amount += locationSummary.dcb.amount;
                            dateSummary.charge.count += locationSummary.charge.count;
                            dateSummary.charge.amount += locationSummary.charge.amount;
                            dateSummary.cash.count += locationSummary.cash.count;
                            dateSummary.cash.amount += locationSummary.cash.amount;
                            dateSummary.creditCard.count += locationSummary.creditCard.count;
                            dateSummary.creditCard.amount += locationSummary.creditCard.amount;
                            dateSummary.debitCard.count += locationSummary.debitCard.count;
                            dateSummary.debitCard.amount += locationSummary.debitCard.amount;
                        });
                        results.allTotals.mealPlan.count += dateSummary.mealPlan.count;
                        results.allTotals.dcb.count += dateSummary.dcb.count;
                        results.allTotals.dcb.amount += dateSummary.dcb.amount;
                        results.allTotals.charge.count += dateSummary.charge.count;
                        results.allTotals.charge.amount += dateSummary.charge.amount;
                        results.allTotals.cash.count += dateSummary.cash.count;
                        results.allTotals.cash.amount += dateSummary.cash.amount;
                        results.allTotals.creditCard.count += dateSummary.creditCard.count;
                        results.allTotals.creditCard.amount += dateSummary.creditCard.amount;
                        results.allTotals.debitCard.count += dateSummary.debitCard.count;
                        results.allTotals.debitCard.amount += dateSummary.debitCard.amount;
                        campusDateResults.push({
                            type: 'date',
                            aggregated: true,
                            locationName: 'Daily Total',
                            summary: dateSummary,
                            locationChildrenCount: 1,
                            // hack to make table look good
                            mealPeriodChildrenCount: 1
                        });
                        results[campus.campusName].campusResults.push({
                            dateString: date.dateString,
                            dateResults: campusDateResults
                        });
                    });
                });
                return results;
            }; */
            $scope.getSalesReport = async function () {
                $scope.searchDisabled = true;
                clearTimeout(allowRetry);
                allowRetry = setTimeout(function () {
                    $scope.searchDisabled = false;
                }, 30000);
                var reportSearch = {};

                if ($scope.reportSearch.companyId) {
                    reportSearch.companyId = $scope.reportSearch.companyId;
                }
                if ($scope.reportSearch.locationId) {
                    let locationObj = $scope.locations.find((location) => {
                        return location.id == $scope.reportSearch.locationId;
                    });

                    reportSearch.locationId = (locationObj) ? locationObj.originalLocationId : null;
                    reportSearch.specificLocationOnly = !!locationObj.specificLocationOnly;
                }
                if ($scope.reportSearch.menuPeriodId) {
                    reportSearch.menuPeriodId = $scope.reportSearch.menuPeriodId;
                }
                if ($scope.reportSearch.posId) {
                    reportSearch.posStationId = $scope.reportSearch.posId;
                }

                // Here we are combining start/end date with start/end hour to send the time string as
                // url params to the server this is how the time params looks like: "21-04-20T10:00:00"

                const startHourStr = moment($scope.reportSearch.startHour, ['hh:mm A']).format('HH:mm:ss');
                reportSearch.startDateTime = `${moment($scope.reportSearch.fromDate).format('YYYY-MM-DD')}T${startHourStr}`;

                const endHourStr = moment($scope.reportSearch.endHour, ['hh:mm A']).add(60, 'seconds').format('HH:mm:ss');
                if (endHourStr === '00:00:00') {
                    reportSearch.endDateTime = `${moment($scope.reportSearch.toDate).add(1, 'day').format('YYYY-MM-DD')}T${endHourStr}`;
                } else {
                    reportSearch.endDateTime = `${moment($scope.reportSearch.toDate).format('YYYY-MM-DD')}T${endHourStr}`;
                }


                $scope.loadingSalesReport = true;

                $scope.currencyRates = await getApplicableCurrencyRates(reportSearch);

                Reports.getSalesReport(reportSearch, function (response) {
                    $scope.results = convertReports(response, $scope.currencyRates);
                    var currentReportSearch = {
                        startHour: moment($scope.reportSearch.startHour, ['hh:mm A']).format('hh:mm:ss A'),
                        endHour: moment($scope.reportSearch.endHour, ['hh:mm A']).add(59, 'seconds').format('hh:mm:ss A'),
                        fromDate: $scope.reportSearch.fromDate,
                        toDate: $scope.reportSearch.toDate,
                        companyName: $scope.lookupEntity('companies', $scope.reportSearch.companyId),
                        locationName: $scope.lookupEntity('locations', $scope.reportSearch.locationId),
                        mealPeriodName: $scope.lookupEntity('mealPeriods', $scope.reportSearch.mealPeriodId),
                        posName: $scope.lookupEntity('poses', $scope.reportSearch.posId),
                        locationId: $scope.reportSearch.locationId
                    };
                    currentReportSearch = _.pick(currentReportSearch, function (value) {
                        return !!value;
                    });
                    if (_.isEmpty(currentReportSearch)) {
                        $scope.currentReportSearch = undefined;
                    } else {
                        $scope.currentReportSearch = currentReportSearch;
                    }

                    var currentLocation = _.findWhere($scope.locations, {
                        id: $scope.currentReportSearch.locationId
                    }) || {};
                    $scope.currentReportSearch.locationName = currentLocation.originalName || '';

                    $scope.loadingSalesReport = false;
                });
            };
            $scope.saveSelectedFromDate = function () {
                var selectedFromDate = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                DateRangeService.setFromDate(selectedFromDate);
            };
            $scope.saveSelectedToDate = function () {
                var selectedToDate = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                DateRangeService.setToDate(selectedToDate);
            };

            var getApplicableCurrencyRates = function (reportSearch) {
                return Reports.getExchangeRates({
                    companyId: reportSearch.companyId,
                    startDateTime: reportSearch.startDateTime,
                    endDateTime: reportSearch.endDateTime
                }).$promise;
            };

            var getApplicableCurrencyRate = function (fromCurrencyCode, dateString) {
                var baseCurrencyCode = $scope.currencyRates.organizationBaseCurrency || 'CAD';
                fromCurrencyCode = fromCurrencyCode || 'USD';
                dateString = moment(dateString).format('YYYY-MM-DD');

                var ratesByCurrency = $scope.currencyRates.rateAsPerCurrencyMap[fromCurrencyCode] || {};
                var rates = ratesByCurrency.exchangeRateAsPerDate || {};
                var rateDates = Object.keys(rates).sort();

                var rateToUse = 1;
                var rateSourceDate = '';
                if (rateDates.length) {
                    rateSourceDate = rateDates[0];
                    rateToUse = rates[rateSourceDate];

                    for (var rateDate of rateDates) {
                        if (dateString < rateDate) {
                            break;
                        }

                        rateSourceDate = rateDate;
                        rateToUse = rates[rateDate];
                    }
                }

                return {
                    fromCurrencyCode: fromCurrencyCode,
                    toCurrencyCode: baseCurrencyCode,
                    appliedDate: dateString,
                    sourceDate: rateSourceDate,
                    rate: rateToUse,
                    converted: fromCurrencyCode !== baseCurrencyCode
                };
            };

            var convertAmountToCurrency = $scope.convertAmountToCurrency = function (amountCent, rate) {
                var decimalAmountCent = new Decimal(amountCent || 0);
                var decimalRate = new Decimal(rate || 1);
                var decimalConvertedAmount = decimalAmountCent.times(decimalRate);

                return decimalConvertedAmount.round().toNumber();
            };

            $scope.currenciesApplied = [];
            var addToCurrenciesApplied = function (rate) {
                if (!rate.converted) {
                    return;
                }

                var identifier = {
                    appliedDate: rate.appliedDate,
                    fromCurrencyCode: rate.fromCurrencyCode,
                    toCurrencyCode: rate.toCurrencyCode,
                    converted: rate.converted
                };

                var foundCurrency = _.findWhere($scope.currenciesApplied, identifier);
                if (!foundCurrency) {
                    $scope.currenciesApplied.push(rate);
                }
            };

            $scope.init = function () {
                var fromDate;
                var toDate;
                if (DateRangeService.getFromDate()) {
                    fromDate = moment(DateRangeService.getFromDate()).startOf('day').toDate();
                } else {
                    fromDate = moment().startOf('week').toDate();
                }
                if (DateRangeService.getToDate()) {
                    toDate = moment(DateRangeService.getToDate()).endOf('day').toDate();
                } else {
                    toDate = moment().endOf('day').toDate();
                }
                $scope.reportSearch = {
                    fromDate: fromDate,
                    toDate: toDate,
                    isAdvancedSearch: true
                };
                lookupCompanyHierarchy();
                loadAllMenuPeriods();
                // disable sales report query on initialize due to performance issue fornow
            };
            $scope.init();
            $scope.exportToPdf = function (tableId) {
                Export.tableToPdf(tableId);
            };

            $scope.$on('$destroy', function () {
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.locationId', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.servicePeriodId', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.fromDate', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.toDate', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });
        }
    ]);
};
