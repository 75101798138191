'use strict';


const moment = require('moment');

module.exports = function (freshideasReports) {

    freshideasReports.controller('ParticipationRateReport', [
        '$scope',
        '$rootScope',
        'Reports',
        'Utils',
        '$modal',
        'NgGridSortService',
        'NgGridTemplateService',
        'Lookup',
        'Meals',
        'Export',
        'Security',
        'Platform',
        function ($scope, $rootScope, Reports, Utils, $modal, NgGridSortService, NgGridTemplateService, Lookup, Meals, Export, Security, Platform) {

            $scope.isIosWebkit = Platform.isIosWebkit();
            $scope.currentUser = Security.getUser();
            $scope.isFullAdmin = ($scope.currentUser.permission === 'FULLADMIN');
            $scope.hasSearched = false;

            $scope.init = function () {
                $scope.loadCompanyHierarchy().$promise
                    .then($scope.loadCompanyMeals);
            };

            $scope.loadCompanyHierarchy = function () {
                return Lookup.companyHierarchy({}, function (response) {
                    $scope.company = _.findWhere(response, {id: $scope.currentUser.companyId});
                });
            };

            $scope.loadCompanyMeals = function () {
                return Meals.getMealPlans({companyId: $scope.currentUser.companyId}, function (response) {
                    $scope.mealPlans = _.filter(response.entries, function (mealPlan) {
                        var result =
                            mealPlan.resetPeriodEnd
                            && (['DAILY', 'WEEKLY', 'MONTHLY'].indexOf(mealPlan.mealPlanCyclePeriod) > -1);

                        return result;
                    });

                    $scope.mealPlanMap = {};
                    _.each($scope.mealPlans, function (mealPlan) {
                        $scope.mealPlanMap[mealPlan.mealPlanId] = mealPlan;
                    });

                    $scope.dcbMealPlanOptions = _.where($scope.mealPlans, {mealPlanType: 'DCB'});
                    $scope.mealPlanOptions = _.where($scope.mealPlans, {mealPlanType: 'MEAL'});
                });
            };

            $scope.toggleMealPlan = function (mealPlan) {
                mealPlan.selected = !mealPlan.selected;

                var mealPlanIds = _.map($scope.mealPlanOptions, function (option) {
                    return (option.selected)? option.mealPlanId : undefined;
                });
                mealPlanIds = _.compact(mealPlanIds);

                var dcbMealPlanIds = _.map($scope.dcbMealPlanOptions, function (option) {
                    return (option.selected)? option.mealPlanId : undefined;
                });
                dcbMealPlanIds = _.compact(dcbMealPlanIds);

                $scope.participationRateSearch.mealPlanIds = mealPlanIds;
                $scope.participationRateSearch.dcbMealPlanIds = dcbMealPlanIds;
                $scope.participationRateSearch.allMealPlanIds = mealPlanIds.concat(dcbMealPlanIds);
            };

            $scope.setSortByField = function (sortBy) {
                $scope.currentReportSearch.sortBy = sortBy;
                $scope.currentReportSearch.ascending = !$scope.currentReportSearch.ascending;
            };

            $scope.resetFilters = function () {
                $scope.participationRateSearch.startDateTime = moment().startOf('week').toDate();
                $scope.participationRateSearch.endDateTime = moment().endOf('day').toDate();
                $scope.participationRateSearch.isAdvancedSearch = true;
                $scope.participationRateSearch.mealPlanIds = [];
                $scope.participationRateSearch.dcbMealPlanIds = [];
                $scope.participationRateSearch.allMealPlanIds = [];

                _.each($scope.dcbMealPlanOptions, function (option) {
                    option.selected = false;
                });
                _.each($scope.mealPlanOptions, function (option) {
                    option.selected = false;
                });
            };

            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };
            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };

            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };

            $scope.participationRateSearch = {
                isAdvancedSearch: true,
                startDateTime: moment().startOf('month').subtract(1, 'week').toDate(),
                endDateTime: moment().endOf('day').toDate(),
                mealPlanIds: []
            };

            $scope.loadParticipationRate = function () {
                var participationRateSearch = {};
                participationRateSearch.startDateTime = moment($scope.participationRateSearch.startDateTime).startOf('day').valueOf();
                participationRateSearch.endDateTime = moment($scope.participationRateSearch.endDateTime).endOf('day').valueOf();
                participationRateSearch.mealPlanIds = $scope.participationRateSearch.mealPlanIds;
                participationRateSearch.dcbMealPlanIds = $scope.participationRateSearch.dcbMealPlanIds;
                participationRateSearch.allMealPlanIds = $scope.participationRateSearch.allMealPlanIds;

                $scope.participationRateData = [];
                $scope.isLoading = true;
                $scope.hasSearched = true;
                clearTotalParticipationRate();

                Reports.getParticipationRateReport(participationRateSearch, function (response) {
                    var data = response;

                    $scope.resultLimit = 50;

                    if (data.length > 0) {
                        $scope.isLoading = false;

                        $scope.participationRateData = [];
                        _.each(response, function (data) {
                            var matchedMealPlan = _.findWhere($scope.mealPlans, {mealPlanId: data.mealPlanId});
                            if (matchedMealPlan
                                && participationRateSearch.allMealPlanIds.indexOf(data.mealPlanId) > -1) {
                                data.mealPlanName = matchedMealPlan.name;
                                data.mealPlanType = matchedMealPlan.mealPlanType;
                                $scope.participationRateData.push(data);
                            }
                        });
                        calculateTotalParticipationRate();
                    } else {
                        $scope.isLoading = false;
                        $scope.participationRateData = [];
                    }

                    $scope.currentReportSearch = participationRateSearch;
                }, function (error) {
                    $scope.isLoading = false;
                });
            };

            var clearTotalParticipationRate = function () {
                $scope.totalParticipationRateData = {};
            };
            var calculateTotalParticipationRate = function () {
                var totalParticipationRateData = {};
                _.each($scope.participationRateData, function (data) {
                    var mealPlanId = data.mealPlanId;
                    var mealPlan = $scope.mealPlanMap[mealPlanId];
                    var mealPlanType = mealPlan.mealPlanType;

                    totalParticipationRateData[mealPlanType] = totalParticipationRateData[mealPlanType] || {
                        mealPlanName: 'Total ' + mealPlanType,
                        mealPlanType: mealPlanType,
                        dcbUsed: 0,
                        dcbUsedMin: 0,
                        dcbUsedMax: 0,
                        dcbPotential: 0,
                        mealUsed: 0,
                        mealUsedMin: 0,
                        mealUsedMax: 0,
                        mealPotential: 0,
                        count: 0,
                        aggregated: true
                    };

                    switch (mealPlanType) {
                        case 'MEAL':
                            totalParticipationRateData.MEAL.mealUsed += data.mealUsed;
                            totalParticipationRateData.MEAL.mealUsedMin += data.mealUsedMin;
                            totalParticipationRateData.MEAL.mealUsedMax += data.mealUsedMax;
                            totalParticipationRateData.MEAL.mealPotential += data.mealPotential;
                            totalParticipationRateData.MEAL.count += data.count;
                            break;
                        case 'DCB':
                            totalParticipationRateData.DCB.dcbUsed += data.dcbUsed;
                            totalParticipationRateData.DCB.dcbUsedMin += data.dcbUsedMin;
                            totalParticipationRateData.DCB.dcbUsedMax += data.dcbUsedMax;
                            totalParticipationRateData.DCB.dcbPotential += data.dcbPotential;
                            totalParticipationRateData.DCB.count += data.count;
                            break;
                    }
                });

                if (totalParticipationRateData.MEAL) {
                    $scope.participationRateData.push(totalParticipationRateData.MEAL);
                }

                if (totalParticipationRateData.DCB) {
                    $scope.participationRateData.push(totalParticipationRateData.DCB);
                }
            };

            $scope.loadMoreResults = function () {
                var resultLimit = Math.min($scope.resultLimit + 50, $scope.participationRateData.length);
                $scope.resultLimit = resultLimit;
            };

            $scope.exportToPdf = function (tableId) {
                $scope.resultLimit = $scope.participationRateData.length;

                // wrap the actual export code in a `setTimeout` so that the
                // all patron spend data are generated in the UI first
                setTimeout(function () {
                    Export.tableToPdf(tableId, 'landscape');
                }, 0);
            };

            $scope.init();
        }
    ]);

};
