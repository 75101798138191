'use strict';

module.exports = function (freshideasSetup) {

    freshideasSetup.controller('TimeModifierCtrl', [
    '$scope',
    '$translate',
    '$modal',
    '$filter',
    '$timeout',
    'Users',
    'Security',
    'Settings',
    'PosAlertService',
    function ($scope, $translate, $modal, $filter, $timeout, Users, Security, Settings, PosAlertService) {
        $scope.timePeriods = ['PM', 'AM'];
        const privilege = Security.getUser().permission;
        $scope.hasEditPrivilege = privilege && (privilege === 'FULLADMIN' || privilege === 'SITEADMIN');

        $scope.showPrivilegeErrorModal = () => {
            PosAlertService.showAlertByName('general', {
                title: 'general.error.oops.ttl',
                message: 'setupWizard.merchant.timecards.permission.error'
            });
        };

        $scope.init = function (originalTimeStamp, label) {
            $scope.timeStamp = originalTimeStamp;
            if (!$scope.timeStamp.timestampPeriod) {
                $scope.timeStamp.timestampPeriod = $scope.timePeriods[0];
            }
        };

        $scope.datePickerOptions = {
                formatYear: 'yy',
                startingDay: 1,
                closeOnDateSelection: true,
                placement: 'left-bottom',
                popupPlacement: 'left-bottom'
        };


        $scope.onClickTime = function () {
            if (!$scope.hasEditPrivilege) {
                $scope.showPrivilegeErrorModal();
                return;
            }

            var timeValidator = function (oldVal, newVal) {
                    var value = oldVal + '' + newVal;
                    if (value.length > 4) {
                        return false;
                    }

                    // Checking for hours
                    if (value.length > 3) {
                        if (value.charAt(0) > 1 ) {
                            return false;
                        }
                        if (value.charAt(1) > 2) {
                            return false;
                        }

                        if (value.charAt(2) > 5) {
                            return false;
                        }
                    }

                    // Checking for minutes
                    if (value.length > 2 ) {
                        if (value.charAt(1) > 5) {
                            return false;
                        }
                    }

                    return true;
            };

            var callback = function (editedValue) {
                $scope.timeStamp.timeInHrsMins = editedValue;
                if ($scope.$parent.checkIfTimecardEdited) {
                    $scope.$parent.checkIfTimecardEdited();
                }
            };


            return showPinPad('time', $scope.timeStamp.timeInHrsMins, callback, timeValidator);
        };

        var showPinPad = function (type, model, callback, validator, note) {
                    var pinPadInstance = $modal.open({
                        templateUrl: 'pos/pos.numpad.tpl.html',
                        controller: 'PosNumpadCtrl',
                        animation: false,
                        windowClass: 'modal-numpad modal-fullscreen-transparent modal-right',
                        resolve: {
                            initial: function () {
                                return model;
                            },
                            type: function () {
                                return type;
                            },
                            note: function () {
                                return note;
                            }
                        },
                        backdrop: true
                    });
                    pinPadInstance.lucovaValidator = validator;
                    return pinPadInstance.result.then(function (value) {
                        if (callback) {
                            callback(value);
                        }
                    });
        };

        $scope.$watch(function () {
                return $scope.timeStamp.newTimestamp;
            }, function () {
                $timeout( function () {
                    $scope.opened.dataDate = false;
                }, 0);
        });

        $scope.opened = {};
        $scope.toggleDatePicker = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();

            if (!$scope.hasEditPrivilege) {
                $scope.showPrivilegeErrorModal();
                return;
            }

            var status = !!$scope.opened.dataDate;
            var newStatus = !status;
            $scope.opened.dataDate = newStatus;
        };
    }]);
};
