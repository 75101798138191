'use strict';

module.exports = function (posModule) {
    posModule.controller('PosCategory', [
        '$scope',
        'item',
        function (
            $scope,
            item) {
            $scope.item = item;
            $scope.selectedItem = null;
            $scope.quantity = 1;

            $scope.getImages = function () {
                if ($scope.item.children && $scope.item.children.length) {
                    const firstChild = $scope.item.children.find((child) => !!child) || {};
                    const menuItemImages = firstChild.menuItemImages;

                    if (menuItemImages && menuItemImages.length) {
                        menuItemImages.sort(function (a, b) {
                            return a.sortOrder - b.sortOrder;
                        });
                        return menuItemImages;
                    }
                }

                return [];
            };

            $scope.selectItem = function (item) {
                if ($scope.selectedItem !== item) {
                    $scope.selectedItem = item;
                    $scope.quantity = 1;
                } else {
                    $scope.selectedItem = null;
                }
            };

            $scope.increaseQuantity = function () {
                $scope.quantity += 1;
            };

            $scope.decreaseQuantity = function () {
                if ($scope.quantity > 1) {
                    $scope.quantity -= 1;
                }
            };

            $scope.confirmItem = function () {
                $scope.selectedItem.quantity = $scope.quantity;
                $scope.$close($scope.selectedItem);
            };
        }
    ]);
};
