'use strict';


const angular = require('angular');
const ngResource = require('angular-resource');

export default angular.module('freshideas.resources.locations', [ngResource])
    .service('Locations', [
        '$resource',
        'EnvConfig',
        'SharedDataService',
        'CompanyAttributesService',
        function (
        $resource,
        EnvConfig,
        SharedDataService,
        CompanyAttributesService) {
        var Location = $resource(EnvConfig.host + '/freshideas/web/location', {}, {
            'getDefault': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/default'},

            'getLocations': {method: 'GET', cache: false, url: EnvConfig.host + '/freshideas/web/location/list'},

            'get': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location'},
            'update': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location'},
            'add': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/location'},
            'deleteUser': {method: 'DELETE', url: EnvConfig.host + '/freshideas/web/location'},

            'getLocationPOSStations': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/posStation'},
            'addPOSStation': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location/posStation'},
            'updatePOSStation': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/location/posStation'},
            'updatePOSStationAttributes': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/location/posStation/attributes'},
            'deletePOSStation': {method: 'DELETE', url: EnvConfig.host + '/freshideas/web/location/posStation'},

            'getLocationCategories': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/menuCategory'},
            'addCategory': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location/menuCategory'},
            'updateCategory': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/location/menuCategory'},
            'deleteCategory': {method: 'DELETE', url: EnvConfig.host + '/freshideas/web/location/menuCategory'},

            'getLocationModifiers': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/menuModifier'},
            'addModifier': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location/menuModifier'},
            'updateModifier': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/location/menuModifier'},
            'deleteModifier': {method: 'DELETE', url: EnvConfig.host + '/freshideas/web/location/menuModifier'},

            'getParentItemsFromModifier': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/getParentItems', isArray: true},

            'addAssociations': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location/menuAssociation'},
            'removeAssociations': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/location/menuAssociation'},

            'createProductPages': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location/createProductPages'},

            'getLocationHierarchy1': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/menu/locationPOSMenusAll'},
            'menuGridV2': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/menu/gridV2'},
            'updateMenuMobile': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/menu/updateMenuMobile'},

            'getLocationPrinters': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/printers'},
            'addPrinter': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location/printers'},
            'updatePrinter': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/location/printers'},
            'deletePrinter': {method: 'DELETE', url: EnvConfig.host + '/freshideas/web/location/printers'},
            'listAllPrinters': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/listAllPrinters'},

            'getSecondaryPosPrinters': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/secondaryPosPrinters', isArray: true},

            'addServicePeriod': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location/servicePeriod'},
            'removeServicePeriod': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location/removeServicePeriod'},

            'addSuspend': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location/suspend'},
            'updateSuspend': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/location/suspend'},
            'getSuspend': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/suspend'},
            'deleteSuspend': {method: 'DELETE', url: EnvConfig.host + '/freshideas/web/location/suspend'},
            'lookupSuspend': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/suspend-lookup'},

            'addKdsOrder': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location/kds'},
            'updateKdsOrder': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/location/kds'},
            'getKdsOrders': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/kds'},

            'unassignPrinterFromItems': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location/printers/unassign'},
            'updateLocationAttributes': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/location/attributes'},

            'itemSearch': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/item-search'},
            'getAssignedPosStation': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/assignedPosStation'},
            'getMenusByOrganization': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/menu/availableMenus', isArray: true},

            'sendPushMessage': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location/push-message'},
            'confirmPush': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location/confirm-push-message'},
            'getTopItems': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/topItems'},
            'getMenuItemsToCache': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/menuItemsToCache'},

            'getPendingFiitMenuImport': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/fiit-menu-import'},
            'enqueueFiitMenuImportJob': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location/fiit-menu-import'},

            'getMenuPeriods': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/menu/listMenuPeriods', isArray: true},
            'getMenuPeriod': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/menu/menuPeriod'},
            'addMenuPeriod': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/location/menu/addMenuPeriod'},
            'updateMenuPeriod': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location/menu/updateMenuPeriod'},
            'updateSrmDevice': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location/srm'},
            'getLocationSrmDevices': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/srm', isArray: true},
            'deleteSrmDevice': {method: 'DELETE', url: EnvConfig.host + '/freshideas/web/location/srm'},
            'toggleKitchenPrintingForAllItems': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location/toggleKitchenPrintingForAllItems'},
            'createOrUpdateFiitEvent': {method: 'POST', url: EnvConfig.host + '/freshideas/web/location/fiit-event'},
            'getFiitEvents': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/fiit-event', isArray: true},
            'deleteFiitEvent': {method: 'DELETE', url: EnvConfig.host + '/freshideas/web/location/fiit-event'},
            'getPastFiitEvents': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/past-fiit-event'},
            'getFiitEventResults': {method: 'GET', url: EnvConfig.host + '/freshideas/web/location/fiit-event-result', isArray: true},
        });

        // NOTE: This menu cache is being shared in products and in POS screen. Be careful when working with this cache
        // to not contaminate the data. Making a clone on individual item if necessary.
        Location.getLocationHierarchy = function (params, useCache = false, checkForMenuPeriod = true) {
            if (checkForMenuPeriod && CompanyAttributesService.hasMenuPeriodsEnabled()) {

                //  When changing any function in CommonOfflineCache.getCurrentMenuPeriod()
                // please make the changes here as well.

                var currentMenuPeriod = JSON.parse(localStorage.getItem('menuPeriod'));
                params.menuPeriodId = (currentMenuPeriod) ? currentMenuPeriod.menuPeriodId : undefined;

                if (!params.menuPeriodId) {
                    delete params.menuPeriodId;
                }
            }

            const ordered = {};
            Object.keys(params).sort().forEach(function (key) {
              ordered[key] = params[key];
            });
            let paramStr = jQuery.param(ordered).split('&');
            let menuIdKey = (paramStr.length > 1) ? paramStr[1] : paramStr[0];
            let menuToCache = {};

            if (!params.languageId || params.languageId === 0) {
                params.languageId = SharedDataService.preferredLanguage.languageId;
            }

            if (useCache && SharedDataService.cachedProducts[menuIdKey] && SharedDataService.cachedProductsTimestamp[menuIdKey]) {
                params.cacheTimestamp = SharedDataService.cachedProductsTimestamp[menuIdKey][params.languageId];
            }

            return Location.getLocationHierarchy1(params).$promise.then(function (response) {
                if (!response.useCache) {
                    let cachedProducts = {};
                    let cachedProductsTimestamp = {};

                    cachedProducts[menuIdKey] = {};
                    cachedProducts[menuIdKey][params.languageId] = response;

                    cachedProductsTimestamp[menuIdKey] = {};
                    cachedProductsTimestamp[menuIdKey][params.languageId] = response.newCacheTimestamp;

                    menuToCache.cachedProducts = cachedProducts;
                    menuToCache.cachedProductsTimestamp = cachedProductsTimestamp;

                    SharedDataService.addToCachedProducts(menuToCache);
                }

                // NOTE: returning the original cache instead of a cloned copy to optimize the speed
                // of the call (a deep clone can take hundreds of ms).
                var cache = SharedDataService.cachedProducts[menuIdKey][params.languageId];
                return cache;
            });
        };

        /**
        **  Function to cache menu Items using a new Endpoints 'menuItemsToCache'
        **  This gets the top 300 categories transacted at the location and iterates over
        **  all of them and process them
        **/
        Location.cacheMenuItemsForSearch = function (params) {
            return Location.getMenuItemsToCache(params).$promise.then(function (response) {
                if (response.posMenuItemEntries && response.posMenuItemEntries[0]
                    && response.posMenuItemEntries[0].entries) {
                    _.each(response.posMenuItemEntries[0].entries, function (entry) {
                        Location.processItem(entry);
                    });
                }
            });
        };

        /**
        **  Function to parse an item and store it in Memory against an item's upc code.
        **  If an item does not have a upc code, it will not be stored.
        **  Every item/category is stored with all of its children.
        **  We also parse through all childrens and store them as well if they have a upc
        **/
        Location.processItem = function (entry) {
            var upc = entry.upc;
            if (upc) {
                if (!SharedDataService.menuItemsMap[upc] && Object.keys(SharedDataService.menuItemsMap).length >= 4000) {
                    return;
                }

                SharedDataService.menuItemsMap[upc] = entry;
                if (entry.children) {
                    _.each(entry.children, function (child) {
                        Location.processItem(child);
                    });
                }
            }

        };

        return Location;
    }]);
