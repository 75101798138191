'use strict';

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosOrderDiscountCtrl', [
        '$scope',
        'adjustments',
        'showPinPad',
        'Security',
        'PosAlertService',
        'CashierShift',
        'CurrentSession',
        'CompanyAttributesService',
        function (
            $scope,
            adjustments,
            showPinPad,
            Security,
            PosAlertService,
            CashierShift,
            CurrentSession,
            CompanyAttributesService) {

            $scope.isLoading = {};

            $scope.percentDiscountType = {
                type: 'percentage',
                field: 'percentDiscount',
                option: 'smb.pos.discount.percentage.option',
                label: 'smb.pos.discount.percentage.label',
                value: adjustments.percentDiscount,
                checkIfEnabled: true,
                dataCy: 'cartLevelPercentDiscountSection' // Used by cypress for testing. Please do not change
            };
            $scope.dollarDiscountType = {
                type: 'dollar',
                field: 'dollarDiscount',
                option: 'smb.pos.discount.dollar.option',
                label: 'smb.pos.discount.dollar.label',
                value: adjustments.dollarDiscount,
                checkIfEnabled: true,
                dataCy: 'cartLevelDollarDiscountSection' // Used by cypress for testing. Please do not change
            };
            $scope.labelledDiscountType = {
                type: 'labelled',
                field: 'labelledDiscount',
                option: 'smb.pos.discount.labelled.option',
                label: 'smb.pos.discount.dollar.label',
                value: (adjustments.labelledDiscounts) ? adjustments.labelledDiscounts.value : 0,
                checkIfEnabled: false,
                dataCy: 'cartLevelLabelledDiscountSection' // Used by cypress for testing. Please do not change
            };

            $scope.discountTypes = [$scope.labelledDiscountType, $scope.percentDiscountType, $scope.dollarDiscountType];

            $scope.selectDiscountType = function (discountType) {
                $scope.currentDiscountType = discountType;
            };

            var percentDiscountCallback = function (editedValue) {
                $scope.percentDiscountType.value = parseFloat(editedValue);
                $scope.resetOtherDiscounts();
            };
            $scope.editPercentDiscount = function () {
                var validator = function (oldVal, newVal) {
                    if (parseInt(oldVal) + '' + parseInt(newVal) > 100) {
                        return false;
                    }
                    return true;
                };

                var note = ''; /* ($scope.canApplyTransactionPercentageDiscount())
                            ? ''
                            : 'smb.pos.balance.discount.itemDiscountAlreadyApplied'; */

                return showPinPad(
                    'percentage',
                    $scope.percentDiscountType.value,
                    checkIfAuthenticationRequired,
                    validator,
                    note);
            };

            $scope.isDiscountEnabled = function () {
                return CompanyAttributesService.enableTransactionLevelOpenDiscount();
            };

            var dollarDiscountCallback = function (editedValue) {
                $scope.dollarDiscountType.value = parseFloat(editedValue);
                $scope.resetOtherDiscounts();
            };
            $scope.editDollarDiscount = function () {
                return showPinPad(
                    'currency',
                    $scope.dollarDiscountType.value,
                    checkIfAuthenticationRequired);
            };

            var checkIfAuthenticationRequired = function (editedValue) {
                var params = {};
                params.requestedPermission = 'pos:cashier_discount_limit';
                params.callback = function (pinResponse) {
                    if ($scope.currentDiscountType.type == 'dollar') {
                        dollarDiscountCallback(editedValue);
                    } else {
                        percentDiscountCallback(editedValue);
                    }
                };
                params.errorCallback = function (error) {
                    var message = '';
                    if (error) {
                        var exception = error.exception || {};
                        if (exception.appCode === 412) {
                            message = 'general.error.cashier-discout-fail-invalid-manager-pin.msg';
                        } else {
                            message = exception.message || '';
                        }
                        PosAlertService.showAlertByName('cashier-discout-fail', {
                            message: message
                        });
                    }
                };
                params.forceCheckIfManagerLogin = true;
                params.message = 'general.popup.manager-pin.ttl';
                var isManagerOverrideRequired = false;
                if ($scope.isDiscountLimitActive) {
                    if ($scope.currentDiscountType.type == 'percentage') {
                        if (parseFloat(editedValue) > parseFloat($scope.discountLimit)) {
                            isManagerOverrideRequired = true;
                        }
                    } else if ($scope.currentDiscountType.type == 'dollar') {
                        isManagerOverrideRequired = true;
                    }

                }

                if (isManagerOverrideRequired) {
                    $scope.$emit('PincodeAuthentication:Required', params);
                } else {
                   if ($scope.currentDiscountType.type == 'dollar') {
                        dollarDiscountCallback(editedValue);
                    } else {
                        percentDiscountCallback(editedValue);
                    }
                }
            };


            var checkIfPincodeRequired = function (discount) {
                var params = {};
                params.requestedPermission = 'pos:discount_pincode';
                params.callback = function (pinResponse) {
                    if (pinResponse && pinResponse.user) {
                        if (discount.permissions && pinResponse.user.permission != 'SITEADMIN') {
                            var role = discount.permissions.find(function (perm) {
                                return perm.roleId == pinResponse.user.roleId;
                            });

                            if ((!role) || !role.isEnabled) {
                                params.errorCallback({exception: {message: 'general.error.discout-fail-no-access.msg'}});
                                return;
                            }
                        }
                        discount.userId = pinResponse.user.userId;
                    }
                };
                params.errorCallback = function (error) {
                    var message = '';
                    if (error) {
                        var exception = error.exception || {};
                        if (exception.appCode === 412) {
                            /* var managerAuthenticationRequired = Security.isManagerOverrideRequired(params.requestedPermission);
                            if (managerAuthenticationRequired) {
                                message = 'general.error.cashier-discout-fail-invalid-manager-pin.msg';
                            } else {
                                message = 'general.error.cashier-discout-fail-invalid-pin.msg';
                            }*/
                            message = 'general.error.cashier-discout-fail-invalid-pin.msg';
                        } else {
                            message = exception.message || '';
                        }
                        PosAlertService.showAlertByName('cashier-discout-fail', {
                            message: message
                        });
                    }
                    discount.isSelected = false;
                };

                if (discount.pinRequired) {
                    $scope.$emit('PincodeAuthentication:Required', params);

                    /* if ($scope.currentDiscountType.type == 'percentage') {
                        if (parseFloat(editedValue) > parseFloat($scope.discountLimit)) {
                            isManagerOverrideRequired = true;
                        }
                    } else if ($scope.currentDiscountType.type == 'dollar') {
                        isManagerOverrideRequired = true;
                    }*/
                } else {
                    params.callback();
                }

                /* if (isManagerOverrideRequired) {
                    $scope.$emit('PincodeAuthentication:Required', params);
                } else {
                   if ($scope.currentDiscountType.type == 'dollar') {
                        dollarDiscountCallback(editedValue);
                    } else {
                        percentDiscountCallback(editedValue);
                    }
                }*/
            };

            $scope.applyDiscount = function () {
                $scope.labelledDiscountType.value = 0;
                $scope.labelledDiscountType.selectedDiscounts = [];
                if ($scope.currentDiscountType && $scope.currentDiscountType.type == 'labelled') {
                    _.each($scope.labelledDiscounts, function (discount) {
                        if (discount.isSelected) {
                            $scope.labelledDiscountType.value += discount.discountPercentage;
                            var discountObj = {};
                            discountObj.id = discount.id;
                            discountObj.userId = discount.userId;
                            discountObj.discountName = discount.discountName;
                            $scope.labelledDiscountType.selectedDiscounts.push(discountObj);
                        }
                    });
                }

                var labelledDiscountObj = {
                    value: ($scope.currentDiscountType.type === 'labelled') ? $scope.labelledDiscountType.value : 0,
                    selectedDiscounts: ($scope.labelledDiscountType.selectedDiscounts.length == 0) ? undefined : $scope.labelledDiscountType.selectedDiscounts
                };

                var returnValue = {
                    percentDiscount: ($scope.currentDiscountType.type === 'percentage') ? $scope.percentDiscountType.value : 0,
                    dollarDiscount: ($scope.currentDiscountType.type === 'dollar') ? $scope.dollarDiscountType.value : 0,
                    labelledDiscount: labelledDiscountObj
                };

                $scope.$close(returnValue);
            };

            $scope.resetOtherDiscounts = function () {
                if ($scope.currentDiscountType.type != 'percentage') {
                    $scope.percentDiscountType.value = adjustments.percentDiscount;
                }
                if ($scope.currentDiscountType.type != 'dollar') {
                    $scope.dollarDiscountType.value = adjustments.dollarDiscount;
                }
                if ($scope.currentDiscountType.type != 'labelled') {
                    $scope.labelledDiscountType.value = 0;
                    delete $scope.labelledDiscountType.selectedDiscounts;
                     _.each($scope.labelledDiscounts, function (labelledDiscount) {
                        labelledDiscount.isSelected = false;
                    });
                }
            };

            $scope.resetAllDiscounts = function () {
                $scope.percentDiscountType.value = adjustments.percentDiscount;
                $scope.dollarDiscountType.value = adjustments.dollarDiscount;
                $scope.labelledDiscountType.value = 0;
                _.each($scope.labelledDiscounts, function (labelledDiscount) {
                    labelledDiscount.isSelected = false;
                });

                $scope.currentDiscountType.value = 0;
            };

            $scope.init = function () {
                $scope.currentDiscountType = $scope.labelledDiscountType;
                $scope.isDiscountLimitActive = (Security.getUser().company.attributes.other__activate_cashier_discount_limit == 'true') ? true : false;
                $scope.discountLimit = Security.getUser().company.attributes.other__cashier_discount_limit;
                $scope.loadDiscounts();
            };

            $scope.selectDiscount = function (discount) {
                $scope.resetOtherDiscounts();
                if (!discount.isSelected) {
                    _.each($scope.labelledDiscounts, function (labelledDiscount) {
                        if (labelledDiscount.id == discount.id) {
                            discount.isSelected = true;
                        } else {
                            labelledDiscount.isSelected = false;
                            delete labelledDiscount.userId;
                        }
                    });
                    checkIfPincodeRequired(discount);
                }
            };


            $scope.deselectDiscount = function (discount) {
                if (discount.isSelected) {
                    discount.isSelected = false;
                }
            };

            $scope.loadDiscounts = function () {
                $scope.isLoading.discounts = true;
                return CashierShift.getLabelledDiscounts({companyId: CurrentSession.getCompany().companyId}, function (response) {
                    var discounts = _.filter(response, {transactionLevel: true, internalName: null});
                    discounts.sort(function (a, b) {
                        if (a.discountName < b.discountName) {
                            return -1;
                        }

                        if (b.discountName < a.discountName) {
                            return 1;
                        }

                        return 0;
                    });
                    var permission = 'Site Owner, ';
                    _.each(discounts, function (discount) {
                        discount.status = (discount.active) ? 'ACTIVE' : 'INACTIVE';
                        if (discount.permissionRoles) {
                            discount.permissionRoles = permission + discount.permissionRoles;
                        } else {
                            discount.permissionRoles = permission.substr(0, permission.length - 2);
                        }
                    });

                    $scope.labelledDiscounts = discounts;
                    if (adjustments.labelledDiscounts) {
                        _.each(adjustments.labelledDiscounts.selectedDiscounts, function (labelledDiscount) {
                            var discountToModify = $scope.labelledDiscounts.find(function (discount) {
                                return discount.id === labelledDiscount.id;
                            });

                            if (discountToModify) {
                                discountToModify.isSelected = true;
                                if (labelledDiscount.userId) {
                                    discountToModify.userId = labelledDiscount.userId;
                                }
                            }
                        });
                    }
                    $scope.isLoading.discounts = false;
                }, function (error) {
                    $scope.isLoading.discounts = false;
                });
            };
            $scope.init();
        }
    ]);

};
