'use strict';

const moment = require('moment');

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosStartPatronsCtrl', [
        '$scope',
        '$rootScope',
        '$q',
        '$log',
        '$modal',
        '$translate',
        '$timeout',
        'Security',
        'PosStatusService',
        'SmbPosService',
        'LucovaWebSocket',
        'PosAlertService',
        'CashierShift',
        'Lucova',
        'Locations',
        'Pure',
        'CurrentSession',
        'KioskService',
        'KioskModalService',
        'GatewayFiit',
        'CommonOfflineCache',
        'CompanyAttributesService',
        'AudioService',
        'SharedDataService',
        'HelpService',
        function (
            $scope,
            $rootScope,
            $q,
            $log,
            $modal,
            $translate,
            $timeout,
            Security,
            PosStatusService,
            SmbPosService,
            LucovaWebSocket,
            PosAlertService,
            CashierShift,
            Lucova,
            Locations,
            Pure,
            CurrentSession,
            KioskService,
            KioskModalService,
            GatewayFiit,
            CommonOfflineCache,
            CompanyAttributesService,
            AudioService,
            SharedDataService,
            HelpService) {

            let lastQuickChargeTime = Date.now();
            const QUICK_CHARGE_COOLDOWN = 1000; // milliseconds

            $scope.isCampus = CurrentSession.isCampus();
            $scope.showLanguageSelection = false;

            if (KioskService.isKiosk()) {

                // let kioskImageUrl = CompanyAttributesService.getKioskImageUrl();
                let kioskLanguageSelection = CompanyAttributesService.hasKioskLanguageSelection();

                let secondaryDisplaySettings = CurrentSession.getCompany().secondaryDisplaySettings;
                let secondaryDisplaySlides = secondaryDisplaySettings ? secondaryDisplaySettings.slides.map((f) => f.resourceUrl) : [];

                let kioskBackgrounds = CurrentSession.getCompany().secondaryDisplaySettings.kioskBackgrounds;
                let dayIndex = moment().day(); // [0-7] (Sunday-Saturday)

                // consider first kioskBackground from list where current day is active
                let background = _.find(kioskBackgrounds, function (background) {
                    let active = (background.dayActive[dayIndex] === '1') ? true : false;

                    return active;
                });

                let kioskImageUrl = (!background) ? '' : background.resourceUrl;

                if ((!kioskImageUrl || kioskImageUrl.length === 0) && secondaryDisplaySlides.length) {
                    kioskImageUrl = secondaryDisplaySlides[0];
                }

                if (kioskLanguageSelection) {
                    $scope.showLanguageSelection = true;
                }

                $scope.kioskBackgroundImage = 'url(' + kioskImageUrl + ')';
            }

            var navBarIconTimeout;
            var navBarDoubleClickTimeout;
            const doubleClickIntervalMillis = 400;

            $scope.showNavBarIcon = false;

            $scope.openLanguageSelectionModal = function (event) {
                event.preventDefault();
                event.stopPropagation();

                var modalInstance = $modal.open({
                    templateUrl: 'pos/smb/templates/kiosk.pos.order.language.selection.tpl.html',
                    animation: false,
                    backdrop: true,
                    controller: 'KioskLanguageSelectionCtrl',
                    windowClass: 'modal-80',
                    keyboard: false,
                    resolve: {}});

                modalInstance.result.then(function (response) {
                    // $scope.selectPatron({'guest': true});
                });
            };

            $scope.revealNavBarBtn = function (event) {
                event.stopPropagation();

                // Check if click is outside the 350 ms double click tigger interval
                // require a triple click to reveal the nav icon
                if (!navBarDoubleClickTimeout
                    || Date.now() - navBarDoubleClickTimeout > doubleClickIntervalMillis) {

                    navBarDoubleClickTimeout = Date.now();
                    return;
                }

                navBarDoubleClickTimeout = undefined;

                $scope.showNavBarIcon = true;
                navBarIconTimeout = $timeout(function () {
                    $scope.showNavBarIcon = false;
                }, 5000);
            };

            $scope.openKioskNavBar = function (event) {
                event.stopPropagation();
                $scope.$emit('kioskNavbar:open', {enableNavBar: true});
            };

            $scope.mobileAppName = CurrentSession.getOrganizationAppName();

            $scope.toggleShowScanAppMsg = function ($event) {
                $event.stopPropagation();
                $scope.showScanAppMsg = !$scope.showScanAppMsg;
            };

            $scope.PosStatusService = PosStatusService;
            $scope.suspendEnabled = Security.getUser().company.attributes.suspend_enabled === 'true';
            $scope.mobileOrdersEnabled = Security.getUser().company.hasPreorder;
            $scope.openDrawerDisabled = Security.getUser().company.attributes.other__hide_open_drawer_button === 'true';

            $scope.$watch(function () {
                return Security.getUser().company.attributes.kds_enabled;
            }, function (value) {
                $scope.suspendEnabled = Security.getUser().company.attributes.suspend_enabled === 'true';
            });

            var patronTypeAll = {
                name: 'smb.pos.patron.type.all',
                placeholder: 'All Customers',
                value: 'all',
            };

            $scope.getMobileOrderHistoryCount = function () {
                var patrons = $scope.patrons || {};
                if (patrons.hasOwnProperty('preorder')) {
                    return _.filter(patrons['preorder'], (patron) => {
                        var preorder = patron.lucovaUser.preorder;
                        preorder = _.filter(preorder, (order) => {
                            return order.status === 'cancelled' || order.fulfilled;
                        });
                        if (preorder.length) {
                            return patron;
                        }
                    }).length;
                } else {
                    return 0;
                }
            };

            $scope.getSuspendedCount = function () {
                var count = 0;
                for (var i = 0; i < $scope.suspendByDate.length; i++) {
                    count += $scope.suspendByDate[i].suspendList.length;
                }
                return count;
            };

            $scope.constants = {
                patronTypes: [{
                    name: 'smb.pos.patron.type.checkedIn',
                    placeholder: 'Start Order',
                    value: 'checkedIn',
                    show: true
                }, {
                    name: 'smb.pos.patron.type.mobileOrderHistory',
                    secondary: 0,
                    placeholder: 'Pre-order',
                    value: 'preorder',
                    show: $scope.mobileOrdersEnabled
                }, {
                    name: 'smb.pos.patron.type.suspended',
                    secondary: 0,
                    placeholder: 'Saved',
                    value: 'suspended',
                    show: $scope.suspendEnabled
                },
                    patronTypeAll]
            };

            // Watch quantities on suspended orders to update bracketed quantity accordingly
            $scope.$watch(function () {
                return $scope.getSuspendedCount();
            }, function (value) {
                $scope.constants.patronTypes[2].secondary = value;
            });

            // Watch quantities on mobile order history to update bracketed quantity accordingly
            $scope.$watch(function () {
                return $scope.getMobileOrderHistoryCount();
            }, function (value) {
                $scope.constants.patronTypes[1].secondary = value;
            });

            // To accomodate both FIIT-only patrons and Lucova-only users in the
            // same list, the new POS will be using the following structure
            // for each patron:
            // {
            //     fiitPatron: {},
            //     lucovaUser: {}, (determines whether to use Lucova transaction workflow)
            //     fullName: (use SmbPosService.transformPatron to parse this),
            //     loyalty: (loyalty info),
            //     coupons: (shown in loyalty page, might move into `loyalty`),
            //     topItems: (shown in loyalty page, might move into `loyalty`)
            // }
            $scope.filteredPatrons = [];
            $scope.showSuspended = false;
            $scope.$translate = $translate;

            $scope.refreshPatrons = function () {
                SmbPosService.refreshLucovaUsers().then(function () {
                    $scope.filterPatrons();
                });
            };

            var patronSelectionPromiseResolve = function (patronSelectionPromise, patron, isQuickChargeApplicable = false) {
                patronSelectionPromise.then(function (fiitPatron) {
                    var patronObj = {};

                    patronObj.fiitPatron = fiitPatron;
                    if (patron.lucovaUser) {
                        patronObj.lucovaUser = angular.copy(patron.lucovaUser);

                        if (patron.lucovaUser.nownGiftCards
                            && patron.lucovaUser.nownGiftCards.length > 0) {
                            patronObj.lucovaUser.nownGiftCards = patron.lucovaUser.nownGiftCards;
                        }
                    }

                    if (patron.fiitMpsAccount) {
                        patronObj.fiitMpsAccount = patron.fiitMpsAccount;
                    }

                    patronObj.isNearby = patron.isNearby;

                    patronObj = SmbPosService.transformPatron(patronObj);
                    startOrderOrPopup(patronObj, isQuickChargeApplicable);
                }).catch(function (error) {
                    startOrderOrPopup(patron, isQuickChargeApplicable);
                });
            };

            var shouldOpenLoyaltyPopup = function (patron, isQuickChargeApplicable) {
                if (KioskService.isKiosk()) {
                    return false;
                }
                var doesPatronHaveLoyalty = hasLoyalty(patron);
                var isSuspended = !!(patron && patron.suspended);

                // Open the popup only when
                // - the patron has loyalty
                // - the order is not a held order (to prevent both the loyalty popup and held order popup to show at the same time)
                return doesPatronHaveLoyalty && !isSuspended
                    && (!isQuickChargeApplicable || (isQuickChargeApplicable && !isQuickChargeEnabled()));
            };

            var hasLoyalty = function (patron) {
                return !!(patron
                    && (patron.fiitPatron || patron.lucovaUser || patron.fiitMpsAccount));
            };

            var startOrderOrPopup = function (patronObj, isQuickChargeApplicable = false) {
                if (isQuickChargeApplicable && isQuickChargeEnabled()
                    && Date.now() - lastQuickChargeTime > QUICK_CHARGE_COOLDOWN) {
                    lastQuickChargeTime = Date.now();
                    runQuickChargeTransaction(patronObj);
                } else if (shouldOpenLoyaltyPopup(patronObj)) {
                    $scope.openCustomerLoyalty(patronObj);
                } else {
                    startOrderWithPatron(patronObj, null);
                }
            };

            $scope.openCustomerLoyalty = function (patron) {
                var modalInstance = $modal.open({
                    templateUrl: 'pos/smb/templates/pos.loyalty.tpl.html',
                    animation: false,
                    backdrop: true,
                    controller: 'SmbPosLoyaltyCtrl',
                    windowClass: 'smb-pos smb-pos__loyalty-modal',
                    resolve: {
                        patron: function () {
                            return patron;
                        },
                        location: function () {
                            return location;
                        },
                        goHome: function () {
                            return function () { };
                        },
                        adjustments: function () {
                            return {};
                        }
                    }
                });

                modalInstance.result.then(function (response) {
                    startOrderWithPatron(patron, response);
                    $rootScope.$broadcast('pos::close-patron-search');
                });
            };

            $scope.selectPatron = function (patron, isQuickChargeApplicable = false) {
                HelpService.setQueryText('transaction');
                $scope.$emit('kioskNavbar:open', {enableNavBar: false});
                if ($scope.currentPatronType.value === 'preorder') {
                    $scope.selectMobileOrder(patron, 'HISTORY');
                } else {
                    var patronSelectionPromise;
                    var deferred;
                    if (patron.lucovaUser
                        && patron.lucovaUser.nown
                        && patron.lucovaUser.nown.nown_gift_nums
                        && patron.lucovaUser.nown.nown_gift_nums.length > 0) {

                        // Gift Cards
                        // Ideally we should send one request to get all the gift cards but since we do not expect a
                        // customer to have more than 2-3 giftcards it is acceptable here to send a request per gift card
                        var giftCardPromises = [];
                        for (var i = 0; i < patron.lucovaUser.nown.nown_gift_nums.length; i++) {
                            patron.lucovaUser.nownGiftCards = [];
                            giftCardPromises.push(
                                CashierShift.lookupGiftCard({
                                    code: patron.lucovaUser.nown.nown_gift_nums[i],
                                    currencyId: CurrentSession.getCompany().baseCurrencyId,
                                    cashierShiftId: SmbPosService.shift.cashierShiftId
                                }).$promise
                            );
                        }

                        Promise.all(giftCardPromises).then(function (giftCards) {
                            for (var i = 0; i < giftCards.length; i++) {
                                if (giftCards[i].id) {
                                    patron.lucovaUser.nownGiftCards.push(giftCards[i]);
                                }
                            }

                            // TODO: Rethinking this because all Lucova users should theoretically have
                            // fiitPatron info by now so the linking might not be necessary anymore
                            patronSelectionPromise = SmbPosService.linkFiitPatron(patron.lucovaUser);
                            patronSelectionPromiseResolve(patronSelectionPromise, patron, isQuickChargeApplicable);
                        }, function (error) {
                            console.error(error);
                        });
                    } else if (patron.lucovaUser) {
                        patronSelectionPromise = SmbPosService.linkFiitPatron(patron.lucovaUser);
                        patronSelectionPromiseResolve(patronSelectionPromise, patron, isQuickChargeApplicable);
                    } else if (patron.fiitPatron) {
                        delete patron.lucovaUser;

                        // Using `$q.defer` instead of `Promise` to trigger $scope.digest
                        // for any changes outside of $scope cycle.
                        deferred = $q.defer();
                        patronSelectionPromise = deferred.promise;
                        deferred.resolve(patron.fiitPatron);
                        patronSelectionPromiseResolve(patronSelectionPromise, patron, isQuickChargeApplicable);
                    } else {
                        /**
                        *** Commented By Akash Mehta on 27th Mar 2020
                        *** NOTE : `fiitMpsAccount` field is used to store the meal Plan account details from FIIT.
                        *** Just a note to the next developer !! We did not add a new check for fiitMpsAccount
                        *** above as the code below does the exact behaviour required when we scan the physical meal card.
                        *** When someone scans a physical FIIT meal card, the patron obj in this case does not need any transformation.
                        *** Hence, we just proceed ahead by rejecting the promise, which inturns proceeds ahead with starting the order.
                        *** If the above mentioned flow changes in this code block, please ensure that you create an explicit check for
                        *** the field `fiitMpsAccount` with the currently existing logic.
                        **/
                        deferred = $q.defer();
                        patronSelectionPromise = deferred.promise;
                        deferred.reject({});
                        patronSelectionPromiseResolve(patronSelectionPromise, patron, isQuickChargeApplicable);
                    }
                }
            };

            $scope.beginGuestTransaction = () => {
                let patron = {guest: true};
                HelpService.setQueryText('transaction');
                let order = SmbPosService.startOrder({
                    patron: patron
                });
                $scope.setSuspendedOrder({});
                showOrderScreen(order);
            };

            $scope.isNameEmpty = function (patron) {
                let name = patron.fiitPatron.firstName + ' ' + patron.fiitPatron.lastName;
                return /\S/.test(name);
            };

            function requestPinCode () {
                return new Promise((resolve, reject) => {
                    if (CompanyAttributesService.enhanceSecurityMeasures() && CompanyAttributesService.requestTransactionPin()) {
                        const params = {
                            callback: resolve,
                            errorCallback: reject,
                            requestedPermission: '',
                            message: 'smb.transaction.beginWithPincode',
                            verifyAllUserPinsForCompany: true,
                            forceAuthenticate: true
                        };

                        $scope.$emit('PincodeAuthentication:Required', params);
                    } else {
                        resolve({});
                    }
                });
            }

            function showOrderScreen (order, {adjustments} = {}) {
                requestPinCode()
                    .then(({user}) => {
                        if (user && user.userId) {
                            order.employee.employeeId = user.userId;
                        }
                        $scope.setCurrentOrder(order, {adjustments: adjustments});
                        $scope.switchView('order');
                    })
                    .catch((error) => {
                        if (error) {
                            const onErrorModalClosed = () => {
                                // doing this so that the pinpad stays up if the entered pin code is wrong
                                showOrderScreen(order, {adjustments: adjustments});
                            };
                            PosAlertService.showAlertByName(
                                'pincode-fail', {
                                modalCallback: onErrorModalClosed,
                                dismissModalCallback: onErrorModalClosed
                            });
                        }
                    });
            }

            var startOrderWithPatron = function (patron, customerLoyaltyModalResponse) {
                SmbPosService.setCustomerLoyaltyModalResponse(customerLoyaltyModalResponse);
                var order = SmbPosService.startOrder({
                    patron: angular.copy(patron)
                });
                $scope.setSuspendedOrder({});
                showOrderScreen(order);
            };

            $scope.unsuspendOrder = async function (suspend) {
                var patronObj, foundFiitPatron;

                if (suspend.patronKey) {
                    foundFiitPatron = await SmbPosService.fetchPatronByPatronKey(suspend.patronKey);
                }

                if (foundFiitPatron) {
                    patronObj = foundFiitPatron;
                    patronObj.suspended = true;
                } else {
                    patronObj = {
                        guest: true,
                        suspended: true
                    };
                }

                var order = SmbPosService.startOrder({
                    patron: patronObj
                });
                order.fullReceipt = JSON.parse(suspend.receiptJson);
                // Track the order name for printing the receipt
                order.orderName = suspend.orderName;
                let extraDataObj = {};
                if (suspend.extraData) {
                    extraDataObj = JSON.parse(suspend.extraData);
                }
                for (var fullReceiptItem of order.fullReceipt) {
                    fullReceiptItem._uuid = Pure.generateUuid();
                    fullReceiptItem._timestamp = Date.now();
                }

                $scope.setSuspendedOrder(suspend);
                showOrderScreen(order, extraDataObj);
            };

            const runDeleteSuspendOrder = (suspendList, suspend) => {
                Locations.deleteSuspend({
                    locationId: suspend.locationId,
                    suspendId: suspend.suspendId
                });

                var suspendIndex = _.findIndex(suspendList, {
                    suspendId: suspend.suspendId
                });
                suspendList.splice(suspendIndex, 1);

                if (suspendList.length === 0) {
                    var suspendListIndex = _.findIndex(
                        $scope.suspendByDate, {
                        date: suspendList.date
                    });
                    $scope.suspendByDate.splice(suspendListIndex, 1);
                }
            };

            $scope.deleteSuspendedOrder = function (event, date, suspendList, suspend) {
                event.stopPropagation();
                PosAlertService.showAlertByName('delete-suspend', {
                    'modalCallback': function () {
                        // Using open drawer permission since this is the same level required for
                        // deleting an order. Could add further permissions to add flexibility
                        var requestedPermission = 'pos:opendrawer';
                        var params = {
                            callback: function (pinObj) {
                                runDeleteSuspendOrder(suspendList, suspend);
                            },
                            errorCallback: function (error) {
                            },
                            requestedPermission: requestedPermission,
                            message: 'smb.pos.deleteorder.description'
                        };

                        $scope.$emit('PincodeAuthentication:Required', params);
                    }
                });
            };

            $scope.currentPatronType = {};
            var previousPatronType = {};

            $scope.patronSort = {
                field: 'lucovaUser.rssi',
                sortASC: false
            };

            $scope.setPatronType = function (patronType) {
                // Show suspended
                if (patronType.value === 'suspended') {
                    SmbPosService.loadSuspend();
                    $scope.currentPatronType = patronType;
                    $scope.showSuspended = true;
                    return;
                }

                // Disable showSuspended
                $scope.showSuspended = false;
                $scope.isPatronListLoading = true;
                $scope.currentPatronType = patronType;

                SmbPosService.clearPreorder();

                if (patronType.value === 'checkedIn') {
                    $scope.patronSort.field = 'lucovaUser.rssi';
                    $scope.patronSort.sortASC = true;

                    previousPatronType = $scope.currentPatronType;
                    $scope.filterPatrons(patronType);
                } else {
                    $scope.patronSort.field = 'lucovaUser.preorder[0].transaction_opened';
                    $scope.patronSort.sortASC = true;

                    previousPatronType = $scope.currentPatronType;
                    $scope.filterPatrons(patronType);
                }
            };

            $scope.getPatronPhoto = function (photoUrl) {
                return SmbPosService.getPatronPhoto(photoUrl);
            };

            $scope.isGuestTransaction = function (user) {
                return SmbPosService.isGuestTransaction(user);
            };

            $scope.patronSearch = {
                searchString: ''
            };

            $scope.clearSearchPatrons = function () {
                $scope.patronSearch.searchString = '';
                $scope.searchPatrons();
            };

            let searchTimer;
            $scope.searchPatrons = function () {
                if (!$scope.patronSearch.searchString) {
                    $scope.filteredPatrons.length = 0;
                    $scope.setPatronType(previousPatronType);
                } else {
                    if (searchTimer) {
                        $timeout.cancel(searchTimer);
                    }
                    // At least 3 characters to begin search
                    if ($scope.patronSearch.searchString && $scope.patronSearch.searchString.length < 3) {
                        // The timeout ensures that if the intended search string is
                        // actually less than 3 characters the lookup will still be executed
                        searchTimer = $timeout(() => {
                            if ($scope.patronSearch.searchString.length) {
                                $scope.filterAllPatrons();
                            }
                        }, 1000);
                    } else {
                        $scope.filterAllPatrons();
                    }
                }
            };

            $scope.loading = false;

            // Commented By Akash Mehta on November 9 2020
            // We should add a better check in the future. Currently its working as expected
            var isQuickChargeEnabled = function () {
                return !!(GatewayFiit.isEnabled() && $scope.quickChargeSessionStatus.active);
            };

            $scope.isQuickChargeSet = function () {
                var menuPeriod = CommonOfflineCache.getCurrentMenuPeriod();
                return !!(menuPeriod && menuPeriod.quickChargeEnabled && menuPeriod.quickChargeItemId);
            };

            $scope.onScan = function (qrcode = '') {
                // $timeout used for loading because barcode-qr-detector functions aren't automatically watched.
                if ($scope.loading || !qrcode) {
                    return;
                }
                $timeout(() => $scope.loading = true);
                var arr = split(qrcode, ':', 2);
                const attemptSuspendOrderLookup = arr.length == 3 && arr[0] === 'ho' && $scope.suspendEnabled;

                if (attemptSuspendOrderLookup) {
                    let suspendId = parseInt(arr[2]);
                    Locations.lookupSuspend({'suspendId': suspendId}).$promise.then((foundSuspend) => {
                        if (!foundSuspend) {
                            return;
                        }
                        $scope.unsuspendOrder(foundSuspend);
                    }).catch((error) => {
                        console.log(error);
                    });
                } else {
                    SmbPosService.fetchLucovaPatron(qrcode).then((patron) => {
                        if (!patron) {
                            $timeout(() => {
                                $scope.loading = false;
                            }, 300);
                            return;
                        }

                        if (GatewayFiit.isEnabled()
                            && patron[0]
                            && patron[0].lucovaUser
                            && patron[0].lucovaUser.fiit
                            && (patron[0].lucovaUser.fiit.patron_id || patron[0].lucovaUser.fiit.patron_key)) {

                            let patronId = patron[0].lucovaUser.fiit.patron_id;
                            let patronKey = patron[0].lucovaUser.fiit.patron_key;

                            SmbPosService.fetchPatronFromFiitBackend(patronId, patronKey, {}, patron[0])
                            .then(() => {
                                $scope.selectPatron(patron[0], isQuickChargeEnabled());
                            })
                            .catch(() => {
                                // NOOP, nothing needs to be done if a patron is not found
                            })
                            .finally(() => {
                                $timeout(() => {
                                    $scope.loading = false;
                                }, 300);
                            });
                        } else {
                            $timeout(() => {
                                $scope.loading = false;
                            }, 300);
                            $scope.selectPatron(patron[0]);
                        }

                    }).catch(async function (err) {
                        if (GatewayFiit.isEnabled()) {
                            SmbPosService.fetchPatronFromFiitBackend(undefined, qrcode, {}, {}).then(function (result) {
                                $scope.selectPatron(result, isQuickChargeEnabled());
                            }).catch(async function (error) {
                                if (!KioskService.isKiosk()) {
                                    const alertShown = error.fiitMpsAccount && error.fiitMpsAccount.alertShown;
                                    if (isQuickChargeEnabled()
                                        && CompanyAttributesService.allowSavingMealCards()
                                        && !PosStatusService.isOffline()
                                        && !alertShown) {
                                        await $modal.open({
                                            templateUrl: 'pos/smb/templates/pos.save.mealcard.tpl.html',
                                            animation: false,
                                            backdrop: 'static',
                                            controller: 'SaveMealCardCtrl',
                                            windowClass: 'smb-pos__checkin',
                                            resolve: {
                                                qrcode: () => qrcode,
                                                isQuickCharge: () => true,
                                                cashierShiftId: () => SmbPosService.shift.cashierShiftId
                                            },
                                            keyboard: false
                                        }).result
                                        .then(() => {
                                            SmbPosService.data.overviewData.totalFiitSavedMealCardCount++;
                                        })
                                        .catch(() => {
                                            // noop
                                        });
                                    } else if (!alertShown) {
                                        // alert for when a card is not found
                                        PosAlertService.showAlertByName('general-error', {
                                            title: 'Meal Card Is Missing',
                                            message: error.fiitMpsAccount.error
                                        });
                                    }
                                } else {
                                    // Kiosk error modal
                                    var kioskModalParams = {
                                        title: $translate.instant('general.error.oops.ttl'),
                                        subtitle: $translate.instant('kiosk.error.invalidAppCode'),
                                        timeOut: 5
                                    };
                                    KioskModalService.showModalByName('alert', kioskModalParams);
                                }
                            }).finally(() => {
                                $timeout(() => {
                                    $scope.loading = false;
                                }, 300);
                            });
                        } else {
                            $timeout(() => {
                                $scope.loading = false;
                            }, 300);

                            if (!KioskService.isKiosk()) {
                                $scope.showManualCheckinModal(true);
                            } else {
                                // Kiosk error modal
                                var kioskModalParams = {
                                    title: $translate.instant('general.error.oops.ttl'),
                                    subtitle: $translate.instant('kiosk.error.invalidAppCode'),
                                    timeOut: 5
                                };
                                KioskModalService.showModalByName('alert', kioskModalParams);
                            }
                        }
                    });
                }
            };


            var split = function (string, delimiter, maxSplits) {
                if (!string) {
                    return [];
                }

                var arr = string.split(delimiter);
                var result = arr.splice(0, maxSplits);

                if (arr.length > 0) {
                    result.push(arr.join(delimiter));
                }

                return result;
            };

            $scope.isGatewayFiitEnabled = function () {
                return GatewayFiit.isEnabled();
            };

            $scope.selectBluetoothCheckedInPatron = function (patron) {
                if (!patron) {
                    return;
                }

                if (isQuickChargeEnabled() && patron && patron.lucovaUser
                    && patron.lucovaUser.fiit && patron.lucovaUser.fiit.patron_id) {
                    SmbPosService.fetchPatronFromFiitBackend(patron.lucovaUser.fiit.patron_id, patron.lucovaUser.fiit.patron_key, {}, patron)
                        .then(function (result) {
                            $scope.selectPatron(patron, true);
                        }).catch(function (error) {
                            $scope.selectPatron(patron);
                        });
                } else {
                    $scope.selectPatron(patron);
                }
            };

            $scope.showManualCheckinModal = function (showError) {
                $modal.open({
                    templateUrl: 'pos/smb/templates/pos.manual.checkin.tpl.html',
                    animation: false,
                    backdrop: 'static',
                    controller: 'SmbManualCheckinCtrl',
                    windowClass: 'smb-pos__checkin',
                    resolve: {
                        selectPatron: function () {
                            return $scope.selectPatron;
                        },
                        isGatewayFiitEnabled: function () {
                            return $scope.isGatewayFiitEnabled();
                        },
                        isExistingOrder: function () {
                            return false;
                        },
                        showError: function () {
                            return showError;
                        },
                        isQuickChargeEnabled: function () {
                            return isQuickChargeEnabled();
                        },
                        autoCloseModalAfterScan: () => true,
                        dismissButtonText: () => {}
                    },
                    keyboard: false
                });
            };

            const playErrorAudio = () => {
                if (CompanyAttributesService.hasSoundEnabled()) {
                    AudioService.loudErrorSound();
                }
            };

            let currentQuickChargeModal = null;

            var runQuickChargeTransaction = function (patron) {
                if (SmbPosService.isQuickChargeRunning()) {
                    playErrorAudio();
                    return;
                }

                // Allow quick charge from the modal screen
                if (currentQuickChargeModal) {
                    currentQuickChargeModal.close();
                }

                SmbPosService.setQuickChargeRunning(true);

                var quickChargeModal = $modal.open({
                    templateUrl: 'pos/quickcharge.tpl.html',
                    animation: false,
                    backdrop: 'static',
                    controller: 'QuickchargeCtrl',
                    windowClass: 'quick-charge-modal',
                    resolve: {
                        selectedPatron: function () {
                            return patron;
                        }
                    },
                    keyboard: false
                });

                currentQuickChargeModal = quickChargeModal;
            };

            $scope.showUpgrade = function () {
                $modal.open({
                    templateUrl: 'pos/smb/templates/pos.upgrade.tpl.html',
                    animation: false,
                    backdrop: true,
                    controller: 'SmbUpgradeCtrl',
                    windowClass: 'smb-pos',
                    keyboard: false
                });
            };

            $scope.filterPatrons = function (patronType) {
                var patrons = $scope.patrons || {};
                patronType = patronType || $scope.currentPatronType;

                var filteredPatrons = patrons[patronType.value];
                setFilteredPatrons(filteredPatrons);
            };

            $scope.getMobileOrderPatrons = function () {
                var filteredPatrons = $scope.patrons['preorder'];
                return filteredPatrons;
            };

            $scope.filterAllPatrons = function () {
                $scope.isPatronListLoading = true;
                $scope.currentPatronType = patronTypeAll;

                $scope.filteredPatrons.length = 0;

                SmbPosService.fetchAllPatrons($scope.patronSearch.searchString).then(function () {
                    $scope.patronSort.field = 'fullName';
                    $scope.patronSort.sortASC = false;

                    setFilteredPatrons($scope.patrons.all);
                });
            };

            var setFilteredPatrons = function (filteredPatrons = []) {
                $scope.filteredPatrons.length = 0;

                let _patrons = applyFilters(filteredPatrons, $scope.currentPatronType.value);

                $scope.filteredPatrons.push(..._patrons);

                $scope.isPatronListEmpty = !filteredPatrons || filteredPatrons.length === 0;
                $scope.isPatronListLoading = false;
            };

            // Returns all pending preorders (haven't been printed yet)
            // Also used for receiving new mobile orders
            $scope.getPrintPendingOrders = function () {
                var printPendingOrders = _.filter(LucovaWebSocket.getMobileOrderUsers(), (lucovaUser) => {
                    var preorder = lucovaUser.preorder;
                    preorder = _.filter(preorder, (order) => {
                        return order.status === 'print_pending';

                    });
                    if (preorder.length) {
                        return lucovaUser;
                    }
                });

                $scope.printPendingOrders = printPendingOrders;
                return printPendingOrders;
            };

            $scope.countdownTimer = function (preorder) {
                if (preorder.status === 'completed' || preorder.status === 'cancelled') {
                    return $translate.instant('smb.pos.mobile.order.status', {
                        status: preorder.status
                    });
                } else {
                    var target = preorder.estimated_completion;

                    if (target > 0) {
                        var now = moment();
                        var diff = moment.duration(moment.unix(target).diff(now));
                        var duration = moment.duration(diff, 'milliseconds');

                        if (duration.asSeconds() > 0) {
                            return $translate.instant('mobile.order.modal.btn.countdown.no.text', {
                                countdown: moment.utc(duration.asMilliseconds()).format('mm:ss')
                            });
                        } else {
                            return $translate.instant('mobile.order.modal.btn.countdown.due');
                        }
                    }
                }
            };

            $scope.getAcceptedMobileOrders = function () {
                // accepted orders contain unfulfilled-completed orders, and acknowledged orders
                var filteredPatrons = _.filter($scope.getMobileOrderPatrons(), (patron) => {
                    var preorder = patron.lucovaUser.preorder;
                    preorder = _.filter(preorder, (order) => {
                        return order.status != 'print_pending' && order.status != 'cancelled' && !order.fulfilled;
                    });
                    if (preorder.length) {
                        return patron;
                    }
                });

                var sortedPatrons = filteredPatrons.sort((a, b) => {
                    return b.lucovaUser.preorder[0].transaction_opened - a.lucovaUser.preorder[0].transaction_opened;
                });
                return sortedPatrons;
            };

            $scope.getMobileOrderHistory = function () {
                // mobile order history contain fulfilled-completed orders, and cancelled orders
                return _.filter($scope.getMobileOrderPatrons(), (patron) => {
                    var preorder = patron.lucovaUser.preorder;
                    preorder = _.filter(preorder, (order) => {
                        return order.status === 'cancelled' || order.fulfilled;
                    });
                    if (preorder.length) {
                        return patron;
                    }
                });
            };

            // for opening a selected mobile order by patron & type
            $scope.selectMobileOrder = function (patron, type) {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modal.mobileOrder.patron.tpl.html',
                    animation: false,
                    backdrop: 'static',
                    controller: 'MobileOrderPatronModalCtrl',
                    windowClass: 'mobile-order-screen',
                    keyboard: false,
                    resolve: {
                        mobileOrderPatrons: function () {
                            var patronsArray;

                            if (type === 'HISTORY') {
                                patronsArray = $scope.getMobileOrderHistory();
                            } else {
                                patronsArray = $scope.getAcceptedMobileOrders();
                            }

                            return patronsArray;
                        },
                        activeIndex: function () {
                            if (type === 'HISTORY') {
                                return _.findIndex($scope.getMobileOrderHistory(), function (fulfilledPatron) {
                                    return fulfilledPatron.lucovaUser.user_name === patron.lucovaUser.user_name;
                                });
                            } else {
                                return _.findIndex($scope.getAcceptedMobileOrders(), function (unfulfilledPatron) {
                                    return unfulfilledPatron.lucovaUser.user_name === patron.lucovaUser.user_name;
                                });
                            }
                        },
                        modalType: function () {
                            return type;
                        },
                        activeUserPreorderId: function () {
                            return 'NEW';
                        }
                    }
                });

                modalInstance.result.then(function (resolved) {
                });
            };

            // for opening ALL mobile orders given the type
            $scope.openMobileOrderModal = function (type) {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modal.mobileOrder.patron.tpl.html',
                    animation: false,
                    backdrop: 'static',
                    controller: 'MobileOrderPatronModalCtrl',
                    windowClass: 'mobile-order-screen',
                    keyboard: false,
                    resolve: {
                        activeIndex: function () {
                            return 0;
                        },
                        mobileOrderPatrons: function () {
                            var patronsArray;

                            if (type === 'HISTORY') {
                                patronsArray = $scope.getMobileOrderHistory();
                            } else {
                                patronsArray = $scope.getAcceptedMobileOrders();
                            }

                            if (type === 'ACCEPTED') {
                                // ACCEPTED MOBILE ORDERS should only show: unfulfilled-completed & acknowledged
                                patronsArray = $scope.getAcceptedMobileOrders();
                            } else {
                                // NEW MOBILE ORDERS should only show: print_pending
                                patronsArray = _.filter($scope.getMobileOrderPatrons(), (patron) => {
                                    var preorder = patron.lucovaUser.preorder;
                                    preorder = _.filter(preorder, (order) => {
                                        return order.status === 'print_pending';
                                    });
                                    if (preorder.length) {
                                        return patron;
                                    }
                                });
                            }

                            return patronsArray;
                        },
                        modalType: function () {
                            return type;
                        },
                        activeUserPreorderId: function () {
                            return 'NEW';
                        }
                    }
                });

                modalInstance.result.then(function (resolved) {
                    SmbPosService.loadLucovaUsers();
                    $scope.refreshPatrons();
                });
            };

            $scope.isPatronNearby = function (patron) {
                // check if patron is 'checkedIn'
                var foundPatron = _.filter($scope.patrons['checkedIn'], (checkedInPatron) => {
                    if (checkedInPatron.isNearby) {
                        return patron.lucovaUser.user_name === checkedInPatron.lucovaUser.user_name;
                    }
                });

                return foundPatron.length > 0;
            };

            // different behaviors based on currentPatronType
            function applyFilters (_patrons, type) {
                _patrons = _patrons ? _patrons : [];
                let patrons = _.filter(_patrons, function (patron) {
                    if (!patron.lucovaUser
                        || !patron.lucovaUser.preorder
                        || !patron.lucovaUser.preorder.length) {
                        return patron;
                    }

                    var preorder = patron.lucovaUser.preorder[0];

                    if (type === 'checkedIn') {
                        // only show patron if nearby AND
                        // latest order is already fulfilled or cancelled
                        if (patron.isNearby
                            && (preorder.fulfilled || preorder.status === 'cancelled')) {
                            return patron;
                        }
                    } else if (type === 'preorder') {
                        // only show fulfilled-completed or cancelled orders in the 'mobile order history' tab
                        if (preorder.fulfilled || preorder.status === 'cancelled') {
                            return patron;
                        }
                    } else {
                        // if no preorders e.g. bluetooth check-in
                        return patron;
                    }
                });
                return patrons;
            }

            var getPatronChat = (preorder) => {
                // getting chat for current patron
                var preorderId = preorder._id;
                Lucova.manager().getPreorderChatById({
                    preorder_id: preorderId
                }, function (response) {
                    // setting chat channel
                    if (response.chat_channel != null) {
                        preorder.chat_channel = response.chat_channel;
                    }
                }, function (error) {
                    $log.error(error);
                });
            };

            var onChatUpdate = function (event, payload) {
                var foundPatron = _.find($scope.getMobileOrderPatrons(), (patron) => {
                    var preorder = patron.lucovaUser.preorder;
                    preorder = _.filter(preorder, (order) => {
                        return order._id === payload.preorder_id;
                    });
                    if (preorder.length) {
                        return patron;
                    }
                });

                if (foundPatron) {
                    // refresh chat channel here
                    _.each(foundPatron.lucovaUser.preorder, (preorder) => {
                        getPatronChat(preorder);
                    });
                }
            };

            $scope.lucovaNearbyUsers = [];

            $scope.init = function () {
                $scope.isLoading = true;

                $scope.setPatronType($scope.constants.patronTypes[0]);

                $scope.patrons = SmbPosService.patrons;
                $scope.suspendByDate = SmbPosService.suspend;

                $scope.lucovaNearbyUsers = SmbPosService.lucovaNearbyUsers;
                $scope.refreshPatrons();
                $scope.quickChargeSessionStatus = SmbPosService.quickChargeSessionStatus;
                if ($scope.suspendEnabled) {
                    SmbPosService.loadSuspend();
                }
                $scope.showScanAppMsg = false;

                if ($scope.patrons) {
                    $scope.isLoading = false;
                }
            };
            $scope.init();

            var unbindPosUsersUpdateListener = $rootScope.$on('pos.users.update', $scope.refreshPatrons);

            var unbindTransitionRequestListener =
                $rootScope.$on('pos::transition::request', function (event, destination) {
                    // Immediately confirm since we are on the main screen, and not transaction
                    $rootScope.$emit('pos::transition::confirm', destination);
                });

            $scope.toggleQuickChargeSession = function () {
                // Commented By Akash Mehta on April 1 2021
                // Previously we were checking the stale value (i.e.if the status is active) and if it was we used to end the QC session.
                // But due to a change in the behaviour of the location-toggle in delivery, we now have to check the new value (i.e.
                // if the session is not active.) (QC -> QuickCharge, QCS -> Quick Charge session) E.g:
                // Previously: toggle is turned off -> QCS status is still active -> assume we need to end the QC session
                // Now: toggle is turned off -> QCS status is now inactive -> assume we need to end the QC session
                if (!$scope.quickChargeSessionStatus.active) {
                    endQuickChargeSession();
                } else {
                    startQuickChargeSession();
                }
            };

            var startQuickChargeSession = function () {
                var menuPeriod = CommonOfflineCache.getCurrentMenuPeriod();
                if (!menuPeriod) {
                    return;
                }
                SmbPosService.startQuickChargeSession(GatewayFiit.isEnabled(), menuPeriod.menuPeriodId).catch(function (error) {
                    if (error && error.error) {
                        var message = '';
                        if (error.response) {
                            if (error.response.status && error.response.data) {
                                message = error.response.data.error;
                            } else {
                                message = error.response;
                            }
                        }

                        PosAlertService.showAlertByName('general-error', {
                            title: error.error,
                            message: message
                        });
                    }
                    $timeout(function () {
                        $scope.quickChargeSessionStatus.active = false;
                    }, 0);
                });
            };


            var endQuickChargeSession = function () {
                var quickChargeSession = CommonOfflineCache.getQuickChargeSession();
                if (!quickChargeSession) {
                    return;
                }

                SmbPosService.endQuickChargeSession(GatewayFiit.isEnabled(), quickChargeSession.quickChargeSessionId).catch(function (error) {
                    if (error && error.error) {
                        var message = '';
                        if (error.response) {
                            console.log(error);
                            if (error.response.status && error.response.data) {
                                message = error.response.data.error;
                            } else {
                                message = error.response;
                            }
                        }

                        PosAlertService.showAlertByName('general-error', {
                            title: error.error,
                            message: message
                        });
                    }
                    $timeout(function () {
                        $scope.quickChargeSessionStatus.active = true;
                    }, 0);
                });
            };

            var unbindChatListener = $rootScope.$on('pos.mobileOrder.homescreen.chat.update', onChatUpdate);

            $scope.$on('$destroy', function () {
                // Unbind rootScope listeners
                unbindPosUsersUpdateListener();
                unbindTransitionRequestListener();
                unbindChatListener();
                $timeout.cancel(navBarIconTimeout);
            });

            $scope.showScanAppOnKiosk = Security.getUser().company.attributes.show_scan_app_on_kiosk === 'true';
            $scope.starOrderButtonText = Security.getUser().company.attributes.start_order_button_text;
            $scope.userRole = Security.getUser().roleType == null ? 'Admin' : Security.getUser().roleType;
        }
    ]);
};
