'use strict';

// /* globals StarWebPrintBuilder, StarWebPrintTrader, message, async */

const angular = require('angular');
// const $ = require('jquery');
// const moment = require('moment');

export default angular.module('freshideas.services.printStatus', []).factory('PrintStatusService', [
    'Pure',
    function (Pure) {
        var printers = {};

        var PRINTER_TYPE = {
            POS: 'pos',
            KITCHEN: 'kitchen'
        };

        // Instead of storing a list of all error printer messages, store them as a nested map with indexed by printer type (
        // whether it's a POS printer or a kitchen printer), printer id (POS Station ID or Kitchen Printer Id), and transaction id.
        // This structure is to allow querying for printer errors by the printer first (ie. printer type - printer id pair). The
        // transaction id index is so that multiple error messages from the same transaction (due to retry) are only considered as
        // one error message.
        var addMessage = function (options) {
            // options = { printerType, printerId, printerUrl, identifier, title, description, data}
            var printerType = options.printerType;
            var printerId = options.printerId;
            var printerUrl = sanitizePrinterUrl(options.printerUrl);
            var identifier = options.identifier; // transaction id, or special string that identifies the operation (eg. 'no-sale')

            printers[printerType] = printers[printerType] || {};
            printers[printerType][printerId] = printers[printerType][printerId] || {};
            printers[printerType][printerId][identifier] = {
                id: Pure.generateUuid(),
                timestamp: Date.now(),
                printerType: printerType,
                printerId: printerId,
                printerUrl: printerUrl,
                identifier: identifier,
                title: options.title,
                description: options.description,
                data: options.data
            };
        };

        var clearMessages = function () {
            var props = Object.keys(printers);
            for (var i = 0; i < props.length; i++) {
                delete printers[props[i]];
            }
        };

        var clearMessagesByUrl = function (url) {
            url = sanitizePrinterUrl(url);

            for (var printerType of Object.keys(printers)) {
                for (var printerId of Object.keys(printers[printerType])) {
                    for (var transactionId of Object.keys(printers[printerType][printerId])) {
                        var message = printers[printerType][printerId][transactionId];
                        if (message.printerUrl === url) {
                            delete printers[printerType][printerId][transactionId];

                            if (_.isEmpty(printers[printerType][printerId])) {
                                delete printers[printerType][printerId];
                            }
                        }

                    }
                }
            }
        };

        var getMessages = function () {
            var result = [];
            for (var printerType of Object.keys(printers)) {
                for (var printerId of Object.keys(printers[printerType])) {
                    for (var transactionId of Object.keys(printers[printerType][printerId])) {
                        result.push(printers[printerType][printerId][transactionId]);
                    }
                }
            }
            result = _.sortBy(result, 'timestamp').reverse();
            return result;
        };
        var getMessageCount = function (printerTypeToFind, printerIdToFind) {
            var count = 0;
            for (var printerType of Object.keys(printers)) {
                for (var printerId of Object.keys(printers[printerType])) {
                    count += Object.keys(printers[printerType][printerId]).length;
                }
            }
            return count;
        };

        var getPrinterStatuses = function () {
            var statuses = {};
            for (var printerType of Object.keys(printers)) {
                for (var printerId of Object.keys(printers[printerType])) {
                    var printerMessages = printers[printerType][printerId];
                    if (printerMessages) {
                        var lastPrinterMessage = _.max(printerMessages, function (message) {
                            return message.timestamp;
                        });

                        statuses[printerType] = statuses[printerType] || {};
                        statuses[printerType][printerId] = lastPrinterMessage;
                    }
                }
            }

            return statuses;
        };

        var success = function (printerType, printerId, printerUrl) {
            if (printers && printers[printerType] && printers[printerType][printerId]) {
                delete printers[printerType][printerId];
            }

            clearMessagesByUrl(printerUrl);
        };

        // Printer url might be passed as base url or with the full request path.
        // Sanitizing the url ensure any url comparisons are based on one type
        //   of url (in thie case, just the base url)
        var sanitizePrinterUrl = function (url) {
            // using `||` pattern instead ES6's default value pattern to ensure null
            // url is converted to empty string too
            url = url || '';
            return url.replace('/StarWebPRNT/SendMessage', '');
        };

        return {
            PRINTER_TYPE,
            addMessage,
            clearMessages,
            clearMessagesByUrl,
            getMessages,
            getMessageCount,
            getPrinterStatuses,
            success,
            printers
        };
    }]);
