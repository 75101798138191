'use strict';


const angular = require('angular');

module.exports = function (freshideasSettings) {

    freshideasSettings.controller('SettingsServicePeriodEditCtrl', [
    '$scope',
    '$q',
    '$modal',
    'servicePeriod',
    'selectedCompanyId',
    'companies',
    'isEdit',
    'Settings',
    'SharedFunctionService',
    function ($scope, $q, $modal, servicePeriod, selectedCompanyId, companies, isEdit, Settings, SharedFunctionService) {
      $scope.dayNames = SharedFunctionService.daysOfTheWeek;

      if (!isEdit) {
        servicePeriod = {
          isNew: true,
          companyId: parseInt(selectedCompanyId),
          serviceDays: []
        };
      }

      $scope.selectedCompany = _.findWhere(companies, {id: selectedCompanyId});

      $scope.selectedServicePeriod = angular.copy(servicePeriod);

      $scope.findServiceDay = function (dayName) {
        var serviceDay = _.findWhere($scope.selectedServicePeriod.serviceDays, {day: dayName});

        if (serviceDay) {
          return serviceDay;
        }

        serviceDay = {day: dayName, enabled: false};

        $scope.selectedServicePeriod.serviceDays.push(serviceDay);

        return serviceDay;
      };
      $scope.revertServicePeriod = function (form) {
        console.log('revert');
        var selectedServicePeriod = angular.copy(servicePeriod);

        $scope.selectedServicePeriod = selectedServicePeriod;
      };
      $scope.updateServicePeriod = function () {
        Settings.updateServicePeriod({}, $scope.selectedServicePeriod, function (response) {
          $scope.$close(response);
        });
      };
      $scope.addServicePeriod = function () {
        var selectedServicePeriod = angular.copy($scope.selectedServicePeriod);
        delete selectedServicePeriod.isNew;
        Settings.addServicePeriod({}, selectedServicePeriod, function (response) {
          $scope.$close(response);
        });
      };
    }
    ]);
};
