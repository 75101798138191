'use strict';


module.exports = function (posModule) {

    const SharedDataService = require('../external/pos.data-service.js');
    const Decimal = require('decimal.js').default;

    posModule.controller('PosNumpadCtrl', [
        '$scope',
        '$filter',
        '$modalInstance',
        'initial',
        'type',
        'note',
        function ($scope, $filter, $modalInstance, initial, type, note) {
            $scope.type = type || 'number';

            // eg. baseCent of 1 has an exponent of 0, baseCent of 100 has an exponent of 2, etc
            var baseCurrencyExponent = Math.floor(Math.log10(SharedDataService.baseCent));
            var baseCurrencyFactor = Math.pow(10, baseCurrencyExponent);

            var value = '0';

            $scope.inputValue = initial || 0;
            $scope.note = note;

            $scope.getValue = function () {
                if ($scope.type === 'percentage') {
                    return $filter($scope.type)($scope.inputValue * 100, 0);
                } else if ($scope.type === 'decimal') {
                    if ($scope.inputValue === '') {
                        return '0';
                    } else if ($scope.inputValue.toString().charAt(0) === '.') {
                        return '0' + $scope.inputValue;
                    }

                    return $scope.inputValue;
                } else if ($scope.type === 'time') {
                    if ($scope.inputValue === '') {
                        return '00:00';
                    }
                    return $scope.inputValue.toString();
                } else if ($scope.type === 'currency') {
                    return $filter('currency')($scope.inputValue);
                }
                return $filter($scope.type)($scope.inputValue);
            };

            $scope.ok = function () {
                setTimeout(function () {
                    // Remove '.' if last character in string
                    if (/\.+$/.test($scope.inputValue)) {
                        $scope.inputValue = $scope.inputValue.substring(0, $scope.inputValue.length - 1);
                    }

                    // Set inputValue to 0 if empty string
                    if ($scope.inputValue === '') {
                        $scope.inputValue = '0';
                    }

                    $scope.$close($scope.inputValue);
                }, 0);
            };

            $scope.addValue = function (val) {
                if (val !== 'del') {
                    if ($modalInstance.lucovaValidator &&
                        !$modalInstance.lucovaValidator(value, val)) {
                        return;
                    }

                    // Only one decimal
                    if (val === '.' && value.indexOf('.') > -1) {
                        return;
                    }

                    // Max 3 numbers after decimal point
                    if (value.indexOf('.') > -1 && value.split('.')[1].length === 3) {
                        return;
                    }

                    if (value.charAt(0) !== '0') {
                        if (value.length > 9) {
                            return;
                        }
                        value += val;
                    } else {
                        value = val;
                    }
                } else {
                    value = value.slice(0, -1);
                }

                setValue();
            };

            function setValue () {
                if ($scope.type === 'number' || $scope.type === 'decimal') {
                    $scope.inputValue = value;
                    return;
                }

                if ($scope.type == 'time') {
                    var value1 = (value.length > 3) ? value.slice(0, 2) :
                                ((value.length > 2) ? '0'+value.slice(0, 1) : '00');

                    var value2 = (value.length > 3) ? value.slice(2) :
                                ((value.length > 2) ? value.slice(1) :
                                ((value.length > 1) ? value :
                                (value.length == 1) ? '0'+ value : '00'));
                    $scope.inputValue = value1 + ':' + value2;
                    return;
                }

                // type `percentage` and `currency`
                if (value === '' || value === '0') {
                    $scope.inputValue = '0.00';
                } else {
                    var valString;

                    if (value.length === 1) {
                        valString = '00' + value;
                    } else if (value.length === 2) {
                        valString = '0' + value;
                    } else {
                        valString = value;
                    }

                    var dollarValue = valString.slice(0, -2);
                    var centValue = valString.slice(-2);
                    var currencyValueString = dollarValue + '.' + centValue;

                    var currencyValue;
                    if ($scope.type === 'currency') {
                        // take currency's base unit into consideration
                        currencyValue = new Decimal(currencyValueString).times(baseCurrencyFactor).toNumber();
                    } else {
                        currencyValue = currencyValueString;
                    }

                    $scope.inputValue = currencyValue + '';
                }
            }
        }
    ]);
};
