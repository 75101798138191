'use strict';

const Decimal = require('decimal.js').default;
const LoyaltyRedemption = require('../../external/loyalty/redemption.js');
const AppConstants = require('../../common/freshideas/app-constants');

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosOrderReceiptCtrl', [
        '$scope',
        '$modal',
        '$timeout',
        '$rootScope',
        'Lucova',
        'Locations',
        'Security',
        'LucovaWebSocket',
        'Pure',
        'SharedFunctionService',
        'SharedDataService',
        'SmbPosService',
        'PrintService',
        'KdsService',
        'TRANSACTION_STATUS',
        'PrintType',
        'PosStatusService',
        'PromiseRetry',
        'CompanyAttributesService',
        'KioskService',
        'CurrentSession',
        'SecondaryDisplay',
        'PosAlertService',
        'CartBuilderService',
        'HelpService',
        function (
            $scope,
            $modal,
            $timeout,
            $rootScope,
            Lucova,
            Locations,
            Security,
            LucovaWebSocket,
            Pure,
            SharedFunctionService,
            SharedDataService,
            SmbPosService,
            PrintService,
            KdsService,
            TRANSACTION_STATUS,
            PrintType,
            PosStatusService,
            PromiseRetry,
            CompanyAttributesService,
            KioskService,
            CurrentSession,
            SecondaryDisplay,
            PosAlertService,
            CartBuilderService,
            HelpService) {

            const isPGCDiscountable = CompanyAttributesService.isPGCDiscountable();

            // Update shift cancelled transactions if any items in cart
            $scope.$on('$destroy', function (test) {
                // Unbind rootScope listeners
                unbindTransitionRequestListener();
                unbindKioskUpcScannedSuccess();
            });

            if (KioskService.isKiosk()) {
                $scope.$on('kiosk::change-selected-category', function (event, category) {
                    $scope.kioskSelectedCategory = category;
                });
            }

            $scope.isDone = false;
            var unbindTransitionRequestListener =
                $rootScope.$on('pos::transition::request', function (event, destination) {
                    // Immediately confirm if cart is empty or transaction is done
                    if (PosStatusService.isOffline()
                        || ((!$scope.fullReceipt || $scope.fullReceipt.length === 0 || $scope.isDone)
                            && $scope.cancelledItemsCount === 0)) {
                        $rootScope.$emit('pos::transition::confirm', destination);
                        SecondaryDisplay.clearDisplay();
                        return;
                    }

                    if (!$scope.cancelledItemsUserId) {
                        SmbPosService.cancelItemsRequestPin($scope).then(function (pinObj) {
                            // Bind user PIN to cancelled order
                            $scope.cancelledItemsUserId = pinObj.user.userId;

                            // Adds all items currently in receipt into cancelled items
                            SmbPosService.cancelItemsAddReceipt($scope);
                            SmbPosService.cancelItemsLogToBackend($scope);
                            $scope.cancelledItemsCount = 0;
                            $scope.cancelledItemsAmount = 0.0;
                            $rootScope.$emit('pos::transition::confirm', destination);
                            SecondaryDisplay.clearDisplay();
                        }, function (error) {
                            console.error(error);
                        });
                    } else {
                        SmbPosService.cancelItemsAddReceipt($scope);
                        SmbPosService.cancelItemsLogToBackend($scope);
                        $rootScope.$emit('pos::transition::confirm', destination);
                        SecondaryDisplay.clearDisplay();
                    }
                });


            $scope.removeReceiptItem = function (receiptItem) {
                $scope.$parent.removeReceiptItem(receiptItem);
                $scope.triggerApplyTransactionPercentageDiscount();
            };

            $scope.modifyItemQuantity = function (receiptItem, option) {
                $scope.$parent.modifyItemQuantity(receiptItem, option);
            };

            $scope.hasTaxPopupEnabled = CompanyAttributesService.hasTaxPopupEnabled();
            $scope.hasTicketPrePrint = CompanyAttributesService.hasTicketPrePrint();
            $scope.hasServiceModeSelectorEnabled = CompanyAttributesService.hasServiceModeSelectorEnabled();

            var sendToKdsAndBackend = function (suspendedTransaction = null, orderNumber = null, overrideFilter = false) {
                // Only if KDS is enabled
                if (Security.getUser().company.attributes.kds_enabled !== 'true') {
                    return;
                }

                // Filter receipt only for those with kdsEnabled = true
                // Start with only base items
                // Make a copy of $scope.receipt because _.filter modifies it
                var filter = {
                    level: 0
                };
                if (overrideFilter === false) {
                    filter['kdsEnabled'] = true;
                }
                var filteredReceipt = _.filter($scope.receipt, filter);

                // Nothing to do if receipt is empty
                if (filteredReceipt.length === 0) {
                    return;
                }

                // Check the modifiers and add the ones whose base items
                // exist in filteredReceipt
                var filteredModifiers = _.filter($scope.receipt, {
                    level: 1
                });

                var itemIndexes = new Set();
                for (var i = 0; i < filteredReceipt.length; i++) {
                    itemIndexes.add(filteredReceipt[i].index);
                }

                for (i = 0; i < filteredModifiers.length; i++) {
                    if (itemIndexes.has(filteredModifiers[i].index)) {
                        filteredReceipt.push(filteredModifiers[i]);
                    }
                }

                // Format receipt for sending
                var formattedReceipt = [];
                for (i = 0; i < filteredReceipt.length; i++) {
                    formattedReceipt.push({
                        itemName: filteredReceipt[i].name,
                        itemQuantity: filteredReceipt[i].quantity,
                        itemNotes: filteredReceipt[i].notes,
                        itemIndex: filteredReceipt[i].index,
                        itemLevel: filteredReceipt[i].level,
                        multiplierInWords: filteredReceipt[i].multiplierInWords,
                        selected: false
                    });
                }

                // Transaction processed from regular order
                // Send to KDS & backend
                var pushKdsMessage = function () {
                    // Send update through Socket.IO
                    try {
                        Lucova.manager().pushMessage({
                            terminalId: Lucova.getTerminalId(),
                        },
                            function (response) { },
                            function (error) { }
                        );
                    } catch (e) {
                        console.log(e);
                    }
                };

                // Generate the KDS object for sending
                var kdsDataObject = {
                    uuid: Pure.generateUuid(),
                    locationId: SmbPosService.shift.locationId,
                    status: KdsService.ReceiptStatusEnum.INCOMING,
                    receipt: formattedReceipt,
                    timestamp: new Date().getTime(),
                    lastUpdated: new Date().getTime(),
                    receiptCounter: orderNumber
                };

                // From suspend modal
                if (suspendedTransaction) {
                    kdsDataObject.guestTransaction = suspendedTransaction.guestTransaction;
                    kdsDataObject.patronName = suspendedTransaction.orderName;
                    kdsDataObject.suspendId = suspendedTransaction.suspendId;

                    if (suspendedTransaction.patronPhotoUrl) {
                        kdsDataObject.patronPhotoUrl = suspendedTransaction.patronPhotoUrl;
                    }
                    KdsService.updateReceiptFiit(kdsDataObject);
                    return;
                } else {
                    // Patron name
                    if ($scope.suspendedOrder.fullName) {
                        kdsDataObject.patronName = $scope.suspendedOrder.fullName;
                    } else if ($scope.patron.fullName) {
                        kdsDataObject.patronName = $scope.patron.fullName;
                    } else if ($scope.patron.firstName) {
                        kdsDataObject.patronName = $scope.patron.firstName;
                    }

                    if ($scope.suspendedOrder.suspendId) {
                        kdsDataObject.suspendId = $scope.suspendedOrder.suspendId;
                    }

                    // Patron picture
                    if ($scope.suspendedOrder.patronPhotoUrl) {
                        kdsDataObject.patronPhotoUrl = $scope.suspendedOrder.patronPhotoUrl;
                        kdsDataObject.guestTransaction = $scope.suspendedOrder.guestTransaction;
                    } else if ($scope.patron.lucovaUser && $scope.patron.lucovaUser.photo_url) {
                        kdsDataObject.patronPhotoUrl = $scope.patron.lucovaUser.photo_url;
                        kdsDataObject.patronName = $scope.patron.fullName;
                    } else {
                        kdsDataObject.guestTransaction = true;
                    }
                    if ($scope.buzzerCode) {
                        kdsDataObject.buzzerCode = $scope.buzzerCode;
                    }
                    PromiseRetry.start({
                        fn: function () {
                            KdsService.addReceiptFiit(kdsDataObject).then(function (response) {
                                pushKdsMessage();
                            });
                        },
                        timeout: 5000,
                        maxRetry: 2
                    });
                }
            };

            var generateReceiptHierarchy = function (receipt) {
                var receiptItemsRoot = _.filter(receipt, {
                    level: 0
                });

                // Build list of children for each item
                for (var i = 0; i < receiptItemsRoot.length; i++) {
                    var receiptChildren = _.filter(receipt, {
                        index: receiptItemsRoot[i].index,
                        level: 1
                    });

                    if (receiptChildren) {
                        receiptItemsRoot[i].children = receiptChildren;
                    }
                }

                return receiptItemsRoot;
            };

            // Print kitchen receipts from suspend modal
            var printSuspendedKitchenReceipts = function (suspendedTransaction) {
                try {
                    _.each($scope.receipt, function (item) {
                        item.amount_cents = item.itemPrice * 100;
                    });
                    var posPrinters = SmbPosService.shift.posStation.posPrinters;

                    // Print to each printer the items assigned to it
                    var receiptItemsFiltered = generateReceiptHierarchy($scope.receipt);
                    var receiptItemsPrinterGrouped = _.groupBy(receiptItemsFiltered, 'printerId');

                    var firstPosSecondaryPrinter = _.findWhere(posPrinters, {kitchenPrinter: true}) || {printerId: 'null'};

                    for (var key of Object.keys(receiptItemsPrinterGrouped)) {
                        if (key !== 'null') {
                            var receiptItems = receiptItemsPrinterGrouped[key];
                            var posPrinter = _.filter(posPrinters, {
                                'posPrinterId': parseInt(key),
                            });

                            // if pos printer cannot be found on this station, assign it to the first
                            // secondary printer instead;
                            if (posPrinter.length === 0) {
                                posPrinter = [firstPosSecondaryPrinter];
                            }

                            var kitchenSheetData = {};
                            kitchenSheetData.locationName = SmbPosService.shift.location.name || '(Location)';
                            kitchenSheetData.location = SmbPosService.shift.location.address;
                            kitchenSheetData.cashierName = ''; // NOTE: doesn't seem to be used and also no data is available immediately in the frontend
                            kitchenSheetData.stationName = SmbPosService.shift.posStation.name || '(POS Station)';
                            kitchenSheetData.companyName = SharedDataService.company.companyName || '(Company)';
                            kitchenSheetData.receiptId = 0;
                            kitchenSheetData.transactionDateTime = Date.now();
                            kitchenSheetData.patronName = suspendedTransaction.orderName;
                            kitchenSheetData.posPrinters = SmbPosService.shift.posStation.posPrinters;
                            kitchenSheetData.patronName = 'Order Name: ' + suspendedTransaction.orderName;

                            kitchenSheetData.heldOrder = {
                                suspendId: suspendedTransaction.suspendId,
                                orderName: suspendedTransaction.orderName,
                                orderEmail: suspendedTransaction.orderEmail,
                                orderPhoneNumber: suspendedTransaction.orderPhoneNumber
                            };

                            let isMobileOrder = false;
                            // Signals to the print service to print a new kitchen sheet on each item
                            var separateItems = CurrentSession.getCompany().attributes.receipt__separate_items_on_secondary === 'true';
                            PrintService.printKitchenSheets(kitchenSheetData, receiptItems, posPrinter, isMobileOrder, separateItems);
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            };

            var printSuspendedBill = function (suspendedTransaction) {
                var response = {
                    stationName: SmbPosService.shift.posStation.name || '(POS Station)',
                    locationName: SmbPosService.shift.location.name || '(Location)',
                    location: SmbPosService.shift.location.address,
                    cashierName: '',
                    companyName: SharedDataService.company.companyName || '(Company)',
                    patronName: 'Order Name: ' + suspendedTransaction.orderName,
                    transactionDateTime: Date.now(),
                    transactionSubTotal: $scope.currentOrderBalance.subTotalAmount,
                    transactionDeliveryFee: $scope.tenderAmounts.deliveryFee,
                    transactionTax: $scope.tenderAmounts.totalTaxes,
                    transactionTotal: $scope.tenderAmounts.totalSales,
                    cashAmount: 0,
                    cashRounding: 0,
                    creditCardAmount: 0,
                    debitCardAmount: 0,
                    otherAmount: 0,
                    change: 0,
                    mealEqAmount: 0,
                    tipAmount: 0,
                    remainingBalance: $scope.tenderAmounts.remainingBalance,
                    dcbAmount: 0,
                    icbAmount: 0,
                    mealPlansRedeemed: 0,
                    mealPlanIds: undefined,
                    dcbMealPlanIds: undefined,
                    loyaltyAmount: 0,
                    dollarDiscountAmount: $scope.tenderAmounts.dollarDiscountAmount,
                    percentDiscountAmount: $scope.tenderAmounts.percentDiscountAmount,
                    percentDiscount: $scope.tenderAmounts.percentDiscount,
                    posPrinters: SmbPosService.shift.posStation.posPrinters,
                    giftCardPurchases: [],
                    giftCardUsage: []
                };
                response.heldOrder = {
                    suspendId: suspendedTransaction.suspendId,
                    orderName: suspendedTransaction.orderName,
                    orderEmail: suspendedTransaction.orderEmail,
                    orderPhoneNumber: suspendedTransaction.orderPhoneNumber
                };

                var receipt = $scope.receipt;
                var cardReceiptArr = [];
                var skipCashDrawer = true;
                var printType = PrintType.CUSTOMER;

                PrintService.printReceipt(response, receipt, cardReceiptArr, skipCashDrawer, printType);
            };

            // Open suspend modal to hold transaction
            // Modes are:
            // 'splash' = splash screen (navigated from held orders)
            // 'new_suspend' = create a new held order
            // 'update_suspend' = update an existing held order
            $scope.suspendTransaction = function (suspendMode) {
                $scope.suspendMode = suspendMode;
                $modal.open({
                    templateUrl: 'common/modals/modalSuspend.html',
                    controller: 'SuspendController',
                    windowClass: 'smb-pos smb-pos__suspend-modal',
                    animation: false,
                    backdrop: true,
                    resolve: {
                        sendToKdsAndBackend: function () {
                            return function (suspendedTransaction, orderNumber, overrideFilter) {
                                sendToKdsAndBackend(suspendedTransaction, orderNumber, overrideFilter);
                            };
                        },
                        printSuspendedMainReceipt: function () {
                            return printSuspendedBill;
                        },
                        printSuspendedKitchenReceipts: function () {
                            return printSuspendedKitchenReceipts;
                        },
                        suspendMode: function () {
                            return $scope.suspendMode;
                        },
                        guestTransaction: function () {
                            return $scope.currentOrder.patron.guest;
                        },
                        currentOrder: function () {
                            return $scope.currentOrder;
                        },
                        fullReceipt: function () {
                            return $scope.fullReceipt;
                        },
                        solinkCancelledItems: function () {
                            return $scope.solinkCancelledItems;
                        },
                        receipt: function () {
                            return $scope.receipt;
                        },
                        goToHome: function () {
                            return function () {
                                $scope.switchView('start');
                            };
                        },
                        currentOrderBalance: function () {
                            return $scope.currentOrderBalance;
                        },
                        suspendedOrder: function () {
                            return $scope.suspendedOrder;
                        },
                        adjustments: function () {
                            return $scope.adjustments;
                        }
                    }
                });
            };

            // Initialize receipt items if recovering from suspend
            var loadSuspended = function (showSplash) {
                if ($scope.currentOrder.fullReceipt) {
                    $scope.fullReceipt.push(...$scope.currentOrder.fullReceipt);
                    $scope.rebuildReceipt();

                    // Show splash screen when recovering from held order
                    // 'if' statement here is just so we open the splash only once
                    if (showSplash) {
                        $scope.suspendTransaction('splash');
                    }
                }
            };

            $scope.$on('pos::suspend::receipt::reset', function (event, args) {
                loadSuspended(false);
            });
            loadSuspended(true);

            var populateItemFromModalResponse = function (receiptItem, modalResponse) {
                $scope.$parent.populateItemFromModalResponse(receiptItem, modalResponse);
            };


            $scope.selectReceiptItem = function (receiptItem) {
                // In `receiptItem`, the field that means the "unit price" of an
                // item is `itemPrice`
                receiptItem.detail = receiptItem.detail || {};
                receiptItem.detail.quantity = receiptItem.quantity || 1;

                receiptItem.detail.discount = receiptItem.detail.discount || {};

                receiptItem.detail.discount.price = receiptItem.data.currentBundlePrice;
                receiptItem.detail.discount.percentage = (receiptItem.data.originalBundlePrice > 0)
                    ? 1 - (receiptItem.data.currentBundlePrice / receiptItem.data.originalBundlePrice)
                    : 0;

                $scope.selectedReceiptItem = receiptItem;

            };


            /**
            ** The case takes in consideration that the price in the details object is the new price being set
            ** and hence calculates the % off differently.
            ** For the new modal , a new case is introduced as price_discount, which considers that the price being passed is being
            ** given off (rather than it being the new price like the case above).
            **/
            function calculateDiscount (receiptItem, field) {
                switch (field) {
                    case 'percentage':
                        receiptItem.detail.discount.price =
                            parseFloat(receiptItem.data.originalBundlePrice * (1 - receiptItem.detail.discount.percentage))
                                .toPrecision(12);
                        break;
                    case 'price':
                        receiptItem.detail.discount.percentage = (
                            parseFloat(1 - receiptItem.detail.discount.price / receiptItem.data.originalBundlePrice))
                            .toPrecision(12);
                        break;
                    case 'price_discount':
                        receiptItem.detail.discount.percentage = (
                            parseFloat((receiptItem.detail.discount.price / receiptItem.detail.quantity) / receiptItem.data.originalBundlePrice))
                            .toPrecision(12);
                        break;
                }
            }

            $scope.modifyExistingItem = function (receiptItem) {
                $scope.selectReceiptItem(receiptItem);
                $scope.$parent.modifyExistingItem(receiptItem).then(function () {
                    if (!$scope.modificationModalDismissed) {
                        if ($scope.modifierModalResponse.isEdit) {
                            populateItemFromModalResponse(receiptItem, $scope.modifierModalResponse);
                            $scope.applyReceiptItemChange(receiptItem, $scope.adjustments);
                            $scope.checkIfDecimalQuantity(receiptItem, 'Parsed Modal Response On Edit');
                        }
                    }
                });
            };

            /**
            **  THe parameter modifyingItem is being added since we now manipulate price as well of the receiptItem.
            **  Check definition in parent Controller for more clarification.
            **  Note : buildForModal will always be false for any call to the function from this controller.
            **/
            $scope.applyReceiptItemChange = function (receiptItem, adjustments, skipReceiptRebuild, isFullReceiptModified) {
                $scope.$parent.applyReceiptItemChange(receiptItem, adjustments, skipReceiptRebuild, false, isFullReceiptModified);
            };

            $scope.undoReceiptItemChange = function (item) {
                $scope.selectReceiptItem(item);
            };

            $scope.currentOrder.balance.useLoyalty = $scope.adjustments.mealEquivalencyAdjustment;
            $scope.adjustMealEquivalency = function () {
                if (angular.isDefined($scope.adjustments.mealEquivalencyAdjustment)) {
                    $scope.adjustments.mealEquivalencyAdjustment = !$scope.adjustments.mealEquivalencyAdjustment;

                    applyLoyaltyPercentageDiscount();

                    // Because there isn't a way to pass whether loyalty is used into the tender screen without
                    // introducing more parameters, use `SmbPosService.order` to carry the value forward.
                    $scope.currentOrder.balance.useLoyalty = $scope.adjustments.mealEquivalencyAdjustment;
                }
            };
            var applyLoyaltyPercentageDiscount = function () {
                if ($scope.adjustments.mealEquivalencyAdjustment) {
                    var loyaltyDiscountPercentage = calculateLoyaltyDiscountPercentage();
                    var percentageValue = new Decimal(loyaltyDiscountPercentage).dividedBy(100).toDecimalPlaces(2).toNumber();
                    $scope.applyTransactionPercentageDiscount(percentageValue, true);
                } else {
                    $scope.applyTransactionPercentageDiscount(0, true);
                }

                $scope.rebuildReceipt();
            };
            var calculateLoyaltyDiscountPercentage = function () {
                var availableLoyaltyPoints = new Decimal(calculateAvailableLoyaltyPoints($scope.available));
                var requiredLoyaltyValue = calculateRequiredLoyaltyValue($scope.receipt, $scope.adjustments);

                var loyaltyRedemptionRules = CurrentSession.getCompany().organization.settings.loyaltyRedemptionRules;
                var loyaltyRedemptionPerDollar = CurrentSession.getCompany().organization.settings.loyaltyRedemptionPerDollar;
                var loyaltyTier = LoyaltyRedemption.getTier(
                    requiredLoyaltyValue.toNearest(SharedDataService.baseDollar).toNumber(),
                    availableLoyaltyPoints.toDecimalPlaces(0).toNumber(),
                    loyaltyRedemptionRules,
                    loyaltyRedemptionPerDollar
                );

                if (loyaltyTier.discount) {
                    return loyaltyTier.discount;
                } else {
                    return 0;
                }
            };
            var calculateAvailableLoyaltyPoints = function (availablePlanList) {
                var availablePoints = 0;

                _.each(availablePlanList, function (plan) {
                    if (plan.name === 'Loyalty' || plan.mealPlanDescription === 'loyalty') {
                        availablePoints += plan.currentMealPlanBalance;
                    }
                });

                return availablePoints;
            };
            var calculateRequiredLoyaltyValue = function (receiptList, adjustments) {
                var requiredLoyaltyValue = new Decimal(0);

                _.each(receiptList, function (receiptItem) {
                    if (receiptItem.loyaltyEnabled && !(receiptItem.subtype === 'discount' || receiptItem.subtype === 'loyalty')) {
                        var receiptItemValue = new Decimal(receiptItem.itemPrice * receiptItem.quantity);
                        requiredLoyaltyValue = requiredLoyaltyValue.plus(receiptItemValue);
                    }
                });

                var dollarDiscount = (adjustments) ? adjustments.dollarDiscount || 0 : 0;
                requiredLoyaltyValue = requiredLoyaltyValue.minus(new Decimal(dollarDiscount));

                return requiredLoyaltyValue;
            };

            $scope.editQuantity = function (item) {
                return showPinPad('decimal', item.detail.quantity, function (editedValue) {
                    item.detail.quantity = parseFloat(editedValue);
                });
            };

            /** **
            *** To remove - Not being used
            ***
            ***/

            $scope.editDiscount = function (item) {
                if ($scope.adjustments.percentDiscount) {
                    return;
                }

                var validator = function (oldVal, newVal) {
                    if (parseInt(oldVal) + '' + parseInt(newVal) > 100) {
                        return false;
                    }
                    return true;
                };

                var callback = function (editedValue) {
                    delete item.detail.discount.type;
                    item.detail.discount.percentage = parseFloat(editedValue);
                    calculateDiscount(item, 'percentage');
                };

                return showPinPad('percentage', item.detail.discount.percentage, callback, validator);
            };

            $scope.editPrice = function (item) {
                if ($scope.adjustments.percentDiscount) {
                    return;
                }

                return showPinPad('currency', item.detail.discount.price, function (editedValue) {
                    delete item.detail.discount.type;
                    item.detail.discount.price = parseFloat(editedValue);
                    calculateDiscount(item, 'price');
                });
            };

            $scope.editTransactionPercentDiscount = function () {
                var validator = function (oldVal, newVal) {
                    if (parseInt(oldVal) + '' + parseInt(newVal) > 100) {
                        return false;
                    }
                    return true;
                };

                var callback = function (editedValue) {
                    $scope.adjustments.percentDiscount = parseFloat(editedValue);
                    $scope.applyTransactionPercentageDiscount($scope.adjustments.percentDiscount);
                };

                var note = ($scope.canApplyTransactionPercentageDiscount())
                    ? ''
                    : 'smb.pos.balance.discount.itemDiscountAlreadyApplied';

                return showPinPad(
                    'percentage',
                    $scope.adjustments.percentDiscount,
                    callback,
                    validator,
                    note);
            };

            function showPinPad (type, model, callback, validator, note) {
                var modalInstance = $modal.open({
                    templateUrl: 'pos/pos.numpad.tpl.html',
                    controller: 'PosNumpadCtrl',
                    animation: false,
                    windowClass: 'modal-numpad modal-fullscreen-transparent modal-right',
                    resolve: {
                        initial: function () {
                            return model;
                        },
                        type: function () {
                            return type;
                        },
                        note: function () {
                            return note;
                        }
                    },
                    backdrop: true
                });
                modalInstance.lucovaValidator = validator;

                return modalInstance.result.then(function (value) {
                    if (callback) {
                        callback(value);
                    }
                });
            }

            $scope.transactionDiscount = {
                percentage: 0
            };

            $scope.applyTransactionPercentageDiscount = function (percentage, isLoyalty) {
                CartBuilderService.applyTransactionPercentageDiscount(percentage, $scope.fullReceipt, $scope.receipt,
                    $scope.adjustments, isLoyalty, isPGCDiscountable, $scope.rebuildReceipt);
            };

            $scope.applyTransactionDollarDiscount = function (dollar) {
                let showAlert = true;

                $scope.adjustments.dollarDiscount = dollar;
                $scope.rebuildReceipt(showAlert);
            };

            $scope.canApplyTransactionPercentageDiscount = function () {
                var mainReceiptItems = _.where($scope.receipt, {level: 0});
                var canApply = true;

                if (mainReceiptItems.length) {
                    canApply = _.every(mainReceiptItems, function (receiptItem) {
                        var receiptItemDetail = receiptItem.detail || {};
                        var receiptItemDiscountDetail = receiptItemDetail.discount || {};

                        return receiptItemDiscountDetail.type === 'transaction' || !receiptItemDiscountDetail.percentage;
                    });
                }

                return canApply;
            };

            $scope.$on('pos::transcation::applyPercentageDiscounts', function () {
                if ($scope.adjustments.percentDiscount === 0) {
                    return;
                }

                if ($scope.adjustments.mealEquivalencyAdjustment) {
                    applyLoyaltyPercentageDiscount();
                } else {
                    $scope.applyTransactionPercentageDiscount($scope.adjustments.percentDiscount);
                }
            });

            $scope.processTaxAppliedAlongWithDiscounts = function (modalResponse) {
                if (modalResponse.selectedTaxRates) {
                    $scope.adjustments.selectedTaxRates = modalResponse.selectedTaxRates;
                } else {
                    delete $scope.adjustments.selectedTaxRates;
                }

                $scope.applyTaxChanges($scope.fullReceipt, $scope.adjustments);

                if ($scope.adjustments.percentDiscount || $scope.adjustments.dollarDiscount) {
                    $scope.applyTransactionPercentageDiscount($scope.adjustments.percentDiscount);
                    $scope.applyTransactionDollarDiscount($scope.adjustments.dollarDiscount);
                } else {
                    var mainReceiptItems = _.where($scope.receipt, {level: 0});
                    _.each(mainReceiptItems, function (receiptItem) {
                        $scope.selectReceiptItem(receiptItem);
                        if (receiptItem.detail.discount) {
                            receiptItem.discount = receiptItem.discount || {};
                            receiptItem.discount.percentage = receiptItem.detail.discount.percentage;
                            receiptItem.discount.price = receiptItem.detail.discount.price;
                            receiptItem.discount.labelledDiscount = receiptItem.detail.discount.labelledDiscount;
                            receiptItem.discount.subtype = receiptItem.detail.discount.subtype;
                        }
                        $scope.applyReceiptItemChange(receiptItem, $scope.adjustments, true, false);
                    });
                }

                $scope.rebuildReceipt();
            };

            var isShowingDeliveryAlert = false;
            $scope.removeDelivery = function () {
                if (isShowingDeliveryAlert) {
                    return;
                }
                isShowingDeliveryAlert = true;

                PosAlertService.showAlertByName('general-alert', {
                    'title': 'smb.pos.balance.delivery.remove.ttl',
                    'message': 'smb.pos.balance.delivery.remove.msg',
                    'modalCallback': function () {
                        SmbPosService.removeDeliveryFromTransaction($scope.adjustments);
                        $scope.$parent.rebuildReceipt();
                        isShowingDeliveryAlert = false;
                    },
                    'dismissModalCallback': function () {
                        isShowingDeliveryAlert = false;
                    }
                });
            };

            $scope.applyDifferentTax = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'pos/smb/templates/pos.order.tax.popup.tpl.html',
                    controller: 'SmbPosOrderTaxPopupCtrl',
                    windowClass: 'smb-pos smb-pos-popup smb-pos-popup__discount',
                    animation: false,
                    backdrop: 'static',
                    resolve: {
                        adjustments: function () {
                            return $scope.adjustments;
                        },
                        showPinPad: function () {
                            return showPinPad;
                        }
                    }
                });

                modalInstance.result.then(function (modalResponse) {
                    $scope.processTaxAppliedAlongWithDiscounts(modalResponse);
                });
            };

            $scope.addTransactionDiscount = function () {
                if ($scope.discountSelectionDisabled) {
                    return;
                }
                var adjustmentsObj = angular.copy($scope.adjustments);
                if (adjustmentsObj.labelledDiscounts) {
                    var labelledDiscountsObj = {
                        selectedDiscounts: $scope.adjustments.labelledDiscounts,
                        value: $scope.adjustments.percentDiscount
                    };
                    adjustmentsObj.labelledDiscounts = labelledDiscountsObj;
                    adjustmentsObj.percentDiscount = 0;
                }
                var modalInstance = $modal.open({
                    templateUrl: 'pos/smb/templates/pos.order.discount.tpl.html',
                    controller: 'SmbPosOrderDiscountCtrl',
                    windowClass: 'smb-pos smb-pos-popup smb-pos-popup__discount',
                    animation: false,
                    backdrop: 'static',
                    resolve: {
                        adjustments: function () {
                            return adjustmentsObj;
                        },
                        showPinPad: function () {
                            return showPinPad;
                        }
                    }
                });

                modalInstance.result.then(function (adjustments) {
                    $scope.adjustments.percentDiscount = adjustments.percentDiscount || adjustments.labelledDiscount.value;
                    $scope.adjustments.dollarDiscount = adjustments.dollarDiscount;

                    if (adjustments.labelledDiscount.selectedDiscounts) {
                        $scope.adjustments.labelledDiscounts = adjustments.labelledDiscount.selectedDiscounts;
                    } else {
                        delete $scope.adjustments.labelledDiscounts;
                    }

                    $scope.applyTransactionPercentageDiscount($scope.adjustments.percentDiscount);
                    $scope.applyTransactionDollarDiscount($scope.adjustments.dollarDiscount);
                });
            };

            // Update suspended transaction on fiit backend
            $scope.updateSuspendedTransaction = function () {
                var transactionObject = Object.assign({}, $scope.suspendedOrder, {
                    receiptJson: JSON.stringify($scope.fullReceipt.map(
                        (receiptItem) => Object.assign(receiptItem, {parent: null})))
                });

                Locations.updateSuspend(transactionObject);
                $scope.switchView('start');
            };

            const TENDER_MODAL_TIMEOUT = 5000;
            $scope.tendering = false;

            $scope.shouldPreventTender = function () {
                return $scope.tendering || !$scope.receipt || $scope.receipt.length === 0 || $scope.currentOrderBalance.subTotalAmount < 0 || $scope.currentOrderBalance.totalAmount < 0;
            };

            const unbindKioskUpcScannedSuccess = $rootScope.$on('KioskUPCScannedSuccess', () => {
                $scope.tenderTransaction();
            });

            $scope.tenderTransaction = function () {
                HelpService.setQueryText('transaction');
                if ($scope.shouldPreventTender()) {
                    if ($scope.currentOrderBalance.subTotalAmount < 0 || $scope.currentOrderBalance.totalAmount < 0) {
                        var alertParams = {
                            title: 'smb.pos.negative.total.title',
                            message: 'smb.pos.negative.total.description'
                        };
                        PosAlertService.showAlertByName('general', alertParams);
                    }
                    return;
                }

                if (isTendering()) {
                    return;
                }

                startTendering();

                if (KioskService.isKiosk()) {
                    $scope.currentOrder.serviceMode = AppConstants.serviceModes.KIOSK;
                }

                var receiptCounter = runTicketPrePrint();

                var posData = $scope.createPosData();

                var ticketPrePrint = undefined;
                if (receiptCounter) {
                    ticketPrePrint = {
                        receiptCounter: receiptCounter
                    };
                }

                var initialTenders = [];

                if ($scope.adjustments && $scope.adjustments.prepaidAmounts) {
                    for (let discount of $scope.adjustments.prepaidAmounts) {
                        var initialTender = {
                            type: 'other',
                            field: 'cashCardAdjustment',
                            amount: discount.amountToDeduct || 0,
                            uuid: Pure.generateUuid(),
                            mealPlanId: discount.id,
                            patronId: discount.patronId,
                            patronMealPlanId: discount.patronMealPlanId,
                            transactionTenderDetail: {
                                otherType: discount.name,
                                otherDisplayName: 'Loyalty Cash Card'
                            }
                        };

                        initialTenders.push(initialTender);
                    }
                }

                LucovaWebSocket.setTransactionStatus(TRANSACTION_STATUS.INITIAL);
                var activeTemplateUrl;
                var activeWindowClass;
                if (KioskService.isKiosk()) {
                    activeTemplateUrl = 'pos/smb/templates/kiosk.pos.tender.tpl.html';
                    activeWindowClass = 'kiosk-modal';
                } else {
                    activeTemplateUrl = 'pos/smb/templates/pos.tender.tpl.html';
                    activeWindowClass = 'smb-pos__tender-modal';
                }
                var tenderModal = $modal.open({
                    templateUrl: activeTemplateUrl,
                    controller: 'PosTenderTransactionCtrl',
                    windowClass: activeWindowClass,
                    animation: false,
                    resolve: {
                        sendToKdsAndBackend: function () {
                            return function (suspendedTransaction, orderNumber) {
                                sendToKdsAndBackend(suspendedTransaction, orderNumber);
                            };
                        },
                        posData: function () {
                            return posData;
                        },
                        patron: function () {
                            return $scope.currentOrder.patron;
                        },
                        lucovaUser: function () {
                            return $scope.currentOrder.patron.lucovaUser;
                        },
                        currentOrder: function () {
                            return $scope.currentOrder;
                        },
                        verified: function () {
                            return $scope.verified; // TODO?
                        },
                        guestTransaction: function () {
                            // return $scope.guestTransaction; // TODO?
                            return $scope.currentOrder.guest;
                        },
                        cashierShiftId: function () {
                            return SmbPosService.shift.cashierShiftId;
                        },
                        locationId: function () {
                            return SmbPosService.shift.locationId;
                        },
                        receipt: function () {
                            return $scope.receipt;
                        },
                        fullReceipt: function () {
                            return $scope.fullReceipt;
                        },
                        available: function () {
                            return angular.copy($scope.available);
                        },
                        servicePeriodId: function () {
                            return SmbPosService.shift.servicePeriodId;
                        },
                        transactionAmount: function () {
                            return SharedFunctionService.getTransactionAmount(undefined, posData);
                        },
                        quickCharge: function () {
                            return false;
                        },
                        campaignDiscount: function () {
                            return {}; // campaignLargerDiscount; // TODO?
                        },
                        manualCoupon: function () {
                            return {
                                manualCouponDiscount: $scope.manualCouponDiscount,
                                manualCouponCode: $scope.manualCouponCode
                            };
                        },
                        TRANSACTION_STATUS: function () {
                            return TRANSACTION_STATUS;
                        },
                        isOffline: function () {
                            return false; // isOffline; // TODO?
                        },
                        suspendedOrder: function () {
                            return $scope.suspendedOrder;
                        },
                        initialAdjustments: function () {
                            return $scope.adjustments;
                        },
                        applyTransactionPercentageDiscount: function () {
                            return $scope.applyTransactionPercentageDiscount;
                        },
                        cancelledItemsCount: function () {
                            return $scope.cancelledItemsCount;
                        },
                        cancelledItemsAmount: function () {
                            return $scope.cancelledItemsAmount;
                        },
                        cancelledItemsUserId: function () {
                            return $scope.cancelledItemsUserId;
                        },
                        solinkCancelledItems: function () {
                            return $scope.solinkCancelledItems;
                        },
                        guestLabel: function () {
                            return $scope.patron.fullName;
                        },
                        modifyExistingItem: function () {
                            return $scope.modifyExistingItem;
                        },
                        removeReceiptItem: function () {
                            return $scope.removeReceiptItem;
                        },
                        modifyItemQuantity: function () {
                            return $scope.modifyItemQuantity;
                        },
                        ticketPrePrint: function () {
                            return ticketPrePrint;
                        },
                        buzzerCode: function () {
                            // This is buzzerCode is defined in pos.order.parent.ctrl.js
                            return $scope.buzzerCode;
                        },
                        initialTenders: function () {
                            return initialTenders;
                        }
                    },
                    backdrop: 'static',
                    keyboard: false
                }, function (error) {
                    $scope.tendering = undefined;
                });

                tenderModal.result.then(function (tender) {
                    $scope.adjustments.lockPrice = false;

                    if (tender === 'done') {
                        $scope.isDone = true;
                    }

                    $scope.tendering = undefined;
                    LucovaWebSocket.setTransactionStatus(TRANSACTION_STATUS.DEAD);
                    $scope.switchView('start');
                }, function (errorResponse) {
                    $scope.tendering = undefined;
                    $scope.adjustments.lockPrice = false;

                    if (errorResponse && errorResponse.goToHome) {
                        LucovaWebSocket.setTransactionStatus(TRANSACTION_STATUS.DEAD);
                        $scope.switchView('start');
                    }
                });
                document.activeElement.blur();
            };

            $scope.tendering = undefined;
            var isTendering = function () {
                return !!$scope.tendering;
            };
            var startTendering = function () {
                $scope.tendering = $timeout(function () {
                    $scope.tendering = undefined;
                }, TENDER_MODAL_TIMEOUT);
            };

            var runTicketPrePrint = function () {
                let patronName;

                if ($scope.patron && $scope.patron.fullName) { // Guest label always stores the cashier entered name as fullName
                    patronName = $scope.patron.fullName;
                } else if ($scope.patron && $scope.patron.firstName) {
                    patronName = $scope.patron.firstName;
                } else {
                    patronName = 'Guest';
                }

                if ($scope.hasTicketPrePrint) {
                    var mockTransactionResponse = {
                        locationName: $scope.shift.location.name,
                        cashierName: '',
                        stationName: $scope.shift.posStation.name,
                        companyName: CurrentSession.getCompany().name,
                        receiptId: undefined, // no receipt id at this point
                        transactionDateTime: new Date().getTime(),
                        patronName: patronName,
                        posPrinters: $scope.shift.posStation.posPrinters,
                        receiptCounter: undefined,
                        serviceMode: $scope.currentOrder.serviceMode
                    };

                    SmbPosService.populateReceiptCounter(mockTransactionResponse);
                    SmbPosService.printKitchenReceipts($scope.receipt, mockTransactionResponse, {buzzerCode: $scope.buzzerCode});

                    return mockTransactionResponse.receiptCounter;
                }

                return undefined;
            };

            $scope.serviceModes = AppConstants.serviceModes;

            $scope.toggleServiceMode = (state) => {
                let newState;
                // unselect
                if (state === $scope.currentOrder.serviceMode) {
                    newState = undefined;
                } else {
                    newState = state;
                }
                $scope.currentOrder.serviceMode = newState;
            };

            const clearDiscounts = () => {
                $scope.discountSelectionDisabled = false;
                $scope.applyTransactionPercentageDiscount(0);
                $scope.applyTransactionDollarDiscount(0);
                delete $scope.adjustments.labelledDiscounts;
            };

            // only one discount offer should by applied at once
            // offer and associatedDiscount validation is done in canDiscountOfferBeUsed
            const applyDiscountOffer = async (offer, associatedDiscount) => {
                if (!offer || !associatedDiscount) {
                    clearDiscounts();
                    return;
                }

                let percentDiscount = 0;
                let dollarDiscount = 0;
                const offerValue = parseInt(offer.offerValue);

                if (offer.offerType === 'PERCENT_DISCOUNT') {
                    percentDiscount += new Decimal(offerValue)
                        .dividedBy(new Decimal(100))
                        .toNumber();
                } else if (offer.offerType === 'AMOUNT_DISCOUNT_CENTS') {
                    dollarDiscount += new Decimal(offerValue)
                        .dividedBy(new Decimal(100))
                        .toNearest(SharedDataService.baseDollar)
                        .toNumber();
                }

                $scope.adjustments.labelledDiscounts = [{
                    id: associatedDiscount.id,
                    userId: associatedDiscount.userId,
                    discountName: associatedDiscount.discountName
                }];

                $scope.applyTransactionPercentageDiscount(percentDiscount);
                $scope.applyTransactionDollarDiscount(dollarDiscount);
                $scope.discountSelectionDisabled = true;
                updateOffersUsedDisplayValue();
            };

            const updateOffersUsedDisplayValue = () => {
                if (!$scope.patron || !$scope.patron.offers) {
                    return;
                }

                let value = '';
                const availableOffers = [...$scope.patron.offers];
                const offersUsed = availableOffers.filter((offer) => offer.autoApply || offer.selected);
                if (offersUsed.length === 1) {
                    value = offersUsed[0].name;
                } else {
                    value = offersUsed.length;
                }
                $scope.$emit('pos::updateOffersUsedDisplayValue', value);
            };

            $scope.$on('pos::applyDiscountOffer', (event, offer, associatedDiscount) => {
                if (offer && associatedDiscount) {
                    applyDiscountOffer(offer, associatedDiscount);
                } else {
                    clearDiscounts();
                }
                updateOffersUsedDisplayValue();
            });

            $scope.init = function () {
                const loyaltyResponse = SmbPosService.getCustomerLoyaltyModalResponse();
                if (loyaltyResponse && loyaltyResponse.discountOfferToUse) {
                    applyDiscountOffer(loyaltyResponse.discountOfferToUse.offer, loyaltyResponse.discountOfferToUse.associatedDiscount);
                } else {
                    updateOffersUsedDisplayValue();
                }
            };

            $scope.init();
        }
    ]);
};
