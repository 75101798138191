'use strict';

const Pure = require('../../external/pure');

export function pure (freshideas) {

    freshideas.factory('Pure', function () {
        var service = {
            convertDollarToCents: Pure.convertDollarToCents,
            convertCentsToDollar: Pure.convertCentsToDollar,
            generateUuid: Pure.generateUuid,
            doTimeout: Pure.doTimeout,
            filterObject: Pure.filterObject,
            buildUrl: Pure.buildUrl,
            calculateNewLabour: Pure.calculateNewLabour,
            splicePreorderArrayFromPatrons: Pure.splicePreorderArrayFromPatrons,
            ipTest: Pure.ipTest
        };
        return service;
    });

}

