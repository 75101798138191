'use strict';

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('KioskLanguageSelectionCtrl', [
        '$scope',
        '$translate',
        '$modalInstance',
        '$timeout',
        'SmbPosService',
        'SharedDataService',
        function (
            $scope,
            $translate,
            $modalInstance,
            $timeout,
            SmbPosService,
            SharedDataService
        ) {

        $scope.organizationLanguages = SmbPosService.data.organizationLanguages;

        $scope.setPreferredLanguage = function (event, preferredLanguage) {
            SharedDataService.preferredLanguage.languageId = preferredLanguage.languageId;
            SharedDataService.preferredLanguage.locale = preferredLanguage.locale;

            $translate.use(preferredLanguage.locale);

            $modalInstance.close();
        };

        let timeOutId;
        var startTimer = function () {
            let timeOutInterval = 3000;

            timeOutId = $timeout(function () {
                $modalInstance.dismiss();
            }, timeOutInterval);
        };

        var resetTimer = function () {
            if (angular.isDefined(timeOutId)) {
                $timeout.cancel(timeOutId);
            }

            startTimer();
        };

        startTimer();
        $(window).on('mousemove mousedown keypress DOMMouseScroll mousewheel touchmove MSPointerMove', resetTimer);

        $scope.$on('$destroy', function () {
            $timeout.cancel(timeOutId);
        });

    }]);
};
