'use strict';


// const angular = require('angular');
module.exports = function (freshideasLocation) {
    freshideasLocation
    .controller('LocationTopnavSideContent', [
        '$scope',
        '$modal',
        '$q',
        '$translate',
        '$location',
        'LocationFactory',
        'Locations',
        'Company',
        'notificationTranslationHelper',
        'ErrorUtil',
        function ($scope, $modal, $q, $translate, $location, LocationFactory, Locations, Company, notificationTranslationHelper, ErrorUtil) {

            $scope.isSearch = false;
            $scope.locationFactory = LocationFactory;

            $scope.options = [
            {'name': 'Import Items', 'action': 'import-items'},
            {'name': 'Edit Modifiers', 'action': 'modifiers'}
            ];

            $scope.activeOption = $scope.options[0];

            var init = function () {
                console.log('Started Topnav');
            };

            // var loadLocations = function () {
            //     return Lookup.companyLocations({'companyId': $scope.locationFactory.companyId}).$promise.then(function (response) {
            //         $scope.locations = _.map(response, function (location) {
            //             location.id = parseInt(location.id);
            //             return location;
            //         });
            //     });
            // };

            $scope.toggleSearch = function () {

                $scope.isSearch = !$scope.isSearch;
            };

            $scope.toggleModifiers = function () {

                if ($scope.locationFactory.sideContent.type === 'modifiers') {
                    this.locationFactory.dismissSideContent();
                } else {
                    this.locationFactory.showModifiers();
                }
            };

            $scope.back = function () {

                $scope.locationFactory.back();
            };

            $scope.showAddCategory = function () {
                $scope.locationFactory.sideContent = {
                    type: 'add-category',
                    data: undefined
                };
            };

            $scope.showAddItem = function () {

                $scope.locationFactory.sideContent = {
                    type: 'add-item',
                    data: undefined
                };
            };

            $scope.showList = function () {

                $scope.locationFactory.listType = 'bar-items';
            };

            $scope.showGrid = function () {

                $scope.locationFactory.listType = 'box-items';
            };

            $scope.showCategories = function () {

                $scope.locationFactory.sideContent = {};
                $scope.locationFactory.listType = 'category-items';
            };

            $scope.selectedOption = function (item) {
                $scope.activeOption = item;

                switch (item.action) {
                    case 'add-item':
                        this.locationFactory.showAddItem();
                        break;
                    case 'add-category':
                        this.locationFactory.showAddCategory();
                        break;
                    case 'assign-category':
                        this.locationFactory.showAssignCategory();
                        break;
                    case 'import-items':
                        $scope.importMenuItems();
                        break;
                    case 'modifiers':
                        this.locationFactory.showModifiers();
                        break;
                }
            };

            init();
        }
        ]);
};
