'use strict';


module.exports = function (nownPos) {
    nownPos.controller('QuickchargeCtrl', [
        '$scope',
        '$modal',
        '$modalInstance',
        '$timeout',
        'GatewayFiit',
        'PosAlertService',
        'SmbPosService',
        'TransactionService',
        'selectedPatron',
        'AudioService',
        'CompanyAttributesService',
        '$translate',
        function (
            $scope,
            $modal,
            $modalInstance,
            $timeout,
            GatewayFiit,
            PosAlertService,
            SmbPosService,
            TransactionService,
            selectedPatron,
            AudioService,
            CompanyAttributesService,
            $translate) {

            const LAST_TX_FETCH_DELAY = 2000; // ms

            $scope.selectedPatron = selectedPatron;
            $scope.remainingBalances;
            $scope.errorMessage;
            $scope.loading = false;

            // status of 0 = access is allowed
            // status of 1 = contact financial aid for access
            // status of 2 = contact food services for access
            $scope.patronHasAccess = $scope.selectedPatron.fiitMpsAccount.status === 0;

            var updatePatronState = function (fiitTender) {
                if (fiitTender.fiitMealPlanType == 'MEAL') {
                    $scope.remainingBalances.mealUnits.totalBalance -= fiitTender.unitsUsed;
                    $scope.remainingBalances.mealUnits.servicePeriodBalance -= fiitTender.unitsUsed;
                } else if (fiitTender.fiitMealPlanType == 'DCB') {
                    $scope.remainingBalances.dcb.totalBalance -= fiitTender.unitsUsed;
                    $scope.remainingBalances.dcb.servicePeriodBalance -= fiitTender.unitsUsed;
                }
            };

            // Alert the parent controller that we can move on if desired
            $scope.alertFinished = () => {
                SmbPosService.setQuickChargeRunning(false);
            };

            var voidModalCloseCallBack = function () {
                isExecutingVoid = false;
                $modalInstance.close();
                $scope.alertFinished();
            };

            var voidModalDismissCallBack = function () {
                isExecutingVoid = false;
                $scope.alertFinished();
            };

            var isExecutingVoid = false;
            $scope.voidTransaction = function () {
                if (!$scope.quickChargeTransaction || !$scope.quickChargeTransaction.transactionId || isExecutingVoid) {
                    return;
                }

                isExecutingVoid = true;

                TransactionService.startVoidTransaction($scope.quickChargeTransaction, voidModalCloseCallBack, voidModalDismissCallBack);
            };

            const playErrorAudio = () => {
                if (CompanyAttributesService.hasSoundEnabled()) {
                    AudioService.loudErrorSound();
                }
            };

            const setLastTransactionTime = () => {
                if (selectedPatron.fiitMpsAccount
                    && selectedPatron.fiitMpsAccount.fiitLastTransaction
                    && selectedPatron.fiitMpsAccount.fiitLastTransaction.transactionDateTime) {
                    $scope.lastTransactionTime = selectedPatron.fiitMpsAccount.fiitLastTransaction.transactionDateTime;
                }
            };

            $scope.init = function () {
                $scope.canBeVoided = false;

                $scope.remainingBalances = {
                    mealUnits: {
                        totalBalance: 0,
                        servicePeriodBalance: 0,
                        ruleDeductions: []
                    },
                    dcb: {
                        totalBalance: 0,
                        servicePeriodBalance: 0,
                        ruleDeductions: []
                    }
                };

                const mealPlans = selectedPatron.fiitMpsAccount.mealPlans;
                if (mealPlans) {
                    mealPlans.forEach((mp) => {
                        if (mp.mealPlanType == 'MEAL') {
                            $scope.remainingBalances.mealUnits.totalBalance += mp.currentMealPlanBalance;
                            $scope.remainingBalances.mealUnits.servicePeriodBalance += mp.remainingServicePeriodMeals;
                            if (mp.unavailablityReasons.ruleApplied) {
                                Array.prototype.push.apply($scope.remainingBalances.mealUnits.ruleDeductions, mp.patronMealRuleDeductions);
                            }
                        } else if (mp.mealPlanType == 'DCB') {
                            $scope.remainingBalances.dcb.totalBalance += mp.currentDcbBalance;
                            $scope.remainingBalances.dcb.servicePeriodBalance += mp.remainingServicePeriodDcb;
                            if (mp.unavailablityReasons.ruleApplied) {
                                Array.prototype.push.apply($scope.remainingBalances.dcb.ruleDeductions, mp.patronMealRuleDeductions);
                            }
                        }
                    });
                }

                $scope.fiitLastTransaction = selectedPatron.fiitMpsAccount.fiitLastTransaction || {loading: true};
                $scope.lastTransactionTime = null;
                $scope.currentTime = Date.now();

                if ($scope.fiitLastTransaction.loading) {
                    // we will wait to fetch the last tx to reduce server load
                    $timeout(() => {
                        GatewayFiit.getLastTransaction(selectedPatron.fiitMpsAccount.patronId)
                        .then((lastTx) => {
                            $scope.fiitLastTransaction = selectedPatron.fiitMpsAccount.fiitLastTransaction = lastTx || {};
                            setLastTransactionTime();
                        })
                        .finally(() => $scope.fiitLastTransaction.loading = false);
                    }, LAST_TX_FETCH_DELAY);
                } else {
                    setLastTransactionTime();
                }

                if (!$scope.patronHasAccess) {
                    $scope.errorMessage = $scope.selectedPatron.fiitMpsAccount.status === 1 ?
                    $translate.instant('patron.popup.tender.label.error.financialAid') :
                    $translate.instant('patron.popup.tender.label.error.foodServices');

                    return;
                }

                $scope.loading = true;
                TransactionService.runQuickChargeTransaction(selectedPatron).then(function (response) {
                    $scope.loading = false;
                    if (!response.tenders
                        || !response.tenders.length
                        || !response.tenders[0].fiitTenders.length) {
                        PosAlertService.showAlertByName('oops-general');
                        playErrorAudio();
                        return;
                    }
                    $scope.fiitTender = response.tenders[0].fiitTenders[0];
                    $scope.quickChargeTransaction = response;
                    $scope.canBeVoided = true;
                    updatePatronState(response.tenders[0].fiitTenders[0]);
                    SmbPosService.loadStatus();
                    $scope.alertFinished();
                }).catch(function (error) {
                    $scope.loading = false;
                    $scope.canBeVoided = false;
                    playErrorAudio();
                    if (error) {
                        if (error.insufficientBalance) {
                            $scope.errorMessage = 'Insufficient balance';
                        } else if (error.preventDCB) {
                            $scope.errorMessage = error.errorMessage || 'DCB Use Declined';
                        } else {
                            $scope.errorMessage = 'Error while running a quick charge transaction';
                        }
                    }

                    if (CompanyAttributesService.hasQuickerChargeEnabled()) {
                        $scope.alertFinished();
                    }

                    SmbPosService.loadStatus();
                });
            };

            $scope.init();
        }
    ]);
};
