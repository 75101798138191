'use strict';

const angular = require('angular');

export default angular.module('freshideas.services.customReceipt', [])
.factory('CustomReceiptService', [
    'PRINTOUT_TYPE',
    function (
        PRINTOUT_TYPE) {

        var prepareReceiptPrintOptions = function (item) {
            if (item.printOnKitchenSheet && item.printOnCustomerReceipt) {
                if (item.children) {
                    item.children.forEach(function (child) {
                        child.printoutType = PRINTOUT_TYPE.ALL;
                    });
                }
                item.printoutType = PRINTOUT_TYPE.ALL;
            } else if (item.printOnKitchenSheet) {
                if (item.children) {
                    item.children.forEach(function (child) {
                        child.printoutType = PRINTOUT_TYPE.KITCHEN_SHEET;
                    });
                }
                item.printoutType = PRINTOUT_TYPE.KITCHEN_SHEET;
            } else if (item.printOnCustomerReceipt) {
                if (item.children) {
                    item.children.forEach(function (child) {
                        child.printoutType = PRINTOUT_TYPE.CUSTOMER_RECEIPT;
                    });
                }
                item.printoutType = PRINTOUT_TYPE.CUSTOMER_RECEIPT;
            } else {
                if (item.children) {
                    item.children.forEach(function (child) {
                        child.printoutType = PRINTOUT_TYPE.NONE;
                    });
                }
                item.printoutType = PRINTOUT_TYPE.NONE;
            }
            return item;
        };

        var processReceiptPrintFilters = function (entries) {
            entries.forEach(function (entry) {
                if (entry.printoutType) {
                    switch (entry.printoutType) {
                        case PRINTOUT_TYPE.ALL:
                            entry.printOnCustomerReceipt = true;
                            entry.printOnKitchenSheet = true;
                            break;
                        case PRINTOUT_TYPE.KITCHEN_SHEET:
                            entry.printOnCustomerReceipt = false;
                            entry.printOnKitchenSheet = true;
                            break;
                        case PRINTOUT_TYPE.CUSTOMER_RECEIPT:
                            entry.printOnCustomerReceipt = true;
                            entry.printOnKitchenSheet = false;
                            break;
                        case PRINTOUT_TYPE.NONE:
                            entry.printOnCustomerReceipt = false;
                            entry.printOnKitchenSheet = false;
                            break;
                    }
                }
                if (entry.children) {
                    entry.children = processReceiptPrintFilters(entry.children);
                } else if (entry.entries) {
                    entry.entries = processReceiptPrintFilters(entry.entries);
                }

            });
            return entries;
        };

        var processReceiptPrintFiltersV2 = function (entry) {
            if (entry.children) {
                entry.children.forEach((child) => {
                    child.printOnKitchenSheet = !!entry.printOnKitchenSheet;
                    child.printOnCustomerReceipt = !!entry.printOnCustomerReceipt;
                });
            }

            return entry;
        };

        return {
            prepareReceiptPrintOptions: prepareReceiptPrintOptions,
            processReceiptPrintFilters: processReceiptPrintFilters,
            processReceiptPrintFiltersV2: processReceiptPrintFiltersV2
        };
    }
]);
